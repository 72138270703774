import React, { PureComponent } from 'react';
import { View, StyleSheet, Text, Platform } from 'react-native';
import { LAYOUT, CURRENCY_SYMBOL, THEME } from '../../config/Constants';
import colors from '../../theme/Colors';
import Utility from '../../utils/Utility';
import FastImageView from '../FastImageView';
import ProductAttributesAndProperties from './ProductAttributesAndProperties';
import { isDesktop, isWeb, isIOS, isPresent } from '../../utils/BooleanUtility';
import { PRODUCT_RIGHT_CONTAINER_WIDTH } from '../../config/LayoutConstants/ProductConfig';
import { getMinifiedImage } from '../../utils/ImageUtility';
import ProductPrice from './ProductPrice';

class ProductImageWithDescriptionCard extends PureComponent {
  render() {
    const {
      itemData: {
        id = '',
        sku_id = '',
        name = '',
        mrp = '',
        image_webp_url: imageUrl = '',
        brand = {},
        brand: { name: brandName = '' } = {},
        product_category = {},
        sp,
        discount,
      } = {},
      navigation,
      fromProductPage = false,
    } = this.props;

    const textStyle = isWeb()
      ? styles.productDescriptionWeb
      : styles.productDescription;
    return (
      <View
        style={[
          styles.container,
          fromProductPage &&
            isDesktop() && {
            width: PRODUCT_RIGHT_CONTAINER_WIDTH,
            marginTop: 0,
          },
        ]}
      >
        {!isDesktop() && (
          <FastImageView
            style={styles.image}
            source={isWeb() ? imageUrl : Utility.getMinifiedImage(imageUrl, styles.image.width, styles.image.height)}
            resizeMode='contain'
          />
        )}
        <View style={styles.contentContainer}>
          <Text style={styles.brand}>{brandName}</Text>
          <Text style={textStyle} numberOfLines={2}>
            {name}
          </Text>
          <ProductPrice sp={sp} mrp={mrp} discount={discount} removePadding />
          <ProductAttributesAndProperties
            brand={brand}
            productCategory={product_category}
            navigation={navigation}
            id={id}
            sku_id={sku_id}
            addHorizontalPadding={false}
            reduceWidthForDesktop={fromProductPage && isDesktop()}
          />
        </View>
      </View>
    );
  }
}

export default ProductImageWithDescriptionCard;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    paddingVertical: 28,
    paddingHorizontal: 12,
    backgroundColor: colors.white,
    width: Utility.getScreenWidth(),
    ...Platform.select({
      ios: { marginTop: 80 },
      android: { marginTop: 24 },
      web: { marginTop: 0 },
    }),
  },
  image: {
    height: 164,
    width: 164,
  },
  brand: {
    fontFamily: 'Roboto-Medium',
    fontSize: 11,
    fontStyle: 'normal',
    color: '#979BAA',
  },
  productDescription: {
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
    fontStyle: 'normal',
    color: colors.foxyBlack,
    marginVertical: 4,
  },
  productDescriptionWeb: {
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
    fontStyle: 'normal',
    color: colors.foxyBlack,
    marginVertical: 4,
    width: PRODUCT_RIGHT_CONTAINER_WIDTH - 20,
  },
  price: {
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
    fontStyle: 'normal',
    color: colors.foxyBlack,
  },
  contentContainer: {
    flexDirection: 'column',
    marginTop: 24,
    paddingLeft: 8,
    width: Utility.getScreenWidth() - 188,
  },
});
