import React, { PureComponent } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import colors from '../../theme/Colors';
import size from '../../theme/Fonts';
import Utility from '../../utils/Utility';
import TimeUtility from '../../utils/TimeUtility';
import WithNavigation from '../../utils/WithNavigation';
import RemoteConfig from '../../utils/RemoteConfig';
import { REMOTE_CONFIG_KEYS } from '../../config/Constants';
import { interpolateString } from '../../utils/StringUtility';
import { isDesktop } from '../../utils/BooleanUtility';
import { PRODUCT_LEFT_CONTAINER_WIDTH } from '../../config/LayoutConstants/ProductConfig';
import { getScreenWidth } from '../../utils/LayoutUtility';

class ProductDelayTray extends PureComponent {
  render() {
    // OrderCampaign is missing in the Api Call
    const { orderCampaign: { expected_instock_date = '' } = {} } = this.props;
    const preOrderDate = expected_instock_date ?? '';
    if (Utility.isBlank(preOrderDate)) {
      return null;
    }
    const advanceOrderCampaignProductText = RemoteConfig.getValue(
      REMOTE_CONFIG_KEYS.advance_order_campaign_product_text,
    );
    let bottom;
    if (Utility.isWeb()) {
      bottom = 30;
    } else if (Utility.isAndroid()) {
      bottom = 68;
    } else {
      bottom = 78;
    }
    const containerStyle = isDesktop() ? styles.containerDesktop : styles.container
    return (
      <View style={[containerStyle, { bottom }]}>
        <View style={styles.textContainer}>
          {advanceOrderCampaignProductText ? (
            <Text style={styles.textNormal}>
              {interpolateString(advanceOrderCampaignProductText, {
                date: preOrderDate,
              })}
            </Text>
          ) : (
            <>
              <Text style={styles.textNormal}>
                Item is expected to ship on or before
              </Text>
              <Text style={styles.textBold}> {preOrderDate}</Text>
            </>
          )}
        </View>
      </View>
    );
  }
}

export default WithNavigation(ProductDelayTray);

const styles = StyleSheet.create({
  container: {
    width: Utility.getScreenWidth(),
    flexDirection: 'row',
    backgroundColor: '#E8F4FF',
    position: 'absolute',
    zIndex: 1,
    alignItems: 'center',
    height: 28,
  },
  containerDesktop: {
    width: isDesktop() ? PRODUCT_LEFT_CONTAINER_WIDTH : getScreenWidth(),
    backgroundColor: '#E8F4FF',
    zIndex: 101,
    top: 10,
    height: 28,
  },
  textContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    marginHorizontal: 12,
  },
  textNormal: {
    fontSize: size.h4,
    fontFamily: 'Roboto-Regular',
    color: colors.foxyBlack,
  },
  textBold: {
    fontSize: size.h4,
    fontFamily: 'Roboto-Bold',
    color: colors.foxyBlack,
    marginTop: Utility.isIOS() ? 1 : 0,
    flex: 1,
  },
});
