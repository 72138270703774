import React from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { useRoute } from '@react-navigation/native';
import { useProfiledNavigation } from '@shopify/react-native-performance-navigation';

// Creating the HOC
function withProfiledNavigation(Component) {
  const ComponentWithNavigation = (props) => {
    const navigation = useProfiledNavigation();
    const route = useRoute();

    return <Component {...props} navigation={navigation} route={route} />;
  };

  hoistNonReactStatics(ComponentWithNavigation, Component);

  return ComponentWithNavigation;
}

export default withProfiledNavigation;
