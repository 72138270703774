import React from 'react';
import { View, StyleSheet, Text, TouchableOpacity } from 'react-native';
import colors from '../../theme/Colors';
import size from '../../theme/Fonts';
import Utility from '../../utils/Utility';
import FastImageView from '../FastImageView';
import WithNavigation from '../../utils/WithNavigation';
import { AnalyticsManager, EventType } from '../../analytics';
import ContactsUsUtility from '../../utils/ContactUsUtility';
import images from '../../theme/Images';
import ModalContainerWithoutSafeArea from '../modals/ModalContainerWithoutSafeArea';
import { memoize } from 'lodash';

const ContactUsModal = ({
  navigation,
  route: { params: { contactUsModalData = {} } = {} } = {},
}) => {
  const closeModal = () => {
    navigation.pop();
  };

  const onContactUsOptionPress = (option) => {
    AnalyticsManager.logEvent(EventType.profileEvents.OTHER_ACTIONS_CLICK, {
      type: 'contact_us',
    });
    closeModal();
    ContactsUsUtility.onContactUsModalOptionPress(option);
  };

  const { heading = '', share_options = [] } = contactUsModalData;

  return (
    <ModalContainerWithoutSafeArea>
      <View style={styles.bottomModal}>
        <Text style={styles.title}>{heading}</Text>
        <View style={styles.shareOptionsContainer}>
          {share_options.map((option) => (
            <TouchableOpacity
              key={option.name}
              style={styles.optionContainer}
              onPress={memoize(
                () => onContactUsOptionPress(option),
                () => [option.name],
              )}
            >
              <FastImageView
                source={option.icon_url}
                style={styles.shareOptionImage}
                resizeMode="contain"
              />
              <Text style={styles.shareOptionLabel}>{option.name}</Text>
            </TouchableOpacity>
          ))}
        </View>
        <View style={styles.modal_close_container}>
          <TouchableOpacity onPress={closeModal}>
            <FastImageView
              style={styles.modal_close}
              source={images.edge.modal_close}
            />
          </TouchableOpacity>
        </View>
      </View>
    </ModalContainerWithoutSafeArea>
  );
};

export default WithNavigation(ContactUsModal);

const styles = StyleSheet.create({
  bottomModal: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    marginBottom: 0,
    marginRight: 0,
    marginLeft: 0,
    paddingHorizontal: 12,
    paddingBottom: 24,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    backgroundColor: colors.white,
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
  },
  container: {
    paddingHorizontal: 12,
    paddingTop: 12,
  },
  title: {
    color: colors.black,
    fontSize: size.h3,
    fontFamily: 'Roboto-Regular',
    marginVertical: 8,
    marginTop: 6,
  },
  optionContainer: {
    width: (Utility.getScreenWidth() - 48) / 3,
    justifyContent: 'center',
    marginVertical: 16,
    alignItems: 'center',
  },
  shareOptionsContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    flex: 1,
  },
  shareOptionImage: {
    height: 40,
    width: 40,
  },
  shareOptionLabel: {
    fontSize: 14,
    fontFamily: 'Roboto-Regular',
    marginTop: 4,
    color: colors.subtitle,
  },
  modal_close_container: {
    position: 'absolute',
    zIndex: 1,
    top: 16,
    right: 12,
  },
  modal_close: {
    height: 23,
    width: 23,
    resizeMode: 'contain',
  },
});
