import React, { useMemo } from 'react';
import { TouchableOpacity } from 'react-native';
import Utility from '../../utils/Utility';

const DebouncedPressable = ({ onPress, style = {}, children }) => (
  <TouchableOpacity
    onPress={onPress}
    style={style}
    hitSlop={Utility.getHitSlop('button')}
  >
    {children}
  </TouchableOpacity>
);

export default DebouncedPressable;
