import { getStoreRef } from '../store/StoreUtility';
import AnalyticsManager from './AnalyticsManager';
import EventParameterKey from './EventParameterKey';
import { incrementSystemPrompt } from '../actions/ActionTypes';

export const triggerPermissionsEvent = (
  eventName,
  permissionName,
  source,
  initiated_from = 'automatic_app_launch',
) => {
  const state = getStoreRef().getState();
  const { permissionStatus = {} } = state;

  const {
    increment_system_prompt = 0,
    increment_foxy_notification_prompt = 0,
  } = permissionStatus;

  let shownModalCount = {
    viewed_system_prompt: 'NA',
    viewed_custom_prompt: 'NA',
  };

  if (eventName == 'permission_prompt') {
    shownModalCount.viewed_system_prompt = increment_system_prompt + 1;
    shownModalCount.viewed_custom_prompt = increment_foxy_notification_prompt;

    getStoreRef().dispatch(incrementSystemPrompt());
  } else {
    shownModalCount.viewed_system_prompt = increment_system_prompt;
    shownModalCount.viewed_custom_prompt = increment_foxy_notification_prompt;
  }

  AnalyticsManager.logEvent(eventName, {
    [EventParameterKey.PERMISSION_NAME]: permissionName,
    [EventParameterKey.SOURCE]: source,
    initiated_from,
    ...shownModalCount,
  });
};
