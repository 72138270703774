import {
  FlatList,
  Image,
  LayoutAnimation,
  Platform,
  StyleSheet,
  Text,
  UIManager,
  View,
  TouchableOpacity,
  Linking,
} from 'react-native';
import React, { Component } from 'react';
import StepIndicator from 'react-native-step-indicator';
import { ScrollView } from 'react-native-gesture-handler';
import _ from 'lodash';
import colors from '../../../theme/Colors';
import withNavigation from '../../../utils/WithNavigation';
import {
  LAYOUT,
  CURRENCY_SYMBOL,
  stepIndicatorConfig,
} from '../../../config/Constants';
import Utility from '../../../utils/Utility';

import FastImageView from '../../FastImageView';
import images from '../../../theme/Images';

import { cartItemsStyles } from '../../payment/paymentStatus/styles';
import RoundedButton from '../../layout/buttons/RoundedButton';

import {
  AnalyticsManager,
  EventParameterKey,
  EventType,
} from '../../../analytics';
import { SCREEN_CONSTANTS } from '../../../config/ScreenConstants';
import { navigateToScreen } from '../../../utils/NavigationUtility';
import { isWeb } from '../../../utils/BooleanUtility';
import { getMinifiedImage } from '../../../utils/ImageUtility';

class OrderStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showItemsDetails: false,
    };
    if (
      Platform.OS === 'android' &&
      UIManager.setLayoutAnimationEnabledExperimental
    ) {
      UIManager.setLayoutAnimationEnabledExperimental(true);
    }
  }

  toggleShowItemsDetails = () => {
    const { showItemsDetails } = this.state;
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
    AnalyticsManager.logEvent('details_view', {
      type: 'item_details',
      action: !showItemsDetails ? 'expand' : 'collapse',
    });
    this.setState((prevState) => ({
      showItemsDetails: !prevState.showItemsDetails,
    }));
  };

  getStepperLabels = (status) => {
    return status === 'cancelled'
      ? ['Confirmed', 'Cancelled']
      : ['Confirmed', 'Packed', 'Shipped', 'Delivered'];
  };

  getCurrentStepperPosition = (status) => {
    return status === 'cancelled' ? 2 : Utility.getShipmentStatus(status);
  };

  divider = () => {
    return <View style={styles.divider} />;
  };

  stepper = () => {
    const {
      metaData: { aasmState },
      delay = {},
    } = this.props;
    if (
      [
        'rto',
        'rto_received',
        'rto_failed',
        'return_requested',
        'return_request_cancelled',
        'return_completed',
        'returned_to_origin',
        'returning_to_origin',
        'return_processing',
      ].includes(aasmState)
    ) {
      return null;
    }
    const labels = this.getStepperLabels(aasmState);
    const stepCount = aasmState === 'cancelled' ? 2 : 4;
    const currentPosition = this.getCurrentStepperPosition(aasmState);

    let stepperConfigs = { ...stepIndicatorConfig };
    if (aasmState === 'cancelled') {
      stepperConfigs = Utility.updateStepperColor(colors.subtitle);
    }
    if (aasmState === 'cancellation_requested') {
      stepperConfigs = Utility.updateStepperColor(colors.subtitle);
    }
    if (aasmState === 'awaiting_confirmation') {
      stepperConfigs = Utility.updateStepperColor(colors.subtitle);
    }
    if (aasmState === 'lost_in_transit') {
      stepperConfigs = Utility.updateStepperColor(
        colors.orderPage.lost_in_transit_status,
      );
    }
    if (aasmState === 'third_attempt_failed') {
      stepperConfigs = Utility.updateStepperColor(
        colors.orderPage.third_attempt_failed_status,
      );
    }
    if (
      aasmState === 'first_attempt_failed' ||
      aasmState === 'second_attempt_failed'
    ) {
      stepperConfigs = Utility.updateStepperColor(
        colors.orderPage.lost_in_transit_status,
      );
    }

    if (
      [
        'rto',
        'rto_received',
        'rto_failed',
        'return_requested',
        'return_request_cancelled',
        'return_completed',
        'returned_to_origin',
        'returning_to_origin',
        'return_processing',
      ].includes(aasmState)
    ) {
      stepperConfigs = Utility.updateStepperColor(colors.cta.lightBlue);
    }

    if (
      Utility.isPresent(delay.delayInDispatch) ||
      Utility.isPresent(delay.delayInDelivery)
    ) {
      stepperConfigs = Utility.updateStepperColor(
        colors.orderPage.lost_in_transit_status,
      );
    }

    return (
      <View style={styles.container}>
        <StepIndicator
          customStyles={stepperConfigs}
          currentPosition={currentPosition}
          stepCount={stepCount}
          labelAlign='center'
          labels={labels}
        />
      </View>
    );
  };

  trackShipmentImage = () => {
    return (
      <Image
        source={images.foxyPromise.fastDelivery}
        style={styles.trackingImage}
        tintColor={colors.white}
        resizeMode='contain'
      />
    );
  };

  trackUrl = () => {
    const { navigation, trackingUrl, awbNumber } = this.props;
    AnalyticsManager.logEvent('item_action', {
      item_type: 'track_shipment',
      source: 'order_page',
    });

    if (isWeb()) {
      Linking.openURL(trackingUrl);
      return;
    }
    navigation.navigate('WebUrlView', {
      destination: trackingUrl,
      title: `Tracking - #${awbNumber}`,
    });
  };

  orderStatus = () => {
    const {
      metaData: {},
      metaData: { orderItems, status },
      trackingUrl,
      showTracking,
    } = this.props;
    let filteredItems = _.filter(orderItems, (item = {}) => {
      return item.status === status;
    });

    if (Utility.isBlank(filteredItems)) {
      filteredItems = orderItems || {};
    }

    if (Utility.isBlank(filteredItems)) {
      return null;
    }

    let heading = '';
    let detailedSubheading = '';
    const delayedFilteredItems = filteredItems.filter(
      (item) => item.advanceOrderCampaignPresent === true,
    );

    if (
      Utility.isPresent(delayedFilteredItems) &&
      delayedFilteredItems.length > 0
    ) {
      const {
        heading: orderHeading,
        detailedSubheading: orderDetailedSubheading,
      } = delayedFilteredItems[0];
      heading = orderHeading;
      detailedSubheading = orderDetailedSubheading;
    } else {
      const {
        heading: orderHeading,
        detailedSubheading: orderDetailedSubheading,
      } = filteredItems[0];
      heading = orderHeading;
      detailedSubheading = orderDetailedSubheading;
    }

    return (
      <View>
        <Text style={styles.orderstatusHeading}>{heading}</Text>
        <View style={styles.orderStatusSubheadingContainer}>
          <Text style={styles.subheading}>{detailedSubheading}</Text>

          {Utility.isPresent(trackingUrl) && (
            <RoundedButton
              buttonText={'Track status'}
              buttonTextColor={colors.white}
              buttonColor={colors.black}
              buttonAction={this.trackUrl}
              style={styles.roundedButtonContainer}
              buttonTextStyle={styles.buttonText}
              renderProp={this.trackShipmentImage}
            />
          )}
        </View>
      </View>
    );
  };

  renderOrderItemImage = (item, index, length) => {
    if (index === 6) {
      return (
        <View
          style={[
            styles.orderItem,
            {
              alignItems: 'center',
              justifyContent: 'center',
            },
          ]}
        >
          <Text
            style={{
              fontSize: 14,
              fontFamily: 'Roboto-Medium',
              color: colors.foxyBlack,
            }}
          >{`+${length - 6}`}</Text>
        </View>
      );
    }
    if (index > 4) {
      return null;
    }
    return (
      <FastImageView
        source={getMinifiedImage(item.imageUrl, styles.orderItem.width, styles.orderItem.width)}
        style={styles.orderItem}
        resizeMode='contain'
      />
    );
  };

  keyExtractorOrderItemImage = (item = {}) => `${item?.id}`;

  filteredItems = () => {
    const {
      metaData: { orderItems, status },
    } = this.props;
    const { showItemsDetails } = this.state;
    let filteredItems = _.filter(orderItems, (item) => {
      return item.status === status;
    });

    if (Utility.isBlank(filteredItems)) {
      filteredItems = orderItems || {};
    }
    const combinedEsp = _.reduce(
      filteredItems,
      (sum, item) => {
        return sum + item.esp;
      },
      0,
    );

    return (
      <View>
        <ScrollView
          style={styles.orderItemsContainer}
          horizontal
          showsHorizontalScrollIndicator={false}
        >
          <FlatList
            data={filteredItems}
            renderItem={({ item, index }) =>
              this.renderOrderItemImage(item, index, filteredItems.length)
            }
            keyExtractor={this.keyExtractorOrderItemImage}
            horizontal
          />
        </ScrollView>
        {/* <this.divider /> */}
        {/* <this.itemSummary
          filteredItems={filteredItems}
          combinedEsp={combinedEsp}
        /> */}
        {showItemsDetails && (
          <this.itemsDetail
            filteredItems={filteredItems}
            combinedEsp={combinedEsp}
          />
        )}

        <TouchableOpacity
          style={styles.chevronContainer}
          onPress={this.toggleShowItemsDetails}
        >
          <Image
            source={showItemsDetails ? images.chevronUp : images.chevronDown}
            style={styles.chevron}
          />
        </TouchableOpacity>
      </View>
    );
  };

  keyExtractor = (item = {}) => `${item?.id}_grid`;

  onProductCardPress = (slug, item) => {
    const { navigation, previousScreen = '' } = this.props;
    const { itemType = '', name = '', id = '', slug: itemSlug = '' } = item;

    if (itemType === 'Routine') {
      AnalyticsManager.logEvent(EventType.routine.ITEM_CLICK, {
        [EventParameterKey.TYPE]: itemType,
        [EventParameterKey.NAME]: name,
        [EventParameterKey.ENTITY]: 'routine',
        [EventParameterKey.ID]: id,
        [EventParameterKey.SLUG]: itemSlug,
        [EventParameterKey.SCREEN_NAME]: previousScreen,
      });
      navigation.push('PreRoutineDetails', {
        slug,
        source: 'orders_page',
      });
      return;
    }
    const {
      metaData: { orderItems = [] },
    } = this.props;
    const isLoyaltyPlan = Utility.isPresent(
      _.filter(orderItems, (i) => {
        return i.itemType === 'LoyaltyPlan';
      }),
    );
    if (isLoyaltyPlan) {
      navigateToScreen({
        navigation,
        type: 'navigate',
        screen: 'LoyaltyPlans',
        params: {
          previousScreen: SCREEN_CONSTANTS.ORDER_SUCCESS,
        },
      });
      return;
    }
    navigateToScreen({
      navigation,
      type: 'push',
      screen: 'Product',
      params: {
        slug: `/api/v2/products/${slug}`,
        display: LAYOUT.SCREEN,
      },
    });
  };

  renderItem = ({ item, index }) => {
    const {
      quantity,
      imageUrl,
      name,
      esp,
      brandName,
      variant = {},
      slug,
    } = item;

    const {
      metaData: { orderItems, status },
    } = this.props;

    const noOfItems = _.filter(orderItems, (i) => {
      return i.status === status;
    }).length;

    if (Utility.isBlank(item)) {
      return null;
    }
    return (
      <TouchableOpacity
        onPress={_.memoize(
          () => this.onProductCardPress(slug, item),
          () => [slug],
        )}
      >
        <View style={cartItemsStyles.container}>
          <View style={cartItemsStyles.imageContainer}>
            <FastImageView
              source={getMinifiedImage(
                imageUrl,
                cartItemsStyles.image.width,
                cartItemsStyles.image.height,
              )}
              style={cartItemsStyles.image}
              resizeMode='contain'
            />
          </View>

          <View style={cartItemsStyles.itemDetailsConntainer}>
            <Text style={cartItemsStyles.brandName}>{brandName}</Text>
            <Text
              style={cartItemsStyles.productName}
              numberOfLines={2}
              ellipsizeMode='tail'
            >
              {name}
            </Text>
            {Utility.isPresent(variant) ? (
              <View style={cartItemsStyles.variantContainer}>
                {Utility.isPresent(getMinifiedImage(variant?.image_url)) && (
                  <FastImageView
                    source={getMinifiedImage(
                      variant?.image_url,
                      cartItemsStyles.variantImage.width,
                      cartItemsStyles.variantImage.height,
                    )}
                    style={cartItemsStyles.variantImage}
                    resizeMode='contain'
                  />
                )}
                <Text
                  style={cartItemsStyles.variantDisplayName}
                  numberOfLines={1}
                >
                  {variant?.display_name}
                </Text>
                <Text style={cartItemsStyles.quantityWithVariant}>
                  {` x ${quantity}`}
                </Text>
              </View>
            ) : (
              <Text style={cartItemsStyles.quantity}>{`Qty: ${quantity}`}</Text>
            )}
          </View>

          <View style={cartItemsStyles.spContainer}>
            <Text style={cartItemsStyles.sp}>
              {`${CURRENCY_SYMBOL} ${esp || 0}`}
            </Text>
          </View>
        </View>
        {index !== noOfItems - 1 && <this.divider />}
      </TouchableOpacity>
    );
  };

  itemsDetail = ({ filteredItems = [] }) => {
    return (
      <FlatList
        data={filteredItems}
        extraData={{ ...this.props, ...this.state }}
        keyExtractor={this.keyExtractor}
        ListHeaderComponent={this.divider}
        ListFooterComponent={this.divider}
        renderItem={this.renderItem}
      />
    );
  };

  itemSummary = ({ filteredItems = [], combinedEsp = 0 }) => {
    const { showItemsDetails } = this.state;
    return (
      <TouchableOpacity
        style={styles.itemSummary}
        onPress={this.toggleShowItemsDetails}
      >
        <Text style={styles.itemSummaryText}>
          {`${filteredItems.length} items • ${CURRENCY_SYMBOL}${combinedEsp}`}
        </Text>
        <Image
          source={showItemsDetails ? images.chevronUp : images.chevronDown}
          style={styles.chevron}
        />
      </TouchableOpacity>
    );
  };

  shipmentsWithAwb = () => {
    const { index, totalShipments } = this.props;
    if (Utility.isBlank(totalShipments)) {
      return null;
    }
    if (totalShipments <= 0) {
      return null;
    }
    return (
      <View style={styles.shipmentsWithAwbContainer}>
        <Text style={styles.shipmentsWithAwbText}>
          {`Shipment: ${index + 1}/ ${totalShipments}`}
        </Text>
      </View>
    );
  };

  render() {
    return (
      <View style={styles.container}>
        <this.shipmentsWithAwb />
        <this.stepper />
        <this.divider />
        <this.orderStatus />
        <this.filteredItems />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    marginTop: 12,
    paddingHorizontal: 12,
  },
  shipmentsWithAwbContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 8,
  },
  shipmentsWithAwbText: {
    fontSize: 12,
    fontFamily: 'Roboto-Medium',
    color: '#979BAA',
  },
  itemSummary: {
    position: 'absolute',
    height: 300,
    width: 300,
    backgroundColor: 'red',
  },
  itemSummaryText: {
    fontSize: 14,
    fontFamily: 'Roboto-Medium',
    color: colors.foxyBlack,
  },
  chevron: {
    height: 14,
    width: 14,
    resizeMode: 'contain',
    tintColor: colors.foxyBlack,
    // position: 'absolute',
    // right: 12,
    // top: 16,
  },
  orderItemsContainer: { flexDirection: 'row', marginVertical: 8, zIndex: 1 },
  orderItem: {
    height: 36,
    width: 36,
    borderRadius: 18,
    borderWidth: 1,
    borderColor: '#F4F6F8',
    marginLeft: 4,
  },
  divider: {
    width: '100%',
    height: 1,
    backgroundColor: '#EFEFEF',
    marginVertical: 12,
  },
  orderstatusHeading: {
    fontSize: 14,
    fontFamily: 'Roboto-Medium',
    color: colors.foxyBlack,
  },
  orderStatusSubheadingContainer: { flexDirection: 'row' },
  subheading: {
    fontSize: 14,
    fontFamily: 'Roboto-Regular',
    color: '#979BAA',
    flex: 2,
  },
  roundedButtonContainer: {
    height: 36,
    borderRadius: 16,
    alignItems: 'center',
    marginTop: -14,
    justifyContent: 'center',
    paddingHorizontal: 12,
    paddingVertical: 8,
  },
  buttonText: {
    fontSize: 14,
    fontFamily: 'Roboto-Medium',

    textAlign: 'center',
  },
  trackingImage: {
    height: 18,
    width: 18,
    marginRight: 4,
    tintColor: colors.white,
  },
  chevronContainer: {
    resizeMode: 'contain',
    tintColor: colors.foxyBlack,
    position: 'absolute',
    right: 12,
    top: 12,
    borderColor: colors.border,
    borderWidth: 1,
    height: 32,
    width: 32,
    borderRadius: 18,
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1,
  },
});

export default withNavigation(OrderStatus);
