import { combineReducers, applyMiddleware, createStore, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { persistReducer, createMigrate } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/hardSet';
import FilesystemStorage from 'redux-persist-filesystem-storage';
import createDebugger from 'redux-flipper';
import rootSaga from '../sagas';
// Reactotron Stuff
import UserAccountInfo from './UserAccountInfo';
import InterestsList from './InterestsList';
import Posts from './Posts';
import data from './FeedData';
import feedDetails from './FeedDetails';
import storeDetails from './StoreDetails';
import artistDetails from './ArtistDetails';
import brandDetails from './BrandDetails';
import cart from './Cart';
import queue from './Queue';
import searchDetails from './SearchDetails';
import order from './Order';
import facialAnalysis from './FacialAnalysis';
import followFeatureCard from './FollowCards';
import stories from './Stories';
import bag from './bag';
import todayDeals from './todayDeals';
import userInteractions from './userInteractions';
import contactsData from './ContactsData';
import boostedOffers from './BoostedOffers';
import RetailStore from './RetailStore';
import zoomAnimationVisibility from './ZoomAnimationVisibility';
import routines from './Routines';
import { isWeb } from '../utils/BooleanUtility';
import permissionStatus from './PermissionsStatus';
import userPersonalizationInfo from './UserPersonalizationInfo';
import productReducer from './productReducer';

const rootReducer = combineReducers({
  bag,
  UserAccountInfo,
  InterestsList,
  Posts,
  data,
  feedDetails,
  storeDetails,
  artistDetails,
  cart,
  brandDetails,
  queue,
  searchDetails,
  order,
  facialAnalysis,
  followFeatureCard,
  stories,
  todayDeals,
  userInteractions,
  contactsData,
  boostedOffers,
  RetailStore,
  zoomAnimationVisibility,
  routines,
  permissionStatus,
  userPersonalizationInfo,
  product: productReducer,
});

const migrations = {
  9: (state) => ({
    ...state,
    brandDetails: {
      ...state.brandDetails,
      isLoading: false,
    },
    productDetails: {
      ...state.productDetails,
      isLoading: false,
    },
    artistDetails: {
      ...state.artistDetails,
      isLoading: false,
    },
    UserAccountInfo: {
      ...state.UserAccountInfo,
      viewedArtistStory: {},
    },
  }),
  10: (state) => ({
    ...state,
    UserAccountInfo: {
      ...state.UserAccountInfo,
      viewedArtistStory: {},
    },
  }),
  11: (state) => ({
    ...state,
    UserAccountInfo: {
      ...state.UserAccountInfo,
      addresses: {},
    },
    order: {},
    checkoutDetails: {
      lastPaymentMode: {},
    },
    cart: {
      ...state.cart,
      cartItems: [],
    },
  }),
  12: (state) => ({
    ...state,
    UserAccountInfo: {
      ...state.UserAccountInfo,
      profile: {
        name: '',
        email: '',
        locality: '',
        handle: '',
      },
    },
  }),
  13: (state) => ({
    ...state,
    UserAccountInfo: {
      ...state.UserAccountInfo,
      checkoutDetails: {
        lastPaymentMode: {
          mode: '',
          detail: '',
        },
      },
    },
  }),
  14: (state) => ({
    ...state,
    UserAccountInfo: {
      ...state.UserAccountInfo,
      youtube_profile: {
        server_auth_code: '',
        profile_pic: '',
        idToken: '',
        id: '',
        email: '',
        user_name: '',
      },
      instagram_profile: {
        insta_user_name: '',
        insta_full_name: '',
        insta_profile_picture_url: '',
      },
      mediaCategories: [],
      watermark: '',
      singletonSteps: {
        createDirectory: true,
        productTutorialVideo: true,
        scanningTutorialVideo: true,
        downloadLogo: true,
        createWatermark: true,
        loadMediaCategories: true,
      },
    },
  }),
  15: (state) => ({
    ...state,
    UserAccountInfo: {
      ...state.UserAccountInfo,
      artistOnboarding: {
        ...state.UserAccountInfo.artistOnboarding,
        onboardingCompleted: false,
        showCongratulationsModal: false,
      },
    },
  }),

  16: (state) => ({
    ...state,
    UserAccountInfo: {
      ...state.UserAccountInfo,
      currentOtp: '',
      requestedFromHintPrompt: false,
    },
  }),

  17: (state) => ({
    ...state,
    UserAccountInfo: {
      ...state.UserAccountInfo,
      fetchingProductVariants: false,
    },
  }),
  18: (state) => ({
    ...state,
    UserAccountInfo: {
      ...state.UserAccountInfo,
      profileAnalytics: {
        youtube: {
          subscriberCount: '0',
          accessToken: '',
          refreshToken: '',
        },
        instagram: {
          followersCount: '0',
        },
      },
    },
  }),

  19: (state) => ({
    ...state,
    UserAccountInfo: {
      ...state.UserAccountInfo,
      invitationCode: '',
    },
  }),
  21: (state) => ({
    ...state,
    data: {
      ...state.data,
      entities: {
        ...state.data.entities,
        media_categories: {},
      },
    },
    UserAccountInfo: {
      ...state.UserAccountInfo,
      artistProfile: {
        pendingApprovalCampaigns: [],
        approvedCampaigns: [],
        declinedCampaigns: [],
        isArtist: false,
        artistLevel: 0,
        freeProductsAllowance: 0,
      },
    },
  }),
  22: (state) => ({
    ...state,
    data: {
      ...state.data,
      entities: {
        ...state.data.entities,
        tags: {},
      },
    },
  }),
  23: (state) => ({
    ...state,
    UserAccountInfo: {
      ...state.UserAccountInfo,
      lastVideoUpload: {
        id: '',
        postData: '',
      },
      artistProfile: {
        ...state.UserAccountInfo.artistProfile,
        selectedArtistTypes: [],
        selectedBrands: [],
        selectedOfferings: {},
        selectedTrainings: [],
        videoInReviewCampaigns: [],
        videoApprovedCampaigns: [],
        cashRequestedCampaigns: [],
        completedCampaigns: [],
      },
    },
  }),
  24: (state) => ({
    ...state,
    UserAccountInfo: {
      ...state.UserAccountInfo,
      termsAccepted: false,
    },
  }),
  25: (state) => ({
    ...state,
    UserAccountInfo: {
      ...state.UserAccountInfo,
      tempInstagramHandle: '',
    },
  }),
  26: (state) => ({
    ...state,
    UserAccountInfo: {
      ...state.UserAccountInfo,
      smsRetrieverStatus: '',
    },
  }),
  27: (state) => ({
    ...state,
    UserAccountInfo: {
      ...state.UserAccountInfo,
      currentOptinStage: '',
      lastOptinIndex: 0,
    },
  }),
  28: (state) => ({
    ...state,
    UserAccountInfo: {
      ...state.UserAccountInfo,
      artistProfile: {
        ...state.UserAccountInfo.artistProfile,
        allArtistTypes: [],
      },
      instagram_profile: {
        ...state.UserAccountInfo.instagram_profile,
        insta_verification_last_initiated_at: '',
      },
    },
  }),
  29: (state) => ({
    ...state,
    UserAccountInfo: {
      ...state.UserAccountInfo,
      uploadedVideosCount: 0,
      artistProfile: {
        ...state.UserAccountInfo.artistProfile,
        freelancer_profile: {},
      },
    },
  }),
  30: (state) => ({
    ...state,
    UserAccountInfo: {
      ...state.UserAccountInfo,
      artistProfile: {
        ...state.UserAccountInfo.artistProfile,
        selectedSalon: {},
        selectedStore: {},
        selectedStudentAcademy: {},
        selectedAcademy: {},
      },
      campaigns: {
        statusMessage: {},
      },
    },
  }),
  31: (state) => ({
    ...state,
    UserAccountInfo: {
      ...state.UserAccountInfo,
      artistProfile: {
        ...state.UserAccountInfo.artistProfile,
        claimedWelcomeGift: false,
      },
    },
  }),
  32: (state) => ({
    ...state,
    UserAccountInfo: {
      ...state.UserAccountInfo,
      deliveryRatings: {},
      shouldRenderOnboarding: false,
      currentPincode: '',
      artistProfile: {
        ...state.artistProfile,
        foxyInviteCode: '',
        hasBeenReferred: false,
      },
    },
  }),
  33: (state) => ({
    ...state,
    UserAccountInfo: {
      ...state.UserAccountInfo,
      guestProfile: {},
    },
  }),
  34: (state) => ({
    ...state,
    UserAccountInfo: {
      ...state.UserAccountInfo,
      facialAnalysis: {},
    },
  }),
  35: (state) => ({
    ...state,
    UserAccountInfo: {
      ...state.UserAccountInfo,
      currentPincode: '',
    },
  }),
  36: (state) => ({
    ...state,
    UserAccountInfo: {
      ...state.UserAccountInfo,
      new_user: false,
    },
  }),
  37: (state) => ({
    ...state,
    UserAccountInfo: {
      ...state.UserAccountInfo,
      deliveryRatings: {},
      artistTypes: {},
    },
  }),
  38: (state) => ({
    ...state,
    UserAccountInfo: {
      ...state.UserAccountInfo,
      new_user: '',
      remote_configs: {},
      scannedProducts: [],
      checkoutDetails: {
        ...state.UserAccountInfo.checkoutDetails,
        savedUpis: [],
      },
    },
  }),

  39: (state) => ({
    ...state,
    UserAccountInfo: {
      ...state.UserAccountInfo,
      loginModalState: '',
    },
  }),
  40: (state) => ({
    ...state,
    UserAccountInfo: {
      ...state.UserAccountInfo,
      featureCards: [],
    },
  }),

  41: (state) => ({
    ...state,
    UserAccountInfo: {
      ...state.UserAccountInfo,
      phoneNumberModalTitle: '',
      emojis: {},
      currentCity: '',
      lastUsedAddress: '',
      artistProfile: {
        ...state.UserAccountInfo.artistProfile,
        cancelledCampaigns: [],
      },
    },
  }),
  42: (state) => ({
    ...state,
    UserAccountInfo: {
      ...state.UserAccountInfo,
      checkoutDetails: {
        ...state.UserAccountInfo.checkoutDetails,
        savedUpis: [],
      },
    },
  }),
  43: (state) => ({
    ...state,
    UserAccountInfo: {
      ...state.UserAccountInfo,
      areAttributesReviewed: false,
    },
  }),
  44: (state) => ({
    ...state,
    UserAccountInfo: {
      ...state.UserAccountInfo,
      surprise_available: '',
      surprise_last_taken_at: '',
    },
  }),
  45: (state) => ({
    ...state,
    UserAccountInfo: {
      ...state.UserAccountInfo,
      has_selfie: '',
      myKitData: [],
    },
  }),
  46: (state) => ({
    ...state,
    UserAccountInfo: {
      ...state.UserAccountInfo,
      myKitData: [],
    },
  }),
  47: (state) => ({
    ...state,
    UserAccountInfo: {
      ...state.UserAccountInfo,
      app_last_open_at: new Date(),
    },
  }),
  48: (state) => ({
    ...state,
    facialAnalysis: {},
  }),
  49: (state) => ({
    ...state,
    cart: {
      ...state.cart,
      freeItems: [],
    },
  }),
  50: (state) => ({
    ...state,
    data: {
      ...state.data,
      entities: {
        ...state.data.entities,
        offers: {},
      },
    },
  }),
  51: (state) => ({
    ...state,
    UserAccountInfo: {
      ...state.UserAccountInfo,
      last_app_killed_status: '',
    },
  }),
  52: (state) => ({
    ...state,
    UserAccountInfo: {
      ...state.UserAccountInfo,
      // force_logged_out_flow: '',
      user_engagement: {},
      uac_score: '',
    },
  }),

  53: (state) => ({
    ...state,
    UserAccountInfo: {
      ...state.UserAccountInfo,
      lastScannedImageUrl: '',
    },
  }),

  54: (state) => ({
    ...state,
    UserAccountInfo: {
      ...state.UserAccountInfo,
      uac_events_status: {
        user_s1_triggered: false,
        user_s2_triggered: false,
        user_s3_triggered: false,
      },
    },
  }),

  55: (state) => ({
    ...state,

    UserAccountInfo: {
      ...state.UserAccountInfo,
      lastVideoUpload: {
        ...state.UserAccountInfo.lastVideoUpload,
        status: '',
        progress: 0,
      },
      appInstalledSource: undefined,
    },
    followFeatureCard: {
      dismissedOrFollowedCardIds: [],
    },
  }),

  56: (state) => ({
    ...state,
    appInstalled: [],
    UserAccountInfo: {
      ...state.UserAccountInfo,
      checkoutDetails: {
        ...state.UserAccountInfo.checkoutDetails,
        savedIntents: [],
        savedNb: [],
      },
    },
  }),

  57: (state) => ({
    ...state,
    UserAccountInfo: {
      ...state.UserAccountInfo,
      freeProductModalOpened: false,
      uac_events: {
        uac_device_score: 0,
        uac_selfie_score: 0,
        uac_gender_score: 0,
        uac_paymentapps_score: 0,
        uac_commerceapps_score: 0,
      },
    },
    cart: {
      ...state.cart,
      cartOffers: {},
    },
  }),

  58: (state) => ({
    ...state,
    UserAccountInfo: {
      ...state.UserAccountInfo,
      optForPlasticFreePackaging: false,
    },
  }),

  59: (state) => ({
    ...state,
    UserAccountInfo: {
      ...state.UserAccountInfo,
      isSpecialOfferApplied: false,
    },
  }),
  60: (state) => ({
    ...state,
    UserAccountInfo: {
      ...state.UserAccountInfo,
      followedArtistStoryRecommendations: [],
      followedStories: [],
    },
  }),

  61: (state) => ({
    ...state,
    UserAccountInfo: {
      ...state.UserAccountInfo,
      refreshStoryRails: false,
    },
    storyCard: {
      viewedStories: [],
      hideStoriesIntro: false,
    },
  }),

  62: (state) => ({
    ...state,
    UserAccountInfo: {
      ...state.UserAccountInfo,
      isSpecialOfferApplied: false,
    },
  }),

  63: (state) => ({
    ...state,
    UserAccountInfo: {
      ...state.UserAccountInfo,
      followedArtistStoryRecommendations: [],
      followedStories: [],
    },
    stories: {
      ...state.stories,
      viewedStories: [],
      hideStoriesIntro: false,
    },
  }),

  64: (state) => {
    const followedArtistStoryRecommendationsObject = {};

    if (
      Array.isArray(state.UserAccountInfo.followedArtistStoryRecommendations)
    ) {
      state.UserAccountInfo.followedArtistStoryRecommendations.forEach(
        (item) => {
          followedArtistStoryRecommendationsObject[
            `${item.item_type}${item.item_id}`
          ] = item;
        },
      );
    }

    return {
      ...state,
      UserAccountInfo: {
        ...state.UserAccountInfo,
        followedArtistStoryRecommendations:
          followedArtistStoryRecommendationsObject,
      },
    };
  },
  65: (state) => ({
    ...state,
    UserAccountInfo: {
      ...state.UserAccountInfo,
      paytmDetails: {},
      deviceId: '',
    },
  }),
  66: (state) => ({
    ...state,
    cart: {
      ...state.cart,
      pricingData: {},
    },
  }),

  67: (state) => ({
    ...state,
    bag: {
      ...state.bag,
      cartItems: [],
      cartPricing: {},
      cartPrompts: [],
      UtoP: {
        isUpgradeToPrepaid: false,
        prepaidUpgradeOptionExpiresAt: '',
        orderId: '',
      },
      isContactLess: true,
      isOfferShimmerVisible: false,
    },
    UserAccountInfo: {
      ...state.UserAccountInfo,
      checkoutDetails: {
        ...state.UserAccountInfo.checkoutDetails,
        savedCards: [],
      },
    },
  }),

  68: (state) => ({
    ...state,
    InterestsList: [],
    Posts: {},
    data: {
      entities: {
        ads: {},
        banners: {},
        brands: {},
        features: {},
        products: {},
        salons: {},
        short_banners: {},
        videos: {},
        images: {},
        media_categories: {},
        tags: {},
        lists: {},
        artists: {},
        offers: {},
      },
      result: '1',
    },
    queue: {
      followedArtist: [],
      unfollowedArtist: [],
      likedProduct: [],
      unlikedProduct: [],
      likedList: [],
      unlikedList: [],
      likedVideo: [],
      unlikedVideo: [],
      likedBrand: [],
      unlikedBrand: [],
      likedImage: [],
      unlikedImage: [],
      likedSalon: [],
      unlikedSalon: [],
    },
  }),
  69: (state) => ({
    ...state,
    UserAccountInfo: {
      ...state.UserAccountInfo,
      networkInfo: {},
    },
  }),

  70: (state) => ({
    ...state,
    bag: {
      ...state.bag,
      defaultAddress: {},
    },
  }),

  71: (state) => ({
    ...state,
    UserAccountInfo: {
      ...state.UserAccountInfo,
      lastNotificationModalDisplayTime: '',
      isSwipeNextCueDisplayed: false,
    },
  }),

  72: (state) => ({
    ...state,
    bag: {
      ...state.bag,
      isNewPaymentJourney: true,
    },
  }),

  73: (state) => ({
    ...state,
    stories: {
      ...state.stories,
      viewedStories: [],
      viewedTopics: {},
    },
  }),
  74: (state) => ({
    ...state,
    UserAccountInfo: {
      ...state.UserAccountInfo,
      showProfileShimmer: false,
    },
  }),
  75: (state) => ({
    ...state,
    bag: {
      ...state.bag,
      cod: false,
    },
  }),
  76: (state) => ({
    ...state,
    isUserLogoutOnce: false,
  }),
  77: (state) => ({
    ...state,
    UserAccountInfo: {
      ...state.UserAccountInfo,
      uac_events_status: {
        ...state.UserAccountInfo.uac_events_status,
        has_device_event_triggered: false,
      },
    },
  }),
  78: (state) => ({
    ...state,
    bag: {
      ...state.bag,
      saveToBagOffers: false,
    },
  }),
  79: (state) => ({
    ...state,
    todayDeals: {},
  }),
  80: (state) => ({
    ...state,
    bag: {
      ...state.bag,
      swipedLeftOrRightWishlist: [],
      saveToBagOffers: [],
    },
    todayDeals: {},
  }),
  81: (state) => ({
    ...state,
    UserAccountInfo: {
      ...state.UserAccountInfo,
      initial_app_opened_at: new Date().getTime(),
      lastVideoUpload: {
        ...state.UserAccountInfo.lastVideoUpload,
        numOfRetries: 0,
      },
    },
  }),

  82: (state) => ({
    ...state,
    bag: {
      ...state.bag,
      addedToCartProduct: {},
    },
  }),
  83: (state) => ({
    ...state,
    UserAccountInfo: {
      ...state.UserAccountInfo,
      appReviewSubmitted: false,
    },
  }),
  84: (state) => ({
    ...state,
    feedDetails: {
      ...state.feedDetails,
      shouldRenderReferralModal: false,
    },
    UserAccountInfo: {
      ...state.UserAccountInfo,
      activeGroupDeals: [],
    },
  }),
  85: (state) => ({
    ...state,
    userInteractions: {
      ...state.userInteractions,
      productViews: [],
      myBrowsing: [],
    },
  }),

  86: (state) => ({
    ...state,
    UserAccountInfo: {
      ...state.UserAccountInfo,
      appReviewSubmitted: false,
    },
  }),
  87: (state) => ({
    ...state,
    bag: {
      ...state.bag,
      bagModalOpenTimeStamp: null,
      appLaunchState: 'organic',
    },
  }),
  88: (state) => ({
    ...state,
    UserAccountInfo: {
      ...state.UserAccountInfo,
      hasPreviousAssociatedAccount: false,
    },
  }),
  89: (state) => ({
    ...state,
    UserAccountInfo: {
      ...state.UserAccountInfo,
      activeGroupDeals: [],
    },
    feedDetails: {
      ...state.feedDetails,
      referralGiftData: {},
    },
  }),
  90: (state) => ({
    ...state,
    userInteractions: {
      ...state.userInteractions,
      wishlistTooltip: [],
      lastLike: null,
      isWishlistCrossClicked: false,
    },
  }),
  91: (state) => ({
    ...state,
    contacts: {
      alreadyInvitedContacts: [],
    },
  }),
  92: (state) => ({
    ...state,
    UserAccountInfo: {
      ...state.UserAccountInfo,
      activeGroupDeals: [],
    },
    contactsData: { contacts: [], alreadyInvitedContacts: {} },
    feedDetails: {
      ...state.feedDetails,
      isReferralModalVisible: false,
    },
  }),
  93: (state) => ({
    ...state,
    UserAccountInfo: {
      ...state.UserAccountInfo,
      salonSearchHistory: [],
    },
  }),
  94: (state) => ({
    ...state,
    UserAccountInfo: {
      ...state.UserAccountInfo,
      likedSalons: [],
    },
  }),

  95: (state) => ({
    ...state,
    boostedOffers: {},
  }),
  96: (state) => ({
    ...state,
    UserAccountInfo: {
      ...state.UserAccountInfo,
      orderActionsCards: {},
    },
  }),
  97: (state) => ({
    ...state,
    bag: {
      ...state.bag,
      cartData: {},
    },
  }),
  98: (state) => ({
    ...state,
    feedDetails: {
      ...state.feedDetails,
      referralModalType: '',
    },
  }),
  99: (state) => ({
    ...state,
    retailStore: {},
  }),

  100: (state) => ({
    ...state,
    UserAccountInfo: {
      ...state.UserAccountInfo,
      optForCarryBag: false,
    },
  }),
  101: (state) => ({
    ...state,
    contactsData: {
      ...state.contactsData,
      activeContacts: [],
    },
  }),
  102: (state) => ({
    ...state,
    UserAccountInfo: {
      ...state.UserAccountInfo,
      showCongratsModalForEdge: true,
    },
  }),
  103: (state) => ({
    ...state,
    UserAccountInfo: {
      ...state.UserAccountInfo,
      showEdgePendingIndicator: false,
    },
  }),
  104: (state) => ({
    ...state,
    UserAccountInfo: {
      ...state.UserAccountInfo,
      continueAction: {
        ...state.UserAccountInfo.continueAction,
        continueSlug: '',
        continueImage: '',
        continueScreenType: '',
      },
    },
  }),
  105: (state) => ({
    ...state,
    UserAccountInfo: {
      ...state.UserAccountInfo,
      appFirstOpened: new Date(),
    },
  }),
  106: (state) => ({
    ...state,
    userInteractions: {
      ...state.userInteractions,
      wishlistTooltipResetAt: new Date(),
    },
  }),
  107: (state) => ({
    ...state,
    bag: {
      ...state.bag,
      deliveryFeeDiscountCouponCode: '',
    },
  }),
  108: (state) => ({
    ...state,
    UserAccountInfo: {
      ...state.UserAccountInfo,
      notificationData: [],
    },
  }),
  109: (state) => ({
    ...state,
    UserAccountInfo: {
      ...state.UserAccountInfo,
      isClickedLikeAndDislike: [],
    },
  }),
  110: (state) => ({
    ...state,
    bag: {
      ...state.bag,
      cartData: {},
    },
  }),
  111: (state) => ({
    ...state,
    routines: {
      viewedRoutines: [],
      checkList: {},
    },
  }),
  112: (state) => ({
    ...state,
    routines: {
      ...state.routines,
      liveStreams: [],
    },
  }),
  113: (state) => ({
    ...state,
    UserAccountInfo: {
      ...state.UserAccountInfo,
      recentlyViewedProductIds: [],
      showDynamicListTitle: false,
      shopifyAddresses: [],
      lastVisitedProductId: undefined,
    },
    zoomAnimationVisibility: {
      ...state.zoomAnimationVisibility,
      last_zoom_animation_visible_at: null,
      current_zoom_animation_visiblity_counter: 0,
    },
  }),
  114: (state) => ({
    ...state,
    UserAccountInfo: {
      ...state.UserAccountInfo,
      authorized: false,
    },
  }),
  115: (state) => ({
    ...state,
    UserAccountInfo: {
      ...state.UserAccountInfo,
      phoneNumberModalInfo: {},
    },
  }),
  116: (state) => ({
    ...state,
    permissionStatus: {},
  }),
  117: (state) => ({
    ...state,
    UserAccountInfo: {
      ...state.UserAccountInfo,
      userRegisteredOnCt: false,
    },
  }),
  118: (state) => ({
    ...state,
    userPersonalizationInfo: {},
  }),
  119: (state) => ({
    ...state,
    UserAccountInfo: {
      ...state.UserAccountInfo,
      locationInfo: {},
    },
  }),
  120: (state) => ({
    ...state,
    UserAccountInfo: {
      ...state.UserAccountInfo,
      locationUpdatedAt: '',
    },
  }),
  121: (state) => ({
    ...state,
    feedDetails: {
      id: state.feedDetails.id,
      refreshing: state.feedDetails.refreshing,
      error: state.feedDetails.error,
      referralGiftData: state.referralGiftData,
    },
  }),
  122: (state) => ({
    ...state,
    UserAccountInfo: {
      ...state.UserAccountInfo,
      activeRoutineId: false,
    },
  }),
  123: (state) => ({
    ...state,
    UserAccountInfo: {
      ...state.UserAccountInfo,
      activeRoutineSlug: '',
    },
  }),
  124: (state) => ({
    ...state,
    routines: {
      ...state.routines,
      scheduledNotificationIds: [],
    },
  }),
  125: (state) => ({
    ...state,
    UserAccountInfo: {
      ...state.UserAccountInfo,
      activeRoutineSlugs: [],
    },
  }),
  126: (state) => ({
    ...state,
    product: {},
  }),
};

// appReviewGiven
const persistConfig = {
  key: 'root',
  version: 125, // use integers only
  storage: FilesystemStorage,
  stateReconciler: autoMergeLevel2,
  migrate: createMigrate(migrations, { debug: true }),
  debug: true,
  // blacklist: [data],
  // keyPrefix: '',
  // timeout: 0,
};

const webPersistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(
  isWeb() ? webPersistConfig : persistConfig,
  rootReducer,
);

export default () => {
  if (isWeb()) {
    const sagaMiddleware = createSagaMiddleware();
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(sagaMiddleware)));
    sagaMiddleware.run(rootSaga);
    return store;
  }

  const sagaMiddleware = createSagaMiddleware();
  const middlewares = [sagaMiddleware];
  if (__DEV__) {
    middlewares.push(createDebugger());
  }
  const middleware = applyMiddleware(...middlewares);
  const store = createStore(
    persistedReducer,
    middleware,
  );
  sagaMiddleware.run(rootSaga);
  return store;
};
