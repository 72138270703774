import Config from '../libraries/ReactNativeConfig';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { getAllCountries } from 'react-native-country-picker-modal';
import { isBlank, isPresent } from './BooleanUtility';
import { getStoreRef } from '../store/StoreUtility';

const mandatoryAddressFields = Config.ADDRESS_PAGE_MANDATORY_FIELDS?.split(',') || [];

export default class AddressUtility {
  static restructureAddressArray = (addresses = []) => {
    const formattedArray = [];
    addresses.forEach((address) => {
      let updatedAddress = address;
      if (address?.attributes) {
        updatedAddress = { ...address, ...address.attributes };
        delete updatedAddress.attributes;
      }
      formattedArray.push(updatedAddress);
    });
    return formattedArray;
  };

  static restructureAddressObject = (address) => {
    let updatedAddress = address;
    if (address?.attributes) {
      updatedAddress = { ...address, ...address.attributes };
      delete updatedAddress.attributes;
    }
    return updatedAddress;
  };
}

export const getDefaultAddress = (addresses) => {
  if (isBlank(addresses)) return {};
  const addressArr = Object.values(addresses);
  let defaultAddress = {};
  addressArr.forEach((address) => {
    if (address.default === true) {
      defaultAddress = address;
    }
  });
  return defaultAddress;
};

export const validateAddress = (addressObj) => {
  const { contact_name: contactName, phone_number: phoneNumber, pincode, line1: addressLine1, line2: addressLine2,  email: emailId } = addressObj;
  const newAddress = {
    contactName,
    phoneNumber,
    pincode,
    addressLine1,
    addressLine2,
    emailId,
  };
  let isAddressValid = true;
  mandatoryAddressFields.forEach((field) => {
    if (field === 'birthDate' || field === 'placeName') return;
    if (isBlank(newAddress[field])) {
      isAddressValid = false;
    }
  });
  return isAddressValid;
};

export const getCurrentAddressPinCode = (address) => {
  let addressArray = [];
  if (isPresent(address)) {
    addressArray = Object.keys(address).map((key) => ({
      pincode: address[key].pincode,
      default: address[key].default,
    }));
  }

  let defaultAddress = addressArray.filter((item) => item.default);
  if (isBlank(defaultAddress)) {
    defaultAddress = addressArray.splice(0, 1);
  }

  const pincode = defaultAddress.length > 0 ? defaultAddress[0].pincode : '';
  return pincode;
};

export const getCurrentAddress = (address) => {
  let addressArray = [];
  if (isBlank(address)) {
    addressArray = Object.keys(address).map((key) => ({
      id: key,
      name: address[key].name,
      pincode: address[key].pincode,
      line1: address[key].line1,
      line2: address[key].line2,
      contact_name: address[key].contact_name,
      default: address[key].default,
    }));
  }

  let defaultAddress = addressArray.filter((item) => item.default);
  if (isBlank(defaultAddress)) {
    defaultAddress = addressArray.splice(0, 1);
  }

  const addressId = defaultAddress.length > 0 ? defaultAddress[0].id : '';
  return addressId;
};

export const getCurrentAddressEmail = (addresses) => {
  const defaultAddressId = getCurrentAddress(addresses);
  return addresses[`${defaultAddressId}`]?.email || '';
}

export const isPhoneNumberValid = (phoneNumber, callingCode, countryCode) => {
  const phoneUtil = PhoneNumberUtil.getInstance();
  try {
    const parsedPhoneNumber = phoneUtil.parse(
      `${callingCode}${phoneNumber}`,
      countryCode,
    );
    const isValidNumberForRegion = phoneUtil.isValidNumberForRegion(
      parsedPhoneNumber,
      countryCode,
    );
    return isValidNumberForRegion;
  } catch (e) {
    return false;
  }
};

export const findCountryCode = (callingCode, callback) => {
  if (isBlank(callingCode)) {
    callback(Config.COUNTRY_ISO_CODE);
    return;
  }
  getAllCountries().then((countries) => {
    const foundCountry = countries.find((country) => `${country.callingCode[0]}` === `${callingCode}`);
    callback(foundCountry.cca2);
  });
};

export const isAddressesEmpty = () => {
  const state = getStoreRef().getState();
  const { UserAccountInfo = {} } = state;
  const { addresses } = UserAccountInfo;
  return isBlank(addresses);
}
