import React from 'react';
import {
  StyleSheet,
  Text,
  View,
  Image,
  TouchableWithoutFeedback,
  Platform,
} from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { Grayscale } from '../../libraries/ReactNativeColorMatrixImageFilters';
import memoize from 'lodash/memoize';
import colors from '../../theme/Colors';
import images from '../../theme/Images';
import RoundedButton from '../layout/buttons/RoundedButton';
import CouponCodePill from '../cart/CouponCodePill';
import Utility from '../../utils/Utility';
import Config from '../../libraries/ReactNativeConfig';
import {
  AnalyticsManager,
  EventParameterKey,
  EventParameterValue,
  EventType,
} from '../../analytics';
import AnalyticsUtility from '../../analytics/AnalyticsUtility';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import ContextProvider from '../../utils/ContextProvider';
import { navigateToScreen } from '../../utils/NavigationUtility';
import { isDesktop, isWeb, isWebIOS } from '../../utils/BooleanUtility';

const CouponCode = (props) => {
  const { couponCodeName, inActive, promptData, isComboOffer = false } = props;
  let backgroundColor = inActive
    ? colors.ultraLightGrey
    : colors.ultraLightGreen;
  let borderColor = inActive ? colors.darkGrey : colors.darkGreen;
  if (!inActive && promptData.reward_type === 'gift') {
    backgroundColor = colors.prompt.ultraLightBlue;
    borderColor = colors.prompt.lightBlue;
  }
  if (
    promptData.status === 'active' &&
    promptData.reward_value_type === 'free_product'
  ) {
    borderColor = colors.lightPink;
    backgroundColor = colors.light_pink_opaque;
  }

  if (Utility.isPresent(Config.LINEAR_GRADIENT_SECONDARY) && !inActive) {
    borderColor = Config.LINEAR_GRADIENT_SECONDARY;
    backgroundColor = `${Config.LINEAR_GRADIENT_SECONDARY}1A`;
  }

  if (isComboOffer) {
    backgroundColor = '#F5AB5B80';
    borderColor = '#FFB467';
  }

  return (
    <CouponCodePill
      couponCode={couponCodeName}
      style={{
        paddingHorizontal: 4,
        paddingVertical: 2,
        backgroundColor,
        borderColor,
        minWidth: 70,
      }}
    />
  );
};

const ImageView = ({ promptData }) => {
  if (!isValidOnSku) {
    return (
      <Grayscale
        style={{
          position: 'absolute',
          top: 6,
          right: 6,
        }}
      >
        <Image
          source={images[`offer_${promptData.reward_type}`]}
          style={{
            height: 26,
            width: 26,
            overflow: 'visible',
          }}
        />
      </Grayscale>
    );
  }
  return (
    <Image
      source={images[`offer_${promptData.reward_type}`]}
      style={{
        height: 26,
        width: 26,
        position: 'absolute',
        top: 6,
        right: 6,
        overflow: 'visible',
      }}
    />
  );
};

export default function OfferPromptDescription(props) {
  const {
    heading,
    subHeading,
    promptData,
    promptData: {
      show_invalidity_modal = false,
      initiate_edge_activation = false,
      group_deal_prompt = false,
    } = {},
    navigateToOfferDetail,
    couponCodeName,
    inActive,
    buttonAction = () => {},
    easeIn,
    easeOut,
    setIsActive,
    isValidOnSku,
    navigateToInviteContacts,
    previousScreen = '',
    listData = {},
    listData: {
      id: listId = '',
      name: listName = '',
      slug: listSlug = '',
      content: listContent = '',
      type: listLayout = '',
    } = {},
    index = 0,
    listIndex,
    parentListsData,
    prompts = [],
    productData = {},
  } = props;
  const { membership_cohort = '', is_upgradable } = React.useContext(
    ContextProvider.userContext,
  );
  let borderColor = !inActive ? colors.prompt.borderGreen : colors.background;
  let background =
    promptData.reward_type === 'gift' ? '#4285f4B3' : '#01b460A6';

  if (!inActive && promptData.reward_type === 'gift') {
    borderColor = colors.prompt.borderBlue;
  }

  if (!isValidOnSku) {
    borderColor = colors.background;
  }

  let iconStyle = {
    height: 26,
    width: 26,
    position: 'absolute',
    top: 6,
    right: 6,
    overflow: 'visible',
    resizeMode: 'contain',
  };

  if (
    promptData.status === 'active' &&
    promptData.reward_value_type === 'free_product'
  ) {
    borderColor = colors.light_pink_opaque;
    background = colors.light_pink_opaque;
    iconStyle = {
      height: 26,
      width: 26,
      position: 'absolute',
      top: 6,
      right: 6,
      overflow: 'visible',
      resizeMode: 'contain',
      tintColor: colors.lightPink,
    };
  }

  if (Utility.isPresent(Config.LINEAR_GRADIENT_SECONDARY) && !inActive) {
    borderColor = `${Config.LINEAR_GRADIENT_SECONDARY}66`;
  }
  let icon = images[`offer_${promptData?.reward_type}`];

  if (promptData.is_edge_offer) {
    icon = { uri: images.edge.offer };
  }

  let descriptionBackgroundColor = colors.white;
  let isComboOffer = false;
  if (promptData.is_combo_offer) {
    isComboOffer = true;
    if (!inActive) {
      borderColor = '#FFB467';
      background = '#FFB467';
    }
    descriptionBackgroundColor = '#FEF7EF';
    icon = { uri: images.comboOffer };
  }

  if (isWeb() && promptData.app_only) {
    icon = { uri: isWebIOS() ? images.appStoreIcon : images.androidIcon };
  }

  let cta_text = promptData.group_deal_prompt ? 'Invite' : 'View Products';

  if (promptData.initiate_edge_activation) {
    cta_text = 'Activate';
  }

  const descriptionContainerStyle = memoize(
    () => [
      styles.descriptionContainer,
      {
        backgroundColor: descriptionBackgroundColor,
        borderColor,
      },
    ],
    () => [borderColor],
  )();

  const navigation = useNavigation();

  const showMembershipUpgradeModal = () => {
    const {
      invalid_modal_data: invalidModalData = {},
      invalid_modal_data: {
        data: { minimum_edge_priority: minimumEdgePriority = -1 },
      },
    } = promptData;
    if (Utility.isBlank(invalidModalData)) return;
    if (membership_cohort === 'member') {
      navigation.push('UpgradeEdgeModal', {
        previousScreen,
        previousScreenSlug: '',
        continueScreenType: 'product',
        imageUrl: '',
        invalidModalData,
      });
    } else {
      navigateToScreen({
        navigation,
        type: 'push',
        screen: 'LoyaltyPlans',
        params: {
          minimumEdgePriority,
        },
      });
    }
  };

  const fireActionEvents = () => {
    AnalyticsManager.logEvent(EventType.discoveryEvents.ITEM_ACTION, {
      [EventParameterKey.TYPE]: 'offer_card',
      [EventParameterKey.COUPON_CODE]: promptData.coupon_code,
      [EventParameterKey.SCREEN_NAME]: previousScreen,
    });
    if (Utility.isPresent(listData)) {
      AnalyticsUtility.fireItemClickEvent(
        previousScreen,
        promptData.coupon_code,
        EventParameterValue.ITEM_TYPE.OFFER_CARD,
        heading,
        index,
        listId,
        listLayout,
        listName,
        listIndex,
        '',
        '',
        listContent,
        '',
        '',
        listSlug,
        AnalyticsUtility.getParentsList(parentListsData),
      );
    }
  };

  const cardAction = () => {
    if (
      show_invalidity_modal &&
      (membership_cohort !== 'member' || is_upgradable)
    ) {
      showMembershipUpgradeModal();
      return;
    }
    if (initiate_edge_activation && membership_cohort !== 'member') {
      navigateToScreen({
        navigation,
        type: 'push',
        screen: SCREEN_CONSTANTS.loyalty_plans,
        params: {
          previousScreen,
        },
      });
      return;
    }
    if (group_deal_prompt) {
      navigateToInviteContacts();
    }
  };

  const isEligibleForEdgeFlow = () => {
    return (
      (show_invalidity_modal || initiate_edge_activation) &&
      (membership_cohort !== 'member' || is_upgradable)
    );
  };

  const onCardPress = () => {
    AnalyticsUtility.fireOfferBannerClick(promptData, prompts, productData);
    if (isWeb() && promptData.app_only) {
      onAppOnlyOfferClick();
      return;
    }
    fireActionEvents();
    if (isEligibleForEdgeFlow() || group_deal_prompt) {
      cardAction();
    } else {
      buttonAction(true);
      if (!promptData?.selected) {
        if (!inActive) {
          setIsActive(false);
          easeOut();
        } else {
          setIsActive(true);
          easeIn();
        }
      }
    }
  };

  const onAppOnlyOfferClick = () => {
    AnalyticsManager.logEvent(EventType.offer.OFFER_APPLICATION_FAILURE, {
      coupon_code: promptData.coupon_code,
      reason: 'App only offer',
    });
    if (isDesktop() && promptData.coupon_code === 'FOXYFIRST') {
      navigation.navigate('AppInstallPrompt', { from: location.pathname });
      return;
    }
    AnalyticsManager.logEvent(
      EventType.MISC_WEB_EVENTS.APP_ONLY_OFFER_MODAL_VIEW,
      { offer_id: promptData.offer_id },
    );
    navigation.navigate('ApplyOnAppOfferModal', {
      couponCode: promptData.coupon_code,
      message: promptData.message.split('|')[0],
      offerId: promptData.offer_id,
    });
  };

  const onButtonPress = () => {
    if (isWeb() && promptData.app_only) {
      onAppOnlyOfferClick();
      return;
    }
    fireActionEvents();
    if (isEligibleForEdgeFlow() || group_deal_prompt) {
      cardAction();
    } else {
      navigateToOfferDetail(promptData);
    }
  };

  return (
    <TouchableWithoutFeedback onPress={onCardPress} disabled={!isValidOnSku}>
      <View style={descriptionContainerStyle}>
        <View style={styles.headingContainer}>
          <Text style={styles.headingStyle} numberOfLines={1}>
            {heading}
          </Text>
        </View>

        <View style={styles.subHeadingContainer}>
          <Text style={styles.subHeadingStyle} numberOfLines={3}>
            {subHeading}
          </Text>
        </View>
        {isValidOnSku ? (
          <View style={styles.offerPromptCouponContainer}>
            <CouponCode
              couponCodeName={couponCodeName}
              inActive={inActive}
              promptData={promptData}
              isComboOffer={isComboOffer}
            />
            {!promptData.is_generic ? (
              <RoundedButton
                buttonText={isWeb() && promptData.app_only ? `${cta_text} on app` : cta_text}
                buttonTextColor={colors.white}
                buttonColor={colors.black}
                buttonAction={onButtonPress}
                disabledButtonColor={colors.black}
              />
            ) : (
              <Text
                style={{
                  fontSize: 12,
                  fontFamily: 'Roboto',
                  color: colors.foxyBlack,
                  fontWeight: '400',
                }}
              >
                Valid on all products
              </Text>
            )}
          </View>
        ) : (
          <Text
            style={{
              fontSize: 12,
              fontFamily: 'Roboto',
              color: colors.silver,
              fontWeight: '400',
              alignSelf: 'center',
              textAlign: 'center',
            }}
          >
            Offer not valid for this variant
          </Text>
        )}
        <View style={[styles.couponCodeLeftCutClipper, { borderColor }]} />
        <View style={[styles.couponCodeRightCutClipper, { borderColor }]} />
        <View style={styles.couponCodeLeftCut} />

        <View
          style={{
            height: 20,
            width: 20,
            position: 'absolute',
            backgroundColor: colors.background,
            bottom: 45,
            right: -24,
            zIndex: 1000,
          }}
        />
        {promptData.personalised_offer ? (
          <View
            style={{
              position: 'absolute',
              top: 6,
              right: 6,
              overflow: 'visible',

              paddingHorizontal: 6,
              paddingVertical: 2,
            }}
          >
            <Text
              allowFontScaling={false}
              style={{
                fontFamily: 'Roboto',
                fontSize: 12,
                color: background,
                fontWeight: 'bold',
              }}
            >
              JUST FOR YOU
            </Text>
          </View>
        ) : (
          <Image source={icon} style={iconStyle} />
        )}
      </View>
    </TouchableWithoutFeedback>
  );
}

const styles = StyleSheet.create({
  descriptionContainer: {
    backgroundColor: colors.white,
    paddingHorizontal: 16,
    paddingTop: 12,
    flexDirection: 'column',
    height: 128,
    alignItems: 'flex-start',
    borderRadius: 8,

    borderWidth: 4,
    borderColor: colors.prompt.borderGreen,
    zIndex: 10,
  },
  headingStyle: {
    fontSize: 14,
    fontFamily: 'Roboto-Medium',
    fontStyle: 'normal',
    color: colors.foxyBlack,
    ...Platform.select({
      web: { width: '90%' },
      default: {},
    }),
  },
  subHeadingStyle: {
    fontSize: 12,
    fontFamily: 'Roboto-Regular',
    color: colors.foxyBlack,
    marginTop: 6,
  },
  couponText: {
    fontSize: 14,
    fontFamily: 'Roboto-Regular',
    color: colors.foxyBlack,
  },
  offerPromptCouponContainer: {
    flexDirection: 'row',
    alignSelf: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  offerPromptDescriptionImage: {
    height: 26,
    width: 26,
    position: 'absolute',
    top: 6,
    right: 6,
    overflow: 'visible',
  },
  couponCodeRightCut: {
    height: 20,
    width: 20,
    position: 'absolute',
    backgroundColor: colors.background,
    bottom: 45,
    right: -24,
    zIndex: 1000,
  },
  couponCodeLeftCut: {
    height: 20,
    width: 20,
    position: 'absolute',
    backgroundColor: colors.background,
    bottom: 45,
    left: -24,
    zIndex: 1000,
  },
  couponCodeRightCutClipper: {
    height: 20,
    width: 20,
    position: 'absolute',
    backgroundColor: colors.background,
    bottom: 45,
    right: -10,
    borderRadius: 10,
    overflow: 'hidden',
    zIndex: 10,
    borderWidth: 4,
  },
  couponCodeLeftCutClipper: {
    height: 20,
    width: 20,
    position: 'absolute',
    backgroundColor: colors.background,
    bottom: 45,
    left: -10,
    borderRadius: 10,
    overflow: 'hidden',
    zIndex: 10,
    borderWidth: 4,
  },
  genericOfferText: {
    fontSize: 12,
    fontFamily: 'Roboto',
    color: colors.foxyBlack,
    fontWeight: '400',
  },
  headingContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    overflow: 'hidden',
    width: '100%',
  },
  subHeadingContainer: { height: 60 },
  couponCodePill: {
    paddingHorizontal: 6,
    paddingVertical: 2,
  },
});
