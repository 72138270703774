import React, { useState } from 'react';
import { View, Text, StyleSheet, Image } from 'react-native';
import DebouncedTouchableOpacity from '../shared/DebouncedTouchableOpacity';
import Utility from '../../utils/Utility';
import images from '../../theme/Images';
import InviteToClaimModal from '../modals/InviteToClaimModal';
import colors from '../../theme/Colors';
import { withEither } from '../../lib/Monads';
import { getMinifiedImage } from '../../utils/ImageUtility';

const InviteToClaim = (props) => {
  const {
    navigation,
    selfieImageUrl = '',
    itemData: productData = {},
    enableInviteCenter = false,
  } = props;

  const [isModalVisible, setModalVisible] = useState(false);

  const navigate = () => {
    setModalVisible(true);
  };

  const navigateToInviteCenter = () => {
    if (!navigation) return;
    navigation.navigate('InviteCenter');
  };

  let imageSource = images.for_you_placeholder;

  if (Utility.isPresent(selfieImageUrl)) {
    imageSource = { uri: getMinifiedImage(selfieImageUrl, styles.profileImg.width, styles.profileImg.height) };
  }

  const GoToInviteCentreButton = () => {
    return (
      <DebouncedTouchableOpacity
        {...props}
        style={styles.inviteContainer}
        onPress={navigateToInviteCenter}
      >
        <Text style={styles.inviteCentreButton}>Go to Invite Centre</Text>
      </DebouncedTouchableOpacity>
    );
  };

  const InviteToClaimButton = () => {
    return (
      <DebouncedTouchableOpacity
        {...props}
        style={styles.inviteContainer}
        onPress={navigate}
      >
        <Image
          source={images.inviteIcon}
          resizeMode={'contain'}
          style={styles.inviteUsersIcon}
        />
        <Text style={styles.text}>Invite to claim</Text>
      </DebouncedTouchableOpacity>
    );
  };

  const enableInviteCenterFn = () => {
    return enableInviteCenter;
  };

  const ConditionalInviteCenterOrClaimButton = withEither(
    enableInviteCenterFn,
    GoToInviteCentreButton,
  )(InviteToClaimButton);

  return (
    <View>
      <View style={styles.container}>
        <View style={styles.profileContainer}>
          <Image
            source={imageSource}
            resizeMode='cover'
            style={styles.profileImg}
          />
          <View style={styles.addUserContainer1}>
            <Image
              source={images.addUser}
              resizeMode={'contain'}
              style={styles.addUser}
            />
          </View>
          <View style={styles.addUserContainer2}>
            <Image
              source={images.addUser}
              resizeMode={'contain'}
              style={styles.addUser}
            />
          </View>
        </View>
        <ConditionalInviteCenterOrClaimButton />
      </View>
      <InviteToClaimModal
        isModalVisible={isModalVisible}
        setModalVisible={setModalVisible}
        productData={productData}
        referralModalType={'GROUP_DEAL_MODAL'}
      />
    </View>
  );
};

export default InviteToClaim;

const styles = StyleSheet.create({
  container: {
    paddingVertical: 10,
    paddingHorizontal: 12,
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
  },

  profileContainer: {
    flexDirection: 'row',
  },

  inviteContainer: {
    borderRadius: 32,
    marginLeft: 16,
    height: 32,
    backgroundColor: colors.black,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 2.5,
  },

  profileImg: {
    width: 32,
    height: 32,
    borderRadius: 16,
  },

  addUser: {
    width: 18,
    height: 18,
  },

  inviteUsersIcon: {
    width: 18,
    height: 18,
    color: colors.white,
  },

  addUserContainer1: {
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 16,
    height: 32,
    width: 32,
    padding: 8,
    marginLeft: 4,
    backgroundColor: colors.contactsInitialsColor[2].bgColor,
  },

  addUserContainer2: {
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 16,
    height: 32,
    width: 32,
    padding: 8,
    marginLeft: 4,
    backgroundColor: colors.contactsInitialsColor[7].bgColor,
  },

  text: {
    marginLeft: 10,
    color: colors.white,
    fontSize: 14,
  },
  inviteCentreButton: {
    color: colors.white,
    marginBottom: 2,
    fontFamily: 'Roboto-Regular',
  },
});
