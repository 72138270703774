export const FETCH_ROUTINE_DETAILS = 'FETCH_ROUTINE_DETAILS';
export const FETCH_ROUTINE_SECTION_LISTS = 'FETCH_ROUTINE_SECTION_LISTS';
export const ROUTINE_OPENED = 'ROUTINE_OPENED';
export const SAVE_CHECKLIST = 'SAVE_CHECKLIST';
export const ROUTINE_ACTIVITY_DONE = 'ROUTINE_ACTIVITY_DONE';
export const FETCH_POLLS_DATA = 'FETCH_POLLS_DATA';
export const SELECT_POLL_OPTION = 'SELECT_POLL_OPTION';
export const FETCH_ROUTINE_LIST_FOR_URL = 'FETCH_ROUTINE_LIST_FOR_URL';
export const LIVESTREAM_WATCHED_STATUS = 'LIVESTREAM_WATCHED_STATUS';
export const SAVE_SCHEDULED_NOTIFICATION_IDS = 'SAVE_SCHEDULED_NOTIFICATION_IDS';

export function getRoutineDetails(slug, callback) {
  return {
    type: FETCH_ROUTINE_DETAILS,
    slug,
    callback,
  };
}

export function fetchRoutineListForUrl(url, callback) {
  return {
    type: FETCH_ROUTINE_LIST_FOR_URL,
    url,
    callback,
  };
}

export function saveLiveStreamStatus(status, callback = () => {}) {
  return {
    type: LIVESTREAM_WATCHED_STATUS,
    status,
    callback,
  };
}

export function selectPollOption(pollId, questionId, optionIds, callback) {
  return {
    type: SELECT_POLL_OPTION,
    pollId,
    questionId,
    optionIds,
    callback,
  };
}

export function getRoutineSectionLists(slug, callback) {
  return {
    type: FETCH_ROUTINE_SECTION_LISTS,
    slug,
    callback,
  };
}

export function getPollsData(id, callback) {
  return {
    type: FETCH_POLLS_DATA,
    id,
    callback,
  };
}

export const routineOpened = (id) => {
  return {
    type: ROUTINE_OPENED,
    id,
  };
};

export const saveCheckList = (id, list) => {
  return {
    type: SAVE_CHECKLIST,
    id,
    list,
  };
};

export function routineActivityDone(routineSlug, routineActivityId, callback) {
  return {
    type: ROUTINE_ACTIVITY_DONE,
    routineSlug,
    routineActivityId,
    callback,
  };
}

export function saveScheduledNotificationIds(scheduledNotificationIds = []) {
  return {
    type: SAVE_SCHEDULED_NOTIFICATION_IDS,
    scheduledNotificationIds,
  };
}
