import React, { PureComponent } from 'react';
import { View, StyleSheet, Image, Text } from 'react-native';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Grayscale } from '../../libraries/ReactNativeColorMatrixImageFilters';
import Config from '../../libraries/ReactNativeConfig';
import images from '../../theme/Images';
import Utility from '../../utils/Utility';
import colors from '../../theme/Colors';
import { MoEReactInbox } from '../../libraries/ReactMoe';
import AppConfig from '../../config/AppConfig';
import withNavigation from '../../utils/WithNavigation';
import FastImageView from '../FastImageView';
import { getMinifiedImage } from '../../utils/ImageUtility';

const styles = StyleSheet.create({
  selectedBorder: {
    borderColor: '#EF8E8C',
    borderWidth: 2,
    borderRadius: 20,
    padding: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },

  imageStyle: {
    height: 34,
    width: 34,
    borderRadius: 17,
    overflow: 'hidden',
  },
  notificationIndication: {
    width: 4,
    height: 4,
    backgroundColor: colors.notificationCountPink,
    borderRadius: 2,
    marginTop: 2,
    alignSelf: 'center',
  },
  imageWithLabelStyle: {
    height: 24,
    width: 24,
  },
  removeBorderRadius: {
    borderRadius: 0,
  },
});

class PayoffTabIcon extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showNotificationIndicator: false,
    };
  }

  componentDidMount() {
    const { navigation } = this.props;
    this.unsubscribe = navigation.addListener('focus', () => {
      this.setNotificationCount();
    });
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  setNotificationCount = async () => {
    if (Utility.isWeb()) return;
    const count = (await MoEReactInbox.getUnClickedCount()) || 0;
    const { showNotificationIndicator } = this.state;
    const showIndication = count > 0;
    if (showIndication !== showNotificationIndicator) {
      this.setState({
        showNotificationIndicator: showIndication,
      });
    }
  };

  selectedImage = () => {
    const {
      PAY_OFF_ACTIVE_TAB_ICON_URL,
      DISABLE_ACTIVE_BORDER_FOR_PAY_OFF_TAB,
      PAY_OFF_TAB_LABEL,
    } = Config || {};
    const { selfieImage } = this.props;
    const { showNotificationIndicator } = this.state;
    let imageComponentSource = images.for_you_placeholder;
    if (Utility.isPresent(PAY_OFF_ACTIVE_TAB_ICON_URL)) {
      imageComponentSource = { uri: PAY_OFF_ACTIVE_TAB_ICON_URL };
    }
    if (Utility.isPresent(selfieImage)) {
      imageComponentSource = { uri: getMinifiedImage(selfieImage) };
    }
    const borderStyles = AppConfig.getBooleanValue(
      Config.DISABLE_ACTIVE_BORDER_FOR_PAY_OFF_TAB,
    )
      ? {}
      : styles.selectedBorder;

    const imageStyles = [
      styles.imageStyle,
      Utility.isPresent(PAY_OFF_TAB_LABEL) ? styles.imageWithLabelStyle : {},
      AppConfig.getBooleanValue(Config.DISABLE_ACTIVE_BORDER_FOR_PAY_OFF_TAB)
        ? styles.removeBorderRadius
        : {},
    ];
    return (
      <View>
        <View style={borderStyles}>
          <FastImageView source={imageComponentSource} style={imageStyles} />
        </View>
        {showNotificationIndicator && (
          <View style={styles.notificationIndication} />
        )}
      </View>
    );
  };

  unselectedImage = () => {
    const { selfieImage } = this.props;
    const {
      PAY_OFF_TAB_ICON_URL,
      PAY_OFF_TAB_LABEL,
      DISABLE_ACTIVE_BORDER_FOR_PAY_OFF_TAB,
    } = Config || {};
    const { showNotificationIndicator } = this.state;
    let imageComponentSource = images.for_you_placeholder;
    let Component = Grayscale;
    let extraData = {};
    if (Utility.isPresent(PAY_OFF_TAB_ICON_URL)) {
      imageComponentSource = { uri: PAY_OFF_TAB_ICON_URL };
      Component = View;
      extraData = {
        tintColor: colors.subtitle,
      };
    }
    if (
      Utility.isPresent(selfieImage) &&
      AppConfig.getBooleanValue(Config.SHOW_PAYOFF_TAB_SELFIE_IMAGE)
    ) {
      imageComponentSource = { uri: getMinifiedImage(selfieImage) };
    }
    const imageStyles = [
      styles.imageStyle,
      PAY_OFF_TAB_LABEL ? styles.imageWithLabelStyle : {},
      AppConfig.getBooleanValue(Config.DISABLE_ACTIVE_BORDER_FOR_PAY_OFF_TAB)
        ? styles.removeBorderRadius
        : {},
    ];
    return (
      <View>
        <Component>
          <FastImageView
            source={imageComponentSource}
            style={imageStyles}
            {...extraData}
          />
        </Component>
        {showNotificationIndicator && (
          <View style={styles.notificationIndication} />
        )}
      </View>
    );
  };

  setTabIcon = () => {
    const { focused } = this.props;
    if (focused) {
      return <this.selectedImage />;
    }

    return <this.unselectedImage />;
  };

  render() {
    return (
      <>
        <this.setTabIcon />
      </>
    );
  }
}

const mapStateToProps = function (state) {
  return {
    selfieImage: state.UserAccountInfo.profile.selfie_image_url,
  };
};

export default withNavigation(connect(mapStateToProps, null)(PayoffTabIcon));
