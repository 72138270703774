import { Platform } from 'react-native';
import ImagePicker from '../libraries/ReactNativeImageCropPicker';
import NavigationService from '../navigator/NavigationService';
import PermissionsUtility, {
  checkMediaLibraryPermission,
} from './PermissionsUtility';
import { AnalyticsManager, EventType } from '../analytics';
import { isIOS, isPresent } from './BooleanUtility';

export const foxyImagePicker = async ({
  media = [],
  headerTitle = '',
  storagePermissionStatus = '',
  onCancelAlert = () => {},
  mediaContainsOneVideo = false,
  imagePickerProps = {},
  allowMediaSingleSelection,
  successCallback,
  id = '',
  activity_id = '',
}) => {
  const isPermissionGranted = await checkMediaLibraryPermission();

  try {
    return await pickMediaFromGallery({
      media,
      headerTitle,
      mediaContainsOneVideo,
      imagePickerProps,
      allowMediaSingleSelection,
    });
  } catch ({ code }) {
    if (successCallback) {
      successCallback(false);
    }
    if (storagePermissionStatus === 'never_ask_again' && !isPermissionGranted) {
      PermissionsUtility.handleRequestExternalStoragePermission(
        pickMediaFromGallery,
        storagePermissionStatus,
        onCancelAlert,
        id,
        successCallback,
        activity_id,
      );
      return;
    }

    PermissionsUtility.handleStoragePermission(code);
  }
};

export const pickMediaFromGallery = async ({
  media = [],
  headerTitle = '',
  mediaContainsOneVideo = false,
  imagePickerProps = {},
  allowMediaSingleSelection,
}) => {
  const images = await ImagePicker.openPicker(imagePickerProps);
  if (allowMediaSingleSelection) {
    return { appendedImages: images };
  }
  const { filteredArray: newMedia, isMediaContainsOneVideo } = createMediaArray(
    images,
    mediaContainsOneVideo,
  );
  const newImages = [...media, ...newMedia];
  const appendedImages = newImages.slice(0, 7);
  if (isPresent(headerTitle)) {
    AnalyticsManager.logFirebaseEvent(EventType.review_upload.MEDIA_SELECT, {
      media_type: headerTitle,
    });
  }

  return { appendedImages, isMediaContainsOneVideo };
};

export const createMediaArray = (mediaArray, mediaContainsOneVideo) => {
  let isMediaContainsOneVideo = mediaContainsOneVideo;
  const filteredArray = [];
  mediaArray.forEach((media) => {
    if (filteredArray.length > 7) {
      return filteredArray;
    }
    if (media.mime === 'video/mp4') {
      if (!isMediaContainsOneVideo) {
        isMediaContainsOneVideo = true;
        filteredArray.push(media);
      }
    } else {
      filteredArray.push(media);
    }
  });
  return {
    filteredArray,
    isMediaContainsOneVideo,
  };
};

export const openGalleryOrShowPermissionModal = async ({
  openGallery = () => {},
  type,
  modalInfo = {},
}) => {
  try {
    const isMediaPermissionAllowed = await checkMediaLibraryPermission();
    if (isMediaPermissionAllowed || isIOS() || Platform.Version < 33) {
      openGallery();
    } else {
      NavigationService.navigate('StoragePermissionModal', {
        type,
        openGallery,
        modalInfo,
      });
    }
  } catch (error) {}
};
