import Config from '../libraries/ReactNativeConfig';
import AppConfig from '../config/AppConfig';
import { CURRENCY_SYMBOL } from '../config/Constants';
import { isBlank } from './BooleanUtility';

export const formatNumberInIndianStyleAndRemoveFractions = (number) =>
  (+number).toLocaleString('en-IN', {
    useGrouping: true,
    maximumFractionDigits: 0,
  });

export const getPriceText = (number) => {
  if (isBlank(number)) return '';
  const shouldFormatPrice = AppConfig.getBooleanValue(
    Config.FORMAT_PRICE_INDIAN_STYLE,
  );
  const formattedNumber = shouldFormatPrice
    ? formatNumberInIndianStyleAndRemoveFractions(number)
    : +number;
  return `${CURRENCY_SYMBOL} ${formattedNumber}`;
};
