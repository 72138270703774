import { takeLatest, put, select, takeEvery } from 'redux-saga/effects';
import { Platform, NativeModules } from 'react-native';
import {
  ADD_TO_CART,
  REMOVE_FROM_CART,
  BULK_ADD_TO_CART,
  GET_CART_ITEMS,
  updateCartItems,
  APPLY_CART_COUPON,
  APPLY_CART_COUPON_SUCCESS,
  UPDATE_CART_ITEMS,
  SAVE_CART_ITEM,
  GET_CART_DATA,
  CREATE_ORDER,
  GET_CART_OFFERS,
  REMOVE_OFFER,
  SAVE_UPI_DETAILS,
  SAVE_INTENT_DETAILS,
  GET_SAVED_UPI,
  SAVE_UPI_IN_REDUX,
  SAVE_NETBANKING_IN_REDUX,
  GET_NETBANKING_DETAILS,
  GET_OFFER_PROMPT,
  OPT_FOR_PLASTIC_FREE_PACKAGING,
  ADD_FREE_PRODUCT_TO_CART,
  GET_FREE_ITEM_FOR_OFFER,
  SAVE_FREE_OFFER_ITEM,
  GET_OFFER_REWARDS,
  VALIDATE_UPI,
  DELETE_CARD,
  CHECK_UPI_STATUS,
  GET_CART_PRICING,
  saveCartPricing,
  INCREMENT_DECREMENT_FROM_CART,
  CREATE_NEW_ORDER,
  SAVE_CART,
  SAVE_BAG_ITEMS,
  SAVE_BAG_PRICE,
  SAVE_BAG,
  COPY_CART_ITEMS,
  CREATE_NEW_ORDER_V2,
  renderOnboarding,
  applyCartCoupon,
  REMOVE_PREPAID_ONLY_OFFERS,
  renderOfferShimmers,
  addAddedToCartProduct,
  saveBoostedOffers,
  getBoostedOffers,
  saveCartData,
  OPT_FOR_CARRY_BAG,
  getUserTodayDeals,
  saveLocalNotificationData,
  FETCH_CART,
  SET_CART_NOTIFICATION,
  GET_PAYMENTS_DETAILS_FOR_PARTIAL_CART,
} from '../actions/ActionTypes';
import {
  CART_URL,
  URL,
  ORDER_URL,
  CART_PRICING_URL,
  CART_URL_NEW,
  REMOTE_CONFIG_KEYS,
  CART_ITEMS_URL,
  NOTIFICATION_IDENTIFIERS,
  NOTIFICATION_ACTIONS,
  NOTIFICATION_TYPE,
  COUPON_CODE_IMAGE,
  TRACKING_PARAMS,
  ADDRESS_DEEPLINK,
  CART_NOTIFICATION_STATES,
  CART_NOTIFICATION_DEEPLINKS,
} from '../config/Constants';
import { logoutUser, setPhoneNumberModalInfo } from '../actions/LoginActions';
import { getOffersPrompt } from '../actions/PromptsActions';
import Utility from '../utils/Utility';
import { find, findIndex, join } from 'lodash';
import {
  getApiHeaders,
  convertJsonFromResponse,
  getAddressTypeForId,
} from './GeneratorUtil';
import RemoteConfig from '../utils/RemoteConfig';
import { AnalyticsManager } from '../analytics';
import NavigationService from '../navigator/NavigationService';
import NotificationManager from '../utils/NotificationsManager';
import NotificationUtils from '../utils/NotificationUtils';
import images from '../theme/Images';
import { API_DOMAIN } from '../config/Constants';
import { getCurrentAddress } from '../utils/AddressUtility';
import { isNative, isWeb } from '../utils/BooleanUtility';

const platform = isNative() ? 'app' : 'web';
import { checkLastOrderStatus } from '../utils/PersonalizationUtility';
import { getMinifiedImage } from '../utils/ImageUtility';
import { isPresent } from '../utils/BooleanUtility';

const checkIfFlashDealPresent = (item, todayDeals) => {
  const { skus = [] } = todayDeals;
  return (
    (skus[`${item?.sku_id}`] &&
      item.participating_discount_offer_type === 'flash_deal') ||
    item.participating_discount_offer_type === 'club_deal'
  );
};

function* setCartReminderNotificationAlarm(action, cart) {
  const remoteConfigData = JSON.parse(
    RemoteConfig.getValue(
      REMOTE_CONFIG_KEYS.customised_cart_reminder_notification_data,
    ),
  );

  let cartData = cart;
  if (Utility.isBlank(cart)) {
    cartData = yield select((state) => state.bag.cartData);
  }

  const notificationData = NotificationUtils.getCartReminderNotificationData(
    remoteConfigData,
    cartData,
  );

  const addresses = yield select((state) => state.UserAccountInfo.addresses);
  const cartItems = yield select((state) => state.bag.cartItems);

  if (Utility.isBlank(cartItems) || cartItems[0].quantity === 0) {
    NotificationManager.cancelCartNotification();
    return;
  }

  const todayDeals = yield select((state) => state.todayDeals);
  const ends_at = todayDeals.ends_at;

  const isFlashDealPresent = !!find(cartItems, (item) =>
    checkIfFlashDealPresent(item, todayDeals),
  );

  const { images: { added_to_bag = '', added_shipping = '' } = {} } =
    remoteConfigData;

  const imageUrl = !notificationData?.extraData?.hasAddress
    ? added_to_bag
    : added_shipping;

  let cartUrl = Utility.appendUtmParamsWithUrl(
    CART_ITEMS_URL,
    TRACKING_PARAMS.NOTIFICATION.CART,
  );

  if (Utility.isBlank(addresses)) {
    cartUrl = Utility.appendUtmParamsWithUrl(
      ADDRESS_DEEPLINK,
      TRACKING_PARAMS.NOTIFICATION.CART,
    );
  }

  const { cartObject: { item_total, discount } = {} } = notificationData;

  let discountPercentage = 0;
  if (Utility.isPresent(item_total) && Utility.isPresent(discount)) {
    if (item_total > 0 && discount > 0) {
      discountPercentage = (discount / item_total) * 100;
    }
  }

  let { nextState } = action;

  if (
    nextState === CART_NOTIFICATION_STATES.addAddress &&
    Utility.isPresent(addresses)
  ) {
    nextState = CART_NOTIFICATION_STATES.makePayment;
  }

  const notificationTexts =
    NotificationUtils.getRemoteConfigNotificationText(nextState);
  const payload = {
    ...notificationData,
    image: imageUrl,
    deepLink: CART_NOTIFICATION_DEEPLINKS[nextState],
    primary_cta: NOTIFICATION_ACTIONS.CONTINUE.cta,
    primary_destination: cartUrl,
    secondary_cta: NOTIFICATION_ACTIONS.REMIND_IN_24_HOURS.cta,
    secondary_destination: NOTIFICATION_ACTIONS.REMIND_IN_24_HOURS.action,
    type: NOTIFICATION_TYPE.CART_REMINDER,
    isLocal: true,
    receivedTime: new Date(),
    isClicked: false,
    state: nextState,
    isFlashDealPresent,
    ends_at: ends_at,
    discountPercentage: discountPercentage,
    notificationTitle: notificationTexts.title,
    notificationDescription: notificationTexts.description,
    notificationFallbackTitle: notificationTexts.fallbackTitle,
    notificationFallbackDescription: notificationTexts.fallbackDescription,
    enableTimerInDescription: notificationTexts.enableTimerInDescription,
    notificationCta: CART_NOTIFICATION_DEEPLINKS[nextState],
    isLiveNotification: true,
  };

  NotificationManager.createCartNotification(payload);

  yield put(saveLocalNotificationData(payload));
}

function* saveCartDataInRedux(data, shouldSendNotification = false) {
  const {
    items: cartItems = [],
    address = {},
    pricing: {
      items_total: itemTotal = 0,
      discount = 0,
      total = 0,
      plastic_free_packaging_charges: plasticFreePackagingCharge = '',
      delivery_fee: deliveryFee = '',
    } = {},
  } = data;

  const addresses = yield select((state) => state.UserAccountInfo.addresses);
  let giftsWithPurchaseCount = 0;
  let quantity = 0;
  let productImage = '';
  cartItems?.forEach((item) => {
    giftsWithPurchaseCount += item.is_free ? 1 : 0;
    quantity += item.quantity ? item.quantity : 0;
  });
  const name = yield select((state) => state.UserAccountInfo.profile.name);
  productImage = cartItems[cartItems.length - 1]?.product?.image_url;
  productImage = getMinifiedImage(productImage, 100, 100);

  const cartData = {
    name,
    cart_count:
      quantity - giftsWithPurchaseCount > 0
        ? quantity - giftsWithPurchaseCount
        : 0,
    cart_value: itemTotal + deliveryFee + plasticFreePackagingCharge,
    cart_actual_total: total,
    gwp_count: giftsWithPurchaseCount,
    discount,
    has_address: Utility.isPresent(address) || Utility.isPresent(addresses),
    product_image: Utility.isPresent(productImage) ? productImage : '',
    total_cart_count: cartItems.length,
    item_total: itemTotal,
  };

  yield put(saveCartData(cartData));
  if (shouldSendNotification) {
    // Its handled in Android by using cart quantity
    if (Utility.isIOS() && quantity < 1) {
      NotificationManager.cancelNotification(
        NOTIFICATION_IDENTIFIERS.CART_REMINDER,
      );
    } else {
      yield* setCartReminderNotificationAlarm(
        { nextState: CART_NOTIFICATION_STATES.addAddress },
        cartData,
      );
    }
  }
}

function* getCartPricing() {
  const authToken = yield select((state) => state.UserAccountInfo.authToken);
  const addresses = yield select((state) => state.UserAccountInfo.addresses);
  const addressId = getCurrentAddress(addresses);
  let url = `${CART_PRICING_URL}?address_id=${addressId}&platform=${platform}&os=${Platform.OS}`;
  try {
    const response = yield fetch(url, {
      method: 'GET',
      headers: yield getApiHeaders(),
    });
    const statusCode = response?.status;

    if (statusCode >= 200 && statusCode < 300) {
      const json = yield convertJsonFromResponse(response);
      if (Utility.isPresent(json)) {
        yield put(saveCartPricing(json));
      }
    } else {
    }
  } catch (error) {
    console.tron.log(
      ` error in cart pricing ${JSON.stringify(
        error,
      )}, auth token : ${authToken}`,
    );
  }
}

function* addToCartAsyc(action) {
  const {
    data: { sku_id, quantity, campaign_id },
    callback,
  } = action;
  const url = CART_URL;
  const authToken = yield select((state) => state.UserAccountInfo.authToken);
  try {
    let postData = { auth_token: authToken, sku_id, quantity };
    if (campaign_id) {
      postData['campaign_id'] = campaign_id;
    }
    const response = yield fetch(url, {
      method: 'POST',
      headers: yield getApiHeaders(),
      body: JSON.stringify(postData),
    });
    const statusCode = response.status;
    const json = yield convertJsonFromResponse(response);
    if (response.status >= 200 && response.status < 300) {
      if (Utility.isBlank(json)) {
        return;
      }
      yield put({ type: SAVE_CART_ITEM, data: json });
      callback(json);
    } else if (statusCode === 401) {
      yield put(logoutUser());
    } else {
      if (Utility.isPresent(json) && Utility.isPresent(json.errors)) {
        callback(json);
      }
      // yield put(addToCartFailed(action.data));
    }
  } catch (error) {
    console.tron.log(
      ` error ${JSON.stringify(error)}, auth token : ${authToken}`,
    );
  }
}

function* removeFromCartAsyc(action) {
  const {
    data: { sku_id },
    callback,
  } = action;
  const url = CART_URL;
  const authToken = yield select((state) => state.UserAccountInfo.authToken);
  console.tron.log(action.data);
  try {
    const response = yield fetch(url, {
      method: 'DELETE',
      headers: yield getApiHeaders(),
      body: JSON.stringify({
        auth_token: authToken,
        sku_id,
        is_free,
      }),
    });
    const statusCode = response.status;
    if (response.status >= 200 && response.status < 300) {
      callback(true);
    } else if (statusCode === 401) {
      yield put(logoutUser());
    } else {
      console.tron.log('error in removing', response);
      // yield put(addToCartFailed(action.data));
    }
  } catch (error) {
    console.tron.log(
      ` error ${JSON.stringify(error)}, auth token : ${authToken}`,
    );
  }
}

function* incrementDecrementCart(action) {
  const {
    data,
    data: {
      sku_id,
      quantity,
      campaign_id,
      is_free = false,
      source = 'cart',
      flashDealData = {},
      offer_id,
      usn,
      force_apply = '',
      listId = '',
      force_apply_coupon,
      isForceIncrement,
    },
    callback = () => {},
  } = action;

  let myBrowsing = yield select((state) => state.userInteractions.myBrowsing);
  if (Utility.isBlank(myBrowsing)) {
    myBrowsing = [];
  }

  let currentIndex = findIndex(myBrowsing, (myBrowsingItem) => {
    return (
      myBrowsingItem.id === flashDealData?.id &&
      myBrowsingItem?.type?.toLowerCase() === flashDealData?.type?.toLowerCase()
    );
  });

  if (currentIndex === -1) {
    currentIndex = myBrowsing.length;
  }

  let url = `${CART_URL_NEW}?source=${source}&platform=${platform}&os=${Platform.OS}&item_index=${currentIndex}&wallet_eligibility=non_eligible`;
  const isStoreAddressSelected = yield select(
    (state) => state.bag.showRetailStoreAddress,
  );

  const store_address = yield select(
    (state) => state?.RetailStore?.foxy_store_address,
  );

  if (isStoreAddressSelected && Utility.isPresent(store_address)) {
    url = `${url}&store_id=${store_address.foxy_store_id}&wallet_eligibility=non_eligible`;
  }

  if (isForceIncrement) {
    url = `${url}&is_force_increment=${isForceIncrement}&wallet_eligibility=non_eligible`;
  }

  const authToken = yield select((state) => state.UserAccountInfo.authToken);

  try {
    let postData = {
      auth_token: authToken,
      quantity,
      is_free,
      coupon_code: offer_id,
    };

    if (Utility.isPresent(sku_id)) {
      postData['sku_id'] = sku_id;
    }

    if (Utility.isPresent(listId)) {
      postData['possible_offer_list_id'] = listId;
    }

    if (Utility.isPresent(usn)) {
      postData['usn'] = usn;
    }

    if (campaign_id) {
      postData['campaign_id'] = campaign_id;
    }

    if (Utility.isPresent(force_apply)) {
      postData['force_apply'] = force_apply;
    }

    if (Utility.isPresent(force_apply_coupon)) {
      // postData['force_apply_coupon'] = force_apply_coupon;
      postData['coupon_code'] = force_apply_coupon;
    }

    const response = yield fetch(url, {
      method: 'POST',
      headers: yield getApiHeaders(),
      body: JSON.stringify({
        cart_item: postData,
        force: Utility.isPresent(force_apply_coupon) && '1',
      }),
    });

    const statusCode = response.status;
    const json = yield convertJsonFromResponse(response);
    if (Utility.isBlank(json)) {
      yield put(renderOfferShimmers(false));
      return;
    }
    if (response.status >= 200 && response.status < 300) {
      callback(json);
      yield saveCartDataInRedux(json, true);
      if (source === 'cart') {
        yield put({ type: SAVE_BAG, data: json });
      }
    } else if (statusCode === 401) {
      yield put(logoutUser());
    } else if (statusCode === 422) {
      if (Utility.isPresent(json)) {
        if (Utility.isPresent(json.modal_data)) {
          NavigationService.navigate('OfferCapModal', {
            ...json.modal_data,
            data,
          });
          callback(json);
        } else {
          callback(json);
        }
      }
      // yield put(addToCartFailed(action.data));
    } else {
      if (Utility.isPresent(json) && Utility.isPresent(json.errors)) {
        callback(json);
      } else {
        callback({});
      }
    }
    yield put(addAddedToCartProduct({}));
  } catch (error) {
    console.tron.log(
      ` error ${JSON.stringify(error)}, auth token : ${authToken}`,
    );
    yield put(renderOfferShimmers(false));
  }
}

function* bulkAddToCartAsync(action) {
  const {
    data: { cartItems },
  } = action;
  const { callback } = action;
  const url = URL.BULK_CART;
  const authToken = yield select((state) => state.UserAccountInfo.authToken);
  try {
    const response = yield fetch(url, {
      method: 'POST',
      headers: yield getApiHeaders(),
      body: JSON.stringify({
        auth_token: authToken,
        cart_items: cartItems,
      }),
    });
    const statusCode = response.status;
    if (statusCode === 401) {
      callback(false);
      yield put(logoutUser());
    } else if (statusCode >= 200 && statusCode < 300) {
      // success
      callback(true);
      console.tron.log('add to cart status code', statusCode);
    } else {
      callback(false);
      console.tron.log('error in bulk add to cart', statusCode);
    }
  } catch (error) {
    console.tron.log(
      ` error ${JSON.stringify(error)}, auth token : ${authToken}`,
    );
  }
}

function* getCartItemsAsync(action) {
  const { callback = () => {}, showCartNotification = false } = action;
  const store_address = yield select(
    (state) => state?.RetailStore?.foxy_store_address,
  );

  const isStoreAddressSelected = yield select(
    (state) => state.bag.showRetailStoreAddress,
  );

  let url = `${CART_URL}?source=cart&platform=${platform}&os=${Platform.OS}&wallet_eligibility=non_eligible`;

  if (Utility.isPresent(store_address) && isStoreAddressSelected) {
    url = `${CART_URL}?source=cart&platform=${platform}&os=${Platform.OS}&store_id=${store_address.foxy_store_id}&wallet_eligibility=non_eligible`;
  }

  const authToken = yield select((state) => state.UserAccountInfo.authToken);
  try {
    const response = yield fetch(url, {
      method: 'GET',
      headers: yield getApiHeaders(),
    });
    const statusCode = response.status;
    if (statusCode === 401) {
      callback(false, {});
      yield put(logoutUser());
    } else if (statusCode >= 200 && statusCode < 300) {
      const json = yield convertJsonFromResponse(response);
      if (Utility.isBlank(json)) {
        callback(false);
        return;
      }
      callback(true, json);
      yield put({ type: SAVE_BAG, data: json });
      yield saveCartDataInRedux(json, showCartNotification);
    } else {
      callback(false);
    }
  } catch (error) {
    console.tron.log(
      ` error ${JSON.stringify(error)}, auth token : ${authToken}`,
    );
  }
}

function* copyCartItemsAsync(action) {
  const { callback = () => {}, payload = {} } = action;
  const url = `${CART_URL}/checkout_reminder`;

  const { a, b } = payload;

  console.tron.log(payload, JSON.stringify(payload), 'AA!');

  const authToken = yield select((state) => state.UserAccountInfo.authToken);
  try {
    const response = yield fetch(url, {
      method: 'POST',
      headers: yield getApiHeaders(),
      body: JSON.stringify(payload),
    });

    const statusCode = response.status;
    if (statusCode === 401) {
      callback(false, {});
      yield put(logoutUser());
    } else if (statusCode >= 200 && statusCode < 300) {
      const json = yield convertJsonFromResponse(response);
      if (Utility.isBlank(json)) {
        callback(false);
        return;
      }
      callback(true);
      yield put({ type: SAVE_BAG, data: json });
      saveCartDataInRedux(json);
    } else {
      callback(false);
    }
  } catch (error) {
    console.tron.log(
      ` error ${JSON.stringify(error)}, auth token : ${authToken}`,
    );
  }
}

function* applyCartCouponAsync(action) {
  const {
    couponCode,
    callback = () => {},
    appliedFromDeeplink = false,
    force = false,
    source = 'cart',
    skuId,
  } = action;
  let url = `${URL.CART_COUPON}?source=${source}&wallet_eligibility=non_eligible`;

  if (appliedFromDeeplink) {
    url = `${url}&apply_when_eligible=true&wallet_eligibility=non_eligible`;
  }

  if (force) {
    url = `${url}&force=1&wallet_eligibility=non_eligible`;
  }

  const isStoreAddressSelected = yield select(
    (state) => state.bag.showRetailStoreAddress,
  );

  const store_address = yield select(
    (state) => state?.RetailStore?.foxy_store_address,
  );

  if (isStoreAddressSelected && Utility.isPresent(store_address)) {
    url = `${url}&store_id=${store_address.foxy_store_id}&wallet_eligibility=non_eligible`;
  }

  const authToken = yield select((state) => state.UserAccountInfo.authToken);
  try {
    const response = yield fetch(url, {
      method: 'POST',
      headers: yield getApiHeaders(),
      body: JSON.stringify({
        auth_token: authToken,
        coupon_code: couponCode,
        sku_id: skuId,
      }),
    });
    const statusCode = response.status;
    const json = yield convertJsonFromResponse(response);
    if (statusCode === 401) {
      yield put(logoutUser());
    } else if (statusCode >= 200 && statusCode < 300) {
      // success
      if (Utility.isBlank(json)) {
        callback(false, {});
        return;
      }
      yield put({ type: SAVE_BAG, data: json });
      saveCartDataInRedux(json);
      console.tron.log('apply coupon success', statusCode);
      callback(true, json);
    } else {
      if (!!json?.login_required) {
        AnalyticsManager.logEvent('login_only_offer_applied', {
          coupon_code: couponCode,
        });
        Utility.furtherAction.isActionAsync = true;
        Utility.furtherAction.action = applyCartCoupon;
        Utility.furtherAction.params = [couponCode, callback, false, false];
        yield put(
          setPhoneNumberModalInfo({
            title: 'Login',
            subTitle: `to apply the offer "${couponCode}"`,
            image: COUPON_CODE_IMAGE.IMAGE,
          }),
        );
        NavigationService.renderOnboarding();
        callback(false, {});
      } else {
        if (Utility.isPresent(json?.modal_data)) {
          NavigationService.navigate('OfferCapModal', {
            ...json?.modal_data,
            openedFromOffer: true,
          });
          callback(false, {});
        } else {
          callback(false, json);
        }
      }
      console.tron.log('error in apply offer', statusCode);
    }
  } catch (error) {
    console.tron.log(
      ` error ${JSON.stringify(error)}, auth token : ${authToken}`,
    );
    callback(false, {});
  }
}

function* addFreeProductToCartAsync(action) {
  const { skuId, offerId, callback } = action;
  const url = `${URL.ADD_FREE_ITEM}?offer_id=${offerId}&sku_id=${skuId}`;

  const authToken = yield select((state) => state.UserAccountInfo.authToken);
  try {
    const response = yield fetch(url, {
      method: 'POST',
      headers: yield getApiHeaders(),
      body: JSON.stringify({
        auth_token: authToken,
      }),
    });
    const statusCode = response.status;
    const json = yield convertJsonFromResponse(response);
    if (statusCode === 401) {
      yield put(logoutUser());
    } else if (statusCode >= 200 && statusCode < 300) {
      if (Utility.isBlank(json)) {
        callback(false, {});
        return;
      }
      callback(true, json);
    } else {
      callback(false, json);
    }
  } catch (error) {
    callback(false, {});
  }
}

function* getCartDataAsync(action) {
  const { callback } = action;
  const url = URL.CART_PARAMS;
  const authToken = yield select((state) => state.UserAccountInfo.authToken);
  try {
    const response = yield fetch(url, {
      method: 'GET',
      headers: yield getApiHeaders(),
    });
    const statusCode = response.status;

    if (statusCode === 401) {
      callback(false, {});
      yield put(logoutUser());
    } else if (statusCode >= 200 && statusCode < 300) {
      const json = yield convertJsonFromResponse(response);
      if (Utility.isBlank(json)) {
        callback(false, {});
        return;
      }
      const payuParams = json.payu_params;
      callback(true, payuParams);
    } else {
      callback(false, {});
    }
  } catch (error) {
    console.tron.log(
      ` error ${JSON.stringify(error)}, auth token : ${authToken}`,
    );
    callback(false, {});
  }
}

function* createOrderAsync(action) {
  const {
    addressId,
    callback,
    isContactLessDelivery,
    paymentMethod = '',
    orderId,
    params,
    savePaymentMethod,
    pg = '',
  } = action;
  let url = `${ORDER_URL}?platform=${platform}`;

  const upgradableToPrepaid = yield select(
    (state) => state.bag.UtoP.isUpgradeToPrepaid,
  );

  console.tron.log(orderId, upgradableToPrepaid, paymentMethod);

  if (
    upgradableToPrepaid &&
    paymentMethod !== 'cod' &&
    !Utility.isBlank(orderId)
  ) {
    url = `${ORDER_URL}/${orderId}/upgrade_to_prepaid`;
  }

  const authToken = yield select((state) => state.UserAccountInfo.authToken);

  const addressType = yield getAddressTypeForId(addressId);

  const is_digital_cart = yield select(
    (state) => state.bag?.cartPricing?.digital_cart,
  );

  const addressID = RemoteConfig.getValue(
    REMOTE_CONFIG_KEYS.edge_address_id_for_cart,
  );

  try {
    const body = {
      auth_token: authToken,
      order: {
        address_id: is_digital_cart ? addressID : addressId,
        address_type: addressType,
      },
      payment_method: paymentMethod,
      contact_less: isContactLessDelivery,
      payment_method_value: params,
      pg,
    };
    if (savePaymentMethod) {
      body.save_payment_method = true;
    }
    const response = yield fetch(url, {
      method: 'POST',
      headers: yield getApiHeaders(),
      body: JSON.stringify(body),
    });
    const statusCode = response.status;
    const json = yield convertJsonFromResponse(response);
    if (statusCode === 401) {
      yield put(logoutUser());
      callback(false, {});
    } else if (statusCode >= 200 && statusCode < 300) {
      if (Utility.isBlank(json)) {
        callback(false, {});
        return;
      }
      const { status, order_id } = json;
      if (status === 'in_progress') {
        const payuParams = json.payu_params;
        callback(true, payuParams, {}, status);
      } else if (status === 'success' && Utility.isPresent(order_id)) {
        callback(true, {}, json, status);
      } else if (status === 'success') {
        // navigate to order success page
        callback(true, {}, json?.data, status);
        yield put(getUserTodayDeals());
        yield put(getBoostedOffers());
      }
    } else {
      callback(false, json, {}, '');
    }
  } catch (error) {
    console.tron.log('error', error);
    callback(false, {}, {}, '');
  }
}

function* createNewOrderV2Async(action) {
  const {
    payload: {
      paymentMethod = 'cod',
      addressId = '',
      paymentMethodPayload = {},
      isSavedPaymentMethod = false,
      paymentGateway = '',
      params = {},
      skuIds = [],
      retry_payment_order_id = ''
    } = {},
    callback = () => {},
  } = action;
  let url = `${ORDER_URL}?platform=${platform}`;
  if (skuIds.length !== 0) {
    console.tron.log(skuIds, 'createNewOrderV2Async');
    url = `${url}&${join(
      skuIds?.map((skuId) => `sku_ids[]=${skuId}`),
      '&',
    )}`;
  }
  const UtoP = yield select((state) => state.bag.UtoP);

  const { isUpgradeToPrepaid, orderId } = UtoP;

  if (
    isUpgradeToPrepaid &&
    paymentMethod !== 'cod' &&
    !Utility.isBlank(orderId)
  ) {
    url = `${ORDER_URL}/${orderId}/upgrade_to_prepaid`;
  }

  if (Utility.isPresent(params) && params.retry_payment) {
    url = `${ORDER_URL}/${params.order_id}/retry_payment`;
  }

  if (Utility.isPresent(retry_payment_order_id)) {
      url = `${ORDER_URL}/${retry_payment_order_id}/retry_payment`;
  }

  const { isContactLess: isContactLessDelivery, isNewPaymentJourney } =
    yield select((state) => state.bag);

  const authToken = yield select((state) => state.UserAccountInfo.authToken);

  const showRetailStoreAddress = yield select(
    (state) => state.bag.showRetailStoreAddress,
  );
  console.tron.log(showRetailStoreAddress, 'showRetailStoreAddress');
  const store_id = yield select(
    (state) => state.RetailStore?.foxy_store_address?.foxy_store_id,
  );

  console.tron.log('addressID', 'is_digital_cart');

  const is_digital_cart = yield select(
    (state) => state.bag?.cartPricing?.digital_cart,
  );
  console.tron.log(is_digital_cart, 'is_digital_cart');

  const addressID = JSON.parse(
    RemoteConfig.getValue(REMOTE_CONFIG_KEYS.edge_address_id_for_cart),
  );

  console.tron.log(addressID, 'is_digital_cart');

  const addressType = yield getAddressTypeForId(addressId);

  try {
    let body = {
      auth_token: authToken,
      order: {
        address_id: is_digital_cart ? addressID : addressId,
        address_type: addressType,
      },
      payment_method: paymentMethod,
      contact_less: isContactLessDelivery,
      payment_method_value: paymentMethodPayload,
      pg: paymentGateway,
      payments: 'v2',
    };
    if (showRetailStoreAddress) {
      body = {
        auth_token: authToken,
        order: {
          foxy_store_id: store_id,
        },
        payment_method: paymentMethod,
        contact_less: isContactLessDelivery,
        payment_method_value: paymentMethodPayload,
        pg: paymentGateway,
        payments: 'v2',
      };
    }
    if (isSavedPaymentMethod) {
      body.save_payment_method = true;
    }
    const response = yield fetch(url, {
      method: 'POST',
      headers: yield getApiHeaders(),
      body: JSON.stringify(body),
    });
    const statusCode = response.status;
    const json = yield convertJsonFromResponse(response);
    if (statusCode === 401) {
      callback(false, {});
      yield put(logoutUser());
    } else if (statusCode >= 200 && statusCode < 300) {
      if (Utility.isBlank(json)) {
        callback(false);
        return;
      }
      callback(true, json);
      yield put(getUserTodayDeals());
      yield put(getBoostedOffers());
      checkLastOrderStatus(new Date());
    } else {
      callback(false, json);
    }
  } catch (error) {
    callback(false, {}, {}, '');
  }
}

function* createNewOrderAsync(action) {
  const {
    payload: {
      paymentMethod = 'cod',
      addressId = '',
      paymentMethodPayload = {},
      isSavedPaymentMethod = false,
      paymentGateway = '',
      params = {},
      payments,
      skuIds = [],
      retry_payment_order_id
    } = {},
    callback = () => {},
  } = action;
  let url = `${ORDER_URL}?platform=${platform}`;
  if (skuIds.length !== 0) {
    console.tron.log(skuIds, 'createNewOrderAsync');
    url = `${url}&${join(
      skuIds?.map((skuId) => `sku_ids[]=${skuId}`),
      '&',
    )}`;
  }
  const UtoP = yield select((state) => state.bag.UtoP);

  const { isUpgradeToPrepaid, orderId } = UtoP;

  if (
    isUpgradeToPrepaid &&
    paymentMethod !== 'cod' &&
    !Utility.isBlank(orderId)
  ) {
    url = `${ORDER_URL}/${orderId}/upgrade_to_prepaid`;
  }

  if (Utility.isPresent(params) && params.retry_payment) {
    url = `${ORDER_URL}/${params.order_id}/retry_payment`;
  }

  if (Utility.isPresent(retry_payment_order_id)) {
    url = `${ORDER_URL}/${retry_payment_order_id}/retry_payment`;
  }

  const { isContactLess: isContactLessDelivery } = yield select(
    (state) => state.bag,
  );

  const authToken = yield select((state) => state.UserAccountInfo.authToken);

  const showRetailStoreAddress = yield select(
    (state) => state.bag.showRetailStoreAddress,
  );
  console.tron.log(showRetailStoreAddress, 'showRetailStoreAddress');
  const store_id = yield select(
    (state) => state?.RetailStore?.foxy_store_address?.foxy_store_id,
  );
  const is_digital_cart = yield select(
    (state) => state.bag?.cartPricing?.digital_cart,
  );

  const addressID = RemoteConfig.getValue(
    REMOTE_CONFIG_KEYS.edge_address_id_for_cart,
  );

  const addressType = yield getAddressTypeForId(addressId);

  try {
    let body = {
      auth_token: authToken,
      order: {
        address_id: is_digital_cart ? addressID : addressId,
        address_type: addressType,
      },
      payment_method: paymentMethod,
      contact_less: isContactLessDelivery,
      payment_method_value: paymentMethodPayload,
      pg: paymentGateway,
      payments,
    };
    if (showRetailStoreAddress) {
      body = {
        auth_token: authToken,
        order: {
          foxy_store_id: store_id,
        },
        payment_method: paymentMethod,
        contact_less: isContactLessDelivery,
        payment_method_value: paymentMethodPayload,
        pg: paymentGateway,
      };
    }
    if (isSavedPaymentMethod) {
      body.save_payment_method = true;
    }
    if (isWeb()) {
      body.udf1 = 'web';
    }
    const response = yield fetch(url, {
      method: 'POST',
      headers: yield getApiHeaders(),
      body: JSON.stringify(body),
    });
    const statusCode = response.status;
    const json = yield convertJsonFromResponse(response);
    if (statusCode === 401) {
      callback(false, {});
      yield put(logoutUser());
    } else if (statusCode >= 200 && statusCode < 300) {
      if (Utility.isBlank(json)) {
        callback(false, {});
        return;
      }
      const {
        status,
        pg_params: { status: pgParamsStatus = '' } = {},
        order_id,
      } = json;
      if (status === 'in_progress') {
        const payuParams = json.payu_params;
        callback(true, payuParams, {}, status);
      } else if ((status === 'success' || pgParamsStatus === 'success') && Utility.isPresent(order_id)) {
        callback(true, {}, json, status);
        yield put(getUserTodayDeals());
        yield put(getBoostedOffers());
        checkLastOrderStatus(new Date());
      } else if (status === 'success') {
        // navigate to order success page
        callback(true, {}, json?.data, status);
        yield put(getUserTodayDeals());
        yield put(getBoostedOffers());
        checkLastOrderStatus(new Date());
      } else if (status === 'failure') {
        callback(false, {}, json, status);
      } else {
        callback(false, {}, {}, status);
      }
    } else {
      callback(false, json, {}, '');
    }
  } catch (error) {
    console.tron.log('error', error);
  }
}

function* getCartOffersAsync(action) {
  const { callback } = action;
  const url = URL.OFFERS;
  const authToken = yield select((state) => state.UserAccountInfo.authToken);
  try {
    const response = yield fetch(url, {
      method: 'GET',
      headers: yield getApiHeaders(),
    });
    const statusCode = response.status;
    const json = yield convertJsonFromResponse(response);
    if (statusCode === 401) {
      callback(false, []);
      yield put(logoutUser());
    } else if (statusCode >= 200 && statusCode < 300) {
      if (Utility.isBlank(json)) {
        callback(false, []);
        return;
      }
      callback(true, json);
    } else {
      callback(false, []);
    }
  } catch (error) {
    console.tron.log(
      ` error ${JSON.stringify(error)}, auth token : ${authToken}`,
    );
    callback(false, []);
  }
}

function* getFreeItemForOfferAsync(action) {
  const { offerID } = action;
  const url = `${URL.FREE_OFFER_ITEM}?offer_id=${offerID}`;
  let json;
  const authToken = yield select((state) => state.UserAccountInfo.authToken);
  try {
    const response = yield fetch(url, {
      method: 'GET',
      headers: yield getApiHeaders(),
    });
    const statusCode = response.status;
    json = yield convertJsonFromResponse(response);
    if (statusCode === 401) {
      yield put(logoutUser());
      return;
    }
    if (Utility.isBlank(json)) {
      return;
    }
    yield put({ type: SAVE_FREE_OFFER_ITEM, data: json });
  } catch (error) {
    console.tron.log(
      ` error ${JSON.stringify(error)}, auth token : ${authToken}`,
    );
    yield put({ type: SAVE_FREE_OFFER_ITEM, data: json });
  }
}
function* getOfferRewardsAsync(action) {
  const { offerID, callback } = action;
  let json;
  const url = `${URL.GET_REWARDS}${offerID}/reward`;
  const authToken = yield select((state) => state.UserAccountInfo.authToken);
  //#Fixme - once web is live, need to fix on backend offers_controllers#reward
  const headers = yield getApiHeaders();
  delete headers.Platform;
  try {
    const response = yield fetch(url, {
      method: 'GET',
      headers,
    });
    const statusCode = response.status;
    json = yield convertJsonFromResponse(response);
    if (statusCode === 401) {
      callback(false, []);
      yield put(logoutUser());
    } else if (statusCode >= 200 && statusCode < 300) {
      if (Utility.isBlank(json)) {
        callback(false, []);
        return;
      }
      callback(true, json);
    } else {
      if (Utility.isPresent(json)) {
        yield put({ type: SAVE_FREE_OFFER_ITEM, data: json });
        return;
      }
      callback(false, []);
    }
  } catch (error) {
    console.tron.log(
      ` error ${JSON.stringify(error)}, auth token : ${authToken}`,
    );
    yield put({ type: SAVE_FREE_OFFER_ITEM, data: json });
    callback(false, []);
  }
}

function* removeOfferAsync(action) {
  const { callback, coupon_code } = action;
  const url = `${URL.CART_COUPON}?wallet_eligibility=non_eligible`;
  const authToken = yield select((state) => state.UserAccountInfo.authToken);
  let cartCoupon = {};

  if (coupon_code === 'remove_prepaid_only') {
    cartCoupon = { remove_prepaid_only_cart_coupons: true };
  } else {
    cartCoupon = {
      coupon_code,
    };
  }

  try {
    const response = yield fetch(url, {
      method: 'DELETE',
      headers: yield getApiHeaders(),
      body: JSON.stringify({
        auth_token: authToken,
        cart_coupon: cartCoupon,
      }),
    });
    const statusCode = response.status;
    if (statusCode === 401) {
      yield put(logoutUser());
    } else if (statusCode >= 200 && statusCode < 300) {
      // success
      const json = yield convertJsonFromResponse(response);
      if (Utility.isBlank(json)) {
        callback(false);
        return;
      }
      saveCartDataInRedux(json);
      yield put({ type: SAVE_BAG, data: json });
      saveCartDataInRedux(json);
      callback(true, json);
    } else {
      console.tron.log('error in remove offer', statusCode);
      callback(false);
    }
  } catch (error) {
    console.tron.log(
      ` error ${JSON.stringify(error)}, auth token : ${authToken}`,
    );
    callback(false);
  }
}

function* saveUpiAsync(action) {
  const url = URL.UPI_DATA;
  const { detail } = action.data;
  const authToken = yield select((state) => state.UserAccountInfo.authToken);
  try {
    const response = yield fetch(url, {
      method: 'POST',
      headers: yield getApiHeaders(),
      body: JSON.stringify({
        auth_token: authToken,
        upi_account: {
          virtual_bank_account_id: detail,
        },
      }),
    });
    const statusCode = response.status;
    if (statusCode === 401) {
      yield put(logoutUser());
    } else if (statusCode >= 200 && statusCode < 300) {
      // success
    } else {
      console.tron.log('error in save upi', statusCode);
    }
  } catch (error) {
    console.tron.log(
      ` error ${JSON.stringify(error)}, auth token : ${authToken}`,
    );
  }
}

function* getSavedUpiAsync() {
  const url = URL.UPI_DATA;
  const authToken = yield select((state) => state.UserAccountInfo.authToken);
  try {
    const response = yield fetch(url, {
      method: 'GET',
      headers: yield getApiHeaders(),
    });
    const statusCode = response.status;
    if (statusCode === 401) {
      yield put(logoutUser());
    } else if (statusCode >= 200 && statusCode < 300) {
      // success save upi
      const json = yield convertJsonFromResponse(response);
      if (Utility.isBlank(json)) {
        return;
      }
      yield put({ type: SAVE_UPI_IN_REDUX, data: json });
      yield put({ type: GET_NETBANKING_DETAILS });
    } else {
      console.tron.log('error in save upi', statusCode);
    }
  } catch (error) {
    console.tron.log(
      ` error ${JSON.stringify(error)}, auth token : ${authToken}`,
    );
  }
}

function* getNetbankingDetails() {
  const url = URL.NETBANKING_DETAILS;
  const authToken = yield select((state) => state.UserAccountInfo.authToken);
  try {
    const response = yield fetch(url, {
      method: 'GET',
      headers: yield getApiHeaders(),
    });
    const statusCode = response.status;
    if (statusCode === 401) {
      yield put(logoutUser());
    } else if (statusCode >= 200 && statusCode < 300) {
      // success save upi
      const json = yield convertJsonFromResponse(response);
      if (Utility.isPresent(json)) {
        yield put({ type: SAVE_NETBANKING_IN_REDUX, data: json });
      }
    } else {
      console.tron.log('error in save upi', statusCode);
    }
  } catch (error) {
    console.tron.log(
      ` error ${JSON.stringify(error)}, auth token : ${authToken}`,
    );
  }
}

function* getOfferPrompt(action) {
  const { entityId, entityType, callback = () => {}, hasGroupDeal } = action;
  let url = `${URL.OFFER_PROMPT}${entityType}s/prompts`;
  if (Utility.isPresent(entityId?.product_id)) {
    url = `${URL.OFFER_PROMPT}${entityType}s/${entityId?.product_id}/prompts?wallet_eligibility=non_eligible`;
  }

  if (Utility.isPresent(entityId?.variant_id)) {
    url = `${URL.OFFER_PROMPT}${entityType}s/${entityId?.product_id}/prompts?variant_id=${entityId?.variant_id}&wallet_eligibility=non_eligible`;
  }
  if (url.indexOf('?') !== -1) {
    url = `${url}&has_group_deal=${hasGroupDeal}`;
  } else {
    url = `${url}?has_group_deal=${hasGroupDeal}`;
  }

  if (entityType === 'product') {
    url += `&send_flash_deal_offer=true`;
  }

  const authToken = yield select((state) => state.UserAccountInfo.authToken);
  try {
    const response = yield fetch(url, {
      method: 'GET',
      headers: yield getApiHeaders(),
    });
    const statusCode = response.status;
    const json = yield convertJsonFromResponse(response);
    if (statusCode === 401) {
      callback(false, {});
    } else if (statusCode >= 200 && statusCode < 300) {
      if (Utility.isBlank(json)) {
        callback(false, {});
        return;
      }
      callback(true, json);
    } else {
      callback(false, json);
      console.tron.log('error in get offer prompts', statusCode);
    }
  } catch (error) {
    console.tron.log(
      ` error in prompt${JSON.stringify(error)}, auth token : ${authToken}`,
    );
  }
}

function* optForPlasticFreePackaging(action) {
  const { optForPlasticFree, callback = () => {} } = action;
  let url = `${URL.OPT_FOR_PLASTIC_FREE_PACKAGING}?plastic_free_packaging_charges=${optForPlasticFree}&source=cart&platform=${platform}&os=${Platform.OS}&wallet_eligibility=non_eligible`;

  const authToken = yield select((state) => state.UserAccountInfo.authToken);
  try {
    const response = yield fetch(url, {
      method: 'POST',
      headers: yield getApiHeaders(),
    });
    const statusCode = response.status;
    const json = yield convertJsonFromResponse(response);
    if (statusCode === 401) {
      callback(false);
    } else if (statusCode >= 200 && statusCode < 300) {
      if (Utility.isBlank(json)) {
        callback(false);
        return;
      }
      callback(true);
      yield put({ type: SAVE_BAG_PRICE, data: json });
    } else {
      callback(false);
    }
  } catch (error) {
    console.tron.log(
      ` error in prompt${JSON.stringify(error)}, auth token : ${authToken}`,
    );
  }
}

function* deleteSavedCardAsync(action) {
  const { cardToken, callback } = action;
  let url = `${URL.DELETE_CARD}/${cardToken}`;

  const authToken = yield select((state) => state.UserAccountInfo.authToken);
  try {
    const response = yield fetch(url, {
      method: 'DELETE',
      headers: yield getApiHeaders(),
    });
    const statusCode = response.status;
    const json = yield convertJsonFromResponse(response);
    if (statusCode === 401) {
      callback(false, {});
    } else if (statusCode >= 200 && statusCode < 300) {
      if (Utility.isBlank(json)) {
        return;
      }
      callback(true, json);
    } else {
      callback(false, json);
      console.tron.log('error in deleting card', statusCode);
    }
  } catch (error) {
    console.tron.log(
      ` error in prompt${JSON.stringify(error)}, auth token : ${authToken}`,
    );
  }
}

function* checkUpiStatusAsync(action) {
  const { txId, callback } = action;
  let url = `${URL.CHECK_UPI_TRANSACTION_STATUS}.json?txnid=${txId}`;

  const authToken = yield select((state) => state.UserAccountInfo.authToken);
  try {
    const response = yield fetch(url, {
      method: 'POST',
      headers: yield getApiHeaders(),
    });
    const statusCode = response.status;
    const json = yield convertJsonFromResponse(response);
    if (statusCode === 401) {
      callback(false, {});
    } else if (statusCode >= 200 && statusCode < 300) {
      if (Utility.isBlank(json)) {
        return;
      }
      callback(true, json);
    } else {
      callback(false, json);
      console.tron.log('error in validating paymenr', statusCode);
    }
  } catch (error) {
    console.tron.log(
      ` error in prompt${JSON.stringify(error)}, auth token : ${authToken}`,
    );
  }
}

function* validateUpiAsync(action) {
  const { vpa, callback } = action;
  let url = `${URL.VALIDATE_UPI}?virtual_bank_account_id=${vpa}`;

  const authToken = yield select((state) => state.UserAccountInfo.authToken);
  try {
    const response = yield fetch(url, {
      method: 'GET',
      headers: yield getApiHeaders(),
    });
    const statusCode = response.status;
    const json = yield convertJsonFromResponse(response);
    if (statusCode === 401) {
      callback(false, {});
    } else if (statusCode >= 200 && statusCode < 300) {
      if (Utility.isPresent(json)) {
        callback(true, json);
      }
    } else {
      callback(false, json);
      console.tron.log('error in validating upi', statusCode);
    }
  } catch (error) {
    console.tron.log(
      ` error in prompt${JSON.stringify(error)}, auth token : ${authToken}`,
    );
  }
}

function* optForCarryBag(action) {
  const { optForCarryBag, callback = () => {} } = action;
  let url = `${URL.ADD_CARRY_BAG_CHARGES}?carry_bag_amount=${optForCarryBag}&source=cart&platform=${platform}&os=${Platform.OS}&wallet_eligibility=non_eligible`;

  const isStoreAddressSelected = yield select(
    (state) => state.bag.showRetailStoreAddress,
  );

  const store_address = yield select(
    (state) => state?.RetailStore?.foxy_store_address,
  );

  if (isStoreAddressSelected && Utility.isPresent(store_address)) {
    url = `${url}&store_id=${store_address.foxy_store_id}`;
  }

  const authToken = yield select((state) => state.UserAccountInfo.authToken);
  try {
    const response = yield fetch(url, {
      method: 'POST',
      headers: yield getApiHeaders(),
    });
    const statusCode = response.status;
    const json = yield convertJsonFromResponse(response);
    if (statusCode === 401) {
      callback(false);
    } else if (statusCode >= 200 && statusCode < 300) {
      if (Utility.isBlank(json)) {
        callback(false);
        return;
      }
      callback(true);
      yield put({ type: SAVE_BAG_PRICE, data: json });
    } else {
      callback(false);
    }
  } catch (error) {
    console.tron.log(
      ` error in prompt${JSON.stringify(error)}, auth token : ${authToken}`,
    );
  }
}

function* getPaymentsDetailsForPartialCart(action) {
  const { skuIds = [], callback = () => {} } = action;
  let url = `${CART_URL}?source=cart&platform=app&os=${Platform.OS}&wallet_eligibility=non_eligible`;
  if (skuIds.length !== 0) {
    url = `${url}&${join(
      skuIds?.map((skuId) => `sku_ids[]=${skuId}`),
      '&',
    )}`;
  }

  const authToken = yield select((state) => state.UserAccountInfo.authToken);
  try {
    const response = yield fetch(url, {
      method: 'GET',
      headers: yield getApiHeaders(),
    });
    const statusCode = response.status;
    if (statusCode === 401) {
      callback(false, {}, response.error);
      yield put(logoutUser());
    } else if (statusCode >= 200 && statusCode < 300) {
      const json = yield convertJsonFromResponse(response);
      if (Utility.isBlank(json)) {
        callback(false);
        return;
      }
      callback(true, json);
    } else {
      callback(
        false,
        {},
        'Oops!!! Something went wrong. Please try again later.',
      );
    }
  } catch (error) {
    console.tron.log(
      ` error ${JSON.stringify(error)}, auth token : ${authToken}`,
    );
  }
}

export default function* watchFetchAddToCart() {
  yield takeLatest(ADD_TO_CART, addToCartAsyc);
  yield takeLatest(REMOVE_FROM_CART, removeFromCartAsyc);
  yield takeLatest(BULK_ADD_TO_CART, bulkAddToCartAsync);
  yield takeLatest(GET_CART_ITEMS, getCartItemsAsync);
  yield takeLatest(APPLY_CART_COUPON, applyCartCouponAsync);
  yield takeLatest(GET_CART_DATA, getCartDataAsync);
  yield takeLatest(CREATE_ORDER, createOrderAsync);
  yield takeLatest(CREATE_NEW_ORDER, createNewOrderAsync);
  yield takeLatest(GET_CART_OFFERS, getCartOffersAsync);
  yield takeLatest(REMOVE_OFFER, removeOfferAsync);
  yield takeLatest(SAVE_UPI_DETAILS, saveUpiAsync);
  yield takeLatest(GET_SAVED_UPI, getSavedUpiAsync);
  yield takeEvery(GET_OFFER_PROMPT, getOfferPrompt);
  yield takeEvery(OPT_FOR_PLASTIC_FREE_PACKAGING, optForPlasticFreePackaging);
  yield takeEvery(ADD_FREE_PRODUCT_TO_CART, addFreeProductToCartAsync);
  yield takeEvery(GET_FREE_ITEM_FOR_OFFER, getFreeItemForOfferAsync);
  yield takeEvery(GET_OFFER_REWARDS, getOfferRewardsAsync);
  yield takeEvery(GET_NETBANKING_DETAILS, getNetbankingDetails);
  yield takeEvery(VALIDATE_UPI, validateUpiAsync);
  yield takeEvery(DELETE_CARD, deleteSavedCardAsync);
  yield takeEvery(CHECK_UPI_STATUS, checkUpiStatusAsync);
  yield takeEvery(GET_CART_PRICING, getCartPricing);
  yield takeEvery(INCREMENT_DECREMENT_FROM_CART, incrementDecrementCart);
  yield takeEvery(COPY_CART_ITEMS, copyCartItemsAsync);
  yield takeEvery(CREATE_NEW_ORDER_V2, createNewOrderV2Async);
  yield takeLatest(OPT_FOR_CARRY_BAG, optForCarryBag);
  yield takeLatest(FETCH_CART, getCartItemsAsync);
  yield takeLatest(SET_CART_NOTIFICATION, setCartReminderNotificationAlarm);
  yield takeLatest(GET_PAYMENTS_DETAILS_FOR_PARTIAL_CART, getPaymentsDetailsForPartialCart);
}
