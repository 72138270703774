import React, { PureComponent } from 'react';
import { View, StyleSheet, Text } from 'react-native';
import MediaStyleSheet from 'react-native-media-query';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import colors from '../../theme/Colors';
import size from '../../theme/Fonts';
import DynamicLinkManager from '../../utils/DynamicLinkManager';

import Utility from '../../utils/Utility';
import FastImageView from '../FastImageView';
import ScaleAnimate from '../shared/ScaleAnimate';
import DebouncedTouchableOpacity from '../shared/DebouncedTouchableOpacity';
import AnalyticsUtility from '../../analytics/AnalyticsUtility';
import { navigateToScreen } from '../../utils/NavigationUtility';
import { isDesktop, isBlank } from '../../utils/BooleanUtility';
import withProfiledNavigation from '../../utils/withProfiledNavigation';
import { getNavigationSource } from '../../utils/PerfUtility';

class EdgeToEdgeBanner extends PureComponent {
  static getComponentHeight(item) {
    const { arguments: { aspect_ratio } = {} } = item;
    const [width, height] = aspect_ratio.split(':');
    const viewAspectRatio = height / width;
    try {
      return viewAspectRatio * Utility.getScreenWidth() / (isDesktop() ? 2 : 1);
    } catch (error) {
      return 0;
    }
  }

  constructor(props) {
    super(props);
    const {
      itemData: {
        arguments: {
          text_vertical_position,
          text_horizontal_position,
          cta_vertical_position,
          cta_horizontal_position,
          aspect_ratio,
        },
      },
    } = this.props;

    const [width, height] = aspect_ratio.split(':');
    this.viewAspectRatio = height / width;

    this.textContainerStyle = [
      style.textContainer,
      textContainerStyles['textHorizontalAlignment'][text_horizontal_position],
      textContainerStyles['textVerticalAlignment'][text_vertical_position],
    ];

    this.subtitleTextAlignment = [
      style.subtitle,
      textContainerStyles.subtitleAlignment[text_horizontal_position],
    ];

    this.buttonContainerStyle = [
      style.buttonContainer,
      textContainerStyles['ctaHorizontalAlignement'][cta_horizontal_position],
      textContainerStyles['ctaVerticalAlignment'][cta_vertical_position],
    ];

    this.memoizeTitleColor = {};
    this.memoizeDescriptionColor = {};
    this.memoizeButtonColor = {};
    this.memoizeHyperLinkColor = {};
    this.navigationSource = getNavigationSource(this, this.props);
  }

  memoizedTitleColor = (color) => {
    const {
      itemData: {
        arguments: { text_alignment = 'center' },
      },
    } = this.props;
    if (!this.memoizeTitleColor[color]) {
      this.memoizeTitleColor = [
        style.title,
        {
          color: color,
          textAlign: text_alignment,
        },
      ];
    }
    return this.memoizeTitleColor;
  };

  memoizedDescriptionColor = (color) => {
    const {
      itemData: {
        arguments: { text_alignment },
      },
    } = this.props;
    if (!this.memoizeDescriptionColor[color]) {
      this.memoizeDescriptionColor = [
        ...this.subtitleTextAlignment,
        {
          color,
          textAlign: text_alignment,
        },
      ];
    }
    return this.memoizeDescriptionColor;
  };

  memoizedButtonColor = (color) => {
    if (!this.memoizeButtonColor[color]) {
      this.memoizeButtonColor = [style.button, { backgroundColor: color }];
    }

    return this.memoizeButtonColor;
  };

  memoizedHyperLinkColor = (color) => {
    if (!this.memoizedHyperLinkColor[color]) {
      this.memoizeHyperLinkColor = [style.hyperlinkText, { color }];
    }

    return this.memoizeHyperLinkColor;
  };

  textContainer = () => {
    const {
      itemData: {
        title,
        body,
        arguments: {
          title_color = colors.white,
          subtitle_color = colors.white,
          hide_feature_title = 'false',
        },
      },
    } = this.props;
    const titleChars =
      title.length <= 25 ? title : `${title.substring(0, 25)}...`;
    const subTitleChar =
      body.length <= 95 ? body : `${body.substring(0, 95)}...`;

    if (Utility.isBlank(title) && Utility.isBlank(body)) return null;
    return (
      <View style={this.textContainerStyle}>
        {hide_feature_title === 'false' && (
          <Text
            style={this.memoizedTitleColor(title_color)}
          >{`${titleChars}`}</Text>
        )}
        <Text
          style={this.memoizedDescriptionColor(subtitle_color)}
        >{`${subTitleChar}`}</Text>
      </View>
    );
  };

  navigateToScreen = (path, destination) => {
    const { navigation } = this.props;

    navigateToScreen({
      navigation,
      type: 'push',
      screen: path,
      navigationSource: this.navigationSource,
      params: {
        destination, // webview reads data from destination
        slug: destination,
      },
    });
  };

  onPressButton = () => {
    const {
      itemData: {
        id = '',
        type = '',
        title: name = '',
        slug = '',
        destination,
        external_link,
      } = {},
      index,
      listIndex,
      listData: {
        id: listId = '',
        name: listName = '',
        slug: listSlug = '',
        content: listContent = '',
        type: listLayout = '',
      } = {},
      previousScreen = '',
      parentListsData,
      itemData,
    } = this.props;

    const url = Utility.isPresent(external_link) ? external_link : destination;
    DynamicLinkManager.handleDynamicLink(
      url,
      this.navigateToScreen,
      destination,
    );
    AnalyticsUtility.fireItemClickEvent(
      previousScreen,
      id,
      type,
      name,
      index,
      listId,
      listLayout,
      listName,
      listIndex,
      '',
      '',
      listContent,
      '',
      slug,
      listSlug,
      AnalyticsUtility.getParentsList(parentListsData),
    );
  };

  buttonContainer = () => {
    const {
      itemData: {
        cta_color,
        cta_text,
        arguments: { cta_type, hide_cta_button: hideCtaButton = false },
      },
    } = this.props;
    if (hideCtaButton || isBlank(cta_text)) return null;
    const buttonStyle =
      cta_type === 'hyperlink'
        ? style.hyperlinkContainer
        : this.memoizedButtonColor(cta_color);

    const buttonTextStyle =
      cta_type === 'hyperlink'
        ? this.memoizedHyperLinkColor(cta_color)
        : style.buttonText;

    return (
      <View style={this.buttonContainerStyle}>
        <DebouncedTouchableOpacity {...this.props} onPress={this.onPressButton}>
          <View style={buttonStyle}>
            <Text style={buttonTextStyle}>{cta_text}</Text>
          </View>
        </DebouncedTouchableOpacity>
      </View>
    );
  };

  render() {
    const { itemData: { image_url } = {}, previousScreen = '' } = this.props;
    return (
      <ScaleAnimate {...this.props} onPress={this.onPressButton}>
        <View
          style={[
            style.container,
            { height: this.viewAspectRatio * Utility.getScreenWidth() / (isDesktop() ? 2 : 1) },
          ]}
          dataSet={{ media: ids.container }}
        >
          <FastImageView
            height={this.viewAspectRatio * Utility.getScreenWidth() / (isDesktop() ? 2 : 1)}
            width={Utility.getScreenWidth() / (isDesktop() ? 2 : 1)}
            source={Utility.getImageUrl(image_url, 1500)}
            showLoader={previousScreen === SCREEN_CONSTANTS.LIST_TAB}
          />
          <View style={style.contentContainer}>
            <this.textContainer />
            <this.buttonContainer />
          </View>
        </View>
      </ScaleAnimate>
    );
  }
}

export default withProfiledNavigation(EdgeToEdgeBanner);

const textContainerStyles = StyleSheet.create({
  textVerticalAlignment: {
    top: {
      justifyContent: 'flex-start',
      marginTop: 32,
    },
    center: {
      justifyContent: 'flex-end',
    },
    bottom: {
      flex: 3.7,
      justifyContent: 'flex-end',
    },
  },
  textHorizontalAlignment: {
    left: {
      alignItems: 'flex-start',
    },
    right: {
      alignItems: 'flex-end',
    },
    center: {
      alignItems: 'center',
    },
  },

  subtitleAlignment: {
    left: {
      textAlign: 'left',
    },
    center: {
      textAlign: 'center',
    },
    right: {
      width: Utility.getScreenWidth() / 1.6,
      textAlign: 'right',
    },
  },

  ctaVerticalAlignment: {
    top: {
      justifyContent: 'flex-start',
    },
    center: {
      justifyContent: 'center',
    },
    bottom: {
      justifyContent: 'flex-end',
    },
  },
  ctaHorizontalAlignement: {
    left: {
      alignSelf: 'flex-start',
    },
    right: {
      alignSelf: 'flex-end',
    },
    center: {
      alignSelf: 'center',
    },
  },
});

const { ids, styles: style } = MediaStyleSheet.create({
  container: {
    width: Utility.getScreenWidth(),
    marginBottom: 8,
    marginTop: 6,
    backgroundColor: '#eeeee4',
    borderRadius: 10,
    flexDirection: 'column',
    '@media (min-width: 768px)': {
      width: Utility.getScreenWidth() / 2,
      alignSelf: 'center',
    },
  },

  textContainer: {
    flex: 1,
    marginTop: 34,
    alignItems: 'center',
  },

  buttonContainer: {
    height: '100%',
    position: 'absolute',
    top: 0,
    paddingHorizontal: 24,
    paddingBottom: 12,
    paddingTop: 24,
  },

  title: {
    fontFamily: 'Roboto-Bold',
    fontSize: 24,
    lineHeight: 28,
    color: '#fff',
  },

  subtitle: {
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
    marginTop: 12,
    color: '#fff',
    textAlign: 'center',
  },

  button: {
    height: 44,
    minWidth: 130,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    backgroundColor: '#fff',
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 12,
    paddingBottom: 14,
    marginBottom: 12,
  },

  hyperlinkContainer: {
    height: 44,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    // paddingTop: 12,
    // paddingBottom: 14,
  },

  buttonText: {
    fontFamily: 'Roboto-Regular',
    fontSize: size.h3,
    color: colors.black,
  },
  hyperlinkText: {
    fontFamily: 'Roboto-Regular',
    fontSize: size.h3,
    textDecorationLine: 'underline',
  },

  contentContainer: {
    zIndex: 1,
    position: 'absolute',
    height: '100%',
    paddingLeft: 24,
    paddingRight: 24,
    width: Utility.getScreenWidth(),
  },
});
