import React, { Component } from 'react';
import {
  View,
  StyleSheet,
  ImageBackground,
  Text,
  SafeAreaView,
  TouchableWithoutFeedback,
  TouchableOpacity,
  Linking,
  Image,
  StatusBar,
  BackHandler,
} from 'react-native';
import { Alert } from '../../libraries/NativeLibraries';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import images from '../../theme/Images';
import size from '../../theme/Fonts';
import colors from '../../theme/Colors';
import Utility from '../../utils/Utility';
import {
  PERMISSION_STORE,
  SCREEN,
  CURRENT_GUEST_STATE,
  PERMISSIONS,
  AnalyticsScreenNames,
} from '../../config/Constants';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import {
  saveUserState,
  guestFirstAppOpenTime,
  getAllowedAttributes,
} from '../../actions/LoginActions';

import FoxyAlert from '../camera/shared/FoxyAlert';
import {
  AnalyticsManager,
  EventType,
  EventParameterKey,
} from '../../analytics';
import StylesConstants, { HeaderHeight } from '../../utils/StylesConstants';
import NotificationManager from '../../utils/NotificationsManager';
import { setCongratsModalForEdge } from '../../actions/FoxyEdgeAction';
import NavigationService from '../../navigator/NavigationService';
import { getFirebasePerformanceTrace } from '../../utils/PerfUtility';

class SelfieIntro extends Component {
  constructor() {
    super();

    this.isPermissionDenied = false;
    this.currentPermissionState = '';
    this.state = {
      showPermissionModal: false,
    };

    this.trace = getFirebasePerformanceTrace(SCREEN_CONSTANTS.SELFIE_INTRO);
    this.trace.start();

    this.smartSelfieContainerStyle = [
      styles.smartSelfieButtonContainer,
      { height: StylesConstants.getHeaderHeight() },
    ];
  }

  componentDidMount() {
    const { guestProfile, navigation } = this.props;

    Utility.getTimeDifferenceBetweenFirstAppOpen(guestProfile.firstOpenTime);
    if (Utility.isAndroid()) {
      this.navListener = navigation.addListener('focus', () => {
        StatusBar.setBackgroundColor('#000000');
        StatusBar.setBarStyle('light-content');
      });
    }
    AnalyticsManager.logEvent(EventType.onboardingEvent.SELFIE_PROMPT_VIEW);
    if (Utility.isAndroid()) {
      this.backhandler = BackHandler.addEventListener(
        'hardwareBackPress',
        this.onHardwareBackKeyPress,
      );
    }
    this.trace.stop();
    AnalyticsManager.logEvent(EventType.pageLoad.PAGE_LOAD, {
      [EventParameterKey.SCREEN_NAME]: SCREEN_CONSTANTS.SELFIE_INTRO,
    });

    if (Utility.skipOnboardingSelfie) {
      this.skip();
    }
  }

  componentWillUnmount() {
    if (Utility.isAndroid()) {
      this.navListener();
      this.backhandler?.remove();
    }
  }

  onHardwareBackKeyPress = () => {
    return true;
  };

  onPermissionDialogAllowClick = () => {
    this.hidePermissionDialog();
    setTimeout(() => {
      if (
        Utility.isAndroid() &&
        this.currentPermissionState !== 'never_ask_again'
      ) {
        this.currentPermissionState = '';
        this.checkCameraPermission();
      } else {
        this.currentPermissionState = '';
        Linking.openSettings();
      }
    }, 500);
  };

  navigateToTakeSelfie = () => {
    const { navigation, route } = this.props;

    const previousScreen = route.params?.previousScreen;
    const extraData = route.params?.extraData || {};

    if (previousScreen === 'subscribe_routine') {
      navigation.replace(SCREEN.TAKE_SELFIE, {
        previousScreen: SCREEN_CONSTANTS.SUBSCRIBE_ROUTINE,
        extraData,
      });
      return;
    }

    if (previousScreen === SCREEN.RETAIL_STORE) {
      navigation.replace(SCREEN.TAKE_SELFIE, {
        previousScreen: SCREEN.RETAIL_STORE,
        ...extraData,
      });
      return;
    }

    if (previousScreen === SCREEN_CONSTANTS.PROFILE_ICON) {
      navigation.replace(SCREEN.TAKE_SELFIE, {
        previousScreen: SCREEN_CONSTANTS.PROFILE_ICON,
        ...extraData,
      });
    } else {
      navigation.navigate(SCREEN.TAKE_SELFIE, {
        previousScreen: SCREEN_CONSTANTS.SELFIE_PROMPT,
        ...extraData,
      });
    }
  };

  checkCameraPermission = () => {
    Utility.checkPermissionStatic(
      PERMISSION_STORE.camera,
      true,
      (permissionState) => {
        if (permissionState === 'granted' || permissionState === 'authorized') {
          const { navigation } = this.props;
          this.navigateToTakeSelfie();
          // navigation.navigate(SCREEN.TAKE_SELFIE, {
          //   previousScreen: SCREEN_CONSTANTS.SELFIE_PROMPT,
          // });
          this.currentPermissionState = '';
        } else if (
          permissionState === 'never_ask_again' ||
          permissionState === 'denied'
        ) {
          if (Utility.isBlank(this.currentPermissionState)) {
            this.currentPermissionState = permissionState;
            this.showPermissionDialog();
          } else {
            this.showPermissionDialog();
          }
        }
      },
      Utility.createPermissionMetaObject(
        AnalyticsScreenNames.SELFIE_PROMPT,
        PERMISSIONS.CAMERA,
      ),
    );
  };

  showPermissionDialog = () => {
    this.setState({
      showPermissionModal: true,
    });
  };

  hidePermissionDialog = () => {
    this.setState({
      showPermissionModal: false,
    });
  };

  onPressContinue = () => {
    AnalyticsManager.logEvent(EventType.onboardingEvent.SELFIE_PROMPT_CONTINUE);
    if (!Utility.isBlank(this.currentPermissionState)) {
      this.showPermissionDialog();
    } else {
      this.checkCameraPermission();
    }
  };

  openAlert = (message) => {
    Alert.alert('Permission Required', message, [
      {
        text: 'Settings',
        onPress: () => {
          Linking.openURL('app-settings:');
        },
      },
      {
        text: 'Cancel',
        onPress: () => {},
        style: 'cancel',
      },
    ]);
  };

  goBack = () => {
    const { navigation } = this.props;
    console.tron.log('Wokring');
    navigation.goBack();
  };

  skip = (source = '') => {
    const {
      navigation,
      route,
      saveUserState,
      authToken,
      guestFirstAppOpenTime,
      setCongratsModalForEdge,
    } = this.props;

    const previousScreen = route.params?.params?.previousScreen;
    const routineCheckList = route.params?.params?.routineCheckList;
    const onCheckListItemsTap = route.params?.params?.onCheckListItemsTap;
    if (previousScreen === SCREEN.RETAIL_STORE) {
      navigation.goBack();
      return;
    }
    if (previousScreen === SCREEN_CONSTANTS.SELFIE_PROMPT) {
      setCongratsModalForEdge(true);
      NavigationService.push('TheEdge');
      return;
    }

    if (
      [
        SCREEN_CONSTANTS.SUBSCRIBE_ROUTINE,
        SCREEN_CONSTANTS.PRE_ROUTINE,
      ]?.includes(previousScreen) &&
      Utility.isPresent(routineCheckList)
    ) {
      navigation.replace('RoutineCheckList', {
        routineCheckList,
        onCheckListItemsTap,
      });
      return;
    }

    if (
      [
        SCREEN_CONSTANTS.SUBSCRIBE_ROUTINE,
        SCREEN_CONSTANTS.PRE_ROUTINE,
      ]?.includes(previousScreen)
    ) {
      navigation.goBack();
      return;
    }

    if (!authToken || Utility.isBlank(authToken)) {
      saveUserState(CURRENT_GUEST_STATE.SKIPPED);
      guestFirstAppOpenTime(new Date());
    }
    this.navigateToFeed();
    this.isPermissionDenied = false;

    if (source === 'skip_button_press') {
      AnalyticsManager.logEvent(EventType.onboardingEvent.SELFIE_PROMPT_SKIP);
    }
  };

  navigateToFeed = () => {
    const { navigation } = this.props;
    Utility.tabNavigatorReplaced = true;
    navigation.replace('TabNavigator');
  };

  skipButton = () => {
    return (
      <TouchableOpacity onPress={() => this.skip('skip_button_press')}>
        <View style={styles.skipButtonContainer}>
          <Text style={styles.skipButton}>Skip</Text>
        </View>
      </TouchableOpacity>
    );
  };

  render() {
    const { showPermissionModal } = this.state;
    return (
      <ImageBackground
        style={styles.container}
        source={images.selfiePromptImage}
      >
        <SafeAreaView style={styles.overlay}>
          <View
            style={[
              styles.flexContainer,
              {
                alignItems: 'center',
                flex: 0.6,
                marginTop: 30,
              },
            ]}
          >
            <View style={this.smartSelfieContainerStyle}>
              <Text style={styles.headText}>The Smartest Selfie Ever</Text>
              <TouchableOpacity>
                <Text
                  style={{
                    fontFamily: 'Roboto-Regular',
                    fontSize: size.h3,
                    color: colors.white,
                  }}
                >
                  {''}
                </Text>
              </TouchableOpacity>
            </View>
            <Text style={styles.subText}>
              Get AI-powered skin & hair recommendations
            </Text>
          </View>
          <View style={styles.flexContainer} />
          <View
            style={[
              styles.flexContainer,
              { justifyContent: 'flex-end', paddingBottom: 20 },
            ]}
          >
            <Text style={styles.tagOverButton}>
              Allow camera permissions at the next prompt
            </Text>
            <TouchableWithoutFeedback
              onPress={this.onPressContinue}
              hitSlop={Utility.getHitSlop()}
            >
              <View style={styles.buttonLayout}>
                <Text style={[styles.headText, { fontSize: 18 }]}>
                  Continue
                </Text>
              </View>
            </TouchableWithoutFeedback>
            <this.skipButton />
          </View>
        </SafeAreaView>
        <FoxyAlert
          isVisible={showPermissionModal}
          alertBoxTitle="Permission Required"
          alertMessage="Camera permission is required to take the selfie"
          firstButtonTitle="Allow"
          firstButtonTextColor={colors.dodgerBluePressed}
          secondButtonTitle="Skip"
          height={220}
          firstButtonOnPress={this.onPermissionDialogAllowClick}
          secondButtonOnPress={this.skip}
          onTapOutside={this.hidePermissionDialog}
        />
      </ImageBackground>
    );
  }
}

const mapStateToProps = function (state) {
  return {
    authToken: state.UserAccountInfo.authToken,
    guestProfile: state.UserAccountInfo.guestProfile,
  };
};
const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      saveUserState,
      guestFirstAppOpenTime,
      setCongratsModalForEdge,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(SelfieIntro);

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  overlay: {
    position: 'absolute',
    height: '100%',
    width: '100%',

    flexDirection: 'column',
  },
  flexContainer: {
    flex: 1,
    paddingLeft: 20,
    paddingRight: 20,
  },
  headText: {
    fontFamily: 'Roboto-Medium',
    fontSize: 20,
    padding: 8,
    color: colors.white,
  },
  subText: {
    fontFamily: 'Roboto-Regular',
    fontSize: size.h3,

    paddingLeft: 8,
    paddingRight: 8,
    // paddingTop: 3,
    textAlign: 'center',
    color: colors.white,
  },
  buttonLayout: {
    backgroundColor: colors.black,
    borderWidth: 1,
    borderColor: colors.white,
    borderRadius: 4,
    height: Utility.getButtonHeight(),
    alignItems: 'center',
    justifyContent: 'center',
  },

  tagOverButton: {
    fontFamily: 'Roboto-Medium',
    fontSize: size.h3,
    color: colors.white,
    textAlign: 'center',
    padding: 7,
  },
  skipButtonContainer: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 10,
  },
  skipButton: {
    fontFamily: 'Roboto-Medium',
    color: colors.white,
    fontSize: size.h3,
  },
  smartSelfieButtonContainer: {
    flexDirection: 'row',
    width: Utility.getScreenWidth(),
    paddingLeft: 20,
    paddingRight: 20,
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
});
