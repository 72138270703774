import React from 'react';
import { Text, View } from 'react-native';
import FastImageView from '../../components/FastImageView';
import { ids, styles } from './styles';
import Utility from '../../utils/Utility';
import { getMinifiedImage } from '../../utils/ImageUtility';

export default function LargeTag(props) {
  const {
    itemData = {},
    itemData: { image_url = '', name = '', large_image = '' } = {},
    options: { show_item_labels = '0' } = {},
  } = props;

  if (Utility.isBlank(large_image)) {
    return null;
  }

  const newImageUrl = getMinifiedImage(
    large_image,
    styles.frameLessTagStyles.width,
    styles.frameLessTagStyles.height,
  );

  return (
    <View style={styles.frameLessTagContainer}>
      <FastImageView
        source={newImageUrl}
        style={styles.frameLessTagStyles}
        resizeMode='contain'
        dataSet={{ media: ids.frameLessTagStyles }}
      />
      {show_item_labels === '1' && <Text style={styles.tagsText}>{name}</Text>}
    </View>
  );
}
