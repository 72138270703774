import React, { useCallback, useEffect, useState } from 'react';
import { FlatList, RefreshControl, StyleSheet, Linking } from 'react-native';
import { useRoute } from '@react-navigation/native';
import { useDispatch } from 'react-redux';
import StaticNavigationHeader from '../shared/StaticNavigationHeader';
import colors from '../../theme/Colors';
import OrderDetailShimmer from '../shared/OrderDetailShimmer';
import { useHardwareBackKeyPress } from '../../lib/hooksUtil';
import { getShopifyOrdersDetails } from '../../actions/ActionTypes';
import RenderActionCard from './actionCards/RenderActionCard';
import ContactsUsUtility from '../../utils/ContactUsUtility';
import { isIOS, isPresent } from '../../utils/BooleanUtility';

const JSONAPIDeserializer = require('jsonapi-serializer').Deserializer;

export default function ShopifyOrderDetails() {
  const [orderData, setOrderData] = useState({});
  const { params = {} } = useRoute();
  const [showShimmer, setShowShimmer] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  useHardwareBackKeyPress();
  const dispatch = useDispatch();
  const { id: shopifyOrderId } = params;
  const { actionCards = [] } = orderData;

  const fetchOrderDetails = useCallback(
    (isRefresh = false) =>
      dispatch(
        getShopifyOrdersDetails(shopifyOrderId, (success, response) => {
          if (success) {
            try {
              new JSONAPIDeserializer({
                typeAsAttribute: false,
                pluralizeType: true,
                keyForAttribute: 'camelCase',
              })
                .deserialize(response)
                .then((data) => {
                  setOrderData(data);
                });
            } catch (e) {}
          }
          if (isRefresh) {
            setRefreshing(false);
          } else {
            setShowShimmer(false);
          }
        }),
      ),
    [shopifyOrderId],
  );

  useEffect(() => {
    setShowShimmer(true);
    fetchOrderDetails();
  }, []);

  const onRefresh = () => {
    setRefreshing(true);
    fetchOrderDetails(true);
  };


  const orderItems = actionCards.filter((item) =>
    isPresent(item?.orderItems),
  )[0]?.orderItems;

  const returnRequests = actionCards.filter((item) =>
    isPresent(item?.returnRequests),
  )?.[0]?.returnRequests;

  const returnRequestsV2 = actionCards.filter((item) =>
    isPresent(item?.returnRequestsV2),
  )?.[0]?.returnRequestsV2;

  const refundCoupons = actionCards.filter((item) =>
    isPresent(item?.refundCoupons),
  )?.[0]?.refundCoupons;

  const totalAmount = actionCards.filter((item) =>
    isPresent(item?.totalAmount),
  )?.[0]?.totalAmount;

  const returnReasons = actionCards.filter((item) =>
    isPresent(item?.returnReasons),
  )?.[0]?.returnReasons;

  const cancellationReasons = actionCards.filter((item) =>
    isPresent(item?.cancellationReasons),
  )?.[0]?.cancellationReasons;

  const returnProcessingFee = actionCards.filter((item) =>
    isPresent(item?.returnProcessingFee),
  )?.[0]?.returnProcessingFee;

  const returnPolicyUrl = actionCards.filter((item) =>
    isPresent(item?.returnPolicyUrl),
  )?.[0]?.returnPolicyUrl;

  const cancellationPolicyUrl = actionCards.filter((item) =>
    isPresent(item?.cancellationPolicyUrl),
  )?.[0]?.cancellationPolicyUrl;

  const {
    orderName,
    status,
    createdAt,
    shipmentId,
    email: orderEmail,
    address,
    ekanekOrderId = ''
  } = orderData;

  const renderItem = ({ item = {} }) => (
    <RenderActionCard
      item={item}
      ekanekOrderId={ekanekOrderId}
      status={status}
      createdAt={createdAt}
      orderName={orderName}
      shipmentId={shipmentId}
      orderEmail={orderEmail}
      onContactUsOptionPress={ContactsUsUtility.onContactUsOptionPress}
      address={address}
      orderItems={orderItems}
      totalAmount={totalAmount}
      returnReasons={returnReasons}
      returnRequests={returnRequests}
      refundCoupons={refundCoupons}
      returnProcessingFee={returnProcessingFee}
      returnPolicyUrl={returnPolicyUrl}
      shopifyOrderId={shopifyOrderId}
      returnRequestsV2={returnRequestsV2}
      cancellationReasons={cancellationReasons}
      cancellationPolicyUrl={cancellationPolicyUrl}
    />
  );

  if (showShimmer) {
    return (
      <>
        <StaticNavigationHeader title='ORDER DETAILS' />
        <OrderDetailShimmer />
      </>
    );
  }

  return (
    <>
      <StaticNavigationHeader title='ORDER DETAILS' />

      <FlatList
        data={actionCards}
        refreshControl={
          <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
        }
        renderItem={renderItem}
        showsVerticalScrollIndicator={false}
        contentContainerStyle={styles.contentContainerStyle}
        initialNumToRender={actionCards.length}
        extraData={[orderName, status, createdAt, shipmentId, orderEmail]}
      />
    </>
  );
}

const styles = StyleSheet.create({
  contentContainerStyle: {
    backgroundColor: colors.background,
    paddingBottom: isIOS() ? 48 : 24,
  },
});
