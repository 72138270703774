import { assign, capitalize } from 'lodash';
import React, { PureComponent } from 'react';
import { View, StyleSheet, Text, TextInput } from 'react-native';
import { TextField } from 'react-native-material-textfield';
import colors from '../../theme/Colors';
import size from '../../theme/Fonts';
import Utility from '../../utils/Utility';

class InviteCodeTextField extends PureComponent {
  componentDidMount() {
    this.textFieldRef?.focus();
  }

  render() {
    const {
      onChangeText = () => {},
      error = '',
      inviteCode = '',
      success = false,
    } = this.props;
    const verticalLineStyle = Utility.isPresent(error)
      ? styles.redLine
      : styles.greyLine;
    const bottomText = success ? "Congrats, your're eligible!" : error;
    const bottomTextStyle = success
      ? [styles.errorText, { color: 'green' }]
      : styles.errorText;
    return (
      <View style={styles.textInputStyle}>
        <TextField
          placeholderTextColor={colors.silver}
          value={inviteCode}
          onChangeText={onChangeText}
          onSubmitEditing={() => {}}
          keyboardType="default"
          returnKeyType="next"
          maxLength={24}
          style={styles.textInput}
          textAlignVertical="center"
          errorColor={colors.foxyRed}
          lineWidth={0}
          activeLineWidth={0}
          label={'Your Invite Code'}
          ref={(ref) => {
            this.textFieldRef = ref;
          }}
          tintColor={colors.silver}
        />
        {!success && <View style={verticalLineStyle} />}
        {success && <View style={styles.greenLine} />}
        <Text style={bottomTextStyle}>{bottomText}</Text>
      </View>
    );
  }
}

export default InviteCodeTextField;

const styles = StyleSheet.create({
  textInput: {
    color: colors.black,
    fontSize: size.h3,
    fontFamily: 'Roboto-Regular',
    borderColor: colors.transparent,
    borderBottomWidth: 1,
    marginTop: 10,
    marginBottom: Utility.isIOS() ? -2 : -10,
  },
  errorText: {
    fontSize: size.h4,
    fontFamily: 'Roboto-Regular',
    color: colors.foxyRed,
    height: 24,
    justifyContent: 'center',
  },
  redLine: {
    width: '100%',
    height: 1,
    backgroundColor: colors.foxyRed,
  },
  greyLine: {
    width: '100%',
    height: 1,
    backgroundColor: colors.silver,
  },
  greenLine: {
    width: '100%',
    height: 1,
    backgroundColor: colors.green,
  },
  textInputStyle: {
    marginTop: 8,
    width: Utility.getScreenWidth() - 36,
    marginHorizontal: 6,
    marginBottom: 4,
  },
});
