import React, { Component } from 'react';
import { View, Text, BackHandler, StyleSheet } from 'react-native';
import Config from '../../libraries/ReactNativeConfig';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import StaticNavigationHeader from '../shared/StaticNavigationHeader';
import { getOrdersHistory } from '../../actions/OrderActions';
import OrderItems from './OrderItems';
import Utility from '../../utils/Utility';
import EmptyState from '../layout/emptyState/Emptystate';
import images from '../../theme/Images';
import colors from '../../theme/Colors';
import OrderHistoryShimmer from '../shared/OrderHistoryShimmer';
import _ from 'lodash';
import withNavigation from '../../utils/WithNavigation';
import {
  AnalyticsManager,
  EventParameterKey,
  EventType,
} from '../../analytics';
import {
  NOTIFICATION_REQUEST_MODAL_TYPE,
  REMOTE_CONFIG_KEYS,
} from '../../config/Constants';
import { setPhoneNumberModalInfo } from '../../actions/LoginActions';
import RemoteConfig from '../../utils/RemoteConfig';
import NavigationService from '../../navigator/NavigationService';
import FoxyRoundedButton from '../../lib/FoxyRoundedButton';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import { getFirebasePerformanceTrace } from '../../utils/PerfUtility';
import { jsonParser } from '../../utils/JsonUtility';
import ContactsUsUtility from '../../utils/ContactUsUtility';
import AppConfig from '../../config/AppConfig';

var JSONAPIDeserializer = require('jsonapi-serializer').Deserializer;

class OrdersHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderHistoryData: [],
      showShimmer: false,
      showLoader: false,
      isSelectOrderView: false,
    };
    this.page = 0;
    if (Utility.isAndroid()) {
      this.backhandler = BackHandler.addEventListener(
        'hardwareBackPress',
        this.onHardwareBackKeyPress,
      );
    }
    this.debouncedPaginateOrderHistory = _.debounce(
      this.paginateOrderHistory,
      1000,
    );
    setTimeout(this.displayNotificationRequestModal, 2000);
    this.showContactUsCard = jsonParser(
      RemoteConfig.getValue(REMOTE_CONFIG_KEYS.show_contact_us_card),
    );
    this.trace = getFirebasePerformanceTrace(SCREEN_CONSTANTS.ORDER_HISTORY);
    this.trace.start();
  }

  componentDidMount() {
    const { getOrdersHistory, navigation } = this.props;

    AnalyticsManager.logEvent('page_load_2', {
      page: 'order_history',
    });

    this.setShowShimmer(true);
    getOrdersHistory(this.page, (success, response) => {
      this.trace.stop();
      this.setShowShimmer(false);
      if (success) {
        this.page++;

        try {
          new JSONAPIDeserializer({
            typeAsAttribute: false,
            pluralizeType: true,
            keyForAttribute: 'camelCase',
          })
            .deserialize(response)
            .then((data) => {
              this.setState({ orderHistoryData: data });
            });
        } catch (e) {}
      }
    });
    if (navigation.getState().routes[0]?.name === 'OrdersHistory') {
      this.goToTabNavigator = true;
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { authToken } = this.props;
    if (Utility.isBlank(prevProps.authToken) && Utility.isPresent(authToken)) {
      const { getOrdersHistory } = this.props;

      this.setShowShimmer(true);
      getOrdersHistory(0, (success, response) => {
        this.setShowShimmer(false);
        if (success) {
          this.page++;
          try {
            new JSONAPIDeserializer({
              typeAsAttribute: false,
              pluralizeType: true,
              keyForAttribute: 'camelCase',
            })
              .deserialize(response)
              .then((data) => {
                this.setState({ orderHistoryData: data });
              });
          } catch (e) {}
        }
      });
    }
  }

  componentWillUnmount() {
    if (Utility.isAndroid()) {
      this.backhandler.remove();
    }
  }

  setShowLoader = (show) => {
    this.setState({
      showLoader: show,
    });
  };

  onHardwareBackKeyPress = () => {
    this.goBack();
    return true;
  };

  paginateOrderHistory = () => {
    const { getOrdersHistory } = this.props;
    this.setShowLoader(true);

    if (this.page === -1) {
      this.setState({
        showLoader: false,
      });
      return;
    }

    getOrdersHistory(this.page, (success, response) => {
      if (success) {
        this.page++;
        this.setShowLoader(false);

        try {
          new JSONAPIDeserializer({
            typeAsAttribute: false,
            pluralizeType: true,
            keyForAttribute: 'camelCase',
          })
            .deserialize(response)
            .then((data) => {
              if (Utility.isBlank(data)) {
                this.page = -1;
              }
              this.setState({
                orderHistoryData: [...this.state.orderHistoryData, ...data],
              });
            });
        } catch (e) {}
      }
    });
  };

  setShowShimmer = (showShimmer) => {
    this.setState({
      showShimmer,
    });
  };

  navigateToStore = () => {
    const { navigation } = this.props;
    navigation.navigate(Config.ORDER_HISTORY_EMPTY_STATE_CTA);
  };

  renderOnboarding = () => {
    const { renderOnboarding, setPhoneNumberModalInfo } = this.props;
    setPhoneNumberModalInfo(Utility.getLoginModalInfo('CHECK_ORDER_HISTORY'));
    NavigationService.renderOnboarding();
  };

  loginScreen = () => {
    return (
      <>
        <StaticNavigationHeader
          title='Order History'
          onBackPress={this.goBack}
        />
        <View style={styles.container}>
          <EmptyState
            title='No orders found'
            buttonText='Login to see your orders'
            subtitle={`Place your first order on ${Config.APP_NAME}!`}
            image={images.emptyState.collection}
            onPress={this.renderOnboarding}
          />
        </View>
      </>
    );
  };

  shimmer = () => {
    return (
      <>
        <StaticNavigationHeader
          title='Order History'
          onBackPress={this.goBack}
        />
        <OrderHistoryShimmer />
      </>
    );
  };

  emptyState = () => {
    return (
      <>
        <StaticNavigationHeader
          title='Order History'
          onBackPress={this.goBack}
        />
        <View style={styles.container}>
          <EmptyState
            title='No orders found'
            buttonText='Order now'
            subtitle={`Place your first order on ${Config.APP_NAME}!`}
            image={images.emptyState.collection}
            onPress={this.navigateToStore}
          />
        </View>
      </>
    );
  };

  showNotificationModal = (showNotificationPrompt) => {
    console.tron.log('showNotificationModal', showNotificationPrompt);
    const { navigation } = this.props;
    navigation.push('NotificationModal', {
      showNotificationPrompt,
      type: NOTIFICATION_REQUEST_MODAL_TYPE.ORDER_HISTORY,
    });
  };

  displayNotificationRequestModal = () => {
    const { initial_app_opened_at, lastNotificationModalDisplayTime } =
      this.props;
    Utility.canDisplayNotificationRequestModal(
      lastNotificationModalDisplayTime,
      'orderHistory',
      (canDisplay, showNotificationPrompt) => {
        if (canDisplay) {
          this.showNotificationModal(showNotificationPrompt);
        }
      },
      initial_app_opened_at,
      true,
    );
  };

  toggleSelectOrderView = () => {
    this.setState((previousState) => ({
      isSelectOrderView: !previousState.isSelectOrderView,
    }));
  };

  onPressContactUs = () => {
    AnalyticsManager.logEvent(EventType.discoveryEvents.ITEM_ACTION, {
      [EventParameterKey.ITEM_TYPE]: 'Contact_us',
      [EventParameterKey.SCREEN_NAME]: SCREEN_CONSTANTS.ORDER_HISTORY,
    });
    if (AppConfig.getBooleanValue(Config.ENABLE_ORDER_PAGE_CONTACT_US_MODAL)) {
      ContactsUsUtility.onContactUsOptionPress();
      return;
    }
    const { navigation } = this.props;
    navigation.navigate('RequestCallBackPage', {
      headerTitle: 'Ask for a Callback',
      previousScreen: SCREEN_CONSTANTS.ORDER_HISTORY,
    });
  };

  contactUsCard = () => {
    const { orderHistoryData = [], isSelectOrderView } = this.state;
    if (orderHistoryData.length <= 1) return null;

    if (isSelectOrderView) {
      return (
        <View style={styles.contactUsStyle}>
          <View style={styles.contactUsContainerBlue}>
            <Text style={styles.heading}>
              Please select one order from below.
            </Text>
            <FoxyRoundedButton
              title={'Back'}
              onPress={this.toggleSelectOrderView}
              backgroundColor={colors.black}
              titleColor={colors.white}
              iconTintColor={colors.foxyRed}
              iconHeight={11}
              iconWidth={11}
            />
          </View>
        </View>
      );
    }
    return (
      <View style={styles.contactUsStyle}>
        <View style={styles.contactUsContainer}>
          <Text style={styles.heading}>Need help? We’re here for you</Text>
          <FoxyRoundedButton
            title={'Contact us'}
            onPress={this.toggleSelectOrderView}
            backgroundColor={colors.black}
            titleColor={colors.white}
            leftIcon={images.contactUsIcon}
            iconTintColor={colors.foxyRed}
            iconHeight={11}
            iconWidth={11}
          />
        </View>
      </View>
    );
  };

  goBack = () => {
    const { navigation } = this.props;
    const { isSelectOrderView } = this.state;
    if (isSelectOrderView) {
      this.toggleSelectOrderView();
      return;
    }
    if (this.goToTabNavigator) {
      navigation.replace('TabNavigator');
      return;
    }
    navigation.goBack();
  };

  render() {
    const {
      orderHistoryData = [],
      showShimmer,
      showLoader,
      isSelectOrderView,
    } = this.state;
    const { navigation, authToken } = this.props;

    if (Utility.isBlank(authToken)) {
      return <this.loginScreen />;
    }
    if (showShimmer) {
      return <this.shimmer />;
    }
    if (Utility.isBlank(orderHistoryData)) {
      return <this.emptyState />;
    }

    return (
      <>
        <StaticNavigationHeader
          title={isSelectOrderView ? 'Please select a order' : 'Order History'}
          onBackPress={this.goBack}
        />
        {this.showContactUsCard && <this.contactUsCard />}
        <OrderItems
          navigation={navigation}
          orderHistoryData={orderHistoryData}
          paginateOrderHistory={this.debouncedPaginateOrderHistory}
          showLoader={showLoader}
          isSelectOrderView={isSelectOrderView}
        />
      </>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.background,
  },
  contactUsStyle: {
    backgroundColor: colors.white,
    height: 74,
  },
  contactUsContainer: {
    width: Utility.getScreenWidth() - 24,
    padding: 12,
    alignItems: 'center',
    justifyContent: 'space-between',
    marginHorizontal: 12,
    alignSelf: 'center',
    borderRadius: 16,
    marginTop: 12,
    flexDirection: 'row',
    height: 52,
    backgroundColor: colors.lightBackgroundGreen,
  },
  contactUsContainerBlue: {
    width: Utility.getScreenWidth() - 24,
    padding: 12,
    alignItems: 'center',
    justifyContent: 'space-between',
    marginHorizontal: 12,
    alignSelf: 'center',
    borderRadius: 16,
    marginTop: 12,
    flexDirection: 'row',
    height: 52,
    backgroundColor: colors.prompt.borderBlue,
  },
  heading: {
    fontSize: 14,
    fontFamily: 'Roboto-Regular',
    color: colors.foxyBlack,
    lineHeight: 15,
    textAlign: 'center',
  },
  confirmationIcon: {
    height: 8,
    width: 8,
    backgroundColor: colors.darkGreen,
    borderRadius: 10,
    marginRight: 1,
  },
});

const mapStateToProps = function (state) {
  return {
    authToken: state.UserAccountInfo.authToken,
    lastNotificationModalDisplayTime:
      state.UserAccountInfo.lastNotificationModalDisplayTime,
    initial_app_opened_at: state.UserAccountInfo.initial_app_opened_at,
  };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      getOrdersHistory,
      setPhoneNumberModalInfo,
    },
    dispatch,
  ),
});

export default withNavigation(
  connect(mapStateToProps, mapDispatchToProps)(OrdersHistory),
);
