import {
  BASE_API_V2,
  WEB_URL,
  WWW_WEB_URL,
  HTTP_WWW_WEB_URL,
  LINK_DOMAIN,
  HTTP_WEB_URL,
  SHORT_WEB_URL,
  APP_INTENT_SCHEME,
  LISTS_API_VERSION,
} from '../config/Constants';
import Config from '../libraries/ReactNativeConfig';
import Utility from './Utility';

export default class DynamicLinkUtilities {
  static removeDomainName = (url) => {
    let slug = '';
    const params = {};
    let match;

    const regex = /[?&]([^=#]+)=([^&#]*)/g;

    if (Utility.isPresent(url) && url.includes('?')) {
      while ((match = regex.exec(url))) {
        params[match[1]] = match[2];
      }
      url = url.split('?')[0];
    }

    if (Utility.isPresent(url)) {
      if (url.includes(Config.APP_INTENT_SCHEME)) {
        slug = url.replace(Config.APP_INTENT_SCHEME, '');
      } else if (url.includes(Config.WWW_WEB_URL)) {
        slug = url.replace(Config.WWW_WEB_URL, '');
      } else if (url.includes(Config.HTTP_WWW_WEB_URL)) {
        slug = url.replace(Config.HTTP_WWW_WEB_URL, '');
      } else if (url.includes(Config.WEB_URL)) {
        slug = url.replace(Config.WEB_URL, '');
      } else if (url.includes(Config.LINK_DOMAIN)) {
        slug = url.replace(Config.LINK_DOMAIN, '');
      } else if (url.includes(Config.HTTP_WEB_URL)) {
        slug = url.replace(Config.HTTP_WEB_URL, '');
      } else if (url.includes(Config.HTTPS_DOMAIN_NAME)) {
        slug = url.replace(Config.HTTPS_DOMAIN_NAME, '');
      } else if (url.includes(Config.SHORT_WEB_URL)) {
        slug = url.replace(Config.SHORT_WEB_URL, '');
      }
    }

    return [slug, params];
  };

  static extractRouteFromSlug = (slug, url = '') => {
    let route = '';

    const arrSlugItem = slug.split('/');

    if (arrSlugItem.length >= 1) {
      route = arrSlugItem[1];
    }

    // If URL container xyz.com/<order_id>/orders/ it means its a shopify order and need to redirect to orders page
    const digitRegex = /\d+\/+orders/;
    if (digitRegex.test(route)) {
      route = 'orders';
    }

    if (route === 'brands' && slug.indexOf('categories') !== -1) {
      route = 'brand_categories';
    }

    if (Utility.isBlank(route) && !url.includes(Config.SHORT_WEB_URL)) {
      route = 'WebUrlView';
    }

    return route;
  };

  static generateSlugWithData = (path, currentSlug, params) => {
    let slugObject = { slug: currentSlug, extra: {} };
    if (path === 'youtube_videos' || path === 'foxy_videos') {
      slugObject = {
        slug: currentSlug,
        extra: {
          ...this.generateVideoItemDataFromSlug(currentSlug),
          externalActionRequired: true,
        },
      };
    } else if (path === 'free_items') {
      slugObject = this.extractFreeGiftInfo(currentSlug, params);
    } else if (path === 'add_to_bag') {
      slugObject = {
        slug: currentSlug,
        extra: { ...this.generateCartDataFromSlug(currentSlug) },
        externalActionRequired: true,
      };
    } else if (path === 'apply_coupon') {
      slugObject = this.extractCouponCodeFromSlug(currentSlug);
    } else if (path === 'payment_methods') {
      slugObject = this.extractParamsFromSlug(currentSlug, params);
    } else if (path === 'cart_items') {
      slugObject = this.extractParamsFromSlug(currentSlug, params);
    } else if (path === 'register_collab') {
      slugObject = this.generateUrlForBrandCollabDetails(currentSlug);
    } else if (path === 'campaigns' || path === 'campaign') {
      slugObject = { slug: `/api/v2${currentSlug}.json`, extra: {} };
    } else if (path === 'offers') {
      slugObject = {
        slug: `/api/${LISTS_API_VERSION}${currentSlug}.json`,
        extra: {},
      };
    } else if (path === 'products' || currentSlug.includes('variant=')) {
      slugObject = this.generateVariantDataFromSlug(currentSlug);
    } else if (path === 'youtube_offer') {
      slugObject = {
        slug: currentSlug,
        extra: {
          ...this.generateVideoItemDataFromSlug(currentSlug),
          externalActionRequired: true,
        },
      };
    } else if (path === 'tags') {
      slugObject = { slug: `/api/${LISTS_API_VERSION}${currentSlug}.json`, extra: {} };
    } else if (path === 'lists') {
      slugObject = { slug: `/api/${LISTS_API_VERSION}${currentSlug}.json`, extra: {} };
    } else if (path === 'categories') {
      slugObject = { slug: `/api/${LISTS_API_VERSION}${currentSlug}.json`, extra: { ...params } };
    } else if (path === 'salons') {
      slugObject = { slug: `/api/v1${currentSlug}.json`, extra: { ...params } };
    } else if (path === 'foxy_stores') {
      slugObject = {
        slug: `/store_api/v1${currentSlug}.json`,
        extra: { storeName: currentSlug },
      };
    } else if (path === 'routines') {
      slugObject = {
        slug: `${currentSlug.replace('/routines/', '')}`,
        extra: { ...params },
      };
    } else if (path === 'during_routine') {
      slugObject = {
        slug: `${currentSlug.replace('/during_routine/', '')}`,
        extra: { ...params },
      };
    } else if (path === 'media_playlist') {
      slugObject = {
        slug: `${currentSlug.replace('/media_playlist/', `/api/${LISTS_API_VERSION}/lists/`)}`,
        extra: { ...params },
      };
    } else if (path === 'brands') {
      slugObject = { slug: `/api/${LISTS_API_VERSION}${currentSlug}.json`, extra: { ...params } };
    } else if (path === 'personalized') {
      slugObject = { slug: `/api/${LISTS_API_VERSION}${currentSlug}.json`, extra: {} };
    } else if (path === 'generic_offers') {
      slugObject = {
        slug: `/api/${LISTS_API_VERSION}${currentSlug?.replace('generic_offers', 'offers')}.json`,
        extra: { ...params },
      };
    } else {
      slugObject = { slug: `/api/v2${currentSlug}.json`, extra: { ...params } };
    }
    return slugObject;
  };

  static extractCouponCodeFromSlug = (slug, params) =>
    // Dynamic link structure  Https://foxy.in/apply_coupon/<coupon_code>
    // Slug will be /apply_coupon/<coupon_code>
    ({ slug, extra: { promoCodeThroughDynamicLink: slug.split('/')[2] } });

  static extractParamsFromSlug = (slug, params) => ({ slug, extra: params });

  static generateUrlForBrandCollabDetails = (slug) => {
    // api for brand collab is /v2/campaigns/<ID> and our slug is v2/register_collab/<ID> . in this case we case use existing api
    const str = slug.replace('register_collab', 'campaigns');
    return {
      slug: `/api/${BASE_API_V2}${str}.json`,
      extra: { registerCollabThroughDeeplink: true },
    };
  };

  static generateCartDataFromSlug = (slug) => {
    //  Slug structure will be /<skuId>/<quantity>
    const cartData = slug.substring(1, slug.length).split('/');
    return { sku_id: cartData[1], quantity: cartData[2] || 1 };
  };

  static generateVariantDataFromSlug = (slug = '') => {
    const extractQueryParams = slug?.split('=');
    return {
      slug: `/api/v1${slug?.split('?')[0]}.json`,
      extra: {
        variant: extractQueryParams[1],
      },
    };
  };

  static extractFreeGiftInfo = (slug, params) => {
    const extractQueryParams = slug.split('?');
    return {
      slug: `/api/v1${extractQueryParams[0].replace(
        'free_items',
        'lists',
      )}.json`,
      extra: {
        itemsToBeSelect: params.items,
      },
    };
  };

  static generateVideoItemDataFromSlug = (slug) => {
    //Slug Structure  : /api/v1/youtube_videos/listId/videoId
    let itemToRemove = '/youtube_videos/';
    if (slug.includes('foxy_videos')) {
      itemToRemove = '/foxy_videos/';
    } else if (slug.includes('youtube_offer')) {
      itemToRemove = '/youtube_offer/';
    }

    const removedV1FromSlug = slug.replace(itemToRemove, '');
    const extractListId_VideoId = removedV1FromSlug.split('/');
    if (slug.includes('youtube_offer')) {
      return {
        videoSlug: extractListId_VideoId?.[0],
        listId: 0,
        couponCode: extractListId_VideoId?.[1]?.replace('.json', '') || 0,
      };
    }
    return {
      listId: extractListId_VideoId?.[0],
      videoId: extractListId_VideoId?.[1]?.replace('.json', '') || 0,
    };
  };
}
