import React, { PureComponent } from 'react';
import { View, StyleSheet, Text, Image } from 'react-native';
import { FlatList } from 'react-native-gesture-handler';
import NavigationService from '../../navigator/NavigationService';
import {
  AnalyticsManager,
  EventParameterKey,
  EventParameterValue,
  EventType,
} from '../../analytics';
import { REMOTE_CONFIG_KEYS } from '../../config/Constants';
import ReferralProductCard from '../../containers/inviteCenter/ReferralProductCard';
import colors from '../../theme/Colors';
import size from '../../theme/Fonts';
import images from '../../theme/Images';
import RemoteConfig from '../../utils/RemoteConfig';
import Utility from '../../utils/Utility';
import DebouncedTouchableOpacity from '../shared/DebouncedTouchableOpacity';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import ContactsUtility from '../../utils/ContactsUtility';
import FastImageView from '../FastImageView';
import ModalContainerWithoutSafeArea from './ModalContainerWithoutSafeArea';
import { getMinifiedImage } from '../../utils/ImageUtility';
import { FREE_GIFT_DATA } from '../../containers/inviteCenter/Constants';

export default class InviteToClaimModal extends PureComponent {
  constructor(props) {
    super(props);
    const { route } = props;
    const {
      params: {
        productData: {
          sku: { group_buying_price: groupPrice = '' } = {},
          mrp,
        } = FREE_GIFT_DATA,
      } = {},
    } = route;

    this.fetchRemoteConfigStrings();
    this.discount = ContactsUtility.calculateDiscountPercentage(
      groupPrice,
      mrp,
    );
    this.logModalViewEvent(true);
  }

  fetchRemoteConfigStrings() {
    const { route } = this.props;
    const {
      params: {
        productData: {
          sku: { required_group_size: groupSize = '' } = {},
        } = FREE_GIFT_DATA,
        referralModalType = '',
      } = {},
    } = route;
    const {
      group_deal_popup_modal: groupDealModalSteps = '',
      referral_popup_modal: referralModalSteps = '',
      popup_modal_title_subtitles: modalTitleSubtitle = '',
      see_how_popup_modal: seeHowModalSteps = '',
    } = Utility.jsonParser(
      RemoteConfig.getValue(REMOTE_CONFIG_KEYS.group_deals_contacts_strings),
    );

    switch (referralModalType) {
      case 'SEE_HOW_MODAL':
        this.stepsToShow = seeHowModalSteps;
        this.headerStrings = modalTitleSubtitle.referral_see_how;
        break;
      case 'GROUP_DEAL_MODAL':
        this.stepsToShow = [
          groupDealModalSteps[0]?.replace(
            '$required_group_size',
            `${groupSize}`,
          ),
          ...groupDealModalSteps?.slice(1),
        ];
        this.headerStrings = modalTitleSubtitle.group_deal;
        break;
      default:
        this.stepsToShow = referralModalSteps;
        this.headerStrings = modalTitleSubtitle.referral;
    }
  }

  hideModal = () => {
    NavigationService.goBack();
    this.logModalViewEvent(false);
  };

  isLastStep = (index) => index + 1 === this.stepsToShow?.length;

  RenderPoint = ({ index }) => {
    const style = this.isLastStep(index)
      ? styles.currentPointStyle
      : styles.pointStyle;
    return (
      <View style={style}>
        <View style={styles.point} />
      </View>
    );
  };

  getModalName = () => {
    const { route } = this.props;
    const { params: { referralModalType = '' } = {} } = route;
    switch (referralModalType) {
      case 'SEE_HOW_MODAL':
        return 'see_how_modal';
      case 'GROUP_DEAL_MODAL':
        return 'group_deal_modal';
      default:
        return 'referral_modal';
    }
  };

  fireModalActionEven = (cta) => {
    AnalyticsManager.logEvent(EventType.MODAL_ACTION, {
      [EventParameterKey.MODAL_NAME]: this.getModalName(),
      [EventParameterKey.CTA]: cta,
    });
  };

  onPressInviteContacts = () => {
    const { route } = this.props;
    const {
      params: {
        productData = FREE_GIFT_DATA,
        productData: {
          sku: {
            required_group_size: groupSize = '',
            id: skuId = '',
            group_buying_price: groupPrice = '',
          } = {},
          mrp,
        } = FREE_GIFT_DATA,
      } = {},
    } = route;

    this.fireModalActionEven(
      EventParameterValue.GROUP_DEALS_REFERRALS.INVITE_CONTACTS,
    );

    this.hideModal();

    setTimeout(() => {
      NavigationService.navigate('GroupDealContacts', {
        skuId,
        groupPrice,
        discount: `${this.discount}% off`,
        mrp,
        productData,
        requiredGroupSize: groupSize,
      });
    }, 400);
  };

  RenderDashedLine = ({ index }) => {
    if (index + 1 === this.stepsToShow?.length) return null;
    return <Image style={styles.horizontalLine} source={images.dashedLine} />;
  };

  renderSteps = ({ item, index }) => {
    const textStyle = this.isLastStep(index)
      ? styles.listItemTextBold
      : styles.listItemText;
    return (
      <View>
        <View style={styles.listItemContainer}>
          <this.RenderPoint index={index} />
          <Text style={textStyle}>{item}</Text>
        </View>
        <this.RenderDashedLine index={index} />
      </View>
    );
  };

  keyExtractor = (item, index) => index;

  logModalViewEvent = (isModalVisible) => {
    const { route } = this.props;
    const {
      params: {
        productData: {
          sku: {
            required_group_size: groupSize = '',
            id: skuId = '',
            group_buying_price: groupPrice = '',
          } = {},
        } = FREE_GIFT_DATA,
        previousScreen,
      } = {},
    } = route;

    const event = isModalVisible
      ? EventType.order.MODAL_VIEW
      : EventType.order.MODAL_CLOSE;

    AnalyticsManager.logEvent(event, {
      [EventParameterKey.MODAL_NAME]: this.getModalName(),
      [EventParameterKey.SKU_ID]: skuId,
      [EventParameterKey.GROUP_DEAL_SIZE]: groupSize,
      [EventParameterKey.GROUP_DEAL_PRICE]: groupPrice,
      previous_screen: previousScreen
        ? previousScreen
        : SCREEN_CONSTANTS.PERSONALISED_HORIZONTAL,
    });
  };

  productCard = (props) => {
    const { productData = FREE_GIFT_DATA, referralModalType = '' } = props;
    if (referralModalType === 'SEE_HOW_MODAL') return <this.SeeHowBanner />;
    return (
      <View style={styles.productCard}>
        <ReferralProductCard
          item={productData}
          navigation={NavigationService}
          setModalVisible={this.setModalVisible}
          previousScreen={SCREEN_CONSTANTS.INVITE_TO_CLAIM_MODAL}
        />
      </View>
    );
  };

  SeeHowBanner = () => {
    const {
      popup_modal_title_subtitles: {
        referral_see_how: { banner_text: bannerText = '' } = {},
      } = {},
    } = Utility.jsonParser(
      RemoteConfig.getValue(REMOTE_CONFIG_KEYS.group_deals_contacts_strings),
    );
    return (
      <View style={styles.seeHowBanner}>
        <FastImageView
          source={getMinifiedImage(
            images.coins,
            styles.seeHowBannerImage.width,
            styles.seeHowBannerImage.height,
          )}
          style={styles.seeHowBannerImage}
          resizeMode={'stretch'}
        />
        <Text style={styles.seeHowBannerText}>{bannerText}</Text>
      </View>
    );
  };

  InviteContactsButton = () => {
    return (
      <DebouncedTouchableOpacity
        style={styles.buttonContainer}
        onPress={this.onPressInviteContacts}
      >
        <Text style={styles.buttonText}>Invite contacts</Text>
      </DebouncedTouchableOpacity>
    );
  };

  openSeeHowModal = () => {
    this.fireModalActionEven(EventParameterValue.GROUP_DEALS_REFERRALS.SEE_HOW);
    const { route } = this.props;
    const {
      params: { productData = FREE_GIFT_DATA, previousScreen = '' } = {},
    } = route;
    NavigationService.navigate('InviteToClaimModal', {
      productData,
      previousScreen,
      referralModalType: 'SEE_HOW_MODAL',
    });
  };

  ReferralSeeHowButton = ({ referralModalType }) => {
    if (referralModalType !== '') return null;
    return (
      <DebouncedTouchableOpacity
        style={styles.seeHowButtonContainer}
        onPress={this.openSeeHowModal}
      >
        <Text style={styles.seeHowButtonText}>See How</Text>
      </DebouncedTouchableOpacity>
    );
  };

  setModalVisible = (isVisible) => {};

  render() {
    const { route } = this.props;
    const {
      params: { productData = FREE_GIFT_DATA, referralModalType = '' } = {},
    } = route;
    return (
      <>
        <ModalContainerWithoutSafeArea>
          <View style={styles.bottomModal}>
            <Text style={styles.modalHeading}>
              {this.headerStrings.heading}
            </Text>
            <Text style={styles.modalSubheading}>
              {this.headerStrings.subheading}
            </Text>
            <this.productCard
              productData={productData}
              setModalVisible={this.setModalVisible}
              referralModalType={referralModalType}
            />
            <FlatList
              style={styles.listContainer}
              data={this.stepsToShow}
              renderItem={this.renderSteps}
              keyExtractor={this.keyExtractor}
            />
            <this.ReferralSeeHowButton
              referralModalType={referralModalType}
              setModalVisible={this.setModalVisible}
            />
            <this.InviteContactsButton />
            <View style={styles.footerTextContainer}>
              <Text style={styles.footerText}>Please click </Text>
              <Text style={styles.footerTextBold}>'allow' </Text>
              <Text style={styles.footerText}>
                on the contact book permission screen
              </Text>
            </View>
          </View>
        </ModalContainerWithoutSafeArea>
      </>
    );
  }
}

const styles = StyleSheet.create({
  footerTextContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 10,
    marginBottom: 12,
    width: '100%',
  },

  footerText: {
    color: colors.black,
    fontSize: size.h4,
    fontFamily: 'Roboto-Regular',
    textAlign: 'center',
  },

  footerTextBold: {
    color: colors.black,
    fontSize: size.h4,
    fontFamily: 'Roboto-Bold',
    textAlign: 'center',
  },

  buttonContainer: {
    backgroundColor: colors.black,
    borderRadius: 4,
    height: 40,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonText: {
    fontSize: size.h2,
    fontFamily: 'Roboto-Medium',
    color: colors.white,
  },

  point: {
    width: 6,
    height: 6,
    backgroundColor: colors.black,
    borderRadius: 3,
  },

  horizontalLine: {
    height: 40,
    width: 2,
    left: 4,
    marginTop: -12,
    marginBottom: -12,
  },

  listContainer: {
    marginTop: 24,
    marginBottom: 24,
  },

  listItemText: {
    fontSize: size.h3,
    fontFamily: 'Roboto-Regular',
    color: colors.black,
    marginLeft: 12,
  },
  listItemTextBold: {
    fontSize: size.h3,
    fontFamily: 'Roboto-Bold',
    color: colors.black,
    marginLeft: 15,
  },

  listItemContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },

  pointStyle: {
    borderWidth: 1,
    height: 12,
    width: 12,
    borderRadius: 6,
    alignItems: 'center',
    justifyContent: 'center',
    borderColor: colors.white,
  },

  currentPointStyle: {
    borderWidth: 1,
    height: 12,
    width: 12,
    borderRadius: 6,
    alignItems: 'center',
    justifyContent: 'center',
    borderColor: colors.black,
  },

  bottomModal: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    marginBottom: 0,
    marginRight: 0,
    marginLeft: 0,
    paddingHorizontal: 12,
    paddingVertical: 24,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    overflow: 'hidden',
    backgroundColor: colors.white,
    borderTopLeftRadius: 25,
    borderTopRightRadius: 25,
  },

  modalHeading: {
    color: colors.black,
    paddingHorizontal: 12,
    fontSize: size.h1,
    fontFamily: 'Roboto-Bold',
  },
  modalSubheading: {
    color: colors.black,
    paddingHorizontal: 12,
    fontSize: size.h3,
    fontFamily: 'Roboto-Regular',
  },
  productCard: {
    marginLeft: 12,
  },
  seeHowButtonContainer: {
    backgroundColor: colors.white,
    borderRadius: 4,
    height: 40,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: colors.black,
    marginBottom: 12,
  },
  seeHowButtonText: {
    fontSize: size.h2,
    fontFamily: 'Roboto-Medium',
    color: colors.black,
  },
  seeHowBanner: {
    height: 70,
    width: Utility.getScreenWidth(),
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginTop: 24,
    backgroundColor: colors.referrals.golden,
    marginLeft: -12,
    paddingHorizontal: 12,
  },
  seeHowBannerText: {
    fontSize: size.h3,
    color: colors.foxyBlack,
    paddingRight: 12,
    fontFamily: 'Roboto-Regular',
    width: '80%',
  },
  seeHowBannerImage: {
    height: 62,
    width: 62,
  },
});
