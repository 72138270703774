import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import Barcode from '@kichiyaki/react-native-barcode-generator';
import { useNavigation } from '@react-navigation/native';
import NavigationService from '../../navigator/NavigationService';
import colors from '../../theme/Colors';
import size from '../../theme/Fonts';
import { isBlank, isPresent } from '../../utils/BooleanUtility';
import { getScreenWidth } from '../../utils/LayoutUtility';
import FoxyButton from '../shared/FoxyButton';
import { useSelector } from 'react-redux';
import FastImageView from '../FastImageView';
import { getDefaultAddress, validateAddress } from '../../utils/AddressUtility';
import Config from '../../libraries/ReactNativeConfig';


export const BulletPoint = ({ text = '', style = {} }) => {
  const styleBullet = { ...styles.bullet, ...style };
  return (
    <View style={styles.row}>
      <Text style={styleBullet}>{'\u25CF  '}</Text>
      <Text style={styleBullet}>{text}</Text>
    </View>
  );
};

const navigateToOnboarding = () => {
  NavigationService.renderOnboarding({
    addressBeforeLogin: true,
    furtherAction: navigateToRewards,
  });
};

const navigateToRewards = () => {
  NavigationService.navigate(Config.REWARDS_PAGE_ROUTE);
};

const navigateToAddress = (navigation) => () => {
  navigation.navigate('Address', {
    addressBeforeLogin: true,
    proceedTo: Config.REWARDS_PAGE_ROUTE,
  });
};

const CustomerID = ({ itemData }) => {
  const navigation = useNavigation();
  const authToken = useSelector((state) => state.UserAccountInfo.authToken);
  const mobileNumber = useSelector((state) => state.UserAccountInfo.mobileNumber);
  const callingCode = useSelector((state) => state.UserAccountInfo.callingCode);
  const addresses = useSelector((state) => state.UserAccountInfo.addresses);
  const defaultAddress = getDefaultAddress(addresses);
  const hasValidAddress = validateAddress(defaultAddress);
  let onActionPress = navigateToOnboarding;
  if (!hasValidAddress && isPresent(authToken)) {
    onActionPress = navigateToAddress(navigation);
  }
  const { title, image_url, background_color: backgroundColor } = itemData;
  if (isBlank(authToken) || !hasValidAddress) {
    return (
      <View style={[styles.container, { backgroundColor }]}>
        <Text style={styles.title}>{title}</Text>
        <Text style={styles.subtitle}>Register now to access {Config.APP_NAME} pass</Text>
        <FastImageView source={image_url} style={styles.slimImage} resizeMode='contain' />
        <View style={styles.buttonContainer}>
          <FoxyButton
            title={'Register'}
            actionButtonColor={colors.foxyGreen}
            onActionPress={onActionPress}
          />
        </View>
      </View>
    );
  }
  const pureNumber = mobileNumber.replace(`+${callingCode}`, '');
  return (
    <View style={styles.containerAuthorized}>
      <Text style={styles.title}>Your {Config.APP_NAME} Pass</Text>
      <Text style={styles.subtitle}>Show this barcode at store checkout to earn rewards</Text>
      <Barcode value={pureNumber} format="CODE128" style={styles.barcode} />
      <Text style={styles.value}>{pureNumber}</Text>
    </View>
  );
};

export default CustomerID;

const styles = StyleSheet.create({
  containerAuthorized: {
    paddingVertical: 4,
    paddingHorizontal: 24,
    backgroundColor: colors.white,
    justifyContent: 'center',
    marginVertical: 12,
  },
  container: {
    paddingVertical: 4,
    paddingHorizontal: 24,
    backgroundColor: colors.white,
    justifyContent: 'center',
    marginVertical: 12,
  },
  title: {
    color: colors.black,
    marginBottom: 4,
    fontFamily: 'Roboto-Bold',
    fontSize: size.h1,
  },
  subtitle: {
    color: colors.black,
    marginBottom: 12,
    fontFamily: 'Roboto',
    fontSize: size.h3,
  },
  barcode: {
    marginTop: 12,
  },
  value: {
    color: colors.black,
    alignSelf: 'center',
    marginTop: 4,
    fontFamily: 'Roboto',
    fontSize: size.h3,
  },
  bullet: {
    color: colors.black,
    marginTop: 12,
    fontFamily: 'Roboto',
    fontSize: size.h3,
  },
  row: {
    flexDirection: 'row',
  },
  buttonContainer: {
    height: 72,
    width: getScreenWidth() - 48,
    backgroundColor: colors.white,
    justifyContent: 'center',
    marginBottom: -8,
  },
  slimImage: {
    height: (1 / 3) * (getScreenWidth() - 24),
    overflow: 'hidden',
    borderColor: colors.border,
    backgroundColor: 'transparent',
    width: getScreenWidth(),
    marginLeft: -24,
  },
});