import { isPresent, isWeb } from './BooleanUtility';
import { isInStock } from './ProductUtility';
import Utility from './Utility';

export default class VariantUtility {
  static checkIfMultiVariant(multiVariantAttributes) {
    return (
      Utility.isPresent(multiVariantAttributes) &&
      Utility.isPresent(
        multiVariantAttributes[0]?.multi_level_variant_attributes,
      )
    );
  }

  static getParentVariantForChildVariantId(variantId, multiVariantAttributes) {
    return (
      multiVariantAttributes.filter(
        VariantUtility.filterParentAttributes(variantId),
      )?.[0] || {}
    );
  }

  static filterParentAttributes = (variantId) => (attribute) => {
    const { multi_level_variant_attributes: multiVariantAttributes = [] } =
      attribute || {};
    return Utility.isPresent(multiVariantAttributes)
      ? Utility.isPresent(
          multiVariantAttributes.filter(
            VariantUtility.filterParentAttributes(variantId),
          )?.[0],
        ) || false
      : VariantUtility.checkIfAttributeIsPresent(variantId)(attribute) || false;
  };

  static checkIfAttributeIsPresent = (variantId) => (attribute) => {
    return attribute.variant?.sku_id === variantId;
  };

}

export const findSkuIdByVariantId = (attributes = [], variantId) => {
  for (const attr of attributes) {
    if (attr.variant && attr.variant.id === +variantId) {
      return attr.variant.sku_id;
    }
    if (isPresent(attr.multi_level_variant_attributes)) {
      const result = findSkuIdByVariantId(attr.multi_level_variant_attributes, +variantId);
      if (result) {
        return result;
      }
    }
  }
  return null;
};

export const getMultiVariantOosConfig = (multiVariantAttrs = []) => {
  const result = { oos: true };
  multiVariantAttrs.forEach(({ value, multi_level_variant_attributes = [], variant }) => {
    if (isPresent(multi_level_variant_attributes)) {
      result[value] = getMultiVariantOosConfig(multi_level_variant_attributes);
      if (!result[value].oos) {
        result.oos = false;
      }
    } else {
      const isOutOfStock = isWeb() ? variant.outOfStock && !isInStock(variant.web_stocked_status) : variant.outOfStock;
      result[value] = {
        skuId: variant.sku_id,
        oos: isOutOfStock,
      };
      if (!isOutOfStock) {
        result.oos = false;
      }
    }
  });
  return result;
};
