import React from 'react';
import { Image, Text, View, BackHandler } from 'react-native';
import CardStack, { Card } from 'react-native-card-stack-swiper';
import StyleSheet from 'react-native-media-query';
import _ from 'lodash';
import Toast from 'react-native-easy-toast';
import LinearGradient from 'react-native-linear-gradient';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import WishlistBuilderProductCard from '../../components/Product/WishlistBuilderProductCard';
import {
  like,
  swipedLeftOrRightWishlistBuilder,
} from '../../actions/ActionTypes';
import EmptyState from '../../components/layout/emptyState/Emptystate';
import Utility from '../../utils/Utility';
import images from '../../theme/Images';
import Config from '../../libraries/ReactNativeConfig';
import { AnalyticsManager } from '../../analytics';
import { CartStyles } from '../../components/cart/styles';
import colors from '../../theme/Colors';
import { CustomNavigationHeader } from '../../components/Product';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import DockedHeader from '../../utils/DockedHeader';
import { goBackWithFallbackHome, navigateToScreen } from '../../utils/NavigationUtility';
import AppConfig from '../../config/AppConfig';
import { getScreenWidth } from '../../utils/LayoutUtility';
import { WishlistAsBottomTab } from '../../config/Constants';

class SwipableCards extends React.Component {
  constructor(props) {
    super(props);

    const { route } = props;

    this.outputRotationRange = ['-45deg', '0deg', '45deg'];

    this.listData = route.params?.listData || {};
    this.previousScreen = route.params?.previousScreen || '';
    this.navigateToSearch = route.params?.navigateToSearch || '';
    const { objects = [] } = this.listData;

    const { swipedLeftOrRightWishlist = [] } = this.props;

    this.wishlist = _.filter(
      objects,
      (e) => !swipedLeftOrRightWishlist.includes(e.id.toString()),
    );

    this.firstImage = [0, ...objects];

    this.newArray = swipedLeftOrRightWishlist.includes('1')
      ? this.wishlist
      : this.firstImage;
    if (Utility.isAndroid()) {
      this.backhandler = BackHandler.addEventListener(
        'hardwareBackPress',
        this.backAction,
      );
    }
  }

  updateLikedState = (id, type, sku_id) => {
    const { like } = this.props;
    const data = {
      id,
      type,
      sku_id,
    };
    like(data);
  };

  toastRef = (ref) => {
    this.toast = ref;
  };

  showToast = (message, duration = 1000) => {
    this.toast.show(message, duration);
  };

  navigateToViewWishlist = () => {
    const { navigation, authorized } = this.props;
    const profileMenuItems = AppConfig.getProfileMenuItems(authorized);
    if (!profileMenuItems.includes('my_kit')) {
      const wishlistScreenName = WishlistAsBottomTab
        ? SCREEN_CONSTANTS.WISHLIST
        : SCREEN_CONSTANTS.TODAY_DEALS;
      navigateToScreen({
        navigation,
        type: 'navigate',
        screen: wishlistScreenName,
      });
      return;
    }
    navigation.push('MyCollection');
  };

  wishlistBuilderEmptyState = () => {
    const image = Utility.isPresent(Config.WISHLIST_EMPTY_IMAGE)
      ? { uri: Config.WISHLIST_EMPTY_IMAGE }
      : images.empty_wishlist;
    return (
      <EmptyState
        title="That’s it"
        subtitle="Come back tomorrow for more items"
        buttonText="View my wishlist"
        image={image}
        onPress={this.navigateToViewWishlist}
      />
    );
  };

  onCardSwipeLeft = (item, index) => {
    const { swipedLeftOrRightWishlistBuilder } = this.props;
    AnalyticsManager.logEvent('list_item_swipe', {
      direction: 'left',
      sku_id: item.id,
    });
    if (index === this.listData?.objects?.length - 1) {
      swipedLeftOrRightWishlistBuilder('list_end');
      return;
    }
    swipedLeftOrRightWishlistBuilder(item?.id?.toString());
  };

  onCardSwipeRight = (item, index, type) => {
    const { swipedLeftOrRightWishlistBuilder } = this.props;
    this.updateLikedState(item.id, type, item.sku_id);
    AnalyticsManager.logEvent('list_item_swipe', {
      direction: 'right',
      sku_id: item?.id,
    });
    this.showToast('Added to wishlist');
    if (index === this.listData?.objects?.length - 1) {
      swipedLeftOrRightWishlistBuilder('list_end');
      return;
    }
    swipedLeftOrRightWishlistBuilder(item?.id?.toString());
  };

  goBack = () => {
    const { navigation } = this.props;
    const wishlistScreenName = Config.TABS.includes('Wishlist')
      ? SCREEN_CONSTANTS.WISHLIST
      : SCREEN_CONSTANTS.TODAY_DEALS;
    goBackWithFallbackHome(navigation, wishlistScreenName);
  };

  onCardSwipeRightTutorial = (item, index) => {
    AnalyticsManager.logEvent('list_item_swipe', {
      direction: 'right',
      sku_id: item.id,
    });
  };

  renderItem = (item, index) => {
    if (item === 0) {
      return (
        <Card
          onSwipedRight={() =>
            this.onCardSwipeRightTutorial({ id: '1' }, index)
          }
          onSwipedLeft={() => this.onCardSwipeRightTutorial({ id: '1' }, index)}
        >
          <LinearGradient
            style={styles.tutorialContainer}
            start={{ x: 0, y: 0 }}
            end={{ x: 1, y: 0 }}
            colors={['#FF3D6BD6', '#FF4F32D6']}
            dataSet={{ media: ids.tutorialContainer }}
          >
            <Image
              source={images.ic_swipe}
              style={styles.tutorialImage}
              resizeMode="contain"
            />
            <Text style={styles.tutorialTitle}>
              Build your wishlist the fun way
            </Text>
            <Text style={styles.tutorialSubtitle}>
              Swipe right to add a product to your wishlist and left to dismiss
            </Text>
            <Text style={styles.tutorialSubtitle}>
              Insider tip - Items from your wishlist have a higher probability
              of coming in your flash deals
            </Text>
          </LinearGradient>
        </Card>
      );
    }
    return (
      <Card
        style={{ flex: 1 }}
        onSwipedRight={() => this.onCardSwipeRight(item, index, item.type)}
        onSwipedLeft={() => this.onCardSwipeLeft(item, index)}
      >
        <WishlistBuilderProductCard
          layout={'wishlistBuilder'}
          itemData={item}
          noOfItems={(this.itemData || []).length}
          key={item?.id || index}
          previousScreen={this.previousScreen}
        />
      </Card>
    );
  };

  backAction = () => {
    const { navigation } = this.props;
    navigation.goBack();
    return true;
  };

  componentWillUnmount() {
    if (Utility.isAndroid()) {
      this.backhandler.remove();
    }
  }

  render() {
    return (
      <View style={styles.container}>
        <CustomNavigationHeader
          content={this.itemData}
          goBack={this.goBack}
          id={'wishlist_builder'}
          showSearchButton={true}
          showShareButton={false}
          onSearchIconTap={this.navigateToSearch}
          utmCampaign={'whishlist_builder'}
          screenName={SCREEN_CONSTANTS.WISHLIST_BUILDER}
        >
          <DockedHeader name="Wishlist builder" />
        </CustomNavigationHeader>
        <CardStack
          ref={(swiper) => {
            this.swiper = swiper;
          }}
          style={styles.content}
          horizontalThreshold={Utility.getScreenWidth() / 50}
          renderNoMoreCards={this.wishlistBuilderEmptyState}
          verticalSwipe={false}
          secondCardZoom={0.9}
          outputRotationRange={this.outputRotationRange}
        >
          {this.newArray.map(this.renderItem)}
        </CardStack>
        <Toast style={CartStyles.toast} ref={this.toastRef} />
      </View>
    );
  }
}

const { styles, ids } = StyleSheet.create({
  container: {
    alignSelf: 'center',
    height: 650,
    flex: 1,
    backgroundColor: 'transparent',
  },
  content: {
    flex: 5,
    alignItems: 'center',
    justifyContent: 'center',
    elevation: 7,
    shadowColor: colors.foxyBlack,
    shadowOffset: { width: 0, height: 3 },
    shadowOpacity: 0.29,
    shadowRadius: 4.65,
  },
  card: {
    width: 320,
    height: 580 + 107 - 34,
    backgroundColor: '#FE474C',
    borderRadius: 5,
    shadowColor: 'rgba(0,0,0,0.5)',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.5,
  },
  card1: {
    backgroundColor: '#fff',
  },
  card2: {
    backgroundColor: '#FEB12C',
  },
  label: {
    lineHeight: 400,
    textAlign: 'center',
    fontSize: 55,
    fontFamily: 'System',
    color: '#ffffff',
    backgroundColor: 'transparent',
  },
  footer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonContainer: {
    width: 220,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  button: {
    shadowColor: 'rgba(0,0,0,0.3)',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.5,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 0,
  },
  orange: {
    width: 55,
    height: 55,
    borderWidth: 6,
    borderColor: 'rgb(246,190,66)',
    borderRadius: 55,
    marginTop: -15,
  },
  green: {
    width: 75,
    height: 75,
    backgroundColor: '#fff',
    borderRadius: 75,
    borderWidth: 6,
    borderColor: '#01df8a',
  },
  red: {
    width: 75,
    height: 75,
    backgroundColor: '#fff',
    borderRadius: 75,
    borderWidth: 6,
    borderColor: '#fd267d',
  },
  tutorialContainer: {
    width: Utility.getScreenWidth() - 24,
    height: 580 + 107 - 34,
    backgroundColor: '#FF3D6BD6',
    borderRadius: 8,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    paddingHorizontal: 16,
    '@media (min-width: 768px)': {
      width: getScreenWidth() / 2.5,
    },
  },
  tutorialImage: { width: 120, height: 120, marginBottom: 130 },
  tutorialTitle: {
    fontSize: 20,
    color: '#fff',
    fontFamily: 'Roboto-Bold',
    textAlign: 'center',
    marginBottom: 16,
  },
  tutorialSubtitleOne: {
    fontSize: 12,
    color: '#fff',
    fontFamily: 'Roboto-Medium',
    textAlign: 'center',
    marginBottom: 16,
  },
  tutorialSubtitle: {
    fontSize: 12,
    color: '#fff',
    fontFamily: 'Roboto-Medium',
    textAlign: 'center',
    marginBottom: 16,
  },
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      like,
      swipedLeftOrRightWishlistBuilder,
    },
    dispatch,
  ),
});

const mapStateToProps = (store) => {
  return {
    swipedLeftOrRightWishlist: store.bag.swipedLeftOrRightWishlist,
    authorized: store.UserAccountInfo.authorized,
  };
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(SwipableCards);
