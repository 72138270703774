import { StyleSheet, Text, View } from 'react-native';
import React from 'react';
import ShimmerPlaceHolder from '../../libraries/ReactNativeShimmerPlaceholder';
import colors from '../../theme/Colors';
import { StaticNavigationHeader } from '../../components/shared';
import Utility from '../../utils/Utility';

const OfferWalletCard = () => {
  return (
    <View style={styles.offerWalletCard}>
      <ShimmerPlaceHolder
        autoRun
        style={styles.couponContainer}
        colorShimmer={['#fafafa', '#eeeeee', '#fafafa']}
      />
      <ShimmerPlaceHolder
        autoRun
        style={styles.circle}
        colorShimmer={['#fafafa', '#eeeeee', '#fafafa']}
      />
    </View>
  );
};

const MyWalletShimmer = ({ hideHeader }) => {
  return (
    <View style={styles.myWalletShimmer}>
      {!hideHeader && <StaticNavigationHeader title='' bgColor={colors.white} skipDivider />}
      <View style={styles.shimmerContainer}>
        {[...Array(12)].map((_, index) => (
          <OfferWalletCard key={index} />
        ))}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  couponContainer: {
    backgroundColor: '#FFE4F4',
    width: Utility.getScreenWidth() - 56,
    height: 100,
    borderRadius: 16,
    paddingVertical: 8,
    justifyContent: 'space-between',
  },

  circle: {
    height: 56,
    width: 56,
    borderRadius: 28,
    position: 'absolute',
    top: 22,
    left: -28,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'red',
  },
  myWalletShimmer: { flex: 1, backgroundColor: colors.white },
  shimmerContainer: { alignSelf: 'center', paddingLeft: 24, paddingTop: 152 },
});

export default MyWalletShimmer;
