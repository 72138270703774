import React, { memo, useState } from 'react';
import { Image, ImageBackground } from 'react-native';
import FastImage from '../libraries/ReactNativeFastImage';
import { isBlank, isPresent, isWeb } from '../utils/BooleanUtility';
import Config from '../libraries/ReactNativeConfig';
import images from '../theme/Images';
import Utility from '../utils/Utility';
import { useIsComponentMounted } from '../lib/hooksUtil';
import ShimmerPlaceHolder from '../libraries/ReactNativeShimmerPlaceholder';

const colorShimmer = ['#fafafa', '#eeeeee', '#fafafa'];

const FastImageView = (props) => {
  const {
    source = '',
    children,
    visible = true,
    enableProfilePicPlaceholder = false,
    onError = () => {},
    showLoader = false,
  } = props;

  const [isLoading, setIsLoading] = useState(showLoader);
  const [useFallbackUrl, setUseFallbackUrl] = useState(false);
  const isComponentMounted = useIsComponentMounted();

  const profileEmptyImage = isPresent(Config.PROFILE_HEADER_IMAGE)
    ? { uri: Config.PROFILE_HEADER_IMAGE }
    : images.profileEmptyState;

  let imageUrl = {};
  if (source && typeof source === 'object') {
    imageUrl = source;
  } else if (isBlank(source) && enableProfilePicPlaceholder) {
    imageUrl = profileEmptyImage;
  } else {
    imageUrl = { uri: `${source}` };
  }
  if (useFallbackUrl) {
    imageUrl = { uri: Utility.getImageFallbackUrlFromCdn2(imageUrl.uri) };
  }

  const onImageError = () => {
    if (isComponentMounted.current) {
      setUseFallbackUrl(true);
    }
    onError();
  };

  const onImageLoadEnd = () => setIsLoading(false);

  if (!visible) return null;

  let ImageComponent = FastImage;

  if (isWeb()) {
    ImageComponent = children ? ImageBackground : Image;
  }
  if (isGifImage(imageUrl.uri)) {
    ImageComponent = Image;
  }

  return (
    <>
      <ImageComponent
        {...props}
        style={getStyles(props)}
        source={imageUrl}
        onError={onImageError}
        onLoadEnd={onImageLoadEnd}
      >
        {children}
      </ImageComponent>
      {isLoading && (
        <ShimmerPlaceHolder
          colorShimmer={colorShimmer}
          autoRun
          style={getShimmerStyles(props)}
        />
      )}
    </>
  );
};

const isGifImage = (imageUrl) => imageUrl?.includes('.gif');

const getStyles = (props) => {
  const {
    style,
    additionalBackgroundColor,
    height,
    width,
    marginRight,
    marginLeft,
    borderTopRightRadius,
    borderBottomRightRadius,
    borderTopLeftRadius,
    borderBottomLeftRadius,
    tintColor,
  } = props;
  const styles = [style];
  if (additionalBackgroundColor) {
    styles.push({ backgroundColor: additionalBackgroundColor });
  }
  if (isPresent(height)) {
    styles.push({ height });
  }
  if (isPresent(width)) {
    styles.push({ width });
  }
  if (isPresent(marginRight)) {
    styles.push({ marginRight });
  }
  if (isPresent(marginLeft)) {
    styles.push({ marginLeft });
  }
  if (isPresent(borderTopRightRadius)) {
    styles.push({ borderTopRightRadius });
  }
  if (isPresent(borderBottomRightRadius)) {
    styles.push({ borderBottomRightRadius });
  }
  if (isPresent(borderTopLeftRadius)) {
    styles.push({ borderTopLeftRadius });
  }
  if (isPresent(borderBottomLeftRadius)) {
    styles.push({ borderBottomLeftRadius });
  }
  if (isPresent(tintColor)) {
    styles.push({ tintColor });
  }
  return styles;
};

const getShimmerStyles = (props) => {
  const styles = getStyles(props);
  return [...styles, { position: 'absolute', alignSelf: 'center' }];
};

export default memo(FastImageView);
