import { Image } from 'react-native';
import React from 'react';
import Config from '../../libraries/ReactNativeConfig';

export default function DesktopHeaderLogo() {
  return (
    <Image source={{ uri: Config.DESKTOP_HEADER_LOGO }} style={logoStyle} />
  );
}

const logoStyle = {
  width: +Config.DESKTOP_LOGO_WIDTH,
  height: +Config.DESKTOP_LOGO_HEIGHT,
  lineHeight: 1.5,
};
