// Dependencies
import React, { Component } from 'react';
import {
  View,
  FlatList,
  Image,
  ImageBackground,
  Text,
  TouchableOpacity,
} from 'react-native';
import { memoize } from 'lodash';
import Config from '../../libraries/ReactNativeConfig';
import Images from '../../theme/Images';
import {
  ProductDetailStyles,
  ProductInfoStyles,
  productDetailIds,
} from './styles';
import images from '../../theme/Images';
import colors from '../../theme/Colors';
import { withEither } from '../../lib/Monads';
import PRODUCT from '../../config/LayoutConstants/ProductConfig';
import Utility from '../../utils/Utility';
import FastImageView from '../FastImageView';
import DebouncedTouchableOpacity from '../shared/DebouncedTouchableOpacity';
import VariantUtility from '../../utils/VariantUtility';
import AppConfig from '../../config/AppConfig';
import {
  CUSTOMIZED_SKU_FORM_PAGE,
  DisableVarianListScrollability,
  REMOTE_CONFIG_KEYS,
} from '../../config/Constants';
import {
  isBlank,
  isDesktop,
  isPresent,
  isWeb,
} from '../../utils/BooleanUtility';
import { getMinifiedImage } from '../../utils/ImageUtility';
import { getHitSlop } from '../../utils/LayoutUtility';
import VariantWithImage from './VariantWithImage';
import { jsonParser } from '../../utils/JsonUtility';
import RemoteConfig from '../../utils/RemoteConfig';
import SizeChartPlaceholder from './SizeChartPlaceholder';
import { isInStock } from '../../utils/ProductUtility';

// FIXME: LOT OF INLINE FUNCTIONS, DESTRUCTURING CAN BE FIXED. REFACTORING EFFORT : HIGH

const VariantPropertyListIndex = {
  primary: 0,
  secondary: 1,
  tertiary: 2,
};
class ProductVariant extends Component {
  constructor(props) {
    super(props);

    this.inStockedVariants = [];
    this.stockedOutVariants = [];
    this.unStockedVariants = [];
    this.discontinuedVariants = [];
    const {
      variantAttributes = [],
      multiVariantAttributes,
      itemData: { color_data = [] } = {},
    } = this.props;
    this.colorData = Object.values(color_data);
    this.sortedVariants = multiVariantAttributes ?? variantAttributes;
    if (isPresent(this.colorData)) {
      this.sortedVariants = this.colorData;
    }
    this.allowedVariants = [];
    this.availableVariants = [];
    this.unavailableVariants = [];
    this.state = {
      isMoreShadePressed: false,
    };
    this.customInjectedJavascript =
      jsonParser(
        RemoteConfig.getValue(REMOTE_CONFIG_KEYS.custom_injected_javascript),
      ) || {};
    this.sizeChartData =
      jsonParser(RemoteConfig.getValue(REMOTE_CONFIG_KEYS.size_chart)) || {};
  }

  checkIfShadeVariantIsSelected = (item) => {
    const { selectedMultiVariantPrimary, selectedVariant } = this.props;
    if (isPresent(selectedMultiVariantPrimary)) {
      return selectedMultiVariantPrimary?.value === item?.value;
    }
    if (isPresent(selectedVariant)) {
      return selectedVariant.id === item?.id;
    }
    return false;
  };

  getExistingSelectedChildVariantIndex = (newParentValue, parentLevel) => {
    const {
      variantsOosConfig,
      selectedMultiVariantSecondary,
      selectedMultiVariantTertiary,
    } = this.props;
    const { primary, secondary, tertiary } = VariantPropertyListIndex;
    let index = 0;
    let existingSelectedChildValue = '';
    try {
      switch (parentLevel) {
        case primary:
          existingSelectedChildValue = selectedMultiVariantSecondary.value;
          break;
        case secondary:
          existingSelectedChildValue = selectedMultiVariantTertiary.value;
          break;
        default:
      }
      index = Object.keys(variantsOosConfig[newParentValue]).indexOf(
        existingSelectedChildValue,
      );
    } catch (error) {}
    return index > 0 ? index - 1 : 0;
  };

  primaryVariantSelected = (item) => {
    const {
      variant,
      multi_level_variant_attributes: attributes,
      value = '',
    } = item;
    const { updateSelectedVariant, setSelectedMultiVariantPrimary } =
      this.props;

    setSelectedMultiVariantPrimary(item);
    if (isPresent(attributes)) {
      const existingSelectedSecondaryVariantIndex =
        this.getExistingSelectedChildVariantIndex(
          value,
          VariantPropertyListIndex.primary,
        );
      this.secondaryVariantSelected(
        attributes[existingSelectedSecondaryVariantIndex],
      );
    } else {
      updateSelectedVariant(variant ?? item);
    }
  };

  getImageBackgroundStyle = (colorCode) =>
    memoize(
      () => ({
        ...ProductDetailStyles.productVariantWhiteImageBackground,
        backgroundColor: colorCode,
      }),
      () => [colorCode],
    )();

  getImageBackgroundContainerStyle = (borderColor) =>
    memoize(
      () => [ProductDetailStyles.ImageBackgroundContainer, { borderColor }],
      () => [borderColor],
    )();

  getImageSource = (uri) =>
    memoize(
      () => ({
        uri,
      }),
      () => [uri],
    )();

  colorSwatch = ({ item, propertyListIndex }) => {
    const { id, value, color_code, image_url = '' } = item;
    const {
      selectedVariant,
      showSelectedVariantIdFromSlug,
      disableSelectedVariantFromSlug,
      selectedMultiVariantPrimary = {},
      selectedMultiVariantSecondary,
      selectedMultiVariantTertiary,
    } = this.props;
    const { value: tertiaryParentVariantValue } = selectedMultiVariantSecondary;
    const { value: secondaryVariantParentValue } = selectedMultiVariantPrimary;
    const isDisabled = this.memoizedGetOosStatus(item, propertyListIndex);

    if (
      selectedVariant === '' &&
      item?.default !== undefined &&
      AppConfig.getBooleanValue(
        Config.PRODUCT_PAGE_SHOW_DEFAULT_SELECTED_VARIANT,
      )
    ) {
      this.props.updateSelectedVariant(item);
    }

    if (
      isPresent(showSelectedVariantIdFromSlug) &&
      showSelectedVariantIdFromSlug === id
    ) {
      this.props.updateSelectedVariant(item);
      disableSelectedVariantFromSlug();
    }
    let onPress;
    let isSelected;
    const { secondary, tertiary } = VariantPropertyListIndex;
    switch (propertyListIndex) {
      case secondary:
        onPress = memoize(
          () => this.handleVariantItemPress(item, secondaryVariantParentValue),
          () => [value ?? id, secondaryVariantParentValue],
        );
        isSelected = selectedMultiVariantSecondary?.value === value;
        break;
      case tertiary:
        onPress = memoize(
          () => this.handleVariantItemPress(item, tertiaryParentVariantValue),
          () => [value ?? id, tertiaryParentVariantValue],
        );
        isSelected = selectedMultiVariantTertiary?.value === value;
        break;
      default:
        onPress = memoize(
          () => this.handleVariantItemPress(item),
          () => [value ?? id],
        );
        isSelected = selectedMultiVariantPrimary?.value === value;
    }
    const borderColor = isSelected ? colors.black : colors.lightGrey;
    const imageBackgroundContainerStyle =
      this.getImageBackgroundContainerStyle(borderColor);
    const imageBackgroundStyle = this.getImageBackgroundStyle(
      isPresent(color_code) ? color_code : colors.grey,
    );
    const imageSource = this.getImageSource(image_url);

    return (
      <DebouncedTouchableOpacity
        onPress={onPress}
        style={imageBackgroundContainerStyle}
      >
        <ImageBackground source={imageSource} style={imageBackgroundStyle} />
        {isSelected ? (
          <View style={ProductDetailStyles.whiteTickContainer}>
            <Image
              style={ProductDetailStyles.productVariantTickImage}
              source={Images.whiteTick}
            />
          </View>
        ) : null}
        {(isDisabled || item.variant?.outOfStock || item.outOfStock) && (
          <View style={ProductDetailStyles.oosContainer} />
        )}
      </DebouncedTouchableOpacity>
    );
  };

  handleVariantItemPress = (item = {}, parentValue) => {
    const { navigation, productCategory = {} } = this.props;
    const changeProductOnPrimaryVariantSelect = AppConfig.getBooleanValue(
      Config.CHANGE_PRODUCT_ON_PRIMARY_VARIANT_SELECT,
    );

    if (parentValue) {
      this.secondaryVariantSelected(item, parentValue);
    } else {
      const { multi_level_variant_attributes = [] } = item;
      if (
        changeProductOnPrimaryVariantSelect &&
        isPresent(multi_level_variant_attributes)
      ) {
        this.secondaryVariantSelected(multi_level_variant_attributes[0]); // updating secondary variant
      }

      this.primaryVariantSelected(item);
    }
    const { variant = {} } = item;
    const { sku = {} } = variant;
    const { id: skuId, needs_customization: needsCustomization = false } = sku;
    if (CUSTOMIZED_SKU_FORM_PAGE && needsCustomization && skuId) {
      navigation.navigate(CUSTOMIZED_SKU_FORM_PAGE, { item, productCategory });
    }
  };

  primaryVariantItem = ({ item = {} }) => {
    const {
      showSelectedVariantIdFromSlug,
      disableSelectedVariantFromSlug = () => {},
    } = this.props;

    const isDisabled = this.memoizedGetOosStatus(
      item,
      VariantPropertyListIndex.primary,
    );

    const selectedContainerStyle = DisableVarianListScrollability
      ? ProductDetailStyles.flexWrappedSizeContainerSelected
      : ProductDetailStyles.sizeContainerSelected;
    const unselectedContainerStyle = DisableVarianListScrollability
      ? ProductDetailStyles.flexWrappedSizeContainer
      : ProductDetailStyles.sizeContainer;
    const containerStyle = this.checkIfShadeVariantIsSelected(item)
      ? selectedContainerStyle
      : unselectedContainerStyle;
    const textStyle = isDisabled
      ? ProductDetailStyles.disabledSizeVariantText
      : ProductDetailStyles.sizeVariantText;
    if (showSelectedVariantIdFromSlug == item?.id) {
      disableSelectedVariantFromSlug();
    }

    const { itemData: { has_combo_variants = false } = {} } = this.props;

    if (has_combo_variants) {
      return (
        <VariantWithImage
          onPress={memoize(
            () => this.handleVariantItemPress(item),
            () => [item.value ?? item.id],
          )}
          disabled={isDisabled || item.variant?.outOfStock || item.outOfStock}
          item={item}
          isSelected={this.checkIfShadeVariantIsSelected(item)}
        />
      );
    }

    return (
      <TouchableOpacity
        onPress={memoize(
          () => this.handleVariantItemPress(item),
          () => [item.value ?? item.id],
        )}
        style={containerStyle}
      >
        <Text style={textStyle}>{item?.value || item?.display_name}</Text>
        {isDisabled && (
          <View style={ProductDetailStyles.disabledSizePillLine} />
        )}
      </TouchableOpacity>
    );
  };

  variantName = ({ name = '', propertyValue = '' }) => {
    const { multiVariantAttributes } = this.props;

    if (isBlank(multiVariantAttributes)) return null;

    return (
      <View style={ProductDetailStyles.variantNameContainer}>
        <View style={ProductDetailStyles.flexRow}>
          <Text
            style={ProductDetailStyles.variantNameTextStyle}
          >{`${name} : `}</Text>

          <Text style={ProductDetailStyles.darkTextStyleVariantName}>
            {propertyValue}
          </Text>
        </View>
      </View>
    );
  };

  packSize = (props) => {
    const { name } = props;

    return (
      <View style={ProductDetailStyles.packSizeVariantContainer}>
        <Text style={ProductDetailStyles.variantNameTextStyle}>{'Size :'}</Text>

        <Text style={ProductDetailStyles.darkTextStyleVariantName}>{name}</Text>
      </View>
    );
  };

  unavailableVariantName = () => {
    return (
      <View style={ProductDetailStyles.variantNameContainerUnavailableVariant}>
        <Text style={ProductDetailStyles.variantNameTextStyle}>
          Other unavailable sizes
        </Text>
      </View>
    );
  };

  shadeSwatches = (item, index, propertyListIndex) => {
    const { isMoreShadePressed = false } = this.state;
    const moreVariantCount = isDesktop() ? 19 : 13;
    const { itemData: { has_combo_variants = false } = {} } = this.props;

    if (has_combo_variants) {
      return (
        <VariantWithImage
          onPress={memoize(
            () => this.handleVariantItemPress(item),
            () => [item.value ?? item.id],
          )}
          disabled={item.variant?.outOfStock || item.outOfStock}
          item={item}
          isSelected={this.checkIfShadeVariantIsSelected(item)}
        />
      );
    }

    if (index < moreVariantCount) {
      return (
        <this.colorSwatch item={item} propertyListIndex={propertyListIndex} />
      );
    }

    if (index === moreVariantCount && !isMoreShadePressed) {
      return (
        <TouchableOpacity onPress={this.onMoreShadeTap} hitSlop={getHitSlop()}>
          <View style={ProductDetailStyles.moreVariantsContainer}>
            <Text style={ProductDetailStyles.variantNameText}>{`+ ${
              this.sortedVariants.length - index
            }`}</Text>
          </View>
        </TouchableOpacity>
      );
    }

    if (!isMoreShadePressed && index > moreVariantCount) return null;

    return (<this.colorSwatch item={item} propertyListIndex={propertyListIndex} />);
  };

  navigateToSizeChart = () => {
    const {
      navigation,
      itemData: { size_chart_url: url },
    } = this.props;
    navigation.navigate('WebUrlView', {
      destination: url,
      title: 'Size Chart',
      showBottomButton: true,
      customInjectedJavascript: this.customInjectedJavascript.SIZE_CHART,
      placeholder: () => (
        <SizeChartPlaceholder sizeChart={this.sizeChartData} />
      ),
    });
  };

  sizeChartButton = () => {
    const {
      itemData: { size_chart_url: url },
    } = this.props;
    if (isBlank(url)) return null;
    return (
      <View style={ProductDetailStyles.sizeChartButton}>
        <DebouncedTouchableOpacity
          {...this.props}
          onPress={this.navigateToSizeChart}
          style={ProductDetailStyles.sizeChartContainer}
        >
          <FastImageView
            source={Images.sizeChart}
            style={ProductDetailStyles.sizeChartIcon}
          />
          <Text style={ProductDetailStyles.sizeChartButtonText}>
            Size Chart
          </Text>
        </DebouncedTouchableOpacity>
      </View>
    );
  };

  sizeListKeyExtractor = (item) => `${item.value}`;

  secondaryVariantSelected = (item, parentValue) => {
    const {
      variant = {},
      multi_level_variant_attributes: attributes = [],
      value = '',
    } = item || {};
    const {
      setSelectedMultiVariantSecondary,
      selectedMultiVariantSecondary,
      updateSelectedVariant,
    } = this.props;

    if (parentValue === selectedMultiVariantSecondary.value) {
      // In case selected variant is of tertiary level
      this.tertiaryVariantSelected(item);
    } else {
      setSelectedMultiVariantSecondary(item);
      if (isPresent(attributes) && attributes.length > 0) {
        const existingSelectedTertiaryVariantIndex =
          this.getExistingSelectedChildVariantIndex(
            value,
            VariantPropertyListIndex.secondary,
          );
        this.tertiaryVariantSelected(
          attributes[existingSelectedTertiaryVariantIndex],
        );
        return;
      }
      updateSelectedVariant(variant);
    }
  };

  getOutOfStockStatus = (item, level) => {
    const { value: itemValue = '' } = item;
    const {
      variantsOosConfig = {},
      selectedMultiVariantPrimary: { value: selectedPrimaryValue } = {},
      selectedMultiVariantSecondary: { value: selectedSecondaryValue } = {},
      selectedMultiVariantTertiary,
    } = this.props;
    const { primary, secondary, tertiary } = VariantPropertyListIndex;
    let isOutOfStock = false;
    try {
      switch (level) {
        case primary:
          isOutOfStock = variantsOosConfig[itemValue].oos;
          break;
        case secondary:
          isOutOfStock = variantsOosConfig[selectedPrimaryValue][itemValue].oos;
          break;
        case tertiary:
          isOutOfStock =
            variantsOosConfig[selectedPrimaryValue][selectedSecondaryValue][
              itemValue
            ].oos;
          break;
        default:
      }
    } catch (e) {}
    return isOutOfStock;
  };

  memoizedGetOosStatus = (item, level) =>
    memoize(
      () => this.getOutOfStockStatus(item, level),
      () => [`${item.value}_${level}`],
    )();

  secondaryVariantItem = ({ item = {}, index }) => {
    const styles = ProductInfoStyles;
    const { selectedMultiVariantSecondary, selectedMultiVariantPrimary = {} } =
      this.props;
    const { value: parentVariantValue } = selectedMultiVariantPrimary;

    const isDisabled = this.memoizedGetOosStatus(
      item,
      VariantPropertyListIndex.secondary,
    );

    const isSelected =
      selectedMultiVariantSecondary?.value === item?.value && !isDisabled;
    const selectedContainerStyle = DisableVarianListScrollability
      ? styles.flexWrappedSelectedSizePillBorder
      : styles.selectedSizePillBorder;
    const unselectedContainerStyle = DisableVarianListScrollability
      ? styles.flexWrappedUnselectedSizePillBorder
      : styles.unselectedSizePillBorder;
    const borderStyle = isSelected
      ? selectedContainerStyle
      : unselectedContainerStyle;
    const textStyle = isDisabled
      ? ProductDetailStyles.disabledSizeVariantText
      : ProductDetailStyles.sizeVariantText;
    return (
      <TouchableOpacity
        style={borderStyle}
        onPress={memoize(
          () => this.handleVariantItemPress(item, parentVariantValue),
          () => [item.value ?? item.id, parentVariantValue],
        )}
      >
        <Text style={textStyle}>{item?.value}</Text>
        {isDisabled && (
          <View style={ProductDetailStyles.disabledSizePillLine} />
        )}
      </TouchableOpacity>
    );
  };

  flatListKeyExtractor = (item, index) => index;

  tertiaryVariantSelected = (item) => {
    const { setSelectedMultiVariantTertiary, updateSelectedVariant } =
      this.props;
    setSelectedMultiVariantTertiary(item);
    const { variant = {} } = item || {};
    updateSelectedVariant(variant);
  };

  tertiaryVariantItem = ({ item = {} }) => {
    const styles = ProductInfoStyles;
    const { selectedMultiVariantTertiary, selectedMultiVariantSecondary } =
      this.props;
    const { value: parentVariantValue } = selectedMultiVariantSecondary;

    const isDisabled = this.memoizedGetOosStatus(
      item,
      VariantPropertyListIndex.tertiary,
    );

    const isSelected = selectedMultiVariantTertiary?.value === item?.value;
    const selectedContainerStyle = DisableVarianListScrollability
      ? styles.flexWrappedSelectedSizePillBorder
      : styles.selectedSizePillBorder;
    const unselectedContainerStyle = DisableVarianListScrollability
      ? styles.flexWrappedUnselectedSizePillBorder
      : styles.unselectedSizePillBorder;
    const borderStyle = isSelected
      ? selectedContainerStyle
      : unselectedContainerStyle;
    const textStyle = isDisabled
      ? ProductDetailStyles.disabledSizeVariantText
      : ProductDetailStyles.sizeVariantText;
    return (
      <TouchableOpacity
        style={borderStyle}
        onPress={memoize(
          () => this.handleVariantItemPress(item, parentVariantValue),
          () => [item.value ?? item.id, parentVariantValue],
        )}
      >
        <Text style={textStyle}>{item?.value}</Text>
        {isDisabled && (
          <View style={ProductDetailStyles.disabledSizePillLine} />
        )}
      </TouchableOpacity>
    );
  };

  getImageListProps = (propertyListIndex) => {
    const { has_combo_variants } = this.props;
    return {
      horizontal: has_combo_variants,
      numColumns: !has_combo_variants && (isDesktop() ? 10 : 7),
      renderItem: ({ item, index }) =>
        this.shadeSwatches(item, index, propertyListIndex),
      style: isWeb()
        ? ProductDetailStyles.imageVariantFlatlistStyle
        : [ProductDetailStyles.imageVariantFlatlistStyle, { flex: 1 }],
      contentContainerStyle:
        ProductDetailStyles.imageVariantFlatlistContentStyle,
    };
  };

  VariantLists = (props) => {
    const { variantAttributes, multiVariantAttributes } = props;
    const {
      stockedOut,
      selectedMultiVariantPrimary,
      selectedMultiVariantSecondary,
      selectedMultiVariantTertiary,
    } = this.props;

    if (isBlank(multiVariantAttributes)) {
      return null;
    }

    const {
      variant_attributes: selectedVariantAttributes,
      multi_level_variant_attributes: selectedMultiVariantAttributes,
    } = selectedMultiVariantPrimary;

    const {
      multi_level_variant_attributes: selectedMultiVariantAttributesSecondary,
    } = selectedMultiVariantSecondary;

    const flatListContentContainerStyle = DisableVarianListScrollability
      ? ProductDetailStyles.flexWrappedContentContainerStyle
      : {};

    let imageProps1 = {};
    let imageProps2 = {};
    let imageProps3 = {};

    if (multiVariantAttributes[0].type === 'image') {
      imageProps1 = this.getImageListProps(VariantPropertyListIndex.primary);
    }

    if (
      selectedMultiVariantAttributes &&
      selectedMultiVariantAttributes[0].type === 'image'
    ) {
      imageProps2 = this.getImageListProps(VariantPropertyListIndex.secondary);
    }

    if (
      selectedMultiVariantAttributesSecondary &&
      selectedMultiVariantAttributesSecondary[0].type === 'image'
    ) {
      imageProps3 = this.getImageListProps(VariantPropertyListIndex.tertiary);
    }

    return (
      <View style={ProductDetailStyles.productSizeVariantContainer}>
        <this.variantName
          name={multiVariantAttributes[0].name}
          propertyValue={selectedMultiVariantPrimary.value}
        />
        <this.sizeChartButton />
        <FlatList
          data={multiVariantAttributes}
          horizontal={!DisableVarianListScrollability}
          renderItem={this.primaryVariantItem}
          keyExtractor={this.flatListKeyExtractor}
          showsHorizontalScrollIndicator={false}
          style={ProductDetailStyles.flatListItemStyle}
          contentContainerStyle={flatListContentContainerStyle}
          extraData={{
            ...this.props.selectedVariant,
            ...selectedMultiVariantPrimary,
          }}
          {...imageProps1}
        />
        {(isPresent(selectedVariantAttributes) ||
          isPresent(selectedMultiVariantAttributes)) && (
          <this.variantName
            name={selectedMultiVariantAttributes[0].name}
            propertyValue={selectedMultiVariantSecondary.value}
          />
        )}
        <FlatList
          data={selectedMultiVariantAttributes ?? selectedVariantAttributes}
          horizontal={!DisableVarianListScrollability}
          showsHorizontalScrollIndicator={false}
          renderItem={this.secondaryVariantItem}
          keyExtractor={this.sizeListKeyExtractor}
          style={ProductInfoStyles.secondaryVariantFlatlistStyle}
          contentContainerStyle={flatListContentContainerStyle}
          extraData={{
            ...selectedMultiVariantPrimary,
            ...selectedMultiVariantSecondary,
          }}
          {...imageProps2}
        />
        {isPresent(selectedMultiVariantAttributesSecondary) && (
          <this.variantName
            name={selectedMultiVariantAttributesSecondary[0].name}
            propertyValue={selectedMultiVariantAttributesSecondary.value}
          />
        )}
        <FlatList
          data={
            selectedMultiVariantAttributesSecondary ?? selectedVariantAttributes
          }
          horizontal={!DisableVarianListScrollability}
          showsHorizontalScrollIndicator={false}
          renderItem={this.tertiaryVariantItem}
          keyExtractor={(item) => item.value}
          style={ProductInfoStyles.secondaryVariantFlatlistStyle}
          contentContainerStyle={flatListContentContainerStyle}
          extraData={{
            ...selectedMultiVariantSecondary,
            ...selectedMultiVariantTertiary,
          }}
          {...imageProps3}
        />
      </View>
    );
  };

  onMoreShadeTap = () => {
    const { onMoreShadeTap } = this.props;
    if (isDesktop()) {
      this.setState({
        isMoreShadePressed: true,
      });
      return;
    }
    onMoreShadeTap();
  };

  VariantsSection = (props) => {
    const { variantAttributes, multiVariantAttributes } = props;
    return (
      <View
        style={ProductDetailStyles.integerVariantsContainer}
        dataSet={{ media: productDetailIds.integerVariantsContainer }}
      >
        <this.VariantLists
          variantAttributes={variantAttributes}
          multiVariantAttributes={multiVariantAttributes}
        />
      </View>
    );
  };

  render() {
    const {
      variantAttributes,
      hasVariants,
      recommendedVariants,
      packSize,
      multiVariantAttributes,
    } = this.props;
    if (!hasVariants) {
      if (isPresent(packSize)) {
        return (
          <View
            style={ProductDetailStyles.productWhiteSizeVariantContainer}
            dataSet={{
              media: productDetailIds.productWhiteSizeVariantContainer,
            }}
          >
            <this.packSize name={packSize} />
          </View>
        );
      }
      return null;
    }

    return (
      <View style={ProductDetailStyles.whiteBackground}>
        <this.VariantsSection
          variantAttributes={variantAttributes}
          type={multiVariantAttributes[0]?.type}
          recommendedVariants={recommendedVariants}
          multiVariantAttributes={multiVariantAttributes}
        />
      </View>
    );
  }
}

export default React.memo(ProductVariant);
