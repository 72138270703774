import React, { Component } from 'react';
import {
  View,
  Text,
  TouchableWithoutFeedback,
  StyleSheet,
  Image,
} from 'react-native';
import styles from '../styles';
import { ProfilePic } from '../../myProfile';
import Utility from '../../../utils/Utility';
import size from '../../../theme/Fonts';
import colors from '../../../theme/Colors';
import images from '../../../theme/Images';
import {
  AnalyticsManager,
  EventParameterKey,
  EventType,
} from '../../../analytics';
import Config from '../../../libraries/ReactNativeConfig';
import AppConfig from '../../../config/AppConfig';
import { isWeb } from '../../../utils/BooleanUtility';

export default class ConsumerProfileInfoSection extends Component {
  constructor(props) {
    super(props);
    this.memoizedSelfieClick = {};
    this.memoizedCompleteProfile = {};
  }

  getMemoizedSelfieClick = () => {
    return (isSelfieUpdate) => {
      if (!this.memoizedSelfieClick[isSelfieUpdate]) {
        this.memoizedSelfieClick[isSelfieUpdate] = () =>
          this.onTapSelfieClick(isSelfieUpdate);
      }
      return this.memoizedSelfieClick[isSelfieUpdate];
    };
  };

  getMemoizedCompleteProfile = () => {
    return (isEditProfile) => {
      if (!this.memoizedCompleteProfile[isEditProfile]) {
        this.memoizedCompleteProfile[isEditProfile] = () =>
          this.onTapCompleteProfile(isEditProfile);
      }
      return this.memoizedCompleteProfile[isEditProfile];
    };
  };

  email = (props) => (
    <View style={{ width: Utility.getScreenWidth() - 200, paddingRight: 4 }}>
      <Text style={styles.userHandle} numberOfLines={1} ellipsizeMode="tail">
        {props.email}
      </Text>
    </View>
  );

  _userDetails = (props) => (
    <View style={{ marginLeft: 10 }}>
      {Utility.isPresent(props.name) && (
        <Text style={styles.userName}>{props.name}</Text>
      )}
      {Utility.isPresent(props.mobileNumber) &&
        (Utility.isPresent(props.email) || Utility.isPresent(props.name)) && (
          <Text style={styles.userHandle}>{props.mobileNumber}</Text>
        )}
      {Utility.isPresent(props.email) && (
        <this.email name={props.email} email={props.email} />
      )}
    </View>
  );

  _mobileOnly = () => {
    const { name, email, mobileNumber } = this.props;
    if (Utility.isPresent(name)) return null;
    return (
      <Text style={[styles.userName, { marginLeft: 10 }]}>{mobileNumber}</Text>
    );
  };

  buttonContainer = (props) => {
    const { title, onPress, hide } = props;
    if (hide) return null;
    return (
      <TouchableWithoutFeedback onPress={onPress}>
        <View style={styles.buttonContainer}>
          <Text style={styles.buttonTitle}>{title}</Text>
          <Image source={images.chevronRight} style={styles.chevronRight} />
        </View>
      </TouchableWithoutFeedback>
    );
  };

  onTapCompleteProfile = (isEditProfile) => {
    const { onPressCompleteProfile } = this.props;
    AnalyticsManager.logEvent(EventType.profileEvents.PROFILE_ANALYSIS_CLICK, {
      [EventParameterKey.TYPE]: isEditProfile ? 'edit' : 'complete',
    });
    onPressCompleteProfile();
  };

  onTapSelfieClick = (isSelfieAlreadyClick) => {
    const { onPressTakeSelfie } = this.props;
    AnalyticsManager.logEvent(
      EventType.onboardingEvent.SELFIE_ANALYSIS_INITIATE,
      {
        [EventParameterKey.TYPE]: isSelfieAlreadyClick ? 'update' : 'review',
      },
    );
    onPressTakeSelfie(isSelfieAlreadyClick);
  };

  profileActionButtonContainer = () => {
    if (isWeb()) return null;
    const {
      onPressCompleteProfile,
      name,
      email,
      mobileNumber,
      onPressTakeSelfie,
      selfieImageUrl,
      showReviewSelfieButton,
      authorized,
    } = this.props;

    const isEditProfile =
      Utility.isPresent(name) &&
      Utility.isPresent(email) &&
      Utility.isPresent(mobileNumber);
    const isSelfieUpdate = Utility.isPresent(selfieImageUrl);
    const editProfileButtonName = isEditProfile
      ? 'Edit Profile'
      : 'Complete Profile';
    let takeSelfieText = isSelfieUpdate ? 'Review Selfie' : 'Take Selfie';
    if (AppConfig.getBooleanValue(Config.HIDE_SELFIE_FLOW_PAYOFF)) {
      takeSelfieText = 'Build your profile';
    }

    return (
      <View style={styles.profileCtaContainer}>
        <this.buttonContainer
          title={editProfileButtonName}
          onPress={this.getMemoizedCompleteProfile()(isEditProfile)}
        />
       {authorized && <this.buttonContainer
          title={takeSelfieText}
          onPress={this.getMemoizedSelfieClick()(isSelfieUpdate)}
          hide={!showReviewSelfieButton}
        />}
      </View>
    );
  };

  render() {
    const {
      mobileNumber,
      onPressCompleteProfile,
      name,
      handle,
      email,
      showProfilePicLoader,
      showModalSelfie,
    } = this.props;
    return (
      <View style={styles.consumerProfileCardStyle}>
        <View style={styles.profileContainer}>
          <View>
            <this._mobileOnly />

            <this._userDetails
              name={name}
              mobileNumber={mobileNumber}
              email={email}
            />
          </View>
          <ProfilePic
            showModalSelfie={showModalSelfie}
            height={56}
            width={56}
            showProfilePicLoader={showProfilePicLoader}
            emptyStateImage={images.consumerProfileEmptyStateImage}
          />
        </View>
        <this.profileActionButtonContainer />
      </View>
    );
  }
}
