// Dependencies
import React, { PureComponent } from 'react';
import { View, TouchableOpacity, Image } from 'react-native';
import PropTypes from 'prop-types';
import withNavigation from '../../utils/WithNavigation';
import Images from '../../theme/Images';
import styles from './styles';
import Utility from '../../utils/Utility';
import colors from '../../theme/Colors';
import { isPresent } from '../../utils/BooleanUtility';

class NavigationBackHeader extends PureComponent {
  goback = () => {
    const { navigation, route } = this.props;
    if (isPresent(route.params.shortSlug)) {
      navigation.replace('TabNavigator');
      return;
    }
    navigation.goBack();
  };

  render() {
    return (
      <View style={styles.backHeaderStyle}>
        <TouchableOpacity onPress={this.goback} hitSlop={Utility.getHitSlop()}>
          <Image
            source={Images.ic_back_product}
            style={styles.navigationBackHeader}
          />
        </TouchableOpacity>
      </View>
    );
  }
}

NavigationBackHeader.propTypes = {
  navigation: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
  }).isRequired,
};

export default withNavigation(NavigationBackHeader);
