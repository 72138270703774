import React, { PureComponent } from 'react';
import { View, StyleSheet } from 'react-native';

import { List } from '.';
import ListsFilter from '../../components/layout/ListsFilter';
import Utility from '../../utils/Utility';
import GridScreenPlaceHolder from '../../components/shared/GridScreenPlaceHolder';
import WithNavigation from '../../utils/WithNavigation';
import FilterResultsEmptyState from '../../components/sort-filter-options/FilterResultsEmptyState';
import {
  isBlank,
  isPresent,
  isDesktop,
  isWeb,
} from '../../utils/BooleanUtility';
import { getScreenHeight, getScreenWidth } from '../../utils/LayoutUtility';
import WebListTitle from './WebListTitle';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '../../config/Constants';
import colors from '../../theme/Colors';

class ListWithFilterComponent extends PureComponent {
  quickFiltersRow = () => {
    const {
      stickyPosition,
      selectedFilters,
      itemData,
      getQuickFiltersListRef,
      onFiltersPress,
      filtersFromDeepLink,
      previousScreen = '',
   } = this.props;

    if (Utility.isBlank(itemData.quick_filters)) return null;

    return (
      <View style={styles.wrapper}>
        <ListsFilter
          getQuickFiltersListRef={getQuickFiltersListRef}
          stickyPosition={stickyPosition}
          selectedFilters={selectedFilters}
          itemData={itemData}
          onFiltersPress={onFiltersPress}
          index={1}
          filtersFromDeepLink={filtersFromDeepLink}
          previousScreen={previousScreen}
        />
      </View>
    );
  };

  listRow = () => {
    if (isDesktop()) {
      return (
        <View style={styles.listContainer}>
          <List {...this.props} />
        </View>
      );
    }
    return <List {...this.props} />;
  };

  render() {
    const {
      showProductShimmer,
      itemData: { objects = EMPTY_ARRAY, filters = EMPTY_OBJECT } = {},
      onPressResetButton = () => EMPTY_OBJECT,
      selectedFilters = EMPTY_OBJECT,
      webListTitle = '',
      filtersList = {},
    } = this.props;
    return (
      <>
        {isWeb() && webListTitle && (
          <WebListTitle title={webListTitle} background='#F2F2F2' />
        )}
        <View style={styles.container}>
          {isPresent(filters) && <this.quickFiltersRow />}
          {showProductShimmer && (
            <View style={styles.gridShimmerContainer}>
              <GridScreenPlaceHolder />
            </View>
          )}


          {!showProductShimmer && (
            <FilterResultsEmptyState
              showFilterEmptyState={isBlank(objects)}
              onPressResetButton={onPressResetButton}
              areFiltersEnabled={isPresent(selectedFilters)}
              filtersList={filtersList}
              selectedFilters={selectedFilters}
            >
              <View style={styles.container}>
                <List {...this.props} showFooterInList={isWeb()} />
              </View>
            </FilterResultsEmptyState>
          )}
        </View>
      </>
    );
  }
}

export default ListWithFilterComponent;

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.background,
    flex: 1,
  },
  wrapper: {
    zIndex: 1,
    height: 55,
    width: getScreenWidth(),
    alignSelf: 'center',
  },
  listContainer: {
    flex: 1,
    width: Utility.getScreenWidth() + 20,
    alignSelf: 'center',
  },
  container: {
    flex: 1,
    width: getScreenWidth(),
    alignSelf: 'center',
  },
  gridShimmerContainer: {
    marginTop: 20,
  },
});
