import React from 'react';
import { useRoute } from '@react-navigation/native';
import ShopOnline from '../List/ShopOnline';
import { API_DOMAIN, LISTS_API_VERSION } from '../../config/Constants';

export default function ShopOnlineCategory() {
  const { params = {} } = useRoute();
  const { first, second, third } = params;
  const url = `${API_DOMAIN}/api/${LISTS_API_VERSION}/categories/${first}${second ? '/second' : ''}${third ? '/third' : ''}/shop_online`;
  return <ShopOnline url={url} />;
}
