import { getStoreRef } from '../store/StoreUtility';
import { isNative, isPresent, isWeb } from './BooleanUtility';

export const getSkuId = (itemData) => {
  let id;
  if (
    isPresent(itemData) &&
    isPresent(itemData.variants_details)
  ) {
    id = itemData.variants_details.principal_sku_id;
  } else if (
    isPresent(itemData) &&
    isPresent(itemData.variant_attributes) &&
    isPresent(itemData.variant_attributes[0].allowed_values) &&
    isPresent(itemData.variant_attributes[0].allowed_values[0].sku_id)
  ) {
    id = itemData.variant_attributes[0].allowed_values[0].sku_id;
  } else {
    id = itemData.sku_id;
  }

  return id;
};

export const isInStock = (stockedStatus) => {
  if (isWeb()) {
    return stockedStatus === 'in_stock_1' || stockedStatus === 'in_stock';
  }
  return stockedStatus === 'in_stock';
};

export const getStockedStatus = (stockedStatus, webStockedStatus) => {
  if (isWeb()) return webStockedStatus || stockedStatus;
  return stockedStatus;
};

export const isInStock1ItemInCart = () => {
  if (isNative()) return false;
  const state = getStoreRef().getState();
  const { bag = {} } = state;
  const { cartItems = [] } = bag;
  return Boolean(cartItems.find(cartItem => cartItem.product?.web_stocked_status === 'in_stock_1'));
};

export const isForceInStockSku = (skuId) => {
  if (isNative()) return false;
  const state = getStoreRef().getState();
  const { bag = {} } = state;
  const { cartItems = [] } = bag;
  return Boolean(cartItems.find(cartItem => cartItem.product?.web_stocked_status === 'in_stock_1' && +cartItem.sku_id === +skuId));
};
