// Dependencies

/**
 * @deprecated . Please use MoreItemsList.js instead
 */

import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  View,
  ImageBackground,
  Text,
  Animated,
  findNodeHandle,
  BackHandler,
  TouchableOpacity,
  Image,
  ActivityIndicator,
} from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import _ from 'lodash';
import Toast from 'react-native-easy-toast';
import {
  fetchRelatedContent,
  getArtistContent,
  fetchMorePageList,
} from '../../actions/ActionTypes';
import styles from './styles';
import { NavigationBackHeader } from '../../components/header';
import {
  LAYOUT,
  MAX_LINES,
  ARTIST_CONSTANTS,
  REMOTE_CONFIG_KEYS,
} from '../../config/Constants';
import { withMaybe } from '../../lib/Monads';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import { CustomNavigationHeader } from '../../components/Product';
import DockedHeader from '../../utils/DockedHeader';
import {
  Pager,
  Grid,
  Listing,
  ReviewGrid,
  GridRail,
  Rail,
  Vertical,
} from '../../components/layout';
import GridScreenPlaceHolder from '../../components/shared/GridScreenPlaceHolder';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import colors from '../../theme/Colors';
import {
  EventParameterKey,
  AnalyticsManager,
  EventType,
} from '../../analytics';
import ErrorBoundary from '../../components/shared/ErrorBoundary';
import PersonalisedPage from './PersonalisedPage';
import FullBanner from '../../components/banner/FullBanner';
import SortingFilteringOptions from '../../components/sort-filter-options/SortingFilteringOptions';
import FilterResultsEmptyState from '../../components/sort-filter-options/FilterResultsEmptyState';
import FilterAnalyticsEvents from '../../components/sort-filter-options/FiltersAnalyticsEvents';
import RemoteConfig from '../../utils/RemoteConfig';
import FoxyPersonalisedItems from '../FoxyPersonalisedItems';
import Images from '../../theme/Images';
import PageNotFound from '../../components/shared/PageNotFound';
import { List } from '../List';
import { appendAttributesWithHomePageUrl } from '../../utils/UriUtility';
import AnalyticsUtility from '../../analytics/AnalyticsUtility';
import { goBackWithFallbackHome } from '../../utils/NavigationUtility';
import { getFirebasePerformanceTrace } from '../../utils/PerfUtility';
import { getMinifiedImage } from '../../utils/ImageUtility';
import FilterUtils, { canOpenSortingModal } from '../../components/sort-filter-options/FilterUtils';

class MoreItems extends Component {
  static navigationOptions = ({ route }) => ({
    title: route.params?.title ?? 'More',
    headerLeft: <NavigationBackHeader />,
  });

  Components = {
    rail: Rail,
    gridRail: GridRail,
    list: Listing,
    grid: Grid,
    vertical: Vertical,
    rectangularGrid: Grid,
    reviewGrid: ReviewGrid,
    personalizedVertical: PersonalisedPage,
    fullPageBanner: FullBanner,
  };

  constructor(props) {
    super(props);
    Utility.setPageLoadStart('more_items');
    this.trace = getFirebasePerformanceTrace(SCREEN_CONSTANTS.MORE_PAGE);
    this.trace.start();
    const { navigation, route } = this.props;
    this.extractedFiltersFromDeeplink = [];
    let existingSelectedFilters = {};
    if (Utility.isPresent(route.params?.url)) {
      this.extractedFiltersFromDeeplink =
        Utility.extractSelectedFiltersFromDeeplink(route.params?.url);

      if (Utility.isPresent(this.extractedFiltersFromDeeplink)) {
        existingSelectedFilters = _.groupBy(
          this.extractedFiltersFromDeeplink,
          (element) => element.criteria_name,
        );
      }
    }

    this.preventListEmptyStateOnInitialPageLoad = true;
    this.bottomShimmerColumnCount = 2;
    this.state = {
      scrollY: new Animated.Value(0),
      offerApplied: '',
      titleLeftSpacing: 12,
      isCartVisible: false,
      viewRef: null,
      itemData: props.itemData || {},
      selectedFilters: existingSelectedFilters,
      apiLoading: false,
      showLoadMoreShimmerAtListBottom: false,
      showSearchFooter: false,
      showActivityIndicator: false,
      filtersFromDeepLink: this.extractedFiltersFromDeeplink,
      isPageLoading: true,
      hideOosProduct: false,
    };

    if (Utility.isAndroid()) {
      this.backhandler = BackHandler.addEventListener(
        'hardwareBackPress',
        this.onHardwareBackKeyPress,
      );
    }

    this.list = route.params?.listData;
    this.item = route.params?.item;
    this.property = route.params?.property;
    this.uaavs = route.params?.uaavs;
    this.fromPersonalizedPage = route.params?.fromPersonalisedCard ?? false;
    this.bannerImageUrl = route.params?.filteredImage;
    if (!Utility.isBlank(this.item)) {
      this.display =
        this.item.display === LAYOUT.LIST ||
        this.item.display === 'review-grid' ||
        this.item.display === 'rectangular-grid' ||
        this.item.display === 'rail' ||
        this.item.display === 'grid-rail' ||
        this.item.display === 'vertical'
          ? _.camelCase(this.item.display)
          : LAYOUT.GRID;

      if (!Utility.isBlank(this.item.more_page_display)) {
        this.display =
          this.item.more_page_display === 'personalized-vertical' ||
          this.item.more_page_display === 'rectangular-grid' ||
          this.item.display === 'grid-rail'
            ? _.camelCase(this.item.more_page_display)
            : this.item.more_page_display;
      }

      this.LayoutComponent = this.Components[this.display];
    }
    this.AnimatedText = Animated.createAnimatedComponent(Text);
    this.BannerImage = Animated.createAnimatedComponent(ImageBackground);
    this.previousScreen = route.params?.previousScreen ?? '';
    this.listIndex = route.params?.index ?? '';
    const { itemData } = this.props;
    if (Utility.isPresent(itemData)) {
      const analyticsMeta = {
        [EventParameterKey.SCREEN_NAME]: this.previousScreen,
        [EventParameterKey.LIST_POSITION]: this.listIndex || 0,
        [EventParameterKey.LIST_ID]: itemData.id,
        [EventParameterKey.LIST_NAME]: itemData.name,
        [EventParameterKey.LIST_DISPLAY]: itemData.display,
        [EventParameterKey.LIST_CONTENT]: itemData.content,
      };
      AnalyticsManager.logEvent(
        EventType.discoveryEvents.LIST_DETAIL_VIEW,
        analyticsMeta,
      );

      AnalyticsManager.logEvent('list_rendered', {
        id: itemData.id,
      });
    }

    this.pageNo = 0;
    this.pageResultItemsCount = 10;
    this.allowNextPageLoad = true;
    this.search = route.params?.search;
    this.slug = '';
    if (this.search) {
      this.slug = `${route.params.slug}&Nan=Nan`;
      this.slug = this.slug?.replace('v0', 'v2');
    } else {
      this.slug = `${route.params.slug}?Nan=Nan`;
    }

    if (Utility.isPresent(this.extractedFiltersFromDeeplink)) {
      this.slug = `${this.slug} ${Utility.extractSelectedFiltersFromDeeplink(
        route.params?.url,
        true,
      )}`;
    }

    const extras = route.params?.extra;
    if (Utility.isPresent(extras)) {
      const { fromPersonalizedPage = false } = extras;
      this.addFacialAttributesInApiRequest = fromPersonalizedPage;
    }

    this.updateQuickFilterNameOnSortingFilterApplied = null;
    this.onEndReachedCalledDuringMomentum = true;
    this.selectedQuickFilters = [];
    this.quickFiltersRef = null;
    this.scrollViewRef = null;
    this.debounceEndReached = _.debounce(this.onFlatListEndReached, 500);
    this.filtersEnabled = false;
    this.flatListRef = null;
    this.prevAppliedFiltersFromModal = [];
    this.preventPaginationOnFirstTimeQuickFtilersApply = false;
    this.counterFilterPress = -1;

    if (Utility.isPresent(this.property)) {
      this.slug += `&property[]=${this.property}`;
    }
  }

  toggleCartVisibility = () => {
    this.props.navigation.push('Cart', {
      source: 'more_items',
    });
  };

  onCartDismiss = () => {
    this.setState({ isCartVisible: false });
  };

  componentDidMount() {
    const { navigation, selfieImageUrl, getArtistContent, facialProperties } =
      this.props;
    this.timer = null;

    if (Utility.isPresent(this.uaavs)) {
      this.slug += this.uaavs;
    }
    if (this.previousScreen === 'dashboard') {
      getArtistContent(ARTIST_CONSTANTS.artistContentSlug);
      return;
    }
    if (this.fromPersonalizedPage && Utility.isPresent(selfieImageUrl)) {
      this.slug = appendAttributesWithHomePageUrl(
        this.slug,
        facialProperties,
        false,
      );
      // return;
    }
    const paginationCount = RemoteConfig.getValue(
      REMOTE_CONFIG_KEYS.pagination_objects_count,
    );
    this.pageResultItemsCount = paginationCount;

    if (this.slug?.indexOf('fromPersonalizedPage=true') !== -1) {
      this.slug = this.slug?.replace('fromPersonalizedPage=true', '');
    }

    this.hitMorePageApi();
  }

  componentWillUnmount() {
    if (Utility.isAndroid()) {
      this.backhandler.remove();
    }
  }

  toggleCartVisibility = () => {
    this.props.navigation.push('Cart', {
      source: 'more_items',
    });
  };

  isSearchEnabled = () => this.pageNo === 0 && this.filtersEnabled;

  setTimeOutCallback = () => {
    const offsetNumber = Utility.isAndroid() ? 0 : 0;
    const scroll = 0.67 * Utility.getScreenWidth() - offsetNumber;
    // this.flatListRef.scrollToOffset({ animated: true, offset: scroll });
    this.onEndReachedCalledDuringMomentum = true;
  };

  onHardwareBackKeyPress = () => {
    const { navigation } = this.props;
    navigation.goBack();
    return true;
  };

  hitMorePageApi = (hideOosProduct = this.state.hideOosProduct) => {
    const { fetchMorePageList, navigation, route } = this.props;
    const { itemData } = this.state;

    if (Utility.isBlank(route.params?.slug)) {
      return;
    }
    if (this.pageNo === 0 && this.filtersEnabled) {
      setTimeout(this.setTimeOutCallback, 500);
    }
    this.setState({
      showLoadMoreShimmerAtListBottom: !this.isSearchEnabled(),
    });

    // Right now showing shimmer only on filter applied
    if (this.pageNo === 0) {
      // itemData.objects = [];
      this.setState({
        // itemData,
        apiLoading: true,
      });
    }

    AnalyticsUtility.recordTimeWithFilterCounter(
      {
        screen_name: SCREEN_CONSTANTS.MORE_PAGE,
        ...Utility.setRecordTimeEventMeta(itemData, 'list'),
        page_number: this.pageNo,
      },
      this.morePageCallback,
      fetchMorePageList,
      this.slug,
      this.pageNo,
      hideOosProduct,
      this.addFacialAttributesInApiRequest,
      this.counterFilterPress,
    );
  };

  autoHitApiCall = () => {
    setTimeout(() => {
      this.onFlatListEndReached();
    }, 1000);
  };

  morePageCallback = (success, data, pageNo, counterFilterPress, status) => {
    const { itemData, selectedFilters } = this.state;

    this.preventListEmptyStateOnInitialPageLoad = false;

    if (success) {
      Utility.setPageLoadEnd('more_items', pageNo);
      //FIXME: In case if something breaks, Check this section only
      if (
        counterFilterPress !== -1 &&
        this.counterFilterPress !== counterFilterPress
      ) {
        return;
      }

      //FIXME: till here
      this.allowNextPageLoad = data.objects.length >= this.pageResultItemsCount;
      if (pageNo === 0) {
        if (Utility.isPresent(this.property)) {
          if (Utility.isBlank(data.quick_filters)) {
            data.quick_filters = [];
          }
          data.quick_filters = [
            Utility.createPreSelectedFilter(this.property),
            ...data.quick_filters,
          ];
          data.quick_filters = _.uniqBy(data.quick_filters, 'name');
        }
        this.bottomShimmerColumnCount = 1;
        this.setState(
          {
            itemData: data,
            apiLoading: false,
            showLoadMoreShimmerAtListBottom: false,
          },
          this.autoHitApiCall,
        );

        if (Utility.isPresent(data) && Utility.isBlank(data.objects)) {
          FilterAnalyticsEvents.logFilterResultsEmptyEvent(selectedFilters, {
            id: data.id,
            name: data.name,
          });
        }
      } else {
        const objects = [...itemData.objects, ...data.objects];
        itemData.objects = objects;
        this.setState(
          {
            itemData,
            showLoadMoreShimmerAtListBottom: false,
            apiLoading: false,
          },
          this.autoHitApiCall,
        );
      }
      this.trace.stop();
      AnalyticsManager.logEvent(EventType.pageLoad.PAGE_LOAD, {
        [EventParameterKey.SCREEN_NAME]: SCREEN_CONSTANTS.MORE_PAGE,
      });
      AnalyticsManager.logEvent('list_rendered', {
        id: data.id,
      });
      // if (data?.content === 'product') {
      //   let items = [];
      // _.forEach(data.objects, (e) => {    //TODO: Delete it
      //   items.push({ id: e.sku_id });
      // });
      // items = Utility.getItems(data.objects);

      // AnalyticsManager.logFirebaseEvent(
      //   EventType.googleRemarketingEvents.VIEW_ITEM_LIST,
      //   {
      //     items,
      //     item_list_name: data.name,
      //     item_list_id: data.id,
      //   },
      // );
      // }
    } else {
      if (data?.status === 404) {
        Utility.clearPageLoadTimer();
        this.setState({ isPageLoading: false });
      }
      this.setState({
        showLoadMoreShimmerAtListBottom: false,
        apiLoading: false,
        showSearchFooter: true,
      });
    }
    if (!this.allowNextPageLoad) {
      this.setState({
        showSearchFooter: true,
      });
    } else {
      this.setState({
        showSearchFooter: false,
      });
    }
  };

  toggleCartVisibility = () => {
    this.setState((prevState) => ({ isCartVisible: !prevState.isCartVisible }));
  };

  onCartDismiss = () => {
    this.setState({ isCartVisible: false });
  };

  goBack = () => {
    const { navigation } = this.props;
    goBackWithFallbackHome(navigation);
  };

  listConditionFn = (props) =>
    props.content === 'list' && this.previousScreen !== SCREEN_CONSTANTS.ARTIST;

  customNavigationHeader = withMaybe(this.listConditionFn)(
    CustomNavigationHeader,
  );

  conditionalBanner = withMaybe(this.listConditionFn)(View);

  dockedHeader = (props) => {
    const { name } = props;
    return (
      <View style={styles.dockedHeaderContainer}>
        <Text
          numberOfLines={1}
          style={styles.productNameInHeaderStyle}
          allowFontScaling={false}
        >
          {name}
        </Text>
      </View>
    );
  };

  showToast = (message) => {
    this.toast.show(message, 1000);
  };

  updateSelectedOffer = (id) => {
    this.setState((prevState) => {
      const newId = prevState.offerApplied !== id ? id : '';
      return { offerApplied: newId };
    });
  };

  imageLoaded = () => {
    this.setState({ viewRef: findNodeHandle(this.backgroundImage) });
  };

  banner = (props) => {
    let { banner_image_url, content, item, previousScreen } = props;

    if (this.fromPersonalizedPage) {
      return null;
    }

    if (this.item.more_page_display === 'full-page-banner') {
      return null;
    }

    return (
      <View content={content} style={styles.bannerStyle}>
        <Animated.Image // banner background
          useNativeDriver
          ref={(img) => {
            this.backgroundImage = img;
          }}
          source={{
            uri: getMinifiedImage(
              banner_image_url,
              Utility.getScreenWidth(),
              0.67 * Utility.getScreenWidth(),
            ),
          }}
          style={[
            styles.bannerImageStyle,
            {
              height: this.state.scrollY.interpolate({
                inputRange: [-200, 0],
                outputRange: [
                  0.67 * Utility.getScreenWidth() + 200,
                  0.67 * Utility.getScreenWidth(),
                ],
                extrapolate: 'clamp',
              }),
              width: this.state.scrollY.interpolate({
                inputRange: [-200, 0],
                outputRange: [
                  Utility.getScreenWidth() + 200,
                  Utility.getScreenWidth(),
                ],
                extrapolate: 'clamp',
              }),
            },
          ]}
          onLoadEnd={this.imageLoaded}
        />
        {/* <AnimatedBlurView // blurview
          style={[
            styles.bannerImageStyle,
            {
              height: this.state.scrollY.interpolate({
                inputRange: [-200, 0],
                outputRange: [
                  0.67 * Utility.getScreenWidth() + 200,
                  0.67 * Utility.getScreenWidth(),
                ],
                extrapolate: 'clamp',
              }),
              width: this.state.scrollY.interpolate({
                inputRange: [-200, 0],
                outputRange: [
                  Utility.getScreenWidth() + 200,
                  Utility.getScreenWidth(),
                ],
                extrapolate: 'clamp',
              }),
            },
          ]}
          viewRef={this.state.viewRef}
          blurType='light'
          blurAmount={10}
        /> */}
        <Animated.Image // banner image
          useNativeDriver
          ref={(img) => {
            this.backgroundImage = img;
          }}
          source={{
            uri: getMinifiedImage(
              banner_image_url,
              Utility.getScreenWidth(),
              0.67 * Utility.getScreenWidth(),
            ),
          }}
          style={[
            styles.bannerImageStyle,
            {
              resizeMode: 'contain',
              height: this.state.scrollY.interpolate({
                inputRange: [-200, 0],
                outputRange: [
                  0.67 * Utility.getScreenWidth() + 200,
                  0.67 * Utility.getScreenWidth(),
                ],
                extrapolate: 'clamp',
              }),
              width: this.state.scrollY.interpolate({
                inputRange: [-200, 0],
                outputRange: [
                  Utility.getScreenWidth() + 200,
                  Utility.getScreenWidth(),
                ],
                extrapolate: 'clamp',
              }),
            },
          ]}
        />
        <Animated.View // Overlay
          style={[
            styles.imageOverlay,
            {
              height: this.state.scrollY.interpolate({
                inputRange: [-200, 0],
                outputRange: [
                  0.75 * Utility.getScreenWidth() + 200,
                  0.75 * Utility.getScreenWidth(),
                ],
                extrapolate: 'clamp',
              }),
              width: this.state.scrollY.interpolate({
                inputRange: [-200, 0],
                outputRange: [
                  Utility.getScreenWidth() + 200,
                  Utility.getScreenWidth(),
                ],
                extrapolate: 'clamp',
              }),
              useNativeDriver: true,
            },
          ]}
        />
        <View style={styles.infoContainer}>
          <Text numberOfLines={MAX_LINES.two} style={styles.listHeading}>
            {item.name}
          </Text>
        </View>
      </View>
    );
  };

  forceUpdatePersonalisedPageAfterSelfie = () => {
    this.addFacialAttributesInApiRequest = true;
    this.hitMorePageApi();
  };

  getComponent = ({ item, index }) => {
    if (Utility.isBlank(item)) {
      return null;
    }

    const { apiLoading, itemData } = this.state;
    if (apiLoading) {
      return <GridScreenPlaceHolder />;
    }

    if (this.item.more_page_display === 'full-page-banner') {
      this.display = _.camelCase(this.item.more_page_display);
      this.LayoutComponent = this.Components[this.display];
    }
    const { id, size, content, banners = {} } = this.item;
    let bannerObject = {};
    if (!Utility.isBlank(banners.objects)) {
      bannerObject =
        index < banners.objects.length ? [banners.objects[index]] : [];
    }
    return (
      <>
        <this.LayoutComponent
          item={item}
          content={content}
          size={size}
          id={id}
          list={this.item}
          listName={this.item.name}
          listContent={this.item.content}
          listId={this.item.id}
          navigation={this.props.navigation}
          display={this.display}
          displayCount={item.length}
          previousScreen={SCREEN_CONSTANTS.MORE_PAGE}
          toggleCartVisibility={this.toggleCartVisibility}
          showToast={this.showToast}
          listData={itemData}
          listIndex={2}
          forceUpdatePersonalisedPageAfterSelfie={
            this.forceUpdatePersonalisedPageAfterSelfie
          }
          bannerImageUrl={this.bannerImageUrl}
          // paginate
        />
        <Pager item={bannerObject} navigation={this.props.navigation} />
      </>
    );
  };

  resetSlug = () => {
    const { itemData } = this.state;
    this.slug = `${itemData.slug}?Nan=Nan`;
  };

  resetFilters = () => {
    this.pageNo = 0;
    this.allowNextPageLoad = true;

    Utility.isPresent(this.quickFiltersRef) &&
      this.quickFiltersRef.resetQuickFilters();
    this.resetSlug();
    this.setState({
      selectedFilters: {},
    });

    this.hitMorePageApi();
  };

  listEmptyState = () => {
    const { itemData } = this.state;
    if (Utility.isPresent(itemData.objects)) return null;

    if (this.preventListEmptyStateOnInitialPageLoad) return null;

    return (
      <View>
        {/* <this.flatListHeaderViews forceShowBanner /> */}
        <FilterResultsEmptyState onPressResetButton={this.resetFilters} />
      </View>
    );
  };

  groupSelectedFilter = (
    item,
    skipStateSet = false,
    isQuickFilters = false,
  ) => {
    const { selectedFilters } = this.state;
    this.arrayToBeGroup = item;
    if (isQuickFilters && Utility.isPresent(selectedFilters)) {
      const rawArray =
        Utility.convertSelectedFiltersObjectIntoArray(selectedFilters);
      const newArray = [...rawArray, ...item];
      this.arrayToBeGroup = _.uniqBy(newArray, (e) => e.value);
    }

    const groupBySelectedFilters = _.groupBy(
      this.arrayToBeGroup,
      (element) => element.criteria_name,
    );

    if (!skipStateSet) {
      this.setState({
        selectedFilters: groupBySelectedFilters,
      });
    }
    return groupBySelectedFilters;
  };

  removeSelectedQuickFilterInFilterModal = (item) => {
    const { selectedFilters } = this.state;
    const arrCurrentObj = selectedFilters[item.criteria_name];
    const objectsAfterItemRemove = _.remove(
      arrCurrentObj,
      (element) => element.value !== item.value,
    );
    selectedFilters[item.criteria_name] = objectsAfterItemRemove;
    this.setState({
      selectedFilters,
    });
  };

  generateUrlFromQuickFilters = (item, added = true, index) => {
    const { itemData } = this.state;
    this.pageNo = 0;
    this.allowNextPageLoad = true;
    this.filtersEnabled = true;
    this.preventPaginationOnFirstTimeQuickFtilersApply = true;
    if (added) {
      const criteria =
        item.criteria_name === 'sort_by'
          ? item.criteria_name
          : `${item.criteria_name}[]`;
      this.slug = `${this.slug}&${criteria}=${item.value}`;
      this.selectedQuickFilters.push(item);
      this.prevAppliedFiltersFromModal.push(item);
      this.groupSelectedFilter(this.selectedQuickFilters, true);
      FilterAnalyticsEvents.logQuickFilterApplyEvent(item, itemData, index);
    } else {
      const criteria = `&${item.criteria_name}[]=${item.value}`;
      this.slug = this.slug?.replace(criteria, '');
      this.removeSelectedQuickFilterInFilterModal(item);
      FilterAnalyticsEvents.logQuickFilterResetEvent(item, itemData, index);
    }
  };

  generateUrlFromFiltersArray = (item = []) => {
    let url = '';
    item.forEach((element) => {
      if (Array.isArray(element.value)) {
        element.value.forEach((itemValue) => {
          if (itemValue instanceof Object) {
            url = `${url}&${element.criteria_name}[]=${itemValue.value}`;
          } else {
            url = `${url}&${element.criteria_name}[]=${itemValue}`;
          }
        });
      } else {
        url = `${url}&${element.criteria_name}[]=${element.value}`;
      }
    });

    return url;
  };

  toggleQuickFilters = (item, sortFilters) => {
    const { quick_filters = [] } = this.item;
    let highlightedQuickFilers = [];
    highlightedQuickFilers = item.filter((element) =>
      quick_filters.some((filters) => element.value === filters.value),
    );
    this.quickFiltersRef.applyFilters(
      this.prevAppliedFiltersFromModal,
      highlightedQuickFilers,
      sortFilters,
    );
    this.prevAppliedFiltersFromModal = highlightedQuickFilers;
  };

  onQuickFilterPress = (
    item,
    added = true,
    index = -1,
    resetQuickFilter,
    hideOosProduct = this.state.hideOosProduct,
  ) => {
    const { navigation } = this.props;
    const {
      itemData: { slug, name = '', id },
      itemData,
      selectedFilters,
      filters,
      scrollY,
    } = this.state;
    const shouldOpenSortingModal = canOpenSortingModal(item, selectedFilters);
    if (shouldOpenSortingModal) {
      navigation.navigate('SortingFilteringModal', {
        selectedFilters,
        modalFilters: filters,
        onFilterApplyPress: this.onQuickFilterPress,
        scrollY,
        listName: name,
        listId: id,
        listSlug: slug,
        screenName: SCREEN_CONSTANTS.MORE_PAGE,
        listData: itemData,
        entityName: name,
        onPressHideOosCheckBox: this.onPressHideOosCheckBox,
        hideOosProduct,
        showFoxyMatchModal: item.criteria_name === 'quick_filter_foxy_match',
        resetQuickFilter,
      });
      return;
    }

    // Array in case if you select filter from modal.
    if (Array.isArray(item)) {
      const url = this.generateUrlFromFiltersArray(item);
      this.slug = `${slug}?Nan=Nan${url}`;
      const groupBySelectedFilters = this.groupSelectedFilter(item);
      FilterAnalyticsEvents.logFilterApplyEvent(
        groupBySelectedFilters,
        itemData,
        SCREEN_CONSTANTS.MORE_PAGE,
        name,
      );
      this.pageNo = 0;
      this.allowNextPageLoad = true;
      if (Utility.isBlank(item)) {
        this.filtersEnabled = false;
      } else {
        this.filtersEnabled = true;
      }
      const sortByFilters = groupBySelectedFilters.sort_by;
      this.toggleQuickFilters(item, sortByFilters);
    } else {
      this.generateUrlFromQuickFilters(item, added, index);
    }
    this.counterFilterPress += 1;
    this.hitMorePageApi(hideOosProduct);
  };

  onSortingFilterApplied = (updateSortingQuickFilter) => {
    this.updateQuickFilterNameOnSortingFilterApplied = updateSortingQuickFilter;
  };

  quickFiltersList = () => {
    const { quick_filters, filters } = this.item;
    const { filtersFromDeepLink } = this.state;
    if (Utility.isBlank(quick_filters)) return null;
    const inputRange = Utility.isAndroid() ? [120, 150] : [100, 120];
    return (
      <View
        style={{
          overflow: 'hidden',
          width: Utility.getScreenWidth(),
          // paddingBottom: 5,
          zIndex: 1,
          elevation: 2,
        }}
      >
        <View
          style={{
            backgroundColor: colors.white,
            height: 50, // Utility.isAndroid() ? 102 : 142,
          }}
        >
          <SortingFilteringOptions
            ref={(ref) => {
              this.quickFiltersRef = ref;
            }}
            quickFilters={quick_filters}
            filters={filters}
            scrollY={this.state.scrollY}
            onSortingFilterApplied={this.onSortingFilterApplied}
            onQuickFilterPress={this.onQuickFilterPress}
            selectedFilters={this.state.selectedFilters}
            filtersFromDeepLink={filtersFromDeepLink}
          />
        </View>
        <View
          style={{
            height: 1,
            backgroundColor: colors.border,
            width: Utility.getScreenWidth(),
          }}
        />
      </View>
    );
  };

  onMomentumScrollBegin = () => {
    this.onEndReachedCalledDuringMomentum = false;
  };

  onFlatListEndReached = () => {
    if (!this.allowNextPageLoad) {
      return;
    }

    if (this.addFacialAttributesInApiRequest) {
      return;
    }

    // if (this.preventPaginationOnFirstTimeQuickFtilersApply) {
    //   this.preventPaginationOnFirstTimeQuickFtilersApply = false;
    //   return;
    // }

    // if (!this.onEndReachedCalledDuringMomentum) {
    this.pageNo += 1;
    this.hitMorePageApi();
    this.onEndReachedCalledDuringMomentum = true;
    // }
  };

  flatListHeaderViews = (props) => {
    const {
      banner_image_url,
      content,
      image_url,
      offers,
      request_personalized_details = true,
    } = this.item;
    if (content !== 'product') {
      return null;
    }

    if (!request_personalized_details) {
      return null;
    }

    const { forceShowBanner } = props;
    const { offerApplied } = this.state;
    const hideBanners = Utility.isBlank(this.item.objects) && !forceShowBanner;
    return (
      <>
        <FoxyPersonalisedItems
          slug={this.slug}
          subheading='Products in this list which you shouldn’t miss'
        />
      </>
    );
  };

  renderSearchFooter = () => {
    const { navigation } = this.props;
    const navigateToSearch = () => navigation.push('Search', {});
    return (
      <View style={styles.feedFooterContainer}>
        <Text style={styles.footerTextHeading}>
          Didn’t find what you were looking for ?
        </Text>
        <TouchableOpacity onPress={navigateToSearch}>
          <View style={styles.footerTextAndImageContainer}>
            <Text style={styles.footerButtonText}>Search</Text>
            <Image
              style={styles.footerSearchImage}
              source={Images.searchIic_search_productcon}
            />
          </View>
        </TouchableOpacity>
      </View>
    );
  };

  renderActivityIndicator = () => (
    <ActivityIndicator
      size='large'
      style={styles.activityIndicatorContainer}
      color={colors.green}
    />
  );

  listKeyExtractor = (item, index) => `${item.length}_${index}_more`;

  listFooterComponent = () => {
    const { showLoadMoreShimmerAtListBottom, itemData, showSearchFooter } =
      this.state;
    const itemDataObjectCount =
      Utility.isPresent(itemData) && itemData.objects
        ? itemData.objects.length
        : 0;
    const minHeight = 110;
    const maxHeight = Utility.isAndroid() ? 450 : 400;
    const height = itemDataObjectCount >= 4 ? minHeight : maxHeight;

    if (showLoadMoreShimmerAtListBottom) {
      return (
        <ActivityIndicator
          size='large'
          style={styles.activityIndicatorContainer}
          color={colors.green}
        />
      );
    }

    if (!showLoadMoreShimmerAtListBottom) {
      return <View style={{ height: 12 }} />;
    }
    // return <View style={{ height: 60 }} />;
  };

  onPressHideOosCheckBox = (isHideOos) => {
    this.setState({
      hideOosProduct: isHideOos,
    });
  };

  onListStartScroll = (event) => {
    this.onFlatListEndReached();
  };

  onEndReached = () => {
    const { apiLoading } = this.state;
    if (apiLoading) {
      this.pageNo += 1;
      this.hitMorePageApi();
    }
  };

  showEmptySearch = () => {
    const { navigation } = this.props;
    navigation.push('Search', {});
  };

  render() {
    const { selfieImageUrl, navigation, route } = this.props;
    const { isPageLoading } = this.state;
    if (Utility.isPresent(this.state.itemData)) {
      this.item = this.state.itemData;
      this.display =
        this.item.display === LAYOUT.LIST ||
        this.item.display === 'review-grid' ||
        this.item.display === 'rectangular-grid' ||
        this.item.display === 'grid-rail' ||
        this.item.display === 'vertical'
          ? _.camelCase(this.item.display)
          : LAYOUT.GRID;
      if (!Utility.isBlank(this.item.more_page_display)) {
        this.display =
          this.item.more_page_display === 'personalized-vertical' ||
          this.item.more_page_display === 'rectangular-grid' ||
          this.item.display === 'grid-rail'
            ? _.camelCase(this.item.more_page_display)
            : this.item.more_page_display;
      }
    }

    if (Utility.isBlank(this.item) && !isPageLoading) {
      return <PageNotFound goToHome slug={route.params?.slug} />;
    }

    if (Utility.isBlank(this.item)) {
      return <GridScreenPlaceHolder fullscreen />;
    }

    const {
      content,
      banner_image_url,
      slug,
      share_url: shareUrl,
      id,
      type,
      name,
      offers,
      image_url,
      more_page_display,
    } = this.item;
    let { objects = [] } = this.item;
    const {
      itemData: { objects: listObject = [] },
    } = this.state;

    if (Utility.isBlank(listObject) && !isPageLoading) {
      return <PageNotFound goToHome slug={route.params?.slug} />;
    }

    if (Utility.isBlank(objects) && this.fromPersonalizedPage) {
      return <GridScreenPlaceHolder fullscreen />;
    }

    // if (Utility.isBlank(listObject)) {
    //   return <GridScreenPlaceHolder fullscreen />;
    // }

    const { scrollY, offerApplied, apiLoading } = this.state;
    this.LayoutComponent = this.Components[this.display];
    const screenName = `${type} more`;

    const { paginate_last_item } = this.item;
    const objectLength = objects.length - 1;

    return (
      <SafeAreaView
        style={{ flex: 1, backgroundColor: colors.background }}
        forceInset={{ top: 'never', bottom: 'always' }}
      >
        <CustomNavigationHeader
          content={content}
          scrollY={scrollY}
          slug={slug}
          goBack={this.goBack}
          shareUrl={shareUrl}
          fromPersonalizedPage={this.fromPersonalizedPage}
          id={id}
          type={type}
          image={banner_image_url}
          name={name}
          previousScreen={this.previousScreen}
          morePage={Utility.isBlank(this.item.quick_filters)}
          screenName={SCREEN_CONSTANTS.MORE_PAGE}
          morePageDisplay={more_page_display}
          quickFiltersAvailable={Utility.isPresent(this.item.quick_filters)}
          showSearchButton
          onSearchIconTap={this.showEmptySearch}
        >
          <DockedHeader name={name} />
        </CustomNavigationHeader>
        <this.quickFiltersList />
        <ErrorBoundary screen_name={screenName} itemData={this.item}>
          <List
            itemData={this.state.itemData}
            navigation={this.props.navigation}
            enableListingBackgroundColor
            previousScreen={SCREEN_CONSTANTS.MORE_PAGE}
            paginateLastItem={paginate_last_item}
            totalItemsInList={objectLength}
            // getQuickFiltersListRef={getQuickFiltersListRef}
            // stickyPosition={stickyPosition}
            // selectedFilters={selectedFilters}
            // onFiltersPress={onFiltersPress}
            // onPressResetButton={onPressResetButton}
            // // abc={() => {}}
            // setListPaginatedCallApiRef={setListPaginatedCallApiRef}
            // verticalListHeader={this.brandHeader}
            // paginateLastItem={paginateLastItem}
            // totalItemsInList={totalItems}
            // filtersEnabled={filtersEnabled}
            // filteredSlug={filteredSlug}
          />
        </ErrorBoundary>
        <Toast
          style={{ position: 'absolute', bottom: 20 }}
          ref={(ref) => {
            this.toast = ref;
          }}
        />
      </SafeAreaView>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    { fetchRelatedContent, getArtistContent, fetchMorePageList },
    dispatch,
  ),
});

const mapStateToProps = (store, ownProps) => ({
  selfieImageUrl: store.UserAccountInfo.profile.selfie_image_url,
  facialProperties: store.UserAccountInfo.facialAnalysis.facialProperties,
});
export default withNavigation(
  connect(mapStateToProps, mapDispatchToProps)(MoreItems),
);
