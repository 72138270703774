import { Text, StyleSheet, View, TouchableOpacity, ScrollView, Image } from 'react-native';
import React, { useRef } from 'react';
import styles from './styles';
import FastImageView from '../../../components/FastImageView';
import images from '../../../theme/Images';
import Video from '../../../libraries/ReactNativeVideo';
import { isWeb } from '../../../utils/BooleanUtility';
import UploadButtonWeb from '../../../components/productRatings/UploadButtonWeb';

const UploadMediaContent = ({
  onClickMediaUploadButton,
  deleteImage,
  navigation,
  media = [],
  appendImageCardStyle,
  onUploadMediaPress,
}) => {
  const fileInputRef = useRef(null);

  const handleFilePicker = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const onUploadButtonPressWeb = async (event) => {
    handleFileChange(event, onUploadMediaPress);
  };

  const handleFileChange = async (event, onUploadMediaPress) => {
    const files = event.target.files;
    const fileListArray = Array.from(files);

    const imageArray = await Promise.all(
      fileListArray.map(async (file) => {
        return new Promise((resolve) => {
          if (isWeb()) {
            const reader = new FileReader();
            reader.onload = (e) => {
              resolve(e.target.result);
            };
            reader.readAsDataURL(file);
          } else {
            resolve(null);
          }
        });
      }),
    );
    const imageDataArray = fileListArray.map((file, index) => ({
      type: file.type,
      data: imageArray[index],
    }));

    onUploadMediaPress(imageDataArray);
  };

  const SelectedImages = () => {
    const renderEachImage = (image) => {
      const onPress = () => {
        deleteImage(media.indexOf(image));
      };

      const Thumbnail = () => {
        if (String(image?.mime).includes('image')) {
          return <FastImageView source={image.path} style={styles.selectedMedia} />;
        }

        let player;
        const assignRef = (ref) => {
          player = ref;
        };
        const seekTo0 = () => {
          player.seek(0);
        };
        return (
          <>
            <Video
              style={styles.selectedMedia}
              source={{ uri: image.path }}
              paused
              resizeMode='cover'
              ref={assignRef}
              onLoad={seekTo0}
            />
            <TouchableOpacity style={styles.videoPauseIconStyle}>
              <FastImageView source={images.video_pause_button} style={styles.videoPauseIcon} />
            </TouchableOpacity>
          </>
        );
      };

      const ThumbnailWeb = () => {
        const { type = '', data = {} } = image;
        if (type.includes('image')) {
          return <FastImageView source={{ uri: data }} style={styles.selectedMedia} />;
        } else if (type.includes('video')) {
          let player;
          const assignRef = (ref) => {
            player = ref;
          };
          const seekTo0 = () => {
            player.seek(0);
          };
          return (
            <>
              <Video
                style={styles.selectedMedia}
                source={{ uri: data }}
                paused
                resizeMode='cover'
                ref={assignRef}
                onLoad={seekTo0}
              />
              <TouchableOpacity style={styles.videoPauseIconStyle}>
                <FastImageView source={images.video_pause_button} style={styles.videoPauseIcon} />
              </TouchableOpacity>
            </>
          );
        }
        return null;
      };

      return (
        <View style={styles.selectedMediaContainer}>
          {isWeb() ? <ThumbnailWeb /> : <Thumbnail />}
          <TouchableOpacity onPress={onPress} style={styles.crossIconContainer}>
            <FastImageView source={images.white_cross} style={styles.crossIcon} />
          </TouchableOpacity>
        </View>
      );
    };

    const RenderAppendButton = () => {
      if (media.length > 7) {
        return <View style={styles.emptyPlaceHolder} />;
      }
      return (
        <>
          <TouchableOpacity
            style={appendImageCardStyle}
            onPress={isWeb() ? handleFilePicker : onClickMediaUploadButton}
          >
            <FastImageView source={images.upload_image_camera_icon} style={styles.imageStyle} />
          </TouchableOpacity>
          {isWeb() && <UploadButtonWeb fileInputRef={fileInputRef} handleFileChange={onUploadButtonPressWeb} />}
        </>
      );
    };

    return (
      <ScrollView horizontal showsHorizontalScrollIndicator={false} style={styles.scrollViewContainer}>
        <RenderAppendButton />
        <View style={styles.selectedMediaRailContainer}>{media.map(renderEachImage)}</View>
      </ScrollView>
    );
  };

  return <SelectedImages />;
};

export default UploadMediaContent;
