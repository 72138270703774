import React from 'react';
import { StyleSheet } from 'react-native';
import images from '../theme/Images';
import Utility from './Utility';
import { Image } from 'react-native';
import {
  TabNavigationLeftHeader,
  TabNavigationRightHeader,
} from '../components/header';
import { memoize } from 'lodash';
import { TABBAR_CONSTANT } from '../config/Constants';

export default class NavigationStacksUtility {
  static getListTabStackNavigationOptions = (props) => {
    const {
      label,
      activeImageTintColor,
      inactiveImageTintColor,
      activeIconUrl,
      inactiveIconUrl,
      activeTextColor,
      inactiveTextColor,
    } = props;
    return {
      tabBarLabel: label,
      tabBarActiveTintColor: activeTextColor,
      tabBarInactiveTintColor: inactiveTextColor || '#979BAA',
      tabBarStyle: { borderTopWidth: 0, elevation: 0 },
      tabBarOptions: {
        style: { borderTopWidth: 0 },
        labelStyle: {
          fontSize: 11,
          fontFamily: 'Roboto-Regular',
          // marginBottom: 4,
        },
      },
      tabBarOnPress: ({ navigation, defaultHandler }) => {
        defaultHandler();
        const { state: { routes = [] } = {} } = navigation;
        if (!navigation.isFocused()) return;
        if (Utility.isPresent(routes)) {
          const route = routes[0];
          const { params: { scrollToTop = () => {} } = {} } = route;
          scrollToTop();
        }
      },
      tabBarIcon: ({ focused }) => {
        const focusedTabImage = Utility.isPresent(activeIconUrl)
          ? { uri: activeIconUrl }
          : images.tab.offer_selected;

        const tabImage = Utility.isPresent(inactiveIconUrl)
          ? { uri: inactiveIconUrl }
          : images.tab.offer_unselected;

        const image = focused ? focusedTabImage : tabImage;

        const tintColor = focused ? activeImageTintColor : inactiveImageTintColor;
        const imageStyle = getMemoizedImageStyle(tintColor);
        return (
          <Image
            source={image}
            style={imageStyle}
            tintColor={tintColor}
          />
        );
      },
    };
  };

  static getListTabHeaderOptions = (props) => {
    const { title } = props;
    return {
      headerShown: true,
      headerLeft: () => <TabNavigationLeftHeader tabBar={TABBAR_CONSTANT.listTab} />,
      headerRight: () => <TabNavigationRightHeader tabBar={TABBAR_CONSTANT.listTab} />,
      headerBackVisible: false,
      headerShadowVisible: false,
      title,
      headerTitleAlign: 'center',
    };
  };
}

const styles = StyleSheet.create({
  icon: {
    height: 24,
    width: 24,
    resizeMode: 'contain',
  },
});

const getMemoizedImageStyle = memoize((tintColor) => ([
  styles.icon,
  { tintColor },
]));
