import React, { Component } from 'react';
import { View, TouchableOpacity, Image } from 'react-native';
import PropTypes from 'prop-types';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import images from '../../theme/Images';
import { sharedStyles } from './styles';
import { LAYOUT, LOGIN_MODAL_MESSAGES } from '../../config/Constants';
import Utility from '../../utils/Utility';
import { like, unLike, followBrand } from '../../actions/ActionTypes';
import { setPhoneNumberModalInfo } from '../../actions/LoginActions';
import { withEither } from '../../lib/Monads';
import colors from '../../theme/Colors';
import {
  EventParameterKey,
  AnalyticsManager,
  EventType,
  EventParameterValue,
} from '../../analytics';

class LikeButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      liked: false,
    };
  }

  updateLikedState = (id, type, showLiked, slug) => {
    const {
      authToken,
      mrp = '',
      skuId = '',
      onLike,
      like,
      unLike,
      setPhoneNumberModalInfo,
      extraEventParameters = {},
    } = this.props;
    const url = slug?.replace(
      '.json',
      showLiked ? '/unlike.json' : '/like.json',
    );
    const data = {
      id,
      type,
      url,
    };
    const { screenName, itemName } = this.props;
    const analyticsMeta = {
      [EventParameterKey.SCREEN_NAME]: screenName,
      [EventParameterKey.ITEM_ID]: data.id,
      [EventParameterKey.ITEM_NAME]: itemName,
      [EventParameterKey.ITEM_TYPE]: data.type,
      ...extraEventParameters,
    };
    this.setState({ liked: !showLiked });
    if (showLiked) {
      unLike(data);
      AnalyticsManager.logEvent(
        EventType.discoveryEvents.ITEM_UNLIKE,
        analyticsMeta,
      );
    } else {
      like(data);
      try {
        AnalyticsManager.logEvent(
          EventType.discoveryEvents.ITEM_LIKE,
          analyticsMeta,
        );
        AnalyticsManager.logFirebaseEvent(
          EventType.googleRemarketingEvents.ADD_TO_WISHLIST,
          {
            [EventParameterKey.CURRENCY]: 'INR',
            [EventParameterKey.VALUE]: mrp, // TODO: Get value when this is a product card
            [EventParameterKey.ITEMS]: [{ id: skuId }],
          },
        );
        AnalyticsManager.logFBStandardEvent(
          EventType.FB.EVENT_NAME_ADDED_TO_WISHLIST,
          mrp,
          {
            [EventParameterKey.FB.EVENT_PARAM_CURRENCY]: 'INR',
            [EventParameterKey.FB.EVENT_PARAM_CONTENT_ID]: `${skuId}`,
            [EventParameterKey.FB.EVENT_PARAM_CONTENT_TYPE]: 'product',
          },
        );
        onLike();
      } catch (e) {
        console.tron.log(e);
      }
    }
  };

  navigationHeaderLikeButton = (props) => {
    const { id, type, slug, showLiked } = props;
    const styles = sharedStyles;
    const likeImageStyle = showLiked
      ? styles.likeButtonImage
      : styles.unLikeButtonImage;
    const source = showLiked ? images.likeImage : images.unlikeImage;
    return (
      <TouchableOpacity
        style={styles.likeButtonContainerForHeader}
        onPress={() => this.updateLikedState(id, type, showLiked, slug)}
        hitSlop={Utility.getHitSlop('likeButton')}
      >
        <Image source={source} style={likeImageStyle} />
      </TouchableOpacity>
    );
  };

  listOrCardLikeButton = (props) => {
    const {
      showLiked,
      conditionalRenderFn,
      layout,
      id,
      type,
      slug,
      tintColor,
    } = props;
    const { matchedProduct } = this.props;
    if (conditionalRenderFn && conditionalRenderFn(layout)) return null;
    const unlikeImage =
      layout === LAYOUT.CARD ? images.unlikeImageWhite : images.unlikeImage;
    const styles = sharedStyles;
    return (
      <View style={styles.likeButtonStyle}>
        <TouchableOpacity
          onPress={() => this.updateLikedState(id, type, showLiked, slug)}
          hitSlop={
            matchedProduct
              ? Utility.getHitSlop('matched')
              : Utility.getHitSlop('likeButton')
          }
        >
          <Image
            source={showLiked ? images.likeImage : unlikeImage}
            style={styles.listOrCardLikeImage}
          />
        </TouchableOpacity>
      </View>
    );
  };

  likeProduct = () => {
    const {
      id,
      type,
      liked,
      slug,
      layout = 'list',
      conditionalRenderFn,
      tintColor = null,
      addToCartLikeButton,
    } = this.props;
    const showLiked = this.state.liked || liked;
    this.updateLikedState(id, type, showLiked, slug);
  };

  productPageLeftSplitChildLikeButton = (props) => {
    const {
      showLiked,
      conditionalRenderFn,
      layout,
      id,
      type,
      slug,
      tintColor,
    } = props;
    if (conditionalRenderFn && conditionalRenderFn(layout)) return null;
    const unlikeImage =
      layout === LAYOUT.CARD ? images.unlikeImageWhite : images.unlikeImage;
    const styles = sharedStyles;
    return (
      <View
        style={{
          alignContent: 'center',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          elevation: 3,
        }}
      >
        <View style={styles.addToCartLikeButtonStyles}>
          <TouchableOpacity
            onPress={() => this.updateLikedState(id, type, showLiked, slug)}
            hitSlop={Utility.getHitSlop('likeButton')}
          >
            <Image
              source={showLiked ? images.likeImage : unlikeImage}
              style={{
                width: 20,
                height: 18,
                resizeMode: 'stretch',
                tintColor: !showLiked ? tintColor : null,
              }}
            />
          </TouchableOpacity>
        </View>
      </View>
    );
  };

  updateLikeOnDoubleTap = () => {
    console.tron.log('update like on doub tap called');
    const { id, type, liked, slug = '' } = this.props;
    const showLiked = this.state.liked || liked;
    this.updateLikedState(id, type, showLiked, slug);
  };

  listOrCardConditionFn = (props) =>
    props.layout === LAYOUT.LIST || props.layout === LAYOUT.CARD;

  likeButton = compose(
    withEither(this.listOrCardConditionFn, this.listOrCardLikeButton),
  )(this.navigationHeaderLikeButton);

  render() {
    const {
      id,
      type,
      liked,
      slug,
      layout = 'list',
      conditionalRenderFn,
      tintColor = null,
      addToCartLikeButton,
    } = this.props;

    const showLiked = this.state.liked || liked;

    if (addToCartLikeButton) {
      return (
        <this.productPageLeftSplitChildLikeButton
          id={id}
          type={type}
          showLiked={showLiked}
          slug={slug}
          layout={layout}
          conditionalRenderFn={conditionalRenderFn}
          tintColor={tintColor}
        />
      );
    }
    return (
      <this.likeButton
        id={id}
        type={type}
        showLiked={showLiked}
        slug={slug}
        layout={layout}
        conditionalRenderFn={conditionalRenderFn}
        tintColor={tintColor}
      />
    );
  }
}

LikeButton.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  authToken: PropTypes.string,
  mrp: PropTypes.string,
  skuId: PropTypes.string,
  layout: PropTypes.string,
  screenName: PropTypes.string,
  itemName: PropTypes.string,
  liked: PropTypes.bool,
  addToCartLikeButton: PropTypes.bool,
  tintColor: PropTypes.string,
  setPhoneNumberModalInfo: PropTypes.func,
  conditionalRenderFn: PropTypes.func,
  like: PropTypes.func,
  unLike: PropTypes.func,
  onLike: PropTypes.func,
};

LikeButton.defaultProps = {
  authToken: null,
  mrp: '',
  skuId: '',
  layout: null,
  liked: false,
  addToCartLikeButton: false,
  tintColor: null,
  screenName: 'Unknown',
  itemName: 'Unknown',
  setPhoneNumberModalInfo: () => {},
  conditionalRenderFn: () => {},
  like: () => {},
  unLike: () => {},
  onLike: () => {},
};

const mapStateToProps = (store, ownProps) => {
  const { id } = ownProps;
  return {
    liked: !!(
      store.UserAccountInfo.favourites[`${ownProps.type}s`] &&
      store.UserAccountInfo.favourites[`${ownProps.type}s`][id]
    ),
    authToken: store.UserAccountInfo.authToken,
  };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      followBrand,
      like,
      unLike,
      setPhoneNumberModalInfo,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(LikeButton);
