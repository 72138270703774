import Config from '../libraries/ReactNativeConfig';
import colors from '../theme/Colors';
import { getDynamicWidthForGrid } from './LayoutUtility';

const DYNAMIC_CARD_ELEMENT_HEIGHTS = {
  bottom_rating: 28,
  single_line_title: 18,
  single_line_subtitle: 16,
  default_product_description: 96,
  brand_title: 24,
  color_swatches: 30,
};

const DYNAMIC_CARD_AND_IMAGE_DIMENSIONS = {
  rail: {
    card_width: getDynamicWidthForGrid(2, 16) * 1.2,
    square_image_height: getDynamicWidthForGrid(2, 16) * 1.2,
    vertical_image_height: (4 / 3) * getDynamicWidthForGrid(2, 16) * 1.2,
  },
  grid: {
    card_width: getDynamicWidthForGrid(2, 16),
    square_image_height: getDynamicWidthForGrid(2, 16),
    vertical_image_height: (4 / 3) * getDynamicWidthForGrid(2, 16),
  },
};

export const DYNAMIC_CARD_DEFAULT_STYLES = {
  gridCardStyle: {
    backgroundColor: colors.white,
    borderColor: colors.borderColor,
    margin: 4,
    flexDirection: 'column',
    borderRadius: 4,
    width: DYNAMIC_CARD_AND_IMAGE_DIMENSIONS.grid.card_width,
    alignSelf: 'center',
  },
  railCardStyle: {
    backgroundColor: colors.white,
    borderColor: colors.borderColor,
    margin: 4,
    flexDirection: 'column',
    borderRadius: 4,
    width: DYNAMIC_CARD_AND_IMAGE_DIMENSIONS.rail.card_width,
    alignSelf: 'center',
  },
  gridSquareImageStyle: {
    height: DYNAMIC_CARD_AND_IMAGE_DIMENSIONS.grid.square_image_height,
    width: DYNAMIC_CARD_AND_IMAGE_DIMENSIONS.grid.square_image_height,
  },
  gridVerticalImageStyle: {
    height: DYNAMIC_CARD_AND_IMAGE_DIMENSIONS.grid.vertical_image_height,
    width: DYNAMIC_CARD_AND_IMAGE_DIMENSIONS.grid.square_image_height,
  },
  railSquareImageStyle: {
    height: DYNAMIC_CARD_AND_IMAGE_DIMENSIONS.rail.square_image_height,
    width: DYNAMIC_CARD_AND_IMAGE_DIMENSIONS.rail.square_image_height,
  },
  railVerticalImageStyle: {
    height: DYNAMIC_CARD_AND_IMAGE_DIMENSIONS.rail.vertical_image_height,
    width: DYNAMIC_CARD_AND_IMAGE_DIMENSIONS.rail.square_image_height,
  },
};

export const getCardContainerStyle = (listOptions = {}, layout) => {
  const {
    ratings_position:
      ratingsPosition = Config.DYNAMIC_PRODUCT_CARD_RATINGS_POSITION || 'hide',
    title_line_count:
      titleLineCount = Config.DYNAMIC_PRODUCT_CARD_TITLE_LINE_COUNT || '1',
    subtitle_line_count:
      subtitleLineCount = Config.DYNAMIC_PRODUCT_CARD_SUBTITLE_LINE_COUNT ||
        '0',
    image_aspect_ratio:
      imageAspectRatio = Config.DYNAMIC_PRODUCT_CARD_IMAGE_ASPECT_RATIO ||
        '1:1',
    hide_brand_name:
        hideBrandName = Config.DYNAMIC_PRODUCT_CARD_HIDE_BRAND_NAME || '1',
    show_color_swatches = '0',
  } = listOptions ?? {};

  const cardContainerStyle = {
    ...DYNAMIC_CARD_DEFAULT_STYLES[`${layout}CardStyle`],
  };
  const imageType = imageAspectRatio === '1:1' ? 'square' : 'vertical';

  cardContainerStyle.height =
    DYNAMIC_CARD_AND_IMAGE_DIMENSIONS[layout][`${imageType}_image_height`] +
    DYNAMIC_CARD_ELEMENT_HEIGHTS.default_product_description;

  cardContainerStyle.height +=
    parseInt(titleLineCount) * DYNAMIC_CARD_ELEMENT_HEIGHTS.single_line_title;

  cardContainerStyle.height +=
    parseInt(subtitleLineCount) *
    DYNAMIC_CARD_ELEMENT_HEIGHTS.single_line_subtitle;

  if (ratingsPosition === 'bottom') {
    cardContainerStyle.height += DYNAMIC_CARD_ELEMENT_HEIGHTS.bottom_rating;
  }

  if (hideBrandName !== '1') {
    cardContainerStyle.height += DYNAMIC_CARD_ELEMENT_HEIGHTS.brand_title;
  }

  if (show_color_swatches === '1') {
    cardContainerStyle.height += DYNAMIC_CARD_ELEMENT_HEIGHTS.color_swatches;
  }

  return cardContainerStyle;
};
