// Dependencies
import React, { PureComponent } from 'react';
import {
  View,
  Dimensions,
  Text,
  TouchableHighlight,
  ActivityIndicator,
  StyleSheet,
} from 'react-native';
import PropTypes from 'prop-types';
// Components
import colors from '../theme/Colors';
import size from '../theme/Fonts';
import Utility from '../utils/Utility';
import { AnalyticsManager, EventType, EventParameterKey } from '../analytics';
import { ProductDetailStyles } from '../components/Product/styles';
import DebouncedTouchableHighlight from '../components/shared/DebouncedTouchableHighlight';
import FastImageView from '../components/FastImageView';
import LinearGradient from 'react-native-linear-gradient';
import { isDesktop } from '../utils/BooleanUtility';

const { width, height } = Dimensions.get('window');

export default class FoxyShadowButton extends PureComponent {
  componentDidMount() {
    const { appFirstLoadTime } = this.props;
    if (appFirstLoadTime) {
      const diff = Utility.getTimeDiff(new Date(), appFirstLoadTime, true);

      AnalyticsManager.logEvent(EventType.onboardingEvent.SCREEN_LOAD, {
        [EventParameterKey.LOAD_TIME]: diff,
      });
    }
  }

  render() {
    const {
      borderRadius,
      backgroundColor,
      onPress,
      title,
      underlayColor,
      Loading = false,
      width,
      height,
      style,
      textStyle,
      disabled = false,
      buttonTextColor,
      border,
      actionIcon,
      secondaryButtonIcon,
      primaryButtonIcon,
      showActionIcon,
      actionIconStyle = ProductDetailStyles.detailAddToCartImageBuyNow,
      underlayWidth = width,
      iconTintColor = '',
      disabledButtonFirstColor = colors.disabled,
      disabledButtonSecondColor = colors.disabled,
      firstColor = colors.linearGradientGreenFirst,
      secondColor = colors.linearGradientGreenSecond,
    } = this.props;
    const buttonColor = disabled
      ? [disabledButtonFirstColor, disabledButtonSecondColor]
      : [firstColor, secondColor];
    return (
      <LinearGradient
        start={{ x: 0, y: 0 }}
        end={{ x: 1, y: 0 }}
        colors={buttonColor}
        style={[
          style,
          {
            borderRadius,
            justifyContent: 'center',
            alignItems: 'center',
          },
          border,
        ]}
      >
        <DebouncedTouchableHighlight
          underlayColor={underlayColor}
          onPress={onPress}
          style={{
            width: underlayWidth,
            height,
            borderRadius,
          }}
          disabled={disabled || Loading}
        >
          <View style={styles.container}>
            <View
              style={styles.innerContainer}
              testID='foxy-continue-button'
              accessibilityLabel='foxy-continue-button'
            >
              {showActionIcon && (
                <FastImageView
                  source={actionIcon}
                  style={actionIconStyle}
                  tintColor={iconTintColor || textStyle?.color || '#fff'}
                  resizeMode='contain'
                />
              )}
              {!Loading ? (
                <Text
                  style={[
                    {
                      fontSize: size.h2,
                      color: colors.background,
                      fontFamily: 'Roboto-Medium',
                      alignSelf: 'center',
                    },
                    textStyle,
                  ]}
                  numberOfLines={1}
                  ellipsizeMode='tail'
                  allowFontScaling={false}
                >
                  {title}
                </Text>
              ) : (
                <ActivityIndicator color={colors.white} size='small' />
              )}
            </View>
          </View>
        </DebouncedTouchableHighlight>
      </LinearGradient>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  innerContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
  buttonText: {
    fontSize: size.h2,
    color: colors.background,
    fontWeight: '500',
    fontFamily: 'Roboto',
    alignSelf: 'center',
  },
});

FoxyShadowButton.propTypes = {
  underlayColor: PropTypes.string,
  title: PropTypes.string,
  backgroundColor: PropTypes.string,
  borderRadius: PropTypes.number,
  onPress: PropTypes.func,
  Loading: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
};

FoxyShadowButton.defaultProps = {
  underlayColor: colors.enabledPress,
  title: 'Continue',
  backgroundColor: colors.enabled,
  borderRadius: 4,
  onPress: () => {},
  Loading: false,
  // width: width / 1.154,
  // height: height / 14.92,
  height: 46,
  width: 312,
};
