import React, { PureComponent } from 'react';
import { View, StyleSheet, Text, Image } from 'react-native';
import ShimmerPlaceHolder from '../../libraries/ReactNativeShimmerPlaceholder';
import colors from '../../theme/Colors';
import size from '../../theme/Fonts';
import images from '../../theme/Images';
import Utility from '../../utils/Utility';
import FastImageView from '../FastImageView';
import { isBlank } from '../../utils/BooleanUtility';
import { getScreenWidth } from '../../utils/LayoutUtility';

class ThankYouCard extends PureComponent {
  renderShimmer = () => {
    const shimmerColors = ['#fafafa', '#eeeeee', '#fafafa'];
    return (
      <View style={styles.placeHolderContainer}>
        <View style={styles.titlePlaceHolderContainer}>
          <ShimmerPlaceHolder
            colorShimmer={shimmerColors}
            autoRun
            style={styles.textPlaceHolder}
          />
          <ShimmerPlaceHolder
            colorShimmer={shimmerColors}
            autoRun
            style={styles.imagePlaceHolder}
          />
        </View>

        <ShimmerPlaceHolder
          colorShimmer={shimmerColors}
          autoRun
          style={styles.subTitlePlaceHolder}
        />
        <ShimmerPlaceHolder
          colorShimmer={shimmerColors}
          autoRun
          style={styles.subTitlePlaceHolder2}
        />
      </View>
    );
  };

  render() {
    const {
      artistName = '',
      title = '',
      subTitle = '',
      isLoading = '',
    } = this.props;
    if (isLoading) return <this.renderShimmer />;
    const thankyouCardTitle = isBlank(title) ? 'Thank you for joining' : title;
    const thankYouCardSubTitle = isBlank(subTitle)
      ? `Thank you for joining with ${artistName}`
      : subTitle;
    return (
      <View style={styles.container}>
        <View style={styles.textContainer}>
          <Text style={styles.title}>{thankyouCardTitle}</Text>
          <Text style={styles.subtitle} numberOfLines={2}>
            {thankYouCardSubTitle}
          </Text>
        </View>
        <FastImageView
          source={images.routines.fireWorks}
          style={styles.image}
        />
      </View>
    );
  }
}

export default ThankYouCard;

const styles = StyleSheet.create({
  container: {
    borderRadius: 12,
    backgroundColor: '#FFF4EB',
    flexDirection: 'row',
    alignItems: 'center',
    height: 84,
    marginHorizontal: 12,
    marginVertical: 20,
  },
  textContainer: {
    marginHorizontal: 12,
    width: Utility.getScreenWidth() - 130,
  },
  title: {
    color: colors.foxyBlack,
    fontSize: size.h1,
    fontFamily: 'Roboto-Bold',
    marginTop: 4,
  },
  subtitle: {
    color: colors.foxyBlack,
    fontSize: size.h3,
    fontFamily: 'Roboto',
    flexShrink: 1,
    marginBottom: 4,
    marginTop: 6,
  },
  image: {
    height: 70,
    width: 70,
    marginRight: 12,
  },

  placeHolderContainer: {
    height: 120,
    width: getScreenWidth() - 30,
    marginLeft: 15,
    borderRadius: 12,
    marginVertical: 30,
    backgroundColor: colors.white,
    paddingHorizontal: 12,
    paddingVertical: 10,
    // alignContent: 'center',
    // justifyContent: 'center',
  },
  textPlaceHolder: {
    width: Utility.getScreenWidth() - 200,
    height: 20,
    borderRadius: 10,
  },
  subTitlePlaceHolder: {
    width: Utility.getScreenWidth() - 140,
    height: 12,
    borderRadius: 4,
    marginTop: 12,
  },
  titlePlaceHolderContainer: {
    flexDirection: 'row',
    // alignContent: 'center',
    alignItems: 'center',
  },
  imagePlaceHolder: {
    height: 40,
    width: 40,
    borderRadius: 20,
    marginLeft: 20,
    alignSelf: 'center',
  },
  subTitlePlaceHolder2: {
    width: Utility.getScreenWidth() - 80,
    height: 12,
    borderRadius: 4,
    marginTop: 12,
  },
});
