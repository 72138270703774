// Dependencies
import React, { PureComponent, Suspense } from 'react';
import {
  View,
  NativeModules,
  ImageBackground,
} from 'react-native';
import { FlatList } from 'react-native-gesture-handler';
import { capitalize, max, upperFirst } from 'lodash';
import PropTypes from 'prop-types';
import {
  LAYOUT,
  ORIENTATION,
  REMOTE_CONFIG_KEYS,
} from '../../../config/Constants';
import Utility from '../../../utils/Utility';

import styles from './styles';
import { LAYOUT_CONFIG } from '../../../config/LayoutConstants/LayoutConfig';
import { SCREEN_CONSTANTS } from '../../../config/ScreenConstants';
import { Viewport } from '../../../libraries/Viewport';

import { HorizontalProductRail } from '../../Product';

import { getRailComponent, getRailHeight } from './RailHelper';
import ErrorBoundaryComponent from '../../shared/ErrorBoundaryComponent';
import { isDesktop } from '../../../utils/BooleanUtility';
import { getMinifiedImage } from '../../../utils/ImageUtility';
import { FlatListPerformanceView } from '../../../libraries/ReactNativePerformanceShopify';
import { getListSource } from '../../../utils/PerfUtility';
import { isIOS } from '../../../utils/BooleanUtility';

class Rail extends PureComponent {
  static getComponentHeight(item) {
    return getRailHeight(item);
  }

  constructor(props) {
    super(props);
    const { item = {}, content, size, display, listData } = props;
    const { UIManager } = NativeModules;
    UIManager.setLayoutAnimationEnabledExperimental &&
      UIManager.setLayoutAnimationEnabledExperimental(true);
    this.ContainerComponent = getRailComponent(
      item[0]?.type,
      content,
      size,
      display,
    );
    this.cardHeight = getRailHeight(listData)
    this.viewedItemIds = [];
    this.itemData = item;
    this.memoizedEmptyCardWidth = [];
  }

  getComponent = (item, index) => {
    if (Utility.isBlank(item)) {
      return null;
    }
    const {
      id,
      size,
      navigation,
      content,
      showRating,
      toggleCartVisibility,
      search = false,
      listIndex,
      searchQuery,
      elementItemCounts,
      listName,
      display,
      previousScreen,
      listData,
      listData: { slug: listSlug = '' },
      onPress,
      maxFreeItemsToSelect,
      showToast,
      listContent,
      onItemPress = () => {},
      routineActivityId = '',
      extraEventParameters = {},
      parentListsData = [],
      currentSku,
      fromProductPage,
    } = this.props;

    const CardContainer = getRailComponent(
      item.type,
      content,
      size,
      display,
      listData?.objects?.length,
    );

    if (!CardContainer) {
      return null;
    }
    return (
      <ErrorBoundaryComponent
        itemData={item}
        listData={listData}
        screenName={previousScreen}
      >
        <Suspense>
          <CardContainer
            id={item.id}
            listName={listName}
            listContent={listContent}
            itemData={item}
            item={item}
            data={item}
            layout={LAYOUT.RAIL}
            navigation={navigation}
            size={size}
            type={item.type}
            orientation={ORIENTATION.VERTICAL}
            listId={id}
            index={index}
            showRating={showRating}
            toggleCartVisibility={toggleCartVisibility}
            listIndex={listIndex}
            search={search}
            searchQuery={searchQuery}
            elementItemCounts={elementItemCounts}
            previousScreen={previousScreen}
            listSlug={listSlug}
            listData={listData}
            onPress={onPress}
            refreshOfferStrip={this.props.refreshOfferStrip}
            maxFreeItemsToSelect={maxFreeItemsToSelect}
            showToast={showToast}
            display={display}
            onItemPress={onItemPress}
            routineActivityId={routineActivityId}
            parentListsData={parentListsData}
            extraEventParameters={extraEventParameters}
            currentSku={currentSku}
            fromProductPage={fromProductPage}
            key={item.id}
          />
        </Suspense>
      </ErrorBoundaryComponent>
    );
  };

  singleHorizontalProductRailCard = () => {
    const {
      id,
      size,
      navigation,
      content,
      showRating,
      toggleCartVisibility,
      search = false,
      listIndex,
      searchQuery,
      elementItemCounts,
      listName,
      display,
      previousScreen,
      listData,
      listData: { slug: listSlug = '' } = {},
      onPress,
      maxFreeItemsToSelect,
      showToast,
      listContent,
      item,
    } = this.props;
    return (
      <View style={styles.railViewSingleCard}>
        <HorizontalProductRail
          id={item.id}
          listName={listName}
          listContent={listContent}
          listSlug={listSlug}
          itemData={item[0]}
          data={item}
          layout={LAYOUT.RAIL}
          navigation={navigation}
          size={size}
          type={item.type}
          orientation={ORIENTATION.VERTICAL}
          listId={id}
          index={0}
          showRating={showRating}
          toggleCartVisibility={toggleCartVisibility}
          listIndex={listIndex}
          search={search}
          searchQuery={searchQuery}
          elementItemCounts={elementItemCounts}
          previousScreen={previousScreen}
          listData={listData}
          onPress={onPress}
          refreshOfferStrip={this.props.refreshOfferStrip}
          maxFreeItemsToSelect={maxFreeItemsToSelect}
          showToast={showToast}
          isSingleItem={true}
        />
      </View>
    );
  };

  emptyCardView = () => {
    const {
      listData: {
        options: { rail_offset_percentage: railOffsetPercentage = '' } = {},
      } = {},
    } = this.props;
    let absoluteWidth =
      (railOffsetPercentage / 100) * Utility.getScreenWidth();
    if (isDesktop()) {
      absoluteWidth /= 2;
    }
    return <View style={this.getMemoizedEmptyCardWidth(absoluteWidth)} />;
  };

  getMemoizedEmptyCardWidth = (width) => {
    if (!this.memoizedEmptyCardWidth[width]) {
      this.memoizedEmptyCardWidth[width] = [
        styles.emptyCardView,
        { width: width },
     ];
    }
    return this.memoizedEmptyCardWidth[width];
  };

  renderItems = ({ item, index }) => this.getComponent(item, index);

  keyExtractor = (item, index) => `${index}_${item.id}_rail`;

  listKey = (item, index) => `${listKey}${index}_${item.id}_rail`;

  getItemLayout = (item, index) => {
    return { length: this.cardHeight, offset: this.cardHeight * index, index };
  };

  render() {
    const {
      feed,
      previousScreen,
      ignoreMinCount,
      listKey,
      content = '',
      search,
      size,
      listData,
      listData: { background_image_url: backGroundImageUrl = '' } = {},
      item = {},
      navigation,
      listName,
    } = this.props;

    let displayCount = feed
      ? max([LAYOUT_CONFIG.minRailCount, this.props.displayCount || 0])
      : this.props.displayCount || 0;

    if (
      Utility.isBlank(this.itemData) ||
      (!ignoreMinCount &&
        this.itemData.length < LAYOUT_CONFIG.minRailCount &&
        listData?.content !== 'tall_banner')
    ) {
      if (
        previousScreen === SCREEN_CONSTANTS.FEED ||
        previousScreen === SCREEN_CONSTANTS.STORE
      ) {
        return null;
      }
    }

    if (
      listData?.content === 'tall_banner' &&
      this.itemData.length < LAYOUT_CONFIG.minGridCount
    ) {
      return null;
    }

    if (
      displayCount > LAYOUT_CONFIG.maxRailCount ||
      (displayCount === 0 && Utility.isPresent(this.itemData))
    ) {
      displayCount = LAYOUT_CONFIG.maxRailCount;
    }
    if (content === 'mixed' && search) {
      displayCount = this.props.displayCount;
    }

    const componentType = `${upperFirst(size)}${capitalize(
      this.itemData[0]?.type
    )}Rail`;

    if (componentType === 'HorizontalProductRail' && displayCount === 1) {
      const item = this.itemData[0];
      return <this.singleHorizontalProductRailCard />;
    }

    const flatlistStyle =
      previousScreen === SCREEN_CONSTANTS.DURING_ROUTINE
        ? styles.flatListOnRoutinePageStyle
        : styles.flatlistStyle;
    const containerStyle =
      previousScreen === SCREEN_CONSTANTS.DURING_ROUTINE
        ? styles.routineRailContainerStyle
        : styles.railContainerStyle;
    const backgroundImage = { uri: getMinifiedImage(backGroundImageUrl) };

    let resizeMode = 'cover';
    let initialNumToRender = content === 'tag' ? 5 : 3;
    let windowSize = content === 'tag' ? 5 : 7;

    if (isDesktop()) {
      displayCount *= 2;
      initialNumToRender *= 2;
      windowSize *= 2;
      resizeMode = 'repeat';
    }

    return (
      <ImageBackground source={backgroundImage} style={styles.backgroundImage} imageStyle={{ resizeMode }}>
        <View style={styles.railView}>
          <Viewport.Tracker>
            <FlatListPerformanceView listName={`${getListSource(this, this.props)}`}>
              <FlatList
                data={this.itemData.slice(0, displayCount)}
                horizontal
                style={flatlistStyle}
                bounces={false}
                showsHorizontalScrollIndicator={false}
                renderItem={this.renderItems}
                keyExtractor={this.keyExtractor}
                listKey={this.listKey} // added to prevent virtuaized list crash
                ListHeaderComponent={this.emptyCardView}
                contentContainerStyle={containerStyle}
                getItemLayout={this.getItemLayout}
                initialNumToRender={initialNumToRender}
                windowSize={windowSize}
                maxToRenderPerBatch={10}
                removeClippedSubviews={isIOS()}
              />
            </FlatListPerformanceView>
          </Viewport.Tracker>
        </View>
      </ImageBackground>
    );
  }
}

// PropTypes
Rail.propTypes = {
  feed: PropTypes.bool,
  ignoreMinCount: PropTypes.bool,
  listKey: PropTypes.string,
  list: PropTypes.shape({
    objects: PropTypes.array,
  }),
  item: PropTypes.array,
  onPress: PropTypes.func,
};

Rail.defaultProps = {
  feed: false,
  ignoreMinCount: false,
  listKey: null,
  list: {
    objects: [],
  },
  item: [],
  onPress: null,
};
export default Rail;
