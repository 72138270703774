import React, { Component } from 'react';
import { ProductInfoStyles } from '../Product/styles';
import { View, FlatList } from 'react-native';
import AnimatedDotsCarousel from '../../libraries/ReactNativeAnimatedDotsCarousel';
import colors from '../../theme/Colors';
import { isBlank, isNative } from '../../utils/BooleanUtility';

class Bullets extends Component {
  constructor(props) {
    super(props);
    const { carouselDotsPosition } = props;
    this.state = {
      activeIndex: 0,
    };
    this.containerStyle =
      ProductInfoStyles[`dotsContainer${carouselDotsPosition}`];
    if (props.useAnimatedDots) {
      this.containerStyle = {
        alignSelf: 'center',
        height: 8,
        bottom: 0,
        marginVertical: 12,
      };
      if (carouselDotsPosition === 'OverImage') {
        this.containerStyle = {
          alignSelf: 'center',
          height: 8,
          bottom: 24,
        };
      }
      this.decreasingDots = [
        {
          config: { color: colors.grey, margin: 3, size: 6, opacity: 1 },
          quantity: 1,
        },
        {
          config: { color: colors.grey, margin: 3, size: 4, opacity: 1 },
          quantity: 1,
        },
      ];
      this.activeIndicatorConfig = {
        color: colors.darkGrey,
        margin: 3,
        opacity: 1,
        size: 8,
      };
      this.inactiveIndicatorConfig = {
        color: colors.grey,
        margin: 3,
        opacity: 1,
        size: 8,
      };
    }
  }

  componentDidMount() {
    if (this.props.onRef != null) {
      this.props.onRef(this);
    }
  }

  setActiveIndex = (index) => {
    this.setState({ activeIndex: index });
  };

  bullet = ({ item, index }) => {
    const { activeIndex } = this.state;
    const currentStyle =
      activeIndex === index
        ? ProductInfoStyles.activeDotStyle
        : ProductInfoStyles.dotStyle;

    return <View style={currentStyle}></View>;
  };

  bulletLayout(data, index) {
    return {
      length: ProductInfoStyles.dotStyle.width + 2,
      offset: (ProductInfoStyles.dotStyle.width + 2) * index,
      index,
    };
  }

  bulletSelectorRailKeyExtractor = (item, index) => `${index}_${item}`;

  render() {
    const { items, useAnimatedDots } = this.props;
    const { activeIndex } = this.state;
    if (isBlank(items) || items.length < 2) return null;
    if (isNative() && useAnimatedDots) {
      return (
        <View style={this.containerStyle}>
          <AnimatedDotsCarousel
            length={items.length}
            currentIndex={activeIndex}
            maxIndicators={5}
            interpolateOpacityAndColor
            activeIndicatorConfig={this.activeIndicatorConfig}
            inactiveIndicatorConfig={this.inactiveIndicatorConfig}
            decreasingDots={this.decreasingDots}
          />
        </View>
      );
    }

    return (
      <View style={this.containerStyle}>
        <FlatList
          data={items}
          contentContainerStyle={ProductInfoStyles.dotsContentContainer}
          horizontal
          scrollEnabled={false}
          showsHorizontalScrollIndicator={false}
          getItemLayout={this.bulletLayout}
          renderItem={this.bullet}
          keyExtractor={this.bulletSelectorRailKeyExtractor}
          extraData={this.state.activeIndex}
        />
      </View>
    );
  }
}

export default Bullets;
