import React, { Component } from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  TouchableWithoutFeedback,
  Image,
  Linking,
} from 'react-native';
import PropTypes from 'prop-types';
import Modal from 'react-native-modal';
import LottieView from 'lottie-react-native';
import Utility from '../../utils/Utility';
import FoxyShadowButton from '../../lib/FoxyShadowButton';
import colors from '../../theme/Colors';
import { ids, styles } from './OfferSuccessModalStyles';
import images from '../../theme/Images';
import { WHATS_APP_NUMBER } from '../../config/Constants';

import animations from '../../theme/Animations';
import FastImageView from '../FastImageView';
import DebouncedTouchableOpacity from '../shared/DebouncedTouchableOpacity';
import {
  AnalyticsManager,
  EventParameterKey,
  EventType,
} from '../../analytics';
import { getMinifiedImage } from '../../utils/ImageUtility';
import { getRealScreenHeight } from '../../utils/LayoutUtility';
import { isWebMobile } from '../../utils/BooleanUtility';

class OfferSuccessModal extends Component {
  componentDidUpdate = (prevProps) => {
    const { isVisible, sku, offerSuccessModalType, offerId } = this.props;
    if (!prevProps.isVisible && isVisible) {
      AnalyticsManager.logEvent(EventType.order.MODAL_VIEW, {
        [EventParameterKey.MODAL_NAME]: 'OfferClaimModal',
        [EventParameterKey.SKU_ID]: sku,
        modal_type: offerSuccessModalType,
      });
      AnalyticsManager.logEvent(EventType.offer.OFFER_SUCCESS_MODAL_VIEW, { offer_id: offerId });
    }
  };

  crossTapped = () => {
    const { cancelPressed, offerId, alertMessage } = this.props;
    AnalyticsManager.logEvent(EventType.offer.OFFER_SUCCESS_MODAL_DISMISS, {
      offer_id: offerId,
      text: alertMessage,
    });
    cancelPressed();
  };

  openWhatsApp = () => {
    Linking.openURL(
      `whatsapp://send?text=Hi, I have a question about sign up.&phone=${WHATS_APP_NUMBER} `,
    );
  };

  hyperLink = (props) => {
    const { whatsappHyperlinkMessage } = this.props;
    return (
      <TouchableWithoutFeedback
        onPress={() => Utility.openWhatsApp(whatsappHyperlinkMessage)}
      >
        <Text style={styles.hyperLink}>{props.children}</Text>
      </TouchableWithoutFeedback>
    );
  };

  alertMessageContainer = (props) => {
    const {
      showCross = false,
      numOfLines = 3,
      showImage,
      isProfilePicture = false,
      isAssets,
      isAbsolute,
      showJson,
    } = this.props;
    const messageBoxHeight = props.hideSecondButton
      ? props.alertBoxHeight - 46 * 1
      : props.alertBoxHeight - 46 * 2;
    const numberOfLines = numOfLines || 6;
    const profilePictureStyle = {
      height: 76,
      width: 76,
      borderRadius: 38,
      alignSelf: 'center',
      marginBottom: 10,
      marginTop: 4,
    };
    const regularPictureStyle = {
      width: 100,
      height: 100,
      alignSelf: 'center',
      marginBottom: 4,
      marginTop: 4,
    };

    let pictureStyle = isProfilePicture
      ? profilePictureStyle
      : regularPictureStyle;

    if (isAbsolute) {
      pictureStyle = {
        width: 60,
        height: 60,
        alignSelf: 'center',
        position: 'absolute',
        top: -30,
      };
    }
    const minifiedImageUrl = getMinifiedImage(
      props.newUrl,
      pictureStyle.width,
      pictureStyle.height,
    );
    const imageSource = isAssets ? minifiedImageUrl : { uri: minifiedImageUrl };
    const alertMessageStyles = styles[`${props.textAlignment}AlertMessage`];

    let alertBoxTitle = styles.alertBoxTitle;

    if (isAbsolute) {
      alertBoxTitle = styles.alertBoxTitleLargeMargin;
    } else if (!showImage && !showJson) {
      alertBoxTitle = styles.alertBoxTitleSmallMargin;
    }

    return (
      <View style={styles.alertMessageContainer}>
        {showImage && (
          <FastImageView
            source={imageSource}
            resizeMode="contain"
            style={pictureStyle}
          />
        )}
        {showJson && (
          <LottieView
            source={animations.paymentSuccess}
            style={styles.alertBoxAnimation}
            autoPlay
            loop={false}
          />
        )}
        <Text style={alertBoxTitle}>{props.alertBoxTitle}</Text>

        {props.showWhatsappHyperlink && (
          <>
            <Text
              numberOfLines={6}
              style={alertMessageStyles}
              allowFontScaling={false}
            >
              {props.alertMessage}
            </Text>
            <Text style={styles.alertMessage}>
              <this.hyperLink>Chat with us on Whatsapp</this.hyperLink> for any
              question
            </Text>
          </>
        )}
        {!props.showWhatsappHyperlink && (
          <Text
            numberOfLines={numberOfLines}
            style={styles.alertMessage}
            allowFontScaling={false}
          >
            {props.alertMessage}
          </Text>
        )}

        <props.ChildrenComponent />
        {showCross ? (
          <TouchableOpacity
            style={styles.crossStyle}
            onPress={this.crossTapped}
          >
            <Image source={images.cross} />
          </TouchableOpacity>
        ) : null}
      </View>
    );
  };

  actionButton = (props) => {
    const {
      hideSecondButton,
      opacity,
      firstActionButtonStyle = {},
      secondActionButtonStyle = {},
    } = props;

    if (hideSecondButton) {
      return null;
    }
    return (
      <DebouncedTouchableOpacity
        {...this.props}
        style={[styles.actionButtonsContainer, firstActionButtonStyle]}
        onPress={props.onPress}
      >
        <Text
          style={[styles.actionButtonText, { color: props.textColor, opacity }]}
        >
          {props.title.toUpperCase()}
        </Text>
      </DebouncedTouchableOpacity>
    );
  };

  actionButtonsContainer = (props) => (
    <View style={styles.actionButtonsContainer}>
      <TouchableOpacity
        style={styles.firstActionButtonContainer}
        onPress={props.firstButtonOnPress}
      >
        <Text
          style={[
            styles.firstActionButtonTitle,
            { color: props.firstButtonTextColor },
          ]}
        >
          {props.firstButtonTitle}
        </Text>
      </TouchableOpacity>
      <FoxyShadowButton
        width={165}
        title={props.secondButtonTitle}
        onPress={props.secondButtonOnPress}
        backgroundColor={props.secondButtonBackgroundColor}
      />
    </View>
  );

  onPressOutside = () => {
    const { secondButtonOnPress, onTapOutside } = this.props;
    if (onTapOutside) {
      onTapOutside();
    } else {
      secondButtonOnPress();
    }
  };

  crossButtonTapped = () => {
    const { crossButtonTapped = () => {} } = this.props;
    crossButtonTapped();
  };

  crossButtonContainer = () => {
    return (
      <TouchableOpacity
        style={styles.crossButtonContainer}
        onPress={this.crossButtonTapped}
      >
        <FastImageView
          source={images.salon.cross_black_2x.uri}
          style={styles.crossIconStyle}
        />
      </TouchableOpacity>
    );
  };

  onButtonPress = () => {
    const { isProductAddedToCart, firstButtonOnPress, offerId, firstButtonTitle } = this.props;
    AnalyticsManager.logEvent(EventType.offer.OFFER_SUCCESS_MODAL_CTA_CLICK, {
      offer_id: offerId,
      text: firstButtonTitle,
    });

    firstButtonOnPress(isProductAddedToCart);
  };

  render() {
    const {
      height,
      alertBoxTitle,
      alertMessage,
      firstButtonTitle,
      firstButtonTextColor,
      secondButtonTitle,
      secondButtonTextColor,
      firstButtonOnPress,
      secondButtonOnPress,
      isVisible,
      Children,
      onTapOutside,
      textAlignment,
      hideSecondButton = false,
      showWhatsappHyperlink,
      autoWrapContent,
      image_url,
      firstActionButtonStyle,
      secondActionButtonStyle,
      isAssets,
      addToCartButton,
      isProductAddedToCart,
    } = this.props;
    const ChildrenComponent = Children;
    const boxHeight = !autoWrapContent ? { height } : {};
    return (
      <Modal
        isVisible={isVisible}
        onBackdropPress={this.onPressOutside}
        style={{
          margin: 0,
          justifyContent: 'center',
          alignItems: 'center',
          width: 280,
        }}
        onSwipeEnd={this.onPressOutside}
        onSwipeComplete={this.onPressOutside}
        swipeDirection="down"
        swipeThreshold={200}
        propagateSwipe
        backdropTransitionOutTiming={0}
      >
        <View
          style={[
            styles.alertBoxContainer,
            {
              top: (Utility.getScreenHeight() - height - 120) / 2,
              alignSelf: 'center',
              ...boxHeight,
            },
            (isWebMobile()) && {
              top: (getRealScreenHeight() - height - 120) / 2,
            },
          ]}
          dataSet={{ media: ids.alertBoxContainer }}
        >
          <this.alertMessageContainer
            alertMessage={alertMessage}
            alertBoxTitle={alertBoxTitle}
            alertBoxHeight={height}
            ChildrenComponent={ChildrenComponent}
            hideSecondButton={hideSecondButton}
            textAlignment={textAlignment}
            showWhatsappHyperlink={showWhatsappHyperlink}
            newUrl={image_url}
          />

          <this.actionButton
            textColor={firstButtonTextColor}
            onPress={this.onButtonPress}
            title={firstButtonTitle}
            hideSecondButton={false}
            firstActionButtonStyle={firstActionButtonStyle}
            opacity={1}
          />
          <this.crossButtonContainer />
        </View>
      </Modal>
    );
  }
}

OfferSuccessModal.propTypes = {
  isVisible: PropTypes.bool,
  height: PropTypes.number,
  alertBoxTitle: PropTypes.string,
  alertMessage: PropTypes.string,
  firstButtonTitle: PropTypes.string,
  firstButtonTextColor: PropTypes.string,
  secondButtonTitle: PropTypes.string,
  secondButtonTextColor: PropTypes.string,
  firstButtonOnPress: PropTypes.func,
  secondButtonOnPress: PropTypes.func,
  Children: PropTypes.func,
  onTapOutside: PropTypes.func,
  showSecondButton: PropTypes.bool,
  textAlignment: PropTypes.string,
  showWhatsappHyperlink: PropTypes.bool,
  whatsappHyperlinkMessage: PropTypes.string,
};

OfferSuccessModal.defaultProps = {
  isVisible: false,
  height: 206,
  alertBoxTitle: '',
  alertMessage: '',
  firstButtonTitle: '',
  firstButtonTextColor: colors.primaryActionBackgroundColor,
  secondButtonTextColor: colors.foxyBlack,
  secondButtonTitle: 'Discard',
  secondButtonBackgroundColor: colors.primaryActionBackgroundColor,
  firstButtonOnPress: () => {},
  secondButtonOnPress: () => {},
  Children: () => <View />,
  onTapOutside: () => {},
  showSecondButton: true,
  textAlignment: 'justify',
  showWhatsappHyperlink: false,
  whatsappHyperlinkMessage: '',
};

export default OfferSuccessModal;
