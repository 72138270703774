import React, { PureComponent } from 'react';
import {
  View,
  TouchableOpacity,
  Image,
  Text,
  BackHandler,
  ActivityIndicator,
  Linking,
  KeyboardAvoidingView,
  ImageBackground,
  StyleSheet,
} from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import _ from 'lodash';
import { PaymentStyles, CartStyles } from './styles';
import images from '../../theme/Images';
import FullWidthDivider from '../../utils/FullWidthDivider';
import {
  setUpgradeToPrepaid,
  getCartItems,
  isAppReviewSubmitted,
  setCartNotification,
} from '../../actions/ActionTypes';
import { setPhoneNumberModalInfo } from '../../actions/LoginActions';
import colors from '../../theme/Colors';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import { withEither, withMaybe } from '../../lib/Monads';
import {
  AnalyticsManager,
  EventParameterValue,
  EventType,
} from '../../analytics';
import ScaleAnimate from '../shared/ScaleAnimate';
import moment from 'moment';
import PrepaidOrderCountDown from '../orders/PrepaidOrderCountDown';
import LinearGradient from 'react-native-linear-gradient';
import ShimmerPlaceHolder from '../../libraries/ReactNativeShimmerPlaceholder';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  APP_STORE_LINK,
  WEB_URL,
  NOTIFICATION_ACTIONS,
  NOTIFICATION_IDENTIFIERS,
  NOTIFICATION_REQUEST_MODAL_TYPE,
  NOTIFICATION_TYPE,
  PLAY_STORE_LINK,
  CART_ITEMS_URL,
  TRACKING_PARAMS,
  HOME_PAGE_DEEPLINK,
  CART_NOTIFICATION_STATES,
} from '../../config/Constants';
import { DeliveryInfo } from './DeliveryInfo';
import NavigationService from '../../navigator/NavigationService';
import QRCode from '../../libraries/ReactNativeQrcodeSvg';
import Config from '../../libraries/ReactNativeConfig';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import NotificationManager from '../../utils/NotificationsManager';
import RoutineNotifications from '../Routines/RoutineNotifications';
import AppConfig from '../../config/AppConfig';
import FastImageView from '../FastImageView';
import { getScreenWidth } from '../../utils/LayoutUtility';
import { goBackWithFallbackHome } from '../../utils/NavigationUtility';
import { isWeb, isWebIOS } from '../../utils/BooleanUtility';
import AnalyticsUtility from '../../analytics/AnalyticsUtility';
import NotificationUtils from '../../utils/NotificationUtils';
import RemoteConfig from '../../utils/RemoteConfig';
import { getMinifiedImage } from '../../utils/ImageUtility';
import { isPresent } from '../../utils/BooleanUtility';

class OrderStatus extends PureComponent {
  orderStatusConstants = {
    headerText: 'Order Status',
    successText: 'Order Placed Successfully',
    paymentSuccessText: 'Payment Successful',
    failureText: 'Payment Failed',
    failureDescription:
      'Any amount deducted will be refunded within 4-7 days. How would you like to proceed?',
    retry: 'Retry',
    cancel: 'Cancel',
    selectDifferentPaymentMethod: 'Use another payment method',
  };

  constructor(props) {
    super(props);
    this.state = {
      isLoaderVisible: false,
      showAppReviewModal: false,
    };
    const { route } = props;
    const { params: { extra: { login_to_confirm_order_id = '' } = {} } = {} } =
      route;
    console.tron.log(route, login_to_confirm_order_id, 'routeroute');
    this.orderResponse = route.params?.orderResponse;
    this.retryPayment = route.params?.retryPayment;
    if (Utility.isAndroid()) {
      this.backhandler = BackHandler.addEventListener(
        'hardwareBackPress',
        this.onHardwareBackKeyPress,
      );
    }
    this.currentRating = -1;
    this.source = route.params?.source;
    this.login_to_confirm_order_id = login_to_confirm_order_id;

    if (isPresent(login_to_confirm_order_id)) {
      this.orderResponse = {
        status: 'success',
        order_status: 'awaiting_confirmation',
        order_id: login_to_confirm_order_id,
        confirmed_by_login: true,
        success_header: 'Order placed successfully',
        success_subheading:
          'Your order has been placed successfully. Please check orders details below',
      };
    }
    const { has_selfie } = props;
    RoutineNotifications.createRoutineNotifications(
      this.orderResponse,
      has_selfie,
    );
  }

  componentDidMount() {
    const {
      getCartItems,
      authToken,
      defaultAddress: { phone_number = '' } = '',
      lastNotificationModalDisplayTime,
      appReviewSubmitted,
      setPhoneNumberModalInfo,
    } = this.props;

    const orderStatus = this.orderResponse?.status || 'failure';
    if (orderStatus === 'success') {
      getCartItems(() => {});
      // Will execute if  order is success and user is already logged in
      if (Utility.isPresent(authToken)) {
        setTimeout(this.openInAppReview, 800);
      }
      if (appReviewSubmitted) {
        setTimeout(this.displayNotificationRequestModal, 4000);
      }
    }

    if (this.orderResponse?.order_status === 'awaiting_confirmation') {
      Utility.skipOnboardingSelfie = true;
    }

    //FIXME: Add a proper fix for this
    setTimeout(() => {
      if (Utility.isBlank(authToken)) {
        Utility.hideLoginSkipButtonForSalon = true;
        setPhoneNumberModalInfo(Utility.getLoginModalInfo('CHECKOUT'));
        NavigationService.renderOnboarding({
          orderStatus,
          parentSkipAction: this.goBack,
          goBackOnBackgroundTap: false,
        });
      }
    }, 500);
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { authToken, orderStatus, appReviewSubmitted, setCartNotification } =
      this.props;
    const { showAppReviewModal } = this.state;
    // Will execute if order is success and user just logged in after that
    if (this.orderResponse?.status === 'success') {
      if (!prevProps.authToken && Utility.isPresent(authToken)) {
        setCartNotification(CART_NOTIFICATION_STATES.paymentDone);
        setTimeout(this.openInAppReview, 800);
      }
      const showNotificationPrompt =
        prevState.showAppReviewModal && !showAppReviewModal;
      if (showNotificationPrompt) {
        setTimeout(this.displayNotificationRequestModal, 2000);
      }
    }
  };

  componentWillUnmount() {
    if (Utility.isAndroid()) {
      this.backhandler.remove();
    }
  }

  displayNotificationRequestModal = () => {
    const { initial_app_opened_at, lastNotificationModalDisplayTime } =
      this.props;
    Utility.canDisplayNotificationRequestModal(
      lastNotificationModalDisplayTime,
      'orderSuccess',
      (canDisplay, showNotificationPrompt) => {
        if (canDisplay) {
          this.showNotificationModal(showNotificationPrompt);
        }
      },
      initial_app_opened_at,
      true,
    );
  };

  renderLoader = (visible) => {
    this.setState({
      isLoaderVisible: visible,
    });
  };

  retryTapped = () => {
    if (typeof this.retryPayment === 'function') this.retryPayment();

    this.renderLoader(true);
    setTimeout(() => {
      this.renderLoader(false);
    }, 5000);
  };

  showNotificationModal = (showNotificationPrompt) => {
    const { navigation } = this.props;
    navigation.push('NotificationModal', {
      showNotificationPrompt,
      type: NOTIFICATION_REQUEST_MODAL_TYPE.ORDER_SUCCESS,
    });
  };

  goBack = () => {
    AnalyticsUtility.fireGoBackEvent();
    const { navigation } = this.props;
    const orderStatus = this.orderResponse?.status || 'failure';

    if (orderStatus === 'failure') {
      goBackWithFallbackHome(navigation);
      return;
    }
    if (isWeb()) {
      navigation.push('TabNavigator');
    } else {
      navigation.navigate('Feed');
    }
  };

  onHardwareBackKeyPress = () => {
    this.goBack();
    return true;
  };

  header = () => (
    <TouchableOpacity
      onPress={this.goBack}
      style={CartStyles.orderStatusCross}
      hitSlop={Utility.getHitSlop()}
    >
      <Image style={CartStyles.crossImage} source={images.cross} />
    </TouchableOpacity>
  );

  divider = () => <View style={[PaymentStyles.divider, { width: '100%' }]} />;

  successUI = (props) => {
    const { defaultAddress: { phone_number = '' } = '', authToken } =
      this.props;

    Utility.furtherAction = {
      phone_number,
      isActionAsync: false,
    };
    // }
    if (Utility.isPresent(authToken)) {
      return this.showOrderSuccessful();
    }
    return this.showOTPView();
  };

  onViewRoutinePress = () => {
    const { navigation } = this.props;
    navigation.navigate('Routines', {});
  };

  onViewOrderPress = () => {
    const { navigation } = this.props;
    console.tron.log(this.orderResponse, 'this.orderResponse?');
    navigation.push('OrderDetails', {
      entity_id: this.orderResponse?.order_id.toString(),
      entity_type: 'Order',
      id: `Order-${this.orderResponse?.order_id.toString()}`,
    });
  };

  showOrderSuccessful = () => {
    const { authToken } = this.props;
    if (Utility.isBlank(authToken)) {
      return null;
    }

    const { defaultAddress: { phone_number = '' } = '' } = this.props;

    const styles = PaymentStyles;
    const imageArray = this.orderResponse?.product_images_urls;

    let successText = this.orderResponse?.upgradable_to_prepaid
      ? this.orderStatusConstants.successText
      : this.orderStatusConstants.paymentSuccessText;

    let orderStatusDetailText =
      'Your order has been placed successfully. Please check the status of shipment in your orders.';

    if (!!this.orderResponse?.confirmed_by_login) {
      successText = this.orderResponse?.success_header;
      orderStatusDetailText = this.orderResponse?.success_subheading;
    }

    if (
      this.orderResponse?.order_id &&
      Config.SHOW_ORDER_PAGE_MERCHENT_ID !== 'true'
    ) {
      orderStatusDetailText = orderStatusDetailText?.replace(
        'Your order',
        'Your order #' + this.orderResponse?.order_id.toString(),
      );
    }

    const showViewRoutine =
      Utility.isPresent(this.orderResponse?.no_of_routine_items) &&
      this.orderResponse?.no_of_routine_items !== 0;
    const hideArrivingDate =
      this.orderResponse?.no_of_routine_items ===
      this.orderResponse?.skus?.length;
    if (this.orderResponse?.is_store_order) {
      return (
        <View>
          <ImageBackground
            source={images.retailStore.foxyPassBackground}
            style={styles.backgroundImage}
            resizeMode='cover'
          >
            <View style={styles.scanContainer}>
              <View style={styles.qrContainer}>
                <QRCode
                  value={`https://foxy.in/orders/${this.orderResponse?.order_id}/confirmation`}
                  size={200}
                  logoSize={60}
                  logoBorderRadius={40}
                  enableLinearGradient={true}
                  linearGradient={['#2A5E8F', '#69394F']}
                />
                <Image source={images.foxy_barcode} style={styles.barcode} />
              </View>
            </View>
          </ImageBackground>

          <View
            style={{
              alignItems: 'center',
            }}
          >
            <Text style={[styles.orderStatusText, { color: colors.foxyBlack }]}>
              {successText}
            </Text>

            <Text style={styles.orderStatusDetailText}>
              {orderStatusDetailText}
            </Text>
          </View>

          {!Utility.isBlank(imageArray) ? (
            <View style={styles.boughtItemImagesContainer}>
              {imageArray.map((item) => {
                return (
                  <ScaleAnimate>
                    <FastImageView
                      source={getMinifiedImage(item, 60, 60)}
                      style={styles.boughtProductImage}
                      resizeMode='contain'
                    />
                  </ScaleAnimate>
                );
              })}
            </View>
          ) : null}
        </View>
      );
    }
    return (
      <View style={styles.successUIContainer}>
        <Image
          source={images.orderStatus.success}
          style={styles.orderStatusImage}
        />

        <Text style={[styles.orderStatusText, { color: colors.foxyBlack }]}>
          {successText}
        </Text>

        <Text style={styles.orderStatusDetailText}>
          {orderStatusDetailText}
        </Text>
        <ScrollView horizontal='true' showsHorizontalScrollIndicator={false}>
          {!Utility.isBlank(imageArray) ? (
            <View style={styles.boughtItemImagesContainer}>
              {imageArray.map((item) => {
                return (
                  <ScaleAnimate>
                    <FastImageView
                      source={getMinifiedImage(item, 60, 60)}
                      style={styles.boughtProductImage}
                      resizeMode='contain'
                    />
                  </ScaleAnimate>
                );
              })}
            </View>
          ) : null}
          {this.orderResponse && this.orderResponse?.has_surprise && (
            <Image source={images.gift} style={styles.freeGift} />
          )}
        </ScrollView>
        {showViewRoutine && (
          <TouchableOpacity
            onPress={this.onViewRoutinePress}
            style={styles.viewRoutineActionButton}
          >
            <Text style={styles.actionButtonText}>View Routine</Text>
          </TouchableOpacity>
        )}

        {!!this.orderResponse?.confirmed_by_login && (
          <TouchableOpacity
            onPress={this.onViewOrderPress}
            style={styles.viewRoutineActionButton}
          >
            <Text style={styles.actionButtonText}>View Order</Text>
          </TouchableOpacity>
        )}
        <View style={{ margin: 12, marginTop: 24, width: '100%' }}>
          <this.divider />
          {!hideArrivingDate && (
            <>
              <View style={styles.orderArrivingStatusContainer}>
                <Image
                  source={images.packageBox}
                  style={styles.orderDeliveryImage}
                />
                <View style={styles.arrivingTextContainer}>
                  <Text
                    style={[styles.orderStatusDetailText, { marginTop: null }]}
                  >
                    Arriving
                  </Text>
                  {Utility.isBlank(
                    this.orderResponse?.expected_delivery_date,
                  ) ? (
                    <ShimmerPlaceHolder
                      autoRun
                      style={{ height: 16, width: 54, marginTop: 4 }}
                    />
                  ) : (
                    <Text style={styles.deliveryDateText}>
                      {this.orderResponse?.expected_delivery_date}
                    </Text>
                  )}
                </View>
              </View>
              <this.divider />
            </>
          )}

          <View
            style={{
              flexDirection: 'row',
              height: 64,
              width: Utility.getScreenWidth() - 2 * Utility.largePadding,
              alignItems: 'center',
              marginTop: 12,
            }}
          >
            <Image
              source={
                images.orderAddress[`${this.orderResponse?.address_type}`]
              }
              style={styles.orderDeliveryImage}
            />
            <View style={styles.arrivingTextContainer}>
              <Text style={[styles.orderStatusDetailText, { marginTop: 8 }]}>
                {this.orderResponse?.address_type}
              </Text>
              <Text
                style={[
                  styles.deliveryDateText,
                  { maxWidth: Utility.getScreenWidth() / 1.5 },
                ]}
                numberOfLines={3}
                ellipsizeMode='tail'
              >
                {this.orderResponse?.address}
              </Text>
            </View>
          </View>

          <View
            style={{
              marginTop: 8,
            }}
          >
            <DeliveryInfo defaultAddress={this.orderResponse} fromOrder />
          </View>
        </View>
      </View>
    );
  };

  showOTPView = () => {
    const { defaultAddress: { phone_number = '' } = '' } = this.props;

    const styles = PaymentStyles;
    const imageArray = this.orderResponse?.product_images_urls;
    return (
      <View style={styles.successUIContainer}>
        <Image
          source={images.order_confirmation}
          style={styles.orderStatusImage}
        />
        <Text
          style={[
            styles.orderStatusText,
            {
              color: colors.foxyBlack,
              textAlign: 'center',
            },
          ]}
        >
          {`Please enter the OTP to confirm the order`}
        </Text>

        <ScrollView horizontal='true' showsHorizontalScrollIndicator={false}>
          {!Utility.isBlank(imageArray) ? (
            <View style={styles.boughtItemImagesContainer}>
              {imageArray.map((item) => {
                return (
                  <ScaleAnimate>
                    <FastImageView
                      source={{
                        uri: getMinifiedImage(
                          item,
                          styles.boughtProductImage.width,
                          styles.boughtProductImage.height,
                        ),
                      }}
                      style={styles.boughtProductImage}
                      resizeMode='contain'
                    />
                  </ScaleAnimate>
                );
              })}
            </View>
          ) : null}
          {this.orderResponse && this.orderResponse?.has_surprise && (
            <Image source={images.gift} style={styles.freeGift} />
          )}
        </ScrollView>
        <View
          style={{
            height: Utility.getScreenWidth(),
            width: Utility.getScreenWidth(),
            backgroundColor: '#fff',
          }}
        />
      </View>
    );
  };

  openInAppReview = () => {
    if (AppConfig.getBooleanValue(Config.HIDE_REVIEW_MODAL_ON_ORDER_SUCCESS)) {
      return;
    }
    const { appReviewSubmitted, navigation } = this.props;
    if (appReviewSubmitted) {
      return;
    }

    navigation.navigate('AppReviewModal', {
      onCancel: this.onCancelAppReviewModal,
      onFinishRating: this.onFinishRating,
      onSubmitRating: this.onSubmitRating,
    });

    this.setState({
      showAppReviewModal: true,
    });
    AnalyticsManager.logEvent(EventType.order.MODAL_VIEW, {
      modal_name: EventParameterValue.MODAL_NAME.APP_REVIEW,
    });
  };

  onCancelAppReviewModal = (dismissedByUser) => {
    this.setState({
      showAppReviewModal: false,
    });
    if (dismissedByUser) {
      AnalyticsManager.logEvent(EventType.order.MODAL_CLOSE, {
        modal_name: EventParameterValue.MODAL_NAME.APP_REVIEW,
      });
    }
  };

  onFinishRating = (rating) => {
    this.currentRating = rating;
  };

  onSubmitRating = () => {
    const { isAppReviewSubmitted } = this.props;
    AnalyticsManager.logEvent(EventType.order.APP_REVIEW, {
      rating: this.currentRating,
    });
    if (this.currentRating > 3) {
      let appLink = Utility.isAndroid() ? PLAY_STORE_LINK : APP_STORE_LINK;
      appLink = isWeb() ? (isWebIOS() ? APP_STORE_LINK : PLAY_STORE_LINK) : appLink;
      setTimeout(() => {
        Linking.openURL(appLink);
      }, 800);
    }
    isAppReviewSubmitted(true);
    this.onCancelAppReviewModal(false);
  };

  goToPayments = () => {
    const { navigation, setUpgradeToPrepaid } = this.props;
    AnalyticsManager.logEvent(EventType.payment.PAYMENT_METHOD_UPGRADE);
    setUpgradeToPrepaid({
      isUpgradeToPrepaid: true,
      prepaidUpgradeOptionExpiresAt:
        this.orderResponse?.prepaid_upgrade_option_expires_at,
      orderId: this.orderResponse?.order_id,
    });
    navigation.navigate('Payments');
  };

  goBackToPaymentSelection = () => {
    const { navigation } = this.props;

    navigation.navigate('Payments');
  };

  processingUi = (props) => {
    const styles = PaymentStyles;
    return (
      <View style={styles.successUIContainer}>
        <ActivityIndicator
          size={90}
          color={colors.silver}
          style={{ marginTop: 64, marginBottom: 20 }}
        />
        <Text style={[styles.orderStatusText, { color: colors.foxyBlack }]}>
          Confirming your payments
        </Text>

        <Text style={[styles.orderStatusDetailText, { marginTop: 14 }]}>
          Please wait for a few seconds..
        </Text>
      </View>
    );
  };

  failureUI = (props) => {
    const styles = PaymentStyles;
    const { isLoaderVisible } = this.state;
    return (
      <View style={styles.successUIContainer}>
        <Image
          source={images.orderStatus.failure}
          style={[styles.orderStatusImage, { marginTop: 64 }]}
        />
        <Text style={[styles.orderStatusText, { color: colors.foxyRed }]}>
          {this.orderStatusConstants.failureText}
        </Text>

        <Text style={[styles.orderStatusDetailText, { marginTop: 32 }]}>
          {this.orderStatusConstants.failureDescription}
        </Text>
        <TouchableOpacity
          onPress={this.retryTapped}
          style={styles.retryButton}
          disabled={isLoaderVisible}
        >
          {!isLoaderVisible ? (
            <Text style={styles.actionButtonText}>
              {this.orderStatusConstants.retry}
            </Text>
          ) : (
            <ActivityIndicator animating color={colors.white} size='small' />
          )}
        </TouchableOpacity>
        <TouchableOpacity
          onPress={this.goBackToPaymentSelection}
          style={[
            styles.retryButton,
            {
              backgroundColor: 'white',
              marginTop: 16,
            },
          ]}
        >
          <Text
            style={[
              styles.actionButtonText,
              {
                color: colors.foxyBlack,
                fontSize: 16,
              },
            ]}
          >
            {this.orderStatusConstants.selectDifferentPaymentMethod}
          </Text>
        </TouchableOpacity>
      </View>
    );
  };

  isOrderSuccess = (props) => props.orderStatus === 'success';

  isOrderFailure = (props) => props.orderStatus === 'failure';

  isOrderProcessing = (props) => props.orderStatus === 'processing';

  isUpgradableToPrepaid = () => {
    return this.orderResponse?.upgradable_to_prepaid;
  };

  afterOtpFill = () => {};

  conditionalView = withEither(this.isUpgradableToPrepaid, ScrollView)(View);

  uiAccordingToOrderStatus = compose(
    withEither(this.isOrderSuccess, this.successUI),
    withEither(this.isOrderProcessing, this.successUI),
  )(this.failureUI);

  conditionalActionButton = withMaybe(this.isOrderFailure)(TouchableOpacity);

  uiWithAuthToken = () => {
    const orderStatus = this.orderResponse?.status || 'failure';
    return (
      <>
        <this.header />
        <View style={styles.blankView} />
        <this.uiAccordingToOrderStatus
          orderStatus={orderStatus}
          orderResponse={this.orderResponse}
        />
      </>
    );
  };

  uiWithoutAuthToken = () => {
    let orderStatus = this.orderResponse?.status || 'failure';

    return (
      <KeyboardAvoidingView behavior='position'>
        <View style={styles.blankView} />
        <this.uiAccordingToOrderStatus
          orderStatus={orderStatus}
          orderResponse={this.orderResponse}
        />
      </KeyboardAvoidingView>
    );
  };

  render() {
    const { authToken } = this.props;

    return (
      <View
        style={{
          paddingHorizontal: 16,
          backgroundColor: 'white',
          height: '100%',
        }}
      >
        {Utility.isPresent(authToken) ? (
          <this.uiWithAuthToken />
        ) : (
          <this.uiWithoutAuthToken />
        )}
      </View>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  lastNotificationModalDisplayTime:
    state.UserAccountInfo.lastNotificationModalDisplayTime,
  defaultAddress: state.bag.defaultAddress,
  authToken: state.UserAccountInfo.authToken,
  has_selfie: state.UserAccountInfo.has_selfie,
  appReviewSubmitted: state.UserAccountInfo.appReviewSubmitted,
  initial_app_opened_at: state.UserAccountInfo.initial_app_opened_at,
});

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(
      {
        setUpgradeToPrepaid,
        getCartItems,
        isAppReviewSubmitted,
        setPhoneNumberModalInfo,
        setCartNotification,
      },
      dispatch,
    ),
  };
}

export default withNavigation(
  connect(mapStateToProps, mapDispatchToProps)(OrderStatus),
);

const styles = StyleSheet.create({
  blankView: {
    height: 100,
    width: Utility.getScreenWidth(),
    backgroundColor: colors.white,
  },
});
