import { StyleSheet, Platform } from 'react-native';
import colors from '../../theme/Colors';
import Utility from '../../utils/Utility';
import size from '../../theme/Fonts';
import Config from '../../libraries/ReactNativeConfig';
import { getScreenWidth } from '../../utils/LayoutUtility';

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  wrapper: {
    flex: 1,
    // paddingLeft: 10,
    // paddingRight: 10,
    overflow: 'visible',
    width: getScreenWidth(),
    alignSelf: 'center',
  },
  profileCardContainer: {
    height: 96,
    width: '100%',
    paddingLeft: 16,
    paddingRight: 16,
    flexDirection: 'row',

    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  profileCardNameContainer: {
    justifyContent: 'center',
    alignItems: 'flex-start',
    marginLeft: 12,
    marginRight: 12,
  },
  userName: {
    fontFamily: 'Roboto-Bold',
    color: colors.foxyBlack,
    fontSize: size.h1,
    letterSpacing: 0,
    maxWidth: Utility.getScreenWidth() - 200,
  },
  completeProfileButtonContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  userHandle: {
    fontFamily: 'Roboto-Regular',
    color: colors.subtitle,
    fontSize: size.h3,
    letterSpacing: 0,
  },
  editProfileButton: {
    height: 30,
    borderRadius: 4,

    position: 'absolute',
    right: 12,
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: colors.border,
    top: 30,
    width: 78,
  },
  editButtonText: {
    fontFamily: 'Roboto-Medium',
    color: colors.foxyBlack,
    fontSize: size.h4,
    fontStyle: 'normal',
    letterSpacing: 0,
    marginLeft: 6,
    marginRight: 6,
  },

  menuOption: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 12,
    paddingBottom: 12,
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  menuOptionText: {
    fontFamily: 'Roboto-Bold',
    fontSize: size.h1,
    color: colors.foxyBlack,
  },
  menuSubText: {
    fontFamily: 'Roboto-Regular',
    fontSize: size.h4,
    marginLeft: 10,
    color: colors.subtitle,
  },
  image: {
    height: 40,
    width: 40,
  },

  orderColumn: {
    flex: 1,
  },
  orderIdText: {
    color: colors.foxyBlack,
    fontFamily: 'Roboto-Regular',
  },
  orderStatusRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    flex: 1,
  },
  orderStatusText: {
    color: colors.green,
    fontFamily: 'Roboto-Regular',
    fontSize: size.h4,
  },
  orderDateText: {
    color: colors.subtitle,
    fontSize: size.h5,
    fontFamily: 'Roboto-Regular',
  },
  interestCard: {
    height: 110,
    width: 110,
    marginRight: 10,
    borderRadius: 4,
    backgroundColor: '#f4f4f4',
  },
  facialCardContainer: {
    height: 30,
    width: 100,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 20,
    backgroundColor: '#f5f5f5',
    marginLeft: 10,
    marginRight: 10,
  },
  checkBox: {
    position: 'absolute',
    top: 7,
    right: 10,
    height: 20,
    width: 20,
  },

  addressContainer: {
    flexDirection: 'row',
    backgroundColor: colors.white,
    alignItems: 'center',
    borderBottomColor: '#e3e4e6',
    borderBottomWidth: 1,
    padding: 10,
    justifyContent: 'space-between',
  },
  addressTypeHead: {
    fontFamily: 'Roboto-Regular',
    fontSize: size.h3,
    color: colors.silverDark,
  },
  completeAddress: {
    fontFamily: 'Roboto-Regular',
    fontSize: size.h4,
    color: colors.subtitle,
  },
  horizontalBar: {
    height: 1,
    width: Utility.getScreenWidth(),
    backgroundColor: colors.background,
  },
  equalFlex: {
    flex: 1,
  },
  addressTypeSubtitle: {
    fontFamily: 'Roboto-Regular',
    fontSize: size.h4,
    width: 200,
    color: colors.subtitle,
  },

  uploadSelfieCard: {
    height: 60,
    borderRadius: 4,
    backgroundColor: colors.foxyBlack,
    flexDirection: 'row',
    paddingLeft: 20,
    justifyContent: 'space-between',
    paddingRight: 20,
    alignItems: 'center',
  },
  uploadSelfieImage: {
    height: 50,
    width: 50,
  },
  uploadSelfieHeadText: {
    fontFamily: 'Roboto-Bold',
    fontSize: size.h3,
    color: colors.white,
  },
  uploadSelfieSubText: {
    fontFamily: 'Roboto-Regular',
    fontSize: size.h4,
    color: colors.white,
  },

  selfieRoundLayout: {
    borderRadius: 20,
    height: 50,
    width: 50,
  },

  attributesChips: {
    backgroundColor: 'rgba(210,210,210,0.4)',
    padding: 5,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 3,
    borderRadius: 40,
  },

  chipsText: {
    fontFamily: 'Roboto-Regular',
    color: colors.white,
    fontSize: size.h4,
  },

  colorCircle: {
    height: 15,
    marginLeft: 3,
    width: 15,
    overflow: 'hidden',
    borderRadius: 30,
  },

  facialAttribsIcon: {
    height: 12,
    width: 12,
    marginTop: 4,
  },
  borderLayout: {
    height: 60,
    borderWidth: 1,
    marginTop: 5,
    marginBottom: 5,
    borderColor: '#e4e4e4',
    // paddingLeft: 7,
    // paddingRight: 7,
    paddingTop: 7,
    borderRadius: 4,
    paddingBottom: 7,
  },
  ordersCardLayout: {
    height: 120,
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 12,
    paddingRight: 12,
    marginRight: 8,
    width: Utility.getScreenWidth() - 100,
    borderColor: '#e4e4e4',
    borderRadius: 4,
    borderWidth: 1,
    backgroundColor: colors.white,
  },
  orderText: {
    fontFamily: 'Roboto-Medium',
    fontSize: 10,
    color: colors.silver,
  },
  orderItemText: {
    fontFamily: 'Roboto-Regular',
    fontSize: size.h4,
    width: 180,
    color: colors.foxyBlack,
  },
  orderStatus: {
    fontFamily: 'Roboto-Medium',
    fontSize: 12,
    color: colors.green,
  },

  itemCounterBadge: {
    position: 'absolute',
    top: 20,

    bottom: 20,

    height: 25,
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    width: 25,
    backgroundColor: colors.white,
    borderWidth: 1,
    borderColor: colors.silver,
    borderRadius: 40,
  },

  badgeText: {
    fontFamily: 'Roboto-Regular',
    color: colors.foxyBlack,
    fontSize: size.h5,
  },
  profileImage: {
    height: 50,
    width: 50,
    borderTopLeftRadius: 45,
    borderTopRightRadius: 45,
    borderBottomLeftRadius: 45,
    borderBottomRightRadius: 45,
    overflow: 'visible',
    marginLeft: 16,
    backgroundColor: colors.background,
  },

  emptyStateBlock: {
    height: 100,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: 15,
    marginTop: 5,
    marginBottom: 5,
  },
  emptyStateHeaderText: {
    fontFamily: 'Roboto-Medium',
    fontSize: 13,
    color: colors.foxyBlack,
  },
  emptyStateSubHeadingText: {
    fontFamily: 'Roboto-Regular',
    fontSize: 11,
    color: colors.silver,
  },

  emptyStateCTA: {
    height: 25,
    borderRadius: 4,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 8,
    width: 90,
    borderWidth: 1,
    borderColor: colors.foxyPink,
  },

  cardLayoutContainer: {
    marginTop: 5,
    marginBottom: 5,
    backgroundColor: colors.white,
    borderWidth: 1,
    borderColor: colors.borderDark,
    borderRadius: 4,
  },
  cardsItem: {
    paddingLeft: 10,
    paddingTop: 16,
    paddingBottom: 16,

    paddingRight: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  menuCardItemTextStyle: {
    color: colors.foxyBlack,
    fontFamily: 'Roboto-Regular',
    fontSize: size.h4,
  },

  completeProfileContainer: {
    width: 90,
    height: 35,
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1,
    backgroundColor: colors.white,
    borderColor: colors.border,
    borderRadius: 4,
    flexDirection: 'row',
  },
  mediumBoldText: {
    fontFamily: 'Roboto-Medium',
    fontSize: size.h4,
    color: colors.foxyBlack,
  },
  profileColumnContainer: {
    flexDirection: 'column',
    justifyContent: 'center',
    paddingTop: 10,
    paddingBottom: 10,
    alignItems: 'center',
  },

  sidePadding: {
    paddingLeft: 16,
    paddingRight: 16,
  },
  buildNumberText: {
    color: colors.disabled,
    fontFamily: 'Roboto-Regular',
    fontSize: 11,
    paddingBottom: 4,
  },

  profileContainer: {
    height: 90,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  profileCtaContainer: {
    flexDirection: 'row',
    paddingLeft: 10,
  },

  buttonContainer: {
    borderRadius: 8,
    backgroundColor: Utility.isPresent(Config.THEME_COLOR)
      ? Config.THEME_COLOR
      : colors.black,
    height: 30,
    flexDirection: 'row',
    paddingLeft: 14,
    paddingRight: 14,
    marginRight: 8,
    justifyContent: 'center',
    alignItems: 'center',
  },
  consumerProfileCardStyle: {
    paddingLeft: 12,
    paddingRight: 12,
    // paddingTop:2,
    ...Platform.select({
      web: { height: 80 },
      default: { height: 130 },
    }),
  },
  buttonTitle: {
    fontFamily: 'Roboto',
    color: colors.white,
    fontSize: size.h4,
  },

  chevronRight: {
    height: 10,
    width: 10,
  },

  yourSelfieContainer: {
    // height:70,
    marginTop: 7,
    marginRight: 4,
    justifyContent: 'center',
    alignItems: 'center',
    width: 70,
  },

  selfieIcon: {
    height: 40,
    width: 40,
    resizeMode: 'contain',
    borderRadius: 46,
  },
  yourSelfieText: {
    fontFamily: 'Roboto-Regular',
    color: colors.black,
    fontSize: size.h4,
    marginTop: 8,
  },
  cardItemsImageContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  cardItemsImage: {
    tintColor: colors.silver,
    marginLeft: 10,
  },
  profileEdgeEntry: {
    marginTop: -22,
    marginBottom: -12,
  },
  creditFooterImage: {
    width: getScreenWidth(),
    height: 20,
    bottom: 0,
  },
});

export default styles;
