import React, { useEffect } from 'react';
import { Platform, StyleSheet, LogBox } from 'react-native';
import {
  SafeAreaProvider,
  useSafeAreaInsets,
  initialWindowMetrics,
} from 'react-native-safe-area-context';
import { GestureHandlerRootView } from 'react-native-gesture-handler';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { v1 as uuidv1 } from 'uuid';
import { enableFreeze } from 'react-native-screens';
import { ListsProfiler } from './libraries/ReactNativePerformanceShopify';
import AnalyticsManager from './analytics/AnalyticsManager';
import EventType from './analytics/AnalyticsEventType';
import EventParameterKey from './analytics/EventParameterKey';
import AppNavigator from './navigator/AppNavigator';
import configureStore from './reducer';
import Utility from './utils/Utility';

import { messaging } from './libraries/Firebase';
import './libraries/initializeFirebase';

import RemoteConfig from './utils/RemoteConfig';
import withCodePush from './codepush';

import Session from './utils/Sessions';
import { isNative } from './utils/BooleanUtility';

import { setStoreRef } from './store/StoreUtility';
import NotificationManager from './utils/NotificationsManager';
import { addWebTokens, guestFirstAppOpenTime, registerGuestUser, saveGuestAuthToken } from './actions/LoginActions';
import { clearWishlistTooltip, fetchCart, getBoostedOffers, getUserTodayDeals } from './actions/ActionTypes';
import BGNetworkTaskManager from './utils/BGNetworkTaskManager';
import AnalyticsUtility from './analytics/AnalyticsUtility';
import moment from 'moment';
import linking from './navigator/LinkingConfig';
import SplashScreen from 'react-native-splash-screen';
import { PerformanceProfiler } from '@shopify/react-native-performance';
import { errorHandler, onReportPrepared, onBlankAreaCallback, onListPerformanceCallback } from './utils/PerfUtility';

export const store = configureStore();
setStoreRef(store);
const persistor = persistStore(store);
const prefix = Platform.OS === 'android' ? 'foxy://foxy/' : 'foxy://';

enableFreeze(true);

const App = () => {
  LogBox.ignoreAllLogs();
  const insets = useSafeAreaInsets();
  useEffect(() => {
    RemoteConfig.initRemoteConfig(NotificationManager.createNotification);

    Utility.safeAreaInsets(insets);
    Utility.getDeviceAPILevel();
    Utility.setImmersiveModeOff();
    saveAppStartTimeStamp();
    checkPermission();
    fetchWebTokens();
    generateGuestToken();
    fetchTodayDeals();
    fireWebLoadEvent();
    if (isNative()) {
      SplashScreen.hide();
    }
  }, []);

  const getFcmToken = () => {
    if (isNative()) {
      messaging().subscribeToTopic('ekanek-notifications');
    }
  };

  const saveAppStartTimeStamp = () => {
    try {
      Utility.appLoadJs = new Date();
      if (isNative()) {
        AnalyticsManager.logEvent(EventType.appLifeCycleEvents.IMPRESSION, {
          [EventParameterKey.APP_STATE]: 'app_load_js',
          [EventParameterKey.TIME_STAMP]: Utility.appLoadJs,
        });
      }
    } catch (e) {
      if (isNative()) {
        AnalyticsManager.logEvent(
          EventType.appLifeCycleEvents.EXCEPTION_CAPTURED,
          {
            [EventParameterKey.SOURCE]: 'App.js: saveAppStartTimeStamp',
          },
        );
      }
    }
  };

  const requestPermission = async () => {
    try {
      if (isNative()) {
        await messaging().requestPermission();
      }
      // User has authorised
    } catch (error) {
      // User has rejected permissions
    }
  };

  const checkPermission = async () => {
    if (isNative()) {
      const enabled = await messaging().hasPermission();
      if (enabled) {
        getFcmToken();
      } else {
        requestPermission();
      }
    }
  };

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <AppNavigator uriPrefix={prefix} />
      </PersistGate>
    </Provider>
  );
};

const FoxyApp = withCodePush(App);

export default () => {
  /**
   * @initialWindowMetrics: ref PR: https://github.com/ekanek/foxy-app/pull/2209 for details
   */
  return (
    <PerformanceProfiler onReportPrepared={onReportPrepared} errorHandler={errorHandler} renderTimeoutMillis={30000} enabled={isNative()}>
      <ListsProfiler onInteractive={onListPerformanceCallback} onBlankArea={onBlankAreaCallback}>
        <SafeAreaProvider initialMetrics={initialWindowMetrics}>
          <GestureHandlerRootView style={style.app}>
            <FoxyApp />
          </GestureHandlerRootView>
        </SafeAreaProvider>
      </ListsProfiler>
    </PerformanceProfiler>
  );
};

const fetchWebTokens = () => {
  if (isNative()) return;
  const { UserAccountInfo = {} } = store.getState();
  const { guestProfile = {} } = UserAccountInfo;
  const { guestAuthToken } = guestProfile;
  const localStorageGuestToken = localStorage.getItem('guest_token');
  if (localStorageGuestToken && !guestAuthToken) {
    store.dispatch(addWebTokens(localStorageGuestToken, localStorage.getItem('auth_token') || null));
    store.dispatch(fetchCart(() => {}));
  }
};

const generateGuestToken = () => {
  if (isNative()) return;
  const { UserAccountInfo = {} } = store.getState();
  const { guestProfile = {}, authToken } = UserAccountInfo;
  const { guestAuthToken } = guestProfile;
  if (
    Utility.isBlank(authToken) &&
    (!guestAuthToken || Utility.isBlank(guestAuthToken))
  ) {
    const randomGuestToken = uuidv1();
    store.dispatch(saveGuestAuthToken(randomGuestToken));
    BGNetworkTaskManager.saveGuestAuthToken(randomGuestToken);
    store.dispatch(registerGuestUser());
    store.dispatch(guestFirstAppOpenTime(new Date()));
    AnalyticsUtility.setGuestTokenAsProperties(randomGuestToken);
  } else {
    BGNetworkTaskManager.saveGuestAuthToken(guestAuthToken);
  }
};

const fetchTodayDeals = () => {
  if (isNative()) return;
  const { wishlistTooltipResetAt } = store.getState().userInteractions;
  store.dispatch(getUserTodayDeals());
  store.dispatch(getBoostedOffers());
  const appFirstOpenedAt = moment(wishlistTooltipResetAt);
  const todayDate = moment(new Date());

  if (todayDate.diff(appFirstOpenedAt, 'days') > 5) {
    store.dispatch(clearWishlistTooltip());
  }
};

const fireWebLoadEvent = () => {
  AnalyticsUtility.fireWebLoadEvent();
};
const style = StyleSheet.create({
  app: { flex: 1 },
});
