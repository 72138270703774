// Dependencies
import React, { Component } from 'react';
import {
  View,
  TouchableOpacity,
  Image,
  Text,
  Platform,
  StyleSheet,
} from 'react-native';
import PropTypes from 'prop-types';
import {
  BagIcon,
  TabNavigationRightHeader,
} from '../header';
import Images from '../../theme/Images';
import colors from '../../theme/Colors';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import { sharedStyles } from './styles';
import { MAX_LINES } from '../../config/Constants';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import AnimatedNumbers from '../../lib/AnimatedNumber';
import ShimmerPlaceHolder from '../../libraries/ReactNativeShimmerPlaceholder';
import FastImageView from '../FastImageView';
import DynamicLinkManager from '../../utils/DynamicLinkManager';
import Share from '../../libraries/ReactNativeShare';
import { getAllTabsRoutes, goBackWithFallbackHome } from '../../utils/NavigationUtility';
import { isDesktop, isIOS, isPresent, isWeb } from '../../utils/BooleanUtility';
import WebHeaderLogo from './WebHeaderLogo';
import AnalyticsUtility from '../../analytics/AnalyticsUtility';
import DebouncedPressable from './DebouncedPressable';

class StaticNavigationHeader extends Component {
  constructor(props) {
    super(props);
    const { subtitle } = props;
    this.navigationHeaderHeight = Utility.isIOS() ? 44 + Utility.topInset : 56;
    this.titleStyle = Boolean(subtitle)
      ? sharedStyles.pageHeaderTextWithSubtitle
      : sharedStyles.pageHeaderText;
    this.memoizedHeaderStyles = {};
    this.parentButtonContainerStyle = isIOS()
      ? sharedStyles.parentButtonContainerIOS
      : sharedStyles.parentButtonContainer;
  }

  goBack = () => {
    const { navigation, customBack = false, scan } = this.props;
    AnalyticsUtility.fireGoBackEvent();
    if (customBack) {
      this.props.onBackPress();
      return;
    }
    goBackWithFallbackHome(navigation);
  };

  onHardwareBackKeyPress = () => {
    this.goBack();
    return true;
  };

  rightButton = () => {
    const { showRightButton = false, RightButton } = this.props;
    if (!showRightButton) {
      return null;
    }
    return <RightButton />;
  };

  navigationToCart = () => {
    const { navigation } = this.props;
    navigation.navigationHeaderHeight('Cart');
  };

  cartButtonContainer = (props) => {
    return (
      <View
        style={sharedStyles.navigationAllButtonContainer}
      >
        <BagIcon handleTap={this.navigationToCart} showCart />
      </View>
    );
  };

  onSearchIconTap = () => {
    const { navigation } = this.props;
    navigation.navigate('Search');
  };

  searchButtonContainer = (props) => {
    return (
      <TouchableOpacity
        onPress={this.onSearchIconTap}
        style={sharedStyles.navigationAllButtonContainer}
        hitSlop={Utility.getHitSlop()}
      >
        <Image
          source={Images.ic_search_product}
          style={sharedStyles.navbarShareIconStyle}
        />
      </TouchableOpacity>
    );
  };

  onShareIconTap = (medium) => {
    const { shareText = '', shareUrl = '' } = this.props;
    let title = 'Edge';

    if (Utility.isBlank(shareUrl)) {
      this.toast.show('An unexpected error occurred');
      return;
    }
    const getDynamicLink = DynamicLinkManager.getShortenUrl(shareUrl, {
      title,
      description: shareText,
    });
    getDynamicLink
      .then((link) => {
        const options = {
          title: 'Foxy',
          message: shareText,
          url: link,
        };

        Share.open(options)
          .then((res) => {})
          .catch((err) => {});
      })
      .catch((error) => {
        const options = {
          title: 'Foxy',
          message: this.shareMessage,
          url: this.shareUrl,
        };

        Share.open(options)
          .then((res) => {})
          .catch((err) => {});
      });
  };

  shareButtonContainer = (props) => {
    return (
      <TouchableOpacity
        onPress={this.onShareIconTap}
        style={sharedStyles.navigationAllButtonContainer}
        hitSlop={Utility.getHitSlop()}
      >
        <Image
          source={Images.ic_share_product}
          style={sharedStyles.navbarShareIconStyle}
        />
      </TouchableOpacity>
    );
  };

  scanButtonContainer = (props) => {
    const { onPressScanIcon } = this.props;
    return (
      <TouchableOpacity
        onPress={onPressScanIcon}
        style={sharedStyles.navigationAllButtonContainer}
        hitSlop={Utility.getHitSlop()}
      >
        <FastImageView
          source={Images.retailStore.qrIcon}
          style={sharedStyles.scanIconSize}
        />
      </TouchableOpacity>
    );
  };

  getMemoizedHeaderStyle = (color) => {
    if (!this.memoizedHeaderStyles[color]) {
      this.memoizedHeaderStyles[color] = [this.titleStyle, { color }];
    }
    return this.memoizedHeaderStyles[color];
  };

  render() {
    const {
      navigation,
      title = '',
      skipDivider,
      previousScreen,
      onBackPress,
      bgColor = colors.white,
      overrideBackButtonBgColor = false,
      showCart,
      showSearchButton,
      addedToCartProduct = {},
      textColor = colors.foxyBlack,
      children,
      showScanButton,
      showShareButton = false,
      onPressScanIcon,
      subtitle,
      showLogo = false,
      route = {},
    } = this.props;
    const { name: routeName } = route;
    const styles = sharedStyles;
    const backButtonColorBg = overrideBackButtonBgColor
      ? { backgroundColor: bgColor }
      : { backgroundColor: colors.white };

    if (isWeb() && getAllTabsRoutes().includes(routeName)) return null;
    return (
      <>
        <View
          style={[
            styles.staticNavigationHeaderContainer,
            {
              backgroundColor: bgColor,
              height: this.navigationHeaderHeight,
            },
          ]}
        >
          <DebouncedPressable
            onPress={
              typeof onBackPress === 'function' ? onBackPress : this.goBack
            }
            style={[
              styles.navigationBackButtonContainer,
              { bottom: isIOS() ? 6 : 11},
              backButtonColorBg,
            ]}
          >
            <Image
              source={Images.ic_back_product}
              style={[
                styles.navbarIconDefaultStyle,
                {
                  tintColor: textColor,
                },
              ]}
            />
          </DebouncedPressable>
          {((isWeb() && isPresent(title)) || showLogo) && <WebHeaderLogo />}
          {Utility.isBlank(addedToCartProduct) ? (
            <>
              <Text
                numberOfLines={MAX_LINES.single}
                allowFontScaling={false}
                style={this.getMemoizedHeaderStyle(textColor)}
              >
                {`${title}`}
              </Text>
              {Boolean(subtitle) && (
                <Text
                  numberOfLines={MAX_LINES.single}
                  allowFontScaling={false}
                  style={subtitleStyle}
                >
                  {`${subtitle}`}
                </Text>
              )}
            </>
          ) : (
            <ShimmerPlaceHolder
              autoRun
              style={styles.pageHeaderTitleShimmer}
              colorShimmer={['#fafafa', '#eeeeee', '#fafafa']}
            />
          )}
          {previousScreen === SCREEN_CONSTANTS.SEARCH && (
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'flex-end',
                marginLeft: 'auto',
                marginBottom: Utility.isIOS() ? 13 : 18,
              }}
            >
              <TabNavigationRightHeader showCart={false} moreSearchPage />
            </View>
          )}
          <View
            style={this.parentButtonContainerStyle}
          >
            {showCart && <this.cartButtonContainer />}
            {showSearchButton && !isDesktop() && <this.searchButtonContainer />}
            {showShareButton && !isDesktop() && <this.shareButtonContainer />}
            {showScanButton && <this.scanButtonContainer />}
          </View>
          <this.rightButton />
          {!skipDivider && <View style={styles.divider} />}
          {children}
        </View>
      </>
    );
  }
}

export default withNavigation(StaticNavigationHeader);

StaticNavigationHeader.defaultProps = {
  skipDivider: false,
};
StaticNavigationHeader.PropTypes = {
  skipDivider: PropTypes.bool,
};

const headerStyles = StyleSheet.create({
  subtitle: {
    bottom: Utility.isIOS() ? 5 : 8,
    fontSize: 13,
    fontFamily: 'Roboto-Regular',
    color: colors.black,
  },
});

const subtitleStyle = StyleSheet.compose(sharedStyles.pageHeaderText, headerStyles.subtitle);
