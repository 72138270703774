import { Platform } from 'react-native';
import StyleSheet from 'react-native-media-query';
import colors from '../../theme/Colors';
import Utility from '../../utils/Utility';
import { getDynamicWidthForGrid, getScreenWidth } from '../../utils/LayoutUtility';

export const { ids, styles } = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    zIndex: 100,
  },
  headerContainer: {
    alignItems: 'center',
  },
  linearTabContainer: {
    width: Utility.getScreenWidth(),
    backgroundColor: colors.white,
    marginTop: -10,
  },
  centerAlignLinearTabStyle: {
    backgroundColor: colors.white,
    marginTop: -10,
    alignContent: 'center',
    alignSelf: 'center',
  },
  pillsTabContainer: {
    width: Utility.getScreenWidth(),
    backgroundColor: colors.white,
  },
  routinePillsTabContainer: {
    width: Utility.getScreenWidth() * 0.80,
    backgroundColor: colors.white,
  },
  centerAlignPillsTabStyle: {
    backgroundColor: colors.white,
    alignContent: 'center',
    alignSelf: 'center',
  },
  circularRailTabContainer: {
    // width: Utility.getScreenWidth(),
    backgroundColor: colors.white,
    height: 130,
  },
  RoutineCircularRailTabContainer: {
    width: Utility.getScreenWidth() * 0.80,
    backgroundColor: colors.white,
    height: 130,
  },
  centerAlignCircularTabStyle: {
    backgroundColor: colors.white,
    height: 130,
    alignContent: 'center',
    alignSelf: 'center',
    // width: Utility.getScreenWidth(),
  },
  headerTabsContent: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    alignContent: 'center',
  },
  selectedLinearTabText: {
    fontSize: 16,
    fontFamily: 'Roboto-Bold',
    color: colors.foxyBlack,
    alignSelf: 'center',
    marginTop: 5,
  },

  unselectedLinearTabText: {
    fontSize: 16,
    fontFamily: 'Roboto-Bold',
    color: colors.darkGrey,
    alignSelf: 'center',
  },

  labelContainer: {
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  tabBarShadow: {
    elevation: 0,
    shadowOpacity: 0,
  },
  tabStyle: {
    width: Utility.getScreenWidth() / 2,
    alignContent: 'center',
  },

  tabStyleForThree: {
    width: Utility.getScreenWidth() / 3,
    alignContent: 'center',
  },

  tabBarStyle: {
    backgroundColor: colors.white,
    paddingBottom: 0,
    height: 48,
    shadowOpacity: 0,
    elevation: 0,
  },
  indicatorStyle: {
    backgroundColor: colors.foxyBlack,
    borderRadius: 10,
    width: '30%',
    alignSelf: 'center',
    bottom: 6,
    left: -4,
    paddingBottom: 4,
  },
  indicatorStyleForThree: {
    backgroundColor: colors.foxyBlack,
    width: Utility.getScreenWidth() / 3,
    height: 2,
    top: 14,
    bottom: 0,
    left: -12,
  },
  image: {
    width: Utility.getScreenWidth() / 6.5,
    height: Utility.getScreenWidth() / 6.5,
    bottom: 2,
    borderRadius: 120,
  },
  desktopImage: {
    width: Utility.getScreenWidth() / (6.5 * 1.5),
    height: Utility.getScreenWidth() / (6.5 * 1.5),
  },
  contentContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingRight: 20,
    paddingVertical: 12,
  },
  circularTabImageContainer: {
    borderRadius: Utility.getScreenWidth() / 13,
    top: 4,
    overflow: 'hidden',
    '@media (min-width: 768px)': {
      borderRadius: Utility.getScreenWidth() / (13 * 1.5),
    },
  },
  circularTabContainerStyle: {
    justifyContent: 'center',
    alignItems: 'center',
    height: 106,
    width: getScreenWidth() / 5,
    marginRight: 10,
    marginTop: 4,
    '@media (min-width: 768px)': {
      width: getScreenWidth() / 7.5,
      marginTop: 20,
    },
  },
  circularTabTextContainer: {
    height: 40,
    width: getScreenWidth() / 5,
    marginTop: 4,
    '@media (min-width: 768px)': {
      width: getScreenWidth() / 7.5,
    },
  },
  unselectedCircularTabText: {
    color: colors.darkGrey,
    fontSize: 12,
    fontFamily: 'Roboto-Regular',
    paddingHorizontal: 4,
    textAlign: 'center',
  },
  selectedCircularTabText: {
    color: colors.white,
    fontSize: 12,
    fontFamily: 'Roboto-Regular',
    paddingHorizontal: 4,
    left: 1,
    textAlign: 'center',
    '@media (min-width: 768px)': {
      fontSize: 14,
    },
  },
  contentContainerStyle: {
    paddingLeft: 12,
    paddingTop: 12,
  },
  circularTabContainer: {
    backgroundColor: colors.white,
  },
  selectedCircularTabStyle: {
    position: 'absolute',
    width: Utility.getScreenWidth() / 6,
    height: Utility.getScreenWidth() / 6,
    borderRadius: 80,
    borderColor: colors.foxyBlack,
    borderWidth: 2,
    top: 0.5,
    alignSelf: 'center',
    ...Platform.select({
      web: {
        borderRadius: '50%',
      },
      default: {
        borderRadius: 80,
        bottom: 0,
      },
    }),
    '@media (min-width: 768px)': {
      width: Utility.getScreenWidth() / 9,
      height: Utility.getScreenWidth() / 9,
    },
  },
  selectedPillsTabStyle: {
    height: 32,
    borderRadius: 16,
    borderWidth: 2,
    borderColor: colors.foxyBlack,
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    minWidth: 40,
    paddingHorizontal: 12,
    marginRight: 12,
    marginBottom: 12,
  },
  unselectedPillsTabStyle: {
    height: 32,
    borderRadius: 16,
    borderWidth: 1,
    borderColor: colors.grey,
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    minWidth: 40,
    paddingHorizontal: 12,
    marginRight: 12,
    marginBottom: 12,
  },
  selectedPillsTabTextStyle: {
    fontSize: 14,
    color: colors.foxyBlack,
    fontFamily: 'Roboto-Medium',
    alignSelf: 'center',
    bottom: 1,
  },
  unselectedPillsTabTextStyle: {
    fontSize: 14,
    color: colors.darkGrey,
    fontFamily: 'Roboto-Medium',
    alignSelf: 'center',
    bottom: 1,
  },
  flatListContainerStyle: {
    paddingTop: 12,
  },
  shimmer: {
    paddingTop: 14,
  },
  linearTabsStyle: {
    width: Utility.getScreenWidth() / 2,
    backgroundColor: colors.white,
    height: 48,
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  multiLevelTabsContainer: {
    paddingTop: 12,
  },
  pillsTab: {
    backgroundColor: colors.background,
    paddingBottom: 12,
  },
  multiLevelTabsContainerForPage: {},
  linearTabStyle: {
    height: 26,
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    minWidth: 40,
    paddingHorizontal: 9,
    marginRight: 8,
    marginBottom: 6,
  },
  circularTabContentContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingRight: 20,
    paddingVertical: 12,
  },
  selectedCircularTabTextContainer: {
    borderWidth: 2,
    borderRadius: 16,
    backgroundColor: colors.foxyBlack,
    borderColor: colors.foxyBlack,
    marginTop: 6,
    '@media (min-width: 768px)': {
      marginTop: 12,
    },
  },
  unselectedCircularTabTextContainer: {
    borderWidth: 2,
    borderRadius: 16,
    borderColor: colors.background,
    textAlign: 'center',
    marginTop: 4,
  },
  greyLine: {
    width: getScreenWidth(),
    height: 1.5,
    backgroundColor: colors.grey,
    marginTop: -1,
  },
  rootContainer: {
    flex: 1,
  },
  listContainer: { paddingBottom: 48 },
  gridListContainer: { paddingBottom: 48, minHeight: (getDynamicWidthForGrid(2, 16) + 110) * 2 },
});
