import React, { PureComponent } from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  FlatList,
  Platform,
} from 'react-native';
import StyleSheet from 'react-native-media-query';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  AnalyticsManager,
  EventParameterKey,
  EventParameterValue,
  EventType,
} from '../../analytics';
import withNavigation from '../../utils/WithNavigation';
import FastImageView from '../FastImageView';
import DebouncedTouchableOpacity from '../shared/DebouncedTouchableOpacity';
import { EDGE_MODAL_TYPES, REMOTE_CONFIG_KEYS } from '../../config/Constants';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import colors from '../../theme/Colors';
import size from '../../theme/Fonts';
import images from '../../theme/Images';
import DynamicLinkManager from '../../utils/DynamicLinkManager';
import RemoteConfig from '../../utils/RemoteConfig';
import Utility from '../../utils/Utility';
import { addContinueAction } from '../../actions/FoxyEdgeAction';
import { getScreenWidth } from '../../utils/LayoutUtility';
import { navigateToScreen } from '../../utils/NavigationUtility';
import { isWeb } from '../../utils/BooleanUtility';
import ModalContainerWithoutSafeArea from './ModalContainerWithoutSafeArea';

const { ids, styles } = StyleSheet.create({
  modalContainer: {
    width: '100%',
    height: '100%',
  },
  bottomModal: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    marginBottom: 0,
    marginRight: 0,
    marginLeft: 0,
    paddingHorizontal: 12,
    paddingBottom: 6,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    backgroundColor: colors.white,
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    '@media (min-width: 768px)': {
      width: getScreenWidth() - 24,
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      bottom: 'auto',
      right: 'auto',
    },
  },
  container: {
    alignItems: 'center',
    flexDirection: 'column',
  },
  heading: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignSelf: 'flex-start',
    marginLeft: 8,
  },
  title: {
    color: colors.black,
    fontSize: size.h1,
    fontFamily: 'Roboto-Regular',
    fontWeight: '700',
  },
  subtitle: {
    color: colors.black,
    fontSize: size.h3,
    fontFamily: 'Roboto-Regular',
    fontWeight: '400',
    marginTop: 4,
  },
  image: {
    height: 310,
    width: 256,
    marginHorizontal: 6,
  },
  bottomText: {
    color: colors.foxyBlack,
    fontSize: size.h3,
    fontFamily: 'Roboto-Regular',
    fontStyle: 'italic',
    fontWeight: '700',
    textAlign: 'left',
    paddingLeft: 8,
    marginBottom: 12,
    ...Platform.select({
      web: { width: Utility.getScreenWidth() - 58 },
      default: { width: Utility.getScreenWidth() - 24 },
    }),
  },
  whiteButtonContainer: {
    backgroundColor: colors.white,
    borderRadius: 4,
    borderWidth: 1.5,
    borderColor: colors.black,
    height: 40,
    marginBottom: 8,
    justifyContent: 'center',
    alignItems: 'center',
    ...Platform.select({
      web: { width: Utility.getScreenWidth() - 58 },
      default: { width: Utility.getScreenWidth() - 24 },
    }),
  },
  knowMoreText: {
    fontSize: size.h2,
    fontFamily: 'Roboto-Medium',
    color: colors.foxyBlack,
  },
  blackButtonContainer: {
    backgroundColor: colors.black,
    borderRadius: 4,
    height: 40,
    marginBottom: 12,
    justifyContent: 'center',
    alignItems: 'center',
    ...Platform.select({
      web: { width: Utility.getScreenWidth() - 58 },
      default: { width: Utility.getScreenWidth() - 24 },
    }),
  },
  buttonText: {
    fontSize: size.h2,
    fontFamily: 'Roboto-Medium',
    color: colors.white,
  },
  topIcon: {
    height: 40,
    width: 40,
    borderRadius: 20,
    marginTop: -20,
    marginBottom: 8,
  },
  modal_close_container: {
    position: 'absolute',
    zIndex: 1,
    top: 12,
    right: 0,
  },
  modal_close: {
    height: 23,
    width: 23,
    resizeMode: 'contain',
  },
  topImage: {
    position: 'absolute',
    top: -20,
    width: 40,
    height: 40,
    alignSelf: 'center',
  },
  dropShadow: {
    shadowColor: 'rgba(255, 206, 1, 0.7)',
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 1,
    shadowRadius: 10,
    position: 'absolute',
    top: 0,
  },
  imagesList: {
    height: 334,
    ...Platform.select({
      web: { width: Utility.getScreenWidth() - 58 },
      default: {
        flexGrow: 1,
        justifyContent: 'center',
        alignItems: 'center',
      },
    }),
  },
});

class SubscribeRoutineModal extends PureComponent {
  constructor(props) {
    super(props);
    this.modalData = JSON.parse(
      RemoteConfig.getValue(REMOTE_CONFIG_KEYS.become_member_modal),
    );
    const { route, cartItems } = this.props;
    const {
      previousScreen,
      previousScreenSlug,
      imageUrl,
      continueScreenType,
      isRoutineLive,
    } = route?.params;

    this.addContinueActionArgs = {
      slug: previousScreenSlug,
      imageUrl,
      screenType: continueScreenType,
    };
    if (previousScreen === SCREEN_CONSTANTS.CART && cartItems?.length > 0) {
      this.addContinueActionArgs = {
        slug: 'https://foxy.in/cart',
        imageUrl: cartItems[0].product?.image_url,
        screenType: SCREEN_CONSTANTS.CART,
      };
    }
    this.isRoutineLive = isRoutineLive;
  }

  closeModal = () => {
    const { navigation } = this.props;
    navigation.goBack();
  };

  onPressPrimaryAction = () => {
    const { route } = this.props;
    const { secondaryAction } = route.params;
    this.closeModal();
    setTimeout(() => {
      secondaryAction();
    }, 0);

    // let primaryActionForRenewModal = 'https://www.foxy.in/foxy_edge';

    // AnalyticsManager.logEvent(EventType.artistEvents.MODAL_ACTION, {
    //   [EventParameterKey.TYPE]: 'secondary',
    //   [EventParameterKey.MODAL_NAME]:
    //     EventParameterValue.MODAL_NAME.BECOME_MEMBER_MODAL,
    //   [EventParameterKey.CTA]: 'Know More',
    //   previousScreen,
    //   is_guest_user: Utility.isBlank(authToken),
    //   address_present: Utility.isPresent(address),
    //   dob_present: Utility.isDobPresent(date_of_birth),
    // });
    // DynamicLinkManager.handleDynamicLink(
    //   primaryActionForRenewModal,
    //   this.handleRouteFromLink,
    // );

    // if (Utility.isPresent(slug) && Utility.isPresent(imageUrl)) {
    //   addContinueAction(slug, imageUrl, screenType);
    // }
  };

  joinButton = () => {
    const { route } = this.props;
    const { secondaryButtonText = 'Activate my membership' } = route.params;
    return (
      <DebouncedTouchableOpacity
        style={styles.blackButtonContainer}
        onPress={this.onPressSecondaryAction}
      >
        <Text style={styles.buttonText}>{secondaryButtonText}</Text>
      </DebouncedTouchableOpacity>
    );
  };

  handleRouteFromLink = (route, slug, path, extra, params) => {
    const { navigation } = this.props;
    this.closeModal();
    setTimeout(() => {
      navigateToScreen({
        navigation,
        type: isWeb() ? 'push' : 'navigate',
        screen: route,
        params: { slug, extra, params },
      });
    }, 100);
  };

  onPressSecondaryAction = () => {
    const {
      route,

      authToken,
      address,
      date_of_birth,
    } = this.props;
    const {
      secondaryAction = '',
      secondaryButtonText = '',
      addContinueAction = () => {},
      previousScreen,
      slug,
      imageUrl,
      screenType,
    } = route.params;

    let secondaryActionForRenewModal = 'https://www.foxy.in/loyalty_plans';

    AnalyticsManager.logEvent(EventType.artistEvents.MODAL_ACTION, {
      [EventParameterKey.TYPE]: 'primary',
      [EventParameterKey.MODAL_NAME]:
        EventParameterValue.MODAL_NAME.BECOME_MEMBER_MODAL,
      [EventParameterKey.CTA]: secondaryButtonText,
      previousScreen,
      is_guest_user: Utility.isBlank(authToken),
      address_present: Utility.isPresent(address),
      dob_present: Utility.isDobPresent(date_of_birth),
    });
    DynamicLinkManager.handleDynamicLink(
      secondaryActionForRenewModal,
      this.handleRouteFromLink,
    );

    if (Utility.isPresent(slug) && Utility.isPresent(imageUrl)) {
      addContinueAction(slug, imageUrl, screenType);
    }
  };

  knowMoreButton = () => {
    return (
      <DebouncedTouchableOpacity
        style={styles.whiteButtonContainer}
        onPress={this.onPressPrimaryAction}
      >
        <Text style={styles.knowMoreText}>I will pay instead</Text>
      </DebouncedTouchableOpacity>
    );
  };

  bottomButtons = () => {
    const { hideKnowMore = false } = this.modalData;
    return (
      <>
        {!hideKnowMore && <this.knowMoreButton />}
        <this.joinButton />
      </>
    );
  };

  renderImage = ({ item }) => {
    return <FastImageView style={styles.image} source={item} />;
  };

  renderImageList = () => {
    return (
      <FlatList
        data={this.modalData?.images}
        contentContainerStyle={styles.imagesList}
        renderItem={this.renderImage}
        horizontal
        showsHorizontalScrollIndicator={false}
      />
    );
  };

  renderView = () => {
    return (
      <View style={styles.container}>
        <FastImageView style={styles.topIcon} source={images.clubStar} />
        <View style={styles.heading}>
          <Text style={styles.title}>{'Get this routine for free'}</Text>
          <Text style={styles.subtitle}>
            {
              'Activate your Foxy Edge plan to get exciting benefits, extra discounts & a lot more ...'
            }
          </Text>
        </View>
        <this.renderImageList />
        <Text style={styles.bottomText}>and much more ...</Text>
        <this.bottomButtons />
        <TouchableOpacity
          style={styles.modal_close_container}
          onPress={this.closeModal}
        >
          <FastImageView
            style={styles.modal_close}
            source={images.edge.modal_close}
          />
        </TouchableOpacity>
        <View style={styles.dropShadow}>
          <FastImageView source={images.edge.offer} style={styles.topImage} />
        </View>
      </View>
    );
  };

  render() {
    return (
      <ModalContainerWithoutSafeArea>
        <View style={styles.bottomModal} dataSet={{ media: ids.bottomModal }}>
          <this.renderView />
        </View>
      </ModalContainerWithoutSafeArea>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    cartItems: state.bag.cartItems,
    address: state.UserAccountInfo.addresses,
    authToken: state.UserAccountInfo.authToken,
    date_of_birth: state.UserAccountInfo.profile.date_of_birth,
  };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      addContinueAction,
    },
    dispatch,
  ),
});

export default withNavigation(
  connect(mapStateToProps, mapDispatchToProps)(SubscribeRoutineModal),
);
