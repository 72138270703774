import React from 'react';
import { useRoute } from '@react-navigation/native';
import { API_DOMAIN, LISTS_API_VERSION } from '../../config/Constants';
import ShopOnline from '../../containers/List/ShopOnline';

export default function ShopOnlineBrandCategory() {
  const { params = {} } = useRoute();
  const { first, second, third, shortSlug } = params;
  const url = `${API_DOMAIN}/api/${LISTS_API_VERSION}/brands/${shortSlug}/categories/${first}${second ? '/second' : ''}${third ? '/third' : ''}/shop_online`;
  return <ShopOnline url={url} />;
}
