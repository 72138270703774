import React from 'react';
import { View, StyleSheet } from 'react-native';
import ShimmerPlaceHolder from '../../libraries/ReactNativeShimmerPlaceholder';
import { getScreenWidth } from '../../utils/LayoutUtility';
import colors from '../../theme/Colors';

const TabCardShimmer = ({ style, showBottomText }) => {
  const shimmerColor = [colors.white, colors.shimmer.lightGrey, colors.white];
  return (
    <View style={styles.tabCardContainer}>
      <ShimmerPlaceHolder
        autoRun
        colorShimmer={shimmerColor}
        style={[styles.tabCard, style]}
      />
      {showBottomText && (
        <ShimmerPlaceHolder
          autoRun
          colorShimmer={shimmerColor}
          style={styles.tabText}
        />
      )}
    </View>
  );
};

export const CircularTabShimmer = () => {
  return (
    <View style={[styles.tabContainer, { paddingBottom: 24 }]}>
      {[...Array(5)].map((_, index) => (
        <TabCardShimmer
          key={index}
          style={styles.circularCardStyle}
          showBottomText
        />
      ))}
    </View>
  );
};

export const PillsTabShimmer = () => {
  return (
    <View style={styles.tabContainer}>
      {[...Array(4)].map((_, index) => (
        <TabCardShimmer key={index} style={styles.pillsTabCard} />
      ))}
    </View>
  );
};

const TabListShimmer = () => {
  return (
    <>
      <CircularTabShimmer />
      <View style={styles.greyLine} />
      <PillsTabShimmer />
    </>
  );
};

export default TabListShimmer;

const styles = StyleSheet.create({
  tabCardContainer: {
    paddingHorizontal: 4,
    alignItems: 'center',
  },
  tabCard: {
    borderRadius: 150,
    overflow: 'hidden',
    width: getScreenWidth() / 6,
    height: getScreenWidth() / 6,
  },
  tabText: {
    width: getScreenWidth() / 5,
    marginTop: 10,
    height: 25,
    borderRadius: 16,
  },
  tabContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 20,
    paddingLeft: 10,
  },
  pillsTabCard: {
    height: 24,
    borderRadius: 16,
    width: 110,
    marginBottom: 10,
  },
  greyLine: {
    width: getScreenWidth(),
    height: 1.5,
    backgroundColor: colors.lightGrey,
    top: 20,
  },
});
