import { View, Text, TouchableOpacity, StyleSheet, ScrollView } from 'react-native';
import React, { useState } from 'react';
import colors from '../../theme/Colors';
import FastImageView from '../FastImageView';
import images from '../../theme/Images';
import { getMinifiedImage } from '../../utils/ImageUtility';
import { getScreenWidth } from '../../utils/LayoutUtility';
import { memoize } from 'lodash';
import Utility from '../../utils/Utility';
import { useRoute } from '@react-navigation/native';

export default function SizeChartPlaceholder(props) {
  const { sizeChart: { metrics = [], sizeChartData = [], image = '' } = {} } = props;
  const [selectedPillIndex, setSelectedPillIndex] = useState(0);
  const [selectedMetric, setSelectedMetric] = useState(metrics[0]);

  const CategoryPill = ({ title, index }) => {
    const pillTextStyle = selectedPillIndex === index ? styles.selectedPillText : styles.unselectedPillText;
    const pillContainerStyle =
      selectedPillIndex === index ? styles.selectedPillContainer : styles.unselectedPillContainer;
    return (
      <TouchableOpacity
        style={pillContainerStyle}
        onPress={memoize(
          () => onPillPress(index),
          () => [index],
        )}
      >
        <Text style={pillTextStyle}>{title}</Text>
      </TouchableOpacity>
    );
  };

  const onPillPress = (index) => {
    setSelectedPillIndex(index);
  };

  const onMetricPress = (value) => {
    setSelectedMetric(value);
  };

  const SelectedSizeChart = () => {
    const { title } = sizeChartData[selectedPillIndex];
    return (
      <View style={styles.selectedSizeChartContainer}>
        <Text style={styles.selectedSizeChartTitle}>{title}</Text>
        <View style={styles.metricContainer}>
          {metrics.map((metric, index) => {
            const metricTextStyle = metric === selectedMetric ? styles.selectedMetric : styles.unselectedMetric;
            return (
              <>
                {index !== 0 && <Text style={styles.unselectedMetric}> | </Text>}
                <TouchableOpacity
                  hitSlop={Utility.getHitSlop()}
                  onPress={memoize(
                    () => onMetricPress(metric),
                    () => [metric],
                  )}
                >
                  <Text style={metricTextStyle}>{metric}</Text>
                </TouchableOpacity>
              </>
            );
          })}
        </View>
        {sizeChartData[selectedPillIndex] && sizeChartData[selectedPillIndex][`${selectedMetric}Chart`] && (
          <FastImageView
            source={sizeChartData[selectedPillIndex][`${selectedMetric}Chart`]}
            style={styles.sizeChartImage}
            resizeMode='contain'
          />
        )}
      </View>
    );
  };

  return (
    <ScrollView bounces={false}>
      <View style={styles.container}>
        <FastImageView
          source={getMinifiedImage(
            image,
            styles.placeholderImage.width,
            styles.placeholderImage.height,
          )}
          style={styles.placeholderImage}
        />
        <View style={styles.categoryPillsContainer}>
          {sizeChartData.map((item, index) => {
            return <CategoryPill title={item.title} index={index} />;
          })}
        </View>
        <SelectedSizeChart />
      </View>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: getScreenWidth(),
    paddingVertical: 48,
    alignItems: 'center',
    backgroundColor: colors.white,
    alignSelf: 'center',
  },
  placeholderImage: {
    width: 200,
    height: 200,
    resizeMode: 'contain',
  },
  categoryPillsContainer: {
    flexWrap: 'wrap',
    marginTop: 48,
    flexDirection: 'row',
    paddingHorizontal: 12,
  },
  unselectedPillContainer: {
    borderColor: colors.foxyBlack,
    borderWidth: 1,
    paddingVertical: 6,
    paddingHorizontal: 16,
    borderRadius: 20,
    alignSelf: 'center',
    marginBottom: 8,
    marginRight: 8,
  },
  selectedPillContainer: {
    borderColor: colors.foxyBlack,
    borderWidth: 1,
    paddingVertical: 6,
    paddingHorizontal: 16,
    borderRadius: 20,
    alignSelf: 'center',
    marginBottom: 8,
    marginRight: 8,
    backgroundColor: colors.foxyBlack,
  },
  unselectedPillText: {
    fontFamily: 'Roboto-Regular',
    fontSize: 12,
    color: colors.foxyBlack,
  },
  selectedPillText: {
    fontFamily: 'Roboto-Regular',
    fontSize: 12,
    color: colors.white,
  },
  selectedSizeChartContainer: {
    width: '100%',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: 24,
  },
  selectedSizeChartTitle: {
    fontFamily: 'Roboto-Bold',
    fontSize: 16,
    color: colors.foxyBlack,
  },
  selectedMetric: {
    fontFamily: 'Roboto-Medium',
    fontSize: 14,
    color: colors.foxyBlue,
    marginTop: 4,
  },
  unselectedMetric: {
    fontFamily: 'Roboto-Medium',
    fontSize: 14,
    color: colors.foxyBlack,
    marginTop: 4,
  },
  metricContainer: {
    flexDirection: 'row',
  },
  sizeChartImage: {
    width: getScreenWidth() - 24,
    height: 120,
    resizeMode: 'contain',
    marginTop: 24,
    marginHorizontal: 12,
  },
});
