import React from 'react';
import {
  View,
  StyleSheet,
  Text,
  Image,
  TouchableWithoutFeedback,
  TouchableOpacity,
} from 'react-native';
import PropTypes from 'prop-types';
import colors from '../../theme/Colors';
import size from '../../theme/Fonts';
import Utility from '../../utils/Utility';
import images from '../../theme/Images';
import Config from '../../libraries/ReactNativeConfig';
import FastImageView from '../FastImageView';
import DebouncedTouchableOpacity from './DebouncedTouchableOpacity';

const styles = StyleSheet.create({
  wrapper: {
    paddingLeft: 4,
    paddingRight: 4,
  },
  chipContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: 32,
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 12,
    paddingRight: 12,
    borderRadius: 16,
    borderColor: colors.border,
    borderWidth: 1,
    minWidth: 40,
  },
  chipContainerSelected: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: 32,
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 12,
    paddingRight: 12,
    borderRadius: 16,
    borderColor: Config.FILTER_MODAL_SELECTED_CHIP_BORDER_COLOR,
    backgroundColor: Config.FILTER_MODAL_SELECTED_CHIP_BACKGROUND_COLOR,
    borderWidth: 1,
    minWidth: 40,
  },
  chipText: {
    fontFamily: 'Roboto-Regular',
    fontSize: size.h4,
    color: colors.foxyBlack,

    textAlign: 'center',
  },
  chipTextSelected: {
    fontFamily: 'Roboto-Regular',
    fontSize: size.h4,
    color: Config.FILTER_MODAL_SELECTED_CHIP_TEXT_COLOR,
    textAlign: 'center',
  },
  chipIconRight: {
    height: 10,
    width: 10,
    tintColor: '#173143CC',
    marginLeft: 10,
  },
  chipIconRightSelected: {
    height: 10,
    width: 10,
    tintColor: Config.FILTER_MODAL_SELECTED_CHIP_RIGHT_ICON_TINT_COLOR,
    marginLeft: 10,
  },

  colorContainer: {
    height: 16,
    width: 16,
    borderRadius: 6,
  },
  chipIcon: {
    height: 13,
    width: 11,
    marginRight: 4,
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    tintColor: colors.foxyBlack,
  },

  badgeCircle: {
    height: 15,
    width: 15,
    backgroundColor: colors.subtitle,
    marginRight: 4,
    borderRadius: 30,
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
  },
  badgeCountText: {
    fontFamily: 'Roboto-Bold',
    fontSize: 10,
    color: colors.white,
  },
  defaultChipText: {
    fontFamily: 'Roboto-Regular',
    fontSize: size.h4,
    color: colors.border,
    textAlign: 'center',
  },
  rightChipIcon: {
    height: 13,
    width: 11,
    marginRight: 4,
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    tintColor: colors.foxyBlack,
    marginLeft: 4,
  },
  chevronIcon: {
    height: 12,
    width: 12,
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    tintColor: colors.foxyBlack,
  },
});

const ChipRightIcon = (props) => {
  const {
    style,
    onQuickFilterRemoved,
    foxyMatchRightIcon,
    criteriaName,
    tintColor,
    isSelected,
  } = props;
  if (
    Utility.isPresent(foxyMatchRightIcon) &&
    criteriaName === 'quick_filter_foxy_match' &&
    !isSelected
  ) {
    return (
      <Image
        source={foxyMatchRightIcon}
        style={styles.rightChipIcon}
        resizeMode="contain"
      />
    );
  }
  return (
    <TouchableOpacity onPress={onQuickFilterRemoved}>
      <Image style={style} source={images.cross} />
    </TouchableOpacity>
  );
};

const ChipLeftIcon = (props) => {
  const { count, colorCode, leftIcon, criteriaName, image } = props;

  let icon = leftIcon;
  let imageStyle = {};
  if (Utility.isPresent(image)) {
    if (typeof image !== 'string') {
      icon = image;
    } else {
      icon = { uri: image };
    }
    imageStyle = { height: 18, width: 18 };
  }

  if (Utility.isPresent(colorCode)) {
    return (
      <View
        style={[
          styles.colorContainer,
          { backgroundColor: colorCode, marginRight: 8 },
        ]}
      />
    );
  }

  if (
    Utility.isBlank(colorCode) &&
    count > 0 &&
    criteriaName === 'quick_filter_sort_by'
  ) {
    return (
      <View style={styles.badgeCircle}>
        <Text style={styles.badgeCountText}>{count}</Text>
      </View>
    );
  }

  if (Utility.isPresent(icon) && criteriaName === 'quick_filter_sort_by') {
    return (
      <Image
        style={[styles.chipIcon, { marginRight: 8 }, imageStyle]}
        source={icon}
        resizeMode={'contain'}
      />
    );
  }

  return null;
};

const ChipsButton = (props) => {
  const {
    chipName,
    onChipClick,
    chipLeftIcon = null,
    chipRightIcon = null,
    isSelected,
    colorCode,
    count,
    onQuickFilterRemoved,
    criteriaName,
    image,
    height,
    unselectedChipBackgroundColor,
    foxyMatchRightIcon = null,
    selectedChipBackGroundColor,
    index,
    tintColor,
  } = props;
  if (Utility.isBlank(chipName)) return null;
  const textStyle = isSelected ? styles.chipTextSelected : styles.chipText;
  const rightIconStyle = isSelected
    ? styles.chipIconRightSelected
    : styles.chipIconRight;
  const chipContainer = isSelected
    ? styles.chipContainerSelected
    : [
        styles.chipContainer,
        { backgroundColor: unselectedChipBackgroundColor },
      ];

  return (
    <DebouncedTouchableOpacity
      {...props}
      delayPressOut={0}
      onPress={onChipClick}
    >
      <View style={styles.wrapper}>
        <View style={chipContainer}>
          <ChipLeftIcon
            count={count}
            colorCode={colorCode}
            leftIcon={chipLeftIcon}
            criteriaName={criteriaName}
            image={image}
          />

          <Text
            style={textStyle}
            numberOfLines={1}
            ellipsizeMode="tail"
          >{`${chipName}`}</Text>
          {(Utility.isPresent(chipRightIcon) ||
            Utility.isPresent(foxyMatchRightIcon)) && (
            <ChipRightIcon
              style={rightIconStyle}
              onQuickFilterRemoved={onQuickFilterRemoved}
              foxyMatchRightIcon={foxyMatchRightIcon}
              criteriaName={criteriaName}
              tintColor={tintColor}
              isSelected={isSelected}
            />
          )}
        </View>
      </View>
    </DebouncedTouchableOpacity>
  );
};

ChipsButton.prototype = {
  chipName: PropTypes.string,
  chipRightIcon: PropTypes.string,
  chipLeftIcon: PropTypes.string,
  chipSelectedBackgroundColor: PropTypes.string,
  chipUnSelectedBackgroundColor: PropTypes.string,
  onChipClick: PropTypes.any,
  chipIndex: PropTypes.number,
  isSelected: PropTypes.bool,
  foxyMatchRightIcon: PropTypes.string,
};

ChipsButton.defaultProps = {
  chipName: null,
  chipRightIcon: null,
  chipLeftIcon: null,
  chipSelectedBackgroundColor: colors.white,
  chipUnSelectedBackgroundColor: colors.white,
  onChipClick: () => {},
  chipIndex: -1,
  isSelected: false,
  foxyMatchRightIcon: null,
};

export default ChipsButton;
