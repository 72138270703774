import {
  Text,
  StyleSheet,
  View,
  Image,
  FlatList,
  Animated,
  TouchableOpacity,
  BackHandler,
} from 'react-native';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styles from './styles';
import images from '../../theme/Images';
import { StaticNavigationHeader } from '../../components/shared';
import FastImageView from '../../components/FastImageView';
import { getFaqSectionData } from '../../actions/ActionTypes';
import withNavigation from '../../utils/WithNavigation';
import {
  AnalyticsManager,
  EventParameterKey,
  EventParameterValue,
  EventType,
} from '../../analytics';
import { isAndroid } from '../../utils/BooleanUtility';

class FaqSection extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      faq_data: [],
      isLoading: true,
    };
    if (isAndroid()) {
      this.backhandler = BackHandler.addEventListener(
        'hardwareBackPress',
        this.goBack,
      );
    }
  }

  componentDidMount() {
    const { navigation, getFaqSectionData } = this.props;
    getFaqSectionData('', this.getFaqSectionDataCallBack);
  }

  componentWillUnmount() {
    if (isAndroid()) {
      this.backhandler.remove();
    }
  }

  onPressQuestion = (slug, name, index) => {
    const { navigation } = this.props;
    AnalyticsManager.logEvent(EventType.discoveryEvents.LIST_ITEM_CLICK, {
      [EventParameterKey.LIST_POSITION]: index,
      [EventParameterKey.ITEM_NAME]: name.slice(0, 45),
    });
    navigation.navigate('FaqQuestionSection', { slug, name });
  };

  getFaqSectionDataCallBack = (success, response) => {
    if (success) {
      this.setState({
        faq_data: response.data,
        isLoading: false,
      });
      AnalyticsManager.logEvent(EventType.pageLoad.PAGE_LOAD, {
        [EventParameterKey.SCREEN_NAME]:
          EventParameterValue.SCREEN_NAME.NEED_HELP,
      });
    }
  };

  faqQuestion = ({ item, index }) => {
    const { attributes = {}, id } = item;
    const { name = '', slug = '' } = attributes;
    return (
      <TouchableOpacity onPress={() => this.onPressQuestion(slug, name, index)}>
        <View style={styles.questionContainer}>
          <Text style={styles.questionTextStyle}>{name}</Text>
          <Image source={images.chevronRight} style={styles.rightArrowStyle} />
        </View>
        <View style={styles.horizontalLine} />
      </TouchableOpacity>
    );
  };

  goBack = () => {
    const { navigation } = this.props;
    navigation.goBack();
    return true;
  };

  keyExtractor = (item, index) => index;

  render() {
    const { faq_data, isLoading } = this.state;
    return (
      <View style={styles.container}>
        <StaticNavigationHeader title='Need help?' />
        <View style={styles.topSection}>
          <Text style={styles.topSectionHeading}>How can we help you?</Text>
          <FastImageView
            source={images.faq_top_section_image}
            style={styles.imageStyle}
            resizeMode='contain'
          />
        </View>
        <FlatList
          style={styles.listContainer}
          data={faq_data}
          renderItem={this.faqQuestion}
          keyExtractor={this.keyExtractor}
        />
      </View>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      getFaqSectionData,
    },
    dispatch,
  ),
});

export default withNavigation(connect(null, mapDispatchToProps)(FaqSection));
