import React, { Component } from 'react';
import {
  View,
  Text,
  Keyboard,
  TextInput,
} from 'react-native';
import Config from '../../libraries/ReactNativeConfig';
import FoxyShadowButton from '../../lib/FoxyShadowButton';
import { PaymentStyles } from './styles';
import colors from '../../theme/Colors';
import withNavigation from '../../utils/WithNavigation';
import { isBlank, isDesktop } from '../../utils/BooleanUtility';
import { getScreenWidth } from '../../utils/LayoutUtility';
import AppConfig from '../../config/AppConfig';
import { isPhoneNumberValid } from '../../utils/AddressUtility';
import { connect } from 'react-redux';

const detailsForPaymentWaitingPage = {
  name: 'Blink by Emtel',
  intent_url: null,
  icon: 'https://mufoxy.cdn.ekanek.app/media/app_asset/image/1/blink_emtel.png',
  merchant_name: 'FoxyMu',
};

class BlinkPayment extends Component {
  constructor(props) {
    super(props);
    this.ref = [];
    this.ref.inputField = React.createRef();
    const { mobileNumber = '' } = this.props;
    this.state = {
      mobileNumber,
      isLoaderVisible: false,
      error: '',
    };
  }

  addNumber = value => {
    if (value?.length > Config.CONTACT_NUMBER_LIMIT) {
      return;
    }
    this.setState({ mobileNumber: value, error: '' });
  };

  createOrderAndPay = details => {
    const { createOrder } = this.props;
    const { mobileNumber } = this.state;
    if (!isPhoneNumberValid(mobileNumber, AppConfig.callingCode, Config.COUNTRY_ISO_CODE)) {
      this.setState({
        error: 'Enter a valid mobile number',
      });
      return;
    }
    const blinkParams = {
      virtual_bank_account_id: mobileNumber,
      bankcode: 'Blink',
    };
    const payload = {
      vpa: mobileNumber,
    };

    const paymentPayload = {
      paymentGateway: 'peach',
      paymentMethod: 'blinkbyemtel',
      paymentMethodPayload: blinkParams,
      payuPayload: payload,
      extraData: {
        method: 'blinkbyemtel',
        details: detailsForPaymentWaitingPage,
      },
    };

    createOrder(paymentPayload);
  };

  render() {
    const styles = PaymentStyles;
    const { mobileNumber, isLoaderVisible, error } = this.state;
    return (
      <View style={styles.upiContainer}>
        <View styles={styles.enterCvvContainer}>
          <TextInput
            placeholder='Enter your Blink Mobile Number'
            placeholderTextColor={colors.silver}
            lineWidth={1}
            blurOnSubmit={false}
            value={mobileNumber}
            onChangeText={this.addNumber}
            ref={this.ref.inputField}
            containerStyle={styles.vpaContainer}
            onSubmitEditing={Keyboard.dismiss}
            returnKeyType='done'
            autoCapitalize={false}
            keyboardType='numeric'
            style={styles.upiTextField}
          />
          {!isBlank(error) && <Text style={styles.upiTextFieldError}>{error}</Text>}
          <FoxyShadowButton
            width={isDesktop() ? '100%' : getScreenWidth() - 34}
            title={'Pay'}
            onPress={this.createOrderAndPay}
            backgroundColor={colors.primaryActionBackgroundColor}
            style={styles.createOrderButton}
            firstColor={colors.linearGradientGreenFirst}
            secondColor={colors.linearGradientGreenSecond}
            Loading={isLoaderVisible}
          />
        </View>
      </View>
    );
  }
}

const mapStateToProps = function (state) {
  return {
    mobileNumber: state.UserAccountInfo.mobileNumber,
  };
};

export default connect(mapStateToProps, null)(withNavigation(BlinkPayment));
