import {
  View,
  Image,
  TouchableOpacity,
  StyleSheet,
} from 'react-native';
import React from 'react';
import SearchInput from './SearchInput';
import Account from './Account';
import Bag from './Bag';
import colors from '../../theme/Colors';
import CustomTabRoute from './CustomTabRoute';
import Config from '../../libraries/ReactNativeConfig';

export default function CustomTabHeader(props) {
  const { navigation, state } = props;
  const handleIconPress = () => {
    navigation.navigate('Feed');
  };

  return (
    <View style={styles.container}>
      <TouchableOpacity onPress={handleIconPress}>
        <Image source={{ uri: Config.DESKTOP_HEADER_LOGO }} style={logoStyle} />
      </TouchableOpacity>
      {state.routes.map((route, index) => <CustomTabRoute {...props} index={index} route={route} />)}
      <View style={styles.rightBar}>
        <SearchInput navigation={navigation} />
        <Bag navigation={navigation} />
        <Account navigation={navigation} />
      </View>
    </View>
  );
}

const logoStyle = {
  width: +Config.DESKTOP_LOGO_WIDTH,
  height: +Config.DESKTOP_LOGO_HEIGHT,
  lineHeight: 1.5,
};

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    textAlign: 'center',
    paddingHorizontal: 24,
    paddingVertical: 0,
    height: 70,
    backgroundColor: colors.white,
    boxShadow: '0px 2px 10px 1px rgba(229,229,229,0.8)',
    zIndex: 10,
  },
  rightBar: {
    flexDirection: 'row',
    minWidth: 470,
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'center',
    lineHeight: 1.5,
  },
  button: {
    width: 78,
    height: '100%',
    position: 'relative',
    fontSize: 16,
    textAlign: 'center',
    lineHeight: 1.5,
  },
});
