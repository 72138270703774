import React, { PureComponent } from 'react';
import {
  View,
  StyleSheet,
  Image,
  Text,
  TouchableWithoutFeedback,
  FlatList,
  ScrollView,
} from 'react-native';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import colors from '../../../theme/Colors';
import size from '../../../theme/Fonts';
import images from '../../../theme/Images';
import withNavigation from '../../../utils/WithNavigation';
import Utility from '../../../utils/Utility';
import ImageTagsProcessingUtil from '../../../utils/ImageTagsProcessingUtil';
import Marker from '../../onboarding/ImageMarkers/Marker';
import { PERMISSION_STORE, SCREEN } from '../../../config/Constants';
import { SCREEN_CONSTANTS } from '../../../config/ScreenConstants';
import ConsumerProfileSelfieMarker from '../../onboarding/ImageMarkers/ConsumerProfileSelfieMarker';
import { AnalyticsManager, EventParameterKey, EventType } from '../../../analytics';
import FoxyShadowButton from '../../../lib/FoxyShadowButton';
import Config from '../../../libraries/ReactNativeConfig';

class ContentFoxyMatch extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
    };
    this.currentPermissionState = '';
    const {
      entityName = '',
      screenName = '',
      headingText = '',
      listId = '',
      listName = '',
    } = this.props;
    this.fireFoxyMatchEvent();
    this.buttonWidth = Utility.getScreenWidth() - 24;
  }

  componentDidMount = () => {
    const { selectedFilters } = this.props;
    const isChecked =
      !Utility.isBlank(selectedFilters) &&
      Utility.isPresent(selectedFilters[0].value);
    this.setState({
      checked: isChecked,
    });
  };

  checkCameraPermission = () => {
    const { navigation } = this.props;
    navigation.navigate(SCREEN.ATTRIBUTE_SELECTOR);
  };

  emptyStateContainer = () => (
    <View style={style.emptyStateContainer}>
      <Image source={images.selfiePromptSmall} />
      <Text style={style.emptyStateTitle}>
        Personalised suggestions are just a selfie away
      </Text>
      <Text style={style.emptyStateDescription}>
        Take a selfie to detect your facial attributes and filter out products
        suitable for you
      </Text>

      <TouchableWithoutFeedback onPress={this.navigateToSelfie}>
        <View style={style.takeSelfieButtonContainer}>
          <Text style={style.takeSelfieButtonText}>Take Selfie</Text>
        </View>
      </TouchableWithoutFeedback>
    </View>
  );

  checkboxContainer = () => {
    const { checked } = this.state;
    return (
      <TouchableWithoutFeedback onPress={this.onChange}>
        <View style={style.checkboxRow}>
          <View>
            <Image
              style={style.checkboxContainer}
              source={checked ? images.checkSquare : images.squareOutline}
            />
          </View>
          <Text style={style.checkboxLabel}>
            Products suitable for my attributes
          </Text>
        </View>
      </TouchableWithoutFeedback>
    );
  };

  onChange = () => {
    const { checked } = this.state;
    const nextState = !checked;
    const {
      onFilterSelect,
      facialAttributes = [],
    } = this.props;
    const facialAttributeArray = [];

    if (nextState) {
      facialAttributes.forEach((element) => {
        element.values.forEach((elementValue) => {
          facialAttributeArray.push({
            attribute_id: element.id,
            value: elementValue.id,
          });
        });
      });
    }
    this.setState(
      {
        checked: nextState,
      },
      this.fireFoxyMatchEvent,
    );
    onFilterSelect({ value: facialAttributeArray });
  };

  fireFoxyMatchEvent = (itemName = '') => {
    const {
      entityName = '',
      screenName = '',
      headingText = '',
      listId = '',
      listName = '',
      listSlug = '',
    } = this.props;
    AnalyticsManager.logEvent(EventType.discoveryEvents.FILTER_ACTION, {
      [EventParameterKey.FILTER_TYPE]: headingText,
      [EventParameterKey.LIST_ID]: listId,
      [EventParameterKey.LIST_NAME]: listName,
      [EventParameterKey.LIST_SLUG]: listSlug,
      [EventParameterKey.ENTITY_NAME]: entityName,
      [EventParameterKey.SCREEN_NAME]: screenName,
      [EventParameterKey.FILTER_FOXY_MATCH]: this.state.checked,
      [EventParameterKey.ITEM_NAME]: itemName,
    });
  };

  checkboxRow = () => <this.checkboxContainer />;

  _facialMarkerTags = (props) => {
    const { marker } = props;
    return (
      <View style={style.markerContainer}>
        <ConsumerProfileSelfieMarker
          shadowOpacity={Utility.isAndroid() ? 1.0 : 0.3}
          shadowElevation={1}
          title={marker.name}
          values={marker.values}
          onPressMarker={() => {}}
          textWidth={70}
        />
      </View>
    );
  };

  navigateToSelfie = () => {
    const { navigation, resetQuickFilter } = this.props;
    this.fireFoxyMatchEvent('Update Selfie');
    navigation.goBack();
    resetQuickFilter();
    setTimeout(this.checkCameraPermission, 0);
  };

  updateSelfieButton = () => (
    <TouchableWithoutFeedback onPress={this.navigateToSelfie}>
      <Text style={style.updateSelfieButton}>Update Selfie </Text>
    </TouchableWithoutFeedback>
  );

  renderFlatListItem = ({ item, index }) => (
    <this._facialMarkerTags marker={item} />
  );

  showSelfieAttributes = (props) => {
    const { selfieAttributes } = props;
    return (
      <View style={style.selfieAttributesContainer}>
        <View style={style.rowWithSpaceBetween}>
          <Text style={style.myAttributesText}>My Attributes And Goals</Text>
          <this.updateSelfieButton />
        </View>

        <FlatList
          style={{ overflow: 'visible' }}
          data={selfieAttributes}
          numColumns={3}
          showsHorizontalScrollIndicator={false}
          renderItem={this.renderFlatListItem}
        />
      </View>
    );
  };

  render() {
    const { facialAttributes, headingText } = this.props;
    const buttonColor = Utility.isPresent(Config.THEME_COLOR)
      ? Config.THEME_COLOR
      : colors.black;
    const filledButtonUnderlayColor =
      Config.FILTER_MODAL_FILLED_BUTTON_UNDERLAY_COLOR ||
      colors.foxyShadowButtonUnderlayColor;
    return (
      <View>
        <ScrollView>
          <View style={style.wrapper}>
            <View style={style.container}>
              <View style={style.foxyMatchTitleView}>
                <Text style={style.titleStyle}>{headingText}</Text>
                <View style={style.textUnderLine} />
              </View>
              {Utility.isBlank(facialAttributes) && (
                <this.emptyStateContainer />
              )}
              {Utility.isPresent(facialAttributes) && (
                <this.showSelfieAttributes
                  selfieAttributes={facialAttributes}
                />
              )}
            </View>
          </View>
        </ScrollView>
        <FoxyShadowButton
          title={'Apply'}
          onPress={this.onChange}
          style={style.buttonContainer}
          textStyle={style.buttonText}
          firstColor={buttonColor}
          secondColor={buttonColor}
          underlayColor={filledButtonUnderlayColor}
          underlayWidth={this.buttonWidth}
        />
      </View>
    );
  }
}

const mapStateToProps = (state) => ({
  facialAnalysis: state.UserAccountInfo.facialAnalysis,
});

export default withNavigation(connect(mapStateToProps, null)(ContentFoxyMatch));

const style = StyleSheet.create({
  wrapper: {
    padding: 12,
    height: 350,
  },
  emptyStateContainer: {
    padding: 15,
    backgroundColor: colors.ultraLightGray,
    borderRadius: 4,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  emptyStateTitle: {
    fontFamily: 'Roboto-Medium',
    color: colors.foxyBlack,
    marginTop: 15,
    fontSize: size.h3,
  },
  emptyStateDescription: {
    fontFamily: 'Roboto-Regular',
    textAlign: 'center',
    color: colors.subtitle,
    marginTop: 8,
    fontSize: size.h4,
  },

  takeSelfieButtonContainer: {
    height: 40,
    width: '100%',
    borderWidth: 1,
    borderRadius: 4,
    borderColor: colors.borderDark,
    marginTop: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },

  takeSelfieButtonText: {
    fontFamily: 'Roboto-Medium',
    color: colors.foxyBlack,
    fontSize: size.h3,
  },

  selfieAttributesContainer: {
    paddingBottom: 30,
    paddingLeft: 8,
    paddingRight: 8,
    backgroundColor: colors.white,
    borderRadius: 4,
    flexDirection: 'column',
    justifyContent: 'center',
  },

  row: {
    flexDirection: 'row',
  },

  checkboxLabel: {
    fontFamily: 'Roboto-Regular',
    fontSize: size.h3,
    color: colors.foxyBlack,
    marginLeft: 10,
  },

  checkboxStyle: {
    height: 18,
    width: 18,
    borderColor: colors.bor,
  },

  myAttributesText: {
    fontFamily: 'Roboto-Bold',
    fontSize: size.h4,
    color: colors.foxyBlack,
  },
  rowWithSpaceBetween: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  updateSelfieButton: {
    fontFamily: 'Roboto-Medium',
    color: colors.foxyBlue,
    marginBottom: 10,
  },

  markerContainer: {
    marginLeft: 2,
    marginRight: 2,
    marginTop: 5,
    marginBottom: 5,
  },

  checkboxRow: {
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
  },

  checkboxContainer: {
    height: 18,
    width: 18,
    borderRadius: 4,
  },
  titleStyle: {
    fontFamily: 'Roboto-Medium',
    fontSize: 18,
    color: colors.foxyBlack,
    alignItems: 'center',
  },
  textUnderLine: {
    width: 92,
    height: 1,
    backgroundColor: colors.foxyBlack,
  },
  foxyMatchTitleView: {
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    backgroundColor: colors.white,
    paddingBottom: 0,
    height: 48,
    shadowOpacity: 0,
    elevation: 0,
    marginTop: 10,
    paddingLeft: 8,
  },
  buttonText: { borderRadius: 8 },
  buttonContainer: {
    marginTop: 12,
    alignItems: 'center',
    width: Utility.getScreenWidth() - 32,
    alignSelf: 'center',
    position: 'absolute',
    bottom: 0,
  },
});
