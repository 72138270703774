import React, { PureComponent } from 'react';
import { View, StyleSheet, FlatList, Text } from 'react-native';
import Config from '../../libraries/ReactNativeConfig';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import { compose } from 'recompose';
import Card from '../../lib/Card';
import { withEither } from '../../lib/Monads';
import ProductImageAndRating from '../Product/ProductImageAndRating';

import { ProductDescription } from '../Product';
import AddToCart from '../Product/AddToCart';
import { PRODUCT } from '../../config/LayoutConstants/ProductConfig';
import Utility from '../../utils/Utility';
import ScaleAnimate from '../shared/ScaleAnimate';
import {
  AnalyticsManager,
  EventType,
  EventParameterKey,
} from '../../analytics';
import { LAYOUT } from '../../config/Constants';
import colors from '../../theme/Colors';
import AnalyticsUtility from '../../analytics/AnalyticsUtility';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import { applyFlashDeal } from '../../actions/ActionTypes';

import FastImageView from '../FastImageView';
import size from '../../theme/Fonts';
import SkuColorVariant from './SkuColorVariant';
import SkuSizeVariant from './SkuSizeVariant';
import AppConfig from '../../config/AppConfig';
import WithNavigation from '../../utils/WithNavigation';
import { isDesktop } from '../../utils/BooleanUtility';
import { navigateToScreen } from '../../utils/NavigationUtility';
import withProfiledNavigation from '../../utils/withProfiledNavigation';
import { getNavigationSource } from '../../utils/PerfUtility';

class LargeVariantGrid extends PureComponent {
  static getComponentHeight() {
    return 350;
  }

  constructor(props) {
    super(props);

    this.debouncedNavigate = _.debounce(this.navigate, 2000, {
      leading: true,
      trailing: false,
    });
    this.debounceProductCardClick = _.debounce(
      this.fireSearchResultClickEvent,
      2000,
      {
        leading: true,
        trailing: false,
      },
    );

    this.variants_position = 'top';
    this.cta_position = 'top';
    this.variantCircleContainerStyleCache = {};
    this.navigationSource = getNavigationSource(this, this.props);
  }

  showCart = () => {
    const { toggleCartVisibility } = this.props;
    // TODO: This prevents a crash from the search results page but doesn't allow the user to navigate to the cart page
    if (toggleCartVisibility !== undefined) {
      toggleCartVisibility();
    }
  };

  navigate = () => {
    const {
      navigation,
      itemData,
      itemData: { id, slug, sku_id } = {},
      toggleCartVisibility,
      applyFlashDeal,
      previousScreen,
    } = this.props;

    if (previousScreen === SCREEN_CONSTANTS.SEARCH) {
      applyFlashDeal({
        type: 'product',
        id,
      });
    }
    navigateToScreen({
      navigation,
      type: 'push',
      screen: 'Product',
      navigationSource: this.navigationSource,
      params: {
        id,
        slug,
        display: LAYOUT.SCREEN,
        itemData,
        sku_id,
        toggleCartVisibility,
        previousScreen,
      },
    });
  };

  onPress = async () => {
    const {
      onPress = () => {},
      listIndex,
      index,
      search,
      searchQuery,
      elementItemCounts,
      itemData,
      itemData: { id = '', slug = '' } = {},
      listId,
      layout,
      previousScreen,
      listName,
      listContent,
      listSlug = '',
      toggleUnavailableProductsModal,
    } = this.props;
    if (previousScreen === SCREEN_CONSTANTS.SEARCH) {
      let clickedIndex = Utility.addPreviousIndexValues(
        elementItemCounts,
        listIndex,
      );

      clickedIndex += index + 1;
      const meta = {
        [EventParameterKey.SEARCH_QUERY]: searchQuery,
        [EventParameterKey.ITEM_TYPE]: itemData.type,
        [EventParameterKey.ITEM_NAME]: itemData.name,
        [EventParameterKey.ITEM_ID]: itemData.id,
        [EventParameterKey.PRODUCT_ID]: itemData.id,
        [EventParameterKey.PRODUCT_STOCKED_STATUS]: itemData.stocked_status,
        [EventParameterKey.ITEM_POSITION]: clickedIndex || 'NAN', //TODO: this was crashing need to check
      };
      this.debounceProductCardClick(meta);
    }

    if (toggleUnavailableProductsModal) {
      await toggleUnavailableProductsModal();
    }

    AnalyticsUtility.fireItemClickEvent(
      previousScreen,
      Utility.getSkuId(itemData),
      itemData.type,
      itemData.name,
      index,
      listId,
      layout,
      listName,
      listIndex,
      '',
      !itemData.outOfStock,
      listContent,
      id,
      slug,
      listSlug,
    );

    this.debouncedNavigate();
    if (onPress !== undefined && onPress !== null) {
      onPress();
    }
  };

  fireSearchResultClickEvent = (meta) => {
    AnalyticsManager.logEvent(EventType.search.SEARCH_RESULT_CLICK, meta);
  };

  addToCart = () => {
    const {
      previousScreen,
      maxFreeItemsToSelect,
      showToast,
      refreshOfferStrip,
      refreshOffersDetailsPageDiscountStrip,
      listId,
      listName,
      listIndex,
      listContent,
      index,
      listData: {
        additional_data = {},
        options: { cta_position_bottom = '0' } = {},
      } = {},
      orientation,
      itemData,
      hideAddToCart,
      itemData: {
        id,
        sku_id: skuId,
        has_variants: hasVariants,
        outOfStock = false,
        priority,
        single_stocked_variant: singleStockedVariantSku,
      } = {},
    } = this.props;

    if (outOfStock === null) {
      outOfStock = true;
    }
    const layout = cta_position_bottom === '1' ? LAYOUT.LARGEGRID : LAYOUT.GRID;
    const style =
      cta_position_bottom === '0'
        ? styles.cartImageContainer
        : { width: '100%' };
    return (
      <View style={style}>
        <AddToCart
          skuId={skuId}
          orientation={orientation}
          toggleCartState={this.toggleCartState}
          layout={layout}
          addToCartLayout={PRODUCT.ADD_TO_CART_LAYOUT.PRODUCT_CARD}
          hideAddToCart={hideAddToCart}
          id={id}
          itemData={itemData}
          hasVariants={hasVariants}
          showCart={this.showCart}
          priority={priority}
          outOfStock={outOfStock}
          refreshOfferStrip={refreshOfferStrip}
          refreshOffersDetailsPageDiscountStrip={
            refreshOffersDetailsPageDiscountStrip
          }
          previousScreen={previousScreen}
          maxFreeItemsToSelect={maxFreeItemsToSelect}
          showToast={showToast}
          listId={listId}
          listName={listName}
          listIndex={listIndex}
          listContent={listContent}
          index={index}
          singleStockedVariantSku={singleStockedVariantSku}
          additional_data={additional_data}
          cta_position_bottom={cta_position_bottom}
        />
      </View>
    );
  };

  variantCircle = ({ item, index }) => {
    const {
      itemData: {
        variants_details: {
          variants_shade_images: variantShadeImages = [],
        } = {},
      },
    } = this.props;
    const variantCircleContainer =
      this.memoizedVariantCircleContainerStyle()(index);
    return (
      <FastImageView style={variantCircleContainer} source={item}>
        {index === variantShadeImages.length && (
          <Text style={styles.variantCircleText}>{item}</Text>
        )}
      </FastImageView>
    );
  };

  memoizedVariantCircleContainerStyle = () => {
    const {
      itemData: {
        variants_details: {
          variants_shade_images: variantShadeImages = [],
        } = {},
      },
    } = this.props;
    return (index = '') => {
      if (!this.variantCircleContainerStyleCache[index]) {
        this.variantCircleContainerStyleCache[index] = [
          styles.variantCircleContainer,
          { borderWidth: index === variantShadeImages.length ? 0.5 : 0 },
        ];
      }
      return this.variantCircleContainerStyleCache[index];
    };
  };

  VariantPreview = () => {
    const {
      itemData: {
        variants_details: {
          variants_count: variantsCount = 0,
          variants_shade_images: variantShadeImages = [],
          variants_property_name: variantPropertyName = '',
        } = {},
      },
    } = this.props;

    return (
      <FlatList
        data={variantShadeImages}
        renderItem={this.variantCircle}
        horizontal
        contentContainerStyle={styles.variantPreviewFlatlistContent}
      />
    );
  };

  skuSizeCondition = ({ variantName }) => Utility.isPresent(variantName);

  conditionalVariants = withEither(
    this.skuSizeCondition,
    SkuSizeVariant,
  )(SkuColorVariant);

  productVariants = () => {
    const {
      itemData: { pack_size, display_name, image_url },
    } = this.props;
    return (
      <this.conditionalVariants
        variantShade={display_name}
        variantImage={image_url}
        packSize={pack_size}
        containerStyle={styles.variantContainerStyle}
      />
    );
  };

  render() {
    const {
      listData: {
        size,
        display = '',
        options: {
          variant_position_bottom = '0',
          cta_position_bottom = '0',
        } = {},
      },
      orientation,
      previousScreen,
      itemData,
      hasVariants,
      hideAddToCart,
      itemData: {
        offer_value,
        final_sp,
        additional_discount,
        type,
        brand_name: brandName,
        images,
        webp_images,
        image_webp_url,
        rating,
        id,
        product_name: name,
        mrp,
        sp,
        discount,
        sku_id: skuId,
        pack_size,
        product_name,
        display_name,
        image_url,
        outOfStock,
        priority,
        image_url: imageUrl,
        single_stocked_variant: singleStockedVariantSku,
        prompts = [],
      } = {},
    } = this.props;
    const disabled =
      previousScreen === 'brandCollab' || previousScreen === 'free_items';
    const display_price = final_sp;

    return (
      <ScaleAnimate {...this.props} disabled={disabled} onPress={this.onPress}>
        <Card style={styles.railCardContainer}>
          <View style={styles.railImageContainer}>
            <ProductImageAndRating
              layout={LAYOUT.RAIL}
              rating={rating}
              images={images}
              webp_images={webp_images}
              image_webp_url={image_webp_url}
              imageUrl={imageUrl}
              id={id}
              name={name}
              previousScreen={previousScreen}
              itemData={itemData}
              matchedProduct
            />
            {cta_position_bottom !== '1' && <this.addToCart />}
          </View>
          <this.productVariants />

          <ProductDescription
            itemData={itemData}
            name={name}
            mrp={mrp}
            offer_value={offer_value}
            sp={sp}
            display_price={display_price}
            discount={discount}
            brandName={brandName}
            variantBrandName={brandName}
            layout={LAYOUT.RAIL}
            id={id}
            previousScreen={previousScreen}
            numberOfLines={1}
            theme={'large'}
            size={size}
            hideBrandName={AppConfig.getBooleanValue(
              Config.HIDE_BRAND_ON_PRODUCT_CARD,
            )}
            leftAlignText
          />

          {variant_position_bottom === '1' && (
            <View style={styles.variantPreviewContainer}>
              <this.VariantPreview />
            </View>
          )}

          {cta_position_bottom === '1' && <this.addToCart />}
        </Card>
      </ScaleAnimate>
    );
  }
}

const dividingScale = isDesktop() ? 4.30 : 2.15;

const styles = StyleSheet.create({
  railCardContainer: {
    backgroundColor: colors.white,
    borderColor: colors.borderColor,
    margin: 4,
    flexDirection: 'column',
    width: Utility.getScreenWidth() / dividingScale,
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: 4,
    marginBottom: 6,
    borderRadius: Utility.isPresent(Config.LARGE_PRODUCT_CARD_CORNER)
      ? parseInt(Config.LARGE_PRODUCT_CARD_CORNER)
      : 0,
  },
  railImageContainer: {
    height: ((4 / 3) * Utility.getScreenWidth()) / dividingScale,
    width: '100%',
    marginBottom: 4,
  },
  fullCardDivider: {
    height: 1,
    width: '100%',
    backgroundColor: colors.borderColor,
  },
  cartImageContainer: {
    height: 36,
    width: 36,
    marginTop: -48,
    marginRight: 12,
    alignSelf: 'flex-end',
  },
  variantContainerStyle: {
    position: 'absolute',
    left: 12,
    top: 8,
    borderWidth: 1,
    borderColor: colors.border,
    borderRadius: 15,
    maxWidth: 84,
    padding: 2,
    backgroundColor: colors.white,
  },
  variantPillContainerStyle: {
    position: 'absolute',
    left: 12,
    top: 10,
    borderWidth: 1,
    borderColor: colors.border,
    borderRadius: 15,
    maxWidth: 84,
    padding: 2,
    backgroundColor: colors.white,
  },
  variantCircleText: {
    fontSize: size.h6,
    color: colors.black,
    fontFamily: 'Roboto',
  },
  variantPreviewContainer: {
    height: 28,
    alignSelf: 'flex-start',
    marginHorizontal: 4,
  },
  variantPreviewFlatlistContent: { marginVertical: 4 },
  variantCircleContainer: {
    height: 16,
    width: 16,
    borderRadius: 8,
    margin: 4,
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      applyFlashDeal,
    },
    dispatch,
  ),
});

export default withProfiledNavigation(
  connect(null, mapDispatchToProps)(LargeVariantGrid),
);
