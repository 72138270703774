import Config from '../libraries/ReactNativeConfig';
import { isWeb } from '../utils/BooleanUtility';

export default class AppConfig {
  static phoneNumberLimit = parseInt(Config.CONTACT_NUMBER_LIMIT || '');
  static callingCode = parseInt(Config.COUNTRY_CODE?.substring(1));
  static minimumPincodeLimit = parseInt(Config.ADDRESS_PAGE_MINIMUM_PINCODE_LIMIT || '')

  static getBooleanValue = (value) => {
    return 'true' === value;
  };

  static isCartPresentInBottomTabs() {
    return Config.TABS?.includes('Cart');
  }

  static shouldSendAttributeAsAnalyticsUserId(attribute) {
    return Config.USER_ID_ATTRIBUTE_FOR_ANALYTICS === attribute;
  }

  static getProfileMenuItems(authorized) {
    if (isWeb()) return Config.WEB_PROFILE_MENU_ITEMS;
    return authorized
      ? Config.AUTHORIZED_PROFILE_MENU_ITEMS
      : Config.UNAUTHORIZED_PROFILE_MENU_ITEMS;
  }
}
