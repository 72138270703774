import { firebase, perf, trace } from '../libraries/Firebase';
import { isBlank, isPresent, isWeb } from './BooleanUtility';
import { AnalyticsManager, EventParameterKey, EventType } from '../analytics';
import { SEP } from '../config/Constants';

export const onReportPrepared = (report) => {
  const { renderPassName, interactive } = report;
  switch (interactive) {
    case true:
      recordInteractiveTime(report);
      break;
    case false:
      recordLoadingTime(report);
      break;

    default:
      break;
  }
};

export const errorHandler = (error) => {
  /**
   * Cannot report this error in analytics because this error contains
   * very long descriptive message
   */

  let message = '';
  if (error.message) {
    message = error?.message?.split('.')[0];
  }

  if (error.name) {
    AnalyticsManager.logEvent(EventType.PERF.ERROR, {
      [EventParameterKey.ERROR]: error.name,
      [EventParameterKey.MESSAGE]: message,
    });
  }
};

export const onListPerformanceCallback = (TTI, listName) => {
  addPerfTrace(`${listName}${SEP}load_time`, 'time_taken', TTI);
};

export const onBlankAreaCallback = (offsetStart, offsetEnd, listName) => {};

const recordLoadingTime = ({
  sourceScreen,
  timeToBootJsMillis,
  destinationScreen,
  timeToRenderMillis,
  timeToAbortMillis,
}) => {
  if (isBlank(sourceScreen) && isPresent(timeToBootJsMillis)) {
    recordAppLoadTime('actual_app_load_time', 'time_taken', timeToBootJsMillis);
  } else if (
    isPresent(sourceScreen) &&
    isPresent(destinationScreen) &&
    isPresent(timeToRenderMillis)
  ) {
    addPerfTrace(
      `${sourceScreen}_to_${destinationScreen}_load_time`,
      'time_taken',
      timeToRenderMillis,
    );
  }
};
const recordInteractiveTime = ({
  sourceScreen = 'app_start',
  destinationScreen,
  timeToRenderMillis,
  renderPassName,
}) => {
  addPerfTrace(
    `${sourceScreen}_to_${destinationScreen}_${renderPassName}_interactive_time`,
    'time_taken',
    timeToRenderMillis,
  );
};

const recordAppLoadTime = async (traceName, metricsName, metricsValue) => {
  const trace = await getFirebasePerformanceTrace(traceName);
  trace.putMetric(metricsName, metricsValue);
  await trace.stop();
};

const addPerfTrace = async (traceName, metricsName, metricsValue) => {
  const trace = await getFirebasePerformanceTrace(traceName);
  trace.putMetric(metricsName, metricsValue);
  await trace.stop();
};

export const getNavigationSource = (classRef, props) => ({
  source: `${getComponentName(classRef)}${SEP}${getItemName(props)}`,
});

export const getListSource = (classRef, props = {}) => {
  if (isBlank(props)) {
    return getComponentName(classRef);
  }
  
  return `${getComponentName(classRef)}${SEP}${getItemName(props)}`;
};

export const getComponentName = (classRef) => {
  return classRef.constructor.name;
};

export const getItemName = (props = {}) => {
  const {
    listData: { name: listName = '' } = {},
    itemData: { name: itemName = '', title = '' } = {},
    item: { title: itemTitle = '' } = {},
  } = props;

  let prefix = '';
  if (isPresent(listName)) {
    prefix = `${listName}`;
  }
  if (isPresent(itemName)) {
    prefix = `${prefix}${SEP}${itemName}`;
  }

  if (isBlank(itemName) && isPresent(title)) {
    prefix = `${prefix}${SEP}${title}`;
  }

  if (isPresent(itemTitle)) {
    prefix = `${prefix}${SEP}${itemTitle}`;
  }
  return prefix;
};

export const getFirebasePerformanceTrace = (traceName) => {
  if (isWeb()) {
    return trace(perf, traceName);
  }
  return firebase.perf().newTrace(traceName);
};
