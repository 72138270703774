import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { ScrollView, BackHandler, RefreshControl, Image } from 'react-native';
import ErrorBoundary from '../shared/ErrorBoundary';
import { fetchRoutines } from '../../actions/ActionTypes';
import { List } from '../../containers/List';
import { connect } from 'react-redux';
import ShimmerPlaceHolder from '../../libraries/ReactNativeShimmerPlaceholder';
import { StyleSheet, View } from 'react-native';
import Utility from '../../utils/Utility';
import { StaticNavigationHeader } from '../shared';
import withNavigation from '../../utils/WithNavigation';
import {
  AnalyticsManager,
  EventParameterKey,
  EventType,
} from '../../analytics';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import { NOTIFICATION_REQUEST_MODAL_TYPE, ROUTINES_TAB_SLUG, TABBAR_CONSTANT } from '../../config/Constants';
import PermissionsUtility from '../../utils/PermissionsUtility';
import { isDesktop, isPresent } from '../../utils/BooleanUtility';
import Config from '../../libraries/ReactNativeConfig';
import { TabNavigationLeftHeader, TabNavigationRightHeader } from '../header';
import colors from '../../theme/Colors';
import images from '../../theme/Images';
import { getNavigationSource, getFirebasePerformanceTrace } from '../../utils/PerfUtility';
import RoutinesTabIcon from './RoutinesTabIcon';

class Routines extends PureComponent {
  Components = {
    list: List,
  };

  constructor(props) {
    super(props);
    this.state = {
      serverError: false,
      retryLoading: false,
      connectionError: false,
      loading: true,
      itemData: {},
    };

    if (Utility.isAndroid()) {
      this.backhandler = BackHandler.addEventListener(
        'hardwareBackPress',
        this.onHardwareBackKeyPress,
      );
    }
    this.colorShimmerArray = ['#ffffff1A', '#ffffff33', '#ffffff1A'];
    this.trace = getFirebasePerformanceTrace('Routines');
    this.trace.start();
    this.routes = props.navigation.getState().routes;
    this.isCurrentScreen = false;
    this.listRef = null;
    this.navigationSource = getNavigationSource(this, this.props);
  }

  componentDidMount() {
    const { navigation } = this.props;
    this._navListener = navigation.addListener('focus', () => {
      Utility.setStatusBarWhite();
      this.fetchRoutines();
    });
    this.didFocusListener = navigation.addListener('focus', () => {
      this.onDidFocus();
      this.isCurrentScreen = true;
    });
    this.blurListener = navigation.addListener('blur', () => {
      this.isCurrentScreen = false;
    });
    this.tabPressListener = navigation.addListener('tabPress', () => {
      if (this.isCurrentScreen) {
        this.scrollPageToTop();
      }
    });
    this.displayNotificationRequestModal();
  }

  componentDidUpdate(prevProps) {
    const { authToken: prevAuthToken = '' } = prevProps;
    const { authToken = '' } = this.props;
    if (prevAuthToken !== authToken) {
      this.onPressReload();
    }
  }

  componentWillUnmount() {
    this._navListener();
    if (Utility.isAndroid()) {
      this.backhandler.remove();
    }
    this.didFocusListener();
    this.blurListener();
    this.tabPressListener();
  }

  onDidFocus = () => {
    Utility.setStatusBarColor(colors.white);
    AnalyticsManager.logEvent(EventType.discoveryEvents.TAB_VIEW, {
      tab: 'Routines',
    });
    AnalyticsManager.setCurrentScreen(SCREEN_CONSTANTS.ROUTINES_PAGE);
  };

  scrollPageToTop = () => {
    if (this.isCurrentScreen && this.listRef) {
      this.listRef.scrollTo(0);
    }
  };

  setListRef = (ref) => {
    this.listRef = ref;
  };

  onHardwareBackKeyPress = () => {
    const { navigation, route } = this.props;

    if (this.routes?.length < 2) {
      navigation.replace('TabNavigator');
      return;
    }
    navigation.goBack();
    return true;
  };

  showNotificationModal = (show, showNotification) => {
    const { navigation } = this.props;
    navigation.push('NotificationModal', {
      showNotificationPrompt: showNotification,
      type: NOTIFICATION_REQUEST_MODAL_TYPE.ROUTINES,
    });
  };

  displayNotificationRequestModal = () => {
    const { lastNotificationModalDisplayTime, initial_app_opened_at } =
      this.props;

    if (
      !PermissionsUtility.shouldShowNotificationModalForCurrentSession(
        SCREEN_CONSTANTS.ROUTINES_PAGE,
      )
    ) {
      return;
    }

    Utility.canDisplayNotificationRequestModal(
      lastNotificationModalDisplayTime,
      'routine',
      (canDisplay, showNotificationPrompt) => {
        if (canDisplay) {
          this.showNotificationModal(true, showNotificationPrompt);
          PermissionsUtility.setNotificationModalVisibilityForCurrentSession(
            SCREEN_CONSTANTS.ROUTINES_PAGE,
            false,
          );
        }
      },
      initial_app_opened_at,
      true,
    );
  };

  fetchRoutines = () => {
    const { slug = ROUTINES_TAB_SLUG || '', fetchRoutines } = this.props;
    if (!Utility.isBlank(slug)) {
      fetchRoutines(slug, (success, response = {}) => {
        if (!this.traceStopped) {
          this.trace.stop();
          this.traceStopped = true;
        }
        if (success) {
          this.setState({
            serverError: false,
            connectionError: false,
            loading: false,
            itemData: response,
          });
          AnalyticsManager.logEvent(EventType.pageLoad.PAGE_LOAD, {
            [EventParameterKey.PAGE]: SCREEN_CONSTANTS.ROUTINES_PAGE,
          });
          return;
        }
        if (response.errorType === 'api_failure') {
          this.setState({
            loading: false,
            serverError: response.status === 'server_error',
            // connection_error to be added on Routines page. Handling is added here
            connectionError: response.status === 'connection_error',
            retryLoading: false,
          });
        } else {
          this.setState({
            loading: false,
            serverError: false,
            connectionError: true,
            retryLoading: false,
          });
        }
      });
    }
  };

  onPressReload = () => {
    this.setState(
      {
        loading: true,
      },
      this.fetchRoutines,
    );
  };

  showToast = (message) => {
    this.toast.show(message, 1500);
  };

  renderRoutineDescriptionShimmer = () => {
    return (
      <View style={styles.cardContainerPlaceholder}>
        <ShimmerPlaceHolder
          autoRun
          style={styles.cardImaegShimmer}
          colorShimmer={this.colorShimmerArray}
        />
        <ShimmerPlaceHolder
          autoRun
          style={styles.cardContentTitlePlaceholder}
          colorShimmer={this.colorShimmerArray}
        />
        <ShimmerPlaceHolder
          autoRun
          style={styles.cardContentDescriptionPlaceholder}
          colorShimmer={this.colorShimmerArray}
        />
        <ShimmerPlaceHolder
          autoRun
          style={styles.cardContentArtistPlaceholder}
          colorShimmer={this.colorShimmerArray}
        />
        <ShimmerPlaceHolder
          autoRun
          style={styles.cardContentDurationPlaceholder}
          colorShimmer={this.colorShimmerArray}
        />
        <ShimmerPlaceHolder
          autoRun
          style={styles.cardContentCTAPlaceholder}
          colorShimmer={this.colorShimmerArray}
        />
      </View>
    );
  };

  renderShimmer = () => {
    return (
      <View style={styles.shimmerContainer}>
        <ShimmerPlaceHolder
          colorShimmer={this.colorShimmerArray}
          autoRun
          style={styles.titleShimmer}
        />
        <ShimmerPlaceHolder
          autoRun
          style={styles.subtitleShimmer}
          colorShimmer={this.colorShimmerArray}
        />
        <this.renderRoutineDescriptionShimmer />
        <this.renderRoutineDescriptionShimmer />
        <this.renderRoutineDescriptionShimmer />
      </View>
    );
  };

  render() {
    const { navigation, route: { params: { showHeader = false } = {} } = {} } =
      this.props;
    const { serverError, retryLoading, connectionError, loading, itemData } =
      this.state;

    if (loading) {
      return (
        <>
          {showHeader && <StaticNavigationHeader title={itemData?.name} />}
          <this.renderShimmer />
        </>
      );
    }

    const ContainerComponent = this.Components[itemData.type];
    const isServerError = serverError || Utility.isBlank(itemData);
    const containerStyle =
      isServerError || connectionError ? styles.scrollViewContainer : {};
    return (
      <>
        {showHeader && (
          <StaticNavigationHeader
            title={itemData.name}
            onBackPress={this.onHardwareBackKeyPress}
          />
        )}
        <ScrollView
          ref={this.setListRef}
          showsVerticalScrollIndicator={false}
          refreshControl={
            <RefreshControl
              refreshing={loading}
              onRefresh={this.fetchRoutines}
            />
          }
          contentContainerStyle={containerStyle}
        >
          <ErrorBoundary
            showServerError={isServerError}
            onPressReload={this.onPressReload}
            connectionError={connectionError}
            loading={retryLoading}
            hideHeader
            screen_name={'all_routines'}
          >
            <ContainerComponent
              navigation={navigation}
              itemData={itemData}
              id={itemData.id}
              listName={itemData.name}
              listContent={itemData.content}
              type={itemData.type}
              display={itemData.display}
              layout={itemData.display}
              showToast={this.showToast}
              previousScreen={SCREEN_CONSTANTS.ROUTINES_PAGE}
            />
          </ErrorBoundary>
        </ScrollView>
      </>
    );
  }
}

const mapStateToProps = function (state) {
  return {
    lastNotificationModalDisplayTime:
      state.UserAccountInfo.lastNotificationModalDisplayTime,
    initial_app_opened_at: state.UserAccountInfo.initial_app_opened_at,
    authToken: state?.UserAccountInfo?.authToken,
  };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      fetchRoutines,
    },
    dispatch,
  ),
});

export default withNavigation(
  connect(mapStateToProps, mapDispatchToProps)(Routines),
);

export const RoutinesNavigationOptions = isDesktop() ? {
  headerShown: false,
} : {
  headerShown: true,
  title: Config.ROUTINES_HEADER_TITLE,
  headerTitleAlign: 'center',
  headerTitleStyle: {
    textAlign: 'center',
    alignSelf: 'center',
    fontSize: 16,
    fontFamily: 'Roboto-Medium',
  },
  headerLeft: () => (
    <TabNavigationLeftHeader tabBar={TABBAR_CONSTANT.routines} />
  ),
  headerRight: () => (
    <TabNavigationRightHeader
      showCart
      store
      tabBar={TABBAR_CONSTANT.routines}
    />
  ),
  headerBackVisible: false,
  headerShadowVisible: false,
  headerStyle: {
    backgroundColor: colors.white,
  },
  tabBarLabel: Config.ROUTINES_TAB_LABEL,
  tabBarActiveTintColor: Config.ROUTINES_TAB_ACTIVE_COLOR,

  tabBarStyle: { borderTopWidth: 0, elevation: 0 },
  tabBarIcon: ({ focused }) => {
    const focusedIcon = isPresent(Config.ROUTINES_TAB_ACTIVE_ICON_URL)
      ? { uri: Config.ROUTINES_TAB_ACTIVE_ICON_URL }
      : images.routines_selected_tab;
    const unfocusedIcon = isPresent(Config.ROUTINES_TAB_ICON_URL)
      ? { uri: Config.ROUTINES_TAB_ICON_URL }
      : images.routines_unselected_tab;
    const icon = focused ? focusedIcon : unfocusedIcon;
    const tintColor = focused ? '' : Config.ROUTINES_TAB_INACTIVE_COLOR;

    const imageStyle = getMemoizedImageStyle(tintColor);

    return (
      <RoutinesTabIcon
        image={icon}
        style={imageStyle}
      />
    );
  },
  tabBarOnPress: ({ navigation, defaultHandler }) => {
    defaultHandler();
    const { state: { routes = [] } = {} } = navigation;
    if (!navigation.isFocused()) return;
    if (Utility.isPresent(routes)) {
      const route = routes[0];
      const { params: { scrollToTop = () => {} } = {} } = route;
      scrollToTop();
    }
  },
};

const imageStyles = {};

const getMemoizedImageStyle = (tintColor) => {
  if (!imageStyles[tintColor]) {
    imageStyles[tintColor] = { ...styles.tabImageStyle, tintColor };
  }
  return imageStyles[tintColor];
};

const styles = StyleSheet.create({
  shimmerContainer: { paddingHorizontal: 12, marginTop: 32 },
  titleShimmer: {
    height: 20,
    width: 150,
    backgroundColor: '#EAEAEA',
  },
  subtitleShimmer: {
    height: 20,
    width: 200,
    backgroundColor: '#EAEAEA',
  },
  cardContainerPlaceholder: {
    width: '100%',
    height: 286,
  },
  cardImaegShimmer: {
    width: '100%',
    height: 118,
    backgroundColor: '#EAEAEA',
  },
  cardContentContainerPlaceholder: {
    width: '100%',
    height: 158,
    backgroundColor: '#EAEAEA',
  },
  cardContentTitlePlaceholder: {
    marginLeft: 8,
    marginTop: 8,
    width: 150,
    height: 16,
    backgroundColor: '#EAEAEA',
  },
  cardContentDescriptionPlaceholder: {
    marginLeft: 8,
    marginTop: 8,
    width: '90%',
    height: 64,
    backgroundColor: '#EAEAEA',
  },
  cardContentArtistPlaceholder: {
    marginLeft: 8,
    marginTop: 8,
    width: 164,
    height: 16,
    backgroundColor: '#EAEAEA',
  },
  cardContentDurationPlaceholder: {
    marginLeft: 8,
    marginTop: 8,
    width: 100,
    height: 32,
    backgroundColor: '#EAEAEA',
  },
  cardContentCTAPlaceholder: {
    marginRight: 0,
    marginTop: 8,
    width: 156,
    height: 32,
    borderRadius: 32,
    backgroundColor: '#EAEAEA',
  },
  tabImageStyle: {
    height: 24,
    width: 24,
    resizeMode: 'contain',
    tintColor: colors.black,
  },
  scrollViewContainer: {
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
});
