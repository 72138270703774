import React, { PureComponent } from 'react';
import { View, StyleSheet, Text } from 'react-native';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import colors from '../../theme/Colors';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import ScaleAnimate from '../shared/ScaleAnimate';
import { LAYOUT } from '../../config/Constants';
import size from '../../theme/Fonts';
import { withMaybe } from '../../lib/Monads';
import LikeButton from '../shared/LikeButton';
import {
  AnalyticsManager,
  EventType,
  EventParameterKey,
  AnalyticsUtilty,
} from '../../analytics';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import { setSearchResultClicks } from '../../actions/ActionTypes';
import FastImageView from '../FastImageView';
import { navigateToScreen } from '../../utils/NavigationUtility';
import { getMinifiedImage } from '../../utils/ImageUtility';
import withProfiledNavigation from '../../utils/withProfiledNavigation';
import { getNavigationSource } from '../../utils/PerfUtility';

class DefaultSizeBrandList extends PureComponent {
  brandDescriptionWithCondition = withMaybe(this.showDescriptionConditionFn)(
    this.brandDescription,
  );

  constructor(props) {
    super(props);
    this.debouncedNavigate = _.debounce(this.navigate, 1000, {
      leading: true,
      trailing: false,
    });
    this.debounceBrandCardClick = _.debounce(
      this.fireSearchResultClickEvent,
      2000,
      {
        leading: true,
        trailing: false,
      },
    );
    this.state = {
      liked: false,
    };

    this.navigationSource = getNavigationSource(this, this.props);
  }

  showDescriptionConditionFn = (props) => props.layout === 'list';

  toggleLike = () => {
    this.setState((prevState) => ({ liked: !prevState.liked }));
  };

  navigate = () => {
    const {
      navigation,
      id,
      itemData,
      listIndex,
      index,
      search,
      searchQuery,
      elementItemCounts,
      toggleCartVisibility,
      layout,
      previousScreen,
      listData,
      listId,
      listName,
      listContent,
      type,
      setSearchResultClicks,
    } = this.props;

    if (previousScreen === 'recommendation') {
      this.followBrand && this.followBrand();
      return null;
    }

    if (previousScreen === SCREEN_CONSTANTS.SEARCH) {
      const data = {
        query: searchQuery,
        type,
        id,
      };
      setSearchResultClicks(data);
      let clickedIndex = Utility.addPreviousIndexValues(
        elementItemCounts,
        listIndex,
      );
      clickedIndex += index + 1;
      const meta = {
        [EventParameterKey.SEARCH_QUERY]: searchQuery,
        [EventParameterKey.ITEM_TYPE]: itemData.type,
        [EventParameterKey.ITEM_NAME]: itemData.name,
        [EventParameterKey.ITEM_ID]: itemData.id,
        [EventParameterKey.ITEM_POSITION]: clickedIndex,
      };

      this.debounceBrandCardClick(meta);
    }

    if (Utility.isPresent(itemData)) {
      AnalyticsUtilty.fireItemClickEvent(
        previousScreen,
        itemData.id,
        'brand',
        itemData.name,
        index,
        listId,
        layout,
        listName,
        listIndex,
        '',
        '',
        listContent,
        itemData.id,
        itemData?.slug,
        listData?.slug,
      );
    }

    navigateToScreen({
      navigation,
      type: 'push',
      screen: 'Brand',
      navigationSource: this.navigationSource,
      params: {
        id,
        itemData,
        slug: itemData.slug,
        display: LAYOUT.SCREEN,
        toggleCartVisibility,
      },
    });
  };

  fireSearchResultClickEvent = (meta) => {
    AnalyticsManager.logEvent(EventType.search.SEARCH_RESULT_CLICK, meta);
  };

  brandImage = (props) => {
    const { imageUrl, imageBackgroundStyle, name, webpImageUrl } = props;

    let newUrl =
      Utility.isAndroid() && Utility.isPresent(webpImageUrl)
        ? webpImageUrl
        : imageUrl;

    return (
      <View style={styles.imageContainer}>
        <FastImageView
          source={{
            uri: newUrl,
          }}
          style={imageBackgroundStyle}
          resizeMode="contain"
          onError={this.imageError}
        >
          {Utility.isBlank(imageUrl) ? (
            <Text style={styles.placeholderText} numberOfLines={2}>
              {name}
            </Text>
          ) : null}
        </FastImageView>
      </View>
    );
  };

  BrandDescription = (props) => {
    const { name = '', short_description } = props;
    const brandName = name?.toUpperCase();
    return (
      <View style={styles.brandDescStyle}>
        <Text style={styles.brandName}>{brandName}</Text>
        {Utility.isPresent(short_description) && (
          <Text style={styles.brandShortDescription} numberOfLines={2}>
            {short_description}
          </Text>
        )}
      </View>
    );
  };

  conditionalRenderFn = (x) => x === LAYOUT.RAIL || x === LAYOUT.GRID;

  brandDescription = (props) => {
    const { itemData } = props;
    const brandName = itemData?.name?.toUpperCase();
    return (
      <>
        <this.BrandDescription
          name={brandName}
          shortAddress={itemData.short_address}
          short_description={itemData.short_description}
        />
        <View style={styles.likeButtonStyle}>
          <LikeButton
            id={itemData.id}
            type={itemData.type}
            slug={itemData.slug}
            liked={this.state.liked}
            toggleLike={this.toggleLike}
            layout={LAYOUT.LIST}
            conditionalRenderFn={this.conditionalRenderFn}
          />
        </View>
      </>
    );
  };

  imageError = () => {
    const { itemData, navigation } = this.props;
    const screen = '';
    AnalyticsUtilty.fireImageErrorAnalytics(
      itemData.id,
      itemData.image_url,
      itemData.name,
      'brand',
      screen,
    );
  };

  render() {
    const { itemData, showDescription } = this.props;
    return (
      <ScaleAnimate {...this.props} onPress={this.debouncedNavigate}>
        <View style={styles.cardContainer}>
          <this.brandImage
            imageUrl={getMinifiedImage(
              itemData.image_url,
              styles.imageBackgroundStyle.width,
              styles.imageBackgroundStyle.height,
            )}
            webpImageUrl={itemData.image_webp_url}
            imageBackgroundStyle={styles.imageBackgroundStyle}
            name={itemData.name}
          />
          <this.brandDescription
            itemData={itemData}
            showDescription={showDescription}
          />
        </View>
      </ScaleAnimate>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({ setSearchResultClicks }, dispatch),
});

export default withProfiledNavigation(connect(null, mapDispatchToProps)(DefaultSizeBrandList));

const styles = StyleSheet.create({
  cardContainer: {
    backgroundColor: colors.white,
    borderColor: colors.borderColor,
    marginHorizontal: Utility.spacingBetweenItems,
    flexDirection: 'row',
    borderRadius: 4,
    width: Utility.getScreenWidth() - 24,
    height: 112,
    alignSelf: 'center',
    marginBottom: 2,
  },
  imageContainer: {
    alignSelf: 'center',
    margin: 8,
    height: 92,
    width: 92,
    borderRadius: 4,
    justifyContent: 'center',
  },
  fullCardDivider: {
    height: 1,
    width: '100%',
    backgroundColor: colors.borderColor,
  },
  brandDescStyle: {
    flexDirection: 'column',
    marginLeft: 14,
    marginRight: 4,
    flex: 2.5,
    marginTop: 12,
    justifyContent: 'center',
    marginBottom: 12,
  },
  brandName: {
    fontFamily: 'Roboto-Medium',
    fontSize: size.h4,
    color: colors.foxyBlack,
    fontStyle: 'normal',
    lineHeight: 14,
    marginTop: 5,
    height: 14,
  },
  placeholderText: {
    position: 'absolute',
    color: 'white',
    bottom: 8,
    left: 8,
    right: 8,
    textAlign: 'center',
    alignSelf: 'center',
    fontFamily: 'Roboto',
    fontSize: 12,
  },
  brandShortDescription: {
    marginTop: 4,
    color: '#173143',
    opacity: 0.7,
    fontSize: size.h4,
  },
  likeButtonStyle: {
    marginRight: 22,
    marginTop: 42,
  },
  imageBackgroundStyle: {
    borderRadius: 4,
    overflow: 'hidden',
    backgroundColor: 'white',
    height: 96,
    width: 96,
  },
});
