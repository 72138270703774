import React, { Component } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FlatList } from 'react-native-gesture-handler';
import { bindActionCreators } from 'redux';
import { getPersonalisedLists } from '../../../actions/PersonalisedActions';
import BannerWithTimer from './BannerWithTimer';
import colors from '../../../theme/Colors';
import Utility from '../../../utils/Utility';
import OfferShimmer from '../../shared/OfferShimmer';
import ErrorBoundaryComponent from '../../shared/ErrorBoundaryComponent';
import { FlatListPerformanceView } from '../../../libraries/ReactNativePerformanceShopify';
import { getListSource } from '../../../utils/PerfUtility';

export class TickingBanners extends Component {
  constructor(props) {
    super(props);
    this.state = {
      itemData: {},
      loading: true,
    };
  }

  componentDidMount() {
    const { getPersonalisedLists } = this.props;
    getPersonalisedLists('offers-near-expiry', (success, response) => {
      this.setState({
        itemData: response,
      });
    });

    setTimeout(() => {
      this.setState({
        loading: false,
      });
    }, 10000);
  }

  renderItem = ({ item, index }) => {
    const { itemData } = this.state;
    const { listData = {}, previousScreen = '' } = this.props;
    return (
      <ErrorBoundaryComponent
        itemData={item}
        listData={listData}
        screenName={previousScreen}
      >
        <BannerWithTimer
          itemData={item}
          length={itemData.length}
          {...this.props}
        />
      </ErrorBoundaryComponent>
    );
  };

  render() {
    const { itemData, loading } = this.state;
    const { listData = {} } = this.props;

    if (Utility.isBlank(itemData)) {
      if (loading) {
        return <OfferShimmer large />;
      }
      return null;
    }
    const { listData: { name = '' } = {}, listName } = this.props;
    const flatListName = name || listName;
    return (
      <View style={styles.container}>
        <View>
          <Text style={styles.title} ellipsizeMode='tail' numberOfLines={1}>
            {listData?.name || ''}
          </Text>
          <Text
            style={styles.subtitle}
            allowFontScaling={false}
            numberOfLines={1}
            ellipsizeMode='tail'
          >
            {listData?.subtitle || ''}
          </Text>
        </View>
        <FlatListPerformanceView listName={`${getListSource(this, this.props)}`}>
          <FlatList
            data={itemData}
            renderItem={this.renderItem}
            horizontal
            showsHorizontalScrollIndicator={false}
            extraData={this.state}
          />
        </FlatListPerformanceView>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    marginBottom: 8,
  },
  title: {
    fontSize: 18,
    color: colors.foxyBlack,
    fontFamily: 'Roboto-Bold',
    marginHorizontal: 12,
    marginTop: 32,
    marginBottom: 4,
  },
  subtitle: {
    fontSize: 14,
    fontFamily: 'Roboto-Regular',
    color: '#979BAA',
    marginLeft: 12,
  },
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      getPersonalisedLists,
    },
    dispatch,
  ),
});

export default connect(null, mapDispatchToProps)(TickingBanners);
