import { Buffer } from 'buffer';
import React from 'react';
import {
  Platform,
  Dimensions,
  Animated,
  StatusBar,
  NativeModules,
  Linking,
  Alert,
  NativeEventEmitter,
} from 'react-native';
import Config from '../libraries/ReactNativeConfig';
import { MaterialIndicator } from 'react-native-indicators';
import VersionCheck from '../libraries/ReactNativeVersionCheck';
import AppConfig from '../config/AppConfig';
import _, { findIndex, forEach, isNull } from 'lodash';
import Share from '../libraries/ReactNativeShare';
import moment from 'moment';
import Permissions from '../libraries/ReactNativePermissions';
import { getStatusBarHeight } from 'react-native-status-bar-height';
import AnalyticsManager from '../analytics/AnalyticsManager';
import EventParameterKey from '../analytics/EventParameterKey';
import {
  REMOTE_CONFIG_KEYS,
  CDN_3,
  DEFAULT_IMAGE_FORMAT,
  stepIndicatorConfig,
  GENDER_IDS,
  TESTIMONIAL_COLOR_BUCKET,
  COUNTRY_CODE,
  EMPTY_ARRAY,
  WishlistAsBottomTab,
} from '../config/Constants';
import Geolocation from '@react-native-community/geolocation';
import RemoteConfig from './RemoteConfig';
const PhoneNumberUtil = require('google-libphonenumber').PhoneNumberUtil;

import {
  API_DOMAIN,
  GOOGLE_AUTH_CONFIG,
  AVAILABLE_BUILD_TYPES,
  IMAGES_S3_BUCKET,
  CURRENT_GUEST_STATE,
  WHATS_APP_NUMBER,
  COMPETITOR_APP,
  BETAFACE_TAGS_MAPPING,
  ANDROID,
  CLOUDFRONT_URL,
} from '../config/Constants';
import images from '../theme/Images';
import { SCREEN_CONSTANTS } from '../config/ScreenConstants';
import ImageTagsProcessingUtil from './ImageTagsProcessingUtil';
import SelfieUtilities from './SelfieUtilities';
import EventType from '../analytics/AnalyticsEventType';
import { isDesktop, isIOS, isWeb, isBlank, isPresent } from './BooleanUtility';
import { pushProductsAndOffersFromListToGTM } from '../analytics/GTMAnalytics';
import { allowedContentTypes } from './ListUtility';
import { PermissionsAndroid } from '../libraries/NativeLibraries';

let windowWidth = Dimensions.get('window').width;
let windowHeight = Dimensions.get('window').height;
if (windowWidth > windowHeight) {
  const tempDimension = windowWidth;
  windowWidth = windowHeight;
  windowHeight = tempDimension;
}
let isInstagramAutoSelected = false;
export default class Utility {
  static setVideoUploadProgress = undefined;
  static screenHeight = Dimensions.get('screen').height;
  static selfieAnalysisStartedAt = '';

  static tabNavigatorReplaced = false;

  static windowHeight = Dimensions.get('window').height;

  static screenWidth = Dimensions.get('window').width;

  static currentActiveFollowCard = {
    id: undefined,
  };

  static genderFromSelfie = '';

  static appOpenAt = new Date();

  static skipOnboardingSelfie = false;

  static navigationRoute = '';

  static navigationSlug;

  static navigationExtra = {};

  static navigationParams = {};

  static forceRefreshHomePage = false;

  static forceRefreshConsumerProfile = null;

  static areAttributesDeleted = null;
  static whitelistedEvents = null;

  static forceHitHomeApi = false;
  static calculateAppAndDeviceInfoScoreExceptionOccured = false;

  static skipPageLevelNotificationModal = false;

  static invokeSelfiePromptAfterLogin = false;
  static haltOffersLandingFlow = false;
  static fetchUserAttributesForGuestUserFlowFromHintOrTruecaller = false;
  static remoteConfigs = {};

  static comboColors = [
    '#2397F1',
    '#C26F18',
    '#01B460',
    '#EE9B0F',
    '#2397F1',
    '#C26F18',
    '#01B460',
    '#EE9B0F',
  ];
  static usedComboColors = {};

  static notificationManagerIOS = new NativeEventEmitter(
    NativeModules.LocalNotificationHandler,
  );
  static furtherAction = {
    action: () => {},
    params: [],
    phone_number: '',
    hint_prompt_phone_number: '',
    fromConsumerProfile: false,
    isActionAsync: false,
    skipAction: () => {},
    performSkipAction: false,
  };

  static UACEventsData = {
    isDeviceInfoTriggers: false,
    deviceInfoScore: 0,
    isAppInfoTriggered: false,
    appInfoScore: 0,
    sumDeviceAndAppInfo: 0,
  };

  static isOpenedFromOnboardingDeeplink = false;

  static appFirstLoaded = new Date();

  static membershipStatus = '';

  static membershipId = '';

  static appLastLoadedAtTimeDifference = null;

  static isAppLoadedForFirstTime = true;

  static isAppLaunchedFromOnboarding = false;

  static setNeverAskAgainPermission = false;

  static isReplacedFromAppNavigator = false;

  static getCurrentAppType() {
    return AVAILABLE_BUILD_TYPES.foxy;
  }

  static statusBarHeight() {
    if (Platform.OS === 'android') {
      return StatusBar.currentHeight;
    }
    return getStatusBarHeight();
  }

  static bottomNavBarHeightAndroid =
    Dimensions.get('screen').height - Dimensions.get('window').height;

  static padding = 12;

  static imageUrl = null;

  static largePadding = 16;

  static spacingBetweenItems = 4;

  static artistStoryMargin = 8;

  static artistNameContainerHeight = 35;

  static listDefaultViewHeight = 64;

  static lastEventBeforeClickingSelfie = '';

  static showCameraPermissionScreen = true;

  static isSelfieModalAlreadyDismissed = false;

  static isAndroid() {
    return Platform.OS === 'android';
  }

  static isIOS() {
    return Platform.OS === 'ios';
  }

  static isNative() {
    return Platform.OS === 'android' || Platform.OS === 'ios';
  }

  static isWeb() {
    return Platform.OS === 'web';
  }

  static topInset = 0;

  static bottomInset = 0;

  static appLoadJs = null;

  static feedLoadStart = null;

  static feedLoadEnd = null;

  static appLoadNative = null;

  static video_open_time = null;

  static video_play_time = null;

  static openOtpModalFromProfile = false;

  static pageLoadStart = null;

  static pageLoadEnd = null;

  static personalizedItemsLoadStart = null;

  static personalizedItemsLoadEnd = null;

  static networkInfo = {};

  static foxy_edge_source = '';

  static refreshDynamicListFn = () => {};

  static setPersonalizedItemsLoadStart(
    source,
    page = 0,
    action = '',
    firePageLoadDelay = true,
    data = {},
  ) {
    const netInfo = Utility.getNetworkInfo(Utility.networkInfo);
    Utility.pageLoadStart = Date.now();
    AnalyticsManager.logEvent('page_load_2', {
      ...netInfo,
      ...data,
      page: source,
      type: 'initial',
      action,
    });
    Utility.pageLoadTimer =
      firePageLoadDelay &&
      setTimeout(() => {
        AnalyticsManager.logEvent('page_load_delay_2', {
          ...netInfo,
          ...data,
          page: source,
          page_no: page,
          type: 'initial',
          action,
        });
      }, 5000);
  }

  static setPersonalizedItemsLoadEnd(source, metaData = {}) {
    Utility.pageLoadEnd = Date.now();

    const netInfo = Utility.getNetworkInfo(Utility.networkInfo);

    if (
      Utility.getTimeDiffInMs(Utility.pageLoadEnd, Utility.pageLoadStart) > 5000
    ) {
      AnalyticsManager.logEvent('page_load_delay_2', {
        ...netInfo,
        ...metaData,
        page: source,
        delay: Utility.getTimeDiffInMs(
          Utility.pageLoadEnd,
          Utility.pageLoadStart,
        ),
      });
    }

    AnalyticsManager.logEvent('page_load_2', {
      ...netInfo,
      ...metaData,
      page: source,
      delay: Utility.getTimeDiffInMs(
        Utility.pageLoadEnd,
        Utility.pageLoadStart,
      ),
    });
  }

  static pageLoadTimer = () => {};

  static setPageLoadStart(
    source,
    page = 0,
    action = '',
    firePageLoadDelay = true,
    data = {},
  ) {
    //console.tron.log(data, 'refreshingrefreshing', {
    //  ...netInfo,
    //  ...data,
    //  page: source,
    //  type: 'initial',
    //  action,
    //});
    const netInfo = Utility.getNetworkInfo(Utility.networkInfo);
    Utility.pageLoadStart = Date.now();
    AnalyticsManager.logEvent('page_load_2', {
      ...netInfo,
      ...data,
      page: source,
      type: 'initial',
      action,
    });
    Utility.pageLoadTimer =
      firePageLoadDelay &&
      setTimeout(() => {
        AnalyticsManager.logEvent('page_load_delay_2', {
          ...netInfo,
          ...data,
          page: source,
          page_no: page,
          type: 'initial',
          action,
        });
      }, 5000);
  }

  static clearPageLoadTimer() {
    clearInterval(Utility.pageLoadTimer);
  }

  static setPageLoadEnd(source, page = 0, data = {}) {
    const netInfo = Utility.getNetworkInfo(Utility.networkInfo);
    clearInterval(Utility.pageLoadTimer);
    Utility.pageLoadEnd = Date.now();

    if (
      Utility.getTimeDiffInMs(Utility.pageLoadEnd, Utility.pageLoadStart) >
        5000 &&
      page === 0
    ) {
      AnalyticsManager.logEvent('page_load_delay_2', {
        ...netInfo,
        ...data,
        page: source,
        delay: Utility.getTimeDiffInMs(
          Utility.pageLoadEnd,
          Utility.pageLoadStart,
        ),
        page_no: page,
        type: 'final',
      });
    }

    AnalyticsManager.logEvent('page_load_2', {
      ...netInfo,
      ...data,
      page: source,
      delay: Utility.getTimeDiffInMs(
        Utility.pageLoadEnd,
        Utility.pageLoadStart,
      ),
      page_no: page,
    });
  }

  static safeAreaInsets(insets) {
    Utility.topInset = insets.top;
    Utility.bottomInset = insets.bottom;
  }

  static getDynamicWidth(numberOfItems, spacing) {
    return (Utility.getScreenWidth() - Utility.padding * 2 - spacing) / numberOfItems;
  }

  static getDynamicWidthForGrid(numberOfItems, spacing, windowRatio = 1) {
    return (
      (Utility.getScreenWidth() * windowRatio -
        (12 - Utility.spacingBetweenItems) * 2 -
        spacing) /
      numberOfItems
    );
  }

  static getDynamicWidthForSmallGrid(numberOfItems, spacing) {
    return (
      (windowWidth * 0.75 - (12 - Utility.spacingBetweenItems) * 2 - spacing) /
      numberOfItems
    );
  }

  static getScreenWidth() {
    // Change name to window width
    return isDesktop() ? 1080 : Dimensions.get('window').width;
  }

  static getWindowHeight() {
    return _.max([
      Dimensions.get('window').width,
      Dimensions.get('window').height,
    ]);
  }

  static getWindowInnerHeight() {
    return Dimensions.get('window').height;
  }

  static getTimeAgo(date) {
    return moment.utc(date).local().startOf('seconds').fromNow();
  }

  static getStockedItems(itemData) {
    const items = [];
    _.forEach(itemData, (item = {}) => {
      const { stocked_status = '', variants_details = {}, sku_id = '' } = item;
      if (!Utility.isProductOutOfStock(stocked_status)) {
        if (Utility.isPresent(variants_details)) {
          items.push({ id: variants_details?.principal_sku_id });
        } else {
          items.push({ id: sku_id });
        }
      }
    });
    return items;
  }

  static isProductOutOfStock(stockedStatus) {
    return ['stocked_out', 'unstocked', 'discontinued'].includes(stockedStatus);
  }

  static getItems(itemData) {
    const items = [];
    _.forEach(itemData, (item) => {
      if (Utility.isPresent(item.variants_details)) {
        items.push({ id: item.variants_details.principal_sku_id });
      } else {
        items.push({ id: item.sku_id });
      }
    });
    return items;
  }

  static getItemsIdsAndQuantity(itemData) {
    const items = [];
    _.forEach(itemData, (item) => {
      if (Utility.isPresent(item.variants_details)) {
        items.push({ id: item.variants_details.principal_sku_id, quantity: item.quantity });
      } else {
        items.push({ id: item.sku_id, quantity: item.quantity });
      }
    });
    return items;
  }

  static getSkuId(itemData) {
    let id;
    if (
      Utility.isPresent(itemData) &&
      Utility.isPresent(itemData.variants_details)
    ) {
      id = itemData.variants_details.principal_sku_id;
    } else if (
      Utility.isPresent(itemData) &&
      Utility.isPresent(itemData.variant_attributes) &&
      Utility.isPresent(itemData.variant_attributes[0].allowed_values) &&
      Utility.isPresent(itemData.variant_attributes[0].allowed_values[0].sku_id)
    ) {
      id = itemData.variant_attributes[0].allowed_values[0].sku_id;
    } else {
      id = itemData.sku_id;
    }

    return id;
  }

  static getScreenHeight() {
    if (isDesktop()) {
      return  Dimensions.get('screen').height;
    }
    return _.max([
      Dimensions.get('screen').width,
      Dimensions.get('screen').height,
    ]);
  }

  static fireListRenderedEvent(data = {}, screen, type) {
    if (isWeb()) pushProductsAndOffersFromListToGTM(data);
    const listId = [];
    const sliceCount = 10;
    const listIds = {};
    const groupId = data?.id;
    const listDisplay = data?.display;
    if (Utility.isPresent(data) && !Utility.isBlank(data.objects)) {
      data.objects?.forEach((e) => {
        const { id = [] } = e;
        let changedlistId;
        if (type === 'product') {
          listId.push(e.id);
        } else if (Utility.isPresent(e.objects) && e.objects.length > 0) {
          if (Utility.isBlank(parseInt(e?.id, 10))) {
            changedlistId = `dl_${id.slice(0, 7)}`;
          } else {
            changedlistId = e?.id;
          }
          listId.push(changedlistId);
        }
      });
      const splittedListIdArray = [];
      while (listId.length > 0) {
        splittedListIdArray.push(listId.splice(0, sliceCount));
      }
      splittedListIdArray.forEach((id, index) => {
        listIds[`list_ids_${index}`] = id.toString();
      });
      listIds.previous_screen = screen;
      listIds.groupId = groupId;
      listIds.display = listDisplay;
      AnalyticsManager.logEvent('list_rendered', listIds);
    }
  }

  static getIngredientsCount = (ingredients, my_attributes_values = []) => {
    let myAttributes = [];
    my_attributes_values.forEach((e) => {
      if (Utility.isBlank(e?.new_values)) {
        return;
      }
      myAttributes.push(e.new_values[0]);
    });

    let good = [],
      bad = [],
      neutral = [];

    _.forEach(ingredients, (ingredient) => {
      if (
        Utility.isBlank(ingredient.contraindications) &&
        Utility.isBlank(ingredient.indications)
      ) {
        neutral.push(ingredient);
      }

      if (Utility.isPresent(ingredient.indications)) {
        _.forEach(ingredient.indications, (indication) => {
          if (myAttributes.includes(indication)) {
            good.push({ ...ingredient, ...{ status: 'good' } });
          } else {
            neutral.push(ingredient);
          }
        });
      }
      if (Utility.isPresent(ingredient.contraindications)) {
        _.forEach(ingredient.contraindications, (contraindication) => {
          if (myAttributes.includes(contraindication)) {
            bad.push({ ...ingredient, ...{ status: 'bad' } });
          } else {
            neutral.push(ingredient);
          }
        });
      }
    });

    good = _.uniqBy(good, function (e) {
      return e.name;
    });
    bad = _.uniqBy(bad, function (e) {
      return e.name;
    });
    neutral = _.uniqBy(neutral, function (e) {
      return e.name;
    });

    const mergedIngredient = _.uniqBy(
      [...good, ...bad, ...neutral],
      function (e) {
        return e.name;
      },
    );

    let goodCount = 0,
      badCount = 0,
      neutralCount = 0;

    mergedIngredient.forEach((ingredient) => {
      if (ingredient.status === 'good') {
        goodCount += 1;
      } else if (ingredient.status === 'bad') {
        badCount += 1;
      } else if (ingredient.status === 'neutral') {
        neutralCount += 1;
      }
    });

    return {
      goodCount,
      badCount,
      neutralCount,
    };
  };

  static getEsp(prompts, appliedCouponCodes) {
    let selectedPrompt = Utility.isPresent(prompts) ? prompts[0] : {};

    selectedPrompt =
      _.find(prompts, (prompt) => !!prompt.esp_offer) || selectedPrompt;

    const appliedCouponCodesArray =
      appliedCouponCodes.map((coupon) => coupon.coupon_code) || [];

    const {
      uninitialized: {
        default: {
          message: espMessage = '',
          esp: offerEsp = '',
          mrp: espMrp = '',
          discount: espDiscount = '',
        } = {},
      } = {},
    } = selectedPrompt;

    let couponCodeWithMinEsp = {};

    if (Utility.isPresent(prompts)) {
      const selectedPromptNew = _.find(prompts, (prompt) => {
        const { coupon_code = '' } = prompt;
        return appliedCouponCodesArray.includes(coupon_code);
      });

      if (Utility.isPresent(selectedPromptNew)) {
        selectedPrompt = selectedPromptNew;
      }
    }

    let offersEsp = offerEsp;
    let promptMrp = espMrp;
    let promptDiscount = espDiscount;
    let promptMessage = espMessage;

    if (Utility.isPresent(selectedPrompt)) {
      couponCodeWithMinEsp =
        _.find(
          appliedCouponCodes,
          (couponCode) =>
            couponCode?.coupon_code === selectedPrompt.coupon_code,
        ) || {};

      const {
        status = 'uninitialized',
        current_rule_set_id: currentRuleSetId = 'default',
      } = couponCodeWithMinEsp;
      let promptEsp = {};

      const cardPromptEsp = selectedPrompt[`${status}`];
      if (
        Utility.isPresent(currentRuleSetId) &&
        Utility.isPresent(cardPromptEsp[`${currentRuleSetId}`])
      ) {
        promptEsp = cardPromptEsp[`${currentRuleSetId}`];
      } else {
        promptEsp = cardPromptEsp.default;
      }
      if (Utility.isBlank(promptEsp)) {
        promptEsp = {};
      }

      const { esp = '', mrp = '', discount = '', message = '' } = promptEsp;
      offersEsp = esp;
      promptMrp = mrp;
      promptDiscount = discount;
      promptMessage = message;
    }

    return {
      offersEsp,
      promptMrp,
      promptDiscount,
      promptMessage,
      offer_id: selectedPrompt?.offer_id,
      isEspOffer: !!selectedPrompt?.esp_offer,
    };
  }

  static getAndroidSoftnavigationBarHeight() {
    if (Utility.isAndroid) {
      const bottonSoftBarHeight =
        Utility.getScreenHeight() - Utility.getWindowHeight() > 44
          ? 44
          : Utility.getScreenHeight() - Utility.getWindowHeight();
      return bottonSoftBarHeight;
    }
    return 0;
  }

  static getCartModalBottomSpace() {
    return Utility.getAndroidSoftnavigationBarHeight() !== 0
      ? Utility.getAndroidSoftnavigationBarHeight()
      : 12;
  }

  static getHitSlop(type = 'default') {
    const hitslopValues = {
      inputField: {
        top: 100,
        bottom: 100,
        left: 100,
        right: 100,
      },
      likeButton: {
        top: 20,
        bottom: 20,
        left: 20,
        right: 20,
      },
      default: {
        top: 10,
        bottom: 10,
        left: 10,
        right: 10,
      },
      contentPageCrossButton: {
        top: 40,
        bottom: 40,
        left: 40,
        right: 40,
      },
      backButton: {
        top: 20,
        bottom: 4,
        left: 50,
        right: 0,
      },
      address: {
        top: 20,
        bottom: 20,
        left: 20,
        right: 0,
      },
      checkBoxes: {
        top: 40,
        bottom: 40,
        left: 100,
        right: 100,
      },
      button: {
        top: 30,
        right: 50,
        bottom: 20,
        left: 0,
      },
      cross: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20,
      },
      slider: {
        top: 10,
        bottom: 10,
        left: 10,
      },
      playPause: {
        top: 16,
        left: 12,
        bottom: 10,
        right: 4,
      },
      matched: {
        top: 20,
        bottom: 20,
        left: 60,
        right: 100,
      },
      quantityPlus: {
        top: 10,
        right: 50,
        left: 50,
        bottom: 10,
      },
      quantityMinus: {
        top: 10,
        left: 50,
        right: 50,
        bottom: 10,
      },
      birthDateCross: {
        top: 100,
        right: 100,
        left: 100,
        bottom: 100,
      },
      quantityMinus: {
        top: 12,
        right: 30,
        left: 30,
        bottom: 12,
      },
      quantityPlus: {
        top: 12,
        right: 30,
        left: 30,
        bottom: 12,
      },
      my_profile: {
        top: 30,
        right: 30,
        left: 20,
        bottom: 25,
      },
    };
    const hitslop = hitslopValues[type];
    return hitslop;
  }

  static isBlank(value) {
    return (
      ((_.isEmpty(value) ||
        (typeof value === 'string' && _.isEmpty(value.trim())) ||
        _.isNull(value) ||
        _.isUndefined(value) ||
        value === 'null' ||
        value === 'undefined') &&
        !_.isNumber(value) &&
        !_.isDate(value)) ||
      _.isNaN(value)
    );
  }

  static isPresent(value) {
    return !Utility.isBlank(value);
  }

  static isDobBlank(value) {
    return value === '' || value === null || value === undefined;
  }

  static isDobPresent(value) {
    return !Utility.isDobBlank(value);
  }

  static shortenName = (name, limit) =>
    name.length > limit ? `${name.substring(0, limit)}...` : name;

  static isIndefinite(value) {
    return _.isEmpty(value) || _.isNull(value) || _.isUndefined(value);
  }

  static onShareTapped = (slug, genShareOption = {}) => {
    const options = {
      ...genShareOption,
      title: Config.APP_NAME,
      url: slug,
    };
    Share.open(options)
      .then((res) => {
        console.tron.log(res);
      })
      .catch((err) => {
        err && console.tron.log(err);
      });
  };

  static calculateTimeDifference(user_created_at, offer_time) {
    let timeInSec =
      moment(user_created_at).add(offer_time, 'seconds').diff(new Date()) /
      1000;
    return timeInSec;
  }

  static getPromptMeta(promptData) {
    const allOffers = [],
      initialized = [],
      unInitaialized = [],
      active = [],
      redeemed = [],
      partiallyRedeemed = [];
    _.forEach(promptData, (e) => {
      allOffers.push(e.offer_id);
      if (e.status === 'uninitialized') {
        unInitaialized.push(e.offer_id);
      } else if (e.status === 'initialized') {
        initialized.push(e.offer_id);
      } else if (e.status === 'active') {
        active.push(e.offer_id);
      } else if (e.status === 'redeemed') {
        redeemed.push(e.offer_id);
      } else {
        partiallyRedeemed.push(e.offer_id);
      }
    });
    return {
      offer_ids: allOffers.toString(),
      uninitialized_offer_ids: unInitaialized.toString(),
      initialized_offer_ids: initialized.toString(),
      active_offer_ids: active.toString(),
      partially_redeemed_offer_ids: partiallyRedeemed.toString(),
      redeemed_offer_ids: redeemed.toString(),
    };
  }

  static calculateTimeDifferenceForOffer(endsAt) {
    let timeInSec = moment(endsAt).diff(new Date()) / 1000;
    return timeInSec;
  }

  static calculateTimeDifferenceForOffers(endsAt) {
    let timeInSec = moment(endsAt).diff(new Date()) / 1000;

    return timeInSec - 5.5 * 60 * 60;
  }

  static onSocialShareTapped = (slug, type) => {
    const options = {
      url: '',
      title: Config.APP_NAME,
      social: Share.Social[_.upperCase(type)],
      message: `${slug}`,
    };
    const sharePromise = Share.shareSingle(options);
    sharePromise
      .then((res) => {
        console.tron.log(res);
      })
      .catch((err) => {
        err && console.tron.log(err);
      });
  };

  static getMinifiedImage(url, width = 360, height = Utility.getScreenWidth()) {
    if (Utility.isBlank(url)) {
      return '';
    }
    let newImageUrl = url;
    if (!Utility.isBlank(url)) {
      newImageUrl = Utility.getImageUrl(url, width, height);
    }
    return newImageUrl;
  }

  static getImageUrlForProducts = (images = [], imageUrl) => {
    return Utility.isBlank(images) || images.length === 0
      ? imageUrl
      : images[0];
  };

  static getImageUrl = (
    url,
    width = 360,
    height,
    format = DEFAULT_IMAGE_FORMAT,
  ) => {
    if (Utility.isBlank(url)) {
      return '';
    }

    let standardWidth = width;
    let newUrl = url.replace(`${CLOUDFRONT_URL}/`, `${CDN_3}/`);
    if (!url.includes('width=')) {
      if (width <= 120) {
        standardWidth = 120;
      } else if (width > 120 && width <= 180) {
        standardWidth = 180;
      } else if (width > 180 && width <= 360) {
        standardWidth = 360;
      } else if (width > 360 && width <= 480) {
        standardWidth = 720; // for full width image use higher resolution as lower resolution is looking pixelated
      } else if (width > 480 && width <= 720) {
        standardWidth = 720;
      } else if (width > 720) {
        standardWidth = 1024;
      } else {
        standardWidth = 360;
      }
      newUrl = `${newUrl}?width=${standardWidth}`;
    }

    if (!newUrl.includes('format=')) {
      if (!isIOS()) {
        newUrl = `${newUrl}&format=${format}`;
      } else {
        newUrl = `${newUrl}&format=png`;
      }
    }
    return newUrl;
  };

  static getSmallImageUrl = (url, width, height) => {
    if (Utility.isBlank(url)) {
      return '';
    }
    let standardWidth = width;
    if (width <= 120) {
      standardWidth = 120;
    } else if (width > 120 && width <= 180) {
      standardWidth = 180;
    } else if (width > 180 && width <= 360) {
      standardWidth = 360;
    } else if (width > 360 && width <= 480) {
      standardWidth = 720;
    } else if (width > 480 && width <= 720) {
      standardWidth = 720;
    } else if (width > 720) {
      standardWidth = 1024;
    } else {
      standardWidth = 360;
    }
    let newUrl = url;
    newUrl = `${newUrl}?width=${standardWidth}`;

    return newUrl;
  };

  static getActivityIndicator(style) {
    return Platform.select({
      ios: (
        <Animated.View style={style}>
          <MaterialIndicator color='rgb(255, 255, 255)' size={60} />
        </Animated.View>
      ),
      android: (
        <Animated.View style={style}>
          <MaterialIndicator color='rgb(255, 255, 255)' size={40} />
        </Animated.View>
      ),
      web: (
        <Animated.View style={style}>
          <MaterialIndicator color='rgb(255, 255, 255)' size={40} />
        </Animated.View>
      )
    });
  }

  static getActivityIndicatorSmall(
    viewStyle = {},
    indicatorColor = 'rgb(255, 255, 255)',
    indicatorSize = 30,
  ) {
    return (
      <Animated.View style={viewStyle}>
        <MaterialIndicator color={indicatorColor} size={indicatorSize} />
      </Animated.View>
    );
  }

  static getTimeInSeconds(time) {
    const Time = moment().startOf('day').seconds(time).format('mm:ss');
    return Time;
  }

  static formatTime(time, format) {
    const Time = moment().startOf('day').seconds(time).format(format);

    return Time;
  }

  static setImmersiveModeOn = () => {
    // if (Utility.isAndroid()) {
    //   Immersive.setFullImmersiveMode(true);
    // }
  };

  static findRanking = (rankings, my_attributes_values = []) => {
    const myAttributes = [];
    my_attributes_values.forEach((e) => {
      if (Utility.isBlank(e?.new_values)) {
        return;
      }
      myAttributes.push(e.new_values[0].toString());
    });

    const myRank = _.find(rankings, (rank, index) => {
      return !!myAttributes.includes(index);
    });

    return myRank || {};
  };

  static findIndicationsAndCounterIndications = (
    ingredients,
    my_attributes_values = [],
    principalAttributes = [],
  ) => {
    const myAttributes = [];
    my_attributes_values.forEach((e) => {
      if (Utility.isBlank(e?.new_values)) {
        return;
      }
      myAttributes.push(e.new_values[0].toString());
    });

    const myAttributesForProductCategory = _.intersectionWith(
      myAttributes,
      Object.keys(principalAttributes),
      _.isEqual,
    );

    const ingredientsForMe = {};

    _.forEach(ingredients, (ingredient) => {
      const {
        name,
        contraindications = [],
        indications = [],
        icon_image_url,
        slug,
        supers: super_ingredients = [],
        indications_mapping = {},
      } = ingredient;

      const mySuper = _.intersectionWith(
        super_ingredients.map(String),
        myAttributesForProductCategory,
        _.isEqual,
      );

      const uniqueIndication = [];
      _.map(indications, (indication) => {
        if (!super_ingredients.includes(indication)) {
          uniqueIndication.push(indication);
        }
      });

      const myIndications = _.intersectionWith(
        uniqueIndication.map(String),
        myAttributesForProductCategory,
        _.isEqual,
      );

      const myContraIndications = _.intersectionWith(
        contraindications.map(String),
        myAttributesForProductCategory,
        _.isEqual,
      );

      ingredientsForMe[`${name}`] = {};

      ingredientsForMe[`${name}`].myIndications = myIndications;
      ingredientsForMe[`${name}`].myContraIndications = myContraIndications;
      ingredientsForMe[`${name}`].mySuper = mySuper;
      ingredientsForMe[`${name}`].image = icon_image_url;
      ingredientsForMe[`${name}`].slug = slug;
      ingredientsForMe[`${name}`].mapping = indications_mapping;
    });

    return ingredientsForMe;
  };

  static setImmersiveModeOff = () => {
    // if (Utility.isAndroid()) {
    //   Immersive.setFullImmersiveMode(false);
    // }
  };

  static toggleArrayElements(array, currentItem) {
    const newArray = array.includes(currentItem)
      ? array.filter((item) => item !== currentItem)
      : [...array, currentItem];
    return newArray;
  }

  static createFavouriteListObject(
    id,
    display,
    content,
    size,
    display_count,
    name,
    objects,
  ) {
    const favObj = {
      type: 'list',
      id,
      display,
      content,
      size,
      display_count,
      name,
      objects,
    };
    return favObj;
  }

  static mapPathToComponentRoute(path) {
    const routeString = {
      youtube_channels: 'Artist',
      artists: 'Artist',
      products: 'Product',
      youtube_videos: 'ContentModal',
      foxy_videos: 'ContentModal',
      youtube_offer: 'ContentModal',
      brands: 'Brand',
      cart: 'Cart',
      camera: 'Camera',
      lists: 'MoreItems',
      my_kit: 'MyCollection',
      myorders: 'OrdersHistory',
      orders: 'OrdersHistory',
      image_review: 'AttributeSelector',
      add_to_bag: 'AddToBag',
      apply_coupon: 'CartOffers',
      offers: 'OfferDetail',
      generic_offers: 'GenericOffer',
      free_items: 'FreeGift',
      store_page: 'Store',
      offer_page: 'Store',
      famous: 'MyProfile',
      feed: 'Feed',
      offer: 'Store',
      search: 'Search',
      ingredients: 'MoreItems',
      tags: 'Tag',
      cart_items: 'Cart',
      recycle_with_foxy: 'RecycleWithFoxyWebView',
      'recycle-with-foxy': 'RecycleWithFoxyWebView',
      payment_methods: 'Payments',
      checkout_reminder: 'CheckoutReminder',
      categories: 'Category',
      brand_categories: 'MoreItems',
      'personalised-beauty': 'Payoff',
      'personalized-beauty': 'Payoff',
      reviews: !Config.TABS?.includes('Reviews') ? 'ReviewsPage' : 'Reviews',
      altBrand: 'AltBrand',
      'unlock-joy-or-delights': 'ChooseFreeGiftScreen',
      post_review: 'PostReview',
      '': 'Feed',
      'today-deals': WishlistAsBottomTab ? 'Wishlist' : 'TodayDeals',
      'go-back': 'GoBack',
      go_back: 'GoBack',
      invite: 'InviteCenter',
      'invite-center': 'InviteCenter',
      'invite-centre': 'InviteCenter',
      'invite-contacts': 'Contacts',
      influencer: 'Influencer',
      salons: 'SalonAmount',
      order: 'OrderDetails',
      shipment: 'OrderDetails',
      points_history: 'PointsHistory',
      my_personalised_offers: 'PersonalisedOffers',
      foxy_stores: 'RetailStore',
      loyalty_plans: 'LoyaltyPlans',
      take_selfie: 'TakeSelfie',
      foxy_edge: 'TheEdge',
      foxy_club: 'TheEdge',
      call_api: 'CallApi',
      attribute_selector: 'AttributeSelector',
      review_selfie: 'AttributeSelector',
      routines: 'PreRoutineDetails',
      pre_routine: 'PreRoutineDetails',
      all_routines: 'Routines',
      during_routine: 'DuringRoutineDetails',
      add_dob: 'DateOfBirth',
      need_help: 'FaqSection',
      faq_sections: 'FaqQuestionSection',
      faqs: 'FaqAnswerSection',
      selfie_prompt: 'SelfieIntro',
      orderHistory: 'OrdersHistory',
      media_playlists: 'MediaPlaylist',
      media_playlist: 'MediaPlaylist',
      contact_us: 'RequestCallBackPage',
      callback: 'SelectCallBackTimeSlot',
      address: 'Address',
      collections: 'MoreItems',
      personalized: 'MoreItems',
      'all-categories': 'Categories',
      'allow-notifications': 'AllowNotificationSettings',
      retry_payment: 'Payments',
      login_to_confirm: 'OrderStatus',
    };

    const route = routeString[path] ? routeString[path] : 'WebUrlView';

    return route;
  }

  static shouldUseId(path) {
    if (path === 'OrderDetails') {
      return true;
    }
    return false;
  }

  static getArrayFromObject(object, type) {
    if (Utility.isBlank(object)) {
      return [];
    }
    return Object.keys(object).map((key) => ({
      id: key,
      type,
      data: object[key],
    }));
  }

  static extractImageName(uri) {
    const getLastIndexOfSlash = uri.lastIndexOf('/') + 1;
    const getFileName = uri.substring(getLastIndexOfSlash, uri.length);
    return getFileName;
  }

  static extractLastName(uri) {
    const getLastIndexOfSlash = uri.lastIndexOf('/') + 1;
    const getFileName = uri.substring(getLastIndexOfSlash, uri.length);
    return getFileName;
  }

  static evaluateLuhnAlgorithm(inputNum) {
    let sum = 0;
    let doubleUp = false;
    /* from the right to left, double every other digit starting with the second to last digit. */
    for (let i = inputNum.length - 1; i >= 0; i--) {
      const curDigit = parseInt(inputNum.charAt(i));
      /* double every other digit starting with the second to last digit */
      if (doubleUp) {
        /* doubled number is greater than 9 than subtracted 9 */
        if (curDigit * 2 > 9) {
          sum += curDigit * 2 - 9;
        } else {
          sum += curDigit * 2;
        }
      } else {
        sum += curDigit;
      }
      doubleUp = !doubleUp;
    }
    /* sum and divide it by 10. If the remainder equals zero, the original credit card number is valid.  */
    return sum % 10 === 0;
  }

  static getCardBrand(curVal) {
    let selBrand;
    // JCB
    const jcbRegex = new RegExp('^(?:2131|1800|35)[0-9]{0,}$'); // 2131, 1800, 35 (3528-3589)
    // American Express
    const amexRegex = new RegExp('^3[47][0-9]{0,}$'); // 34, 37
    // Diners Club
    const dinersRegex = new RegExp('^3(?:0[0-59]{1}|[689])[0-9]{0,}$'); // 300-305, 309, 36, 38-39
    // Visa
    const visaRegex = new RegExp('^4[0-9]{0,}$'); // 4
    // MasterCard
    const masterCardRegex = new RegExp(
      '^(5[1-5]|222[1-9]|22[3-9]|2[3-6]|27[01]|2720)[0-9]{0,}$',
    ); // 2221-2720, 51-55
    const maestroRegex = new RegExp('^(5[06789]|6)[0-9]{0,}$'); // always growing in the range: 60-69, started with / not something else, but starting 5 must be encoded as mastercard anyway
    // Discover
    const discoverRegex = new RegExp(
      '^(6011|65|64[4-9]|62212[6-9]|6221[3-9]|622[2-8]|6229[01]|62292[0-5])[0-9]{0,}$',
    );
    // //6011, 622126-622925, 644-649, 65
    const rupayRegex = new RegExp('^6[0-9]{15}$');
    curVal = curVal.replace(/\D/g, '');
    // checks per each, as their could be multiple hits
    if (curVal.match(jcbRegex)) {
      selBrand = 'jcb';
    } else if (curVal.match(amexRegex)) {
      selBrand = 'amex';
    } else if (curVal.match(dinersRegex)) {
      selBrand = 'diners_club';
    } else if (curVal.match(visaRegex)) {
      selBrand = 'visa';
    } else if (curVal.match(masterCardRegex)) {
      selBrand = 'mastercard';
    } else if (curVal.match(discoverRegex)) {
      selBrand = 'discover';
    } else if (curVal.match(maestroRegex)) {
      if (curVal[0] === '5') {
        // started 5 must be mastercard
        selBrand = 'mastercard';
      } else {
        selBrand = 'maestro'; // maestro is all 60-69 which is not something else, thats why this condition in the end
      }
    } else if (curVal.match(rupayRegex)) {
      selBrand = 'rupay';
    } else {
      selBrand = 'unknown';
    }
    return selBrand;
  }

  static getCardDimentions(columns, content, size = '') {
    let spacing = 16;
    let additionalHeight = 0;
    let cardImageRatio = 1;
    let horizontalMargin = 0;
    let windowRatio = 1;
    if (size === 'small') {
      windowRatio = 0.75;
      spacing = 17;
      cardImageRatio = 0.75;
      additionalHeight = 40;
    }
    if (content === 'artistCard') {
      spacing = 24;
      additionalHeight = Utility.artistNameContainerHeight;
    }
    if (content === 'mediaCard') {
      additionalHeight = 52;
      cardImageRatio = 9 / 16;
    }
    if (columns !== 2) {
      horizontalMargin = 3;
    }
    const viewwidth =
      Utility.getDynamicWidthForGrid(isDesktop() ? columns * 2 : columns, spacing, windowRatio) -
      horizontalMargin;
    const viewheight =
      cardImageRatio *
        Utility.getDynamicWidthForGrid(isDesktop() ? columns * 2 : columns, spacing, windowRatio) +
      additionalHeight;
    const imagewidth =
      cardImageRatio *
      Utility.getDynamicWidthForGrid(isDesktop() ? columns * 2 : columns, spacing, windowRatio);
    const imageheight =
      cardImageRatio *
      Utility.getDynamicWidthForGrid(isDesktop() ? columns * 2 : columns, spacing, windowRatio);
    return [viewwidth, viewheight, imagewidth, imageheight];
  }

  static osBasedPermissionName = (permissionName) => {
    if (Utility.isAndroid()) {
      return permissionName.android;
    }
    return permissionName.ios;
  };

  static createPermissionMetaObject = (screen_name, permission_name) => {
    return { screen_name, permission_name };
  };
  static logPermissionEvent = (isAllowed, meta) => {
    let eventName = isAllowed
      ? EventType.miscAppEvents.PERMISSION_ALLOW
      : EventType.miscAppEvents.PERMISSION_DENY;

    AnalyticsManager.logEvent(eventName, meta);
  };

  //Used in foxy camera only
  static checkMultiplePermission(permissionArray = [], callback) {
    const permissions = permissionArray.map((element) =>
      Utility.osBasedPermissionName(element),
    );
    if (Utility.isAndroid()) {
      PermissionsAndroid.requestMultiple(permissions).then((response) => {
        if (
          response['android.permission.CAMERA'] === 'granted' &&
          response['android.permission.READ_EXTERNAL_STORAGE'] === 'granted' &&
          response['android.permission.WRITE_EXTERNAL_STORAGE'] === 'granted'
        ) {
          Utility.logPermissionEvent(true);
        } else {
          Utility.logPermissionEvent(false);
        }
        callback(response);
      });
    }
    if (Utility.isIOS()) {
      Permissions.requestMultiple(permissions).then((statuses) => {});
    }
  }

  static checkForPermissions(permissionName, requiredInIOS, callback) {
    const permissionTag = Utility.osBasedPermissionName(permissionName);
    if (Utility.isAndroid()) {
      PermissionsAndroid.check(permissionTag).then((granted) => {
        if (granted) {
          callback(true);
        } else {
          callback(false);
        }
      });
    }
    if (requiredInIOS) {
      Permissions.check(permissionTag)
        .then((data) => {
          if (data === 'authorized') {
            callback(true);
          } else {
            callback(false);
          }
        })
        .catch(() => {});
    }
  }

  /**
   * FIXME:
   * 1 - These methods shouldn't be here in Utility. because PermissionUtility is responsible for this action.
   * 2 - Raising and event is also a responsibility of permissionUtility.
   *
   * Moving further, we will remove these all checkPermission related methods from this class
   */
  static checkPermission(
    permissionName,
    requiredInIOS,
    callback,
    meta = {},
    logEvent = true,
  ) {
    const permissionTag = Utility.osBasedPermissionName(permissionName);
    if (Utility.isAndroid()) {
      PermissionsAndroid.request(permissionTag)
        .then((data) => {
          if (data === 'granted') {
            if (logEvent) {
              Utility.logPermissionEvent(true, meta);
            }
            callback(true, data);
          } else {
            if (logEvent) {
              Utility.logPermissionEvent(false, meta);
            }
            callback(false, data);
          }
        })
        .catch(() => {});
    }
    if (requiredInIOS && Utility.isIOS()) {
      Permissions.request(permissionTag)
        .then((data) => {
          if (data === 'authorized') {
            if (logEvent) {
              Utility.logPermissionEvent(true, meta);
            }
            callback(true, 'authorized');
          } else {
            if (logEvent) {
              Utility.logPermissionEvent(false, meta);
            }
            callback(false, 'denied');
          }
        })
        .catch((err) => callback(false));
    }
  }

  static checkForPermission(
    permissionName,
    permissionMessage,
    authorizedCallback,
    noPermissionCallback,
    meta = {},
  ) {
    Permissions.request(permissionName).then((response) => {
      if (response === 'authorized') {
        Utility.logPermissionEvent(true, meta);
        authorizedCallback();
        return;
      }
      if (Utility.isIOS() && response === 'denied') {
        Utility.logPermissionEvent(false, meta);
        noPermissionCallback(permissionMessage);
      }
      if (response === 'restricted') {
        Utility.logPermissionEvent(false, meta);
        noPermissionCallback(permissionMessage);
      }
    });
  }

  static checkPermissionStatic(
    permissionName,
    requiredInIOS,
    callback,
    meta = {},
  ) {
    const permissionTag = Utility.osBasedPermissionName(permissionName);
    if (Utility.isAndroid()) {
      PermissionsAndroid.request(permissionTag)
        .then((data) => {
          if (data === 'granted') {
            Utility.logPermissionEvent(true, meta);
          } else {
            Utility.logPermissionEvent(false, meta);
          }
          callback(data);
        })
        .catch(() => {
          callback(false);
        });
    }
    if (requiredInIOS) {
      Permissions.request(permissionTag)
        .then((data) => {
          if (data === 'authorized') {
            Utility.logPermissionEvent(true, meta);
          } else {
            Utility.logPermissionEvent(false, meta);
          }
          callback(data);
        })
        .catch((err) => callback(false));
    }
  }

  static getNotificationPermission(source, callback) {
    if (isWeb()) return;
    if (this.isAndroid()) {
      return;
    }
    const event = 'notification_permission';
    const permissionListener = new NativeEventEmitter(
      NativeModules.LocalNotificationHandler,
    ).addListener(event, ({ action }) => {
      callback(action);
      permissionListener?.remove();
    });

    NativeModules.LocalNotificationHandler.getNotificationOnAppLaunch();
  }

  static extractImageName(uri) {
    const getLastIndexOfSlash = uri.lastIndexOf('/') + 1;
    const getFileName = uri.substring(getLastIndexOfSlash, uri.length);
    return getFileName;
  }

  static getFacialMarker(tags = [], currentGender = 'female') {
    const object = [];

    // Gender Block
    let gender = currentGender;
    const userGender = Utility.getValueByKey(tags, 'gender');
    if (!this.isConfident(userGender)) {
      gender = 'female';
    }
    const mappedGender = this.extractMappedTag('GENDER', gender);
    object.push({
      title: 'Gender',
      value: mappedGender,
    });

    // Mustache Block
    const mustache = Utility.getValueByKey(tags, 'mustache');

    const hairMustache = Utility.getValueByKey(tags, 'hair mustache');

    if (
      this.isConfident(mustache) &&
      this.isAttributeAvailable(mustache.value) &&
      this.isMale(currentGender)
    ) {
      object.push({
        title: 'Moustache Type',
        value: this.extractMappedTag('MOUSTACHE_TYPE', hairMustache.value),
      });
    }

    // Beard Goatee Block
    const goatee = Utility.getValueByKey(tags, 'goatee');

    if (
      this.isAttributeAvailable(goatee.value) &&
      this.isConfident(goatee) &&
      this.isMale(currentGender)
    ) {
      object.push({ title: 'Beard Style', value: 'Styled' });
    }

    // Face Shape Block
    const ovalFace = Utility.getValueByKey(tags, 'oval face');
    if (
      this.isAttributeAvailable(ovalFace.value) &&
      this.isConfident(ovalFace, 0.4)
    ) {
      object.push({ title: 'Face Shape', value: 'Oval' });
    }

    // Hair Length block
    const bald = Utility.getValueByKey(tags, 'bald');
    if (this.isAttributeAvailable(bald.value) && this.isConfident(bald, 0.9)) {
      object.push({ title: 'Hair Length', value: 'Bald' });
    } else {
      const hairLength = Utility.getValueByKey(tags, 'hair length');
      const mappedHairLength = this.extractMappedTag(
        'HAIR_LENGTH',
        hairLength.value,
        currentGender,
      );
      object.push({ title: 'Hair Length', value: mappedHairLength });
    }

    // Beard Length  Block
    const beard = Utility.getValueByKey(tags, 'beard');
    if (
      this.isAttributeAvailable(beard.value) &&
      this.isConfident(beard) &&
      this.isMale(currentGender)
    ) {
      const fiveOClockShadow = Utility.getValueByKey(tags, '5oclock shadow');

      let isFiveOClockShadowExists = false;
      if (
        this.isAttributeAvailable(fiveOClockShadow.value) &&
        this.isConfident(fiveOClockShadow)
      ) {
        object.push({ title: 'Beard Length', value: 'Stubble' });
        isFiveOClockShadowExists = true;
      }
      if (!isFiveOClockShadowExists) {
        const beardHair = Utility.getValueByKey(tags, 'hair beard');

        const mappedBeardLength = this.extractMappedTag(
          'BEARD_LENGTH',
          beardHair.value,
        );

        object.push({ title: 'Beard Length', value: mappedBeardLength });
      }
    }

    // Hair Color Block
    const getBlackHairTag = Utility.getValueByKey(tags, 'black hair');

    const hasBlackHairs = this.isAttributeAvailable(getBlackHairTag.value);
    const getBrownHairsTag = Utility.getValueByKey(tags, 'brown hair');

    const hasBrownHairs = this.isAttributeAvailable(getBrownHairsTag.value);
    const getGrayHairTag = Utility.getValueByKey(tags, 'gray hair');

    const hasGrayHair = this.isAttributeAvailable(getGrayHairTag.value);
    const arr = [];
    if (hasBlackHairs) {
      arr.push(getBlackHairTag);
    }
    if (hasBrownHairs) {
      arr.push(getBrownHairsTag);
    }
    if (hasGrayHair) {
      arr.push(getGrayHairTag);
    }

    const maxConfidentHairColor = Utility.compareHairColorConfidence(arr);

    if (arr.length < 1 || maxConfidentHairColor.length < 1) {
      if (!hasBlackHairs && !hasBrownHairs && !hasGrayHair) {
        const hairColorType = Utility.getValueByKey(tags, 'hair color type');

        object.push({
          title: 'Hair Colour',
          value: this.extractMappedTag(
            'HAIR_COLOUR',
            hairColorType.value,
            currentGender,
          ),
        });
      } else if (hasBlackHairs && !hasBrownHairs && !hasGrayHair) {
        object.push({ title: 'Hair Colour', value: 'Black' });
      } else if (!hasBlackHairs && hasBrownHairs && !hasGrayHair) {
        object.push({ title: 'Hair Colour', value: 'Brown' });
      } else if (!hasBlackHairs && !hasBrownHairs && hasGrayHair) {
        object.push({ title: 'Hair Colour', value: 'Grey' });
      }
    } else {
      const color = _.upperFirst(maxConfidentHairColor.name.split(' ')[0]);
      object.push({ title: 'Hair Colour', value: color });
    }

    // Hair Type Block
    const wavyHairs = Utility.getValueByKey(tags, 'wavy hair');
    const hasWavyHairs =
      this.isAttributeAvailable(wavyHairs.value) && this.isConfident(wavyHairs);
    const straightHair = Utility.getValueByKey(tags, 'straight hair');
    const hasStraightHairs =
      this.isAttributeAvailable(straightHair.value) &&
      this.isConfident(straightHair);
    if (!hasWavyHairs && hasStraightHairs) {
      object.push({ title: 'Hair Type', value: 'Straight' });
    } else if (hasWavyHairs && !hasStraightHairs) {
      object.push({ title: 'Hair Type', value: 'Wavy' });
    } else if (!hasWavyHairs && !hasStraightHairs) {
      object.push({
        title: 'Hair Type',
        value: this.compareConfidence(
          true,
          wavyHairs.name,
          wavyHairs.confidence,
          straightHair.name,
          straightHair.confidence,
        ),
      });
    } else if (hasWavyHairs && hasStraightHairs) {
      object.push({
        title: 'Hair Type',
        value: this.compareConfidence(
          false,
          wavyHairs.name,
          wavyHairs.confidence,
          straightHair.name,
          straightHair.confidence,
        ),
      });
    }

    //age section
    const { value } = Utility.getValueByKey(tags, 'age');
    const ageObjects = ['Below 18', '18-24', '25-35', 'Above 35'];
    let ageValue = '';
    if (value < 18) {
      ageValue = ageObjects[0];
    } else if (value >= 18 && value <= 24) {
      ageValue = ageObjects[1];
    } else if (value >= 25 && value <= 35) {
      ageValue = ageObjects[2];
    } else {
      ageValue = ageObjects[3];
    }
    object.push({
      title: 'Age',
      value: ageValue,
    });

    return object;
  }

  // Deprecated
  static getFacialMarkers(tags = [], points = [], xAxis, yAxis) {
    const facialPoints = [];

    const hairCategories = ['wavy hair', 'straight hair', 'curly hair'];
    let skinColor = null;

    let hairLength = null;
    let hairColor = null;
    let eyesColor = null;
    let beard = null;
    let bald = null;
    let hairType = null;
    let gender = null;
    let lastConfidence = 0;
    let goatee = false;
    let mustache = false;

    tags.forEach((element) => {
      switch (element.name) {
        case 'wavy hair':
        case 'straight hair':
        case 'curly hair':
          if (element.confidence > lastConfidence) {
            hairType = element.name;
            lastConfidence = element.confidence;
          }
          break;
        case 'mustache':
          mustache = Utility.isAttributeAvailable(element.value) || false;
          break;
        case 'bald':
          bald = element.value;
          break;
        case 'gender':
          gender = element.value;
          Utility.genderFromSelfie = gender;
          break;
        case 'color skin':
          skinColor = element.value;
          break;
        case 'color mustache':
          mustacheColor = element.value;
          break;
        case 'color hair':
          hairColor = element.value;
          break;
        case 'color eyes':
          eyesColor = element.value;
          break;
        case 'beard':
          beard = Utility.isAttributeAvailable(element.value) ? 'yes' : null;
          break;
        case 'goatee':
          goatee = Utility.isAttributeAvailable(element.value) || false;
          break;
        case 'hair length':
          hairLength = element.value;
          break;
        default:
          break;
      }
    });

    points.forEach((element) => {
      switch (element.name) {
        case 'temple right 1':
          facialPoints.push(
            Utility.addItemToArray(
              Utility.isAndroid() ? element.x * 1.1 : element.x,
              element.y * 0.9,
              'Hair Type',
              false,
              { xAxis, yAxis },
              false,
            ),
          );
          break;
        case 'temple right':
          facialPoints.push(
            Utility.addItemToArray(
              element.x,
              element.y * 0.7,
              'Hair Length',
              false,
              { xAxis, yAxis },
              false,
            ),
          );
          break;
        // case 'eye right top outer':
        //   facialPoints.push(
        //     Utility.addItemToArray(
        //       element.x,
        //       element.y,
        //       'Eye Colour',
        //       eyesColor,
        //       { xAxis, yAxis },
        //       false,
        //     ),
        //   );
        //   break;

        case 'forehead left':
          if (skinColor) {
            facialPoints.push(
              Utility.addItemToArray(
                element.x,
                element.y * 0.9,
                'Skin Tone',
                skinColor,
                { xAxis, yAxis },
                false,
              ),
            );
          }
          break;
        case 'chin left 3':
          if (beard && gender === 'male') {
            facialPoints.push(
              Utility.addItemToArray(
                element.x,
                element.y,
                'Beard',
                false,
                { xAxis, yAxis },
                false,
              ),
            );
          }

          break;
        case 'temple left':
          if (hairColor) {
            facialPoints.push(
              Utility.addItemToArray(
                element.x,
                element.y * 0.6,
                'Hair Colour',
                hairColor,
                { xAxis, yAxis },
                false,
              ),
            );
          }
          break;
        // case 'nose right bottom outer':
        //   if (gender === 'male' && mustacheColor) {
        //     facialPoints.push(
        //       Utility.addItemToArray(
        //         element.x,
        //         element.y - 20,
        //         'Mustache',
        //         mustacheColor,
        //         { xAxis, yAxis },
        //         false,
        //       ),
        //     );
        //   }

        default:
          break;
      }
    });

    return { points: facialPoints, gender: Utility.genderFromSelfie };
  }

  static isAttributeAvailable(value) {
    return value === 'yes';
  }

  static isConfident(attributeObject, confidenceValue = 0.6) {
    return attributeObject.confidence > confidenceValue;
  }

  static isMale(gender) {
    return gender === 'male';
  }

  static getGenderUsingId(id) {
    return id === GENDER_IDS.male
      ? 'male'
      : id === GENDER_IDS.female
      ? 'female'
      : 'unisex';
  }

  static addItemToArray = (x, y, name, color, ratio, icon) => {
    const { xAxis, yAxis } = ratio;
    const X = x * xAxis;
    const Y = y * yAxis;

    return {
      name,
      color,
      icon,
      additionalData: [],
    };
  };

  static getLocationCordinates(callback) {
    Geolocation.getCurrentPosition(
      (position) => {
        callback(position.coords.latitude, position.coords.longitude);
      },
      (error) => {
        callback(NaN, NaN);
      },
    );
  }

  static deviceAPILevel = 0;

  static isNotification = false;

  static getDeviceAPILevel() {
    if (Utility.isAndroid()) {
      NativeModules.DeviceDetails.getAPILevel((err, value) => {
        Utility.deviceAPILevel = value;
      });
    }
  }

  static isPermissionAllowed(permissionName, callback) {
    if (Utility.isIOS() && permissionName === 'location') {
      Permissions.check(permissionName, { type: 'whenInUse' })
        .then((data) => {
          this.checkPermissionStatus(data, callback);
        })
        .catch((err) => callback(false));
    } else {
      Permissions.check(permissionName)
        .then((data) => {
          this.checkPermissionStatus(data, callback);
        })
        .catch((err) => callback(false));
    }
  }

  static checkPermissionStatus(data, callback) {
    if (data === 'authorized') {
      callback(true, data);
    }
    if (data === 'denied') {
      callback(false, data);
    }
    if (data === 'restricted') {
      callback(false, data);
    }
    if (data === 'undetermined') {
      callback(false, data);
    }
  }

  static shouldPreventReduxFetch(ownProps) {
    if (ownProps.preventReduxFetch) {
      return true;
    }
    return false;
  }

  static isImageUrlValid(url) {
    if (Utility.isBlank(url) || url === '') {
      return false;
    }
    const ext = url.split('.').pop();
    if (
      ext === 'jpg' ||
      ext === 'png' ||
      ext === 'jpeg' ||
      ext === 'webp' ||
      ext === 'net'
    ) {
      return true;
    }
    return false;
  }

  static convertSecondIntoMinutes(seconds) {
    const minutes =
      Math.floor(seconds / 60) < 10
        ? `0${Math.floor(seconds / 60)}`
        : Math.floor(seconds / 60);
    const sec =
      Math.floor(seconds % 60) < 10
        ? `0${Math.floor(seconds % 60)}`
        : Math.floor(seconds % 60);
    return `${minutes}:${sec}`;
  }

  static async refreshAccessToken(refreshToken) {
    const result = await refresh(GOOGLE_AUTH_CONFIG, { refreshToken });
    return result;
  }

  static shouldShowHeader(display, content, previousScreen = '') {
    const disp = _.camelCase(display);
    if (
      (disp === 'grid' ||
        disp === 'list' ||
        disp === 'vertical' ||
        disp === 'rail' ||
        disp === 'storyRail' ||
        disp === 'verticalRail' ||
        disp === 'personalizedGrid' ||
        disp === 'horizontalPlaylist' ||
        disp === 'verticalPlaylist' ||
        disp === 'tabbed' ||
        disp === 'matchedProduct' ||
        disp === 'gridRail' ||
        disp === 'rectangularGrid' ||
        disp === 'section' ||
        disp === 'pager' ||
        disp === 'pagerBottom' ||
        disp === 'heroRail' ||
        disp === 'listWithIngredients' ||
        disp === 'cardSequence' ||
        disp === 'swipableCards' ||
        disp === 'staggeredGrid' ||
        disp === 'listWithDesc' ||
        disp === 'masonry' ||
        disp === 'fancyRail' ||
        disp === 'verticalBanner' ||
        disp === 'coupon' ||
        disp === 'staggeredGrid') &&
      (content === 'product' ||
        content === 'artist' ||
        content === 'media' ||
        content === 'brand' ||
        content === 'salon' ||
        content === 'list' ||
        content === 'media_category' ||
        content === 'offer' ||
        content === 'sku' ||
        content === 'product_category' ||
        content === 'quick_filters' ||
        content === 'banner' ||
        content === 'feature' ||
        content === 'routine' ||
        content === 'tag' ||
        content === 'tall_banner' ||
        content === 'mixed' ||
        content === 'link' ||
        content === 'tag' ||
        content === 'link' ||
        content === 'tall_banner' ||
        content === 'rating')
    ) {
      return true;
    }

    if (disp === 'rail' && content === 'mixed') {
      return true;
    }

    if (previousScreen === SCREEN_CONSTANTS.COLLECTION) {
      return true;
    }
    // if (
    //   disp === 'horizontalPlaylist' &&
    //   (previousScreen === SCREEN_CONSTANTS.ARTIST ||
    //     previousScreen === SCREEN_CONSTANTS.PRODUCT_DETAIL)
    // ) {
    //   return true;
    // }
    return false;
  }

  static shouldShowVerticalSpace(display, content, previousScreen = '') {
    return true;
    const disp = _.camelCase(display);
    if (
      (disp === 'grid' ||
        disp === 'list' ||
        disp === 'rail' ||
        disp === 'verticalRail' ||
        disp === 'personalizedGrid' ||
        disp === 'horizontalPlaylist' ||
        disp === 'verticalPlaylist' ||
        disp === 'tabbed' ||
        disp === 'matchedProduct' ||
        disp === 'gridRail' ||
        disp === 'rectangularGrid' ||
        disp === 'section' ||
        disp === 'pager') &&
      (content === 'product' ||
        content === 'artist' ||
        content === 'media' ||
        content === 'brand' ||
        content === 'salon' ||
        content === 'list' ||
        content === 'media_category' ||
        content === 'offer' ||
        content === 'sku' ||
        content === 'product_category' ||
        content === 'quick_filters' ||
        content === 'banner' ||
        content === 'feature')
    ) {
      return true;
    }
    if (previousScreen === SCREEN_CONSTANTS.COLLECTION) {
      return true;
    }
    // if (
    //   disp === 'horizontalPlaylist' &&
    //   (previousScreen === SCREEN_CONSTANTS.ARTIST ||
    //     previousScreen === SCREEN_CONSTANTS.PRODUCT_DETAIL)
    // ) {
    //   return true;
    // }
    return false;
  }

  static getAnalyticsPercentage(initialCount, latestCount) {
    const newNum = latestCount - initialCount;
    percentage = Math.round((newNum / initialCount) * 100);
    if (!Number.isFinite(percentage)) {
      return { number: 0, state: 'none' };
    }
    if (percentage < 0) {
      return { number: percentage, state: 'down' };
    }
    return { number: percentage, state: 'up' };
  }

  static compareArray = (array1, array2) =>
    array1.length === array2.length &&
    array1.every((value, index) => value === array2[index]);

  static isItemsEqual(item1, item2) {
    if (item1 === item2) {
      return true;
    }
    if (Utility.isBlank(item1) && Utility.isBlank(item2)) {
      return true;
    }
    if (Utility.isBlank(item1) && item2 === '') {
      return true;
    }
    if (item1 === '' && Utility.isBlank(item2)) {
      return true;
    }
    return false;
  }

  static convertOfferingsArrayToObject(offeringsArray) {
    let offeringsObject = {};
    if (offeringsArray.length > 0) {
      offeringsArray.map((item) => {
        offeringsObject = { ...offeringsObject, [item.service_id]: item };
      });
    }
    return offeringsObject;
  }

  static convertTrainingArray(trainingArray) {
    let convertedTrainingArray = [];
    if (trainingArray.length > 0) {
      trainingArray.forEach((element) => {
        convertedTrainingArray = [
          ...convertedTrainingArray,
          element.service_id,
        ];
      });
    }
    return convertedTrainingArray;
  }

  static convertArrayToObject(objectArray) {
    let objectToReturn = {};
    if (objectArray.length > 0) {
      objectArray.map((item) => {
        objectToReturn = { ...objectToReturn, [item.id]: item };
      });
    }
    return objectToReturn;
  }

  static getDateOfDaysAGO(daysAgo) {
    const endDate = new Date().toISOString().slice(0, 10); // today's date
    let startDate = new Date(endDate);
    startDate.setDate(startDate.getDate() - daysAgo).toString();
    startDate = new Date(startDate).toISOString().slice(0, 10);

    return { startDate, endDate };
  }

  static formatDate(date) {
    const monthArr = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sept',
      'Oct',
      'Nov',
      'Dec',
    ];
    const dateArr = date.split('-');
    return `${dateArr[2]}-${monthArr[parseInt(dateArr[1])]}`;
  }

  static formatDateWithYear(dateString) {
    let date = new Date(dateString);
    const day = date.getDate();
    const month = parseInt(date.getMonth()) + 1;
    const year = date.getFullYear();
    return day + '/' + month + '/' + year;
  }

  static getYoutubeAnalyticsFormattedStats(
    response,
    currentYoutubeSubscribers,
  ) {
    const statData = response.rows;
    let labels = [];
    const dataset_views = [];
    const dataset_subscribers = [];
    const points = [];
    const offset = 6;
    let baseNumber = currentYoutubeSubscribers;
    statData.forEach((element, index) => {
      labels.push(element[0]);

      dataset_views.push(element[1]);

      baseNumber -= element[2];
      baseNumber += element[3];
    });
    statData.forEach((element, index) => {
      baseNumber += element[2];
      baseNumber -= element[3];

      dataset_subscribers.push(baseNumber);
    });

    labels = labels.map((element, index) => {
      if (index % offset === 0) {
        return Utility.formatDate(element);
      }
    });

    return {
      labels,
      dataset_views,
      dataset_subscribers,
      points: [],
    };
  }

  static getButtonWidth() {
    return 312;
  }

  static getButtonHeight() {
    return 46;
  }

  static filterMySelectedInterest = (selectedInterests, interestList) => {
    const filteredList = [];
    for (let index = 0; index < interestList.length; index++) {
      for (let i = 0; i < selectedInterests.length; i++) {
        if (interestList[index].id === selectedInterests[i]) {
          filteredList.push(interestList[index]);
        }
      }
    }
    return filteredList;
  };

  static getCurrentElementAttributes = (allAttributes, elementName) => {
    let attributes = [];
    const genderId = Utility.genderFromSelfie || [];
    const currentGender = Utility.getGenderUsingId(genderId?.[0]);
    attributes = allAttributes.filter((element) => {
      const elementNameCondition =
        Utility.isBlank(elementName) || element.name === elementName;
      const genderCondition =
        Utility.isBlank(currentGender) ||
        element.gender === currentGender ||
        element.gender === 'unisex';
      return elementNameCondition && genderCondition;
    });
    return attributes;
  };

  static getAllowedValueIdsForAttributes = (attributes) => {
    let attributeValueIds = [];
    attributes.map((attribute) => {
      attribute?.allowed_values.filter((allowed_value) => {
        attributeValueIds.push(allowed_value?.id);
        return;
      });
    });
    return attributeValueIds;
  };

  static addKeyToArrayElemetns = (arrElement) => {
    const arr = [];

    for (let index = 0; index < arrElement.length; index++) {
      arr.push({ hex_code: null, icon: null, name: arrElement[index] });
    }
    return arr;
  };

  static getIndexByElementName = (facePoints, selectedFacePoint) => {
    let position = 0;

    for (let index = 0; index < facePoints.length; index++) {
      if (facePoints[index].name === selectedFacePoint) {
        position = index;
        break;
      }
    }
    return position;
  };

  static openWhatsappWithCustomNumber = (message, number = '') => {
    const contactNumber = number.replace(/[- ]/g, '');
    const text = encodeURIComponent(message);
    if (isWeb()) {
      Linking.openURL(`https://wa.me//${contactNumber}?text=${text}`);
      return;
    }
    const deeplink = `whatsapp://send?text=${text}&phone=${contactNumber}`;
    Linking.openURL(deeplink);
  };

  static openWhatsApp = (message, onError = () => {}, phoneNumber) => {
    if (isWeb()) {
      Linking.openURL(`https://wa.me//${phoneNumber || WHATS_APP_NUMBER}?text=${message}`);
      return;
    }
    const deeplink = `whatsapp://send?text=${message || ''}&phone=${
      phoneNumber ?? WHATS_APP_NUMBER
    }`;
    Linking.canOpenURL(deeplink)
      .then((supported) => {
        if (!supported) {
          onError(true);
        } else {
          Linking.openURL(deeplink);
        }
      })
      .catch((err) => {
        AnalyticsManager.logEvent(
          EventType.appLifeCycleEvents.EXCEPTION_CAPTURED,
          {
            [EventParameterKey.SOURCE]: `Failed to detect whatsapp url: ${deeplink}`,
          },
        );
      });
  };

  static openWhatsAppWithNumber = (url) => {
    const data = url.split('?')[1];
    const deeplink = `whatsapp://send?${data}`;
    Linking.openURL(deeplink);
  };

  static getEmptyYoutubeProfileObject() {
    return {
      id: '',
      server_auth_code: '',
      profile_pic: '',
      idToken: '',
      google_id: '',
      email: '',
      user_name: '',
      accessTokenExpirationDate: '',
      tokenType: '',
      refreshToken: '',
      accessToken: '',
      youtube_channel_id: '',
      title: '',
      initial_subscriber_count: 0,
      subscriber_count: 0,
      video_count: 0,
      initial_view_count: 0,
      view_count: 0,
      boost_requested: false,
      expert_review_requested: false,
    };
  }

  static setInitialRoute(current_guest_state = '') {
    if (Utility.getCurrentAppType() === AVAILABLE_BUILD_TYPES.foxy) {
      if (
        Utility.isBlank(current_guest_state) ||
        current_guest_state === CURRENT_GUEST_STATE.INITIAL
      ) {
        return 'AppIntro';
      }
      if (
        current_guest_state === CURRENT_GUEST_STATE.FULLY_REGISTERED ||
        current_guest_state === CURRENT_GUEST_STATE.SKIPPED
      ) {
        return 'TabNavigator';
      }
    } else {
      return 'MyProfile';
    }
  }

  static getAddressArray(object) {
    let addresses = [];
    for (const key in object) {
      addresses.unshift(object[key]);
    }
    if (addresses.length > 4) {
      addresses = addresses.splice(0, 4);
    }

    return addresses;
  }

  static isValidHex(hex) {
    return /^#[0-9A-F]{6}$/i.test(hex);
  }

  static hexToRgb(hex) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16) / 255,
          g: parseInt(result[2], 16) / 255,
          b: parseInt(result[3], 16) / 255,
        }
      : null;
  }

  static getShipmentStatus = (currentStatus) => {
    switch (currentStatus) {
      case 'awaiting_confirmation':
        return 0;
        break;
      case 'confirmed':
        return 0.25;
        break;
      case 'created':
        return 0.5;
        break;
      case 'picked':
        return 0.75;
        break;
      case 'packed':
        return 1.25;
        break;
      case 'ready_to_ship':
        return 1.5;
        break;
      case 'shipped':
        return 2.25;
        break;
      case 'in_transit':
        return 2.5;
        break;
      case 'received_at_hub':
        return 2.75;
        break;
      case 'lost_in_transit':
        return 2.5;
        break;
      case 'out_for_delivery':
      case 'out_for_delivery_for_first_attempt':
      case 'out_for_delivery_for_second_attempt':
      case 'out_for_delivery_for_third_attempt':
      case 'first_attempt_failed':
      case 'out_for_delivery_for_second_attempt':
      case 'second_attempt_failed':
      case 'third_attempt_failed':
        return 2.6;
        break;
      case 'rto':
      case 'rto_received':
      case 'rto_failed':
      case 'return_requested':
      case 'return_request_cancelled':
      case 'return_completed':
      case 'returned_to_origin':
      case 'returning_to_origin':
      case 'return_processing':
        return 2.3;
        break;
      case 'delivered':
        return 3;
        break;
      default:
        return 0;
        break;
    }
  };

  static getOrderStatusText(number) {
    const status = [
      'Confirmed',
      'Shipped',
      'Out for delivery',
      'Delivered',
      'Cancelled',
      'Confirmed',
    ];
    return status[number];
  }

  static phoneNumberFormatter(phoneNumber) {
    if (!phoneNumber) return getPhoneErrorResponse('');
    const phoneUtil = PhoneNumberUtil.getInstance();

    if (isPhoneStartsWith(phoneNumber, '0')) {
      phoneNumber = phoneNumber.replace('0', '');
    }
    if (isNotPhoneStartsWith(phoneNumber, '+')) {
      if (phoneNumber.length !== AppConfig.phoneNumberLimit)
        return getPhoneErrorResponse(phoneNumber);
      return getPhoneSuccessResponse(`${COUNTRY_CODE}${phoneNumber}`);
    }
    if (isPhoneStartsWith(phoneNumber, COUNTRY_CODE)) {
      if (
        phoneNumber.length !==
        AppConfig.phoneNumberLimit + (COUNTRY_CODE?.length || 3)
      )
        return getPhoneErrorResponse(phoneNumber);
      return getPhoneSuccessResponse(phoneNumber);
    }
    try {
      const isValidPhone = phoneUtil.isValidNumber(
        phoneUtil.parse(phoneNumber),
      );
      if (!isValidPhone) return getPhoneErrorResponse(phoneNumber);
    } catch (err) {
      // invalid country code error
      return getPhoneErrorResponse(phoneNumber);
    }
    return getPhoneSuccessResponse(phoneNumber);
  }

  static enableOrderCancel(aasm_status) {
    const status = [
      'picked',
      'packed',
      'ready_to_ship',
      'shipped',
      'in_transit',
      'received_at_hub',
      'out_for_delivery_for_first_attempt',
      'out_for_delivery',
      'first_attempt_failed',
      'out_for_delivery_for_second_attempt',
      'second_attempt_failed',
      'out_for_delivery_for_third_attempt',
      'delivered',
      'closed',
    ];

    return !status.includes(aasm_status);
  }

  static returnOrder(aasm_state, deliveryDate) {
    const status = ['delivered', 'closed'];
    if (status.includes(aasm_state)) {
      const getDeliveryDate = new Date(deliveryDate);
      const currentDate = new Date();
      const timeDiff = Math.abs(
        currentDate.getTime() - getDeliveryDate.getTime(),
      );
      const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24)) - 1;
      if (diffDays < 7) {
        return { type: 'return' };
      }
      return { type: 'reorder' };
    }
    return { type: 'none' };
  }

  static isInstagramAutoSelected() {
    return isInstagramAutoSelected;
  }

  static setInstagramAutoSelected(isAutoSelected) {
    isInstagramAutoSelected = isAutoSelected;
  }

  static objectToArrayConverter(object) {
    return Object.keys(object).map((key) => object[key]);
  }

  static objectToArrayConverterKeys(object) {
    return Object.keys(object).map((key) => key);
  }

  static objectToArrayConverterKeysWithFilteredKeys(filterKeys, object) {
    return Object.keys(object)
      .map((key) => key)
      .filter((value) => filterKeys.includes(value))
      .map((key) => object[key]);
  }

  static differenceBetweenDate(lastCacheSavedDate) {
    const currentDate = new Date().toISOString();

    const lastDate = new Date(lastCacheSavedDate).getTime();
    return Math.abs(lastDate - new Date(currentDate).getTime()) / 3600000;
  }

  static addPreviousIndexValues(arr, currentIndex) {
    //This method is used to find the actual position of an item on paginated list when each page is rendered as a different FlatList.
    if (Utility.isBlank(arr)) {
      return 0;
    }

    let count = 0;
    if (currentIndex === 0 || arr.length === 0) {
      return 0;
    }
    for (let index = currentIndex - 1; index >= 0; index--) {
      count += arr[index];
    }
    return count;
  }

  static validateQRCode = (code) => {
    const re = /^[a-zA-Z0-9]+$/;
    return re.test(String(code));
  };

  static validateEmail = (email) => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  static validatePhoneNum = (number) => {
    console.tron.log('initial_num', number);
    let phoneNum = number.toString();
    phoneNum = phoneNum?.replace(COUNTRY_CODE, '');
    if (phoneNum?.charAt(0) === '0') {
      phoneNum = phoneNum?.replace('0', '');
    }
    console.tron.log('final_num', phoneNum);
    return phoneNum;
  };

  static insertCharAtPosition(index, string, stringToAdd) {
    return (
      string.substring(0, index) +
      stringToAdd +
      string.substring(index, string.length)
    );
  }

  static remoteHairColorAttributes = null;

  static remoteHairLengthAttribute = null;

  // static remoteHairTypeAttribute = null;

  static getTimeDifferenceBetweenFirstAppOpen(firstOpenTime) {
    const firstOpenDate = new Date(firstOpenTime);
    const currentDate = new Date();
    const timeDiff = Math.abs(currentDate.getTime() - firstOpenDate.getTime());
    const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24)) - 1;

    return diffDays;
  }

  static getInstalledApps(appPackages) {
    const appCodes = [];
    appPackages.forEach((element) => {
      if (COMPETITOR_APP[element.packageName]) {
        appCodes.push(COMPETITOR_APP[element.packageName]);
      }
    });
    AnalyticsManager.logEvent(EventType.appLifeCycleEvents.app_detect, {
      [EventParameterKey.LIST]: appCodes.join(','),
    });
  }

  static getInstalledUpiApps(appPackages, upiApps) {
    const appCodes = [];
    if (Utility.isBlank(upiApps)) {
      return null;
    }
    const jsonObject = Utility.jsonParser(upiApps);
    appPackages.forEach((element) => {
      if (jsonObject[element.packageName]) {
        appCodes.push(jsonObject[element.packageName]);
      }
    });
    AnalyticsManager.logEvent(EventType.appLifeCycleEvents.app_detect, {
      [EventParameterKey.LIST]: appCodes,
      source: 'payment_page',
    });
    return appCodes;
  }

  static convertMarkerTitleIntoType(title) {
    const type = title.toLowerCase().replace(' ', '_');
    return type;
  }

  static getValueByKey(array, key) {
    return _.find(array, { name: key });
  }

  static extractMappedTag = (tagName, value, currentGender = '') => {
    const tag = Utility.isPresent(currentGender)
      ? `${tagName}_${currentGender?.toUpperCase()}`
      : tagName;

    return BETAFACE_TAGS_MAPPING[tag] && BETAFACE_TAGS_MAPPING[tag][value]
      ? BETAFACE_TAGS_MAPPING[tag][value]
      : _.upperFirst(value);
  };

  static getSelectedTags = (properties) => {
    const tags = properties.filter((element) => element.values.length > 0);
    return tags;
  };

  static elementExists = (array, tag) => _.find(array, { title: tag });

  static compareConfidence = (
    checkForLeast,
    item_one_name,
    item_one_confidence,
    item_two_name,
    item_two_confidence,
  ) => {
    if (checkForLeast) {
      return item_one_confidence < item_two_confidence
        ? _.upperFirst(item_one_name.split(' ')[0])
        : _.upperFirst(item_two_name.split(' ')[0]);
    }
    return item_one_confidence > item_two_confidence
      ? _.upperFirst(item_one_name.split(' ')[0])
      : _.upperFirst(item_two_name.split(' ')[0]);
  };

  static compareHairColorConfidence = (array) => {
    if (array.length < 1) return [];
    const max = array.reduce((prev, current) =>
      prev.confidence > current.confidence ? prev : current,
    );
    return max;
  };

  static extractTitleFromText = (text, splitBy = '') => {
    let title = text.split(splitBy);
    if (title.length < 1) {
      return _.upperFirst(title);
    } else {
      let mappedTitle = '';
      title.forEach((element) => {
        return (mappedTitle = mappedTitle + ' ' + _.upperFirst(element));
      });

      return mappedTitle;
    }
  };

  static isSelfie(imageType) {
    return imageType === 'selfie';
  }

  static shouldShowSurprise = (
    surpriseAvailable = false,
    surpriseLastTakenAt,
  ) => {
    if (surpriseAvailable) {
      if (Utility.isBlank(surpriseLastTakenAt)) {
        return true;
      }
      const currentTimeStamp = Date.now() / 1000;
      const secondsElapseSinceLastSurprise =
        currentTimeStamp - surpriseLastTakenAt;
      const daysElapsed = Math.floor(secondsElapseSinceLastSurprise / 86400);
      if (daysElapsed >= 7) {
        //todo: change this to 7
        return true;
      }
      return false;
    }
    return false;
  };

  static getAppVersion() {
    return VersionCheck.getCurrentVersion() || '';
  }

  static getTimeDiff(eventOccureTime, pageLoadedAt, diffInSec = false) {
    if (diffInSec) {
      return (
        Math.abs(eventOccureTime.getTime() - pageLoadedAt.getTime()) / 1000
      );
    }
    return Math.abs(eventOccureTime.getTime() - pageLoadedAt.getTime());
  }

  static rotateArray(arr, count) {
    const arrayCopy = arr.slice(0);
    const reducedCount =
      count - arrayCopy.length * Math.floor(count / arrayCopy.length);
    return [
      ...arrayCopy.splice(reducedCount),
      ...arrayCopy.splice(0, reducedCount),
    ];
  }

  static getTimeDiffInMs(first, second) {
    if (
      JSON.stringify(new Date(first)) === 'null' ||
      JSON.stringify(new Date(second)) === 'null'
    ) {
      return 'error';
    }
    return new Date(first) - new Date(second);
  }

  static softCheckCardEquality = (cardInRedux, cardInPayu) => {
    if (cardInRedux.length === 0 || cardInPayu.length === 0) {
      return false;
    }
    if (
      cardInRedux.substring(0, 6) === cardInPayu.substring(0, 6) &&
      cardInRedux.substring(cardInRedux.length - 4) ===
        cardInPayu.substring(cardInPayu.length - 4)
    ) {
      return true;
    }
    return false;
  };

  static setRecordTimeEventMeta = (itemData = {}, type = '') => {
    if (itemData) {
      return { id: itemData.id, name: itemData.name, type: type };
    }
    return {};
  };

  static getRandom = (min, max) => {
    const floatRandom = Math.random();

    const difference = max - min;

    // random between 0 and the difference
    const random = Math.round(difference * floatRandom);

    const randomWithinRange = random + min;

    return randomWithinRange;
  };

  static getRandomDate = () => {
    const year = Utility.getRandom(1998, 2005);
    const monthNum = Utility.getRandom(1, 12);
    const month = monthNum < 10 ? `0${monthNum}` : monthNum;
    const dayNum = Utility.getRandom(1, 28);
    const day = dayNum < 10 ? `0${dayNum}` : dayNum;
    return `${year}-${month}-${day}`;
  };

  static currentProfilePicToShow(
    selfieImage,
    instagram_profile,
    profilePic,
    emptyStateImage = null,
  ) {
    let imageSource = '';
    if (Utility.isPresent(profilePic)) {
      imageSource = profilePic.startsWith('https://instagram')
        ? { uri: instagram_profile.insta_profile_picture_url }
        : { uri: Utility.getMinifiedImage(profilePic) };
    } else if (Utility.isPresent(instagram_profile.insta_profile_picture_url)) {
      imageSource = { uri: instagram_profile.insta_profile_picture_url };
    } else if (Utility.isPresent(selfieImage)) {
      imageSource = { uri: Utility.getMinifiedImage(selfieImage) };
    } else {
      imageSource = Utility.isPresent(emptyStateImage)
        ? emptyStateImage
        : images.profileEmptyState;
    }

    return imageSource;
  }

  static currentProfilePicToShowString(
    selfieImage,
    instagram_profile,
    profilePic,
    size,
  ) {
    if (size === 'large') {
      return Utility.getMinifiedImage(selfieImage);
    }
    let imageSource = '';
    if (Utility.isPresent(profilePic)) {
      imageSource = profilePic.startsWith('https://instagram')
        ? instagram_profile.insta_profile_picture_url
        : Utility.getMinifiedImage(profilePic);
    } else if (Utility.isPresent(instagram_profile.insta_profile_picture_url)) {
      imageSource = instagram_profile.insta_profile_picture_url;
    } else if (Utility.isPresent(selfieImage)) {
      imageSource = Utility.getMinifiedImage(selfieImage);
    } else {
      imageSource = null;
    }

    return imageSource;
  }

  static deriveIndexFromListObject(array, mediaId) {
    let selectVideoIndex = 0;
    if (Utility.isPresent(array) && Utility.isPresent(mediaId)) {
      array.forEach((mediaElement, index) => {
        if (mediaElement.id === mediaId) {
          selectVideoIndex = index;
        }
      });
    }

    return selectVideoIndex;
  }

  static removeDashFromText = (text) => {
    let splitText = text.split('-');
    if (splitText.length > 1) {
      return splitText[1];
    }
    return text;
  };

  static isDeviceGreaterThanNougat() {
    return Platform.Version > ANDROID.BUILD.VERSIONS.NOUGAT;
  }

  static isGenderAttributeExists = (my_attributes_values) => {
    if (Utility.isBlank(my_attributes_values)) return false;

    let genderExists = false;
    my_attributes_values.forEach((attribute) => {
      if (attribute.user_attribute_id === 28) {
        genderExists = true;
        return genderExists;
      }
    });

    return genderExists;
  };

  static getGender = (my_attributes_values) => {
    if (Utility.isBlank(my_attributes_values)) return false;

    let gender;
    my_attributes_values.forEach((attribute) => {
      if (attribute.user_attribute_id === 28) {
        gender = attribute.new_values;
        return gender;
      }
    });

    return gender;
  };

  static getWebpImages = (images) => {
    return images.map((image) => `${image}?format=webp`);
  };

  static generateRandomString = (length, chars) => {
    let mask = '';
    if (chars.indexOf('a') > -1) mask += 'abcdefghijklmnopqrstuvwxyz';
    if (chars.indexOf('A') > -1) mask += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    if (chars.indexOf('#') > -1) mask += '0123456789';
    if (chars.indexOf('!') > -1) mask += '~`!@#$%^&*()_+-={}[]:";\'<>?,./|\\';
    let result = '';
    for (let i = length; i > 0; --i) {
      result += mask[Math.floor(Math.random() * mask.length)];
    }
    return result;
  };

  static invokeSelfieFlowAfterLoginForGuest(
    invokeSelfiePrompt = true,
    haltOffersLanding = true,
    autoFetchUserAttributes = true,
  ) {
    Utility.invokeSelfiePromptAfterLogin = invokeSelfiePrompt;
    Utility.haltOffersLandingFlow = haltOffersLanding;
    Utility.fetchUserAttributesForGuestUserFlowFromHintOrTruecaller =
      autoFetchUserAttributes;
  }

  static calculateUACDeviceScore(uac_devices, currentDeviceInfo) {
    const { model, manufacturer } = currentDeviceInfo;
    let matchFound = false;
    let finalScore = 0;
    uac_devices.forEach((device) => {
      if (device.manufacturer.toLowerCase() === manufacturer.toLowerCase()) {
        if (Utility.isBlank(device.model)) {
          finalScore = device.score;
          matchFound = true;
        } else if (device.model.toLowerCase() === model.toLowerCase()) {
          console.tron.log('Matched');
          finalScore = device.score;
          matchFound = true;
        }
        if (matchFound) {
          return finalScore;
        }
      }

      return 0;
    });

    return finalScore;
  }

  static calculateAppInfoScore(uac_apps, currentApps) {
    let isKeyFound = false;
    let score = 0;

    for (let key in uac_apps) {
      currentApps.forEach((app) => {
        if (key === app.packageName) {
          isKeyFound = true;
          score = uac_apps[key];

          return score;
        }
      });

      if (isKeyFound) {
        return score;
      }
    }

    return score;
  }

  static calculateBeautyAppInfoScore(uac_apps, currentApps) {
    let isKeyFound = false;
    let score = 0;

    for (let key in uac_apps) {
      currentApps.forEach((app) => {
        if (key === app.packageName) {
          isKeyFound = true;
          score = uac_apps[key];

          return score;
        }
      });

      if (isKeyFound) {
        return score;
      }
    }

    return score;
  }

  static calculateCommerceInfoScore(uac_apps, currentApps) {
    const uacApps = Utility.objectToArrayConverterKeys(uac_apps);

    const currentAppsObject = {};

    currentApps.forEach((app, index) => {
      currentAppsObject[`${index}`] = app.packageName;
    });

    if (
      uacApps.every((key) => Object.values(currentAppsObject).includes(key))
    ) {
      return 2;
    }
    if (uacApps.some((key) => Object.values(currentAppsObject).includes(key))) {
      return 1;
    }

    return 0;
  }

  static hideCartButton(campaignStatus) {
    let status = [
      'Registration closed',
      'Campaign full',
      'Cancel registration',
      'Paid',
      'Cash on way',
      'Claim cash',
      'Share now',
      'Closed',
    ];
    if (status.includes(campaignStatus)) {
      return true;
    }
    return false;
  }

  static canProceedForNotificationRequest(
    lastNotificationModalDisplayTime,
    initialAppOpenedAt,
    source = '',
  ) {
    const remoteRequestData = Utility.jsonParser(
      RemoteConfig.getValue(REMOTE_CONFIG_KEYS.notification_prompt_request_v2),
    );

    const lastDisplayTime = this.isBlank(lastNotificationModalDisplayTime)
      ? initialAppOpenedAt
      : lastNotificationModalDisplayTime;
    const currentDate = new Date();
    const timeDiff = Math.abs(currentDate.getTime() - lastDisplayTime);
    const diffMins = Math.ceil(timeDiff / (1000 * 60 * 1)) - 1;

    //In case notification prompt has to be shown on first time app open. The duration for such a case is taken as 100 days.
    if (remoteRequestData['oneTimeShowScreens']?.includes(source)) {
      if (diffMins > 144000) {
        return true;
      }
      return false;
    }

    if (
      Utility.isBlank(lastNotificationModalDisplayTime) &&
      diffMins >= remoteRequestData['initialDisplayDuration']
    ) {
      return true;
    } else if (diffMins >= remoteRequestData['displayDurationInMins']) {
      return true;
    } else {
      return false;
    }
  }

  static showNotificationModalFromSource(source = '') {
    const remoteRequestData = Utility.jsonParser(
      RemoteConfig.getValue(REMOTE_CONFIG_KEYS.notification_prompt_request_v2),
    );

    //In case notification prompt has to be shown on first time app open. The duration for such a case is taken as 100 days.
    if (remoteRequestData['showScreen']?.includes(source)) {
      return true;
    }
    return false;
  }

  static canDisplayNotificationRequestModal(
    lastDisplayTime,
    source,
    callback,
    initialAppOpenedAt = undefined,
    isEventBased = false,
  ) {
    //preventing opening modal other places except Feed and store.
    if (!initialAppOpenedAt) {
      callback(false, false);
      return;
    }

    if (Utility.skipPageLevelNotificationModal) {
      return;
    }

    if (!this.showNotificationModalFromSource(source)) {
      callback(false, false);
      return;
    }

    if (
      !isEventBased &&
      !this.canProceedForNotificationRequest(
        lastDisplayTime,
        initialAppOpenedAt,
        source,
      )
    ) {
      callback(false, false);
      return;
    }

    if (this.isAndroid()) {
      this.areNotificationsEnabled(callback);
      return;
    }

    this.getNotificationPermission(source, (permission) => {
      if (
        permission === 'NotificationNotDetermined' ||
        permission === 'NotificationProvisional'
      ) {
        callback(true, true);
      } else if (permission !== 'NotificationAuthorized') {
        callback(true, false);
      } else {
        callback(false, false);
      }
    });
  }

  static openSettings() {
    Linking.openSettings();
  }

  static convertSelectedFiltersObjectIntoArray = (selectedFilters) => {
    let array = [];
    for (let key in selectedFilters) {
      array = [...array, ...selectedFilters[key]];
    }
    return _.uniqBy(array, (e) => e.value);
  };

  static indexOfItem = (filters, value) => {
    return _.findIndex(filters, (index) => index?.value === value);
  };

  static getIndexOfFoxyMatch = (filters = EMPTY_ARRAY) => {
    if (isBlank(filters)) return -1;
    return _.findIndex(
      filters,
      (index) =>
        index?.criteria_name === 'user_attribute_allowed_value_id' ||
        index?.criteria_name === 'uaavs',
    );
  };

  static indexOfBoolean = (filter, value) => {
    if (this.indexOfItem(filter, value) == -1) return false;

    return true;
  };

  static getNetworkInfo(network = {}) {
    if (isBlank(network)) return;
    const { type = '' } = network;

    return {
      [EventParameterKey.NETWORK_TYPE_INFO]: Utility.isPresent(type)
        ? type.toString()
        : 'empty',
    };
  }

  static getCommonUserAttributes = (currentAttributes, filteredCriteria) => {
    let commonFacialAttributes = [];
    const selfieAttributes = Utility.isPresent(currentAttributes)
      ? ImageTagsProcessingUtil.getAllowedTags(
          Utility.getSelectedTags(currentAttributes),
        )
      : [];
    // console.tron.log("Usable Facial Attributes ", selfieAttributes);
    selfieAttributes.forEach((attribute) => {
      const intersection = attribute.values.filter((element = {}) =>
        this.indexOfBoolean(filteredCriteria, element?.id),
      );
      let attrib = attribute;
      if (this.isPresent(intersection)) {
        attrib.values = intersection;

        commonFacialAttributes.push(attrib);
      }
    });
    return commonFacialAttributes;
  };

  static updateRatingsUrl(url, filterRatings) {
    let ratingSlug = '';
    _.forEach(filterRatings, (rating) => {
      ratingSlug += `&star[]=${rating}`;
    });
    return url + ratingSlug;
  }

  static getSkuIdForProduct(productData = {}, selectedVariant, orderItemSku) {
    let { sku_id, variant_attributes = [] } = productData;
    let variants;

    if (Utility.isPresent(orderItemSku)) {
      return orderItemSku;
    }

    if (Utility.isPresent(variant_attributes)) {
      variants = variant_attributes[0];
    }

    if (Utility.isBlank(variants)) {
      variants = {};
    }

    const { allowed_values = [] } = variants;

    if (Utility.isPresent(allowed_values)) {
      let { sku_id: firstVariantSkuId } = allowed_values[0];

      sku_id = firstVariantSkuId;
    }

    if (Utility.isPresent(selectedVariant)) {
      const { sku_id: selectedVariantSkuId } = selectedVariant;
      sku_id = selectedVariantSkuId;
    }
    return sku_id;
  }

  static getVariantData(skuId, variants) {
    const { allowed_values = [], type } = variants;

    let currentVariant = _.filter(allowed_values, (variant) => {
      console.tron.log(skuId, variant.sku_id, 'bbaa');
      return variant.sku_id === skuId;
    });

    if (type !== 'image') {
      return {};
    }
    return {
      name: currentVariant[0].display_name,
      image: currentVariant[0].image_url,
    };
  }

  static returnNumberOfAttributeColumns(arraySize = 0) {
    const values = Utility.jsonParser(
      RemoteConfig.getValue(REMOTE_CONFIG_KEYS.user_attributes_column_display),
    );
    let returnColumSize = 2;
    if (arraySize <= 2) {
      returnColumSize = values.two_attributes;
    } else if (arraySize <= 3) {
      returnColumSize = values.three_attributes;
    } else if (arraySize <= 4) {
      returnColumSize = values.four_attributes;
    } else {
      returnColumSize = values.five_or_more_attributes;
    }
    return returnColumSize;
  }

  static getTimeOutValue() {
    const apiTimeOutValueInMilliSeconds = RemoteConfig.getValue(
      REMOTE_CONFIG_KEYS.api_time_out_in_ms,
    );
    return apiTimeOutValueInMilliSeconds;
  }

  static returnObjectForListEndSearch() {
    return [
      {
        type: 'bottomSearchContainer',
      },
    ];
  }

  static insertItemAtPosition = (arr, index, item) => {
    arr.splice(index, 0, item);
  };

  static createFilterObjectIfExists = (
    list,
    search,
    filtersEnabled,
    productAndBrandsOnlySwitchValue,
  ) => {
    if (!search) return { list, position: -1 };
    const newList = [...list];
    let listName = '';
    let quickFilters = [];
    let filtersList = [];
    let listIndex = -1;
    let listSlug = '';

    if (_.findIndex(newList, (item) => item.type === 'quick_filters') !== -1) {
      return { list: newList, position: -1 };
    }

    if (!productAndBrandsOnlySwitchValue) {
      return { list: newList, position: -1 };
    }

    newList.forEach((element, index) => {
      if (
        (element.content === 'product' || element.content === 'sku') &&
        Utility.isPresent(element.quick_filters)
      ) {
        listIndex = index;
        listName = element.name;
        if (element.display !== 'rail') {
          quickFilters = element.quick_filters;
          filtersList = element.filters;
        }
        listSlug = element.slug;
        return;
      }
    });

    if (listIndex === -1) {
      return { list: newList, position: -1 };
    }

    const slug = newList[listIndex].slug;
    const HeaderObject = {
      type: 'quick_filters_header',
      name: `${listName}`,
      display: 'list',
      size: 'defaultSize',
      slug: slug,
      display_count: 1,
      content: 'quick_filters',
      objects: [1, 2, 3],
    };

    Utility.insertItemAtPosition(newList, listIndex, HeaderObject);
    if (this.isPresent(quickFilters)) {
      const filterObject = {
        type: 'quick_filters',
        name: ``,
        display: 'list',
        size: 'defaultSize',
        id: -3,
        slug: slug,
        display_count: 1,
        content: 'quick_filters_header',
        quick_filters: quickFilters,
        filters: filtersList,
        hideHeader: true,
        objects: [1, 2, 3],
      };
      Utility.insertItemAtPosition(newList, listIndex + 1, filterObject);
    }
    return { list: newList, position: listIndex + 2, slug: listSlug };
  };

  static getIndexOfProductList = (objects) => {
    return findIndex(
      objects,
      (e) => allowedContentTypes(e.content) && isPresent(e.quick_filters),
    );
  };

  static getIndexOfEmptyState = (objects) => {
    return _.findIndex(objects, (e) => e.content === 'filters_empty_state');
  };

  static getIndexOfShimmer = (objects) => {
    return _.findIndex(objects, (e) => e.content === 'shimmer');
  };

  static addShimmerInListObject = (list) => {
    const shimmerObject = {
      type: 'shimmer',
      name: ``,
      display: 'shimmer',
      size: 'defaultSize',
      id: -2,
      display_count: 1,
      content: 'shimmer',
      quick_filters: [],
      filters: [],
      hideHeader: true,
      objects: [1, 2, 3],
    };

    const listClone = { ...list };
    const { objects } = listClone;
    let indexOfProduct = this.getIndexOfProductList(objects);

    // if product list not available then i will be either empty state or ongoing shimmer
    if (indexOfProduct === -1) {
      indexOfProduct = this.getIndexOfEmptyState(objects);
    }

    if (indexOfProduct === -1) {
      indexOfProduct = this.getIndexOfShimmer(objects);
    }

    Utility.insertItemAtPosition(objects, indexOfProduct, shimmerObject);
    objects.splice(indexOfProduct + 1, 1);
    return [...objects];
  };

  static addFiltersEmptyStateInListObject = (objects) => {
    const indexOfProduct = objects.length;
    const slug = objects[indexOfProduct - 1].slug;
    const filtersEmptyState = {
      type: 'filters_empty_state',
      name: ``,
      display: 'list',
      id: -1,
      size: 'defaultSize',
      display_count: 1,
      content: 'filters_empty_state',
      quick_filters: [],
      filters: [],
      slug: slug,
      hideHeader: true,
      objects: [1, 2, 3],
    };

    Utility.insertItemAtPosition(objects, indexOfProduct, filtersEmptyState);
    objects.splice(indexOfProduct + 1, 1);
    return [...objects];
  };

  static removeShimmerAndEmptyStateFromListObject = (list) => {
    const { objects } = list;
    return _.filter(
      objects,
      (element) =>
        element.type !== 'shimmer' && element.type !== 'filters_empty_state',
    );
  };

  static extractSelectedFiltersFromDeeplink = (
    url,
    returnQueryParamsOnly = false,
  ) => {
    let indexOfQuestionMark = url?.indexOf('?') ?? -1;
    if (indexOfQuestionMark === -1) {
      return [];
    }
    indexOfQuestionMark = indexOfQuestionMark + 1;
    const subStr = url.substring(indexOfQuestionMark, url.length);
    if (returnQueryParamsOnly) {
      return subStr;
    }
    const split = subStr.split('&');
    if (Utility.isBlank(split)) return [];

    const selectedItems = [];
    split.forEach((element) => {
      const furtherSplit = element.split('=');
      if (
        !furtherSplit[0]?.includes('[]') ||
        furtherSplit[0]?.includes('user_attributes')
      )
        return;
      const criteriaName = furtherSplit[0].replace('[]', '');
      const criteriaValue = furtherSplit[1];
      selectedItems.push({
        criteria_name: criteriaName,
        value: criteriaValue,
        selected: true,
      });
    });

    return selectedItems;
  };

  static addAlpha = (color, opacity) => {
    // coerce values so it is between 0 and 1.
    var _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
    return color + _opacity?.toString(16)?.toUpperCase();
  };

  static createPreSelectedFilter = (property, propertyName) => {
    return {
      criteria_name: 'property_values',
      name: propertyName ?? property,
      value: property,
      preSelected: true,
      count: 0,
      selected: true,
    };
  };

  static expandSelectedAttributes = (properties) => {
    const tags = properties.filter((element) => element.values.length > 0);
    const arrayClone = tags;
    tags.forEach((element, index) => {
      const values = element.values;
      if (values.length > 1) {
        values.forEach((e) => {
          arrayClone.push({ ...element, values: [e] });
        });
        arrayClone.splice(index, 1);
      }
    });
    return arrayClone;
  };

  static setStatusbarTranslucent = () => {
    StatusBar.setBackgroundColor('transparent', true);
    StatusBar.setBarStyle('dark-content', true);
    if (Utility.isAndroid()) {
      StatusBar.setTranslucent(true);
    }
  };

  static setStatusBarWhite = () => {
    StatusBar.setBackgroundColor('white', true);
    StatusBar.setBarStyle('dark-content', true);
    if (Utility.isAndroid()) {
      StatusBar.setTranslucent(false);
    }
  };

  static setStatusBarStyleDark = () => {
    StatusBar.setBarStyle('dark-content', true);
  };

  static setStatusBarBlack = () => {
    StatusBar.setBackgroundColor('black', true);
    StatusBar.setBarStyle('light-content', true);
    if (Utility.isAndroid()) {
      StatusBar.setTranslucent(false);
    }
  };

  static setStatusBarColor = (color) => {
    StatusBar.setBackgroundColor(color, true);
    StatusBar.setBarStyle('dark-content', true);
    if (Utility.isAndroid()) {
      StatusBar.setTranslucent(false);
    }
  };

  static replacePlaceHolders(string, replaceWith) {
    const pattern = /\$.*}/;
    const finalString = string.replace(pattern, replaceWith);
    return finalString;
  }

  static getImageUrlFromCdn2 = (url, width, height) => {
    if (Utility.isBlank(url)) {
      return '';
    }
    let standardWidth = width;

    if (width <= 120) {
      standardWidth = 120;
    } else if (width > 120 && width <= 180) {
      standardWidth = 180;
    } else if (width > 180 && width <= 360) {
      standardWidth = 360;
    } else if (width > 360 && width <= 480) {
      standardWidth = 720; // for full width image use higher resolution as lower resolution is looking pixelated
    } else if (width > 480 && width <= 720) {
      standardWidth = 720;
    } else if (width > 720) {
      standardWidth = 1024;
    }

    const imageRequest = JSON.stringify({
      bucket: IMAGES_S3_BUCKET,
      key: url.replace(`${CLOUDFRONT_URL}/`, ''),
      edits: {
        resize: {
          width: standardWidth,
          height: 0,
          fit: 'cover',
        },
      },
    });
    const newUrl = `${CLOUDFRONT_URL}/${Buffer.from(imageRequest).toString(
      'base64',
    )}`;

    return newUrl;
  };

  static getImageFallbackUrlFromCdn2 = (imageUrl) => {
    if (Utility.isBlank(imageUrl)) {
      return null;
    }
    const imageWidth = -1;
    if (!imageUrl.startsWith(CDN_3)) {
      return imageUrl;
    }
    if (imageUrl.indexOf('?') == -1) {
      return null;
    }

    const params = imageUrl.split('?');
    const widthParam = params[1].replace(/[^\d.]/g, '');
    const cdn2Url = params[0].replace(CDN_3, CLOUDFRONT_URL);

    return this.getImageUrlFromCdn2(cdn2Url, widthParam);
  };

  static getNotificationDataFoDailyDeals = (
    data,
    selfie_image_url,
    facialProperties,
    name,
  ) => {
    if (Utility.isBlank(selfie_image_url)) return;
    if (!SelfieUtilities.detectIfSelfieIsReviewed(facialProperties)) {
      return;
    }
    let day = parseInt(new Date().getDay());
    const notificationData = {};
    const productName = {};
    const offerDiscount = {};
    const newData = {};
    const parsedNotificationData = Utility.jsonParser(
      RemoteConfig.getValue(REMOTE_CONFIG_KEYS.daily_deals_notifications),
    );
    const today = day;
    const tomorrow = day + 1 > 6 ? 0 : day + 1;
    const dayAfter = tomorrow + 1 > 6 ? 0 : tomorrow + 1;
    _.forEach(data, (e) => {
      newData[`${day}`] = e;
      day += 1;
      if (day >= 7) {
        day = day - 7;
      }
    });
    Object.entries(newData).forEach(([key]) => {
      let day_data = key;
      if (key >= 7) {
        day_data = key - 7;
      }

      productName[key] =
        newData[`${day_data}`]?.skus[
          Object?.keys(newData[day_data]?.skus)[day_data]
        ].name;
      offerDiscount[`${day_data}`] =
        newData[day_data]?.skus[
          Object?.keys(newData[day_data]?.skus)[day_data]
        ].discount_text;
    });
    const filteredNotificationData = {};
    _.forEach(parsedNotificationData, (item, index) => {
      if (index == today || index == tomorrow || index == dayAfter) {
        filteredNotificationData[index] = item;
      }
    });
    Object.entries(filteredNotificationData).forEach(([key, value]) => {
      notificationData[`${key}`] = value;

      notificationData[`${key}`].subheading = value.subheading
        .toString()
        .replace('${offer_discount}', `${offerDiscount[key]}` || '')
        .replace('${product_name}', `${productName[key]}` || '')
        .replace('${user_name}', `${name}` || '');

      notificationData[`${key}`].heading = value.heading
        .toString()
        .replace('${offer_discount}', `${offerDiscount[key]}` || '')
        .replace('${product_name}', `${productName[key]}` || '')
        .replace('${user_name}', `${name || ''}` || '');
    });
    return notificationData;
  };

  static areNotificationsEnabled = (callback) => {
    if (Utility.isIOS()) {
      return;
    }
    // to be used in future for channel level blockage detection. As of now not required
    const androidNotificationsChannelsArray = [];

    NativeModules.NotificationsChannelModule.getNotificationChannelStatus(
      androidNotificationsChannelsArray,
      (notificationsEnabled) => {
        const showDisabledNotificationModal = !notificationsEnabled;
        callback(showDisabledNotificationModal, showDisabledNotificationModal);
      },
    );
  };

  static jsonParser = (string, key = '') => {
    let parsedJson = {};
    try {
      parsedJson = JSON.parse(string);
    } catch (error) {
      console.tron.log('JSON ERROR', `${error}`, key);
    }
    return parsedJson;
  };

  static getLoginModalInfo(type) {
    return Utility.jsonParser(
      RemoteConfig.getValue(REMOTE_CONFIG_KEYS.login_modal_info),
    )[type];
  }
  static isSomethingSeenToday = (prevData, customDays = 1) => {
    if (customDays === 0) {
      return false;
    }
    if (Utility.isBlank(prevData)) {
      return false;
    }
    try {
      const currentData = new Date();
      currentData.setDate(currentData.getDate() - customDays);

      return Date.parse(prevData) > Date.parse(currentData);
    } catch (e) {
      return false;
    }
  };

  static isDelayed = ({ delayInDispatch, delayInDelivery }) => {
    return (
      Utility.isPresent(delayInDispatch) || Utility.isPresent(delayInDelivery)
    );
  };

  static checkIfProductOrVariantIsLiked = (
    itemData = {},
    products,
    variants,
  ) => {
    const { type, id } = itemData;
    if (type === 'product') {
      return !!products[`${id}`];
    }
    return !!variants[`${id}`];
  };

  static updateStepperColor = (color) => {
    let stepperConfigs = { ...stepIndicatorConfig };
    stepperConfigs.stepStrokeFinishedColor = color;
    stepperConfigs.separatorFinishedColor = color;
    stepperConfigs.stepIndicatorFinishedColor = color;
    stepperConfigs.stepIndicatorCurrentColor = color;
    stepperConfigs.currentStepLabelColor = color;
    stepperConfigs.stepStrokeCurrentColor = color;
    return stepperConfigs;
  };

  static getAddressIcon = (type) => {
    let icon = null;
    if (Utility.isBlank(type)) {
      return images.other_location;
    }
    if (type.toLowerCase() === 'home') {
      icon = images.home_building;
    } else if (
      type.toLowerCase() === 'office' ||
      type.toLowerCase() === 'work'
    ) {
      icon = images.office_building;
    } else {
      icon = images.other_location;
    }
    return icon;
  };

  static hideLoginSkipButtonForSalon = false;

  static isValidUsn = (text) => {
    const regex = new RegExp(/^[0-9a-zA-Z]+$/);

    return regex.test(text);
  };

  static appendUtmParamsWithUrl = (url, trackingParams) => {
    let shareUrl = url;
    if (Utility.isBlank(shareUrl)) return url;
    if (shareUrl.indexOf('?') === -1) {
      shareUrl += `?Nan=Nan`;
    }

    if (Utility.isBlank(trackingParams)) return shareUrl;
    for (let key in trackingParams) {
      shareUrl += '&' + key + '=' + trackingParams[key];
    }

    return shareUrl;
  };

  static getTestimonialCardBgColor = (index) => {
    const indexToPick = index % TESTIMONIAL_COLOR_BUCKET.length;
    return TESTIMONIAL_COLOR_BUCKET[indexToPick];
  };

  static isIphone14ProOrLater() {
    return (
      Utility.isIOS() &&
      (Utility.windowHeight === 852 || Utility.windowHeight === 932)
    );
  }
}

const getPhoneResponse = ({ phoneNumber, error }) => ({
  error,
  message: error ? 'Not a valid phone number' : '',
  phoneNumber,
});

const getPhoneErrorResponse = (phoneNumber) =>
  getPhoneResponse({ phoneNumber, error: true });

const getPhoneSuccessResponse = (phoneNumber) =>
  getPhoneResponse({ phoneNumber, error: false });

const isPhoneStartsWith = (phoneNumber, subStr) =>
  phoneNumber.startsWith(subStr);

const isNotPhoneStartsWith = (phoneNumber, subStr) =>
  !isPhoneStartsWith(phoneNumber, subStr);

export const openUrlInBrowser = (url, callback = () => {}) => {
  Linking.canOpenURL(url)
    .then((supported) => {
      if (!supported) {
        callback(false);
        return;
      }
      Linking.openURL(url);
    })
    .catch((e) => {
      callback(false);
    });
};

export const openMaps = (lat, long, label) => {
  const scheme = Platform.OS === 'ios' ? 'maps:0,0?q=' : 'geo:0,0?q=';
  const latLng = `${lat},${long}`;
  const url = Platform.select({
    ios: `${scheme}${label}@${latLng}`,
    android: `${scheme}${latLng}(${label})`,
  });
  openUrlInBrowser(url);
};

export const openDialer = (number) => {
  const url = Platform.OS === 'ios' ? `telprompt:${number}` : `tel:${number}`;
  openUrlInBrowser(url);
};

export const findIngredients = (ingredients, my_attributes_values = []) => {
  let myAttributes = [];
  my_attributes_values.forEach((e) => {
    if (Utility.isBlank(e?.new_values)) {
      return;
    }
    myAttributes.push(e.new_values[0]);
  });

  let good = [],
    bad = [],
    neutral = [];

  _.forEach(ingredients, (ingredient) => {
    if (
      Utility.isBlank(ingredient.contraindications) &&
      Utility.isBlank(ingredient.indications)
    ) {
      neutral.push(ingredient);
    }

    if (Utility.isPresent(ingredient.indications)) {
      _.forEach(ingredient.indications, (indication) => {
        if (myAttributes.includes(indication)) {
          good.push({ ...ingredient, ...{ status: 'good', indication } });
        } else {
          neutral.push(ingredient);
        }
      });
    }
    if (Utility.isPresent(ingredient.contraindications)) {
      _.forEach(ingredient.contraindications, (contraindication) => {
        if (myAttributes.includes(contraindication)) {
          bad.push({
            ...ingredient,
            ...{ status: 'bad', contraindication },
          });
        } else {
          neutral.push(ingredient);
        }
      });
    }
  });

  let newGood = _.uniqBy(good, function (e) {
    return e.name;
  });
  let newBad = _.uniqBy(bad, function (e) {
    return e.name;
  });
  let newNeutral = _.uniqBy(neutral, function (e) {
    return e.name;
  });

  return {
    newGood: good,
    newBad: bad,
    newNeutral,
  };
};
