import React, { Component } from 'react';
import {
  View,
  Text,
  BackHandler,
  StyleSheet,
  ScrollView,
} from 'react-native';
import colors from '../../theme/Colors';
import Config from '../../libraries/ReactNativeConfig';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Utility from '../../utils/Utility';
import images from '../../theme/Images';
import { Image } from 'react-native';
import {
  TabNavigationLeftHeader,
  TabNavigationRightHeader,
} from '../../components/header';
import {
  TABBAR_CONSTANT,
  CATEGORY_BROWSING_TAB_SLUG,
  NOTIFICATION_REQUEST_MODAL_TYPE,
  EVENT_BUS_ACTIONS,
  FOXY_URLS,
} from '../../config/Constants';
import { ScreenPlaceholder } from '../../components/shared';
import ErrorBoundary from '../../components/shared/ErrorBoundary';
import { List } from '../List';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import WithNavigation from '../../utils/WithNavigation';
import { fetchList } from '../../actions/ActionTypes';
import { CartTray } from '../feed';
import OfferTray from '../feed/OfferTray';
import {
  AnalyticsManager,
  EventParameterKey,
  EventType,
} from '../../analytics';
import Toast from 'react-native-easy-toast';
import { isDesktop, isWeb } from '../../utils/BooleanUtility';
import WebFooter from '../../components/webFooter/WebFooter';
import { subscribe } from '../../utils/EventBus';
import { getFirebasePerformanceTrace } from '../../utils/PerfUtility';
import AppInstallPrompts from '../../components/shared/AppInstallPrompts';
import withProfiledNavigation from '../../utils/withProfiledNavigation';
import { ReactNavigationPerformanceView } from '@shopify/react-native-performance-navigation';
import { getNavigationSource } from '../../utils/PerfUtility';

class CategoryBrowsing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      itemData: {},
      listPage: 0,
      showServerError: false,
      retryLoading: false,
      refreshing: false,
      isCartTrayVisible: false,
      visible: false,
    };
    this.trace = getFirebasePerformanceTrace(SCREEN_CONSTANTS.CATEGORY_BROWSING);
    this.trace.start();
    this.fetchList();
    if (Utility.isAndroid()) {
      this.backhandler = BackHandler.addEventListener(
        'hardwareBackPress',
        this.onHardwareBackKeyPress,
      );
    }
    Utility.setPageLoadStart(SCREEN_CONSTANTS.CATEGORY_BROWSING);
    this.isCurrentScreen = false;
    this.categoryBrowsingRef = React.createRef();
    this.navigationSource = getNavigationSource(this, this.props);
  }

  componentDidMount() {
    const { navigation } = this.props;
    this.didFocusListener = navigation.addListener('focus', () => {
      this.onDidFocus();
      this.isCurrentScreen = true;
    });
    this.blurListener = navigation.addListener('blur', () => {
      this.isCurrentScreen = false;
    });
    this.tabPressListener = navigation.addListener('tabPress', () => {
      if (this.isCurrentScreen) {
        this.scrollToTop();
      }
    });
    this.subscription = subscribe(EVENT_BUS_ACTIONS.CATEGORY_BROWSING_SCROLL_TO_TOP, this.scrollToTop);
  }


  componentWillUnmount() {
    if (Utility.isAndroid()) {
      this.backhandler.remove();
    }
    this.didFocusListener();
    this.blurListener();
    this.tabPressListener();
    this.subscription.unsubscribe();
  }

  scrollToTop = () => {
    if (isWeb()) {
      this.categoryBrowsingRef.current?.scrollTo({ y: 0 });
    }
  }

  onDidFocus = () => {
    Utility.setStatusBarColor(colors.white);
    AnalyticsManager.logEvent(EventType.discoveryEvents.TAB_VIEW, {
      tab: 'categories',
    });
    AnalyticsManager.setCurrentScreen(SCREEN_CONSTANTS.CATEGORY_BROWSING);
  };



  fetchList = () => {
    const { fetchList } = this.props;
    const { listPage, itemData } = this.state;
    fetchList(
      { slug: CATEGORY_BROWSING_TAB_SLUG || '' },
      listPage,
      (success, data) => {
        if (!this.traceStopped) {
          this.trace.stop();
          this.traceStopped = true;
        }
        this.setState({
          showServerError: !success,
          retryLoading: false,
          refreshing: false,
        });
        if (!success || Utility.isBlank(data)) {
          return;
        }
        const dataToBeSet = Utility.isBlank(itemData)
          ? data
          : { ...itemData, objects: [...itemData.objects, ...data.objects] };
        this.setState({
          itemData: dataToBeSet,
          listPage: listPage + 1,
        });
      },
    );
  };

  onRetryPressed = () => {
    this.setState({
      refreshing: true,
    });
    this.fetchList();
  };

  onHardwareBackKeyPress = () => {
    const { navigation } = this.props;
    navigation.navigate('Feed');
    return true;
  };

  toggleCartVisibility = () => {
    const { navigation } = this.props;
    navigation.push(this.navigationSource, 'Cart', {
      source: SCREEN_CONSTANTS.CATEGORY_BROWSING,
    });
  };

  showCartTray = () => {
    this.setState({
      isCartTrayVisible: true,
    });
  };

  hideCartTray = () => {
    this.setState({
      isCartTrayVisible: false,
    });
  };

  showFreeProductScreen = () => {
    const { navigation } = this.props;
    AnalyticsManager.logEvent('VIEW_PRODUCTS_CLICKED', {
      screen: SCREEN_CONSTANTS.CATEGORY_BROWSING,
    });
    navigation.navigate('FreeGiftScreen');
  };

  checkVisible = (isVisible) => {
    const { visible } = this.state;
    if (isVisible) {
      if (!visible) {
        this.setState({ visible: true });
      }
    } else if (visible) {
      this.setState({ visible: false });
    }
  };

  setToastRef = (ref) => {
    this.toast = ref;
  };

  showToastForAddToCart = (message) => {
    this.toast.show(message, 1000);
  };

  showNotificationModal = (showNotificationPrompt) => {
    const { navigation } = this.props;
    navigation.push('NotificationModal', {
      showNotificationPrompt,
      type: NOTIFICATION_REQUEST_MODAL_TYPE.CATEGORY_BROWSING,
    });
  };

  displayNotificationModal = () => {
    const { initial_app_opened_at, lastNotificationModalDisplayTime } =
      this.props;
    Utility.canDisplayNotificationRequestModal(
      lastNotificationModalDisplayTime,
      SCREEN_CONSTANTS.CATEGORY_BROWSING,
      (canDisplay, showNotificationPrompt) => {
        if (canDisplay) {
          this.showNotificationModal(showNotificationPrompt);
        }
      },
      initial_app_opened_at,
    );
  };

  render() {
    const {
      itemData = {},
      showServerError,
      retryLoading,
      refreshing,
      isCartTrayVisible,
      visible,
    } = this.state;
    const { navigation } = this.props;

    const showShimmer = (Utility.isBlank(itemData?.objects) && !showServerError) || refreshing;
    const ContainerComponent = isDesktop() ? ScrollView : View;

    return (
      <ReactNavigationPerformanceView
        screenName='category'
        interactive={!showShimmer}
        renderPassName={!showShimmer ? 'interactive' : 'loading'}
      >
        <ErrorBoundary
          screen_name={SCREEN_CONSTANTS.CATEGORY_BROWSING}
          itemData={{ name: 'store', type: 'list', id: 'undefined' }}
          howServerError={showServerError}
          onPressReload={this.onRetryPressed}
          loading={retryLoading}
          hideHeader
        >
          {showShimmer && (
            <ScreenPlaceholder
              previousScreen={SCREEN_CONSTANTS.CATEGORY_BROWSING}
            />
          )}

          {!showShimmer && (
            <ContainerComponent style={styles.container} ref={this.categoryBrowsingRef}>
              <List
                itemData={itemData}
                navigation={navigation}
                previousScreen={SCREEN_CONSTANTS.CATEGORY_BROWSING}
                parentListsData={itemData}
                checkVisible={this.checkVisible}
                showFreeProductScreen={this.showFreeProductScreen}
                showToast={this.showToastForAddToCart}
              />
              {isDesktop() && <WebFooter />}
              <CartTray
                toggleCartVisibility={this.toggleCartVisibility}
                showCartTray={this.showCartTray}
                hideCartTray={this.hideCartTray}
                isCartTrayVisible={isCartTrayVisible}
              />
              <OfferTray
                toggleCartVisibility={this.toggleCartVisibility}
                showCartTray={this.showCartTray}
                hideCartTray={this.hideCartTray}
                showFreeProductScreen={this.showFreeProductScreen}
                isCartTrayVisible={!visible}
              />
              <Toast style={styles.toastStyle} ref={this.setToastRef} />
            </ContainerComponent>
          )}
          <AppInstallPrompts link={FOXY_URLS.appInstallPage.category} />
        </ErrorBoundary>
      </ReactNavigationPerformanceView>
    );
  }
}

function mapStateToProps(state) {
  return {
    initial_app_opened_at: state.UserAccountInfo.initial_app_opened_at,
    lastNotificationModalDisplayTime:
      state.UserAccountInfo.lastNotificationModalDisplayTime,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators({ fetchList }, dispatch),
  };
}

export default withProfiledNavigation(
  connect(mapStateToProps, mapDispatchToProps)(CategoryBrowsing),
);

export const CategoryHeaderOptions = isDesktop() ? {
  headerShown: false,
} : {
  title: Config.CATEGORY_BROWSING_HEADER_TITLE,
  headerTitleAlign: 'center',
  headerTitleStyle: {
    textAlign: 'center',
    alignSelf: 'center',
    fontSize: 15,
    fontFamily: 'Roboto-Medium',
  },
  headerLeft: () => (
    <TabNavigationLeftHeader tabBar={TABBAR_CONSTANT.category} />
  ),
  headerRight: () => (
    <TabNavigationRightHeader
      showCart
      store
      tabBar={TABBAR_CONSTANT.category}
    />
  ),
  headerBackVisible: false,
  headerShadowVisible: false,
  headerStyle: {
    backgroundColor: colors.white,
  },
  tabBarLabel: Config.CATEGORY_BROWSING_TAB_LABEL,
  tabBarActiveTintColor:
    Config.CATEGORY_BROWSING_TAB_ACTIVE_TEXT_COLOR ??
    Config.CATEGORY_BROWSING_TAB_ACTIVE_COLOR,
  tabBarInactiveTintColor: Config.CATEGORY_BROWSING_TAB_INACTIVE_COLOR,
  tabBarStyle: { borderTopWidth: 0, elevation: 0 },
  tabBarIcon: ({ focused }) => {
    const focusedIcon = Utility.isPresent(
      Config.CATEGORY_BROWSING_TAB_ACTIVE_ICON_URL,
    )
      ? { uri: Config.CATEGORY_BROWSING_TAB_ACTIVE_ICON_URL }
      : images.tab.offer_selected;
    const unfocusedIcon = Utility.isPresent(
      Config.CATEGORY_BROWSING_TAB_ICON_URL,
    )
      ? { uri: Config.CATEGORY_BROWSING_TAB_ICON_URL }
      : images.tab.offer_unselected;
    const icon = focused ? focusedIcon : unfocusedIcon;
    const tintColor = focused
      ? ''
      : Config.CATEGORY_BROWSING_TAB_INACTIVE_COLOR;

    const imageStyle = getMemoizedImageStyle(tintColor);

    return <Image source={icon} style={imageStyle} />;
  },
  tabBarOnPress: ({ navigation, defaultHandler }) => {
    defaultHandler();
    const { state: { routes = [] } = {} } = navigation;
    if (!navigation.isFocused()) return;
    if (Utility.isPresent(routes)) {
      const route = routes[0];
      const { params: { scrollToTop = () => {} } = {} } = route;
      scrollToTop();
    }
  },
};

const imageStyles = {};

const getMemoizedImageStyle = (tintColor) => {
  if (!imageStyles[tintColor]) {
    imageStyles[tintColor] = [styles.tabImageStyle, { tintColor }];
  }
  return imageStyles[tintColor];
};

const styles = StyleSheet.create({
  tabImageStyle: {
    height: 24,
    width: 24,
    resizeMode: 'contain',
  },
  toastStyle: {
    position: 'absolute',
    bottom: 100,
  },
  container: {
    flex: 1,
    backgroundColor: colors.background,
  },
});

export const CategoryNavigationOptions = {
  headerShown: true,
  title: Config.CATEGORY_BROWSING_HEADER_TITLE,
  headerTitleAlign: 'center',
  headerTitleStyle: {
    textAlign: 'center',
    alignSelf: 'center',
    fontSize: 15,
    fontFamily: 'Roboto-Medium',
  },
  headerLeft: () => (
    <TabNavigationLeftHeader tabBar={TABBAR_CONSTANT.category} />
  ),
  headerRight: () => (
    <TabNavigationRightHeader
      showCart
      store
      tabBar={TABBAR_CONSTANT.category}
    />
  ),
  headerBackVisible: false,
  headerShadowVisible: false,
  headerStyle: {
    backgroundColor: colors.white,
  },
  tabBarLabel: Config.CATEGORY_BROWSING_TAB_LABEL,
  tabBarActiveTintColor:
    Config.CATEGORY_BROWSING_TAB_ACTIVE_TEXT_COLOR ??
    Config.CATEGORY_BROWSING_TAB_ACTIVE_COLOR,
  tabBarInactiveTintColor: Config.CATEGORY_BROWSING_TAB_INACTIVE_COLOR,
  tabBarStyle: { borderTopWidth: 0, elevation: 0 },
  tabBarIcon: ({ focused }) => {
    const focusedIcon = Utility.isPresent(
      Config.CATEGORY_BROWSING_TAB_ACTIVE_ICON_URL,
    )
      ? { uri: Config.CATEGORY_BROWSING_TAB_ACTIVE_ICON_URL }
      : images.tab.offer_selected;
    const unfocusedIcon = Utility.isPresent(
      Config.CATEGORY_BROWSING_TAB_ICON_URL,
    )
      ? { uri: Config.CATEGORY_BROWSING_TAB_ICON_URL }
      : images.tab.offer_unselected;
    const icon = focused ? focusedIcon : unfocusedIcon;
    const tintColor = focused
      ? (isWeb() ? '' : Config.CATEGORY_BROWSING_TAB_ACTIVE_COLOR)
      : Config.CATEGORY_BROWSING_TAB_INACTIVE_COLOR;

    const imageStyle = getMemoizedImageStyle(tintColor);

    return <Image source={icon} style={imageStyle} />;
  },
  tabBarOnPress: ({ navigation, defaultHandler }) => {
    defaultHandler();
    const { state: { routes = [] } = {} } = navigation;
    if (!navigation.isFocused()) return;
    if (Utility.isPresent(routes)) {
      const route = routes[0];
      const { params: { scrollToTop = () => {} } = {} } = route;
      scrollToTop();
    }
  },
};
