import React from 'react';
import { Text, StyleSheet } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import { getScreenWidth } from '../../../utils/LayoutUtility';
import colors from '../../../theme/Colors';
import { isBlank } from '../../../utils/BooleanUtility';
import size from '../../../theme/Fonts';
import Utility from '../../../utils/Utility';
import { HtmlView } from '../../Product/RichContent/RichContentSummaryCardView';
import { GRADIENT_CONFIGURATIONS } from '../../../config/Constants';

const RoutineSummaryWidgetCard = ({
  summaryCardData: {
    titleHtml = '',
    bodyHtml = '',
    backgroundColor = colors.ultraDarkGreen,
  } = {},
  summaryCardData = {},
}) => {
  const gradientColor = Utility.isBlank(backgroundColor)
    ? colors.ultraDarkGreen
    : backgroundColor;
  const gradientColors = [`${gradientColor}66`, gradientColor];
  const shimmerGradientColors = [`${gradientColor}66`, gradientColor, `${gradientColor}66`];
  const htmlData = `<!DOCTYPE html>
  <html>
  <head>
    <meta content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0" name="viewport">
      <style>
        body, html, #height-calculator { margin: 0; padding: 0 } #height-calculator { position: absolute; top: 0; left: 12px; right: 12px; } img {max-width: 100%}
      </style>
    </head>
    <body>
      <div id="height-calculator">
        ${bodyHtml?.replace(/(\r\n|\n|\r)/gm, '')}
      </div>
      <script>
          div = document.getElementById("height-calculator");
          function informReactNative() {
            window?.ReactNativeWebView?.postMessage(div.clientHeight);
           }
          window.onload = (event) => {
            setTimeout(informReactNative, 100);
          };
           new ResizeObserver(informReactNative).observe(div)
        </script>
    </body>
  </html>`;

  if (isBlank(summaryCardData) || isBlank(titleHtml) || isBlank(bodyHtml)) {
    return null;
  }
  return (
    <LinearGradient
      colors={gradientColors}
      style={styles.card}
      start={GRADIENT_CONFIGURATIONS.DIAGONAL_TL_BR.start}
      end={GRADIENT_CONFIGURATIONS.DIAGONAL_TL_BR.end}
    >
      <Text style={styles.title}>{titleHtml}</Text>
      <HtmlView
        html={htmlData}
        width="100%"
        shimmerColors={shimmerGradientColors}
      />
    </LinearGradient>
  );
};

const styles = StyleSheet.create({
  card: {
    width: getScreenWidth() - 24,
    borderRadius: 8,
    marginBottom: 12,
    alignSelf: 'center',
    paddingVertical: 12,
    paddingHorizontal: 16,
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    color: colors.black,
  },
  description: {
    fontSize: 14,
    color: colors.white,
    padding: 6,
  },
  container: {
    paddingBottom: 16,
  },
  htmlText: {
    color: colors.foxyBlack,
    fontSize: size.h3,
    fontFamily: 'Roboto-Regular',
    marginVertical: 4,
  },
});

export default RoutineSummaryWidgetCard;
