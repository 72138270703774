import React, { Component } from 'react';
import { View, BackHandler } from 'react-native';
import { sharedStyles } from './styles';
import { TABBAR_CONSTANT } from '../../config/Constants';
import List from '../../containers/List/List';
import { TabNavigationRightHeader, NavigationBackHeader } from '../header';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import ScreenPlaceholder from './ScreenPlaceholder';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import ErrorBoundary from './ErrorBoundary';
import { ReactNavigationPerformanceView } from '@shopify/react-native-performance-navigation';

class DetailPage extends Component {
  // static whyDidYouRender = true;
  static navigationOptions = ({ navigation }) => {
    return {
      title: 'Tags',
      headerTitleStyle: {
        textAlign: 'center',
        alignSelf: 'center',
        flex: 1, // must to centrally align title on android, react navigation bug
      },
      headerLeft: <NavigationBackHeader />,
      headerRight: (
        <TabNavigationRightHeader tabBar={TABBAR_CONSTANT.category} />
      ),
    };
  };

  constructor(props) {
    super(props);
    this.state = {
      isCartVisible: false,
    };
    Utility.setPageLoadStart('tag_detail');
    if (Utility.isAndroid()) {
      this.backHandler = BackHandler.addEventListener(
        'hardwareBackPress',
        this.goBack,
      );
    }
  }

  componentWillUnmount() {
    if (Utility.isAndroid()) {
      this.backHandler?.remove();
    }
  }

  goBack = () => {
    const { navigation } = this.props;
    navigation.goBack();
    return true;
  };

  toggleCartVisibility = () => {
    this.props.navigation.push('Cart', {
      source: 'tag_details',
    });
  };

  onCartDismiss = () => {
    this.setState({ isCartVisible: false });
  };

  onEndReached = () => {
    const { onEndReached = () => {} } = this.props;
    onEndReached();
  };

  firePageLoadEnd = () => {
    Utility.setPageLoadEnd(SCREEN_CONSTANTS.TAG_DETAIL);
  };

  showShimmer = () => {
    const { itemData = {} } = this.props;
    return (
      Utility.isBlank(itemData) ||
      Utility.isBlank(itemData.objects) ||
      itemData.objects.length === 0
    );
  };

  render() {
    const {
      navigation,
      route,
      itemData,
      search,
      getQuickFiltersListRef,
      stickyPosition,
      selectedFilters,
      onFiltersPress,
      filtersEnabled,
      onPressResetButton,
      filtersSlug,
      setListPaginatedCallApiRef,
      abc,
      isPageLoading,
      previousScreen,
      serverError = false,
    } = this.props;

    const pageNotFound =
      (Utility.isBlank(itemData) ||
        Utility.isBlank(itemData?.objects) ||
        itemData?.objects?.length === 0) &&
      !isPageLoading &&
      !serverError;

    const { display, content } = itemData;
    const autoPaginateProductList = display === 'grid' && content === 'product';
    const isLoading = this.showShimmer();
    // console.tron.log("Auto paginate", autoPaginateProductList);
    return (
      <ReactNavigationPerformanceView interactive={!isLoading}>
        {isLoading && (
          <ScreenPlaceholder firePageLoadEnd={this.firePageLoadEnd} />
        )}

        {!isLoading && (
          <ErrorBoundary
            screen_name='Tag Details'
            itemData={itemData}
            showServerError={serverError}
            pageNotFound={pageNotFound}
            slug={route.params?.slug}
            hideHeader
          >
            <View style={sharedStyles.container}>
              {/* <StaticNavigationHeader title="Tags" /> */}

              <List
                itemData={itemData}
                navigation={navigation}
                showBanner={false}
                id={itemData.id}
                toggleCartVisibility={this.toggleCartVisibility}
                previousScreen={previousScreen}
                onEndReached={this.onEndReached}
                listName={itemData.name}
                search={search}
                paginate={autoPaginateProductList}
                getQuickFiltersListRef={getQuickFiltersListRef}
                stickyPosition={stickyPosition}
                selectedFilters={selectedFilters}
                onFiltersPress={onFiltersPress}
                onPressResetButton={onPressResetButton}
                filtersSlug={filtersSlug}
                // abc={() => {}}
                setListPaginatedCallApiRef={setListPaginatedCallApiRef}
              />
            </View>
          </ErrorBoundary>
        )}
      </ReactNavigationPerformanceView>
    );
  }
}

export default withNavigation(React.memo(DetailPage));
