import React, { PureComponent } from 'react';
import {
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  BackHandler,
  StyleSheet,
  PixelRatio,
} from 'react-native';
import { TabBar, TabView } from 'react-native-tab-view';
import _, { memoize } from 'lodash';
import { connect } from 'react-redux';
import colors from '../../theme/Colors';
import size from '../../theme/Fonts';
import images from '../../theme/Images';
import Utility from '../../utils/Utility';
import BottomSheet from '../onboarding/BottomSheet';
import ContentCheckBox from './filter-modal-content/ContentCheckBox';
import ContentQuickFilters from './filter-modal-content/ContentQuickFilters';
import ActionButton from '../shared/ActionButton';
import ContentFoxyMatch from './filter-modal-content/ContentFoxyMatch';
import FilterAnalyticsEvents from './FiltersAnalyticsEvents';
import Config from '../../libraries/ReactNativeConfig';
import FilterUtils from './FilterUtils';
import FoxyShadowButton from '../../lib/FoxyShadowButton';
import {
  AnalyticsManager,
  EventParameterKey,
  EventType,
} from '../../analytics';

import withNavigation from '../../utils/WithNavigation';
import { getScreenWidth } from '../../utils/LayoutUtility';
import { isBlank, isPresent, isDesktop, isWebIOS } from '../../utils/BooleanUtility';
import FastImageView from '../FastImageView';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '../../config/Constants';

class SortingFilteringModal extends PureComponent {
  constructor(props) {
    super(props);

    const {
      route: {
        params: {
          listId = '',
          listName = '',
          selectedFilters,
          listData = EMPTY_OBJECT,
          listSlug = '',
          modalFilters = [],
          hideOosProduct = false,
        } = EMPTY_OBJECT,
      } = EMPTY_OBJECT,
    } = this.props;
    this.state = {
      routes: modalFilters,
      foxyMatchRoute: [],
      warningStatus: '',
      hideOosProduct: hideOosProduct || false,
      disableClearFilter: !isPresent(selectedFilters),
      selectedRecommendedFilters: [],
    };
    this.prevSelectedFilters = [];
    this.appliedFilters = [];
    for (let key in selectedFilters) {
      this.appliedFilters = _.uniqBy(
        [...this.appliedFilters, ...selectedFilters[key]],
        (e) => e.value,
      );
      this.prevSelectedFilters = _.uniqBy(
        [...this.prevSelectedFilters, ...selectedFilters[key]],
        (e) => e.value,
      );
    }

    this.productCategoryFiltersIndex = _.findIndex(
      modalFilters,
      (e) => e.criteria_name === 'product_category_filters',
    );
    this.productCategoryFilters =
      modalFilters[this.productCategoryFiltersIndex]?.filters || [];
    this.recommendedFilters =
      this.productCategoryFilters.filter(
        (obj) => obj.criteria_name === 'recommended_filters',
      )?.[0]?.criteria || [];

    const {
      list: {
        id: filterListId = '',
        name: filterListName = '',
        slug: filterListSlug = '',
      },
    } = FilterUtils.getList(listData?.list || listData);
    this.tempFilterValues = []; // to be use while reseting the fiters
    this.resetMethods = [];
    this.pushFoxyMatchRoute = [];
    this.memoizeLabelTitleColor = EMPTY_OBJECT;
    this.memoizeModalHeight = EMPTY_OBJECT;
    this.buttonWidth =
      (Utility.getScreenWidth() - (12 - Utility.spacingBetweenItems) * 2 - 16) /
      1.3;
    this.primaryButtonWidth =
      (Utility.getScreenWidth() - (12 - Utility.spacingBetweenItems) * 2 - 16) *
      0.25;
    this.secondaryButtonWidth =
      (Utility.getScreenWidth() - (12 - Utility.spacingBetweenItems) * 2 - 16) *
      0.75;
    this.listName = filterListName || listName;
    this.listId = filterListId || listId;
    this.listSlug = filterListSlug || listSlug;
    this.primaryButtonBorderColor = Utility.isPresent(Config.THEME_COLOR)
      ? Config.THEME_COLOR
      : colors.foxyBlack;
    if (Utility.isAndroid()) {
      this.backhandler = BackHandler.addEventListener(
        'hardwareBackPress',
        this.onHardwareBackKeyPress,
      );
    }
    this.fireEvent();
    this.devicePixelRatio = PixelRatio.get();
    this.checkBoxImageUri = Utility.getMinifiedImage(
      images.black_check_box,
      20 * this.devicePixelRatio,
      20 * this.devicePixelRatio,
    );
  }

  componentDidMount = () => {
    const {
      route = EMPTY_OBJECT,
      facialAnalysis: { facialProperties },
    } = this.props;
    const { foxyMatchRoute } = this.state;
    const {
      params: {
        modalFilters = EMPTY_ARRAY,
        listSlug = '',
        screenName = '',
        entityName = '',
      } = EMPTY_OBJECT,
    } = route;
    let filtersWithKey = _.map(modalFilters, (element, index) => ({
      ...element,
      key: `${element.display}_${index}`,
    }));

    const indexOfFoxyMatch = _.findIndex(
      filtersWithKey,
      (element) => element.display === 'foxy_match',
    );
    if (indexOfFoxyMatch !== -1) {
      const commonUserAttribs = Utility.getCommonUserAttributes(
        facialProperties,
        filtersWithKey[indexOfFoxyMatch].criteria,
      );

      if (Utility.isBlank(commonUserAttribs)) {
        filtersWithKey = _.filter(
          filtersWithKey,
          (element) => element.display !== 'foxy_match',
        );
      }
    }

    const isFoxyMatchPresent = Utility.isPresent(
      filtersWithKey.filter((filter) => filter.display === 'foxy_match'),
    );
    this.tempFilterValues = filtersWithKey;
    if (isFoxyMatchPresent) {
      const lastRoute = filtersWithKey.pop();
      this.pushFoxyMatchRoute.push(lastRoute);
    }
    this.setState({
      routes: filtersWithKey,
      foxyMatchRoute: this.pushFoxyMatchRoute,
    });

    const visibleTabsOnModal = modalFilters.map((element) => element.title);
    FilterAnalyticsEvents.logFilterModalOpenEvent(
      visibleTabsOnModal,
      { id: this.listId, name: this.listName, slug: this.listSlug },
      screenName,
      entityName,
    );
    this.setInitialSelectedRecommendedFilters();
  };

  componentWillUnmount() {
    if (Utility.isAndroid()) {
      this.backhandler.remove();
    }
  }

  setInitialSelectedRecommendedFilters = () => {
    const selectedRecommendedFilters = this.recommendedFilters.filter(
      (recommendedFilter) =>
        isPresent(
          this.appliedFilters.filter(
            (appliedFilter) => appliedFilter.value === recommendedFilter.value,
          ),
        ),
    ) || [];
    this.setState({
      selectedRecommendedFilters,
    });
  };

  onHardwareBackKeyPress = () => {
    const { navigation } = this.props;
    navigation.goBack();
    return true;
  };

  addResetFunction = (resetFunction) => {
    this.resetMethods.push(resetFunction);
  };

  onPressResetButton = () => {
    const {
      route: {
        params: { listSlug = '', entityName = '', screenName = '' } = EMPTY_OBJECT,
      } = EMPTY_OBJECT,
    } = this.props;
    this.appliedFilters = [];
    this.setState({
      disableClearFilter: isBlank(this.appliedFilters),
      hideOosProduct: false,
    });
    this.resetMethods.forEach((element) => {
      element();
    });

    FilterAnalyticsEvents.logFilterResetEvent('modal', screenName, entityName, {
      id: this.listId,
      name: this.listName,
      slug: this.listSlug,
    });
  };

  onPressFilterApply = () => {
    const {
      route: {
        params: {
          onFilterApplyPress,
          resetQuickFilter,
          onPressHideOosCheckBox = () => EMPTY_OBJECT,
        } = EMPTY_OBJECT,
      } = EMPTY_OBJECT,
    } = this.props;
    const { hideOosProduct } = this.state;
    onFilterApplyPress(this.appliedFilters, false, 0, () => EMPTY_OBJECT, hideOosProduct);
    onPressHideOosCheckBox(this.state.hideOosProduct);
    resetQuickFilter();
    this.closeModal();
  };

  replaceExistingSortCriteriaWithLatest = (criteria, item) => {
    let isSortByOrUserAttributesPresent = false;
    let sortByOrUserAttributesIndex = -1;
    let isUserAttributes = false;

    if (Utility.isBlank(this.appliedFilters)) {
      this.appliedFilters.push({
        criteria_name: item.criteria_name || criteria,
        value: item.value,
        name: item.name,
        filter_section_criteria: criteria,
        is_quick_filter: false,
      });
    } else {
      this.appliedFilters.forEach((element, index) => {
        if (element.criteria_name === criteria) {
          if (criteria === 'user_attribute_allowed_value_id' || 'uaavs') {
            isUserAttributes = true;
          }
          isSortByOrUserAttributesPresent = true;
          sortByOrUserAttributesIndex = index;
        }
      });

      if (isSortByOrUserAttributesPresent) {
        this.appliedFilters[sortByOrUserAttributesIndex] = {
          criteria_name: item.criteria_name || criteria,
          value: item.value,
          name: item.name,
          filter_section_criteria: criteria,
        };

        if (isUserAttributes && Utility.isBlank(item.value)) {
          this.appliedFilters = this.appliedFilters.filter(
            (element, index) => index !== sortByOrUserAttributesIndex,
          );
        }
      } else {
        this.appliedFilters.push({
          criteria_name: item.criteria_name || criteria,
          value: item.value,
          name: item.name,
          filter_section_criteria: criteria,
          is_quick_filter: false,
        });
      }
    }
    this.onPressFilterApply();
  };

  onFilterSelect = (criteria, item) => {
    let isSortByCriteriaPresent = false;
    let sortByCriteriaIndex = -1;
    this.appliedFilters.forEach((element, index) => {
      if (element.criteria_name === 'sort_by') {
        isSortByCriteriaPresent = true;
        sortByCriteriaIndex = index;
      }
    });
    switch (criteria) {
      case 'sort_by':
        this.replaceFiltersForSortByCriteria(criteria, item);
        break;
      case 'user_attribute_allowed_value_id':
      case 'uaavs':
        this.replaceExistingSortCriteriaWithLatest(criteria, item);
        break;
      default:
        if (isSortByCriteriaPresent && criteria === 'sort_by') {
          this.appliedFilters[sortByCriteriaIndex] = {
            criteria_name: item.criteria_name || criteria,
            value: item.value,
            name: item.name,
            is_quick_filter: false,
            filter_section_criteria: criteria,
          };
        }

        const currentFilter = this.appliedFilters.filter(
          (element) =>
            element.criteria_name === (item.criteria_name || criteria) &&
            element.value === item.value,
        );

        if (Utility.isBlank(currentFilter)) {
          if (
            isSortByCriteriaPresent &&
            criteria === 'recommended_filters' &&
            item.criteria_name === 'sort_by'
          ) {
            this.appliedFilters[sortByCriteriaIndex] = {
              criteria_name: item.criteria_name || criteria,
              value: item.value,
              name: isPresent(item.prefix_title)
                ? `${item.prefix_title}: ${item.name}`
                : item.name,
              is_quick_filter: false,
              filter_section_criteria: criteria,
            };
          } else {
            this.appliedFilters.push({
              criteria_name: item.criteria_name || criteria,
              value: item.value,
              name: isPresent(item.prefix_title)
                ? `${item.prefix_title}: ${item.name}`
                : item.name,
              filter_section_criteria: criteria,
              is_quick_filter: false,
            });
          }
        } else {
          this.appliedFilters = _.reject(
            this.appliedFilters,
            (element) =>
              element.criteria_name === (item.criteria_name || criteria) &&
              element.value === item.value,
          );
        }
        break;
    }
    this.setInitialSelectedRecommendedFilters();
    this.setState({
      disableClearFilter: !isPresent(this.appliedFilters),
    });
  };

  replaceFiltersForSortByCriteria = (criteria, item) => {
    let isSortByCriteriaPresent = false;
    let sortByCriteriaIndex = -1;
    if (Utility.isBlank(this.appliedFilters)) {
      this.appliedFilters.push({
        criteria_name: criteria,
        value: item.value,
        name: item.name,
        is_quick_filter: false,
        filter_section_criteria: criteria,
      });
    } else {
      this.appliedFilters.forEach((element, index) => {
        if (element.criteria_name === criteria) {
          if (criteria === 'sort_by') {
            isSortByCriteriaPresent = true;
          }
          sortByCriteriaIndex = index;
        }
      });
      if (isSortByCriteriaPresent) {
        this.appliedFilters[sortByCriteriaIndex] = {
          criteria_name: criteria,
          value: item.value,
          name: item.name,
          is_quick_filter: false,
          filter_section_criteria: criteria,
        };
      } else {
        this.appliedFilters.push({
          criteria_name: criteria,
          value: item.value,
          name: item.name,
          is_quick_filter: false,
          filter_section_criteria: criteria,
        });
      }
    }
    this.setState({
      disableClearFilter: !isPresent(this.appliedFilters),
    });
  };

  renderFoxyMatchTab = (props) => {
    const { route, jumpTo } = props;
    const {
      route: {
        params: {
          entityName = '',
          screenName = '',
          selectedFilters,
          resetQuickFilter = () => {},
        } = EMPTY_OBJECT,
      } = EMPTY_OBJECT,
    } = this.props;
    const {
      facialAnalysis: { facialProperties },
    } = this.props;

    const commonUserAttribs = Utility.getCommonUserAttributes(
      facialProperties,
      route.criteria,
    );
    if (Utility.isBlank(commonUserAttribs)) {
      return null;
    }
    return (
      <ContentFoxyMatch
        headingText={route.title}
        jumpTo={jumpTo}
        onFilterSelect={_.memoize(
          (item) => this.onFilterSelect(route.criteria_name, item),
          (item) => [item.value, route.criteria_name],
        )}
        selectedFilters={selectedFilters[route.criteria_name]}
        facialAttributes={commonUserAttribs}
        screenName={screenName}
        entityName={entityName}
        listName={this.listName}
        listId={this.listId}
        listSlug={this.listSlug}
        resetQuickFilter={resetQuickFilter}
      />
    );
  };

  renderLabel = ({ title }) => {
    return <Text style={styles.labelTitleStyle}>{title}</Text>;
  };

  tabView = ({ filterSection = EMPTY_OBJECT }) => {
    if (filterSection.display === 'foxy_match') return null;
    const {
      route: {
        params: {
          selectedFilters,
          entityName = '',
          screenName = '',
          hideBrandFilter = false,
        } = EMPTY_OBJECT,
      } = EMPTY_OBJECT,
    } = this.props;
    const { warningStatus, selectedRecommendedFilters } = this.state;
    return (
      <>
        <this.renderLabel title={filterSection?.title} />
        <ScrollView style={styles.chipsScrollViewStyle}>
          {warningStatus === 'show_warning' && (
            <Text style={styles.headerTextStyle}>
              Please click ‘Apply’ filters before exiting!
            </Text>
          )}
          {filterSection?.filters?.map((pills, index) => (
            <ContentQuickFilters
              listName={this.listName}
              listId={this.listId}
              listSlug={this.listSlug}
              criteria_name={filterSection.criteria_name}
              options={pills.criteria}
              onFilterSelect={_.memoize(
                (item) => this.onFilterSelect(pills.criteria_name, item),
                (item) => [item.value, pills.criteria_name],
              )}
              headingText={pills.title}
              selectedFilters={selectedFilters[pills.criteria_name]}
              allowMultiSelection={pills?.multi_select}
              applyResetFunction={this.addResetFunction}
              index={index}
              screenName={screenName}
              entityName={entityName}
              title={filterSection.title}
              hideBrandFilter={hideBrandFilter}
              selectedRecommendedFilters={selectedRecommendedFilters}
              pillsCriteriaName={pills.criteria_name}
              appliedFilters={this.appliedFilters}
            />
          ))}
        </ScrollView>
      </>
    );
  };

  fireEvent = (index) => {
    const {
      route: { params: { entityName = '', screenName = '' } = EMPTY_OBJECT } = EMPTY_OBJECT,
    } = this.props;
    AnalyticsManager.logEvent(EventType.discoveryEvents.FILTER_ACTION, {
      [EventParameterKey.FILTER_SECTION]: 'Sort & Filters',
      [EventParameterKey.LIST_ID]: this.listId,
      [EventParameterKey.LIST_NAME]: this.listName,
      [EventParameterKey.LIST_SLUG]: this.listSlug,
      [EventParameterKey.ENTITY_NAME]: entityName,
      [EventParameterKey.SCREEN_NAME]: screenName,
    });
  };

  memoizedModalHeight = (height) => {
    if (!this.memoizeModalHeight[height]) {
      this.memoizeModalHeight[height] = [
        styles.bottomSheetContainer,
        { height },
      ];
    }
    return this.memoizeModalHeight[height];
  };

  buttonContainer = () => {
    const {
      route: { params: { showFoxyMatchModal } = EMPTY_OBJECT } = EMPTY_OBJECT,
    } = this.props;
    const { disableClearFilter, hideOosProduct = false } = this.state;
    if (showFoxyMatchModal) return null;
    const secondaryButtonColor = Utility.isPresent(Config.THEME_COLOR)
      ? Config.THEME_COLOR
      : colors.black;

    const strokeButtonUnderlayColor = colors.transparent;
    const filledButtonUnderlayColor =
      Config.FILTER_MODAL_FILLED_BUTTON_UNDERLAY_COLOR ||
      colors.foxyShadowButtonUnderlayColor;
    const textColor = disableClearFilter ? colors.darkGrey : Config.THEME_COLOR || colors.foxyBlack;
    const borderColor = disableClearFilter
      ? colors.darkGrey
      : this.primaryButtonBorderColor;
    const disableButtonTextColor = hideOosProduct
      ? colors.foxyBlack
      : textColor;
    const disableButtonBorderColor = hideOosProduct
      ? this.primaryButtonBorderColor
      : borderColor;
    const canDisableClearButton = hideOosProduct ? false : disableClearFilter;

    const primaryButtonContainerStyle = [
      styles.primaryButtonContainer,
      isDesktop() && { flex: 1, marginRight: 12 },
    ];

    const secondaryButtonContainerStyle = [
      styles.secondaryButtonContainer,
      isDesktop() && { flex: 1, marginLeft: 12 },
    ];

    const rowButtonsContainer = [
      styles.rowButtonsContainer,
      isDesktop() && { width: '100%', marginLeft: 0, paddingHorizontal: 24 },
    ];

    return (
      <View style={rowButtonsContainer}>
        <FoxyShadowButton
          title={'Clear'}
          onPress={this.onPressResetButton}
          style={styles.primaryButtonContainer}
          textStyle={memoize(
            () => {
              return [
                styles.primaryButtonText,
                { color: disableButtonTextColor },
              ];
            },
            () => [disableButtonTextColor],
          )()}
          firstColor={colors.transparent}
          secondColor={colors.transparent}
          underlayColor={strokeButtonUnderlayColor}
          border={memoize(
            () => {
              return [styles.border, { borderColor: disableButtonBorderColor }];
            },
            () => [disableButtonBorderColor],
          )()}
          underlayWidth={this.primaryButtonWidth}
          disabled={canDisableClearButton}
          disabledButtonFirstColor={colors.transparent}
          disabledButtonSecondColor={colors.transparent}
        />
        <FoxyShadowButton
          title={'Apply'}
          onPress={this.onPressFilterApply}
          style={secondaryButtonContainerStyle}
          textStyle={styles.secondaryButtonText}
          firstColor={secondaryButtonColor}
          secondColor={secondaryButtonColor}
          underlayColor={filledButtonUnderlayColor}
          underlayWidth={this.secondaryButtonWidth}
        />
      </View>
    );
  };

  closeModal = () => {
    const { navigation } = this.props;
    navigation.pop();
  };

  onTabOutside = () => {
    const { warningStatus, hideOosProduct } = this.state;
    const {
      route: {
        params: {
          resetQuickFilter,
          hideOosProduct: prevHideOosProduct,
        } = EMPTY_OBJECT,
      } = EMPTY_OBJECT,
    } = this.props;
    resetQuickFilter();
    const closeModal =
      (hideOosProduct === prevHideOosProduct ||
        warningStatus === 'show_warning') &&
      (this.prevSelectedFilters.length === this.appliedFilters.length ||
        warningStatus === 'show_warning' ||
        (isBlank(this.prevSelectedFilters) && isBlank(this.appliedFilters)));
    if (closeModal) {
      this.closeModal();
      return;
    }
    if (isBlank(warningStatus)) {
      this.setState({
        warningStatus: 'show_warning',
      });
    }
  };

  renderFilters = () => {
    const { routes } = this.state;
    return routes.map((route, index) => {
      return <this.tabView key={index} filterSection={route} />;
    });
  };

  fireCheckBoxEvent = () => {
    const { hideOosProduct } = this.state;

    const {
      route: {
        params: {
          entityName = '',
          screenName = '',
          listId = '',
          listName = '',
          listSlug = '',
        },
      },
    } = this.props;

    AnalyticsManager.logEvent(EventType.discoveryEvents.FILTER_ACTION, {
      [EventParameterKey.FILTER_TYPE]: 'Sort & Filters',
      [EventParameterKey.LIST_ID]: listId,
      [EventParameterKey.LIST_NAME]: listName,
      [EventParameterKey.LIST_SLUG]: listSlug,
      [EventParameterKey.ENTITY_NAME]: entityName,
      [EventParameterKey.SCREEN_NAME]: screenName,
      [EventParameterKey.FILTER_HIDE_OOS]: this.state.hideOosProduct,
    });
  };

  onCheckboxPressed = () => {
    const { hideOosProduct } = this.state;
    this.setState({ hideOosProduct: !hideOosProduct }, this.fireCheckBoxEvent);
  };

  renderHideOosProductCheckBox = () => {
    const { hideOosProduct } = this.state;
    return (
      <TouchableOpacity
        onPress={this.onCheckboxPressed}
        style={styles.checkboxView}
      >
        <View style={styles.checkbox}>
          {hideOosProduct && (
            <FastImageView
              resizeMode='contain'
              source={this.checkBoxImageUri}
              height={16}
              width={16}
              style={styles.checkboxTick}
              tintColor={Config.FILTER_MODAL_INDICATOR_COLOR}
            />
          )}
        </View>
        <Text style={styles.checkboxText}>Hide out of stock products</Text>
      </TouchableOpacity>
    );
  };

  render() {
    const {
      route: { params: { showFoxyMatchModal } = EMPTY_OBJECT } = EMPTY_OBJECT,
    } = this.props;
    const { foxyMatchRoute } = this.state;
    const modalHeight = !showFoxyMatchModal ? (isWebIOS() ? 550 : 660) : 380;
    return (
      <>
        <TouchableOpacity onPress={this.onTabOutside} style={styles.touchableContainer} />
        <View style={styles.modalContainer} pointerEvents='box-none'>
          <View style={this.memoizedModalHeight(modalHeight)}>
            {!showFoxyMatchModal && <this.renderFilters />}
            {showFoxyMatchModal &&
              foxyMatchRoute.map((route) => {
                return <this.renderFoxyMatchTab route={route} />;
              })}
            {!showFoxyMatchModal && <this.renderHideOosProductCheckBox />}
            {!showFoxyMatchModal && <this.buttonContainer />}
          </View>
        </View>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  facialAnalysis: state.UserAccountInfo.facialAnalysis,
});

export default withNavigation(
  connect(mapStateToProps, null)(SortingFilteringModal),
);

const styles = StyleSheet.create({
  touchableContainer: {
    width: '100%',
    height: '100%',
  },
  modalContainer: {
    height: '100%',
    position: 'absolute',
    justifyContent: 'flex-end',
    bottom: 0,
    width: '100%',
    backgroundColor: colors.translucent,
  },
  bottomSheetContainer: {
    position: 'absolute',
    bottom: 0,
    height: 600,
    width: getScreenWidth(),
    alignSelf: 'center',
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    backgroundColor: colors.white,
    paddingBottom: Utility.isIOS() ? Utility.bottomInset : 8,
  },
  row: {
    flexDirection: 'row',
    alignContent: 'center',
    paddingBottom: Utility.isIOS() ? Utility.bottomInset + 8 : 0,
  },
  rowContainer: {
    flex: 0.7,
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 8,
    borderColor: colors.black,
    width: 172,
    height: 46,
  },
  rowButtonContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  resetButton: {
    height: 20,
    width: 20,
    marginTop: 1,
    marginLeft: 8,
    alignItems: 'center',
  },
  resetButtonText: {
    fontFamily: 'Roboto-Medium',
    marginLeft: 10,
    fontSize: size.h2,
    color: Config.FILTER_MODAL_RESET_BUTTON,
  },

  tabBarStyle: {
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    backgroundColor: colors.white,
    paddingBottom: 0,
    height: 48,
    shadowOpacity: 0,
    elevation: 0,
  },
  indicatorStyle: {
    backgroundColor: colors.white,
  },

  badgeContainer: {
    // position:'absolute',
    left: 6,
    bottom: 3,
  },
  badgeWrapper: {
    height: 14,
    width: 14,
    backgroundColor: colors.subtitle,
    borderTopLeftRadius: 30,
    borderTopRightRadius: 30,
    borderBottomRightRadius: 30,
    borderBottomLeftRadius: 30,
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    marginTop: 18,
  },
  badgeWrapperHighlighted: {
    height: 14,
    width: 14,
    marginTop: 18,
    backgroundColor: Config.FILTER_MODAL_BADGE_COLOR,
    borderTopLeftRadius: 30,
    borderTopRightRadius: 30,
    borderBottomRightRadius: 30,
    borderBottomLeftRadius: 30,
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
  },
  badgeText: {
    fontFamily: 'Roboto-Medium',
    fontSize: 9,
    color: colors.white,
  },
  tabBarShadow: {
    elevation: 0,
    shadowOpacity: 0,
  },
  tabStyle: {
    // width: Utility.getScreenWidth() / 2,
    marginRight: 30,
  },
  buttonWidth: {
    width: 170,
  },
  rowButtonsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: Utility.getScreenWidth() - 24,
    marginLeft: 12,
  },
  primaryButtonContainer: {
    marginTop: 12,
    width:
      (Utility.getScreenWidth() - (12 - Utility.spacingBetweenItems) * 2 - 16) *
      0.25,
    alignItems: 'center',
  },
  secondaryButtonText: { borderRadius: 8 },
  secondaryButtonContainer: {
    marginTop: 12,
    alignItems: 'center',
    width:
      (Utility.getScreenWidth() - (12 - Utility.spacingBetweenItems) * 2 - 16) *
      0.75,
  },
  primaryButtonText: { alignSelf: 'center' },
  border: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 8,
  },
  labelContainer: {
    // flexDirection: 'row',
    alignContent: 'flex-start',
    // justifyContent: 'center',
    alignItems: 'flex-start',
  },
  chipsScrollViewStyle: {
    marginTop: 22,
  },
  radioMargin: {
    marginTop: 22,
  },
  resetButtonIcon: {
    width: 16,
    height: 20,
    resizeMode: 'contain',
    marginRight: 6,
    overflow: 'visible',
    tintColor: colors.foxyBlack,
  },
  labelTitleStyle: {
    fontSize: size.h2,
    textAlign: 'center',
    fontFamily: 'Roboto-Medium',
    paddingTop: 16,
    alignSelf: 'flex-start',
    color: colors.black,
    paddingLeft: 20,
    height: 40,
  },
  headerTextStyle: {
    color: colors.black,
    fontSize: 12,
    fontFamily: 'Roboto-Normal',
    paddingLeft: 20,
  },
  checkboxView: {
    height: 30,
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 12,
  },
  checkbox: {
    height: 16,
    width: 16,
    borderRadius: 2,
    borderWidth: 1,
    borderColor: colors.foxyBlack,
    marginRight: 8,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 12,
    alignSelf: 'center',
  },
  checkboxText: {
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.black,
    paddingTop: 12,
    alignSelf: 'center',
  },
  checkboxTick: { height: 16, width: 16 },
});
