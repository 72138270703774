import React, { Component } from 'react';
import {
  View,
  Text,
  FlatList,
  Platform,
  UIManager,
  StyleSheet,
} from 'react-native';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import { connect } from 'react-redux';
import { getProductPersonalizedDetails } from '../../../actions/ProductPageElementsActions';
import withNavigation from '../../../utils/WithNavigation';
import Utility from '../../../utils/Utility';
import colors, { colorCode } from '../../../theme/Colors';
import DefaultSizeProductList from '../../Product/DefaultSizeProductList';
import { DefaultSizeVariantList } from '../../variants';
import ErrorBoundaryComponent from '../../shared/ErrorBoundaryComponent';
import { FlatListPerformanceView } from '../../../libraries/ReactNativePerformanceShopify';
import { getListSource } from '../../../utils/PerfUtility';

if (
  Platform.OS === 'android' &&
  UIManager.setLayoutAnimationEnabledExperimental
) {
  UIManager.setLayoutAnimationEnabledExperimental(true);
}

export class ListWithIngredients extends Component {
  renderPills = ({ ingredients = [], star_ingredients = [], product = {} }) => {
    if (Utility.isBlank(star_ingredients)) {
      return null;
    }
    const {
      facialAnalysis: { my_attributes_values = [] } = {},
      listData: {},
    } = this.props;

    const {
      product_category: {
        category_user_attributes: { principal = {} } = {},
      } = {},
    } = product;

    if (Utility.isBlank(star_ingredients)) {
      return null;
    }

    const ingredient_for_product = Utility.findIndicationsAndCounterIndications(
      star_ingredients,
      my_attributes_values,
      principal,
    );

    const green_array = [];
    const attribute_array = [];
    const neutral_array = [];

    Object.entries(ingredient_for_product).map(([key, value]) => {
      if (value.myIndications.length > 0 || value.mySuper.length > 0) {
        green_array.push(key);
        if (value.myIndications.length > 0) {
          value.myIndications.map((e) => {
            if (!attribute_array.includes(value.mapping[e])) {
              attribute_array.push(value.mapping[e]);
            }
          });
        }
        if (value.mySuper.length > 0) {
          value.mySuper.map((e) => {
            if (!attribute_array.includes(value.mapping[e])) {
              attribute_array.push(value.mapping[e]);
            }
          });
        }
      } else {
        neutral_array.push(key);
      }
    });

    const newArray = [...attribute_array, ...green_array, ...neutral_array];

    return (
      <View style={styles.pillContainer}>
        {newArray.map((pill, index) => {
          if (index === 5 && newArray.length - 5 !== 1) {
            return (
              <View
                style={[
                  styles.ingredientPill,
                  { backgroundColor: '#979BAA33' },
                ]}
              >
                <Text
                  style={[
                    styles.pillText,
                    { color: '#979BAA' },
                  ]}
                >
                  {`+ ${newArray.length - 5} more`}
                </Text>
              </View>
            );
          }
          if (index > 5) {
            return null;
          }
          return (
            <View
              style={[
                styles.ingredientPill,
                { backgroundColor:
                    green_array.includes(pill) || attribute_array.includes(pill)
                      ? colors.lightBackgroundGreen
                      : '#979BAA33',
                },
              ]}
            >
              <Text
                style={[
                  styles.pillText,
                  { color:
                      green_array.includes(pill) || attribute_array.includes(pill)
                        ? '#01b460'
                        : '#979BAA',
                  },
                ]}
              >
                {pill}
              </Text>
            </View>
          );
        })}
      </View>
    );
  };

  renderItem = ({ item: product, index }) => {
    const {
      item: objects = [],
      listData: { display_count = 3 } = {},
      listData,
      facialAnalysis: { my_attributes_values = [] } = {},
      previousScreen = '',
    } = this.props;
    const {
      product_category: {
        category_user_attributes: { principal = {} } = {},
       } = {},
      star_ingredients,
    } = product;

    const ingredient_for_product = Utility.findIndicationsAndCounterIndications(
      star_ingredients,
      my_attributes_values,
      principal,
    );

    if (product.type === 'variant') {
      return (
        <ErrorBoundaryComponent
          itemData={product}
          listData={listData}
          screenName={previousScreen}
        >
          <DefaultSizeVariantList
            itemData={product || {}}
            listData={listData}
            renderIngredients={() => (
              <this.renderPills
                ingredients={product?.matched_ingredients}
                star_ingredients={product?.star_ingredients}
                product={product}
                ingredient_for_product={ingredient_for_product}
              />
            )}
          />
        </ErrorBoundaryComponent>
      );
    }

    return (
      <ErrorBoundaryComponent
        itemData={product}
        listData={listData}
        screenName={previousScreen}
      >
        <DefaultSizeProductList
          itemData={product || {}}
          listData={listData}
          renderIngredients={() => (
            <this.renderPills
              ingredients={product?.matched_ingredients}
              star_ingredients={product?.star_ingredients}
              product={product}
              ingredient_for_product={ingredient_for_product}
            />
          )}
        />
      </ErrorBoundaryComponent>
    );
  };

  render() {
    const {
      item: objects = [],
      listData: { display_count = 3 } = {},
      listData,
    } = this.props;

    if (Utility.isBlank(objects[0])) {
      return null;
    }

    const initial = objects.slice(0, display_count);
    return (
      <View style={styles.container}>
        <FlatListPerformanceView listName={`${getListSource(this, this.props)}`}>
          <FlatList
            data={initial}
            keyExtractor={(_, index) => index.toString()}
            renderItem={this.renderItem}
            extraData={this.state}
            listKey={(item, index) => `${item.type}_${item.id}_list_with_ingredients`}
          />
        </FlatListPerformanceView>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  loadMoreText: {
    fontSize: 14,
    fontFamily: 'Roboto-Medium',
    color: colors.cta.lightBlue,
    // flex: 1,
    // justifyContent: 'flex-end',
    // marginRight: 12,
    // alignSelf: 'flex-end',
  },
  subTitle: {
    marginTop: 4,
    fontSize: 14,
    lineHeight: 18,
    fontFamily: 'Roboto',
    fontWeight: '400',
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.subtitle,
    flex: 1,
    alignContent: 'flex-start',
    marginBottom: 12,
  },
  title: {
    fontSize: 18,
    lineHeight: 21,
    fontFamily: 'Roboto-Bold',
    fontWeight: '700',
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.foxyBlack,
    flex: 1,
    alignContent: 'flex-start',
    marginTop: 40,
  },
  innerContainer: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: colors.background,
  },
  container: {
    backgroundColor: colors.background,
    marginHorizontal: 12,
    marginVertical: 8,
  },
  pillContainer: {
    flexDirection: 'row',
    backgroundColor: colors.white,
    // height: 18,
    marginHorizontal: 16,
    marginBottom: 4,
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignSelf: 'flex-start',
  },
  ingredientPill: {
    // paddingVertical: 5,
    paddingHorizontal: 9,
    backgroundColor: colors.lightBackgroundGreen,
    borderWidth: 0,
    borderRadius: 12,
    marginRight: 8,
    marginBottom: 8,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  pillText: {
    fontSize: 12,
    color: '#01b460',
    fontFamily: 'Roboto-Regular',
    paddingVertical: 4,
    paddingHorizontal: 8,
  },
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({ getProductPersonalizedDetails }, dispatch),
});

const mapStateToProps = (state) => ({
  facialAnalysis: state.UserAccountInfo.facialAnalysis,
});

export default withNavigation(connect(mapStateToProps, mapDispatchToProps)(ListWithIngredients));
