// Dependencies
import React, { Component } from 'react';
import { View, Image, Text, TouchableOpacity, Linking } from 'react-native';
import _ from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import { ArtistDetailStyles } from './styles';
import images from '../../theme/Images';
import colors from '../../theme/Colors';
import { LAYOUT, WHATS_APP_NUMBER } from '../../config/Constants';
import FollowArtistButton from './FollowArtistButton';
import FoxyAlert from '../camera/shared/FoxyAlert';
import ShimmerPlaceHolder from '../../libraries/ReactNativeShimmerPlaceholder';
import AnalyticsUtility from '../../analytics/AnalyticsUtility';
import FastImageView from '../FastImageView';
import { getMinifiedImage } from '../../utils/ImageUtility';

class ArtistDescriptionCard extends Component {
  artistDescriptionCardConstants = {
    alertTitle: 'Thank You',
    alertText: 'Your details have been shared with the artist',
    artistDescriptionDefaultHeight: 232,
    androidTopSpacing: 22,
  };

  callToActionConstants = {
    OPEN_YOUTUBE: 'open_youtube',
    OPEN_INSTAGRAM: 'open_instagram',
    OPEN_WHATSAPP: 'open_whatsapp',
  };
  constructor(props) {
    super(props);
    this.navigationHeaderHeight = Utility.isIOS() ? 44 + Utility.topInset : 70;
    this.state = {
      isContactModalVisible: false,
      showAlert: false,
    };
    this.visibleStats = 0;
  }

  toggleContactModal = () => {
    this.setState((prevState) => ({
      isContactModalVisible: !prevState.isContactModalVisible,
    }));
  };

  toggleSuccessAlert = () => {
    this.setState((prevState) => ({
      showAlert: !prevState.showAlert,
    }));
  };

  fireImageErrorAnalytics = () => {
    const { id, image_url, name, navigation, onScreen = '' } = this.props;
    AnalyticsUtility.fireImageErrorAnalytics(
      id,
      image_url,
      name,
      'artist',
      onScreen,
    );
  };

  actionButtonPressed = (callToAction) => {
    const { instagram_url = '', youtube_url = '', name } = this.props;
    let urlToOpen = instagram_url;
    if (callToAction.target === this.callToActionConstants.OPEN_INSTAGRAM) {
      urlToOpen = instagram_url;
    } else if (
      callToAction.target === this.callToActionConstants.OPEN_YOUTUBE
    ) {
      urlToOpen = youtube_url;
    } else {
      this.toggleContactModal();
      // Linking.openURL(
      //   `whatsapp://send?text=Hi, I want to ${callToAction.title} with ${name} &phone=${WHATS_APP_NUMBER} `,
      // );
      return;
    }
    if (Utility.isBlank(urlToOpen)) {
      return;
    }
    Linking.openURL(urlToOpen);
  };

  editTapped = () => {
    const { navigation, isArtist } = this.props;
    if (isArtist) {
      navigation.navigate('ProfileEdit', {});
    } else {
      // navigation.navigate('MyProfile', {});
    }
  };

  statsComponent = (props) => {
    let { header, count } = props;
    const styles = ArtistDetailStyles;
    if (
      Utility.isBlank(count) ||
      this.visibleStats >= 3 ||
      count === '0' ||
      count === 0
    ) {
      return null;
    } else {
      this.visibleStats += 1;
    }
    return (
      <View style={styles.statsComponentContainer}>
        <Text
          allowFontScaling={false}
          style={[styles.statsCount, { color: colors.silver }]}
        >
          {header}
        </Text>
        <Text allowFontScaling={false} style={styles.statsCount}>
          {count}
        </Text>
      </View>
    );
  };

  artistTypes = (props) => {
    let { artist_type, emoji, bio } = props;
    const { artistTypes } = this.props;
    const styles = ArtistDetailStyles;
    if (
      Utility.isBlank(artist_type) ||
      artist_type.length === 0 ||
      !_.isArray(artist_type)
    ) {
      return null;
    }
    if (Utility.isBlank(artist_type[0])) {
      return null;
    }
    if (Utility.isBlank(artistTypes)) {
      return null;
    }
    //sort artist_type based on priority
    artist_type = _.sortBy(artist_type, [
      function (artTypeId) {
        return artistTypes[artTypeId].priority;
      },
    ]);
    const id = artist_type[0];
    if (Utility.isBlank(id)) {
      return null;
    }
    const imageUrl = artistTypes[id].filled_icon;
    let artistTypeText = '';
    if (Utility.isBlank(bio)) {
      if (artist_type.length > 1) {
        artist_type.slice(0, 2).map((id, index) => {
          if (index === 0) {
            artistTypeText = `${artistTypes[id].name}`;
          } else {
            artistTypeText = `${artistTypeText}, ${artistTypes[id].name}`;
          }
        });
      } else {
        artistTypeText = artistTypes[id].name;
      }
    } else {
      artistTypeText = bio;
    }

    const artistTypeLogo = Utility.isBlank(emoji) ? imageUrl : emoji;
    return (
      <View
        style={[styles.artistDesignationContainer, { width: 200, height: 16 }]}
      >
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <Image
            source={{
              uri: getMinifiedImage(
                artistTypeLogo,
                styles.smallLogo.width,
                styles.smallLogo.height,
              ),
            }}
            style={styles.smallLogo}
          />
          <Text style={styles.descriptionText}>{artistTypeText}</Text>
        </View>
      </View>
    );
  };

  artistLocation = (props) => {
    const styles = ArtistDetailStyles;
    let { location } = this.props;
    if (Utility.isBlank(location)) {
      location = 'India';
    }
    return (
      <View style={styles.artistAddressContainer}>
        <Image source={images.currentLocation} style={styles.smallLogo} />
        <Text style={styles.descriptionText}>{location}</Text>
      </View>
    );
  };

  artistBio = (props) => {
    const styles = ArtistDetailStyles;
    const { bio } = props;
    return (
      <View style={{ width: '100%' }}>
        <Text style={styles.artistBio}>{bio}</Text>
      </View>
    );
  };

  render() {
    const styles = ArtistDetailStyles;
    const {
      id,
      banner_image_url,
      image_url,
      name,
      artist_type,
      address,
      youtube_url,
      instagram_url,
      followers_count = '',
      subscribers_count = '',
      video_count = '',
      views_count = '',
      slug,
      bio,
      location,
      insta_posts_count,
      emoji,
    } = this.props;

    let { call_to_action } = this.props;
    const { isContactModalVisible, showAlert } = this.state;
    if (
      Utility.isBlank(call_to_action) ||
      Utility.isBlank(call_to_action.title)
    ) {
      if (!Utility.isBlank(instagram_url)) {
        call_to_action = {
          title: 'DM on Instagram',
          target: 'open_instagram',
        };
      } else {
        call_to_action = {
          title: 'Subscribe on YouTube',
          target: 'open_youtube',
        };
      }
    }
    let actionButtonColor =
      Utility.isBlank(call_to_action) || Utility.isBlank(call_to_action.color)
        ? colors.green
        : call_to_action.color;
    if (call_to_action.target === 'open_youtube') {
      actionButtonColor = colors.youtube;
    } else if (call_to_action.target === 'open_instagram') {
      actionButtonColor = colors.instagram;
    }
    this.visibleStats = 0;
    let artistDescriptionCardHeight =
      this.artistDescriptionCardConstants.artistDescriptionDefaultHeight +
      Utility.topInset;
    if (Utility.isAndroid()) {
      artistDescriptionCardHeight +=
        this.artistDescriptionCardConstants.androidTopSpacing;
    }
    return (
      <>
        <View
          style={[styles.bannerImage, { height: artistDescriptionCardHeight }]}
        >
          <View
            style={[styles.infoContainer, { marginTop: this.navigationHeaderHeight + 16 }]}
          >
            <View style={styles.artistDescription}>
              <FastImageView
                source={{
                  uri: getMinifiedImage(image_url, 72, 72),
                }}
                style={styles.artistAvatar}
                resizeMode='cover'
                onError={this.fireImageErrorAnalytics}
              />
              <View style={styles.artistNameAndAddress}>
                {Utility.isPresent(name) ? (
                  <Text style={styles.artistName}>{name}</Text>
                ) : (
                  <ShimmerPlaceHolder
                    autoRun
                    style={{ height: 16, width: 100 }}
                  />
                )}

                <this.artistTypes
                  artist_type={artist_type}
                  emoji={emoji}
                  bio={bio}
                />
                <this.artistLocation address={location} />
              </View>
            </View>
            {/* <TouchableOpacity onPress={this.editTapped} style={styles.editButton}>
              <Image source={images.edit} style={styles.editButtonImage}></Image>
           </TouchableOpacity> */}
            <View style={styles.artistInsightContainer}>
              <this.statsComponent
                header='Subscribers'
                count={subscribers_count}
              />
              <this.statsComponent header='Followers' count={followers_count} />
              <this.statsComponent header='Views' count={views_count} />
              <this.statsComponent header='Videos' count={video_count} />
              <this.statsComponent header='Posts' count={insta_posts_count} />
              <FollowArtistButton
                id={id}
                followButtonLayout={LAYOUT.SCREEN}
                slug={slug}
              />
            </View>
          </View>
          {Utility.isBlank(youtube_url) &&
          Utility.isBlank(instagram_url) &&
          Utility.isBlank(this.props.call_to_action) ? (
            <ShimmerPlaceHolder
              autoRun
              style={[styles.bookNowButton, { backgroundColor: colors.background }]}
            />
          ) : (
            <TouchableOpacity
              style={[styles.bookNowButton, { backgroundColor: actionButtonColor }]}
              onPress={() => this.actionButtonPressed(call_to_action)}
            >
              <Text style={styles.bookNowText}>
                {(call_to_action && call_to_action.title) || 'Book a service'}
              </Text>
            </TouchableOpacity>
          )}
        </View>

        <FoxyAlert
          isVisible={showAlert}
          alertBoxTitle={this.artistDescriptionCardConstants.alertTitle}
          alertMessage={this.artistDescriptionCardConstants.alertText}
          firstButtonTitle={'Ok'}
          firstButtonTextColor={colors.dodgerBluePressed}
          firstButtonOnPress={this.toggleSuccessAlert}
          textAlignment='left'
          hideSecondButton={true}
          height={160}
        />
      </>
    );
  }
}

const mapStateToProps = (store, ownProps) => ({
  artistTypes: store.UserAccountInfo.artistTypes,
  isArtist: store.UserAccountInfo.artistProfile.isArtist || false,
});

export default withNavigation(
  connect(mapStateToProps, null)(ArtistDescriptionCard),
);
