// Dependencies
import React, { Component } from 'react';
import { Image } from 'react-native';
import { connect } from 'react-redux';
import Config from '../../libraries/ReactNativeConfig';
import { bindActionCreators } from 'redux';
import styles from './styles';
import Utility from '../../utils/Utility';
import { setPhoneNumberModalInfo } from '../../actions/LoginActions';
import DebouncedTouchableOpacity from '../shared/DebouncedTouchableOpacity';
import images from '../../theme/Images';
import {
  AnalyticsManager,
  EventParameterKey,
  EventType,
} from '../../analytics';
import { REMOTE_CONFIG_KEYS } from '../../config/Constants';
import RemoteConfig from '../../utils/RemoteConfig';
import WithNavigation from '../../utils/WithNavigation';
import NavigationService from '../../navigator/NavigationService';
import { navigateToScreen } from '../../utils/NavigationUtility';
import { getMinifiedImage } from '../../utils/ImageUtility';

class WishlistIcon extends Component {
  handleTap = () => {
    const { navigation, authToken, setPhoneNumberModalInfo } = this.props;
    AnalyticsManager.logEvent(EventType.profileEvents.WISHLIST_ICON_CLICK, {
      [EventParameterKey.IS_GUEST_USER]: Utility.isBlank(authToken),
    });
    if (Utility.isBlank(authToken)) {
      setPhoneNumberModalInfo(Utility.getLoginModalInfo('WISHLIST_VIEW'));
      NavigationService.renderOnboarding({
        parentSkipAction: this.navigateToWishlist,
        furtherAction: this.navigateToWishlist,
      });
      Utility.invokeSelfieFlowAfterLoginForGuest(false);
      return;
    }
    navigateToScreen({
      navigation,
      type: 'push',
      screen: 'TodayDeals',
      params: {
        source: 'navigation_header',
      },
    });
  };

  navigateToWishlist = () => {
    const { navigation } = this.props;
    navigateToScreen({
      navigation,
      type: 'push',
      screen: 'TodayDeals',
    });
  }

  render() {
    const imageStyle = Utility.isPresent(Config.WISHLIST_HEADER_ICON_TINT_COLOR)
      ? styles.notificationStyleWithTint
      : styles.notificationStyle;
    const imageUrl = Utility.isPresent(Config.WISHLIST_HEADER_ICON_URL)
      ? {
          uri: getMinifiedImage(
            Config.WISHLIST_HEADER_ICON_URL,
            imageStyle.width,
            imageStyle.height,
          ),
        }
      : images.heartIcon;
    return (
      <DebouncedTouchableOpacity
        {...this.props}
        onPress={this.handleTap}
        hitSlop={Utility.getHitSlop()}
      >
        <Image source={imageUrl} style={imageStyle} testID="header-wishlist-icon" accessibilityLabel="header-wishlist-icon" />
      </DebouncedTouchableOpacity>
    );
  }
}

const mapStateToProps = (state) => ({
  cartItems: state.bag?.cartItems,
  cartPricing: state.bag?.cartPricing,
  addresses: state.UserAccountInfo?.addresses,
  name: state.UserAccountInfo?.profile?.name,
  authToken: state.UserAccountInfo.authToken,
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      setPhoneNumberModalInfo,
    },
    dispatch,
  ),
});

export default WithNavigation(
  connect(mapStateToProps, mapDispatchToProps)(WishlistIcon),
);
