// Dependencies
import React, { Component } from 'react';
import { View, TouchableOpacity, Image, Text, StyleSheet } from 'react-native';
import PropTypes from 'prop-types';
import { memoize } from 'lodash';
import { BagIcon, TabNavigationRightHeader } from '../header';
import Images from '../../theme/Images';
import colors from '../../theme/Colors';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import { sharedStyles } from './styles';
import { MAX_LINES } from '../../config/Constants';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import { isIOS } from '../../utils/BooleanUtility';
import { getHitSlop } from '../../utils/LayoutUtility';

class ProductDescriptionHeader extends Component {
  constructor(props) {
    super(props);
    this.navigationHeaderHeight = isIOS() ? 44 + Utility.topInset : 56;
  }

  goBack = () => {
    const { navigation, customBack = false, onBackPress } = this.props;
    if (customBack) {
      onBackPress();
      return;
    }
    navigation.goBack();
  };

  onHardwareBackKeyPress = () => {
    this.goBack();
    return true;
  };

  rightButton = () => {
    const { showRightButton = false, RightButton } = this.props;
    if (!showRightButton) {
      return null;
    }
    return <RightButton />;
  };

  navigationToCart = () => {
    const { navigation } = this.props;
    navigation.push('Cart');
  };

  cartButtonContainer = (props) => {
    return (
      <View style={headerStyle.cartButton}>
        <BagIcon handleTap={this.navigationToCart} showCart />
      </View>
    );
  };

  onSearchIconTap = () => {
    const { navigation } = this.props;
    navigation.navigate('Search');
  };

  searchButtonContainer = (props) => {
    return (
      <TouchableOpacity
        onPress={this.onSearchIconTap}
        style={headerStyle.shareButton}
        hitSlop={getHitSlop()}
      >
        <Image
          source={Images.ic_search_product}
          style={sharedStyles.navbarShareIconStyle}
        />
      </TouchableOpacity>
    );
  };

  render() {
    const {
      navigation,
      title,
      skipDivider,
      previousScreen,
      onBackPress,
      bgColor = colors.white,
      overrideBackButtonBgColor = false,
      showCart,
      showSearchButton,
      transparent = false,
    } = this.props;
    const styles = sharedStyles;
    const backButtonColorBg = overrideBackButtonBgColor
      ? { backgroundColor: '#00000000' }
      : { backgroundColor: colors.white };
    return (
      <>
        <View
          style={memoize(
            () => ([
              headerStyle.container,
              { backgroundColor: transparent ? 'transparent' : bgColor },
            ]),
            () => [transparent, bgColor],
          )()}
        >
          <TouchableOpacity
            onPress={
              typeof onBackPress === 'function' ? onBackPress : this.goBack
            }
            style={memoize(
              () => ([
                styles.backButtonContainer,
                backButtonColorBg,
              ]),
              () => [overrideBackButtonBgColor],
            )()}
            hitSlop={getHitSlop('button')}
          >
            <Image
              source={Images.ic_back_product}
              style={styles.navbarIconBlackStyle}
            />
          </TouchableOpacity>
          <Text
            numberOfLines={MAX_LINES.single}
            allowFontScaling={false}
            style={styles.pageHeaderText}
          >
            {`${title}`}
          </Text>
          {previousScreen === SCREEN_CONSTANTS.SEARCH && (
            <View style={styles.tabNavigationRightHeaderContainer}>
              <TabNavigationRightHeader showCart={false} moreSearchPage />
            </View>
          )}
          {showCart && <this.cartButtonContainer />}
          {showSearchButton && <this.searchButtonContainer />}
          <this.rightButton />
          {!skipDivider && <View style={styles.divider} />}
        </View>
      </>
    );
  }
}

const headerStyle = StyleSheet.create({
  container: {
    width: '100%',
    zIndex: 1,
    flexDirection: 'row',
    height: isIOS() ? 44 + Utility.topInset : 56,
    paddingTop: isIOS() ? Utility.topInset + 30 : 52,
    backgroundColor: colors.white,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    alignItems: 'center',
    justifyContent: 'center',
  },
  shareButton: {
    position: 'absolute',
    width: 32,
    height: 32,
    borderRadius: 16,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(255, 255, 255,0.8)',
    bottom: isIOS() ? 8 : 12,
    right: 50,
  },
  cartButton: {
    position: 'absolute',
    width: 32,
    height: 32,
    borderRadius: 16,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(255, 255, 255,0.8)',
    bottom: isIOS() ? 8 : 12,
    right: 12,
  },
});

export default withNavigation(ProductDescriptionHeader);

ProductDescriptionHeader.defaultProps = {
  skipDivider: false,
};

ProductDescriptionHeader.PropTypes = {
  skipDivider: PropTypes.bool,
};
