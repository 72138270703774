import React, { PureComponent } from 'react';
import { Text, View, Image, StyleSheet } from 'react-native';
import { bindActionCreators } from 'redux';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { connect } from 'react-redux';
import withNavigation from '../../../utils/WithNavigation';
import colors from '../../../theme/Colors';
import Utility from '../../../utils/Utility';
import { updateOrder } from '../../../actions/OrderActions';
import { CartStyles } from '../../cart/styles';
import { AnalyticsManager } from '../../../analytics';
import withProfiledNavigation from '../../../utils/withProfiledNavigation';
import { getNavigationSource } from '../../../utils/PerfUtility';

class OrderAddress extends PureComponent {

  constructor(props) {
    super(props);
    this.navigationSource = getNavigationSource(this, this.props);
  }

  updateOrderAddressAndRefresh = (id) => {
    const { updateOrder, orderId, onRefresh = () => {} } = this.props;
    const body = {
      address_id: id,
      update_order_address: true,
    };
    updateOrder(orderId, body, () => {
      onRefresh();
    });
  };

  onPress = () => {
    const { id = {}, item = {}, navigation } = this.props;
    AnalyticsManager.logEvent('card_action', {
      entity_type: id.split('-')[0],
      entity_id: id.split('-')[1],
      card_id: item.id,
      button: 'primary',
    });
    navigation.push(this.navigationSource, 'Address', {
      updateOrderAddressAndRefresh: this.updateOrderAddressAndRefresh,
      previousScreen: 'edit_order_address',
    });
  };

  render() {
    const { orderAddress, orderAasmState } = this.props;
    {
      if (Utility.isBlank(orderAddress)) {
        return null;
      }

      const styles = CartStyles;
      if (Utility.isBlank(orderAddress)) {
        return null;
      }
      let {
        name = 'Others',
        line1 = '',
        line2 = '',
        pincodeId = '',
        contactName = '',
      } = orderAddress;
      const locationIcon = Utility.getAddressIcon(name);

      let actualContactName = contactName;
      if (
        name === 'Store' &&
        (Utility.isBlank(contactName) || contactName === null)
      ) {
        actualContactName = name;
      }
      actualContactName = actualContactName ? `${actualContactName} : ` : '';
      return (
        <View style={AddressStyles.container}>
          {orderAasmState === 'confirmed' && (
            <View style={styles.addressTextContainer}>
              <Text style={styles.addressHeaderText}>Shipping Address</Text>
              <TouchableOpacity
                onPress={this.onPress}
                style={AddressStyles.changeButtonContainer}
              >
                <Text style={AddressStyles.changeButton}>Change</Text>
              </TouchableOpacity>
            </View>
          )}

          <View style={styles.individualAddressContainer}>
            <View style={styles.userAddressTextContainer}>
              <Image style={AddressStyles.addressIcon} source={locationIcon} />
              <Text style={AddressStyles.address}>
                {`${actualContactName}${line1 || ''}${
                  line2 || ''
                }, ${pincodeId}`}
              </Text>
            </View>
            <View />
          </View>
        </View>
      );
    }
  }
}

const AddressStyles = StyleSheet.create({
  container: { marginTop: 3, marginBottom: 4, backgroundColor: colors.white },
  changeButtonContainer: { marginRight: 12 },
  changeButton: {
    fontSize: 14,
    fontFamily: 'Roboto-Medium',
    color: colors.cta.lightBlue,
  },
  addressIcon: {
    width: 20,
    height: 16,
    resizeMode: 'contain',
    tintColor: colors.black,
    marginRight: 8,
    marginTop: 2,
  },
  address: {
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
    color: colors.foxyBlack,
  },
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      updateOrder,
    },
    dispatch,
  ),
});

export default withProfiledNavigation(
  connect(null, mapDispatchToProps)(OrderAddress),
);
