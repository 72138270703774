import { ActivityIndicator, StyleSheet, Text, View } from 'react-native';
import React from 'react';
import Barcode from '@kichiyaki/react-native-barcode-generator';
import { useApiCall } from '../../lib/hooksUtil';
import { API_DOMAIN } from '../../config/Constants';
import FoxyShadowButton from '../../lib/FoxyShadowButton';
import Utility from '../../utils/Utility';
import HTMLView from 'react-native-htmlview';
import FastImageView from '../FastImageView';
import { TouchableOpacity } from 'react-native-gesture-handler';

import colors from '../../theme/Colors';
import { useNavigation } from '@react-navigation/native';
import images from '../../theme/Images';
import OfferCounter from '../../containers/offer/OfferCounter';
import _ from 'lodash';
import DebouncedTouchableOpacity from '../shared/DebouncedTouchableOpacity';
import { DefaultSizeOfferPromptListing } from '../layout/offerPrompt';
import size from '../../theme/Fonts';
import { BulletPoint } from '../feature/CustomerID';
import { isIOS, isDesktop } from '../../utils/BooleanUtility';
import WebView from 'react-native-webview';

const GenericOfferNew = (props) => {
  const {
    route: {
      params: { slug = '', isStoreOffer = false, coupon_code = '' } = {},
    } = {},
  } = props;
  const navigation = useNavigation();

  const apiUrl = `${API_DOMAIN}${slug}`;
  const { data, isLoading } = useApiCall(apiUrl, {});
  const { tnc } = data;

  const goBack = () => {
    navigation.goBack();
  };

  const htmlData = {
    html: HTML_DATA.replace('RICH_CONTENT_HTML_DATA', tnc),
  };

  const bottomModalStyle = isDesktop() ? styles.bottomModalDesktop : styles.bottomModal;
  const headerRowStyle = isDesktop() ? styles.headerRowDesktop : styles.headerRow;

  if (isStoreOffer) {
    return (
      <>
      <DebouncedTouchableOpacity onPress={goBack}>
        <View style={styles.touchableContainer} />
      </DebouncedTouchableOpacity>
      <View style={styles.modalContainer} pointerEvents='box-none'>
        <View style={styles.bottomModal}>
          <View style={styles.headerRow}>
            <Text style={styles.offerHeaderText}>Exclusive store offer for you!</Text>
            <TouchableOpacity
              style={styles.modal_close_container}
              onPress={goBack}
            >
              <FastImageView
                style={styles.modal_close}
                source={images.edge.modal_close}
              />
            </TouchableOpacity>
          </View>
          <Text style={styles.subheadingStyle}>Scan barcode at the store to get discount!</Text>
          <Barcode value={coupon_code} format="CODE128" style={styles.barcode} />
          <Text style={styles.storeOfferCode}>Coupon code: {coupon_code}</Text>
          <WebView
            source={htmlData}
            containerStyle={styles.webviewContainerStoreOffer}
          />
        </View>
      </View>
      </>
    );
  }

  return (
    <>
      <DebouncedTouchableOpacity onPress={goBack}>
        <View style={styles.touchableContainer} />
      </DebouncedTouchableOpacity>
      <View style={styles.modalContainer} pointerEvents='box-none'>
        <View style={bottomModalStyle}>
          <View style={headerRowStyle}>
            <Text style={styles.offerHeaderText}>Hey, you’ve got an offer</Text>
            <TouchableOpacity
              style={styles.modal_close_container}
              onPress={goBack}
            >
              <FastImageView
                style={styles.modal_close}
                source={images.edge.modal_close}
              />
            </TouchableOpacity>
          </View>
          {!isLoading ? (
            <>
              <DefaultSizeOfferPromptListing
                item={{...data, is_generic: true}}
                inheritStyles={styles}
                index={Math.floor(Math.random() * 18)}
                showSubHeading
                fromGenericOfferModal
              />
              <WebView
                source={htmlData}
                containerStyle={styles.webviewContainer}
              />
            </>
          ) : (
            <View style={styles.loadingContainer}>
              <ActivityIndicator size={'large'} color={colors.foxyBlack} />
              <Text style={styles.loadingText}>Loading..</Text>
            </View>
          )}
          <FoxyShadowButton
            width={Utility.getScreenWidth() - 24}
            title={'Continue shopping'}
            onPress={goBack}
            underlayColor={colors.translucent}
            backgroundColor={colors.black}
            style={styles.foxyShadowButtonStyle}
            textStyle={styles.foxyShadowButtonText}
            firstColor={colors.black}
            secondColor={colors.black}
          />
        </View>
      </View>
    </>
  );
};

export default GenericOfferNew;

const styles = StyleSheet.create({
  couponContainer: {
    backgroundColor: '#FFE4F4',
    width: Utility.getScreenWidth() - 60,
    height: 94,
    borderRadius: 16,
    paddingVertical: 8,
    justifyContent: 'space-between',
    paddingHorizontal: 12,
    alignSelf: 'center',
    marginHorizontal: 12,
    paddingLeft: 52,
    marginLeft: 35,
    marginTop: 12,
  },
  cardIcon: { height: 42, width: 42 },
  container: { backgroundColor: 'white', flex: 1 },
  contentContainer: {
    backgroundColor: colors.white,
    paddingHorizontal: 12,
    paddingVertical: 8,
    flex: 1,
    marginTop: 28,
  },
  circle: {
    height: 72,
    width: 72,
    borderRadius: 36,
    position: 'absolute',
    top: 10,
    left: -28,
    alignItems: 'center',
    justifyContent: 'center',
  },
  maxDiscount: { fontSize: 20, fontFamily: 'Roboto-Bold', color: '#173143' },
  headingStyle: {
    fontSize: 18,
    fontFamily: 'Roboto-Bold',
    fontStyle: 'normal',
    color: colors.foxyBlack,
  },
  subheadingStyle: {
    fontSize: 12,
    fontFamily: 'Roboto-Regular',
    fontStyle: 'normal',
    color: colors.foxyBlack,
    marginTop: -8,
  },
  timerText: {
    fontSize: 12,
    fontFamily: 'Roboto-Regular',
    color: colors.foxyBlack,
    marginVertical: 4,
  },
  digitStyle: {
    backgroundColor: colors.black,
    borderRadius: 2,
  },
  digitTxtStyle: {
    color: colors.white,
    fontSize: size.h5,
    fontFamily: 'Roboto-Regular',
  },
  timeLabelStyle: {
    color: colors.black,
    fontFamily: 'Roboto-Regular',
    fontSize: size.h5,
    marginTop: 0,
    marginLeft: 10,
  },
  separatorStyle: {
    color: colors.black,
  },
  countdownText: {
    color: colors.black,
    fontFamily: 'Roboto-Regular',
    fontSize: size.h6,
  },
  innerContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  bottomContainer: { marginBottom: 4 },
  validOnProduct: {
    fontSize: 10,
    fontFamily: 'Roboto-Medium',
    color: colors.subtitle,
    marginBottom: 6,
  },
  buttonContainer: { width: 91, height: 22 },
  headerContainer: {
    height: 152,
    width: Utility.getScreenWidth(),
    backgroundColor: '#B5FFF240',
    borderBottomRightRadius: 32,
  },
  heading: { flexDirection: 'row', alignSelf: 'center' },
  walletImage: { height: 24, width: 24 },
  headingText: {
    fontSize: 18,
    fontFamily: 'Roboto-Bold',
    color: colors.foxyBlack,
  },
  subheadingText: {
    fontSize: 12,
    fontFamily: 'Roboto-Regular',
    marginTop: 4,
    alignSelf: 'center',
  },
  totalBalanceText: {
    fontSize: 12,
    fontFamily: 'Roboto-Regular',
    color: colors.subtitle,
    marginTop: 12,
    alignSelf: 'center',
  },
  balance: {
    fontSize: 32,
    fontFamily: 'Roboto-Bold',
    color: colors.green,
    alignSelf: 'center',
  },
  bottomButtonContainer: { alignItems: 'flex-end', flex: 1, marginBottom: -2 },
  touchableContainer: {
    width: '100%',
    height: '100%',
  },
  modalContainer: {
    height: '100%',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: colors.translucent,
  },
  bottomModal: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    marginBottom: 0,
    marginRight: 0,
    marginLeft: 0,
    paddingHorizontal: 12,
    paddingBottom: isIOS() ? Utility.bottomInset + 12 : 24,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    backgroundColor: colors.white,
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    zIndex: 1,
  },
  bottomModalDesktop: {
    position: 'absolute',
    bottom: 0,
    marginBottom: 0,
    paddingHorizontal: 12,
    paddingBottom: isIOS() ? Utility.bottomInset + 12 : 24,
    backgroundColor: colors.white,
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    zIndex: 1,
    alignSelf: 'center',
  },
  headerRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    flex: 1,
    width: Utility.getScreenWidth() - 24,
  },
  headerRowDesktop: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    flex: 1,
  },
  offerHeaderText: {
    fontSize: 18,
    color: colors.foxyBlack,
    fontFamily: 'Roboto-Bold',
    marginTop: 12,
    marginBottom: 12,
  },
  modal_close_container: {
    zIndex: 1,
    top: 12,
  },
  modal_close: {
    height: 23,
    width: 23,
    resizeMode: 'contain',
  },

  couponDetailRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  validText: {
    fontSize: 12,
    fontFamily: 'Roboto-Medium',
    color: colors.foxyBlack,
    marginBottom: 4,
  },

  foxyShadowButtonStyle: {
    marginTop: 16,
    zIndex: 2,
  },
  foxyShadowButtonText: {
    color: colors.white,
    fontSize: 14,
    fontFamily: 'Roboto-Regular',
  },

  loadingContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
    height: 300,
  },
  loadingText: {
    fontSize: 14,
    fontFamily: 'Roboto-Regular',
    color: colors.foxyBlack,
    marginTop: 12,
  },
  barcode: {
    marginVertical: 18,
    marginBottom: 4,
    alignSelf: 'center',
  },
  webviewContainer: {
    height: 180,
    width: '110%',
    marginLeft: -24,
  },
  webviewContainerStoreOffer: {
    height: 140,
    width: '110%',
    marginLeft: -24,
  },
  storeOfferCode: {
    fontSize: 12,
    fontFamily: 'Roboto-Regular',
    fontStyle: 'normal',
    color: colors.foxyBlack,
    alignSelf: 'center',
  },
});

const HTML_DATA = `<!DOCTYPE html>
<html>
<head>
  <meta content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0" name="viewport">
    <style>
      body, html, li { font-size: 15px }
    </style>
  </head>
  <body>
    <div id="height-calculator">
      RICH_CONTENT_HTML_DATA
    </div>
  </body>
</html>`;
