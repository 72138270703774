// Dependencies
import React, { Component } from 'react';
import { View, Image, ImageBackground, Text } from 'react-native';
import PropTypes from 'prop-types';
import lodash from 'lodash';
import { withMaybe } from '../../lib/Monads';
import Card from '../../lib/Card';
import {
  LAYOUT,
  ORIENTATION,
  THEME,
  MAX_LINES,
  SIZE,
} from '../../config/Constants';
import fontSize from '../../theme/Fonts';
import colors from '../../theme/Colors';
import { ProfileStyles } from './styles';
import { MEDIA } from '../../config/LayoutConstants/ContentMediaConfig';
import images from '../../theme/Images';
import ScaleAnimate from '../shared/ScaleAnimate';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import { MediaCard } from '../media';
import FastImageView from '../FastImageView';
import { getMinifiedImage } from '../../utils/ImageUtility';

class UserPostCard extends Component {
  mediaImage = (props) => {
    const { type, imageUrl, size } = props;
    const styles = ProfileStyles;
    const imageIcon = images[`${type}Icon`];
    let height = (9 / 16) * Utility.getDynamicWidthForGrid(2, 16);
    let width = Utility.getDynamicWidthForGrid(2, 16);
    if (size === 'small') {
      height = MEDIA.CARD.rail.small.imageheight;
      width = MEDIA.CARD.rail.small.imagewidth;
    }
    return (
      <FastImageView
        source={getMinifiedImage(imageUrl, styles.mediaImageStyle.width, styles.mediaImageStyle.height)}
        style={[styles.mediaImageStyle, { height, width }]}
        resizeMode="cover"
      >
        <Image
          source={imageIcon}
          resizeMode='cover'
          style={styles.contentTypeIndicatorStyle}
        />
      </FastImageView>
    );
  };

  mediaDescription = (props) => {
    const styles = ProfileStyles;
    const { description } = props;
    return (
      <View style={styles.mediaDescriptionContainer}>
        <Text numberOfLines={MAX_LINES.two} style={styles.cardTextStyle}>
          {description}
        </Text>
      </View>
    );
  };

  listCard = (props) => {
    const { itemData } = props;
    return <MediaCard itemData={itemData} layout='list' hideDivider />;
  };

  render() {
    const styles = ProfileStyles;
    const { itemData, onClick, size, vertical = false } = this.props;
    const { metadata: { title = ' ', viewCount = ' ' } = {} } = itemData;
    let height = (9 / 16) * Utility.getDynamicWidthForGrid(2, 16) + 44; // 44 for description
    let width = Utility.getDynamicWidthForGrid(2, 16);
    if (size === 'small') {
      height = MEDIA.CARD.rail.small.viewheight;
      width = MEDIA.CARD.rail.small.viewwidth;
    }
    if (vertical) {
      return <this.listCard />;
    }
    return (
      <ScaleAnimate {...this.props} onPress={onClick}>
        <View>
          <Card
            cornerRadius={4}
            borderWidth={1}
            style={[styles.userPostCardContainer, { height, width }]}
          >
            <this.mediaImage
              imageUrl={itemData.image_url}
              type={itemData.type}
              size={size}
            />
            <this.mediaDescription description={title || ''} />
          </Card>
        </View>
      </ScaleAnimate>
    );
  }
}

// PropTypes
UserPostCard.propTypes = {
  id: PropTypes.string,
  layout: PropTypes.string,
  size: PropTypes.string,
  itemData: PropTypes.shape({
    image_url: PropTypes.string,
    id: PropTypes.string,
  }),
  theme: PropTypes.string,
  listId: PropTypes.string,
  onClick: PropTypes.func,
};

UserPostCard.defaultProps = {
  theme: THEME.LIGHT,
  listId: null,
  onClick: () => {},
};

export default withNavigation(UserPostCard);
