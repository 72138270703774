import React, { Component } from 'react';
import { getStoreRef } from '../../store/StoreUtility';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '../../config/Constants';
import PaymentsWaiting from './paymentStatus/PaymentsWaiting';

class AwaitingPaymentOrchestrator extends Component {
  constructor(props) {
    super(props);
    const { route: { params: { previousScreen = '', slug = '' } = {} } = {} } =
      this.props;
    this.previousScreen = previousScreen;
    this.slug = slug;
  }

  getCartItemsForPartialCart = () => {
    const { route: { params: { cartItems = [] } = {} } = {} } = this.props;
    return cartItems || {};
  };

  getCartPricingForPartialCart = () => {
    const { route: { params: { cartPricing = {} } = {} } = {} } = this.props;
    return cartPricing || {};
  };

  getCartItems = () => {
    const state = getStoreRef().getState();
    const { bag: { cartItems = EMPTY_ARRAY } = EMPTY_OBJECT } = state;
    return cartItems;
  };

  getCartPricing = () => {
    const state = getStoreRef().getState();
    const { bag: { cartPricing = EMPTY_ARRAY } = EMPTY_OBJECT } = state;
    return cartPricing;
  };

  render() {
    const {
      route: {
        params: {
          extraParametersForPartialCart: {
            showPaymentsForPartialCart = false,
          } = {},
        } = {},
      } = {},
    } = this.props;
    const cartItemForPartialCart = this.getCartItemsForPartialCart();
    const CartPricingForPartialCart = this.getCartPricingForPartialCart();
    if (showPaymentsForPartialCart) {
      return (
        <PaymentsWaiting
          {...this.props}
          cartItems={cartItemForPartialCart}
          cartPricing={CartPricingForPartialCart}
          showPaymentsForPartialCart
        />
      );
    }
    return (
      <PaymentsWaiting
        {...this.props}
        cartItems={this.getCartItems()}
        cartPricing={this.getCartPricing()}
      />
    );
  }
}

export default AwaitingPaymentOrchestrator;
