import {
  Text,
  View,
  TouchableOpacity,
  Linking,
  StyleSheet,
} from 'react-native';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Config from '../../../libraries/ReactNativeConfig';
import { bindActionCreators } from 'redux';
import { getCancelReason } from '../../../actions/ActionTypes';
import { heldOrder } from '../../../actions/OrderActions';
import withNavigation from '../../../utils/WithNavigation';
import { StaticNavigationHeader } from '../../../components/shared';
import FastImageView from '../../../components/FastImageView';
import FoxyShadowButton from '../../../lib/FoxyShadowButton';
import colors from '../../../theme/Colors';
import Utility from '../../../utils/Utility';
import images from '../../../theme/Images';
import {
  AnalyticsManager,
  EventParameterKey,
  EventType,
} from '../../../analytics';
import { SCREEN_CONSTANTS } from '../../../config/ScreenConstants';
class CancelOrder extends PureComponent {
  constructor(props) {
    super(props);
    const { navigation, route = {} } = this.props;
    this.headerTitle = route.params?.headerTitle;
    this.cancelReasonId = route.params?.cancelReasonId;
    this.orderId = route.params?.orderId;
    this.entityType = route.params?.entityType;
    const {
      title,
      heading,
      subHeading,
      primaryCtaText,
      secondaryCtaText,
      imagesUrl,
    } = route?.params || {};
    this.title = title || 'Cancel Order';
    this.heading = heading || 'Are you sure you want to cancel? 😔';
    this.subHeading = subHeading || 'We are really sad to see you go.';
    this.primaryCtaText = primaryCtaText || 'No, I changed my mind';
    this.secondaryCtaText = secondaryCtaText || 'Yes, cancel';
    this.imagesUrl = imagesUrl || images.cancel_order_image;
  }

  onPressAddEmail = (emailId) => {
    const { userEmail, navigation, heldOrder } = this.props;
    AnalyticsManager.logEvent(EventType.discoveryEvents.ITEM_ACTION, {
      [EventParameterKey.ENTITY_ID]: this.orderId,
      [EventParameterKey.ENTITY_TYPE]: this.entityType,
      [EventParameterKey.PAGE]: 'cancel_order',
      [EventParameterKey.CTA]: 'add_my_email',
    });
    const data = {
      entity_id: this.orderId,
      cancellation_reason: this.cancelReasonId,
    };

    heldOrder(data, (success) => {
      if (success) {
        AnalyticsManager.logEvent(EventType.discoveryEvents.ITEM_ACTION, {
          source: 'held_order',
        });
        navigation.navigate('ConfirmRequestPage', {
          heading: 'We are bummed about this! 😔',
          subHeading:
            'Your order has been put on hold. Our customer care will call you soon to confirm the cancellation of items.',
        });
      } else {
        AnalyticsManager.logEvent(
          EventType.appLifeCycleEvents.EXCEPTION_CAPTURED,
          {
            source: 'held_order',
          },
        );
      }
    });
  };

  showEmailModal = () => {
    const { navigation } = this.props;
    navigation.navigate('GetEmailForCallbackRequestModal', {
      onPressAddEmail: this.onPressAddEmail,
    });
  };

  onPressPrimaryActionButton = () => {
    const { navigation } = this.props;
    AnalyticsManager.logEvent(EventType.discoveryEvents.ITEM_ACTION, {
      [EventParameterKey.ENTITY_ID]: this.orderId,
      [EventParameterKey.ENTITY_TYPE]: this.entityType,
      [EventParameterKey.PAGE]: 'cancel_order',
      [EventParameterKey.CTA]: 'no_i_changed_my_mind',
    });
    const screenName =
      Config.ORDER_PAGE_TYPE === 'shopify'
        ? 'ShopifyOrderDetails'
        : 'OrderDetails';
    navigation.navigate(screenName, { id: this.orderId });
  };

  onPressSecondaryActionButton = () => {
    const {
      userEmail,
      navigation,
      heldOrder,
      route: { params: { secondaryCtaAction } = {} } = {},
    } = this.props;
    if (Utility.isBlank(userEmail)) {
      this.showEmailModal();
      return;
    }
    if (secondaryCtaAction) {
      secondaryCtaAction();
      return;
    }
    AnalyticsManager.logEvent(EventType.discoveryEvents.ITEM_ACTION, {
      [EventParameterKey.ENTITY_ID]: this.orderId,
      [EventParameterKey.ENTITY_TYPE]: this.entityType,
      [EventParameterKey.PAGE]: 'cancel_order',
      [EventParameterKey.CTA]: 'yes_cancel',
    });
    const data = {
      entity_id: this.orderId,
      cancellation_reason: this.cancelReasonId,
    };

    heldOrder(data, (success) => {
      if (success) {
        AnalyticsManager.logEvent(EventType.discoveryEvents.ITEM_ACTION, {
          source: 'held_order',
        });
        navigation.navigate('ConfirmRequestPage', {
          headerTitle: 'Cancel Order',
          heading: 'We are bummed about this! 😔',
          subHeading:
            'Your order has been put on hold. Our customer care will call you soon to confirm the cancellation of items.',
        });
      } else {
        AnalyticsManager.logEvent(
          EventType.appLifeCycleEvents.EXCEPTION_CAPTURED,
          {
            source: 'held_order',
          },
        );
      }
    });
  };

  render() {
    return (
      <View>
        <StaticNavigationHeader
          onBackPress={this.onPressActionButton}
          title={this.title}
        />
        <View style={styles.container}>
          <View style={styles.subContainer}>
            <FastImageView
              source={this.imagesUrl}
              style={styles.imageStyle}
              resizeMode='contain'
            />
            <Text style={styles.boldText}>{this.heading}</Text>
            <Text style={styles.subHeading}>{this.subHeading}</Text>
          </View>
          <View style={styles.buttonContainer}>
            <FoxyShadowButton
              width={Utility.getScreenWidth() - 24}
              title={this.primaryCtaText}
              textStyle={{ color: colors.white }}
              onPress={this.onPressPrimaryActionButton}
              style={styles.button}
              firstColor={colors.black}
              secondColor={colors.black}
              underlayColor={colors.foxyBlack}
            />
          </View>
          <View style={styles.buttonContainer}>
            <FoxyShadowButton
              width={Utility.getScreenWidth() - 24}
              title={this.secondaryCtaText}
              textStyle={{ color: colors.black }}
              onPress={this.onPressSecondaryActionButton}
              style={styles.button}
              firstColor={colors.white}
              secondColor={colors.white}
              underlayColor={colors.lightGrey}
            />
          </View>
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    width: Utility.getScreenWidth(),
    height: 373,
    backgroundColor: colors.white,
    alignSelf: 'center',
  },
  subContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 12,
  },
  imageStyle: {
    width: Utility.getScreenWidth() - 48,
    height: 120,
    marginTop: 24,
  },
  boldText: {
    fontSize: 18,
    fontFamily: 'Roboto-Bold',
    color: colors.foxyBlack,
    marginTop: 30,
  },
  subHeading: {
    fontSize: 14,
    fontFamily: 'Roboto-Regular',
    color: colors.foxyBlack,
    paddingTop: 8,
    alignContent: 'center',
    textAlign: 'center',
  },
  buttonContainer: {
    width: Utility.getScreenWidth(),
    backgroundColor: colors.white,
    alignItems: 'center',
    paddingTop: 12,
  },
  button: {
    height: 48,
    borderWidth: 1,
    borderColor: colors.black,
    borderStyle: 'solid',
  },

  contactUsContainer: {
    height: 130,
    width: Utility.getScreenWidth(),
    backgroundColor: colors.white,
    marginTop: 12,
  },
  subContainerStyle: {
    marginHorizontal: 20,
    marginTop: 24,
    marginBottom: 14,
  },
  subHeadingStyle: {
    fontSize: 14,
    fontFamily: 'Roboto-Regular',
    color: colors.foxyBlack,
    marginTop: 4,
  },
  blueTextButton: {
    fontSize: 14,
    fontFamily: 'Roboto-Bold',
    color: colors.cta.lightBlue,
    marginTop: 8,
    right: 3,
  },
  boldTextStyle: {
    fontSize: 14,
    fontFamily: 'Roboto-Bold',
    color: colors.foxyBlack,
  },
});

const mapStateToProps = (store) => {
  return {
    userEmail: store.UserAccountInfo.profile.email,
  };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      heldOrder,
    },
    dispatch,
  ),
});

export default withNavigation(
  connect(mapStateToProps, mapDispatchToProps)(CancelOrder),
);
