// Dependencies
import React, { PureComponent } from 'react';
import { View, Text, DeviceEventEmitter } from 'react-native';
import PropTypes from 'prop-types';
import { Viewport } from '../../libraries/Viewport';
import InViewPort from '../../utils/InViewPort';
import _ from 'lodash';
import { withMaybe } from '../../lib/Monads';
import { LAYOUT } from '../../config/Constants';
import Carousel from 'react-native-snap-carousel';

// Components
import LikeButton from '../shared/LikeButton';
import withNavigation from '../../utils/WithNavigation';
import {
  playlistStyle,
  smallVerticalPlaylistCardWidth,
  verticalPlaylistCardWidth,
} from './Style';
import Utility from '../../utils/Utility';
import {
  EventParameterKey,
  EventType,
  AnalyticsManager,
} from '../../analytics';
import Orientation from 'react-native-orientation';
import AudioHandler from '../../utils/AudioHandler';
import FoxyVerticalSingleMediaCard from './FoxyVerticalSingleMediaCard';
import ErrorBoundaryComponent from '../shared/ErrorBoundaryComponent';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';

class VerticalPlaylistCard extends PureComponent {
  static getComponentHeight({ size = 'defaultSize' }) {
    const style =
      size === 'small'
        ? playlistStyle.smallVerticalPlaylistContainer
        : playlistStyle.verticalPlaylistContainer;

    const { height, marginVertical } = style;
    return height + 2 * marginVertical;
  }

  constructor(props) {
    super(props);
    const { size = '' } = this.props;
    this.currentIndex = 0;
    this.verticalCardsRef = [];
    // console.tron.log('PREVIOUS_SCREEN', props.previousScreen);
    this.cardWidth =
      size === 'small'
        ? smallVerticalPlaylistCardWidth
        : verticalPlaylistCardWidth;
  }

  lockScreenToPortrait = (payload) => {
    // Orientation.lockToPortrait();
  };

  forRailOrGridLayout = (props) =>
    props.layout == LAYOUT.RAIL || props.layout == LAYOUT.GRID;

  listOrCardCondition = (props) =>
    !(props.layout === LAYOUT.CARD || props.layout === LAYOUT.LIST);

  viewsWithCondition = withMaybe(this.listOrCardCondition)(Text);

  LikeButtonWithCondition = withMaybe(this.forRailOrGridLayout)(LikeButton);

  onViewportEnter = () => {
    this.isInViewPort = true;
    this.listener = DeviceEventEmitter.addListener(
      'headPhoneStatus',
      (status) => {
        if (status === 'connected') {
          this.unmuteCurrentIndex();
        }
      },
    );
    this.playCurrent();
  };

  unmuteCurrentIndex = () => {
    if (this.verticalCardsRef && this.verticalCardsRef[this.currentIndex]) {
      this.verticalCardsRef[this.currentIndex].unmute();
    }
  };

  onViewportLeave = () => {
    this.isInViewPort = false;
    if (this.listener) {
      this.listener.remove();
    }
    this.pauseCurrent();
  };

  playCurrent = () => {
    if (
      this.verticalCardsRef &&
      this.verticalCardsRef[this.currentIndex] &&
      this.isInViewPort
    ) {
      this.verticalCardsRef[this.currentIndex].play();
    }
  };

  pauseCurrent = () => {
    if (this.verticalCardsRef && this.verticalCardsRef[this.currentIndex]) {
      this.verticalCardsRef[this.currentIndex].pause();
    }
  };

  titleContainer = () => {
    const { listData } = this.props;
    const styles = playlistStyle;
    const subtitle = listData.subtitle;
    if (Utility.isBlank(listData?.name)) {
      return null;
    }
    return (
      <View style={styles.verticalPlaylistHeadingContainer}>
        <Text
          style={[styles.verticalPlaylistTitle, { color: listData.name_color }]}
        >
          {listData.name}
        </Text>
        {subtitle && subtitle.length > 0 ? (
          <Text
            style={[
              styles.verticalPlaylistSubtitle,
              { color: listData.subtitle_color },
            ]}
          >
            {listData.subtitle}
          </Text>
        ) : null}
      </View>
    );
  };

  goToNextPage = () => {
    if (Utility.isPresent(this.carousel)) {
      this.carousel.snapToNext();
    }
  };

  goToPrevPage = () => {
    if (Utility.isPresent(this.carousel)) {
      this.carousel.snapToPrev();
    }
  };

  onBeforeSnapToItem = (nextPageIndex) => {
    this.pauseCurrent();
    const { listData = {} } = this.props;
    const {
      artist: { name: artistName = '', id: artistId } = {},
      metadata: {
        contentPlatform = 'foxy',
        title: contentTitle,
        id: videoId,
        duration,
      } = {},
    } = listData.objects[this.currentIndex] || {};
    AnalyticsManager.logEvent(EventType.videoEvents.VIDEO_CHANGE, {
      [EventParameterKey.VIDEO_ID]: videoId,
      [EventParameterKey.VIDEO_TITLE]: contentTitle,
      [EventParameterKey.ARTIST_NAME]: artistName,
      [EventParameterKey.ARTIST_ID]: artistId,
      [EventParameterKey.VIDEO_DURATION]: duration,
      [EventParameterKey.VIDEO_TYPE]: _.capitalize(contentPlatform),
      [EventParameterKey.SOURCE]: 'vertical_card',
      [EventParameterKey.METHOD]:
        (nextPageIndex > this.currentIndex &&
          !(
            this.currentIndex === 0 &&
            nextPageIndex === listData?.objects?.length - 1
          )) ||
        (this.currentIndex === listData?.objects?.length - 1 &&
          nextPageIndex === 0)
          ? 'Next Swipe'
          : 'Previous Swipe',
    });
    this.currentIndex = nextPageIndex;
    this.playCurrent();
  };

  onSnapToItem = (nextPageIndex) => {};

  onLayout = (e) => {
    const {
      nativeEvent: { layout },
    } = e;
    // if (e.nativeEvent.layout.y - Utility.getScreenHeight() < 0) {
    this.pauseCurrent();
    // }
  };

  checkVisible = (visible) => {
    // const { iteratorIndex, index } = this.props;
    // console.tron.log('isvisible: ', visible)
    // console.tron.log('iteratorIndex: ', iteratorIndex, 'index: ', index)
    visible ? this.onViewportEnter() : this.onViewportLeave();
  };

  saveCarouselRef = (ref) => {
    this.carousel = ref;
  };

  isProductDetailPageCondn = () => this.props.useInViewPort;

  // conditionalProductsRail = withEither(
  //   this.isProductDetailPageCondn,
  //   this.productsRailWithInViewPort,
  // )(this.productsRailWithViewPort);

  conditionalProductsRail = () =>
    this.props.useInViewPort
      ? this.productsRailWithInViewPort()
      : this.productsRailWithViewPort();

  productsRailWithViewPort = () => {
    const style = playlistStyle;
    const { item, verticalComponentWidth } = this.props;
    // const newData = size === 'small' ? [...item, phantomCard] : item;

    return (
      <View
        style={style.verticalListCarouselContainer} /*onLayout={this.onLayout}*/
      >
        <Viewport.Aware
          onViewportEnter={this.onViewportEnter}
          onViewportLeave={this.onViewportLeave}
        >
          <Carousel
            ref={this.saveCarouselRef}
            data={item}
            sliderWidth={verticalComponentWidth || Utility.getScreenWidth()}
            itemWidth={this.cardWidth + 12}
            renderItem={this.mediaCard}
            vertical={false}
            bullets={false}
            style={style.verticalPlaylistsCardsContainer}
            autoplay={false}
            index={this.currentIndex}
            initialNumToRender={3}
            maxToRenderPerBatch={3}
            lockScrollWhileSnapping
            enableMomentum={true}
            // activeSlideOffset={20}
            // swipeThreshold={40}
            onBeforeSnapToItem={this.onBeforeSnapToItem}
            onSnapToItem={this.onSnapToItem}
            // contentContainerCustomStyle={{
            //   paddingLeft: 12,
            //   paddingRight: 12,
            // }}
            // windowSize={1}
          />
        </Viewport.Aware>
      </View>
    );
  };

  productsRailWithInViewPort = () => {
    const style = playlistStyle;
    const { item, verticalComponentWidth, previousScreen } = this.props;

    let containerStyle = style.verticalListCarouselContainer;
    if (previousScreen === SCREEN_CONSTANTS.DURING_ROUTINE) {
      containerStyle = style.verticalListCarouselContainerDuringRoutine;
    }
    return (
      <View style={containerStyle} /*onLayout={this.onLayout}*/>
        <InViewPort
          onChange={this.checkVisible}
          topMargin={80}
          bottomMargin={80}
        >
          <Carousel
            ref={this.saveCarouselRef}
            data={item}
            sliderWidth={verticalComponentWidth || Utility.getScreenWidth()}
            itemWidth={this.cardWidth + 12}
            renderItem={this.mediaCard}
            vertical={false}
            bullets={false}
            style={style.verticalPlaylistsCardsContainer}
            autoplay={false}
            index={this.currentIndex}
            initialNumToRender={3}
            maxToRenderPerBatch={3}
            lockScrollWhileSnapping
            enableMomentum={true}
            activeSlideOffset={20}
            swipeThreshold={40}
            onBeforeSnapToItem={this.onBeforeSnapToItem}
            onSnapToItem={this.onSnapToItem}
            // windowSize={1}
          />
        </InViewPort>
      </View>
    );
  };

  setVerticalSingleMediaCardRef = (index, ref) => {
    this.verticalCardsRef[index] = ref;
  };

  mediaCard = ({ item, index: iteratorIndex }) => {
    const {
      itemData,
      listData,
      id,
      slug,
      previousScreen,
      onItemPress = () => {},
      routineActivityId = '',
      listIndex = '',
      listName = '',
      listContent = '',
      extraEventParameters = {},
    } = this.props;
    if (Utility.isBlank(item?.metadata?.videoUrl)) return null;
    return (
      <ErrorBoundaryComponent
        itemData={item}
        listData={listData}
        screenName={previousScreen}
      >
        <FoxyVerticalSingleMediaCard
          item={item}
          itemData={itemData}
          listData={listData}
          id={id}
          slug={slug}
          index={this.currentIndex}
          key={iteratorIndex}
          iteratorIndex={iteratorIndex}
          goToNextPage={this.goToNextPage}
          goToPrevPage={this.goToPrevPage}
          toggleMuteButton={this.toggleMutedState}
          isMuted={AudioHandler.isMuted}
          setVerticalSingleMediaCardRef={this.setVerticalSingleMediaCardRef}
          previousScreen={previousScreen}
          totalNumberOfVideos={this.props.item.length}
          cardWidth={this.cardWidth + 12}
          onItemPress={onItemPress}
          routineActivityId={routineActivityId}
          listIndex={listIndex}
          listName={listName}
          listContent={listContent}
          extraEventParameters={extraEventParameters}
        />
      </ErrorBoundaryComponent>
    );
  };

  render() {
    const { item, previousScreen, listData, size } = this.props;
    const style = playlistStyle;
    if (Utility.isBlank(item) || item.length === 0) {
      return null;
    }
    const containerStyle =
      size === 'small'
        ? style.smallVerticalPlaylistContainer
        : style.verticalPlaylistContainer;

    const backgroundColor =
      previousScreen === SCREEN_CONSTANTS.DURING_ROUTINE
        ? 'transparent'
        : listData.background_color;

    return (
      <>
        <View style={[containerStyle, { backgroundColor }]}>
          <this.conditionalProductsRail />
        </View>
      </>
    );
  }
}

// PropTypes
VerticalPlaylistCard.propTypes = {
  id: PropTypes.string,
  layout: PropTypes.string,
  size: PropTypes.string,
  item: PropTypes.array,
  itemData: PropTypes.shape({
    image_url: PropTypes.string,
    id: PropTypes.string,
  }),
  theme: PropTypes.string,
  listId: PropTypes.string,
  index: PropTypes.number,
  currentlyPlayingIndex: PropTypes.number,
  useInViewPort: PropTypes.bool,
  updateCurrentlyPlayingIndex: PropTypes.func,
};

VerticalPlaylistCard.defaultProps = {
  index: 0,
  currentlyPlayingIndex: null,
  layout: LAYOUT.HORIZONTAL_PLAYLIST,
  useInViewPort: false,
  updateCurrentlyPlayingIndex: () => {},
};

export default withNavigation(VerticalPlaylistCard);
