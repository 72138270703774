import React, { PureComponent } from 'react';
import { View } from 'react-native';
import _ from 'lodash';
import withNavigation from '../../utils/WithNavigation';
import ScaleAnimate from '../shared/ScaleAnimate';
import Utility from '../../utils/Utility';
import { styles } from './styles';
import FastImageView from '../FastImageView';
import AnalyticsUtility from '../../analytics/AnalyticsUtility';
import { getMinifiedImage } from '../../utils/ImageUtility';

class ReferralMiniCard extends PureComponent {
  constructor(props) {
    super(props);
    const { itemData: { image_url = '' } = {} } = props;
    this.debouncedOnPress = _.debounce(this.onPress, 600, {
      leading: true,
      trailing: false,
    });
    this.minifiedImageUrl = getMinifiedImage(
      image_url,
      Utility.getScreenWidth(),
    );
  }

  showReferralModal = () => {
    const { navigation } = this.props;
    navigation.navigate('InviteToClaimModal');
  };

  onPress = () => {
    const {
      itemData,
      previousScreen,
      listIndex = 0,
      itemData: { destination, external_link, title = '', slug = '' } = {},
      listContent,
      listName,
      listId = '',
      listSlug = '',
      listDisplay = '',
      index,
    } = this.props;
    this.showReferralModal();

    const url = Utility.isPresent(external_link) ? external_link : destination;

    const listNames = url.split('/')[url.split('/').length - 1];

    if (Utility.isPresent(itemData)) {
      const { id, type } = itemData;
      AnalyticsUtility.fireItemClickEvent(
        previousScreen,
        id,
        type,
        listNames,
        index,
        listId,
        listDisplay,
        Utility.isPresent(listName) ? listName : title,
        listIndex,
        '',
        '',
        listContent,
        '',
        slug,
        listSlug,
      );
    }
  };

  render() {
    const { itemData: { image_url = '' } = {} } = this.props;
    if (Utility.isBlank(image_url)) {
      return null;
    }

    return (
      <ScaleAnimate {...this.props} onPress={this.debouncedOnPress}>
        <View style={styles.miniBanner}>
          <FastImageView
            resizeMode="contain"
            source={this.minifiedImageUrl}
            style={styles.referralMiniBannerImage}
          />
        </View>
      </ScaleAnimate>
    );
  }
}

export default withNavigation(ReferralMiniCard);
