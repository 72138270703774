import React, { Component } from 'react';
import {
  View,
  Image,
  TouchableOpacity,
  ScrollView,
  KeyboardAvoidingView,
  Keyboard,
  Text,
  ActivityIndicator,
  TouchableWithoutFeedback,
  BackHandler,
  Platform,
  Linking,
  FlatList,
} from 'react-native';
import Permissions from '../../libraries/ReactNativePermissions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { TextField } from 'react-native-material-textfield';
import Config from '../../libraries/ReactNativeConfig';
import Toast from 'react-native-easy-toast';
import _ from 'lodash';
import smartLock from 'react-native-smartlock';
import { withEither } from '../../lib/Monads';
import images from '../../theme/Images';
import colors from '../../theme/Colors';
import {
  editProfile,
  verifyHandleWithCallback,
  addInviteCode,
  getArtistCtaOptions,
  editProfileSuccess,
  getMyProfileDetails,
} from '../../actions/ActionTypes';
import styles from './styles';
import { StaticNavigationHeader, ScaleAnimate } from '../../components/shared';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import ActionButton from '../../components/shared/ActionButton';
import BottomModal from '../../components/profile/BottomModal';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import {
  logoutUser,
  smsRetrieverStatus,
  startFacialAnalysis,
  addProfilePic,
} from '../../actions/LoginActions';

import { CartStyles } from '../../components/cart/styles';
import FullWidthDivider from '../../utils/FullWidthDivider';
import FoxyAlert from '../../components/camera/shared/FoxyAlert';
import {
  AVAILABLE_BUILD_TYPES,
  WEB_URL,
  ANDROID,
  PERMISSION_STORE,
} from '../../config/Constants';
import {
  AnalyticsManager,
  EventType,
  EventParameterValue,
} from '../../analytics';
import size from '../../theme/Fonts';
import EmojiModal from '../../components/Artist/EmojiModal';
import FastImageView from '../../components/FastImageView';
import { isWeb } from '../../utils/BooleanUtility';
import { getMinifiedImage } from '../../utils/ImageUtility';
import withProfiledNavigation from '../../utils/withProfiledNavigation';
import { getNavigationSource } from '../../utils/PerfUtility';

class ProfileEdit extends Component {
  constructor(props) {
    super(props);
    this.bottomDockHeight = 70;
    this.ref = [];
    this.ref.handle = React.createRef();
    this.ref.name = React.createRef();
    this.ref.email = React.createRef();
    this.ref.inviteCode = React.createRef();
    this.ref.bio = React.createRef();
    this.ref.currentCity = React.createRef();
    this.debouncedVerifyHandle = _.debounce(
      props.verifyHandleWithCallback,
      250,
    );
    this.state = {
      handle: props.handle || '',
      name: props.name || '',
      email: props.email || '',
      bio: props.bio || '',
      mobileNumber: props.mobileNumber || '',
      currentCity: props.currentCity || '',
      selectedEmoji: props.emoji || '',
      call_to_action_option_id: props.call_to_action_option_id || '',
      inviteCode: '',
      handleSuccess: true,
      editProfileSuccess: false,
      handleError: false,
      showProfilePicLoader: false,
      profilePicUrl: props.image || '',
      isEmailValid: true,
      isNameEmpty: false,
      showAlert: false,
      inviteCodeError: false,
      alertTitle: '',
      alertText: '',
      showPhotoUploadModal: false,
      visible: false,
      uploadPhotoModalSubheading: Platform.select({
        ios: 'Tap OK when prompted to click or upload photo',
        android: 'Tap Allow when prompted to click or upload photo',
        web: '',
      }),
      selectedArtistCta: props.call_to_action_option_id || 2,
      isEmojiModalVisible: false,

      isEmailFieldDisable: !!(
        Utility.isAndroid() && Platform.Version >= ANDROID.BUILD.VERSIONS.NOUGAT
      ),
      showEmailPrompt: !!(
        Utility.isAndroid() && Platform.Version >= ANDROID.BUILD.VERSIONS.NOUGAT
      ),
    };
    this.shouldShowDefaultBio = Utility.isBlank(props.bio);
    this.debounceGoogleHintPrompt = _.debounce(
      this.showGoogleHintPrompt,
      500,
      {
        leading: false,
        trailing: true,
      },
    );

    this.currentPermissionState = '';
    this.uploadPhotoModalSubheading = Platform.select({
      ios: 'Tap OK when prompted to click or upload photo',
      android: 'Tap Allow when prompted to click or upload photo',
      web: '',
    });
    this.navigationSource = getNavigationSource(this, this.props);
  }

  showTickCondition = (props) => !props.selected;

  isIosFn = () => Utility.isIOS();

  customKeyboardAvoid = withEither(this.isIosFn, KeyboardAvoidingView)(View);

  componentDidMount() {
    if (Platform.OS === 'android') {
      this.backHandler = BackHandler.addEventListener(
        'hardwareBackPress',
        this.onHardwareBackKeyPress,
      );
    }
    AnalyticsManager.setCurrentScreen(
      EventParameterValue.SCREEN_NAME.EDIT_PROFILE,
    );
    const { getArtistCtaOptions } = this.props;
    getArtistCtaOptions();
  }

  componentWillUnmount() {
    if (Platform.OS === 'android') {
      this.backHandler.remove();
    }
  }

  onHardwareBackKeyPress = () => {
    const { navigation } = this.props;
    navigation.goBack();
    return true;
  };

  enableEmailField = (isEmailFieldDisable, showEmailPrompt) => {
    if (Utility.isAndroid()) {
      this.setState({
        isEmailFieldDisable,
        showEmailPrompt,
      });
    }
  };

  showGoogleHintPrompt = () => {
    const { isEmailFieldDisable, showEmailPrompt } = this.state;

    if (!showEmailPrompt) {
      return;
    }
    if (Utility.isAndroid()) {
      smartLock
        .getCredentials()
        .then((credentials) => {
          this.setState({
            email: credentials.id,
          });
          this.enableEmailField(false, false);
          // user has permissions
        })
        .catch((err) => {
          this.enableEmailField(false, false);
        });
    }
  };

  profileEditConstants = {
    handleErrorText: 'This handle is unavailable',
    invalidEmail: 'Email address is invalid',
    enterName: 'Please enter a name',
    invalidInviteCode: 'This invite code in invalid',
  };

  editProfile = () => {
    const { name, handle, image } = this.props;
    this.setState({
      handle,
      name,
      profilePicUrl: image,
    });
  };

  addHandle = (value) => {
    this.setState({ handle: value, handleError: false });
    if (value.length > 2) {
      this.debouncedVerifyHandle(value, (success) => {
        if (success) {
          this.setState({
            handleError: false,
          });
        } else {
          this.setState({
            handleError: true,
          });
        }
      });
    } else {
      this.setState({
        handleError: true,
      });
    }
  };

  addName = (value) => {
    this.setState({
      name: value,
      isNameEmpty: value === '',
    });
  };

  addEmail = (value) => {
    const isValid = value.includes('.') ? this.validateEmail(value) : true;
    this.setState({
      email: value,
      isEmailValid: isValid,
    });
  };

  addBio = (value) => {
    this.setState({
      bio: value,
    });
    if (this.shouldShowDefaultBio) {
      this.shouldShowDefaultBio = false;
    }
  };

  addCurrentCity = (value) => {
    this.setState({
      currentCity: value,
    });
  };

  addInviteCode = (value) => {
    let code = value;
    if (!Utility.isBlank(code)) {
      if (code.includes(WEB_URL)) {
        code = code.split('/').pop();
      }
      if (code.length === 6) {
        this.submitInviteCode(code);
      }
    }
    this.setState({
      inviteCode: code,
      inviteCodeError: false,
    });
  };

  profileUpdateSuccess = () => {
    const { navigation, mobileNumber, name, email } = this.props;
    AnalyticsManager.setGeneralUserAttributes({
      name: `${name}`,
      email: `${email}`,
      contactNumber: `${mobileNumber}`,
    });
    navigation.goBack();
  };

  actionButtonPressed = () => {
    const {
      handle,
      name,
      email,
      profilePicUrl,
      handleError,
      currentCity,
      bio,
      selectedEmoji,
      selectedArtistCta,
      editProfileSuccess,
    } = this.state;
    const { editProfile } = this.props;
    const user = {};
    const artist = {};
    const isValid = this.validateEmail(email);
    if (Utility.isBlank(name)) {
      user.name = '';
    }
    if (email === '') {
      user.email = '';
    }
    if (email !== '' && !isValid) {
      this.setState({
        isEmailValid: isValid,
      });
      return;
    }
    if (Utility.isBlank(handle)) {
      user.handle = null;
    }

    if (Utility.isPresent(handle) && handle !== this.props.handle) {
      user.handle = handle;
    }
    if (Utility.isPresent(name)) {
      user.name = name;
      if (name !== this.props.name) {
        AnalyticsManager.logEvent(EventType.profileEvents.NAME_UPDATE, {});
      }
    }
    if (Utility.isPresent(email)) {
      user.email = email;
      if (email !== this.props.email) {
        AnalyticsManager.logEvent(EventType.profileEvents.EMAIL_UPDATE, {});
      }
    }
    if (
      Utility.isPresent(currentCity) &&
      currentCity !== this.props.currentCity
    ) {
      artist.city = currentCity;
    }
    if (Utility.isPresent(bio) && bio !== this.props.bio) {
      artist.bio = bio;
    }
    if (
      Utility.isPresent(selectedEmoji) &&
      selectedEmoji !== this.props.emoji
    ) {
      artist.emoji = selectedEmoji;
    }
    if (
      Utility.isPresent(selectedArtistCta) &&
      selectedArtistCta !== this.props.call_to_action_option_id
    ) {
      artist.call_to_action_option_id = selectedArtistCta;
    }
    Keyboard.dismiss();
    if (handleError) {
      this.toast.show('This handle is unavailable');
      return;
    }

    if (Utility.isBlank(user)) {
      editProfileSuccess(user);
      this.profileUpdateSuccess();
      return;
    }

    AnalyticsManager.setUserProfileAfterEdit(user.name, user.email);
    editProfile(user, artist, (callbackData, success) => {
      this.setState({ editProfileSuccess: success });
      if (success) {
        this.toast.show('Profile updated');
        console.tron.log('toast. show called');
        // this.profileUpdateSuccess();
        setTimeout(() => {
          this.profileUpdateSuccess();
        }, 100);
      } else {
        this.toast.show('Some error occurred. Please try again');
      }
    });
  };

  submitInviteCode = (inviteCode) => {
    const { addInviteCode } = this.props;
    addInviteCode(inviteCode, (result, json) => {
      if (result) {
        Keyboard.dismiss();
        this.setState({
          inviteCode: '',
        });
        setTimeout(() => {
          this.toast.show('Invite Code Successfully Linked');
        }, 1000);
      } else {
        if (!Utility.isBlank(json.errors)) {
          if (json.errors.length > 0) {
            this.profileEditConstants.invalidInviteCode = json.errors[0];
          }
        }
        this.setState({
          inviteCodeError: true,
        });
      }
    });
  };

  getImageUrl = (profileUrl) => {
    console.tron.log('image uri is ', profileUrl);
    const { startFacialAnalysis, addProfilePic, getMyProfileDetails } =
      this.props;
    const imageName = Utility.extractImageName(profileUrl);
    AnalyticsManager.logEvent(
      EventType.profileEvents.PROFILE_PICTURE_UPDATE,
      {},
    );
    this.setState({
      showProfilePicLoader: true,
    });
    const previousScreen = SCREEN_CONSTANTS.PROFILE_EDIT;
    startFacialAnalysis(imageName, profileUrl, 'profile', (data) => {
      this.setState({
        showProfilePicLoader: false,
      });
      if (data) {
        getMyProfileDetails();
        this.setState({
          profilePicUrl: profileUrl,
        });
        // addProfilePic(data.profile.image_url);
      }
    });
  };

  cancelModal = () => {
    this.setState({
      ...this.state,
      showWarningModal: false,
    });
  };

  onTapOutSideAlert = () => {
    this.setState({
      ...this.state,
      showWarningModal: false,
    });
  };

  goToModal = () => {
    Permissions.check('camera').then((response) => {
      if (response === 'authorized') {
        Permissions.request('photo').then((response) => {
          this.goToCamera();
        });
      } else {
        this.setState({
          ...this.state,
          showPhotoUploadModal: true,
        });
      }
    });
  };

  cameraPermissionDenied = () => {
    this.setState({
      ...this.state,
      showWarningModal: true,
    });
  };

  hideCameraPermissionModal = () => {
    this.setState({
      visible: false,
    });
  };

  showCameraPermissionModal = () => {
    this.setState({
      visible: true,
    });
  };

  goToCamera = (source = '') => {
    const { navigation } = this.props;

    Utility.checkPermissionStatic(
      PERMISSION_STORE.camera,
      true,
      (permissionStatus) => {
        console.tron.log('Status ', permissionStatus);
        if (
          permissionStatus === 'granted' ||
          permissionStatus === 'authorized'
        ) {
          navigation.navigate('TakeSelfie', {
            previousScreen: SCREEN_CONSTANTS.PROFILE_EDIT,
            getImageUrl: this.getImageUrl,
          });
          this.currentPermissionState = '';
        } else if (
          permissionStatus === 'never_ask_again' ||
          permissionStatus === 'denied'
        ) {
          console.tron.log(
            'Current Permission Status ',
            this.currentPermissionState,
            source,
          );
          if (Utility.isBlank(this.currentPermissionState)) {
            this.currentPermissionState = permissionStatus;
          } else if (Utility.isPresent(source)) {
            this.hideCameraPermissionModal();
            Linking.openSettings();
          }
          if (Utility.isBlank(source)) {
            this.showCameraPermissionModal();
          }
        }
      },
    );
  };

  onCloseBottomDialog = () => {
    console.tron.log('reached in close modal');
    this.setState({
      ...this.state,
      showPhotoUploadModal: false,
    });
  };

  goToAddress = (address = '') => {
    const { navigation } = this.props;
    navigation.navigate(this.navigationSource, 'Address', {
      previousScreen: SCREEN_CONSTANTS.PROFILE_EDIT,
      addressToEdit: address,
    });
  };

  artistCtaPressed = (id) => {
    this.setState({
      selectedArtistCta: id,
    });
  };

  emojiSelected = (emoji) => {
    this.setState({
      isEmojiModalVisible: false,
      selectedEmoji: emoji,
    });
  };

  toggleEmojiModal = () => {
    this.setState((prevState) => ({
      isEmojiModalVisible: !prevState.isEmojiModalVisible,
    }));
  };

  profileImage = () => {
    const { profilePicUrl, showProfilePicLoader } = this.state;
    const { image, selfieImage, instagram_profile } = this.props;
    const imageSource = Utility.currentProfilePicToShow(
      selfieImage,
      instagram_profile,
      image,
    );
    return (
      <TouchableOpacity
        style={styles.profileImageContainer}
        onPress={() => this.goToCamera()}
      >
        <FastImageView
          source={imageSource}
          style={styles.userProfileImage}
          resizeMode="cover"
        >
          <View style={styles.profileImageEditBox} />
          <Text style={styles.profileImageEditText}>Edit</Text>
          {showProfilePicLoader ? (
            <View
              style={{
                position: 'absolute',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <ActivityIndicator size="large" color={colors.green} />
            </View>
          ) : null}
        </FastImageView>

        {Utility.isBlank(profilePicUrl) ? (
          <Image
            source={images.myProfile.addImage}
            style={styles.addImageButtonInProfileEdit}
          />
        ) : null}
      </TouchableOpacity>
    );
  };

  handleAccessory = () => {
    const { handle, handleError, handleSuccess } = this.state;
    if (handleError === true || handle === '' || handle === undefined) {
      return (
        <TouchableOpacity onPress={this.clearInputData('handle')}>
          <Image source={images.failure} style={styles.icon} />
        </TouchableOpacity>
      );
    }
    if (handleError === false && handle && handle.length > 2) {
      return <Image source={images.success} style={styles.icon} />;
    }
  };

  clearInputData = (fieldName) => () => {
    this.ref[fieldName].current.clear();
    this.setState({ [fieldName]: '' });
    this.ref[fieldName].current.focus();

    this.enableEmailField(true, true);
  };

  validateEmail = (email) => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  clearAccessory = (fieldName) => () => {
    const value = this.state[fieldName];
    if (value === null || value === '' || value === undefined) {
      return null;
    }
    return (
      <TouchableOpacity
        onPress={this.clearInputData(fieldName)}
        hitSlop={Utility.getHitSlop('button')}
      >
        <Image source={images.grayscaleCross} style={styles.icon} />
      </TouchableOpacity>
    );
  };

  instaAccessories = (fieldName) => () => {
    return (
      <TouchableWithoutFeedback
        hitSlop={Utility.getHitSlop()}
        onPress={this.changeInstagramHandle}
      >
        <View style={[styles.rightAlignItemsRow, { marginLeft: 10 }]}>
          <View style={[styles.handleContainer, { flexDirection: 'row' }]}>
            <Image
              source={images.instagramBlack}
              style={{ height: 12, width: 12 }}
            />
            <Text style={[styles.handleChangeRowText, { marginLeft: 4 }]}>
              {Utility.isBlank(this.state.handle) ? 'Connect' : 'Change'}
            </Text>
          </View>
        </View>
      </TouchableWithoutFeedback>
    );
  };

  // addressButton = () => {
  //   return (
  //     <TouchableOpacity onPress={this.goToAddress}>
  //       <Card borderWidth={1} style={styles.addressButtonContainer}>
  //         <Text style={styles.manageAddressText}>Manage Address</Text>
  //         <Image style={styles.addressRightArrow} source={images.chevronRight} />
  //       </Card>
  //     </TouchableOpacity>)
  // }

  address = (props) => {
    const { address, index } = props;
    return (
      <>
        <View style={CartStyles.individualAddressContainer}>
          <TouchableOpacity
            onPress={() => this.goToAddress(address)}
            style={[
              CartStyles.userAddressTextContainer,
              {
                marginLeft: 20,
                marginRight: 20,
                width: Utility.getScreenWidth() - 40,
              },
            ]}
            hitSlop={Utility.getHitSlop('address')}
          >
            <Text style={CartStyles.addressText}>
              {`${address.name || ''}: ${address.line1 || ''},${
                address.line2 || ''
              } - ${address.pincode || ''}`}
            </Text>
            <Image
              source={images.chevronRight}
              style={[
                CartStyles.rightArrow,
                {
                  tintColor: colors.foxyBlack,
                  marginRight: 4,
                  alignSelf: 'center',
                },
              ]}
            />
          </TouchableOpacity>
          <View />
        </View>
        <FullWidthDivider margin={20} />
      </>
    );
  };

  myAddress = () => {
    const { address } = this.props;
    let addressArray = [];
    if (!Utility.isBlank(address)) {
      addressArray = Object.keys(address).map((key) => ({
        id: key,
        name: address[key].name,
        pincode: address[key].pincode,
        line1: address[key].line1,
        line2: address[key].line2,
      }));
    }

    return (
      <View>
        {addressArray.length != 0 ? (
          <Text style={styles.myAddressHeader}>My address</Text>
        ) : null}
        {addressArray.map((item, index) => (
          <this.address
            address={item}
            index={index}
            key={`${index}_${item}`}
            showArrow
          />
        ))}
        {addressArray.length === 0 ? (
          <View>
            <TouchableOpacity
              style={[CartStyles.addressTextContainer, { height: 46 }]}
              onPress={() => this.goToAddress()}
            >
              <Image
                source={images.plus}
                style={[
                  CartStyles.plus,
                  {
                    marginLeft: 16,
                    tintColor: colors.foxyBlack,
                  }
                ]}
              />
              <Text
                style={[
                  CartStyles.addAddressText,
                  { color: colors.foxyBlack },
                ]}
              >
                Add an address
              </Text>
              <Image
                source={images.chevronRight}
                style={[
                  CartStyles.rightArrow,
                  { tintColor: colors.foxyBlack },
                ]}
              />
            </TouchableOpacity>
            <FullWidthDivider margin={20} />
          </View>
        ) : null}
      </View>
    );
  };

  getActionButtonOption = (item) => {
    const { selectedArtistCta } = this.state;
    const selected = selectedArtistCta === item.id;
    const backgroundColor = selected ? item.color : colors.white;
    const textColor = selected ? colors.white : colors.foxyBlack;
    return (
      <ScaleAnimate
        {...this.props}
        onPress={() => this.artistCtaPressed(item.id)}
      >
        <View
          style={[
            styles.ctaButton,
            {
              borderColor: item.color,
              backgroundColor,
            },
          ]}
        >
          {selected ? (
            <Image
              source={images.greenCircularTick}
              style={styles.circularTick}
            />
          ) : null}
          <Text style={[styles.ctaActionButtonText, { color: textColor }]}>
            {item.title}
          </Text>
        </View>
      </ScaleAnimate>
    );
  };

  actionButtonsForArtistPublicProfile = () => {
    if (Utility.getCurrentAppType() === AVAILABLE_BUILD_TYPES.artist_only) {
      return null;
    }
    const { artistCtaOptions } = this.props;
    const { selectedArtistCta } = this.state;
    if (Utility.isBlank(artistCtaOptions)) {
      return null;
    }
    return (
      <View>
        <Text style={styles.myAddressHeader}>Select profile action</Text>
        <FlatList
          data={artistCtaOptions}
          scrollEnabled={false}
          renderItem={({ item }) => this.getActionButtonOption(item)}
          keyExtractor={(item) => `${item.type}`}
          extraData={selectedArtistCta}
          style={{ marginTop: 4 }}
        />
      </View>
    );
  };

  bottomButtonDock = (props) => (
    <this.customKeyboardAvoid
      behavior="position"
      enabled
      keyboardVerticalOffset={-8}
    >
      <View
        style={[
          styles.actionBottonContainer,
          { height: this.bottomDockHeight + Utility.bottomInset },
        ]}
      >
        <ActionButton
          onActionPress={this.actionButtonPressed}
          title="Save"
          actionButtonColor={colors.primaryActionBackgroundColor}
          layoutStyle={{ marginBottom: 8 }}
        />
      </View>
    </this.customKeyboardAvoid>
  );

  logoutPressed = () => {
    this.setState({
      showAlert: true,
      alertText: 'Are you sure you want to logout?',
    });
  };

  logout = () => {
    const { logoutUser, navigation, smsRetrieverStatus } = this.props;
    navigation.navigate('Feed');
    smsRetrieverStatus('initiate');
    logoutUser();
  };

  confirmLogout = () => {
    this.cancelAlert();
    setTimeout(() => {
      this.logout();
    }, 1000);
  };

  cancelAlert = () => {
    this.setState({
      showAlert: false,
    });
  };

  changeInstagramHandle = () => {
    const { instagram_profile, navigation } = this.props;
    this.setState({
      handleError: false,
    });
    navigation.navigate('ArtistOnboardingContainer', {
      previousScreen: SCREEN_CONSTANTS.CHANGE_INSTA_HANDLE,
      editProfile: this.editProfile,
    });
  };

  onTapOutsideSelfieUpdateModal = () => {
    this.setState({
      visible: false,
    });
  };

  onPressImageReviewSelfieUpdateModal = () => {
    this.setState({
      visible: false,
    });
    this.props.navigation.navigate('AttributeSelector');
  };

  moveToField = (fieldName) => {
    this.ref[fieldName].current.focus();
  };

  render() {
    const {
      name,
      handle,
      email,
      handleError,
      isEmailValid,
      isNameEmpty,
      alertText,
      showAlert,
      alertTitle,
      uploadPhotoModalSubheading,
      showPhotoUploadModal,
      showWarningModal,
      inviteCode,
      inviteCodeError,
      bio,
      isEmojiModalVisible,
      selectedEmoji,
      currentCity,
      mobileNumber,
      isEmailFieldDisable,
    } = this.state;
    const { hasBeenReferred, isArtist, artistTypes } = this.props;
    let { selectedArtistTypes } = this.props;
    let artistTypeImage = '';
    let artistBio = '';
    if (
      !Utility.isBlank(selectedArtistTypes) &&
      !Utility.isBlank(artistTypes)
    ) {
      selectedArtistTypes = _.sortBy(selectedArtistTypes, [
        function (artTypeId) {
          return artistTypes[artTypeId].priority;
        },
      ]);
      const artistHighestPrioiryTypeId = selectedArtistTypes[0];
      artistTypeImage = artistTypes[artistHighestPrioiryTypeId].filled_icon;
      if (selectedArtistTypes.length > 1) {
        selectedArtistTypes.slice(0, 2).map((id, index) => {
          if (index === 0) {
            artistBio = `${artistTypes[id].name}`;
          } else {
            artistBio = `${artistBio}, ${artistTypes[id].name}`;
          }
        });
      } else {
        artistBio = artistTypes[artistHighestPrioiryTypeId].name;
      }
    }
    return (
      <View
        style={{
          flex: 1,
          backgroundColor: colors.background,
        }}
      >
        <StaticNavigationHeader title="Edit Profile" />

        <ScrollView>
          <this.customKeyboardAvoid
            behavior="position"
            enabled
            keyboardVerticalOffset={-350}
          >
            <View style={styles.profileEditHandleContainer}>
              <this.profileImage />
            </View>
            <View style={styles.nameTextFieldContainer}>
              <TextField
                label="Full Name"
                lineWidth={1}
                blurOnSubmit={false}
                value={name}
                onChangeText={this.addName}
                ref={this.ref.name}
                autoCapitalize={false}
                returnKeyType="next"
                hideLabel={false}
                renderAccessory={this.clearAccessory('name')}
                onSubmitEditing={() => this.moveToField('email')}
                error={isNameEmpty ? this.profileEditConstants.enterName : null}
                style={styles.textInputText}
                inputContainerStyle={{ borderBottomColor: colors.border }}
                textContentType="name"
                tintColor={colors.silver}
              />
            </View>
            {/* <View style={styles.nameTextFieldContainer}>
              <TextField
                label='Mobile Number'
                lineWidth={1}
                blurOnSubmit={false}
                value={mobileNumber}
                ref={this.ref.phone}
                autoCapitalize={false}
                hideLabel={false}
                disabled
                style={[styles.textInputText, { color: colors.silverWicked }]}
                inputContainerStyle={{ borderBottomColor: colors.border }}
              />
            </View> */}
            <TouchableWithoutFeedback onPress={this.debounceGoogleHintPrompt}>
              <View style={styles.emailTextFieldContainer}>
                <TextField
                  label="Email"
                  disabled={isEmailFieldDisable}
                  lineWidth={1}
                  blurOnSubmit={false}
                  value={email}
                  onChangeText={this.addEmail}
                  ref={this.ref.email}
                  autoCapitalize={false}
                  returnKeyType="done"
                  hideLabel={false}
                  renderAccessory={this.clearAccessory('email')}
                  onSubmitEditing={Keyboard.dismiss}
                  error={
                    !isEmailValid
                      ? this.profileEditConstants.invalidEmail
                      : null
                  }
                  style={styles.textInputText}
                  inputContainerStyle={{ borderBottomColor: colors.border }}
                  textContentType="emailAddress"
                  keyboardType="email-address"
                  tintColor={colors.silver}
                />
              </View>
            </TouchableWithoutFeedback>
            {/* {hasBeenReferred ? null : (
            <View style={styles.emailTextFieldContainer}>
              <TextField
                label="Invite Code"
                lineWidth={1}
                blurOnSubmit={false}
                value={inviteCode}
                onChangeText={this.addInviteCode}
                ref={this.ref.inviteCode}
                autoCapitalize={false}
                returnKeyType="done"
                hideLabel={false}
                renderAccessory={this.clearAccessory('inviteCode')}
                onSubmitEditing={Keyboard.dismiss}
                style={styles.textInputText}
                error={inviteCodeError ? this.profileEditConstants.invalidInviteCode : null}
                inputContainerStyle={{ borderBottomColor: colors.border }}
                disabled={hasBeenReferred}
              />
            </View>
          )} */}

            {!Utility.jsonParser(
              Config.DISABLE_INSTAGRAM_HANDLE_ON_EDIT_PROFILE,
            ) && (
              <TouchableWithoutFeedback
                hitSlop={Utility.getHitSlop()}
                onPress={this.changeInstagramHandle}
              >
                <View style={styles.emailTextFieldContainer}>
                  <TextField
                    // label='Email'
                    // disabled={isEmailFieldDisable}
                    // lineWidth={1}
                    // blurOnSubmit={false}
                    // value={email}
                    // onChangeText={this.addEmail}
                    // ref={this.ref.email}
                    // autoCapitalize={false}
                    // returnKeyType='done'
                    // hideLabel={false}
                    renderAccessory={this.instaAccessories()}
                    // onSubmitEditing={Keyboard.dismiss}
                    // error={
                    //   !isEmailValid
                    //     ? this.profileEditConstants.invalidEmail
                    //     : null
                    // }
                    style={styles.textInputText}
                    inputContainerStyle={{ borderBottomColor: colors.border }}
                    label="Handle"
                    lineWidth={1}
                    blurOnSubmit={false}
                    value={Utility.isBlank(handle) ? '' : handle}
                    onChangeText={this.addHandle}
                    ref={this.ref.handle}
                    autoCapitalize={false}
                    prefix="@"
                    hideLabel={false}
                    returnKeyType="done"
                    // renderAccessory={this.handleAccessory}
                    onSubmitEditing={Keyboard.dismiss}
                    error={
                      handleError
                        ? this.profileEditConstants.handleErrorText
                        : null
                    }
                    // style={styles.textInputTextDisabled}
                    // inputContainerStyle={{
                    //   borderBottomColor: colors.border,
                    // }}
                    disabled
                    onPress={this.changeInstagramHandle}
                    tintColor={colors.silver}
                  />
                </View>
              </TouchableWithoutFeedback>
            )}

            {Utility.getCurrentAppType() !==
              AVAILABLE_BUILD_TYPES.artist_only && isArtist ? (
              <View style={styles.emailTextFieldContainer}>
                <TextField
                  label="Current City"
                  lineWidth={1}
                  blurOnSubmit={false}
                  value={currentCity}
                  onChangeText={this.addCurrentCity}
                  ref={this.ref.currentCity}
                  autoCapitalize={false}
                  returnKeyType="done"
                  hideLabel={false}
                  renderAccessory={this.clearAccessory('currentCity')}
                  onSubmitEditing={Keyboard.dismiss}
                  style={styles.textInputText}
                  inputContainerStyle={{ borderBottomColor: colors.border }}
                  tintColor={colors.silver}
                />
              </View>
            ) : null}
            {Utility.getCurrentAppType() !==
              AVAILABLE_BUILD_TYPES.artist_only && isArtist ? (
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <View>
                  <Text style={styles.emojiTitle}>Emoji</Text>
                  <TouchableOpacity
                    style={styles.emojiButton}
                    onPress={this.toggleEmojiModal}
                  >
                    <FastImageView
                      source={{
                        uri: getMinifiedImage(
                          Utility.isBlank(selectedEmoji)
                            ? artistTypeImage
                            : selectedEmoji,
                          styles.artistTypeImageInBio.width,
                          styles.artistTypeImageInBio.height,
                        ),
                      }}
                      style={styles.artistTypeImageInBio}
                      resizeMode="contain"
                    />
                  </TouchableOpacity>
                </View>
                <View
                  style={[
                    styles.bioTextFieldContainer,
                    { width: Utility.getScreenWidth() - 60 },
                  ]}
                >
                  <TextField
                    label="Bio"
                    lineWidth={1}
                    blurOnSubmit={false}
                    value={this.shouldShowDefaultBio ? artistBio : bio}
                    onChangeText={this.addBio}
                    ref={this.ref.bio}
                    autoCapitalize={false}
                    returnKeyType="done"
                    hideLabel={false}
                    renderAccessory={this.clearAccessory('email')}
                    onSubmitEditing={Keyboard.dismiss}
                    style={styles.textInputText}
                    inputContainerStyle={{ borderBottomColor: colors.border }}
                    tintColor={colors.silver}
                  />
                </View>
              </View>
            ) : null}
            {/* {isArtist && <this.myAddress />} */}
            {/* <this.addressButton /> */}
            {isArtist && <this.actionButtonsForArtistPublicProfile />}
            {hasBeenReferred || true ? null : (
              <View style={styles.emailTextFieldContainer}>
                <TextField
                  label="Invite Code"
                  lineWidth={1}
                  blurOnSubmit={false}
                  value={inviteCode}
                  onChangeText={this.addInviteCode}
                  ref={this.ref.inviteCode}
                  autoCapitalize={false}
                  returnKeyType="done"
                  hideLabel={false}
                  renderAccessory={this.clearAccessory('inviteCode')}
                  onSubmitEditing={Keyboard.dismiss}
                  style={styles.textInputText}
                  error={
                    inviteCodeError
                      ? this.profileEditConstants.invalidInviteCode
                      : null
                  }
                  inputContainerStyle={{ borderBottomColor: colors.border }}
                  disabled={hasBeenReferred}
                  tintColor={colors.silver}
                />
              </View>
            )}
            {Utility.getCurrentAppType() ===
              AVAILABLE_BUILD_TYPES.artist_only && (
              <TouchableOpacity onPress={this.logoutPressed}>
                <Text style={styles.logout}>Logout</Text>
              </TouchableOpacity>
            )}
            <View style={{ height: 250, width: '100%' }} />
          </this.customKeyboardAvoid>
        </ScrollView>

        <this.bottomButtonDock />

        <FoxyAlert
          isVisible={showAlert}
          alertBoxTitle="Logout?"
          alertMessage={alertText}
          firstButtonTitle="Yes"
          firstButtonTextColor={colors.dodgerBluePressed}
          secondButtonTitle="Cancel"
          firstButtonOnPress={this.confirmLogout}
          secondButtonOnPress={this.cancelAlert}
        />
        <FoxyAlert
          isVisible={showWarningModal}
          onTapOutside={this.onTapOutSideAlert}
          alertBoxTitle="Camera Permission Needed"
          alertMessage="We need camera permission to take your profile picture"
          firstButtonTitle="Close"
          // secondButtonTitle="Close"
          firstButtonOnPress={this.cancelModal}
          // secondButtonOnPress={this.cancelModal}
          hideSecondButton
          height={180}
        />
        <BottomModal
          header="Click or Upload Photo"
          subheading={this.uploadPhotoModalSubheading}
          buttonTitle={isWeb() ? 'Upload Photo' : 'Continue to Camera'}
          onClose={this.onTapOutsideSelfieUpdateModal}
          onActionClick={() => this.goToCamera('bottom_modal')}
          imgSrc={images.cameraIcon}
          onPressOutside={this.onTapOutsideSelfieUpdateModal}
          isVisible={this.state.visible}
          showWhatsAppBar={false}
        />
        <EmojiModal
          isVisible={isEmojiModalVisible}
          emojiSelected={this.emojiSelected}
          toggleEmojiModal={this.toggleEmojiModal}
        />
        <Toast
          ref={(ref) => {
            this.toast = ref;
          }}
        />
      </View>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  name: state.UserAccountInfo.profile.name,
  handle: state.UserAccountInfo.profile.handle,
  email: state.UserAccountInfo.profile.email,
  image: state.UserAccountInfo.profile.image_url,
  selfieImage: state.UserAccountInfo.profile.selfie_image_url,
  mobileNumber: state.UserAccountInfo.mobileNumber,
  address: state.UserAccountInfo.addresses,
  instagram_profile: state.UserAccountInfo.instagram_profile,
  hasBeenReferred: state.UserAccountInfo.artistProfile.hasBeenReferred,
  isArtist: state.UserAccountInfo.artistProfile.isArtist,
  selectedArtistTypes:
    state.UserAccountInfo.artistProfile.selectedArtistTypes || [],
  artistCtaOptions:
    state.UserAccountInfo.artistProfile &&
    state.UserAccountInfo.artistProfile.artistCtaOptions,
  artistTypes: state.UserAccountInfo.artistTypes,
  currentCity:
    state.UserAccountInfo.artistProfile &&
    state.UserAccountInfo.artistProfile.city,
  emoji:
    state.UserAccountInfo.artistProfile &&
    state.UserAccountInfo.artistProfile.emoji,
  bio:
    state.UserAccountInfo.artistProfile &&
    state.UserAccountInfo.artistProfile.bio,
  call_to_action_option_id:
    state.UserAccountInfo.artistProfile &&
    state.UserAccountInfo.artistProfile.call_to_action_option_id,
});
const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      editProfile,
      verifyHandleWithCallback,
      logoutUser,
      smsRetrieverStatus,
      addInviteCode,
      startFacialAnalysis,
      getArtistCtaOptions,
      addProfilePic,
      editProfileSuccess,
      getMyProfileDetails,
    },
    dispatch,
  ),
});

export default withProfiledNavigation(
  connect(mapStateToProps, mapDispatchToProps)(ProfileEdit),
);
