import React from 'react';
import { View, Image, StyleSheet } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import DatePicker from '../../libraries/ReactNativeDatePicker';
import FoxyButton from '../shared/FoxyButton';
import colors from '../../theme/Colors';
import Utility from '../../utils/Utility';
import { useNavigation } from '@react-navigation/native';
import images from '../../theme/Images';
import size from '../../theme/Fonts';
import ModalContainerWithoutSafeArea from './ModalContainerWithoutSafeArea';

const DateSelectionModal = (props) => {
  const navigation = useNavigation();
  let selectedDate = new Date('2000-01-01');

  function onDateChange(date) {
    selectedDate = date;
  }

  function onConfirmPress() {
    const { route: { params = {} } = {} } = props;
    const { onSubmit = () => {} } = params;
    onSubmit(selectedDate);
    dismissModal();
  }

  function dismissModal() {
    navigation.pop();
  }

  return (
    <ModalContainerWithoutSafeArea>
      <View style={styles.modalContainer}>
        <View style={styles.crossContainer}>
          <TouchableOpacity
            onPress={dismissModal}
            hitSlop={Utility.getHitSlop()}
          >
            <Image source={images.salon.cross_black_2x} style={styles.cross} />
          </TouchableOpacity>
        </View>
        <DatePicker
          open
          date={selectedDate}
          mode={'date'}
          maximumDate={new Date()}
          style={styles.datePicker}
          onDateChange={onDateChange}
          theme={'light'}
        />
        <FoxyButton
          title={'Done'}
          actionButtonColor={colors.black}
          onActionPress={onConfirmPress}
          layoutStyle={styles.buttonLayoutStyle}
        />
      </View>
    </ModalContainerWithoutSafeArea>
  );
};

export default DateSelectionModal;

const styles = StyleSheet.create({
  modalContainer: {
    position: 'absolute',
    bottom: 0,
    width: Utility.screenWidth,
    alignSelf: 'center',
    paddingVertical: 24,
    backgroundColor: colors.white,
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    alignItems: 'flex-end',
  },
  crossContainer: {
    top: 12,
    right: 12,
    zIndex: 1,
    position: 'absolute',
  },
  cross: {
    height: 16,
    width: 16,
    tintColor: colors.black,
  },
  datePicker: {
    width: Utility.getScreenWidth() - 24,
    marginBottom: 80,
    alignSelf: 'center',
  },
  dateStyle: {
    color: colors.foxyBlack,
    fontFamily: 'Roboto-Regular',
    fontSize: size.h2,
  },
  buttonLayoutStyle: {
    bottom: 28,
    left: 12,
    right: 12,
  },
});
