import React from 'react';
import { View, TouchableOpacity, Image, Animated } from 'react-native';
import Config from '../../libraries/ReactNativeConfig';
import Toast from 'react-native-easy-toast';
import _ from 'lodash';
import { Dropdown } from 'react-native-material-dropdown';
import DebouncedPressable from '../shared/DebouncedPressable';
import { LAYOUT, AVAILABLE_BUILD_TYPES } from '../../config/Constants';
import { ProductDetailStyles } from './styles';
import Images from '../../theme/Images';
import Utility from '../../utils/Utility';
import colors from '../../theme/Colors';
import images from '../../theme/Images';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import FollowArtistButton from '../Artist/FollowArtistButton';

import { BagIcon } from '../header';

import CustomNavigationHeader from './CustomNavigationHeader';
import { isDesktop, isNative, isPresent, isWeb } from '../../utils/BooleanUtility';
import { getMinifiedImage } from '../../utils/ImageUtility';

export default class ProductPageCustomNavigationHeader extends CustomNavigationHeader {
  constructor(props) {
    super(props);
    this.searchIconContainerStyle = [
      ProductDetailStyles.navigationShareButtonContainer,
      { right: 16 },
    ];
    this.containerStyle = [
      ProductDetailStyles.navigationContainer,
      {
        height: Utility.isIOS()
          ? this.navigationHeaderHeight + 6
          : this.navigationHeaderHeight,
      },
    ];
  }

  onSearchTap = () => {
    const { navigation } = this.props;

    navigation.push('Search', {});
  };

  render() {
    const {
      content,
      id,
      slug,
      image,
      isProduct,
      name,
      sp,
      mrp,
      discount,
      brand,
      subHeading,
      showMenuButton,
      previousScreen,
      morePage = false,
      morePageDisplay,
      showCartIcon,
      onPressCart,
      showSearchIcon,
      product_image_aspect_shape,
      smallImage = false,
    } = this.props;
    let { showShareButton } = this.props;
    let shareButtonRightSpace = 90;
    const cartButtonRightSpace = 52;
    if (content === 'artist') {
      shareButtonRightSpace = 52; // for artist page there are three icons in navigation header
    }
    if (
      previousScreen === 'dashboard' ||
      Utility.getCurrentAppType() === AVAILABLE_BUILD_TYPES.artist_only ||
      previousScreen === SCREEN_CONSTANTS.COLLECTION ||
      (previousScreen === SCREEN_CONSTANTS.ARTIST && morePage) ||
      previousScreen === SCREEN_CONSTANTS.PERSONALISED_PAGE ||
      morePageDisplay === 'full-page-banner'
    ) {
      showShareButton = false;
    }
    let headerImageInitialOutput = 0;
    if (!Utility.isBlank(previousScreen)) {
      if (previousScreen === SCREEN_CONSTANTS.COLLECTION) {
        navigationHeaderInitialColor = 'rgba(255,255,255,1)';
        navigationHeaderInitialElevation = 2;
        headerImageInitialOutput = 1;
      }
    }

    const inputRangeOffset =
      product_image_aspect_shape === 'vertical' ? 55 : 25;

    const showDropdown = previousScreen === SCREEN_CONSTANTS.ARTIST;
    const showFollowButton =
      previousScreen === SCREEN_CONSTANTS.ARTIST && !morePage;

    const inputRange = smallImage
      ? [140, 160]
      : [Utility.getScreenWidth() + 20, Utility.getScreenWidth() + 25];

    const searchImage = isPresent(Config.SEARCH_HEADER_IMAGE)
      ? { uri: getMinifiedImage(Config.SEARCH_HEADER_IMAGE) }
      : Images.ic_search_product;

    return (
      <>
        <View style={this.containerStyle}>
          <Animated.View
            style={[
              ProductDetailStyles.navigationHeaderChildView,
              {
                opacity: this.props.scrollY.interpolate({
                  inputRange,
                  outputRange: [headerImageInitialOutput, 1],
                }),

                useNativeDriver: true,
              },
            ]}
          >
            {this.props.children}
          </Animated.View>

          <DebouncedPressable
            onPress={this.goBack}
            style={ProductDetailStyles.navigationBackButtonContainer}
            hitSlop={Utility.getHitSlop('backButton')}
          >
            <Image
              source={Images.ic_back_product}
              style={ProductDetailStyles.navbarIconDefaultStyle}
            />
          </DebouncedPressable>
          {showShareButton && (
            <TouchableOpacity
              onPress={this.showShareModal}
              style={[
                ProductDetailStyles.navigationShareButtonContainer,
                {
                  right: shareButtonRightSpace,
                  zIndex: 4,
                },
              ]}
            >
              <Image
                source={Images.ic_share_product}
                style={ProductDetailStyles.navbarShareIconStyle}
              />
            </TouchableOpacity>
          )}

          {showCartIcon && (
            <View
              style={[
                ProductDetailStyles.navigationShareButtonContainer,
                { right: cartButtonRightSpace },
              ]}
            >
              <BagIcon handleTap={onPressCart} showCart />
            </View>
          )}

          {!isDesktop() && showSearchIcon && (
            <TouchableOpacity
              onPress={this.onSearchTap}
              hitSlop={Utility.getHitSlop()}
              style={this.searchIconContainerStyle}
            >
              <Image
                source={searchImage}
                style={ProductDetailStyles.search_icon}
              />
            </TouchableOpacity>
          )}
          {/* cant use HOC as we need Ref */}

          {/* Follow button in navigation header for artist page */}
          {showFollowButton ? (
            <FollowArtistButton
              id={id}
              followButtonLayout={LAYOUT.NAVIGATION_HEADER}
              slug={slug}
            />
          ) : null}

          {showCartIcon ? (
            <View
              style={[
                ProductDetailStyles.navigationShareButtonContainer,
                { right: cartButtonRightSpace },
              ]}
            >
              <BagIcon handleTap={onPressCart} showCart />
            </View>
          ) : null}
          {showMenuButton && (
            <TouchableOpacity
              // onPress={onClickMenu}
              onPress={this.menuButtonPressed}
              style={[
                ProductDetailStyles.navigationShareButtonContainer,
                { right: shareButtonRightSpace },
              ]}
              hitSlop={Utility.getHitSlop()}
            >
              <Image
                source={images.overflow_menu}
                style={{ height: 35, width: 35 }}
              />
            </TouchableOpacity>
          )}
          {showDropdown ? (
            <Dropdown
              ref={this.dropdownRef}
              label={null}
              data={this.dropDownOption}
              dropdownPosition={-2}
              pickerStyle={{
                position: 'absolute',
                width: 110,
              }}
              containerStyle={{
                right: 110,
                position: 'absolute',
                width: 0,
                height: 0,
                top: this.navigationHeaderHeight,
              }}
              renderAccessory={null}
              textColor={colors.foxyBlack}
              hitSlop={{ top: 20, right: 20, left: 20 }}
              onChangeText={(type) => this.dropDownOptionSelected(type)}
              fontSize={12}
              itemColor={colors.foxyBlack}
              selectedItemColor={colors.foxyBlack}
            />
          ) : null}
          {/* <this.conditionalDivider
            dividerInitialOpacity={headerImageInitialOutput}
            previousScreen={previousScreen}
            morePage={morePage}
          /> */}
        </View>
        <Toast
          ref={(ref) => {
            this.toast = ref;
          }}
        />
      </>
    );
  }
}
