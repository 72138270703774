import React, { PureComponent } from 'react';
import { View, Image, StyleSheet } from 'react-native';
import AnimatedLottieView from 'lottie-react-native';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setShowEdgePendingIndicator } from '../../actions/ActionTypes';

import animations from '../../theme/Animations';
import { isIOS, isPresent } from '../../utils/BooleanUtility';

class RoutinesTabIcon extends PureComponent {
  render() {
    const { image, style, activeRoutineSlugs = [] } = this.props;
    return (
      <View testID='routine-tab-icon' accessibilityLabel='routine-tab-icon'>
        <Image source={image} style={style} />
        {isPresent(activeRoutineSlugs) && (
          <View style={styles.liveIndicatorStyle}>
            <AnimatedLottieView
              source={animations.live_indicator}
              style={styles.liveIndicatorStyle}
              autoPlay
              loop
              resizeMode='contain'
              duration={2000}
            />
          </View>
        )}
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    activeRoutineSlugs: state.UserAccountInfo.activeRoutineSlugs,
  };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      setShowEdgePendingIndicator,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(RoutinesTabIcon);

const styles = StyleSheet.create({
  liveIndicatorStyle: {
    position: 'absolute',
    width: 30,
    height: 30,
    alignSelf: 'flex-end',
    bottom: isIOS() ? 1 : 3,
    left: isIOS() ? 2 : 3,
  },
});
