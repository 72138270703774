import React from 'react';

const ListsProfiler = ({ children }) => <>{children}</>;
const FlatListPerformanceView = ({ children }) => <>{children}</>;
const FlashListPerformanceView = ({ children }) => <>{children}</>;

export {
  ListsProfiler,
  FlatListPerformanceView,
  FlashListPerformanceView,
};
