import React, { PureComponent } from 'react';
import { View } from 'react-native';
import { FlatList } from 'react-native-gesture-handler';
import { FlatListPerformanceView } from '../../../libraries/ReactNativePerformanceShopify';
import Utility from '../../../utils/Utility';
import listingStyles from '../expandableList/styles';
import RoutineListWithDescriptionCard from '../../Routines/RoutineListWithDescriptionCard';
import ErrorBoundaryComponent from '../../shared/ErrorBoundaryComponent';
import { getListSource } from '../../../utils/PerfUtility';

class ListWithDescription extends PureComponent {
  static Components = {
    routine: RoutineListWithDescriptionCard,
  };

  getComponent = (item, index) => {
    if (Utility.isBlank(item)) {
      return null;
    }

    const {
      id,
      size,
      content,
      navigation,
      theme,
      onPress,
      onboarding,
      onCardClick,
      previousScreen,
      search,
      listName,
      listData,
      showToast,
      listContent,
    } = this.props;

    const listIndex = this.props.index;

    const ContainerComponent = ListWithDescription.Components[content];

    if (item === undefined || ContainerComponent === undefined) {
      return null;
    }

    return (
      <ErrorBoundaryComponent
        itemData={item}
        listData={listData}
        screenName={previousScreen}
      >
        <ContainerComponent
          listName={listName}
          listContent={listContent}
          size={size}
          id={item.id}
          itemData={item}
          type={item.type}
          navigation={navigation}
          theme={theme}
          listId={id}
          index={index}
          onPress={onPress}
          iconName={this.props.iconName}
          showCustomIcon={this.props.showCustomIcon}
          showColorComponent={this.props.showColorComponent}
          addedProducts={this.props.addedProducts}
          onboarding={onboarding}
          onCardClick={onCardClick}
          previousScreen={previousScreen}
          listIndex={listIndex}
          search={search}
          listData={listData}
          showToast={showToast}
          key={item?.id || index}
        />
      </ErrorBoundaryComponent>
    );
  };

  keyExtractor = (item, index) => `${item.schema}_${item.id}_${index}list`;

  renderItem = ({ item, index }) => this.getComponent(item, index);

  render() {
    const { item = {} } = this.props;
    const itemData = this.props.item.filter((item) => !!item);
    if (Utility.isBlank(itemData)) {
      return null;
    }
    return (
      <FlatListPerformanceView listName={`${getListSource(this, this.props)}`}>
        <FlatList
          data={itemData}
          scrollEnabled={false}
          renderItem={this.renderItem}
          keyExtractor={this.keyExtractor}
        />
      </FlatListPerformanceView>
    );
  }
}

export default ListWithDescription;
