import {
  View,
  TouchableOpacity,
  Image,
  Text,
  Linking,
} from 'react-native';
import React, { useEffect } from 'react';
import StyleSheet from 'react-native-media-query';
import { useNavigation, useRoute } from '@react-navigation/native';
import images from '../../theme/Images';
import colors from '../../theme/Colors';
import { getScreenWidth } from '../../utils/LayoutUtility';
import FullWidthDivider from '../../utils/FullWidthDivider';
import { PopUpStyles } from '../Product/styles';
import { isDesktop, isWebAndroid, isWebIOS } from '../../utils/BooleanUtility';
import DownloadAppButtons from '../webFooter/DownloadAppButtons';
import { APP_DOWNLOAD_LINK, FOXY_URLS } from '../../config/Constants';
import { setCookie } from '../../utils/webUtility';
import { AnalyticsManager, EventType } from '../../analytics';
import Config from '../../libraries/ReactNativeConfig';

export default function AppInstallPrompt() {
  const { params = {} } = useRoute();
  const { from } = params;
  const navigation = useNavigation();
  const closePrompt = () => {
    AnalyticsManager.logEvent(
      EventType.MISC_WEB_EVENTS.WEB_TO_APP_BANNER_DISMISS,
      { location: from },
    );
    setCookie('app_installed_prompt_closed', true, 7);
    navigation.pop();
  };

  useEffect(() => {
    AnalyticsManager.logEvent(
      EventType.MISC_WEB_EVENTS.WEB_TO_APP_BANNER_VIEW,
      { location: from },
    );
  }, []);

  const link = getDynamicLink(from);

  return (
    <>
      <TouchableOpacity onPress={closePrompt} style={styles.touchableContainer} />
      <View style={styles.modalContainer} pointerEvents="box-none">
        <View style={styles.bottomModal} dataSet={{ media: ids.bottomModal }}>
          <TouchableOpacity
            style={PopUpStyles.cancelButton}
            onPress={closePrompt}
          >
            <Image source={images.cross} style={PopUpStyles.cancelStyle} />
          </TouchableOpacity>
          <Image
            source={{ uri: images.appInstallPrompt }}
            style={styles.image}
            dataSet={{ media: ids.image }}
          />
          {isDesktop() && <DesktopButtons closePrompt={closePrompt} link={link} from={from} />}
          {!isDesktop() && <FullWidthDivider />}
          {!isDesktop() && <MobileButtons closePrompt={closePrompt} link={link} from={from} />}
        </View>
      </View>
    </>
  );
}

const MobileButtons = ({ closePrompt, link, from }) => {
  const handlePress = () => {
    AnalyticsManager.logEvent(
      EventType.MISC_WEB_EVENTS.GET_APP_CLICK,
      { location: from },
    );
    closePrompt();
    if (isWebIOS() || isWebAndroid()) {
      Linking.openURL(link);
    } else {
      Linking.openURL(APP_DOWNLOAD_LINK);
    }
  };

  return (
    <View style={styles.mobileButtonsContainer}>
      <View style={styles.logoAndAppNameContainer}>
        <Image source={{ uri: Config.DESKTOP_HEADER_LOGO }} style={logoStyle} />
      </View>
      <TouchableOpacity onPress={handlePress}>
        <Text style={styles.appOpenButton}>Open App</Text>
      </TouchableOpacity>
    </View>
  );
};

const DesktopButtons = ({ closePrompt, link, from }) => (
  <View style={styles.desktopButtonsContainer}>
    <DownloadAppButtons
      containerStyle={styles.downloadAppButtons}
      onPress={closePrompt}
      link={link}
      from={from}
    />
  </View>
);

const logoStyle = {
  width: +Config.DESKTOP_LOGO_WIDTH / 1.8,
  height: +Config.DESKTOP_LOGO_HEIGHT / 1.8,
  lineHeight: 1.5,
};

const { ids, styles } = StyleSheet.create({
  container: { backgroundColor: 'white', flex: 1 },
  touchableContainer: {
    width: '100%',
    height: '100%',
  },
  modalContainer: {
    height: '100%',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: colors.translucent,
  },
  bottomModal: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    marginBottom: 0,
    marginRight: 0,
    marginLeft: 0,
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundColor: colors.white,
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    zIndex: 1,
    padding: 20,
    paddingBottom: 24,
    '@media (min-width: 800px)': {
      top: '50%',
      left: '50%',
      bottom: 'auto',
      right: 'auto',
      transform: 'translate(-50%, -50%)',
      borderRadius: 16,
    },
  },
  image: {
    width: getScreenWidth() - 40,
    height: 110,
    marginVertical: 10,
    '@media (min-width: 800px)': {
      width: 535,
      height: 178,
    },
  },
  logoContainer: {
    height: 40,
    width: 40,
    boxShadow: '1px 2px 4px 0px #00000040',
    borderRadius: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
  logoCaption: {
    color: colors.foxyBlack,
    fontFamily: 'Roboto-Regular',
    marginLeft: 12,
    fontSize: 16,
  },
  mobileButtonsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: 12,
  },
  logoAndAppNameContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  appOpenButton: {
    backgroundColor: colors.black,
    height: 40,
    width: 140,
    color: colors.white,
    fontSize: 14,
    fontWeight: '700',
    borderRadius: 20,
    lineHeight: 40,
    textAlign: 'center',
    fontFamily: 'Roboto-Regular',
  },
  desktopButtonsContainer: {
    marginTop: 24,
    width: '70%',
  },
  downloadAppButtons: {
    justifyContent: 'space-between',
  },
});

const getDynamicLink = (from) => {
  if (from.startsWith('/products/') || from.startsWith('/shop')) return FOXY_URLS.appInstallPage.offers;
  if (from.startsWith('/feed')) return FOXY_URLS.appInstallPage.store_or_home;
  if (from.startsWith('/brands/')) return FOXY_URLS.appInstallPage.brand;
  if (from.startsWith('/lists/')) return FOXY_URLS.appInstallPage.list;
  if (from.startsWith('/categories')) return FOXY_URLS.appInstallPage.category;
  if (from.startsWith('/cart_items')) return FOXY_URLS.appInstallPage.cart;
  return FOXY_URLS.dynamicAppLink;
};
