import React, { PureComponent } from 'react';
import { View, StyleSheet, FlatList, Text } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import colors from '../../theme/Colors';
import size from '../../theme/Fonts';
import Utility from '../../utils/Utility';
import RemoteConfig from '../../utils/RemoteConfig';
import { REMOTE_CONFIG_KEYS } from '../../config/Constants';

class RoutineParticipationInfo extends PureComponent {
  constructor(props) {
    super(props);
    this.colors = [
      ['#FFE17C', '#FFE9A1'],
      ['#C7E7FF', '#D7EDFF'],
      ['#FFD6B0', '#FFE4CC'],
    ];
  }

  routineParticipationInfoCard = ({ item, index }) => {
    const gradientColors = this.colors[index];
    return (
      <View style={styles.cardContainer}>
        <LinearGradient
          colors={gradientColors}
          start={{ x: 0, y: 0 }}
          end={{ x: 0, y: 1 }}
          style={styles.progressGradient}
        />
        <View style={styles.textContainer}>
          <Text style={styles.title}>{item.title}</Text>
          <Text style={styles.subtitle} numberOfLines={2}>
            {item.subtitle}
          </Text>
        </View>
      </View>
    );
  };

  getNumberInThousandUnit = (number) => {
    const addDecimalPoint = number % 1000 > 100;
    return addDecimalPoint
      ? (number / 1000).toFixed(1)
      : (number / 1000).toFixed(0);
  };

  render() {
    const { dailyLogins = '', participantCount = 1000} = this.props;
    const participants =
      participantCount >= 1000
        ? `${this.getNumberInThousandUnit(participantCount)}k`
        : participantCount;
    const data = [
      { title: participants, subtitle: 'participants' },
      { title: `${dailyLogins}%`, subtitle: 'daily logins' },
    ];
    const minimumParticipationCount = RemoteConfig.getValue(
      REMOTE_CONFIG_KEYS.routine_minimum_participation_count,
    );
    if (participantCount < minimumParticipationCount) return null;
    //TODO: remove flatlist from here.
    return (
      <View style={styles.container}>
        <FlatList
          data={data}
          horizontal
          renderItem={this.routineParticipationInfoCard}
          style={styles.flatlist}
          showsHorizontalScrollIndicator={false}
          bounces={false}
          scrollEnabled={false}
        />
      </View>
    );
  }
}

export default RoutineParticipationInfo;

const styles = StyleSheet.create({
  container: {
    marginTop: 8,
    marginHorizontal: 10,
    height: 86,
    marginBottom: 24,
  },
  cardContainer: {
    width: (Utility.getScreenWidth() - 56) / 3,
    height: '100%',
    marginHorizontal: 6,
    borderRadius: 12,
  },
  textContainer: {
    top: 0,
    position: 'absolute',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
  },
  title: {
    color: colors.foxyBlack,
    fontSize: size.h24,
    fontFamily: 'Roboto-Bold',
    position: 'relative',
    textAlign: 'center',
    marginBottom: 4,
    marginTop: 18,
  },
  subtitle: {
    color: colors.foxyBlack,
    fontSize: size.h4,
    fontFamily: 'Roboto',
    flexShrink: 1,
    position: 'relative',
    textAlign: 'center',
    marginHorizontal: 4,
  },
  progressGradient: {
    top: 0,
    height: '100%',
    width: '100%',
    alignSelf: 'center',
    borderRadius: 12,
  },
  flatlist: {
    alignSelf: 'center',
  },
});
