import { camelCase } from 'lodash';
import LinearTabCard from './LinearTabCard';
import CircularTabCard from './CircularTabCard';
import PillsTabCard from './PillsTabCard';
import { CircularTabShimmer, PillsTabShimmer } from './TabListShimmer';

const TabGroupCardComponent = {
  linearTab: LinearTabCard,
  circularRail: CircularTabCard,
  pills: PillsTabCard,
};

const TabGroupCardShimmerComponent = {
  circularRail: CircularTabShimmer,
  pills: PillsTabShimmer,
};

export const getTabGroupCardComponent = (display) => {
  return TabGroupCardComponent[camelCase(display)];
};

export const getTabGroupCardShimmerComponent = (display) => {
  return TabGroupCardShimmerComponent[camelCase(display)];
};
