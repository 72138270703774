import React, { PureComponent } from 'react';
import {
  View,
  TouchableOpacity,
  Text,
  StyleSheet,
  Linking,
} from 'react-native';
import Config from '../../../libraries/ReactNativeConfig';
import { connect } from 'react-redux';
//import RadialGradient from 'react-native-radial-gradient';
import size from '../../../theme/Fonts';
import withNavigation from '../../../utils/WithNavigation';
import Utility from '../../../utils/Utility';
import { SCREEN_CONSTANTS } from '../../../config/ScreenConstants';
import AppConfig from '../../../config/AppConfig';
import FastImageView from '../../FastImageView';
import { getMinifiedImage } from '../../../utils/ImageUtility';
import {
  AnalyticsScreenNames,
  PERMISSIONS,
  PERMISSION_STORE,
  SCREEN,
} from '../../../config/Constants';

class UserProfileImage extends PureComponent {
  selfieImageUrl = () => {
    const {
      profile: { selfie_image_url },
    } = this.props;
    return getMinifiedImage(selfie_image_url, styles.imageStyle.width);
  };

  navigateToSelfieClick = () => {
    const { navigation } = this.props;
    Utility.checkPermissionStatic(
      PERMISSION_STORE.camera,
      true,
      (permissionStatus) => {
        if (
          permissionStatus === 'granted' ||
          permissionStatus === 'authorized'
        ) {
          navigation.navigate('TakeSelfie', {
            previousScreen: SCREEN_CONSTANTS.ATTRIBUTE_SELECTOR,
          });
          this.currentPermissionState = '';
        } else if (
          permissionStatus === 'never_ask_again' ||
          permissionStatus === 'denied'
        ) {
          if (Utility.isBlank(this.currentPermissionState)) {
            this.currentPermissionState = permissionStatus;
          } else {
            Linking.openSettings();
          }
        }
      },
      Utility.createPermissionMetaObject(
        AnalyticsScreenNames.MY_PROFILE,
        PERMISSIONS.CAMERA,
      ),
    );
  };

  render() {
    const { fontTitleColor, fontSubTitleColor, heading, subHeading } =
      this.props;
    const imageUrl = this.selfieImageUrl();
    return (
      <View style={styles.viewContainer}>
        {/*{!AppConfig.getBooleanValue(Config.HIDE_SELFIE_FLOW_PAYOFF) && (
          <RadialGradient
            style={styles.graientContainer}
            colors={['#C13A49', '#F4C44A', '#F77F6E', '#F45A44']}
            stops={[0.2, 0.2, 0.4, 0.75]}
            center={[100, 100]}
            radius={200}
          >
            <TouchableOpacity
              style={styles.touchableContainer}
              onPress={this.navigateToSelfieClick}
            >
              <FastImageView style={{ flex: 1 }} source={imageUrl}>
                <View style={styles.textViewContainer}>
                  <Text style={styles.textContainer}>Retake</Text>
                </View>
              </FastImageView>
            </TouchableOpacity>
          </RadialGradient>
        )}*/}
        <View style={styles.textView}>
          <Text style={[styles.headingText, { color: fontTitleColor }]}>
            {heading}
          </Text>
          <Text
            numberOfLines={2}
            style={[styles.subText, { color: fontSubTitleColor }]}
          >
            {subHeading}
          </Text>
        </View>
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    profile: state.UserAccountInfo.profile,
  };
};

export default withNavigation(connect(mapStateToProps, null)(UserProfileImage));

const styles = StyleSheet.create({
  viewContainer: {
    flexDirection: 'row',
  },
  textView: { flexDirection: 'column' },
  headingText: {
    fontFamily: 'Roboto',
    fontWeight: '700',
    fontStyle: 'normal',
    fontSize: size.h1,
    marginTop: Utility.isAndroid() ? 10 : 8,
  },
  subText: {
    fontFamily: 'Roboto',
    fontWeight: '400',
    fontStyle: 'normal',
    fontSize: size.h3,
    marginTop: 4,
    marginRight: 80,
  },
  touchableContainer: {
    height: 62,
    width: 62,
    borderRadius: 31,
    overflow: 'hidden',
    alignSelf: 'center',
    top: 3,
  },
  textViewContainer: {
    height: 32,
    alignSelf: 'center',
    backgroundColor: '#000',
    width: 100,
    bottom: -46,
    opacity: 0.8,
  },
  textContainer: {
    alignSelf: 'center',
    fontSize: 10,
    color: '#fff',
    fontFamily: 'Roboto-Medium',
    opacity: 1,
  },
  graientContainer: {
    height: 68,
    width: 68,
    borderRadius: 34,
    marginTop: 8,
    marginRight: 14,
    alignContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
  },
  imageStyle: {
    width: 30,
  },
});
