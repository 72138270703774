import StyleSheet from 'react-native-media-query';
import Config from '../../../libraries/ReactNativeConfig';
import colors from '../../../theme/Colors';
import size from '../../../theme/Fonts';
import Utility from '../../../utils/Utility';

export const { ids: popUpIds, styles: PopUpStyles } = StyleSheet.create({
  reviewPopup: {
    height: 322,
    width: Utility.getScreenWidth(),
    position: 'absolute',
    bottom: -322,
    backgroundColor: 'white',
    zIndex: 10,
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
  },
  pinPopup: {
    height: 190,
    width: Utility.getScreenWidth(),
    position: 'absolute',
    bottom: -190,
    backgroundColor: 'white',
    zIndex: 2,
  },
  container: {
    flexDirection: 'column',
    backgroundColor: 'white',
    width: Utility.getScreenWidth(),
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    '@media (min-width: 768px)': {
      width: Utility.getScreenWidth() / 2,
    },
  },
  addReviewHeader: {
    height: 40,
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 16,
  },
  reviewHeaderText: {
    lineHeight: 19,
    fontFamily: 'Roboto-Medium',
    fontSize: 18,
    fontStyle: 'normal',
    letterSpacing: 0,
    marginLeft: 12,
    color: colors.foxyBlack,
  },
  starContainer: {
    height: 22,
    marginTop: 14,
    marginBottom: 14,
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  videoContainer: {
    height: 106,
    marginLeft: 16,
    marginRight: 16,
    paddingLeft: 8,
    borderRadius: 4,
    alignItems: 'center',
  },
  addedVideoContainer: {
    height: 106,
    marginLeft: 16,
    marginRight: 16,
    borderWidth: 1,
    borderColor: colors.border,
    borderRadius: 4,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingLeft: 6,
    paddingRight: 6,
  },
  textInputStyleForPin: {
    height: 45,
    width: Utility.getScreenWidth() - 2 * Utility.largePadding - 54,
    marginLeft: 16,
    marginRight: 4,
    marginTop: 16,
    paddingLeft: 16,
    color: colors.foxyBlack,
  },
  submitButton: {
    marginTop: 16,
    height: 45,
    marginLeft: 12,
    marginRight: 12,
    backgroundColor: Utility.isPresent(Config.THEME_COLOR)
      ? Config.THEME_COLOR
      : colors.black,
    borderRadius: 4,
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 16,
    flexDirection: 'row',
  },
  submitButtonWithBottomInset: {
    marginTop: 16,
    height: 45,
    marginLeft: 12,
    marginRight: 12,
    backgroundColor: Utility.isPresent(Config.THEME_COLOR)
      ? Config.THEME_COLOR
      : colors.black,
    borderRadius: 4,
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 22 + Utility.bottomInset,
    flexDirection: 'row',
  },
  submitText: {
    fontFamily: 'Roboto-Medium',
    fontSize: 18,
    fontStyle: 'normal',
    letterSpacing: 0,
    textAlign: 'center',
    color: '#ffffff',
  },
  smallReviewStarStyle: {
    height: 32,
    width: 32,
    marginRight: 8,
  },
  cancelButton: {
    position: 'absolute',
    top: 12,
    right: 16,
  },
  cancelStyle: {
    tintColor: 'black',
    height: 14,
    width: 14,
  },
  locationButton: {
    position: 'absolute',
    height: 45,
    width: 45,
    marginLeft: 4,
    right: 16,
    top: 16,
    borderWidth: 1,
    borderColor: colors.disabled,
    borderRadius: 4,
    justifyContent: 'center',
    alignItems: 'center',
  },
  locationImage: {
    height: 22,
    width: 22,
    tintColor: colors.foxyBlack,
  },
  addReviewSubHeading: {
    alignSelf: 'center',
    fontFamily: 'Roboto-Regular',
    fontSize: 14,

    fontStyle: 'normal',
    letterSpacing: 0,
    textAlign: 'center',
    color: colors.disabled,
    marginTop: 12,
  },
  addVideoButton: {
    height: 46,
    width: 170,
    borderRadius: 3,
    elevation: 2,
    backgroundColor: 'white',
    shadowColor: colors.black,
    shadowOpacity: 0.2,
    shadowOffset: { width: 1, height: 1 },
    overflow: 'visible',
    marginTop: 8,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  addReviewButtonText: {
    fontFamily: 'Roboto-Medium',
    fontSize: 14,
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.foxyBlack,
  },
  videoIcon: {
    width: 21,
    height: 14,
    marginRight: 12,
  },
  plusIcon: {
    height: 8,
    width: 8,
    marginRight: 4,
    tintColor: colors.foxyBlack,
  },
  addedVideoStyle: {
    height: 60,
    width: 60,
    borderRadius: 3,
    margin: 6,
    elevation: 2,
    backgroundColor: 'white',
    shadowColor: colors.black,
    shadowOpacity: 0.2,
    shadowOffset: { width: 1, height: 1 },
  },
  addMoreVideoStyle: {
    height: 60,
    width: 60,
    borderRadius: 3,
    margin: 6,
    borderWidth: 1,
    borderColor: colors.border,
    justifyContent: 'center',
    alignItems: 'center',
  },
  addMorePlusIcon: {
    tintColor: colors.border,
    height: 14,
    width: 14,
  },
  changePincodeModal: {
    margin: 0,
    justifyContent: 'center',
  },
  emojiFlatListStyle: {
    marginLeft: 12,
    marginRight: 12,
  },
  emojiItem: {
    height: Utility.getDynamicWidth(7, 112),
    width: Utility.getDynamicWidth(7, 112),
    margin: 8,
  },
  modalTitle: {
    fontFamily: 'Roboto-Medium',
    fontSize: 16,
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.foxyBlack,
  },
  textInputStyleForEmail: {
    color: colors.foxyBlack,
    fontSize: 14,
    fontFamily: 'Roboto-Regular',
  },
  addEmailContainer: {
    height: 60,
    margin: 16,
    marginTop: 0,
  },
  activityIndicatorInActionButton: {
    position: 'absolute',
    alignSelf: 'center',
    left: 104,
  },
  surpriseModal: {
    height: 300,
    width: '100%',
    alignItems: 'center',
  },
  addDeliveryInstructionContainer: { flex: 1, justifyContent: 'flex-end' },
  inputContainerStyle: { borderBottomColor: colors.border },
  changeSlotContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 30,
    marginBottom: 20,
  },
  decrementButtonContainer: {
    height: 40,
    width: 40,
    borderRadius: 20,
    shadowColor: '#555',
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.5,
    shadowRadius: 1,
    alignItems: 'center',
    justifyContent: 'center',
    elevation: 5,
    backgroundColor: colors.white,
    marginHorizontal: 24,
  },
  timeSlotIncrementDecrementImage: { height: 16, width: 16 },
  timeSlotText: {
    fontSize: 18,
    fontFamily: 'Roboto-Bold',
    color: colors.foxyBlack,
    width: 110,
    alignSelf: 'center',
    textAlign: 'center',
  },
  timeSlotHourText: {
    fontSize: 24,
    fontFamily: 'Roboto-Bold',
    color: colors.foxyBlack,
    width: 100,
    alignSelf: 'center',
    textAlign: 'center',
  },
  confirmTimeSlogAction: {
    height: 40,
    width: 40,
    borderRadius: 20,
    shadowColor: '#555',
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.5,
    shadowRadius: 1,
    alignItems: 'center',
    justifyContent: 'center',
    elevation: 5,
    backgroundColor: colors.white,
    marginHorizontal: 24,
  },
  slotsContainer: {
    backgroundColor: '#F4F6F8',
    paddingVertical: 6,
    paddingHorizontal: 12,
    borderRadius: 14,
    marginLeft: 12,
  },
  slotsText: {
    fontSize: 14,
    fontFamily: 'Roboto-Regular',
    color: colors.foxyBlack,
  },
  selectSlotsContainer: { flexDirection: 'row' },
  selectSlotFlatlistContainer: {
    flexDirection: 'column',
    backgroundColor: 'white',
    width: Utility.getScreenWidth(),
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    paddingHorizontal: 16,
  },
  selectSlotSafearea: { justifyContent: 'flex-end' },
  radioButtonText: {
    marginLeft: 16,
    marginTop: 0,
    fontFamily: 'Roboto',
    fontSize: size.h3,
    color: colors.black,
    fontWeight: '400',
  },
  radioButtonContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginBottom: 20,
  },
  addEmailModalContainer: {
    flex: 1,
    justifyContent: 'flex-end',
    '@media (min-width: 768px)': {
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
});
