import React, { useRef, useState } from 'react';
import { View, Text, FlatList, StyleSheet } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import moment from 'moment';
import { memoize } from 'lodash';
import RoutineActionButton from '../../components/Routines/RoutineDetails/RoutineActionCard/RoutineActionButton';
import Utility from '../../utils/Utility';
import RoutinesUtility from '../../components/Routines/RoutinesUtility';
import { getScreenWidth } from '../../utils/LayoutUtility';
import colors from '../../theme/Colors';
import size from '../../theme/Fonts';
import RoutineActivityHeader from './RoutineActivityHeader';
import RoutineActivityList from '../../components/Routines/RoutineDetails/RoutineActivityList';
import RoutineLiveActionCard from '../../components/Routines/RoutineDetails/RoutineLiveActionCard';
import RoutineActionCard from '../../components/Routines/RoutineDetails/RoutineActionCard/RoutineActionCard';
import RoutineLiveStreamEndedCard from '../../components/Routines/RoutineDetails/RoutineLiveStreamEndedCard';
import { GRADIENT_CONFIGURATIONS } from '../../config/Constants';

const RoutineActivity = (props) => {
  const {
    item,
    index,
    slug,
    selectedDay,
    selectedDayActivitiesData = [],
    actionButtonPress,
    isRoutineActive,
    dailyPhotoes,
    artist,
    selectedDayDateTime,
    showActivityToast,
    tabListWidth,
  } = props;

  const LayoutComponent = {
    list: RoutineActivityList,
    liveStream: RoutineLiveActionCard,
    feature: RoutineActionCard,
    poll: RoutineActionCard,
    liveStreamEnded: RoutineLiveStreamEndedCard,
  };

  const [layouts, setLayouts] = useState({});

  const { startsAt: currentActivityStartTime = '', backgroundColor = '', markedDone, } =
    item;
  const [markAsDone, setMarkAsDone] = useState(markedDone);
  const gradientColor = Utility.isBlank(backgroundColor)
    ? '#F9DDFF'
    : backgroundColor;
  const gradientColors = [`${gradientColor}66`, gradientColor];
  const { startsAt: nextActivityStartTime = '' } =
    selectedDayActivitiesData?.[index + 1] || {};
  const currentActivityOffsetTime = RoutinesUtility.getLocalTimeFromUTC(
    currentActivityStartTime,
  );
  const nextActivityOffsetTime = RoutinesUtility.getLocalTimeFromUTC(
    nextActivityStartTime,
  );
  const timeToShow = moment(currentActivityOffsetTime)
    .format('hh:mm a')
    .replace(/^0+/, '');
  const data = item.activityItems;
  const activityTypes = data?.map((state) => state?.activityType || null);
  const containsActivityType = activityTypes?.includes('List');
  const isPollActionCard = activityTypes?.includes('Poll');
  const activityCardTypes = data?.map(
    (state) => state.activity?.arguments?.cardType || null,
  );
  const containsLiveStream = activityCardTypes?.includes('liveStream');

  const verticalLineIndicator = (currentActivityTime, nextActivityTime) => {
    const progress = getIndicatorProgress(
      currentActivityTime,
      nextActivityTime,
    );
    const indicatorStyle = [styles.verticalLineGreen, { height: progress }];
    return (
      <View style={styles.verticalLineContainer}>
        <View style={styles.verticalLine} />
        <View style={indicatorStyle} />
      </View>
    );
  };

  const getIndicatorProgress = (currentActivityTimestamp, nextActivityTimestamp) => {
    if (RoutinesUtility.isDateInFuture(selectedDayDateTime)) {
      return '0%';
    }
    if (
      RoutinesUtility.isDateInPast(selectedDayDateTime) ||
      !isRoutineActive
    ) {
      return '100%';
    }
    const currentTimeInMinutes =
      new Date().getHours() * 60 + new Date().getMinutes();
    const currentActivityTimeInMinutes =
      new Date(currentActivityTimestamp).getHours() * 60 +
      new Date(currentActivityTimestamp).getMinutes();
    const nextActivityTimeInMinutes =
      new Date(nextActivityTimestamp).getHours() * 60 +
      new Date(nextActivityTimestamp).getMinutes();
    if (currentTimeInMinutes > currentActivityTimeInMinutes) {
      if (currentTimeInMinutes >= nextActivityTimeInMinutes) {
        return '100%';
      }
      const totalTimeDiff =
        nextActivityTimeInMinutes - currentActivityTimeInMinutes;
      const currentActivityTimeDiff =
        currentTimeInMinutes - currentActivityTimeInMinutes;
      return `${((currentActivityTimeDiff * 100) / totalTimeDiff).toFixed(0)}%`;
    }
    return '0%';
  };

  const setActionButtonVisibility = () => {
    const {
      startsAt = new Date(),
      activity: { arguments: args = {} } = {},
    } = item;
    const dailyUploadExists = dailyPhotoes.filter(
      (element) => element?.day === selectedDay,
    );
    const isUploadActionCard =
      args?.action === 'upload' && Utility.isPresent(dailyUploadExists);
    const isEnabled =
      new Date() > RoutinesUtility.getLocalTimeFromUTC(startsAt);
    const isToday = RoutinesUtility.isToday(startsAt);
    if (isPollActionCard && !isToday) {
      return 'disabled';
    }
    if (isUploadActionCard || isPollActionCard) {
      return 'hidden';
    }
    if (isEnabled && isToday && isRoutineActive) {
      return 'visible';
    }
    return 'disabled';
  };

  const checkIfLiveStreamEnded = (activityStartTime, duration) => {
    const fiveMinIntervalInMil = 5 * 60 * 1000;
    const timezoneOffsetDate =
      RoutinesUtility.getLocalTimeFromUTC(activityStartTime);
    const liveStreamEndedTime = new Date(
      timezoneOffsetDate.getTime() +
        duration * 60 * 1000 +
        fiveMinIntervalInMil,
    );
    return new Date() > liveStreamEndedTime;
  };

  const getLayoutComponentForActionCard = (item) => {
    const {
      activityType = '',
      duration = '',
      startsAt = new Date(),
      activity: { arguments: args = {} } = {},
    } = item;
    let component = LayoutComponent[`${activityType}`.toLowerCase()];
    if (args?.cardType === 'liveStream') {
      component = LayoutComponent['liveStream'];
      const hasLiveEnded = checkIfLiveStreamEnded(startsAt, duration);
      if (hasLiveEnded) {
        component = LayoutComponent['liveStreamEnded'];
      }
    }
    return component;
  };

  const inactivePollTapped = () => {
    showActivityToast('Poll not open yet');
  };

  const onActivityLayout = (e, index) => {
    const updatedLayouts = { ...layouts, [index]: e.nativeEvent.layout };
    setLayouts(updatedLayouts);
  };

  const renderRoutineActivityItem = ({ item, index }) => {
    const { activity: { id: listId = '' } = {} } = item;
    const { navigation } = props;
    const LayoutComponent = getLayoutComponentForActionCard(item);
    if (LayoutComponent === null || LayoutComponent === undefined) return null;

    return (
      <View
        style={styles.routineActivityContainer}
        onLayout={memoize(
          (e) => onActivityLayout(e, index),
          () => [index],
        )}
      >
        <View style={styles.layoutComponentStyle}>
          <LayoutComponent
            itemData={item}
            slug={slug}
            listId={listId}
            onActionPress={actionButtonPress}
            dailyPhotos={dailyPhotoes}
            buttonVisibility={setActionButtonVisibility(item)}
            inactivePollTapped={inactivePollTapped}
            artist={artist}
            navigation={navigation}
            setMarkAsDone={setMarkAsDone}
            markAsDone={markAsDone}
            tabListWidth={tabListWidth}
          />
        </View>
      </View>
    );
  };

  return (
    <View style={styles.activityContainer}>
      <View style={styles.cardContainer}>
        {data.length === 1 && containsActivityType ? (
          <FlatList
            data={item.activityItems}
            renderItem={renderRoutineActivityItem}
            keyExtractor={(item) => `${item?.name}${item?.id}`}
            contentContainerStyle={styles.activityItemStyle}
          />
        ) : (
          <LinearGradient
            colors={gradientColors}
            start={GRADIENT_CONFIGURATIONS.DIAGONAL_TL_BR.start}
            end={GRADIENT_CONFIGURATIONS.DIAGONAL_TL_BR.end}
            style={styles.gradient}
          >
            {!containsLiveStream && (
              <>
                <RoutineActivityHeader item={item} />
                <View style={styles.divider} />
              </>
            )}
            <FlatList
              data={item.activityItems}
              renderItem={renderRoutineActivityItem}
              keyExtractor={(item) => `${item?.name}${item?.id}`}
            />
            {!containsLiveStream && (
              <RoutineActionButton
                itemData={item}
                onActionPress={actionButtonPress}
                buttonVisibility={setActionButtonVisibility(item)}
                setMarkAsDone={setMarkAsDone}
                markAsDone={markAsDone}
              />
            )}
          </LinearGradient>
        )}
      </View>
      <View style={styles.timelineContainer}>
        <Text style={styles.timelineText}>{timeToShow}</Text>
        {Utility.isPresent(nextActivityStartTime) &&
          verticalLineIndicator(
            currentActivityOffsetTime,
            nextActivityOffsetTime,
          )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  routineActivityContainer: {
    marginTop: 4,
  },
  layoutComponentStyle: {
    marginBottom: 0,
  },
  activityItemStyle: {
    paddingBottom: 0,
  },
  activityContainer: {
    paddingLeft: 12,
    justifyContent: 'flex-end',
    width: '100%',
  },
  cardContainer: {
    width: getScreenWidth() - 58,
    alignSelf: 'flex-end',
    paddingHorizontal: 12,
    paddingVertical: 12,
  },
  gradient: {
    borderRadius: 16,
  },
  verticalLine: {
    width: 1,
    flex: 1,
    backgroundColor: colors.divider,
  },
  verticalLineGreen: {
    width: 1,
    backgroundColor: colors.green,
    zIndex: 1,
    top: 0,
    position: 'absolute',
  },
  verticalLineContainer: {
    flex: 1,
  },
  timelineText: {
    color: colors.black,
    fontFamily: 'Roboto-Regular',
    fontSize: size.h3_5,
    marginBottom: 4,
    backgroundColor: colors.background,
    zIndex: 2,
  },
  timelineContainer: {
    position: 'absolute',
    top: 0,
    left: 8,
    height: '100%',
    width: 58,
    alignItems: 'center',
  },
  divider: {
    backgroundColor: colors.white,
    height: 1,
    marginVertical: 12,
    marginHorizontal: 12,
  },
});

export default RoutineActivity;
