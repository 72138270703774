import React, { PureComponent } from 'react';
import { View, StyleSheet, Text } from 'react-native';
import _, { filter, findIndex, isEqual } from 'lodash';
import PropTypes from 'prop-types';
import { FlatList } from 'react-native-gesture-handler';
import ChipsButton from '../shared/ChipsButton';
import images from '../../theme/Images';
import Utility from '../../utils/Utility';
import colors from '../../theme/Colors';
import AppConfig from '../../config/AppConfig';
import Config from '../../libraries/ReactNativeConfig';
import { connect } from 'react-redux';
import { isPresent } from '../../utils/BooleanUtility';
import FilterUtils from './FilterUtils';
import { EMPTY_OBJECT } from '../../config/Constants';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';

class SortingFilteringOptions extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showBottomModal: false,
      quickFilterOptions: [],
    };

    this.appliedFiltersFromModal = [];
    this.horizontalFlatListRef = React.createRef();
  }

  componentDidMount = () => {
    const { quickFilters, onSortingFilterApplied, filtersFromDeepLink } =
      this.props;
    const { quickFilterOptions } = this.state;
    if (Utility.isBlank(quickFilters)) return;

    this.resetQuickFilters();
    onSortingFilterApplied(this.updateSortingChipName);
  };

  componentDidUpdate(prevProps) {
    const { selectedFilters: prevSelectedFilters = EMPTY_OBJECT } = prevProps;
    const { selectedFilters = EMPTY_OBJECT } = this.props;
    if (
      JSON.stringify(prevSelectedFilters) !== JSON.stringify(selectedFilters)
    ) {
      this.resetQuickFilters();
    }
  }

  sortingFilteringOptionsRef = (ref) => {
    this.horizontalFlatListRef = ref;
  };

  scrollToStart = () => {
    if (this.horizontalFlatListRef.current) {
      this.horizontalFlatListRef.current.scrollToOffset({
        offset: 0,
        animated: true,
      });
    }
  };

  resetQuickFilters = () => {
    const {
      quickFilters,
      filtersFromDeepLink,
      filters: listFilters,
      facialAnalysis: { facialProperties },
      selectedFilters = EMPTY_OBJECT,
      showPersonalisedDetails = false,
      previousScreen = '',
    } = this.props;
    let filters = [];
    if (Utility.isBlank(filtersFromDeepLink)) {
      filters = quickFilters.map((element) => ({
        ...element,
        selected: element.preSelected || false,
        filter_section_criteria: 'recommended_filters',
      }));
    } else {
      filters = quickFilters.map((element) => ({
        ...element,
        selected: filtersFromDeepLink.some((e) => e.value == element.value),
      }));
    }

    this.insertItemAtPosition(filters, 0, {
      leftIcon: images.iconSortFilter,
      criteria_name: 'quick_filter_sort_by',
      tintColor: colors.foxyBlack,
      name: 'Sort & Filter',
      value: 0,
      count: 0,
    });

    const isFoxyMatchPresent = Utility.isPresent(
      listFilters.filter((filter) => filter.display === 'foxy_match'),
    );
    const foxyMatchAttributeObject =
      selectedFilters['user_attribute_allowed_value_id'] ||
      selectedFilters['uaavs'];
    if (
      isFoxyMatchPresent &&
      AppConfig.getBooleanValue(Config.FILTER_MODAL_FOXY_MATCH) &&
      Utility.isPresent(facialProperties) &&
      !showPersonalisedDetails &&
      previousScreen !== SCREEN_CONSTANTS.MORE_PAGE &&
      previousScreen !== SCREEN_CONSTANTS.COMBO_OFFER_DETAIL
    ) {
      this.insertItemAtPosition(filters, 1, {
        rightIcon: images.chevronDown,
        criteria_name: 'quick_filter_foxy_match',
        tintColor: colors.foxyBlack,
        name: 'Foxy Match',
        value: foxyMatchAttributeObject?.[0]?.value || 1,
        count: 0,
        selected: isPresent(foxyMatchAttributeObject),
      });
    }

    this.setState(
      {
        quickFilterOptions: filters,
      },
      () => {
        //  console.tron.log("----", filters);
        const prevFilters = Utility.isPresent(filtersFromDeepLink)
          ? []
          : this.state.quickFilterOptions;
        this.applyQuickFiltersIfSelectedFiltersExists(prevFilters);
      },
    );
  };

  applyQuickFiltersIfSelectedFiltersExists = (prevFilters) => {
    const { selectedFilters } = this.props;

    if (
      Utility.isPresent(selectedFilters) &&
      Utility.isPresent(
        Utility.convertSelectedFiltersObjectIntoArray(selectedFilters),
      )
    ) {
      this.applyFilters(
        prevFilters,
        Utility.convertSelectedFiltersObjectIntoArray(selectedFilters),
      );
    }
  };

  resetQuickFiltersCallback = (filters, element, apply) => {
    let selected = false;
    const index = Utility.indexOfItem(filters, element.value);
    if (index === -1) {
      selected = element.selected;
    } else {
      selected = apply;
    }

    if (
      Utility.getIndexOfFoxyMatch(filters) !== -1 &&
      element.criteria_name === 'quick_filter_foxy_match'
    ) {
      selected = apply;
    }

    return { ...element, selected };
  };

  setSpecificFilters = (filters = [], apply = false) => {
    const { quickFilterOptions } = this.state;
    const { quickFilters } = this.props;

    // This mapping is done since we get the criteria_name as user_attribute_allowed_value_id or uaavs from BE and we have the criteria name as quick_filter_foxy_match at FE
    const filtersWithUpdatedFoxyMatchObject = filters.map((element) =>
      element.criteria_name === 'user_attribute_allowed_value_id' ||
      element.criteria_name === 'uaavs'
        ? {
            ...element,
            criteria_name: 'quick_filter_foxy_match',
            name: 'Foxy Match',
            rightIcon: images.chevronDown,
            tintColor: colors.foxyBlack,
          }
        : element,
    );

    const quickFilterArray = FilterUtils.filterQuickFilterArray(
      quickFilterOptions,
      quickFilters,
    ).filter(
      (element) =>
        !filtersWithUpdatedFoxyMatchObject.find(
          (filterElement) => filterElement.value == element.value,
        ),
    );
    quickFilterArray.splice(1, 0, ...filtersWithUpdatedFoxyMatchObject);
    let newArray = quickFilterArray.map((element) =>
      this.resetQuickFiltersCallback(
        filtersWithUpdatedFoxyMatchObject,
        element,
        apply,
      ),
    );
    const sortByQuickFilter = filters.filter(
      (obj) => obj.criteria_name === 'sort_by',
    );

    // this check is for single selection of the sort by filters in quick filters row
    if (isPresent(sortByQuickFilter) && apply) {
      newArray = newArray.map((obj) => {
        if (
          obj.criteria_name === 'sort_by' &&
          sortByQuickFilter[0].value !== obj.value
        ) {
          return { ...obj, selected: false };
        }
        return obj;
      });
    }
    const uniqueArray = _.uniqBy(newArray, (e) => e.value);
    return uniqueArray;
  };

  resetSpecificFilters = (filters = []) =>
    this.setSpecificFilters(filters, false);

  applyFilters = (prevFilters, nextFilters = [], sortByFilters) => {
    this.appliedFiltersFromModal = this.resetSpecificFilters(prevFilters);
    this.appliedFiltersFromModal = this.setSpecificFilters(nextFilters, true);
    // console.tron.log("Apply Filters ", this.appliedFiltersFromModal),
    this.appliedFiltersFromModal[0].name = Utility.isPresent(sortByFilters)
      ? `Sort: ${sortByFilters[0].name}`
      : 'Sort & Filter';

    setTimeout(() => {
      this.setState(
        {
          quickFilterOptions: this.appliedFiltersFromModal,
        },
        () => this.updateSortingChipCount(),
      );
    }, 0);
  };

  insertItemAtPosition = (arr, index, item) => {
    arr.splice(index, 0, item);
  };

  updateSortingChipName = (name) => {
    const { quickFilterOptions } = this.state;
    quickFilterOptions[0].name = Utility.isBlank(name)
      ? 'Sort & Filter'
      : `Sort: ${name}`;
    this.setState({
      quickFilterOptions,
    });
  };

  updateSortingChipCount = () => {
    const { quickFilterOptions } = this.state;
    const { selectedFilters } = this.props;

    const groupByCriteriaNameObject = _.groupBy(
      Utility.convertSelectedFiltersObjectIntoArray(selectedFilters),
      (element) => element.criteria_name,
    );

    // const selectedQuickFiltersCount = quickFilterOptions.filter((element) => element.selected);
    const quickFiltersCopy = [...quickFilterOptions];
    const keys = Object.keys(groupByCriteriaNameObject);
    quickFiltersCopy[0].count = keys.length;

    this.setState({
      quickFilterOptions: quickFiltersCopy,
    });
  };

  sortingOptionsView = ({ item, index }) => {
    const { unselectedChipBackgroundColor, selectedChipBackGroundColor } =
      this.props;
    return (
      <ChipsButton
        criteriaName={item.criteria_name}
        chipLeftIcon={item.leftIcon}
        foxyMatchRightIcon={item?.rightIcon}
        chipName={item.name}
        count={item.count}
        isSelected={item.selected}
        onChipClick={() => this.onChipClick(item, true, index)}
        onQuickFilterRemoved={() => this.onChipClick(item, false, index)}
        chipRightIcon={item.selected ? images.chevronDown : null}
        colorCode={item.colour_code}
        unselectedChipBackgroundColor={unselectedChipBackgroundColor}
        selectedChipBackGroundColor={selectedChipBackGroundColor}
        tintColor={item.tintColor}
        index={index}
      />
    );
  };

  highlightSelectedChip = (item, added, index) => {
    const { quickFilterOptions } = this.state;
    const { onQuickFilterPress, quickFilters = [] } = this.props;
    const { criteria_name, is_quick_filter = true } = item;
    const resetQuickFilter = this.resetQuickFilters;
    const sortObjIndex = quickFilterOptions.findIndex(
      (obj) =>
        obj.criteria_name === 'sort_by' &&
        obj.selected === true &&
        obj?.is_quick_filter === false &&
        obj.name !== item.name,
    );
    let filteredQuickFilterOptions = quickFilterOptions;
    if (sortObjIndex !== -1 && item.criteria_name === 'sort_by') {
      // Update selected property to false for the found object
      filteredQuickFilterOptions = quickFilterOptions.filter(
        (obj) => !(obj.criteria_name === 'sort_by' && obj.selected === true),
      );
    }

    if (criteria_name !== 'quick_filter_sort_by') {
      let quickFilterOptionsWithSelectedKey = filteredQuickFilterOptions.map(
        (element) =>
          element.name === item.name
            ? { ...element, selected: added }
            : { ...element },
      );

      const indexOfSelectedQuickFilter = _.findIndex(
        quickFilters,
        (e) => e?.value === item.value,
      );
      if (
        !added &&
        !is_quick_filter &&
        indexOfSelectedQuickFilter === -1 &&
        item.criteria_name !== 'quick_filter_foxy_match'
      ) {
        quickFilterOptionsWithSelectedKey =
          quickFilterOptionsWithSelectedKey.filter(
            (quickFilter) => quickFilter.value !== item.value,
          );
      }
      this.setState(
        {
          quickFilterOptions: quickFilterOptionsWithSelectedKey,
        },
        () => this.updateSortingChipCount(),
      );
      this.scrollToStart();
    }
    onQuickFilterPress(item, added, index, resetQuickFilter);
  };

  onChipClick = (item, added = false, index) => {
    const { selected } = item;
    const { scrollY } = this.props;
    if (selected) {
      this.highlightSelectedChip(item, false, index);
      return;
    }
    this.highlightSelectedChip(item, added, index);
  };

  listFooterView = () => <View style={{ padding: 8 }} />;

  render() {
    const { quickFilterOptions } = this.state;
    const { selectedFilters } = this.props;
    return (
      <View style={styles.flatListContainer}>
        <FlatList
          // extraData={this.state.quickFilterOptions}
          ref={this.horizontalFlatListRef}
          data={quickFilterOptions}
          horizontal
          showsHorizontalScrollIndicator={false}
          renderItem={this.sortingOptionsView}
          ListFooterComponent={this.listFooterView}
          style={styles.listStyle}
          extraData={selectedFilters}
        />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  flatListContainer: {
    flexDirection: 'column',
  },
  listStyle: {
    paddingLeft: 8,
    paddingRight: 8,
  },
});

const mapStateToProps = (state) => ({
  facialAnalysis: state.UserAccountInfo.facialAnalysis,
});

export default connect(mapStateToProps, null)(SortingFilteringOptions);

// SortingFilteringOptions.prototype = {
//   enableSortingOptions: PropTypes.bool,
//   sortingOptions: PropTypes.array,
// };

// SortingFilteringOptions.defaultProps = {
//   enableSortingOptions: false,
//   sortingOptions: [],
// };
