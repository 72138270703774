import React from 'react';
import { useRoute } from '@react-navigation/native';
import { API_DOMAIN, LISTS_API_VERSION } from '../../config/Constants';
import ShopOnline from '../../containers/List/ShopOnline';

export default function ShopOnlineBrand() {
  const { params = {} } = useRoute();
  const { name } = params;
  const url = `${API_DOMAIN}/api/${LISTS_API_VERSION}/brands/${name}/shop_online`;
  return <ShopOnline url={url} />;
}
