import { View, Text, TouchableOpacity, Image, Linking } from 'react-native';
import React, { useState, useEffect } from 'react';
import StyleSheet from 'react-native-media-query';
import Modal from 'react-native-modal';
import { getCookie, setCookie } from '../../utils/webUtility';
import { PopUpStyles } from '../Product/styles';
import images from '../../theme/Images';
import { isDesktop, isWebAndroid, isWebIOS } from '../../utils/BooleanUtility';
import FullWidthDivider from '../../utils/FullWidthDivider';
import DownloadAppButtons from '../webFooter/DownloadAppButtons';
import { AnalyticsManager, EventType } from '../../analytics';
import { APP_DOWNLOAD_LINK } from '../../config/Constants';
import colors from '../../theme/Colors';
import { getScreenWidth } from '../../utils/LayoutUtility';
import AppConfig from '../../config/AppConfig';
import Config from '../../libraries/ReactNativeConfig';

export default function AppInstallPrompts({ link }) {
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    if (AppConfig.getBooleanValue(Config.HIDE_DOWNLOAD_APP_PROMPT)) return;
    if (!getCookie('app_installed_prompt_closed')) {
      AnalyticsManager.logEvent(
        EventType.MISC_WEB_EVENTS.WEB_TO_APP_BANNER_VIEW,
        { location: location.pathname },
      );
      setIsVisible(true);
    }
  }, []);

  const closeModal = () => {
    AnalyticsManager.logEvent(
      EventType.MISC_WEB_EVENTS.WEB_TO_APP_BANNER_DISMISS,
      { location: location.pathname },
    );
    setCookie('app_installed_prompt_closed', true, 7);
    setIsVisible(false);
  };

  const handleBackdropPress = () => {
    if (isDesktop()) {
      closeModal();
    }
  };

  if (!isVisible) return null;
  return (
    <Modal
      isVisible={isVisible}
      hasBackdrop={isDesktop()}
      coverScreen={isDesktop()}
      style={styles.modal}
      onBackdropPress={handleBackdropPress}
    >
      <View style={styles.bottomModal} dataSet={{ media: ids.bottomModal }}>
        <TouchableOpacity
          style={PopUpStyles.cancelButton}
          onPress={closeModal}
        >
          <Image source={images.cross} style={PopUpStyles.cancelStyle} />
        </TouchableOpacity>
        <Image
          source={{ uri: images.appInstallPrompt }}
          style={styles.image}
          dataSet={{ media: ids.image }}
        />
        {isDesktop() && <DesktopButtons closePrompt={closeModal} link={link} />}
        {!isDesktop() && <FullWidthDivider />}
        {!isDesktop() && <MobileButtons closePrompt={closeModal} link={link} />}
      </View>
    </Modal>
  );
}

const MobileButtons = ({ closePrompt, link }) => {
  const handlePress = () => {
    AnalyticsManager.logEvent(
      EventType.MISC_WEB_EVENTS.GET_APP_CLICK,
      { location: location.pathname },
    );
    closePrompt();
    if (isWebIOS() || isWebAndroid()) {
      Linking.openURL(link);
    } else {
      Linking.openURL(APP_DOWNLOAD_LINK);
    }
  };

  return (
    <View style={styles.mobileButtonsContainer}>
      <View style={styles.logoAndAppNameContainer}>
        <Image source={{ uri: Config.DESKTOP_HEADER_LOGO }} style={logoStyle} />
      </View>
      <TouchableOpacity onPress={handlePress}>
        <Text style={styles.appOpenButton}>Open App</Text>
      </TouchableOpacity>
    </View>
  );
};

const DesktopButtons = ({ closePrompt, link }) => (
  <View style={styles.desktopButtonsContainer}>
    <DownloadAppButtons
      containerStyle={styles.downloadAppButtons}
      onPress={closePrompt}
      link={link}
    />
  </View>
);

const logoStyle = {
  width: +Config.DESKTOP_LOGO_WIDTH / 1.8,
  height: +Config.DESKTOP_LOGO_HEIGHT / 1.8,
  lineHeight: 1.5,
};

export const { ids, styles } = StyleSheet.create({
  modal: {
    margin: 0,
  },
  bottomModal: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    marginBottom: 0,
    marginRight: 0,
    marginLeft: 0,
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundColor: colors.white,
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    zIndex: 110,
    padding: 20,
    paddingBottom: 24,
    boxShadow: '0px -4px 4px 0px #0000001A',
    '@media (min-width: 800px)': {
      top: '50%',
      left: '50%',
      bottom: 'auto',
      right: 'auto',
      transform: 'translate(-50%, -50%)',
      borderRadius: 16,
    },
  },
  image: {
    width: getScreenWidth() - 40,
    height: 110,
    marginVertical: 10,
    '@media (min-width: 800px)': {
      width: 535,
      height: 178,
    },
  },
  logoContainer: {
    height: 40,
    width: 40,
    boxShadow: '1px 2px 4px 0px #00000040',
    borderRadius: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
  logoCaption: {
    color: colors.foxyBlack,
    fontFamily: 'Roboto-Regular',
    marginLeft: 12,
    fontSize: 16,
  },
  mobileButtonsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: 12,
  },
  logoAndAppNameContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  appOpenButton: {
    backgroundColor: colors.black,
    height: 40,
    width: 140,
    color: colors.white,
    fontSize: 14,
    fontWeight: '700',
    borderRadius: 20,
    lineHeight: 40,
    textAlign: 'center',
    fontFamily: 'Roboto-Regular',
  },
  desktopButtonsContainer: {
    marginTop: 24,
    width: '70%',
  },
  downloadAppButtons: {
    justifyContent: 'space-between',
  },
});
