import React, { useEffect } from 'react';
import { PricingDetails } from './CartPricing';
import { isBlank } from '../../utils/BooleanUtility';
import { AnalyticsManager } from '../../analytics';

const CartPricingForPartialCart = ({
  navigation,
  isOfferShimmerVisible,
  remoteConfigData,
  showDeliveryFeeDiscountButton = false,
  onPressUnlockFreeDelivery = {},
  cartPricing = {},
}) => {
  if (isBlank(cartPricing)) return null;
  useEffect(
    () => {
      if (showDeliveryFeeDiscountButton) {
        AnalyticsManager.logEvent('list_item_view', {
          item_type: 'delivery_fee_discount',
          screen_name: 'cart',
          list_type: 'cart_builder',
        });
      }
    },

    [showDeliveryFeeDiscountButton], // <= means: run this effect only if any variable
  );

  return (
    <PricingDetails
      pricingData={cartPricing}
      navigation={navigation}
      isOfferShimmerVisible={isOfferShimmerVisible}
      remoteConfigData={remoteConfigData}
      showDeliveryFeeDiscountButton={showDeliveryFeeDiscountButton}
      onPressUnlockFreeDelivery={onPressUnlockFreeDelivery}
    />
  );
};

export default CartPricingForPartialCart;
