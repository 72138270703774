import React, { PureComponent } from 'react';
import { View, StyleSheet, FlatList } from 'react-native';
import Config from '../../libraries/ReactNativeConfig';
import _ from 'lodash';
import { compose } from 'recompose';
import Card from '../../lib/Card';
import { withEither } from '../../lib/Monads';
import ProductImageAndRating from './ProductImageAndRating';

import ProductDescription from './ProductDescription';
import { PRODUCT } from '../../config/LayoutConstants/ProductConfig';
import Utility from '../../utils/Utility';
import AddToCart from './AddToCart';
import ScaleAnimate from '../shared/ScaleAnimate';
import {
  AnalyticsManager,
  EventType,
  EventParameterKey,
} from '../../analytics';
import { LAYOUT } from '../../config/Constants';
import colors from '../../theme/Colors';
import ProductColorVariants from '../variants/ProductColorVariants';
import ProductOtherVariants from '../variants/ProductOtherVariants';
import ProductSizeVariants from '../variants/ProductSizeVariants';
import AnalyticsUtility from '../../analytics/AnalyticsUtility';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import { applyFlashDeal } from '../../actions/ActionTypes';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import FastImageView from '../FastImageView';
import AppConfig from '../../config/AppConfig';
import VariantGroup from './productGroups/VariantGroup';
import WithNavigation from '../../utils/WithNavigation';
import { navigateToScreen } from '../../utils/NavigationUtility';
import withProfiledNavigation from '../../utils/withProfiledNavigation';
import { getNavigationSource } from '../../utils/PerfUtility';

class LargeProductGrid extends PureComponent {
  static getComponentHeight() {
    return (2 * Utility.getScreenWidth()) / 2.15;
  }

  constructor(props) {
    super(props);

    this.debouncedNavigate = _.debounce(this.navigate, 2000, {
      leading: true,
      trailing: false,
    });
    this.debounceProductCardClick = _.debounce(
      this.fireSearchResultClickEvent,
      2000,
      {
        leading: true,
        trailing: false,
      },
    );

    this.variants_position = 'top';
    this.cta_position = 'top';
    this.variantCircleStyleCache = {};
    const {
      listData: {
        options: {
          variant_position_bottom = '0',
          cta_position_bottom = '0',
          ratings_position = 'hide',
          show_color_swatches = '0',
          hide_brand_name = '0',
        } = {},
      } = {},
    } = this.props;
    let height = styles.railCardContainer.height;
    if (hide_brand_name !== '1') {
      height += 24;
    }
    if (variant_position_bottom === '1') {
      height += 24;
    }
    if (cta_position_bottom === '1') {
      height += 44;
    }
    if (ratings_position === 'bottom') {
      height += 28;
    }
    if (show_color_swatches === '1') {
      height += 30;
    }
    this.railCardContainer = [styles.railCardContainer, { height }];
    this.navigationSource = getNavigationSource(this, this.props);
  }

  showCart = () => {
    const { toggleCartVisibility } = this.props;
    // TODO: This prevents a crash from the search results page but doesn't allow the user to navigate to the cart page
    if (toggleCartVisibility !== undefined) {
      toggleCartVisibility();
    }
  };

  navigate = () => {
    const {
      navigation,
      itemData,
      itemData: { id, slug, variants_details = {} } = {},
      toggleCartVisibility,
      applyFlashDeal,
      previousScreen,
    } = this.props;

    if (previousScreen === SCREEN_CONSTANTS.SEARCH) {
      applyFlashDeal({
        type: 'product',
        id,
      });
    }

    navigateToScreen({
      navigation,
      type: 'push',
      screen: 'Product',
      navigationSource: this.navigationSource,
      params: {
        id,
        slug,
        display: LAYOUT.SCREEN,
        itemData,
        toggleCartVisibility,
        variants_details,
        previousScreen,
      },
    });
  };

  onPress = async () => {
    const {
      onPress = () => {},
      listIndex,
      index,
      search,
      searchQuery,
      elementItemCounts,
      itemData,
      itemData: { id = '', slug = '' } = {},
      listId,
      listSlug = '',
      layout,
      previousScreen,
      listName,
      listContent,
      toggleUnavailableProductsModal,
    } = this.props;
    if (previousScreen === SCREEN_CONSTANTS.SEARCH) {
      let clickedIndex = Utility.addPreviousIndexValues(
        elementItemCounts,
        listIndex,
      );

      clickedIndex += index + 1;
      const meta = {
        [EventParameterKey.SEARCH_QUERY]: searchQuery,
        [EventParameterKey.ITEM_TYPE]: itemData.type,
        [EventParameterKey.ITEM_NAME]: itemData.name,
        [EventParameterKey.ITEM_ID]: itemData.id,
        [EventParameterKey.PRODUCT_ID]: itemData.id,
        [EventParameterKey.PRODUCT_STOCKED_STATUS]: itemData.stocked_status,
        [EventParameterKey.ITEM_POSITION]: clickedIndex || 'NAN', //TODO: this was crashing need to check
      };
      this.debounceProductCardClick(meta);
    }

    if (toggleUnavailableProductsModal) {
      await toggleUnavailableProductsModal();
    }

    AnalyticsUtility.fireItemClickEvent(
      previousScreen,
      Utility.getSkuId(itemData),
      itemData.type,
      itemData.name,
      index,
      listId,
      layout,
      listName,
      listIndex,
      '',
      !itemData.outOfStock,
      listContent,
      id,
      slug,
      listSlug,
      {},
      itemData,
    );

    this.debouncedNavigate();
    if (onPress !== undefined && onPress !== null) {
      onPress();
    }
  };

  fireSearchResultClickEvent = (meta) => {
    AnalyticsManager.logEvent(EventType.search.SEARCH_RESULT_CLICK, meta);
  };

  addToCart = () => {
    const {
      previousScreen,
      maxFreeItemsToSelect,
      showToast,
      refreshOfferStrip,
      refreshOffersDetailsPageDiscountStrip,
      listId,
      listName,
      listIndex,
      listContent,
      index,
      listData: {
        additional_data = {},
        options: { cta_position_bottom = '0' } = {},
      } = {},
      orientation,
      itemData,
      hideAddToCart,
      itemData: {
        id,
        sku_id: skuId,
        has_variants: hasVariants,
        outOfStock = false,
        priority,
        single_stocked_variant: singleStockedVariantSku,
      } = {},
    } = this.props;

    if (outOfStock === null) {
      outOfStock = true;
    }

    const style =
      cta_position_bottom === '0'
        ? styles.cartImageContainer
        : { width: '100%' };
    return (
      <View style={style}>
        <AddToCart
          skuId={skuId}
          orientation={orientation}
          toggleCartState={this.toggleCartState}
          layout={LAYOUT.LARGEGRID}
          addToCartLayout={PRODUCT.ADD_TO_CART_LAYOUT.PRODUCT_CARD}
          hideAddToCart={hideAddToCart}
          id={id}
          itemData={itemData}
          hasVariants={hasVariants}
          showCart={this.showCart}
          priority={priority}
          outOfStock={outOfStock}
          refreshOfferStrip={refreshOfferStrip}
          refreshOffersDetailsPageDiscountStrip={
            refreshOffersDetailsPageDiscountStrip
          }
          previousScreen={previousScreen}
          maxFreeItemsToSelect={maxFreeItemsToSelect}
          showToast={showToast}
          listId={listId}
          listName={listName}
          listIndex={listIndex}
          listContent={listContent}
          index={index}
          singleStockedVariantSku={singleStockedVariantSku}
          additional_data={additional_data}
          cta_position_bottom={cta_position_bottom}
        />
      </View>
    );
  };

  variantCircle = ({ item, index }) => {
    const { color = '', image = '' } = item;
    const variantCircle = this.memoizedVariantCircleStyle()(color);
    return <FastImageView style={variantCircle} source={image || item} />;
  };

  memoizedVariantCircleStyle = () => {
    return (backgroundColor = '') => {
      if (!this.variantCircleStyleCache[backgroundColor]) {
        this.variantCircleStyleCache[backgroundColor] = [
          styles.variantCircleContainer,
          { backgroundColor },
        ];
      }
      return this.variantCircleStyleCache[backgroundColor];
    };
  };

  VariantPreview = () => {
    const {
      itemData: {
        product_group: { items = [] } = {},
        variants_details: {
          variants_shade_images: variantShadeImages = [],
        } = {},
      },
    } = this.props;
    const data = Utility.isPresent(items)
      ? items.slice(0, 4)
      : variantShadeImages.slice(0, 4);
    if (Utility.isBlank(data)) return null;
    return (
      <FlatList
        data={data}
        renderItem={this.variantCircle}
        horizontal
        contentContainerStyle={styles.flatListContent}
      />
    );
  };

  productShadesCondition = ({ variantsPropertyName }) =>
    variantsPropertyName === 'Shade' || variantsPropertyName === 'Colour';

  productSizeCondition = ({ variantsPropertyName }) =>
    variantsPropertyName === 'Size';

  conditionalVariants = compose(
    withEither(this.productShadesCondition, ProductColorVariants),
    withEither(this.productSizeCondition, ProductSizeVariants),
  )(ProductOtherVariants);

  productVariants = () => {
    const {
      itemData: {
        image_url: imageUrl,
        variants_details: variantDetails = {},
        show_variant_size_count,
        product_group = {},
      },
    } = this.props;
    if (Utility.isPresent(product_group)) {
      return <VariantGroup group={product_group} layout={LAYOUT.GRID} />;
    }
    const {
      variants_count: variantsCount = 0,
      variants_shade_images: variantShadeImages = [],
      variants_property_name: variantPropertyName = '',
    } = variantDetails;

    return (
      <this.conditionalVariants
        variantImage={imageUrl}
        variantsCount={variantsCount}
        variantShadesImages={variantShadeImages}
        variantsPropertyName={variantPropertyName}
        containerStyle={styles.variantPillContainerStyle}
        showVariantSizeCount={show_variant_size_count}
      />
    );
  };

  render() {
    const {
      orientation,
      previousScreen,
      itemData,
      hideAddToCart,
      itemData: {
        offer_value,
        final_sp,
        additional_discount,
        brand_name: variantBrandName,
        images,
        webp_images,
        image_webp_url,
        rating,
        id,
        name,
        mrp,
        sp,
        discount,
        brand: { name: brandName } = {},
        sku_id: skuId,
        has_variants: hasVariants,
        outOfStock,
        priority,
        image_url: imageUrl,
        single_stocked_variant: singleStockedVariantSku,
      } = {},
      listData: {
        display = '',
        options: {
          variant_position_bottom = '0',
          cta_position_bottom = '0',
          show_color_swatches = '0',
          hide_brand_name = '0',
        } = {},
        size,
      } = {},
    } = this.props;
    const disabled =
      previousScreen === 'brandCollab' || previousScreen === 'free_items';
    const display_price = final_sp;
    return (
      <ScaleAnimate {...this.props} disabled={disabled} onPress={this.onPress}>
        <Card style={this.railCardContainer}>
          <View style={styles.railImageContainer}>
            <ProductImageAndRating
              layout={LAYOUT.RAIL}
              rating={rating}
              images={images}
              webp_images={webp_images}
              image_webp_url={image_webp_url}
              imageUrl={imageUrl}
              id={id}
              name={name}
              previousScreen={previousScreen}
              itemData={itemData}
              size={size}
              matchedProduct
            />
            {cta_position_bottom !== '1' && <this.addToCart />}
          </View>
          {variant_position_bottom === '0' && <this.productVariants />}

          <ProductDescription
            itemData={itemData}
            name={name}
            mrp={mrp}
            offer_value={offer_value}
            sp={sp}
            display_price={display_price}
            discount={discount}
            brandName={brandName}
            variantBrandName={variantBrandName}
            layout={LAYOUT.RAIL}
            id={id}
            previousScreen={previousScreen}
            numberOfLines={1}
            theme={'large'}
            size={size}
            hideBrandName={
              AppConfig.getBooleanValue(Config.HIDE_BRAND_ON_PRODUCT_CARD) ||
              hide_brand_name === '1'
            }
            leftAlignText
            showColorSwatches={show_color_swatches === '1'}
          />

          {variant_position_bottom === '1' && (
            <View style={styles.variantPreviewContainer}>
              <this.VariantPreview />
            </View>
          )}

          {cta_position_bottom === '1' && <this.addToCart />}
        </Card>
      </ScaleAnimate>
    );
  }
}

const styles = StyleSheet.create({
  railCardContainer: {
    backgroundColor: colors.white,
    borderColor: colors.borderColor,
    margin: 4,
    flexDirection: 'column',
    width: Utility.getScreenWidth() / 2.15,
    height: ((4 / 3) * Utility.getScreenWidth()) / 2.15 + 88,
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingBottom: 4,
    marginBottom: 6,
    borderRadius: Utility.isPresent(Config.LARGE_PRODUCT_CARD_CORNER)
      ? parseInt(Config.LARGE_PRODUCT_CARD_CORNER)
      : 0,
  },
  railImageContainer: {
    height: ((4 / 3) * Utility.getScreenWidth()) / 2.15,
    width: '100%',
    marginBottom: 4,
  },
  fullCardDivider: {
    height: 1,
    width: '100%',
    backgroundColor: colors.borderColor,
  },
  cartImageContainer: {
    height: 36,
    width: 36,
    marginTop: -48,
    marginRight: 12,
    alignSelf: 'flex-end',
  },
  variantContainerStyle: {
    position: 'absolute',
    left: 12,
    top: 16,
    borderWidth: 1,
    borderColor: colors.border,
    borderRadius: 15,
    maxWidth: 84,
    padding: 2,
    backgroundColor: colors.white,
  },
  variantPillContainerStyle: {
    position: 'absolute',
    left: 12,
    top: 10,
    borderWidth: 1,
    borderColor: colors.border,
    borderRadius: 15,
    maxWidth: 84,
    padding: 2,
    backgroundColor: colors.white,
  },
  flatListContent: { marginVertical: 4 },
  variantPreviewContainer: {
    height: 28,
    alignSelf: 'flex-start',
    marginHorizontal: 4,
  },
  variantCircleContainer: {
    height: 16,
    width: 16,
    borderRadius: 8,
    margin: 4,
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 1,
    borderColor: colors.background,
  },
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      applyFlashDeal,
    },
    dispatch,
  ),
});

export default withProfiledNavigation(
  connect(null, mapDispatchToProps)(LargeProductGrid),
);
