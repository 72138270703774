import React, { PureComponent } from 'react';
import { View, Text } from 'react-native';
import StyleSheet from 'react-native-media-query';
import _ from 'lodash';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import Card from '../../lib/Card';
import { withEither } from '../../lib/Monads';
import ProductImageAndRating from './ProductImageAndRating';

import ProductDescription from './ProductDescription';
import { PRODUCT } from '../../config/LayoutConstants/ProductConfig';
import withNavigation from '../../utils/WithNavigation';
import Utility, { findIngredients } from '../../utils/Utility';
import AddToCart from './AddToCart';
import ScaleAnimate from '../shared/ScaleAnimate';
import {
  AnalyticsManager,
  EventType,
  EventParameterKey,
} from '../../analytics';
import { LAYOUT } from '../../config/Constants';
import colors from '../../theme/Colors';
import ProductColorVariants from '../variants/ProductColorVariants';
import ProductOtherVariants from '../variants/ProductOtherVariants';
import ProductSizeVariants from '../variants/ProductSizeVariants';
import AnalyticsUtility from '../../analytics/AnalyticsUtility';
import FoxyMatchForProductCard from './FoxyMatchForProductCard';
import RoundedButton from '../layout/buttons/RoundedButton';
import AnimatedLikeButton from '../shared/LikeButton';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import { ProductDetailStyles } from './styles';
import ProductRatingAndMoreInfo from './ProductRatingAndMoreInfo';
import { getScreenHeight, getScreenWidth } from '../../utils/LayoutUtility';
import { navigateToScreen } from '../../utils/NavigationUtility';
import withProfiledNavigation from '../../utils/withProfiledNavigation';
import { getNavigationSource } from '../../utils/PerfUtility';

class WishlistBuilderProductCard extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      productPositiveIngredients: {},
      productNegativeIngredients: {},
    };
    this.debouncedNavigate = _.debounce(this.navigate, 1000, {
      leading: true,
      trailing: false,
    });
    const { route } = props;
    this.previousScreen = route.params?.previousScreen;
    this.debounceProductCardClick = _.debounce(
      this.fireSearchResultClickEvent,
      2000,
      {
        leading: true,
        trailing: false,
      },
    );
    this.navigationSource = getNavigationSource(this, this.props);
  }

  showCart = () => {
    const { toggleCartVisibility } = this.props;
    // TODO: This prevents a crash from the search results page but doesn't allow the user to navigate to the cart page
    if (toggleCartVisibility !== undefined) {
      toggleCartVisibility();
    }
  };

  componentDidMount() {
    this.findIngredients();
  }

  findIngredients = () => {
    const { itemData } = this.props;
    const {
      facialAnalysis: { my_attributes_values: myAttributesValues = [] } = {},
    } = this.props;
    const {
      star_ingredients: heroIngredients = [],
      foxy_match = [],
      product_category: {
        category_user_attributes: { principal = [] } = {},
      } = {},
    } = itemData;
    const positiveIngredients = {};
    const negativeIngredients = {};
    const { newGood, newBad } = findIngredients(
      heroIngredients,
      myAttributesValues,
    );

    let positiveIngredientCount = 0;
    let negativeIngredientCount = 0;

    _.forEach(newGood, (positive) => {
      if (Utility.isPresent(principal[`${positive.indication}`])) {
        positiveIngredientCount += 1;
        positiveIngredients[`${principal[`${positive.indication}`]}`] = [
          ...(Utility.isPresent(
            positiveIngredients[`${principal[`${positive.indication}`]}`],
          )
            ? positiveIngredients[`${principal[`${positive.indication}`]}`]
            : []),
          {
            name: `${positive.name}`,
            slug: `${positive.slug}`,
            id: `${positive.id}`,
          },
        ];
      }
    });

    _.forEach(newBad, (negative) => {
      if (Utility.isPresent(principal[`${negative.contraindication}`])) {
        negativeIngredientCount += 1;
        negativeIngredients[`${principal[`${negative.contraindication}`]}`] = [
          ...(Utility.isPresent(
            negativeIngredients[`${principal[`${negative.contraindication}`]}`],
          )
            ? negativeIngredients[
                `${principal[`${negative.contraindication}`]}`
              ]
            : []),
          {
            name: `${negative.name}`,
            slug: `${negative.slug}`,
            id: `${negative.id}`,
          },
        ];
      }
    });

    this.setState({
      productPositiveIngredients: positiveIngredients,
      productNegativeIngredients: negativeIngredients,
    });
  };

  navigate = () => {
    const {
      navigation,
      itemData,
      itemData: { id, slug, variants_details = {} } = {},
      toggleCartVisibility,
    } = this.props;

    navigateToScreen({
      navigation,
      type: 'push',
      screen: 'Product',
      navigationSource: this.navigationSource,
      params: {
        id,
        slug,
        display: LAYOUT.SCREEN,
        itemData,
        toggleCartVisibility,
        variants_details,
        previousScreen: this.props?.previousScreen,
      },
    });
  };

  onPress = () => {
    const {
      onPress = () => {},
      listIndex,
      index,

      searchQuery,
      elementItemCounts,
      itemData,
      listId,
      layout,
      previousScreen,
      listName,
      listContent,
    } = this.props;
    if (previousScreen === SCREEN_CONSTANTS.SEARCH) {
      let clickedIndex = Utility.addPreviousIndexValues(
        elementItemCounts,
        listIndex,
      );

      clickedIndex += index + 1;
      const meta = {
        [EventParameterKey.SEARCH_QUERY]: searchQuery,
        [EventParameterKey.ITEM_TYPE]: itemData.type,
        [EventParameterKey.ITEM_NAME]: itemData.name,
        [EventParameterKey.ITEM_ID]: itemData.id,
        [EventParameterKey.PRODUCT_ID]: itemData.id,
        [EventParameterKey.PRODUCT_STOCKED_STATUS]: itemData.stocked_status,
        [EventParameterKey.ITEM_POSITION]: clickedIndex || 'NAN', //TODO: this was crashing need to check
      };
      this.debounceProductCardClick(meta);
    }

    let id = itemData.type === 'product' ? itemData.sku_id : itemData.id;

    AnalyticsUtility.fireItemClickEvent(
      previousScreen,
      Utility.getSkuId(itemData),
      itemData.type,
      itemData.name,
      index,
      listId,
      layout,
      listName,
      listIndex,
      '',
      !itemData.outOfStock,
      listContent,
    );

    this.debouncedNavigate();
    if (onPress !== undefined && onPress !== null) {
      onPress();
    }
  };

  fireSearchResultClickEvent = (meta) => {
    AnalyticsManager.logEvent(EventType.search.SEARCH_RESULT_CLICK, meta);
  };

  addToCart = (props) => {
    const {
      skuId,
      orientation,
      layout,
      hideAddToCart,
      id,
      itemData,
      hasVariants,
      priority,
      singleStockedVariantSku,
      campaignId,
      onItemAddToCartFromCollab,
      prompts,
    } = props;
    const {
      previousScreen,
      maxFreeItemsToSelect,
      showToast,
      refreshOfferStrip,
      refreshOffersDetailsPageDiscountStrip,
      listId,
      listName,
      listIndex,
      listContent,
      index,
    } = this.props;

    let { outOfStock = false } = props;
    if (outOfStock === null) {
      outOfStock = true;
    }

    return (
      <AddToCart
        skuId={skuId}
        orientation={orientation}
        toggleCartState={this.toggleCartState}
        layout={LAYOUT.MATCHED_PRODUCT}
        addToCartLayout={PRODUCT.ADD_TO_CART_LAYOUT.PRODUCT_CARD}
        hideAddToCart={hideAddToCart}
        id={id}
        itemData={itemData}
        hasVariants={hasVariants}
        showCart={this.showCart}
        priority={priority}
        outOfStock={outOfStock}
        refreshOfferStrip={refreshOfferStrip}
        refreshOffersDetailsPageDiscountStrip={
          refreshOffersDetailsPageDiscountStrip
        }
        previousScreen={previousScreen}
        maxFreeItemsToSelect={maxFreeItemsToSelect}
        showToast={showToast}
        campaignId={campaignId}
        onItemAddToCartFromCollab={onItemAddToCartFromCollab}
        listId={listId}
        listName={listName}
        listIndex={listIndex}
        listContent={listContent}
        index={index}
        singleStockedVariantSku={singleStockedVariantSku}
        prompts={prompts}
        smallButton
        style={{ width: '45%' }}
      />
    );
  };

  productShadesCondition = ({ variantsPropertyName }) =>
    variantsPropertyName === 'Shade';

  productSizeCondition = ({ variantsPropertyName }) =>
    variantsPropertyName === 'Size';

  conditionalVariants = compose(
    withEither(this.productShadesCondition, ProductColorVariants),
    withEither(this.productSizeCondition, ProductSizeVariants),
  )(ProductOtherVariants);

  productVariants = (props) => {
    const {
      itemData: { image_url: imageUrl, variants_details: variantDetails = {} },
    } = this.props;
    const { containerStyle } = props;

    const {
      variants_count: variantsCount = 0,
      variants_shade_images: variantShadeImages = [],
      variants_property_name: variantPropertyName = '',
    } = variantDetails;

    return (
      <this.conditionalVariants
        variantImage={imageUrl}
        variantsCount={variantsCount}
        variantShadesImages={variantShadeImages}
        variantsPropertyName={variantPropertyName}
        containerStyle={containerStyle}
      />
    );
  };

  renderPills = ({ star_ingredients = [], ingredient_for_product }) => {
    if (Utility.isBlank(star_ingredients)) {
      return null;
    }

    const green_array = [];
    const attribute_array = [];
    const neutral_array = [];

    Object.entries(ingredient_for_product).map(([key, value]) => {
      if (value.myIndications.length > 0 || value.mySuper.length > 0) {
        green_array.push(key);
        if (value.myIndications.length > 0) {
          value.myIndications.map((e) => {
            if (!attribute_array.includes(value.mapping[e])) {
              attribute_array.push(value.mapping[e]);
            }
          });
        }
        if (value.mySuper.length > 0) {
          value.mySuper.map((e) => {
            if (!attribute_array.includes(value.mapping[e])) {
              attribute_array.push(value.mapping[e]);
            }
          });
        }
      } else {
        neutral_array.push(key);
      }
    });

    const newArray = [...attribute_array, ...green_array, ...neutral_array];

    return (
      <View style={styles.pillContainer}>
        {newArray.map((pill, index) => {
          if (index === 5) {
            return (
              <View
                style={[
                  styles.ingredientPill,
                  { backgroundColor: '#979BAA33' },
                ]}
              >
                <Text style={[styles.pillText, { color: '#979BAA' }]}>
                  {`+ ${newArray.length - 5} more`}
                </Text>
              </View>
            );
          }
          if (index > 5) {
            return null;
          }
          return (
            <View
              style={[
                styles.ingredientPill,
                {
                  backgroundColor:
                    pill.includes(green_array) || pill.includes(attribute_array)
                      ? colors.lightBackgroundGreen
                      : '#979BAA33',
                },
              ]}
            >
              <Text
                style={[
                  styles.pillText,
                  {
                    color:
                      pill.includes(green_array) || pill.includes(attribute_array)
                        ? '#01b460'
                        : '#979BAA',
                  },
                ]}
              >
                {pill}
              </Text>
            </View>
          );
        })}
      </View>
    );
  };

  likeButton = () => {
    const { itemData, previousScreen } = this.props;
    return (
      <AnimatedLikeButton
        ref={(ref) => (this.likeButtonRef = ref)}
        id={itemData.id}
        type={itemData.type}
        slug={itemData.slug}
        mrp={itemData.mrp}
        skuId={Utility.getSkuId(itemData)}
        layout={LAYOUT.LIST}
        content={itemData.content}
        onLike={this.onLike}
        itemName={itemData.name}
        screenName={previousScreen}
        matchedProduct
      />
    );
  };

  productHeroDescription = (props) => {
    const { heroDescription } = props;
    if (Utility.isBlank(heroDescription)) return null;

    return (
      <View style={ProductDetailStyles.heroDescriptionBoxContainer}>
        <Text
          style={ProductDetailStyles.productDescriptionHeroStyle}
          numberOfLines={2}
        >
          {heroDescription}
        </Text>
      </View>
    );
  };

  render() {
    const {
      orientation,
      previousScreen,
      itemData,
      hideAddToCart,
      itemData: {
        product_name,
        offer_value,
        final_sp,
        brand_name: variantBrandName,
        images,
        webp_images,
        image_webp_url,
        rating,
        id,
        name,
        mrp,
        sp,
        discount,
        brand: { name: brandName = '' } = {},
        sku_id: skuId,
        prompts,
        has_variants: hasVariants,
        outOfStock,
        priority,
        image_url: imageUrl,
        single_stocked_variant: singleStockedVariantSku,
        hero_description,
        product_category: {
          category_user_attributes: { principal = {} } = {},
        } = {},
        star_ingredients,
      } = {},
      navigation,
      facialAnalysis,
      facialAnalysis: { my_attributes_values = [] } = {},
    } = this.props;

    const ingredient_for_product = Utility.findIndicationsAndCounterIndications(
      star_ingredients,
      my_attributes_values,
      principal,
    );

    const { productPositiveIngredients = {}, productNegativeIngredients = {} } =
      this.state;

    const disabled =
      previousScreen === 'brandCollab' || previousScreen === 'free_items';

    const display_price = final_sp;

    return (
      <>
        <ScaleAnimate
          {...this.props}
          disabled={disabled}
          onPress={this.onPress}
        >
          <View style={styles.cardContainer} dataSet={{ media: ids.cardContainer }}>
            <View style={styles.imageContainer}>
              <ProductImageAndRating
                layout={LAYOUT.PERSONALIZED_HORIZONTAL}
                rating={rating}
                images={images}
                webp_images={webp_images}
                image_webp_url={image_webp_url}
                imageUrl={imageUrl}
                id={id}
                name={name}
                previousScreen={previousScreen}
                matchedProduct
              />
            </View>
            <View style={styles.upperContainer}>
              <ProductDescription
                itemData={itemData}
                name={Utility.isPresent(product_name) ? product_name : name}
                mrp={mrp}
                offer_value={offer_value}
                sp={sp}
                display_price={display_price}
                discount={discount}
                brandName={brandName}
                variantBrandName={variantBrandName}
                layout={LAYOUT.MATCHED_PRODUCT}
                id={id}
                previousScreen={previousScreen}
                prompts={prompts}
                matchedProduct
                numberOfLines={3}
                excludePricing
              />

              {Utility.isPresent(hero_description) && (
                <this.productHeroDescription
                  heroDescription={hero_description}
                />
              )}

              <View style={styles.rating}>
                <ProductRatingAndMoreInfo
                  rating={itemData?.rating}
                  ratingSize={14}
                  marginBetweenRatingIcon={1.5}
                  disableRatingText
                />
              </View>

              <this.renderPills
                matched_ingredients={itemData?.matched_ingredients}
                star_ingredients={itemData?.star_ingredients}
                ingredient_for_product={ingredient_for_product}
              />
              <FoxyMatchForProductCard
                itemData={itemData}
                facialAnalysis={facialAnalysis}
                navigation={navigation}
                productPositiveIngredients={productPositiveIngredients}
                productNegativeIngredients={productNegativeIngredients}
                scrollToRatings={this.scrollToRatings}
                isSelfiePresent={Utility.isPresent(imageUrl)}
                renderOnboardingForFoxyMatch={this.renderOnboardingForFoxyMatch}
                productPage={this.productPageData}
                matchedProduct
              />
            </View>
            <View style={styles.description}>
              <ProductDescription
                itemData={itemData}
                name={name}
                mrp={mrp}
                offer_value={offer_value}
                sp={sp}
                display_price={display_price}
                discount={discount}
                brandName={brandName}
                variantBrandName={variantBrandName}
                layout={LAYOUT.MATCHED_PRODUCT}
                id={id}
                previousScreen={previousScreen}
                onlyPricing
              />

              <View style={styles.bottomContainer} dataSet={{ media: ids.bottomContainer }}>
                <RoundedButton
                  buttonText={'Wishlist'}
                  buttonTextColor={'#000'}
                  buttonColor={'#fff'}
                  buttonAction={() => {
                    this.likeButtonRef.likeProduct();
                  }}
                  style={styles.buttonContainer}
                  buttonTextStyle={styles.buttonTextStyle}
                  renderProp={this.likeButton}
                  dataSet={{ media: ids.buttonContainer }}
                />

                <this.addToCart
                  skuId={skuId}
                  orientation={orientation}
                  toggleCartState={this.toggleCartState}
                  layout={LAYOUT.LIST}
                  addToCartLayout={PRODUCT.ADD_TO_CART_LAYOUT.PRODUCT_CARD}
                  hideAddToCart={hideAddToCart}
                  id={id}
                  itemData={itemData}
                  hasVariants={hasVariants}
                  showCart={this.showCart}
                  priority={priority}
                  outOfStock={outOfStock}
                  previousScreen={previousScreen}
                  singleStockedVariantSku={singleStockedVariantSku}
                  prompts={prompts}
                  smallButton
                />
              </View>
            </View>
          </View>
        </ScaleAnimate>
      </>
    );
  }
}

const { styles, ids } = StyleSheet.create({
  cardContainer: {
    backgroundColor: colors.white,
    borderColor: colors.borderColor,
    margin: 4,
    flexDirection: 'column',
    borderRadius: 12,
    width: Utility.getScreenWidth() - 24,
    height: 580 + 107 - 34,
    alignSelf: 'center',
    '@media (min-width: 768px)': {
      width: getScreenWidth() / 2.4,
      height: getScreenWidth() / 1.6,
    },
  },
  imageContainer: {
    flex: 1,
    marginBottom: 12,
  },
  fullCardDivider: {
    height: 1,
    width: '100%',
    backgroundColor: colors.borderColor,
  },
  cartImageContainer: {
    position: 'absolute',
    bottom: 78,
    right: 10,
  },
  variantContainerStyle: {
    position: 'absolute',
    left: 12,
    top: 16,
    borderWidth: 1,
    borderColor: colors.border,
    borderRadius: 15,
    maxWidth: 80,
    backgroundColor: '#ffffff',
  },
  innerContainer: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: colors.background,
  },
  container: { backgroundColor: colors.background, marginHorizontal: 12 },
  pillContainer: {
    flexDirection: 'row',
    backgroundColor: colors.white,
    // height: 31,
    marginHorizontal: 8,
    // marginBottom: 4,
    flexWrap: 'wrap',
    marginTop: 12,
    overflow: 'hidden',
  },
  ingredientPill: {
    // paddingVertical: 5,
    paddingHorizontal: 9,
    borderColor: colors.linerGradientGreenFirst,
    backgroundColor: colors.lightBackgroundGreen,
    borderWidth: 0,
    borderRadius: 12,
    marginRight: 8,
    marginBottom: 8,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  ingredientPillDisabled: {
    // paddingVertical: 5,
    paddingHorizontal: 9,
    borderColor: colors.linerGradientGreenFirst,
    backgroundColor: '#EAEBEE',
    borderWidth: 0,
    borderRadius: 12,
    marginRight: 8,
    marginBottom: 8,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  pillText: {
    fontSize: 12,
    color: '#01b460',
    fontFamily: 'Roboto-Regular',
    paddingVertical: 4,
    paddingHorizontal: 8,
  },
  pillTextDisabled: {
    fontSize: 12,
    color: '#979BAA',
    fontFamily: 'Roboto-Regular',
    paddingVertical: 4,
    paddingHorizontal: 8,
  },
  buttonTextStyle: {
    fontSize: 14,
    fontFamily: 'Roboto-Medium',
    marginLeft: 8,
  },
  buttonContainer: {
    width: '45%',
    height: 36,
    borderRadius: 20,
    borderWidth: 1,
    borderColor: '#000',
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 12,
    '@media (min-width: 768px)': {
      width: '45%',
    },
  },
  bottomContainer: {
    flexDirection: 'row',
    marginTop: 8,
    justifyContent: 'space-around',
    width: '100%',
    alignSelf: 'center',
    padding: 8,
  },
  description: { paddingBottom: 8 },
  rating: { marginLeft: 8, marginBottom: 8 },
  upperContainer: { justifyContent: 'flex-end', flexDirection: 'column' },
});
const mapStateToProps = (state) => ({
  user_engagement: state.UserAccountInfo.user_engagement,
  favourites: state.UserAccountInfo.favourites,
  facialAnalysis: state.UserAccountInfo.facialAnalysis,
  couponCodes:
    state.bag.cartPricing && state.bag.cartPricing.coupon_codes
      ? state.bag.cartPricing.coupon_codes
      : [],
  imageUrl: state.UserAccountInfo.profile.selfie_image_url,
  authToken: state.UserAccountInfo.authToken,
});

export default withProfiledNavigation(
  connect(mapStateToProps)(WishlistBuilderProductCard),
);
