import React, { memo, useState } from 'react';
import { View, StyleSheet, Platform } from 'react-native';
import WebView from 'react-native-webview';
import { camelCase } from 'lodash';
import { isBlank } from '../../../utils/BooleanUtility';
import { getWindowWidth } from '../../../utils/LayoutUtility';
import RemoteConfig from '../../../utils/RemoteConfig';
import { REMOTE_CONFIG_KEYS } from '../../../config/Constants';
import colors from '../../../theme/Colors';

const getHtml = () =>
  RemoteConfig.getValue(REMOTE_CONFIG_KEYS.webview_html_and_style);

function RichContentHTMLView({
  itemData: { display_type: display = '', contents = [] } = {},
  isExpanded = true,
  paddingRight = 0,
  initialHeight = 0,
  enableBottomPadding = false,
}) {
  if (isBlank(contents) || !isExpanded) {
    return null;
  }
  const separator = camelCase(display).includes('singleLine') ? '' : '\n';

  const htmlData = {
    html: getHtml().replace('RICH_CONTENT_HTML_DATA', contents.join(separator)),
  };

  return (
    <HtmlView
      html={htmlData}
      paddingRight={paddingRight}
      initialHeight={initialHeight}
      enableBottomPadding={enableBottomPadding}
    />
  );
}

export default memo(RichContentHTMLView);

const HtmlView = ({
  html,
  paddingRight,
  initialHeight,
  enableBottomPadding,
}) => {
  const [height, setHeight] = useState(0);

  const onMessage = (event) => {
    if (event.nativeEvent.data) {
      const htmlHeight = Number(event.nativeEvent.data);
      setHeight(htmlHeight);
    }
  };

  if (isBlank(html)) {
    return null;
  }
  let webHeight = 0;
  if (initialHeight) {
    webHeight = initialHeight;
  } else {
    webHeight = height > 0 ? height : 100;
  }

  const style = enableBottomPadding
    ? styles.container
    : styles.containerWithNoBottomPadding;
  const androidLayerType = Platform.OS === 'android' ? 'hardware' : undefined;
  return (
    <View style={style}>
      <WebView
        scrollEnabled={false}
        source={html}
        scalesPageToFit
        containerStyle={{
          height: webHeight,
          paddingRight,
        }}
        style={styles.webview}
        onMessage={onMessage}
        androidLayerType={androidLayerType}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    borderRadius: 4,
    overflow: 'hidden',
    paddingHorizontal: 0,
    width: getWindowWidth(),
    paddingBottom: 24,
  },
  containerWithNoBottomPadding: {
    borderRadius: 4,
    overflow: 'hidden',
    paddingHorizontal: 0,
    width: getWindowWidth(),
  },
  webview: { backgroundColor: colors.transparent },
});
