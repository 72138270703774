import React, { useEffect, useRef, useState } from 'react';
import { styles } from './styles';
import { View, FlatList, Animated } from 'react-native';
import { camelCase, memoize } from 'lodash';
import { useDispatch } from 'react-redux';
import { fetchList } from '../../actions/ActionTypes';
import { getTabGroupCardComponent, getTabGroupCardShimmerComponent, getTabGroupComponent } from './TabHelper';
import { isBlank, isDesktop, isPresent } from '../../utils/BooleanUtility';
import ListPlaceHolder from '../../components/shared/GridScreenPlaceHolder';
import { AnalyticsUtilty } from '../../analytics';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import { getScreenWidth } from '../../utils/LayoutUtility';
import { EMPTY_OBJECT } from '../../config/Constants';
import { filteredRequiredAttribute } from '../../utils/UriUtility';

const GetComponent = (props) => {
  const { itemData, lastLevelTab } = props;
  if (isPresent(itemData) && !lastLevelTab) {
    return <TabGroup {...props} />;
  }
  return null;
};

const RenderSubTab = (props) => {
  const {
    selectedTabData: {
      objects: { objects: subTabData = [], display = '' } = {},
      last_level_tab: lastLevelTab,
    },
    selectedIndex,
    listData = {},
    previousScreen = '',
    setSelectedTabList = () => {},
    defaultList = {},
    setListLoadingState,
    showTabsShimmer,
    requiredUserAttributes,
    tabListWidth = getScreenWidth(),
  } = props;

  const ShimmerComponent = getTabGroupCardShimmerComponent(display);

  if (showTabsShimmer && ShimmerComponent) return <ShimmerComponent showBottomText />;


  return (
    <GetComponent
      itemData={subTabData}
      lastLevelTab={lastLevelTab}
      index={selectedIndex}
      listData={listData}
      previousScreen={previousScreen}
      setSelectedTabList={setSelectedTabList}
      defaultList={defaultList}
      display={display}
      setListLoadingState={setListLoadingState}
      showTabsShimmer={showTabsShimmer}
      requiredUserAttributes={requiredUserAttributes}
      tabListWidth={tabListWidth}
    />
  );
};

const RenderTab = (props) => {
  const {
    itemData = {},
    index = 0,
    setSelectedTabList,
    setSelectedTabData,
    selectedIndex,
    setSelectedIndex,
    defaultList,
    setTabsData,
    tabsData,
    display,
    listData: {
      name: listName = '',
      display: listDisplay = '',
      content: listContent = '',
      slug: listSlug = '',
      id: listId = '',
      display_order: listIndex = '',
      background_color,
    },
    previousScreen,
    setListLoadingState,
    setShowTabsShimmer,
    requiredUserAttributes,
    tabListWidth = getScreenWidth(),
  } = props;
  const dispatch = useDispatch();
  useEffect(() => {
    updateStateFromProps();
  }, [itemData]);
  const flatListRef = useRef(null);
  const isPillsTab = display === 'pills';
  const isCircularTab = display === 'circular-rail';
  const isLinearTab = display === 'linear-tab';
  const isShortItemData = itemData.length <= 3;
  const isDuringRoutine = previousScreen === SCREEN_CONSTANTS.DURING_ROUTINE;
  // normal pills tab style
  const pillsTabStyle =
    isPillsTab && !isDuringRoutine && isShortItemData
      ? styles.centerAlignPillsTabStyle
      : styles.pillsTabContainer;
  // routine pills tab style
  const pillsTabContainerStyle = isDuringRoutine
    ? styles.routinePillsTabContainer
    : pillsTabStyle;
  const circularRailTabContainerStyle = isDuringRoutine
    ? styles.RoutineCircularRailTabContainer
    : styles.circularRailTabContainer;
  const circularTabStyle =
    isCircularTab && isShortItemData
      ? styles.centerAlignCircularTabStyle
      : circularRailTabContainerStyle;
  const tabStyle = {
    linearTab:
      isLinearTab && isShortItemData
        ? styles.centerAlignLinearTabStyle
        : styles.linearTabContainer,
    pills: [pillsTabContainerStyle, { backgroundColor: background_color }],
    circularRail: [circularTabStyle, { backgroundColor: background_color }, isDesktop() && { height: 170 }],
  };
  const flatListContainerStyle = isLinearTab
    ? [styles.container]
    : [
      styles.container,
      { backgroundColor: background_color, width: tabListWidth },
    ];
  const flatListStyle = tabStyle[camelCase(display)];
  const updateStateFromProps = () => {
    const { last_level_tab: lastLevelTab } = itemData[0];
    setShowTabsShimmer(true);
    setListLoadingState(true);
    setSelectedIndex(0);
    setSelectedTabList({});
    setSelectedTabData(itemData[0] || {});
    setShowTabsShimmer(false);
    setTabsData(itemData);
    if (lastLevelTab) {
      if (isPresent(itemData[0]?.listData)) {
        setSelectedTabList(itemData[0]?.listData);
        setListLoadingState(false);
        return;
      }
      if (isPresent(itemData[0]?.list_slug)) {
        hitApiForList(itemData[0]?.list_slug, 0);
      }
    }
  };
  const hitApiForList = (slug = '', index) => {
    if (isBlank(slug)) return;
    let url = slug;
    const isPersonalizedUrl =
      url.includes('personalized') && isBlank(requiredUserAttributes);
    if (isPresent(requiredUserAttributes) || isPersonalizedUrl) {
      url = filteredRequiredAttribute(
        url,
        requiredUserAttributes,
        isPersonalizedUrl,
      );
    }
    setListLoadingState(true);
    dispatch(
      fetchList({ slug: url }, 0, (success, data) => {
        if (!success || isBlank(data) || isBlank(data.objects)) {
          return;
        }
        itemData[index].listData = data;
        setSelectedTabList(data);
        setListLoadingState(false);
      }),
    );
  };

  const fireEvent = (item, index) => {
    const {
      id = '',
      name = '',
      display: itemDisplay = '',
      list_slug: slug = '',
    } = item;
    AnalyticsUtilty.fireItemClickEvent(
      previousScreen,
      id,
      itemDisplay,
      name,
      index,
      listId,
      listDisplay,
      listName,
      listIndex,
      '',
      '',
      listContent,
      '',
      slug,
      listSlug,
    );
  };

  const onTabPress = (item = {}, index = 0) => {
    const { last_level_tab: lastLevelTab = false, list_slug: listSlug = '' } =
      item;
    if (index === selectedIndex) return;
    setShowTabsShimmer(true);
    setListLoadingState(true);
    setSelectedIndex(index);
    setSelectedTabList({});
    fireEvent(item, index);
    setTimeout(() => {
      setSelectedTabData(tabsData[index]);
      setShowTabsShimmer(false);
      if (lastLevelTab) {
        if (isPresent(itemData[index]?.listData)) {
          setSelectedTabList(itemData[index]?.listData);
          setListLoadingState(false);
          return;
        }
        hitApiForList(listSlug, index);
      }
    }, 0);
    scrollToStart(index);
  };

  const scrollToStart = (index) => {
    if (display === 'linear-tab') {
      flatListRef.current.scrollToIndex({ animated: true, index });
    } else if (index >= 2 && display !== 'linear-tab') {
      const newIndex = index - 2; // Calculate the new index by shifting left
      flatListRef.current.scrollToIndex({ animated: true, index: newIndex });
    }
  };

  const keyExtractor = (item, index) => `${index}_${item.id}tab`;
  const renderItem = ({ item, index }) => {
    const TabCardComponent = getTabGroupCardComponent(item.display);
    if (TabCardComponent === undefined) return null;
    return (
      <TabCardComponent
        selectedIndex={selectedIndex}
        index={index}
        item={item}
        onTabPress={onTabPress}
        listBackgroundColor={background_color}
      />
    );
  };
  return (
    <View style={flatListContainerStyle}>
      {previousScreen === SCREEN_CONSTANTS.MORE_PAGE && isPillsTab && (
        <View style={styles.greyLine} />
      )}
      <FlatList
        horizontal
        ref={flatListRef}
        data={itemData}
        keyExtractor={keyExtractor}
        renderItem={renderItem}
        style={flatListStyle}
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={styles.contentContainerStyle}
      />
    </View>
  );
};
const TabGroup = (props) => {
  const {
    itemData = {},
    index = 0,
    linearTab,
    listData = {},
    previousScreen = '',
    setSelectedTabList = () => {},
    defaultList = {},
    display = '',
    setListLoadingState,
    animatedStyle,
    showTabsShimmer: defaultShimmer = false,
    parentListContent = '',
    listIndex = '',
    parentListDisplay = '',
    requiredUserAttributes = [],
    tabListWidth,
  } = props;
  const [selectedTabData, setSelectedTabData] = useState(EMPTY_OBJECT);
  const [tabsData, setTabsData] = useState(itemData);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [showTabsShimmer, setShowTabsShimmer] = useState(defaultShimmer);
  const subTabContainerStyle =
    parentListContent === 'mixed' &&
    listIndex >= 1 &&
    parentListDisplay === 'vertical'
      ? {}
      : animatedStyle;
  return (
    <>
      <RenderTab
        itemData={itemData}
        index={index}
        linearTab={linearTab}
        selectedTabData={selectedTabData}
        setSelectedTabData={setSelectedTabData}
        selectedIndex={selectedIndex}
        setSelectedIndex={setSelectedIndex}
        setSelectedTabList={setSelectedTabList}
        defaultList={defaultList}
        tabsData={tabsData}
        setTabsData={setTabsData}
        display={display}
        listData={listData}
        previousScreen={previousScreen}
        setListLoadingState={setListLoadingState}
        setShowTabsShimmer={setShowTabsShimmer}
        requiredUserAttributes={requiredUserAttributes}
        tabListWidth={tabListWidth}
      />
      <Animated.View style={subTabContainerStyle}>
        <RenderSubTab
          selectedTabData={selectedTabData}
          setSelectedTabData={setSelectedTabData}
          selectedIndex={selectedIndex}
          setSelectedIndex={setSelectedIndex}
          listData={listData}
          previousScreen={previousScreen}
          setSelectedTabList={setSelectedTabList}
          defaultList={defaultList}
          setListLoadingState={setListLoadingState}
          showTabsShimmer={showTabsShimmer}
          requiredUserAttributes={requiredUserAttributes}
          tabListWidth={tabListWidth}
        />
      </Animated.View>
    </>
  );
};

export default TabGroup;
