import React, { useCallback } from 'react';
import { TouchableOpacity } from 'react-native';
import { debounce } from 'lodash';
import { useSelector } from 'react-redux';

const EdgeDebouncedTouchableOpacity = (props) => {
  const {
    children,
    disabled = false,
    previousScreen,
    navigation,
    onPress,
  } = props;

  const membershipCohort = useSelector(
    (state) => state.todayDeals.membership_cohort,
  );

  const handleOnPress = () => {
    if (membershipCohort !== 'member') {
      navigation.push('LoyaltyPlans', { previousScreen });
      return;
    }
    onPress();
  };

  const debouncedOnPress = useCallback(
    debounce(handleOnPress, 150, { leading: true, trailing: false }),
    [handleOnPress],
  );

  return (
    <TouchableOpacity {...props} onPress={debouncedOnPress} disabled={disabled}>
      {children}
    </TouchableOpacity>
  );
};

export default EdgeDebouncedTouchableOpacity;
