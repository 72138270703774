import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  FlatList,
} from 'react-native';
import Config from '../../../libraries/ReactNativeConfig';
import React, { useState } from 'react';
import { DefaultSizeOfferPromptFancyRail } from '../../layout/offerPrompt';
import colors from '../../../theme/Colors';
import inheritStyles from '../../orders/ReturnOrCancel/WalletStyles';
import { isBlank } from '../../../utils/BooleanUtility';
import { mapKeys, snakeCase } from 'lodash';
import { useNavigation } from '@react-navigation/native';

const keyExtractor = (item, index) => `${index}`;

export default function VoucherActionCard(props) {
  const navigation = useNavigation();
  const {
    title = '',
    subtitle = '',
    itemData: { refundCoupons = [] } = {},
    disableVoucherOnPress,
  } = props;

  if (isBlank(refundCoupons)) return null;

  const renderItem = ({ item, index }) => {
    const formattedItem = mapKeys(item, (value, key) => snakeCase(key));
    const { disabled = false } = item;
    return (
      <View pointerEvents={disableVoucherOnPress ? 'none' : 'auto'}>
        <DefaultSizeOfferPromptFancyRail
          item={formattedItem}
          isDisabled={disabled}
          index={index}
          showSubHeading
          inheritStyles={inheritStyles}
          onPress={item?.onPress}
        />
      </View>
    );
  };

  const navigateToRewards = () => {
    navigation.navigate(Config.REWARDS_PAGE_ROUTE);
  };

  return (
    <View style={styles.voucherContainer}>
      <Text style={styles.voucherHeading}>{title}</Text>
      <Text style={styles.voucherSubheading}>{subtitle}</Text>

      <FlatList
        data={refundCoupons}
        renderItem={renderItem}
        keyExtractor={keyExtractor}
      />

      <TouchableOpacity onPress={navigateToRewards}>
        <Text style={styles.myRewardsText}>Go to My Rewards ></Text>
      </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  voucherContainer: {
    backgroundColor: colors.white,
    marginVertical: 12,
    paddingTop: 24,
    paddingBottom: 12,
    paddingHorizontal: 12,
  },
  voucherHeading: {
    fontSize: 16,
    fontFamily: 'Roboto-Bold',
    color: colors.black,
  },
  voucherSubheading: {
    fontSize: 14,
    marginVertical: 4,
    marginBottom: 12,
    fontFamily: 'Roboto-Regular',
    color: colors.subtitle,
  },
  myRewardsText: {
    fontSize: 12,
    marginTop: 24,
    color: colors.foxyBlue,
    fontFamily: 'Roboto-Medium',
    textAlign: 'right',
    alignSelf: 'flex-end',
  },
});
