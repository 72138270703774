import React, { PureComponent } from 'react';
import {
  View,
  Text,
  FlatList,
  StyleSheet,
  LayoutAnimation,
  Platform,
  UIManager,
  TouchableOpacity,
  Image,
} from 'react-native';
import Config from '../../../libraries/ReactNativeConfig';
import { ScrollView } from 'react-native-gesture-handler';
import RadioButton from 'react-native-radio-button';
import _, { findIndex } from 'lodash';
import FastImageView from '../../FastImageView';
import images from '../../../theme/Images';
import colors from '../../../theme/Colors';
import Utility from '../../../utils/Utility';
import ChipsButton from '../../shared/ChipsButton';
import FilterAnalyticsEvents from '../FiltersAnalyticsEvents';
import {
  AnalyticsManager,
  EventParameterKey,
  EventType,
} from '../../../analytics';
import { isPresent } from '../../../utils/BooleanUtility';

export default class ContentQuickFilters extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      options: this.props.options,
      expanded: false,
    };
    if (Platform.OS === 'android') {
      UIManager.setLayoutAnimationEnabledExperimental(true);
    }
    this.accordionRef = React.createRef();
  }

  componentDidMount = () => {
    const { options } = this.state;
    const { selectedFilters = [], applyResetFunction } = this.props;
    const isPresentSortByCriteria =
      this.isPresentSortByCriteria(selectedFilters);

    const updatedOptions = this.getUpdatedOptions(
      options,
      selectedFilters,
      isPresentSortByCriteria,
    );

    this.setState({
      options: updatedOptions,
    });

    applyResetFunction(this.onItemReset);
    this.setSelectedRecommendedFilter();
  };

  componentDidUpdate(prevProps) {
    const { selectedRecommendedFilters = [] } = this.props;
    const { selectedRecommendedFilters: prevSelectedRecommendedFilters } =
      prevProps;
    if (
      JSON.stringify(selectedRecommendedFilters) !==
      JSON.stringify(prevSelectedRecommendedFilters)
    ) {
      this.setSelectedRecommendedFilter();
    }
  }

  setSelectedRecommendedFilter = () => {
    const { options } = this.state;
    const { selectedRecommendedFilters = [], appliedFilters } = this.props;
    const updatedOptions = options.map((element) => {
      const isAppliedFilter = isPresent(
        appliedFilters.filter((filter) => element.value == filter.value),
      );
      const isRecommendedFilter = isPresent(
        selectedRecommendedFilters.filter(
          (filter) => element.value == filter.value,
        ),
      );
      const isSelected = isAppliedFilter || isRecommendedFilter;
      return {
        ...element,
        selected: isSelected,
      };
    });

    this.setState({
      options: updatedOptions,
    });
  };

  getUpdatedOptions = (options, selectedFilters, isPresentSortByCriteria) => {
    return options.map((element) => {
      const isSelected =
        Utility.isPresent(
          selectedFilters.filter((filter) => element.value == filter.value),
        ) ||
        (element.default_selected && !isPresentSortByCriteria);

      return {
        ...element,
        selected: isSelected,
      };
    });
  };

  isPresentSortByCriteria = (filtersData = []) => {
    const findIndexOfSortBy = findIndex(
      filtersData,
      (element) => element?.criteria_name === 'sort_by',
    );
    return findIndexOfSortBy !== -1;
  };

  toggleExpand = (index) => {
    const { expanded } = this.state;
    const {
      onFilterSelect,
      listId,
      listName,
      criteria_name,
      entityName,
      screenName,
      headingText = '',
    } = this.props;
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
    this.setState({ expanded: !expanded });
    AnalyticsManager.logEvent(EventType.discoveryEvents.FILTER_ACTION, {
      [EventParameterKey.ITEM_TYPE]: expanded ? 'expanded' : 'collapsed',
      [EventParameterKey.LIST_ID]: listId,
      [EventParameterKey.LIST_NAME]: listName,
      [EventParameterKey.FILTER_OPTION]: headingText,
      [EventParameterKey.ENTITY_NAME]: entityName,
      [EventParameterKey.SCREEN_NAME]: screenName,
      [EventParameterKey.FILTER_POSITION]: index,
    });
  };

  pillsAccordion = ({ options, headingText, index, allowMultiSelection }) => {
    const { expanded } = this.state;
    const isExpanded = index === 0 || index === 1 ? expanded : !expanded;
    return (
      <View>
        <TouchableOpacity
          ref={this.accordionRef}
          onPress={_.memoize(
            () => this.toggleExpand(index),
            () => [index],
          )}
          style={style.topView}
        >
          <View>
            <Text style={style.titleStyle}>{headingText}</Text>
            <View style={style.textUnderLine} />
          </View>
          <View style={style.arrowContainer}>
            <Image
              source={!isExpanded ? images.chevronUp : images.chevronDown}
              style={style.arrowStyle}
            />
          </View>
        </TouchableOpacity>
        <View />
        {!isExpanded && (
          <View style={style.chipsStyle}>
            {options.map((item, indexKey) => (
              <View style={style.chipsButtonViewContainer}>
                <ChipsButton
                  chipName={item.name}
                  colorCode={item.colour_code}
                  isSelected={item.selected}
                  image={item.image}
                  onChipClick={_.memoize(
                    () =>
                      this.onQuickFilterTap(
                        item,
                        indexKey,
                        allowMultiSelection,
                      ),
                    () => [item.value, indexKey],
                  )}
                  unselectedChipBackgroundColor={
                    Config.FILTER_MODAL_UNSELECTED_CHIP_BACKGROUND_COLOR
                  }
                />
              </View>
            ))}
          </View>
        )}
      </View>
    );
  };

  onItemReset = () => {
    const { options } = this.state;
    const isPresentSortByCriteria = this.isPresentSortByCriteria(options);
    this.setState({
      options: options.map((element) => ({
        ...element,
        selected:
          (element.default_selected && isPresentSortByCriteria) || false,
      })),
    });
  };

  onQuickFilterTap = (item, index = -1, allowMultiSelection) => {
    const { options } = this.state;
    const {
      onFilterSelect,
      listId,
      listName,
      criteria_name,
      entityName,
      screenName,
      headingText = '',
      listSlug = '',
    } = this.props;
    const { selected } = item; // returns previous state
    const nextItemSelectedState = !selected;
    const listObject = { id: listId, name: listName, slug: listSlug };

    // Logging events based on selection state
    if (nextItemSelectedState) {
      FilterAnalyticsEvents.logFilterValueSelectEvent(
        item,
        headingText,
        index,
        listObject,
        entityName,
        screenName,
      );
    } else {
      FilterAnalyticsEvents.logFilterValueUnselectEvent(
        item,
        headingText,
        index,
        listObject,
        entityName,
        screenName,
      );
    }

    const updatedOptions = options.map((element) => {
      if (allowMultiSelection) {
        // Normal toggle behavior for multi-selection or other criteria
        return element.name === item.name
          ? { ...element, selected: !element.selected }
          : { ...element };
      } else {
        // Single selection under 'sort_by' criteria
        return { ...element, selected: element.name === item.name };
      }
    });

    // Setting the updated options in state or calling onFilterSelect
    this.setState({ options: updatedOptions });
    onFilterSelect(item);
  };

  render() {
    const { options } = this.state;
    const {
      headingText,
      index,
      hideBrandFilter = false,
      allowMultiSelection = true,
      selectedFilters,
    } = this.props;
    if (headingText === 'Brands' && hideBrandFilter) return null;
    return (
      <View style={style.container}>
        <this.pillsAccordion
          options={options}
          headingText={headingText}
          index={index}
          allowMultiSelection={allowMultiSelection}
        />
      </View>
    );
  }
}

const style = StyleSheet.create({
  rowContainer: {
    flexDirection: 'row',
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: 18,
    paddingBottom: 18,
    justifyContent: 'space-between',
  },

  containerStyle: {
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 5,
    overflow: 'hidden',
    paddingLeft: 10,
  },

  container: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 12,
  },
  titleStyle: {
    fontFamily: 'Roboto-Medium',
    fontSize: 16,
    color: colors.foxyBlack,
  },
  chipsStyle: {
    flexDirection: 'row',
    overflow: 'hidden',
    flexWrap: 'wrap',
    paddingTop: 12,
  },
  topView: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  arrowStyle: {
    height: 16,
    width: 16,
    tintColor: colors.foxyBlack,
    alignSelf: 'center',
  },
  arrowContainer: {
    height: 24,
    width: 24,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: colors.borderColor,
    borderRadius: 20,
    justifyContent: 'center',
    alignContent: 'center',
  },
  textUnderLine: {
    flex: 1,
    height: 1,
    backgroundColor: colors.foxyBlack,
  },
  chipsButtonViewContainer: {
    paddingTop: 4,
    paddingBottom: 4,
  },
});
