import React, { PureComponent } from 'react';
import { View, StyleSheet, FlatList } from 'react-native';
import Utility from '../../utils/Utility';
import FastImageView from '../FastImageView';
import WithNavigation from '../../utils/WithNavigation';
import Carousel from 'react-native-looped-carousel';
import colors from '../../theme/Colors';
import { getMinifiedImage } from '../../utils/ImageUtility';

const styles = StyleSheet.create({
  containerStyle: {
    height: 1 * Utility.getScreenWidth(),
    width: 1 * Utility.getScreenWidth(),
    resizeMode: 'cover',
  },
  zeroHeight: { height: 0 },
  bulletStyle: {
    width: 8,
    height: 8,
    borderRadius: 4,
    marginLeft: 2,
    marginRight: 2,
    borderWidth: 0,
    backgroundColor: '#DFE0E5',
    marginBottom: -8,
  },
  selectedBulletStyle: {
    width: 8,
    height: 8,
    borderRadius: 4,
    marginLeft: 2,
    marginRight: 2,
    borderWidth: 0,
    backgroundColor: colors.subtitle,
    marginBottom: -8,
  },
  imageStyle: {
    height: 1 * Utility.getScreenWidth(),
    width: 1 * Utility.getScreenWidth(),
    resizeMode: 'cover',
  },
});

class ImageCarousel extends PureComponent {
  getImage = (item) => {
    const newUrl = getMinifiedImage(
      item,
      styles.imageStyle.width,
      styles.imageStyle.height,
    );
    return (
      <View>
        <FastImageView
          resizeMode={'contain'}
          style={styles.imageStyle}
          source={newUrl}
        />
      </View>
    );
  };

  render() {
    const { itemData: { contents = [] } = {}, isExpanded = true } = this.props;
    if (Utility.isBlank(contents)) return null;
    const style = isExpanded ? styles.containerStyle : styles.zeroHeight;
    return (
      <View style={style}>
        <Carousel
          horizontal
          bullets={contents.length > 1 && isExpanded}
          style={style}
          currentPage={0}
          autoplay
          isLooped
          delay={4000}
          bulletStyle={styles.bulletStyle}
          chosenBulletStyle={styles.selectedBulletStyle}
        >
          {contents.map(this.getImage)}
        </Carousel>
      </View>
    );
  }
}

export default WithNavigation(ImageCarousel);
