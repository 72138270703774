/* eslint-disable no-param-reassign */
import { LOGOUT_USER } from '../actions/ActionTypes';
import { ROUTINE_OPENED, SAVE_CHECKLIST, LIVESTREAM_WATCHED_STATUS, SAVE_SCHEDULED_NOTIFICATION_IDS } from '../actions/RoutineActions';
import initialState from './initialState';

export default function routines(state = initialState.routines, action) {
  switch (action.type) {
    case ROUTINE_OPENED:
      // eslint-disable-next-line no-case-declarations
      const tViewedRoutines = state.viewedRoutines;
      if (!tViewedRoutines.includes(action.id)) {
        tViewedRoutines.push(action.id);
        state.viewedRoutines = tViewedRoutines;
      }
      return {
        ...state,
      };
    case SAVE_CHECKLIST:
      state.checkList[action.id] = action.list;
      return {
        ...state,
      };
    case LOGOUT_USER:
      return {
        ...state,
        viewedRoutines: [],
        checkList: {},
        liveStreams: [],
        scheduledNotificationIds: [],
      };
    case LIVESTREAM_WATCHED_STATUS:
      // eslint-disable-next-line no-case-declarations
      const { status = {} } = action;
      return {
        ...state,
        liveStreams: [...state.liveStreams, status],
      };
    case SAVE_SCHEDULED_NOTIFICATION_IDS:
      const { scheduledNotificationIds = [] } = state;
      const newScheduledNotificationIds = [
        ...scheduledNotificationIds,
        ...action.scheduledNotificationIds,
      ];
      return {
        ...state,
        scheduledNotificationIds: Array.from(
          new Set(newScheduledNotificationIds),
        ),
      };

    default:
      return state;
  }
}
