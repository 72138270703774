import React, { PureComponent } from 'react';
import {
  View,
  Text,
  Image,
  TouchableWithoutFeedback,
} from 'react-native';
import StyleSheet from 'react-native-media-query';
import Config from '../../../libraries/ReactNativeConfig';
import colors from '../../../theme/Colors';
import size from '../../../theme/Fonts';
import images from '../../../theme/Images';
import Utility from '../../../utils/Utility';
import { isDesktop, isPresent } from '../../../utils/BooleanUtility';

export default class EmptyState extends PureComponent {
  render() {
    const {
      onPress,
      title = '',
      subtitle = '',
      buttonText = '',
      image = images.emptyFilterResults,
      showCta = true,
      height,
    } = this.props;

    const viewHeight = height || (isDesktop() ? Utility.screenHeight / 2 : Utility.getScreenHeight() / 2);
    return (
      <View>
        <View style={[styles.container, { height: viewHeight }]}>
          <Image
            source={image}
            style={styles.emptyStateViewImage}
            resizeMode={'contain'}
            dataSet={{ media: ids.emptyStateViewImage }}
          />
          <Text style={styles.title} dataSet={{ media: ids.title }}>{title}</Text>
          {isPresent(subtitle) && <Text style={styles.subtitle} dataSet={{ media: ids.subtitle }}>{subtitle}</Text>}

          {showCta && (
            <TouchableWithoutFeedback onPress={onPress}>
              <View style={styles.buttonContainer} dataSet={{ media: ids.buttonContainer }}>
                <Text style={styles.buttonText}>{buttonText}</Text>
              </View>
            </TouchableWithoutFeedback>
          )}
        </View>
      </View>
    );
  }
}

const { ids, styles } = StyleSheet.create({
  container: {
    height: Utility.getScreenHeight() / 2.6,
    backgroundColor: colors.background,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    alignContent: 'center',
  },
  emptyStateImage: {
    height: 95,
    width: 100,
    resizeMode: 'contain',
  },
  emptyStateViewImage: {
    width: 240,
    height: 240,
    resizeMode: 'contain',
    '@media (min-width: 768px)': {
      width: 320,
      height: 320,
    },
  },
  title: {
    marginTop: 15,
    fontFamily: 'Roboto-Medium',
    fontSize: size.h3,
    color: colors.foxyBlack,
    '@media (min-width: 768px)': {
      marginTop: 0,
      fontSize: size.h2,
    },
  },
  subtitle: {
    fontFamily: 'Roboto-Regular',
    fontSize: size.h4,
    color: colors.subtitle,
    marginTop: 8,
    '@media (min-width: 768px)': {
      fontSize: size.h3,
    },
  },
  buttonContainer: {
    // width: 120,
    marginTop: 30,
    borderRadius: 16,
    backgroundColor: Utility.isPresent(Config.THEME_COLOR)
      ? Config.THEME_COLOR
      : colors.black,
    paddingHorizontal: 12,
    paddingVertical: 8,
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    '@media (min-width: 768px)': {
      paddingHorizontal: 16,
    },
  },
  buttonText: {
    fontFamily: 'Roboto-Medium',
    fontSize: size.h3,
    color: colors.white,
  },
});
