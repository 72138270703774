import React, { PureComponent } from 'react';
import {
  Image,
  View,
  Text,
  TouchableWithoutFeedback,
  FlatList,
  ScrollView,
  BackHandler,
  Platform,
  Animated,
  TouchableOpacity,
  RefreshControl,
  NativeModules,
  Linking,
} from 'react-native';
import Config from '../../libraries/ReactNativeConfig';
import Keychain from '../../libraries/ReactNativeKeychain';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { MoEReactInbox, ReactMoE } from '../../libraries/ReactMoe';
import Toast from 'react-native-easy-toast';
import _ from 'lodash';
import Share from '../../libraries/ReactNativeShare';
import ShimmerPlaceHolder from '../../libraries/ReactNativeShimmerPlaceholder';
import VersionNumber from '../../libraries/ReactNativeVersionNumber';
import { ReactNavigationPerformanceView } from '@shopify/react-native-performance-navigation';
import FoxyAlert from '../../components/camera/shared/FoxyAlert';
import styles from './styles';

import StaticNavigationHeader from '../../components/shared/StaticNavigationHeader';

import images from '../../theme/Images';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import { ProfilePic } from '../myProfile';
import colors from '../../theme/Colors';
import EmptyStateCard from './Common/EmptyStateCard';
import {
  saveGuestAuthToken,
  registerGuestUser,
} from './../../actions/LoginActions';

import {
  getOrders,
  updateAddress,
  getVideosInReview,
  getLatestMedia,
  onGoingShipments,
  getCardDetails,
  saveRefferalCode,
  removeLastPaymentMode,
  removeAddress,
  getUsersAllAddress,
  deleteCard,
  getMyProfileDetails,
} from '../../actions/ActionTypes';

import {
  toggleInterest,
  postSelectedInterest,
  getUserFacialAttributes,
  logoutUser,
  imageUploadPercentage,
  selfieAnalysisState,
  showImageProgressBar,
  saveUserState,
  showPopover,
  smsRetrieverStatus,
  changeLoginModalState,
  newUser,
  startFacialAnalysis,
  addProfilePic,
  setPhoneNumberModalInfo,
  isRequestedFromHintPrompt,
  showProfileShimmer,
  isUserLoggedOutOnce,
  unlinkDevice,
} from '../../actions/LoginActions';

import {
  getMasterAttributesList,
  refreshMyAttributes,
} from '../../actions/FacialAnalysisActions';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';

import {
  SCREEN,
  CURRENT_GUEST_STATE,
  PERMISSION_STORE,
  SELFIE_STATE,
  stepIndicatorConfig,
  PERMISSIONS,
  AnalyticsScreenNames,
  WHATS_APP_NUMBER,
  REMOTE_CONFIG_KEYS,
  LOGIN_MODAL_MESSAGES,
  TRACKING_PARAMS,
  PLAY_STORE_LINK,
  APP_STORE_LINK,
  TABBAR_CONSTANT,
  EnableMandatoryLogin,
} from '../../config/Constants';
import { UserPostCard } from '../../components/profile';

import SavedPaymentCard from './Common/SavedCard';
import AddressCard from './Common/AddressCard';
import DynamicLinkManager from '../../utils/DynamicLinkManager';
import ImageTagsProcessingUtil from '../../utils/ImageTagsProcessingUtil';
import size from '../../theme/Fonts';
import AnalyticsManager from '../../analytics/AnalyticsManager';
import EventParameterKey from '../../analytics/EventParameterKey';
import EventParameterValue from '../../analytics/EventParameterValue';
import EventType from '../../analytics/AnalyticsEventType';

import SelfieReviewModalWrapper from '../../utils/SelfieReviewModalWrapper';
import RemoteConfig from '../../utils/RemoteConfig';
import LoginCard from '../../components/shared/LoginCard';
import FacialAnalysisCard from '../../components/shared/FacialAnalysisCard';
import ConsumerProfileInfoSection from './Common/ConsumerProfileInfoSection';
import ConsumerProfileSelfieMarker from '../../components/onboarding/ImageMarkers/ConsumerProfileSelfieMarker';

import DockedHeader from '../../utils/DockedHeader';
import ProfileCustomNavigationHeader from '../../components/shared/ProfileCustomNavigationHeader';

import BottomModal from '../../components/profile/BottomModal';
import ConsumerProfilePlaceholderShimmer from '../../components/shared/shimmers/ConsumerProfilePlaceholderShimmer';
import NavigationService from '../../navigator/NavigationService';
import NotificationCount from '../../components/shared/NotificationCount';
import { exitStoreMode } from '../../actions/RetailStoreActions';
import AppConfig from '../../config/AppConfig';
import { getLoyaltyPlanPromptDetails } from '../../actions/FoxyEdgeAction';
import { List } from '../List';
import {
  TabNavigationLeftHeader,
  TabNavigationRightHeader,
} from '../../components/header';
import ContactsUsUtility from '../../utils/ContactUsUtility';
import { v1 as uuidv1 } from 'uuid';
import BGNetworkTaskManager from '../../utils/BGNetworkTaskManager';
import AnalyticsUtility from '../../analytics/AnalyticsUtility';
import FastImageView from '../../components/FastImageView';
import NotificationManager from '../../utils/NotificationsManager';
import { isNative, isWeb } from '../../utils/BooleanUtility';
import { navigateToScreen } from '../../utils/NavigationUtility';
import { getFirebasePerformanceTrace } from '../../utils/PerfUtility';
import { getMinifiedImage } from '../../utils/ImageUtility';
import withProfiledNavigation from '../../utils/withProfiledNavigation';
import { getNavigationSource } from '../../utils/PerfUtility';
import { isIOS, isPresent } from '../../utils/BooleanUtility';
import { isAndroid } from '../../utils/BooleanUtility';
import { jsonParser } from '../../utils/JsonUtility';
import EkstoreCreditFooter from '../banner/EkstoreCreditFooter';

const isInternationalChoiceEnabled = AppConfig.getBooleanValue(
  Config.ENABLE_INTERNATIONAL_CHOICE,
);

class ConsumerProfile extends PureComponent {
  static navigationOptions = ({ navigation }) => {
    if (AppConfig.getBooleanValue(Config.DISABLE_PROFILE_STATIC_HEADER)) {
      return null;
    }
    let { params = {} } = navigation.state;
    let titleStyle = Utility.isAndroid()
      ? {
          textAlign: 'center',
          alignSelf: 'center',
          flex: 1, // must to centrally align title on android, react navigation bug
        }
      : {};
    params = {
      title: 'Profile',
      headerTitleStyle: titleStyle,
    };

    return params;
  };

  constructor(props) {
    super(props);
    const { authToken } = this.props;
    this.trace = getFirebasePerformanceTrace(SCREEN_CONSTANTS.CONSUMER_PROFILE);
    this.trace.start();
    this.didFocusListener = null;
    this.willBlurListener = null;
    // const { interestList} = this.props
    this.logoutObjectRowObject = {
      title: 'Logout',
      haveBorder: false,
      navigateTo: 'logout',
    };
    const showInviteActions = Utility.jsonParser(
      RemoteConfig.getValue(REMOTE_CONFIG_KEYS.hide_actions),
    );
    const hideRoutine = RemoteConfig.getValue(REMOTE_CONFIG_KEYS.hide_routine);
    this.showPowerbyEkstore = jsonParser(
      RemoteConfig.getValue(REMOTE_CONFIG_KEYS.ekstore_credit_footer),
    );
    this.state = {
      scrollY: new Animated.Value(0),
      isScreenActive: false,
      addresses: '',
      visible: false,
      showProfilePicLoader: false,

      settingOptions: [
        {
          title: 'My Addresses',
          showAdditionalList: true,
          type: 'edit_address',
          haveBorder: false,
          horizontalList: false,
          displayOrder: 2,
          subText: '',
          rightSideOptionName: 'View All',
          icon: images.my_address,
        },
        {
          title: 'Other Actions',
          showAdditionalList: true,
          type: 'profile_options',
          haveBorder: false,
          subText: '',
          displayOrder: 4,
          icon: images.payment_methods,
        },
      ],
      showAlert: false,
      addressToDelete: {},
      myOrders: [],
      facial_features: [],
      savedCards: [],
      existingSelfieUrl: '',
      facialPoints: {},
      myAddress: [],
      progressBarInSelfieCard: false,
      refreshing: false,
      showSnackBar: false,
      payuParams: {},
      selfieCardTitle: 'Upload Your Selfie',
      selfieCardSubText: 'Get best product recommendation',
      showSelfieCardProgressIndicator: false,
      fetchingSelfieDetails: false,
      showLogoutModal: false,
      additional_profile_items: this.consumerProfileMenuItems(
        Utility.isPresent(authToken),
      ),
      showDeleteCardConfirmationModal: false,
      showOrderShimmer: false,
      notificationCount: 0,
      loyaltyPlansPromptData: {},
    };

    this.removedInterestObject = {};
    this.removedInterestPosition = 0;
    this.currentPermissionState = '';
    this.selectedCardDetails = {};

    this.dynamicMenuOptions = {
      facial_analysis: {
        title: 'Facial Analysis',
        subText: '',
        showAdditionalList: true,
        haveBorder: false,
        horizontalList: true,
        type: 'facial_features',
        rightSideOptionName: Utility.isPresent(authToken) ? '' : 'Review',
        displayOrder: 0,
        icon: images.shipments,
        enableListHeaderComponent: true,
      },
    };

    this.uploadPhotoModalSubheading = Platform.select({
      ios: 'Tap OK when prompted to click or upload photo',
      android: 'Tap Allow when prompted to click or upload photo',
      web: '',
    });

    this.inviteLink = this.props.invitationCode;
    this.forceRefresh = null;
    this.areAttributesDeleted = null;
    this.lastOptionPressed = undefined;

    if (isNative()) this.setNotificationsOption();
    this.addAboutTheAppSection();
    const routes = props.navigation.getState()?.routes;
    this.prevRoute = routes[routes.length - 2];
    this.navigationSource = getNavigationSource(this, this.props);
  }

  componentDidMount() {
    const {
      navigation,
      getMasterAttributesList,
      profile: { selfie_image_url },
      authToken,
      getLoyaltyPlanPromptDetails,
    } = this.props;
    this.renderInitialData();
    this.convertInviteLinkIntoDynamicShortLink();

    this.unsubscribe = navigation.addListener('focus', () => {
      this.onFocus();
    });

    AnalyticsManager.logEvent(EventType.myProfile.PROFILE_VIEW, {
      [EventParameterKey.LOGIN]: Utility.isPresent(authToken),
      [EventParameterKey.SELFIE]: Utility.isPresent(selfie_image_url),
    });
    this.willBlurListener = navigation.addListener('blur', () => {
      // Update your data
      this.onWillBlur();
    });
    getMasterAttributesList();

    this.trace.stop();
    AnalyticsManager.logEvent(EventType.pageLoad.PAGE_LOAD, {
      [EventParameterKey.SCREEN_NAME]: SCREEN_CONSTANTS.CONSUMER_PROFILE,
    });
    getLoyaltyPlanPromptDetails(
      'foxy-edge-entry-point',
      (success, response) => {
        if (!success) return;
        this.setState({
          loyaltyPlansPromptData: response,
        });
      },
    );
  }

  componentDidUpdate(prevProps) {
    const {
      facialAnalysis: { facialProperties },
      authToken,
      addresses,
      requestedFromHintPrompt,
      isRequestedFromHintPrompt,
      setPhoneNumberModalInfo,
      shouldRenderOnboarding,
      isProfileShimmerVisible,
      showProfileShimmer,
    } = this.props;
    const { settingOptions } = this.state;
    if (
      prevProps.facialAnalysis &&
      !prevProps.facialAnalysis.facialProperties &&
      Utility.isPresent(facialProperties)
    ) {
      const settings = settingOptions;
      settings.unshift(this.dynamicMenuOptions.facial_analysis);
      this.setState({
        settingOptions: settings,
      });
    }

    if (Utility.isBlank(prevProps.authToken) && Utility.isPresent(authToken)) {
      const { additional_profile_items } = this.state;
      additional_profile_items.push(this.logoutObjectRowObject);
      this.setState({
        additional_profile_items,
      });
      this.renderInitialData();
      if (this.lastOptionPressed) {
        this.navigationToScreen(this.lastOptionPressed);
        this.lastOptionPressed = undefined;
      }
    }

    //FIXME: code commented due to otp being sent twice to the user on hint prompt login.
    // if (!prevProps.requestedFromHintPrompt && requestedFromHintPrompt) {
    //   setTimeout(() => {
    //     if (Utility.isPresent(this.props.authToken)) {
    //       return;
    //     }

    //     Utility.furtherAction.phone_number = this.props.mobile;

    //     Utility.furtherAction.fromConsumerProfile = true;
    //     Utility.openOtpModalFromProfile = true;
    //     phoneNumberModalTitle(LOGIN_MODAL_MESSAGES.VIEW_PROFILE);
    //     isRequestedFromHintPrompt(true);
    //     NavigationService.renderOnboarding();
    //   }, 1000);
    // }
  }

  componentWillUnmount() {
    this.unsubscribe();
    this.willBlurListener();
    if (Utility.isAndroid()) {
      this.backHandler?.remove();
    }
  }

  addAboutTheAppSection = () => {
    const { additional_profile_items } = this.state;
    if (
      AppConfig.getBooleanValue(Config.SHOW_ABOUT_APP_SECTION) &&
      Utility.isPresent(Config.ABOUT_APP_WEBVIEW_URL)
    ) {
      const options = {
        title: `About ${Config.APP_NAME}`,
        haveBorder: true,
        navigateTo: 'about_app',
      };
      this.setState({
        additional_profile_items: additional_profile_items.splice(
          1,
          0,
          options,
        ),
      });
    }
  };

  setNotificationsOption = async () => {
    if (!AppConfig.getBooleanValue(Config.SHOW_NOTIFICATIONS_SECTION)) {
      return;
    }
    const notificationsTitle = 'Notifications';
    const notifications = {
      title: notificationsTitle,
      haveBorder: true,
      navigateTo: 'NotificationHistory',
    };

    const options = this.state.additional_profile_items;
    options.splice(1, 0, notifications);
    this.setState({ additional_profile_items: options });
  };

  setNotificationsOptionCount = async () => {
    if (Utility.isWeb()) return;
    let count = await MoEReactInbox.getUnClickedCount();
    const { notificationCount } = this.state;
    const { localNotificationData = [] } = this.props;
    const localNotificationCount = localNotificationData.filter(
      (notification) => notification.isClicked === false,
    );
    count = count + localNotificationCount.length;
    if (notificationCount !== count) {
      this.setState({
        notificationCount: count,
      });
    }
  };

  onHardwareBackKeyPress = () => {
    const { navigation, authorized = false, authToken } = this.props;
    if (!authorized && Utility.isPresent(authToken)) {
      return true;
    }

    if (Utility.isBlank(this.prevRoute)) {
      navigation.navigate('Feed');
    } else {
      navigation.goBack();
    }
    return true;
  };

  showConfirmCardDeleteModal = (cardDetails) => {
    this.selectedCardDetails = cardDetails;
    this.setState({
      showDeleteCardConfirmationModal: true,
    });
  };

  hideConfirmCardDeleteModal = () => {
    this.setState({
      showDeleteCardConfirmationModal: false,
    });
  };

  updateAddressIntoStateInitially = (addressArray) => {
    this.setState({
      myAddress: addressArray,
    });
  };

  refreshAddress = () => {
    const { addresses, shopifyAddresses } = this.props;

    const getAddresses = Utility.getAddressArray({
      ...addresses,
      ...shopifyAddresses,
    });
    this.updateAddressIntoStateInitially(getAddresses);
  };

  getUserAddress = () => {
    const { getUsersAllAddress, addresses, shopifyAddresses } = this.props;

    getUsersAllAddress((success, response) => {
      if (success) {
        if (!Utility.isBlank(response)) {
          this.refreshAddress();
        } else {
          console.tron.log('No address found');
        }
      } else {
        console.tron.log('error in fetching address');
      }
    });

    const getAddresses = Utility.getAddressArray({
      ...addresses,
      ...shopifyAddresses,
    });
    this.updateAddressIntoStateInitially(getAddresses);
  };

  renderInitialData = () => {
    const {
      addresses,
      onGoingShipments,
      facialAnalysis,
      getCardDetails,
      getUserFacialAttributes,
      imageUploadPercentage,
      selfieAnalysisState,
      showPopover,
      getUsersAllAddress,
    } = this.props;
    const { menuItems, settingOptions } = this.state;
    const items = menuItems;
    let settings = settingOptions;

    this.getUserAddress();

    if (
      Utility.isPresent(facialAnalysis) &&
      Utility.isPresent(facialAnalysis.facialProperties)
    ) {
      const selectedAttributes = Utility.expandSelectedAttributes(
        facialAnalysis.facialProperties,
      );
      const settings = settingOptions;

      if (
        !Utility.elementExists(settings, 'Facial Analysis') &&
        !AppConfig.getBooleanValue(Config.PROFILE_HIDE_FACIAL_ANALYSIS) &&
        !isWeb()
      ) {
        settings.unshift(this.dynamicMenuOptions.facial_analysis);
        this.setState({
          settingOptions: settings,
          facial_features: selectedAttributes,
        });
      }
    }

    // Get Saved Card Details
    getCardDetails((response) => {
      this.getSavedCardDetails(response.payu_params);
    });
  };

  convertInviteLinkIntoDynamicShortLink = () => {
    const linkOptions = {
      title: Config.APP_NAME,
      description: RemoteConfig.getValue(REMOTE_CONFIG_KEYS.share_app_text),
      trackingParams: TRACKING_PARAMS.SHARE.GENERIC,
    };
    try {
      const getLink = DynamicLinkManager.getShortenUrl(
        `${Config.WEB_URL}/`,
        linkOptions,
        true,
      );
      getLink
        .then((shortLink) => {
          console.tron.log('My Short url ', shortLink);
          this.inviteLink = shortLink;
        })
        .catch((error) => {
          console.tron.log('Error', error);
        });
    } catch (error) {
      console.tron.log(`Consumer Profile: ${error}`);
    }
  };

  updateAddress = (data) => {
    const { myAddress, settingOptions } = this.state;
    const address = myAddress;
    const update = false;
    const updatedAt = 0;
    if (myAddress.length > 0) {
      address.unshift(data);
      this.setState({
        myAddress: address,
      });
    } else {
      address.unshift(data);
      this.updateAddressIntoStateInitially(address);
    }
  };

  // General functions

  _keyExtractor = (item, index) => item.id;

  navigateToSelfieScreen = () => {
    const { navigation } = this.props;
    Utility.checkPermissionStatic(
      PERMISSION_STORE.camera,
      true,
      (permissionStatus) => {
        if (
          permissionStatus === 'granted' ||
          permissionStatus === 'authorized'
        ) {
          navigation.navigate(SCREEN.TAKE_SELFIE, {
            previousScreen: SCREEN_CONSTANTS.SELFIE_CARD,
            isImageUploading: this.isImageUploading,
          });
          this.currentPermissionState = '';
        } else if (
          permissionStatus === 'never_ask_again' ||
          permissionStatus === 'denied'
        ) {
          if (Utility.isBlank(this.currentPermissionState)) {
            this.currentPermissionState = permissionStatus;
          } else {
            Linking.openSettings();
          }
        }
      },
      Utility.createPermissionMetaObject(
        AnalyticsScreenNames.MY_PROFILE,
        PERMISSIONS.CAMERA,
      ),
    );
  };

  shareApp = () => {
    const { invitationCode } = this.props;
    const shareText = RemoteConfig.getValue(REMOTE_CONFIG_KEYS.share_app_text);
    const shareOptions = {
      title: `Share ${Config.APP_NAME} App Using`,
      message: `${shareText} ${this.inviteLink}`,
    };

    Share.open(shareOptions)
      .then((res) => {})
      .catch((err) => {
        console.tron.log('Seems like this app is not installed on your system');
      });
  };

  navigateToSelfieWithAnalytics = () => {
    AnalyticsManager.logEvent(EventType.onboardingEvent.SELFIE_INITIATE, {
      [EventParameterKey.SOURCE]: EventParameterValue.SOURCE.PROFILE_PAGE,
    });
    this.navigateToSelfieScreen();
  };

  navigateToSelfieScreen = () => {
    const { navigation } = this.props;
    Utility.checkPermissionStatic(
      PERMISSION_STORE.camera,
      true,
      (permissionStatus) => {
        if (
          permissionStatus === 'granted' ||
          permissionStatus === 'authorized'
        ) {
          navigation.navigate(SCREEN.TAKE_SELFIE, {
            previousScreen: SCREEN_CONSTANTS.SELFIE_CARD,
            isImageUploading: this.isImageUploading,
          });
          this.currentPermissionState = '';
        } else if (
          permissionStatus === 'never_ask_again' ||
          permissionStatus === 'denied'
        ) {
          if (Utility.isBlank(this.currentPermissionState)) {
            this.currentPermissionState = permissionStatus;
          } else {
            Linking.openSettings();
          }
        }
      },
      Utility.createPermissionMetaObject(
        AnalyticsScreenNames.MY_PROFILE,
        PERMISSIONS.CAMERA,
      ),
    );
  };

  navigateToTakeSelfieOrReview = (isSelfieAlreadyClick) => {
    const { navigation } = this.props;
    if (AppConfig.getBooleanValue(Config.HIDE_SELFIE_FLOW_PAYOFF)) {
      navigation.navigate(SCREEN_CONSTANTS.ATTRIBUTE_SELECTOR);
      return;
    }
    if (isSelfieAlreadyClick) {
      navigation.navigate(SCREEN_CONSTANTS.ATTRIBUTE_SELECTOR, {
        notShowReviewModal: true,
      });
    } else {
      this.navigateToSelfieScreen();
    }
  };

  navigationToScreen = (rowName, additional_data = {}) => {
    const { navigation, getUsersAllAddress, authToken } = this.props;

    switch (rowName) {
      case 'invite_center':
        if (Utility.isBlank(authToken)) {
          this.onPressLoginCard(rowName);
          return;
        }
        //TODO: hacky way to fix it. there should be a reference or callbacks for the screen you want to remove from stack.
        setTimeout(() => {
          navigation.navigate('InviteCenter', {});
        }, 100);

        break;
      case 'routines':
        if (Utility.isBlank(authToken)) {
          this.onPressLoginCard(rowName);
          return;
        }
        AnalyticsManager.logEvent(EventType.profileEvents.OTHER_ACTIONS_CLICK, {
          type: 'routines',
        });
        //TODO: hacky way to fix it. there should be a reference or callbacks for the screen you want to remove from stack.
        setTimeout(() => {
          navigateToScreen({
            navigation,
            type: 'navigate',
            screen: 'Routines',
            params: {
              showHeader: true,
            },
          });
        }, 100);

        break;
      case 'invite_contacts':
        if (Utility.isBlank(authToken)) {
          this.onPressLoginCard(rowName);
          return;
        }
        //TODO: hacky way to fix it. there should be a reference or callbacks for the screen you want to remove from stack.
        setTimeout(() => {
          navigation.navigate('GroupDealContacts', {});
        }, 100);

        break;
      case 'add_address':
        navigation.navigate(this.navigationSource, 'Address', {
          previousScreen: SCREEN_CONSTANTS.CONSUMER_PROFILE,
          updateAddress: this.updateAddress,
          addressToEdit: additional_data,
          getAllAddress: this.getUserAddress,
          showAddAddressScreen: true,
        });
        break;

      case 'edit_single_address':
        navigation.navigate(this.navigationSource, 'Address', {
          previousScreen: SCREEN_CONSTANTS.CONSUMER_PROFILE,
          updateAddress: this.updateAddress,
          addressToEdit: additional_data,
          showDeleteButton: true,
          getAllAddress: this.getUserAddress,
        });

        break;
      case 'intial_selfie':
        this.navigateToSelfieScreen();
        break;

      case 'edit_profile':
        navigation.navigate('ProfileEdit');
        break;

      case 'my_wallet':
        navigation.navigate('MyWallet');
        break;
      case 'NotificationHistory':
        navigation.navigate('NotificationHistory');
        AnalyticsManager.logEvent(EventType.profileEvents.OTHER_ACTIONS_CLICK, {
          type: 'notification_center',
        });
        break;
      case 'my_orders':
        navigation.navigate('OrdersHistory', { id: additional_data.order_id });
        AnalyticsManager.logEvent(EventType.profileEvents.OTHER_ACTIONS_CLICK, {
          type: 'orders_history',
        });
        break;

      case 'edit_address':
        additional_data.header
          ? navigation.navigate(this.navigationSource, 'Address', {
              previousScreen: SCREEN_CONSTANTS.CONSUMER_PROFILE,
              selectAddress: () => {},
              getAllAddress: this.getUserAddress,
            })
          : navigation.navigate(this.navigationSource, 'Address', {
              previousScreen: SCREEN_CONSTANTS.CONSUMER_PROFILE,
              updateAddress: this.updateAddress,
              showAddAddressScreen: true,
              getAllAddress: this.getUserAddress,
            });
        break;
      case 'open_whatsapp':
        ContactsUsUtility.onContactUsOptionPress();
        break;

      case 'logout':
        this.showLogoutModal(true);
        AnalyticsManager.logEvent(EventType.profileEvents.OTHER_ACTIONS_CLICK, {
          type: 'log_out',
        });
        break;
      case 'app_permission':
        Linking.openSettings();
        break;
      case 'faqs':
        navigation.navigate('WebUrlView', {
          destination: 'https://foxy.in/faqs.html',
          title: `${Config.APP_NAME} faqs`,
          showBottomButton: true,
        });
        break;

      case 'my_payment_methods':
        navigation.navigate('MyPaymentMethods');
        AnalyticsManager.logEvent(EventType.profileEvents.OTHER_ACTIONS_CLICK, {
          type: 'payment_methods',
        });
        break;

      case 'pay_on_foxy':
        if (Utility.isBlank(authToken)) {
          this.lastOptionPressed = 'pay_on_foxy';
          this.onPressLoginCard();
          return;
        }
        AnalyticsManager.logEvent(EventType.salon.PAY_ON_FOXY_CLICK, {
          [EventParameterKey.SOURCE]: EventParameterValue.SOURCE.MY_PROFILE,
        });
        this.salonSearchNavigation();
        break;

      case 'offers_rewards':
        navigation.navigate('PersonalisedOffers', {
          horizontal: false,
          showTitle: true,
          showSubtitle: true,
          enablePagination: true,
        });
        break;

      case 'facial_features':
        navigation.navigate('AttributeSelector', {
          itemId: additional_data.itemId || 28,
        });
        break;

      case 'my_kit':
        navigation.navigate('MyCollection');
        AnalyticsManager.logEvent(EventType.profileEvents.OTHER_ACTIONS_CLICK, {
          type: 'my_kit',
        });
        break;
      case 'need_help':
        navigation.navigate('FaqSection');
        AnalyticsManager.logEvent(EventType.profileEvents.OTHER_ACTIONS_CLICK, {
          type: 'need_help',
        });
        break;
      case 'about_us':
        navigation.navigate('AboutUs');
        AnalyticsManager.logEvent(EventType.profileEvents.OTHER_ACTIONS_CLICK, {
          type: 'about_us',
        });
        break;

      case 'account_settings':
        navigation.navigate('AccountSettings');
        AnalyticsManager.logEvent(EventType.profileEvents.OTHER_ACTIONS_CLICK, {
          type: 'account_settings',
        });
        break;

      case 'share_app':
        this.shareApp();
        AnalyticsManager.logEvent(EventType.profileEvents.OTHER_ACTIONS_CLICK, {
          type: 'share_app',
        });
        break;
      case 'add_shortcut':
        AnalyticsManager.logEvent(EventType.profileEvents.OTHER_ACTIONS_CLICK, {
          type: 'get_personalized_shortcut',
        });
        this.createShortcut();
        break;

      case 'scan_qr':
        navigation.navigate('ScanUSN');

        break;
      case 'about_app':
        navigation.navigate('WebUrlView', {
          destination: `${Config.ABOUT_APP_WEBVIEW_URL}`,
          title: `About ${Config.APP_NAME}`,
          showBottomButton: true,
        });
        break;

      case 'return_prime':
        navigation.navigate('WebUrlView', {
          disableHttpErrorReturn: true,
          newDestination: `${Config.WEB_URL}/apps/return_prime`,
        });
        break;
      default:
        break;
    }
  };

  createShortcut = () => {
    const { andyWarholImages, selfieImageUrl, authorized } = this.props;
    const menuItems = AppConfig.getProfileMenuItems(authorized);
    if (!menuItems?.includes('add_shortcut')) {
      return;
    }
    if (
      Utility.isPresent(andyWarholImages) &&
      Utility.isPresent(andyWarholImages[2])
    ) {
      NativeModules.ShortcutCreator.createShortcut(
        getMinifiedImage(andyWarholImages[2], 100),
      );
    } else if (Utility.isPresent(selfieImageUrl)) {
      NativeModules.ShortcutCreator.createShortcut(
        getMinifiedImage(selfieImageUrl, 100),
      );
    } else {
      this.toast.show(
        'Please complete facial analysis to unlock this feature!',
      );
    }
  };

  getAdditionalDataList = (type) => {
    const {
      myOrders,
      myAddress,
      media,
      additional_profile_items,
      facial_features,
      savedCards,
    } = this.state;
    const {
      facialAnalysis: { facialProperties },
    } = this.props;
    const getSelectedFacialProperties = Utility.isPresent(facialProperties) && isNative()
      ? ImageTagsProcessingUtil.getAllowedTags(
          Utility.expandSelectedAttributes(facialProperties),
        )
      : [];
    switch (type) {
      case 'my_orders':
        return myOrders;
      case 'edit_address':
        return myAddress;
      case 'my_videos':
        return media;
      case 'profile_options':
        return additional_profile_items;
      case 'facial_features':
        return getSelectedFacialProperties;
      case 'saved_payment_methods':
        return savedCards;
      default:
        break;
    }
  };

  getAddressIcon = (type) => {
    let icon = null;
    if (Utility.isBlank(type)) {
      return images.other_location;
    }
    if (type.toLowerCase() === 'home') {
      icon = images.home_building;
    } else if (
      type.toLowerCase() === 'office' ||
      type.toLowerCase() === 'work'
    ) {
      icon = images.office_building;
    } else {
      icon = images.other_location;
    }
    return icon;
  };

  completeProfile = () => {
    AnalyticsManager.logEvent(EventType.myProfile.PROFILE_COMPLETE_INITIATE);
    this.navigationToScreen('edit_profile');
  };

  // Layouts
  _profileCard = (props) => {
    const { imageUrl, mobileNumber } = this.props;
    const { name, handle } = props;
    const imageSource = Utility.isImageUrlValid(imageUrl)
      ? { uri: getMinifiedImage(imageUrl) }
      : images.profileEmptyState;

    const userName = Utility.isBlank(name) ? mobileNumber : name;
    const userHandle = Utility.isBlank(handle) ? '' : `@${handle}`;

    return (
      <View style={styles.profileCardContainer}>
        <ProfilePic />
        <View style={styles.profileCardNameContainer}>
          <View>
            <Text
              numberOfLines={1}
              allowFontScaling={false}
              style={styles.userName}
            >
              {userName}
            </Text>
            {Utility.isBlank(userHandle) ? (
              <this._completeProfileButton />
            ) : (
              <Text style={styles.userHandle}>{userHandle}</Text>
            )}
          </View>
        </View>

        {!Utility.isBlank(handle) && (
          <TouchableWithoutFeedback
            onPress={() => this.navigationToScreen('edit_profile')}
          >
            <View style={styles.editProfileButton}>
              <Text style={styles.editButtonText}>Edit</Text>
            </View>
          </TouchableWithoutFeedback>
        )}
        {/* <this._editProfileButton /> */}
      </View>
    );
  };

  showDeleteAlert = (address) => {
    this.setState({
      showAlert: true,
      addressToDelete: address,
    });
  };

  deleteAddress = () => {
    const { removeAddress, addresses } = this.props;
    const { myAddress, addressToDelete } = this.state;
    if (Utility.isBlank(addressToDelete)) {
      return;
    }
    const data = {
      id: addressToDelete.id,
      address_type: addressToDelete?.address_type,
    };
    removeAddress(data, (success) => {
      if (success) {
        this.refreshAddress();
      }
    });
  };

  toggleAlert = () => {
    this.setState((prevState) => ({
      showAlert: !prevState.showAlert,
    }));
  };

  _myAddressCard = () => {
    const { myAddress } = this.state;

    const sorted = myAddress.sort((a, b) => b.id - a.id);

    const rows = sorted
      .slice(0, 4)
      .map((element, position) => (
        <AddressCard
          locationIcon={this.getAddressIcon(element.name)}
          locationType={element.name || 'Others'}
          addressLine1={element.line1 ?? ''}
          addressLine2={element.line2 ?? ''}
          pincode={element.pincode ?? ''}
          address={element}
          isDefault={element.default}
          onAddressSetDefault={() => this.setDefaultAddress(position)}
          onEditAddress={() =>
            this.navigationToScreen('edit_single_address', element)
          }
          showDeleteAlert={this.showDeleteAlert}
          source='consumerProfile'
        />
      ));
    return rows;
  };

  _mediaCard = (props) => (
    <UserPostCard
      itemData={props.item}
      size='small'
      onClick={() =>
        this.navigationToScreen('play_video', {
          object: props.item,
          index: props.index,
        })
      }
    />
  );

  _facialMarkerTags = (props) => {
    const { marker } = props;
    return (
      <ConsumerProfileSelfieMarker
        shadowOpacity={Utility.isAndroid() ? 1.0 : 0.3}
        shadowElevation={1}
        title={marker.name}
        values={marker.values}
        onPressMarker={() =>
          this.navigationToScreen('facial_features', {
            itemId: marker.id || 28,
          })
        }
      />
    );
  };

  yesTappedInRemoveAddress = () => {
    this.deleteAddress();
    this.toggleAlert();
  };

  cancelTappedInRemoveAddress = () => {
    this.toggleAlert();
    this.setState({
      addressToDelete: {},
    });
  };

  _savedPaymentCards = (props) => {
    const { savedCard } = props;
    const { savedCards } = this.state;
    return (
      <SavedPaymentCard
        cardBrand={savedCard.cardBrand}
        bankName={savedCard.bankName}
        cardNumber={savedCard.cardNumber}
        cardUserName={savedCard.cardName}
        onDeleteCard={() => this.showConfirmCardDeleteModal(savedCard)}
      />
    );
  };

  selfieAttributeHeaderComponent = (props) => {
    const {
      profile: { selfie_image_url },
    } = this.props;
    const { type } = props;
    if (type !== 'facial_features') return null;
    let image = {
      uri: getMinifiedImage(
        selfie_image_url,
        styles.selfieIcon.width,
        styles.selfieIcon.height,
      ),
    };
    if (Utility.isBlank(image.uri)) {
      image = images.for_you_placeholder;
    }
    return (
      <TouchableWithoutFeedback
        onPress={() => this.navigationToScreen('facial_features')}
      >
        <View style={styles.yourSelfieContainer}>
          <FastImageView source={image} style={styles.selfieIcon} />
          <Text style={styles.yourSelfieText}>Your Selfie</Text>
        </View>
      </TouchableWithoutFeedback>
    );
  };

  _settingsOptionRow = (props) => {
    let rightButtonText = props.rightSideButtonText;
    const { loyaltyPlansPromptData = {} } = this.state;
    const { isProfileShimmerVisible = false, navigation } = this.props;

    if (
      props.type === 'edit_address' &&
      Object.keys(this.props.addresses).length < 5
    ) {
      rightButtonText = '';
    }
    return (
      <View>
        {/* {props.additional_data.length > 0 && ( */}
        {/* <TouchableWithoutFeedback onPress={() => this.navigationToScreen(props.type)}> */}
        <View
          style={props.haveBorder ? styles.borderLayout : styles.menuOption}
        >
          <View style={styles.row}>
            <View style={{ flexDirection: 'column' }}>
              <Text style={styles.menuOptionText}>{props.title}</Text>

              {!Utility.isBlank(props.subText) && (
                <Text style={styles.menuSubText}>{props.subText}</Text>
              )}
            </View>
            {!props.showAdditionalList && (
              <Image
                source={images.chevronRight}
                style={{ tintColor: colors.darkBorder }}
              />
            )}
            {props.showAdditionalList && props.additional_data.length > 0 && (
              <TouchableWithoutFeedback
                onPress={() =>
                  this.navigationToScreen(props.type, { header: true })
                }
              >
                <Text
                  style={{
                    position: 'absolute',
                    color: colors.cta.lightBlue,
                    right: 0,
                    fontFamily: 'Roboto-Medium',
                    alignItems: 'center',
                  }}
                >
                  {rightButtonText}
                </Text>
              </TouchableWithoutFeedback>
            )}
          </View>
        </View>
        {/* </TouchableWithoutFeedback> */}
        {/* )} */}
        {props.showAdditionalList &&
          props.additional_data.length > 0 &&
          props.type !== 'edit_address' && (
            <FlatList
              style={{ marginLeft: 16, overflow: 'visible' }}
              extraData={props.menuItems}
              data={props.additional_data}
              horizontal
              ListHeaderComponent={
                <this.selfieAttributeHeaderComponent type={props.type} />
              }
              showsHorizontalScrollIndicator={false}
              renderItem={({ item, index }) => {
                switch (props.type) {
                  case 'my_orders':
                    return <this._orderCard order={item} />;
                  case 'facial_features':
                    return <this._facialMarkerTags marker={item} />;
                  default:
                    break;
                }
              }}
            />
          )}
        {props.showAdditionalList &&
          props.type === 'edit_address' &&
          props.myAddress.length > 0 && (
            <>
              <View style={styles.sidePadding}>
                <View style={styles.cardLayoutContainer}>
                  {<this._myAddressCard addresses={props.myAddress} />}
                  <TouchableWithoutFeedback
                    onPress={() => this.navigationToScreen('add_address')}
                  >
                    <View
                      style={{
                        paddingTop: 8,
                        paddingBottom: 8,
                        alignItems: 'flex-end',
                      }}
                    >
                      <Text
                        style={{
                          fontFamily: 'Roboto-Medium',
                          fontSize: size.h3,
                          color: colors.cta.lightBlue,
                          marginRight: 12,
                        }}
                      >
                        + Add new address
                      </Text>
                    </View>
                  </TouchableWithoutFeedback>
                </View>
              </View>
            </>
          )}
        {props.showAdditionalList &&
          props.type === 'edit_address' &&
          props.myAddress.length < 1 && (
            <View style={styles.sidePadding}>
              <EmptyStateCard
                backgroundColor={colors.white}
                leftAlignImage={false}
                headingText='Add an Address'
                subheadingText='Place orders quickly'
                imgSrc={images.empty_state_address}
                onPressCard={() => this.navigationToScreen(props.type)}
              />
            </View>
          )}
        {props.showAdditionalList && props.type === 'profile_options' && (
          <>
            {!isProfileShimmerVisible &&
              Utility.isPresent(loyaltyPlansPromptData) && (
                <View style={styles.profileEdgeEntry}>
                  <List
                    itemData={loyaltyPlansPromptData}
                    id={loyaltyPlansPromptData?.id}
                    previousScreen={SCREEN_CONSTANTS.CONSUMER_PROFILE}
                    navigation={navigation}
                  />
                </View>
              )}
            <View style={styles.sidePadding}>
              <View style={styles.cardLayoutContainer}>
                <this._additionalProfileRow menus={props.additional_data} />
              </View>
            </View>
          </>
        )}
      </View>
    );
  };

  _additionalProfileRow = (props) => {
    const { menus } = props;
    let rows = [];
    rows = menus.map((element = {}, index) => {
      const { title } = element;
      if (Utility.isBlank(title)) return null;
      const { notificationCount = 0 } = this.state;
      const showNotificationCount =
        title === 'Notifications' && notificationCount !== 0;
      return (
        <View style={{ flexDirection: 'column', justifyContent: 'center' }}>
          <TouchableOpacity
            onPress={() => this.navigationToScreen(element.navigateTo)}
          >
            <View style={styles.cardsItem}>
              <Text style={styles.menuCardItemTextStyle}>{element.title}</Text>
              <View style={styles.cardItemsImageContainer}>
                {showNotificationCount && (
                  <NotificationCount count={notificationCount} />
                )}
                <Image
                  source={images.chevronRight}
                  style={styles.cardItemsImage}
                />
              </View>
            </View>
          </TouchableOpacity>
          <View style={styles.horizontalBar} />
        </View>
      );
    });

    return rows;
  };

  _selfieAttributeCard = (props) => (
    <TouchableWithoutFeedback
      onPress={() => this.navigationToScreen('facial_features')}
    >
      <View
        style={[
          styles.uploadSelfieCard,
          { justifyContent: 'flex-start', paddingLeft: 16, height: 100 },
        ]}
      >
        <FlatList
          style={{ flex: 1, backgroundColor: '#ff000' }}
          data={props.attributes}
          numColumns={3}
          renderItem={({ item }) => {
            let icon = false;
            if (item.icon) {
              icon =
                typeof item.icon !== 'number'
                  ? {
                      uri: getMinifiedImage(
                        item.icon,
                        styles.facialAttribsIcon.width,
                        styles.facialAttribsIcon.height,
                      ),
                    }
                  : item.icon;
            }
            return (
              <View style={styles.attributesChips}>
                <Text style={styles.chipsText}>{item.name}</Text>
                {item.color && (
                  <View
                    style={[
                      styles.colorCircle,
                      { backgroundColor: `#${item.color}` },
                    ]}
                  />
                )}
                {item.icon && (
                  <View style={styles.colorCircle}>
                    <Image source={icon} style={styles.facialAttribsIcon} />
                  </View>
                )}
              </View>
            );
          }}
        />
        {!Utility.isBlank(
          this.props.instagram_profile.insta_profile_picture_url,
        ) && <this._showSelfieInAttributes betafaceImagePath={props.imgSrc} />}
      </View>
    </TouchableWithoutFeedback>
  );

  _showSelfieInAttributes = (props) => (
    <View
      style={{
        position: 'absolute',
        right: 10,
        top: 22,
        borderRadius: 40,
        overflow: 'hidden',
      }}
    >
      <Image
        source={{ uri: props.betafaceImagePath }}
        style={{ height: 60, width: 60 }}
      />
    </View>
  );

  onRefresh = () => {
    const { refreshMyAttributes, selfieImageUrl } = this.props;
    this.setState({
      refreshing: true,
    });

    this.renderInitialData();
    if (Utility.isPresent(selfieImageUrl)) {
      refreshMyAttributes();
    }

    setTimeout(() => {
      this.setState({
        refreshing: false,
      });
    }, 5000);
  };

  _editProfileButton = () => {
    const { handle } = this.props;
    if (Utility.isBlank(handle)) {
      return null;
    }
    return (
      <TouchableWithoutFeedback style={styles.editProfileButton}>
        <Text style={styles.editButtonText}>Edit</Text>
      </TouchableWithoutFeedback>
    );
  };

  _completeProfileButton = () => (
    <TouchableWithoutFeedback
      style={styles.completeProfileButtonContainer}
      onPress={this.completeProfile}
    >
      <View style={{ flexDirection: 'row' }}>
        <Text
          allowFontScaling={false}
          style={[styles.userHandle, { color: colors.foxyPink }]}
        >
          Update Personal Info
        </Text>
        {/* <Image source={images.myProfile.rightArow} style={styles.rightArrow} /> */}
      </View>
    </TouchableWithoutFeedback>
  );

  showLogoutModal = (show) => {
    this.setState({
      showLogoutModal: show,
    });
  };

  generateGuestTokenBeforeLogout = () => {
    const { saveGuestAuthToken, registerGuestUser } = this.props;
    const guestToken = uuidv1();
    saveGuestAuthToken(guestToken);
    BGNetworkTaskManager.saveGuestAuthToken(guestToken);
    registerGuestUser();
    AnalyticsUtility.setGuestTokenAsProperties(guestToken);
  };

  cancelScheduledNotifications = () => {
    const { scheduledNotificationIds = [] } = this.props;
    if (isAndroid()) {
      NativeModules.ScheduledLocalNotification.cancelAllScheduledNotifications();
    } else if (isIOS() && isPresent(scheduledNotificationIds)) {
      NativeModules.LocalNotificationHandler.cancelScheduledNotifications(
        scheduledNotificationIds,
      );
    }
  };

  deleteAuthTokenfromDevice = async () => {
    if (isWeb()) return;
    try {
      await Keychain.resetGenericPassword();
      console.log('Token deleted successfully');
    } catch (error) {
      console.error('Error deleting token', error);
    }
  };

  confirmLogout = () => {
    const {
      navigation,
      saveUserState,
      logoutUser,
      smsRetrieverStatus,
      changeLoginModalState,
      newUser,
      isUserLoggedOutOnce,
      unlinkDevice,
      scheduledNotificationIds,
    } = this.props;
    const add_items = this.state.additional_profile_items;
    add_items.pop();
    this.showLogoutModal(false);
    Utility.haltOffersLandingFlow = false;
    saveUserState(CURRENT_GUEST_STATE.INITIAL);
    unlinkDevice();
    isUserLoggedOutOnce(true);
    changeLoginModalState('');
    logoutUser();
    this.deleteAuthTokenfromDevice();
    smsRetrieverStatus('initiate');
    exitStoreMode();
    AnalyticsManager.resetUserProperties();
    NotificationManager.cancelCartNotification();
    this.generateGuestTokenBeforeLogout();
    this.cancelScheduledNotifications();
    this.setState({
      myOrders: [],
      myAddress: [],
      media: [],
      facial_features: [],
      savedCards: [],
      additional_profile_items: add_items,
      scrollY: new Animated.Value(0),
    });
    setTimeout(() => {
      if (
        AppConfig.getBooleanValue(Config.DISABLE_PROFILE_STATIC_HEADER) ||
        EnableMandatoryLogin ||
        isInternationalChoiceEnabled
      ) {
        navigation.navigate('Feed');
        this.setState({
          myOrders: [],
          myAddress: [],
          media: [],
          facial_features: [],
          savedCards: [],
          additional_profile_items: add_items,
          scrollY: new Animated.Value(0),
        });
        this.onRefresh();
      } else {
        navigation.navigate('TabNavigator');
      }
      if (Utility.isAndroid()) {
        NativeModules.UserPreferences.resetUserPreferences();
      }
    }, 700);
  };

  buildNumber = (hideCredits) => (
    <View style={{ width: '100%', alignItems: 'center' }}>
      <Text
        style={[
          styles.buildNumberText,
          { color: colors.contentPageSeparatorColor },
        ]}
      >
        {isWeb()
          ? `${Config.APP_NAME}`
          : `${Config.APP_NAME} ${VersionNumber.appVersion} (${VersionNumber.buildVersion})`}
      </Text>
      {!hideCredits && (
        <Text style={[styles.buildNumberText, { fontSize: 10 }]}>
          Made with love in India{' '}
        </Text>
      )}
    </View>
  );

  isFacialAnalysisSuccess = () => {
    const {
      facialAnalysis: { analysis_stage = null },
    } = this.props;
    if (analysis_stage === SELFIE_STATE.COMPLETED) {
      return true;
    }
    return false;
  };

  onDidFocus = () => {
    const { authToken } = this.props;
    console.tron.log(authToken, 'authToken');
    this.setState({
      isScreenActive: true,
    });
    if (
      AppConfig.getBooleanValue(Config.DISABLE_PROFILE_STATIC_HEADER) &&
      !authToken
    ) {
      this.setState({
        additional_profile_items: this.consumerProfileMenuItems(
          Utility.isPresent(authToken),
        ),
        myAddress: [],
      });
    }
  };

  consumerProfileMenuItems = (isLoggedIn) => {
    const { authorized = false, authToken } = this.props;
    const showInviteActions = Utility.jsonParser(
      RemoteConfig.getValue(REMOTE_CONFIG_KEYS.hide_actions),
    );

    const items = [
      {
        title: 'Scan a QR Code',
        haveBorder: true,
        navigateTo: 'scan_qr',
      },
      {
        title: `Pay on ${Config.APP_NAME}`,
        haveBorder: true,
        navigateTo: 'pay_on_foxy',
      },
      { title: 'Order History', haveBorder: true, navigateTo: 'my_orders' },
      {
        title: 'Routines For Me',
        haveBorder: true,
        navigateTo: 'routines',
      },
      {
        title: 'My Wallet',
        haveBorder: true,
        navigateTo: 'my_wallet',
      },
    ];
    if (Utility.isAndroid()) {
      items.push({
        title: 'Get personalized shortcut',
        haveBorder: false,
        navigateTo: 'add_shortcut',
      });
    }
    if (showInviteActions.invite_centre_v2) {
      items.push({
        title: 'Invite Centre',
        haveBorder: true,
        navigateTo: 'invite_center',
      });
    }
    if (showInviteActions.invite_contacts) {
      items.push({
        title: 'Invite Contacts',
        haveBorder: true,
        navigateTo: 'invite_contacts',
      });
    }
    items.push(
      {
        title: 'Your Offers & Rewards',
        haveBorder: true,
        navigateTo: 'offers_rewards',
      },
      {
        title: 'Saved Payment Methods',
        haveBorder: true,
        navigateTo: 'my_payment_methods',
      },
      { title: 'My kit', haveBorder: true, navigateTo: 'my_kit' },
      { title: 'Contact Us', haveBorder: true, navigateTo: 'open_whatsapp' },
      { title: 'Need help?', have_border: true, navigateTo: 'need_help' },
      { title: 'Share App', haveBorder: true, navigateTo: 'share_app' },
      {
        title: Config.ABOUT_US_SECTION_LABEL,
        haveBorder: false,
        navigateTo: 'about_us',
      },
      {
        title: 'Account Settings',
        navigateTo: 'account_settings',
      },
    );
    if (isLoggedIn) {
      items.push({
        title: 'Logout',
        haveBorder: false,
        navigateTo: 'logout',
      });
    }

    const menuItems = AppConfig.getProfileMenuItems(authorized);
    const menusToBeIncluded = (menuItems || '').split(',');
    return items.filter((element) =>
      menusToBeIncluded.includes(element.navigateTo),
    );
  };

  onWillBlur = () => {
    if (Utility.isAndroid()) {
      this.backHandler?.remove();
    }
    this.setState({
      isScreenActive: false,
    });
  };

  showCameraPermissionModal = () => {
    this.setState({
      visible: true,
    });
  };

  hideCameraPermissionModal = () => {
    this.setState({
      visible: false,
    });
  };

  // showModalSelfie = () => {
  //   console.tron.log('Show Modal', 'Show Modal');
  //   // this.setState({
  //   //   visible: true,
  //   // });
  // };

  onTapOutsideSelfieUpdateModal = () => {
    this.setState({
      visible: false,
    });
  };

  onPressContinueSelfieUpdateModal = () => {
    // this.setState({
    //   visible: false,
    // });

    this.goToCamera();
  };

  goToCamera = (source = '') => {
    const { navigation } = this.props;

    Utility.checkPermissionStatic(
      PERMISSION_STORE.camera,
      true,
      (permissionStatus) => {
        console.tron.log('Status ', permissionStatus);
        if (
          permissionStatus === 'granted' ||
          permissionStatus === 'authorized'
        ) {
          navigation.navigate('TakeSelfie', {
            previousScreen: SCREEN_CONSTANTS.PROFILE_EDIT,
            getImageUrl: this.getImageUrl,
          });
          this.currentPermissionState = '';
        } else if (
          permissionStatus === 'never_ask_again' ||
          permissionStatus === 'denied'
        ) {
          if (Utility.isBlank(this.currentPermissionState)) {
            this.currentPermissionState = permissionStatus;
          } else if (Utility.isPresent(source)) {
            this.hideCameraPermissionModal();
            Linking.openSettings();
          }
          if (Utility.isBlank(source)) {
            this.showCameraPermissionModal();
          }
        }
      },
    );
  };

  getImageUrl = (profileUrl) => {
    const { startFacialAnalysis, addProfilePic, getMyProfileDetails } =
      this.props;
    const imageName = Utility.extractImageName(profileUrl);
    this.setState({
      showProfilePicLoader: true,
    });

    startFacialAnalysis(imageName, profileUrl, 'profile', (data) => {
      this.setState({
        showProfilePicLoader: false,
      });
      if (data) {
        getMyProfileDetails();
        this.setState({
          profilePicUrl: profileUrl,
        });
        // addProfilePic(data.profile.image_url);
      }
    });
  };

  onPressImageReviewSelfieUpdateModal = () => {
    this.setState({
      visible: false,
    });
    this.props.navigation.navigate('AttributeSelector', {
      notShowReviewModal: true,
    });
  };

  onPressLoginCard = (lastOptionPressed) => {
    const { setPhoneNumberModalInfo } = this.props;
    if (Utility.isPresent(lastOptionPressed)) {
      this.lastOptionPressed = lastOptionPressed;
    }
    if (isWeb()) {
      AnalyticsManager.logEvent(EventType.MISC_WEB_EVENTS.LOGIN_CLICK);
      AnalyticsManager.logEvent(EventType.onboardingEvent.LOGIN_INITIATE, { source: location.href });
    } else {
      AnalyticsManager.logEvent(EventType.onboardingEvent.LOGIN_INITIATE, {
        [EventParameterKey.SOURCE]: EventParameterValue.SOURCE.PROFILE_PAGE,
      });
    }
    Utility.openOtpModalFromProfile = true;
    setPhoneNumberModalInfo(Utility.getLoginModalInfo('VIEW_PROFILE'));
    if (this.lastOptionPressed === 'invite_center') {
      setPhoneNumberModalInfo(Utility.getLoginModalInfo('VIEW_INVITES'));
    } else if (this.lastOptionPressed === 'invite_contacts') {
      setPhoneNumberModalInfo(Utility.getLoginModalInfo('VIEW_CONTACTS'));
    } else if (this.lastOptionPressed === 'pay_on_foxy') {
      setPhoneNumberModalInfo(Utility.getLoginModalInfo('PAY_WITH_FOXY'));
      this.addSalonSearchNavigationToQueue();
    }
    NavigationService.renderOnboarding();
  };

  addSalonSearchNavigationToQueue = () => {
    Utility.furtherAction.action = this.salonSearchNavigation;
  };

  salonSearchNavigation = () => {
    const { navigation } = this.props;
    navigation.navigate('SalonSearch');
  };

  profilePicContainer = () => {
    const {
      name,
      handle,
      mobile,
      email,
      callingCode = AppConfig.callingCode,
      profile: { selfie_image_url },
      authorized,
    } = this.props;
    const { showProfilePicLoader } = this.state;
    let mobileNumber = mobile;
    if (isInternationalChoiceEnabled) {
      mobileNumber = `+${callingCode}-${mobile}`;
    }
    const showReviewSelfieButton = AppConfig.getBooleanValue(
      Config.PROFILE_HIDE_REVIEW_SELFIE_BUTTON,
    )
      ? false
      : authorized;
    return (
      <ConsumerProfileInfoSection
        handle={handle}
        name={name}
        mobileNumber={mobileNumber}
        onPressCompleteProfile={this.completeProfile}
        onPressTakeSelfie={(isSelfieAlreadyClick) =>
          this.navigateToTakeSelfieOrReview(isSelfieAlreadyClick)
        }
        email={email}
        showModalSelfie={this.goToCamera}
        showProfilePicLoader={showProfilePicLoader}
        selfieImageUrl={selfie_image_url}
        showReviewSelfieButton={showReviewSelfieButton}
        authorized={authorized}
      />
    );
  };

  profileSectionContainer = () => {
    const { authToken, isProfileShimmerVisible } = this.props;
    if (isProfileShimmerVisible && Utility.isBlank(authToken)) {
      return (
        <ConsumerProfilePlaceholderShimmer
          isSelfieAttributesPresent={this.isSelfieAttributesAvailable()}
        />
      );
    }
    if (Utility.isBlank(authToken)) {
      return <LoginCard onPress={this.onPressLoginCard} />;
    }

    return <this.profilePicContainer />;
  };

  goBack = () => {
    const { navigation } = this.props;
    navigation.goBack();
  };

  headers = () => {
    const { authToken, authorized = false } = this.props;
    const { scrollY } = this.state;

    if (!isWeb() && Utility.isBlank(this.prevRoute)) {
      return null;
    }

    if (Utility.isBlank(authToken)) {
      return (
        <StaticNavigationHeader
          title='My Profile'
          bgColor={colors.background}
          overrideBackButtonBgColor
          skipDivider
        />
      );
    }

    return (
      <ProfileCustomNavigationHeader
        ref={(ref) => {
          this.navigationRef = ref;
        }}
        scrollY={scrollY}
        slug='Slugs'
        goBack={this.goBack}
        id='1'
        name='docker'
        content='artist'
        screenName={SCREEN_CONSTANTS.POST_DETAIL}
        showBackIcon={authorized}
      >
        <DockedHeader name='My Profile' />
      </ProfileCustomNavigationHeader>
    );
  };

  isSelfieAttributesAvailable = () => {
    const { profile, facialAnalysis: { facialProperties = [] } = {} } =
      this.props;
    return (
      Utility.isPresent(profile.selfie_image_url) ||
      this.isFacialAnalysisSuccess() ||
      Utility.isPresent(facialProperties)
    );
  };

  takeASmartSelfieCard = () => {
    const {
      profile,
      facialAnalysis: { facialProperties = [] } = {},
      isProfileShimmerVisible,
      authorized,
      authToken,
    } = this.props;

    if (isWeb()) return null;
    if (
      AppConfig.getBooleanValue(Config.PROFILE_HIDE_FACIAL_ANALYSIS) ||
      (authToken && !authorized)
    ) {
      return null;
    }
    if (this.isSelfieAttributesAvailable() || isProfileShimmerVisible) {
      return null;
    }
    return (
      <View style={styles.sidePadding}>
        <FacialAnalysisCard
          onPressTakeSelfieButton={this.navigateToSelfieWithAnalytics}
        />
      </View>
    );
  };

  onFocus = () => {
    if (Utility.isAndroid()) {
      this.backHandler = BackHandler.addEventListener(
        'hardwareBackPress',
        this.onHardwareBackKeyPress,
      );
    }
    const {
      navigation,
      isProfileShimmerVisible,
      showProfileShimmer,
      requestedFromHintPrompt,
      authorized = false,
    } = this.props;
    navigation.setOptions({ gestureEnabled: authorized });
    let { settingOptions } = this.state;
    if (!!Utility.areAttributesDeleted) {
      Utility.areAttributesDeleted = false;
      let newSettings = settingOptions?.slice(1);
      this.setState({ settingOptions: newSettings });
    }
    if (!!Utility.forceRefreshConsumerProfile) {
      Utility.forceRefreshConsumerProfile = false;
      this.onRefresh();
    }
    Utility.setStatusBarColor('#f4f6f8');
    this.setNotificationsOptionCount();
    if (isProfileShimmerVisible) {
      Utility.furtherAction = {
        phone_number: '',
        fromConsumerProfile: false,
        isActionAsync: false,
      };
      Utility.openOtpModalFromProfile = false;
      showProfileShimmer(false);
      if (requestedFromHintPrompt) {
        isRequestedFromHintPrompt(false);
      }
    }
    this.setState({
      isScreenActive: true,
    });
    if (Utility.isBlank(this.prevRoute)) {
      Utility.setStatusBarWhite();
    }
  };

  render() {
    const {
      name,
      handle,
      mobile,
      profile,
      facialAnalysis: { facialProperties = [] },
      email,
      navigation,
      isProfileShimmerVisible = false,
    } = this.props;
    const {
      showDeleteCardConfirmationModal,
      settingOptions,
      myAddress,
      refreshing,
      showSnackBar,
      showLogoutModal,
      fetchingSelfieDetails,
      isScreenActive,
      showAlert,
      showProfilePicLoader,
      scrollY,
    } = this.state;

    const rowItems = _.orderBy(settingOptions, ['displayOrder'], ['asc']);

    const {
      show_power_by_ekstore: hideCredits = true,
    } = this.showPowerbyEkstore;

    return (
      <ReactNavigationPerformanceView
        screenName={AnalyticsScreenNames.CONSUMER_PROFILE}
        interactive
      >
        <SelfieReviewModalWrapper isActive={isScreenActive}>
          <View style={{ flex: 1, backgroundColor: colors.background }}>
            {!AppConfig.getBooleanValue(
              Config.DISABLE_PROFILE_STATIC_HEADER,
            ) && <this.headers />}
            <ScrollView
              scrollEventThrottle={16}
              onScroll={Animated.event([
                { nativeEvent: { contentOffset: { y: scrollY } } },
              ])}
              refreshControl={
                <RefreshControl
                  refreshing={refreshing}
                  onRefresh={this.onRefresh}
                  title='Loading...'
                />
              }
            >
              <View style={styles.wrapper}>
                <this.profileSectionContainer />
                <this.takeASmartSelfieCard />

                {Utility.isPresent(profile.selfie_image_url) &&
                  fetchingSelfieDetails && (
                    <View style={styles.sidePadding}>
                      <Text
                        style={[styles.menuOptionText, { marginBottom: 10 }]}
                      >
                        Facial Analysis
                      </Text>
                      <ShimmerPlaceHolder
                        autoRun
                        width={Utility.getScreenWidth() - 32}
                        height={70}
                        style={{ borderRadius: 4 }}
                      />
                    </View>
                  )}
                <FlatList
                  data={rowItems}
                  extraData={this.state}
                  style={{ paddingTop: 10, paddingBottom: 10 }}
                  showsHorizontalScrollIndicator={false}
                  keyExtractor={this._keyExtractor}
                  renderItem={({ item }) => (
                    <this._settingsOptionRow
                      haveBorder={item.haveBorder}
                      horizontalList={item.horizontalList}
                      rightSideButtonText={item.rightSideOptionName}
                      title={item.title}
                      subText={item.subText}
                      menuItems={settingOptions}
                      showAdditionalList={item.showAdditionalList}
                      additional_data={this.getAdditionalDataList(item.type)}
                      type={item.type}
                      myAddress={myAddress}
                      imgsrc={item.icon}
                    />
                  )}
                />
              </View>
              <this.buildNumber hideCredits={hideCredits} />
              <View style={styles.creditFooterImage}>
                <EkstoreCreditFooter />
              </View>
              <View style={{ height: 24 }} />
            </ScrollView>
            <Toast
              style={{ position: 'absolute', top: 20 }}
              ref={(ref) => {
                this.toast = ref;
              }}
            />
            <FoxyAlert
              isVisible={showLogoutModal}
              alertBoxTitle='Logout?'
              alertMessage='Are you sure you want to logout.'
              textAlignment='center'
              firstButtonTitle='Yes'
              firstButtonTextColor={colors.dodgerBluePressed}
              secondButtonTitle='Cancel'
              firstButtonOnPress={this.confirmLogout}
              secondButtonOnPress={() => this.showLogoutModal(false)}
            />

            <FoxyAlert
              isVisible={showAlert}
              alertBoxTitle='Delete address?'
              alertMessage='Are you sure you want to remove this address?'
              firstButtonTitle={'Yes'}
              secondButtonTitle={'No'}
              firstButtonTextColor={colors.dodgerBluePressed}
              firstButtonOnPress={this.yesTappedInRemoveAddress}
              secondButtonOnPress={this.cancelTappedInRemoveAddress}
              textAlignment='left'
              height={230}
            />

            <FoxyAlert
              isVisible={showDeleteCardConfirmationModal}
              alertBoxTitle='Delete Card'
              alertMessage='Are you sure you want to delete this card?'
              firstButtonTitle='Yes'
              firstButtonTextColor={colors.dodgerBluePressed}
              secondButtonTitle='No'
              firstButtonOnPress={this.deleteSavedCard}
              secondButtonOnPress={this.hideConfirmCardDeleteModal}
            />

            <BottomModal
              header='Click or Upload Photo'
              subheading={this.uploadPhotoModalSubheading}
              buttonTitle='Continue to Camera'
              onClose={this.onTapOutsideSelfieUpdateModal}
              onActionClick={() => this.goToCamera('bottom_modal')}
              imgSrc={images.cameraIcon}
              onPressOutside={this.onTapOutsideSelfieUpdateModal}
              isVisible={this.state.visible}
              showWhatsAppBar={false}
            />
          </View>
        </SelfieReviewModalWrapper>
      </ReactNavigationPerformanceView>
    );
  }
}
const mapStateToProps = (state, ownProps) => ({
  // showCongratulationsModal: state.UserAccountInfo.artistOnboarding.showCongratulationsModal,
  mobile: state.UserAccountInfo.mobileNumber,
  callingCode: state.UserAccountInfo.callingCode,
  selfieImageUrl: state.UserAccountInfo.profile.selfie_image_url,
  andyWarholImages: state.UserAccountInfo.profile.andy_warhol_filters || [],
  imageUrl: state.UserAccountInfo.profile.image_url,
  name: state.UserAccountInfo.profile.name,
  handle: state.UserAccountInfo.profile.handle,
  email: state.UserAccountInfo.profile.email,
  mobileNumber: state.UserAccountInfo.mobileNumber,
  profile: state.UserAccountInfo.profile,
  artistProfile: state.UserAccountInfo.artistProfile,
  interestList: state.InterestsList,
  selectedInterests: state.UserAccountInfo.selectedInterests,
  authToken: state.UserAccountInfo.authToken,
  facialAnalysis: state.UserAccountInfo.facialAnalysis,
  addresses: state.UserAccountInfo.addresses,
  instagram_profile: state.UserAccountInfo.instagram_profile,
  invitationCode: state.UserAccountInfo.invitationCode,
  lastPaymentMode:
    state.UserAccountInfo.checkoutDetails &&
    state.UserAccountInfo.checkoutDetails.lastPaymentMode,
  isProfileShimmerVisible: state.UserAccountInfo.showProfileShimmer,
  requestedFromHintPrompt: state.UserAccountInfo.requestedFromHintPrompt,
  shouldRenderOnboarding: state.UserAccountInfo.shouldRenderOnboarding,
  localNotificationData: state.UserAccountInfo.notificationData,
  shopifyAddresses: state.UserAccountInfo.shopifyAddresses,
  authorized: state.UserAccountInfo.authorized,
  scheduledNotificationIds: state.routines.scheduledNotificationIds,
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      getOrders,
      toggleInterest,
      postSelectedInterest,
      getUserFacialAttributes,
      logoutUser,
      updateAddress,
      saveUserState,
      getVideosInReview,
      getLatestMedia,
      onGoingShipments,
      getCardDetails,
      getUserFacialAttributes,
      saveRefferalCode,
      imageUploadPercentage,
      selfieAnalysisState,
      showImageProgressBar,
      removeLastPaymentMode,
      showPopover,
      refreshMyAttributes,
      smsRetrieverStatus,
      changeLoginModalState,
      newUser,
      removeAddress,
      getUsersAllAddress,
      deleteCard,
      addProfilePic,
      startFacialAnalysis,
      getMasterAttributesList,
      setPhoneNumberModalInfo,
      isRequestedFromHintPrompt,
      showProfileShimmer,
      isUserLoggedOutOnce,
      unlinkDevice,
      getMyProfileDetails,
      exitStoreMode,
      getLoyaltyPlanPromptDetails,
      saveGuestAuthToken,
      registerGuestUser,
    },

    dispatch,
  ),
});

export const ProfileNavigationOptions = {
  headerShown: true,
  headerRight: () => (
    <TabNavigationRightHeader tabBar={TABBAR_CONSTANT.profile} />
  ),
  headerBackVisible: false,
  headerShadowVisible: false,
  title: Config.PROFILE_TAB_LABEL || '',
  headerTitleAlign: 'center',
  headerStyle: {
    backgroundColor: colors.white,
  },
  headerLeft: () => null,
  tabBarLabel: Config.PROFILE_TAB_LABEL || 'Home',
  tabBarActiveTintColor: Config.PROFILE_TAB_ACTIVE_COLOR || '#F97700',
  tabBarInactiveTintColor: Config.PROFILE_TAB_INACTIVE_COLOR || '#979BAA',
  tabBarStyle: { borderTopWidth: 0, elevation: 0 },
  animation: 'fade',
  tabBarIcon: ({ focused }) => {
    let image = focused ? images.tab.home_selected : images.tab.home_unselected;
    if (Utility.isPresent(Config.PROFILE_TAB_ICON_URL)) {
      image = { uri: Config.PROFILE_TAB_ICON_URL };
    }
    if (focused && Utility.isPresent(Config.PROFILE_TAB_ACTIVE_ICON_URL)) {
      image = { uri: Config.PROFILE_TAB_ACTIVE_ICON_URL };
    }

    const tintColor = focused
      ? Config.PROFILE_TAB_ACTIVE_COLOR
      : Config.PROFILE_TAB_INACTIVE_COLOR || '#979BAA';
    const tabImageStyle = getTabImageStyle(tintColor);
    return <Image source={image} style={tabImageStyle} tintColor={tintColor} />;
  },
};

export default withProfiledNavigation(
  connect(mapStateToProps, mapDispatchToProps)(ConsumerProfile),
);

const memoizedTabImageStyles = {};

const getTabImageStyle = (tintColor) => {
  if (!memoizedTabImageStyles[tintColor]) {
    memoizedTabImageStyles[tintColor] = {
      height: 24,
      width: 24,
      resizeMode: 'contain',
      tintColor,
    };
  }
  return memoizedTabImageStyles[tintColor];
};
