import React from 'react';
import { Text } from 'react-native';
import FastImageView from '../../components/FastImageView';
import { styles } from './styles';
import Utility from '../../utils/Utility';
import Card from '../../lib/Card';
import { MAX_LINES } from '../../config/Constants';
import { getMinifiedImage } from '../../utils/ImageUtility';

export default function DefaultSizeTag({ itemData = {} }) {
  const { image_webp_url = '', image_url = '' } = itemData;
  const newImageUrl =
    Utility.isAndroid() && Utility.isPresent(image_webp_url)
      ? image_webp_url
      : getMinifiedImage(
          image_url,
          styles.defaultSizeCardImageStyle.width,
          styles.defaultSizeCardImageStyle.height,
        );
  return (
    <Card
      borderRadius={2}
      borderWidth={1}
      cornerRadius={4}
      style={styles.defaultSizeContainer}
    >
      <FastImageView
        source={newImageUrl}
        style={styles.defaultSizeCardImageStyle}
        resizeMode='contain'
      />
      <Text
        allowFontScaling={false}
        numberOfLines={MAX_LINES.two}
        style={styles.defaultSizeCardText}
      >
        {itemData.name}
      </Text>
    </Card>
  );
}
