import React, { PureComponent } from 'react';
import { View, StyleSheet } from 'react-native';
import _ from 'lodash';
import { compose } from 'recompose';
import Card from '../../lib/Card';
import { withEither } from '../../lib/Monads';
import ProductImageAndRating from '../Product/ProductImageAndRating';

import ProductDescription from '../Product/ProductDescription';
import { PRODUCT } from '../../config/LayoutConstants/ProductConfig';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import AddToCart from '../Product/AddToCart';
import ScaleAnimate from '../shared/ScaleAnimate';
import {
  AnalyticsManager,
  EventType,
  EventParameterKey,
} from '../../analytics';
import { LAYOUT } from '../../config/Constants';
import colors from '../../theme/Colors';
import SkuColorVariant from './SkuColorVariant';
import SkuSizeVariant from './SkuSizeVariant';
import AnalyticsUtility from '../../analytics/AnalyticsUtility';
import MoreOptions from '../Product/MoreOptions';
import ReviewProduct from '../Product/ReviewProduct';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import { applyFlashDeal } from '../../actions/ActionTypes';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { navigateToScreen } from '../../utils/NavigationUtility';
import withProfiledNavigation from '../../utils/withProfiledNavigation';
import { getNavigationSource } from '../../utils/PerfUtility';

// import SkuListItem from './SkuListItem';   //have to add this

class DefaultSizeVariantCard extends PureComponent {
  constructor(props) {
    super(props);
    this.debouncedNavigate = _.debounce(this.navigate, 1000, {
      leading: true,
      trailing: false,
    });
    this.debounceProductCardClick = _.debounce(
      this.fireSearchResultClickEvent,
      2000,
      {
        leading: true,
        trailing: false,
      },
    );
    this.navigationSource = getNavigationSource(this, this.props);
  }

  showCart = () => {
    const { toggleCartVisibility } = this.props;
    // TODO: This prevents a crash from the search results page but doesn't allow the user to navigate to the cart page
    if (toggleCartVisibility !== undefined) {
      toggleCartVisibility();
    }
  };

  navigate = () => {
    const {
      navigation,
      itemData: { product_slug: slug, sku_id, id },
      toggleCartVisibility,
      applyFlashDeal,
      previousScreen,
    } = this.props;

    // if (previousScreen === SCREEN_CONSTANTS.SEARCH) {
    applyFlashDeal({
      type: 'Variant',
      id,
    });
    // }

    navigateToScreen({
      navigation,
      type: 'push',
      screen: 'Product',
      navigationSource: this.navigationSource,
      params: {
        slug: `/api/v2/products/${slug}.json`,
        display: LAYOUT.SCREEN,
        toggleCartVisibility,
        sku_id,
        previousScreen: this.props?.previousScreen,
      },
    });
  };

  onPress = async () => {
    const {
      onPress = () => {},
      listIndex,
      index,
      search,
      searchQuery,
      elementItemCounts,
      itemData,
      itemData: { slug = '' } = {},
      listId,
      layout,
      previousScreen,
      listName,
      listContent,
      listSlug = '',
      toggleUnavailableProductsModal,
      extraEventParameters = {},
    } = this.props;
    if (previousScreen === SCREEN_CONSTANTS.SEARCH) {
      let clickedIndex = Utility.addPreviousIndexValues(
        elementItemCounts,
        listIndex,
      );

      clickedIndex += index + 1;
      const meta = {
        [EventParameterKey.SEARCH_QUERY]: searchQuery,
        [EventParameterKey.ITEM_TYPE]: itemData.type,
        [EventParameterKey.ITEM_NAME]: itemData.name,
        [EventParameterKey.ITEM_ID]: itemData.id,
        [EventParameterKey.PRODUCT_ID]: itemData.id,
        [EventParameterKey.PRODUCT_STOCKED_STATUS]: itemData.stocked_status,
        [EventParameterKey.ITEM_POSITION]: clickedIndex || 'NAN', //TODO: this was crashing need to check
      };
      this.debounceProductCardClick(meta);
    }

    if (toggleUnavailableProductsModal) {
      await toggleUnavailableProductsModal();
    }

    AnalyticsUtility.fireItemClickEvent(
      previousScreen,
      itemData.id,
      itemData.type,
      itemData.name,
      index,
      listId,
      layout,
      listName,
      listIndex,
      '',
      !itemData.outOfStock,
      listContent,
      '',
      slug,
      listSlug,
      extraEventParameters,
    );

    this.debouncedNavigate();
    if (onPress !== undefined && onPress !== null) {
      onPress();
    }
  };

  fireSearchResultClickEvent = (meta) => {
    AnalyticsManager.logEvent(EventType.search.SEARCH_RESULT_CLICK, meta);
  };

  addToCart = (props) => {
    const {
      skuId,
      orientation,
      layout,
      hideAddToCart,
      id,
      itemData,
      hasVariants,
      priority,
      singleStockedVariantSku,
      campaignId,
      onItemAddToCartFromCollab,
      prompts,
    } = props;
    const {
      previousScreen,
      maxFreeItemsToSelect,
      showToast,
      refreshOfferStrip,
      refreshOffersDetailsPageDiscountStrip,
      listId,
      listName,
      listIndex,
      listContent,
      index,
      listData = {},
      listData: { additional_data = {} } = {},
    } = this.props;

    let { outOfStock = false } = props;
    if (outOfStock === null) {
      outOfStock = true;
    }

    return (
      <AddToCart
        skuId={skuId}
        orientation={orientation}
        toggleCartState={this.toggleCartState}
        layout={LAYOUT.PERSONALISEDRAIL}
        addToCartLayout={PRODUCT.ADD_TO_CART_LAYOUT.PRODUCT_CARD}
        hideAddToCart={hideAddToCart}
        id={id}
        itemData={itemData}
        hasVariants={hasVariants}
        showCart={this.showCart}
        priority={priority}
        outOfStock={outOfStock}
        refreshOfferStrip={refreshOfferStrip}
        refreshOffersDetailsPageDiscountStrip={
          refreshOffersDetailsPageDiscountStrip
        }
        previousScreen={previousScreen}
        maxFreeItemsToSelect={maxFreeItemsToSelect}
        showToast={showToast}
        campaignId={campaignId}
        onItemAddToCartFromCollab={onItemAddToCartFromCollab}
        listId={listId}
        listName={listName}
        listIndex={listIndex}
        listContent={listContent}
        index={index}
        singleStockedVariantSku={singleStockedVariantSku}
        prompts={prompts}
        additional_data={additional_data}
        listData={listData}
      />
    );
  };

  skuSizeCondition = ({ variantName }) => Utility.isPresent(variantName);

  conditionalVariants = withEither(
    this.skuSizeCondition,
    SkuSizeVariant,
  )(SkuColorVariant);

  skuVariants = (props) => {
    const { variantName, imageUrl, packSize, containerStyle } = props;

    return (
      <this.conditionalVariants
        variantShade={variantName}
        variantImage={imageUrl}
        packSize={packSize}
        containerStyle={containerStyle}
      />
    );
  };

  render() {
    const {
      orientation,
      previousScreen,
      itemData,
      hasVariants,
      hideAddToCart,
      itemData: {
        offer_value,
        final_sp,
        additional_discount,
        type,
        brand_name: variantBrandName,
        images,
        webp_images,
        image_webp_url,
        rating,
        id,
        name,
        mrp,
        sp,
        discount,
        sku_id: skuId,
        pack_size,
        product_name,
        display_name,
        image_url,
        outOfStock,
        priority,
        image_url: imageUrl,
        single_stocked_variant: singleStockedVariantSku,
        prompts = [],
      },
      listId,
      listName,
      listIndex,
      seeProductReviews,
      similarProducts,
    } = this.props;

    const disabled =
      previousScreen === 'brandCollab' || previousScreen === 'free_items';

    const display_price = final_sp;

    return (
      <View>
        <ScaleAnimate
          {...this.props}
          disabled={disabled}
          onPress={this.onPress}
        >
          <Card style={{ flexDirection: 'column' }}>
            <ReviewProduct
              listName={listName}
              seeProductReviews={seeProductReviews}
            />
            <View style={styles.cardContainer}>
              <View style={styles.imageContainer}>
                <ProductImageAndRating
                  layout={LAYOUT.PERSONALISEDRAIL}
                  rating={rating}
                  images={images}
                  webp_images={webp_images}
                  image_webp_url={image_webp_url}
                  imageUrl={imageUrl}
                  id={id}
                  name={name}
                  previousScreen={previousScreen}
                  itemData={itemData}
                />
              </View>
              <View style={{ flex: 1, marginRight: 4 }}>
                <ProductDescription
                  itemData={itemData}
                  name={product_name}
                  mrp={mrp}
                  offer_value={offer_value}
                  sp={sp}
                  display_price={display_price}
                  discount={discount}
                  brandName={variantBrandName}
                  variantBrandName={variantBrandName}
                  layout={LAYOUT.PERSONALISEDRAIL}
                  id={id}
                  previousScreen={previousScreen}
                  prompts={prompts}
                  renderProp={() => (
                    <this.skuVariants
                      variantName={display_name}
                      imageUrl={image_url}
                      packSize={pack_size}
                      layout={LAYOUT.PERSONALISEDRAIL}
                      containerStyle={styles.variantContainerStyle}
                    />
                  )}
                />
              </View>
              <View style={styles.cartImageContainer}>
                <this.addToCart
                  skuId={skuId}
                  orientation={orientation}
                  toggleCartState={this.toggleCartState}
                  layout={LAYOUT.PERSONALISEDRAIL}
                  addToCartLayout={PRODUCT.ADD_TO_CART_LAYOUT.PRODUCT_CARD}
                  hideAddToCart={hideAddToCart}
                  id={id}
                  itemData={itemData}
                  hasVariants={hasVariants}
                  showCart={this.showCart}
                  priority={priority}
                  outOfStock={outOfStock}
                  previousScreen={previousScreen}
                  singleStockedVariantSku={singleStockedVariantSku}
                  prompts={prompts}
                />
              </View>
            </View>
            <MoreOptions
              products={similarProducts}
              listId={listId}
              listName={listName}
              previousScreen={previousScreen}
              listIndex={listIndex}
            />
          </Card>
        </ScaleAnimate>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  cardContainer: {
    backgroundColor: colors.white,
    borderColor: colors.borderColor,
    marginHorizontal: Utility.spacingBetweenItems,
    flexDirection: 'row',
    borderRadius: 4,
    width: Utility.getScreenWidth() - 24,
    height: 170,
    alignSelf: 'center',
    marginBottom: 2,
  },
  imageContainer: {
    alignSelf: 'center',
    height: 140,
    width: 140,
  },
  fullCardDivider: {
    height: '100%',
    width: 1,
    backgroundColor: colors.borderColor,
  },
  cartImageContainer: {
    position: 'absolute',
    bottom: 64,
    right: 12,
  },
  variantContainerStyle: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginLeft: 4,
  },
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      applyFlashDeal,
    },
    dispatch,
  ),
});

export default withProfiledNavigation(
  connect(null, mapDispatchToProps)(DefaultSizeVariantCard),
);
