import React, { Component } from 'react';
import {
  View,
  Text,
  Image,
  TouchableWithoutFeedback,
  StyleSheet,
} from 'react-native';
import images from '../../../theme/Images';
import colors from '../../../theme/Colors';
import Utility from '../../../utils/Utility';
import size from '../../../theme/Fonts';
import { getMinifiedImage } from '../../../utils/ImageUtility';

const styles = StyleSheet.create({
  container: {
    borderRadius: 4,
    width: 80,
    marginLeft: 4,
    marginRight: 4,
    marginTop: 7,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },

  titleText: {
    fontFamily: 'Roboto-Regular',
    fontSize: size.h5,
    paddingBottom: 2,
    color: colors.subtitle,
  },

  icon: {
    height: 40,
    width: 40,
    resizeMode: 'contain',
  },
  colorCircle: {
    height: 40,
    width: 40,
    borderRadius: 40,
  },
  selectText: {
    fontFamily: 'Roboto-Medium',
    color: colors.foxyPink,
  },
  cheveronContainer: {
    height: 10,
    width: 10,
    marginLeft: 5,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
    backgroundColor: colors.foxyPink,
  },

  column: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 2,
    // paddingLeft: 5,
  },

  row: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 2,
    paddingLeft: 5,
  },

  text: {
    fontFamily: 'Roboto-Regular',
    fontSize: size.h4,
    paddingRight: 1,
    paddingLeft: 1,
    // width: 65,
    marginTop: 7,
    // alignSelf:'center',
    alignItems: 'center',
    textAlign: 'center',
    color: colors.subtitle,
  },
});

class ConsumerProfileSelfieMarker extends Component {
  setText = (title, otherItemsCount) => {
    const text = `${title}`;
    return text;
  };

  _text = (props) => {
    return (
      <View style={{ height: 40 }}>
        <Text numberOfLines={2} ellipsizeMode='tail' style={styles.text}>
          {props.text}
        </Text>
      </View>
    );
  };

  _titleOnly = (props) => (
    <View style={[styles.column, { paddingLeft: 0 }]}>
      <this._text text={this.setText(props.title, props.total_values)} />
    </View>
  );

  _titleWithIcon = (props) => (
    <View style={styles.column}>
      <View style={styles.icon}>
        <Image
          style={styles.icon}
          source={{ uri: getMinifiedImage(props.icon, styles.icon.width, styles.icon.height) }}
        />
      </View>
      <this._text text={this.setText(props.title, props.total_values)} />
    </View>
  );

  _titleWithColor = (props) => (
    <View style={styles.column}>
      <View style={styles.colorCircle}>
        <View
          style={[styles.colorCircle, { backgroundColor: props.colorCode }]}
        />
      </View>
      <this._text text={this.setText(props.title, props.total_values)} />
    </View>
  );

  _selectAttribute = () => (
    <View style={styles.row}>
      <Text style={styles.selectText}>Select</Text>
      <View style={styles.cheveronContainer}>
        <Image
          source={images.chevronRight}
          style={{ tintColor: colors.white, height: 8, width: 8 }}
        />
      </View>
    </View>
  );

  _attributeValue = (props) => <View />;

  iconImage = () => {
    const { values = [] } = this.props;
    let image = '';
    if (values.length > 0 && values[0] !== undefined && values[0] !== null) {
      image =
        Utility.isPresent(values[0]) && values[0].image !== null
          ? values[0].image
          : values[0].icon;
    }
    return image;
  };

  render() {
    const { title, onPressMarker, values, shadowElevation, shadowOpacity } =
      this.props;
    const valuesPresent =
      Utility.isPresent(values) && Utility.isPresent(values[0]);
    const iconPresent =
      !!(valuesPresent && Utility.isPresent(values[0].icon)) ||
      !!(valuesPresent && Utility.isPresent(values[0].image));
    const colorCodePresent = !!(
      valuesPresent && Utility.isPresent(values[0].color_code)
    );
    const isTitleOnly = valuesPresent && !iconPresent && !colorCodePresent;
    const iconImage = this.iconImage();

    return (
      <TouchableWithoutFeedback onPress={onPressMarker}>
        <View style={styles.container}>
          {/* <Text style={styles.titleText}>{title}</Text> */}
          {/* {!valuesPresent && <this._selectAttribute />} */}
          {iconPresent && (
            <this._titleWithIcon
              title={values[0].name}
              icon={iconImage}
              total_values={values.length}
            />
          )}
          {colorCodePresent && (
            <this._titleWithColor
              title={values[0].name}
              colorCode={values[0].color_code}
              total_values={values.length}
            />
          )}
          {isTitleOnly && (
            <this._titleOnly
              title={values[0].name}
              total_values={values.length}
            />
          )}
        </View>
      </TouchableWithoutFeedback>
    );
  }
}

export default ConsumerProfileSelfieMarker;
