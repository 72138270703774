import React, { PureComponent } from 'react';
import { View, Image, Text, StyleSheet } from 'react-native';
import PropTypes from 'prop-types';
import { styles as BannerStyles } from '../../banner/styles';
import ScaleAnimate from '../../shared/ScaleAnimate';
import DynamicLinkManager from '../../../utils/DynamicLinkManager';
import withNavigation from '../../../utils/WithNavigation';
import Utility from '../../../utils/Utility';
import CountDown from '../../../helpers/Counter';
import FastImageView from '../../FastImageView';
import colors from '../../../theme/Colors';
import AnalyticsUtility from '../../../analytics/AnalyticsUtility';
import NavigationService from '../../../navigator/NavigationService';
import { navigateToScreen } from '../../../utils/NavigationUtility';
import { getMinifiedImage } from '../../../utils/ImageUtility';
import withProfiledNavigation from '../../../utils/withProfiledNavigation';
import { getNavigationSource } from '../../../utils/PerfUtility';

class BannerCard extends PureComponent {

  constructor(props) {
    super(props)
    this.navigationSource = getNavigationSource(this, this.props);
  }

  navigateToScreen = (path, destination) => {
    const { navigation, authToken } = this.props;

    let newDestination = destination;
    if (path === 'ImageReview' && Utility.isBlank(authToken)) {
      NavigationService.renderOnboarding();
      Utility.invokeSelfieFlowAfterLoginForGuest();
      return;
    }

    if (path === 'RecycleWithFoxyWebView') {
      const { itemData: { destination = '' } = {} } = this.props;
      newDestination = destination;
    }
    navigateToScreen({
      navigation,
      type: 'push',
      screen: path,
      navigationSource: this.navigationSource,
      params: {
        newDestination, // webview reads data from destination
        slug: newDestination,
      },
    });
  };

  onActionPressed = () => {
    const {
      selfieImageUrl,
      itemData,
      previousScreen,
      listIndex = 0,

      listData = {},
      listData: { slug: listSlug = '' },
      listContent,
      listName,
      index,
      navigation,
      facialProperties,
      itemData: {
        web_slug: destination,
        dynamic_destination,
        external_link,
      } = {},
      display,
    } = this.props;

    try {
      let url = Utility.isBlank(destination) ? listData.slug : destination;
      url = Utility.isPresent(external_link) ? external_link : url;

      const listNames = url.split('/')[url.split('/').length - 1];

      if (Utility.isPresent(itemData)) {
        const { id, type } = itemData;
        AnalyticsUtility.fireItemClickEvent(
          previousScreen,
          id,
          type,
          listNames,
          index,
          listData.id,
          display,
          listName,
          listIndex,
          '',
          '',
          listContent,
          '',
          destination,
          listSlug,
        );
      }
    } catch (e) {}

    if (Utility.isBlank(dynamic_destination)) {
      DynamicLinkManager.handleLinkWithInternalTrackingParams(
        destination,
        this.navigateToScreen,
      );
    }
  };

  timerOfferText = () => {
    const {
      itemData: { ends_at },
      onTimerFinish,
    } = this.props;
    return (
      <View style={BannerStyles.bannerWithTimer}>
        <Text style={BannerStyles.bannerWithTimerText}>Offer ends in</Text>

        <CountDown
          size={10}
          until={Utility.calculateTimeDifferenceForOffer(ends_at)}
          endsAt={ends_at}
          onFinish={onTimerFinish}
          digitStyle={BannerStyles.bannerTimerDigitStyle}
          digitTxtStyle={{ color: '#fff', fontSize: 16 }}
          timeLabelStyle={BannerStyles.bannerTimerLabelStyle}
          separatorStyle={BannerStyles.bannerTimerSeparatorStyle}
          timeToShow={['H', 'M', 'S']}
          timeLabels={{ h: 'hrs', m: 'mins', s: 'sec' }}
          showSeparator
        />
      </View>
    );
  };

  timerSection = () => {
    const { itemData, length } = this.props;
    if (Utility.isBlank(itemData)) {
      return null;
    }
    return (
      <View style={length > 1 ? styles.smallTimer : styles.largeTimer}>
        <Image
          resizeMode='stretch'
          source={{
            uri: getMinifiedImage(
              itemData.image_url,
              Utility.getScreenWidth(),
              Utility.getScreenWidth(),
            ),
          }}
          style={BannerStyles.bannerImageStyle}
          blurRadius={80}
        />

        <View style={BannerStyles.bannerTimerContainer}>
          <this.timerOfferText />
        </View>
      </View>
    );
  };

  render() {
    const { itemData, length } = this.props;

    if (itemData === undefined) {
      return null;
    }
    return (
      <ScaleAnimate {...this.props} onPress={this.onActionPressed}>
        <FastImageView
          resizeMode='stretch'
          source={getMinifiedImage(
            itemData.image_url,
            Utility.getScreenWidth(),
            (1 / 3) * (Utility.getScreenWidth() - 24),
          )}
          style={length > 1 ? styles.smallImage : styles.largeImage}
        />
        <this.timerSection />
      </ScaleAnimate>
    );
  }
}

const styles = StyleSheet.create({
  largeImage: {
    height: (1 / 3) * (Utility.getScreenWidth() - 24),
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    overflow: 'hidden',
    borderColor: colors.border,
    backgroundColor: 'transparent',
    marginHorizontal: 12,
    marginTop: 16,
    width: Utility.getScreenWidth() - 24,
  },
  smallImage: {
    height: (1 / 3) * (Utility.getScreenWidth() - 24),
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    overflow: 'hidden',
    borderColor: colors.border,
    backgroundColor: 'transparent',
    marginHorizontal: 12,
    marginTop: 16,
    width: Utility.getScreenWidth() - 72,
  },
  largeTimer: {
    marginLeft: Utility.padding,
    marginRight: Utility.padding,
    width: Utility.getScreenWidth() - 24,
    height: 42,
    alignSelf: 'center',
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    overflow: 'hidden',
  },
  smallTimer: {
    marginLeft: Utility.padding,
    marginRight: Utility.padding,
    width: Utility.getScreenWidth() - 74,
    height: 42,
    alignSelf: 'center',
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    overflow: 'hidden',
  },
});

BannerCard.propTypes = {
  itemData: PropTypes.shape({
    type: PropTypes.string,
    id: PropTypes.string,
    image_url: PropTypes.string,
  }),
};

export default withProfiledNavigation(BannerCard);
