import { snakeCase } from 'lodash';
import { crashlytics } from '../libraries/Firebase';
import Config from '../libraries/ReactNativeConfig';
import AnalyticsEvent from './AnalyticsEvent';
import FirebaseAnalytics from './FirebaseAnalytics';
import MoengageAnalytics from './MoengageAnalytics';
import FacebookAnalytics from './FacebookAnalytics';
import { SCREEN_CONSTANTS } from '../config/ScreenConstants';
import WebengageAnalytics from './WebengageAnalytics';
import RemoteConfig from '../utils/RemoteConfig';
import { REMOTE_CONFIG_KEYS } from '../config/Constants';
import AppConfig from '../config/AppConfig';
import {
  isAppLaunchedFromDeeplink,
  isBlank,
  isPresent,
  updateIsAppLaunchedFromDeeplink,
  isNative,
  isWeb,
} from '../utils/BooleanUtility';
import { getCurrentAddressEmail } from '../utils/AddressUtility';
import { getGuestToken } from '../utils/TokenUtility';
import { getStoreRef } from '../store/StoreUtility';
import { gtmDataLayer } from './GTMAnalytics';
import { getScreenClassification } from '../utils/webUtility';
import GoogleAnalytics from './GoogleAnalytics';

class AnalyticsManager {
  static networkInfo = {};

  static whitelistedEvents = null;

  static eventTrackingAgents = isWeb() ? [
    FirebaseAnalytics,
    FacebookAnalytics,
    GoogleAnalytics,
  ] : [
    FirebaseAnalytics,
    MoengageAnalytics,
    FacebookAnalytics,
    WebengageAnalytics,
    // CleverTapAnalytics,
    // UXCamAnalytics,
  ];

  static trackEvent = (event) => {
    if (isBlank(AnalyticsManager.whitelistedEvents)) {
      const remoteConfigWhitelistedObject = RemoteConfig.getValue(
        REMOTE_CONFIG_KEYS.whitelisted_events,
      );

      if (isPresent(remoteConfigWhitelistedObject)) {
        AnalyticsManager.whitelistedEvents = JSON.parse(
          remoteConfigWhitelistedObject,
        );
      }
    }

    if (event instanceof AnalyticsEvent) {
      AnalyticsManager.eventTrackingAgents.forEach((trackingAgent) => {
        //Initially for 2-3 seconds remote config can be null

        if (isBlank(AnalyticsManager.whitelistedEvents)) {
          trackingAgent.trackEvent(event);
          return;
        }

        const analyticsClassName = snakeCase(trackingAgent.name);
        const whiteListedEventsObject =
          AnalyticsManager.whitelistedEvents[analyticsClassName];

        if (isBlank(whiteListedEventsObject)) {
          trackingAgent.trackEvent(event);
          return;
        }
        const whitelistAllEvents =
          isPresent(whiteListedEventsObject) &&
          whiteListedEventsObject.length === 0;

        if (
          whitelistAllEvents ||
          whiteListedEventsObject.includes(event.eventType)
        ) {
          trackingAgent.trackEvent(event);
        }
      });
    }
  };

  static logEvent = (eventName, eventParams = {}) => {
    let meta = { guest_token: getGuestToken(), ...eventParams };
    if (isWeb()) {
      meta = {
        platform: 'website',
        'screen-width': window.innerWidth,
        'screen-class': getScreenClassification(),
        ...meta,
      };
    }
    AnalyticsManager.trackEvent(
      new AnalyticsEvent({
        eventType: eventName,
        meta,
      }),
    );
  };

  static logFirebaseEvent = (eventName, eventParams = {}) => {
    FirebaseAnalytics.trackEvent(
      new AnalyticsEvent({
        eventType: eventName,
        meta: eventParams,
      }),
    );
  };

  static logFBStandardEvent = (eventName, valueToSum, parameters = {}) => {
    FacebookAnalytics.logStandardEvent(eventName, valueToSum, parameters);
  };

  static logFBPurchaseEvent = (purchaseAmount, currencyCode, parameters) => {
    FacebookAnalytics.logPurchaseEvent(
      purchaseAmount,
      currencyCode,
      parameters,
    );
  };

  static logPixelStandardEvent = (eventName, params) => {
    FacebookAnalytics.logPixelStandardEvent(eventName, params);
  };

  static setCurrentScreen = (screenName) => {
    if (
      !isAppLaunchedFromDeeplink.product &&
      isPresent(screenName) &&
      screenName === SCREEN_CONSTANTS.PRODUCT_DETAIL
    ) {
      return;
    }
    updateIsAppLaunchedFromDeeplink({ product: false });

    if (isPresent(screenName) && screenName !== SCREEN_CONSTANTS.MORE_PAGE) {
      AnalyticsManager.eventTrackingAgents.forEach((trackingAgent) => {
        if (typeof trackingAgent.currentScreen !== 'function') {
          return;
        }
        trackingAgent.currentScreen(screenName);
      });
      // UXCamAnalytics.currentScreen(screenName);
    }
  };

  static setUserProperty = (key, value) => {
    if (isBlank(value)) return;
    AnalyticsManager.eventTrackingAgents.forEach((trackingAgent) => {
      if (typeof trackingAgent.setUserProperty !== 'function') {
        return;
      }

      trackingAgent.setUserProperty(key, value);
    });
    // UXCamAnalytics.setUserProperty(key, value);
  };

  static logBuildConfig = () => {
    AnalyticsManager.setUserProperty('build_version', Config.VERSION_NAME);
    AnalyticsManager.setUserProperty('build_code', Config.VERSION_CODE);
  };

  static getUniqueIdentifier = (userAccountInfo = {}) => {
    const {
      mobileNumber = '',
      addresses = '',
      publicId = '',
    } = userAccountInfo;
    if (
      AppConfig.shouldSendAttributeAsAnalyticsUserId('phone_number') &&
      isPresent(mobileNumber)
    ) {
      return mobileNumber;
    }
    const defaultEmail = getCurrentAddressEmail(addresses);
    if (
      AppConfig.shouldSendAttributeAsAnalyticsUserId('email') &&
      isPresent(defaultEmail)
    ) {
      return defaultEmail;
    }
    return publicId;
  };

  static setUserId = (deviceId) => {
    AnalyticsManager.eventTrackingAgents.forEach((trackingAgent) => {
      if (typeof trackingAgent.setUserId !== 'function') {
        return;
      }
      trackingAgent.setUserId(deviceId);
    });
    // UXCamAnalytics.setUserId(id);
    // CleverTapAnalytics.setInitialUserProfile(deviceId);
    crashlytics().setUserId(deviceId);
  };

  static setUserAlias = () => {
    // const uniqueIdentifier = AnalyticsManager.getUniqueIdentifier();
    // AnalyticsManager.eventTrackingAgents.forEach((trackingAgent) => {
    //   if (typeof trackingAgent.setUserAlias !== 'function') {
    //     return;
    //   }
    //   trackingAgent.setUserAlias(uniqueIdentifier);
    //   trackingAgent.setUserProperty('public_id', uniqueIdentifier);
    //   // CleverTapAnalytics.setInitialUserProfile(uniqueIdentifier);
    // });
  };

  static setGeneralUserAttributes = (userAttributes) => {
    const newUserAttributes = splitAndAddFirstAndLastNames(userAttributes);
    AnalyticsManager.eventTrackingAgents.forEach((trackingAgent) => {
      if (typeof trackingAgent.setGeneralUserAttributes !== 'function') {
        return;
      }

      trackingAgent.setGeneralUserAttributes(newUserAttributes);
    });
  };

  static setNetworkStateMeta = (networkInfo) => {
    AnalyticsManager.networkInfo = networkInfo;
  };

  static logPurchaseEvent = (purchaseAmount, currencyCode, parameters) => {
    FacebookAnalytics.logPurchaseEvent(
      purchaseAmount,
      currencyCode,
      parameters,
    );
  };

  static resetUserProperties = () => {
    AnalyticsManager.eventTrackingAgents.forEach((trackingAgent) => {
      if (typeof trackingAgent.setUserProperty !== 'function') {
        return;
      }
      trackingAgent.setUserProperty('gender', '');
      trackingAgent.setUserProperty('gender_selected_by_user', '');
      trackingAgent.setUserProperty('is_artist', 'false');
      trackingAgent.setUserProperty('artist_level', '0');
      trackingAgent.setUserProperty('published_media_count', '0');
      trackingAgent.setUserProperty('selfie_analysis_success', 'false');
      trackingAgent.setUserProperty('membership_cohort', 'non_member');
      trackingAgent.setUserProperty('membership_id', '');
      trackingAgent.setUserProperty('authenticated', 'false');
    });
  };

  static setUserProfileAfterEdit(name, email) {
    const profile = { name, email };
    const state = getStoreRef().getState();
    const { UserAccountInfo: { mobileNumber = '' } = {} } = state;
    // CleverTapAnalytics.setUserProfile(profile, mobileNumber);
  }

  static setPublicIdAsIdentifierInClevertap = (publicId) => {
    // CleverTapAnalytics.setInitialUserProfile(publicId);
  };

  static logGTMTag(params) {
    if (isNative()) return;
    gtmDataLayer(params);
  }
}

export default AnalyticsManager;

const splitAndAddFirstAndLastNames = (userAttributes = {}) => {
  const newUserAttributes = { ...userAttributes };
  const { name = '' } = userAttributes;
  if (isPresent(name)) {
    const nameArr = name.split(' ');
    newUserAttributes.firstName = nameArr[0];
    if (nameArr.length > 1) {
      nameArr.shift();
      newUserAttributes.lastName = nameArr.join(' ');
    }
  }
  return newUserAttributes;
};
