// Dependencies
import React, { PureComponent } from 'react';
import {
  View,
  FlatList,
  Text,
  ActivityIndicator,
  Switch,
  Animated
} from 'react-native';
import Config from '../../../libraries/ReactNativeConfig';
import PropTypes from 'prop-types';
// Components
import _ from 'lodash';
import List from '../../../containers/List/List';
import FavouriteList from '../../../containers/List/FavouriteList';
import Banner from '../../../containers/banner/Banner';
import Feature from '../../../containers/feature/Feature';
import Media from '../../../containers/media/Media';
import { Header } from '../../header';
import { ORIENTATION, LAYOUT, EVENT_BUS_ACTIONS } from '../../../config/Constants';
import { ShortBanner } from '../../banner';
import Utility from '../../../utils/Utility';
import styles from './styles';
import { SCREEN_CONSTANTS } from '../../../config/ScreenConstants';
import colors from '../../../theme/Colors';

import { AnalyticsManager } from '../../../analytics';
import AppConfig from '../../../config/AppConfig';
import { Viewport } from '../../../libraries/Viewport';
import ErrorBoundaryComponent from '../../shared/ErrorBoundaryComponent';
import { isDesktop, isNative } from '../../../utils/BooleanUtility';
import { subscribe } from '../../../utils/EventBus';
import { FlatListPerformanceView } from '../../../libraries/ReactNativePerformanceShopify';
import { getListSource } from '../../../utils/PerfUtility';

const style = {
  width: Utility.getScreenWidth(),
  overflow: 'visible',
};

class Section extends PureComponent {
  Components = {
    list: List,
    video: Media,
    image: Media,
    feature: Feature,
    short_banner: ShortBanner,
    favouriteList: FavouriteList,
    banner: Banner,
  };

  constructor(props) {
    super(props);
    const { previousScreen } = this.props;
    this.refreshing = false;
    this.state = {
      forceRefresh: false,
      scrollY: new Animated.Value(0),
    };

    this.viewedItemsIds = [];
    this.refArray = [];
    this.viewabilityConfig = {
      // waitForInteraction: true,
      itemVisiblePercentThreshold: 0,
    };
    this.index = 0;

    AnalyticsManager.setCurrentScreen(previousScreen);
    this.flatListRef = React.createRef();
  }

  scrollToTop = () => {
    const { scrollY } = this.state;
    if (scrollY !== 0) {
      this.flatListRef?.current?.scrollToOffset({ animated: true, y: 0 });
    }
  };

  componentDidMount() {
    const { listData } = this.props;
    if (this.props.setListRef) {
      this.props.setListRef(this);
    }
    if (Utility.isPresent(listData) && !Utility.isBlank(listData.objects)) {
      setTimeout(this.fireListRenderedEvent, 3000);
    }
    this.subscription = subscribe(EVENT_BUS_ACTIONS.SIDEBAR_SCROLL_TO_TOP, this.scrollToTop);
  }

  componentDidUpdate(prevProps) {
    const { navigation, listData, setForceRefreshHomePage, previousScreen } =
      this.props;

    if (
      JSON.stringify(prevProps?.listData?.objects) !==
      JSON.stringify(listData?.objects)
    ) {
      setTimeout(this.fireListRenderedEvent, 3000);
    }
  }

  componentWillUnmount() {
    this.subscription.unsubscribe();
  }

  fireListRenderedEvent = () => {
    const { listData, previousScreen } = this.props;
    Utility.fireListRenderedEvent(listData, previousScreen);
  };

  getItemData = (propsItem) => {
    const { search } = this.props;
    if (search) {
      return propsItem;
    }
    return propsItem;
  };

  getComponent = (item, index) => {
    if (Utility.isBlank(item)) {
      return null;
    }
    const ContainerComponent = this.Components[item.type];
    if (ContainerComponent === null || ContainerComponent === undefined) {
      return null;
    }
    const {
      navigation,
      feed,
      iconName,
      showCustomIcon,
      showColorComponent,
      onPress,
      preventReduxFetch = false,
      toggleCartVisibility,
      previousScreen = '',
      allVideosId,
      search = false,
      searchQuery,
      elementItemCounts,
      updateCurrentlyPlayingIndex,
      currentlyPlayingIndex,
      shuffle,
      showToast,
      showMoreButton,
      checkVisible,
      showFreeProductScreen,
      paginationResult,
      isSearchResultScreen,
      productAndBrandsOnlySwitchValue,
      listData = {},
      parentListsData = [],
      id: listId = '',
      listSlug = '',
      listIndex,
      extraStyleParameter = {},
      extraEventParameters = {},
      paginateLastItem,
      showDynamicListTitle,
      totalItemsInList,
    } = this.props;
    if (
      previousScreen === SCREEN_CONSTANTS.SEARCH &&
      (item.content === 'artist' || item.content === 'media') &&
      productAndBrandsOnlySwitchValue
    ) {
      return null;
    }

    const updatedParentListData = Utility.isPresent(parentListsData)
      ? parentListsData.concat({ listId, listSlug, item_position: listIndex })
      : [{ listId, listSlug, item_position: listIndex }];

    const itemData = item.is_dynamic ? undefined : item;
    const slug = item.is_dynamic ? item.slug : undefined;
    const args = item?.arguments;
    const hideHeader = item.is_dynamic ? !showDynamicListTitle : false;
    const showDynamicListShimmer = item.is_dynamic && Utility.isBlank(itemData);
    const isNotDynamicList = !item.is_dynamic;
    return (
      <View
        style={{
          backgroundColor: Utility.isPresent(item?.background_color)
            ? item?.background_color
            : colors.background,
        }}
      >
        {isNotDynamicList && (
          <Header
            item={item}
            navigation={navigation}
            layout={item.display}
            feed={feed}
            previousScreen={previousScreen}
            showMoreButton={showMoreButton}
            index={index}
            isSearchResultScreen={isSearchResultScreen}
            searchQuery={searchQuery}
            productAndBrandsOnlySwitchValue={productAndBrandsOnlySwitchValue}
            extraEventParameters={extraEventParameters}
            hideHeader={hideHeader}
            extraStyleParameter={extraStyleParameter}
          />
        )}
        <ErrorBoundaryComponent
          itemData={item}
          listData={listData}
          screenName={previousScreen}>
          <ContainerComponent
            navigation={navigation}
            itemData={itemData}
            slug={slug}
            args={args}
            id={itemData?.id}
            type={itemData?.type}
            size={itemData?.size}
            orientation={ORIENTATION.VERTICAL}
            layout={itemData?.display}
            index={index}
            feed={feed}
            iconName={iconName}
            showCustomIcon={showCustomIcon}
            showDynamicListShimmer={showDynamicListShimmer}
            showColorComponent={showColorComponent}
            addedProducts={this.props.addedProducts}
            onPress={onPress}
            ignoreMinCount={item?.is_dynamic}
            preventReduxFetch={preventReduxFetch}
            toggleCartVisibility={toggleCartVisibility}
            previousScreen={previousScreen}
            allVideosId={allVideosId}
            search={search}
            searchQuery={searchQuery}
            elementItemCounts={elementItemCounts}
            updateCurrentlyPlayingIndex={updateCurrentlyPlayingIndex}
            currentlyPlayingIndex={currentlyPlayingIndex}
            shuffle={shuffle}
            listName={itemData?.name}
            listContent={itemData?.content}
            parentListsData={updatedParentListData}
            showToast={showToast}
            listIndex={index}
            checkVisible={checkVisible}
            showFreeProductScreen={showFreeProductScreen}
            paginationResult={paginationResult}
            isSearchResultScreen={isSearchResultScreen}
            productAndBrandsOnlySwitchValue={productAndBrandsOnlySwitchValue}
            useInViewPort={true}
            avoidFlashList={isNative()}
            paginateLastItem={paginateLastItem || listData.paginate_last_item}
            totalItemsInList={totalItemsInList}
            listData={listData}
          />
        </ErrorBoundaryComponent>
      </View>
    );
  };

  onRefresh = () => {
    const { onRefresh, previousScreen, disableRefresh } = this.props;
    if (previousScreen === SCREEN_CONSTANTS.SEARCH || disableRefresh) {
      return;
    }
    if (onRefresh !== undefined) {
      onRefresh();
      this.refreshing = true;
    }
  };

  checkForRefreshing = () => {
    const { refreshing } = this.props;
    const { forceRefresh } = this.state;
    if (forceRefresh) {
      this.refreshing = refreshing;
    } else {
      this.refreshing = refreshing && this.refreshing;
    }
  };

  onEndReached = () => {
    const { endReached = () => {} } = this.props;
    endReached();
  };

  searchHeaderComponent = () => {
    const { previousScreen, productAndBrandsOnlySwitchValue, togigleSwitch } =
      this.props;
    if (
      Utility.isBlank(previousScreen) ||
      AppConfig.getBooleanValue(Config.DISABLE_PRODUCTS_AND_BRANDS_ONLY_SEARCH)
    ) {
      return null;
    }
    if (previousScreen !== SCREEN_CONSTANTS.SEARCH) {
      return null;
    }
    return (
      <View style={styles.searchHeaderContainer}>
        <Text style={styles.searchHeaderSwitchText}>
          Products and Brands only
        </Text>
        <Switch
          style={styles.searchHeaderSwitch}
          thumbTintColor={Utility.isIOS() ? null : 'rgba(145, 211, 69)'}
          trackColor={
            Utility.isIOS()
              ? null
              : {
                  true: 'rgba(145, 211, 69)',
                  false: 'rgba(0, 0, 0, 0.2)',
                }
          }
          onValueChange={toggleSwitch}
          tintColor={Utility.isIOS() ? 'rgba(145, 211, 69)' : null}
          onTintColor={Utility.isIOS() ? 'rgba(145, 211, 69)' : null}
          value={productAndBrandsOnlySwitchValue}
        />
      </View>
    );
  };

  renderFooter = () => {
    // it will show indicator at the bottom of the list when data is loading otherwise it returns null

    const { loadMore = false } = this.props;
    if (loadMore) {
      return (
       <ActivityIndicator
         size='large'
         style={{ color: colors.foxyPink, marginTop: 12 }} 
       />
      );
    }

    return (
      <View
        style={{
          width: Utility.getScreenWidth(),
          height: 0,
        }}
      />
    );
  };

  keyExtractor = (item, index) => `${item.type}_${item.id}_${index}_vertical`;

  renderItem = ({ item, index }) => this.getComponent(item, index);

  render() {
    // this.checkForRefreshing();
    const paddingBottom = isDesktop ? 50 : 0;
    const {
      previousScreen,
      allVideosId,
      currentlyPlayingIndex,
      disableRefresh,
      sectionListHeader,
      sectionListFooter,
    } = this.props;
    const backgroundColor =
      previousScreen === SCREEN_CONSTANTS.MY_PROFILE
        ? colors.white
        : colors.background;
    const contentContainerStyle =
      previousScreen === SCREEN_CONSTANTS.PRE_ROUTINE ||
      SCREEN_CONSTANTS.SUBSCRIBE_ROUTINE
        ? [styles.contentContainerStyle, { marginLeft: -12, marginTop: 0 }]
        : styles.contentContainerStyle;
    const flatListContentStyle =
      previousScreen === SCREEN_CONSTANTS.CATEGORY_BROWSING
        ? { paddingBottom: 0 }
        : contentContainerStyle;
    const sectionListContainerStyle =
      previousScreen === SCREEN_CONSTANTS.CATEGORY_BROWSING
        ? styles.sectionListContainer
        : {};

  
    return (
      // TODO: Find an alternative solution. Not able to measure the performance of Flatlist because scroll stops working if the component is wrapped with FlatListPerformanceView. 
      <Viewport.Tracker>
        <View style={sectionListContainerStyle}>
          <FlatList
            horizontal={false}
            data={this.props.item}
            ref={this.flatListRef}
            onRefresh={disableRefresh ? null : this.onRefresh}
            refreshing={this.refreshing}
            renderItem={this.renderItem}
            keyExtractor={this.keyExtractor}
            style={previousScreen === SCREEN_CONSTANTS.CATEGORY_BROWSING ? styles.pageWithSideBarContainer : style}
            onEndReached={this.onEndReached}
            onEndReachedThreshold={0.5}
            ListHeaderComponent={sectionListHeader || this.searchHeaderComponent}
            ListFooterComponent={sectionListFooter || this.renderFooter}
            extraData={this.props.item}
            bounces={false}
            scrollEnabled
            contentContainerStyle={flatListContentStyle}
          />
        </View>
      </Viewport.Tracker>
    );
  }
}

// PropTypes
Section.propTypes = {
  item: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      slug: PropTypes.string,
    }),
  ),
  onRefresh: PropTypes.func,
  refreshing: PropTypes.bool,
};

Section.defaultProps = {
  item: [],
  onRefresh: () => {},
  refreshing: false,
};

export default Section;
