import React, { Component } from 'react';
import {
  Text,
  View,
  StyleSheet,
  TouchableOpacity,
  FlatList,
  Image,
} from 'react-native';
import RadioButton from 'react-native-radio-button';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import RemoteConfig from '../../utils/RemoteConfig';
import FoxyShadowButton from '../../lib/FoxyShadowButton';
import { REMOTE_CONFIG_KEYS } from '../../config/Constants';
import Utility from '../../utils/Utility';
import colors from '../../theme/Colors';
import size from '../../theme/Fonts';
import images from '../../theme/Images';
import {
  deleteUserSelfie,
  saveAndyWarholImages,
} from '../../actions/ActionTypes';
import {
  saveCDNlinktoRedux,
  deleteFaceAnalysisData,
} from '../../actions/LoginActions';
import ModalContainerWithoutSafeArea from './ModalContainerWithoutSafeArea';

class CancellationReasonsModal extends Component {
  constructor(props) {
    super(props);
    this.reasons = this.getReasonsFromRemoteConfig();
    this.errorMessage = RemoteConfig.getValue(
      REMOTE_CONFIG_KEYS.delete_selfie_reason_not_selected_error_message,
    );
    this.logoutToastMessage = RemoteConfig.getValue(
      REMOTE_CONFIG_KEYS.delete_selfie_logout_toast_message,
    );
    this.state = {
      selectedValue: -1,
      selectedReason: '',
      showErrorMessage: false,
    };
    this.circularRef = React.createRef();
  }

  getReasonsFromRemoteConfig = () => {
    const remoteValues = Utility.jsonParser(
      RemoteConfig.getValue(REMOTE_CONFIG_KEYS.update_shipment_status),
    );
    const reasonsArr = [];
    remoteValues?.forEach((element) => {
      reasonsArr?.push(element?.reason);
    });

    return reasonsArr;
  };

  onReasonSelected = (index, reason) => {
    this.setState({ selectedValue: index, selectedReason: reason });
  };

  renderRadioBoxes = ({ item, index }) => {
    const { selectedValue } = this.state;
    return (
      <TouchableOpacity
        style={styles.radioRow}
        onPress={_.memoize(
          () => this.onReasonSelected(index, item),
          () => [index],
        )}
      >
        <RadioButton
          outerColor={colors.subtitle}
          innerColor={colors.foxyBlack}
          isSelected={selectedValue === index}
          size={12}
          onPress={_.memoize(
            () => this.onReasonSelected(index, item),
            () => [index],
          )}
        />
        <Text style={styles.radioText}>{`${item.reason}`}</Text>
      </TouchableOpacity>
    );
  };

  keyExtractor = (item, index) => item.id;

  updateShipmentStatus = () => {
    const {
      route: { params: { cancelOrder, item } = {} },
    } = this.props;
    const { selectedReason } = this.state;
    cancelOrder(item, selectedReason);
    this.onDismiss();
  };

  showErrorInModal = () => {
    const { showErrorMessage } = this.state;
    return (
      <View style={styles.errorTextView}>
        {showErrorMessage && (
          <Text style={styles.errorText}> {this.errorMessage} </Text>
        )}
      </View>
    );
  };

  onDismiss = () => {
    const { navigation } = this.props;
    navigation.goBack();
  };

  render() {
    const {
      route: { params: { cancellationReasons } = {} } = {},
    } = this.props;

    return (
      <ModalContainerWithoutSafeArea>
        <View style={styles.bottomModal}>
          <View style={styles.modalHeadingTextContainer}>
            <Text style={styles.modalHeadingText}>Reason for cancellation</Text>
            <View style={styles.crossButton}>
              <TouchableOpacity
                style={styles.crossTouchableOpacity}
                onPress={this.onDismiss}
              >
                <Image source={images.cross} style={styles.crossIcon} />
              </TouchableOpacity>
            </View>
          </View>
          <View>
            <Text style={styles.feedbackText}>
              Could you help us understand the reason better?
            </Text>
          </View>
          <FlatList
            data={cancellationReasons}
            style={styles.reasonsFlatlistStyle}
            showsHorizontalScrollIndicator={false}
            keyExtractor={this.keyExtractor}
            renderItem={this.renderRadioBoxes}
            extraData={this.state.selectedValue}
            scrollEnabled={false}
          />
          <this.showErrorInModal />
          <FoxyShadowButton
            width={Utility.getScreenWidth() - 36}
            style={styles.foxyShadowButton}
            title={'Cancel Order'}
            backgroundColor={colors.codGray}
            firstColor={colors.black}
            secondColor={colors.black}
            underlayColor={colors.black}
            borderRadius={4}
            onPress={this.updateShipmentStatus}
          />
        </View>
      </ModalContainerWithoutSafeArea>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      deleteUserSelfie,
      saveCDNlinktoRedux,
      saveAndyWarholImages,
      deleteFaceAnalysisData,
    },
    dispatch,
  ),
});

export default connect(null, mapDispatchToProps)(CancellationReasonsModal);

const styles = StyleSheet.create({
  background: {
    height: Utility.getScreenHeight(),
    backgroundColor: colors.background,
    paddingHorizontal: 6,
  },
  bottomModal: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    marginBottom: 0,
    marginRight: 0,
    marginLeft: 0,
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: colors.white,
    borderTopLeftRadius: 25,
    borderTopRightRadius: 25,
  },
  modalHeadingTextContainer: {
    borderTopLeftRadius: 25,
    borderTopRightRadius: 25,
    flexDirection: 'row',
    backgroundColor: colors.white,
    alignItems: 'center',
    paddingHorizontal: 12,
    display: 'flex',
    marginTop: 24,
  },
  modalHeadingText: {
    flex: 1,
    fontFamily: 'Roboto-Bold',
    color: colors.foxyBlack,
    fontSize: 18,
    justifyContent: 'center',
  },
  feedbackText: {
    marginVertical: 12,
    color: colors.subtitle,
    marginHorizontal: 12,
  },
  reasonsFlatlistStyle: {
    paddingVertical: 10,
    marginBottom: 12,
    width: '100%',
    paddingHorizontal: 12,
  },
  foxyShadowButton: {
    marginBottom: Utility.isAndroid ? 22 : 42,
    marginHorizontal: 16,
  },
  radioRow: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginBottom: 20,
  },
  radioText: {
    marginLeft: 16,
    marginTop: 0,
    fontFamily: 'Roboto',
    fontSize: size.h3,
    color: colors.black,
    fontWeight: '400',
  },
  checkbox: {
    height: 16,
    width: 16,
    borderRadius: 2,
    borderWidth: 1,
    borderColor: colors.foxyGreen,
    marginRight: 8,
    justifyContent: 'center',
    alignItems: 'center',
  },
  toast: {
    position: 'absolute',
    bottom: 30,
    maxWidth: Utility.getScreenWidth() - 32,
  },
  errorTextView: {
    marginVertical: 24,
    minHeight: 38,
    maxHeight: 40,
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  errorText: {
    padding: 4,
    color: colors.foxyRed,
    fontFamily: 'Roboto-Medium',
    fontSize: size.h3,
  },
  crossTouchableOpacity: {
    height: 24,
    width: 24,
    justifyContent: 'center',
    alignItems: 'center',
  },
  crossButton: {
    // flex: 1,
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  crossIcon: {
    tintColor: colors.foxyBlack,
    height: 12,
    width: 12,
  },
});
