// Dependencies
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create(
  {
    modal: {
      justifyContent: 'flex-end',
      marginBottom: 0,
      marginRight: 0,
      marginLeft: 0,
    },
  },
);

export default styles;
