import { v1 as uuidv1 } from 'uuid';

const subscriptions = {};

export const subscribe = (eventType, callback) => {
  const id = uuidv1();

  if (!subscriptions[eventType]) {
    subscriptions[eventType] = {};
  }

  subscriptions[eventType][id] = callback;

  return {
    unsubscribe: () => {
      delete subscriptions[eventType][id];
      if (Object.keys(subscriptions[eventType]).length === 0) {
        delete subscriptions[eventType];
      }
    },
  };
};

export const publish = (eventType, arg) => {
  if (!subscriptions[eventType]) {
    return;
  }
  Object.keys(subscriptions[eventType]).forEach(key => subscriptions[eventType][key](arg));
};
