import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { LAYOUT } from '../../config/Constants';
import Utility from '../../utils/Utility';
import FastImageView from '../FastImageView';
import { getMinifiedImage } from '../../utils/ImageUtility';

const VariantShade = (props) => {
  const { variantImage, variantShade, layout } = props;
  return (
    <>
      {Utility.isPresent(variantImage) && (
        <FastImageView
          style={styles.variantShadeImage}
          source={getMinifiedImage(
            variantImage,
            styles.variantShadeImage.width,
            styles.variantShadeImage.height,
          )}
        />
      )}
      <Text
        allowFontScaling={false}
        style={
          layout === LAYOUT.LIST
            ? styles.variantShadeTextList
            : styles.variantShadeText
        }
        numberOfLines={1}
      >
        {variantShade}
      </Text>
    </>
  );
};

export default function SkuColorVariant(props) {
  const { variantShade, variantImage, containerStyle, layout } = props;
  return (
    <View style={[styles.variantShadeContainer, containerStyle]}>
      <VariantShade
        variantShade={variantShade}
        variantImage={variantImage}
        layout={layout}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  variantShade: {
    height: 12,
    width: 12,
    borderRadius: 6,
    marginLeft: -7,
    borderWidth: 1,
    borderColor: '#ffffff',
  },
  variantCountText: {
    fontSize: 10,
    marginLeft: 2,
    fontFamily: 'Roboto-Regular',
  },
  variantShadeContainer: {
    flexDirection: 'row',
    padding: 1,
    paddingHorizontal: Utility.isIOS() ? 12 : 6,
    justifyContent: Utility.isIOS() ? 'center' : undefined,
    alignItems: 'center',
  },

  variantShadeText: {
    fontFamily: 'Roboto-Regular',
    maxWidth: 58,
    fontStyle: 'normal',
    letterSpacing: 0,
    flexShrink: 1,
    color: '#606481',
    fontSize: 10,
    lineHeight: 14,
    alignSelf: 'center',
  },
  variantShadeTextList: {
    fontFamily: 'Roboto-Regular',
    maxWidth: 120,
    fontStyle: 'normal',
    letterSpacing: 0,
    color: '#606481',
    fontSize: 10,
    lineHeight: 14,
    alignSelf: 'center',
    // maxWidth: 40,
  },
  variantShadeImage: {
    height: 10,
    width: 10,
    borderRadius: 5,
    marginRight: 4,
    resizeMode: 'contain',
  },
});
