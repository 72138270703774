import { memoize } from 'lodash';
import React from 'react';
import { View, Text } from 'react-native';
import { styles } from './styles';
import DebouncedTouchableOpacity from '../../components/shared/DebouncedTouchableOpacity';

const LinearTabCard = (props) => {
  const {
    selectedIndex = '',
    index,
    item,
    onTabPress,
  } = props;
  const selectedLinearTab = selectedIndex === index;
  const linearTabStyle = selectedLinearTab
    ? styles.selectedLinearTabText
    : styles.unselectedLinearTabText;
  return (
    <DebouncedTouchableOpacity
      {...props}
      onPress={memoize(
        () => onTabPress(item, index),
        () => [index],
      )}
      delayPressOut={0}
    >
      <View style={styles.linearTabStyle}>
        <Text style={linearTabStyle}>{item.name}</Text>
      </View>

      {selectedLinearTab && <View style={styles.indicatorStyle} />}
    </DebouncedTouchableOpacity>
  );
};

export default LinearTabCard;
