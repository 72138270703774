import StyleSheet from 'react-native-media-query';
import Utility from '../../../utils/Utility';
import colors from '../../../theme/Colors';
import size from '../../../theme/Fonts';

export const { ids: paymentIds, styles: PaymentStyles } = StyleSheet.create({
  backgroundImage: {
    alignSelf: 'stretch',
    alignItems: 'center',
    marginVertical: 23,
  },
  scanContainer: {
    width: Utility.getScreenWidth(),
    height: 320,
    alignItems: 'center',
    justifyContent: 'center',
  },
  qrContainer: {
    height: 293,
    width: 270,
    backgroundColor: colors.white,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 16,
  },
  name: {
    fontSize: 12,
    fontFamily: 'Roboto-Medium',
    color: '#979BAA',
    marginBottom: 12,
  },
  barcode: { width: '100%', height: 10, position: 'absolute', bottom: 0 },
  mobileNumber: {
    fontSize: 12,
    fontFamily: 'Roboto-Medium',
    color: '#979BAA',
    marginTop: 12,
  },
  container: {
    flexDirection: 'column',
    width: Utility.getScreenWidth(),
    alignSelf: 'center',
  },
  containerWithHorizontalMargin: {
    flexDirection: 'column',
    marginLeft: Utility.largePadding,
    marginRight: Utility.largePadding,
    marginTop: 50,
  },
  scrollView: {
    marginTop: 0,
  },
  scrollViewWithBottomInset: {
    marginTop: 0,
    marginBottom: Utility.bottomInset,
  },
  scrollViewWithBottomMargin: {
    marginTop: 0,
    marginBottom: 10,
  },
  orderStatusScrollView: {
    marginTop: 20,
    height: '100%',
  },
  paymentContainer: {
    flexDirection: 'column',
  },
  activityIndicatorInCheckout: {
    marginRight: 4,
  },
  header: {
    position: 'absolute',
    top: 0,
    height: 50,
    width: Utility.getScreenWidth(),
    backgroundColor: colors.white,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    borderRadius: 6,
    borderBottomWidth: 1,
    borderBottomColor: colors.background,
  },
  backArrow: {
    width: 20,
    height: 17.5,
    resizeMode: 'contain',
    tintColor: colors.foxyBlack,
    marginLeft: 16,
  },
  headerText: {
    fontFamily: 'Roboto-Regular',
    fontSize: 18,

    fontStyle: 'normal',
    letterSpacing: 0,
    marginLeft: 12,
    color: colors.foxyBlack,
  },
  subHeading: {
    fontFamily: 'Roboto-Medium',
    fontSize: 16,
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.foxyBlack,
  },
  subHeadingContainer: {
    height: 36,
    marginTop: 16,
    marginRight: 16,
    marginLeft: 16,
  },
  divider: {
    height: 1,
    backgroundColor: colors.background,
  },
  fullWidthDivider: {
    height: 1,
    backgroundColor: colors.background,
    width: '100%',
  },
  orderSummaryDivider: {
    height: 1,
    backgroundColor: colors.background,
    marginHorizontal: 12,
    marginBottom: 12,
  },
  payButton: {
    width: '90%',
    alignSelf: 'center',
  },
  paymentOption: {
    height: 52,
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 20,
    paddingRight: 20,
    backgroundColor: colors.white,
    width: '100%',
  },
  paymentOptionContainer: {
    height: 52,
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 20,
    paddingRight: 20,
    backgroundColor: colors.white,
    width: '100%',
    marginLeft: 12,
    marginRight: 12,
    opacity: 1,
  },
  paymentOptionContainerWithOpacity: {
    height: 52,
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 20,
    paddingRight: 20,
    backgroundColor: colors.white,
    width: '100%',
    marginLeft: 12,
    marginRight: 12,
    opacity: 0.4,
  },
  paymentTypeImage: {
    height: 24,
    width: 24,
    borderRadius: 2,
    resizeMode: 'contain',
  },
  paymentTypeText: {
    fontFamily: 'Roboto-Regular',
    fontSize: 14,

    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.foxyBlack,
    marginLeft: 12,
  },
  paymentTypeTextSelected: {
    fontFamily: 'Roboto-Medium',
    fontWeight: '500',
    fontSize: 14,

    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.foxyBlack,
    marginLeft: 12,
  },
  rightArrow: {
    position: 'absolute',
    right: 20,
    tintColor: colors.foxyBlack,
  },
  cvvContainer: {
    width: 100,
    marginLeft: 24,
    overflow: 'hidden',
  },
  saveCardDetailsView: {
    marginTop: 16,
    height: 20,
    flexDirection: 'row',
    alignItems: 'center',
  },
  checkbox: {
    height: 16,
    width: 16,
    borderRadius: 2,
    borderWidth: 1,
    borderColor: colors.silver,
    marginRight: 8,
    justifyContent: 'center',
    alignItems: 'center',
  },
  checkboxImage: {
    height: 16,
    width: 16,
    borderRadius: 2,
    borderWidth: 1,
    borderColor: colors.foxyBlack,
    marginRight: 8,
    justifyContent: 'center',
    alignItems: 'center',
  },
  saveUpiContainer: {
    marginTop: 12,
    height: 20,
    flexDirection: 'row',
    alignItems: 'center',
  },
  upiTextFieldError: {
    fontFamily: 'Roboto-Regular',
    color: colors.foxyRed,
    marginTop: 2,
    fontSize: 12,
    alignSelf: 'flex-start',
  },
  upiTextField: {
    borderRadius: 4,
    paddingLeft: 8,
    borderColor: colors.border,
    borderWidth: 1,
    padding: 8,
    height: 45,
    color: colors.foxyBlack,
  },
  upiContainer: {
    flex: 1,
    backgroundColor: colors.white,
    borderRadius: 6,
    paddingBottom: 10,
    flexDirection: 'column',
    paddingLeft: Utility.largePadding,
    paddingRight: Utility.largePadding,
  },
  iosIntentOrText: { color: colors.silver },
  iosIntentOrTextContainer: {
    marginVertical: 5,
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
  },
  iosIntentImage: {
    height: 40,
    width: 40,
  },
  iosIntentConatiner: { width: 80 },
  iosIntentImageContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  iosIntentsContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    marginVertical: 10,
  },
  singleIosIntentOrTextContainer: {
    marginVertical: 8,
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
  },
  singleIosIntentOrText: { color: colors.silver },
  singleIosIntentText: {
    color: colors.foxyBlack,
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
    fontWeight: '500',
    textAlign: 'center',
  },
  singleIosIntentImage: {
    height: 28,
    width: 28,
  },
  singleIosIntentImageContainer: {
    height: 32,
    width: 32,
    borderRadius: 16,
    backgroundColor: 'white',
    alignItems: 'center',
    justifyContent: 'center',
  },
  singleIosIntentContainer: {
    borderWidth: 1,
    borderColor: colors.green,
    backgroundColor: colors.white,
    width: '100%',
    marginBottom: 0,
    height: 46,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
  },
  singleIosIntent: {
    width: '100%',
    marginTop: 16,
    marginBottom: 8,
  },
  androidIntentOrText: { color: colors.silver },
  androidIntentOrTextContainer: {
    marginVertical: 5,
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
  },
  androidIntentImage: {
    height: 40,
    width: 44,
  },
  androidIntentImageContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  androidIntentContainer: {
    width: 80,
  },
  androidIntent: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    marginVertical: 10,
  },
  saveCardText: {
    fontFamily: 'Roboto-Regular',
    fontSize: 12,

    fontStyle: 'normal',
    letterSpacing: 0,
  },
  saveUPIText: {
    fontFamily: 'Roboto-Regular',
    fontSize: 12,
    fontStyle: 'normal',
    letterSpacing: 0,
    color: '#606481',
  },
  actionButton: {
    position: 'absolute',
    right: 12,
    left: 12,
    bottom: 4,
    height: 30,
    borderRadius: 4,
    justifyContent: 'center',
    alignItems: 'center',
  },
  viewRoutineActionButton: {
    backgroundColor: colors.black,
    height: 40,
    borderRadius: 4,
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    marginTop: 20,
  },
  actionButtonText: {
    fontFamily: 'Roboto-Medium',
    fontSize: 16,
    fontStyle: 'normal',
    letterSpacing: 0,
    textAlign: 'center',
    color: colors.white,
  },
  checkboxTick: { height: 16, width: 16 },
  orderStatusImage: {
    height: 92,
    width: 92,
    marginTop: 12,
  },
  successUIContainer: {
    flexDirection: 'column',
    alignItems: 'center',
  },
  orderStatusText: {
    marginTop: 28,
    fontFamily: 'Roboto-Medium',
    fontSize: 18,
    fontStyle: 'normal',
    letterSpacing: 0,
  },
  orderStatusDetailText: {
    marginTop: 8,
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.silver,
    maxWidth: Utility.getScreenWidth() - 30,
    textAlign: 'center',
  },
  boughtItemImagesContainer: {
    flexDirection: 'row',
    height: 64,
    justifyContent: 'center',
    marginTop: 16,
  },
  freeGift: {
    height: 48,
    width: 48,
    marginLeft: 24,
    marginBottom: 8,
    justifyContent: 'center',
    marginTop: 16,
  },
  boughtProductImage: {
    height: 60,
    width: 60,
    marginLeft: 4,
    marginRight: 4,
    elevation: 2,
    shadowRadius: 1,
    shadowColor: colors.black,
    shadowOpacity: 0.1,
    shadowOffset: { width: 1, height: 1 },
    borderRadius: 4,
    borderWidth: 1,
    // resizeMode: 'contain',
    backgroundColor: colors.white,
    borderColor: colors.background,
  },
  orderArrivingStatusContainer: {
    flexDirection: 'row',
    height: 64,
    width: Utility.getScreenWidth() - 2 * Utility.largePadding,
    alignItems: 'center',
  },
  orderDeliveryImage: {
    height: 40,
    width: 40,
  },
  arrivingTextContainer: {
    marginLeft: 16,
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  currentAddressName: {
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.silver,
    maxWidth: Utility.getScreenWidth() - 30,
    textAlign: 'center',
    marginTop: null,
  },
  changeAddressText: {
    fontSize: 14,
    fontFamily: 'Roboto-Medium',
    color: colors.cta.lightBlue,
  },
  deliveryDateText: {
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.foxyBlack,
    marginRight: 12,
    maxWidth: Utility.getScreenWidth() / 1.5,
  },
  retryButton: {
    marginTop: 64,
    height: 45,
    width: 224,
    flexDirection: 'row',
    backgroundColor: colors.green,
    borderRadius: 4,
    justifyContent: 'center',
    alignItems: 'center',
  },
  netbankingHeader: {
    fontFamily: 'Roboto-Medium',
    fontSize: 14,
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.foxyBlack,
    marginTop: 12,
    marginBottom: 8,
  },
  popularBanksContainer: {
    height: 76,
    width: Utility.getScreenWidth(),
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: -16,
  },
  popularBankImageWithoutTint: {
    height: 40,
    width: 40,
    // borderRadius: 20,
    resizeMode: 'contain',
    overflow: 'hidden',
    tintColor: null,
  },
  popularBankImageWithTint: {
    height: 40,
    width: 40,
    // borderRadius: 20,
    resizeMode: 'contain',
    overflow: 'hidden',
    tintColor: colors.disabled,
  },
  popularBank: {
    width: Utility.getScreenWidth() / 5,
    justifyContent: 'center',
    alignItems: 'center',
    opacity: 1,
  },
  popularBankWithOpacity: {
    width: Utility.getScreenWidth() / 5,
    justifyContent: 'center',
    alignItems: 'center',
    opacity: 0.4,
  },
  popularBankTitle: {
    fontFamily: 'Roboto-Medium',
    fontSize: 12,
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.silver,
    marginTop: 6,
  },
  allBankText: {
    fontFamily: 'Roboto-Regular',
    fontSize: 14,

    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.foxyBlack,
  },
  popularBanksSection: {
    flexDirection: 'row',
    marginLeft: 0,
    marginBottom: 8,
    marginRight: 16,
  },
  price: {
    fontFamily: 'Roboto-Regular',
    fontSize: 16,

    fontStyle: 'normal',
    letterSpacing: 0,
    marginTop: 12,
    color: colors.foxyBlack,
    marginLeft: 16,
  },
  enterCvvContainer: {
    alignItems: 'center',
  },
  enterCvvText: {
    fontFamily: 'Roboto-Regular',
    fontSize: 16,

    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.foxyBlack,
    textAlign: 'left',
  },
  vpaInfo: {
    marginTop: 8,
    fontFamily: 'Roboto-Regular',
    fontSize: 12,

    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.subtitle,
    textAlign: 'left',
  },
  vpaContainer: {
    width: '100%',
  },
  cvvContainerForSavedCard: {
    width: 100,
    alignSelf: 'center',
  },
  submitButton: {
    marginTop: 8,
    height: 44,
    backgroundColor: colors.green,
    borderRadius: 4,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
  submitText: {
    fontFamily: 'Roboto-Medium',
    fontSize: 18,
    fontStyle: 'normal',
    letterSpacing: 0,
    textAlign: 'center',
    color: colors.white,
  },
  successAnimatedTickContainer: {
    flex: 1,
    backgroundColor: colors.white,
    borderRadius: 6,
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 100,
  },
  successAnimatedTick: {
    height: 300,
    width: 300,
    resizeMode: 'cover',
    alignSelf: 'center',
  },
  pendingPayment: {
    height: 200,
    width: 200,
    resizeMode: 'cover',
    alignSelf: 'center',
  },
  cardTextFieldLabel: {
    fontFamily: 'Roboto-Regular',
    fontSize: 14,

    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.foxyBlack,
    marginTop: 8,
    marginBottom: 4,
  },
  cardTextFieldInputContainer: {
    // borderColor: colors.silver,
    // borderWidth: 1,
    borderRadius: 4,
    paddingLeft: 8,
    borderBottomColor: colors.border,
    backgroundColor: '#F4f6f8',
    paddingTop: 2,
    height: 35,
  },
  cardLayoutContainer: {
    height: 52,
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 16,
    paddingRight: 16,
    backgroundColor: colors.white,
    width: '100%',
  },
  cardNumber: {
    marginTop: 16,
    fontFamily: 'Roboto-Medium',
    fontSize: size.h1,
    letterSpacing: 2,
    color: colors.foxyBlack,
  },
  bankName: {
    marginRight: 16,
    marginTop: 8,
    fontFamily: 'Roboto-Regular',
    fontSize: size.h3,
    color: colors.foxyBlack,
    alignSelf: 'flex-end',
  },
  subDetails: {
    fontFamily: 'Roboto-Regular',
    color: colors.foxyBlack,
    fontSize: size.h4,
  },
  expiryDateContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 4,
  },
  expiryDateLabel: {
    fontFamily: 'Roboto-Regular',
    color: colors.foxyBlack,
    fontSize: 8,
    maxWidth: 30,
  },
  expiryDate: {
    fontFamily: 'Roboto-Regular',
    color: colors.foxyBlack,
    fontSize: size.h3,
  },
  cardBrandLogoInSavedCard: {
    height: 32,
    width: 32,
    borderRadius: 2,
    resizeMode: 'contain',
    position: 'absolute',
    right: 16,
    bottom: 6,
  },
  textFieldContainer: {
    marginTop: 12,
  },
  textFieldWrapper: {
    backgroundColor: '#e4e4e4',
    padding: 4,
    borderRadius: 10,
    marginTop: 8,
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 16,
    paddingBottom: 16,
  },
  giftPopover: {
    width: Utility.getScreenWidth() - 32,
    height: 30,
    flexDirection: 'row',
    marginLeft: 16,
    // marginTop: -8,
    alignItems: 'center',
    paddingTop: 4,
    marginBottom: 4,
  },
  giftPopoverContainer: {
    height: 40,
    width: '100%',
    overflow: 'visible',
    backgroundColor: 'yellow',
  },
  giftPopoverText: {
    fontFamily: 'Roboto-Regular',
    color: colors.foxyBlack,
    fontSize: 11,
    marginLeft: 8,
  },
  codPayButtonContainer: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.white,
    paddingBottom: 10,
  },
  payWithCashButton: {
    height: 40,
    backgroundColor: colors.green,
    justifyContent: 'center',
    alignItems: 'center',
    width: Utility.getScreenWidth() - 40,
    borderRadius: 4,
  },
  cashActionButton: {
    fontFamily: 'Roboto-Regular',
    color: colors.white,
    fontSize: 16,
  },
  paymentProcessingText: {
    fontFamily: 'Roboto-Regular',
    color: colors.foxyBlack,
    fontSize: 13,
    alignSelf: 'center',
  },
  upgradeToPrepaidContainer: {
    flexDirection: 'column',
    backgroundColor: '#FFDFDF',
    paddingVertical: 16,
    marginVertical: 20,
    marginHorizontal: 10,
    justifyContent: 'space-between',
    borderRadius: 8,
  },
  foxySurpriseContainer: {
    alignItems: 'center',
    padding: 4,
    margin: 4,
  },
  foxySurpriseImage: {
    height: 92,
    width: 92,
  },
  upgradeToPrepaidTextContainer: {
    flexDirection: 'column',
    width: '100%',
  },
  upgradeToPrepaidHeading: {
    fontSize: 18,
    color: '#173143c4',
    marginBottom: 2,
    alignSelf: 'center',
    textAlign: 'center',
    fontFamily: 'Roboto-Medium',
  },
  upgradeToPrepaidSubHeading: {
    fontSize: 14,
    color: '#173143',
    alignSelf: 'center',
    fontFamily: 'Roboto-Regular',
    textAlign: 'center',
  },
  countDownTimer: {
    fontSize: 14,
    color: colors.foxyRed,
  },
  payOnlineButtonContainer: {
    backgroundColor: colors.green,
    borderRadius: 4,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 25,
    padding: 10,
  },
  payOnlineButtonText: {
    fontSize: 18,
    color: colors.white,
  },
  upgradeToPrepaidPromptContainer: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    backgroundColor: '#FFDFDF',
    padding: 8,
  },
  promptSurpriseImage: {
    height: 20,
    width: 20,
  },
  upgradeToPrepaidPromptTextContainer: {
    fontFamily: 'Roboto-Regular',
    fontSize: 14,

    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.foxyBlack,
  },
  savedCardCvvInputBox: {
    borderRadius: 4,
    borderWidth: 1,
    backgroundColor: colors.white,
    height: 28,
    width: 60,
    paddingLeft: 14,
    position: 'absolute',
    right: 20,
    padding: 0,
    color: colors.foxyBlack,
  },
  savedCardExpandedContainer: {
    backgroundColor: '#ffffff',
    paddingBottom: 20,
  },
  savedCardCvvErrorText: {
    color: colors.foxyRed,
    marginTop: 6,
    marginBottom: 6,
    marginLeft: 22,
    marginRight: 12,
    fontSize: 12,
    alignSelf: 'flex-start',
  },
  codNotAvailableTextStyle: {
    color: colors.foxyBlack,
    marginTop: 6,
    marginBottom: 6,
    marginLeft: 22,
    marginRight: 12,
    fontSize: 12,
    alignSelf: 'flex-start',
    fontFamily: 'Roboto-Medium',
  },
  preferredMethodsText: {
    color: colors.silver,
    marginVertical: 14,
    marginLeft: 16,
    fontSize: 14,
    fontFamily: 'Roboto-Regular',
    '@media (min-width: 768px)': {
      fontSize: 16,
      fontWeight: '800',
      marginLeft: 0,
      fontFamily: 'Roboto-Medium',
    },
  },
  safePaymentImage: {
    width: Utility.getScreenWidth() - 80,
    height: 80,
    resizeMode: 'contain',
    alignSelf: 'center',
    '@media (min-width: 800px)': {
      width: '100%',
    },
  },
  paymentPageOverlay: {
    flex: 1,
    position: 'absolute',
    height: Utility.getScreenHeight(),
    width: Utility.getScreenWidth(),
    backgroundColor: '#000000',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
    opacity: 0.5,
    zIndex: 1,
  },
  bottomComponent: {
    flex: 1,
    backgroundColor: colors.background,
  },
  createOrderButton: {
    marginTop: 12,
  },
  netBankingContainer: {
    flex: 1,
    backgroundColor: colors.white,
    borderRadius: 6,
  },
  netBankingExpanderContainer: {
    flexDirection: 'column',
    marginLeft: Utility.largePadding,
    marginRight: Utility.largePadding,
  },
  moreBanksTextContainer: {
    borderRadius: 4,
    paddingLeft: 8,
    borderColor: colors.border,
    borderWidth: 1,
    padding: 8,
    marginTop: 10,
    height: 45,
    justifyContent: 'center',
  },
  moreBanksText: {
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
    fontStyle: 'normal',
    color: colors.foxyBlack,
  },
  redirectingText: {
    fontStyle: 'normal',
    marginHorizontal: 6,
    marginTop: 10,

    fontFamily: 'Roboto-Regular',
    fontSize: 12,

    letterSpacing: 0,
  },
  paytmAmountContainer: {
    flexDirection: 'row',
    position: 'absolute',
    right: 20,
    bottom: 20,
  },
  paytmAmountText: {
    fontSize: 14,
    fontFamily: 'Roboto-Regular',
    color: colors.foxyBlack,
    marginRight: 8,
  },
  paytmChevron: {
    tintColor: colors.foxyBlack,
  },
  paytmExpandedContainer: {
    backgroundColor: '#ffffff',
    paddingBottom: 20,
  },
  nbButtonImage: {
    height: 24,
    width: 24,
    borderRadius: 2,
    resizeMode: 'contain',
  },
  upiImageStyle: {
    height: 24,
    width: 24,
  },
  addressContainer: {
    marginTop: 16,
    width: Utility.getScreenWidth(),
  },
  changeCtaContainer: {
    position: 'absolute',
    top: 16,
    right: 12,
  },
  addressSubContainer: {
    paddingLeft: 16,
    backgroundColor: colors.white,
  },
  changeCta: {
    fontSize: 14,
    fontFamily: 'Roboto-Medium',
    color: colors.cta.lightBlue,
  },
  bottomComponentStyle: {
    backgroundColor: colors.background,
    height: 30,
    width: Utility.getScreenWidth(),
    '@media (min-width: 800px)': {
      width: '100%',
      paddingHorizontal: 40,
    },
  },
});
