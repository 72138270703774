import React, { PureComponent } from 'react';
import withNavigation from '../../utils/WithNavigation';
import _, { upperFirst } from 'lodash';
import { connect } from 'react-redux';

import ScaleAnimate from '../shared/ScaleAnimate';
import Utility from '../../utils/Utility';

import {
  AnalyticsManager,
  EventType,
  EventParameterKey,
  AnalyticsUtilty,
} from '../../analytics';
import DynamicLinkManager from '../../utils/DynamicLinkManager';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import LargeTag from '../../containers/tag/LargeTag';
import SmallTag from '../../containers/tag/SmallTag';
import DefaultSizeTag from '../../containers/tag/DefaultSizeTag';
import { getTabsRoutes, navigateToScreen } from '../../utils/NavigationUtility';
import withProfiledNavigation from '../../utils/withProfiledNavigation';
import { getNavigationSource } from '../../utils/PerfUtility';

const TagsConfig = {
  SmallTag,
  LargeTag,
  DefaultSizeTag,
};

class TagCard extends PureComponent {
  constructor(props) {
    super(props);
    this.debouncedNavigate = _.debounce(this.navigate, 1000, {
      leading: true,
      trailing: false,
    });
    this.navigationSource = getNavigationSource(this, this.props);
  }

  handleRouteFromLink = (route, slug, path, extra) => {
    const { navigation } = this.props;
    //FIXME: HARDCODED ID MUST BE CHECKED AND REMOVED.
    if (getTabsRoutes().includes(route)) {
      navigation.navigate(route, { slug, extra, id: 290259 });
      return;
    }
    navigateToScreen({
      navigation,
      type: 'push',
      screen: route,
      navigationSource: this.navigationSource,
      params: { slug, extra, id: 290259 },
    });
  };

  navigate = () => {
    const {
      navigation,
      route,
      itemData,
      index,
      listId,
      layout,
      listName,
      listIndex,
      listContent,
      listSlug = '',
      authToken,
      search,
      previousScreen = '',
      parentListsData,
    } = this.props;

    const { destination, external_link } = itemData;

    const url = Utility.isPresent(external_link) ? external_link : destination;

    if (Utility.isPresent(itemData)) {
      let item_type =
        route.name === 'Store' ? 'product_category_tag' : 'media_category_tag';
      AnalyticsUtilty.fireItemClickEvent(
        previousScreen,
        itemData.id,
        item_type,
        itemData.name,
        index,
        listId,
        layout,
        listName,
        listIndex,
        '',
        '',
        listContent,
        '',
        itemData?.slug,
        listSlug,
        AnalyticsUtilty.getParentsList(parentListsData),
      );
    }
    let slug = itemData?.destination_url;
    const getLastIndexOfDot = slug?.lastIndexOf('.') + 1;
    const getLastFourChars = slug?.substring(getLastIndexOfDot, slug.length);
    if (getLastFourChars !== 'json') {
      slug = `${slug}.json`;
    }

    const analyticsMeta = {
      [EventParameterKey.PRODUCT_CATEGORY_ID]: itemData.id,
      [EventParameterKey.PRODUCT_CATEGORY_NAME]: itemData.name,
    };

    if (route.name === 'Store') {
      AnalyticsManager.logEvent(
        EventType.discoveryEvents.PRODUCT_CATEGORY_VIEW,
        analyticsMeta,
      );
    } else {
      AnalyticsManager.logEvent(
        EventType.discoveryEvents.MEDIA_CATEGORY_VIEW,
        analyticsMeta,
      );
    }
    if (Utility.isPresent(itemData?.destination)) {
      DynamicLinkManager.handleLinkWithInternalTrackingParams(
        url,
        this.handleRouteFromLink,
      );
      return;
    }

    if (search) {
      navigation.push(SCREEN_CONSTANTS.TAG, {
        display: 'screen',
        slug,
        name: itemData.name,
        id: itemData.id,
        navigatingFrom: route.name,
      });
      return;
    }
    navigation.push('Tag', {
      display: 'screen',
      slug,
      name: itemData.name,
      id: itemData.id,
      navigatingFrom: route.name,
    });
  };

  render() {
    const { itemData, size, listData } = this.props;
    const Tag = TagsConfig[`${upperFirst(size)}Tag`];
    if (!Tag) {
      return null;
    }
    return (
      <ScaleAnimate {...this.props} onPress={this.debouncedNavigate}>
        <Tag itemData={itemData} options={listData.options} />
      </ScaleAnimate>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.UserAccountInfo.authToken,
});

export default withProfiledNavigation(connect(mapStateToProps, null)(TagCard));
