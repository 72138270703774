import { Platform, NativeModules } from 'react-native';
import StringUtils from './StringUtils';
import Utility from './Utility';
import {
  REMOTE_CONFIG_KEYS,
  PERMISSION_STATUS,
  PERMISSION_CONSTANTS,
  CURRENCY_SYMBOL,
} from '../config/Constants';
import { ReactMoE } from '../libraries/ReactMoe';
import RemoteConfig from './RemoteConfig';

import NotificationManager from './NotificationsManager';
import {
  savePermissionStatus,
  getIOSNotificationPermission,
} from './PermissionsUtility';
import { jsonParser } from './JsonUtility';
import { createAndroidNotificationChannels } from './AndroidNotificationsUtils';
import { isAndroid, isIOS, isWeb } from './BooleanUtility';
import { getStoreRef } from '../store/StoreUtility';

export default class NotificationUtils {
  static previousNotificationPermissionState = '';

  static getCartReminderNotificationData = (
    cartReminderNotificationData,
    cartData,
  ) => {
    const {
      name: userName = 'Hey',
      cart_count: numItems = 0,
      total_cart_count: totalCartCount = 0,
      cart_value: cartValue = 0,
      cart_actual_total: cartDiscountedValue = 0,
      discount: cartDiscount = 0,
      gwp_count: gwpCount = 0,
      has_address: hasAddress = false,
      product_image: productImage = '',
    } = cartData;
    const {
      headings = [],
      behaviour = '',
      notification_info_phrase_1: notificationInfoPhrase1 = '',
      notification_info_phrase_2_1: notificationInfoPhrase21 = '',
      notification_info_phrase_2_2: notificationInfoPhrase22 = '',
      notification_info_phrase_3: notificationInfoPhrase3 = '',
    } = cartReminderNotificationData;
    let itemStr = numItems > 1 ? 'items' : 'item';
    const headingIndex = (new Date().getDay() % 2) + 2;
    const heading = headings[headingIndex]
      .replace('${name}', userName)
      .replace('${step_count}', hasAddress ? '2' : '1');
    const isSticky = behaviour === 'sticky';
    let phrase1 = '';
    let phrase2 = '';
    let phrase3 = '';
    let notificationInfo = '';
    phrase1 = notificationInfoPhrase1
      .replace('%{count_cart}', numItems + '')
      .replace('item(s)', itemStr)
      .replace('${to_pay}', cartDiscountedValue + '');
    if (cartDiscount < 1) {
      phrase1 = phrase1
        .replace('${cart_value}', '')
        .replace(` ${CURRENCY_SYMBOL}`, '')
        .replace(',', ' in your bag.');
    } else {
      phrase1 = phrase1.replace(
        '${cart_value}',
        StringUtils.strikeThrough(`${cartValue}`),
      );
    }

    itemStr = gwpCount > 1 ? 'gifts' : 'gift';
    if (gwpCount > 0) {
      phrase2 = notificationInfoPhrase22
        .replace('${gwp_count}', gwpCount + '')
        .replace('gift(s)', itemStr);
    } else if (cartDiscount > 0) {
      phrase2 = notificationInfoPhrase21.replace(
        '${cart_discount}',
        cartDiscount + '',
      );
    }
    phrase3 = notificationInfoPhrase3;

    notificationInfo = phrase1;
    if (cartDiscount < 1) {
      notificationInfo += phrase2;
    }
    notificationInfo += phrase3;
    const notificationData = {
      title: heading,
      description: notificationInfo,
      isSticky,
      extraData: {
        phrase1,
        phrase3,
        hasAddress,
        cartCount: `${totalCartCount}`,
        product_image: productImage,
      },
      displayAfter: 300,
      showInBackground: true,
      cartObject: cartData,
    };
    return notificationData;
  };

  static updateMoEngageChannelIfNotificationPermissionIsAllowed = () => {
    if (isIOS() || isWeb()) return;
    NativeModules.NotificationsChannelModule.checkIfNotificationPermissionsAllowed(
      (status) => {
        ReactMoE.pushPermissionResponseAndroid(status);
        // CleverTapAnalytics.subscribeToNotifications(status);
        createAndroidNotificationChannels();
      },
    );
  };

  static getRemoteConfigNotificationText = (state) => {
    const notificationTexts = jsonParser(
      RemoteConfig.getValue(
        Utility.isAndroid()
          ? REMOTE_CONFIG_KEYS.cart_notification_texts
          : REMOTE_CONFIG_KEYS.cart_notification_texts_with_one_timer,
      ),
    );

    return notificationTexts[state];
  };
}

export const getCurrentNotificationStatus = () => {
  const state = getStoreRef().getState();
  const { permissionStatus: { notification = 'not_asked' } = {} } = state;

  return PERMISSION_STATUS[notification];
};

export const getLiveActivityStatus = () => {
  const state = getStoreRef().getState();
  const {
    permissionStatus: {
      live_activity = Utility.isAndroid() ? false : true,
    } = {},
  } = state;

  return live_activity;
};

export const checkNotificationStatusFromNativeLayer = (
  previousStatus,
  callback = () => {},
) => {
  NativeModules.NotificationsChannelModule.notificationEnabledForApp()
    .then((status) => {
      ReactMoE.pushPermissionResponseAndroid(status);
      // CleverTapAnalytics.subscribeToNotifications(status);
      if (status) {
        savePermissionStatus(PERMISSION_CONSTANTS.notification, 'granted');
        callback('allowed');
      } else if (!status && previousStatus === 'granted') {
        savePermissionStatus(
          PERMISSION_CONSTANTS.notification,
          'never_ask_again',
        );
        callback('never_ask_again');
      } else {
        callback(previousStatus);
      }
    })
    .catch((err) => {
      console.tron.log(
        'Exception:checkNotificationStatusFromNativeLayer()',
        err,
      );
    });
};

export const checkAndroidNotificationStatusFromNativeLayer = (
  previousStatus,
  callback = () => {},
) => {
  NativeModules.NotificationsChannelModule.notificationEnabledForApp()
    .then((status) => {
      if (status) {
        callback('allowed');
      } else if (!status && previousStatus === 'granted') {
        callback('never_ask_again');
      } else {
        callback(previousStatus);
      }
    })
    .catch((err) => {
      console.tron.log(
        'Exception:checkNotificationStatusFromNativeLayer()',
        err,
      );
    });
};

export const getNotificationStatus = (callback) => {
  if (isAndroid()) {
    NativeModules.NotificationsChannelModule.notificationEnabledForApp()
      .then((status) => {
        let notificationStatus = 'never_ask_again';
        if (status) {
          notificationStatus = 'allowed';
        }
        callback(notificationStatus);
      })
      .catch((err) => {
        callback('status_fetch_error');
      });
  } else {
    getIOSNotificationPermission('AppNavigator', (permission) => {
      let status = 'not_asked';
      if (permission === 'NotificationNotDetermined') {
        status = 'not_asked';
      } else if (permission === 'NotificationProvisional') {
        status = 'provisional';
      } else if (permission === 'NotificationDenied') {
        status = 'never_ask_again';
      } else if (permission === 'NotificationAuthorized') {
        status = 'allowed';
      }
      callback(status);
    });
  }
};
