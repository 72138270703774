import React from 'react';
import { StyleSheet, View } from 'react-native';
import CartAndOffers from '../../containers/cart/CartAndOffers';
import Utility from '../../utils/Utility';
import ModalContainerWithoutSafeArea from './ModalContainerWithoutSafeArea';

const MyCartModal = ({ navigation }) => {
  const modalHeadingAndSubheading = {
    title: 'Items in your bag',
    body: 'Grab them before the deal expires',
  };

  const hideModal = () => {
    navigation.goBack();
  };

  return (
    <ModalContainerWithoutSafeArea>
      <View style={styles.modalContainer}>
        <View style={styles.cartOffersContainer}>
          <CartAndOffers
            itemData={modalHeadingAndSubheading}
            fromModal
            hideModal={hideModal}
            customStyles={styles.modalContent}
          />
        </View>
      </View>
    </ModalContainerWithoutSafeArea>
  );
};

const styles = StyleSheet.create({
  modalContainer: {
    justifyContent: 'flex-end',
    alignItems: 'center',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
  },
  cartOffersContainer: {
    width: Utility.getScreenWidth(),
    borderRadius: 6,
    backgroundColor: 'white',
    paddingBottom: 24,
    paddingTop: 20,
  },
  modalContent: { paddingVertical: 0, marginTop: 0 },
});

export default MyCartModal;
