import React from 'react';
import { View } from 'react-native';
import FoxyShadowButton from '../../lib/FoxyShadowButton';
import { PaymentStyles } from './styles';
import colors from '../../theme/Colors';
import { getScreenWidth } from '../../utils/LayoutUtility';

export default function MIPSPayment(props) {
  const CreateOrderAndPay = () => {
    const { createOrder } = props;

    const paymentPayload = {
      paymentGateway: 'mips',
      paymentMethod: 'mips',
      extraData: {
        method: 'card',
      },
      payments: 'v2',
    };

    createOrder(paymentPayload);
  };

  const styles = PaymentStyles;
  return (
    <View style={styles.upiContainer}>
      <View styles={styles.enterCvvContainer}>
        <FoxyShadowButton
          width={getScreenWidth() - 34}
          title={'Pay'}
          onPress={CreateOrderAndPay}
          backgroundColor={colors.primaryActionBackgroundColor}
          style={styles.createOrderButton}
          firstColor={colors.linearGradientGreenFirst}
          secondColor={colors.linearGradientGreenSecond}
        />
      </View>
    </View>
  );
}
