import React, { PureComponent } from 'react';
import { View, StyleSheet, Image, TouchableOpacity } from 'react-native';
import Modal from 'react-native-modal';
import {
  AnalyticsManager,
  EventParameterKey,
  EventParameterValue,
  EventType,
} from '../../analytics';
import FastImageView from '../FastImageView';
import BottomSheet from '../onboarding/BottomSheet';
import {
  REMOTE_CONFIG_KEYS,
  REQUEST_INVITE_MODAL,
} from '../../config/Constants';
import NavigationService from '../../navigator/NavigationService';
import colors from '../../theme/Colors';
import images from '../../theme/Images';
import RemoteConfig from '../../utils/RemoteConfig';
import Utility from '../../utils/Utility';
import ModalContainerWithoutSafeArea from './ModalContainerWithoutSafeArea';
import EnterInviteCodeView from '../../containers/Invite/EnterInviteCodeView';
import InviteCodeInfoModalView from '../../containers/Invite/InviteCodeInfoModalView';
import RequestInviteView from '../../containers/Invite/RequestInviteView';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';

export default class RequestInviteModal extends PureComponent {
  constructor(props) {
    super(props);
    this.modalViews = {
      REQEUEST_INVITE_VIEW: RequestInviteView,
      INVITE_CODE_INFO_VIEW: InviteCodeInfoModalView,
      ENTER_COUPON_CODE_VIEW: EnterInviteCodeView,
    };
    this.inviteCenterStrings = Utility.jsonParser(
      RemoteConfig.getValue(REMOTE_CONFIG_KEYS.invite_center_strings),
    );
  }

  hideModal = (skipEvent = false) => {
    if (!skipEvent) {
      AnalyticsManager.logEvent('modal_action', {
        modal_name: 'how_to_become_eligible',
        previousScreen: 'loyalty_plans',
        cta: 'view_other_options',
      });
    }
    const { navigation } = this.props;
    navigation.goBack();
  };

  onPressInviteContacts = () => {
    const { route } = this.props;
    const { itemData = {}, itemData: { id, duration } = {} } = route.params;

    this.hideModal();

    this.fireModalActionEven(
      EventParameterValue.GROUP_DEALS_REFERRALS.INVITE_CONTACTS,
    );

    setTimeout(() => {
      NavigationService.navigate('InviteContacts', {
        itemData,
      });
    }, 400);
  };

  openRequestInviteModal = (
    previousScreen = SCREEN_CONSTANTS.PRODUCT_DETAIL,
    currentView,
  ) => {
    const { navigation, route } = this.props;
    const { itemData } = route.params;
    navigation.navigate('RequestInviteModal', {
      itemData,
      previousScreen,
      referralModalType: 'invite_request_modal',
      fromInviteCenter: true,
      currentView,
    });
  };

  onPressCouponCode = () => {
    AnalyticsManager.logEvent('modal_action', {
      modal_name: 'invite_request_modal',
      previousScreen: 'loyalty_plans',
      cta: 'i_have_an_invite_code',
    });
    this.hideModal();
    this.openRequestInviteModal(
      'loyalty_plans',
      REQUEST_INVITE_MODAL.screens.ENTER_COUPON_CODE_VIEW,
    );
  };

  onPressHowToBecomeEligible = () => {
    AnalyticsManager.logEvent('modal_action', {
      modal_name: 'invite_code',
      previousScreen: 'loyalty_plans',
      cta: 'how_to_become_eligible',
    });
    this.hideModal();
    setTimeout(() => {
      this.openRequestInviteModal(
        'loyalty_plans',
        REQUEST_INVITE_MODAL.screens.INVITE_CODE_INFO_VIEW,
      );
    }, 400);
  };

  onPressViewOtherOptions = () => {
    this.hideModal();
  };

  onPressActivateMembership = () => {};

  getModalName = () => {
    const { route } = this.props;
    const { referralModalType = '' } = route.params;
    switch (referralModalType) {
      case 'invite_request_modal':
        return 'invite_request_modal';
      default:
        return 'invite_request_modal';
    }
  };

  fireModalActionEven = (cta) => {
    AnalyticsManager.logEvent(EventType.MODAL_ACTION, {
      [EventParameterKey.MODAL_NAME]: this.getModalName(),
      [EventParameterKey.CTA]: cta,
      previousScreen: 'loyalty_plans',
    });
  };

  keyExtractor = (item, index) => index;

  renderCouponCodeView = () => {
    const { route } = this.props;
    const { fromInviteCenter } = route.params;
    return (
      <>
        <BottomSheet
          onPressOutside={this.hideModal}
          containerStyle={styles.modalContainer}
        >
          <EnterInviteCodeView
            onPressInviteContacts={this.onPressInviteContacts}
            onPressCouponCode={this.onPressCouponCode}
            onPressHowToBecomeEligible={this.onPressHowToBecomeEligible}
            onPressViewOtherOptions={this.onPressViewOtherOptions}
            onPressActivateMembership={this.onPressActivateMembership}
            inviteCenterStrings={this.inviteCenterStrings}
            toggleModal={this.hideModal}
            fromInviteCenter={fromInviteCenter}
          />
          <this.modalCloseButton />
        </BottomSheet>
      </>
    );
  };

  modalCloseButton = () => {
    return (
      <View style={styles.crossContainer}>
        <TouchableOpacity
          onPress={this.hideModal}
          hitSlop={Utility.getHitSlop('birthDateCross')}
        >
          <FastImageView
            source={images.edge.modal_close}
            style={styles.cross}
          />
        </TouchableOpacity>
      </View>
    );
  };

  renderModal = () => {
    const { route } = this.props;
    const {
      fromInviteCenter = false,
      currentView = REQUEST_INVITE_MODAL.screens.REQEUEST_INVITE_VIEW,
    } = route.params;
    const ContainerComponent = this.modalViews[currentView];
    return (
      <ModalContainerWithoutSafeArea>
        <View style={styles.bottomModal}>
          <ContainerComponent
            onPressInviteContacts={this.onPressInviteContacts}
            onPressCouponCode={this.onPressCouponCode}
            onPressHowToBecomeEligible={this.onPressHowToBecomeEligible}
            onPressViewOtherOptions={this.onPressViewOtherOptions}
            onPressActivateMembership={this.onPressActivateMembership}
            inviteCenterStrings={this.inviteCenterStrings}
            toggleModal={this.hideModal}
            fromInviteCenter={fromInviteCenter}
          />
          <this.modalCloseButton />
        </View>
      </ModalContainerWithoutSafeArea>
    );
  };

  render() {
    const { route } = this.props;
    const { currentView = REQUEST_INVITE_MODAL.screens.REQEUEST_INVITE_VIEW } =
      route.params;
    if (currentView === REQUEST_INVITE_MODAL.screens.ENTER_COUPON_CODE_VIEW) {
      return <this.renderCouponCodeView />;
    }
    return <this.renderModal />;
  }
}

const styles = StyleSheet.create({
  modalContainer: {
    width: '100%',
    height: '100%',
    backgroundColor: colors.translucent,
  },
  bottomModal: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    marginBottom: 0,
    marginRight: 0,
    marginLeft: 0,
    paddingHorizontal: 12,
    paddingVertical: 24,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    overflow: 'hidden',
    backgroundColor: colors.white,
    borderTopLeftRadius: 25,
    borderTopRightRadius: 25,
  },
  crossContainer: {
    top: 24,
    right: 16,
    zIndex: 1,
    position: 'absolute',
  },
  cross: {
    height: 23,
    width: 23,
    tintColor: colors.black,
  },
});
