const APLUS_CONTENT_CUSTOM_CSS_INJECTED = `

var styleElement = document.createElement('style');
styleElement.innerHTML = \`

  /* Header and Footer Removal */
  HEADER_FOOTER {
    display: none !important;
  }


  /* Carousel Removal */
  CAROUSEL {
    display: none !important;
  }


  /* Ratings and Reviews Removal */
  SECTION_SELECTOR[id*="heading"]:has(+ SECTION_SELECTOR * REVIEWS_RATING)  {
    display: none !important;
  }
  SECTION_SELECTOR:has(REVIEWS_RATING) {
    display: none !important;
  }


  /* Product Lists Removal With Titles */
  PRODUCT_LIST {
    display: none !important;
  }


  /* Extracting Product Description from Product Info */
  PRODUCT_INFO > * {
    display: none !important;
  }
  PRODUCT_INFO > .PRODUCT_DESCRIPTION {
    display: block !important;
  }


  /* Miscellaneous Elements Removal */
  OTHER_SELECTORS {
    display: none !important;
  }

  /* Override Background Color */
  BACKGROUND_COLOR_SELECTORS {
    background: BACKGROUND_COLOR;
  }

\`;

document.head.appendChild(styleElement);

const mainContent = document.querySelector('MAIN_CONTENT_SELECTOR');

const informReactNative = () => {
    window?.ReactNativeWebView?.postMessage(JSON.stringify({height: mainContent.clientHeight}));
}

new ResizeObserver(informReactNative).observe(mainContent)

window?.ReactNativeWebView?.postMessage(JSON.stringify({height: mainContent.clientHeight}));
`;

export default APLUS_CONTENT_CUSTOM_CSS_INJECTED;
