import React, { memo, Component } from 'react';
import { connect } from 'react-redux';
import { View, Text } from 'react-native';
import _ from 'lodash';
import styles from './styles';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import DebouncedTouchableOpacity from '../../components/shared/DebouncedTouchableOpacity';
import { CURRENCY_SYMBOL } from '../../config/Constants';
import {
  AnalyticsManager,
  EventParameterKey,
  EventType,
} from '../../analytics';
import applyOffer from '../../hoc/applyOffer';
import OffersUtil from '../../utils/OffersUtil';
import withProfiledNavigation from '../../utils/withProfiledNavigation';
import { getNavigationSource } from '../../utils/PerfUtility';

class CartBuilderSummary extends Component {

  constructor(props) {
    super(props)
    this.navigationSource = getNavigationSource(this, this.props);
  }

  openCart = () => {
    const {
      navigation,
      source = 'cart_tray',
      offersData: { coupon_code } = {},
    } = this.props;
    navigation.push(this.navigationSource, 'Cart', {
      source,
    });
    AnalyticsManager.logEvent(EventType.discoveryEvents.ITEM_ACTION, {
      [EventParameterKey.COUPON_CODE]: coupon_code,
      [EventParameterKey.ACTION]: 'view_bag',
    });
  };

  onPromptClick = (cta_action, coupon_code) => {
    const { applyCartCoupon = () => {} } = this.props;
    if (Utility.isBlank(cta_action)) {
      return;
    }
    if (cta_action === 'apply_offer') {
      applyCartCoupon(coupon_code);
    }
  };

  promptStrip = () => {
    const {
      cartItems,
      cartOffers,
      offersData: {
        id = '',
        combo_offer_details: {
          quantity_required: quantities = {},
          max_items: maxItems,
        } = {},
        quantity_caps_rules: {
          sku_level_quantity_cap = '',
          offer_level_quantity_cap = '',
        } = {},
        coupon_code = '',
      } = {},
      itemData = {},
      offerSkuIds = {},
      applyCartCoupon = () => {},
    } = this.props;

    const offer = _.find(cartOffers, (e) => {
      return e.offer_id === id;
    });

    let skuCapReached = false;

    const filteredCartItems = cartItems.reduce(
      (filteredCartItems, cartItem) => {
        if (offerSkuIds[parseInt(itemData.id)]?.includes(cartItem.sku_id)) {
          filteredCartItems.push(cartItem);
        }
        return filteredCartItems;
      },
      [],
    );
    let quantityRequired = 0;

    Object.entries(quantities).map(([key, value]) => {
      quantityRequired += value;
    });

    if (Object.entries(quantities).length > 1) {
      quantityRequired = quantities[`${itemData.id}`];
    }

    let quantity = 0;
    _.each(filteredCartItems, (e) => {
      if (
        e.quantity > sku_level_quantity_cap &&
        Utility.isPresent(sku_level_quantity_cap)
      ) {
        skuCapReached = true;
      }
      return (quantity += e.quantity);
    });

    const showStrip =
      quantity > quantityRequired &&
      quantity <= maxItems &&
      maxItems !== quantityRequired;

    let styleForBottomStrip = styles.stripContainer;

    if (['redeemed', 'partially_redeemed'].includes(offer?.status)) {
      styleForBottomStrip = styles.stripContainerRedeemed;
    }

    const { message, bgColor, cta_text, cta_action } =
      OffersUtil.getComboOfferPrompt(
        quantity,
        quantityRequired,
        maxItems,
        skuCapReached,
        sku_level_quantity_cap,
        offer_level_quantity_cap,
        showStrip,
        ['redeemed', 'partially_redeemed'].includes(offer?.status),
        Object.entries(quantities).length,
      );

    if (Utility.isBlank(message)) {
      return null;
    }

    const replacedMessage = message
      .replace(
        '${offer_level_quantity_cap}',
        offer_level_quantity_cap?.toString() || '',
      )
      .replace(
        '${sku_level_quantity_cap}',
        sku_level_quantity_cap?.toString() || '',
      )
      .replace(
        '${quantity_required_to_complete_combo}',
        (quantityRequired - quantity)?.toString() || '',
      );

    let promptStyle = _.memoize(
      () => [styleForBottomStrip, { backgroundColor: bgColor }],
      () => [bgColor],
    )();

    if (Utility.isPresent(cta_text)) {
      promptStyle = [
        promptStyle,
        {
          paddingVertical: 16,
          paddingBottom: Utility.isIOS() ? 36 : 16,
        },
      ];
    }

    return (
      <>
        <DebouncedTouchableOpacity
          {...this.props}
          style={promptStyle}
          activeOpacity={1}
          onPress={_.memoize(
            () => this.onPromptClick(cta_action, coupon_code),
            () => [cta_action, coupon_code],
          )}
        >
          <Text style={styles.stripText}>{replacedMessage}</Text>

          {Utility.isPresent(cta_text) && (
            <Text style={styles.stripTextCta}>{cta_text}</Text>
          )}
        </DebouncedTouchableOpacity>
      </>
    );
  };

  render() {
    const {
      cartItems,
      cartOffers,
      offersData: { id = '' },
      itemData = {},
      offersData,
      coupon_codes,
      showStrip = false,
    } = this.props;

    if (Utility.isBlank(offersData) || !offersData?.combo_offer) {
      return null;
    }

    const offer = _.find(cartOffers, (e) => {
      return e.offer_id === id;
    });

    if (showStrip) {
      return <this.promptStrip />;
    }

    if (Utility.isBlank(offer)) {
      return null;
    }

    if (!['redeemed', 'partially_redeemed'].includes(offer.status)) {
      return null;
    }

    const filteredCartItems = cartItems.filter((cartItem = {}) => {
      return (
        cartItem?.possible_offer_list_ids?.includes(parseInt(itemData?.id)) ||
        cartItem?.participating_discount_offer_id === id ||
        cartItem?.offer_id === id
      );
    });

    const couponDiscount = _.find(coupon_codes, (e) => {
      return e.offer_id === offer.offer_id;
    });

    const {
      combo_offer_details: {
        mrp_sum: mrp,
        sp_sum: sp,
        percentage: discount,
        show_discount_percentage = true,
      } = {},
    } = couponDiscount || {};

    if (Utility.isBlank(filteredCartItems)) {
      return null;
    }

    const {
      combo_offer_details: {
        quantity_required: quantities = {},
        max_items: maxItems,
      } = {},
    } = offer || {};

    let quantityRequired = 0;

    Object.entries(quantities).map(([key, value]) => {
      quantityRequired += value;
    });

    if (Utility.isBlank(quantityRequired) || Utility.isBlank(maxItems)) {
      return null;
    }

    const stripStyle = styles.cartTrayContainerOfferContainer;

    return (
      <View style={stripStyle}>
        <View style={styles.cartTrayContainerOffer}>
          <View>
            <View style={styles.cartTrayContentContainer}>
              <Text style={styles.cartTraySp}>{`${CURRENCY_SYMBOL} ${sp}`}</Text>
              {parseInt(discount) >= 0 && (
                <Text
                  style={styles.cartTrayMrp}
                >{`${CURRENCY_SYMBOL} ${mrp}`}</Text>
              )}
            </View>
            {show_discount_percentage && parseInt(discount) >= 0 && (
              <Text style={styles.cartTrayOff}>{`(${discount}% OFF)`}</Text>
            )}
          </View>

          <DebouncedTouchableOpacity
            {...this.props}
            style={styles.viewBagButton}
            onPress={this.openCart}
            hitSlop={Utility.getHitSlop()}
          >
            <Text style={styles.viewBagButtonText}>View Bag</Text>
          </DebouncedTouchableOpacity>
        </View>
      </View>
    );
  }
}

const mapStateToProps = (state) => ({
  cartItems: state.bag.cartItems,
  cartOffers: state.bag.cartPrompts,
  coupon_codes: state.bag.cartPricing.coupon_codes,
});

export default applyOffer(
  withProfiledNavigation(
    connect(mapStateToProps, null)(memo(CartBuilderSummary)),
  ),
);
