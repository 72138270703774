import React, { PureComponent } from 'react';
import {
  View,
  Image,
  Text,
  Animated,
  Vibration,
  ActivityIndicator,
} from 'react-native';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import _, { debounce, findKey } from 'lodash';
import Toast from 'react-native-easy-toast';
import LinearGradient from 'react-native-linear-gradient';
import { withMaybe, withEither } from '../../lib/Monads';
import {
  addToCart,
  removeFromCart,
  viewItem,
  addFreeItemsToCart,
  removeFreeItemToCart,
  incrementDecrementCart,
  incrementDecrementCartBeforeCall,
  getCartItems,
  renderOfferShimmers,
  like,
  addAddedToCartProduct,
  applyCartCoupon,
  removeOffer,
  getCartPricing,
} from '../../actions/ActionTypes';
import {
  LAYOUT,
  THEME,
  REMOTE_CONFIG_KEYS,
  EDGE_MODAL_TYPES,
  EMPTY_ARRAY,
  EMPTY_OBJECT,
} from '../../config/Constants';
import ProductCardStyles from './styles/styles';
import images from '../../theme/Images';
import { PRODUCT } from '../../config/LayoutConstants/ProductConfig';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import { ProductDetailStyles, ProductInCartStyles } from './styles';
import colors, { Theme } from '../../theme/Colors';
import {
  EventParameterKey,
  AnalyticsManager,
  EventType,
} from '../../analytics';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import FoxyAlert from '../camera/shared/FoxyAlert';

import BarLoader from '../../lib/BarLoader';
import RemoteConfig from '../../utils/RemoteConfig';
import RoundedButton from '../layout/buttons/RoundedButton';
import NetworkStats from '../../utils/NetworkStat';
import AnimatedLikeButton from '../shared/AnimatedLikeButton';
import DebouncedTouchableOpacity from '../shared/DebouncedTouchableOpacity';
import OffersUtil from '../../utils/OffersUtil';
import Config from '../../libraries/ReactNativeConfig';
import FastImageView from '../FastImageView';
import DynamicLinkManager from '../../utils/DynamicLinkManager';
import FullWidthShimmer from '../../lib/FullWidthShimmer';
import AppConfig from '../../config/AppConfig';
import VariantUtility from '../../utils/VariantUtility';
import AnalyticsUtility from '../../analytics/AnalyticsUtility';
import { isBlank, isPresent, isNative, isWeb } from '../../utils/BooleanUtility';
import withProfiledNavigation from '../../utils/withProfiledNavigation';
import { getNavigationSource } from '../../utils/PerfUtility';
import { getStoreRef } from '../../store/StoreUtility';

class AddToCart extends PureComponent {
  // NOTE: state variable with prev is used for optimistic update, as state is changed instantaneously on
  // user initiation, while actual data from api is handled in get derivedStatefromprops
  constructor(props) {
    super(props);
    this.addedToCart = props.addedToCart;
    this.addedToCartForCard = props.addedToCartForCard;
    this.state = {
      addedToCart: this.addedToCart,
      prevAddedToCard: this.addedToCart,
      addedToCartForCard: this.addedToCartForCard,
      prevAddedToCartForCard: this.addedToCartForCard,
      leftPosition: new Animated.Value(0),
      isAnimating: false,
      isRemoveProductConfirmationAlertVisible: false,
      notClubbableCouponCode: '',
      notClubbableOfferId: '',
      notClubbableWith: {},
      errorMessage: '',
      unfulfilledCoupon: {},
      successfullyAppliedCoupon: {},
      errors: false,
    };
    this.checkoutMeta = Utility.jsonParser(
      RemoteConfig.getValue(REMOTE_CONFIG_KEYS.checkout_new),
    );

    this.debouncedAddProductOrShowShade = _.debounce(
      (skuId) => {
        this.checkToAddProductOrShowShade(skuId);
      },
      500,
      { leading: true, trailing: false },
    );

    if (!!props?.inModal) {
      this.checkoutMeta = {
        change_add_to_cart_button: false,
        button_name: 'Add to cart',
        navigate_after_add_to_cart: false,
        address_before_cart: false,
        cart_priority: 1,
        payment_priority: 0,
        address_before_cart_for_added_products: false,
      };
    }
    this.debouncedAddToCart = _.debounce(
      this.incrementProductInCartAndFireAnalytics,
      1000,
      {
        leading: true,
        trailing: false,
      },
    );

    this.debouncedRemoveFromCart = _.debounce(
      this.decrementProductInCartAndFireAnalytics,
      1000,
      {
        leading: true,
        trailing: false,
      },
    );

    this.debouncedUpdateCart = _.debounce(this.updateCart, 500, {
      leading: true,
      trailing: false,
    });

    this.rectangularBagButtonStyleCache = {};
    this.rectangularBagButtonTextCache = {};
    this.incrementHitSlop = Utility.getHitSlop('quantityPlus');
    this.decrementHitSlop = Utility.getHitSlop('quantityMinus');
    this.memoizedAddProductOrShowShadeCache = {};
    this.memoizedAddProductOrShowCartCache = {};
    const { layout, isFancyCard } = this.props;
    this.roundBagButtonImageStyle = [
      ProductCardStyles[`${layout}AddToCartImage`],
      {
        tintColor: isFancyCard
          ? colors.white
          : Config.ROUNDED_ADD_TO_CART_COLOR,
      },
    ];
    this.roundedBagButtonAddedImageStyle = [
      ProductCardStyles[`${layout}AddToCartImage`],
      {
        tintColor: isFancyCard
          ? colors.white
          : Config.ROUNDED_ADDED_TO_CART_COLOR,
      },
    ];
    this.memoizedAddToCartPressFromCartCache = {};
    this.navigationSource = getNavigationSource(this, this.props);
  }

  static getDerivedStateFromProps = (props, state) => {
    if (
      props.addedToCart !== state.prevAddedToCard ||
      props.addedToCartForCard !== state.prevAddedToCartForCard ||
      props.addedToCart !== state.addedToCart
    ) {
      return {
        addedToCart: props.addedToCart,
        prevAddedToCard: props.addedToCart,
        addedToCartForCard: props.addedToCartForCard,
        prevAddedToCartForCard: props.addedToCartForCard,
      };
    }
    // Return null if the state hasn't changed
    return null;
  };

  loadAddToCartAnimation = (isLoading) => {
    this.setState({
      isAnimating: isLoading,
    });
  };

  verticalRailOrPersonalisedRailCondition = (props) =>
    !(
      props.layout === LAYOUT.VERTICALRAIL ||
      props.layout === LAYOUT.PERSONALISEDRAIL
    );

  conditionalText = withMaybe(this.verticalRailOrPersonalisedRailCondition)(
    Text,
  );

  addToCartCallback = (response, data) => {
    const { auto_applied_coupon_message } = response;
    const {
      errorCallback,
      showToast,
      refreshOfferStrip,
      refreshOffersDetailsPageDiscountStrip,
      getCartItems,
      setUnfulfilledCoupon,
      incrementDecrementCartBeforeCall,
    } = this.props;
    getCartItems(() => {}, true);

    if (response.condition_unfullfilled) {
      setUnfulfilledCoupon(json);
      this.setState({
        errors: true,
      });
    } else if (Utility.isPresent(response.modal_data)) {
      this.setState({
        addedToCartForCard: false,
      });
      const newData = { ...data, quantity: -1 * data.quantity };
      incrementDecrementCartBeforeCall(newData);
    } else if (
      Utility.isPresent(response.errors) &&
      Utility.isPresent(response.errors[0])
    ) {
      if (this.getCartQuantityForVariant() < 1) {
        this.setState({
          addedToCartForCard: false,
        });
      }
      getCartItems(() => {}, true);
      if (showToast) {
        showToast(response.errors[0]);
      }
    }
    if (errorCallback !== undefined && errorCallback !== null) {
      errorCallback(response);
    }

    if (showToast && auto_applied_coupon_message) {
      showToast(auto_applied_coupon_message);
      AnalyticsManager.logEvent(EventType.discoveryEvents.COUPON_TOAST, {
        [EventParameterKey.MESSAGE]: auto_applied_coupon_message,
      });
    }
  };

  addItemToCart = (data) => {
    const { onItemAddToCartFromCollab, addToCart, offer_id } = this.props;

    this.setState({
      addedToCart: true,
      addedToCartForCard: true,
    });
    addToCart(updatedData, (response) =>
      this.addToCartCallback(response, data),
    );
    if (onItemAddToCartFromCollab) {
      onItemAddToCartFromCollab();
    }
  };

  changeItemInCart = (data) => {
    const {
      onItemAddToCartFromCollab,
      setPromptLoading,
      incrementDecrementCart,
      incrementDecrementCartBeforeCall,
      renderOfferShimmers = () => {},
      itemData = {},
      offer_id,
      setUnFullfilledCoupon,
      removeUnFullfilledCoupon,
      inModal,
      setSuccessfullyAppliedCoupon,
      getCartItems,
      cta_position_bottom = '0',
    } = this.props;
    this.loadAddToCartAnimation(true);
    renderOfferShimmers(true);

    setTimeout(() => {
      renderOfferShimmers(false);
      this.loadAddToCartAnimation(false);
    }, 4000);

    if (typeof setPromptLoading === 'function') setPromptLoading();
    this.setState({
      addedToCart: true,
      addedToCartForCard: true,
    });

    const flashDealData = {
      type: 'Product',
      id: itemData?.id,
    };

    data.flashDealData = flashDealData;
    if (Utility.isPresent(offer_id)) {
      data.offer_id = offer_id;
    }

    incrementDecrementCart(data, (response) => {
      renderOfferShimmers(false);

      if (inModal) {
        getCartItems();
      }

      this.loadAddToCartAnimation(false);
      if (response?.condition_unfullfilled) {
        setUnfulfilledCoupon(response);
        return;
      }
      if (response?.clubbable === false) {
        removeUnFullfilledCoupon();
        setTimeout(() => {
          this.offerNotClubbable(
            response.coupon_code,
            response.offer_id,
            response.offers_prompts,
          );
        }, 500);

        return;
      }
      if (response && inModal) {
        removeUnFullfilledCoupon();
        const appliedCoupon = response?.prompts?.find(
          (couponCode) => couponCode.coupon_code === offer_id,
        );
        setSuccessfullyAppliedCoupon(appliedCoupon);
        return;
      }
      this.addToCartCallback(response, data);
    });
    if (onItemAddToCartFromCollab) {
      onItemAddToCartFromCollab();
    }
  };

  addToCartDesignConstants = {
    textColor: {
      true: colors.foxyPink,
      false: colors.foxyBlack,
    },
    borderColor: {
      true: colors.borderDark,
      false: colors.foxyBlack,
    },
  };

  addFreeItemsToBag = () => {};

  setSuccessfullyAppliedCoupon = (data) => {
    this.setState({
      successfullyAppliedCoupon: data,
    });
  };

  removeSuccessfullyAppliedCoupon = () => {
    this.setState({
      successfullyAppliedCoupon: {},
    });
  };

  navigateToUnfulfilledModal = () => {
    const { navigation } = this.props;
    const { unfulfilledCoupon } = this.state;
    if (isBlank(unfulfilledCoupon)) return;
    navigation.navigate(SCREEN_CONSTANTS.UNFULFILLED_OFFER_MODAL, {
      navigation,
      itemData: unfulfilledCoupon,
      navigateToOfferDetail: this.onViewProductsTap,
    });
  };

  setUnfulfilledCoupon = (data) => {
    this.setState(
      {
        unfulfilledCoupon: data,
      },
      this.navigateToUnfulfilledModal,
    );
  };

  navigateToClubbableModal = () => {
    const { navigation } = this.props;
    const { notClubbableCouponCode, notClubbableOfferId, notClubbableWith } =
      this.state;
    navigation.navigate(SCREEN_CONSTANTS.CLUBBABLE_MODAL, {
      notClubbableCouponCode,
      navigation,
      notClubbableOfferId,
      notClubbableWith,
      applyOffer: this.forceApplyOffer,
    });
  };

  offerNotClubbable = (
    notClubbableCouponCode,
    notClubbableOfferId,
    notClubbableWith,
  ) => {
    this.setState(
      {
        notClubbableCouponCode,
        notClubbableOfferId,
        notClubbableWith,
      },
      this.navigateToClubbableModal,
    );
  };

  forceApplyOfferCallback = (success, callbackData = {}) => {
    const { renderOfferShimmers, previousScreen } = this.props;
    renderOfferShimmers(false);

    if (Utility.isBlank(callbackData)) {
      this.setState({
        errors: true,
      });
      return;
    }

    if (success) {
      AnalyticsManager.logEvent('offer_applied', {
        source: previousScreen,
        coupon_code: coupon,
        offer_id: callbackData.offer_id,
        offer_type: 'offer_coupon',
        offer_applied_by: 'user_applied',
      });
      AnalyticsManager.logEvent(EventType.artistEvents.MODAL_VIEW, {
        modal_name: 'success_offer',
        offer_id: callbackData?.offer_id,
      });
      this.setSuccessfullyAppliedCoupon(callbackData);
    } else if (callbackData?.clubbable === false) {
      const offerPrompts = [];
      const {
        coupon_code = '',
        offer_id = '',
        offers_prompts = {},
      } = callbackData;
      _.forEach(offers_prompts, (e = {}) => {
        const { offer_id: offer_prompt_id = '' } = e;
        offerPrompts.push(offer_prompt_id);
      });
      AnalyticsManager.logEvent('modal_view', {
        modal_name: 'replace_offer',
        new_offer_id: callbackData.offer_id,
        old_offer_ids: offerPrompts.toString(),
      });
      this.offerNotClubbable(coupon_code, offer_id, offers_prompts);
    } else if (!!callbackData?.condition_unfullfilled) {
      AnalyticsManager.logEvent(EventType.artistEvents.MODAL_VIEW, {
        modal_name: 'unfulfilled_offer',
        offer_id: callbackData?.offer_id,
      });
      this.setUnfulfilledCoupon(callbackData);
    } else {
      this.setState({
        errorMessage: `${callbackData?.errors[0] || ''} `,
      });
    }
  };

  forceApplyOffer = (code, id) => {
    const { applyCartCoupon, renderOfferShimmers, showToast } = this.props;

    renderOfferShimmers(true);

    setTimeout(() => {
      renderOfferShimmers(false);
    }, 10000);

    applyCartCoupon(
      code,
      this.forceApplyOfferCallback,
      false,
      false,
      'cart',
      id,
    );
  };

  fireListItemClickEvent = () => {
    const {
      previousScreen,
      index,
      listId,
      layout,
      listName,
      listIndex,
      itemData: { id = '', name = '', type = '', slug = '' } = {},
      listData: { content: listContent = '', slug: listSlug = '' } = {},
      parentListsData = [],
    } = this.props;
    AnalyticsUtility.fireItemClickEvent(
      previousScreen,
      id,
      type,
      name,
      index,
      listId,
      layout,
      listName,
      listIndex,
      '',
      '',
      listContent,
      '',
      slug,
      listSlug,
      AnalyticsUtility.getParentsList(parentListsData),
    );
  };

  getCouponCodes = () => {
    const store = getStoreRef().getState();
    const {
      bag: {
        cartPricing: { coupon_codes = EMPTY_ARRAY } = EMPTY_OBJECT,
      } = EMPTY_OBJECT,
    } = store;
    return coupon_codes;
  };

  updateCart = (sku_id) => {
    const {
      previousScreen,
      onItemAddToCartFromCollab,
      listId,
      layout,
      listName,
      listIndex,
      selectedVariant = {},
      selectedVariant: {
        id: varId = '',
        name: variantName = '',
        sku: { group_buying_price = '' } = {},
      } = {},
      skuId,
      index,
      listContent,
      hasPromptLoaded,
      removeFromCart,
      navigation,
      dismissModal = () => {},
      address,
      variantModal = false,
      cancelpressed = () => {},
      isBuyNow,
      addAddedToCartProduct,
      itemData,
      isClaimed = false,
      favourites: { products = {}, variants = {} } = {},
      listData: { options: listOptions = {}, display } = {},
      stockedStatus,
    } = this.props;

    const skus = this.getTodayDealSkus();
    const showAddedToCart = isPresent(this.getCartItem());

    const networkInfo = NetworkStats.networkDetails;

    let options = Utility.isPresent(listOptions) ? listOptions : {};

    const { force_apply = '', coupon_code = '' } = options;
    const data = {
      sku_id: sku_id,
      quantity: 1,
      force_apply: force_apply || '',
    };

    if (Utility.isPresent(coupon_code) && !showAddedToCart && !isClaimed) {
      AnalyticsManager.logEvent('item_action', {
        coupon_code,
        sku_id,
        list_display: display,
      });
      this.forceApplyOffer(coupon_code, sku_id);
      return;
    }

    if (isBuyNow) {
      dismissModal();
      variantModal && cancelpressed();
      if (Utility.isBlank(address)) {
        navigation.navigate(this.navigationSource, 'Address', {
          proceedTo: stockedStatus === 'in_stock_1' ? 'Cart' : 'Payments',
          previousScreen,
        });
      } else {
        addAddedToCartProduct(itemData);
        navigation.navigate(
          this.navigationSource,
          stockedStatus === 'in_stock_1' ? 'Cart' : 'Payments', {
          showOrderSummary: true,
          previousScreen: 'add_to_bag',
        });
      }
    } else if (!showAddedToCart) {
      if (this.checkoutMeta.navigate_after_add_to_cart) {
        dismissModal();
        variantModal && cancelpressed();
        if (Utility.isBlank(address) && this.checkoutMeta.address_before_cart) {
          navigation.navigate(this.navigationSource, 'Address', {
            proceedTo:
              this.checkoutMeta.payment_priority >
              this.checkoutMeta.cart_priority
                ? 'Payments'
                : 'Cart',
            previousScreen,
          });
        } else if (
          this.checkoutMeta.payment_priority > this.checkoutMeta.cart_priority
        ) {
          navigation.navigate(this.navigationSource, 'Payments', {
            showOrderSummary: true,
            previousScreen: 'add_to_bag',
          });
        } else {
          navigation.navigate(this.navigationSource, 'Cart', {
            showOrderSummary: true,
          });
        }
      }
    }

    if (typeof hasPromptLoaded === 'function') hasPromptLoaded();

    if (showAddedToCart && !isBuyNow) {
      if (previousScreen === 'brandCollab') {
        removeFromCart(data, () => {
          if (onItemAddToCartFromCollab) {
            onItemAddToCartFromCollab();
          }
        });
        return;
      }
      dismissModal();
      if (
        Utility.isBlank(address) &&
        this.checkoutMeta.address_before_cart_for_added_products
      ) {
        navigation.navigate(this.navigationSource, 'Address', {
          proceedTo:
            this.checkoutMeta.payment_priority > this.checkoutMeta.cart_priority
              ? 'Payments'
              : 'Cart',
          previousScreen,
        });
      } else if (
        this.checkoutMeta.payment_priority > this.checkoutMeta.cart_priority
      ) {
        navigation.navigate(this.navigationSource, 'Payments', {
          showOrderSummary: true,
          previousScreen: 'add_to_bag',
        });
      } else {
        navigation.navigate(this.navigationSource, 'Cart', {
          showOrderSummary: true,
        });
      }
    } else {
      const {
        itemData,
        listData = {},
        prompts = [],
        address,
        addAddedToCartProduct,
        isBuyNow,
        extraEventParameters,
        fromProductPage = false,
      } = this.props;
      const couponCodes = this.getCouponCodes();
      const { offersEsp } = Utility.getEsp(prompts, couponCodes);
      const networkData = Utility.getNetworkInfo(networkInfo);
      if (Utility.isPresent(itemData)) {
        const productMeta = {
          ...networkData,
          [EventParameterKey.SCREEN_NAME]: previousScreen,
          [EventParameterKey.ITEM_POSITION]: index || 0,
          [EventParameterKey.SKU_ID]: skuId,
          [EventParameterKey.PRODUCT_ID]: itemData.id,
          [EventParameterKey.BRAND_ID]: Utility.isPresent(itemData?.brand)
            ? itemData?.brand?.id
            : '',
          [EventParameterKey.PRODUCT_LISTING_PRICE]: itemData?.mrp,
          [EventParameterKey.PRODUCT_SELLING_PRICE]: itemData?.sp,
          [EventParameterKey.QUANTITY]: this.props.quantity ?? 1,
          [EventParameterKey.LIST_ID]: Utility.isPresent(listId) ? listId : '',
          [EventParameterKey.LIST_DISPLAY]: Utility.isPresent(layout)
            ? layout
            : '',
          [EventParameterKey.LIST_CONTENT]: listContent || 'product',
          [EventParameterKey.LIST_POSITION]: listIndex || 0,
          esp: offersEsp,
          [EventParameterKey.ADDRESS_PRESENT]: Utility.isPresent(address),
          wishlist_deal_price: skus[`${skuId}`]?.esp || 'not_eligible',
          edge_deal_price: skus[`${skuId}`]?.member_esp || 'not_eligible',
          is_buy_now: !!isBuyNow,
          [EventParameterKey.GROUP_DEAL_PRICE]: group_buying_price,
          [EventParameterKey.LIST_SLUG]: listData?.slug,
          ...extraEventParameters,
        };
        if (Utility.isPresent(selectedVariant)) {
          productMeta[[EventParameterKey.VARIANT_ID]] = varId;
          productMeta[[EventParameterKey.GROUP_DEAL_PRICE]] =
            group_buying_price;
        }

        if (fromProductPage) {
          AnalyticsManager.logEvent(
            EventType.discoveryEvents.FOOTER_BAG_BUTTON_CLICK,
            {
              screen_name: 'products -> show',
              item_type: 'Product',
              item_id: `${itemData.id}`,
              item_name: itemData.name,
              "sp": "524",
              esp: offersEsp,
              button: 'in_stock',
              buy_now: !!isBuyNow
            },
          );
        }
        AnalyticsManager.logEvent(
          EventType.discoveryEvents.PRODUCT_ADDED_TO_CART,
          productMeta,
        );
        AnalyticsManager.logFirebaseEvent(
          EventType.googleRemarketingEvents.ADD_TO_CART,
          {
            [EventParameterKey.VALUE]: itemData.mrp,
            [EventParameterKey.CURRENCY]: 'INR',
            [EventParameterKey.ITEMS]: [
              {
                [EventParameterKey.ITEM_ID]: skuId,
              },
            ],
          },
        );
        AnalyticsManager.logFBStandardEvent(
          EventType.FB.EVENT_NAME_ADDED_TO_CART,
          itemData.mrp,
          {
            [EventParameterKey.FB.EVENT_PARAM_CURRENCY]: 'INR',
            [EventParameterKey.FB.EVENT_PARAM_CONTENT_ID]: `${skuId}`,
            [EventParameterKey.FB.EVENT_PARAM_CONTENT_TYPE]: 'product',
            [EventParameterKey.FB.EVENT_PARAM_CONTENT]: JSON.stringify([
              {
                id: skuId,
                quantity: this.props.quantity ?? 1,
                item_price: itemData.mrp,
              },
            ]),
          },
        );
        AnalyticsManager.logGTMTag({
          event: EventType.GTM.ADD_TO_CART_EVENT,
          meta: {
            send_to: Config.GTM_ADWORDS || Config.GTM_ID,
            value: itemData?.sp,
            items: [
              {
                id: skuId,
                google_business_vertical: 'retail',
              },
            ],
          },
        });
        AnalyticsManager.logGTMTag({
          event: EventType.GTM.ADD_TO_CART,
          ecommerce: {
            currencyCode: Config.ISO_CURRENCY_CODE,
            [EventType.GTM.ADD_TO_CART]: {
              products: [
                {
                  name: itemData?.name,
                  id: skuId,
                  price: itemData?.sp,
                  brand: itemData?.brand?.name,
                  category: itemData?.product_category_name,
                  variant: variantName,
                  quantity: this.props.quantity ?? 1,
                },
              ],
            },
          },
        });
        if (isWeb()) {
          AnalyticsManager.logPixelStandardEvent(EventType.FB_PIXEL.AddToCart, {
            content_ids: [skuId],
            content_name: itemData?.name,
            content_type: 'product',
            contents: JSON.stringify({'id': skuId, 'quantity': this.props.quantity ?? 1}),
            currency: 'INR',
            value: itemData?.sp
          });
        }
      }

      this.changeItemInCart(data); // Adding/Removing item from cart
    }
  };

  getTodayDealSkus = () => {
    const state = getStoreRef().getState();
    const {
      todayDeals: { skus = {} },
    } = state;
    return skus;
  };

  incrementProductInCartAndFireAnalytics = (sku_id) => {
    const {
      listData: { options: listOptions = {}, slug: listSlug = '' } = {},
    } = this.props;
    let options = Utility.isPresent(listOptions) ? listOptions : {};

    const data = {
      sku_id: sku_id,
      quantity: +1,
      source: 'cart',
      force_apply: options?.force_apply || '',
    };

    // to avoid adding the same routine multiple times
    const { type } = this.props;
    if (type === 'routine') {
      return;
    }

    this.changeItemInCart(data); // Adding/Removing item from cart
    const {
      variant,
      cartItem = {},
      skuId,
      cartItem: {
        prompts = [],
        mrp,
        sp,
        id,
        brand: { id: brandId } = {},
        sku: { group_buying_price = '' } = {},
      } = {},
      address,
      isBuyNow,
      favourites: { products = {}, variants = {} } = {},
    } = this.props;
    const couponCodes = this.getCouponCodes();
    const skus = this.getTodayDealSkus();
    const networkInfo = NetworkStats.networkDetails;
    const { offersEsp } = Utility.getEsp(prompts, couponCodes);
    const networkData = Utility.getNetworkInfo(networkInfo);

    const productMeta = {
      ...networkData,
      [EventParameterKey.SCREEN_NAME]: SCREEN_CONSTANTS.CART,
      [EventParameterKey.SKU_ID]: skuId,
      [EventParameterKey.PRODUCT_ID]: id,
      [EventParameterKey.BRAND_ID]: brandId,
      [EventParameterKey.PRODUCT_LISTING_PRICE]: mrp,
      [EventParameterKey.PRODUCT_SELLING_PRICE]: sp,
      [EventParameterKey.QUANTITY]: data.quantity,
      esp: offersEsp,
      [EventParameterKey.ADDRESS_PRESENT]: Utility.isPresent(address),
      wishlist_deal_price: skus[`${skuId}`]?.esp || 'not_eligible',
      edge_deal_price: skus[`${skuId}`]?.member_esp || 'not_eligible',
      is_buy_now: !!isBuyNow,
      [EventParameterKey.GROUP_DEAL_PRICE]: group_buying_price,
      [EventParameterKey.LIST_SLUG]: listSlug,
    };
    if (Utility.isPresent(variant)) {
      productMeta[[EventParameterKey.VARIANT_ID]] = variant.id;
    }
    AnalyticsManager.logEvent(
      EventType.discoveryEvents.PRODUCT_ADDED_TO_CART,
      productMeta,
    );
    AnalyticsManager.logFirebaseEvent(
      EventType.googleRemarketingEvents.ADD_TO_CART,
      {
        [EventParameterKey.VALUE]: mrp,
        [EventParameterKey.CURRENCY]: 'INR',
        [EventParameterKey.ITEMS]: [
          {
            [EventParameterKey.ITEM_ID]: skuId,
          },
        ],
      },
    );
    AnalyticsManager.logFBStandardEvent(
      EventType.FB.EVENT_NAME_ADDED_TO_CART,
      mrp,
      {
        [EventParameterKey.FB.EVENT_PARAM_CURRENCY]: 'INR',
        [EventParameterKey.FB.EVENT_PARAM_CONTENT_ID]: `${skuId}`,
        [EventParameterKey.FB.EVENT_PARAM_CONTENT_TYPE]: 'product',
        [EventParameterKey.FB.EVENT_PARAM_CONTENT]: JSON.stringify([
          {
            id: skuId,
            quantity: 1,
            item_price: mrp,
          },
        ]),
      },
    );
  };

  decrementProductInCartAndFireAnalytics = () => {
    const {
      onItemAddToCartFromCollab,
      isFreeItem = false,
      cartItem,
      quantity,
      variant,
      skuId,
      singleStockedVariantSku,
    } = this.props;

    const data = {
      sku_id: skuId || singleStockedVariantSku,
      quantity: -1,
      is_free: isFreeItem,
      source: 'cart',
    };

    this.changeItemInCart(data); // Adding/Removing item from cart
    const productMeta = {
      [EventParameterKey.PRODUCT_ID]: cartItem?.id,
      [EventParameterKey.PRODUCT_NAME]: cartItem?.name,
      [EventParameterKey.BRAND_ID]: cartItem?.brand?.id,
      [EventParameterKey.BRAND_NAME]: cartItem?.brand?.name,
      [EventParameterKey.PRODUCT_LISTING_PRICE]: cartItem?.mrp,
      [EventParameterKey.PRODUCT_SELLING_PRICE]: cartItem?.sp,
      [EventParameterKey.QUANTITY]: 1,
    };

    if (Utility.isPresent(variant)) {
      productMeta[[EventParameterKey.VARIANT_ID]] = variant.id;
      productMeta[[EventParameterKey.VARIANT_NAME]] = variant.display_name;
    }
    AnalyticsManager.logEvent(
      EventType.discoveryEvents.PRODUCT_REMOVE_FROM_CART,
      productMeta,
    );
    AnalyticsManager.logFirebaseEvent(
      EventType.googleRemarketingEvents.REMOVE_FROM_CART,
      {
        [EventParameterKey.CURRENCY]: 'INR',
        [EventParameterKey.VALUE]: cartItem?.mrp,
        [EventParameterKey.ITEMS]: [
          {
            [EventParameterKey.ITEM_ID]: { id: skuId },
          },
        ],
      },
    );
  };

  formatVariantSelectionToastText = (text) => {
    return text
      .replace(/[sS]elect/gm, '')
      ?.trim()
      .toLowerCase();
  };

  checkToAddProductOrShowCart = (sku_id) => {
    const { isBuyNow } = this.props;
    const { addedToCart } = this.props;
    if (!addedToCart) {
      const {
        hasVariants,
        selectedVariant,
        onMoreShadeTap,
        variantName,
        showToast = () => {},
        variantType,
        showProductPagePromptModal,
        showCart = () => {},
        dismissModal = () => {},
        navigation,
        address,
        isBuyNow,
        selectedMultiVariantPrimary,
        selectedMultiVariantSecondary,
        multiVariantAttributes = [],
        removeUnFullfilledCoupon = () => {},
        inModal = false,
      } = this.props;

      this.fireListItemClickEvent();

      if (
        Utility.isPresent(multiVariantAttributes) &&
        Utility.isBlank(selectedMultiVariantPrimary) &&
        Utility.isBlank(selectedVariant)
      ) {
        if (inModal) {
          removeUnFullfilledCoupon(); //added in case unfullfilled modal is open, and user tries to add to bag without a variant selected.
        }
        setTimeout(
          () =>
            showToast(
              `Please select a ${this.formatVariantSelectionToastText(
                multiVariantAttributes[0]?.name,
              )}`,
            ),
          500,
        );
        return;
      }
      if (
        Utility.isBlank(selectedMultiVariantSecondary) &&
        VariantUtility.checkIfMultiVariant(multiVariantAttributes)
      ) {
        showToast(
          `Please select a ${this.formatVariantSelectionToastText(
            multiVariantAttributes[0]?.multi_level_variant_attributes[0]?.name,
          )}`,
        );
        return;
      }

      if (hasVariants && Utility.isBlank(selectedVariant)) {
        if (variantType === 'image') {
          onMoreShadeTap();
        } else {
          showToast('Please select a size');
        }
      } else {
        Vibration.vibrate(200);
        this.debouncedUpdateCart(sku_id);
      }
    } else {
      const {
        variantModal = false,
        cancelpressed = () => {},
        dismissModal = () => {},
        navigation,
        address,
        isBuyNow,
        removeUnFullfilledCoupon,
        inModal,
        previousScreen,
        stockedStatus,
      } = this.props;
      variantModal && cancelpressed();
      dismissModal();

      if (inModal) {
        removeUnFullfilledCoupon();
      }

      if (isBuyNow) {
        if (Utility.isBlank(address)) {
          navigation.push(this.navigationSource, 'Address', {
            proceedTo: stockedStatus === 'in_stock_1' ? 'Cart' : 'Payments',
            previousScreen,
          });
        } else {
          navigation.push(this.navigationSource, stockedStatus === 'in_stock_1' ? 'Cart' : 'Payments', {
            showOrderSummary: true,
            previousScreen: 'add_to_bag',
          });
        }
      } else {
        if (
          Utility.isBlank(address) &&
          this.checkoutMeta.address_before_cart_for_added_products
        ) {
          navigation.push(this.navigationSource, 'Address', {
            proceedTo:
              this.checkoutMeta.payment_priority >
              this.checkoutMeta.cart_priority
                ? 'Payments'
                : 'Cart',
            previousScreen,
          });
        } else if (
          this.checkoutMeta.payment_priority > this.checkoutMeta.cart_priority
        ) {
          navigation.navigate(this.navigationSource, 'Payments', {
            showOrderSummary: true,
            previousScreen: 'add_to_bag',
          });
        } else {
          if (AppConfig.isCartPresentInBottomTabs()) {
            navigation.navigate(this.navigationSource, 'Cart', {
              showOrderSummary: true,
            });
            return;
          }
          navigation.push(this.navigationSource, 'Cart', {
            showOrderSummary: true,
          });
        }
      }
    }
  };

  showVariantModal = () => {
    const {
      itemData,
      listId,
      listName,
      listIndex,
      listData = {},
      onItemAddToCartFromCollab,
      previousScreen,
      refreshOfferStrip,
      refreshOffersDetailsPageDiscountStrip,
      navigation,
    } = this.props;
    navigation.navigate('VariantModal', {
      itemData,
      listId,
      listName,
      listIndex,
      listData,
      onItemAddToCartFromCollab,
      previousScreen,
      refreshOfferStrip,
      refreshOffersDetailsPageDiscountStrip,
    });
  };

  checkToAddProductOrShowShade = (sku_id) => {
    const {
      hasVariants,
      outOfStock,
      singleStockedVariantSku,
      showCart,
      navigation,
      address,
      isBuyNow,
      itemData,
      previousScreen,
    } = this.props;

    const showAddedToCart = isPresent(this.getCartItem());
    this.props.toggleUnavailableProductsModal &&
      this.props.toggleUnavailableProductsModal();
    this.fireListItemClickEvent();

    if (
      hasVariants &&
      !outOfStock &&
      Utility.isPresent(singleStockedVariantSku)
    ) {
      if (showAddedToCart) {
        if (isBuyNow) {
          if (Utility.isBlank(address)) {
            navigation.navigate(this.navigationSource, 'Address', {
              proceedTo:
                this.checkoutMeta.payment_priority >
                this.checkoutMeta.cart_priority
                  ? 'Payments'
                  : 'Cart',
              previousScreen,
            });
          } else {
            navigation.navigate(this.navigationSource, 'Payments', {
              showOrderSummary: true,
              previousScreen: 'add_to_bag',
            });
          }
        } else {
          if (
            Utility.isBlank(address) &&
            this.checkoutMeta.address_before_cart_for_added_products
          ) {
            navigation.navigate(this.navigationSource, 'Address', {
              proceedTo:
                this.checkoutMeta.payment_priority >
                this.checkoutMeta.cart_priority
                  ? 'Payments'
                  : 'Cart',
              previousScreen,
            });
          } else if (
            this.checkoutMeta.payment_priority > this.checkoutMeta.cart_priority
          ) {
            navigation.navigate(this.navigationSource, 'Payments', {
              showOrderSummary: true,
              previousScreen: 'add_to_bag',
            });
          } else {
            navigation.navigate(this.navigationSource, 'Cart', {
              showOrderSummary: true,
            });
          }
        }
      } else {
        this.debouncedUpdateCart(sku_id);
      }
    } else if (hasVariants && !outOfStock) {
      this.showVariantModal();
    } else if (outOfStock) {
      navigation.navigate('UnavailableProductsModal', { itemData });
    } else {
      this.debouncedUpdateCart(sku_id);
    }
  };

  productDetailAddToCartButton = (props) => {
    const { showAddedToCart, skuId, isBuyNow, alwaysShowQuantitySelector } =
      props;
    const {
      variantModal = false,
      inModal = false,
      can_offer_fullfill,
      isOfferShimmerVisible,
      buyNowButtonDataSet = {},
    } = this.props;
    let bagText = showAddedToCart ? 'View Bag' : 'Add To Bag';

    const bagColor = showAddedToCart ? colors.foxyPink : colors.green;
    let imageSource = images.addedToCart.dark[showAddedToCart];
    const cartItemData = {
      skuId,
    };

    let bagFirstColor = '#51CB8C';
    let bagSecondColor = '#03B460';

    if (isBuyNow) {
      bagText = 'Buy Now';
      imageSource = images.addedToCart.flash;

      if (showAddedToCart) {
        imageSource = images.addedToCart.flash;
        bagText = 'Checkout';
      }
    } else {
      if (this.checkoutMeta.change_add_to_cart_button) {
        if (showAddedToCart) {
          bagText =
            this.checkoutMeta.payment_priority > this.checkoutMeta.cart_priority
              ? 'Checkout'
              : 'View Bag';
        } else {
          bagText = this.checkoutMeta.button_name;
          // imageSource = images.addedToCart.flash;
        }
      }
    }

    let buyNowButtonStyle = !isBuyNow
      ? ProductDetailStyles.bagContainerModal
      : ProductDetailStyles.bagContainerVariantModal;

    let buyNowButtonTextStyle = !isBuyNow
      ? ProductDetailStyles.buyNowBagText
      : ProductDetailStyles.addToBagText;

    let iconStyle = !isBuyNow
      ? ProductDetailStyles.detailAddToCartImageDefault
      : ProductDetailStyles.detailAddToCartImageBuyNow;

    if (inModal) {
      buyNowButtonStyle = ProductDetailStyles.bagNowButtonWithBlackBg;

      buyNowButtonTextStyle = ProductDetailStyles.addToBagText;

      iconStyle = [
        ProductDetailStyles.detailAddToCartImageBuyNow,
        { tintColor: colors.white },
      ];
    }

    if (can_offer_fullfill) {
      bagText = 'Add to Bag & Apply Offer';
    }

    if (alwaysShowQuantitySelector) {
      return <this.quantityIncrementDecrementButton />;
    }

    if (
      AppConfig.getBooleanValue(Config.SHOW_QUANTITY_IN_ADD_TO_BAG) &&
      !isBuyNow &&
      showAddedToCart
    ) {
      return <this.quantityIncrementDecrementButton />;
    }

    return (
      <DebouncedTouchableOpacity
        {...this.props}
        onPress={this.memoizedAddProductOrShowCart()(cartItemData.skuId)}
        hitSlop={Utility.getHitSlop()}
      >
        <View style={[buyNowButtonStyle, props.buyNowButtonStyle]} dataSet={buyNowButtonDataSet}>
          {inModal && isOfferShimmerVisible ? (
            <ActivityIndicator color={colors.white} size='small' />
          ) : (
            <View style={ProductDetailStyles.addToBagViewVariantModal}>
              {!inModal && <Image source={imageSource} style={iconStyle} />}
              <Text style={buyNowButtonTextStyle}>{bagText}</Text>
            </View>
          )}
        </View>
      </DebouncedTouchableOpacity>
    );
  };

  getCartQuantityForVariant = () => {
    const { cartItems, skuId } = this.props;
    const cartItem =
      cartItems.filter((cartItem) => cartItem?.sku_id === skuId) || [];
    return cartItem[0]?.quantity || 0;
  };

  quantityIncrementDecrementButton = () => {
    const { skuId, campaignId } = this.props;
    return (
      <View style={ProductDetailStyles.quantityIncrementContainer}>
        <DebouncedTouchableOpacity
          {...this.props}
          onPress={this.debouncedRemoveFromCart}
        >
          <View hitSlop={this.decrementHitSlop}>
            <FastImageView
              source={images.quantity_minus}
              style={ProductDetailStyles.quantityIcon}
              resizeMode={'contain'}
              tintColor={colors.primaryActionBackgroundColor}
            />
          </View>
        </DebouncedTouchableOpacity>

        <Text style={ProductDetailStyles.quantityText}>
          {this.getCartQuantityForVariant() || 1}
        </Text>
        <DebouncedTouchableOpacity
          {...this.props}
          onPress={() => this.onAddToCartPressFromCart(skuId)}
        >
          <View hitSlop={this.incrementHitSlop}>
            <FastImageView
              source={images.quantity_plus}
              style={ProductDetailStyles.quantityIcon}
              resizeMode={'contain'}
              tintColor={colors.primaryActionBackgroundColor}
            />
          </View>
        </DebouncedTouchableOpacity>
      </View>
    );
  };

  matchedProductCardAddToCartButton = (props) => {
    const { layout, theme = THEME.LIGHT, skuId, itemData, style } = props;
    const {
      campaignId,
      singleStockedVariantSku,
      smallButton = false,
    } = this.props;
    const { addedToCartForCard } = this.state;
    let { outOfStock = false } = this.props;
    if (outOfStock === null) {
      outOfStock = false;
    }
    let styleCart = ProductCardStyles[`${layout}CartStyle`];
    const imageSource = images.addedToCart[theme][addedToCartForCard];

    const addToCartText = addedToCartForCard ? 'View Bag' : 'Add to Bag';

    if (layout === LAYOUT.VERTICALRAIL) {
      styleCart = ProductCardStyles[`${layout}CartStyle`];
    }
    if (layout === LAYOUT.PERSONALISEDRAIL) {
      styleCart = ProductCardStyles[`${layout}CartStyle`];
    }
    let cartItemData = {
      skuId,
      quantity: 1,
    };
    if (Utility.isPresent(singleStockedVariantSku)) {
      cartItemData = {
        skuId: singleStockedVariantSku,
        quantity: 1,
      };
    }
    if (campaignId) {
      cartItemData['campaign_id'] = campaignId;
    }

    let buttonWidth = smallButton ? 132 : 153;

    if (Utility.isPresent(style)) {
      buttonWidth = style.width;
    }

    return (
      <>
        <RoundedButton
          buttonText={addToCartText}
          buttonTextColor={'#fff'}
          buttonColor={'#000'}
          buttonAction={this.memoizedAddProductOrShowShade()(
            cartItemData.skuId,
          )}
          style={{
            width: buttonWidth,
            height: 36,
            borderRadius: 20,
            flexDirection: 'row',
            alignItems: 'center',
          }}
          buttonTextStyle={{
            fontSize: 14,
            fontFamily: 'Roboto-Medium',
            marginLeft: 8,
          }}
          renderProp={() => {
            return (
              <Image
                source={imageSource}
                style={[
                  ProductCardStyles[`${layout}AddToCartImage`],
                  { tintColor: colors.white },
                ]}
              />
            );
          }}
        />
      </>
    );
  };

  featuredRailAddToCartButton = (props) => {
    const { layout, theme = THEME.LIGHT, skuId, itemData, style } = props;
    const { campaignId, singleStockedVariantSku } = this.props;
    const { addedToCartForCard } = this.state;

    const { isUnavailableProductsModalVisible } = this.state;

    const addToCartText = addedToCartForCard ? 'In your Bag' : 'Add';

    let cartItemData = {
      skuId,
      quantity: 1,
    };
    if (Utility.isPresent(singleStockedVariantSku)) {
      cartItemData = {
        skuId: singleStockedVariantSku,
        quantity: 1,
      };
    }
    if (campaignId) {
      cartItemData['campaign_id'] = campaignId;
    }

    let buttonTextColor = colors.black;
    let buttonColor = colors.white;
    if (theme === THEME.DARK) {
      buttonTextColor = colors.white;
      buttonColor = colors.black;
    }

    return (
      <>
        <RoundedButton
          buttonText={addToCartText}
          buttonTextColor={buttonTextColor}
          buttonColor={buttonColor}
          buttonAction={this.memoizedAddProductOrShowShade()(
            cartItemData.skuId,
          )}
          style={ProductInCartStyles.featuredRailAddToCartButtonStyle}
          buttonTextStyle={
            ProductInCartStyles.featuredRailAddToCartButtonTextStyle
          }
        />
        {isUnavailableProductsModalVisible && (
          <UnavailableProductsModal
            isVisible={isUnavailableProductsModalVisible}
            toggleUnavailableProductsModal={this.toggleUnavailableProductsModal}
            modalHeading={this.UnavailableProductsModalHeading}
            onContinuePress={this.toggleUnavailableProductsModal}
            onCancelPress={this.toggleUnavailableProductsModal}
            itemData={itemData}
          />
        )}
      </>
    );
  };

  RoundBagButton = (props) => {
    const {
      imageSource,
      cartItemData,
      styleCart,
      containerStyle,
      isDisabled,
      isFancyCard,
      showAddedToCart,
    } = props;
    const { isOfferShimmerVisible = false } = this.props;
    const imageStyle = showAddedToCart
      ? this.roundedBagButtonAddedImageStyle
      : this.roundBagButtonImageStyle;
    return (
      <DebouncedTouchableOpacity
        testID='list-add-to-bag-Button'
        accessibilityLabel='list-add-to-bag-Button'
        {...this.props}
        onPress={this.memoizedAddProductOrShowShade()(cartItemData.skuId)}
        hitSlop={Utility.getHitSlop()}
        style={containerStyle}
        disabled={isDisabled}
      >
        <View style={styleCart}>
          {isOfferShimmerVisible && isFancyCard ? (
            <ActivityIndicator animating color={colors.white} />
          ) : (
            <Image source={imageSource} style={imageStyle} />
          )}
        </View>
      </DebouncedTouchableOpacity>
    );
  };

  memoizedRectangularBagButtonStyle = () => {
    return (backgroundColor = '', borderRadius = '') => {
      if (
        !this.rectangularBagButtonStyleCache[
          `${backgroundColor}${borderRadius}`
        ]
      ) {
        this.rectangularBagButtonStyleCache[
          `${backgroundColor}${borderRadius}`
        ] = [
          ProductCardStyles.rectangularBagButton,
          {
            borderRadius,
            backgroundColor,
          },
        ];
      }
      return this.rectangularBagButtonStyleCache[
        `${backgroundColor}${borderRadius}`
      ];
    };
  };

  memoizedAddProductOrShowShade = () => {
    return (skuId) => {
      if (!this.memoizedAddProductOrShowShadeCache[skuId]) {
        this.memoizedAddProductOrShowShadeCache[skuId] = () =>
          this.debouncedAddProductOrShowShade(skuId);
      }
      return this.memoizedAddProductOrShowShadeCache[skuId];
    };
  };

  memoizedAddProductOrShowCart = () => {
    return (skuId) => {
      if (!this.memoizedAddProductOrShowCartCache[skuId]) {
        this.memoizedAddProductOrShowCartCache[skuId] = () =>
          this.checkToAddProductOrShowCart(skuId);
      }
      return this.memoizedAddProductOrShowCartCache[skuId];
    };
  };

  memoizedRectangularBagButtonTextStyle = () => {
    return (color = '') => {
      if (!this.rectangularBagButtonTextCache[color]) {
        this.rectangularBagButtonTextCache[color] = [
          ProductCardStyles.rectangularBagText,
          { color },
        ];
      }
      return this.rectangularBagButtonTextCache[color];
    };
  };

  RectangularBagButton = (props) => {
    const { cartItemData, showAddedToCart } = props;
    const { addedToCartForCard } = this.state;
    if (addedToCartForCard && this.getCartQuantityForVariant() > 0) {
      return <this.RectangularQuantityBagButton sku_Id={cartItemData.skuId} />;
    }
    const text = addedToCartForCard ? 'View Bag' : 'Add To Bag';

    let ctaColor = colors.primaryActionBackgroundColor;
    let labelColor = colors.white;
    let ctaCorner = 0;

    if (Utility.isPresent(Config.LARGE_PRODUCT_CARD_CTA_COLOR)) {
      ctaColor = Config.LARGE_PRODUCT_CARD_CTA_COLOR;
    }

    if (Utility.isPresent(Config.LARGE_PRODUCT_CARD_CTA_LABEL_COLOR)) {
      labelColor = Config.LARGE_PRODUCT_CARD_CTA_LABEL_COLOR;
    }

    if (Utility.isPresent(Config.LARGE_PRODUCT_CARD_CORNER)) {
      ctaCorner = parseInt(Config.LARGE_PRODUCT_CARD_CORNER);
    }

    const rectangularBagButtonStyle = this.memoizedRectangularBagButtonStyle()(
      ctaColor,
      ctaCorner,
    );
    const rectangularBagButtonText =
      this.memoizedRectangularBagButtonTextStyle()(labelColor);
    return (
      <DebouncedTouchableOpacity
        {...this.props}
        style={rectangularBagButtonStyle}
        onPress={this.memoizedAddProductOrShowShade()(cartItemData.skuId)}
      >
        <Text style={rectangularBagButtonText}>Add To Bag</Text>
      </DebouncedTouchableOpacity>
    );
  };

  getCartItem = () => {
    const { cartItems = [], skuId, singleStockedVariantSku } = this.props;
    const cartItem = cartItems.filter(
      (cartItem) =>
        cartItem?.sku_id === skuId ||
        cartItem.sku_id === singleStockedVariantSku,
    );
    return cartItem || [];
  };

  getCartQuantityForVariant = () => {
    return this.getCartItem()[0]?.quantity ?? 0;
  };

  RectangularQuantityBagButton = (props) => {
    const { sku_Id } = props;
    const { isAnimating } = this.state;
    const decrementImageSource =
      this.getCartQuantityForVariant() === 1
        ? images.trash_icon
        : images.minus_white;
    const containerStyle = isAnimating
      ? ProductCardStyles.quantityIncrementContainerLoading
      : ProductCardStyles.quantityIncrementContainer;
    const decrementImageStyle =
      this.getCartQuantityForVariant() === 1
        ? ProductCardStyles.rectangularBagButtonTrashIcon
        : ProductCardStyles.rectangularBagButtonPlusIcon;
    return (
      <View style={containerStyle}>
        <DebouncedTouchableOpacity
          {...this.props}
          onPress={this.debouncedRemoveFromCart}
          isDisabled={isAnimating}
        >
          <View hitSlop={this.decrementHitSlop}>
            <FastImageView
              source={decrementImageSource}
              style={decrementImageStyle}
              resizeMode={'contain'}
              tintColor={'white'}
            />
          </View>
        </DebouncedTouchableOpacity>

        <Text style={ProductCardStyles.quantityText}>
          {this.getCartQuantityForVariant() || 1}
        </Text>
        <DebouncedTouchableOpacity
          {...this.props}
          onPress={this.memoizedAddToCartPressFromCart()(sku_Id)}
          isDisabled={isAnimating}
        >
          <View hitSlop={this.incrementHitSlop}>
            <FastImageView
              source={images.plus_white}
              style={ProductCardStyles.rectangularBagButtonPlusIconLarge}
              resizeMode={'contain'}
            />
          </View>
        </DebouncedTouchableOpacity>
        {isAnimating && (
          <BarLoader
            iterations={6}
            width={70}
            style={ProductCardStyles.barLoader}
          />
        )}
      </View>
    );
  };

  shouldShowRectangularButton = ({ cta_position_bottom }) =>
    cta_position_bottom === '1';

  RectangularOrRoundButton = withEither(
    this.shouldShowRectangularButton,
    this.RectangularBagButton,
  )(this.RoundBagButton);

  productCardAddToCartButton = (props) => {
    const { layout, theme = THEME.LIGHT, skuId, itemData } = props;
    const {
      campaignId,
      singleStockedVariantSku,
      additional_data,
      isFancyCard = false,
      isClaimed = false,
      soldOutTextColor = '',
      listData = {},
      listData: { options: listOptions = {} } = {},
      isOfferShimmerVisible = false,
      cta_position_bottom,
      cartItems,
    } = this.props;
    const { addedToCartForCard } = this.state;
    const showAddedToCart = isPresent(this.getCartItem());

    let { outOfStock = false } = this.props;
    if (outOfStock === null) {
      outOfStock = false;
    }
    let styleCart = ProductCardStyles[`${layout}CartStyle`];
    let imageSource = images.addedToCart[theme][showAddedToCart];

    let cartItemData = {
      skuId,
      quantity: 1,
    };
    if (Utility.isPresent(singleStockedVariantSku)) {
      cartItemData = {
        skuId: singleStockedVariantSku,
        quantity: 1,
      };
    }
    if (campaignId) {
      cartItemData['campaign_id'] = campaignId;
    }
    let cartImageTintColor = colors.white;
    if (theme !== THEME.DARK) {
      cartImageTintColor = colors.foxyBlack;
    }

    if (showAddedToCart) {
      cartImageTintColor = colors.foxyPink;
    }

    if (additional_data?.item_action?.primary === 'like') {
      return (
        <AnimatedLikeButton
          ref={this.likeButtonRef}
          id={itemData.id}
          likedItemSkuId={itemData.sku_id}
          type={itemData.type}
          slug={itemData.slug}
          mrp={itemData.mrp}
          skuId={Utility.getSkuId(itemData)}
          layout={LAYOUT.SCREEN}
          content={itemData.content}
          onLike={this.onLike}
          itemName={itemData.name}
          screenName={SCREEN_CONSTANTS.PRODUCT_CARD}
          addToCartLikeButton
          withoutShadow
        />
      );
    }
    // console.tron.log('LAUOT ADD TO CAT 0', layout);

    let imageStyle = [
      ProductCardStyles[`${layout}AddToCartImage`],
      { tintColor: cartImageTintColor },
    ];
    let containerStyle = {};
    let isDisabled = false;

    if (isFancyCard) {
      imageStyle =
        showAddedToCart || isClaimed
          ? ProductInCartStyles.fancyCardImage
          : ProductInCartStyles.fancyCardImageSmall;
      styleCart = ProductInCartStyles.fancyCardImageContainer;
      imageSource = {
        uri:
          showAddedToCart || isClaimed
            ? images.edge.check_white
            : images.edge.plus,
      };
      containerStyle = ProductInCartStyles.fancyCardContainerStyle;
      isDisabled = outOfStock || isClaimed;
      const {
        itemData: { gwp = false },
      } = this.props;
      if (outOfStock) {
        const textStyle = [
          ProductInCartStyles.soldOutText,
          { color: soldOutTextColor },
        ];
        return <Text style={textStyle}>SOLD OUT</Text>;
      }
    }

    return (
      <>
        <this.RectangularOrRoundButton
          cartItemData={cartItemData}
          showAddedToCart={showAddedToCart}
          styleCart={styleCart}
          imageSource={imageSource}
          layout={layout}
          containerStyle={containerStyle}
          cta_position_bottom={cta_position_bottom}
          isDisabled={isDisabled}
          isFancyCard={isFancyCard}
        />
      </>
    );
  };

  onRemoveFromCartPressFromCart = () => {
    const { disableQuantityChange = false } = this.props;
    const { isAnimating, isRemoveProductConfirmationAlertVisible } = this.state;
    if (disableQuantityChange || isAnimating) return;
    if (isRemoveProductConfirmationAlertVisible) {
      this.closeRemoveProductConfirmationAlert();
    }
    this.debouncedRemoveFromCart();
  };

  onAddToCartPressFromCart = (sku_id) => {
    const { isFreeItem = false } = this.props;
    const { isAnimating } = this.state;
    if (isFreeItem || isAnimating) return;
    this.debouncedAddToCart(sku_id);
  };

  memoizedAddToCartPressFromCart = () => {
    return (sku_id = '') => {
      if (!this.memoizedAddToCartPressFromCartCache[sku_id]) {
        this.memoizedAddToCartPressFromCartCache[sku_id] = () =>
          this.onAddToCartPressFromCart(sku_id);
      }
      return this.memoizedAddToCartPressFromCartCache[sku_id];
    };
  };

  cartIncrementDecrementButton = (props) => {
    const { skuId, quantity, campaignId } = props;
    const {
      error,
      isFreeItem = false,
      disableQuantityChange = false,
      type = '',
      restrictAddToCart = false,
    } = this.props;
    const { isAnimating } = this.state;
    const cartItemData = {
      sku_id: skuId,
      quantity,
      campaignId,
    };
    const styles = ProductInCartStyles;
    let plusButtonStyle = styles.plus;

    if (Utility.isPresent(error) || isFreeItem || type === 'routine') {
      plusButtonStyle = styles.plusDisabled;
    }

    if (isAnimating) {
      plusButtonStyle = styles.plusWhileCartChanging;
    }

    if (restrictAddToCart) {
      plusButtonStyle = [plusButtonStyle, { tintColor: colors.subtitle }];
    }

    return (
      <View style={styles.cartButtonContainer}>
        <View style={styles.incrementDecrementSwitch}>
          <DebouncedTouchableOpacity
            {...this.props}
            onPress={this.showRemoveProductConfirmationAlert}
            style={styles.decrementButtonContainer}
            hitSlop={Utility.getHitSlop('cross')}
          >
            <this.renderDecrementButtonImage />
          </DebouncedTouchableOpacity>
          <Text
            allowFontScaling={false}
            style={styles.productQuantandVariantText}
          >
            {quantity}
          </Text>
          <DebouncedTouchableOpacity
            {...this.props}
            onPress={this.memoizedAddToCartPressFromCart()(skuId)}
            style={styles.incrementButtonContainer}
            hitSlop={Utility.getHitSlop('cross')}
            disabled={restrictAddToCart}
          >
            <Image source={images.plus} style={plusButtonStyle} />
          </DebouncedTouchableOpacity>
        </View>
        {isAnimating && <BarLoader iterations={5} width={65} />}
      </View>
    );
  };

  renderDecrementButtonImage = () => {
    const { quantity, disableQuantityChange = false } = this.props;
    const { isAnimating } = this.state;

    const styles = ProductInCartStyles;
    if (quantity > 1) {
      let minusButtonStyle = isAnimating
        ? styles.minusWhileCartChanging
        : styles.minus;
      if (disableQuantityChange) {
        minusButtonStyle = styles.minusDisabled;
      }
      return <View style={minusButtonStyle} />;
    }
    return (
      <Image source={images.previewScreenIcons.delete} style={styles.delete} />
    );
  };

  cartConditionFn = (props) =>
    props.addToCartLayout === PRODUCT.ADD_TO_CART_LAYOUT.PRODUCT_DETAIL;

  showIncrementDecrementButtonFn = (props) =>
    props.addToCartLayout === PRODUCT.ADD_TO_CART_LAYOUT.CART;

  showFeaturedRailButton = (props) =>
    props.addToCartLayout === PRODUCT.ADD_TO_CART_LAYOUT.FEATURED_PRODUCT;

  matchedProductAddToCartCondition = (props) =>
    props.layout === LAYOUT.MATCHED_PRODUCT;

  addToCartButton = compose(
    withEither(
      this.matchedProductAddToCartCondition,
      this.matchedProductCardAddToCartButton,
    ),
    withEither(this.cartConditionFn, this.productDetailAddToCartButton),
    withEither(
      this.showIncrementDecrementButtonFn,
      this.cartIncrementDecrementButton,
    ),
    withEither(this.showFeaturedRailButton, this.featuredRailAddToCartButton),
  )(this.productCardAddToCartButton);

  closeRemoveProductConfirmationAlert = () => {
    this.setState({ isRemoveProductConfirmationAlertVisible: false });
  };

  showRemoveProductConfirmationAlert = () => {
    const { quantity, isFreeItem, usns } = this.props;
    const { isAnimating } = this.state;
    if (isAnimating) {
      return;
    }
    if (Utility.isPresent(usns)) {
      this.setState({ showUSNRemovalModal: true });
      return;
    }
    quantity === 1 && !isFreeItem
      ? this.setState({ isRemoveProductConfirmationAlertVisible: true })
      : this.onRemoveFromCartPressFromCart();
  };

  addToWishlist = () => {
    const {
      like,
      cartItem: { id, type },
    } = this.props;
    this.onRemoveFromCartPressFromCart();
    like({ id, type });
  };

  itemAction = () => {
    const { additional_data = {} } = this.props;
    DynamicLinkManager.handleDynamicLink(
      `${additional_data?.icon_action}`,
      this.navigateToScreen,
    );
  };

  onFirstButtonPressForErrorMessage = () => {
    this.setState({
      errorMessage: '',
    });
  };

  modalAction = () => {
    const { additional_data = {} } = this.props;
    AnalyticsManager.logEvent(EventType.artistEvents.MODAL_ACTION, {
      [EventParameterKey.MODAL_NAME]: EDGE_MODAL_TYPES.JOIN_EDGE_MODAL,
    });
    DynamicLinkManager.handleDynamicLink(
      `${additional_data?.modal_action}`,
      this.navigateToScreen,
    );
  };

  navigateToScreen = (route, slug, path, extra = {}, params, url) => {
    const { navigation } = this.props;
    if (path === 'open_modal') {
      this.openJoinProModal();
      return;
    }
    navigation.navigate(route, { slug, extra, params, url });
  };

  openJoinProModal = () => {
    const {
      navigation,
      itemData = {},
      listData: { additional_data: args = {} } = {},
    } = this.props;
    const listAdditionalData = Utility.isPresent(args) ? args : {};
    navigation.navigate('JoinProModal', {
      itemData: itemData,
      modalAction: this.modalAction,
      modalType: listAdditionalData?.modal_type,
    });
  };

  onViewProductsTap = (url, id) => {
    const { navigation } = this.props;

    if (Utility.isBlank(url)) {
      return;
    }
    if (url.includes(this.chooseGiftIdentifier)) {
      navigation.push('ChooseFreeGiftScreen', {
        id,
      });
      return;
    }

    if (Utility.isPresent(url)) {
      DynamicLinkManager.handleDynamicLink(url, (route, slug, path, extra) => {
        navigation.push(this.navigationSource, route, { slug, extra });
      });
    }
  };

  render() {
    const {
      layout,
      skuId,
      theme,
      addToCartLayout,
      hideAddToCart,
      quantity,
      campaignId,
      isBuyNow,
      cartItem,
      additional_data = {},
      isOfferShimmerVisible,
      navigation,
      isFancyCard,
      multiVariantAttributes,
      can_offer_fullfill,
      cta_position_bottom,
      bag,
      previousScreen,
    } = this.props;
    const {
      promptData,
      loading,
      errorMessage,
      successfullyAppliedCoupon,
      unfulfilledCoupon,
      horizontal,
    } = this.state;

    if (hideAddToCart) {
      return null;
    }
    const { addedToCart, isRemoveProductConfirmationAlertVisible } = this.state;
    const bagText = PRODUCT.BAG_TEXT[addedToCart];

    if (Utility.isPresent(additional_data?.icon)) {
      return (
        <>
          <DebouncedTouchableOpacity
            {...this.props}
            style={ProductInCartStyles.fancyCardContainerStyle}
            onPress={this.itemAction}
            hitSlop={Utility.getHitSlop('addToCart')}
          >
            <FastImageView
              style={{ width: 24, height: 24 }}
              source={additional_data?.icon}
              tintColor={colors.white}
            />
          </DebouncedTouchableOpacity>
        </>
      );
    }

    const {
      coupon_code: appliedCouponCode = '',
      message: appliedCouponMessage = '',
      cta_text: appliedCouponCTA = '',
    } = successfullyAppliedCoupon;

    const couponAppliedActionTitle = Utility.isPresent(appliedCouponCTA)
      ? appliedCouponCTA
      : 'YAY !';
    const itemType =
      previousScreen === SCREEN_CONSTANTS.FREE_GIFTS
        ? 'freeItems'
        : 'cartItems';
    const isSkuAddedToCart = !!(
      bag && findKey(bag[itemType], (item) => item.sku_id === skuId)
    );

    return (
      <>
        <this.addToCartButton
          {...this.props}
          showAddedToCart={isSkuAddedToCart}
          bagText={bagText}
          theme={theme}
          skuId={skuId}
          layout={layout}
          addToCartLayout={addToCartLayout}
          quantity={quantity}
          campaignId={campaignId}
          isBuyNow={isBuyNow}
        />

        <FoxyAlert
          isVisible={Utility.isPresent(successfullyAppliedCoupon)}
          hideSecondButton
          alertBoxTitle={appliedCouponCode}
          alertMessage={appliedCouponMessage}
          firstButtonTitle={couponAppliedActionTitle}
          firstButtonOnPress={this.removeSuccessfullyAppliedCoupon}
          onTapOutside={this.removeSuccessfullyAppliedCoupon}
          autoWrapContent
          firstButtonTextColor={colors.cta.lightBlue}
          image_url={images.alert_message.uri}
          showJson
        />

        <FoxyAlert
          isVisible={isRemoveProductConfirmationAlertVisible}
          onTapOutside={this.closeRemoveProductConfirmationAlert}
          alertBoxTitle='Remove from bag?'
          alertMessage={'Are you sure you want to remove this?'}
          firstButtonTitle='Move to Wishlist'
          secondButtonTitle='Remove'
          image_url={cartItem?.image_url || cartItem?.card_image}
          firstButtonOnPress={this.addToWishlist}
          secondButtonOnPress={this.onRemoveFromCartPressFromCart}
          showImage
          firstButtonTextColor={colors.foxyBlue}
          autoWrapContent
        />

        <FoxyAlert
          isVisible={Utility.isPresent(errorMessage)}
          hideSecondButton
          alertBoxTitle='Oops'
          alertMessage={errorMessage}
          firstButtonTitle='Okay'
          firstButtonOnPress={this.onFirstButtonPressForErrorMessage}
          onTapOutside={this.onFirstButtonPressForErrorMessage}
          height={180}
          autoWrapContent
          firstButtonTextColor={colors.cta.lightBlue}
          image_url={images.alert_message.uri}
          showImage
          isAbsolute
          isError
          source={'add_to_cart'}
        />

        {isOfferShimmerVisible &&
          !isFancyCard &&
          cta_position_bottom !== '1' &&
          !can_offer_fullfill && (
            <FullWidthShimmer style={ProductCardStyles.fullWidthShimmer} />
          )}

        <Toast
          style={{ position: 'absolute', bottom: 20 }}
          ref={(ref) => {
            this.toast = ref;
          }}
        />
      </>
    );
  }
}

const mapStateToProps = (store, ownProps) => {
  const { skuId, id, previousScreen } = ownProps;
  const itemType =
    previousScreen === SCREEN_CONSTANTS.FREE_GIFTS ? 'freeItems' : 'cartItems';
  const isSkuAddedToCart = !!(
    store.bag && _.findKey(store.bag[itemType], (item) => item.sku_id === skuId)
  );
  const { bag = EMPTY_OBJECT } = store;
  return {
    freeItemCount: store.cart?.freeItems?.length ?? 0,
    authToken: store.UserAccountInfo.authToken,
    addedToCart: isSkuAddedToCart,
    cartItems: store.bag.cartItems,
    address: store.UserAccountInfo.addresses,
    favourites: store.UserAccountInfo.favourites,
    isOfferShimmerVisible: store.bag.isOfferShimmerVisible,
    bag: bag,
    addedToCartForCard:
      isSkuAddedToCart ||
      !!_.findKey(bag[itemType], (item) => {
        if (Utility.isPresent(item.product)) {
          return item.product?.id === id;
        }
        return false;
      }), // this gives added to cart true if any of the variant(skuid) of a product(product.id) is added to cart
  };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      addToCart,
      removeFromCart,
      viewItem,
      addFreeItemsToCart,
      removeFreeItemToCart,
      incrementDecrementCart,
      incrementDecrementCartBeforeCall,
      getCartItems,
      renderOfferShimmers,
      like,
      addAddedToCartProduct,
      applyCartCoupon,
      removeOffer,
      getCartPricing,
    },
    dispatch,
  ),
});

export default withProfiledNavigation(
  connect(mapStateToProps, mapDispatchToProps, null, {
    forwardRef: true,
  })(AddToCart),
);
