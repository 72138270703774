import React, { PureComponent } from 'react';
import { View, StyleSheet, Image } from 'react-native';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Grayscale } from '../../libraries/ReactNativeColorMatrixImageFilters';
import images from '../../theme/Images';
import Utility from '../../utils/Utility';
import colors from '../../theme/Colors';
import { MoEReactInbox } from '../../libraries/ReactMoe';
import { setShowEdgePendingIndicator } from '../../actions/ActionTypes';
import moment from 'moment';
import { getMinifiedImage } from '../../utils/ImageUtility';
import FastImageView from '../FastImageView';

const styles = StyleSheet.create({
  selectedBorder: {
    borderColor: '#EF8E8C',
    borderWidth: 2,
    borderRadius: 20,
    padding: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },

  imageStyle: {
    height: 34,
    width: 34,
    borderRadius: 17,
    overflow: 'hidden',
  },
  notificationIndication: {
    width: 4,
    height: 4,
    backgroundColor: colors.notificationCountPink,
    borderRadius: 2,
    marginTop: 2,
    alignSelf: 'center',
  },
});

class TheEdgeTabIcon extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showNotificationIndicator: false,
    };
    const date = new Date();

    const { setShowEdgePendingIndicator, showEdgePendingIndicator } =
      this.props;

    if (moment().weekday() == '5') {
      setShowEdgePendingIndicator(false);
    }

    setTimeout(() => {
      if (
        moment().weekday() == '5' &&
        date.getHours() >= 14 &&
        !showEdgePendingIndicator
      ) {
        setShowEdgePendingIndicator(true);
      }
    }, 50);
  }

  setNotificationCount = async () => {
    if (Utility.isWeb()) return;
    const count = (await MoEReactInbox.getUnClickedCount()) || 0;
    const { showNotificationIndicator } = this.state;
    const showIndication = count > 0;
    if (showIndication !== showNotificationIndicator) {
      this.setState({
        showNotificationIndicator: showIndication,
      });
    }
  };

  selectedImage = () => {
    const { selfieImage } = this.props;
    const { showNotificationIndicator } = this.state;
    let imageComponentSource = images.for_you_placeholder;
    if (Utility.isPresent(selfieImage)) {
      imageComponentSource = {
        uri: getMinifiedImage(selfieImage, styles.imageStyle.width, styles.imageStyle.height),
      };
    }
    return (
      <View>
        <View style={styles.selectedBorder}>
          <FastImageView source={imageComponentSource} style={styles.imageStyle} />
        </View>
        {showNotificationIndicator && (
          <View style={styles.notificationIndication} />
        )}
      </View>
    );
  };

  unselectedImage = () => {
    const { selfieImage } = this.props;
    const { showNotificationIndicator } = this.state;
    let imageComponentSource = images.for_you_placeholder;
    if (Utility.isPresent(selfieImage)) {
      imageComponentSource = {
        uri: getMinifiedImage(selfieImage, styles.imageStyle.width, styles.imageStyle.height),
      };
    }
    return (
      <View>
        <Grayscale>
          <FastImageView source={imageComponentSource} style={styles.imageStyle} />
        </Grayscale>
        {showNotificationIndicator && (
          <View style={styles.notificationIndication}/>
        )}
      </View>
    );
  };

  setTabIcon = () => {
    const { focused } = this.props;
    if (focused) {
      return <this.selectedImage />;
    }

    return <this.unselectedImage />;
  };

  render() {
    const { tintColor, image, focused, showEdgePendingIndicator } = this.props;
    return (
      <View testID="edge-tab-icon" accessibilityLabel="edge-tab-icon">
        <FastImageView
          source={image}
          style={{
            height: 24,
            width: 24,
            resizeMode: 'contain',
            tintColor,
          }}
        />
        {showEdgePendingIndicator && (
          <View
            style={{
              width: 6,
              height: 6,
              backgroundColor: colors.notificationCountPink,
              borderRadius: 3,
              marginTop: 2,
              alignSelf: 'center',
              position: 'absolute',
              right: 0,
              top: 0,
            }}
          />
        )}
      </View>
    );
  }
}

const mapStateToProps = function (state) {
  return {
    showEdgePendingIndicator: state.UserAccountInfo.showEdgePendingIndicator,
  };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      setShowEdgePendingIndicator,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(TheEdgeTabIcon);
