import React, { useCallback } from 'react';
import { Pressable } from 'react-native';
import { debounce } from 'lodash';

const DebouncedTouchableWithoutFeedback = (props) => {
  const { children } = props;
  const customOnPress = () => {
    const { onPress } = props;
    if (onPress) {
      onPress();
    }
  };

  const debouncedOnPress = useCallback(
    debounce(customOnPress, 200, { leading: true }),
  );

  return (
    <Pressable {...props} onPress={debouncedOnPress}>
      {children}
    </Pressable>
  );
};

export default DebouncedTouchableWithoutFeedback;

