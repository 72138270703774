import { TouchableOpacity, Image, Linking } from 'react-native';
import React from 'react';
import StyleSheet from 'react-native-media-query';
import images from '../../theme/Images';
import { isDesktop, isWebAndroid, isWebIOS } from '../../utils/BooleanUtility';
import { AnalyticsManager, EventType } from '../../analytics';
import { APP_DOWNLOAD_LINK, FOXY_URLS } from '../../config/Constants';
import colors from '../../theme/Colors';
import { CART_LEFT_CONTAINER_WIDTH_FOR_DESKTOP, getScreenWidth } from '../../utils/LayoutUtility';

export default function AppInstallPromptCard() {
  const handlePress = () => {
    if (isDesktop()) {
      Linking.openURL(APP_DOWNLOAD_LINK);
    } else if (isWebIOS() || isWebAndroid()) {
      Linking.openURL(FOXY_URLS.appInstallPage.inStock1);
    } else {
      Linking.openURL(APP_DOWNLOAD_LINK);
    }
  };

  return (
    <TouchableOpacity onPress={handlePress}>
      <Image
        source={{ uri: images.appInstallPrompt }}
        style={styles.image}
        dataSet={{ media: ids.image }}
      />
    </TouchableOpacity>
  );
}

export const { ids, styles } = StyleSheet.create({
  image: {
    width: getScreenWidth(),
    height: 120,
    marginTop: 10,
    '@media (min-width: 800px)': {
      width: CART_LEFT_CONTAINER_WIDTH_FOR_DESKTOP,
      height: CART_LEFT_CONTAINER_WIDTH_FOR_DESKTOP / 3,
    },
  },
});
