// Dependencies
import React, { Component, useState } from 'react';
import {
  View,
  TouchableOpacity,
  Text,
  NativeModules,
  Image,
} from 'react-native';
import StyleSheet from 'react-native-media-query';
import { SharedElement } from 'react-native-shared-element';
import { capitalize, debounce, memoize } from 'lodash';
import HTML from 'react-native-render-html';
import Config from '../../libraries/ReactNativeConfig';
import { ProductInfoStyles } from './styles';
import withNavigation from '../../utils/WithNavigation';
import colors from '../../theme/Colors';
import {
  AnalyticsManager,
  EventType,
  EventParameterKey,
} from '../../analytics';
import images from '../../theme/Images';
import { CURRENCY_SYMBOL } from '../../config/Constants';
import AppConfig from '../../config/AppConfig';
import {
  isBlank,
  isDesktop,
  isNative,
  isPresent,
  isWeb,
} from '../../utils/BooleanUtility';
import { getHitSlop, getScreenWidth } from '../../utils/LayoutUtility';
import { PRODUCT_RIGHT_CONTAINER_WIDTH } from '../../config/LayoutConstants/ProductConfig';
import RichContentHTMLView from './RichContent/RichContentHTMLView';
import { appendStyleToWebViewContent } from '../../utils/RNWebViewStyleUtils';

const { UIManager } = NativeModules;

UIManager.setLayoutAnimationEnabledExperimental &&
  UIManager.setLayoutAnimationEnabledExperimental(true);

class ProductDetailDescription extends Component {
  productDetailDescriptionConstants = {
    moreInfo: 'Everything you need to know',
    expiryDate: 'Expiry Date',
    soldBy: 'Sold By',
    padding: 24,
    initialDescriptionHeight: 156,
    descriptionText: 'description',
    howToText: 'how to use',
    ingredientsText: 'ingredients',
  };

  constructor(props) {
    super(props);
    this.state = {
      index: 0,
      description: props.description,
      howTo: props.howTo,
      ingredients: props.ingredients,
      height: 20,
      routes: [
        {
          key: this.productDetailDescriptionConstants.descriptionText,
          title: capitalize(
            this.productDetailDescriptionConstants.descriptionText,
          ),
        },
        {
          key: this.productDetailDescriptionConstants.howToText,
          title: capitalize(this.productDetailDescriptionConstants.howToText),
        },
        {
          key: this.productDetailDescriptionConstants.ingredientsText,
          title: capitalize(
            this.productDetailDescriptionConstants.ingredientsText,
          ),
        },
      ],

      showMoreButton: false,
    };

    this.viewRef = null;
    this.showMoreButtonEnabled = false;
    this.ignoredStyles = [
      'font-family',
      'line-height',
      'height',
      'width',
      'max-width',
      'text-transform',
      'padding',
    ];
    this.debounceNavigate = debounce(this.onPressReadMoreButton, 1000, {
      leading: true,
      trailing: false,
    });

    const {
      name,
      howTo = '',
      ingredients = '',
      description = '',
      itemData: { metrological_info = '' } = {},
    } = props;

    const cleanHTML = (htmlData) => {
      if (isBlank(htmlData)) return '';
      return htmlData.replace(/(\r\n|\n|\r|\--)/gm, '');
    };
    const htmlWithTitle = (title, html) => {
      if (isBlank(html)) return '';
      return `<h4>${title}</h4> <div>${cleanHTML(html)}</div> <br/>`;
    };

    this.finalHTML = `<div>${cleanHTML(description)}</div><br/>${htmlWithTitle(
      'Ingredients',
      ingredients,
    )}${htmlWithTitle('How To Use', howTo)}${htmlWithTitle(
      'Additional Info',
      metrological_info,
    )}`;
    this.finalHTML = { html: this.finalHTML };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.description !== prevProps.description) {
      let routes = [
        {
          key: this.productDetailDescriptionConstants.descriptionText,
          title: capitalize(
            this.productDetailDescriptionConstants.descriptionText,
          ),
        },
      ];
      if (this.props.howTo !== prevProps.howTo) {
        routes = [
          ...routes,
          {
            key: this.productDetailDescriptionConstants.howToText,
            title: capitalize(this.productDetailDescriptionConstants.howToText),
          },
        ];
      }
      if (this.props.ingredients !== prevProps.ingredients) {
        routes = [
          ...routes,
          {
            key: this.productDetailDescriptionConstants.ingredientsText,
            title: capitalize(
              this.productDetailDescriptionConstants.ingredientsText,
            ),
          },
        ];
      }
      this.setState({
        routes,
      });
    }
  }

  fireShowMoreAnalytics = (isExpanded) => {
    const { itemData, selectedVariant } = this.props;
    if (isBlank(itemData)) {
      return;
    }
    AnalyticsManager.logEvent(isExpanded ? EventType.product.PRODUCT_DESCRIPTION_COLLAPSED : EventType.product.PRODUCT_DESCRIPTION_EXPANDED, {
      [EventParameterKey.PRODUCT_ID]: itemData.id,
      [EventParameterKey.PRODUCT_NAME]: itemData.name,
      [EventParameterKey.VARIANT_NAME]: isPresent(selectedVariant)
        ? selectedVariant.display_name
        : '',
      [EventParameterKey.VARIANT_ID]: isPresent(selectedVariant)
        ? selectedVariant.id
        : '',
      [EventParameterKey.BRAND_ID]: isPresent(itemData.brand)
        ? itemData.brand.id
        : '',
      [EventParameterKey.BRAND_NAME]: isPresent(itemData.brand)
        ? itemData.brand.name
        : '',
      [EventParameterKey.PRODUCT_LISTING_PRICE]: itemData.mrp,
      [EventParameterKey.PRODUCT_SELLING_PRICE]: itemData.sp,
    });
  };

  renderTabViews = ({ route, jumpTo }) => {
    const { description, howTo, ingredients } = this.props;
    switch (route.key) {
      case this.productDetailDescriptionConstants.descriptionText:
        return (
          <this.htmlView
            jumpTo={jumpTo}
            htmlData={`<div>${description}</div>`}
            showAboutHeader
          />
        );
      case this.productDetailDescriptionConstants.howToText:
        return (
          <this.htmlView jumpTo={jumpTo} htmlData={`<div>${howTo}</div>`} />
        );
      case this.productDetailDescriptionConstants.ingredientsText:
        return (
          <this.htmlView
            jumpTo={jumpTo}
            htmlData={`<div>${ingredients}</div>`}
          />
        );
      default:
        return null;
    }
  };

  onPressWebViewContainer = () => {
    if (this.showMoreButtonEnabled) return;
    this.onPressMoreClick('single_tap');
  };

  onPressReadMoreButton = () => {
    this.props.navigation.push('FullPageProductDescription', {
      itemData: this.props.itemData,
    });
  };

  htmlView = (props) => {
    const {
      name,
      howTo = '',
      ingredients = '',
      description = '',
      itemData: { metrological_info = '' } = {},
      scrollToClosedReadMoreSection = () => {},
    } = this.props;
    const [isExpanded, setIsExpanded] = useState(false);
    const [shouldShowMoreButton, setShouldShowMoreButton] = useState(false);
    const [descriptionBoxHeight, setDescriptionBoxHeight] = useState(240);
    const [expandedViewHeight, setExpandedViewHeight] = useState(0);
    const { htmlData, showAboutHeader } = props;
    const toggleIsExpanded = () => {
      this.fireShowMoreAnalytics(isExpanded);
      setIsExpanded(!isExpanded);
    };
    const findDimensions = (layout) => {
      this.showMoreButtonEnabled = !(
        layout.height < 240 && shouldShowMoreButton
      );

      if (
        !this.showMoreButtonEnabled &&
        (isPresent(howTo) || isPresent(ingredients))
      ) {
        this.showMoreButtonEnabled = true;
      }
      setShouldShowMoreButton(this.showMoreButtonEnabled);
      if (isNative()) {
        setDescriptionBoxHeight(layout.height);
      }
    };

    const onLayout = (event) => {
      if (isExpanded) {
        setExpandedViewHeight(event.nativeEvent.layout.height);
      } else if (expandedViewHeight > 0) {
        setExpandedViewHeight(0);
        scrollToClosedReadMoreSection();
      }
      findDimensions(event.nativeEvent.layout);
    };

    if (isBlank(htmlData)) {
      return null;
    }

    const descriptionHeight = isExpanded ? null : descriptionBoxHeight;
    const itemData = {
      contents: [`${htmlData}`],
    };
    const readMoreText = isExpanded ? 'Show Less' : 'Read More';
    const styles = ProductInfoStyles;
    const htmlContainerStyle =
      descriptionHeight > 240
        ? styles.htmlContainer
        : memoize(
            () => {
              return [styles.htmlContainer, { maxHeight: descriptionHeight }];
            },
            () => [descriptionHeight],
          )();
    const onPress = isWeb() ? toggleIsExpanded : this.debounceNavigate;
    const contentWidth = isDesktop() ? getScreenWidth() / 2 : getScreenWidth();
    const chevron = isExpanded ? images.chevronUp : images.chevronRight;
    return (
      <TouchableOpacity onPress={onPress}>
        <View
          style={descriptionStyle.htmlViewContainer}
          dataSet={{ media: ids.htmlViewContainer }}
        >
          <View onLayout={onLayout}>
            {isWeb() ? (
              <HTML
                source={this.finalHTML}
                ignoredStyles={this.ignoredStyles}
                containerStyle={htmlContainerStyle}
                tagsStyles={webViewStyle}
                contentWidth={contentWidth}
                imagesInitialDimensions={getScreenWidth() - 12}
              />
            ) : (
              <RichContentHTMLView
                itemData={itemData}
                containerStyle={htmlContainerStyle}
                tagsStyles={webViewStyle}
                paddingRight={20}
                initialHeight={descriptionHeight}
                enableBottomPadding={false}
              />
            )}
          </View>
          {shouldShowMoreButton ? (
            <TouchableOpacity
              onPress={onPress}
              style={ProductInfoStyles.moreButtonWrapper}
              dataSet={{ media: ids.moreButtonWrapper }}
            >
              <View
                style={ProductInfoStyles.moreButtonContainer}
                dataSet={{ media: ids.moreButtonContainer }}
              >
                <Text style={styles.readMoreText}>{readMoreText}</Text>
                <Image source={chevron} style={styles.chevronDown} />
              </View>
            </TouchableOpacity>
          ) : null}
        </View>
      </TouchableOpacity>
    );
  };

  moreInfoView = () => {
    const { productPage = {} } = this.props;
    const styles = ProductInfoStyles;
    return (
      <View style={styles.deliveryAndReturnsContainer}>
        <Text style={styles.productInfoCardHeaderText}>
          {productPage?.description?.heading || ''}
        </Text>
      </View>
    );
  };

  onPressMoreClick = () => {
    const { onPressMore } = this.props;
    setTimeout(onPressMore, 0);
  };

  setViewRef = (ref) => {
    if (this.viewRef) return;
    const { setDescriptionBoxRef } = this.props;
    this.viewRef = ref;
    setDescriptionBoxRef(this.viewRef);
  };

  bestBefore = () => {
    if (
      AppConfig.getBooleanValue(Config.DISABLE_TREND_TEXT_ON_PRODUCT_DETAIL)
    ) {
      return null;
    }
    const {
      sku: {
        best_before,
        expiry_date,
        best_price: { period, price } = {},
        id,
      } = {},
      displaySp,
      sendAlertForPrice,
    } = this.props;

    if (isBlank(best_before) && isBlank(expiry_date) && isBlank(period)) {
      return null;
    }

    const trendingText =
      parseFloat(displaySp) <= parseFloat(price)
        ? `Lowest price in last ${period} days`
        : `Was ${CURRENCY_SYMBOL}${
            parseFloat(displaySp) - parseFloat(price)
          } cheaper in last ${period} days`;

    return (
      <View>
        <View
          style={descriptionStyle.container}
          dataSet={{ media: ids.container }}
        />
        <View>
          {!!period && isPresent(parseFloat(displaySp) - parseFloat(price)) && (
            <View style={descriptionStyle.innerContainer}>
              <View style={descriptionStyle.chevronContainer}>
                <Image
                  style={descriptionStyle.downChevron}
                  source={images.trendingDown}
                />
                <Text style={descriptionStyle.trendingText}>
                  {trendingText}
                </Text>
              </View>
              {!(parseFloat(displaySp) <= parseFloat(price)) && (
                <TouchableOpacity
                  onPress={this.onSetAlertPress}
                  style={descriptionStyle.alertContainer}
                  hitSlop={getHitSlop()}
                >
                  <Text style={descriptionStyle.alertText}>Set alert</Text>
                  <Image
                    style={descriptionStyle.alertImage}
                    source={images.alert}
                  />
                </TouchableOpacity>
              )}
            </View>
          )}
          <View>
            {(!!best_before || !!expiry_date) && (
              <View style={descriptionStyle.bottomContainer}>
                <Image
                  style={descriptionStyle.calanderIcon}
                  source={images.featherCalendar}
                />
                <Text style={descriptionStyle.bestBeforeHeading}>{`${
                  !!best_before ? 'Best before' : 'Expiry date'
                } `}</Text>
                <Text style={descriptionStyle.bestBefore}>
                  {isPresent(best_before) ? best_before : expiry_date || ''}
                </Text>
              </View>
            )}
          </View>
        </View>
      </View>
    );
  };

  onSetAlertPress = () => {
    const {
      sku: { id } = {},
      sendAlertForPrice,
      itemData,
      selectedVariant,
    } = this.props;
    sendAlertForPrice(id);
    AnalyticsManager.logEvent(EventType.product.SET_ALERT, {
      [EventParameterKey.PRODUCT_ID]: itemData.id,
      [EventParameterKey.PRODUCT_NAME]: itemData.name,
      [EventParameterKey.VARIANT_NAME]: isPresent(selectedVariant)
        ? selectedVariant.display_name
        : '',
      [EventParameterKey.VARIANT_ID]: isPresent(selectedVariant)
        ? selectedVariant.id
        : '',
      [EventParameterKey.BRAND_ID]: isPresent(itemData.brand)
        ? itemData.brand.id
        : '',
      [EventParameterKey.BRAND_NAME]: isPresent(itemData.brand)
        ? itemData.brand.name
        : '',
      [EventParameterKey.PRODUCT_LISTING_PRICE]: itemData.mrp,
      [EventParameterKey.PRODUCT_SELLING_PRICE]: itemData.sp,
    });
  };

  onDescriptionLayout = (e) => {
    const { updateReadMoreLayout = () => {} } = this.props;
    updateReadMoreLayout(e);
    console.tron.log('description layout', e.nativeEvent.layout);
  };

  render() {
    const { description, expiryDate, bestBefore, howTo, ingredients } =
      this.props;

    if (isBlank(description)) {
      return null;
    }
    let textToShow = null;
    if (isPresent(expiryDate)) {
      textToShow = `EXPIRY DATE: ${expiryDate}`;
    } else if (isPresent(bestBefore)) {
      textToShow = `BEST BEFORE: ${bestBefore}`;
    }
    const styles = ProductInfoStyles;
    return (
      <View
        style={styles.descriptionContainer}
        onLayout={this.onDescriptionLayout}
      >
        <this.moreInfoView />
        <View style={styles.infoContainer} overFlow='hidden'>
          {isPresent(textToShow) && (
            <Text style={styles.expiryDateText}>{textToShow}</Text>
          )}
          <SharedElement id={this.props.itemData.id}>
            <this.htmlView
              title=''
              htmlData={`${appendStyleToWebViewContent()}${description}`}
            />
          </SharedElement>

          <this.bestBefore />
        </View>
      </View>
    );
  }
}

const { ids, styles: descriptionStyle } = StyleSheet.create({
  containerStyle: {
    borderRadius: 4,
    margin: 8,
    marginTop: 12,
    overflow: 'hidden',
    maxHeight: 240,
  },
  bestBefore: {
    fontSize: 14,
    fontFamily: 'Roboto-Bold',
    color: colors.foxyBlack,
    marginLeft: 4,
  },
  bestBeforeHeading: {
    fontSize: 14,
    fontFamily: 'Roboto-Regular',
    color: colors.foxyBlack,
  },
  calanderIcon: {
    height: 16,
    width: 16,
    resizeMode: 'contain',
    marginRight: 8,
  },
  bottomContainer: {
    flexDirection: 'row',
    marginTop: 12,
    alignItems: 'center',
  },
  alertImage: { height: 16, width: 16, resizeMode: 'contain', marginLeft: 8 },
  alertText: {
    fontSize: 14,
    fontFamily: 'Roboto-Medium',
    color: colors.cta.lightBlue,
  },
  alertContainer: { flexDirection: 'row', alignItems: 'center' },
  trendingText: {
    fontSize: 14,
    fontFamily: 'Roboto-Regular',
    color: colors.foxyBlack,
  },
  downChevron: { height: 16, width: 16, resizeMode: 'contain', marginRight: 8 },
  chevronContainer: { flexDirection: 'row', alignItems: 'center' },
  innerContainer: {
    flexDirection: 'row',
    marginTop: 12,
    justifyContent: 'space-between',
  },
  container: {
    width: getScreenWidth() - 24,
    height: 1,
    backgroundColor: '#DADADA',
    alignSelf: 'center',
    marginHorizontal: 12,
    marginTop: 16,
    marginBottom: 4,
    '@media (min-width: 768px)': {
      width: PRODUCT_RIGHT_CONTAINER_WIDTH - 24,
    },
  },
  htmlViewContainer: {
    width: getScreenWidth() - 24,
    '@media (min-width: 768px)': {
      width: PRODUCT_RIGHT_CONTAINER_WIDTH - 24,
    },
  },
  moreButtonWrapper: {
    '@media (min-width: 768px)': {
      width: PRODUCT_RIGHT_CONTAINER_WIDTH - 30,
    },
  },
  moreButtonContainer: {
    '@media (min-width: 768px)': {
      width: PRODUCT_RIGHT_CONTAINER_WIDTH - 30,
    },
  },
});

const { styles: webViewStyle } = StyleSheet.create({
  a: {
    color: colors.foxyPink,
  },
  p: { color: colors.foxyBlack },
  li: { color: colors.foxyBlack },
  ol: { color: colors.foxyBlack },
  ul: { color: colors.foxyBlack },
  div: { color: colors.foxyBlack },
  span: { color: colors.foxyBlack },
  br: { color: colors.foxyBlack },
});

export default withNavigation(ProductDetailDescription);
