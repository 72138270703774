import React, { Component } from 'react';
import {
  View,
  KeyboardAvoidingView,
  TouchableWithoutFeedback,
  TouchableOpacity,
  Image,
  Text,
  Keyboard,
  ScrollView,
  ActivityIndicator,
  FlatList,
} from 'react-native';
import Config from '../../libraries/ReactNativeConfig';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import { TextField } from 'react-native-material-textfield';
import Toast from 'react-native-easy-toast';
import { PromoCodeStyles, CartStyles, promoCodeIds } from './styles';
import images from '../../theme/Images';
import colors from '../../theme/Colors';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import { applyCartCoupon, removeOffer } from '../../actions/ActionTypes';
import ActionButton from '../shared/ActionButton';
import {
  AnalyticsManager,
  EventType,
  EventParameterKey,
  EventParameterValue,
} from '../../analytics';
import { OFFER_APPLIED_BY } from '../../config/Constants';
import size from '../../theme/Fonts';
import { withEither } from '../../lib/Monads';
import CouponCodePill from './CouponCodePill';
import FoxyDefaultCheckBox from '../shared/FoxyDefaultCheckBox';
import StaticNavigationHeader from '../../components/shared/StaticNavigationHeader';
import FoxyAlert from '../camera/shared/FoxyAlert';
import DynamicLinkManager from '../../utils/DynamicLinkManager';
import DebouncedTouchableOpacity from '../shared/DebouncedTouchableOpacity';
import FastImageView from '../FastImageView';
import { isDesktop, isBlank } from '../../utils/BooleanUtility';
import { navigateToScreen } from '../../utils/NavigationUtility';
import { getMinifiedImage } from '../../utils/ImageUtility';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import { getScreenWidth } from '../../utils/LayoutUtility';

class PromoCode extends Component {
  promoCodeConstants = {
    headerText: 'Apply Offers',
    promocodeTextField: 'Enter Coupon Code',
  };

  constructor(props) {
    super(props);
    this.ref = [];
    this.ref.promocode = React.createRef();
    this.bottomDockHeight = 70;
    this.state = {
      promoCode: '',
      codeError: '',
      appliedOfferObject: [],
      offerAppliedThroughKeyboard: false,
      isFocused: false,
      notClubbableCouponCode: '',
      notClubbableOfferId: '',
      notClubbableWith: {},
      errorMessage: '',
      unfulfilledCoupon: {},
      successfullyAppliedCoupon: {},
      nonClubbableOfferMeta: {},
    };
  }

  goBack = () => {
    const { hidePromoCodeScreen } = this.props;
    this.setState({
      offerAppliedThroughKeyboard: false,
      promoCode: '',
      codeError: '',
    });
    hidePromoCodeScreen();
    this.dismissKeyboard();
    setTimeout(() => {
      this.scrollListReftop.scrollTo({ x: 0, y: 0, animated: true });
    }, 500);
  };

  componentDidMount = () => {
    const { route } = this.props;
    const promoCodeThroughDynamicLink =
      route.params?.extra?.promoCodeThroughDynamicLink;
    if (Utility.isPresent(promoCodeThroughDynamicLink)) {
      this.setState({
        promoCode: promoCodeThroughDynamicLink,
      });
      setTimeout(() => {
        this.applyOfferButtonTapped();
      }, 200);
    }
  };

  UNSAFE_componentWillMount = () => {
    const { fireCartViewEvent = () => {} } = this.props;
    fireCartViewEvent();
  };

  fireOfferAppliedAnalytics = (appliedBy) => {
    const { promoCode, appliedOfferObject } = this.state;
    AnalyticsManager.logEvent(EventType.offer.OFFER_APPLIED, {
      [EventParameterKey.SOURCE]: EventParameterValue.SOURCE.CART,
      [EventParameterKey.COUPON_CODE]: promoCode,
      [EventParameterKey.OFFER_ID]: Utility.isPresent(appliedOfferObject)
        ? appliedOfferObject.id
        : '',
      [EventParameterKey.OFFER_TYPE]: Utility.isPresent(appliedOfferObject)
        ? appliedOfferObject.offer_type
        : '',
      [EventParameterKey.OFFER_APPLIED_BY]: appliedBy,
    });

    AnalyticsManager.logFirebaseEvent(
      EventType.googleRemarketingEvents.SELECT_PROMOTION,
      {
        promotion_name: Utility.isPresent(promoCode) ? promoCode : '',
        promotion_id: Utility.isPresent(appliedOfferObject)
          ? appliedOfferObject.id
          : '',
        items: [],
      },
    );
  };

  fireOfferApplicationFailureAnalytics = () => {
    const { promoCode, appliedOfferObject, codeError } = this.state;
    AnalyticsManager.logEvent(EventType.offer.OFFER_APPLICATION_FAILURE, {
      [EventParameterKey.SOURCE]: EventParameterValue.SOURCE.CART,
      [EventParameterKey.COUPON_CODE]: promoCode,
      [EventParameterKey.OFFER_ID]: Utility.isPresent(appliedOfferObject)
        ? appliedOfferObject.id
        : '',
      [EventParameterKey.OFFER_TYPE]: Utility.isPresent(appliedOfferObject)
        ? appliedOfferObject.offer_type
        : '',
      [EventParameterKey.ERROR]: codeError,
    });
  };

  removeAppliedOffer = (couponCode) => {
    const { removeOffer } = this.props;

    this.renderLoader(true);

    AnalyticsManager.logEvent(EventType.offer.OFFER_REMOVE, {
      coupon_code: couponCode,
    });
    removeOffer(couponCode, (success) => {
      this.renderLoader(false);
      if (!success) {
        this.toast.show('Something went wrong');
      } else {
        this.toast.show('Offer removed');
      }
    });
  };

  navigateToClubbableModal = () => {
    const { navigation } = this.props;
    const {
      notClubbableCouponCode,
      notClubbableOfferId,
      notClubbableWith,
      nonClubbableOfferMeta,
    } = this.state;
    navigation.navigate(SCREEN_CONSTANTS.CLUBBABLE_MODAL, {
      notClubbableCouponCode,
      navigation,
      notClubbableOfferId,
      notClubbableWith,
      applyOffer: this.applyOffer,
      nonClubbableOfferMeta,
    });
  };

  offerNotClubbable = (
    notClubbableCouponCode,
    notClubbableOfferId,
    notClubbableWith,
    nonClubbableOfferMeta,
  ) => {
    this.setState(
      {
        notClubbableCouponCode,
        notClubbableOfferId,
        notClubbableWith,
        nonClubbableOfferMeta,
      },
      this.navigateToClubbableModal,
    );
  };

  navigateToUnfulfilledModal = () => {
    const { navigation } = this.props;
    const { unfulfilledCoupon } = this.state;
    if (isBlank(unfulfilledCoupon)) return;
    navigation.navigate(SCREEN_CONSTANTS.UNFULFILLED_OFFER_MODAL, {
      navigation,
      itemData: unfulfilledCoupon,
      navigateToOfferDetail: this.navigateToOfferDetail,
    });
  };

  setUnfulfilledCoupon = (data) => {
    this.setState(
      {
        unfulfilledCoupon: data,
      },
      this.navigateToUnfulfilledModal,
    );
  };

  setSuccessfullyAppliedCoupon = (data) => {
    this.setState({
      successfullyAppliedCoupon: data,
    });
  };

  removeSuccessfullyAppliedCoupon = () => {
    this.setState({
      successfullyAppliedCoupon: {},
    });
  };

  applyOfferButtonTapped = (item = {}) => {
    const { applyCartCoupon, applyOffer, navigation } = this.props;
    const { promoCode, offerAppliedThroughKeyboard } = this.state;

    this.renderLoader(true);
    applyCartCoupon(
      promoCode?.toUpperCase().trim(),
      (success, callbackData) => {
        this.renderLoader(false);

        if (Utility.isBlank(callbackData)) return;

        if (success) {
          this.fireOfferAppliedAnalytics(OFFER_APPLIED_BY.USER_APPLIED);

          const appliedCoupon = callbackData?.prompts?.find(
            (coupon) => coupon.coupon_code === promoCode?.toUpperCase().trim(),
          );
          AnalyticsManager.logEvent(EventType.artistEvents.MODAL_VIEW, {
            modal_name: 'success_offer',
            offer_id: callbackData.offer_id,
          });
          this.setSuccessfullyAppliedCoupon(appliedCoupon);
        } else {
          if (callbackData.app_only) {
            navigation.navigate('ApplyOnAppOfferModal', {
              couponCode: item?.coupon_code || promoCode?.toUpperCase().trim(),
              message: item?.short_description,
              offerId: item.id,
            });
            return;
          }
          if (!!callbackData?.condition_unfullfilled) {
            this.setUnfulfilledCoupon(callbackData);
            AnalyticsManager.logEvent(EventType.artistEvents.MODAL_VIEW, {
              modal_name: 'unfulfilled_offer',
              offer_id: callbackData.offer_id,
              cta: callbackData.cta_text,
            });
            return;
          }
          if (callbackData.clubbable === false) {
            const offerPrompts = [];
            _.forEach(callbackData.offers_prompts, (e) => {
              offerPrompts.push(e.offer_id);
            });
            AnalyticsManager.logEvent(EventType.artistEvents.MODAL_VIEW, {
              modal_name: 'replace_offer',
              new_offer_id: callbackData.offer_id,
              old_offer_ids: offerPrompts.toString(),
            });
            this.offerNotClubbable(
              callbackData.coupon_code,
              callbackData.offer_id,
              callbackData.offers_prompts,
              {
                old_cart_value: callbackData.old_cart_value,
                new_cart_value: callbackData.new_cart_value,
              },
            );

            return;
          }
          if (
            !Utility.isBlank(callbackData.errors) &&
            callbackData.errors.length > 0
          ) {
            if (offerAppliedThroughKeyboard) {
              this.setState({
                codeError: callbackData.errors[0],
              });
            } else if (Utility.isBlank(promoCode)) {
              this.setState({ codeError: 'Please enter coupon code' });
            } else {
              AnalyticsManager.logEvent(EventType.offer.OFFER_ERROR_MODAL, {
                offer_id: callbackData?.offer_id,
                reason: callbackData?.errors[0] || '',
              });
              this.setState({
                errorMessage: `${callbackData.errors[0]}`,
              });
            }
          }
          this.fireOfferApplicationFailureAnalytics();
        }
        // hitApis();
      },
    );
  };

  showMembershipUpgradeModal = () => {
    const {
      item: {
        invalid_modal_data: invalidModalData = {},
        invalid_modal_data: {
          data: { minimum_edge_priority: minimumEdgePriority = -1 },
        },
        navigation,
      },
      membership_cohort,
    } = this.props;
    if (Utility.isBlank(invalidModalData)) return;
    if (membership_cohort === 'member') {
      navigation.push('UpgradeEdgeModal', {
        previousScreen: 'offers',
        invalidModalData,
      });
    } else {
      navigation.push('LoyaltyPlans', {
        minimumEdgePriority,
      });
    }
  };

  addPromoCode = (item, isCouponAlreadyApplied = false) => {
    const {
      item: {
        initiate_edge_activation,
        show_invalidity_modal,
        navigation,
      } = {},
      membership_cohort,
      is_upgradable,
    } = this.props;

    if (initiate_edge_activation && membership_cohort !== 'member') {
      navigation.push(SCREEN_CONSTANTS.loyalty_plans, {
        previousScreen: 'offers',
      });
      return;
    }

    if (
      show_invalidity_modal &&
      (membership_cohort !== 'member' || is_upgradable)
    ) {
      this.showMembershipUpgradeModal();
      return;
    }

    if (isCouponAlreadyApplied) {
      this.removeAppliedOffer(item?.coupon_code?.toUpperCase());
      return;
    }
    this.setState({
      promoCode: item.coupon_code,
      codeError: '',
      appliedOfferObject: item,
      offerAppliedThroughKeyboard: false,
    });
    setTimeout(() => {
      this.applyOfferButtonTapped(item);
    }, 0);
  };

  addPromoCodeFromText = (promoCode) => {
    this.setState({
      promoCode,
      codeError: '',
      appliedOfferObject: {},
      offerAppliedThroughKeyboard: true,
    });
  };

  dismissKeyboard = () => {
    Keyboard.dismiss();
  };

  showPromoCodeError = (props) => {
    const { coupon } = props;

    if (
      Utility.isBlank(coupon) ||
      (Utility.isPresent(coupon) && Utility.isBlank(coupon[0].prompt)) ||
      (Utility.isPresent(coupon) &&
        Utility.isPresent(coupon[0].prompt) &&
        Utility.isBlank(coupon[0].prompt.format))
    ) {
      return null;
    }
    return (
      <View>
        <Text
          style={{
            fontFamily: 'Roboto-Regular',
            fontSize: size.h4,
            color: colors.foxyPink,
          }}
        >
          {coupon[0].prompt.format}
        </Text>
      </View>
    );
  };

  promocodeBox = (props) => {
    const { item, isApplied = false } = props;
    const { appliedCouponCodes, offersPrompt } = this.props;
    const styles = PromoCodeStyles;

    const couponApplied = appliedCouponCodes
      ? appliedCouponCodes.filter(
          (element) => element.coupon_code === item.coupon_code,
        )
      : false;

    if (Utility.isPresent(couponApplied) && !isApplied) {
      return null;
    }

    let promptMessage = _.find(offersPrompt, (e) => {
      return e.coupon_code === item.coupon_code;
    });

    if (!isApplied) {
      promptMessage = {};
    }

    return (
      <TouchableOpacity
        onPress={() =>
          this.addPromoCode(item, Utility.isPresent(couponApplied))
        }
        style={styles.promocodeBox}
      >
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            marginBottom: 8,
          }}
        >
          <View style={styles.radioButtonContainer}>
            <FoxyDefaultCheckBox
              checked={Utility.isPresent(couponApplied)}
              imageStyle={{ height: 15, width: 15 }}
              containerStyle={{ height: 15, width: 15 }}
            />
          </View>
          <CouponCodePill
            couponCode={item.coupon_code}
            style={styles.promoIdText}
          />
        </View>
        {Utility.isPresent(promptMessage) && (
          <Text
            style={styles.promoTitle}
            numberOfLines={2}
            ellipsizeMode="tail"
          >
            {promptMessage.message}
          </Text>
        )}
        <Text style={styles.promoDescription}>{item.short_description}</Text>
        {Utility.isPresent(item.image_url) && !isApplied ? (
          <FastImageView
            resizeMode="stretch"
            source={getMinifiedImage(item.image_url, isDesktop() ? 1054 : getScreenWidth() - 24, isDesktop() ? 350 : (1 / 3) * (getScreenWidth() - 24))}
            style={styles.bannerImage}
          />
        ) : null}

        <this.showPromoCodeError coupon={couponApplied} />

        {promptMessage?.status !== 'redeemed' && (
          <DebouncedTouchableOpacity
            {...this.props}
            style={styles.ctaTextContainer}
            onPress={() =>
              this.navigateToOfferDetail(
                promptMessage?.cta_url,
                promptMessage?.offer_id,
              )
            }
            hitSlope={Utility.getHitSlop('button')}
          >
            <Text style={styles.ctaText}>
              {promptMessage?.cta_text?.toUpperCase() || ''}
            </Text>
          </DebouncedTouchableOpacity>
        )}
      </TouchableOpacity>
    );
  };

  handleFocus = () => this.setState({ isFocused: true });

  offersList = (props) => {
    const { cartOffers } = props;

    if (Utility.isBlank(cartOffers)) {
      return null;
    }

    return cartOffers.map((item, index) => (
      <this.promocodeBox item={item} isApplied={false} />
    ));
  };

  divider = () => <View style={PromoCodeStyles.divider} />;

  bottomButtonDock = (props) => {
    const styles = PromoCodeStyles;
    const { promoCode } = this.state;

    if (Utility.isBlank(promoCode)) {
      return null;
    }
    return (
      <View
        style={[
          styles.actionBottonContainer,
          {
            height: this.bottomDockHeight + Utility.bottomInset,
            paddingBottom: 8,
          },
        ]}
      >
        <ActionButton
          onActionPress={this.applyOfferButtonTapped}
          title="Apply"
          actionButtonColor={colors.primaryActionBackgroundColor}
        />
      </View>
    );
  };

  isOffersLoading = (props) => props.isOffersLoading;

  activityIndicator = () => {
    const styles = PromoCodeStyles;
    return (
      <View style={styles.activityIndicatorContainer}>
        <ActivityIndicator
          animating={true}
          color={colors.foxyBlack}
          size="large"
          style={{ marginBottom: 10 }}
        />
        <Text
          style={{
            fontFamily: 'Roboto-Regular',
            fontSize: size.h3,
            color: colors.foxyBlack,
          }}
        >
          Loading best offers for you
        </Text>
      </View>
    );
  };

  applyOffer = (code) => {
    const { applyCartCoupon, navigation } = this.props;
    applyCartCoupon(
      code,
      (success, callbackData) => {
        const appliedCoupon = callbackData?.prompts?.find(
          (coupon) => coupon.coupon_code === code,
        );
        if (success) {
          this.setSuccessfullyAppliedCoupon(appliedCoupon);
        }
      },
      false,
      true,
    );
  };

  availableOffersListView = (props) => {
    const styles = PromoCodeStyles;
    const { offersAvailable: { title = '', offers = [] } = {} } = props;
    const { appliedCouponCodes = [] } = this.props;

    if (Utility.isPresent(offers)) {
      return (
        <>
          {offers.length > appliedCouponCodes.length && (
            <Text style={styles.subHeading}>{title}</Text>
          )}
          <this.offersList cartOffers={offers} />
        </>
      );
    }
    return null;
  };

  getComponent = ({ item }) => {
    return <this.availableOffersListView offersAvailable={item} />;
  };

  getAppliedOffersComponent = ({ item, index }) => {
    return <this.promocodeBox item={item} isApplied />;
  };

  appliedOffersHeader = () => {
    const { appliedCouponCodes } = this.props;
    if (Utility.isBlank(appliedCouponCodes)) {
      return null;
    }
    return <Text style={PromoCodeStyles.subHeading}>APPLIED OFFERS</Text>;
  };

  offersListView = () => {
    const styles = PromoCodeStyles;
    const { cartOffers: { offer_sections = [] } = {}, appliedCouponCodes } =
      this.props;

    return (
      <ScrollView
        style={styles.promoCodeScrollView}
        ref={(ref) => {
          this.scrollListReftop = ref;
        }}
      >
        <FlatList
          data={appliedCouponCodes}
          scrollEnabled
          extraData={this.props}
          showsVerticalScrollIndicator={false}
          keyExtractor={(item) => item.coupon_code}
          renderItem={this.getAppliedOffersComponent}
          initialNumToRender={4}
          ListHeaderComponent={this.appliedOffersHeader}
        />
        <FlatList
          data={offer_sections}
          scrollEnabled
          extraData={this.props}
          showsVerticalScrollIndicator={false}
          keyExtractor={(item) => item.title}
          renderItem={this.getComponent}
          initialNumToRender={4}
        />
        <View style={styles.spacer} />
      </ScrollView>
    );
  };

  promoCodeInputFieldAccessory = () => {
    return (
      <TouchableWithoutFeedback
        onPress={this.applyOfferButtonTapped}
        hitSlop={Utility.getHitSlop()}
      >
        <Text style={PromoCodeStyles.couponInput} dataSet={{ media: promoCodeIds.couponInput }}>
          Apply
        </Text>
      </TouchableWithoutFeedback>
    );
  };

  renderLoader = (isLoaderVisible) => {
    this.setState({
      isLoaderVisible,
    });
  };

  availableOffersOrActivityIndicator = withEither(
    this.isOffersLoading,
    this.activityIndicator,
  )(this.offersListView);

  navigateToOfferDetail = (url, id) => {
    const { navigation } = this.props;

    if (Utility.isBlank(url)) {
      return;
    }

    if (url.includes('choose_free_gift')) {
      navigation.push('ChooseFreeGiftScreen', {
        id,
      });
      return;
    }

    if (Utility.isPresent(url)) {
      if (url === Config.HTTPS_DOMAIN_NAME) {
        navigation.navigate('Feed');
      }

      DynamicLinkManager.handleLinkWithInternalTrackingParams(
        url,
        (route, slug, path, extra) => {
          navigateToScreen({
            navigation,
            type: 'push',
            screen: route,
            params: { slug, extra },
          });
        },
      );
    }
  };

  onFirstButtonPressForErrorMessage = () => {
    this.setState({
      errorMessage: '',
    });
  };

  closeSuccessOfferAppliedModal = () => {
    this.setState({
      successfullyAppliedCoupon: '',
    });
  };

  onFirstButtonPressForSuccessfulCoupon = () => {
    const { successfullyAppliedCoupon } = this.state;
    const { navigation } = this.props;
    this.setState({
      successfullyAppliedCoupon: '',
    });
    navigation.goBack();
    this.navigateToOfferDetail(
      successfullyAppliedCoupon?.cta_url,
      successfullyAppliedCoupon?.offer_id,
    );
  };

  render() {
    const styles = PromoCodeStyles;
    const {
      promoCode,
      codeError,
      offerAppliedThroughKeyboard,
      isFocused,
      isLoaderVisible,
      errorMessage,
      successfullyAppliedCoupon,
      unfulfilledCoupon,
    } = this.state;
    const { isOffersLoading, navigation } = this.props;

    let borderBottomBorder;
    if (isFocused) {
      borderBottomBorder = { borderBottomColor: colors.subtitle };
    }

    return (
      <>
        <StaticNavigationHeader title="Bag" />
        <View style={styles.promoCodeContainer}>
          <KeyboardAvoidingView style={styles.keyboardAvoidingView}>
            <View style={styles.container}>
              <View
                style={{
                  backgroundColor: colors.white,
                  paddingLeft: Utility.padding,
                  paddingRight: Utility.padding,
                }}
              >
                <TextField
                  label={this.promoCodeConstants.promocodeTextField}
                  lineWidth={0.8}
                  hideLabel={false}
                  blurOnSubmit={false}
                  onFocus={this.handleFocus}
                  value={offerAppliedThroughKeyboard ? promoCode : ''}
                  autoCapitalize="characters"
                  onChangeText={this.addPromoCodeFromText}
                  ref={this.ref.promocode}
                  maxLength={30}
                  returnKeyType="done"
                  onSubmitEditing={this.dismissKeyboard}
                  error={Utility.isBlank(codeError) ? null : codeError}
                  renderRightAccessory={this.promoCodeInputFieldAccessory}
                  inputContainerStyle={borderBottomBorder}
                  tintColor={colors.silver}
                />
              </View>

              <this.availableOffersOrActivityIndicator
                isOffersLoading={isOffersLoading}
              />
            </View>

            <Toast
              ref={(ref) => {
                this.toast = ref;
              }}
              position="bottom"
              positionValue={Utility.getScreenHeight() / 3.5}
            />

            {isLoaderVisible && (
              <>
                <View style={CartStyles.cartLoadingOverlay}>
                  <ActivityIndicator animating color="red" size="large" />
                </View>
              </>
            )}

            <FoxyAlert
              isVisible={Utility.isPresent(successfullyAppliedCoupon)}
              hideSecondButton
              alertBoxTitle={successfullyAppliedCoupon?.coupon_code}
              alertMessage={successfullyAppliedCoupon?.message}
              firstButtonTitle={
                Utility.isPresent(successfullyAppliedCoupon?.cta_text)
                  ? successfullyAppliedCoupon?.cta_text
                  : 'YAY !'
              }
              firstButtonOnPress={this.onFirstButtonPressForSuccessfulCoupon}
              onTapOutside={this.removeSuccessfullyAppliedCoupon}
              autoWrapContent
              firstButtonTextColor={colors.cta.lightBlue}
              image_url={images.alert_message.uri}
              showJson
            />

            <FoxyAlert
              isVisible={Utility.isPresent(errorMessage)}
              hideSecondButton
              alertBoxTitle="Oops"
              alertMessage={errorMessage}
              firstButtonTitle="Okay"
              firstButtonOnPress={this.onFirstButtonPressForErrorMessage}
              onTapOutside={this.onFirstButtonPressForErrorMessage}
              height={180}
              autoWrapContent
              firstButtonTextColor={colors.cta.lightBlue}
              image_url={images.alert_message.uri}
              showImage
              isAbsolute
              isError
              previousScreen={"promo_code"}
            />
          </KeyboardAvoidingView>
        </View>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  appliedCouponCodes: state.bag.cartPricing.coupon_codes,
  membership_cohort: state.todayDeals.membership_cohort,
  is_upgradable: state.todayDeals.is_upgradable,
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      applyCartCoupon,
      removeOffer,
    },
    dispatch,
  ),
});

export default withNavigation(
  connect(mapStateToProps, mapDispatchToProps)(PromoCode),
);
