import React from 'react';
import { useNavigation } from '@react-navigation/native';
import {
  View,
  TouchableWithoutFeedback,
  SafeAreaView,
  Platform,
} from 'react-native';
import StyleSheet from 'react-native-media-query';
import colors from '../../theme/Colors';
import Utility from '../../utils/Utility';

const ModalContainer = ({
  children,
  showModalInCenter = false,
  onModalTapOutside,
}) => {
  const navigation = useNavigation();
  const goBack = () => {
    if (onModalTapOutside) {
      onModalTapOutside();
      return;
    }
    navigation.goBack();
  };
  const modalContainer = showModalInCenter
    ? modalStyles.centerModalContainer
    : modalStyles.bottomModalContainer;

  const modalContainerIds = showModalInCenter
    ? ids.centerModalContainer
    : ids.bottomModalContainer;

  return (
    <>
      <TouchableWithoutFeedback onPress={goBack}>
        <View style={modalStyles.touchableContainer} />
      </TouchableWithoutFeedback>
      <View style={modalContainer} dataSet={{ media: modalContainerIds }} pointerEvents='box-none'>
        <SafeAreaView style={modalStyles.SafeAreaView}>{children}</SafeAreaView>
      </View>
    </>
  );
};

export default ModalContainer;

const { ids, styles: modalStyles } = StyleSheet.create({
  touchableContainer: {
    opacity: 0.7,
    backgroundColor: colors.translucent,
    ...Platform.select({
      web: {
        height: '100vh',
        width: '100vw',
      },
      default: {
        height: '100%',
        width: '100%',
      },
    }),
  },
  centerModalContainer: {
    width: '100%',
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    top: Utility.getScreenHeight() * 0.25 - 24,
    left: 0,
    right: 0,
    '@media (min-width: 768px)': {
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
  },
  bottomModalContainer: {
    width: '100%',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    '@media (min-width: 768px)': {
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
});
