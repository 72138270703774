import React, { Component, PureComponent } from 'react';
import {
  View,
  Text,
  Image,
  TouchableWithoutFeedback,
} from 'react-native';
import StyleSheet from 'react-native-media-query';
import colors from '../../theme/Colors';
import size from '../../theme/Fonts';
import images from '../../theme/Images';
import { isPresent } from '../../utils/BooleanUtility';
import { getScreenHeight, getScreenWidth } from '../../utils/LayoutUtility';
import FilterAnalyticsEvents from './FiltersAnalyticsEvents';

export default class FilterResultsEmptyState extends PureComponent {
  constructor(props) {
    super(props);
    const { areFiltersEnabled = true, showFilterEmptyState = true } =
      this.props;
    if (areFiltersEnabled && showFilterEmptyState) {
      this.fireFilterEmptyStateScreenEvents();
    }
  }

  fireFilterEmptyStateScreenEvents = () => {
    const {
      filtersList: { id = '', name = '', slug = '' } = {},
      selectedFilters = {},
    } = this.props;
    FilterAnalyticsEvents.logFilterResultsEmptyEvent(selectedFilters, {
      id,
      name,
      slug,
    });
  };

  render() {
    const {
      onPressResetButton,
      buttonText = 'Clear all filters',
      title = 'No products found',
      subtitle = 'Try removing some filters to get the results',
      children,
      showFilterEmptyState = true,
      areFiltersEnabled = true,
    } = this.props;
    return (
      <>
        {isPresent(children) && !showFilterEmptyState && children}
        {areFiltersEnabled && showFilterEmptyState && (
          <View style={styles.container} dataSet={{ media: ids.container }}>
            <Image
              source={images.emptyFilterResults}
              style={styles.emptyStateImage}
              resizeMode={'contain'}
            />
            <Text style={styles.title}>{title}</Text>
            <Text style={styles.subtitle}>{subtitle}</Text>

            <TouchableWithoutFeedback onPress={onPressResetButton}>
              <View style={styles.buttonContainer}>
                <Text style={styles.buttonText}>{buttonText}</Text>
              </View>
            </TouchableWithoutFeedback>
          </View>
        )}
      </>
    );
  }
}

const { ids, styles } = StyleSheet.create({
  container: {
    height: getScreenHeight() / 1.6,
    backgroundColor: colors.background,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    alignContent: 'center',
    '@media (min-width: 768px)': {
      height: getScreenHeight() / 4.5,
      width: getScreenWidth(),
      alignSelf: 'center',
    },
  },
  emptyStateImage: {
    height: 95,
    width: 100,
  },
  title: {
    marginTop: 15,
    fontFamily: 'Roboto-Medium',
    fontSize: size.h3,
    color: colors.foxyBlack,
  },
  subtitle: {
    fontFamily: 'Roboto-Regular',
    fontSize: size.h4,
    color: colors.subtitle,
    marginTop: 8,
  },
  buttonContainer: {
    width: 120,
    marginTop: 30,
    borderRadius: 16,
    backgroundColor: colors.black,
    padding: 7,
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonText: {
    fontFamily: 'Roboto-Medium',
    fontSize: size.h3,
    color: colors.white,
  },
});
