import * as types from '../actions/ActionTypes';
import initialState from './initialState';

export default function permissionStatus(
  state = initialState.permissionStatus,
  action,
) {
  switch (action.type) {
    case types.SET_PERMISSION_STATUS:
      return {
        ...state,
        [action.permission]: action.status,
      };
    case types.INCREMENT_FOXY_NOTIFICATION_PROMPT:
      return {
        ...state,
        increment_foxy_notification_prompt:
          (state.increment_foxy_notification_prompt ?? 0) + 1,
      };
    case types.INCREMENT_SYSTEM_PROMPT:
      return {
        ...state,
        increment_system_prompt: (state.increment_system_prompt ?? 0) + 1,
      };

    default:
      return state;
  }
}
