import React, { useMemo, useCallback } from 'react';
import { View, Text, TouchableOpacity, Image, StyleSheet } from 'react-native';
import LottieView from 'lottie-react-native';
import FastImageView from '../FastImageView';
import Utility from '../../utils/Utility';
import colors from '../../theme/Colors';
import styles from '../camera/shared/styles/AlertBoxStyles';
import images from '../../theme/Images';
import animations from '../../theme/Animations';
import WithNavigation from '../../utils/WithNavigation';
import { getMinifiedImage } from '../../utils/ImageUtility';
import ModalContainerWithoutSafeArea from './ModalContainerWithoutSafeArea';

const PopUpAlert = ({ navigation, route }) => {
  const {
    height = 206,
    alertBoxTitle = '',
    alertMessage = '',
    firstButtonTitle = '',
    firstButtonTextColor = colors.enabled,
    secondButtonTitle = 'Discard',
    secondButtonTextColor = colors.foxyBlack,
    firstButtonOnPress = () => {},
    secondButtonOnPress = () => {},
    Children = () => <View />,
    textAlignment = 'justify',
    hideSecondButton = false,
    showWhatsappHyperlink = false,
    image_url = '',
    firstActionButtonStyle = {},
    secondActionButtonStyle = {},
    isAssets = false,
    autoWrapContent = false,
    showImage = false,
    isProfilePicture = false,
    isAbsolute = false,
    showJson = false,
    animation = animations.paymentSuccess,
    animationStyle = styles.alertBoxAnimation,
    numOfLines = 3,
    showCross = false,
    cancelPressed = () => {},
  } = route?.params || {};

  const boxHeight = !autoWrapContent ? { height } : {};

  const alertBoxContainerStyle = useMemo(
    () => [
      styles.alertBoxContainer,
      {
        top: (Utility.getScreenHeight() - height - 120) / 2,
        alignSelf: 'center',
        ...boxHeight,
      },
    ],
    [boxHeight, height],
  );

  const firstButtonStyle = useMemo(
    () => [styles.actionButtonsContainer, firstActionButtonStyle],
    [firstActionButtonStyle],
  );

  const firstButtonTextStyle = useMemo(
    () => [
      styles.actionButtonText,
      { color: firstButtonTextColor, opacity: 1 },
    ],
    [firstButtonTextColor],
  );

  const secondButtonTextStyle = useMemo(
    () => [styles.actionButtonText, secondButtonTextColor, { opacity: 0.7 }],
    [secondButtonTextColor],
  );

  const secondButtonStyle = useMemo(
    () => [styles.actionButtonsContainer, secondActionButtonStyle],
    [secondActionButtonStyle],
  );

  const ActionButton = (props) => {
    const {
      hideButton,
      buttonStyle = {},
      title = '',
      onPress = () => {},
      textStyle = {},
    } = props;

    if (hideButton) {
      return null;
    }
    return (
      <TouchableOpacity style={buttonStyle} onPress={onPress}>
        <Text style={textStyle}>{title.toUpperCase()}</Text>
      </TouchableOpacity>
    );
  };

  const AlertMessageImage = ({ imageSource, imageStyle }) => {
    if (!showImage) return null;
    return (
      <FastImageView
        source={imageSource}
        resizeMode="contain"
        style={imageStyle}
      />
    );
  };

  const AlertMessageAnimation = () => {
    if (!showJson) return null;
    return (
      <LottieView
        source={animation}
        style={animationStyle}
        autoPlay
        loop={false}
      />
    );
  };

  const AlertMessageTextWithHyperlink = () => {
    if (!showWhatsappHyperlink) return null;
    const alertMessageStyles = styles[`${textAlignment}AlertMessage`];
    return (
      <Text
        numberOfLines={6}
        style={alertMessageStyles}
        allowFontScaling={false}
      >
        {alertMessage}
      </Text>
    );
  };

  const AlertMessageText = () => {
    if (showWhatsappHyperlink) return null;
    return (
      <Text
        numberOfLines={numOfLines || 6}
        style={styles.alertMessage}
        allowFontScaling={false}
      >
        {alertMessage}
      </Text>
    );
  };

  const CrossButton = () => {
    if (!showCross) return null;
    return (
      <TouchableOpacity style={styles.crossStyle} onPress={cancelPressed}>
        <Image source={images.cross} />
      </TouchableOpacity>
    );
  };

  const AlertMessageContainer = () => {
    let pictureStyle = isProfilePicture
      ? popupStyles.profilePictureStyle
      : popupStyles.regularPictureStyle;

    if (isAbsolute) {
      pictureStyle = popupStyles.absolutePictureStyle;
    }

    const newUrl = !isAssets
      ? getMinifiedImage(image_url, pictureStyle.width, pictureStyle.height)
      : image_url;

    const imageSource = isAssets ? newUrl : { uri: newUrl };

    let alertBoxTitleStyle = styles.alertBoxTitle;

    if (isAbsolute) {
      alertBoxTitleStyle = styles.alertBoxTitleLargeMargin;
    } else if (!showImage && !showJson) {
      alertBoxTitleStyle = styles.alertBoxTitleSmallMargin;
    }

    return (
      <View style={styles.alertMessageContainer}>
        <AlertMessageImage
          imageSource={imageSource}
          imageStyle={pictureStyle}
        />
        <AlertMessageAnimation />
        <Text style={alertBoxTitleStyle}>{alertBoxTitle}</Text>
        <AlertMessageTextWithHyperlink />
        <AlertMessageText />
        <Children />
        <CrossButton />
      </View>
    );
  };

  return (
      <ModalContainerWithoutSafeArea>
        <View style={popupStyles.container}>
          <View style={alertBoxContainerStyle}>
            <AlertMessageContainer />
            <ActionButton
              onPress={firstButtonOnPress}
              title={firstButtonTitle}
              hideButton={false}
              buttonStyle={firstButtonStyle}
              textStyle={firstButtonTextStyle}
            />
            <ActionButton
              onPress={secondButtonOnPress}
              title={secondButtonTitle}
              hideButton={hideSecondButton}
              buttonStyle={secondButtonStyle}
              textStyle={secondButtonTextStyle}
            />
          </View>
        </View>
      </ModalContainerWithoutSafeArea>
  );
};

export default WithNavigation(PopUpAlert);

const popupStyles = StyleSheet.create({
  container: {
    margin: 0,
    justifyContent: 'center',
    alignItems: 'center',
    width: 280,
  },
  profilePictureStyle: {
    height: 76,
    width: 76,
    borderRadius: 38,
    alignSelf: 'center',
    marginBottom: 10,
    marginTop: 4,
  },
  regularPictureStyle: {
    width: 60,
    height: 82,
    alignSelf: 'center',
    marginBottom: 4,
    marginTop: 4,
  },
  absolutePictureStyle: {
    width: 60,
    height: 60,
    alignSelf: 'center',
    position: 'absolute',
    top: -30,
  },
});
