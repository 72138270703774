import React, { PureComponent } from 'react';
import { Text, View } from 'react-native';
import StyleSheet from 'react-native-media-query';
import _ from 'lodash';
import CountDown from '../../helpers/Counter';
import colors from '../../theme/Colors';
import Utility from '../../utils/Utility';
import RemoteConfig from '../../utils/RemoteConfig';
import { REMOTE_CONFIG_KEYS } from '../../config/Constants';
import { connect } from 'react-redux';

export class BtlPriceTimer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showFlashDealTimer: true,
    };
    this.timeToShow = ['H', 'M', 'S'];
    this.timeLabels = { m: null, s: null };
  }

  setShowFlashDealTimer = (show) => {
    this.setState({
      showFlashDealTimer: show,
    });
  };

  onFinishTimer = () => {
    this.setShowFlashDealTimer(false);
  };

  checkIfFlashDealPresent = (item) => {
    const { todayDeals: { ends_at, skus = [] } = {}, cartItems } = this.props;
    return (
      (skus[`${item?.sku_id}`] &&
        item.participating_discount_offer_type === 'flash_deal') ||
      item.participating_discount_offer_type === 'club_deal'
    );
  };

  render() {
    const { todayDeals: { ends_at, skus = [] } = {}, cartItems } = this.props;
    const { showFlashDealTimer } = this.state;
    const isFlashDealPresent = !!_.find(
      cartItems,
      this.checkIfFlashDealPresent,
    );

    const btlPriceTimer = RemoteConfig.getValue(
      REMOTE_CONFIG_KEYS.btl_timer_text,
    );

    if (!showFlashDealTimer || !isFlashDealPresent) {
      return null;
    }
    return (
      <View>
        <View style={styles.container} dataSet={{ media: ids.container }}>
          <Text style={styles.flashDealTimerText} dataSet={{ media: ids.flashDealTimerText }}>{btlPriceTimer}</Text>
          <CountDown
            size={10}
            until={Utility.calculateTimeDifferenceForOffer(ends_at)}
            endsAt={ends_at}
            onFinish={this.onFinishTimer}
            digitStyle={styles.digitStyle}
            digitTxtStyle={styles.digitTextStyle}
            timeLabelStyle={styles.timerLabelStyle}
            separatorStyle={styles.separatorStyle}
            timeToShow={this.timeToShow}
            timeLabels={this.timeLabels}
            showSeparator
          />
        </View>
      </View>
    );
  }
}

const { ids, styles } = StyleSheet.create({
  digitStyle: { backgroundColor: '#000', marginLeft: 0 },
  digitTextStyle: { color: '#fff', fontSize: 14, fontFamily: 'Roboto-Regular' },
  timerLabelStyle: { color: 'red', fontWeight: 'bold' },
  separatorStyle: { color: '#000000' },
  flashDealTimerText: {
    color: '#173143',
    fontSize: 12,
    fontFamily: 'Roboto-Regular',
    alignSelf: 'center',
    '@media (min-width: 768px)': {
      fontSize: 14,
    },
  },
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 8,
    paddingBottom: 8,
    backgroundColor: colors.white,
    paddingHorizontal: 16,
    elevation: 1,
    shadowRadius: 2,
    shadowColor: colors.silver,
    shadowOpacity: 0.3,
    shadowOffset: { width: 1, height: 1 },
    '@media (min-width: 768px)': {
      marginBottom: 2,
    },
  },
});

const mapStateToProps = (state) => ({ todayDeals: state.todayDeals });

export default connect(mapStateToProps, null)(BtlPriceTimer);
