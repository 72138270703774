import { memoize } from 'lodash';
import React from 'react';
import { View, Text } from 'react-native';
import { ids, styles } from './styles';
import DebouncedTouchableOpacity from '../../components/shared/DebouncedTouchableOpacity';
import FastImageView from '../../components/FastImageView';
import { getMinifiedImage } from '../../utils/ImageUtility';
import colors from '../../theme/Colors';
import { isDesktop } from '../../utils/BooleanUtility';

const CircularTabCard = (props) => {
  const {
    selectedIndex = '',
    index,
    item,
    onTabPress,
    listBackgroundColor = colors.background,
  } = props;

  const isSelectedTab = selectedIndex === index;
  const circularTabTextStyle = isSelectedTab
    ? styles.selectedCircularTabText
    : styles.unselectedCircularTabText;
  const circularTabTextContainerStyle = isSelectedTab
    ? styles.selectedCircularTabTextContainer
    : [
      styles.unselectedCircularTabTextContainer,
      { borderColor: listBackgroundColor },
    ];
  return (
    <View style={styles.circularTabContainerStyle} dataSet={{ media: ids.circularTabContainerStyle }}>
      <View>
        {isSelectedTab && <View style={styles.selectedCircularTabStyle} dataSet={{ media: ids.selectedCircularTabStyle }}/>}
      </View>
      <DebouncedTouchableOpacity
        {...props}
        onPress={memoize(
          () => onTabPress(item, index),
          () => [index],
        )}
        delayPressOut={0}
        style={styles.circularTabImageContainer}
        dataSet={{ media: ids.circularTabImageContainer }}
      >
        <FastImageView
          source={getMinifiedImage(
            item.picture,
            styles.image.width,
            styles.image.height,
          )}
          style={isDesktop() ? styles.desktopImage : styles.image}
        />
      </DebouncedTouchableOpacity>
      <DebouncedTouchableOpacity
        {...props}
        onPress={memoize(
          () => onTabPress(item, index),
          () => [index],
        )}
        delayPressOut={0}
        style={styles.circularTabTextContainer}
        dataSet={{ media: ids.circularTabTextContainer }}
      >
        <View style={circularTabTextContainerStyle} dataSet={{ media: ids.selectedCircularTabTextContainer }}>
          <Text
            numberOfLines={2}
            ellipsizeMode='tail'
            style={circularTabTextStyle}
            dataSet={{ media: ids.selectedCircularTabText }}
          >
            {item.name}
          </Text>
        </View>
      </DebouncedTouchableOpacity>
    </View>
  );
};

export default CircularTabCard;
