import React, { PureComponent } from 'react';
import { View, Dimensions } from 'react-native';
import StyleSheet from 'react-native-media-query';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Swiper, SwiperSlide } from 'swiper/react';
import Toast from 'react-native-easy-toast';
import _ from 'lodash';
import AudioHandler from '../../utils/AudioHandler';
import ContentPageHeader from '../../components/contentPageHeader';
import FastImageView from '../../components/FastImageView';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import {
  EventParameterKey,
  EventType,
  AnalyticsManager,
} from '../../analytics';
import { videoSwipeNextCueDisplayed } from '../../actions/ActionTypes';
import SwipeNextAnimate from './SwipeNextAnimate';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import ContentPageWrapper from './ContentPageWrapper';
import { isDesktop } from '../../utils/BooleanUtility';
import { getScreenWidth } from '../../utils/LayoutUtility';

class ContentPageCarousel extends PureComponent {
  constructor(props) {
    super(props);
    const { index, itemData, listData, navigation, route, productRating } =
      this.props;
    this.state = {
      manuallyChanged: false,
    };
    this.currentIndex = index;
    this.screenWidth = Utility.isAndroid()
      ? Utility.getScreenWidth()
      : Dimensions.get('window').width;
    if (isDesktop()) {
      this.screenWidth = getScreenWidth() / 2.5;
    }
    this.screenHeight = Utility.isAndroid()
      ? Utility.getScreenHeight()
      : Dimensions.get('window').height;
    this.contentPagesRef = [];
    this.contentPagesRefFn = [];
    listData.objects?.forEach(this.contentPagesRefFunctions);
    this.viewabilityConfig = {
      waitForInteraction: true,
      itemVisiblePercentThreshold: 60,
    };
    this.previousScreen = route.params?.previousScreen || '';
  }

  contentPagesRefFunctions = (value, index) => {
    this.contentPagesRefFn[index] = (ref) => {
      this.contentPagesRef[index] = ref;
    };
  };

  componentDidMount = () => {
    const { showToast, navigation } = this.props;
    if (showToast) {
      this.toast.show('Coupon Applied');
    }
    this.onWillFocus();
    AudioHandler.changeAudioSessionCategoryToPlayBack();
    this.unsubscribeFocus = navigation.addListener('focus', () => {
      Utility.setStatusbarTranslucent();
    });
    this.unsubscribeBlur = navigation.addListener('blur', () => {
      this.setStatusBarWhite();
    });
    if (!navigation.canGoBack()) {
      window.history.back();
    }
  };

  componentWillUnmount() {
    this.setStatusBarWhite();
    this.unsubscribeFocus();
    this.unsubscribeBlur();
  }

  onChange = ({ activeIndex }) => {
    const { listData } = this.props;
    const { manuallyChanged } = this.state;
    if (this.currentIndex === activeIndex) {
      return;
    }
    this.contentPagesRef[this.currentIndex]?.setCurrentIndexStatus(false);
    this.currentIndex = activeIndex;
    this.contentPagesRef[this.currentIndex]?.setCurrentIndexStatus(true);
    const {
      artist: { name: artistName = '', id: artistId = '' } = {},
      metadata: {
        title: contentTitle = '',
        id: videoId = '',
        duration = '',
      } = {},
    } = listData.objects[this.currentIndex];
    if (!manuallyChanged) {
      AnalyticsManager.logEvent(EventType.videoEvents.VIDEO_CHANGE, {
        [EventParameterKey.VIDEO_ID]: videoId,
        [EventParameterKey.VIDEO_TITLE]: contentTitle,
        [EventParameterKey.ARTIST_NAME]: artistName,
        [EventParameterKey.ARTIST_ID]: artistId,
        [EventParameterKey.VIDEO_DURATION]: duration,
        [EventParameterKey.METHOD]:
          (activeIndex > this.currentIndex &&
            !(
              this.currentIndex === 0 &&
              activeIndex === listData.objects.length - 1
            )) ||
          (this.currentIndex === listData.objects.length - 1 &&
            activeIndex === 0)
            ? 'Next Swipe'
            : 'Previous Swipe',
      });
    }
    this.setState({
      manuallyChanged: false,
    });
  };

  onCancelTap = () => {
    const { navigation } = this.props;
    navigation.goBack();
  };

  renderImage = ({ item, iteratorIndex }) => {
    const { route } = this.props;
    const entity = route.params?.entity;
    const { name = '', image = '', subject = '', rating = '' } = entity;
    const uri = Utility.getImageUrl(item.url);
    return (
      <>
        <ContentPageHeader
          artistName={name}
          artistThumbnail={image}
          contentTitle={subject}
          isFollowingArtist={false}
          onCancelTap={this.onCancelTap}
          showCrossButton
          parentComponent={SCREEN_CONSTANTS.CONTENT_PAGE_CAROUSEL}
          productRating={rating}
        />
        <FastImageView source={uri} style={styles.imageContainer} dataSet={{ media: ids.imageContainer }}/>
      </>
    );
  };

  renderItem = ({ item, index: iteratorIndex }) => {
    const { route, listData } = this.props;
    if (item.type !== 'video') {
      return <this.renderImage item={item} iteratorIndex={iteratorIndex} />;
    }
    const {
      id,
      slug,
      listId,
      followedArtist,
      authToken,
      user_engagement,
      favoriteVideos,
      seekToTimeOnLoad = 0,
      videoViewed,
      lastNotificationModalDisplayTime,
      productRating,
      videoToTopicMap,
    } = this.props;
    const publisherType = route.params?.publisherType;
    const publisherSlug = route.params?.publisherSlug;
    const publisherId = route.params?.publisherId;
    const previousScreen = route.params?.previousScreen;
    const videoData = listData.objects[iteratorIndex];
    const { artist: { id: artistId } = {} } = videoData;
    const isFollowingArtist = !!(followedArtist && followedArtist[artistId]);
    const isFavorite = !!(favoriteVideos && favoriteVideos[videoData.id]);
    const seekToTime =
      iteratorIndex === this.currentIndex ? seekToTimeOnLoad : 0;
    const bottomPaddingToAdd = Utility.isAndroid() ? 36 : 0;
    let topicDetail = {};
    if (Utility.isPresent(videoToTopicMap)) {
      topicDetail = videoToTopicMap[iteratorIndex];
    }
    return (
      <ContentPageWrapper
        onRef={this.contentPagesRefFn[iteratorIndex]}
        id={id}
        slug={slug}
        listId={listId}
        index={this.currentIndex}
        key={iteratorIndex}
        iteratorIndex={iteratorIndex}
        isFollowingArtist={isFollowingArtist}
        authToken={authToken}
        user_engagement={user_engagement}
        isFavorite={isFavorite}
        seekToTimeOnLoad={seekToTime}
        videoData={videoData}
        topicDetail={topicDetail}
        videoViewed={videoViewed}
        bottomPaddingToAdd={bottomPaddingToAdd}
        parentComponent={SCREEN_CONSTANTS.CONTENT_PAGE_CAROUSEL}
        lastNotificationModalDisplayTime={lastNotificationModalDisplayTime}
        previousScreen={previousScreen}
        productRating={productRating}
        publisherType={publisherType}
        publisherSlug={publisherSlug}
        publisherId={publisherId}
      />
    );
  };

  cueSwipedUp = () => {
    this.swipeNextCueDisplayed();
    this.goToNextPage();
  };

  swipeNextCueDisplayed = () => {
    this.props.videoSwipeNextCueDisplayed(true);
  };

  swipeNextCue = () => {
    const { isSwipeNextCueDisplayed } = this.props;
    return (
      <SwipeNextAnimate
        videoSwipeNextCueDisplayed={this.swipeNextCueDisplayed}
        showSwipeNextCue={!isSwipeNextCueDisplayed}
        cueSwipedUp={this.cueSwipedUp}
      />
    );
  };

  getItemLayout = (data, index) => {
    return {
      length: Utility.getScreenHeight(),
      index,
      offset: Utility.getScreenHeight() * index,
    };
  };

  setStatusBarWhite = () => {
    if (this.previousScreen.includes('routine')) return;
    Utility.setStatusBarWhite();
  };

  onDidFocus = () => {
    if (!this.previousScreen?.includes('routine')) return;
    Utility.setStatusbarTranslucent();
  };

  onWillFocus = () => {
    if (this.previousScreen.includes('routine')) return;
    Utility.setStatusbarTranslucent();
  };

  render() {
    const { listData = {}, index = 0 } = this.props;
    if (Utility.isBlank(listData)) {
      return <View style={styles.containerView} />;
    }

    const { objects = [] } = listData;

    return (
      <View style={styles.containerView}>
        <Swiper
          direction='vertical'
          height={Utility.getWindowInnerHeight()}
          width={getScreenWidth() / (isDesktop() ? 2.5 : 1)}
          initialSlide={index}
          onSlideChange={this.onChange}
        >
          {objects.map((item, index) => (
            <SwiperSlide key={item.id}>
              <this.renderItem item={item} index={index} />
            </SwiperSlide>
          ))}
        </Swiper>
        <Toast
          ref={(ref) => {
            this.toast = ref;
          }}
        />
        <this.swipeNextCue />
      </View>
    );
  }
}

ContentPageCarousel.propTypes = {
  id: PropTypes.string,
  slug: PropTypes.string,
  listId: PropTypes.string,
  listData: PropTypes.shape({
    id: PropTypes.string,
    objects: PropTypes.array,
  }),
  itemData: PropTypes.shape({
    id: PropTypes.string,
    slug: PropTypes.string,
  }),
  index: PropTypes.number,
  videoViewed: PropTypes.func,
};

ContentPageCarousel.defaultProps = {
  id: null,
  slug: null,
  itemData: {},
  listId: '',
  listData: {},
  index: 0,
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      videoSwipeNextCueDisplayed,
    },
    dispatch,
  ),
});

const mapStateToProps = (store, ownProps) => {
  const { route } = ownProps;
  const itemData = route.params?.itemData;
  const listData = route.params?.listData;
  const listId = route.params?.listId;
  const id = route.params?.id;
  const slug = route.params?.slug;
  const index = route.params?.index;
  const seekToTimeOnLoad = route.params?.seekToTimeOnLoad;
  const videoViewed = route.params?.videoViewed;
  const videoToTopicMap = route.params?.videoToTopicMap;
  const productRating = route.params?.productRating;
  // listData.rotatedObjects = Utility.rotateArray(listData.objects, index);
  let retval = {};
  if (Utility.isPresent(itemData)) {
    retval = {
      id: itemData.id,
      slug: itemData.slug,
      index,
      itemData,
      listId,
      listData,
      seekToTimeOnLoad,
      videoViewed,
      followedArtist: store.UserAccountInfo.followedArtists,
      authToken: store.UserAccountInfo.authToken,
      user_engagement: store.UserAccountInfo.user_engagement,
      favoriteVideos: store.UserAccountInfo.favourites.videos,
      isSwipeNextCueDisplayed: store.UserAccountInfo.isSwipeNextCueDisplayed,
      lastNotificationModalDisplayTime:
        store.UserAccountInfo.lastNotificationModalDisplayTime,
      videoToTopicMap,
      productRating,
    };
  } else {
    // TODO: Fetch the list data using the slug
    retval = {
      id, // Video id
      slug, // Video slug
      index,
      itemData,
      listId,
      listData,
      seekToTimeOnLoad,
      videoViewed,
      followedArtist: store.UserAccountInfo.followedArtists,
      authToken: store.UserAccountInfo.authToken,
      user_engagement: store.UserAccountInfo.user_engagement,
      favoriteVideos: store.UserAccountInfo.favourites.videos,
      isSwipeNextCueDisplayed: store.UserAccountInfo.isSwipeNextCueDisplayed,
      lastNotificationModalDisplayTime:
        store.UserAccountInfo.lastNotificationModalDisplayTime,
      videoToTopicMap,
      productRating,
    };
  }

  return retval;
};

export default withNavigation(
  connect(mapStateToProps, mapDispatchToProps)(ContentPageCarousel),
);

const { ids, styles } = StyleSheet.create({
  containerView: {
    flex: 1,
    backgroundColor: 'black',
    alignItems: 'center',
  },
  safeArea: {
    flex: 1,
    backgroundColor: 'black',
  },
  imageContainer: {
    height: Utility.getScreenHeight(),
    width: getScreenWidth(),
    resizeMode: 'contain',
    justifyContent: 'center',
    '@media (min-width: 768px)': {
      height: Utility.getScreenHeight() - 68,
      width: getScreenWidth() / 2.5,
    },
  },
});
