// Dependencies
import React, { PureComponent } from 'react';
import { View, Text, Image, StyleSheet } from 'react-native';

// Components
import _ from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ArtistCardStyles } from './styles';
import Card from '../../lib/Card';
import images from '../../theme/Images';
import { LAYOUT, ARTIST_CONSTANTS, MORE_ITEMS } from '../../config/Constants';
import FollowArtistButton from './FollowArtistButton';
import ScaleAnimate from '../shared/ScaleAnimate';
import { withEither } from '../../lib/Monads';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import {
  EventParameterKey,
  AnalyticsManager,
  EventType,
  AnalyticsUtilty,
} from '../../analytics';
import colors from '../../theme/Colors';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import FastImageView from '../FastImageView';
import { getMinifiedImage } from '../../utils/ImageUtility';
import withProfiledNavigation from '../../utils/withProfiledNavigation';
import { getNavigationSource } from '../../utils/PerfUtility';

class DefaultSizeArtistList extends PureComponent {
  constructor(props) {
    super(props);
    this.debouncedNavigate = _.debounce(this.navigate, 1000, {
      leading: true,
      trailing: false,
    });
    this.debounceArtistCardClick = _.debounce(
      this.fireSearchResultClickEvent,
      2000,
      {
        leading: true,
        trailing: false,
      },
    );

    this.navigationSource = getNavigationSource(this, this.props);
  }

  navigate = () => {
    const {
      previousScreen,
      navigation,
      listData: { id: listId = '', slug: listSlug = '' },
      itemData: {
        layout,
        id,
        image_url,
        name,
        slug,
        artist_type,
        onPressFollowButton,
        onPressUnfollowButton,
        search,
        listIndex,
        index,
        searchQuery,
        elementItemCounts,
        listName,
        listContent,
        bio_handle = '',
      },
    } = this.props;

    if (previousScreen === SCREEN_CONSTANTS.SEARCH) {
      let clickedIndex = Utility.addPreviousIndexValues(
        elementItemCounts,
        listIndex,
      );
      clickedIndex += index + 1;

      const meta = {
        [EventParameterKey.SEARCH_QUERY]: searchQuery,
        [EventParameterKey.ITEM_TYPE]: 'artist',
        [EventParameterKey.ITEM_NAME]: this.props.name,
        [EventParameterKey.ITEM_ID]: this.props.id,
        [EventParameterKey.ITEM_POSITION]: clickedIndex,
      };
      this.debounceArtistCardClick(meta);
    }

    AnalyticsUtilty.fireItemClickEvent(
      previousScreen,
      id,
      name,
      'artist',
      index,
      listId,
      layout,
      listName,
      listIndex,
      '',
      '',
      listContent,
      '',
      slug,
      listSlug,
    );

    navigation.push(this.navigationSource, 'Artist', {
      bioHandle: bio_handle,
      display: LAYOUT.SCREEN,
      profilePlaceholderUrl: image_url,
    });
  };

  fireSearchResultClickEvent = (meta) => {
    AnalyticsManager.logEvent(EventType.search.SEARCH_RESULT_CLICK, meta);
  };

  artistTypes = (props) => {
    let { artist_type, emoji, bio } = props;
    const { artistTypes } = this.props;
    if (
      Utility.isBlank(artist_type) ||
      artist_type.length === 0 ||
      !_.isArray(artist_type)
    ) {
      return null;
    }
    if (Utility.isBlank(artist_type[0])) {
      return null;
    }
    if (Utility.isBlank(artistTypes)) {
      return null;
    }
    //sort artist_type based on priority
    artist_type = _.sortBy(artist_type, [
      function (artTypeId) {
        return artistTypes[artTypeId].priority;
      },
    ]);
    const id = artist_type[0];
    if (Utility.isBlank(id)) {
      return null;
    }
    const imageUrl = artistTypes[id].filled_icon;
    let artistTypeText = '';
    if (Utility.isBlank(bio)) {
      if (artist_type.length > 1) {
        artist_type.slice(0, 2).map((id, index) => {
          if (index === 0) {
            artistTypeText = `${artistTypes[id].name}`;
          } else {
            artistTypeText = `${artistTypeText}, ${artistTypes[id].name}`;
          }
        });
      } else {
        artistTypeText = artistTypes[id].name;
      }
    } else {
      artistTypeText = bio;
    }

    const artistTypeLogo = Utility.isBlank(emoji) ? imageUrl : emoji;
    return (
      <View style={[styles.artistDesignationContainer, { width: 200 }]}>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            marginLeft: 10,
            marginTop: 4,
          }}
        >
          <Image
            source={{
              uri: getMinifiedImage(
                artistTypeLogo,
                styles.smallLogo.width,
                styles.smallLogo.height,
              ),
            }}
            style={styles.smallLogo}
          />
          <Text style={styles.descriptionText}>{artistTypeText}</Text>
        </View>
      </View>
    );
  };

  ArtistAvatar = (props) => {
    const styles = ArtistCardStyles;
    const { imageUrl, imageStyle } = props;

    return (
      <View style={styles.container}>
        <FastImageView
          source={getMinifiedImage(imageUrl, imageStyle.width, imageStyle.height)}
          style={imageStyle}
          onError={this.imageError}
        />
      </View>
    );
  };

  artistDescription = (props) => {
    let { name, artistType } = props;
    const { artistTypes } = this.props;
    if (Utility.isBlank(artistType) && Utility.isBlank(artistTypes)) {
      return null;
    }
    const styles = ArtistCardStyles;
    let artistTypeText = 'Artist';
    artistType = _.sortBy(artistType, [
      (artistTypeId) => artistTypes[artistTypeId].priority,
    ]);
    if (
      !Utility.isBlank(artistTypes) &&
      !Utility.isBlank(artistType) &&
      artistType.length !== 0
    ) {
      artistTypeText = artistTypes[artistType[0]].name || 'Artist';
    }
    return (
      <View style={styles.artistDescription}>
        <Text style={styles.artistNameForList}>{name}</Text>
        <Text style={styles.artistDesignation}>{artistTypeText}</Text>
      </View>
    );
  };

  artistLocation = (props) => {
    let { location } = props;
    if (Utility.isBlank(location)) {
      location = 'India';
    }
    return (
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          marginLeft: 8,
          marginTop: 4,
        }}
      >
        <Image source={images.currentLocation} style={styles.smallLogo} />
        <Text style={styles.descriptionText}>{location}</Text>
      </View>
    );
  };

  artistName = (props) => {
    const { name } = props;
    const {
      itemData: { artist_type, bio, emoji, location },
    } = this.props;
    return (
      <View>
        <Text style={ArtistCardStyles.listArtistName} numberOfLines={2}>
          {name}
        </Text>
        <this.artistTypes artist_type={artist_type} emoji={emoji} bio={bio} />
        <this.artistLocation address={location} />
      </View>
    );
  };

  listConditionFn = (props) => props.layout === LAYOUT.LIST;

  conditionalDescription = withEither(
    this.listConditionFn,
    this.artistDescription,
  )(this.artistName);

  imageError = () => {
    const { image_url, id, name, navigation, onScreen = '' } = this.props;
    AnalyticsUtilty.fireImageErrorAnalytics(
      id,
      image_url,
      name,
      'artist',
      onScreen,
    );
  };

  openArtistMorePage = () => {
    this.props.navigation.push(this.navigationSource, MORE_ITEMS, {
      slug: ARTIST_CONSTANTS.artistList,
    });
  };

  artistCardEmptyState = (props) => {
    const { cardStyle, imageStyle } = props;
    return (
      <ScaleAnimate {...this.props} onPress={this.openArtistMorePage}>
        <View style={cardStyle}>
          <FastImageView
            source={images.artistCardPlaceholder}
            style={[
              imageStyle,
              {
                margin: 4,
                justifyContent: 'center',
                alignItems: 'center',
                overflow: 'hidden',
              },
            ]}
            resizeMode='cover'
          />
          <Text style={ArtistCardStyles.listArtistName}>Start Following</Text>
          <FollowArtistButton disabled />
        </View>
      </ScaleAnimate>
    );
  };

  render() {
    const {
      itemData,
      previousScreen,
      itemData: {
        layout,
        id,
        image_url,
        name,
        slug,
        artist_type,
        onPressFollowButton,
        onPressUnfollowButton,
      },
    } = this.props;

    if (Utility.isBlank(image_url) || Utility.isBlank(name)) {
      return null;
    }

    return (
      <ScaleAnimate {...this.props} onPress={this.debouncedNavigate}>
        <Card style={styles.listCardStyle}>
          <this.ArtistAvatar
            imageUrl={image_url}
            imageStyle={styles.listImageStyle}
          />
          <this.conditionalDescription
            layout={layout}
            name={name}
            artistType={artist_type}
            style={ArtistCardStyles.listArtistName}
          >
            {name}
          </this.conditionalDescription>
          <FollowArtistButton
            id={id}
            layout={layout}
            slug={slug}
            name={name}
            onPressFollowButton={onPressFollowButton}
            onPressUnfollowButton={onPressUnfollowButton}
            previousScreen={previousScreen}
            itemData={itemData}
            followButtonLayout={LAYOUT.LIST}
          />
        </Card>
      </ScaleAnimate>
    );
  }
}

const mapStateToProps = (store) => ({
  artistTypes: store.UserAccountInfo.artistTypes,
  isArtist: store.UserAccountInfo.artistProfile.isArtist || false,
});

export default withProfiledNavigation(
  connect(mapStateToProps, null)(DefaultSizeArtistList),
);

const styles = StyleSheet.create({
  listCardStyle: {
    backgroundColor: '#fff',
    flexDirection: 'row',
    margin: 0,
    marginTop: 1,
    marginBottom: 1,
    alignSelf: 'center',
    borderRadius: 4,
    height: 112,
    width: Utility.getScreenWidth() - 24,
  },
  listImageStyle: {
    marginLeft: 12,
    overflow: 'hidden',
    width: 96,
    height: 96,
    borderRadius: 96 / 2,
  },
  artistDesignationContainer: {
    flexDirection: 'row',
  },
  smallLogo: {
    width: 12,
    height: 14,
    resizeMode: 'contain',
  },
  descriptionText: {
    fontFamily: 'Roboto',
    fontSize: 12,
    fontWeight: 'normal',
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.foxyBlack,
    marginLeft: 8,
  },
});
