import {
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
} from 'react-native';
import React from 'react';
import { useNavigation } from '@react-navigation/native';
import { LAYOUT, CURRENCY_SYMBOL, MAX_LINES } from '../../../config/Constants';
import FastImageView from '../../FastImageView';
import colors from '../../../theme/Colors';
import { navigateToScreen } from '../../../utils/NavigationUtility';
import { getMinifiedImage } from '../../../utils/ImageUtility';
import { isPresent } from '../../../utils/BooleanUtility';

export default function ShopifyOrderItem({
  quantity,
  imageUrl,
  name,
  esp,
  productSlug,
  refundableAmount,
  hidePriceForOrderItems = false,
  variantName = '',
}) {
  const navigation = useNavigation();
  const onProductCardPress = () => {
    if (productSlug) {
      navigateToScreen({
        navigation,
        type: 'push',
        screen: 'Product',
        params: {
          slug: `/api/v2/products/${productSlug}`,
          display: LAYOUT.SCREEN,
        },
      });
    }
  };

  return (
    <TouchableOpacity onPress={onProductCardPress}>
      <View style={styles.orderItemContainer}>
        <View style={styles.imageContainer}>
          <FastImageView
            source={getMinifiedImage(
              imageUrl,
              styles.image.width,
              styles.image.height,
            )}
            style={styles.image}
            resizeMode='contain'
          />
        </View>

        <View style={styles.itemDetailsContainer}>
          <Text
            style={styles.productName}
            numberOfLines={2}
            ellipsizeMode='tail'
          >
            {name}
          </Text>
          {isPresent(variantName) && (
            <Text numberOfLines={MAX_LINES.one} style={styles.variantName}>
              {variantName}
            </Text>
          )}
          <Text style={styles.quantity}>{`Qty: ${quantity}`}</Text>
        </View>

        {!hidePriceForOrderItems && (
          <View style={styles.spContainer}>
            <Text style={styles.sp}>{`${CURRENCY_SYMBOL} ${
              refundableAmount || esp || 0
            }`}</Text>
          </View>
        )}
      </View>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  orderItemContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 10,
  },
  imageContainer: { flex: 1 },
  image: { height: 60, width: 70 },
  itemDetailsContainer: {
    flexDirection: 'column',
    flex: 4,
    justifyContent: 'space-between',
    marginLeft: 22,
  },
  productName: {
    color: colors.foxyBlack,
    fontFamily: 'Roboto-Regular',
    fontSize: 12,
  },
  variantName: {
    fontFamily: 'Roboto-Regular',
    color: colors.foxyBlack,
    fontSize: 10,
    letterSpacing: 0,
    textAlign: 'left',
    alignItems: 'flex-start',
  },
  quantity: {
    color: colors.silver,
    fontFamily: 'Roboto-Regular',
    fontSize: 12,
  },
  spContainer: { flex: 1 },
  sp: {
    color: colors.silver,
    fontFamily: 'Roboto-Regular',
    fontSize: 12,
    alignSelf: 'flex-end',
  },
});
