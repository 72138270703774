import React, { useState, useRef } from 'react';
import { StyleSheet, Text, View, Platform } from 'react-native';
import { OutlinedTextField } from 'react-native-material-textfield';
import PropTypes from 'prop-types';
import colors from '../../theme/Colors';
import Utility from '../../utils/Utility';
import RemoteConfig from '../../utils/RemoteConfig';
import { REMOTE_CONFIG_KEYS } from '../../config/Constants';

const styles = StyleSheet.create({
  writeAReviewTitle: {
    fontFamily: 'Roboto-Medium',
    color: colors.foxyBlack,
    fontWeight: '500',
    fontSize: 14,
    marginBottom: 16,
    marginHorizontal: 16,
  },
  outerContainer: {
    backgroundColor: 'transparent',
    paddingHorizontal: 18,
  },
  extraMargin: {
    marginBottom: 10,
  },
  bodyInputContainerStyle: { paddingBottom: 24 },
  labelTextStyle: {
    ...Platform.select({
      web: { backgroundColor: colors.white, paddingRight: 5 },
      default: {},
    }),
  },
});

const WriteAReview = ({
  setReviewBody,
  setReviewTitle,
  isReviewBodyEmpty,
  isReviewTitleEmpty,
  body,
  reviewTitle,
}) => {
  const strings = Utility.jsonParser(
    RemoteConfig.getValue(REMOTE_CONFIG_KEYS.product_review_page_strings),
  );
  const bodyFieldRef = useRef();

  const moveToField = () => {
    bodyFieldRef?.current?.focus();
  };

  const onChangeTitle = (value) => {
    setReviewTitle(value);
  };

  const onChangeBody = (value) => {
    setReviewBody(value);
  };

  return (
    <View>
      <Text style={styles.writeAReviewTitle}>Write a Review</Text>
      <View style={styles.outerContainer}>
        <OutlinedTextField
          containerStyle={styles.extraMargin}
          onChangeText={onChangeTitle}
          label={strings.titlePlaceholder}
          keyboardType='default'
          numberOfLines={1}
          maxLength={40}
          error={isReviewTitleEmpty ? 'Please enter a title.' : null}
          activeLineWidth={0.8}
          lineWidth={0.8}
          onSubmitEditing={moveToField}
          tintColor="#979BAA"
          labelTextStyle={styles.labelTextStyle}
          value={reviewTitle}
        />

        <OutlinedTextField
          ref={bodyFieldRef}
          height={150}
          inputContainerStyle={styles.bodyInputContainerStyle}
          onChangeText={onChangeBody}
          label={strings.placeholderBody}
          keyboardType='default'
          value={body}
          multiline
          numberOfLines={15}
          error={isReviewBodyEmpty ? 'Please enter a message.' : null}
          maxLength={600}
          activeLineWidth={0.8}
          lineWidth={0.8}
          tintColor="#979BAA"
          labelTextStyle={styles.labelTextStyle}
          style={{ outline: 'none' }}
        />
      </View>
    </View>
  );
};

WriteAReview.propTypes = {
  setReviewBody: PropTypes.func,
  setReviewTitle: PropTypes.func,
  isReviewBodyEmpty: PropTypes.bool,
  isReviewTitleEmpty: PropTypes.bool,
};

export default WriteAReview;
