import { Platform } from 'react-native';
import { takeLatest, put, select } from 'redux-saga/effects';
import {
  ADD_ADDRESS,
  REMOVE_ADDRESS,
  UPDATE_ADDRESS,
  SAVE_ADDRESS,
  PINCODE_INFO,
  REMOVE_ADDRESS_SUCCESS,
  GET_USER_ALL_ADDRESSES,
  SET_DEFAULT_ADDRESSES,
  SAVE_ALL_ADDRESSES,
  getUsersAllAddress,
  saveAllAddresses,
  DOES_SHOPIFY_ADDRESS_EXIST,
} from '../actions/ActionTypes';
import {
  ADDRESS_URL,
  SHOPIFY_ADDRESS_URL,
  URL,
  V2_ADDRESS_URL,
} from '../config/Constants';
import AppConfig from '../config/AppConfig';
import { logoutUser } from '../actions/LoginActions';
import { getApiHeaders, convertJsonFromResponse } from './GeneratorUtil';
import Utility from '../utils/Utility';

function* addAddressAsync(action) {
  const {
    data: {
      name,
      pincode,
      line1,
      line2,
      phone_number,
      contact_name,
      email,
      address_type = '',
      id,
      callingCode,
    },
    callback,
  } = action;
  const url = `${ADDRESS_URL}?source=cart&platform=app&os=${Platform.OS}`;

  const authToken = yield select((state) => state.UserAccountInfo.authToken);
  const shopify_address_id = address_type === 'ShopifyAddress' ? id : '';
  try {
    const response = yield fetch(url, {
      method: 'POST',
      headers: yield getApiHeaders(),
      body: JSON.stringify({
        auth_token: authToken,
        address: {
          name,
          line1,
          line2,
          phone_number,
          pincode,
          default: action.data.default,
          contact_name,
          email,
          country_code: `${callingCode}` || `${AppConfig.callingCode}`,
          shopify_address_id,
        },
      }),
    });
    const json = yield convertJsonFromResponse(response);
    const statusCode = response.status;
    if (statusCode >= 200 && statusCode < 300) {
      if (Utility.isBlank(json)) {
        return;
      }
      yield put({
        type: SET_DEFAULT_ADDRESSES,
        addressId: json.id,
        callback: () => {},
      });
      callback(true, json, statusCode);
      yield put({ type: SAVE_ADDRESS, data: json });
    } else if (statusCode === 401) {
      yield put(logoutUser());
      callback(false, json, statusCode);
    } else if (statusCode !== 200) {
      console.tron.log('error in adding address', json);
      callback(false, json, statusCode);
    } else {
      callback(false, json, statusCode);
      console.tron.log('status code', statusCode);
    }
  } catch (error) {
    console.tron.log(` error ${JSON.stringify(error)}`);
  }
}

function* getAllAddressesAsync(action) {
  const { callback } = action;
  const url = V2_ADDRESS_URL;
  try {
    const response = yield fetch(url, {
      method: 'GET',
      headers: yield getApiHeaders(),
    });
    const json = yield convertJsonFromResponse(response);
    const statusCode = response.status;
    if (statusCode >= 200 && statusCode < 300) {
      if (Utility.isBlank(json)) {
        return;
      }
      yield put(saveAllAddresses(json));
      callback(true, json);
    } else if (statusCode === 401) {
      yield put(logoutUser());
      callback(false, json);
    } else if (statusCode !== 200) {
      callback(false, json);
    } else {
      callback(false, json);
      console.tron.log('status code', statusCode);
    }
  } catch (error) {
    console.tron.log(` error ${JSON.stringify(error)}`);
  }
}

function* setDefaultAddresses(action) {
  const { addressId, callback, addressType } = action;
  const addressEndpoint =
    addressType === 'ShopifyAddress'
      ? URL.SET_DEFAULT_SHOPIFY_ADDRESS
      : URL.SET_DEFAULT_ADDRESS;
  const url = `${addressEndpoint}${addressId}/set_default`;
  const authToken = yield select((state) => state.UserAccountInfo.authToken);
  try {
    const response = yield fetch(url, {
      method: 'PATCH',
      headers: yield getApiHeaders(),
      body: JSON.stringify({
        auth_token: authToken,
      }),
    });
    const statusCode = response.status;
    if (statusCode >= 200 && statusCode < 300) {
      const json = yield convertJsonFromResponse(response);
      if (Utility.isBlank(json)) {
        return;
      }
      yield put(getUsersAllAddress(() => {}));
      callback(true);
    } else if (statusCode === 401) {
      yield put(logoutUser());
      callback(false);
    } else if (statusCode !== 200) {
      // console.tron.log('error in setting default', json);
      callback(false);
    } else {
      callback(false);
      console.tron.log('status code', statusCode);
    }
  } catch (error) {
    console.tron.log(`error ${JSON.stringify(error)}`);
  }
}

function* updateAddressAsync(action) {
  const {
    data: {
      name,
      pincode,
      line1,
      line2,
      phone_number,
      id,
      contact_name,
      email,
      address_type,
      callingCode,
    },
    callback,
  } = action;
  const url = `${ADDRESS_URL}/${id}?source=cart&platform=app&os=${Platform.OS}`;
  const authToken = yield select((state) => state.UserAccountInfo.authToken);
  console.tron.log('url is ', url, 'data is ', action.data);
  try {
    const response = yield fetch(url, {
      method: 'PATCH',
      headers: yield getApiHeaders(),
      body: JSON.stringify({
        auth_token: authToken,
        address: {
          name,
          line1,
          line2,
          phone_number,
          pincode,
          default: action.data.default,
          contact_name,
          email,
          country_code: callingCode ?? '91',
        },
        address_type,
      }),
    });
    const json = yield convertJsonFromResponse(response);
    const statusCode = response.status;
    if (statusCode >= 200 && statusCode < 300) {
      if (Utility.isBlank(json)) {
        return;
      }
      yield put({
        type: SET_DEFAULT_ADDRESSES,
        addressId: json.id,
        callback: () => {},
      });
      callback(true, json, statusCode);
      yield put({ type: SAVE_ADDRESS, data: json });
    } else if (statusCode === 401) {
      callback(false, json, statusCode);
      yield put(logoutUser());
    } else if (statusCode !== 200) {
      console.tron.log('error in updating address');
      callback(false, json, statusCode);
    } else {
      callback(false, json, statusCode);
      console.tron.log('status code', statusCode);
    }
  } catch (error) {
    console.tron.log(` error ${JSON.stringify(error)}`);
  }
}

function* removeAddressAsyc(action) {
  const {
    data: { id, address_type = '' },
    callback,
  } = action;
  const addressEndpoint =
    address_type === 'ShopifyAddress' ? SHOPIFY_ADDRESS_URL : ADDRESS_URL;
  const url = `${addressEndpoint}/${id}?source=cart&platform=app&os=${Platform.OS}`; // When source = cart, this api also returns pricing data. Which can be different for IOS/Android(last payment method) because intent base payment is not there in IOS and TEZ-OMNI is not in android
  const authToken = yield select((state) => state.UserAccountInfo.authToken);
  try {
    const response = yield fetch(url, {
      method: 'DELETE',
      headers: yield getApiHeaders(),
      body: JSON.stringify({
        auth_token: authToken,
      }),
    });
    const statusCode = response.status;
    if (statusCode >= 200 && statusCode < 300) {
      yield put({ type: REMOVE_ADDRESS_SUCCESS, data: { id } });
      callback(true);
    } else if (statusCode === 401) {
      yield put(logoutUser());
      callback(false, statusCode);
    } else if (statusCode !== 200) {
      callback(false, statusCode);
      console.tron.log('error in removing address');
    } else {
      console.tron.log('status code', statusCode);
    }
  } catch (error) {
    console.tron.log(
      ` error ${JSON.stringify(error)}, auth token : ${authToken}`,
    );
  }
}

function* getPincodeInfo(action) {
  try {
    const { pincode, callback } = action;
    const response = yield fetch(`${URL.PINCODE_INFO}${pincode}`, {
      method: 'GET',
      headers: yield getApiHeaders(),
    });
    if (response.status >= 200 && response.status < 300) {
      const json = yield convertJsonFromResponse(response);
      callback(json);
    } else {
      callback(false);
    }
  } catch (error) {
    console.tron.log('Gettin errr');
  }
}

function* doesShopifyAddressExist(action) {
  try {
    const { phoneNumber, callback } = action;
    const response = yield fetch(
      `${URL.CHECK_SHOPIFY_ADDRESS_BY_PHONE}${phoneNumber}`,
      {
        method: 'GET',
        headers: yield getApiHeaders(),
      },
    );
    if (response.status >= 200 && response.status < 300) {
      const json = yield convertJsonFromResponse(response);
      callback(true, json);
    } else {
      callback(false);
    }
  } catch (error) {
    console.tron.log('Gettin errr');
  }
}
export default function* watchFetchAddress() {
  yield takeLatest(ADD_ADDRESS, addAddressAsync);
  yield takeLatest(UPDATE_ADDRESS, updateAddressAsync);
  yield takeLatest(REMOVE_ADDRESS, removeAddressAsyc);
  yield takeLatest(PINCODE_INFO, getPincodeInfo);
  yield takeLatest(GET_USER_ALL_ADDRESSES, getAllAddressesAsync);
  yield takeLatest(SET_DEFAULT_ADDRESSES, setDefaultAddresses);
  yield takeLatest(DOES_SHOPIFY_ADDRESS_EXIST, doesShopifyAddressExist);
}
