import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  View,
  StyleSheet,
  Text,
  Image,
  TouchableWithoutFeedback,
  TouchableOpacity,
} from 'react-native';
import Modal from 'react-native-modal';
import Toast from 'react-native-easy-toast';
import AddToCart from '../../../components/Product/AddToCart';
import { PRODUCT } from '../../../config/LayoutConstants/ProductConfig';
import { ProductInCartStyles } from '../../../components/Product/styles';
import Utility from '../../../utils/Utility';
import colors from '../../../theme/Colors';
import size from '../../../theme/Fonts';
import { MAX_LINES, CURRENCY_SYMBOL } from '../../../config/Constants';
import { withMaybe } from '../../../lib/Monads';
import { getMinifiedImage } from '../../../utils/ImageUtility';

let opacity = 1;

class AddProductFromScan extends Component {
  showDiscountRateConditionFn = (props) =>
    props.discount === null || props.discount === '';

  discountConditionFn = (props) =>
    parseFloat(props.sp) === parseFloat(props.mrp);

  conditionalPriceText = withMaybe(this.discountConditionFn)(Text);

  conditionalDiscountText = withMaybe(this.showDiscountRateConditionFn)(Text);

  navigate = () => {
    const { navigation } = this.props;
    navigation.navigate('ConsumerProfile');
  };

  _closeContainer = (props) => (
    <TouchableWithoutFeedback onPress={props.onClose}>
      <View style={style.scanMoreButton}>
        <Text style={style.scanMoreText}>Scan More Items</Text>
      </View>
    </TouchableWithoutFeedback>
  );

  _fatButton = (props) => (
    <TouchableWithoutFeedback onPress={props.onButtonPress}>
      <View style={style.buttonContainer}>
        <Text style={style.viewBagButtonText}>View Bag</Text>
      </View>
    </TouchableWithoutFeedback>
  );

  productDescription = (props) => {
    const { mrp, sp, discount, brandName, name } = props;
    const productName = name.replace(`${brandName} `, '');
    return (
      <View style={style.descriptionContainerStyle}>
        <Text
          numberOfLines={MAX_LINES.single}
          style={ProductInCartStyles.brandName}
        >
          {Utility.isPresent(brandName) ? brandName?.toUpperCase() : ''}
        </Text>
        <Text
          numberOfLines={MAX_LINES.two}
          style={ProductInCartStyles.productName}
        >
          {productName}
        </Text>
        <View style={ProductInCartStyles.priceContainer}>
          <Text
            style={ProductInCartStyles.spStyle}
          >{`${CURRENCY_SYMBOL}${sp} `}</Text>
          <this.conditionalPriceText
            style={ProductInCartStyles.mrpStyle}
            sp={sp}
            mrp={mrp}
          >
            {`${CURRENCY_SYMBOL}${mrp}`}
          </this.conditionalPriceText>
          <this.conditionalDiscountText
            style={ProductInCartStyles.discountRateStyle}
            discount={discount}
          >
            {`(${discount || ''})`}
          </this.conditionalDiscountText>
        </View>
      </View>
    );
  };

  productImage = (props) => {
    const { imageUrl } = props;
    return (
      <TouchableOpacity>
        <Image
          source={{
            uri: getMinifiedImage(
              imageUrl,
              style.productImageOrders.width,
              style.productImageOrders.height,
            ),
          }}
          style={style.productImageOrders}
        />
      </TouchableOpacity>
    );
  };

  addToCartError = (response) => {
    if (Utility.isPresent(response.errors) && response.errors.length > 0) {
      this.showToast(response.errors[0]);
    }
  };

  showToast = (message) => {
    this.toast.show(message, 4);
  };

  render() {
    const {
      title,
      onButtonPress,
      onClose,
      isVisible,
      prodData,
      size,
      orientation,
      theme,
      showToast,
      cartItems,
    } = this.props;

    const uniqueCartItems = cartItems.filter(
      (item) =>
        item.sku_id === prodData.sku_id && Utility.isPresent(item.product),
    );

    return (
      <Modal
        isVisible={isVisible}
        swipeDirection="down"
        style={style.bottomModal}
      >
        <View style={style.container}>
          <Text style={style.headingText}>{title}</Text>
          <View
            style={{
              marginTop: 30,
              marginBottom: 30,
              justifyContent: 'space-between',
              flexDirection: 'row',
            }}
          >
            <this.productImage
              imageUrl={prodData.product.image_url}
              layout={'screen'}
              size={size}
              id={prodData.product.id}
            />
            <this.productDescription
              mrp={prodData.product.mrp}
              name={prodData.product.name}
              sp={prodData.product.sp}
              discount={prodData.product.discount}
              brandName={prodData.product.brand && prodData.product.brand.name}
              opacity={opacity}
              brand={prodData.product.brand}
              couponDiscount={prodData.discount}
            />
            {Utility.isPresent(uniqueCartItems) && (
              <AddToCart
                skuId={prodData.sku_id}
                quantity={uniqueCartItems[0].quantity}
                orientation={orientation}
                layout={'screen'}
                theme={theme}
                addToCartLayout={PRODUCT.ADD_TO_CART_LAYOUT.CART}
                cartItem={prodData.product}
                error={prodData.error}
                errorCallback={this.addToCartError}
                showToast={showToast}
              />
            )}
          </View>
          <View
            style={{
              marginBottom: 60,
              justifyContent: 'space-between',
              flexDirection: 'row',
            }}
          >
            <this._closeContainer onClose={onClose} />
            <this._fatButton onButtonPress={onButtonPress} />
          </View>
          <Toast
            ref={(ref) => {
              this.toast = ref;
            }}
          />
        </View>
      </Modal>
    );
  }
}

const style = StyleSheet.create({
  bottomModal: {
    position: 'absolute',
    height: Utility.getWindowHeight(),
    bottom: 0,
    left: 0,
    right: 0,
    marginBottom: 0,
    marginRight: 0,
    marginLeft: 0,
    justifyContent: 'flex-end',
  },
  container: {
    height: 300,
    width: Utility.getScreenWidth(),
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    backgroundColor: colors.white,
    justifyContent: 'flex-end',
    marginBottom: 0,
  },

  productImageOrders: {
    height: 80,
    width: 80,
    marginLeft: 12,
    marginTop: 10,
    marginBottom: 8,
    marginRight: 10,
    resizeMode: 'contain',
  },
  descriptionContainerStyle: {
    height: '100%',
    width: Utility.getScreenWidth() - 200,
    marginRight: 100,
    marginLeft: 8,
    opacity: 1,
  },
  viewBagButtonText: {
    fontFamily: 'Roboto-Bold',
    fontSize: 16,
    color: '#fff',
  },

  headingText: {
    fontFamily: 'Roboto-Bold',
    marginLeft: Utility.getScreenWidth() * 0.05,
    marginTop: 16,
    fontSize: size.h1,
    color: '#1D3142',
    textAlign: 'left',
    width: Utility.getScreenWidth() * 0.9,
  },

  buttonContainer: {
    width: Utility.getScreenWidth() * 0.4,
    marginRight: Utility.getScreenWidth() * 0.05,
    borderRadius: 4,
    justifyContent: 'center',
    alignItems: 'center',
    height: 46,
    backgroundColor: '#fd5c63',
  },

  scanMoreText: {
    fontFamily: 'Roboto-Bold',
    fontSize: 16,
    color: '#ff5a5f',
  },

  scanMoreButton: {
    padding: 20,
    marginLeft: Utility.getScreenWidth() * 0.05,
    backgroundColor: '#fff',
    borderRadius: 4,
    borderWidth: 1,
    borderColor: '#ff5a5f',
    justifyContent: 'center',
    alignItems: 'center',
    width: Utility.getScreenWidth() * 0.4,
    height: 46,
  },
});

const mapStateToProps = (state) => ({
  cartItems: state.bag.cartItems,
});

export default connect(mapStateToProps, null)(AddProductFromScan);
