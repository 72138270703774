import { Image, Text } from 'react-native';
import React, { useState } from 'react';
import Config from '../../libraries/ReactNativeConfig';
import { jsonParser } from '../../utils/JsonUtility';
import DebouncedTouchableOpacity from '../shared/DebouncedTouchableOpacity';
import { getHitSlop } from '../../utils/LayoutUtility';
import images from '../../theme/Images';
import { ProductDetailStyles } from './styles';
import { getMinifiedImage } from '../../utils/ImageUtility';
import FoxyPromiseModal from './FoxyPromiseModal';
import { AnalyticsManager, EventType } from '../../analytics';
import Utility from '../../utils/Utility';

export default function CertificateButton({ data }) {
  const [showModal, setShowModal] = useState(false);
  if (jsonParser(Config.DISABLE_ORIGINAL_CERTIFICATE_FROM_PRODUCT_DETAIL)) {
    return null;
  }

  const { certificate_image_webp_url: certificateImageUrl } = data?.brand || {};
  const promises = [{
    heading: '100% original products',
    subheading:
      'All products on Foxy are sourced directly from brand and/or their authorized resellers and are 100% original',
    isOriginal: true,
    image: getMinifiedImage(certificateImageUrl, 382),
  }];

  const hideModal = () => setShowModal(false);
  const handlePress = () => {
    const {
      name,
      brand: { id: brandId, name: brandName } = {},
      mrp,
      sp,
      product_category_name: productCategoryName = '',
      product_category: { id: productCategoryId } = {},
    } = data;
    AnalyticsManager.logEvent(EventType.product.AUTHENTICITY_CLICK, {
      product_id: Utility.getSkuId(data),
      product_name: name,
      brand_id: brandId,
      brand_name: brandName,
      product_category_id: productCategoryId,
      product_category_name: productCategoryName,
      product_listing_price: mrp,
      product_selling_price: sp,
    });
    setShowModal(true);
  };
  return (
    <>
      <DebouncedTouchableOpacity
        onPress={handlePress}
        style={ProductDetailStyles.certificateButton}
        hitSlop={getHitSlop()}
      >
        <Image
          source={images.originalProductLogo}
          style={ProductDetailStyles.originalProductLogo}
        />
        <Text
          allowFontScaling={false}
          style={ProductDetailStyles.certificateButtonText}
        >
          100% Original
        </Text>
      </DebouncedTouchableOpacity>
      <FoxyPromiseModal promises={promises} showModal={showModal} hideModal={hideModal} />
    </>
  );
}
