import toLower from 'lodash/toLower';
import { URL, URLSearchParams } from '../libraries/ReactNativeUrlPolyfill';
import Config from '../libraries/ReactNativeConfig';
import { isIOS, isPresent, isBlank } from './BooleanUtility';
import { restructuredPersonalizedElements } from './ArrayUtility';
import { PLATFORM_VERSION } from '../config/Constants';
import { getStoreRef } from '../store/StoreUtility';

const getUserPersonalizationInfo = () => {
  const state = getStoreRef().getState();
  const {
    userPersonalizationInfo = {},
    UserAccountInfo: {
      device_information: {
        model: deviceModel = '',
        os_version: androidVersion = '',
      } = {},
      locationInfo: { city = '' } = {},
    } = {},
    todayDeals: { membership_id: edgePlanId = '' } = {},
    facialAnalysis: { my_attributes_values = [] } = {},
    UserAccountInfo,
  } = state;
  const myAttributesValue = UserAccountInfo?.facialAnalysis?.my_attributes_values;
  return {
    userPersonalizationInfo,
    deviceModel,
    osVersion: isIOS() ? PLATFORM_VERSION : androidVersion,
    edgePlanId,
    myAttributesValue,
    city,
  };
};

const filterUserAttributes = (
  userPersonalizationInfo,
  requiredAttributes,
  isPersonalizedUrl,
) => {
  if (isPersonalizedUrl) {
    return userPersonalizationInfo;
  }
  return Object.fromEntries(
    Object.entries(userPersonalizationInfo).filter(([key]) =>
      requiredAttributes.includes(key),
    ),
  );
};

const filterMyUserAttributes = (
  userAttribute,
  requiredAttributes,
  isPersonalizedUrl,
) => {
  if (isPersonalizedUrl) {
    return userAttribute;
  }
  const filteredAttributeData = userAttribute.filter((item) =>
    requiredAttributes.includes(item.user_attribute_id.toString()),
  );
  return filteredAttributeData;
};

const appendAttributeToUrl = (url, key, value) => {
  return `${url}&${key}=${value}`;
};

export const filteredRequiredAttribute = (
  url,
  requiredAttributes,
  isPersonalizedUrl,
) => {
  const {
    userPersonalizationInfo,
    deviceModel,
    osVersion,
    edgePlanId,
    myAttributesValue,
    city,
  } = getUserPersonalizationInfo();
  const filteredUserAttribute = filterUserAttributes(
    userPersonalizationInfo,
    requiredAttributes,
    isPersonalizedUrl,
  );

  const filterMyAttributesValues = filterMyUserAttributes(
    myAttributesValue,
    requiredAttributes,
    isPersonalizedUrl,
  );

  if (isPresent(filteredUserAttribute)) {
    const personalizedAttributesArray = restructuredPersonalizedElements(
      filteredUserAttribute,
    );
    url = appendAttributesWithHomePageUrl(
      url,
      personalizedAttributesArray,
      true,
    );
  }

  if (isPresent(filterMyAttributesValues)) {
    url = appendAttributesWithHomePageUrl(url, filterMyAttributesValues, true);
  }

  if (
    requiredAttributes?.includes(Config.OS_VERSION_PERSONALIZED_ATTRIBUTE_ID) ||
    isPersonalizedUrl
  ) {
    url = appendAttributeToUrl(url, 'os_version', osVersion);
  }

  if (
    requiredAttributes?.includes(Config.APP_VERSION_PERSONALIZED_ATTRIBUTE_ID) ||
    isPersonalizedUrl
  ) {
    url = appendAttributeToUrl(url, 'app_version', Config.VERSION_NAME);
  }

  if (
    (requiredAttributes?.includes(
      Config.EDGE_MEMBERSHIP_PERSONALIZED_ATTRIBUTE_ID,
    ) ||
      isPersonalizedUrl) &&
    isPresent(edgePlanId)
  ) {
    url = appendAttributeToUrl(url, 'edge_plan_id', edgePlanId);
  }

  if (
    (requiredAttributes?.includes(Config.LOCATION_PERSONALIZED_ATTRIBUTE_ID) ||
      isPersonalizedUrl) &&
    isPresent(city)
  ) {
    url = appendAttributeToUrl(url, 'city_name', toLower(city));
  }

  return url;
};

export const appendAttributesWithHomePageUrl = (
  url,
  attributesList = [],
  myAttributes,
) => {
  //TODO:COnvert this two loops into one which will check the object & key to extract on bases on condition
  if (myAttributes) {
    attributesList.forEach((userAttribute) => {
      if (isPresent(userAttribute.new_values)) {
        userAttribute.new_values.forEach((attributeValue) => {
          url = `${url}&user_attributes[${userAttribute.user_attribute_id}][]=${attributeValue}`;
        });
      }
    });
  } else {
    attributesList.forEach((userAttribute) => {
      if (isPresent(userAttribute.values)) {
        userAttribute.values.forEach((attributeValue) => {
          if (isBlank(attributeValue?.id)) {
            return;
          }
          url = `${url}&user_attributes[${userAttribute.id}][]=${attributeValue?.id}`;
        });
      }
    });
  }
  return url;
};

export const extractQueryParamsFromDeepLink = (url = '') => {
  if (url.indexOf('?') === -1) return { url };
  const [origin, paramString] = url.split('?');
  const urlObj = new URL(`${Config.HTTPS_WWW_WEB_URL}?${paramString}`);
  const searchParams = new URLSearchParams(urlObj.search);

  const queryParams = {};

  for (const [key, value] of searchParams.entries()) {
    if (isWhitelistedExtraEventParams(key)) {
      queryParams[key] = value;
    }
  }

  if (isBlank(queryParams['utm_source'])) {
    if (isPresent(queryParams['gs'])) {
      queryParams['utm_source'] = 'google_shopping';
    } else if (isPresent(queryParams['gclid'])) {
      queryParams['utm_source'] = 'gclid';
    }
  }

  return { ...queryParams, url: origin };
};


const isWhitelistedExtraEventParams = (paramKey = '') => keysToExclude.test(paramKey);

const keysToExclude = /^(?!.*(moeFeatures|moe_cid_attr|gcm_webUrl|gcm_image_url)).*$/;
