import React, { Component } from 'react';
import {
  View,
  Text,
  StyleSheet,
  ImageBackground,
  TouchableOpacity,
  Image,
  TouchableWithoutFeedback,
  ActivityIndicator,
  StatusBar,
} from 'react-native';
import Modal from 'react-native-modal';
import { Navigation } from 'swiper/modules';
import Utility from './Utility';
import images from '../theme/Images';
import colors from '..//theme/Colors';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ProductInfoStyles } from '../components/Product/styles';
import { FlatList } from 'react-native-gesture-handler';
import ImageZoom from '../libraries/ReactNativeImagePanZoom';
import styles from '../components/Artist/Onboarding/styles';
import { ProductDetailStyles } from '../components/Product/styles';
import { withMaybe, withEither } from '../lib/Monads';
import { CURRENCY_SYMBOL } from '../config/Constants';
import AddToCart from '../components/Product/AddToCart';
import { SCREEN_CONSTANTS } from '../config/ScreenConstants';
import { AnalyticsManager, EventParameterKey, EventType } from '../analytics';
import foxyImages from '../theme/Images';
import FastImageView from '../components/FastImageView';
import Config from '../libraries/ReactNativeConfig';
import LottieView from 'lottie-react-native';
import animations from '../theme/Animations';
import AppConfig from '../config/AppConfig';
import { isDesktop, isWeb } from './BooleanUtility';
import ModalContainer from '../components/modals/ModalContainer';

class FullScreenImageModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isImageLoading: false,
      zoomAnimationJson: {},
      currentImageIndex: 0,
    };
    this.swiperRef = React.createRef();
    this.isZoomInEventFired = false;
    const { route } = this.props;
    const { itemData: { product_image_aspect_shape = 'square' } = {} } = route.params;
    this.productImageAspectShape = product_image_aspect_shape;
    this.fetchZoomAnimationJson();
  }

  componentDidUpdate(prevProps, prevState) {
    const { currentImageIndex } = this.state;

    if (prevState.currentImageIndex !== currentImageIndex) {
      this.isZoomInEventFired = false;
    }
  }

  getItemLayout = (data, index) => {
    const length =
      modalStyles[`${this.productImageAspectShape}ImageThumbnail`].width;
    const offset = length * index;
    return {
      length,
      offset,
      index,
    };
  };

  fireZoomInEvent = () => {
    if (!this.isZoomInEventFired) {
      this.isZoomInEventFired = true;
      this.fireEvent(EventType.panZoom.ZOOMING, 'zooming');
    }
  };

  scrollImageSelectorRail = (index) => {
    const { route } = this.props;
    const { swiperIndexChanged = () => {} } = route.params;
    this.setState({
      currentImageIndex: index,
    });
    swiperIndexChanged(index);
  };

  slideSwiper = (index) => {
    if (this.swiperRef.current && this.swiperRef.current.swiper) {
      this.swiperRef.current.swiper.slideTo(index);
    }
  };

  onScrollToIndexFailed = () => {
    if (this.flatListRef) {
      this.flatListRef.current.scrollToIndex({ animated: true, index: 0 });
    }
  };

  scrollImage = (index) => {
    this.fireEvent(EventType.panZoom.ZOOM_MODAL_CLICK, 'thumbnail');
    this.scrollImageSelectorRail(index);
    this.slideSwiper(index);
  };

  imageZoomed = (position) => {
    this.fireZoomInEvent();
  };

  onLoadStart = () => {
    this.setState({
      isImageLoading: true,
    });
  };

  onLoadEnd = () => {
    this.setState({
      isImageLoading: false,
    });
  };

  fetchZoomAnimationJson = () => {
    if (isWeb()) return;
    try {
      fetch(animations.zoom_placeholder, {
        method: 'GET',
      })
        .then((response) => response?.json())
        .then((responseData) => {
          this.setState({
            zoomAnimationJson: responseData,
          });
        });
    } catch (e) {
      console.tron.log(e);
    }
  };

  zoomAnimation = () => {
    const { route } = this.props;
    const { zoomAnimation, clearZoomAnimationTimeout } = route.params;
    const { zoomAnimationJson } = this.state;
    if (!zoomAnimation || Utility.isBlank(zoomAnimationJson)) return null;
    return (
      <TouchableWithoutFeedback onPress={clearZoomAnimationTimeout}>
        <View style={modalStyles.zoomOverlay}>
          <View style={modalStyles.zoomAnimationContainer}>
            <LottieView
              source={zoomAnimationJson}
              style={modalStyles.zoomAnimation}
              autoPlay
              loop
            />
          </View>
        </View>
      </TouchableWithoutFeedback>
    );
  };

  getImage = ({ imageUrl }) => {
    return (
      <ImageZoom
        cropWidth={modalStyles.productDetailImage.width}
        cropHeight={modalStyles.productDetailImage.height - 52}
        imageWidth={modalStyles.productDetailImage.width}
        imageHeight={modalStyles.productDetailImage.height - 132}
        maxScale={5}
        useNativeDriver={true}
        onMove={this.imageZoomed}
      >
        <FastImageView
          resizeMode='contain'
          style={modalStyles.zoomableImage}
          source={Utility.getMinifiedImage(imageUrl, 1500)}
          onLoadStart={this.onLoadStart}
          onLoadEnd={this.onLoadEnd}
        />
      </ImageZoom>
    );
  };

  bottomView = () => {
    return (
      <>
        <this.priceView />
        <this.addToCartView />
      </>
    );
  };

  foxyOfferRow = (props) => {
    const { discount } = props;
    return (
      <Text style={ProductDetailStyles.discountRateStyle}>
        {` ${discount}`}
      </Text>
    );
  };

  discountConditionFn = (props) =>
    parseFloat(props.sp) !== parseFloat(props.mrp);

  showDiscountRateConditionFn = (props) =>
    props.discount === null || props.discount === '';

  conditionalPriceText = withMaybe(this.showDiscountRateConditionFn)(Text);

  conditionalDiscountText = withMaybe(this.showDiscountRateConditionFn)(Text);

  offerDiscountRow = (props) => {
    const { discount } = props;

    return (
      <this.conditionalDiscountText
        style={ProductDetailStyles.discountRateStyle}
        discount={discount}
      >
        {`${discount}`}
      </this.conditionalDiscountText>
    );
  };

  priceView = () => {
    const { route = {} } = this.props;
    let {
      mrp,
      sp,
      discount,
      itemData: { final_sp },
      selectedVariant,
      prompt_discount,
      prompt_esp,
      prompt_mrp,
    } = route.params;

    let display_sp_price = '';
    if (Utility.isBlank(selectedVariant)) {
      display_sp_price = final_sp;
    } else {
      display_sp_price = selectedVariant.final_sp;
    }
    if (Utility.isPresent(prompt_esp) && Utility.isBlank(selectedVariant)) {
      sp = prompt_esp;
      display_sp_price = prompt_esp;
      display_price = prompt_esp;
      mrp = prompt_mrp;
      display_discount = prompt_discount;
      discount = prompt_discount;
    }

    return (
      <View style={modalStyles.pricingContainer}>
        <View style={ProductDetailStyles.pricingTextContainer}>
          <Text style={ProductDetailStyles.spStyle}>
            {`${CURRENCY_SYMBOL}${display_sp_price} `}
          </Text>
          {parseFloat(display_sp_price) !== parseFloat(mrp) && (
            <Text
              style={ProductDetailStyles.mrpStyle}
              sp={sp}
              mrp={mrp}
              discount={discount}
            >
              {`${CURRENCY_SYMBOL}${mrp}`}
            </Text>
          )}
        </View>
        <this.offerDiscountRow discount={discount} />
      </View>
    );
  };

  addToCartView = () => {
    const { route = {} } = this.props;
    const {
      skuId,
      itemData,
      addedToCart,
      addToCartLayout,
      selectedVariant,
      onMoreShadeTap,
      variantType,
      showToast,
      refreshOfferStrip,
      previousScreen,
      isUnStocked,
      navigation,
      campaignId,
      listData = {},
      itemData: {
        priority,
        has_variants: hasVariants,
        maxFreeItemsToSelect,
        sku_id,
      },
    } = route.params;
    return (
      <View style={modalStyles.addToCartTextContainer}>
        <AddToCart
          addedToCart={addedToCart}
          addToCartLayout={addToCartLayout}
          showCart={this.showCart}
          skuId={
            Utility.isBlank(selectedVariant) ? sku_id : selectedVariant?.sku_id
          }
          selectedVariant={selectedVariant}
          hasVariants={hasVariants}
          showToast={showToast}
          refreshOfferStrip={refreshOfferStrip}
          previousScreen={SCREEN_CONSTANTS.PRODUCT_DETAIL}
          maxFreeItemsToSelect={maxFreeItemsToSelect}
          itemData={itemData}
          priority={priority}
          campaignId={campaignId}
          // onItemAddToCartFromCollab={onItemAddToCartFromCollab}
          navigation={navigation}
          showRoundedView={true}
          dismissModal={this.dismissModal}
          listData={listData}
        />
      </View>
    );
  };

  imageThumbnail = (image, index) => {
    const { currentImageIndex } = this.state;
    const borderColor =
      currentImageIndex === index ? colors.green : colors.borderLight;
    const resizeMode = this.isVerticalImage ? 'cover' : 'contain';
    const imageThumbnailFastImage = {
      height:
        modalStyles[`${this.productImageAspectShape}ImageThumbnail`].height,
      width: modalStyles[`${this.productImageAspectShape}ImageThumbnail`].width,
    };

    return (
      <View style={modalStyles.imageThumbnailShadow}>
        <TouchableWithoutFeedback onPress={() => this.scrollImage(index)}>
          <View
            style={[
              modalStyles[`${this.productImageAspectShape}ImageThumbnail`],
              { borderColor },
            ]}
          >
            <FastImageView
              resizeMode={resizeMode}
              style={imageThumbnailFastImage}
              source={{
                uri: Utility.getMinifiedImage(image, 64, 64),
              }}
            />
          </View>
        </TouchableWithoutFeedback>
      </View>
    );
  };

  imageSelectorRailKeyExtractor = (item, index) => `${index}_${item}`;
  imageSelectorRailRenderItem = ({ item, index }) =>
    this.imageThumbnail(item, index);

  imageSelectorRail = (props) => {
    const { images, currentImageIndex } = props;
    const styles = ProductInfoStyles;
    return (
      <View
        style={
          modalStyles[
            `${this.productImageAspectShape}ImageSelectorRailContainer`
          ]
        }
      >
        <FlatList
          style={styles.imageSelectorRailList}
          ref={this.flatListRef}
          data={images}
          initialScrollIndex={currentImageIndex}
          contentContainerStyle={
            styles.imageSelectorRailListCoontentContainerStyle
          }
          horizontal
          showsHorizontalScrollIndicator={false}
          getItemLayout={this.getItemLayout}
          renderItem={this.imageSelectorRailRenderItem}
          keyExtractor={this.imageSelectorRailKeyExtractor}
          onScrollToIndexFailed={this.onScrollToIndexFailed}
          extraData={currentImageIndex}
        />
      </View>
    );
  };

  bottomContentContainer = () => {
    return (
      <View style={modalStyles.bottomParentContainer}>
        <this.bottomMarginView />
        {!this.productImageAspectShape.includes('vertical') && (
          <this.bottomView />
        )}
      </View>
    );
  };

  bottomMarginView = () => {
    return <View style={modalStyles.bottomContainerTopMargin}></View>;
  };

  swipeButtons = () => {
    const { route } = this.props;
    const { images, currentImageIndex } = route.params;

    if (images.length == 1) {
      return null;
    }
    if (currentImageIndex == 0) {
      return <this.rightButton />;
    }
    if (images.length == currentImageIndex + 1) {
      return <this.leftButton />;
    }
    return (
      <>
        <this.rightButton />
        <this.leftButton />
      </>
    );
  };

  swiperIndexChangedWeb = ({ activeIndex }) => {
    this.scrollImageSelectorRail(activeIndex);
  };

  renderContentWeb = () => {
    const { route } = this.props;
    const { images, currentImageIndex } = route.params;
    return (
      <Swiper
        style={{
          '--swiper-navigation-color': '#818D98',
          '--swiper-navigation-size': '32px',
        }}
        ref={this.swiperRef}
        modules={[Navigation]}
        navigation
        initialSlide={currentImageIndex}
        onSlideChange={this.swiperIndexChangedWeb}
      >
        {images.map((item) => {
          return (
            <SwiperSlide>
              <this.getImage imageUrl={item} />
            </SwiperSlide>
          );
        })}
      </Swiper>
    );
  };

  fullContent = (props) => {
    const { images, currentImageIndex } = props;
    return (
      <View style={modalStyles.imageContainer}>
        <this.renderContentWeb />

        <this.imageSelectorRail
          images={images}
          currentImageIndex={currentImageIndex}
        />
        {/* {this.props.isProductInStock ? <this.bottomContentContainer /> : null} */}
      </View>
    );
  };

  foxyOfferRow = (props) => {
    const { discount } = props;
    return (
      <Text style={ProductDetailStyles.discountRateStyle}>
        {` ${discount}`}
      </Text>
    );
  };

  showCart = () => {
    this.dismissModal();
  };

  fireEvent = (eventName, type) => {
    AnalyticsManager.logEvent(eventName, {
      [EventParameterKey.INDEX]: this.props.currentImageIndex,
      [EventParameterKey.TYPE]: type,
      [EventParameterKey.SKU_ID]: this.props.skuId,
    });
  };

  dismissModal = () => {
    const { toggleFullScreenImage, navigation } = this.props;
    this.fireEvent(EventType.panZoom.ZOOM_MODAL_CLOSE, 'dismiss');
    navigation.goBack();
  };

  crossButton = () => {
    const { route = {} } = this.props;
    const { name = '' } = route.params || {};
    return (
      <TouchableOpacity
        style={modalStyles.cancelButton}
        onPress={this.dismissModal}
        hitSlop={Utility.getHitSlop('likeButton')}
      >
        <Text
          style={{
            fontSize: 14,
            fontFamily: 'Roboto-Medium',
            color: colors.foxyBlack,
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
          }}
          numberOfLines={1}
          ellipsizeMode='tail'
        >
          {name}
        </Text>
        <View style={modalStyles.cancelParentViewStyle}>
          <Image source={images.cross} style={modalStyles.cancelStyle} />
        </View>
      </TouchableOpacity>
    );
  };

  discountConditionFn = (props) =>
    props.sp === props.mrp && !props.offer_applicable;

  showDiscountRateConditionFn = (props) =>
    props.discount === null || props.discount === '';

  conditionalPriceText = withMaybe(this.discountConditionFn)(Text);
  conditionalDiscountText = withMaybe(this.showDiscountRateConditionFn)(Text);

  offerDiscountRow = (props) => {
    const { route } = this.props;
    const {
      itemData: { offer_applicable, offer_value, additional_discount },
    } = route.params || {};
    const { discount } = props;
    if (offer_applicable) {
      return (
        <this.foxyOfferRow
          additional_discount={additional_discount}
          discount={discount}
        />
      );
    }
    return (
      <this.conditionalDiscountText
        style={ProductDetailStyles.discountRateStyle}
        discount={discount}
      >
        {`${discount}`}
      </this.conditionalDiscountText>
    );
  };

  scrollToNextImage = () => {
    const { route } = this.props;
    const { images } = route.params;
    const { currentImageIndex } = this.state;
    if (images.length > currentImageIndex + 1) {
      this.swiperRef.current.scrollTo(currentImageIndex + 1);
      this.scrollImageSelectorRail(currentImageIndex + 1);
    }
  };

  scrollToPreviousImage = (index) => {
    const { currentImageIndex } = this.state;
    if (currentImageIndex > 0) {
      this.swiperRef.current.scrollTo(currentImageIndex - 1);
      this.scrollImageSelectorRail(currentImageIndex - 1);
    }
  };

  rightButton = () => {
    this.fireEvent(EventType.panZoom.ZOOM_MODAL_CLICK, 'next');
    return (
      <TouchableOpacity
        style={modalStyles.rightButton}
        onPress={this.scrollToNextImage}
        hitSlop={Utility.getHitSlop()}
      >
        <Image
          source={images.rightGrayChevron}
          style={modalStyles.rightButtonStyle}
        />
      </TouchableOpacity>
    );
  };

  leftButton = () => {
    this.fireEvent(EventType.panZoom.ZOOM_MODAL_CLICK, 'previous');
    return (
      <TouchableOpacity
        style={modalStyles.leftButton}
        onPress={this.scrollToPreviousImage}
        hitSlop={Utility.getHitSlop()}
      >
        <Image
          source={images.leftGrayChevron}
          style={modalStyles.leftButtonStyle}
        />
      </TouchableOpacity>
    );
  };

  render() {
    const { route } = this.props;
    const { images } = route.params || {};
    const { isImageLoading, currentImageIndex } = this.state;
    return (
      <ModalContainer onModalTapOutside={this.dismissModal}>
        <this.fullContent
          images={images}
          currentImageIndex={currentImageIndex}
        />
        <this.zoomAnimation />
        <this.crossButton />
        {isImageLoading && (
          <>
            <View style={styles.animatedLogoPlaceholderContainer}>
              <FastImageView
                style={styles.animatedLogoPlaceholder}
                source={Config.PRODUCT_PAGE_ANIMATED_LOGO}
              />
            </View>
          </>
        )}
      </ModalContainer>
    );
  }
}

const modalStyles = StyleSheet.create({
  modalContainer: {
    width: '100%',
    height: (16 / 9) * (Utility.getScreenWidth() - 48) - 70,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
  },
  imageContainer: {
    flexDirection: 'column',
    backgroundColor: 'white',
    borderRadius: 4,
    width: Utility.getScreenWidth(),
    height: Utility.getWindowInnerHeight(),
    overflow: 'hidden',
  },
  productDetailImage: {
    height: Utility.getWindowInnerHeight() - 48 - 70,
    width: Utility.getScreenWidth(),
    // paddingTop: 12,
    paddingBottom: 12,
  },
  zoomableImage: {
    height: '100%',
    width: Utility.getScreenWidth(),
  },
  cancelButton: {
    position: 'absolute',
    top: 12,
    zIndex: 2,
    // paddingTop: Utility.isAndroid()
    //   ? 8 + Utility.topInset
    //   : 30 + Utility.topInset,
    flexDirection: 'row',
    width: Utility.getScreenWidth(),
    marginHorizontal: 12,
    justifyContent: 'space-between',
    alignItems: 'center',
    // alignSelf: 'center',
  },
  cancelStyle: {
    tintColor: colors.foxyBlack,
    height: 14,
    width: 14,
  },
  cancelParentViewStyle: {
    height: 28,
    width: 28,
    borderRadius: 14,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: isDesktop() ? 44 : 16,
    marginLeft: 'auto',
  },
  rightButton: {
    position: 'absolute',
    top: (Utility.getWindowInnerHeight() - 140) / 2,
    alignItems: 'flex-end',
    right: 16,
  },
  rightButtonStyle: {
    height: 24,
    width: 16,
  },
  leftButton: {
    position: 'absolute',
    top: (Utility.getWindowInnerHeight() - 140) / 2,
    alignItems: 'flex-start',
    left: 16,
  },
  leftButtonStyle: {
    height: 24,
    width: 16,
  },
  addToCartTextContainer: {
    flex: 1,
    height: '100%',
    justifyContent: 'center',
    zIndex: -1,
    borderRadius: 4,
  },
  addToCartButtonContainer: {
    flex: 2,
    flexDirection: 'row',
    width: '90%',
    alignSelf: 'center',
  },
  pricingContainer: {
    flex: 1,
    // width: 100,
    flexDirection: 'column',
    justifyContent: 'center',
    // marginLeft: 16,
    marginBottom: 8,
  },
  bottomContainerTopMargin: {
    flexDirection: 'row',
    height: 1,
    width: '100%',
    marginBottom: 20,
    backgroundColor: '#7070700D',
  },
  bottomContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: 'white',
    marginLeft: 16,
    marginRight: 16,
    // position: 'absolute',
    bottom: Utility.isAndroid() ? 45 : 18,
    // marginTop: -100,
    height: 60,
  },
  bottomParentContainer: {
    flexDirection: 'column',
  },
  squareImageSelectorRailContainer: {
    position: 'absolute',
    bottom: 0,
    zIndex: 2,
    height: 172,
    width: Utility.getScreenWidth(),
    alignItems: 'center',
  },
  verticalImageSelectorRailContainer: {
    position: 'absolute',
    bottom: 20,
    zIndex: 2,
    height: 172,
    width: Utility.getScreenWidth(),
    alignItems: 'center',
  },
  squaresmallImageSelectorRailContainer: {
    position: 'absolute',
    bottom: 0,
    zIndex: 2,
    height: 172,
    width: Utility.getScreenWidth(),
    alignItems: 'center',
  },
  verticalsmallImageSelectorRailContainer: {
    position: 'absolute',
    bottom: 20,
    zIndex: 2,
    height: 172,
    width: Utility.getScreenWidth(),
    alignItems: 'center',
  },
  squareImageThumbnail: {
    height: 64,
    width: 64,
    marginRight: 6,
    marginLeft: 6,
    borderWidth: 1,
    borderRadius: 4,
    overflow: 'hidden',
    backgroundColor: 'white',
  },
  verticalImageThumbnail: {
    height: 100,
    width: 75,
    marginRight: 6,
    marginLeft: 6,
    borderWidth: 1,
    borderRadius: 4,
    overflow: 'hidden',
    backgroundColor: 'white',
  },
  squaresmallImageThumbnail: {
    height: 64,
    width: 64,
    marginRight: 6,
    marginLeft: 6,
    borderWidth: 1,
    borderRadius: 4,
    overflow: 'hidden',
    backgroundColor: 'white',
  },
  verticalsmallImageThumbnail: {
    height: 100,
    width: 75,
    marginRight: 6,
    marginLeft: 6,
    borderWidth: 1,
    borderRadius: 4,
    overflow: 'hidden',
    backgroundColor: 'white',
  },
  imageThumbnailFastImage: {
    height: '100%',
    width: '100%',
  },
  imageThumbnailShadow: {
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.28,
    shadowRadius: 2,
  },
  zoomAnimation: {
    height: 300,
    width: 300,
    resizeMode: 'cover',
    alignSelf: 'center',
  },
  zoomAnimationContainer: {
    position: 'absolute',
    alignSelf: 'center',
    zIndex: 4,
    top: (Utility.getWindowInnerHeight() - 318) / 2,
  },
  zoomOverlay: {
    width: '100%',
    flex: 1,
    height: Utility.getWindowInnerHeight(),
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.zoomOverlay,
    position: 'absolute',
    zIndex: 3,
  },
  animatedLogoPlaceholder: {
    width: 80,
    height: 80,
    resizeMode: 'stretch',
  },
  animatedLogoPlaceholderContainer: {
    flex: 1,
    position: 'absolute',
    height: Utility.getWindowInnerHeight(),
    width: Utility.getScreenWidth(),
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
    zIndex: 1,
  },
});

export default FullScreenImageModal;
