import React, { useEffect, useState } from 'react';
import {
  ScrollView,
  View,
  Text,
  TouchableOpacity,
  KeyboardAvoidingView,
  Platform,
  Image,
} from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigation, useRoute } from '@react-navigation/native';
import { OutlinedTextField } from 'react-native-material-textfield';
import images from '../../theme/Images';
import { ids, styles } from './styles';
import { isDesktop, isPresent } from '../../utils/BooleanUtility';
import colors from '../../theme/Colors';
import GrayScaledImage from '../../containers/inviteCenter/GrayscaleImage';
import {
  getInfluencerDetails,
  updateInfluencerData,
} from '../../actions/InfluencerActions';
import { getProAccountObjectForId } from './OneLinkConfig';
import { AnalyticsManager, EventType } from '../../analytics';
import DesktopHeaderLogo from '../shared/DesktopHeaderLogo';

export default function AddSocialProfiles() {
  const { id: currentUserId = '', profile = {} } = useSelector((state) => state.UserAccountInfo) || {};
  const { handle: instagramHandle = '', intro_video_present: isIntroVideoPresent = false } = profile;
  const [focusedTextField, setFocusedTextField] = useState('');
  const [instagramUsername, setInstagramUsername] = useState(instagramHandle);
  const [twitterHandle, setTwitterHandle] = useState('');
  const [youtubeChannel, setYoutubeChannel] = useState('');
  const [youtubeChannelError, setYoutubeChannelError] = useState('');
  const [profileUrl, setProfileUrl] = useState('');
  const [platformUrl, setPlatformUrl] = useState('');
  const [platformUrlError, setPlatformUrlError] = useState('');
  const [influencerData, setInfluencerData] = useState({});
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const { params = {} } = useRoute();
  const { source = '' } = params;
  const fromDashboard = source === 'dashboard';

  useEffect(() => {
    fetchInfluencerDetails();
  }, []);

  const fetchInfluencerDetails = () => {
    if (isPresent(instagramHandle)) {
      dispatch(
        getInfluencerDetails(instagramHandle, (success, response) => {
          if (success) {
            const { pro_accounts = [] } = response;
            const defaultTwitterHandle =
              getProAccountObjectForId(pro_accounts, 2)?.user_input || '';
            setTwitterHandle(defaultTwitterHandle);
            const defaultYoutubeChannel =
              getProAccountObjectForId(pro_accounts, 6)?.user_input || '';
            setYoutubeChannel(defaultYoutubeChannel);
            const defaultProfileUrl =
              getProAccountObjectForId(pro_accounts, 61)?.user_input || '';
            setProfileUrl(defaultProfileUrl);
            setInfluencerData(response);
            AnalyticsManager.logEvent(EventType.INFLUENCER.ADD_SOCIAL_MEDIA_ACCOUNTS_PAGE_VIEW, {
              current_user_id: currentUserId,
              edit: pro_accounts.length > 0,
            });
          }
        }),
      );
    }
  };

  const PrefixIcons = ({ image, isFocused }) => {
    return (
      <GrayScaledImage
        source={{ uri: image }}
        style={{ height: 25, width: 40, resizeMode: 'contain' }}
        enableGrayscale={!isFocused}
      />
    );
  };

  const validateUrl = (url) => {
    const urlRegex =
      /(https?:\/\/(www\.)?)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
    return url.match(urlRegex)?.length > 0;
  };

  const validateYoutubeLink = (url) => {
    const youtubeRegex =
      /(youtu(?:be\.com)\/((channel|c)\/)([a-zA-Z0-9-_.])*.*)/;
    return url.match(youtubeRegex)?.length > 0;
  };

  const addOtherPlatformUrls = () => {
    if (validateUrl(platformUrl)) {
      setPlatformUrl('');
      setProfileUrl(platformUrl);
      return;
    }
    setPlatformUrlError('Please enter a valid URL.');
  };

  const onChangePlatformUrl = (value = '') => {
    setPlatformUrlError('');
    setPlatformUrl(value);
  };

  const otherPlatformSuffix = () => {
    return (
      <TouchableOpacity onPress={addOtherPlatformUrls}>
        <Text style={styles.otherPlatformSuffix}>ADD</Text>
      </TouchableOpacity>
    );
  };

  const removeProfileUrlSuffix = () => {
    return (
      <TouchableOpacity onPress={() => setProfileUrl('')}>
        <Image
          source={images.mediaDetailPage.closeButton}
          style={styles.closeButton}
        />
      </TouchableOpacity>
    );
  };

  const goBack = () => {
    navigation.goBack();
  };

  const updateInfluencer = () => {
    const { pro_accounts = [] } = influencerData;
    const twitterHandleId = getProAccountObjectForId(pro_accounts, 2)?.id || '';
    const youtubeChannelId =
      getProAccountObjectForId(pro_accounts, 6)?.id || '';
    const profileId = getProAccountObjectForId(pro_accounts, 61)?.id || '';
    const instagramId = getProAccountObjectForId(pro_accounts, 1)?.id || '';
    const postData = {
      user: {
        pro_accounts_attributes: [
          {
            id: instagramId || '',
            social_media_platform_id: 1,
            user_input: instagramUsername,
          },
          {
            id: twitterHandleId || '',
            social_media_platform_id: 2,
            user_input: twitterHandle,
          },
          {
            id: youtubeChannelId || '',
            social_media_platform_id: 6,
            user_input: youtubeChannel,
          },
          {
            id: profileId || '',
            social_media_platform_id: 61,
            user_input: profileUrl,
          },
        ],
      },
    };
    dispatch(
      updateInfluencerData(postData, (success, response) => {
        if (success) {
          if (fromDashboard) {
            navigation.navigate('Dashboard');
          }
          navigation.navigate(isIntroVideoPresent ? 'Dashboard' : 'AddIntroVideo');
        }
      }),
    );
  };

  const navigateToAddIntroVideo = () => {
    if (isPresent(youtubeChannel) && !validateYoutubeLink(youtubeChannel)) {
      setYoutubeChannelError('Please enter a valid YouTube Channel Link!');
      return;
    }
    AnalyticsManager.logEvent(EventType.INFLUENCER.SUBMIT_SOCIAL_MEDIA_ACCOUNTS, {
      current_user_id: currentUserId,
    });
    updateInfluencer();
  };

  const onChangeYoutubeChannel = (value = '') => {
    setYoutubeChannel(value);
    setYoutubeChannelError('');
  };

  const handlePress = () => navigation.push('TabNavigator');

  return (
    <View style={styles.container}>
      {isDesktop() && (
        <TouchableOpacity style={styles.logoContainer} onPress={handlePress}>
          <DesktopHeaderLogo />
        </TouchableOpacity>
      )}
      <View style={styles.container} dataSet={{ media: ids.container }}>
        <ScrollView stickyHeaderIndices={[0]} style={styles.scrollView}>
          <View style={styles.header}>
            <TouchableOpacity onPress={goBack}>
              <Image source={images.ic_back_product} style={styles.back} />
            </TouchableOpacity>
            <View dataSet={{ media: ids.headingTextContainer }}>
              <Text
                style={styles.headerTitle}
                dataSet={{ media: ids.headerTitle }}
              >
                Add your social profiles
              </Text>
              <Text
                style={styles.headerSubtitle}
                dataSet={{ media: ids.headerSubtitle }}
              >
                Your places on the internet
              </Text>
            </View>
          </View>
          <View
            dataSet={{ media: ids.formContainer }}
            style={{ paddingTop: 12 }}
          >
            <View style={{ padding: 16 }}>
              <OutlinedTextField
                renderLeftAccessory={() => (
                  <PrefixIcons
                    image={images.instagramIcon}
                    isFocused={
                      focusedTextField === 'instagram' ||
                      isPresent(instagramUsername)
                    }
                  />
                )}
                label='Instagram Username'
                tintColor={colors.subtitle}
                inputContainerStyle={styles.textInputContainerStyle}
                lineWidth={0}
                activeLineWidth={0}
                value={instagramUsername}
                onChangeText={setInstagramUsername}
                labelOffset={{ x1: -54, y1: -20, y0: -8 }}
                contentInset={{ input: 8, left: 0 }}
                onFocus={() => setFocusedTextField('instagram')}
                onBlur={() => setFocusedTextField('')}
              />
            </View>
            <View style={{ padding: 16 }}>
              <OutlinedTextField
                renderLeftAccessory={() => (
                  <PrefixIcons
                    image={images.twitterIcon}
                    isFocused={
                      focusedTextField === 'twitter' || isPresent(twitterHandle)
                    }
                  />
                )}
                label='Twitter Handle'
                tintColor={colors.subtitle}
                inputContainerStyle={styles.textInputContainerStyle}
                lineWidth={0}
                activeLineWidth={0}
                labelOffset={{ x1: -54, y1: -20, y0: -8 }}
                contentInset={{ input: 8, left: 0 }}
                onFocus={() => setFocusedTextField('twitter')}
                onBlur={() => setFocusedTextField('')}
                value={twitterHandle}
                onChangeText={setTwitterHandle}
              />
            </View>
            <View style={{ padding: 16 }}>
              <OutlinedTextField
                renderLeftAccessory={() => (
                  <PrefixIcons
                    image={images.youtubeIcon}
                    isFocused={
                      focusedTextField === 'youtube' ||
                      isPresent(youtubeChannel)
                    }
                  />
                )}
                label='Youtube Channel Link'
                tintColor={colors.subtitle}
                inputContainerStyle={styles.textInputContainerStyle}
                lineWidth={0}
                activeLineWidth={0}
                labelOffset={{ x1: -54, y1: -20, y0: -8 }}
                contentInset={{ input: 8, left: 0 }}
                onFocus={() => setFocusedTextField('youtube')}
                onBlur={() => setFocusedTextField('')}
                error={youtubeChannelError}
                value={youtubeChannel}
                onChangeText={onChangeYoutubeChannel}
              />
            </View>
            {profileUrl && (
              <View style={{ padding: 16 }}>
                <OutlinedTextField
                  renderLeftAccessory={() => (
                    <PrefixIcons image={images.socialMediaHolder} isFocused />
                  )}
                  renderRightAccessory={removeProfileUrlSuffix}
                  label='Profile URL'
                  tintColor={colors.subtitle}
                  inputContainerStyle={styles.textInputContainerStyle}
                  lineWidth={0}
                  activeLineWidth={0}
                  labelOffset={{ x1: -54, y1: -20, y0: -8 }}
                  contentInset={{ input: 8, left: 0 }}
                  value={profileUrl}
                />
              </View>
            )}
            <View style={{ padding: 16 }}>
              <OutlinedTextField
                renderLeftAccessory={() => (
                  <PrefixIcons
                    image={images.otherPlatform}
                    isFocused={
                      focusedTextField === 'otherPlatform' ||
                      isPresent(platformUrl)
                    }
                  />
                )}
                renderRightAccessory={otherPlatformSuffix}
                label='Other Platform URL'
                tintColor={colors.subtitle}
                inputContainerStyle={styles.textInputContainerStyle}
                disabledLineWidth={0}
                lineWidth={0}
                activeLineWidth={0}
                labelOffset={{ x1: -54, y1: -20, y0: -8 }}
                contentInset={{ input: 8, left: 0 }}
                onFocus={() => setFocusedTextField('otherPlatform')}
                onBlur={() => setFocusedTextField('')}
                value={platformUrl}
                onChangeText={onChangePlatformUrl}
                error={platformUrlError}
                disabled={isPresent(profileUrl)}
              />
            </View>
            <View style={styles.verticalSpace} />
          </View>
        </ScrollView>
        <KeyboardAvoidingView
          behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
          style={styles.buttonContainer}
          dataSet={{ media: ids.buttonContainer }}
        >
          <TouchableOpacity
            onPress={navigateToAddIntroVideo}
            style={styles.button}
          >
            <Text style={styles.buttonText}>{fromDashboard ? 'Save' : 'Continue'}</Text>
          </TouchableOpacity>
        </KeyboardAvoidingView>
      </View>
    </View>
  );
}
