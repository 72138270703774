import React, { useEffect, useState } from 'react';
import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  NativeModules,
} from 'react-native';
import { useDispatch } from 'react-redux';
import { trim } from 'lodash';
import FastImageView from '../../components/FastImageView';
import colors from '../../theme/Colors';
import Utility from '../../utils/Utility';
import { fetchProductForReview } from '../../actions/RatingAndReviewsActions';
import { rateProduct } from '../../actions/ActionTypes';
import { AnalyticsManager } from '../../analytics';
import ScaleAnimate from '../../components/shared/ScaleAnimate';
import RemoteConfig from '../../utils/RemoteConfig';
import { REMOTE_CONFIG_KEYS } from '../../config/Constants';
import images from '../../theme/Images';
import useDefaultSelectedVariantForProduct from '../../components/Product/useDefaultSelectedVariantForProduct';
import { useNavigation } from '@react-navigation/native';
import { isBlank } from '../../utils/BooleanUtility';
import { getMinifiedImage } from '../../utils/ImageUtility';
import ProductRating from '../../components/Product/ProductRating';

const ReviewCard = ({ itemData = {}, ...props }) => {
  const [rating, setRating] = useState(0);
  const [productData, setProductData] = useState({});
  const dispatch = useDispatch();
  const navigation = useNavigation();

  // Custom hook to find default and selected variant
  const { selectedVariant, setSelectedVariant } =
    useDefaultSelectedVariantForProduct(props, productData);

  useEffect(() => {
    const { data_api: dataApi, arguments: { lookback_days = 7 } = {} } =
      itemData;
    if (dataApi) {
      dispatch(fetchProductForReview(dataApi, lookback_days, responseCallback));
    }
  }, []);

  const responseCallback = (success, response = {}) => {
    if (!success) return;
    setProductData(response);
    const {
      image_url,
      image_webp_url,
      product_slug = '',
      slug: resp_slug = '',
    } = response;
    const slug = resp_slug ? resp_slug : product_slug;
    const image = image_url ? image_url : image_webp_url;

    if (Utility.isAndroid()) {
      const notificationData = Utility.jsonParser(
        RemoteConfig.getValue(
          REMOTE_CONFIG_KEYS.review_reminder_notification_data,
        ),
      );
      const reviewReminderNotificationData = {
        slug,
        image,
        heading: notificationData?.heading,
        subheading: notificationData?.subheading,
      };
      NativeModules.LocalNotificationsPackage.sendReviewReminderNotification(
        JSON.stringify(reviewReminderNotificationData),
      );
    }
  };

  const getProduct = () => {
    const {
      name = '',
      pack_size = '',
      image_webp_url = '',
      brand: { name: brandName = '' } = {},
      product_name,
      image_url,
    } = productData;
    const productName = name
      ? name?.replace(brandName, '')
      : product_name?.replace(brandName, '');
    const image = getMinifiedImage(
      image_url ? image_url : image_webp_url,
      styles.productImage?.width,
      styles.productImage?.height,
    );

    return (
      <View style={styles.productContainer}>
        <FastImageView style={styles.productImage} source={image} />
        <View style={styles.productDesc}>
          <Text style={styles.brandName} numberOfLines={1}>
            {brandName?.toUpperCase()}
          </Text>
          <Text style={styles.name} numberOfLines={1}>
            {trim(productName)}
          </Text>
          <Text style={styles.variant} numberOfLines={1}>
            {pack_size}
          </Text>
        </View>
      </View>
    );
  };

  const defaultNavigate = () => {
    OnRatingTap(5);
  };

  const OnRatingTap = (stars) => {
    if (isBlank(productData)) return;
    setRating(stars);

    const slug = productData.slug ? productData.slug : productData.product_slug;
    const skuId = Utility.getSkuIdForProduct(productData, selectedVariant);

    const url = slug
      ?.replace('/api/v2', '/api/v1')
      ?.replace('.json', '/ratings.json');
    const data = {
      url: url || '',
      id: skuId || '',
      rating: {
        star: stars,
      },
    };

    dispatch(
      rateProduct(data, (success, response) => {
        AnalyticsManager.logEvent('rate_product', {
          sku_id: skuId,
          product_id: productData?.id,
          status: 'new',
          screen: 'product_detail',
          rating: stars,
        });
        navigation.navigate('PostReview', {
          rating: stars,
          itemData: productData,
          fromReviewCard: true,
          updateSelectedVariant: setSelectedVariant,
        });
      }),
    );
  };

  if (isBlank(productData)) {
    return null;
  }

  return (
    <ScaleAnimate {...props} onPress={defaultNavigate}>
      <View style={styles.cardContainer}>
        <View style={styles.card}>
          <Text style={styles.title}>{itemData.title || 'Review order'}</Text>
          <Text style={styles.description}>
            {itemData.body || 'Get flat 20% off'}
          </Text>
          {getProduct()}
          <View style={styles.divider} />
          <View style={styles.row}>
            <View style={styles.rowContainer}>
              <ProductRating rating={rating} size={20} inBetweenSpace={6} />
            </View>
            <View style={styles.buttonContainer}>
              <TouchableOpacity
                style={styles.reviewButton}
                onPress={defaultNavigate}
              >
                <Text style={styles.buttonText}>Write a review</Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </View>
    </ScaleAnimate>
  );
};

export default ReviewCard;

const styles = StyleSheet.create({
  cardContainer: {
    backgroundColor: colors.background,
    height: 248,
    width: Utility.getScreenWidth(),
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 12,
  },
  card: {
    backgroundColor: colors.white,
    marginVertical: 24,
    height: '90%',
    width: '100%',
    borderRadius: 4,
    paddingHorizontal: 12,
    paddingTop: 10,
  },
  title: {
    fontFamily: 'Roboto-Bold',
    fontSize: 18,
    color: colors.foxyBlack,
    fontStyle: 'normal',
  },
  description: {
    fontFamily: 'Roboto',
    fontSize: 14,
    color: colors.subtitle,
    marginTop: 8,
  },
  productContainer: {
    flexDirection: 'row',
    marginTop: 8,
  },
  productImage: {
    height: 74,
    width: 74,
  },
  productDesc: {
    marginTop: 10,
    paddingLeft: 12,
    width: 250,
    // backgroundColor: 'red',
    justifyContent: 'space-evenly',
    height: 54,
  },
  brandName: {
    fontFamily: 'Roboto-Medium',
    fontSize: 10,
    color: colors.darkGreys,
  },
  name: {
    fontFamily: 'Roboto',
    fontSize: 12,
    color: colors.black,
    width: 250,
  },
  variant: {
    fontFamily: 'Roboto',
    fontSize: 12,
    color: colors.silver,
  },
  divider: {
    height: 2,
    width: Utility.getScreenWidth(),
    backgroundColor: colors.background,
    marginVertical: 12,
    marginHorizontal: -12,
  },
  row: {
    flex: 2,
    flexDirection: 'row',
    marginBottom: 8,
  },
  rowContainer: { flex: 1 },
  buttonContainer: {
    flex: 1,
    justifyContent: 'center',
    marginLeft: 35,
    alignItems: 'flex-end',
    height: 32,
  },
  reviewButton: {
    flex: 1,
    justifyContent: 'center',
    // marginRight: 6,
    alignItems: 'center',
  },
  buttonText: {
    fontFamily: 'Roboto',
    color: colors.foxyBlue,
    fontSize: 15,
    marginBottom: 12,
  },
});
