import React, { Component } from 'react';
import {
  View,
  ScrollView,
  Image,
  SafeAreaView,
  Text,
  ImageBackground,
  FlatList,
  StatusBar,
  Animated,
  TouchableOpacity,
} from 'react-native';
import Config from '../../libraries/ReactNativeConfig';
import Toast from 'react-native-easy-toast';
import { v5 as uuidv5 } from 'uuid';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Share from '../../libraries/ReactNativeShare';
import styles from './styles/PostDetailsStyles';
import Utility from '../../utils/Utility';
import images from '../../theme/Images';
import colors from '../../theme/Colors';
import { CustomNavigationHeader } from '../Product';
import DockedHeader from '../../utils/DockedHeader';
import BottomSheet from '../onboarding/BottomSheet';
import AlertBox from '../camera/shared/AlertBox';
import { fetchProductBySlug, deleteMedia } from '../../actions/ActionTypes';
import { UPLOADING_OPTIONS } from '../../config/LayoutConstants/UploadPostConfig';
import { addPostToLocalState } from '../../actions/UploadPostActions';
import { updateCurrentPostID } from '../../actions/LoginActions';
import DynamicLinkManager from '../../utils/DynamicLinkManager';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import { goBackWithFallbackHome } from '../../utils/NavigationUtility';
import { getMinifiedImage } from '../../utils/ImageUtility';

class PostDetail extends Component {
  constructor() {
    super();
    this.state = {
      scrollY: new Animated.Value(0),
      showBottomSheet: false,
      isLoading: false,
      showWarning: false,
      showLoader: false,
      postTitle: '',
      postViews: '',
      postType: '',
      products: [],
      total_views: '',
      total_likes: '',
      productId: '',
      bannerImage: '',
      duration: '',
      mediaObject: {},
      actualDataObject: {},
      dynamicVideoLink: '',
    };
  }

  componentDidMount() {
    const { navigation, route, fetchProductBySlug } = this.props;

    const objectData = route.params.object;

    let duration = '0';
    if (objectData.type === 'video') {
      duration = Utility.convertSecondIntoMinutes(objectData.metadata.duration);
    }

    this.setState({
      ...this.state,
      postTitle: objectData.metadata.title,
      postType: objectData.type,
      total_views: objectData.metadata.viewCount,
      productId: objectData.id,
      bannerImage: objectData.image_url,
      duration,
      actualDataObject: objectData,
    });

    fetchProductBySlug(objectData.slug, (result) => {
      let products = [];
      result.objects.forEach((element) => {
        if (element.content === 'product') {
          products = element.objects;
        }
      });

      this.setState({
        ...this.state,
        products,
        mediaObject: result,
      });
    });

    try {
      const getDynamicLink = DynamicLinkManager.createDynamicLink(
        objectData.share_url
      );
      getDynamicLink
        .then((link) => {
          this.setState({
            ...this.state,
            dynamicVideoLink: link,
          });
        })
        .catch((error) => {});
    } catch (error) {}
  }

  _topSection = (props) => (
    <View style={styles.topContainer}>
      <ImageBackground
        style={styles.imageStyle}
        source={{
          uri: getMinifiedImage(
            props.bannerImage,
            Utility.getScreenWidth(),
            Utility.getScreenWidth()
          ),
        }}
        resizeMode='cover'
      >
        {props.isVideo === 'video' && (
          <TouchableOpacity
            style={{
              flex: 1,
              justifyContent: 'center',
              alignItems: 'center',
            }}
            onPress={this.navigateToVideo}
          >
            <Image source={images.playMoreButton} />
          </TouchableOpacity>
        )}

        <Text style={styles.alignToBottomRight}>
          {props.duration === '0' ? '' : props.duration}
        </Text>
      </ImageBackground>
    </View>
  );

  _bottomSection = (props) => (
    <View style={styles.bottomContainer}>
      {props.products.length > 0 && <this._productUsed />}
      <this._analyticsRow
        total_views={props.total_views}
        total_likes={props.total_likes}
      />
      <this._postTitleDetails title={props.title} />
    </View>
  );

  _productUsed = () => (
    <View style={styles.column}>
      <Text style={styles.subHeading}>Product Used</Text>
      <FlatList
        data={this.state.products}
        horizontal
        extraData={this.state.products}
        showsHorizontalScrollIndicator={false}
        renderItem={({ item }) => (
          <this._renderProducts imageUrl={item.image_url} />
        )}
      />
    </View>
  );

  _renderProducts = (props) => (
    <View
      style={{
        height: 50,
        width: 50,
        borderTopRightRadius: 50,
        borderTopLeftRadius: 50,
        borderBottomRightRadius: 50,
        borderBottomLeftRadius: 50,

        borderWidth: 1,
        marginLeft: 10,
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
        borderColor: colors.silver,
      }}
    >
      <Image
        resizeMode='contain'
        style={{
          height: 40,
          width: 40,
        }}
        source={{
          uri: props.imageUrl,
        }}
      />
    </View>
  );

  _analyticsRow = (props) => {
    const total_views =
      props.total_views !== '0' ? `${props.total_views} Views` : 'No Views';
    const total_likes = Utility.isBlank(props.total_likes)
      ? false
      : `${props.total_likes} Likes`;
    return (
      <View style={styles.rowWithSpaceAround}>
        <View style={[styles.row, { marginTop: 10 }]}>
          <Text style={styles.tag}>{total_views}</Text>

          {total_likes && <View style={styles.circleBall} />}
          {total_likes && (
            <Text style={[styles.tag, { marginLeft: 5 }]}>
              {props.total_like}
            </Text>
          )}
        </View>
        {/* <View style={{ marginTop: 10 }}>
        <Text style={styles.subHeading}>4 Days Ago</Text>
      </View> */}
      </View>
    );
  };

  _postTitleDetails = (props) => (
    <View style={styles.column}>
      <Text style={styles.heading} numberOfLines={2} ellipsizeMode='tail'>
        {props.title}
      </Text>
      {/* <Text style={styles.post_description} numberOfLines={2} ellipsizeMode="tail">
        My simple everyday makeup routine for daily makeup for women diy. Get the latest products
        for you daily lush look
      </Text>

      <Text style={[styles.post_description, { color: colors.foxyPink }]}>
        #EverydayMakup #DailyMakeupForWomen #Lakme
      </Text> */}
    </View>
  );

  _stickyShareRow = () => (
    <View style={styles.stickyBottomRow}>
      <Text style={styles.post_description}>Share this video on</Text>
      <View
        style={[
          styles.row,
          { paddingLeft: 0, paddingRight: 0, justifyContent: 'space-around' },
        ]}
      >
        <this._shareOption
          imgSrc={images.socialShareIcons.igFull}
          onClick={() => this.socialShare('instagram')}
        />

        <this._shareOption
          imgSrc={images.socialShareIcons.whatsapp}
          onClick={() => this.socialShare('whatsapp')}
        />

        <this._shareOption
          imgSrc={images.socialShareIcons.more}
          onClick={() => this.socialShare(null)}
        />
      </View>
    </View>
  );

  _shareOption = (props) => (
    <TouchableOpacity onPress={props.onClick}>
      <View
        style={[styles.column, { padding: 0, marginTop: 8, marginLeft: 5 }]}
      >
        <Image source={props.imgSrc} style={{ height: 50, width: 50 }} />
      </View>
    </TouchableOpacity>
  );

  hideBottomSheet = () => {
    this.setState({
      ...this.state,
      showBottomSheet: false,
    });
  };

  navigateToUploadPost = () => {
    const { navigation, route, addPostToLocalState } = this.props;
    const { mediaObject, products, actualDataObject } = this.state;
    const { id, type, image_url, metadata } = mediaObject;

    if (mediaObject.metadata.contentPlatform === 'youtube') {
      this.toast.show('You cannot edit Youtube videos');
      this.hideBottomSheet();
    } else {
      const videoUrl = Utility.isBlank(metadata.videoUrl)
        ? ''
        : metadata.videoUrl;

      const postData = {
        id,
        localId: id,
        category: type,
        postCacheDirectoryPath: '',
        uploadType: type === 'video' ? 'FoxyVideo' : 'FoxyGallery',
        items: [
          {
            mimeType: 'video/mp4',
            uuid: uuidv5(videoUrl, uuidv5.URL),
            uri: videoUrl,
            mediaUriAbsolutePath: '',
            mediaUriExternalDirectoryPath: '',
            mediaBase64Uri: '',
            thumbnail: image_url,
            height: metadata.height,
            width: metadata.width,
            videoLength: metadata.duration,
            mediaType: type === 'video' ? 'video' : 'image',
            caption: metadata.title,
            recentProductSearchID: '',
            products,
            videoFrames: [],
            productsTag: [],
          },
        ],
        postTitle: metadata.title,
        postDescription: '',
        postThumbnail: image_url,
        postCategory: type === 'video' ? 'video' : 'image',
        hashTags: [],
        mentions: [],
        editingMode: false,
      };

      addPostToLocalState(postData, (isAllowedToNavigate) => {
        this.hideBottomSheet();
        if (isAllowedToNavigate) {
          navigation.navigate('UploadVideo', {
            navigatingFrom: 'post_edit',
            actualDataObject,
            updatePost: route.params?.updatePost,
            selectedIndex: route.params?.selectedIndex,
            videoType: route.params?.videoType,
          });
        }
      });
    }
  };

  _bottomSheet = () => (
    <BottomSheet
      containerStyle={styles.bottomSheetContainer}
      onPressOutside={this.hideBottomSheet}
    >
      <View style={styles.bottomSheetColumn}>
        <TouchableOpacity onPress={this.navigateToUploadPost}>
          <View style={styles.bottomSheetRow}>
            <Image source={styles.bottomSheetImageIcon} source={images.edit} />
            <Text style={styles.bottomSheetText}>Edit</Text>
          </View>
        </TouchableOpacity>
        <TouchableOpacity onPress={this.showDeleteWarning}>
          <View style={styles.bottomSheetRow}>
            <Image
              source={styles.bottomSheetImageIcon}
              source={images.delete}
            />
            <Text style={styles.bottomSheetText}>Delete</Text>
          </View>
        </TouchableOpacity>
      </View>
    </BottomSheet>
  );

  onClickMore = () => {
    this.setState({
      ...this.state,
      showBottomSheet: true,
    });
  };

  showDeleteWarning = () => {
    this.setState({
      ...this.state,
      showBottomSheet: false,
      showWarning: true,
    });
  };

  confirmDeletePost = () => {
    const { deleteMedia, navigation, route } = this.props;
    const { productId } = this.state;
    this.setState({
      ...this.state,
      showWarning: false,
    });
    this.toast.show('Deleting Post');

    deleteMedia(productId, (response) => {
      if (response !== 'Success') {
        this.toast.show(response);
      } else {
        this.toast.show('Post Deleted Successfully');
        navigation.goBack();

        const selectedIndex = route.params?.selectedIndex;
        const videoType = route.params?.videoType;
        route.params.deletePost(selectedIndex, videoType);
      }
    });

    // TODO: if we want to remove the card from list in my profile. on delete, uncomment this code.
  };

  navigateToVideo = () => {
    const { navigation } = this.props;
    const { mediaObject } = this.state;
    const itemData = mediaObject;
    navigation.push('ContentModal', {
      itemData,
      listId: null,
      index: 0,
    });
  };

  discardWarningPopup = () => {
    this.setState({
      ...this.state,
      showWarning: false,
    });
  };

  socialShare = (shareOn) => {
    const { dynamicVideoLink } = this.state;

    const shareOptions = {
      title: `Share ${Config.APP_NAME} App Using`,
      message: '#Fashion Week Delhi',
      url: dynamicVideoLink,
      social: `${shareOn}`,
    };
    if (!shareOn) {
      Share.open(shareOptions);
    } else {
      Share.shareSingle(shareOptions);
    }
  };

  _navigateBack = () => {
    const { navigation } = this.props;
    goBackWithFallbackHome(navigation);
  };

  render() {
    const {
      scrollY,
      productName,
      showBottomSheet,
      showWarning,
      postTitle,
      postType,
      products,
      total_likes,
      total_views,
      bannerImage,
      duration,
      mediaObject,
    } = this.state;

    return (
      <>
        <View style={styles.wrapper}>
          <View
            style={{
              position: 'absolute',
              top: 0,
              width: '100%',
              height: Utility.topInset + 10,
              backgroundColor: '#000000',
            }}
          />
          <SafeAreaView style={styles.wrapper}>
            <ScrollView
              style={styles.container}
              scrollEventThrottle={16}
              onScroll={Animated.event([
                { nativeEvent: { contentOffset: { y: scrollY } } },
              ])}
            >
              <this._topSection
                isVideo={postType}
                bannerImage={bannerImage}
                duration={duration}
              />
              <this._bottomSection
                title={postTitle}
                products={products}
                total_likes={total_likes}
                total_views={total_views}
              />
            </ScrollView>
            <CustomNavigationHeader
              ref={(ref) => {
                this.navigationRef = ref;
              }}
              scrollY={scrollY}
              slug='Slugs'
              goBack={this._navigateBack}
              id='1'
              isProduct
              name='docker'
              content='artist'
              showShareButton={false}
              showMenuButton
              onClickMenu={this.onClickMore}
              screenName={SCREEN_CONSTANTS.POST_DETAIL}
            >
              <DockedHeader name={productName} />
            </CustomNavigationHeader>
            <Toast
              ref={(ref) => {
                this.toast = ref;
              }}
            />

            {/* {!showBottomSheet && <this._stickyShareRow />} */}
            {showBottomSheet && <this._bottomSheet />}

            <AlertBox
              isVisible={showWarning}
              alertBoxTitle='Delete Post'
              alertMessage='Do you want to delete this post?'
              firstButtonTitle='Delete'
              firstButtonTextColor='#ff0000'
              secondButtonTitle='Cancel'
              firstButtonOnPress={this.confirmDeletePost}
              secondButtonOnPress={this.discardWarningPopup}
            />
          </SafeAreaView>
        </View>
      </>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      fetchProductBySlug,
      deleteMedia,
      addPostToLocalState,
    },
    dispatch
  ),
});

export default connect(null, mapDispatchToProps)(PostDetail);
