import _, { filter, uniqBy } from 'lodash';
import Utility from '../../utils/Utility';
import FilterAnalyticsEvents from './FiltersAnalyticsEvents';
import { isPresent, isBlank } from '../../utils/BooleanUtility';
import { getIndexOfEmptyState } from '../../utils/ArrayUtility';
import { allowedContentTypes } from '../../utils/ListUtility';

export default class FilterUtils {
  static removeSelectedQuickFilterInFilterModal = (item, selectedFilters) => {
    const filters = selectedFilters;
    const filterSectionCriteriaSet = filters[item.filter_section_criteria];
    const filterSectionSelectedValue = filter(
      filterSectionCriteriaSet,
      (element) => element.value != item.value,
    );
    const currentFilterValueSet = filters[item.criteria_name];
    const currentFilterSelectedValue = filter(
      currentFilterValueSet,
      (element) => element.value != item.value,
    );
    if (item.criteria_name === 'quick_filter_foxy_match') {
      delete filters['user_attribute_allowed_value_id'];
      delete filters['uaavs'];
    } else if (
      (isPresent(filterSectionSelectedValue) &&
        isBlank(currentFilterSelectedValue)) ||
      (isPresent(currentFilterSelectedValue) &&
        isBlank(filterSectionSelectedValue))
    ) {
      filters[item.filter_section_criteria] = filterSectionSelectedValue;
      filters[item.criteria_name] = currentFilterSelectedValue;
    } else if (
      isPresent(filterSectionSelectedValue) &&
      isPresent(currentFilterSelectedValue)
    ) {
      filters[item.filter_section_criteria] = filterSectionSelectedValue;
      filters[item.criteria_name] = currentFilterSelectedValue;
    } else {
      delete filters[item.filter_section_criteria];
      delete filters[item.criteria_name];
    }
    return filters;
  };

  static filterQuickFilterArray = (
    currentQuickFilterArray,
    originalQuickFilterArray,
  ) => {
    return currentQuickFilterArray.filter(
      (currentQuickFilter) =>
        originalQuickFilterArray.some(
          (originalQuickFilter) =>
            currentQuickFilter.value === originalQuickFilter.id,
        ) ||
        currentQuickFilter.criteria_name !== 'quick_filter_sort_by' ||
        currentQuickFilter.criteria_name !== 'quick_filter_foxy_match',
    );
  };

  static generateUrlFromQuickFilters = (
    item,
    selected,
    index,
    listObject,
    currentSlug,
    selectedFilters,
    selectedQuickFilters,
    prevAppliedFiltersFromModal,
    screenName,
    entityName = '',
    hideOosProduct,
  ) => {
    let slug = currentSlug;
    let updatedSelectedQuickFilters = selectedQuickFilters;
    if (
      item.criteria_name === 'sort_by' &&
      isPresent(selectedFilters.sort_by)
    ) {
      slug = this.removeCriteriaFromSlug(
        slug,
        selectedFilters['sort_by'][0].criteria_name,
        selectedFilters['sort_by'][0].value,
      );
      delete selectedFilters['sort_by'];
      if (isPresent(selectedFilters?.recommended_filters)) {
        // delete sort_by criteria filters from recommended filters bucket
        selectedFilters.recommended_filters =
          selectedFilters.recommended_filters.filter(
            (item) => item.criteria_name !== 'sort_by',
          );
        if (isBlank(selectedFilters.recommended_filters))
          delete selectedFilters['recommended_filters'];
      }
      updatedSelectedQuickFilters = [...updatedSelectedQuickFilters.filter((obj) => obj.criteria_name !== 'sort_by')];
    }
    let copySelectedFilters = {};
    let arrSelectedQuickFilters = updatedSelectedQuickFilters;
    if (selected) {
      const criteria =
        item.criteria_name === 'sort_by'
          ? item.criteria_name
          : `${item.criteria_name}[]`;
      slug = `${slug}&${criteria}=${item.value}`;
      arrSelectedQuickFilters.push(item);
      prevAppliedFiltersFromModal.push(item);
      copySelectedFilters = this.groupSelectedFilter(
        updatedSelectedQuickFilters,
        true,
        selectedFilters,
      );
      FilterAnalyticsEvents.logQuickFilterApplyEvent(
        item,
        listObject,
        index,
        screenName,
        entityName,
        hideOosProduct,
      );
    } else {
      slug = this.removeCriteriaFromSlug(slug, item.criteria_name, item.value);
      arrSelectedQuickFilters = arrSelectedQuickFilters.filter(
        (element) => element.name !== item.name,
      );

      copySelectedFilters = this.removeSelectedQuickFilterInFilterModal(
        item,
        selectedFilters,
      );
      FilterAnalyticsEvents.logQuickFilterResetEvent(
        item,
        listObject,
        index,
        screenName,
        entityName,
      );
    }
    return {
      slug,
      groupedSelectedFilters: copySelectedFilters,
      pageNo: 0,
      allowNextPageLoad: true,
      filtersEnabled: isPresent(copySelectedFilters),
      selectedQuickFilters: arrSelectedQuickFilters,
      prevAppliedFiltersFromModal,
    };
  };

  static removeCriteriaFromSlug = (slug, name, value) => {
    let criteriaName = name;
    let updatedSlug = slug;
    if (criteriaName === 'quick_filter_foxy_match') {
      criteriaName = updatedSlug.includes('user_attribute_allowed_value_id')
        ? 'user_attribute_allowed_value_id'
        : 'uaavs';
    }
    if (Array.isArray(value)) {
      value.forEach((attribute) => {
        updatedSlug = updatedSlug.replace(
          `&${criteriaName}[]=${attribute.value}`,
          '',
        );
      });
      return updatedSlug;
    }
    updatedSlug = updatedSlug.replace(`&${criteriaName}[]=${value}`, '');
    updatedSlug = updatedSlug.replace(`&${criteriaName}=${value}`, '');
    return updatedSlug;
  };

  static generateUrlFromFiltersArray = (
    item = [],
    useFilterSectionCriteria,
  ) => {
    let url = '';
    item.forEach((element) => {
      const criteria =
        useFilterSectionCriteria &&
        element.filter_section_criteria === 'recommended_filters'
          ? element.filter_section_criteria
          : element.criteria_name;
      if (Array.isArray(element.value)) {
        element.value.forEach((itemValue) => {
          if (itemValue instanceof Object) {
            url = `${url}&${criteria}[]=${itemValue.value}`;
          } else {
            url = `${url}&${criteria}[]=${itemValue}`;
          }
        });
      } else {
        url = `${url}&${criteria}[]=${element.value}`;
      }
    });

    return url;
  };

  static groupSelectedFilter = (
    item,
    isQuickFilters = false,
    selectedFilters,
  ) => {
    // TODO: Consider renaming selectedFilters to filterValuesToBeGrouped for clarity.
    let arrayToBeGroup = item;
    if (isQuickFilters && isPresent(selectedFilters)) {
      const selectedFilterValues =
        Utility.convertSelectedFiltersObjectIntoArray(selectedFilters);
      arrayToBeGroup = uniqBy([...selectedFilterValues, ...item], (e) => e.value);
    }
    const arrayToBeGroupForQuickFilters = arrayToBeGroup;
    const filteredCriteriaMismatch = arrayToBeGroupForQuickFilters.filter(
      (element) => element.criteria_name !== element.filter_section_criteria,
    );
    let groupByFilterSectionCriteria = {};
    let groupedByCriteriaName = {};
    if (isPresent(filteredCriteriaMismatch)) {
      groupByFilterSectionCriteria = _.groupBy(
        filteredCriteriaMismatch,
        (element) => element.filter_section_criteria,
      );
    }
    groupedByCriteriaName = _.groupBy(
      arrayToBeGroup,
      (element) => element.criteria_name,
    );
    const groupBySelectedFilters = {
      ...groupByFilterSectionCriteria,
      ...groupedByCriteriaName,
    };
    return groupBySelectedFilters;
  };

  static toggleQuickFilters = (
    item,
    sortFilters,
    listObject,
    refQuickFilter,
    prevAppliedFiltersFromModal,
  ) => {
    const { quick_filters = [] } = listObject;
    let highlightedQuickFilers = [];
    highlightedQuickFilers = item.filter((element) =>
      quick_filters.some((filters) => element.value === filters.value),
    );
    if (refQuickFilter) {
      refQuickFilter.applyFilters(
        prevAppliedFiltersFromModal,
        highlightedQuickFilers,
        sortFilters,
      );
    }
    return highlightedQuickFilers;
  };

  static onFilterApplied = (
    filter,
    added,
    index = -1,
    listObject,
    selectedFilters,
    refQuickFilter,
    currentSlug,
    prevAppliedFiltersFromModal,
    selectedQuickFilters,
    screenName,
    entityName,
    hideOosProduct,
  ) => {
    let prevAppliedFilters = prevAppliedFiltersFromModal;

    let newSlug = '';

    if (Array.isArray(filter)) {
      const url = this.generateUrlFromFiltersArray(filter);
      newSlug = `${decodeURIComponent(listObject.slug)}?Nan=Nan${url}`;
      let isFilterEnabled = false;
      const groupBySelectedFilters = this.groupSelectedFilter(
        filter,
        false,
        selectedFilters,
      );
      FilterAnalyticsEvents.logFilterApplyEvent(
        groupBySelectedFilters,
        listObject,
        screenName,
        entityName,
        hideOosProduct,
      );
      if (isBlank(filter)) {
        isFilterEnabled = false;
      } else {
        isFilterEnabled = true;
      }
      const sortByFilters = groupBySelectedFilters.sort_by;
      prevAppliedFilters = this.toggleQuickFilters(
        filter,
        sortByFilters,
        listObject,
        refQuickFilter,
        prevAppliedFilters,
      );
      let selectedQuickFilterArray = selectedQuickFilters;
      if (isBlank(groupBySelectedFilters)) {
        selectedQuickFilterArray = [];
      }
      return {
        slug: newSlug,
        pageNo: 0,
        allowNextPageLoad: true,
        filtersEnabled: isFilterEnabled,
        groupedSelectedFilters: groupBySelectedFilters,
        selectedQuickFilters: prevAppliedFilters,
        prevAppliedFiltersFromModal: prevAppliedFilters,
      };
    }

    if (
      !!currentSlug &&
      currentSlug?.indexOf('?Nan') === -1 &&
      !currentSlug.startsWith('&') // FIXME: HACKY FIX for search page filter: In Search.js, at L-1286, slug is being trimmed based on NaN=Nan, therefore, anything before that is ignored.
    ) {
      currentSlug = `${currentSlug}?Nan=Nan`;
    }

    return this.generateUrlFromQuickFilters(
      filter,
      added,
      index,
      listObject,
      currentSlug,
      selectedFilters,
      selectedQuickFilters,
      prevAppliedFiltersFromModal,
      screenName,
      entityName,
      hideOosProduct,
    );
  };

  static getList = (listData) => {
    if (isPresent(listData) && isBlank(listData.objects)) {
      return { list: listData };
    }
    const indexOfFiltersList = _.findIndex(
      listData.objects,
      (e) => allowedContentTypes(e.content) && isPresent(e.quick_filters),
    );
    if (indexOfFiltersList === -1) {
      const emptyStateIndex = Utility.getIndexOfEmptyState(listData.objects);
      if (emptyStateIndex !== -1) {
        return { list: listData.objects[emptyStateIndex] };
      }
      return { list: listData };
    }
    return { list: listData.objects[indexOfFiltersList] };
  };

  static getFiltersFromList = (listData) => {
    if (isPresent(listData) && isBlank(listData.objects)) {
      return { list: listData, filters: [], quickFilters: [] };
    }
    const { objects } = listData;
    const indexOfFiltersList = _.findIndex(
      objects,
      (e) => e.type === 'quick_filters',
    );
    if (indexOfFiltersList === -1) {
      return { list: listData, filters: [], quickFilters: [] };
    }

    const filters = {
      list: listData[indexOfFiltersList],
      filters: objects[indexOfFiltersList].filters,
      quickFilters: objects[indexOfFiltersList].quick_filters,
    };
    return filters;
  };

  static appendFilteredListToMasterListObjects = (
    slug,
    apiResponse,
    masterList,
  ) => {
    const masterListClone = { ...masterList };
    let listObjects =
      Utility.removeShimmerAndEmptyStateFromListObject(masterListClone);
    if (apiResponse?.objects?.length === 0) {
      listObjects = Utility.addFiltersEmptyStateInListObject(listObjects);
    }
    masterListClone.objects = listObjects;

    if (apiResponse?.objects?.length > 0) {
      const indexOfProductList = listObjects.length;
      masterListClone.objects[indexOfProductList] = apiResponse;
    }
    return { ...masterListClone };
  };

  static isFoxyMatchFilterSelected = (selectedFilters) => {
    return isPresent(
      selectedFilters['user_attribute_allowed_value_id'] ||
        selectedFilters['uaavs'],
    );
  };
}

export const canOpenSortingModal = (item, selectedFilters) => {
  const isFoxyMatchFilterSelected =
    FilterUtils.isFoxyMatchFilterSelected(selectedFilters);
  return (
    item.criteria_name === 'quick_filter_sort_by' ||
    (item.criteria_name === 'quick_filter_foxy_match' &&
      !isFoxyMatchFilterSelected)
  );
};
