import React from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { NavigationContainer } from '@react-navigation/native';
import {
  LoginInfo,
  OTPVerification,
  SelfiePrompt,
  TakeSelfie,
  ImageReview,
  ProfileDetails,
  SelectYourInterest,
} from '../components/onboarding';
import NavigationService from './NavigationService';

const StackNavigator = createNativeStackNavigator();

export const LoginInfoOnboardingNavigator = (extraData) => (
  <StackNavigator.Navigator screenOptions={{ headerShown: false }}>
    <StackNavigator.Screen
      name='LoginInfo'
      options={{
        presentation: 'transparentModal',
      }}
    >
      {(props) => <LoginInfo {...props} {...extraData} />}
    </StackNavigator.Screen>
    <StackNavigator.Screen
      name='OTPVerification'
      options={{
        presentation: 'transparentModal',
      }}
    >
      {(props) => <OTPVerification {...props} {...extraData} />}
    </StackNavigator.Screen>
  </StackNavigator.Navigator>
);

export const ProfileOnboardingNavigator = () => (
  <StackNavigator.Navigator>
    <StackNavigator.Screen name='SelfiePrompt' component={SelfiePrompt} />
    <StackNavigator.Screen name='TakeSelfie' component={TakeSelfie} />
    <StackNavigator.Screen name='ImageReview' component={ImageReview} />
    <StackNavigator.Screen name='ProfileDetails' component={ProfileDetails} />
    <StackNavigator.Screen
      name='SelectYourInterest'
      component={SelectYourInterest}
    />
  </StackNavigator.Navigator>
);

export const InterestsOnboardingNavigator = () => (
  <StackNavigator.Navigator>
    <StackNavigator.Screen
      name='SelectYourInterest'
      component={SelectYourInterest}
    />
  </StackNavigator.Navigator>
);

// export const LoginInfoOnboardingNavigator = createAppContainer(
//   createSwitchNavigator({
//     LoginInfo: { screen: LoginInfo },
//     OTPVerification: { screen: OTPVerification },

//     // SelfiePrompt: { screen: SelfiePrompt },
//     // TakeSelfie: { screen: TakeSelfie },
//     // ImageReview: { screen: ImageReview },
//     // ProfileDetails: { screen: ProfileDetails },
//     // SelectYourInterest: { screen: SelectYourInterest },
//   }),
// );

// export const ProfileOnboardingNavigator = createAppContainer(
//   createSwitchNavigator({
//     SelfiePrompt: { screen: SelfiePrompt },
//     TakeSelfie: { screen: TakeSelfie },
//     ImageReview: { screen: ImageReview },
//     ProfileDetails: { screen: ProfileDetails },
//     SelectYourInterest: { screen: SelectYourInterest },
//   }),
// );

// export const InterestsOnboardingNavigator = createAppContainer(
//   createSwitchNavigator({
//     SelectYourInterest: { screen: SelectYourInterest },
//   }),
// );
