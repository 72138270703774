import { NativeModules } from 'react-native';
import { isAndroid, isPresent, isWeb } from '../../utils/BooleanUtility';
import RoutinesUtility from '../../components/Routines/RoutinesUtility';
import { getStoreRef } from '../../store/StoreUtility';
import { getRoutineDetails, saveScheduledNotificationIds } from '../../actions/RoutineActions';
import { IST_OFFSET, NOTIFICATION_IDENTIFIERS, APP_INTENT_SCHEME } from '../../config/Constants';

var JSONAPIDeserializer = require('jsonapi-serializer').Deserializer;

export const scheduleNotifications = (routineDaysData) => {
  if (isWeb()) return;
  let scheduledNotifications = [];
  let scheduledNotificationActivityId = [];
  let notificationTime = 0;

  routineDaysData.forEach((day) => {
    day?.routineActivities?.forEach((activity = {}) => {
      const {
        entityNotification = {},
        entityNotification: { notificationConfig: payload = {} } = {},
      } = activity;

      if (isPresent(entityNotification) && isPresent(payload)) {
        const { entityNotification: { indicatorPercentage = 0 } = {} } =
          activity;
        const activityId = activity?.id;
        payload.indicatorPercentage = isAndroid()
          ? indicatorPercentage
          : indicatorPercentage / 100;
        payload.destinationUrl = `${APP_INTENT_SCHEME}/${payload?.destinationUrl}`;
        scheduledNotificationActivityId.push(activityId);
        const iosNotificationTriggerTime = calculateSecondsToStart(
          activity?.startsAt,
        );
        const androidNotificationTriggerTime = new Date(
          RoutinesUtility.getLocalTimeFromUTC(activity?.startsAt),
        ).getTime(); // because we need time in utc timezone for scheduling notification in android
        notificationTime = isAndroid()
          ? androidNotificationTriggerTime
          : iosNotificationTriggerTime;
        scheduledNotifications.push({
          notificationTime,
          payload,
          activityId,
        });
      }
    });
  });
  getStoreRef().dispatch(
    saveScheduledNotificationIds(scheduledNotificationActivityId),
  );
  if (isPresent(scheduledNotifications)) {
    if (isAndroid()) {
      NativeModules.ScheduledLocalNotification.scheduleLocalNotification(
        JSON.stringify(scheduledNotifications),
      );
      return;
    }
    const notificationData = {
      routineNotification: true,
      scheduledNotifications,
    };
    NativeModules.LocalNotificationHandler.createNotification(
      NOTIFICATION_IDENTIFIERS.ROUTINE_ACTIVITY_NOTIFICATION,
      notificationData,
    );
   
  }
};

export const fetchRoutineDaysDetail = (slugs = []) => {
  const storeRef = getStoreRef(); // Assuming getStoreRef() retrieves the store reference

  slugs.forEach((slug) => {
    storeRef.dispatch(getRoutineDetails(slug, fetchRoutineDaysDetailCallback));
  });
};

const fetchRoutineDaysDetailCallback = (success, response) => {
  if (success) {
    try {
      new JSONAPIDeserializer({
        typeAsAttribute: false,
        pluralizeType: true,
        keyForAttribute: 'camelCase',
      })
        .deserialize(response)
        .then((data) => {
          const { routineDays = [] } = data;
          scheduleNotifications(routineDays);
        });
    } catch (e) {}
  }
};
const calculateSecondsToStart = (startDateTimeString = '') => {
  const startDateTime = new Date(startDateTimeString); // activity time is in IST timezone
  const currentDateTime = new Date(); // we get time in UTC timezone by using this
  const currentISTDateTime = new Date(currentDateTime.getTime() + IST_OFFSET);
  const secondsDifference = Math.floor(
    Math.max(0, startDateTime - currentISTDateTime) / 1000,
  );
  return secondsDifference;
};



