import { StyleSheet } from 'react-native';
import Utility from '../../../utils/Utility';

const styles = StyleSheet.create({
  verticalRailView: {
    marginLeft: Utility.padding - Utility.spacingBetweenItems,
    marginVertical: 8,
  },
  flatlistStyle: {
    overflow: 'visible',
  },
  container: {
    marginVertical: 8,
  },
});

export default styles;
