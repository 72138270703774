// Dependencies
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { Text, TouchableOpacity } from 'react-native';
import { connect } from 'react-redux';
import { denormalize } from 'normalizr';
import { listSchema } from '../../config/Schema';
import SearchOfferCard from '../../components/offer/SearchOfferCard';
// Components
import * as ActionTypes from '../../actions/ActionTypes';
import { withMaybe } from '../../lib/Monads';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import { navigateToScreen } from '../../utils/NavigationUtility';
import withProfiledNavigation from '../../utils/withProfiledNavigation';
import { getNavigationSource } from '../../utils/PerfUtility';

class SearchOffer extends Component {
  constructor(props) {
    super(props);
    this.navigationSource = getNavigationSource(this, this.props);
  }

  static getComponentHeight(columns = 2) {
    return Utility.getDynamicWidthForGrid(columns, 16);
  }

  onOfferPress = () => {
    const { itemData, navigation } = this.props;

    let promptData = {
      type: 'list',
      id: itemData.id,
      offer: { slug: itemData.slug },
    };
    navigateToScreen({
      navigation,
      type: 'push',
      screen: 'OfferDetail',
      navigationSource: this.navigationSource,
      params: { promptData },
    });
  };

  render() {
    const { itemData } = this.props;
    if (Utility.isBlank(itemData)) {
      return null;
    }
    return <SearchOfferCard {...this.props} />;
  }
}

export default withProfiledNavigation(SearchOffer);
