import React, { Component } from 'react';
import { View, StyleSheet, Text } from 'react-native';
import FastImageView from '../FastImageView';
import colors from '../../theme/Colors';
import { isPresent, isBlank } from '../../utils/BooleanUtility';
import {
  getDynamicWidthForGrid,
  getScreenWidth,
} from '../../utils/LayoutUtility';

const CARD_WIDTH = {
  rail: {
    large: getScreenWidth() / 2.3,
  },
  dynamicCard: {
    dynamic: getDynamicWidthForGrid(2, 16),
  },
  grid: {
    large: getScreenWidth() / 2.15,
    dynamic: getDynamicWidthForGrid(2, 16),
  },
};

function isWhiteFamily(hexCode) {
  // Remove the hash at the start if it's there
  if (hexCode.charAt(0) === '#') {
    hexCode = hexCode.slice(1);
  }

  // Convert the hex code to RGB
  const r = parseInt(hexCode.substring(0, 2), 16) / 255;
  const g = parseInt(hexCode.substring(2, 4), 16) / 255;
  const b = parseInt(hexCode.substring(4, 6), 16) / 255;

  // Calculate the luminance
  const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;

  // Define a threshold for luminance
  const luminanceThreshold = 0.9;

  // Calculate the saturation
  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  const saturation = max === 0 ? 0 : (max - min) / max;

  // Define a threshold for saturation
  const saturationThreshold = 0.1;

  // Check if luminance is above the threshold and saturation is below the threshold
  return luminance >= luminanceThreshold && saturation <= saturationThreshold;
}

const ColorSwatches = (props) => {
  const { type = '', colorSwatches, layout = 'grid', size = 'large' } = props;

  const filteredColorSwatches = colorSwatches.filter(
    (colorSwatch) =>
      isPresent(colorSwatch?.color_code) || isPresent(colorSwatch?.image_url),
  );

  const showSelectedBorder =
    type === 'variant' || type === 'sku' || filteredColorSwatches.length === 1;

  const cardWidth =
    CARD_WIDTH?.[layout]?.[size] || getDynamicWidthForGrid(2, 16);

  const initialNumberOfSwatches = Math.ceil((cardWidth - 12) / 30);

  const ColorSwatch = ({ item, index }) => {
    if (
      index === initialNumberOfSwatches - 1 &&
      colorSwatches.length - 1 > index
    )
      return (
        <View style={styles.moreSwatchesContainer}>
          <Text style={styles.moreSwatchesText}>
            +{colorSwatches.length - index}
          </Text>
        </View>
      );
    if (isPresent(item.image_url)) {
      return (
        <FastImageView source={item.image_url} style={styles.colorSwatch} />
      );
    }
    return (
      <View
        style={[
          styles.colorCodeContainer,
          { backgroundColor: item.color_code },
        ]}
      />
    );
  };

  const ColorSwatchConditionalBorder = (item, index) => {
    const { name = '', color_code = '' } = item;
    const isWhiteColor =
      name.toLowerCase().includes('white') ||
      (isPresent(color_code) && isWhiteFamily(color_code));
    let containerStyle = showSelectedBorder
      ? styles.border
      : styles.withoutBorder;
    if (isWhiteColor && filteredColorSwatches.length > 1)
      containerStyle = styles.whiteColorSwatch;
    if (
      (isBlank(item.image_url) && isBlank(item.color_code)) ||
      index > initialNumberOfSwatches - 1
    )
      return null;
    return (
      <View style={styles.swatchContainer}>
        <View style={containerStyle}>
          <ColorSwatch item={item} index={index} />
        </View>
        {showSelectedBorder && (
          <Text numberOfLines={1} ellipsizeMode='tail' style={styles.colorText}>
            {name}
          </Text>
        )}
      </View>
    );
  };

  return (
    <View style={styles.container}>
      {filteredColorSwatches.map(ColorSwatchConditionalBorder)}
    </View>
  );
};

export default ColorSwatches;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    marginHorizontal: 6,
    marginBottom: 12,
    minHeight: 24,
  },
  colorSwatch: {
    height: 20,
    width: 20,
    borderRadius: 10,
    resizeMode: 'cover',
  },
  moreSwatchesContainer: {
    height: 20,
    width: 20,
    borderRadius: 10,
    borderColor: colors.foxyBlack,
    borderWidth: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  moreSwatchesText: {
    fontSize: 8,
    fontFamily: 'Roboto-Regular',
  },
  colorCodeContainer: {
    height: 20,
    width: 20,
    borderRadius: 10,
  },
  border: {
    height: 25,
    width: 25,
    borderRadius: 12.5,
    borderWidth: 1,
    marginRight: 4,
    justifyContent: 'center',
    alignItems: 'center',
    borderColor: colors.foxyBlack,
  },
  withoutBorder: {
    height: 24,
    width: 24,
    borderRadius: 12,
    marginRight: 4,
    justifyContent: 'center',
    alignItems: 'center',
    borderColor: colors.foxyBlack,
  },
  swatchContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  whiteColorSwatch: {
    height: 21,
    width: 21,
    borderRadius: 10.5,
    borderWidth: 1,
    marginRight: 4,
    justifyContent: 'center',
    alignItems: 'center',
    borderColor: colors.foxyBlack,
  },
  colorText: {
    fontSize: 12,
    fontFamily: 'Roboto-Regular',
    color: colors.foxyBlack,
  },
});
