import React, { Component } from 'react';
import {
  Text,
  View,
  TouchableOpacity,
  Image,
  Platform,
  KeyboardAvoidingView,
  Dimensions,
  ScrollView,
  StatusBar,
  Keyboard,
  ActivityIndicator,
} from 'react-native';
import { TextField } from 'react-native-material-textfield';
import _ from 'lodash';
import Permissions from '../../libraries/ReactNativePermissions';
import { SafeAreaView } from 'react-native-safe-area-context';
// import ContactsWrapper from 'react-native-contacts-wrapper';
import styles from './Styles/ProfileDetailsStyles';
import FoxyShadowButton from '../../lib/FoxyShadowButton';
import size from '../../theme/Fonts';
import colors from '../../theme/Colors';
import images from '../../theme/Images';
import Utility from '../../utils/Utility';

import {
  SCREEN,
  ONBOARDING_STATUS,
  ANDROID,
  INSTAGRAM_KEY,
  INSTAGRAM_REDIRECT_URL,
  PERMISSION_STORE,
} from '../../config/Constants';
import { PermissionsAndroid } from '../../libraries/NativeLibraries';

const { width, height } = Dimensions.get('window');
const statusBarHeight = Platform.select({
  ios: 20,
  android: StatusBar.currentHeight,
  web: 20,
});

export default class ProfileDetails extends Component {
  constructor(props) {
    super(props);
    this.styles = {
      height: {
        screen: height,
        modal: 406.0,
        titleContainer: 56.0,
        continueButtonContainer: 61.0,
        scrollView: 289.0,
        statusBar: statusBarHeight,
        keyboardHeight: 0,
      },
    };
    this.state = {
      cameraPermission: null,
      locationPermission: null,
      contactsPermission: null,
      handle: null,
      name: null,
      locality: '',
      referral: '',
      referralContactName: '',
      referralContactPhone: '',
      handleError: null,
      nameFieldError: null,
      localityFieldError: null,
      safeAreaBottomInset: 0,
      elevation: 0,
      shadowColor: 'white',
      hintedLocation: '',
      showHintLocation: false,
      KeyboardHeight: 0,
      modalContainerHeight: this.styles.height.modal,
      scrollViewContentHeight: this.styles.height.scrollView,
      hintedLocationShift: 95,
      fetchingInstaUserDetails: false,
    };
    this.ref = [];
    this.ref.handle = React.createRef();
    this.ref.name = React.createRef();
    this.ref.locality = React.createRef();
    this.ref.referral = React.createRef();
    this.extractOtpRegex = /\d{4}/;
  }

  componentDidMount() {
    const { navigation, route } = this.props;

    this.setState({
      handle: route.params?.insta_handler,
      name: route.params?.insta_username,
    });

    this.keyboardDidShowListener = Keyboard.addListener(
      'keyboardDidShow',
      this.keyboardDidShow,
    );
    this.keyboardDidHideListener = Keyboard.addListener(
      'keyboardDidHide',
      this.keyboardDidHide,
    );
    try {
      Permissions.check('camera').then((response) => {
        this.setState({ cameraPermission: response });
      });
      if (Utility.isAndroid()) {
        PermissionsAndroid.check(
          PermissionsAndroid.PERMISSIONS.ACCESS_FINE_LOCATION,
        ).then((response) => {
          this.setState({ locationPermission: response });
        });
        PermissionsAndroid.check(
          PermissionsAndroid.PERMISSIONS.READ_CONTACTS,
        ).then((response) => {
          this.setState({ contactsPermission: response });
        });
        // if (Platform.Version > 27) {

        //   this.registerSmsListener();
        //   screenProps.onRequestOtp();
        // }
      }
      if (Utility.isIOS()) {
        Permissions.check('location').then((response) => {
          this.setState({ locationPermission: response });
        });
        Permissions.check('contacts').then((response) => {
          this.setState({ contactsPermission: response });
        });
      }
      route.params.onClearHandleStates();

      if (route.params?.isFromInstagram) {
        route.params.onVerifyHandle(route.params.insta_handler);
      }
    } catch (error) {
      console.tron.log('Error on mounting of Profile Details', error);
    }
  }

  componentWillUnmount() {
    Platform.select({
      ios: null,
      android: this.removeSmsListener(),
      web: null,
    });
    this.keyboardDidShowListener?.remove();
    this.keyboardDidHideListener?.remove();
  }

  registerSmsListener = async () => {
    // TODO: Clean this code after proper testing
    // try {
    //   const registered = await SmsRetriever.startSmsRetriever();
    //   if (registered) {
    //     SmsRetriever.addSmsListener(this.onReceiveSms);
    //   }
    // } catch (error) {
    //   console.tron.log(`SMS Listener Error: ${JSON.stringify(error)}`);
    // }
  };

  onReceiveSms = (event) => {
    // TODO: Clean this code after proper testing
    // const { screenProps } = this.props;
    // const { message } = event;
    // if (this.extractOtpRegex.test(message)) {
    //   this.otp = message.match(this.extractOtpRegex);
    //   screenProps.onVerifyOtp(this.otp);
    // }
  };

  removeSmsListener = () => {
    // TODO: Clean this code after proper testing
    // if (SmsRetriever) {
    //   SmsRetriever.removeSmsListener();
    // }
  };

  keyboardDidShow = (e) => {
    const {
      screen,
      modal,
      titleContainer,
      continueButtonContainer,
      statusBar,
    } = this.styles.height;
    const KeyboardHeight = e.endCoordinates.height;
    const availableModalHeight = screen - (KeyboardHeight + statusBar);
    const availableScrollViewHeight =
      screen -
      (KeyboardHeight + titleContainer + continueButtonContainer + statusBar);
    if (availableModalHeight < modal) {
      this.setState({
        modalContainerHeight: availableModalHeight,
        scrollViewContentHeight: availableScrollViewHeight,
        elevation: 3,
        shadowColor: 'black',
      });
    }
    this.setState({ KeyboardHeight });
  };

  keyboardDidHide = (e) => {
    const { modal, scrollView } = this.styles.height;
    this.setState({
      modalContainerHeight: modal,
      scrollViewContentHeight: scrollView,
      elevation: 0,
      shadowColor: 'black',
    });
  };

  titleContainer = (props) => {
    const { elevation, shadowColor } = this.state;
    const { titleContainer } = this.styles.height;
    return (
      <View
        elevation={elevation}
        shadowColor={shadowColor}
        shadowOpacity={0.4}
        shadowRadius={4}
        shadowOffset={{
          width: 0,
          height: 0,
        }}
        style={[
          styles.titleContainer,
          {
            height: titleContainer,
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
          },
        ]}
      >
        <Text style={styles.titleContainerText}>
          Reserve your social handle
        </Text>
        <TouchableOpacity onPress={this.skip}>
          <Text allowFontScaling={false} style={styles.titleContainerText}>
            Skip
          </Text>
        </TouchableOpacity>
      </View>
    );
  };

  profilePicPlusHandle = (props) => {
    const { cameraPermission, handle } = this.state;
    const { route } = props;

    const profilePic = {
      uri: `${route.params.userAccountInfo.profilePic}`,
    };

    // const profilePic = cameraPermission === 'authorized'
    //   ? { uri: `${props.screenProps.userAccountInfo.profilePic}` }
    //   : images.cameraDeniedImage;
    return (
      <View style={styles.profilePicPlusHandle}>
        <Image source={profilePic} style={styles.profilePic} />
        <View style={{ flexDirection: 'column' }}>
          <TextField
            autoFocus
            label="Handle"
            ref={this.ref.handle}
            prefix="@"
            lineWidth={2}
            tintColor={colors.silver}
            labelHeight={size.h4}
            onChangeText={this.addHandle}
            returnKeyType="next"
            blurOnSubmit={false}
            baseColor={colors.silver}
            containerStyle={{ width: width / 1.71 }}
            error={this.handleFieldError(props)}
            renderAccessory={this.handleAccessory}
            onSubmitEditing={this.next('name')}
            onFocus={this.removeSafeAreaBottomInset}
            value={!handle ? '' : handle}
            tintColor={colors.silver}
          />
        </View>
      </View>
    );
  };

  toggleHandlerProgressState = () => {
    this.setState((prevState) => ({
      fetchingInstaUserDetails: !prevState.fetchingInstaUserDetails,
    }));
  };

  getInstagramProfileByToken = (code) => {
    // getUserInstagramProfile(code);
    const { route } = this.props;
    this.toggleHandlerProgressState();
    route.params.getUserInstagramProfile(code, (data) => {
      this.toggleHandlerProgressState();
      if (data) {
        this.addHandle(data.insta_user_name);
        route.params.saveInstagramProfile(data);
      }
    });
  };

  addHandle = (value) => {
    const { route } = this.props;

    this.setState({ handle: value, handleError: null });
    if (value.length > 2) {
      route.params.onVerifyHandle(value);
    } else {
      route.params.onClearHandleStates();
    }
  };

  handleAccessory = () => {
    const { route } = this.props;
    const { handle, fetchingInstaUserDetails } = this.state;
    if (
      route.params.userAccountInfo.handleError !== null &&
      handle !== null &&
      handle !== '' &&
      handle !== undefined
    ) {
      return (
        <TouchableOpacity onPress={this.clearInputData('handle')}>
          <Image source={images.failure} style={styles.icon} />
        </TouchableOpacity>
      );
    }
    if (
      route.params.userAccountInfo.handleSuccess === true &&
      handle &&
      handle.length > 2
    ) {
      return <Image source={images.success} style={styles.icon} />;
    }

    if (fetchingInstaUserDetails) {
      return <ActivityIndicator size="small" color="#4990e2" />;
    }
  };

  handleFieldError = (props) => {
    let { handleError } = this.state;
    const { route } = props;
    if (
      handleError === undefined ||
      handleError === null ||
      handleError === ''
    ) {
      handleError = route.params.userAccountInfo.handleError;
    }
    return handleError;
  };

  personalDetailsFields = (props) => {
    const { nameFieldError, localityFieldError, latitude } = props;
    const { locality, referral, name } = this.state;
    return (
      <View style={styles.formView}>
        <TextField
          label="Full Name"
          ref={this.ref.name}
          lineWidth={2}
          tintColor={colors.silver}
          labelHeight={size.h4}
          onChangeText={this.addName}
          returnKeyType="next"
          error={nameFieldError}
          blurOnSubmit={false}
          baseColor={colors.silver}
          value={name || ''}
          renderAccessory={this.clearAccessory('name')}
          onSubmitEditing={this.next('locality')}
        />
        <TextField
          label="Locality"
          autoCapitalize
          ref={this.ref.locality}
          lineWidth={2}
          blurOnSubmit={false}
          returnKeyType="next"
          value={locality}
          onChangeText={this.addLocality}
          labelHeight={size.h4}
          tintColor={colors.silver}
          baseColor={colors.silver}
          error={localityFieldError}
          renderAccessory={this.locationAccessory({ latitude })}
          onSubmitEditing={this.next('referral')}
        />
        <TextField
          label="Referral Contact(Optional)"
          ref={this.ref.referral}
          value={referral}
          onChangeText={this.addReferral}
          lineWidth={2}
          blurOnSubmit={false}
          labelHeight={size.h4}
          tintColor={colors.silver}
          baseColor={colors.silver}
          renderAccessory={this.contactsAccessory}
        />
      </View>
    );
  };

  addName = (value) => this.setState({ name: value, nameFieldError: null });

  addLocality = (value) => {
    const { route } = this.props;
    this.setState({ locality: value, localityFieldError: null });

    if (value.length > 2) {
      route.params.onAutoCompleteLocality(value, (locality) =>
        this.setState({ locality }),
      );
    }
  };

  // TODO: Which kind of keyboard must be opn at here
  addReferral = (value) => {
    this.setState({ referral: value });
  };

  // TODO: try to destructure = this.state[fieldName]
  clearAccessory = (fieldName) => () => {
    const value = this.state[fieldName];
    if (value === null || value === '' || value === undefined) {
      return null;
    }
    return (
      <TouchableOpacity onPress={this.clearInputData(fieldName)}>
        <Image source={images.grayscaleCross} style={styles.icon} />
      </TouchableOpacity>
    );
  };

  clearInputData = (fieldName) => () => {
    this.ref[fieldName].current.clear();
    this.setState({ [fieldName]: '' });
    this.ref[fieldName].current.focus();
  };

  next = (fieldName) => () => this.ref[fieldName].current.focus();

  locationAccessory = (props) => () =>
    (
      <TouchableOpacity
        style={{ flexDirection: 'row' }}
        onPress={this.requestLocation}
      >
        <Image source={images.location} style={styles.locationIcon} />
        <>
          {props.latitude === null && (
            <Text style={styles.locationSuffixText}>Use current location</Text>
          )}
        </>
      </TouchableOpacity>
    );

  requestLocation = () => {
    Utility.checkPermission(PERMISSION_STORE.location, true, (isAllowed) => {
      if (isAllowed) {
        this.fetchLocationThroughGeolocation();
      }
    });
  };

  fetchLocationThroughGeolocation = () => {
    const { route } = this.props;
    navigator.geolocation.getCurrentPosition((position) =>
      route.params.addLatLong(
        position.coords.latitude,
        position.coords.longitude,
        (locality) => this.setState({ locality }),
      ),
    );
  };

  getContactList = () => {
    Utility.checkPermission(
      PERMISSION_STORE.read_contact,
      true,
      (isAllowed) => {
        if (isAllowed) {
          this.showContacts();
        }
      },
    );
  };

  showContacts = () => {
    // let referral = '';
    // this.ref.referral.current.blur();
    // ContactsWrapper.getContact()
    //   .then((contact) => {
    //     this.ref.referral.current.focus();
    //     referral = `${contact.name}(${contact.phone})`;
    //     this.setState({
    //       referral,
    //       referralContactName: contact.name,
    //       referralContactPhone: contact.phone,
    //     });
    //   })
    //   .catch((error) => {
    //   });
  };

  contactsAccessory = () => {
    const { referral } = this.state;
    let icon = null;
    let disableContactsBook = false;
    if (referral !== '') {
      icon = images.contacts.selected;
      disableContactsBook = true;
    }
    const ClearReferralAccessory = this.clearAccessory('referral');
    return (
      <View style={{ flexDirection: 'row' }}>
        <ClearReferralAccessory />
        <TouchableOpacity
          onPress={this.getContactList}
          style={{ width: 20, height: 20, marginLeft: 16 }}
          disabled={disableContactsBook}
        >
          <Image source={icon} />
        </TouchableOpacity>
      </View>
    );
  };

  isHandleValid = () => {
    const { handle } = this.state;
    const isHandleBlank = Utility.isBlank(handle);
    if (isHandleBlank === true) {
      this.setState({ handleError: 'Handle cannot be empty' });
    }
    return isHandleBlank;
  };

  isNameValid = () => {
    const { name } = this.state;
    const isNameBlank = Utility.isBlank(name);
    if (isNameBlank === true) {
      this.setState({ nameFieldError: 'Name cannot be empty' });
    }
    return isNameBlank;
  };

  isLocalityValid = () => {
    const { locality } = this.state;
    const isLocalityBlank = Utility.isBlank(locality);
    if (Utility.isBlank(locality)) {
      this.setState({ localityFieldError: 'Locality cannot be empty' });
    }
    return isLocalityBlank;
  };

  skip = () => {
    const { route } = this.props;
    route.params.onSkip(ONBOARDING_STATUS.Completed);
    // this.navigateToInterests();
  };

  onPress = () => {
    const { route } = this.props;
    const isHandleBlank = this.isHandleValid();
    const isNameBlank = this.isNameValid();
    const isLocalityBlank = this.isLocalityValid();

    if (
      isHandleBlank === false &&
      isNameBlank === false &&
      isLocalityBlank === false
    ) {
      route.params.onSkip(ONBOARDING_STATUS.OnProfileDetails);
      route.params.onSubmitUserInfo(this.state);
      route.params.profileManuallyFilled(true);
      this.navigateToInterests();
    } else {
      console.tron.log('Not a user');
    }
  };

  navigateToInterests = () => {
    const { navigation } = this.props;
    navigation.navigate(SCREEN.SELECT_YOUR_INTEREST);
  };

  removeSafeAreaBottomInset = () => {
    if (Utility.isIOS()) {
      this.setState({
        safeAreaBottomInset: -(Utility.bottomInset - 18),
      });
    }
  };

  showSafeAreaBottomInset = () => {
    if (Utility.isIOS()) {
      this.setState({ safeAreaBottomInset: 0 });
    }
  };

  onContentSizeChange = (contentWidth, contentHeight) => {
    const { screen, titleContainer, continueButtonContainer, statusBar } =
      this.styles.height;
    const { KeyboardHeight, modalContainerHeight, scrollViewContentHeight } =
      this.state;
    const changeInContentHeight = scrollViewContentHeight - contentHeight;
    const aviliableScrollViewHeight =
      screen -
      (KeyboardHeight + titleContainer + continueButtonContainer + statusBar);
    const IncreaseInModalHeight = modalContainerHeight - changeInContentHeight;
    const IncreaseInScrollViewHeight =
      scrollViewContentHeight - changeInContentHeight;

    if (contentHeight < aviliableScrollViewHeight) {
      this.setState({
        modalContainerHeight: IncreaseInModalHeight,
        scrollViewContentHeight: IncreaseInScrollViewHeight,
      });
    } else {
      this.setState({ elevation: 3, shadowColor: 'black' });
    }
  };

  profileDetailsContainer = (props) => {
    const { modalContainerHeight, scrollViewContentHeight } = this.state;
    return (
      <SafeAreaView forceInset={{ bottom: 'always' }}>
        <View style={[styles.modal, { height: modalContainerHeight }]}>
          <this.titleContainer {...this.props} />
          <View style={{ height: scrollViewContentHeight }}>
            <ScrollView
              onContentSizeChange={this.onContentSizeChange}
              contentContainerStyle={styles.ScrollViewContainerInset}
              keyboardShouldPersistTaps="handled"
            >
              <this.profilePicPlusHandle {...this.props} />
              <this.personalDetailsFields {...props} />
            </ScrollView>
          </View>
          <View style={{ height: 61, alignItems: 'center' }}>
            <FoxyShadowButton title="Continue" onPress={this.onPress} />
          </View>
        </View>
      </SafeAreaView>
    );
  };

  render() {
    const { route } = this.props;
    const { nameFieldError, localityFieldError, safeAreaBottomInset } =
      this.state;
    return (
      <KeyboardAvoidingView
        behavior="position"
        contentContainerStyle={{ marginBottom: safeAreaBottomInset }}
      >
        <this.profileDetailsContainer
          nameFieldError={nameFieldError}
          localityFieldError={localityFieldError}
          latitude={route.params.userAccountInfo.latitude}
          locality={route.params.userAccountInfo.locality}
        />
      </KeyboardAvoidingView>
    );
  }
}
