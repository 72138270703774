// Dependencies
import React, { Component } from 'react';
import { View, FlatList } from 'react-native';
import PropTypes from 'prop-types';
import { LAYOUT, ORIENTATION } from '../../../config/Constants';
import Utility from '../../../utils/Utility';
// Components
import Media from '../../../containers/media';
import styles from './styles';
import { isDesktop } from '../../../utils/BooleanUtility';
import { FlatListPerformanceView } from '../../../libraries/ReactNativePerformanceShopify';
import { getListSource } from '../../../utils/PerfUtility';

class ReviewGrid extends Component {
  static Components = {
    media: Media,
  };

  constructor(props) {
    super(props);
    this.displayCount = 3;
    this.itemData = this.props.item.filter((item) => !!item);
    this.column = 3;
  }

  getComponent = (item, index) => {
    if (Utility.isBlank(item)) {
      return null;
    }
    const { size, id, navigation, showRating } = this.props;
    if (item === undefined) {
      return null;
    }
    return (
      <Media
        id={item.id}
        layout={LAYOUT.REVIEWGRID}
        itemData={item}
        type={item.type}
        size={size}
        navigation={navigation}
        orientation={ORIENTATION.VERTICAL}
        listId={id}
        index={index}
        showRating={showRating}
      />
    );
  };

  render() {
    if (Utility.isBlank(this.itemData)) {
      return null;
    }
    return (
      <View style={styles.container}>
        <FlatListPerformanceView listName={`${getListSource(this, this.props)}`}>
          <FlatList
            data={this.itemData.slice(0, this.displayCount)}
            numColumns={isDesktop() ? this.column * 2 : this.column}
            scrollEnabled
            keyExtractor={(item) => `${item.schema}_${item.id}_grid`}
            renderItem={({ item, index }) => this.getComponent(item, index)}
            style={styles.flatlistStyle}
          />
        </FlatListPerformanceView>
      </View>
    );
  }
}

ReviewGrid.propTypes = {
  list: PropTypes.shape({
    objects: PropTypes.array,
    size: PropTypes.string,
  }),
  item: PropTypes.array,
  content: PropTypes.string,
};

export default ReviewGrid;
