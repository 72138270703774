import React from 'react';
import {
  Text,
  View,
  Image,
  TouchableWithoutFeedback,
} from 'react-native';
import StyleSheet from 'react-native-media-query';
import colors from '../../theme/Colors';

import Utility from '../../utils/Utility';
import Images from '../../theme/Images';
import FastImageView from '../FastImageView';
import { isDesktop } from '../../utils/BooleanUtility';
import { getMinifiedImage } from '../../utils/ImageUtility';

const { ids, styles } = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    width: Utility.getScreenWidth(),
    '@media (min-width: 768px)': {
      flexDirection: 'column',
      width: '100%',
    },
  },
  innerContainer: {
    flexDirection: 'column',
    flex: 4,
    paddingHorizontal: 9,
    '@media (min-width: 768px)': {
      backgroundColor: colors.white,
      marginTop: 5,
      paddingVertical: 21,
      paddingHorizontal: 12,
    },
  },
  brandName: {
    fontFamily: 'Roboto-Medium',
    fontSize: 10,
    fontStyle: 'normal',
    color: colors.subtitle,
    marginBottom: 4,
    '@media (min-width: 768px)': {
      fontSize: 12,
      lineHeight: 18,
    },
  },
  productName: {
    fontFamily: 'Roboto-Regular',
    fontSize: 12,
    color: colors.foxyBlack,
    alignItems: 'flex-start',
    marginBottom: 10,
    marginRight: 16,
    '@media (min-width: 768px)': {
      fontSize: 14,
      marginBottom: 0,
    },
  },
  variantName: {
    fontSize: 11,
    fontFamily: 'Roboto-Regular',
    fontStyle: 'normal',
    color: colors.foxyBlack,
    maxWidth: 80,
  },
  chevron: {
    width: 12,
    height: 12,
    marginLeft: 4,
    tintColor: '#979BAA',
  },
  variantDisplayNameContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 1,
    borderColor: colors.border,
    borderRadius: 4,
    paddingHorizontal: 8,
    paddingVertical: 4,
    width: 120,
    '@media (min-width: 768px)': {
      marginTop: 10,
    },
  },
  variantImage: {
    height: 12,
    width: 12,
    borderRadius: 6,
    marginRight: 4,
    '@media (min-width: 768px)': {
      borderRadius: '50%',
    },
  },
  ratedProductImage: {
    height: 75,
    width: 120,
    '@media (min-width: 768px)': {
      width: 328,
      height: 328,
    },
  },
  ratedProductContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
  },
});

const RatedProduct = ({ itemData, selectedVariant, toggleVariantModal }) => {
  if (Utility.isBlank(itemData)) {
    return null;
  }
  const {
    image_url = '',
    images = '',
    name = '',
    brand = {},
    product_name = '',
  } = itemData;

  let image = Utility.isPresent(image_url) ? image_url : '';
  if (image === '') {
    image = images ? images[0] : '';
  }
  const imageUrl = isDesktop() ? image : Utility.getMinifiedImage(image, styles.variantImage.width, styles.variantImage.height);
  let variantImage = imageUrl;

  if (Utility.isPresent(selectedVariant)) {
    image = Utility.isPresent(selectedVariant.image_url)
      ? selectedVariant.image_url
      : '';
    if (image === '') {
      image = selectedVariant.images ? selectedVariant.images[0] : '';
    }
    variantImage = getMinifiedImage(
      image,
      styles.variantImage.width,
      styles.variantImage.height,
    );
  }

  const productName = name
    ? name.replace(brand?.name, '').trim()
    : product_name?.replace(brand?.name, '').trim();

  const RenderSelectedVariant = () => {
    if (Utility.isBlank(selectedVariant)) {
      return null;
    }
    return (
      <TouchableWithoutFeedback onPress={toggleVariantModal}>
        <View style={styles.variantDisplayNameContainer} dataSet={{ media: ids.variantDisplayNameContainer }}>
          <FastImageView
            source={variantImage}
            style={styles.variantImage}
            resizeMode={isDesktop() ? 'cover' : 'contain'}
            dataSet={{ media: ids.variantImage }}
          />
          <Text style={styles.variantName} numberOfLines={1}>
            {selectedVariant.display_name}
          </Text>
          <Image source={Images.chevronRight} style={styles.chevron} />
        </View>
      </TouchableWithoutFeedback>
    );
  };

  return (
    <View style={styles.container} dataSet={{ media: ids.container }}>
      <View style={!isDesktop && styles.ratedProductContainer}>
        <FastImageView
          source={imageUrl}
          style={styles.ratedProductImage}
          dataSet={{ media: ids.ratedProductImage }}
          resizeMode={isDesktop() ? 'cover' : 'contain'}
        />
      </View>
      <View style={styles.innerContainer} dataSet={{ media: ids.innerContainer }}>
        <Text style={styles.brandName} dataSet={{ media: ids.brandName }}>{brand.name?.toUpperCase()}</Text>
        {!isDesktop() && <RenderSelectedVariant />}
        <Text style={styles.productName} dataSet={{ media: ids.productName }}>{productName}</Text>
        {isDesktop() && <RenderSelectedVariant />}
      </View>
    </View>
  );
};

export default RatedProduct;
