import React, { PureComponent } from 'react';
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  FlatList,
} from 'react-native';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  AnalyticsManager,
  EventParameterKey,
  EventParameterValue,
  EventType,
} from '../../analytics';
import FastImageView from '../../components/FastImageView';
import DebouncedTouchableOpacity from '../../components/shared/DebouncedTouchableOpacity';
import { EDGE_MODAL_TYPES, REMOTE_CONFIG_KEYS } from '../../config/Constants';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import colors from '../../theme/Colors';
import size from '../../theme/Fonts';
import images from '../../theme/Images';
import DynamicLinkManager from '../../utils/DynamicLinkManager';
import RemoteConfig from '../../utils/RemoteConfig';
import Utility from '../../utils/Utility';
import withNavigation from '../../utils/WithNavigation';
import { addContinueAction } from '../../actions/FoxyEdgeAction';
import Config from '../../libraries/ReactNativeConfig';
import LinearGradient from 'react-native-linear-gradient';

const styles = StyleSheet.create({
  touchableContainer: {
    width: '100%',
    height: '100%',
  },
  modalContainer: {
    height: '100%',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
  },
  bottomModal: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    marginBottom: 0,
    marginRight: 0,
    marginLeft: 0,
    paddingTop: 20,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    zIndex: 1,
  },
  container: {
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: colors.white,
    paddingBottom: 24,
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
  },
  heading: {
    paddingHorizontal: 12,
  },
  title: {
    color: colors.black,
    fontSize: size.h1,
    fontFamily: 'Roboto-Regular',
    fontWeight: '700',
  },
  subtitle: {
    color: colors.black,
    fontSize: size.h3,
    fontFamily: 'Roboto-Regular',
    fontWeight: '400',
    marginTop: 4,
  },
  image: {
    height: 310,
    width: 256,
    marginHorizontal: 6,
  },
  bottomText: {
    color: colors.foxyBlack,
    fontSize: size.h3,
    fontFamily: 'Roboto-Regular',
    fontStyle: 'italic',
    fontWeight: '700',
    textAlign: 'left',
    paddingLeft: 8,
    width: Utility.getScreenWidth() - 24,
    marginBottom: 12,
  },
  whiteButtonContainer: {
    backgroundColor: colors.white,
    borderRadius: 4,
    borderWidth: 1.5,
    borderColor: colors.black,
    height: 40,
    width: Utility.getScreenWidth() - 24,
    marginBottom: 8,
    justifyContent: 'center',
    alignItems: 'center',
  },
  knowMoreText: {
    fontSize: size.h2,
    fontFamily: 'Roboto-Medium',
    color: colors.foxyBlack,
  },
  blackButtonContainer: {
    backgroundColor: colors.black,
    borderRadius: 4,
    height: 40,
    width: Utility.getScreenWidth() - 24,
    marginBottom: 12,
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonText: {
    fontSize: size.h2,
    fontFamily: 'Roboto-Medium',
    color: colors.white,
  },
  topIcon: {
    height: 40,
    width: 40,
    borderRadius: 20,
    marginTop: -20,
    marginBottom: 8,
    overflow: 'visible',
  },
  modal_close_container: {
    position: 'absolute',
    zIndex: 1,
    top: 12,
    right: 12,
  },
  modal_close: {
    height: 23,
    width: 23,
    resizeMode: 'contain',
  },
  topImage: {
    position: 'absolute',
    top: -20,
    width: 40,
    height: 40,
    alignSelf: 'center',
  },
  dropShadow: {
    shadowColor: 'rgba(255, 206, 1, 0.7)',
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 1,
    shadowRadius: 10,
    position: 'absolute',
    top: 0,
  },
  imagesList: {
    height: 334,
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 12,
  },
});

class BecomeMemberModal extends PureComponent {
  constructor(props) {
    super(props);
    this.modalData = JSON.parse(
      RemoteConfig.getValue(REMOTE_CONFIG_KEYS.become_member_modal),
    );
    const { route } = props;
    const {
      previousScreen,
      previousScreenSlug,
      imageUrl,
      continueScreenType,
      cartItems,
      slug,
      screenType,
    } = route.params;

    this.addContinueActionArgs = {
      slug: previousScreenSlug,
      imageUrl,
      screenType: continueScreenType,
    };
    if (previousScreen === SCREEN_CONSTANTS.CART && cartItems?.length > 0) {
      const appDomain = Config.WEB_URL;
      this.addContinueActionArgs = {
        slug: `${appDomain}/cart`,
        imageUrl: cartItems[0].product?.image_url,
        screenType: SCREEN_CONSTANTS.CART,
      };
    }
    if (previousScreen === 'Routine') {
      this.addContinueActionArgs = {
        slug,
        imageUrl,
        screenType,
      };
    }

    this.x = { x: 0, y: 0 };
    this.y = { x: 0, y: 1 };
  }

  componentDidMount() {
    const { authToken, address, date_of_birth, route } = this.props;
    const { renewMemberModal, previousScreen } = route.params;
    AnalyticsManager.logEvent(EventType.artistEvents.MODAL_VIEW, {
      [EventParameterKey.MODAL_NAME]:
        EventParameterValue.MODAL_NAME.BECOME_MEMBER_MODAL,
      [EventParameterKey.PREVIOUS_SCREEN]: renewMemberModal
        ? 'foxy_edge_expired'
        : previousScreen,
      is_guest_user: Utility.isBlank(authToken),
      address_present: Utility.isPresent(address),
      dob_present: Utility.isDobPresent(date_of_birth),
    });
  }

  closeModal = () => {
    const { navigation } = this.props;
    navigation.pop();
  };

  onPressPrimaryAction = () => {
    const { route, authToken, address, date_of_birth, addContinueAction } =
      this.props;
    const { previousScreen } = route.params;
    const appDomain = Config.WEB_URL;
    const primaryActionForRenewModal = `${appDomain}/foxy_edge`;

    AnalyticsManager.logEvent(EventType.artistEvents.MODAL_ACTION, {
      [EventParameterKey.TYPE]: 'secondary',
      [EventParameterKey.MODAL_NAME]:
        EventParameterValue.MODAL_NAME.BECOME_MEMBER_MODAL,
      [EventParameterKey.CTA]: 'Know More',
      previousScreen,
      is_guest_user: Utility.isBlank(authToken),
      address_present: Utility.isPresent(address),
      dob_present: Utility.isDobPresent(date_of_birth),
    });
    DynamicLinkManager.handleDynamicLink(
      primaryActionForRenewModal,
      this.handleRouteFromLink,
    );
    const { slug, imageUrl, screenType } = this.addContinueActionArgs;
    if (Utility.isPresent(slug) && Utility.isPresent(imageUrl)) {
      addContinueAction(slug, imageUrl, screenType);
    }
  };

  joinButton = () => {
    const { route } = this.props;
    const { secondaryButtonText = 'Activate my membership' } = route.params;
    return (
      <DebouncedTouchableOpacity
        {...this.props}
        style={styles.blackButtonContainer}
        onPress={this.onPressSecondaryAction}
      >
        <Text style={styles.buttonText}>{secondaryButtonText}</Text>
      </DebouncedTouchableOpacity>
    );
  };

  handleRouteFromLink = (route, slug, path, extra, params) => {
    const { navigation } = this.props;
    this.closeModal();
    setTimeout(() => {
      navigation.navigate(route, { slug, extra, params });
    }, 300);
  };

  onPressSecondaryAction = () => {
    const { route, authToken, address, date_of_birth, addContinueAction } =
      this.props;
    const { secondaryButtonText = '', previousScreen } = route.params;
    const appDomain = Config.WEB_URL;
    let secondaryActionForRenewModal = `${appDomain}/loyalty_plans`;

    AnalyticsManager.logEvent(EventType.artistEvents.MODAL_ACTION, {
      [EventParameterKey.TYPE]: 'primary',
      [EventParameterKey.MODAL_NAME]:
        EventParameterValue.MODAL_NAME.BECOME_MEMBER_MODAL,
      [EventParameterKey.CTA]: secondaryButtonText,
      previousScreen,
      is_guest_user: Utility.isBlank(authToken),
      address_present: Utility.isPresent(address),
      dob_present: Utility.isDobPresent(date_of_birth),
    });
    DynamicLinkManager.handleDynamicLink(
      secondaryActionForRenewModal,
      this.handleRouteFromLink,
    );

    const { slug, imageUrl, screenType } = this.addContinueActionArgs;
    if (Utility.isPresent(slug) && Utility.isPresent(imageUrl)) {
      addContinueAction(slug, imageUrl, screenType);
    }
  };

  knowMoreButton = () => {
    return (
      <DebouncedTouchableOpacity
        {...this.props}
        style={styles.whiteButtonContainer}
        onPress={this.onPressPrimaryAction}
      >
        <Text style={styles.knowMoreText}>Know More</Text>
      </DebouncedTouchableOpacity>
    );
  };

  bottomButtons = () => {
    const { hideKnowMore = false } = this.modalData;
    return (
      <>
        <this.joinButton />
      </>
    );
  };

  renderImage = ({ item }) => {
    return <FastImageView style={styles.image} source={item} />;
  };

  renderImageList = () => {
    return (
      <FlatList
        data={this.modalData?.images}
        contentContainerStyle={styles.imagesList}
        renderItem={this.renderImage}
        horizontal
        showsHorizontalScrollIndicator={false}
      />
    );
  };

  renderView = () => {
    const { heading = '', subheading = '' } = this.modalData;
    const { route } = this.props;
    const { renewMemberModal, routineMissed } = route.params;
    let head = renewMemberModal ? 'Renew your Foxy EDGE membership' : heading;
    let subhead = renewMemberModal
      ? 'Keep getting gifts, amazing benefits, extra discounts & a lot more...'
      : subheading;

    if (routineMissed) {
      head = 'Seems like you missed this routine';
      subhead =
        'Don’t worry, there are more to come. Subscribe to Foxy Edge to get all routines for FREE.';
    }
    return (
      <View style={styles.container}>
        <FastImageView style={styles.topIcon} source={images.clubStar} />
        <View style={styles.heading}>
          <Text style={styles.title}>{head}</Text>
          <Text style={styles.subtitle}>{subhead}</Text>
        </View>
        <this.renderImageList />
        <Text style={styles.bottomText}>and much more ...</Text>
        <this.bottomButtons />
        <TouchableOpacity
          style={styles.modal_close_container}
          onPress={this.closeModal}
        >
          <FastImageView
            style={styles.modal_close}
            source={images.edge.modal_close}
          />
        </TouchableOpacity>
        <View style={styles.dropShadow}>
          <FastImageView source={images.edge.offer} style={styles.topImage} />
        </View>
      </View>
    );
  };

  render() {
    const gradientArray = [
      colors.transparent,
      colors.translucent,
      colors.translucent,
    ];
    return (
      <>
        <TouchableOpacity onPress={this.closeModal}>
          <LinearGradient
            colors={gradientArray}
            style={styles.touchableContainer}
            start={this.x}
            end={this.y}
          />
        </TouchableOpacity>
        <View style={styles.modalContainer} pointerEvents="box-none">
          <View style={styles.bottomModal}>
            <this.renderView />
          </View>
        </View>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    cartItems: state.bag.cartItems,
    address: state.UserAccountInfo.addresses,
    authToken: state.UserAccountInfo.authToken,
    date_of_birth: state.UserAccountInfo.profile.date_of_birth,
  };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      addContinueAction,
    },
    dispatch,
  ),
});

export default withNavigation(
  connect(mapStateToProps, mapDispatchToProps)(BecomeMemberModal),
);
