// install to conversion specific component, need to be fix

import React, { PureComponent } from 'react';
import { View, Text, Image } from 'react-native';
import Config from '../../libraries/ReactNativeConfig';
import lodash from 'lodash';
import Card from '../../lib/Card';
import {
  ORIENTATION,
  LAYOUT,
  SIZE,
  THEME,
  AVAILABLE_BUILD_TYPES,
  REMOTE_CONFIG_KEYS,
} from '../../config/Constants';
import { connect } from 'react-redux';
import { withMaybe } from '../../lib/Monads';
import ProductImageAndRating from './ProductImageAndRating';
import _ from 'lodash';
import FreeProductDescription from './FreeProductDescription';
import { PRODUCT } from '../../config/LayoutConstants/ProductConfig';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import ScaleAnimate from '../shared/ScaleAnimate';
import { compose } from 'recompose';
import ProductCardStyles from './styles/styles';
import { ProductInCartStyles } from './styles';
import imagess from '../../theme/Images';

import {
  EventParameterKey,
  AnalyticsManager,
  EventType,
} from '../../analytics';

import AnalyticsUtility from '../../analytics/AnalyticsUtility';
import colors from '../../theme/Colors';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import RemoteConfig from '../../utils/RemoteConfig';
import { navigateToScreen } from '../../utils/NavigationUtility';
import { getMinifiedImage } from '../../utils/ImageUtility';

class FreeProductCard extends PureComponent {
  static getComponentHeight(columns = 2) {
    return Utility.getDynamicWidthForGrid(columns, 16) + 110;
  }

  constructor(props) {
    super(props);
    this.state = {
      isCartVisible: false,
      selectedItem: false,
    };
    this.debouncedNavigate = _.debounce(this.navigate, 1000, {
      leading: true,
      trailing: false,
    });
    this.debounceProductCardClick = _.debounce(
      this.fireSearchResultClickEvent,
      2000,
      {
        leading: true,
        trailing: false,
      },
    );
    this.isLocked = false;
    this.remoteConfigStrings = Utility.jsonParser(
      RemoteConfig.getValue(REMOTE_CONFIG_KEYS.group_deals_contacts_strings),
    );
    this.onPressMemoList = {};
  }

  static cardLayoutStyle = {
    vertical: {
      borderRadius: 5,
    },
    horizontal: {
      borderRadius: 0,
    },
  };

  listConditionFn = (props) => props.layout === LAYOUT.LIST;

  personalisedRailCondition = (props) =>
    props.layout === LAYOUT.PERSONALISEDRAIL;

  verticalRailCondition = (props) => !(props.layout === LAYOUT.VERTICALRAIL);

  conditionalText = withMaybe(this.verticalRailCondition)(Text);

  conditionalDivider = compose(
    withMaybe(this.listConditionFn),
    withMaybe(this.personalisedRailCondition),
  )(Text);

  onCartDismiss = () => {
    this.setState({
      isCartVisible: false,
    });
  };

  showCart = () => {
    const { toggleCartVisibility } = this.props;
    // TODO: This prevents a crash from the search results page but doesn't allow the user to navigate to the cart page
    if (toggleCartVisibility !== undefined) {
      toggleCartVisibility();
    }
  };

  getStyles = ({
    layout,
    size = 'defaultSize',
    orientation = ORIENTATION.HORIZONTAL,
    theme = THEME.LIGHT,
  }) => {
    let layoutSize = size;
    if (Utility.isBlank(layoutSize)) {
      layoutSize = 'defaultSize';
    }
    if (Utility.isBlank(layout)) {
      return null;
    }
    const styles = ProductCardStyles;

    const { viewwidth, viewheight, imagewidth, imageheight } =
      PRODUCT.CARD[layout][layoutSize];
    const cardDimensions = {
      height: viewheight + 12,
      width: viewwidth,
    };
    const separatorStyle = {};

    const cardStyle = [
      styles[
        `${layoutSize}${lodash.capitalize(theme)}${lodash.capitalize(
          orientation,
        )}CardStyleSku`
      ],
      cardDimensions,
    ];
    const prodImageRatingContainerStyle = [
      styles[`${layout}ProdImageRatingContainer`],
      {
        height: imageheight,
        width: imagewidth,
      },
    ];
    let cartImageStyle = ProductCardStyles[`${orientation}CartImageContainer`];
    if (layout === LAYOUT.VERTICALRAIL) {
      cartImageStyle = ProductCardStyles.verticalRailCartImageContainer;
    }
    if (layout === LAYOUT.PERSONALISEDRAIL) {
      cartImageStyle = ProductCardStyles.personalisedRailCartImageContainer;
    }
    const descriptionContainerStyle =
      styles[`${layout}DescriptionContainerStyle`];
    return {
      cardStyle,
      prodImageRatingContainerStyle,
      cartImageStyle,
      descriptionContainerStyle,
      separatorStyle,
    };
  };

  navigate = () => {
    const { navigation, id, slug, itemData, toggleCartVisibility } = this.props;
    navigateToScreen({
      navigation,
      type: 'push',
      screen: 'Product',
      params: {
        id,
        slug,
        display: LAYOUT.SCREEN,
        itemData,
        toggleCartVisibility,
        previousScreen: this.props?.previousScreen,
      },
    });
  };

  memoizedOnPress = (id) => {
    if (!this.onPressMemoList[id]) {
      this.onPressMemoList[id] = this.onPress(id);
    }
    return this.onPressMemoList[id];
  };

  onPress = (id) => async () => {
    const {
      onPress = () => {},
      showCustomIcon,
      addProductToRedux,
      listIndex,
      index,
      search,
      searchQuery,
      elementItemCounts,
      itemData,
      itemData: { slug = '' } = {},
      listId,
      layout,
      previousScreen,
      listName,
      listSlug = '',
      listContent = '',
      toggleUnavailableProductsModal,
      showToast,
    } = this.props;
    if (this.isLocked) {
      showToast(this.remoteConfigStrings.insufficient_points_toast, 2000);
      return;
    }
    this.props.updateSelectedItem(id);

    if (previousScreen === SCREEN_CONSTANTS.SEARCH) {
      let clickedIndex = Utility.addPreviousIndexValues(
        elementItemCounts,
        listIndex,
      );
      clickedIndex += index + 1;
      const meta = {
        [EventParameterKey.SEARCH_QUERY]: searchQuery,
        [EventParameterKey.ITEM_TYPE]: itemData.type,
        [EventParameterKey.ITEM_NAME]: itemData.name,
        [EventParameterKey.ITEM_ID]: itemData.id,
        [EventParameterKey.ITEM_POSITION]: clickedIndex || 'NAN', //TODO: this was crashing need to check
      };
      this.debounceProductCardClick(meta);
    }

    if (toggleUnavailableProductsModal) {
      await toggleUnavailableProductsModal();
    }

    AnalyticsUtility.fireItemClickEvent(
      previousScreen,
      itemData.id,
      itemData.type,
      itemData.name,
      index,
      listId,
      layout,
      listName,
      listIndex,
      '',
      !itemData.outOfStock,
      listContent,
      '',
      slug,
      listSlug,
      {},
      itemData,
    );

    if (showCustomIcon) {
      addProductToRedux();
      onPress(id);
    } else {
      //   this.debouncedNavigate();
      if (onPress !== undefined && onPress !== null) {
        onPress();
      }
    }
  };

  fireSearchResultClickEvent = (meta) => {
    AnalyticsManager.logEvent(EventType.search.SEARCH_RESULT_CLICK, meta);
  };

  variantImage = (props) => {
    const { variantImage } = props;
    const styles = ProductInCartStyles;
    if (Utility.isBlank(variantImage) || variantImage === '') {
      return null;
    }
    return (
      <Image
        style={styles.variantImageStyle}
        source={{
          uri: getMinifiedImage(
            variantImage,
            styles.variantImageStyle.width,
            styles.variantImageStyle.height,
          ),
        }}
      />
    );
  };

  productVariant = () => {
    const {
      itemData: { image_url, display_name },
    } = this.props;
    if (Utility.isBlank(display_name)) {
      return null;
    }

    const styles = ProductInCartStyles;
    return (
      <View style={styles.variant}>
        <this.variantImage variantImage={image_url} />
        <Text
          allowFontScaling={false}
          style={styles.productQuantandVariantText}
        >
          {display_name}
        </Text>
      </View>
    );
  };

  greenTick = ({ isSelected }) => {
    if (!isSelected) {
      return null;
    }
    return (
      <Image
        style={ProductCardStyles.greenTickStyle}
        source={imagess.greenCircularTick}
      />
    );
  };

  LockIcon = ({ isLocked }) => {
    if (!isLocked) {
      return null;
    }
    return <Image style={ProductCardStyles.lockIcon} source={imagess.lock} />;
  };

  render() {
    const {
      orientation,
      theme = THEME.LIGHT,
      id,
      layout = LAYOUT.RAIL,
      previousScreen,
      itemData,
      hasVariants,
      showToast,
      size = 'defaultSize',
      itemData: { sku = {}, points, id: giftId },
      hideAddToCart,
      freeProductScreen,
      foxyPoints,
      selectedItem,
    } = this.props;

    const {
      offer_value,
      final_sp: display_price,
      additional_discount,
      display_name,
      type,
      image_url: imageUrl,
      brand_name: variantBrandName,
      brand: { name: brandName } = {},
      product_name,
      pack_size,
      rating,
      name,
      mrp,
      sp,
      discount,
      priority,
      outOfStock,
      sku_id: skuId,
      images,
      webp_images,
      image_webp_url,
      card_image,
      card_image_webp_url,
    } = Utility.isPresent(points) ? sku : itemData;

    const {
      cardStyle,
      prodImageRatingContainerStyle,
      cartImageStyle,
      descriptionContainerStyle,
      separatorStyle,
    } = this.getStyles(this.props);

    const disabled =
      Utility.getCurrentAppType() === AVAILABLE_BUILD_TYPES.artist_only ||
      previousScreen === 'brandCollab' ||
      previousScreen === 'free_items';

    if (previousScreen === 'MY_REWARDS' && points > foxyPoints) {
      this.isLocked = true;
    }
    const { imageheight, imagewidth } = PRODUCT.CARD[layout][size];

    const productImageContainerstyle =
      layout !== 'grid'
        ? prodImageRatingContainerStyle
        : { height: imageheight };
    const rewardID = Utility.isPresent(points) ? giftId : skuId;
    const isSelected = selectedItem === rewardID;
    const boxshadow = isSelected
      ? ProductCardStyles.selectedBoxStyle
      : ProductCardStyles.boxStyle;

    const brandTitle = Utility.isPresent(brandName)
      ? brandName
      : Utility.isPresent(variantBrandName)
      ? variantBrandName
      : Config.APP_NAME;
    return (
      <View>
        <ScaleAnimate
          disabled={disabled}
          onPress={this.memoizedOnPress(rewardID)}
        >
          <Card>
            <View style={[cardStyle, boxshadow]}>
              <View style={productImageContainerstyle}>
                <ProductImageAndRating
                  layout={layout}
                  rating={rating}
                  images={images}
                  webp_images={webp_images}
                  image_webp_url={image_webp_url}
                  imageUrl={imageUrl}
                  cardImage={card_image_webp_url}
                  id={id}
                  name={name}
                  previousScreen={previousScreen}
                  disabled={freeProductScreen === 'disabled'}
                  itemData={itemData}
                />
              </View>

              <this.conditionalDivider
                layout={layout}
                style={ProductCardStyles[`${layout}Divider`]}
              />

              <View style={descriptionContainerStyle}>
                <FreeProductDescription
                  name={Utility.isPresent(name) ? name : product_name}
                  mrp={mrp}
                  offer_value={offer_value}
                  sp={sp}
                  display_price={display_price}
                  discount={discount}
                  brandName={brandTitle}
                  layout={layout}
                  theme={theme}
                  showColorComponent={this.props.showColorComponent}
                  addToCartLayout={PRODUCT.ADD_TO_CART_LAYOUT.PRODUCT_CARD}
                  hideAddToCart={hideAddToCart}
                  id={id}
                  itemData={itemData}
                  hasVariants={hasVariants}
                  showCart={this.showCart}
                  priority={priority}
                  outOfStock={outOfStock}
                  showToast={showToast}
                  previousScreen={previousScreen}
                  variant_name={display_name}
                  variant_iamge_url={imageUrl}
                  pack_size={pack_size}
                  skuCard
                />
              </View>
            </View>
          </Card>
          <this.greenTick isSelected={isSelected} />
          <this.LockIcon isLocked={this.isLocked} />
        </ScaleAnimate>
      </View>
    );
  }
}

export default withNavigation(FreeProductCard);
