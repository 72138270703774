import { StyleSheet } from 'react-native';
import colors from '../../../theme/Colors';
import size from '../../../theme/Fonts';


const inheritStyles = StyleSheet.create({
  cardIcon: { height: 32, width: 32 },
  couponContainer: {
    backgroundColor: '#FFE4F4',
    width: '90%',
    height: 80,
    borderRadius: 16,
    marginLeft: 32,
    paddingVertical: 8,
    justifyContent: 'space-between',
    paddingHorizontal: 8,
    paddingLeft: 32,
    marginTop: 8,
  },
  container: {},
  contentContainer: {
    backgroundColor: colors.white,
    paddingHorizontal: 12,
    paddingVertical: 8,
  },
  circle: {
    height: 56,
    width: 56,
    borderRadius: 28,
    position: 'absolute',
    top: 12,
    left: -28,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'red',
  },
  maxDiscount: { fontSize: 16, fontFamily: 'Roboto-Bold', color: '#173143' },
  headingStyle: {
    fontSize: 14,
    fontFamily: 'Roboto-Medium',
    fontStyle: 'normal',
    color: colors.foxyBlack,
  },
  subheadingStyle: {
    fontSize: 12,
    fontFamily: 'Roboto-Regular',
    color: colors.subtitle,
  },
  timerText: {
    fontSize: 12,
    fontFamily: 'Roboto-Regular',
    color: colors.foxyBlack,
    marginBottom: 4,
  },
  digitStyle: {
    backgroundColor: colors.black,
    borderRadius: 2,
  },
  digitTxtStyle: {
    color: colors.white,
    fontSize: size.h5,
    fontFamily: 'Roboto-Regular',
  },
  timeLabelStyle: {
    color: colors.black,
    fontFamily: 'Roboto-Regular',
    fontSize: size.h5,
    marginTop: 0,
    marginLeft: 10,
  },
  separatorStyle: {
    color: colors.black,
  },
  countdownText: {
    color: colors.black,
    fontFamily: 'Roboto-Regular',
    fontSize: size.h6,
  },
  innerContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  validProductText: {
    fontSize: 12,
    fontFamily: 'Roboto-Medium',
    color: colors.subtitle,
    marginBottom: -4,
  },
  ctaContainer: {
    width: 91,
    height: 22,
  },
  footerContainer: { flexDirection: 'row' },
  amountContainer: {
    position: 'absolute',
    top: -36,
    right: 16,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  amount: {
    fontSize: 24,
    fontFamily: 'Roboto-Bold',
    color: colors.green,
    lineHeight: 26,
  },
  chevron: { height: 16, width: 16, marginLeft: 4 },
  bottomButtonContainer: { alignItems: 'flex-end', flex: 1, marginBottom: -2 },
  validOnProduct: {
    fontSize: 10,
    fontFamily: 'Roboto-Medium',
    color: colors.subtitle,
    marginBottom: 6,
  },
});

export default inheritStyles;
