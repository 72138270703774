import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { View, BackHandler, Text, Image, StyleSheet } from 'react-native';
import MediaStyleSheet from 'react-native-media-query';
import debounce from 'lodash/debounce';
import forEach from 'lodash/forEach';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import { getCartItems, setContactLess } from '../../actions/ActionTypes';
import { setCurrentPaymentFlow } from '../../actions/PaymentActions';
import CartItems from '../../components/cart/CartItems';
import RemoteConfig from '../../utils/RemoteConfig';
import { REMOTE_CONFIG_KEYS } from '../../config/Constants';
import colors from '../../theme/Colors';
import { playlistStyle } from '../../components/media/Style';
import images from '../../theme/Images';
import { PaymentStyles } from '../../components/cart/styles';
import { TouchableOpacity } from 'react-native';
// import OrderSummaryShimmer from './OrderSummaryShimmer';
import ShimmerPlaceHolder from '../../libraries/ReactNativeShimmerPlaceholder';
import { getPriceText } from '../../utils/NumberUtility';
import { isPresent } from '../../utils/BooleanUtility';
import { getMinifiedImage } from '../../utils/ImageUtility';

class OrderSummary extends Component {
  constructor(props) {
    super(props);

    this.modalMessage = null;
    const { route } = props;
    this.source = route.params?.source ?? '';
    this.allowWithoutAcceptingContactless = null;
    this.debouncedNavigate = debounce(this.navigateToCart, 1000, {
      leading: true,
      trailing: false,
    });
    if (Utility.isAndroid()) {
      this.backhandler = BackHandler.addEventListener(
        'hardwareBackPress',
        this.onHardwareBackKeyPress,
      );
    }
  }

  componentDidMount() {
    this.modalMessage = RemoteConfig.getValue(
      REMOTE_CONFIG_KEYS.switch_contact_less_modal_message,
    );

    this.allowWithoutAcceptingContactless = RemoteConfig.getValue(
      REMOTE_CONFIG_KEYS.allow_without_contactless,
    );
    const { setCurrentPaymentFlow } = this.props;
    setCurrentPaymentFlow(true);
    Utility.furtherAction = {
      action: null,
      params: [],
      phone_number: '',
      isActionAsync: false,
    };
  }

  componentWillUnmount() {
    if (Utility.isAndroid()) {
      this.backhandler.remove();
    }
  }

  onHardwareBackKeyPress = () => {
    const { navigation } = this.props;
    navigation.goBack();
    return true;
  };

  showToast = (message, duration = 1000) => {
    this.toast.show(message, duration);
  };

  navigateToCart = () => {
    const { navigation } = this.props;
    navigation.replace('Cart', {
      source: 'order_summary',
    });
  };

  product = (props) => {
    const {
      product = {},
      product: {
        loyalty_plan: { card_image: cardImage = '' } = {},
        routine: {
          data: { attributes: { imageUrl: routineImageUrl = '' } = {} } = {},
        } = {},
      } = {},
    } = props;

    if (Utility.isBlank(product)) {
      return null;
    }
    const imageUrls = [
      product?.offer?.card_image,
      product?.product?.image_url,
      cardImage,
      routineImageUrl,
    ];
    const imageUrl = imageUrls.find((url) => url && isPresent(url)) || '';
    const imageWidth = orderSummaryStyles.productCircularImage.width;
    const imageHeight = orderSummaryStyles.productCircularImage.height;

    return (
      <Image
        source={{
          uri: getMinifiedImage(imageUrl, imageWidth, imageHeight),
        }}
        style={orderSummaryStyles.productCircularImage}
      />
    );
  };

  cartProducts = ({ cartItems }) => {
    const { navigation, addedToCartProduct } = this.props;
    if (Utility.isPresent(addedToCartProduct) && Utility.isBlank(cartItems)) {
      return (
        <CartItems
          cartItems={[
            { sku_id: addedToCartProduct.sku_id, product: addedToCartProduct },
          ]}
          navigation={navigation}
          showToast={this.showToast}
          hideAddToCart
        />
      );
    }

    if (cartItems.length > 1) {
      return (
        <View
          style={orderSummaryStyles.cartProductsContainer}
        >
          {cartItems.map((product, index) => {
            if (index === 6) {
              return (
                <View
                  style={orderSummaryStyles.productCircle}
                >
                  <Text
                    style={orderSummaryStyles.extraProductsText}
                  >{`+${cartItems?.length - 6}`}</Text>
                </View>
              );
            }
            if (index < 6) {
              return (
                <this.product
                  key={`more_options_${product.id}`}
                  product={product}
                  index={index}
                  totalProducts={cartItems.length}
                  id={product.id}
                  products={cartItems}
                />
              );
            }
            return null;
          })}
        </View>
      );
    }
    return (
      <CartItems
        cartItems={cartItems.slice(0, 1)}
        navigation={navigation}
        showToast={this.showToast}
        hideAddToCart
      />
    );
  };

  render() {
    const {
      cartItems,
      cartPricing = {},
      addedToCartProduct,
    } = this.props;

    const totalItems = [];
    forEach(cartItems, (cartItem) => {
      for (let i = 0; i < cartItem.quantity; i++) {
        totalItems.push(cartItem);
      }
    });

    return (
      <View
        style={orderSummaryStyles.container}
        onPress={this.navigateToCart}
      >
        <Text
          style={orderSummaryStyles.titleText}
          ellipsizeMode='tail'
          numberOfLines={1}
          dataSet={{ media: ids.title }}
        >
          Order Summary
        </Text>
        <TouchableOpacity
          style={orderSummaryStyles.background}
          onPress={this.debouncedNavigate}
        >
          <View
            style={orderSummaryStyles.productContainer}
          >
            <this.cartProducts cartItems={totalItems} />
            <View style={PaymentStyles.orderSummaryDivider} />

            <View
              style={orderSummaryStyles.priceContainer}
            >
              {Utility.isBlank(addedToCartProduct) ? (
                <Text
                  style={orderSummaryStyles.totalItemsText}
                >{`${totalItems.length} ${
                  totalItems.length > 1 ? 'items' : 'item'
                } · ${getPriceText(cartPricing.total)}`}</Text>
              ) : (
                <View style={{ flexDirection: 'row' }}>
                  <ShimmerPlaceHolder
                    autoRun
                    style={orderSummaryStyles.shimmerMargin}
                    colorShimmer={shimmerColor}
                  />
                  <ShimmerPlaceHolder
                    autoRun
                    style={orderSummaryStyles.shimmer}
                    colorShimmer={shimmerColor2}
                  />
                </View>
              )}

              <Image
                source={images.chevronRight}
                style={PaymentStyles.rightArrow}
              />
            </View>
          </View>
        </TouchableOpacity>
      </View>
    );
  }
}

OrderSummary.propTypes = {
  getCartItems: PropTypes.func,
  cartItems: PropTypes.array,
  cartPricing: PropTypes.object,
  address: PropTypes.array,
  authToken: PropTypes.string,
  cartPrompts: PropTypes.object,
  navigation: PropTypes.any,
  setContactLess: PropTypes.func,
  optForPlasticFreePackaging: PropTypes.bool,
  isContactLess: PropTypes.bool,
};

OrderSummary.defaultProps = {
  getCartItems: () => {},
  cartItems: [],
  cartPricing: {},
  address: [],
  authToken: '',
  cartPrompts: {},
  navigation: {},
  setContactLess: () => {},
  optForPlasticFreePackaging: true,
  isContactLess: false,
};

const mapStateToProps = (state) => ({
  cartPrompts: state.bag.cartPrompts,
  optForPlasticFreePackaging: state.UserAccountInfo.optForPlasticFreePackaging,
  isContactLess: state.bag.isContactLess,
  isOfferShimmerVisible: state.bag.isOfferShimmerVisible,
  address: state.UserAccountInfo.addresses,
  authToken: state.UserAccountInfo.authToken,
  addedToCartProduct: state.bag.addedToCartProduct,
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      getCartItems,
      setContactLess,
      setCurrentPaymentFlow,
    },
    dispatch,
  ),
});

export default withNavigation(
  connect(mapStateToProps, mapDispatchToProps)(OrderSummary),
);

const { ids, styles } = MediaStyleSheet.create({
  container: {
    backgroundColor: colors.background,
    paddingVertical: 12,
    marginTop: 4,
    borderRadius: 8,
  },
  title: {
    fontSize: 14,
    color: '#979BAA',
    fontFamily: 'Roboto-Medium',
    marginBottom: 12,
    paddingHorizontal: 12,
    '@media (min-width: 768px)': {
      fontSize: 16,
      fontWeight: '700',
      paddingLeft: 0,
    },
  },
});
const orderSummaryStyles = StyleSheet.create({
  background: {
    backgroundColor: colors.background,
  },
  container: {
    backgroundColor: colors.background,
    paddingVertical: 12,
    marginTop: 4,
    borderRadius: 8,
  },
  productCircularImage: {
    height: 36,
    marginRight: 8,
    width: 36,
    borderWidth: 1,
    borderRadius: 18,
    borderColor: colors.borderDark,
    resizeMode: 'cover',
    backgroundColor: colors.white,
    overflow: 'hidden',
    flexDirection: 'row',
  },
  shimmerMargin: { width: 54, marginRight: 12 },
  shimmer: { width: 54 },
  priceContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 12,
  },
  productContainer: {
    backgroundColor: colors.white,
    paddingVertical: 12,
    paddingTop: 12,
    borderRadius: 8,
  },
  totalItemsText: {
    fontSize: 14,
    fontFamily: 'Roboto-Bold',
    color: colors.foxyBlack,
  },
  titleText: {
    fontSize: 14,
    color: '#979BAA',
    fontFamily: 'Roboto-Medium',
    marginBottom: 12,
    paddingHorizontal: 12,
  },
  cartProductsContainer: {
    flexDirection: 'row',
    marginBottom: 16,
    paddingHorizontal: 12,
    alignItems: 'center',
  },
  productCircle: {
    height: 36,
    marginRight: 8,
    width: 36,
    borderWidth: 1,
    borderRadius: 18,
    borderColor: colors.borderDark,
    resizeMode: 'cover',
    overflow: 'hidden',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.white,
  },
  extraProductsText: {
    ...playlistStyle.productsUsedText,
    color: colors.foxyBlack,
  },
});

const shimmerColor = ['#fafafa', '#cccccc', '#fafafa'];
const shimmerColor2 = ['#fafafa', '#eeeeee', '#fafafa'];
