import React, { PureComponent } from 'react';
import { View, Animated, Easing } from 'react-native';
import {
  playlistStyle,
  verticalPlaylistCardHeight,
  verticalPlaylistCardWidth,
} from './Style';
import { MEDIA } from '../../config/LayoutConstants/ContentMediaConfig';
import Utility from '../../utils/Utility';
import FastImageView from '../FastImageView';
import { getMinifiedImage } from '../../utils/ImageUtility';

class VerticalMediaImage extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      fadeAnimation: new Animated.Value(1),
      isFadeoutComplete: false,
    };

    const { imageUrl, cardWidth } = props;
    let newImageUrl = imageUrl;
    if (!Utility.isBlank(imageUrl)) {
      newImageUrl = getMinifiedImage(
        imageUrl,
        cardWidth,
        verticalPlaylistCardHeight,
      );
    }

    this.style = [
      playlistStyle.verticalPlaylistMediaImageContainer,
      { opacity: this.state.fadeAnimation },
    ];

    this.source = { uri: newImageUrl };
  }

  componentDidUpdate(prevProps) {
    const { isBuffering, showImage } = this.props;
    if (prevProps.isBuffering && !isBuffering) {
      this.fadeOut();
    } else if (showImage) {
      this.fadeIn();
    }
  }

  fadeIn = () => {
    this.setState({ isFadeoutComplete: false });
    Animated.timing(this.state.fadeAnimation, {
      toValue: 1,
      duration: 700,
      easing: Easing.fadeOut,
      useNativeDriver: true,
    }).start();
  };

  fadeOut = () => {
    Animated.timing(this.state.fadeAnimation, {
      toValue: 0,
      duration: 800,
      easing: Easing.fadeOut,
      useNativeDriver: true,
    }).start(this.postFadeOut);
  };

  postFadeOut = () => {
    this.setState({ isFadeoutComplete: true });
  };

  imageCard = () => {
    return (
      <FastImageView
        resizeMode='cover'
        source={this.source}
        style={playlistStyle.verticalPlaylistImageBackgroundStyle}
      />
    );
  };

  render() {
    const { showImage, isFadeoutComplete } = this.state;
    const { isBuffering, isContentPage = false } = this.props;
    const hideThumbnail =
      (!showImage && !isBuffering && isContentPage) || isFadeoutComplete;
    if (hideThumbnail) {
      return null;
    }

    return (
      <Animated.View style={this.style}>
        <this.imageCard />
      </Animated.View>
    );
  }
}

export default VerticalMediaImage;
