import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  View,
  Text,
  ActivityIndicator,
  Animated,
  TouchableOpacity,
  BackHandler,
  Platform,
  Linking,
} from 'react-native';
import StyleSheet from 'react-native-media-query';
import Toast from 'react-native-easy-toast';
import ShimmerPlaceHolder from '../../libraries/ReactNativeShimmerPlaceholder';
import moment from 'moment';
import FastImageView from '../../components/FastImageView';
import withNavigation from '../../utils/WithNavigation';
import TransparentHeader from '../../components/Product/TransparentHeader';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import colors from '../../theme/Colors';
import DockedHeader from '../../utils/DockedHeader';
import Utility from '../../utils/Utility';
import {
  getRoutineDetails,
  getRoutineSectionLists,
  routineOpened,
  saveCheckList,
} from '../../actions/RoutineActions';
import FoxyAlert from '../../components/camera/shared/FoxyAlert';
import size from '../../theme/Fonts';
import {
  incrementDecrementCart,
  incrementDecrementCartBeforeCall,
  createRoutineParticipation,
  getPaymentsDetailsForPartialCart,
  setActiveRoutineSlugs,
} from '../../actions/ActionTypes';
import AboutRoutineCard from '../../components/Routines/RoutineDetails/AboutRoutineCard';

import RoutineBenefitsCard from '../../components/Routines/RoutineDetails/RoutineBenefitsCard';
import RoutineArtistDetailsCard from '../../components/Routines/RoutineDetails/RoutineArtistDetailsCard';

import PreRoutineCardPlaceholder from '../../components/Routines/ShimmerPlaceholderCards/PreRoutineCardPlaceholder';
import { Vertical } from '../../components/layout';
import {
  LOGIN_MODAL_MESSAGES,
  ORIENTATION,
  CURRENCY_SYMBOL,
  SELFIE_STATE,
  WWW_WEB_URL,
  NOTIFICATION_REQUEST_MODAL_TYPE,
} from '../../config/Constants';

import SelfieUtilities from '../../utils/SelfieUtilities';

import RoutineCheckListCard from '../../components/Routines/RoutineDetails/RoutineCheckListCard';
import { setPhoneNumberModalInfo } from '../../actions/LoginActions';
import { List } from '../List';
import {
  AnalyticsManager,
  EventParameterKey,
  EventParameterValue,
  EventType,
} from '../../analytics';
import RoutinesUtility from '../../components/Routines/RoutinesUtility';
import images from '../../theme/Images';
import ErrorBoundary from '../../components/shared/ErrorBoundary';
import { StaticNavigationHeader } from '../../components/shared';
import animations from '../../theme/Animations';
import NavigationService from '../../navigator/NavigationService';
import {
  goBackWithFallbackHome,
  navigateToScreen,
} from '../../utils/NavigationUtility';
import { getRealScreenHeight, getScreenWidth } from '../../utils/LayoutUtility';
import {
  isDesktop,
  isNative,
  isPresent,
  isWeb,
  isWebMobile,
  isAndroid,
} from '../../utils/BooleanUtility';
import { ScrollView } from 'react-native-gesture-handler';
import WhatsAppCard from './WhatsappCard';
import { getFirebasePerformanceTrace } from '../../utils/PerfUtility';
import { getMinifiedImage } from '../../utils/ImageUtility';
import _ from 'lodash';
import withProfiledNavigation from '../../utils/withProfiledNavigation';
import { ReactNavigationPerformanceView } from '@shopify/react-native-performance-navigation';
import { getNavigationSource } from '../../utils/PerfUtility';
import PermissionsUtility from '../../utils/PermissionsUtility';
import DynamicLinkManager from '../../utils/DynamicLinkManager';
import { addContinueAction } from '../../actions/FoxyEdgeAction';

var JSONAPIDeserializer = require('jsonapi-serializer').Deserializer;

const DEFAULT_ROUTINE_DAYS = 1;

class PreRoutineDetails extends Component {
  constructor(props) {
    super(props);
    const navData = this.props.route.params?.itemData;
    this.state = {
      scrollY: new Animated.Value(0),
      itemData: Utility.isPresent(navData) ? navData : {},
      isLoading: Utility.isPresent(navData) ? false : true,
      listData: {},
      addToCartLoading: false,
      checkList: [],
      serverError: false,
      retryLoading: false,
      connectionError: false,
      isVisible: false,
      partialCartDetailsLoading: false,
    };
    this.originalListData = {};
    this.canStartFirstTimeOpenFlow = false;
    this.trace = getFirebasePerformanceTrace(SCREEN_CONSTANTS.PRE_ROUTINE);
    this.trace.start();
    if (Utility.isBlank(navData)) {
      this.fetchDetails();
    } else {
      this.getSectionLists();
    }
    if (Utility.isAndroid()) {
      this.backhandler = BackHandler.addEventListener(
        'hardwareBackPress',
        this.onBackPress,
      );
    }
    this.navigationSource = getNavigationSource(this, this.props);
  }

  componentDidMount() {
    AnalyticsManager.setCurrentScreen(
      EventParameterValue.SCREEN_NAME.MY_VIDEOS,
    );

    const { navigation } = this.props;

    this.unsubscribe = navigation.addListener('focus', () => {
      Utility.setStatusbarTranslucent();
      this.fetchDetails();
    });
    this.unsubscribeBlur = navigation.addListener('blur', () => {
      Utility.setStatusBarWhite();
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      itemData: {
        id: prevId = '',
        isUserSubscribed: isUserSubscribedPrev = false,
      },
    } = prevState;
    const {
      itemData: { id: currentId = '', isUserSubscribed = false },
    } = this.state;

    if (prevId !== currentId || isUserSubscribedPrev !== isUserSubscribed) {
      const { authToken } = this.props;
      // This condition is to start selfie/checklist flow for the user. This is to be done only once
      // when routine is opened for the first time and the user has subscription.
      if (
        !this.isRoutineOpenedEarlier(currentId) &&
        isUserSubscribed &&
        Utility.isPresent(authToken)
      ) {
        const { routineOpened } = this.props;

        routineOpened(currentId);
        this.canStartFirstTimeOpenFlow = true;
      }
    }
  }

  componentWillUnmount() {
    this.unsubscribe();
    this.unsubscribeBlur();
    if (Utility.isAndroid()) {
      this.backhandler?.remove();
    }
  }

  showNotificationModal = (show, showNotification) => {
    const { navigation } = this.props;
    navigation.push('NotificationModal', {
      showNotificationPrompt: showNotification,
      type: NOTIFICATION_REQUEST_MODAL_TYPE.ROUTINES,
    });
  };

  showToast = (message) => {
    this.toast.show(message, 1000);
  };

  displayNotificationRequestModal = () => {
    const { lastNotificationModalDisplayTime, initial_app_opened_at } =
      this.props;

    if (
      !PermissionsUtility.shouldShowNotificationModalForCurrentSession(
        SCREEN_CONSTANTS.PRE_ROUTINE,
      )
    ) {
      return;
    }

    Utility.canDisplayNotificationRequestModal(
      lastNotificationModalDisplayTime,
      'pre_routine',
      (canDisplay, showNotificationPrompt) => {
        if (canDisplay) {
          this.showNotificationModal(true, showNotificationPrompt);
          PermissionsUtility.setNotificationModalVisibilityForCurrentSession(
            SCREEN_CONSTANTS.PRE_ROUTINE,
            false,
          );
        }
      },
      initial_app_opened_at,
      true,
    );
  };

  openCheckListPage = (checkList) => {
    const { navigation } = this.props;
    const {
      itemData: { id = '' },
    } = this.state;
    navigation.navigate('RoutineCheckList', {
      routineCheckList: checkList,
      onCheckListItemsTap: this.onCheckListItemsTap,
      id: id,
    });
  };

  openSelfiePage = (checkList) => {
    const { navigation } = this.props;
    const { itemData: { isUserSubscribed = false } = {} } = this.state;
    const screenName = isUserSubscribed
      ? SCREEN_CONSTANTS.PRE_ROUTINE
      : SCREEN_CONSTANTS.SUBSCRIBE_ROUTINE;

    SelfieUtilities.navigateToTakeSelfieWithAnalytics(screenName, navigation, {
      routineCheckList: isUserSubscribed ? checkList : null,
      onCheckListItemsTap: this.onCheckListItemsTap,
    });
  };

  isFacialAnalysisSuccess = () => {
    const {
      facialAnalysis: { analysis_stage = null },
    } = this.props;
    if (analysis_stage === SELFIE_STATE.COMPLETED) {
      return true;
    }
    return false;
  };

  isSelfieAttributesAvailable = () => {
    const { profile, facialAnalysis: { facialProperties = [] } = {} } =
      this.props;
    return (
      Utility.isPresent(profile.selfie_image_url) ||
      this.isFacialAnalysisSuccess() ||
      Utility.isPresent(facialProperties)
    );
  };

  isRoutineOpenedEarlier = (id) => {
    const { viewedRoutines } = this.props;
    if (Utility.isBlank(id)) return false;
    return viewedRoutines.includes(id);
  };

  fetchDetails = () => {
    const { getRoutineDetails, navigation, route } = this.props;
    const slug = route.params?.slug;
    getRoutineDetails(slug, this.getRoutineDetailsCallback);
  };

  getRoutineDetailsCallback = (success, response) => {
    if (!this.traceStopped) {
      this.trace.stop();
      this.traceStopped = true;
    }
    if (!success) {
      if (response.errorType === 'api_failure') {
        this.setState({
          loading: false,
          serverError: response.status === 'server_error',
          // connection_error to be added on Routines page. Handling is added here
          connectionError: response.status === 'connection_error',
        });
      } else {
        this.setState({
          loading: false,
          serverError: false,
          connectionError: true,
        });
      }
      Utility.setStatusBarWhite();
      return;
    }
    new JSONAPIDeserializer({
      typeAsAttribute: false,
      pluralizeType: true,
      keyForAttribute: 'camelCase',
    })
      .deserialize(response)
      .then((data) => {
        const {
          startsAt = '',
          endsAt = '',
          isUserSubscribed = false,
          name = '',
          id = '',
        } = data;
        this.setState({
          itemData: data,
          connectionError: false,
          retryLoading: false,
        });
        const { navigation, route, membership_cohort = '' } = this.props;
        const source = route.params?.source;

        const hideNavigation =
          source === SCREEN_CONSTANTS.DURING_ROUTINE ||
          source === SCREEN_CONSTANTS.POST_ROUTINE;
        const isExpired =
          new Date() > RoutinesUtility.getLocalTimeFromUTC(endsAt);

        const isActive =
          RoutinesUtility.getLocalTimeFromUTC(startsAt) < new Date();
        if (!hideNavigation && isUserSubscribed && isExpired) {
          this.navigateToRoutineSchedule(
            false,
            isUserSubscribed,
            isActive,
            isExpired,
          );
          return;
        }
        if (!hideNavigation && isUserSubscribed && isActive) {
          this.navigateToRoutineSchedule(true, isUserSubscribed, isActive);
          return;
        }
        const page = isUserSubscribed
          ? SCREEN_CONSTANTS.PRE_ROUTINE
          : SCREEN_CONSTANTS.SUBSCRIBE_ROUTINE;
        AnalyticsManager.logEvent(EventType.pageLoad.PAGE_LOAD, {
          [EventParameterKey.PAGE]: page,
        });
        AnalyticsManager.logEvent(EventType.routine.ROUTINE_VIEW, {
          [EventParameterKey.ROUTINES.PAGE_TYPE]: page,
          [EventParameterKey.ROUTINES.ROUTINE_NAME]: name,
          [EventParameterKey.ROUTINES.ROUTINE_ID]: id,
          [EventParameterKey.ROUTINES.MEMBERSHIP_COHORT]: membership_cohort,
        });
        this.displayNotificationRequestModal();
        this.setLoading(false);
        this.getSectionLists();
      });
  };

  getSectionLists = () => {
    const { getRoutineSectionLists, navigation, route } = this.props;
    const slug = route.params?.slug;
    getRoutineSectionLists(slug, (listDataSuccess, listDataResponse) => {
      if (!this.traceStopped) {
        this.trace.stop();
        this.traceStopped = true;
      }
      if (!listDataSuccess) {
        Utility.setStatusBarWhite();
        return;
      }
      this.originalListData = JSON.parse(JSON.stringify(listDataResponse));
      this.setState({
        listData: listDataResponse,
        retryLoading: false,
        connectionError: false,
      });
      this.createCheckListData(listDataResponse);
    });
  };

  setLoading = (isLoading) => {
    this.setState({
      isLoading: isLoading,
      retryLoading: isLoading,
    });
  };

  createCheckListData = (listDataResponse) => {
    const {
      itemData: { id: listId },
    } = this.state;

    if (Utility.isBlank(listId)) return;

    const { routine_products: { objects: listObjects = [] } = {} } =
      listDataResponse;

    const { savedCheckLists = {} } = this.props;

    const productsList = listObjects.filter(
      (item) => item.content === 'product',
    );

    if (Utility.isBlank(savedCheckLists)) {
      this.createCheckList(listId, productsList);
    } else {
      const savedCheckList = savedCheckLists[listId];
      if (
        Utility.isBlank(savedCheckList) ||
        productsList.length !== savedCheckList.length
      ) {
        this.createCheckList(listId, productsList);
      } else {
        console.tron.log('sorting list items');
        this.sortListObjects(savedCheckList);
      }
    }
  };

  createCheckList = (listId, productsList) => {
    const { saveCheckList } = this.props;

    const checkListItems = productsList.map((item) => ({
      name: item.name,
      id: item.id,
      isChecked: false,
    }));
    if (Utility.isPresent(checkListItems)) {
      saveCheckList(listId, checkListItems);
      this.startFirstTimeOpenFlow(listId, checkListItems);
    }
  };

  startFirstTimeOpenFlow = (listId, checkList) => {
    if (this.canStartFirstTimeOpenFlow) {
      if (Utility.isPresent(checkList)) {
        this.canStartFirstTimeOpenFlow = false;
        if (this.isSelfieAttributesAvailable()) {
          this.openCheckListPage(checkList);
        } else {
          this.openSelfiePage(checkList);
        }
      }
    }
  };

  sortListObjects = (checkListData) => {
    const { routine_products: { objects: listObjects = [] } = {} } =
      this.originalListData;
    const tempListObjects = JSON.parse(JSON.stringify(listObjects));
    const productsList = tempListObjects.filter(
      (item) => item.content === 'product',
    );

    const tempProductsList = [];
    for (let index = 0; index < productsList.length; index += 1) {
      if (!checkListData[index].isChecked) {
        tempProductsList.push(productsList[index]);
      }
    }
    for (let index = 0; index < productsList.length; index += 1) {
      if (checkListData[index].isChecked) {
        tempProductsList.push(productsList[index]);
      }
    }

    const {
      listData,
      listData: { routine_products: { objects: displayListObjects = [] } = {} },
    } = this.state;

    for (
      let objectsIndex = 0, productsListIndex = 0;
      objectsIndex < displayListObjects.length &&
      productsListIndex < productsList.length;
      objectsIndex += 1
    ) {
      if (displayListObjects[objectsIndex].content === 'product') {
        displayListObjects[objectsIndex] = tempProductsList[productsListIndex];
        productsListIndex += 1;
      }
    }
    const updatedRoutineProducts = {
      ...listData.routine_products,
      objects: displayListObjects,
    };
    const updatedListData = {
      ...listData,
      routine_products: updatedRoutineProducts,
    };
    this.setState({
      listData: updatedListData,
    });
  };

  navigateToRoutineSchedule = (
    isRoutineActive,
    isUserSubscribed = false,
    isActive = false,
    isExpired = false,
  ) => {
    const { navigation, route } = this.props;
    const { params = {} } = route;
    const {
      extra: { entity_id: androidNotificationActivityId = '' } = {},
      entity_id: iosNotificationActivityId = '',
      slug = '',
    } = params;
    const notificationActivityId = isAndroid()
      ? androidNotificationActivityId
      : iosNotificationActivityId;
    const { itemData: { name = '', id = '', startsAt = '' } = {} } = this.state;
    navigateToScreen({
      navigation,
      type: 'replace',
      screen: 'DuringRoutineDetails',
      params: {
        slug,
        isRoutineActive,
        isUserSubscribed,
        isActive,
        isExpired,
        notificationActivityId,
      },
    });
  };

  subscribeMyRoutine = () => {
    const { createRoutineParticipation, navigation, route } = this.props;

    const slug = route.params?.slug;

    this.setState({ addToCartLoading: true });

    createRoutineParticipation(
      { routine_participation: { routine_slug: slug } },
      this.subscribeRoutineCallback,
    );
  };

  closeModalAndRefresh = () => {
    this.setState({
      isVisible: false,
    });
    setTimeout(() => {
      this.fetchDetails();
    }, 0);
  };

  subscribeRoutineCallback = (success, data) => {
    const {
      setActiveRoutineSlugs,
      route: { params: { slug = '' } = {} } = {},
    } = this.props;
    const { itemData: { isLive = false } = {} } = this.state;
    this.setState({ addToCartLoading: false });
    if (success) {
      this.fireEvent('Join Now');
      if (isLive) {
        setActiveRoutineSlugs([slug]);
      }
      this.setState({ isVisible: true });
    }
  };

  fireEvent = (ctaText = '') => {
    const {
      itemData: {
        id = '',
        name = '',
        skuId = '',
        isUserSubscribed = false,
        isLive = false,
        isFree = false,
        mrp = '',
      } = {},
    } = this.state;
    const { membership_cohort, route: { params: { slug = '' } = {} } = {} } =
      this.props;
    const screen = isUserSubscribed
      ? SCREEN_CONSTANTS.PRE_ROUTINE
      : SCREEN_CONSTANTS.SUBSCRIBE_ROUTINE;
    AnalyticsManager.logEvent(EventType.discoveryEvents.ITEM_ACTION, {
      [EventParameterKey.SCREEN_NAME]: screen,
      [EventParameterKey.ID]: id,
      [EventParameterKey.NAME]: name,
      [EventParameterKey.SLUG]: slug,
      [EventParameterKey.SKU_ID]: skuId,
      [EventParameterKey.ROUTINES.MEMBERSHIP_COHORT]: membership_cohort,
      [EventParameterKey.ROUTINES.OFFER_PRICE]: isFree ? 0 : mrp,
      [EventParameterKey.ROUTINES.ROUTINE_STATUS]: isLive,
      [EventParameterKey.CTA]: ctaText,
    });
  };

  openSubscribeRoutineModal = () => {
    const { navigation, route, previousScreen = '' } = this.props;
    const { slug = '' } = route.params;
    const { itemData: { cardImageUrl = '' } = {} } = this.state;
    navigation.navigate('SubscribeRoutineModal', {
      previousScreen,
      secondaryAction: this.onRoutineAddToCart,
      onRoutineAddToCart: this.onRoutineAddToCart,
      routineMissed: false,
      slug,
      imageUrl: cardImageUrl,
      screenType: 'Routine',
    });
  };

  onSubscribeClick = () => {
    const { membership_cohort } = this.props;
    const { itemData: { isFree = false } = {} } = this.state;
    if (membership_cohort === 'member' || isFree) {
      this.subscribeMyRoutine();
    } else {
      this.getPaymentsDetailsForPartialCart();
    }
  };

  onRoutineAddToCart = (usn, source = '') => {
    const {
      incrementDecrementCart,
      incrementDecrementCartBeforeCall,
      navigation,
      cartItems,
      membership_cohort,
    } = this.props;
    const {
      itemData: {
        id = '',
        name = '',
        slug = '',
        skuId = '',
        mrp = '',
        isUserSubscribed = false,
        startsAt = '',
      } = {},
    } = this.state;
    const screen = isUserSubscribed
      ? SCREEN_CONSTANTS.PRE_ROUTINE
      : SCREEN_CONSTANTS.SUBSCRIBE_ROUTINE;
    AnalyticsManager.logEvent(EventType.routine.ITEM_CLICK, {
      [EventParameterKey.SCREEN_NAME]: screen,
      [EventParameterKey.ID]: id,
      [EventParameterKey.NAME]: name,
      [EventParameterKey.SLUG]: slug,
      [EventParameterKey.SKU_ID]: skuId,
      [EventParameterKey.ROUTINES.MEMBERSHIP_COHORT]: membership_cohort,
    });
    if (membership_cohort === 'member') {
      this.subscribeMyRoutine();
      return;
    }
    const isActive = RoutinesUtility.getLocalTimeFromUTC(startsAt) < new Date();

    const isPresentInCart = cartItems.find((item) => item?.sku_id === skuId);
    if (isPresentInCart) {
      navigation.navigate(this.navigationSource, 'Cart', {
        source: SCREEN_CONSTANTS.SUBSCRIBE_ROUTINE,
      });
      return;
    }
    this.setState({
      addToCartLoading: true,
    });
    const data = {
      sku_id: skuId,
      quantity: 1,
      source: 'cart',
    };
    incrementDecrementCartBeforeCall(data);
    incrementDecrementCart(data, (response) => {
      this.setState({
        addToCartLoading: false,
      });
      if (response?.errors?.length > 0) {
        // this.toast.show(response.errors[0]);
        return;
      }
      navigation.navigate(this.navigationSource, 'Cart', {
        source: SCREEN_CONSTANTS.SUBSCRIBE_ROUTINE,
      });

      // if (source === 'product_view_modal') {
      //   this.onCloseProductModal();
      // }
    });
  };

  onCheckListItemsTap = (indexes) => {
    const {
      itemData: { id: listId },
    } = this.state;

    const { savedCheckLists = {}, saveCheckList } = this.props;

    const savedCheckList = savedCheckLists[listId];
    for (const index of indexes) {
      savedCheckList[index].isChecked = !savedCheckList[index].isChecked;
    }
    saveCheckList(listId, savedCheckList);
    this.sortListObjects(savedCheckList);
  };

  onShareTapped = () => {
    const {
      itemData: {
        id,
        skuId,
        name = '',
        isUserSubscribed = false,
        shareUrl = '',
      } = {},
    } = this.state;
    const message = isUserSubscribed
      ? `Check out this amazing routine I am participating in : ${name}. Let's join it together & help each other achieve our goals ✨`
      : `Check out this routine I am interested in -  ${name}`;
    const generalShareOption = {
      title: 'Foxy',
      message,
      url: shareUrl,
    };
    const screen = isUserSubscribed
      ? SCREEN_CONSTANTS.PRE_ROUTINE
      : SCREEN_CONSTANTS.SUBSCRIBE_ROUTINE;
    AnalyticsManager.logEvent(EventType.routine.ITEM_CLICK, {
      [EventParameterKey.ACTION]: 'share',
      [EventParameterKey.NAME]: name,
      [EventParameterKey.ID]: id,
      [EventParameterKey.SCREEN_NAME]: screen,
      [EventParameterKey.SKU_ID]: skuId,
    });

    Utility.onShareTapped(shareUrl, generalShareOption);
  };

  backgroundImage = () => {
    const {
      itemData: { name = '', cardImageUrl = '', bannerImageUrl = '' } = {},
      isLoading = false,
    } = this.state;
    if (isLoading) {
      return (
        <ShimmerPlaceHolder
          autoRun
          style={styles.backgroundImage}
          colorShimmer={['#fafafa', '#eeeeee', '#fafafa']}
        />
      );
    }
    if (isDesktop()) {
      return (
        <FastImageView
          source={Utility.getMinifiedImage(
            bannerImageUrl,
            Utility.getScreenWidth(),
            Utility.getScreenHeight() / 2,
          )}
          style={styles.desktopBackgroundImage}
        />
      );
    }
    return (
      <FastImageView
        source={getMinifiedImage(
          cardImageUrl,
          Utility.getScreenWidth(),
          Utility.getScreenWidth(),
        )}
        style={styles.backgroundImage}
      />
    );
  };

  conditionalRoutineScrollView = () => {
    const {
      itemData: { isUserSubscribed = false },
    } = this.state;
    return isUserSubscribed
      ? this.routineScrollViewContentSubsribed()
      : this.routineScrollViewContentUnsubscribed();
  };

  checkIfRoutineStartTimeExceeded = () => {
    const { itemData: { startsAt = '', endsAt = '' } = {} } = this.state;
    return RoutinesUtility.getLocalTimeFromUTC(endsAt) <= new Date();
  };

  onPressSubscribeRoutine = () => {
    const { authToken } = this.props;

    if (this.checkIfRoutineStartTimeExceeded()) {
      return;
    }
    if (Utility.isBlank(authToken)) {
      this.invokeLoginFlow();
    } else {
      this.onSubscribeClick();
    }
  };

  getPaymentsDetailsForPartialCart = () => {
    const { getPaymentsDetailsForPartialCart } = this.props;
    const { itemData: { skuId = '' } = {}, itemData } = this.state;
    const skuIds = isPresent(skuId) ? [skuId] : [];
    this.setState(
      {
        partialCartDetailsLoading: true,
      },
      () => {
        getPaymentsDetailsForPartialCart(
          skuIds,
          this.getPaymentsDetailsForPartialCartCallBack,
        );
      },
    );
  };

  getPaymentsDetailsForPartialCartCallBack = (success, response, error) => {
    if (success) {
      this.setState(
        {
          partialCartDetailsLoading: false,
        },
        () => {
          this.navigateToPaymentPage(response);
          this.fireEvent('Join');
        },
      );
    } else {
      this.showToast(error);
    }
  };

  navigateToPaymentPage = (response) => {
    const { navigation, route: { params: { slug = '' } = {} } = {} } =
      this.props;

    const { itemData: { skuId = '' } = {} } = this.state;
    navigation.navigate('Payments', {
      cartItems: response?.items,
      cartPricing: response?.pricing,
      skuId,
      previousScreen: 'PreRoutineDetails',
      slug,
      showPaymentsForPartialCart: true,
    });
  };

  invokeLoginFlow = () => {
    const { setPhoneNumberModalInfo } = this.props;
    setPhoneNumberModalInfo(Utility.getLoginModalInfo('DEFAULT'));
    NavigationService.renderOnboarding({
      onPressOutsideAction: this.goBack,
      parentSkipAction: this.goBack,
      furtherAction: this.onSubscribeClick,
    });
  };

  routineScrollViewContentUnsubscribed = () => {
    const {
      itemData: {
        name = '',
        mrp = '',
        description = '',
        participantCount = '',
        startsAt = '',
        endsAt = '',
        whatYouGet = '',
        artist: artistDetails = {},
        userAttributeAllowedValues = [],
        isUserSubscribed = false,
        routineDays = [],
        bannerImageUrl,
      },
      isLoading = false,
      listData: {
        hero_video_list: heroVideo,
        hero_video_list: {
          id: videoListId,
          display: videoListDisplay = '',
          objects: videoListObjects = [],
          content: videoListContent = '',
          type: videoType = '',
          size: videoListSize = '',
        } = {},
        routine_features: routineFeatures,
        routine_features: {
          id: featureListId,
          display: featureListDisplay = '',
          content: featureListContent = '',
          type: featureListType = '',
          size: featureListSize = '',
        } = {},
      },
    } = this.state;

    console.log('----------State', this.state);

    const routineStartTimeExceeded =
      RoutinesUtility.getLocalTimeFromUTC(startsAt) <= new Date();

    if (isLoading) {
      return <this.routineScrollViewContentPlaceholder />;
    }
    const tags = userAttributeAllowedValues.slice(0, 4);
    return (
      <View style={styles.listContainer} dataSet={{ media: ids.listContainer }}>
        <View style={styles.backgroundUnsubscribed}>
          {isDesktop() && <this.backgroundImage />}
          <Text style={styles.title}>{name}</Text>
          <this.Tags tags={tags} />
          <AboutRoutineCard
            description={description}
            participants={participantCount}
            mrp={mrp}
            startsAt={startsAt}
            duration={routineDays.length || DEFAULT_ROUTINE_DAYS}
            isUserSubscribed={isUserSubscribed}
          />
          <View style={styles.separater} />
          <List
            id={videoListId}
            orientation={ORIENTATION.VERTICAL}
            layout={videoListDisplay}
            listContent={videoListContent}
            itemData={heroVideo}
            size={videoListSize}
            refreshing={false}
            type={videoType}
            previousScreen={SCREEN_CONSTANTS.SUBSCRIBE_ROUTINE}
            shimmerDisplay={'mediaRail'}
          />
          <View style={styles.separater} />
          <RoutineArtistDetailsCard artist={artistDetails} />
          <WhatsAppCard
            imageUrl={bannerImageUrl}
            onClickJoinButton={this.onTapJoinWhatAppGroup}
            show={isDesktop()}
          />
          <View style={styles.separater} />
          <RoutineBenefitsCard htmlData={whatYouGet} />
          {!routineStartTimeExceeded && (
            <View>
              <List
                id={featureListId}
                orientation={ORIENTATION.VERTICAL}
                layout={featureListDisplay}
                listContent={featureListContent}
                itemData={routineFeatures}
                size={featureListSize}
                refreshing={false}
                type={featureListType}
                previousScreen={SCREEN_CONSTANTS.SUBSCRIBE_ROUTINE}
                isRoutineSubscribed={false}
                onPress={this.onPressSubscribeRoutine}
                shimmerDisplay={'featureRail'}
              />
            </View>
          )}
          <this.productLists />
        </View>
      </View>
    );
  };

  productLists = () => {
    const {
      itemData: { isUserSubscribed = false } = {},
      listData: {
        routine_products: {
          id: listId,
          display: listDisplay = '',
          objects: listObjects = [],
          content: listContent = '',
          size: listSize = '',
        } = {},
      },
      listData = {},
    } = this.state;
    if (Utility.isBlank(listObjects)) return null;
    const screenName = isUserSubscribed
      ? SCREEN_CONSTANTS.PRE_ROUTINE
      : SCREEN_CONSTANTS.SUBSCRIBE_ROUTINE;
    return (
      <>
        <FastImageView
          source={images.routines.products_recommended}
          style={styles.productBanner}
        />
        <Vertical
          id={listId}
          orientation={ORIENTATION.VERTICAL}
          display={listDisplay}
          content={listContent}
          item={listObjects}
          size={listSize}
          refreshing={false}
          previousScreen={screenName}
          listData={listData}
        />
      </>
    );
  };

  routineScrollViewContentSubsribed = () => {
    const {
      itemData: {
        isUserSubscribed,
        name = '',
        mrp = '',
        description = '',
        participantCount = '',
        startsAt = '',
        endsAt = '',
        artist: artistDetails = {},
        userAttributeAllowedValues = [],
        id: routineId,
        whatYouGet = '',
        routineDays = [],
        bannerImageUrl,
      },
      isLoading = false,
      listData: {
        hero_video_list: heroVideo,
        hero_video_list: {
          id: videoListId,
          display: videoListDisplay = '',
          content: videoListContent = '',
          type: videoType = '',
          size: videoListSize = '',
        } = {},
        routine_features: routineFeatures,
        routine_features: {
          id: featureListId,
          display: featureListDisplay = '',
          content: featureListContent = '',
          type: featureListType = '',
          size: featureListSize = '',
        } = {},
      },
    } = this.state;
    if (isLoading) {
      return <this.routineScrollViewContentPlaceholder />;
    }
    const { savedCheckLists = {} } = this.props;
    const currentCheckList = savedCheckLists[routineId];
    const tags = userAttributeAllowedValues.slice(0, 4);
    const screen = isUserSubscribed
      ? SCREEN_CONSTANTS.PRE_ROUTINE
      : SCREEN_CONSTANTS.SUBSCRIBE_ROUTINE;
    return (
      <View style={styles.listContainer} dataSet={{ media: ids.listContainer }}>
        <View style={styles.background}>
          {isDesktop() && <this.backgroundImage />}
          <Text style={styles.title}>{name}</Text>
          <this.Tags tags={tags} />
          <AboutRoutineCard
            description={description}
            participants={participantCount}
            mrp={mrp}
            startsAt={startsAt}
            duration={routineDays.length || DEFAULT_ROUTINE_DAYS}
            isUserSubscribed={isUserSubscribed}
          />
          <RoutineBenefitsCard htmlData={whatYouGet} />
          <RoutineCheckListCard
            listData={currentCheckList}
            onCheckListItemsTap={this.onCheckListItemsTap}
            routineId={routineId}
          />
          <this.productLists />

          <View style={styles.separater} />
          <RoutineArtistDetailsCard artist={artistDetails} />
          <WhatsAppCard
            imageUrl={bannerImageUrl}
            onClickJoinButton={this.onTapJoinWhatAppGroup}
            show={isDesktop()}
          />
          <List
            id={videoListId}
            orientation={ORIENTATION.VERTICAL}
            layout={videoListDisplay}
            listContent={videoListContent}
            itemData={heroVideo}
            size={videoListSize}
            refreshing={false}
            type={videoType}
            previousScreen={screen}
            shimmerDisplay={'mediaRail'}
          />
        </View>
      </View>
    );
  };

  routineScrollViewContentPlaceholder = () => {
    return (
      <View style={[styles.listContainer, { paddingBottom: 0 }]}>
        <View style={styles.background}>
          <ShimmerPlaceHolder
            autoRun
            style={styles.titlePlaceholder}
            colorShimmer={['#fafafa', '#eeeeee', '#fafafa']}
          />
          <PreRoutineCardPlaceholder />
          <View style={styles.separater} />
          <PreRoutineCardPlaceholder />
          <View style={styles.separater} />
        </View>
      </View>
    );
  };

  primaryButtonContainer = () => {
    const {
      itemData: { mrp = '', isFree = false, isUserSubscribed = false } = {},
      isLoading,
    } = this.state;
    const { membership_cohort: membershipCohort = '' } = this.props;
    const routineStartTimeExceeded = this.checkIfRoutineStartTimeExceeded();
    if (isLoading) {
      return null;
    }
    if (!isUserSubscribed) {
      if (routineStartTimeExceeded) return <View />;
      if (
        membershipCohort === 'member' &&
        !routineStartTimeExceeded &&
        !isFree
      ) {
        return <this.primaryButtonContainerForEdgeMember />;
      }
      if (isFree) {
        return <this.primaryButtonContainerForFreeRoutine />;
      }
      return <this.primaryButtonForActivatingEdge />;
    }
    return <this.joinWhatsapp />;
  };

  primaryButtonForActivatingEdge = () => {
    const { itemData: { mrp = '' } = {} } = this.state;
    return (
      <TouchableOpacity
        style={styles.primaryButtonStyle}
        onPress={this.onPressPrimaryAction}
      >
        <Text style={styles.priceFreeText}>Activate Edge</Text>
        <View style={styles.primaryButtonTextContainer}>
          <Text style={styles.primaryButtonText}>FREE</Text>
          <Text
            style={styles.priceTextLineThrough}
          >{`${CURRENCY_SYMBOL}${mrp}`}</Text>
        </View>
      </TouchableOpacity>
    );
  };

  joinWhatsapp = () => {
    return (
      <TouchableOpacity
        style={styles.JoinWhatsappButton}
        onPress={this.onWhatsappClick}
      >
        <FastImageView
          style={styles.whatsappIcon}
          source={images.whatsapp_green_icon}
        />
        <Text style={styles.buttonText}>Join Group</Text>
      </TouchableOpacity>
    );
  };

  onWhatsappClick = () => {
    const { itemData: { routineDiscussionGroups = [] } = {} } = this.state;
    this.fireEvent('Join Group');
    Linking.openURL(routineDiscussionGroups[0]?.inviteLink);
  };

  primaryButtonContainerForFreeRoutine = () => {
    const { itemData: { startsAt = '' } = {} } = this.state;
    const startsAtText = moment(startsAt).format('MMM D');
    return (
      <View style={styles.primaryButtonContainerForFreeRoutine}>
        <Text style={styles.textForFreeRoutine}>STARTS</Text>
        <Text style={styles.subTextForFreeRoutine}>{startsAtText}</Text>
      </View>
    );
  };

  primaryButtonContainerForEdgeMember = () => {
    return (
      <>
        <View style={styles.primaryButtonTextContainer}>
          <Text
            style={[
              styles.priceFreeText,
              { width: 140, color: colors.darkGrey },
            ]}
          >
            Benefit unlocked with e . d . g . e
          </Text>
        </View>
      </>
    );
  };

  onPressPrimaryAction = () => {
    const {
      route,
      authToken,
      address,
      date_of_birth,
      navigation,
      previousScreen = '',
      addContinueAction,
    } = this.props;
    const { slug = '' } = route.params;
    const { itemData: { cardImageUrl: imageUrl = '' } = {} } = this.state;
    const secondaryActionForRenewModal = 'https://www.foxy.in/loyalty_plans';

    this.fireEvent('Activate Edge');
    DynamicLinkManager.handleDynamicLink(
      secondaryActionForRenewModal,
      this.handleRouteFromLink,
    );

    if (Utility.isPresent(slug) && Utility.isPresent(imageUrl)) {
      addContinueAction(slug, imageUrl, 'Routine');
    }
  };

  handleRouteFromLink = (route, slug, path, extra, params) => {
    const { navigation } = this.props;
    setTimeout(() => {
      navigation.navigate(route, { slug, extra, params });
    }, 100);
  };

  closeModal = () => {
    const { navigation } = this.props;
    navigation.goBack();
  };

  secondaryButtonContainer = () => {
    const {
      isLoading,
      addToCartLoading,
      itemData: { mrp = '', isFree = false, isUserSubscribed = false } = {},
      partialCartDetailsLoading = false,
    } = this.state;
    const { membership_cohort: membershipCohort = '' } = this.props;
    if (isLoading) {
      return null;
    }
    const routineStartTimeExceeded = this.checkIfRoutineStartTimeExceeded();
    const buttonContainerStyle = routineStartTimeExceeded
      ? styles.subscriptionButtonDisabledContainer
      : styles.subscriptionButtonContainer;
    const secondaryButtonSubText =
      isFree || membershipCohort === 'member' ? 'FREE' : 'for';
    const priceTextStyle =
      isFree || membershipCohort === 'member'
        ? [styles.priceTextLineThrough, { color: colors.white }]
        : [styles.secondaryButtonTextContainer, { marginLeft: 6 }];
    if (!isUserSubscribed) {
      return (
        <TouchableOpacity
          style={buttonContainerStyle}
          onPress={this.onPressSubscribeRoutine}
          disabled={routineStartTimeExceeded}
        >
          {routineStartTimeExceeded && (
            <Text
              numberOfLines={2}
              style={[styles.subscriptionButtonText, { padding: 10 }]}
            >
              Subscriptions Closed
            </Text>
          )}
          {!addToCartLoading &&
            !partialCartDetailsLoading &&
            !routineStartTimeExceeded && (
              <View>
                <Text style={styles.subscriptionButtonText}>Join</Text>
                <View style={{ flexDirection: 'row' }}>
                  <Text style={styles.secondaryButtonTextContainer}>
                    {secondaryButtonSubText}
                  </Text>
                  <Text
                    style={priceTextStyle}
                  >{`${CURRENCY_SYMBOL}${mrp}`}</Text>
                </View>
              </View>
            )}
          {(addToCartLoading || partialCartDetailsLoading) && (
            <ActivityIndicator
              animating
              color={colors.white}
              size='small'
              style={styles.activityIndicator}
            />
          )}
        </TouchableOpacity>
      );
    }
    return (
      <TouchableOpacity
        style={styles.previewRoutineContainer}
        onPress={_.memoize(
          () => this.navigateToRoutineSchedule(true),
          () => [false],
        )}
        disabled={routineStartTimeExceeded}
      >
        <Text style={styles.previewButtonText}>Preview Routine</Text>
      </TouchableOpacity>
    );
  };

  subscribeRoutine = () => {
    const { isLoading } = this.state;
    if (isLoading) {
      return null;
    }
    return (
      <View style={styles.subscriptionContainer}>
        <this.primaryButtonContainer />
        <this.secondaryButtonContainer />
      </View>
    );
  };

  onTapJoinWhatAppGroup = () => {
    const { itemData: { routineDiscussionGroups = [] } = {} } = this.state;
    if (isPresent(routineDiscussionGroups)) {
      const inviteLink = routineDiscussionGroups[0]?.inviteLink;
      Linking.openURL(inviteLink);
    }
  };

  onChatTapped = () => {
    const { navigation } = this.props;
    const {
      itemData: {
        name = '',
        skuId = '',
        isUserSubscribed = false,
        routineDiscussionGroups = [],
        cardImageUrl = '',
        id: routineId = '',
      } = {},
    } = this.state;
    navigation.navigate('RoutineGroups', {
      routineGroups: routineDiscussionGroups,
      cardImageUrl,
      routineId,
    });
    const screen = isUserSubscribed
      ? SCREEN_CONSTANTS.PRE_ROUTINE
      : SCREEN_CONSTANTS.SUBSCRIBE_ROUTINE;
    AnalyticsManager.logEvent(EventType.routine.ITEM_CLICK, {
      [EventParameterKey.ACTION]: 'chat',
      [EventParameterKey.NAME]: name,
      [EventParameterKey.ID]: routineId,
      [EventParameterKey.SCREEN_NAME]: screen,
      [EventParameterKey.SKU_ID]: skuId,
    });
  };

  tag = ({ imageUrl = '', finalName = '' }) => {
    return (
      <View style={styles.tag}>
        {Utility.isPresent(imageUrl) && (
          <FastImageView
            source={getMinifiedImage(
              imageUrl,
              styles.tagIcon.width,
              styles.tagIcon.height,
            )}
            style={styles.tagIcon}
          />
        )}
        <Text style={styles.tagText}>{finalName}</Text>
      </View>
    );
  };

  Tags = ({ tags = [] }) => {
    if (Utility.isBlank(tags)) return null;
    return <View style={styles.tagsContainer}>{tags.map(this.tag)}</View>;
  };

  onBackPress = () => {
    const { navigation, route } = this.props;
    goBackWithFallbackHome(navigation);
    if (isPresent(route.params.shortSlug)) {
      navigation.replace('TabNavigator');
    } else if (
      route?.params?.source === SCREEN_CONSTANTS.POST_ROUTINE ||
      route?.params?.source === SCREEN_CONSTANTS.DURING_ROUTINE
    ) {
      navigation.pop(2);
    } else {
      navigation.goBack();
    }
    Utility.setStatusBarWhite();
    return true;
  };

  onPressReload = () => {
    this.setState(
      {
        retryLoading: true,
      },
      this.fetchDetails,
    );
  };

  render() {
    const {
      serverError,
      connectionError,
      retryLoading,
      isVisible,
      itemData: {
        name = '',
        isUserSubscribed = false,
        routineDiscussionGroups = [],
      } = {},
      itemData,
    } = this.state;
    const { authToken, navigation, route } = this.props;

    const screenName = isUserSubscribed
      ? SCREEN_CONSTANTS.PRE_ROUTINE
      : SCREEN_CONSTANTS.SUBSCRIBE_ROUTINE;
    return (
      <ReactNavigationPerformanceView
        screenName='pre_routine'
        interactive={isPresent(itemData)}
      >
        {connectionError && <StaticNavigationHeader title={name} />}
        <ErrorBoundary
          showServerError={serverError}
          onPressReload={this.onPressReload}
          connectionError={connectionError}
          loading={retryLoading}
          screen_name={'pre_routine_detail'}
          // hideHeader={false}
        >
          <View style={styles.container}>
            {!isDesktop() && (
              <TransparentHeader
                scrollY={this.state.scrollY}
                screenName={screenName}
                goBack={this.onBackPress}
                onShareTapped={this.onShareTapped}
                onChatTapped={this.onChatTapped}
                hideChatButton={
                  routineDiscussionGroups.length === 0 ||
                  Utility.isBlank(authToken)
                }
              >
                <DockedHeader name={'Routine Details'} />
              </TransparentHeader>
            )}
            {isNative() && <this.backgroundImage />}
            <ScrollView
              onScroll={Animated.event([
                { nativeEvent: { contentOffset: { y: this.state.scrollY } } },
              ])}
              style={styles.scrollView}
              showsVerticalScrollIndicator={false}
              bounces={false}
            >
              <>
                {isWebMobile() && <this.backgroundImage />}
                <this.conditionalRoutineScrollView />
              </>
            </ScrollView>
            <this.subscribeRoutine />
            <Toast
              style={styles.toast}
              ref={(ref) => {
                this.toast = ref;
              }}
            />
            <FoxyAlert
              isVisible={isVisible}
              hideSecondButton
              alertBoxTitle={'CONGRATS'}
              alertMessage={'Routine subscribed successfully'}
              firstButtonTitle={'View my routine'}
              firstButtonOnPress={this.closeModalAndRefresh}
              onTapOutside={this.closeModalAndRefresh}
              autoWrapContent
              firstButtonTextColor={colors.cta.lightBlue}
              showJson
              animation={animations.tick_animation}
              animationStyle={{
                height: 100,
                width: 100,
                alignSelf: 'center',
                marginBottom: 12,
              }}
            />
          </View>
        </ErrorBoundary>
      </ReactNavigationPerformanceView>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      getRoutineDetails,
      incrementDecrementCart,
      incrementDecrementCartBeforeCall,
      getRoutineSectionLists,
      routineOpened,
      saveCheckList,
      setPhoneNumberModalInfo,
      createRoutineParticipation,
      addContinueAction,
      getPaymentsDetailsForPartialCart,
      setActiveRoutineSlugs,
    },
    dispatch,
  ),
});

const mapStateToProps = (state) => ({
  viewedRoutines: state.routines.viewedRoutines,
  savedCheckLists: state.routines.checkList,
  profile: state.UserAccountInfo.profile,
  facialAnalysis: state.UserAccountInfo.facialAnalysis,
  cartItems: state.bag.cartItems,
  authToken: state.UserAccountInfo.authToken,
  membership_cohort: state.todayDeals.membership_cohort,
  lastNotificationModalDisplayTime:
    state.UserAccountInfo.lastNotificationModalDisplayTime,
  initial_app_opened_at: state.UserAccountInfo.initial_app_opened_at,
  address: state.UserAccountInfo.addresses,
  date_of_birth: state.UserAccountInfo.profile.date_of_birth,
});

export default withProfiledNavigation(
  connect(mapStateToProps, mapDispatchToProps)(PreRoutineDetails),
);

const { ids, styles } = StyleSheet.create({
  container: {
    flex: 1,
    width: getScreenWidth(),
    alignSelf: 'center',
  },
  tag: {
    flexDirection: 'row',
    paddingVertical: 4,
    borderRadius: 12,
    margin: 4,
    paddingHorizontal: 12,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.white,
  },
  tagsContainer: {
    // flex: 1,
    width: '100%',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    marginBottom: 12,
  },
  tagIcon: { height: 14, width: 14, marginRight: 8 },
  tagText: {
    fontFamily: 'Roboto',
    fontSize: size.h3_5,
    color: colors.green,
  },
  backgroundImage: {
    width: Utility.getScreenWidth(),
    height: Utility.getScreenWidth(),
    resizeMode: 'contain',
  },
  desktopBackgroundImage: {
    width: Utility.getScreenWidth(),
    height: Utility.getScreenHeight() / 2,
    resizeMode: 'contain',
  },
  listContainer: {
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    width: Utility.getScreenWidth(),
    marginTop: isWeb() ? 0 : Utility.getScreenWidth() - 30,
    paddingBottom: 70,

    '@media (min-width: 768px)': {
      marginTop: 0,
    },
  },
  background: {
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    paddingHorizontal: 12,
    paddingTop: 12,
    height: '100%',
    backgroundColor: colors.background,
  },
  backgroundUnsubscribed: {
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    paddingHorizontal: 12,
    paddingBottom: 12,
    paddingTop: 12,
    height: '100%',
    backgroundColor: colors.background,
  },
  scrollView: {
    height: Utility.getScreenHeight() - (Utility.isIOS() ? 0 : 12),

    zIndex: 1,
    ...Platform.select({
      web: {},
      default: {
        position: 'absolute',
      },
    }),
  },
  header: {
    position: 'absolute',
    top: 0,
  },
  title: {
    color: colors.foxyBlack,
    fontFamily: 'Roboto-Bold',
    alignSelf: 'center',
    fontSize: 24,
    marginBottom: 12,
  },
  subscriptionContainer: {
    bottom: 0,
    zIndex: 2,
    backgroundColor: colors.white,
    paddingHorizontal: 12,
    paddingVertical: 8,
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    width: '100%',
    ...Platform.select({
      android: { position: 'absolute', paddingBottom: 0 },
      ios: { position: 'absolute', paddingBottom: 22 },
      web: { position: 'absolute', paddingBottom: 10 },
    }),
  },
  priceText: {
    color: colors.foxyBlack,
    fontSize: size.h2,
    fontFamily: 'Roboto-Medium',
  },
  priceTextNormal: {
    color: colors.foxyBlack,
    fontSize: size.h3,
    fontFamily: 'Roboto-Regular',
  },
  priceTextLineThrough: {
    color: colors.darkGrey,
    fontSize: size.h3,
    fontFamily: 'Roboto-Medium',
    textDecorationLine: 'line-through',
    marginLeft: 8,
  },
  priceFreeText: {
    color: colors.green,
    fontSize: size.h2,
    fontFamily: 'Roboto-Medium',
  },
  priceTextSubtitle: {
    color: colors.subtitle,
    fontSize: size.h3_5,
    fontFamily: 'Roboto-Regular',
  },
  subscriptionButtonContainer: {
    width: Utility.getScreenWidth() * 0.5 - 16,
    alignItems: 'center',
    backgroundColor: colors.green,
    borderRadius: 6,
    paddingVertical: 7,
  },

  previewRoutineContainer: {
    width: Utility.getScreenWidth() * 0.5 - 16,
    alignItems: 'center',
    paddingVertical: 13,
    backgroundColor: colors.white,
    borderRadius: 6,
    borderWidth: 1,
    borderColor: colors.green,
  },

  subscriptionButtonDisabledContainer: {
    width: Utility.getScreenWidth() * 0.5 - 16,
    alignItems: 'center',
    paddingVertical: 4,
    backgroundColor: colors.darkGrey,
    borderRadius: 6,
    height: 50,
  },
  subscriptionButtonText: {
    color: colors.white,
    fontSize: size.h3,
    fontFamily: 'Roboto-Medium',
  },

  previewButtonText: {
    color: colors.green,
    fontSize: size.h2,
    fontFamily: 'Roboto-Medium',
  },
  separater: {
    height: 12,
  },
  titlePlaceholder: {
    height: 28,
    width: '80%',
    alignSelf: 'center',
    marginBottom: 12,
  },
  productListsTitle: {
    color: colors.green,
    fontFamily: 'Roboto-Bold',
    fontSize: size.h1,
    marginTop: 18,
  },
  productListsSubtitle: {
    color: colors.foxyBlack,
    fontFamily: 'Roboto-Regular',
    fontSize: size.h3,
    marginTop: 4,
  },
  productBanner: {
    resizeMode: 'contain',
    borderRadius: 4,
    height: 100,
    marginVertical: 12,
    ...Platform.select({
      web: {
        height: isDesktop() ? 200 : 100,
        resizeMode: '',
      },
      default: { flex: 1 },
    }),
  },

  emptyView: {
    height: 150,
  },
  primaryButtonText: {
    fontSize: size.h3,
    color: colors.green,
    alignSelf: 'center',
    textAlign: 'center',
  },
  primaryButtonStyle: {
    width: Utility.getScreenWidth() * 0.5 - 16,
    alignItems: 'center',
    borderRadius: 6,
    borderWidth: 1,
    borderColor: colors.green,
    borderStyle: 'solid',
    justifyContent: 'center',
    alignContent: 'center',
    paddingVertical: 4,
    height: 47,
  },
  actionIconStyle: {
    height: 24,
    width: 24,
    marginRight: 6,
  },
  secondaryButtonTextContainer: {
    color: colors.white,
    fontSize: size.h3,
    fontFamily: 'Roboto-Medium',
    textAlign: 'center',
  },
  primaryButtonTextContainer: {
    flexDirection: 'row',
  },
  image: { height: 35, width: 35, alignSelf: 'center' },
  primaryButtonContainerForFreeRoutine: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  textForFreeRoutine: {
    color: colors.darkGrey,
    fontSize: size.h4,
    fontFamily: 'Roboto-Medium',
    textAlign: 'center',
  },
  subTextForFreeRoutine: {
    color: colors.black,
    fontSize: size.h2,
    fontFamily: 'Roboto-Medium',
    textAlign: 'center',
  },
  JoinWhatsappButton: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: Utility.getScreenWidth() * 0.5 - 16,
    borderRadius: 6,
    borderWidth: 1,
    borderStyle: 'solid',
    alignContent: 'center',
    paddingVertical: 4,
    height: 50,
    backgroundColor: colors.black,
  },
  whatsappIcon: {
    height: 16,
    width: 16,
    tintColor: colors.white,
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
  },
  buttonText: {
    fontSize: size.h2,
    color: colors.white,
    alignSelf: 'center',
    textAlign: 'center',
    fontFamily: 'Roboto-Medium',
    paddingHorizontal: 8,
  },
  activityIndicator: {
    paddingVertical: 9,
  },
  toast: { position: 'absolute', top: 50 },
});
