import React, { Component } from 'react';
import { BackHandler } from 'react-native';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import WithNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import List from '../List/List';

class ListTabPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      itemData: {},
      isLoading: false,
      listPage: 0,
      isRefreshing: false,
    };
    if (Utility.isAndroid()) {
      this.backhandler = BackHandler.addEventListener(
        'hardwareBackPress',
        this.onHardwareBackKeyPress,
      );
    }
  }

  componentWillUnmount() {
    if (Utility.isAndroid()) {
      this.backhandler.remove();
    }
  }

  onHardwareBackKeyPress = () => {
    const { navigation } = this.props;
    navigation.navigate('Feed');
    return true;
  };

  render() {
    const { navigation, listSlug = '' } = this.props;
    return (
      <List
        slug={listSlug}
        navigation={navigation}
        previousScreen={SCREEN_CONSTANTS.LIST_TAB}
      />
    );
  }
}

export default WithNavigation(ListTabPage);
