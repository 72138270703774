import React, { useState, useEffect } from 'react';
import {
  StyleSheet,
  Text,
  View,
  Image,
  FlatList,
  TouchableWithoutFeedback,
  Linking,
  Easing,
  Animated,
} from 'react-native';
import Config from '../../../libraries/ReactNativeConfig';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import LottieView from 'lottie-react-native';
import images from '../../../theme/Images';
import colors from '../../../theme/Colors';
import Counter from '../../../helpers/Counter';
import Utility from '../../../utils/Utility';
import { PaymentStyles } from '../../cart/styles/PaymentStyles';
import CartPricingData from '../../cart/CartPricing';
import FoxyShadowButton from '../../../lib/FoxyShadowButton';
import { CURRENCY_SYMBOL } from '../../../config/Constants';
import {
  addressStyles,
  cartItemsStyles,
  contactUsStyles,
  deliveryStatus,
  failureActions,
  failureStatus,
  paymentStatusMessages,
  paymentValidateStyles,
  paymentVerifyingStyles,
  styles,
  successStatus,
  validationActions,
  addressIds,
} from './styles';
import animations from '../../../theme/Animations';
import FastImageView from '../../FastImageView';
import AddressUtility from '../../../utils/AddressUtility';
import AppConfig from '../../../config/AppConfig';
import { getStoreRef } from '../../../store/StoreUtility';
import { isDesktop, isPresent, isWeb, isBlank } from '../../../utils/BooleanUtility';
import { getMinifiedImage } from '../../../utils/ImageUtility';

const Message = (props) => {
  const {
    heading,
    subheading,
    orderid,
    showTimer,
    time_left,
    changePaymentstate,
  } = props;
  return (
    <>
      {showTimer && (
        <View style={paymentStatusMessages.container}>
          <Image
            resizeMode='contain'
            style={styles.stopWatch}
            source={images.paymentStatus.stopWatch}
          />
          <Counter
            size={14}
            until={time_left}
            onFinish={_.memoize(
              () => changePaymentstate('timeout'),
              () => ['timeout'],
            )}
            endsAt={time_left}
            digitStyle={paymentStatusMessages.digitStyle}
            style={paymentStatusMessages.counterContainer}
            digitTxtStyle={paymentStatusMessages.counterDigitText}
            separatorStyle={paymentStatusMessages.counterSaperator}
            timeToShow={['M', 'S']}
            timeLabels={{ m: null, s: null }}
            showSeparator
            withoutBlock
          />
        </View>
      )}
      <View style={paymentStatusMessages.headingSubheadingContainer}>
        <Text style={paymentStatusMessages.heading}>{heading}</Text>
        <Text style={paymentStatusMessages.subHeading}>{subheading}</Text>
      </View>
      <View style={paymentStatusMessages.orderIdContainer}>
        <Text style={paymentStatusMessages.orderId}>
          {` Order Id: #${orderid}`}
        </Text>
      </View>
    </>
  );
};

const renderUPIPaymentSteps = (steps, details) =>
  _.map(steps, (stepForPayment) => {
    const appName = details?.name || '';
    const merchantName = details?.merchant_name || '';

    const step = stepForPayment
      .replace('${app_name}', appName.toString())
      .replace('${app_name}', appName.toString())
      .replace('${merchant_name}', merchantName.toString());

    return (
      <View style={paymentVerifyingStyles.paymentStepsContainer}>
        <View style={paymentVerifyingStyles.stepperContainer}>
          <View style={paymentVerifyingStyles.stepper} />
        </View>

        <View style={paymentVerifyingStyles.paymentStep}>
          <Text style={paymentVerifyingStyles.paymentStepText}>{step}</Text>
        </View>
      </View>
    );
  });

export function PaymentVerifying({
  paymentDetails: { attributes = {} } = {},
  paymentCurrentStatus: { order_id = '', payment_method = '' } = {},
  virtual_bank_account_id = '',
  details,
  changePaymentstate,
}) {
  const stepsForPayment = _.filter(attributes, (attr, key) => {
    return key.includes('step');
  });
  const isPaymentMethodUPI =
    payment_method === 'upi' || payment_method === 'intent';
  const showUPIId = isPaymentMethodUPI && isPresent(virtual_bank_account_id);
  return (
    <View style={paymentVerifyingStyles.container}>
      <FastImageView
        source={details?.icon || images.payment.onlinePayment.uri}
        resizeMode={'contain'}
        width={100}
        style={paymentVerifyingStyles.iconContainer}
      />
      {isPaymentMethodUPI && (
        <View>
          {renderUPIPaymentSteps(stepsForPayment, details)}
          <View style={paymentVerifyingStyles.verticalLineForStepper} />
        </View>
      )}
      <View style={paymentVerifyingStyles.paymentDetailsContainer}>
        {showUPIId && (
          <Text style={paymentVerifyingStyles.UpiText}>
            {`UPI Id: ${virtual_bank_account_id}`}
          </Text>
        )}

        <View style={paymentVerifyingStyles.orderIdContainer}>
          <Text style={paymentVerifyingStyles.orderId}>
            {`Order Id: #${order_id}`}
          </Text>
        </View>

        <Text style={paymentVerifyingStyles.paymentExpireText}>
          This payment request will automatically expire in 5 minutes
        </Text>
        <View style={paymentVerifyingStyles.timerContainer}>
          <Image
            resizeMode='contain'
            style={paymentVerifyingStyles.timerIcon}
            source={images.paymentStatus.stopWatch}
          />
          <Counter
            size={18}
            until={300}
            onFinish={_.memoize(
              () => changePaymentstate('timeout'),
              () => ['timeout'],
            )}
            endsAt={300}
            digitStyle={paymentVerifyingStyles.digitStyle}
            style={paymentVerifyingStyles.counterContainer}
            digitTxtStyle={paymentVerifyingStyles.counterDigitText}
            separatorStyle={paymentVerifyingStyles.counterSaperator}
            timeToShow={['M', 'S']}
            timeLabels={{ m: null, s: null }}
            showSeparator
            withoutBlock
          />
        </View>
      </View>
    </View>
  );
}

export function OpenIntent({
  paymentDetails: { attributes = {} } = {},
  paymentCurrentStatus: { order_id = '' } = {},
  virtual_bank_account_id = '',
  details,
  changePaymentstate,
}) {
  if (isWeb()) {
    return null;
  }
  if (!Utility.isPresent(details?.intent_url)) {
    return null;
  }
  return (
    <View style={paymentVerifyingStyles.saperatorContainerBottom}>
      <TouchableWithoutFeedback
        onPress={() => {
          if (Utility.isBlank(details?.intent_url)) {
            return;
          }
          Linking.openURL(details?.intent_url);
        }}
      >
        <View
          style={
            Utility.isPresent(details?.intent_url)
              ? paymentVerifyingStyles.iconWithCtaContainer
              : paymentVerifyingStyles.iconWithoutCtaContainer
          }
        >
          <View style={paymentVerifyingStyles.ctaIconContainer}>
            <Image
              resizeMode='contain'
              style={
                Utility.isPresent(details?.intent_url)
                  ? paymentVerifyingStyles.iconWithCta
                  : paymentVerifyingStyles.iconWithoutCta
              }
              source={{ uri: details?.icon }}
            />
          </View>
          <Text style={paymentVerifyingStyles.ctaText}>
            {' '}
            {'Open ' + details?.name + ' App'}{' '}
          </Text>
        </View>
      </TouchableWithoutFeedback>

      {/* {Utility.isPresent(details?.intent_url) && (
    <View style={paymentVerifyingStyles.saperatorContainer}>
      <Text style={paymentVerifyingStyles.saperatorText}>
        Tap to open app
      </Text>
    </View>
  )} */}
    </View>
  );
}

export function IntentPaymentVerifying({
  paymentDetails: { attributes = {} } = {},
  paymentCurrentStatus: { order_id = '' } = {},
  virtual_bank_account_id = '',
  details,
  changePaymentstate,
}) {
  const stepsForPayment = _.filter(attributes, (attr, key) => {
    return key.includes('step');
  });

  return (
    <View style={paymentVerifyingStyles.container}>
      <LottieView
        source={animations.paymentProcessing}
        style={paymentStatusMessages.animation}
        resizeMode='cover'
        autoPlay={true}
      />

      {Utility.isPresent(details?.intent_url) && (
        <View style={paymentVerifyingStyles.saperatorContainer}>
          <Text style={paymentVerifyingStyles.saperatorText}>
            Tap to open app
          </Text>
          <Text style={paymentVerifyingStyles.saperatorOr}>OR</Text>
        </View>
      )}

      <View>
        {_.map(stepsForPayment, (stepForPayment = '') => {
          const app_name = details?.name;
          const merchant_name = details?.merchant_name;

          const replacedString = stepForPayment
            .replace('${app_name}', app_name.toString())
            .replace('${app_name}', app_name.toString())
            .replace('${merchant_name}', merchant_name.toString());

          const step = Utility.isPresent(replacedString)
            ? Utility.replacePlaceHolders(stepForPayment, replacedString)
            : stepForPayment;
          return (
            <View style={paymentVerifyingStyles.paymentStepsContainer}>
              <View style={paymentVerifyingStyles.stepperContainer}>
                <View style={paymentVerifyingStyles.stepper} />
              </View>

              <View style={paymentVerifyingStyles.paymentStep}>
                <Text style={paymentVerifyingStyles.paymentStepText}>
                  {step}
                </Text>
              </View>
            </View>
          );
        })}

        <View style={paymentVerifyingStyles.verticalLineForStepper} />
      </View>
      <View style={paymentStatusMessages.paymentDetailsContainer}>
        <View style={paymentStatusMessages.headingSubheadingContainer}>
          <Text style={paymentStatusMessages.heading}>{attributes.title}</Text>

          <Text style={paymentStatusMessages.subHeading}>
            {attributes.sub_title}
          </Text>
        </View>

        <View style={paymentVerifyingStyles.orderIdContainer}>
          <Text style={paymentVerifyingStyles.orderId}>
            {`Order Id: #${order_id}`}
          </Text>
        </View>
        <View style={paymentVerifyingStyles.timerContainer}>
          <Image
            resizeMode='contain'
            style={paymentVerifyingStyles.timerIcon}
            source={images.paymentStatus.stopWatch}
          />
          <Counter
            size={18}
            until={300}
            onFinish={_.memoize(
              () => changePaymentstate('timeout'),
              () => ['timeout'],
            )}
            endsAt={300}
            digitStyle={paymentVerifyingStyles.digitStyle}
            style={paymentVerifyingStyles.counterContainer}
            digitTxtStyle={paymentVerifyingStyles.counterDigitText}
            separatorStyle={paymentVerifyingStyles.counterSaperator}
            timeToShow={['M', 'S']}
            timeLabels={{ m: null, s: null }}
            showSeparator
            withoutBlock
          />
        </View>
        {/* <Text style={paymentVerifyingStyles.paymentExpireText}>
          This payment request will automatically expire in 5 minutes
        </Text> */}
        {/* <Text style={paymentVerifyingStyles.backInstruction}>
          Do not press back button
        </Text> */}
      </View>
    </View>
  );
}

export function PaymentValidate({
  paymentDetails: {
    attributes: { title = '', sub_title = '', clear_timer } = {},
  },
  paymentCurrentStatus: { order_id = '' } = {},
  clearTimer,
  changePaymentstate,
}) {
  // if (!!clear_timer) clearTimer();
  const [progress] = useState(new Animated.Value(0));

  useEffect(() => {
    Animated.timing(progress, {
      toValue: 1,
      duration: 4000,
      easing: Easing.linear,
      useNativeDriver: true,
    }).start();
  });

  return (
    <View style={paymentValidateStyles.container}>
      <LottieView
        source={animations.paymentValidationFail}
        style={paymentValidateStyles.animationStyle}
        resizeMode='cover'
        progress={progress}
        resizeMode='cover'
      />
      <Message
        heading={title}
        subheading={sub_title}
        orderid={order_id}
        changePaymentstate={changePaymentstate}
      />
    </View>
  );
}

export function HoldOn({
  paymentDetails: {
    attributes: { title = '', sub_title = '', time_left = 0 } = {},
  },
  paymentCurrentStatus: { order_id = '' } = {},
  changePaymentstate,
}) {
  // const [progress] = useState(new Animated.Value(0));

  // useEffect(() => {
  //   Animated.loop(
  //     Animated.sequence([
  //       Animated.timing(progress, {
  //         toValue: 1,
  //         duration: 3000,
  //         delay: 0,
  //         useNativeDriver: true,
  //       }),
  //       Animated.timing(progress, {
  //         toValue: 0,
  //         duration: 3000,
  //         delay: 0,
  //         useNativeDriver: true,
  //       }),
  //     ]),
  //     {
  //       iterations: 100,
  //     },
  //   ).start();
  // });
  return (
    <View style={successStatus.container}>
      <LottieView
        source={animations.holdOn}
        style={successStatus.animationStyle}
        resizeMode='cover'
        autoPlay={true}
        // progress={progress}
        resizeMode='cover'
      />
      <Message
        heading={title}
        subheading={sub_title}
        orderid={order_id}
        time_left={30}
        showTimer
        changePaymentstate={changePaymentstate}
      />
    </View>
  );
}

export function PaymentFail({
  paymentDetails: {
    attributes: { title = '', sub_title = '', clear_timer } = {},
  },
  paymentCurrentStatus: { order_id = '' } = {},
  clearTimer,
  changePaymentstate,
}) {
  // if (!!clear_timer) clearTimer();
  const [progress] = useState(new Animated.Value(0));
  const [progressSuccess] = useState(new Animated.Value(0));

  useEffect(() => {
    Animated.timing(progress, {
      toValue: 1,
      duration: 4000,
      easing: Easing.linear,
      useNativeDriver: true,
    }).start();

    Animated.timing(progressSuccess, {
      toValue: 1,
      duration: 8000,
      easing: Easing.linear,
      useNativeDriver: true,
    }).start();
  });

  return (
    <View style={failureStatus.container}>
      <LottieView
        source={animations.paymentFail}
        style={failureStatus.animationStyle}
        resizeMode='cover'
        progress={progress}
        resizeMode='cover'
      />
      <Message
        heading={title}
        subheading={sub_title}
        orderid={order_id}
        changePaymentstate={changePaymentstate}
      />
    </View>
  );
}

export function PaymentSuccess({
  paymentDetails: {
    attributes: { title = '', sub_title = '', clear_timer } = {},
  },
  paymentCurrentStatus: { order_id = '' } = {},
  clearTimer,
  clearCart,
  onPressSuccessCta,
  changePaymentstate,
  successCtaText = '',
}) {
  if (!!clear_timer) {
    clearTimer();
  }
  const [progress] = useState(new Animated.Value(0));
  const [progressSuccess] = useState(new Animated.Value(0));
  useEffect(() => {
    setTimeout(() => {
      Animated.timing(progress, {
        toValue: 1,
        duration: 4000,
        easing: Easing.linear,
        useNativeDriver: true,
      }).start();
    }, 0);

    Animated.timing(progressSuccess, {
      toValue: 1,
      duration: 5000,
      easing: Easing.ease,
      useNativeDriver: true,
    }).start();
  });

  return (
    <View style={successStatus.container}>
      <LottieView
        source={animations.paymentSuccess}
        style={successStatus.animationStyle}
        resizeMode='cover'
        progress={progress}
        resizeMode='cover'
      />

      <Message
        heading={title}
        subheading={sub_title}
        orderid={order_id}
        changePaymentstate={changePaymentstate}
      />

      <FoxyShadowButton
        width={
          isDesktop()
            ? Utility.getScreenWidth() / 2
            : Utility.getScreenWidth() - 34
        }
        title={successCtaText}
        onPress={onPressSuccessCta}
        backgroundColor={colors.white}
        style={successStatus.continueShopping}
        textStyle={failureActions.secondaryButtonText}
        firstColor={colors.white}
        secondColor={colors.white}
      />
      {/* <LottieView
        source={animations.celebration}
        style={{
          height: '100%',
          width: '100%',
          resizeMode: 'cover',
          alignSelf: 'center',
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 1,
        }}
        resizeMode='cover'
        progress={progressSuccess}
        resizeMode='cover'
      /> */}
    </View>
  );
}
export function FailureActions({
  changePaymentstate,
  paymentDetails,
  paymentCurrentStatus: { order_id = '' } = {},
}) {
  return (
    <View style={styles.container}>
      <FoxyShadowButton
        width={
          isDesktop()
            ? Utility.getScreenWidth() / 2
            : Utility.getScreenWidth() - 34
        }
        title={'Retry with same payment method'}
        onPress={_.memoize(
          () => changePaymentstate('retry'),
          () => ['retry'],
        )}
        backgroundColor={colors.primaryActionBackgroundColor}
        textStyle={validationActions.buttonTextStyle}
        style={validationActions.primaryButton}
        firstColor={colors.linerGradientGreenFirst}
        secondColor={colors.linerGradientGreenSecond}
      />

      <FoxyShadowButton
        width={
          isDesktop()
            ? Utility.getScreenWidth() / 2
            : Utility.getScreenWidth() - 34
        }
        title={'Change payment method'}
        onPress={_.memoize(
          () => changePaymentstate('change_method'),
          () => ['change_method'],
        )}
        backgroundColor={colors.white}
        style={failureActions.secondaryButton}
        textStyle={failureActions.secondaryButtonText}
        firstColor={colors.white}
        secondColor={colors.white}
      />
      <ContactUsCard orderId={order_id} />
    </View>
  );
}

export function ValidationActions({ changePaymentstate, paymentDetails }) {
  return (
    <View style={styles.container}>
      <FoxyShadowButton
        width={
          isDesktop()
            ? Utility.getScreenWidth() / 2
            : Utility.getScreenWidth() - 34
        }
        title={'My money is deducted'}
        onPress={_.memoize(
          () => changePaymentstate('money_deducted'),
          () => ['money_deducted'],
        )}
        backgroundColor={colors.primaryActionBackgroundColor}
        style={validationActions.primaryButton}
        firstColor={colors.linerGradientGreenFirst}
        secondColor={colors.linerGradientGreenSecond}
        textStyle={validationActions.buttonTextStyle}
      />
      <FoxyShadowButton
        width={
          isDesktop()
            ? Utility.getScreenWidth() / 2
            : Utility.getScreenWidth() - 34
        }
        title={'Retry with same payment method'}
        onPress={_.memoize(
          () => changePaymentstate('retry'),
          () => ['retry'],
        )}
        backgroundColor={colors.white}
        style={validationActions.secondaryButton}
        textStyle={validationActions.secondaryButtonText}
        firstColor={colors.white}
        secondColor={colors.white}
      />
      <FoxyShadowButton
        width={
          isDesktop()
            ? Utility.getScreenWidth() / 2
            : Utility.getScreenWidth() - 34
        }
        title={'Change payment method'}
        onPress={_.memoize(
          () => changePaymentstate('change_method'),
          () => ['change_method'],
        )}
        backgroundColor={colors.white}
        style={validationActions.changePaymentMethod}
        textStyle={validationActions.changePaymentMethodText}
        firstColor={colors.white}
        secondColor={colors.white}
      />
    </View>
  );
}

export function DeliveryStatus({
  paymentDetails: { attributes: { expected_delivery_date = '' } = {} },
  navigateToOrders,
}) {
  return (
    <View style={deliveryStatus.container}>
      <Image
        source={images.packageBox}
        style={PaymentStyles.orderDeliveryImage}
      />
      <View style={PaymentStyles.arrivingTextContainer}>
        <Text style={deliveryStatus.arivingText}>Latest By</Text>
        <Text
          style={PaymentStyles.deliveryDateText}
          numberOfLines={2}
          ellipsizeMode='tail'
        >
          {expected_delivery_date}
        </Text>

        <Text style={deliveryStatus.trackOrder}>
          Track and manage your orders easily
        </Text>
      </View>

      <View style={deliveryStatus.ctaContainer}>
        <TouchableOpacity
          onPress={navigateToOrders}
          hitSlop={Utility.getHitSlop()}
        >
          <Text style={deliveryStatus.cta}>{`Orders >`}</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
}

export function CartPricing({ cartPricing = {} }) {
  if (isBlank(cartPricing)) {
    return null;
  }
  return <CartPricingData cartPricing={cartPricing} />;
}

export function CartProducts({ cartItems = {} }) {
  if (Utility.isBlank(cartItems)) {
    return null;
  }

  const renderItems = ({ item }) => {
    if (Utility.isBlank(item)) {
      return null;
    }
    let newCart = item;
    if (Utility.isPresent(item.offer)) {
      newCart = { ...item, product: item.offer };
    }

    const {
      quantity,
      product = {},
      product: { brand: { name: brandName = Config.APP_NAME } = {}, name } = {},
      variant,
      loyalty_plan = {},
      loyalty_plan: { card_image: cardImage = '' } = {},
      routine: {
        data: { attributes: { imageUrl: routineImageUrl = '' } = {} } = {},
      } = {},
    } = newCart;

    const imageUrls = [
      newCart?.offer?.card_image,
      newCart?.product?.image_url,
      cardImage,
      routineImageUrl,
    ];
    const imageUrl = imageUrls.find((url) => url && isPresent(url)) || '';

    return (
      <View style={cartItemsStyles.container}>
        <View style={cartItemsStyles.imageContainer}>
          <FastImageView
            source={{
              uri: getMinifiedImage(
                imageUrl,
                cartItemsStyles.width,
                cartItemsStyles.height,
              ),
            }}
            style={cartItemsStyles.image}
            resizeMode='contain'
          />
        </View>

        <View style={cartItemsStyles.itemDetailsConntainer}>
          <Text style={cartItemsStyles.brandName}>{brandName}</Text>
          <Text
            style={cartItemsStyles.productName}
            numberOfLines={2}
            ellipsizeMode='tail'
          >
            {name}
          </Text>
          {Utility.isPresent(variant) ? (
            <View style={cartItemsStyles.variantContainer}>
              {Utility.isPresent(getMinifiedImage(variant?.image_url)) && (
                <FastImage
                  source={{
                    uri: getMinifiedImage(
                      variant?.image_url,
                      cartItemsStyles.variantImage.width,
                      cartItemsStyles.variantImage.height,
                    ),
                  }}
                  style={cartItemsStyles.variantImage}
                  resizeMode='contain'
                />
              )}
              <Text
                style={cartItemsStyles.variantDisplayName}
                numberOfLines={1}
              >
                {variant?.display_name}
              </Text>
              <Text
                style={cartItemsStyles.quantityWithVariant}
              >{` x ${quantity}`}</Text>
            </View>
          ) : (
            <Text style={cartItemsStyles.quantity}>{`Qty: ${quantity}`}</Text>
          )}
        </View>

        <View style={cartItemsStyles.spContainer}>
          <Text style={cartItemsStyles.sp}>
            {`${CURRENCY_SYMBOL} ${item?.final_sp}`}
          </Text>
        </View>
      </View>
    );
  };

  return (
    <>
      <FlatList
        data={cartItems}
        renderItem={renderItems}
        keyExtractor={(_, index) => index.toString()}
        style={cartItemsStyles.flatlist}
        extraData={cartItems}
      />
    </>
  );
}

const ContactUsCard = ({ orderId }) => {
  if (!AppConfig.getBooleanValue(Config.SHOW_WHATSAPP_CTA_ON_PAYMENT_WAITING)) {
    return null;
  }
  return (
    <TouchableOpacity
      onPress={() =>
        Utility.openWhatsApp('Hi, I need help with my order ' + orderId)
      }
    >
      <View style={contactUsStyles.container}>
        <Image
          source={images.socialShareIcons.whatsapp}
          style={contactUsStyles.watsappImage}
        />
        <Text style={contactUsStyles.contactUs}>Contact us</Text>
      </View>
    </TouchableOpacity>
  );
};

export function Address({ onChangePress }) {
  const state = getStoreRef().getState();
  const currentAddress = AddressUtility.restructureAddressObject(
    state?.bag?.defaultAddress || {},
  );
  if (Utility.isBlank(currentAddress)) {
    return null;
  }
  const {
    line1 = '',
    line2 = '',
    pincode = '',
    contact_name = '',
  } = currentAddress;

  let addressLine1 = line2;
  let addressLine2 = line1;
  if (line2) {
    addressLine1 += ', ';
  }
  if (line1) {
    addressLine2 += ' - ';
    addressLine2 = addressLine2?.replace(', India', '');
  }

  return (
    <View
      style={addressStyles.paymentStatusAddressContainer}
      dataSet={{ media: addressIds.paymentStatusAddressContainer }}
    >
      <View style={addressStyles.addressImageContainer}>
        <Image
          source={images.orderAddress[`${currentAddress?.name}`]}
          style={PaymentStyles.orderDeliveryImage}
        />
      </View>

      <View style={PaymentStyles.arrivingTextContainer}>
        <Text style={PaymentStyles.currentAddressName}>
          {currentAddress?.name}
        </Text>
        <Text
          style={[
            PaymentStyles.deliveryDateText,
            { maxWidth: Utility.getScreenWidth() / 1.5 },
          ]}
          numberOfLines={3}
          ellipsizeMode='tail'
        >
          {`${contact_name ?? ''}: ${addressLine1 ?? ''}${addressLine2 ?? ''}${
            pincode ?? ''
          }`}
        </Text>
      </View>
      {typeof onChangePress === 'function' && (
        <TouchableOpacity onPress={onChangePress}>
          <Text style={PaymentStyles.changeAddressText}>Change</Text>
        </TouchableOpacity>
      )}
    </View>
  );
}