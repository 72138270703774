/* eslint-disable import/prefer-default-export */
import Config from '../libraries/ReactNativeConfig';
import images from '../theme/Images';
import { isWeb } from '../utils/BooleanUtility';

export const PaymentOptions = isWeb() ? [
  {
    id: 'upi',
    header: 'UPI',
    title: 'UPI',
    image: images.payment.upi,
    imageSelected: images.payment.upiSelected,
    type: 'upi',
    identifier: 'UPI',
  },
  {
    id: 'creditdebit',
    header: 'Debit/Credit Cards',
    title: 'Credit Card/Debit Card',
    image: images.card,
    imageSelected: images.cardSelected,
    type: 'card',
    identifier: 'Cards',
  },
  {
    id: 'GPay',
    header: 'Google Pay',
    title: 'Google Pay',
    image: images.gpay_bnw,
    imageSelected: images.payment.gpaySelected,
    type: 'GPay',
    identifier: 'GPay',
  },
  {
    id: 'othernetbanking',
    header: 'NetBanking',
    title: 'NetBanking',
    image: images.payment.bank,
    imageSelected: images.payment.netBankingSelected,
    type: 'nb',
    identifier: 'NetBanking',
  },
  {
    id: 'cash',
    header: Config.CASH_ON_DELIVERY_TEXT,
    title: 'Cash On Delivery',
    image: images.payment.cod,
    imageSelected: images.payment.codSelected,
    type: 'cod',
    identifier: 'Cash',
  },
] : [
  {
    id: 'blink',
    header: 'Blink',
    title: 'Blink',
    image: { uri: 'https://mufoxy.cdn.ekanek.app/media/app_asset/image/4/bw_blink_ic.png'},
    imageSelected: { uri: 'https://mufoxy.cdn.ekanek.app/media/app_asset/image/3/blink_ic.png' },
    type: 'upi',
    identifier: 'Blink',
  },
  {
    id: 'blink',
    header: 'Blink',
    title: 'Blink',
    image: { uri: 'https://mufoxy.cdn.ekanek.app/media/app_asset/image/4/bw_blink_ic.png'},
    imageSelected: { uri: 'https://mufoxy.cdn.ekanek.app/media/app_asset/image/3/blink_ic.png' },
    type: 'upi',
    identifier: 'Blink',
  },
  {
    id: 'mips',
    header: 'Debit/Credit Cards',
    title: 'MIPS',
    image: images.card,
    imageSelected: images.cardSelected,
    type: 'upi',
    identifier: 'MIPS',
  },
  {
    id: 'upi',
    header: 'UPI',
    title: 'UPI',
    image: images.payment.upi,
    imageSelected: images.payment.upiSelected,
    type: 'upi',
    identifier: 'UPI',
  },
  {
    id: 'creditdebit',
    header: 'Debit/Credit Cards',
    title: 'Credit Card/Debit Card',
    image: images.card,
    imageSelected: images.cardSelected,
    type: 'card',
    identifier: 'Cards',
  },
  {
    id: 'PayTmWallet',
    header: 'Paytm Wallet',
    title: 'PayTm Wallet',
    image: images.paytmbnw,
    imageSelected: images.payment.paytmSelected,
    type: 'PayTmWallet',
    identifier: 'PayTmWallet',
  },
  {
    id: 'GPay',
    header: 'Google Pay',
    title: 'Google Pay',
    image: images.gpay_bnw,
    imageSelected: images.payment.gpaySelected,
    type: 'GPay',
    identifier: 'GPay',
  },
  {
    id: 'othernetbanking',
    header: 'NetBanking',
    title: 'NetBanking',
    image: images.payment.bank,
    imageSelected: images.payment.netBankingSelected,
    type: 'nb',
    identifier: 'NetBanking',
  },
  {
    id: 'cash',
    header: Config.CASH_ON_DELIVERY_TEXT,
    title: 'Cash On Delivery',
    image: images.payment.cod,
    imageSelected: images.payment.codSelected,
    type: 'cod',
    identifier: 'Cash',
  },
];
