// Dependencies
import React, { PureComponent, useState } from 'react';
import { View, Text, TouchableOpacity, Platform } from 'react-native';
import StyleSheet from 'react-native-media-query';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  LOGIN_MODAL_MESSAGES,
  REMOTE_CONFIG_KEYS,
  SIZE,
} from '../../config/Constants';
import CountDown from '../../helpers/Counter';
import colors, { colorCode } from '../../theme/Colors';
import images from '../../theme/Images';
import Utility from '../../utils/Utility';
import FastImageView from '../FastImageView';
import DebouncedTouchableOpacity from '../shared/DebouncedTouchableOpacity';
import { setPhoneNumberModalInfo } from '../../actions/LoginActions';
import CouponCodePill from '../cart/CouponCodePill';
import size from '../../theme/Fonts';
import {
  AnalyticsManager,
  EventParameterKey,
  EventType,
} from '../../analytics';
import ShimmerPlaceHolder from '../../libraries/ReactNativeShimmerPlaceholder';
import { ProductDetailStyles } from './styles';
import { CartStyles } from '../cart/styles';
import RemoteConfig from '../../utils/RemoteConfig';
import NavigationService from '../../navigator/NavigationService';
import Config from '../../libraries/ReactNativeConfig';
import withGreenText from './withGreenText';
import _ from 'lodash';
import { addContinueAction } from '../../actions/FoxyEdgeAction';
import applyOffer from '../../hoc/applyOffer';
import { getScreenWidth } from '../../utils/LayoutUtility';
import { navigateToScreen } from '../../utils/NavigationUtility';
import withProfiledNavigation from '../../utils/withProfiledNavigation';
import { getNavigationSource } from '../../utils/PerfUtility';
import { PRODUCT_RIGHT_CONTAINER_WIDTH } from '../../config/LayoutConstants/ProductConfig';
import { isWebIOS } from '../../utils/BooleanUtility';

const Timer = (props) => {
  const [hideTimer, setHideTimer] = useState(false);
  const { endsAt, is_club_member_offer, showClubTag } = props;
  const clubDealFor = Utility.jsonParser(
    RemoteConfig.getValue(REMOTE_CONFIG_KEYS.club_member),
    'club_member_ProductTodayDeals',
  )?.emphasisText;
  const onFinish = () => {
    setHideTimer(true);
  };

  if (is_club_member_offer && !showClubTag && !endsAt) {
    return <Text style={styles.expireInText}>{clubDealFor}</Text>;
  }
  if (hideTimer) return null;
  return (
    <>
      <Text style={styles.expireInText}>Ends in</Text>
      <CountDown
        size={14}
        until={Utility.calculateTimeDifferenceForOffer(endsAt)}
        endsAt={endsAt}
        digitStyle={styles.digitStyle}
        digitTxtStyle={styles.digitTxtStyle}
        separatorStyle={styles.separatorStyle}
        timeToShow={['H', 'M', 'S']}
        timeLabels={{ m: null, s: null }}
        showSeparator
        withoutBlock
        onFinish={onFinish}
      />
    </>
  );
};


export class ProductTodayDeals extends PureComponent {
  boostedCouponCode = '';

  skuId = '';

  offerId = '';

  boostedOfferObject = {};

  isBoostedCouponClaimed = false;

  colorShimmerArray = ['#fafafa', '#eeeeee', '#fafafa'];

  constructor(props) {
    super(props);

    this.productDealsTagData = JSON.parse(
      RemoteConfig.getValue(REMOTE_CONFIG_KEYS.product_deals_tag_data),
    );
    const {
      flash_deal_v2: { text_left_margin = 0, text_color = colors.white } = {},
    } = this.productDealsTagData;
    this.productTagTextStyle = [
      styles.labeText,
      {
        color: text_color,
        // marginLeft: styles.labeText.marginLeft + text_left_margin + 12,
      },
    ];
    this.shimmerColor = ['#f7F7f7', '#eeeeee', '#f7F7f7'];
    this.navigationSource = getNavigationSource(this, this.props);
  }

  componentDidMount() {
    const {
      ctaType,
      espToShow,
      extraData: {
        couponCode,
        offerOnSkuId,
        greenTextCandidatesHighestPriorityIdentifier,
        edge_deal_price = 'NA',
        wish_deal_price = 'NA',
        group_deal_price = 'NA',
      } = {},
    } = this.props;

    AnalyticsManager.logEvent('green_text_render', {
      esp: espToShow,
      coupon_code: couponCode,
      cta_type: ctaType,
      greenTextCandidatesHighestPriorityIdentifier,
      offerOnSkuId,
      is_edge_activation_prompt: ctaType === 'activate',
      is_boosted: true,
      edge_deal_price,
      group_deal_price,
      wish_deal_price,
    });
  }

  onLabelPress = () => {
    const { navigation, setPhoneNumberModalInfo, authToken } = this.props;

    if (Utility.isBlank(authToken)) {
      setPhoneNumberModalInfo(Utility.getLoginModalInfo('WISHLIST_VIEW'));
      NavigationService.renderOnboarding();
      Utility.invokeSelfieFlowAfterLoginForGuest(false);
      return;
    }
    this.navigateToTodayDeals();
  };

  navigateToTodayDeals = () => {
    const { navigation } = this.props;
    if (Config.TABS?.includes('Wishlist')) {
      navigation.navigate('Wishlist');
      return;
    }
    navigateToScreen({
      navigation,
      type: 'push',
      screen: 'TodayDeals',
    });
  }

  onPressClaimButton = (isClubMemberOffer, showClaimButtonForEdge) => {
    const {
      onPressClaimButton,
      authToken,
      setPhoneNumberModalInfo,
      navigation,
      showMembershipUpgradeModal,
    } = this.props;

    AnalyticsManager.logEvent(EventType.discoveryEvents.ITEM_ACTION, {
      [EventParameterKey.IS_LOGGED_IN]: Utility.isPresent(authToken),
      [EventParameterKey.ITEM_TYPE]: 'boosted_offers',
      [EventParameterKey.SOURCE]: 'claim_button',
      [EventParameterKey.COUPON_CODE]: this.boostedCouponCode,
      [EventParameterKey.TEXT]: this.isBoostedCouponClaimed
        ? 'view_bag'
        : 'claim',
      offer_type: isClubMemberOffer ? 'edge_offer' : 'other',
    });

    if (this.isEdgeOfferForNonMembers() && !showClaimButtonForEdge) {
      showMembershipUpgradeModal();
      return;
    }

    if (Utility.isBlank(authToken)) {
      setPhoneNumberModalInfo(Utility.getLoginModalInfo('APPLY_COUPON_CODE'));
      NavigationService.renderOnboarding();
      Utility.invokeSelfieFlowAfterLoginForGuest(false);
      Utility.furtherAction = {
        action: onPressClaimButton,
        params: [this.boostedCouponCode, this.offerId, this.skuId],
      };
      return;
    }

    if (this.isBoostedCouponClaimed) {
      navigation.navigate(this.navigationSource, 'Cart');
      return;
    }

    if (onPressClaimButton) {
      onPressClaimButton(this.boostedCouponCode, this.offerId, this.skuId);
    }
  };

  boostedOffer = ({ couponCode, is_club_member_offer }) => {
    if (!couponCode) return null;
    const style = is_club_member_offer
      ? styles.couponCodePillStyleMember
      : styles.couponCodePillStyle;
    return (
      <CouponCodePill
        couponCodeStatus
        couponCode={couponCode}
        style={style}
        is_club_member_offer={is_club_member_offer}
      />
    );
  };

  flashDealOfferContainer = ({ visible }) => {
    if (!visible) return null;
    const {
      flash_deal_v2: {
        image: flashDealImage = '',
        label_name: flashDealLabelName = '',
      } = {},
    } = this.productDealsTagData;

    return (
      <DebouncedTouchableOpacity {...this.props} onPress={this.onLabelPress}>
        <FastImageView source={flashDealImage} style={styles.labelImage} dataSet={{ media: ids.labelImage }}>
          <Text style={this.productTagTextStyle} dataSet={{ media: ids.labeText }}>{flashDealLabelName}</Text>
        </FastImageView>
      </DebouncedTouchableOpacity>
    );
  };

  CountDownTimer = ({ showClubTag, is_club_member_offer }) => {
    const {
      extraData: {
        endsAt = '',
        greenTextCandidatesHighestPriorityIdentifier = '',
        boostedOfferCouponcode = '',
        isClubMemberOffer = false,
        couponCode = '',
      } = {},
    } = this.props;
    if (Utility.isBlank(endsAt)) return null;

    return (
      <View style={styles.timerContainer}>
        <this.flashDealOfferContainer
          visible={
            !!['flash_deal', 'flash_deal_activation'].includes(
              greenTextCandidatesHighestPriorityIdentifier,
            )
          }
        />
        <this.boostedOffer
          couponCode={boostedOfferCouponcode}
          is_club_member_offer={isClubMemberOffer}
        />

        <Timer
          showCountdownTimerSection={Utility.isPresent(endsAt)}
          endsAt={endsAt}
          couponCode={couponCode}
          showClubTag={showClubTag}
          is_club_member_offer={is_club_member_offer}
        />
      </View>
    );
  };

  isEdgeOfferForNonMembers = () => {
    const {
      todayDeals: { membership_cohort = '' },
    } = this.props;
    return (
      membership_cohort !== 'member' &&
      this.boostedOfferObject.is_club_member_offer
    );
  };

  onActivatePress = () => {
    const {
      showMembershipUpgradeModal,
      navigation,
      data: { slug = '', image_url: imageUrl = '' } = {},
    } = this.props;
    navigateToScreen({
      navigation,
      type: 'push',
      screen: 'LoyaltyPlans',
    });
  };

  onClaimPress = () => {
    const {
      onPressClaimButton,
      extraData: { isCouponClaimed, couponCode, offerOnSkuId } = {},
      navigation,
      applyCartCoupon,
      currentSku,
    } = this.props;

    if (isCouponClaimed) {
      navigation.navigate(this.navigationSource, 'Cart');
      return;
    }

    applyCartCoupon(couponCode, null, false, true, 'product', currentSku);
  };

  onCtaPress = () => {
    const {
      ctaType,
      extraData: { isCouponClaimed = false } = {},
      navigation,
    } = this.props;
    if (isCouponClaimed) {
      navigation.push(this.navigationSource, 'Cart');
      return;
    }
    if (ctaType === 'activate') {
      this.onActivatePress();
      return;
    }

    this.onClaimPress();
  };

  ClaimBoostedOfferCtaContainer = () => {
    const { ctaType, extraData: { isCouponClaimed } = {} } = this.props;
    if (!ctaType) return null;

    const ctaBackgroundStyle = !isCouponClaimed
      ? styles.claimBoostedOfferCta
      : styles.claimedBoostedOfferCta;

    const ctaStyle = !isCouponClaimed
      ? styles.claimBoostedOfferCtaText
      : styles.claimedBoostedOfferCtaText;

    return (
      <View style={styles.claimBoostedOfferCtaContainer}>
        <TouchableOpacity onPress={this.onCtaPress}>
          <View style={ctaBackgroundStyle}>
            <Text style={ctaStyle}>
              {isCouponClaimed ? 'View bag' : _.capitalize(ctaType)}
            </Text>
          </View>
        </TouchableOpacity>
      </View>
    );
  };

  todaysDealText = () => {
    const { greenText } = this.props;

    if (Utility.isBlank(greenText)) return null;

    return <Text style={styles.todayDealsText}>{greenText}</Text>;
  };

  pinkText = ({ message }) => {
    return (
      <Text style={styles.pinkPromptText} allowFontScaling={false}>
        {`${message}`}
      </Text>
    );
  };

  pricingShimmer = () => (
    <View style={ProductDetailStyles.pricingContainerTop}>
      <View style={CartStyles.shimmerContainer}>
        <ShimmerPlaceHolder
          autoRun
          style={CartStyles.spShimmer}
          colorShimmer={this.colorShimmerArray}
        />

        <ShimmerPlaceHolder
          autoRun
          style={CartStyles.mrpShimmer}
          colorShimmer={this.colorShimmerArray}
        />
        <ShimmerPlaceHolder
          autoRun
          style={CartStyles.discountShimmer}
          colorShimmer={this.colorShimmerArray}
        />
      </View>
    </View>
  );

  showLoyaltyPlansPage = () => {
    const { navigation } = this.props;
    navigateToScreen({
      navigation,
      type: 'navigate',
      screen: 'LoyaltyPlans',
      params: {
        previousScreen: 'product',
      },
    });
  };

  onPressClaimEdge = (isEdgeOffer) => {
    const {
      authToken,
      addContinueAction,
      itemData: { image_url = '', slug = '' } = {},
    } = this.props;
    AnalyticsManager.logEvent(EventType.discoveryEvents.ITEM_ACTION, {
      [EventParameterKey.IS_LOGGED_IN]: Utility.isPresent(authToken),
      [EventParameterKey.ITEM_TYPE]: isEdgeOffer ? 'edge_offer' : 'flash_deal',
      [EventParameterKey.SOURCE]: 'activate_button',
      [EventParameterKey.TEXT]: 'activate',
      [EventParameterKey.COUPON_CODE]: this.boostedCouponCode,
    });
    if (Utility.isPresent(slug) && Utility.isPresent(image_url)) {
      addContinueAction(slug, image_url, 'product');
    }
    this.showLoyaltyPlansPage();
  };

  claimEdge = ({ showEdgeDealActivation, isEdgeOffer = false }) => {
    const edgeMembersOnlyMessage = RemoteConfig.getValue(
      REMOTE_CONFIG_KEYS.edge_members_only_text,
    );
    return (
      <View style={styles.claimBoostedOfferCtaContainer}>
        <TouchableOpacity
          onPress={_.memoize(
            () => this.onPressClaimEdge(isEdgeOffer),
            () => [isEdgeOffer],
          )}
        >
          <View style={styles.claimedBoostedOfferCtaForActivate}>
            <FastImageView
              visible={false}
              source={images.greenCheckIcon}
              style={styles.checkIcon}
            />
            <Text style={styles.claimedBoostedOfferCtaTextForActivation}>
              {'Activate'}
            </Text>
          </View>
        </TouchableOpacity>

        <Text style={styles.claimBoostedOfferCtaSubText}>
          {edgeMembersOnlyMessage}
        </Text>
      </View>
    );
  };

  render() {
    const { greenText, isLoading } = this.props;
    if (Utility.isBlank(greenText)) return null;

    if (isLoading) {
      return (
        <View style={styles.todayDealShimmerContainer} dataSet={{ media: ids.todayDealShimmerContainer }}>
          <View>
            <ShimmerPlaceHolder
              colorShimmer={this.shimmerColor}
              autoRun
              style={styles.todayDealShimmerOne}
            />
            <ShimmerPlaceHolder
              colorShimmer={this.shimmerColor}
              autoRun
              style={styles.todayDealShimmerThree}
            />
          </View>

          <ShimmerPlaceHolder
            colorShimmer={this.shimmerColor}
            autoRun
            style={styles.todayDealShimmerTwo}
          />
        </View>
      );
    }
    return (
      <View style={styles.row}>
        <View style={styles.outerContainer}>
          <View style={styles.innerContainer}>
            <this.todaysDealText />
          </View>
          <this.CountDownTimer />
        </View>

        <this.ClaimBoostedOfferCtaContainer />
      </View>
    );
  }
}
const { ids, styles } = StyleSheet.create({
  separatorStyle: { color: '#262626', marginBottom: 2, marginLeft: isWebIOS() ? 1 : 2 },
  timeLabelStyle: {
    color: '#000',
    fontFamily: 'Roboto-Regular',
    fontSize: 10,
    alignSelf: 'flex-end',
    marginRight: 4,
  },
  digitTxtStyle: {
    color: '#173143',
    fontSize: 14,
    marginLeft: 0,
    marginBottom: 0,
    fontFamily: isWebIOS() ? 'Roboto-Regular' : 'Roboto-Bold',
  },
  digitStyle: { backgroundColor: '#FFF', marginLeft: 0 },
  todayDealsText: {
    color: Utility.isPresent(Config.PRODUCT_PAGE_PROMOTED_TEXT_COLOR)
      ? Config.PRODUCT_PAGE_PROMOTED_TEXT_COLOR
      : colors.green,
    fontSize: 14,
    fontFamily: 'Roboto-Medium',
  },
  productBlur: {
    width: Utility.getScreenWidth(),
    height: 48,
    position: 'absolute',
    opacity: 0.95,
  },
  innerContainer: {
    flexDirection: 'row',
    alignItems: 'center',

    paddingHorizontal: 12,
  },

  outerContainer: { marginTop: 4 },
  expireInText: {
    color: colors.foxyBlack,
    fontSize: size.h4,
    marginLeft: 4,
    marginRight: 4,
    fontFamily: 'Roboto-Regular',
  },

  expireInTextYellow: {
    color: '#EE9332',
    fontSize: size.h4,
    marginLeft: 8,
    marginRight: 4,
    fontFamily: 'Roboto-Regular',
  },

  row: {
    flexDirection: 'row',
    backgroundColor: 'white',
  },
  timerContainer: {
    flexDirection: 'row',
    paddingHorizontal: 12,
    marginTop: 4,
    alignItems: 'center',
  },
  labelImage: {
    height: 16,
    width: 82,
    resizeMode: 'contain',
    overflow: 'visible',
    justifyContent: 'center',
    marginRight: 4,
    '@media (min-width: 768px)': {
      marginRight: 0,
    },
  },
  labeText: {
    fontFamily: 'Roboto-Medium',
    fontSize: 11,
    color: colors.black,
    '@media (min-width: 768px)': {
      marginRight: 16,
    },
    ...Platform.select({
      web: { marginLeft: 14 },
      default: { marginLeft: 4 },
    }),
  },

  claimBoostedOfferCtaContainer: {
    flexDirection: 'column',
    alignItems: 'flex-end',
    // justifyContent: 'center',
    flex: 1,
    paddingTop: 4,
    paddingRight: 20,
  },
  claimBoostedOfferCta: {
    width: 74,
    height: 22,
    borderRadius: 16,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: Utility.isPresent(Config.PRODUCT_PAGE_BUY_NOW_BG_COLOR)
      ? Config.PRODUCT_PAGE_BUY_NOW_BG_COLOR
      : colors.green,
  },

  claimedBoostedOfferCta: {
    width: 87,
    height: 22,
    borderRadius: 16,
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    borderColor: Utility.isPresent(Config.LINEAR_GRADIENT_SECONDARY)
      ? `${Config.LINEAR_GRADIENT_SECONDARY}80`
      : colors.dim_green,
    borderWidth: 1,
  },

  claimedBoostedOfferCtaForActivate: {
    width: 87,
    height: 22,
    borderRadius: 16,
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: colors.green,
  },

  claimBoostedOfferCtaText: {
    fontFamily: 'Roboto-Medium',
    color: Utility.isPresent(Config.PRODUCT_PAGE_FLASH_DEAL_CLAIM_TEXT_COLOR)
      ? Config.PRODUCT_PAGE_FLASH_DEAL_CLAIM_TEXT_COLOR
      : colors.white,
    fontSize: size.h4,
  },
  claimedBoostedOfferCtaText: {
    fontFamily: 'Roboto-Medium',
    color: Config.LINEAR_GRADIENT_SECONDARY ?? colors.green,
    fontSize: size.h4,
  },
  claimedBoostedOfferCtaTextForActivation: {
    fontFamily: 'Roboto-Medium',
    color: colors.white,
    fontSize: size.h4,
  },
  claimBoostedOfferCtaSubText: {
    fontFamily: 'Roboto-Regular',
    color: colors.subtitle,
    fontSize: size.h6,
    marginTop: 4,
  },

  checkIcon: {
    height: 10,
    width: 10,
    marginRight: 2,
    marginTop: 2,
  },

  pinkPromptText: {
    fontSize: size.h3,
    fontFamily: 'Roboto-Regular',
    color: Config.PRODUCT_DISCOUNT_COLOR,
    marginHorizontal: 12,
  },
  couponCodePillStyle: {
    backgroundColor: Utility.isPresent(Config.LINEAR_GRADIENT_SECONDARY)
      ? `${Config.LINEAR_GRADIENT_SECONDARY}1A`
      : '#01B46012',
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 3,
    paddingBottom: 3,
    borderColor: Config.LINEAR_GRADIENT_SECONDARY ?? '#01B560',
  },
  couponCodePillStyleMember: {
    backgroundColor: '#FFEEAE',
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 3,
    paddingBottom: 3,
    borderColor: '#FE9800',
  },
  clubDealContainer: { position: 'absolute', right: 0, top: 8 },
  clubMembersRightContainer: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 16,
  },
  clubStars: { height: 24, width: 24, resizeMode: 'contain', marginBottom: 4 },
  clubText: {
    fontSize: 8,
    fontFamily: 'Roboto-Regular',
    color: colors.subtitle,
  },
  todayDealShimmerOne: {
    height: 12,
    width: 90,
    borderRadius: 4,
    marginTop: 10,
  },
  todayDealShimmerTwo: {
    height: 24,
    width: 80,
    borderRadius: 12,
    marginTop: 2,
    marginBottom: 12,
  },
  todayDealShimmerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: getScreenWidth() - 32,
    alignSelf: 'center',
    backgroundColor: 'white',
    '@media (min-width: 768px)': {
      width: PRODUCT_RIGHT_CONTAINER_WIDTH,
      paddingHorizontal: 10,
    },
  },
  todayDealShimmerThree: {
    height: 12,
    width: 180,
    borderRadius: 4,
    marginTop: 10,
  },
});

const mapStateToProps = (state) => {
  return {
    todayDeals: state.todayDeals,
    authToken: state.UserAccountInfo.authToken,
    cartCoupons: state.bag.cartPricing.coupon_codes,
    boostedOffers: state.boostedOffers,
    cartItems: state.bag.cartItems,
  };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      setPhoneNumberModalInfo,
      addContinueAction,
    },
    dispatch,
  ),
});

export default withProfiledNavigation(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(applyOffer(withGreenText(ProductTodayDeals))),
);
