import React from 'react';
import { FlatList, StyleSheet } from 'react-native';
import { useSelector } from 'react-redux';
import { FlatListPerformanceView } from '../../libraries/ReactNativePerformanceShopify';
import { SIZE, LAYOUT, THEME } from '../../config/Constants';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import FullWidthDivider from '../../utils/FullWidthDivider';
import Utility from '../../utils/Utility';
import colors from '../../theme/Colors';
import CartItemsComboHeader from '../../containers/cart/CartItemsComboHeader';
import RoutineCardForCart from '../Product/RoutineCardForCart';
import LoyaltyPlanCardForCart from '../Product/LoyaltyPlanCardForCart';
import { ProductCardForCart } from '../Product';
import ProductCardForCartRetail from '../../containers/retailStore/components/Product/ProductCardForCart';


const getProductCard = (itemData, retail) => {
  const { routine = {}, loyalty_plan } = itemData;
  if (Utility.isPresent(routine)) {
    return RoutineCardForCart;
  }
  if (Utility.isPresent(loyalty_plan)) {
    return LoyaltyPlanCardForCart;
  }
  return retail ? ProductCardForCartRetail : ProductCardForCart;
};

// Since Offers only have offer_id as unique identifier, offer_id is used. For products sku_id is used
const keyExtractor = (item, index) =>
  `${item?.offer_id?.toString()}_${item?.sku_id?.toString()}_${index}}`;

const CartItem = ({ cartItem, navigation, showToast, hideAddToCart }) => {
  let newCart = cartItem;
  if (Utility.isPresent(cartItem.offer)) {
    newCart = { ...cartItem, product: cartItem.offer };
  }

  if (cartItem.quantity === 0) {
    return null;
  }
  if (Utility.isBlank(cartItem.sku_id)) {
    return <CartItemsComboHeader item={cartItem} />;
  }
  const boostedOffers = useSelector((state) => state.boostedOffers);
  const LayoutComponent = getProductCard(newCart);
  return (
    <>
      <LayoutComponent
        size={SIZE.large}
        layout={LAYOUT.CART}
        cart
        id={newCart?.productId}
        itemData={newCart}
        navigation={navigation}
        theme={THEME.LIGHT}
        previousScreen={SCREEN_CONSTANTS.CART}
        skuId={newCart?.sku_id}
        quantity={newCart?.quantity}
        cartItem={newCart}
        campaignId={newCart?.campaign_id}
        cartProduct={newCart?.product}
        error={newCart?.error}
        showToast={showToast}
        hideAddToCart={hideAddToCart}
        isDigitalSku={!!cartItem?.offer}
        boostedOffers={boostedOffers}
      />
      {!hideAddToCart && <FullWidthDivider />}
    </>
  );
};

const CartItems = ({
  cartItems = [],
  navigation,
  showToast,
  hideAddToCart,
}) => {
  if (Utility.isBlank(cartItems)) {
    return null;
  }

  return (
    <FlatListPerformanceView listName={'cart_items'}>
      <FlatList
        data={cartItems}
        renderItem={({ item }) => (
          <CartItem
            cartItem={item}
            navigation={navigation}
            showToast={showToast}
            hideAddToCart={hideAddToCart}
          />
        )}
        keyExtractor={keyExtractor}
        style={styles.container}
        extraData={cartItems}
      />
    </FlatListPerformanceView>
  );
};

const styles = StyleSheet.create({
  container: { backgroundColor: colors.white },
});

export default CartItems;
