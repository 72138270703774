// Dependencies
import React, { Component } from 'react';
import { FlatList, ScrollView } from 'react-native';
import PropTypes from 'prop-types';
import { LAYOUT, ORIENTATION } from '../../../config/Constants';
import Utility from '../../../utils/Utility';
// Components and Others
import Collection from '../../../containers/collection/Collection';
import { Tag } from '../../../containers/tag';
import styles from './styles';
import { COLLECTION } from '../../../config/LayoutConstants/CollectionConfig';
import ErrorBoundaryComponent from '../../shared/ErrorBoundaryComponent';
import { SCREEN_CONSTANTS } from '../../../config/ScreenConstants';
import { isDesktop } from '../../../utils/BooleanUtility';
import { FlatListPerformanceView } from '../../../libraries/ReactNativePerformanceShopify';
import { getListSource } from '../../../utils/PerfUtility';

class GridRail extends Component {
  static Components = {
    product_category: Collection,
    list: Collection,
    brand: Collection,
    ingredient: Collection,
    tag: Tag,
  };

  static getComponentHeight({ content }) {
    return GridRail.getCardHeight(GridRail.Components[content]) * 2;
  }

  static getCardHeight(component) {
    switch (component) {
      case Collection:
        return (
          8 + COLLECTION.CARD['colouredRectangleRail']['defaultSize'].viewheight
        );
      default:
        return 0;
    }
  }

  constructor(props) {
    super(props);
    this.displayCount = this.props.displayCount || 0;
    this.itemData = this.props.item.filter((item) => !!item);
    const itemDataLength = this.itemData.length;
    this.count = (isDesktop() && itemDataLength <= 4) ? 4 : Math.ceil(itemDataLength / 2);
  }

  getComponent = ({ item, index }) => {
    if (Utility.isBlank(item)) {
      return null;
    }
    const {
      size,
      content,
      navigation,
      maxFreeItemsToSelect,
      showToast,
      display,
      previousScreen,
      brandId,
      listIndex,
      extraEventParameters = {},
      categoryIds = [],
      listData = {},
      listData: { slug: listSlug = '' },
      listName = '',
      listContent = '',
    } = this.props;
    const ContainerComponent = GridRail.Components[content];
    if (item === undefined || ContainerComponent === undefined) {
      return null;
    }
    return (
      <ErrorBoundaryComponent
        itemData={item}
        listData={listData}
        screenName={previousScreen}
      >
        <ContainerComponent
          orientation={ORIENTATION.VERTICAL}
          id={item.id}
          itemData={item}
          layout={LAYOUT.GRIDRAIL}
          navigation={navigation}
          type={item.type}
          size={size}
          refreshOfferStrip={this.props.refreshOfferStrip}
          maxFreeItemsToSelect={maxFreeItemsToSelect}
          showToast={showToast}
          display={display}
          index={index}
          listIndex={listIndex}
          previousScreen={previousScreen}
          brandId={brandId}
          categoryIds={categoryIds}
          listSlug={listSlug}
          listData={listData}
          extraEventParameters={extraEventParameters}
          listName={listName}
          listContent={listContent}
          screenName={previousScreen}
        />
      </ErrorBoundaryComponent>
    );
  };

  getItemLayout = (item, index) => {
    const { content } = this.props;
    const height = GridRail.getCardHeight(GridRail.Components[content]);
    return { length: height, offset: height * index, index };
  };

  render() {
    return (
      <ScrollView horizontal style={styles.gridContainer} showsHorizontalScrollIndicator={false}>
        <FlatListPerformanceView listName={`${getListSource(this, this.props)}`}>
          <FlatList
            data={this.itemData.slice(0, this.displayCount)}
            renderItem={this.getComponent}
            showsHorizontalScrollIndicator={false}
            numColumns={this.count}
            keyExtractor={(item) => `${item.schema}_${item.id}_gridrail`}
            style={styles.gridFlatlistStyles}
            getItemLayout={this.getItemLayout}
          />
        </FlatListPerformanceView>
      </ScrollView>
    );
  }
}

GridRail.propTypes = {
  list: PropTypes.shape({
    objects: PropTypes.array,
    size: PropTypes.string,
  }),
  item: PropTypes.array,
};

export default GridRail;
