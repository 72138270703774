import { size } from 'lodash';
import React, { PureComponent } from 'react';
import {
  View,
  Text,
  StyleSheet,
  Keyboard,
  Image,
  Platform,
  KeyboardAvoidingView,
  ActivityIndicator,
} from 'react-native';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  AnalyticsManager,
  EventParameterKey,
  EventParameterValue,
  EventType,
} from '../../analytics';
import FastImageView from '../../components/FastImageView';
import DebouncedTouchableOpacity from '../../components/shared/DebouncedTouchableOpacity';
import { REMOTE_CONFIG_KEYS } from '../../config/Constants';
import NavigationService from '../../navigator/NavigationService';
import colors from '../../theme/Colors';
import images from '../../theme/Images';
import ContactsUtility from '../../utils/ContactsUtility';
import RemoteConfig from '../../utils/RemoteConfig';
import Utility from '../../utils/Utility';
import InviteCodeTextField from '../FoxyEdge/InviteCodeTextField';
import {
  inviteCodeValidate,
  inviteCodeClaim,
  setCongratsModalForEdge,
} from '../../actions/FoxyEdgeAction';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import withNavigation from '../../utils/WithNavigation';

class EnterInviteCodeView extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      inviteCode: '',
      itemData: {},
      error: '',
      success: false,
      isLoading: false,
    };
    this.fetchRemoteConfigStrings();
    this.textFieldRef = null;
  }

  onChangeText = (value) => {
    this.setState({
      inviteCode: `${value}`,
      error: '',
      success: false,
      itemData: {},
    });
  };

  fetchRemoteConfigStrings = () => {
    const { how_to_become_eligible_info: foxyEdgeModalSteps = [] } =
      Utility.jsonParser(
        RemoteConfig.getValue(REMOTE_CONFIG_KEYS.invite_center_strings),
      );

    this.stepsToShow = foxyEdgeModalSteps;
  };

  toggleModal = () => {
    const { isModalVisible, setModalVisible } = this.props;
    if (isModalVisible) {
      setModalVisible(false, '');
    }
  };

  onPressInviteContacts = () => {
    const {
      itemData = {},
      itemData: { id, duration } = {},
      setModalVisible,
    } = this.props;

    setModalVisible(false, '');
    this.fireModalActionEven(EventParameterValue.FOXY_EDGE.VIEW_OTHER_OPTIONS);

    setTimeout(() => {
      NavigationService.navigate('InviteContacts', {
        itemData,
      });
    }, 400);
  };

  getModalName = () => {
    const { referralModalType = '' } = this.props;
    switch (referralModalType) {
      case 'invite_request_modal':
        return 'invite_request_modal';
      default:
        return 'invite_request_modal';
    }
  };

  fireModalActionEven = (cta) => {
    AnalyticsManager.logEvent(EventType.MODAL_ACTION, {
      [EventParameterKey.MODAL_NAME]: this.getModalName(),
      [EventParameterKey.CTA]: cta,
    });
  };

  RenderDashedLine = ({ index }) => {
    if (index + 1 === this.stepsToShow?.length) return null;
    return <Image style={styles.horizontalLine} source={images.dashedLine} />;
  };

  bottomButtons = () => {
    return (
      <>
        <this.howToBecomeEligibleButton />
        <this.continueButton />
      </>
    );
  };

  howToBecomeEligibleButton = () => {
    const { onPressHowToBecomeEligible } = this.props;
    return (
      <DebouncedTouchableOpacity
        {...this.props}
        style={styles.becomeEligibleButtonContainer}
        onPress={onPressHowToBecomeEligible}
      >
        <Text style={styles.becomeEligibleButtonText}>
          How to become eligible
        </Text>
      </DebouncedTouchableOpacity>
    );
  };

  continueButton = () => {
    const {
      inviteCode = '',
      itemData: { invitation_for },
      isLoading,
    } = this.state;
    const { fromInviteCenter = false } = this.props;
    let buttonStyle = Utility.isPresent(inviteCode)
      ? [styles.continueButtonContainer, { backgroundColor: colors.black }]
      : styles.continueButtonContainer;
    let cta = 'Verify Invite Code';
    if (Utility.isPresent(invitation_for)) {
      cta = `Activate ${invitation_for}`;
    }
    if (fromInviteCenter) {
      buttonStyle = [
        buttonStyle,
        { marginBottom: (Utility.isIOS() ? 14 : 0) },
      ];
    }
    return (
      <DebouncedTouchableOpacity
        {...this.props}
        style={buttonStyle}
        disabled={Utility.isBlank(inviteCode) || isLoading}
        onPress={this.onContinuePress}
      >
        {isLoading ? (
          <ActivityIndicator animating color={colors.white} size="small" />
        ) : (
          <Text style={styles.buttonText}>{cta}</Text>
        )}
      </DebouncedTouchableOpacity>
    );
  };

  onContinuePress = () => {
    const {
      inviteCode,
      itemData: { status = '' },
    } = this.state;
    const {
      inviteCodeValidate,
      inviteCodeClaim,
      navigation,
      toggleModal = () => {},
      membership_cohort,
    } = this.props;
    this.setState({
      isLoading: true,
    });

    if (status !== 'valid') {
      AnalyticsManager.logEvent('modal_action', {
        type: 'invite_code',
        cta: 'verify',
        code: inviteCode,
      });
      inviteCodeValidate(`${inviteCode}`.toUpperCase(), (success, response) => {
        this.setState({
          isLoading: false,
        });
        if (!success) return;
        if (response?.error) {
          this.setState({
            error: response?.error,
          });
          return;
        }
        this.setState({
          itemData: response,
          success: true,
        });
      });
      return;
    }
    AnalyticsManager.logEvent('modal_action', {
      type: 'invite_code',
      cta: 'claim',
      code: inviteCode,
    });
    const { itemData: { invitation_for = '' } = {} } = this.state;
    AnalyticsManager.logEvent(EventType.EDGE.MEMBERSHIP_ACTIVATED, {
      [EventParameterKey.MEMBERSHIP_TYPE]: invitation_for,
      status: 'started',
      membership_cohort,
    });
    inviteCodeClaim(inviteCode, (success, response) => {
      this.setState({
        isLoading: false,
      });
      if (!success) return;
      const { setCongratsModalForEdge } = this.props;

      toggleModal(true);
      AnalyticsManager.logEvent(EventType.EDGE.MEMBERSHIP_ACTIVATED, {
        [EventParameterKey.MEMBERSHIP_TYPE]: invitation_for,
        status: 'final',
        membership_cohort: 'member',
      });
      AnalyticsManager.logEvent(EventType.EDGE.EDGE_ACTIVE, {
        [EventParameterKey.MEMBERSHIP_TYPE]: invitation_for,
      });
      setCongratsModalForEdge(true);
      NavigationService.push('TheEdge', {
        source: SCREEN_CONSTANTS.LOYALTY_PLANS,
      });
    });
  };

  edgeCodeView = () => {
    return (
      <>
        <FastImageView
          style={styles.imageView}
          source={images.edgeFeatureCard}
        />
      </>
    );
  };

  inviteCodeViewContainer = () => {
    const { error, inviteCode = '', success = false } = this.state;
    return (
      <View style={styles.modalContainer}>
        <Text style={styles.modalHeading}>Enter your invite code</Text>
        <Text style={styles.modalSubheading}>
          that you received in your Foxy Pass / from a friend
        </Text>
        <this.edgeCodeView />
        <InviteCodeTextField
          onChangeText={this.onChangeText}
          error={error}
          inviteCode={inviteCode}
          success={success}
          assignRef={this.assignRef}
        />
        <this.bottomButtons />
      </View>
    );
  };

  render() {
    const { safeAreaBottomInset } = this.state;

    return Platform.select({
      ios: (
        <KeyboardAvoidingView
          behavior="position"
          contentContainerStyle={{ marginBottom: safeAreaBottomInset }}
        >
          <this.inviteCodeViewContainer />
        </KeyboardAvoidingView>
      ),
      android: <this.inviteCodeViewContainer />,
      web: <this.inviteCodeViewContainer />,
    });
  }
}

const mapStateToProps = (state) => ({
  membership_cohort: state.todayDeals.membership_cohort,
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      inviteCodeValidate,
      inviteCodeClaim,
      setCongratsModalForEdge,
    },
    dispatch,
  ),
});

export default withNavigation(
  connect(mapStateToProps, mapDispatchToProps)(EnterInviteCodeView),
);

const styles = StyleSheet.create({
  modalContainer: {
    width: Utility.getScreenWidth(),
    marginBottom: 0,
    paddingHorizontal: 12,
    paddingVertical: 24,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    overflow: 'hidden',
    backgroundColor: colors.white,
    borderTopLeftRadius: 25,
    borderTopRightRadius: 25,
  },

  footerTextContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 10,
    marginBottom: 12,
    width: '100%',
  },

  imageView: {
    marginVertical: 12,
    height: (Utility.getScreenWidth() - 24) * (146 / 344),
    width: Utility.getScreenWidth() - 24,
  },

  footerText: {
    color: colors.black,
    fontSize: size.h4,
    fontFamily: 'Roboto-Regular',
    textAlign: 'center',
  },

  footerTextBold: {
    color: colors.black,
    fontSize: size.h4,
    fontFamily: 'Roboto-Bold',
    textAlign: 'center',
  },

  becomeEligibleButtonContainer: {
    backgroundColor: colors.white,
    borderRadius: 4,
    borderWidth: 1.5,
    borderColor: colors.black,
    height: 40,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  continueButtonContainer: {
    backgroundColor: '#979BAA',
    borderRadius: 4,
    height: 40,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 6,
  },
  becomeEligibleButtonText: {
    fontSize: size.h2,
    fontFamily: 'Roboto-Medium',
    color: colors.foxyBlack,
  },
  buttonText: {
    fontSize: size.h2,
    fontFamily: 'Roboto-Medium',
    color: colors.white,
  },
  couponCodeButtonText: {
    fontSize: size.h2,
    fontFamily: 'Roboto-Medium',
    color: colors.foxyBlack,
  },
  point: {
    width: 6,
    height: 6,
    backgroundColor: colors.black,
    borderRadius: 3,
  },

  horizontalLine: {
    height: 40,
    width: 2,
    left: 4,
    marginTop: -12,
    marginBottom: -12,
  },

  listContainer: {
    marginTop: 24,
    marginBottom: 24,
  },

  listItemText: {
    fontSize: size.h3,
    fontFamily: 'Roboto-Regular',
    color: colors.black,
    marginLeft: 12,
  },
  listItemTextBold: {
    fontSize: size.h3,
    fontFamily: 'Roboto-Bold',
    color: colors.black,
    marginLeft: 15,
  },

  listItemContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },

  pointStyle: {
    borderWidth: 1,
    height: 12,
    width: 12,
    borderRadius: 6,
    alignItems: 'center',
    justifyContent: 'center',
    borderColor: colors.white,
  },

  currentPointStyle: {
    borderWidth: 1,
    height: 12,
    width: 12,
    borderRadius: 6,
    alignItems: 'center',
    justifyContent: 'center',
    borderColor: colors.black,
  },

  bottomModal: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    marginBottom: 0,
    marginRight: 0,
    marginLeft: 0,
    paddingHorizontal: 12,
    paddingVertical: 24,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    overflow: 'hidden',
    backgroundColor: colors.white,
    borderTopLeftRadius: 25,
    borderTopRightRadius: 25,
  },

  modalHeading: {
    color: colors.black,
    fontSize: size.h1,
    fontFamily: 'Roboto-Bold',
  },
  modalSubheading: {
    color: colors.black,
    fontSize: size.h3,
    fontFamily: 'Roboto-Regular',
  },
  productCard: {
    marginLeft: 12,
  },
  seeHowButtonContainer: {
    backgroundColor: colors.white,
    borderRadius: 4,
    height: 40,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: colors.black,
    marginBottom: 12,
  },
  seeHowButtonText: {
    fontSize: size.h2,
    fontFamily: 'Roboto-Medium',
    color: colors.black,
  },
  seeHowBanner: {
    height: 70,
    width: Utility.getScreenWidth(),
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginTop: 24,
    backgroundColor: colors.referrals.golden,
    marginLeft: -12,
    paddingHorizontal: 12,
  },
  seeHowBannerText: {
    fontSize: size.h3,
    color: colors.foxyBlack,
    paddingRight: 12,
    fontFamily: 'Roboto-Regular',
    width: '80%',
  },
  seeHowBannerImage: {
    height: 62,
    width: 62,
  },
});
