const EventParameterKey = {
  FB: {
    /**
     * Parameter key used to specify currency used with logged event. E.g. "USD", "EUR", "GBP".
     * See <a href="http://en.wikipedia.org/wiki/ISO_4217">ISO-4217</a> for specific values.
     */
    EVENT_PARAM_CURRENCY: 'fb_currency',
    /**
     * Parameter key used to specify the method the user has used to register for the app, e.g.,
     * "Facebook", "email", "Twitter", etc.
     */
    EVENT_PARAM_REGISTRATION_METHOD: 'fb_registration_method',
    /**
     * Parameter key used to specify a generic content type/family for the logged event, e.g.
     * "music", "photo", "video". Options to use will vary depending on the nature of the app.
     */
    EVENT_PARAM_CONTENT_TYPE: 'fb_content_type',
    /**
     * Parameter key used to specify data for the one or more pieces of content being logged about.
     * Data should be a JSON encoded string. Example: "[{\"id\": \"1234\", \"quantity\": 2,
     * \"item_price\": 5.99}, {\"id\": \"5678\", \"quantity\": 1, \"item_price\": 9.99}]"
     */
    EVENT_PARAM_CONTENT: 'fb_content',
    /**
     * Parameter key used to specify an ID for the specific piece of content being logged about.
     * This could be an EAN, article identifier, etc., depending on the nature of the app.
     */
    EVENT_PARAM_CONTENT_ID: 'fb_content_id',
    /** Parameter key used to specify the string provided by the user for a search operation. */
    EVENT_PARAM_SEARCH_STRING: 'fb_search_string',
    /**
     * Parameter key used to specify whether payment info is available for the
     * EVENT_NAME_INITIATED_CHECKOUT event. EVENT_PARAM_VALUE_YES and EVENT_PARAM_VALUE_NO are good
     * canonical values to use for this parameter.
     */
    EVENT_PARAM_PAYMENT_INFO_AVAILABLE: 'fb_payment_info_available',
    /**
     * Parameter key used to specify how many items are being processed for an
     * EVENT_NAME_INITIATED_CHECKOUT or EVENT_NAME_PURCHASE event.
     */
    EVENT_PARAM_NUM_ITEMS: 'fb_num_items',
  },
  NETWORK_TYPE_INFO: 'network_type_info',
  NETWORK_CARRIER_NAME: 'network_carrier_name',
  NETWORK_UPLOAD_SPEED: 'network_upload_speed',
  NETWORK_DOWNLOAD_SPEED: 'network_download_speed',
  NETWORK_STRENGTH: 'network_strenght',
  NETWORK_GENERATION: 'network_generation',
  AASM_STATE: 'aasm_state',
  AMOUNT: 'amount',
  APP_LAST_OPEN_AT: 'app_last_open_at',
  APP_STATE: 'app_state',
  ARTIST_ID: 'artist_id',
  ARTIST_NAME: 'artist_name',
  ARTIST_TYPE_ID: 'artist_type_id',
  ARTIST_TYPE_NAME: 'artist_type_name',
  ATTRIBUTE_COUNT: 'attribute_count',
  ATTRIBUTE_ID: 'attribute_id',
  ATTRIBUTE_NAME: 'attribute_name',
  AUTOCOMPLETE_RESULT: 'autocomplete_result',
  BRAND_ID: 'brand_id',
  BRAND_NAME: 'brand_name',
  BUFFERING_DURATION: 'buffering_duration',
  BUTTON: 'button',
  CAMPAIGN_ID: 'campaign_id',
  CAMPAIGN_NAME: 'campaign_name',
  CARD_ID: 'card_id',
  CARD_IDS: 'card_ids',
  CARD_TITLE: 'card_title',
  CART_TOTAL: 'cart_total',
  CASE: 'case',
  CONTENT_TYPE: 'content_type',
  DIGIT: 'digit',
  CLICKED_PRICE: 'clicked_price',
  CLICKED_SITE: 'clicked_site',
  COD_ELIGIBLE: 'cod_eligible',
  COD_FEE: 'cod_fee',
  CODE: 'code',
  COUNTDOWN_TIME: 'count_down_time',
  COUPON_CODE: 'coupon_code',
  CURRENCY: 'currency',
  PAYMENT_METHOD_SAVED_STATUS: 'payment_method_saved_status',
  PAYMENT_METHOD_SAVED_NAME: 'payment_method_saved_name',
  CURRENT_TIME: 'current_time',
  DELIVERY_FEE: 'delivery_fee',
  DETECTED_VALUE: 'detected_value',
  DISCOUNT: 'discount',
  ENTITY: 'entity',
  ERROR_TYPE: 'error_type',
  ERROR: 'error',
  EXTRA: 'extra',
  FILE_SIZE: 'file_size',
  GENDER: 'gender',
  ID: 'id',
  IMAGE_URL: 'image_url',
  IN_STOCK: 'in_stock',
  STOCK_STATUS: 'stock_status',
  IN_STOCK1_COUNT: 'in_stock1_count',
  INPUT_METHOD: 'input_method',
  IS_PRIORITY: 'is_priority',
  IS_WELCOME_VIDEO: 'is_welcome_video',
  ITEM_COUNT: 'item_count',
  ITEM_ID: 'item_id',
  ITEM_LIST_NAME: 'item_list_name',
  ITEM_LIST_ID: 'item_list_id',
  ITEM_NAME: 'item_name',
  ITEM_POSITION: 'item_position',
  ITEM_TOTAL: 'item_total',
  ITEM_TYPE: 'item_type',
  ITEMS: 'items',
  LAST_EVENT_NAME: 'last_event_name',
  LINK_NAME: 'link_name',
  LIST_ID: 'list_id',
  LIST_NAME: 'list_name',
  LIST_POSITION: 'list_position',
  LIST_CONTENT: 'list_content',
  LIST_TAGS: 'list_tags',
  LIST_DISPLAY: 'list_display',
  LIST: 'list',
  LOAD_TIME: 'load_time',
  MEDIUM: 'medium',
  METHOD: 'method',
  MODAL_NAME: 'modal_name',
  MESSAGE: 'message',
  MILESTONE: 'milestone',
  NEW_VALUE: 'new_value',
  NOTIFICATION_STATUS: 'notification_status',
  LIVE_ACTIVITY_ENABLED: 'live_activity_enabled',
  OFFER_ID: 'offer_id',
  OFFER_TYPE: 'offer_type',
  IS_COMBO_OFFER: 'is_combo_offer',
  OFFER_APPLIED_BY: 'offer_applied_by',
  OLD_VALUE: 'old_value',
  ORDER_ID: 'order_id',
  PAYMENT_METHOD: 'payment_method',
  PAYMENT_TYPE: 'payment_type',
  PERSONALISED: 'personalised',
  PLAY_TIME: 'play_time',
  PREPAID_ONLY: 'prepaid_only',
  PHONE_NUMBER: 'phone_number',
  PRODUCT_CATEGORY_ID: 'product_category_id',
  PRODUCT_CATEGORY_NAME: 'product_category_name',
  PRODUCT_COUNT: 'product_count',
  PRODUCT_ID: 'product_id',
  PRODUCT_LISTING_PRICE: 'product_listing_price',
  PAGE_PRODUCT_STATUS: 'page_product_status',
  PRODUCT_NAME: 'product_name',
  PRODUCT_SELLING_PRICE: 'product_selling_price',
  PROMO_CODE_APPLIED: 'promo_code_applied',
  QUANTITY: 'quantity',
  QUERY_SOURCE: 'query_source',
  QUERY_TIME: 'query_time',
  RAW_ERROR: 'raw_error',
  REASON: 'reason',
  RESPONSE_STATUS: 'response_status',
  RESULT_COUNT: 'result_count',
  RETRY_COUNT: 'retry_count',
  SAVED_CARD_COUNT: 'saved_card_count',
  SCREEN_NAME: 'screen_name',
  SCRUB_END_TIME: 'scrub_end_time',
  SCRUB_START_TIME: 'scrub_start_time',
  SEARCH_FILTER: 'search_filter',
  SEARCH_QUERY: 'search_query',
  SEARCH_TERM: 'search_term',
  SKU_ID: 'sku_id',
  STOCKED_STATUS: 'stocked_status',
  SOURCE: 'source',
  INITIAL_BLOCKED: 'initial_blocked',
  STATE: 'state',
  STATUS_CODE: 'status_code',
  TIME_TAKEN: 'time_taken',
  TOTAL_VALUE: 'total_value',
  TOTAL_MRP: 'total_mrp',
  TOTAL_SKU_QUANTITY: 'total_sku_quantity',
  FREE_SKU_QUANTITY: 'free_sku_quantity',
  SALE_VALUE: 'sale_value',
  TXN_ID: 'txn_id',
  TRANSACTION_ID: 'transaction_id',
  TYPE: 'type',
  VALUE: 'value',
  VARIANT_COUNT: 'variant_count',
  VARIANT_ID: 'variant_id',
  VARIANT_NAME: 'variant_name',
  VIDEO_CATEGORY_ID: 'video_category_id',
  VIDEO_CATEGORY_NAME: 'video_category_name',
  VIDEO_DURATION: 'video_duration',
  VIDEO_ID: 'video_id',
  VIDEO_TITLE: 'video_title',
  VIDEO_TYPE: 'video_type',
  LINK_TYPE: 'link_type',
  URL: 'url',
  PATH: 'path',
  SLUG: 'slug',
  LIST_SLUG: 'list_slug',
  SCORE: 'score',
  USER_STATE: 'user_state',
  ROUTE: 'route',
  LINK_EXTRA_DATA: 'link_extra_data',
  CONTACTLESS: 'contactless',
  APP_VERSION: 'app_version',
  MIN_ALLOWED_ANDROID_VERSION: 'min_allowed_android_version',
  MIN_ALLOWED_IOS_VERSION: 'min_allowed_ios_version',
  REMOTE_CONFIG_LAST_CHANGED: 'remote_config_last_changed',
  ADDRESS_PRESENT: 'address_present',
  ADDRESS_ID: 'address_id',
  PIN_CODE: 'pin_code',
  CITY: 'city',
  IS_GUEST_USER: 'is_guest_user',
  COUPON_CODE_APPLIED: 'coupon_code_applied',
  PRODUCT_STOCKED_STATUS: 'product_stocked_status',
  PRODUCTS_RESULT_COUNT: 'products_result_count',
  LISTS_RESULT_COUNT: 'lists_result_count',
  MEDIA_RESULT_COUNT: 'media_result_count',
  ARTISTS_RESULT_COUNT: 'artists_result_count',
  BRANDS_RESULT_COUNT: 'brands_result_count',
  OPTED_FOR_PLASTIC_FREE_PACKAGING: 'opted_for_plastic_free_packaging',
  LAST_PAYMENT_METHOD: 'last_payment_method',
  LAST_PAYMENT_METHOD_NAME: 'last_payment_method_name',
  OVERALL_NOTIFICATIONS_ENABLED: 'overall_notifications_enabled',
  NOTIFICATIONS_CHANNELS_ENABLED: 'notification_channels_enabled',
  DISABLED_NOTIFICATION_CHANNELS: 'disabled_notification_channel',
  COD_ERROR_CODE: 'cod_error_code',
  COD_ERROR_MESSAGE: 'cod_error_message',
  IOS_NOTI_PER: 'ios_noti_per',
  ACTION: 'action',
  ZOOM: 'zoom',
  INDEX: 'index',
  FILTER_OPTION: 'filter_option',
  FILTER_VALUE: 'filter_value',
  FILTER_POSITION: 'filter_position',
  FILTERS_OPTION_VISIBLE: 'filters_options_visible',
  CLICK: 'click',
  TIME_STAMP: 'time_stamp',
  DIFF_NATIVE_LOAD: 'diff_native_load',
  DIFF_JS_LOAD: 'diff_js_load',
  DIFF_FEED_LOAD_START: 'diff_feed_load_start',
  PAYMENT_ACCOUNT: 'payment_account',
  PAYMENT_VALUE: 'payment_value',
  PAYMENT_STATE: 'payment_state',
  TAG_COUNT: 'tag_count',
  HAS_DESCRIPTION: 'has_description',
  RATING_COUNT: 'rating_count',
  TEXT_REVIEW_COUNT: 'text_review_count',
  HAS_SOCIAL_VALIDATION: 'has_social_validation',
  HAS_TRUST: 'has_trust',
  HAS_RANKING: 'has_ranking',
  RATING: 'rating',
  POSITIVE_INGREDIENT_COUNT: 'positive_ingredient_count',
  NEGATIVE_INGREDIENT_COUNT: 'negative_ingredient_count',
  HERO_INGREDIENTS: 'hero_ingredient',
  ELEMENT_TYPE: 'element_type',
  ELEMENT_NAME: 'element_name',
  IS_CACHED: 'is_cached',
  LOAD_TO_PLAY_TIME: 'load_play_to_time',
  TOPICS_COUNT: 'topics_count',
  VIDEOS_COUNT: 'videos_count',
  PUBLISHER_ID: 'publisher_id',
  PUBLISHER_NAME: 'publisher_name',
  PUBLISHER_TYPE: 'publisher_type',
  ENTITY_TYPE: 'entity_type',
  ENTITY_NAME: 'entity_name',
  ENTITY_ID: 'entity_id',
  LOGIN: 'login',
  SELFIE: 'selfie',
  PAGE_NO: 'page_number',
  CTA: 'cta',
  FIELD_NAME: 'field_name',
  offer_id: 'offer_id',
  STATUS: 'status',
  ESP: 'esp',
  CONTACT_NAME: 'contact_name',
  INVITATION_TYPE: 'invitation_type',
  MEMBER_COUNT: 'member_count',
  MRP: 'mrp',
  GROUP_DEAL_PRICE: 'group_deal_price',
  GROUP_DEAL_SIZE: 'group_deal_size',
  GROUP_ID: 'group_id',
  modal_name: 'modal_name',
  IS_LIKED: 'is_liked',
  SCAN_INITIATED: 'scan_initiated',
  SCAN_SUCCESS: 'scan_success',
  SCAN_FAILURE: 'scan_failure',
  SALON_NAME: 'salon_name',
  GROUP_INVITATION_ID: 'group_invitation_id',
  BROKEN_CONTACT: 'broken_contact',
  FIREBASE: 'firebase',
  UTM: {
    SOURCE: 'source',
    CAMPAIGN: 'campaign',
    MEDIUM: 'medium',
  },
  PREVIOUS_SCREEN: 'previousScreen',
  POSITION: 'position',
  NEGATIVE_INGREDIENTS: 'negative_ingredients',
  POSITIVE_INGREDIENTS: 'positive_ingredients',
  INGREDIENT_COUNT: 'INGREDIENT_COUNT',
  WISHLIST_DEAL_PRICE: 'wishlist_deal_price',
  EDGE_DEAL_PRICE: 'edge_deal_price',
  wishlist_deal_discount: 'wishlist_deal_discount',
  flashdeal_active: 'flashdeal_active',
  FOXY_HANDLE: 'foxy_handle',
  PAGE: 'page',
  NAME: 'name',
  IS_LOGGED_IN: 'is_logged_in',
  TEXT: 'text',
  BOOSTED_OFFER: 'boosted_offer',
  IS_BOOSTED: 'is_boosted',
  POINTS: 'points',
  MODE: 'mode',
  STORE_ID: 'store_id',
  USN: 'usn',
  ROUTINES: {
    ROUTINE_NAME: 'routine_name',
    ROUTINE_PRICE: 'routine_price',
    ROUTINE_ID: 'routine_id',
    PAGE_TYPE: 'page_type',
    ROUTINE_DAY: 'routine_day',
    IS_CHECKED: 'is_checked',
    CARD_ID: 'card_id',
    CARD_LIVE_TIME: 'card_live_time',
    CARD_CLICK_TIME: 'card_click_time',
    MEMBERSHIP_COHORT: 'membership_cohort',
    OFFER_PRICE: 'offer_price',
    ROUTINE_STATUS: 'routine_status',
  },
  user_mobile_number: 'user_mobile_number',
  address_mobile_number: 'address_mobile_number',
  MEMBERSHIP_TYPE: 'membership_type',
  MEMBERSHIP_STATUS: 'membership_status',
  MEMBERSHIP_ID: 'membership_id',
  DISPLAY: 'display',
  APP_FIRST_OPEN: 'app_first_open',
  CATEGORY_ID: 'category_id',
  CATEGORY_NAME: 'category_name',
  MATCHED_PRODUCTS_COUNT: 'matched_products_count',
  FILTER_TYPE: 'filter_type',
  FILTER_HIDE_OOS: 'filter_hide_oos',
  FILTER_INDEX: 'filter_index',
  FILTER_SECTION: 'filter_section',
  FILTER_FOXY_MATCH: 'filter_foxy_match',
  ITEM_ACTION_NEW: 'item_action_new',
  EMAIL_PRESENT: 'email_present',
  PERMISSION_NAME: 'permission_name',
};

export default EventParameterKey;
