import React, { PureComponent } from 'react';
import { View, TouchableOpacity, Image, FlatList, Text } from 'react-native';
import CardView from '../../libraries/ReactNativeCardView'; 
import { memoize, find, findKey, sortBy } from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { CURRENCY_SYMBOL } from '../../config/Constants';
import { ProductInfoStyles, ProductInCartStyles } from './styles';
import Utility from '../../utils/Utility';
import { withMaybe } from '../../lib/Monads';
import images from '../../theme/Images';
import {
  incrementDecrementCart,
  incrementDecrementCartBeforeCall,
} from '../../actions/ActionTypes';
import {
  AnalyticsManager,
  EventParameterKey,
  EventType,
} from '../../analytics';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import FastImageView from '../FastImageView';
import NetworkStats from '../../utils/NetworkStat';
import OffersUtil from '../../utils/OffersUtil';
import VariantUtility from '../../utils/VariantUtility';
import colors from '../../theme/Colors';
import { isDesktop, isWebMobile } from '../../utils/BooleanUtility';
import { getMinifiedImage } from '../../utils/ImageUtility';

class VariantList extends PureComponent {
  constructor(props) {
    super(props);
    const { type = '' } = props;
    this.numColumns = this.isTypeCart(type) ? 1 : 3;
    if (isDesktop()) {
      this.numColumns = 4;
    }
  }
  showDiscountRateConditionFn = (props) =>
    props.discount === null || props.discount === '';

  conditionalDiscountText = withMaybe(this.showDiscountRateConditionFn)(Text);

  discountConditionFn = (props) =>
    parseFloat(props.sp) === parseFloat(props.mrp);

  conditionalPriceText = withMaybe(this.discountConditionFn)(Text);

  variantAddedToCart = (type) => !Utility.isBlank(type) && type === 'cart';

  isTypeCart = (type) => !Utility.isBlank(type) && type === 'cart';

  incrementVariantInCart = (cartItemData) => {
    const {
      incrementDecrementCart,
      itemData,
      onItemAddToCartFromCollab,
      previousScreen,
      campaignId,
    } = this.props;
    const data = {
      sku_id: cartItemData.sku_id,
      quantity: +1,
    };
    if (campaignId) {
      data['campaign_id'] = campaignId;
    }
    if (previousScreen === 'brandCollab') {
      setTimeout(() => {
        this.props.incrementDecrementCartBeforeCall(data);
        this.props.incrementDecrementCart(data);
        if (onItemAddToCartFromCollab) {
          onItemAddToCartFromCollab();
        }
      }, 0);
    }
    this.props.incrementDecrementCartBeforeCall(data);
    incrementDecrementCart(data);
    if (Utility.isPresent(itemData)) {
      const quantity = cartItemData.quantity + 1;
      this.fireProductAddToCartEvent(itemData, quantity);
    }
  };

  decrementVariantInCart = (cartItemData) => {
    const {
      removeFromCart,
      incrementDecrementCart,
      itemData,
      onItemAddToCartFromCollab,
      previousScreen,
      campaignId,
    } = this.props;
    const data = {
      sku_id: cartItemData.sku_id,
      quantity: -1,
    };
    if (campaignId) {
      data['campaign_id'] = campaignId;
    }
    if (previousScreen === 'brandCollab') {
      this.props.incrementDecrementCartBeforeCall(data);
      incrementDecrementCart(data, () => {
        onItemAddToCartFromCollab();
      });
      return;
    }
    if (cartItemData.quantity === 1) {
      this.props.incrementDecrementCartBeforeCall(data);
      incrementDecrementCart(data);
      return;
    }
    this.props.incrementDecrementCartBeforeCall(data);
    incrementDecrementCart(data);
    if (Utility.isPresent(itemData)) {
      const quantity = cartItemData.quantity - 1;
      this.fireProductAddToCartEvent(itemData, quantity);
    }
  };

  getQuantityOfAParticularSku = (cartItems, skuId) => {
    let quantity = 0;
    if (Utility.isBlank(cartItems)) {
      return quantity;
    }
    const cartObject = find(cartItems, (item) => item.sku_id === skuId);
    quantity = Utility.isBlank(cartObject) ? 0 : cartObject.quantity;
    return quantity;
  };

  fireProductAddToCartEvent = (itemData, quantity) => {
    const {
      selectedVariant = {},
      selectedVariant: { sku: { group_buying_price = '' } = {} } = {},
      address,
      todayDeals: { skus = {} } = {},
      favourites: { products = {}, variants = {} } = {},
    } = this.props;
    const networkInfo = NetworkStats.networkDetails;
    const networkData = Utility.getNetworkInfo(networkInfo);
    const isLiked = Utility.checkIfProductOrVariantIsLiked(
      itemData,
      products,
      variants,
    );

    const productMeta = {
      ...networkData,
      [EventParameterKey.SCREEN_NAME]: SCREEN_CONSTANTS.VARIANTS,
      [EventParameterKey.SKU_ID]: selectedVariant.sku_id,
      [EventParameterKey.PRODUCT_ID]: itemData.id,
      [EventParameterKey.BRAND_ID]: itemData.brand.id,
      [EventParameterKey.PRODUCT_LISTING_PRICE]: itemData.mrp,
      [EventParameterKey.PRODUCT_SELLING_PRICE]: itemData.sp,
      [EventParameterKey.QUANTITY]: quantity,
      [EventParameterKey.VARIANT_ID]: selectedVariant.id,
      [EventParameterKey.ADDRESS_PRESENT]: Utility.isPresent(address),
      wishlist_deal_price:
        skus[`${selectedVariant?.sku_id}`]?.esp || 'not_eligible',
      edge_deal_price:
        skus[`${selectedVariant?.sku_id}`]?.member_esp || 'not_eligible',
      is_buy_now: false,
      [EventParameterKey.GROUP_DEAL_PRICE]: group_buying_price,
    };

    AnalyticsManager.logEvent(
      EventType.discoveryEvents.PRODUCT_ADDED_TO_CART,
      productMeta,
    );
    AnalyticsManager.logFirebaseEvent(
      EventType.googleRemarketingEvents.ADD_TO_CART,
      {
        [EventParameterKey.VALUE]: itemData.mrp,
        [EventParameterKey.CURRENCY]: 'INR',
        [EventParameterKey.ITEMS]: [{ id: selectedVariant.sku_id }],
      },
    );
    AnalyticsManager.logFBStandardEvent(
      EventType.FB.EVENT_NAME_ADDED_TO_CART,
      itemData.mrp,
      {
        [EventParameterKey.FB.EVENT_PARAM_CURRENCY]: 'INR',
        [EventParameterKey.FB
          .EVENT_PARAM_CONTENT_ID]: `${selectedVariant.sku_id}`,
        [EventParameterKey.FB.EVENT_PARAM_CONTENT_TYPE]: 'product',
        [EventParameterKey.FB.EVENT_PARAM_CONTENT]: JSON.stringify([
          {
            id: selectedVariant.sku_id,
            quantity: 1,
            item_price: itemData.mrp,
          },
        ]),
      },
    );
  };

  cartIncrementDecrementButton = (props) => {
    const { skuId, quantity } = props;
    const styles = ProductInCartStyles;
    const cartItemData = {
      sku_id: skuId,
      quantity,
    };
    return (
      <View style={styles.incrementDecrementSwitch}>
        <TouchableOpacity
          onPress={() => this.decrementVariantInCart(cartItemData)}
          style={styles.decrementButtonContainer}
        >
          <View style={styles.minus} />
        </TouchableOpacity>
        <Text
          allowFontScaling={false}
          style={styles.productQuantandVariantText}
        >
          {quantity}
        </Text>
        <TouchableOpacity
          onPress={() => this.incrementVariantInCart(cartItemData)}
          style={styles.incrementButtonContainer}
        >
          <Image source={images.plus} style={styles.plus} />
        </TouchableOpacity>
      </View>
    );
  };

  PricingComponent = (props) => {
    const { mrp, discount, showPrice, final_sp, additional_discount } = props;
    if (!showPrice) {
      return null;
    }
    const styles = ProductInfoStyles;

    const display_sp = final_sp;

    return (
      <View style={styles.priceContainer}>
        <this.conditionalPriceText
          style={styles.mrpStyle}
          sp={display_sp}
          mrp={mrp}
        >
          {`${CURRENCY_SYMBOL}${mrp}`}
        </this.conditionalPriceText>
        <Text style={styles.spStyle}>{`${CURRENCY_SYMBOL}${display_sp} `}</Text>
        {parseFloat(display_sp) !== parseFloat(mrp) && (
          <this.conditionalDiscountText
            style={ProductInfoStyles.discountRateStyle}
            discount={discount}
          >
            {`(${discount})`}
          </this.conditionalDiscountText>
        )}
      </View>
    );
  };

  discount = (props) => {
    const { discount } = props;

    return (
      <this.conditionalDiscountText
        style={ProductInfoStyles.discountRateStyle}
        discount={discount}
      >
        {`(${discount})`}
      </this.conditionalDiscountText>
    );
  };

  getVariantComponent = (attribute, showPrice) => {
    const {
      variant = {},
      value: primaryVariantDisplayName = '',
      image_url: primaryVariantImageUrl = '',
    } = attribute;
    let {
      display_name: displayName,
      image_url = '',
      sp,
      mrp,
      discount,
      outOfStock,
      offer_value,
      final_sp,
      offer_code,
      additional_discount,
      prompts = [],
    } = variant;
    const {
      selectedVariant,
      updateSelectedVariant,
      type = '',
      cartItems,
      couponCodes,
      handleVariantItemPress,
    } = this.props;

    const { offersEsp, promptMrp, promptDiscount, isEspOffer } = Utility.getEsp(
      prompts,
      couponCodes,
    );
    const { selectedMultiVariantPrimary, allowedVariantValues } = this.props;

    const quantity = this.getQuantityOfAParticularSku(
      cartItems,
      attribute.sku_id,
    );
    const variantAttribute = attribute?.variant?.id || attribute?.id || '';
    let cardElevation = 0;
    let selectedShadeStyle = ProductInfoStyles.shadeColortext;
    const isShadeSelectedForMultipleVariant =
      selectedMultiVariantPrimary?.value === attribute?.value;
    if (
      (!Utility.isBlank(selectedVariant) &&
        selectedVariant.id === variantAttribute &&
        Utility.isBlank(type)) ||
      isShadeSelectedForMultipleVariant
    ) {
      // if type is cart dont show elevation, only show when type is blank
      selectedShadeStyle = ProductInfoStyles.shadeColortextBold;
      cardElevation = 0;
    }
    let opacity = 1;
    if (outOfStock) {
      opacity = 0.5;
    }
    let width =
      type === 'cart'
        ? Utility.getDynamicWidth(1, 8)
        : Utility.getDynamicWidth(3, 16);

    const imageUrl = (primaryVariantImageUrl ?? image_url).includes('width')
      ? primaryVariantImageUrl ?? image_url
      : getMinifiedImage(
          primaryVariantImageUrl ?? image_url,
          ProductInfoStyles.productVariantImageBackground.width,
          ProductInfoStyles.productVariantImageBackground.height,
        );

    if (isDesktop()) width = '25%';
    return (
      <TouchableOpacity
        disabled={this.variantAddedToCart(type)}
        onPress={memoize(
          () => handleVariantItemPress(attribute),
          () => [attribute.value],
        )}
        style={[isDesktop() && { width }]}
      >
        <CardView
          cardElevation={cardElevation}
          cardMaxElevation={cardElevation}
          cornerRadius={4}
          style={[
            ProductInfoStyles.shadeCardStyle,
            {
              opacity,
              width,
            },
          ]}
        >
          <View style={ProductInfoStyles.shadeImageBackgroundContainer}>
            <FastImageView
              source={imageUrl}
              style={ProductInfoStyles.productVariantImageBackground}
              additionalBackgroundColor={variantAttribute}
              resizeMode='cover'
            >
              {(!Utility.isBlank(selectedVariant) &&
                !outOfStock &&
                (selectedVariant.id === variantAttribute ||
              isShadeSelectedForMultipleVariant)) ? (
                <Image
                  style={ProductInfoStyles.productVariantTickImage}
                  source={images.whiteTick}
                />
              ) : null}
              {outOfStock && (
                <Image
                  style={[
                    ProductInfoStyles.productVariantTickImage,
                    {
                      position: 'absolute',
                      alignSelf: 'center',
                    },
                  ]}
                  source={images.cross}
                />
              )}
            </FastImageView>
          </View>

          <View style={ProductInfoStyles.shadeRowContainer}>
            <View style={ProductInfoStyles.shadeAndPriceContainer}>
              <View
                style={[
                  ProductInfoStyles.shadeColortextContainer,
                  { maxWidth: this.isTypeCart(type) || isWebMobile() ? '70%' : null },
                ]}
              >
                <Text
                  style={selectedShadeStyle}
                  numberOfLines={3}
                  ellipsizeMode='tail'
                >
                  {primaryVariantDisplayName ?? displayName}
                </Text>
              </View>
              <View>
                <this.PricingComponent
                  showPrice={showPrice}
                  sp={sp}
                  mrp={mrp}
                  discount={discount}
                  offer_value={offer_value}
                  final_sp={final_sp}
                  offer_code={offer_code}
                  additional_discount={additional_discount}
                />
              </View>
            </View>
          </View>
        </CardView>
      </TouchableOpacity>
    );
  };

  getVariantComponentInCart = ({ item }) => {
    const attribute = item;
    const showPrice = false;
    let {
      display_name: displayName,
      image_url = '',
      sp,
      mrp,
      discount,
      offer_value,
      final_sp,
      offer_code,
      additional_discount,
    } = attribute;

    const { selectedVariant, type = '', cartItems } = this.props;

    const { selectedMultiVariantPrimary } = this.props;

    const quantity = this.getQuantityOfAParticularSku(
      cartItems,
      attribute.sku_id,
    );
    const variantAttribute = attribute?.variant?.id || attribute?.id || '';
    let cardElevation = 0;
    let selectedShadeStyle = ProductInfoStyles.shadeColortext;
    const isShadeSelectedForMultipleVariant =
      selectedMultiVariantPrimary?.value === attribute?.value;
    if (
      (!Utility.isBlank(selectedVariant) &&
        selectedVariant.id === variantAttribute &&
        Utility.isBlank(type)) ||
      isShadeSelectedForMultipleVariant
    ) {
      selectedShadeStyle = ProductInfoStyles.shadeColortextBold;
      cardElevation = 0;
    }

    const imageUrl = image_url.includes('width')
      ? image_url
      : getMinifiedImage(
          image_url,
          ProductInfoStyles.productVariantImageBackground.width,
          ProductInfoStyles.productVariantImageBackground.height,
        );

    return (
      <CardView
        cardElevation={cardElevation}
        cardMaxElevation={cardElevation}
        cornerRadius={4}
        style={[
          ProductInfoStyles.shadeCardStyleCart,
          isDesktop() && { width: '100%' },
        ]}
      >
        <View style={ProductInfoStyles.shadeImageBackgroundContainer}>
          <FastImageView
            source={imageUrl}
            style={ProductInfoStyles.productVariantImageBackground}
            additionalBackgroundColor={variantAttribute}
            resizeMode='cover'
          >
            <Image
              style={ProductInfoStyles.productVariantTickImage}
              source={images.whiteTick}
            />
          </FastImageView>
        </View>
        <View style={ProductInfoStyles.shadeRowContainer}>
          <View style={ProductInfoStyles.shadeAndPriceContainer}>
            <View
              style={[
                ProductInfoStyles.shadeColortextContainer,
                { maxWidth: this.isTypeCart(type) ? '70%' : null },
              ]}
            >
              <Text
                style={selectedShadeStyle}
                numberOfLines={3}
                ellipsizeMode='tail'
              >
                {displayName}
              </Text>
            </View>

            <this.PricingComponent
              showPrice={showPrice}
              sp={sp}
              mrp={mrp}
              discount={discount}
              offer_value={offer_value}
              final_sp={final_sp}
              offer_code={offer_code}
              additional_discount={additional_discount}
            />
          </View>
          <this.cartIncrementDecrementButton
            skuId={attribute.sku_id}
            type={type}
            quantity={quantity}
          />
        </View>
      </CardView>
    );
  };

  keyExtractor = (item, index) => `${item.color_code}_${index}`;

  flatlistRenderItem = ({ item }) =>
    this.getVariantComponent(item, this.props.showPrice);

  render() {
    const {
      allowedVariantValues = [],
      showPrice,
      selectedVariant,
      type = '',
      selectedMultiVariantPrimary,
      variantsInCart,
    } = this.props;
    if (allowedVariantValues.length === 0) {
      return null;
    }
    let numColumns = 3;
    if (this.isTypeCart(type)) {
      numColumns = 1;
    }
    if (isDesktop()) {
      numColumns = 4;
    }
    return (
      <View style={ProductInfoStyles.shadesContainerView}>
        {Utility.isPresent(variantsInCart) && (
          <>
            <Text style={ProductInfoStyles.headerSection}>In your bag</Text>
            <FlatList
              data={variantsInCart}
              renderItem={this.getVariantComponentInCart}
              showsHorizontalScrollIndicator={false}
              numColumns={1} // for variants added in cart UI is full width
              bounces={false}
              keyExtractor={this.keyExtractor}
              extraData={{ ...selectedVariant, ...selectedMultiVariantPrimary }}
              ListHeaderComponent={this.inYourBag}
            />
          </>
        )}

        <>
          <Text style={ProductInfoStyles.headerSection}>Available shades</Text>
          <FlatList
            data={sortBy(
              allowedVariantValues,
              (e) => !!e?.variant?.outOfStock,
            )}
            renderItem={this.flatlistRenderItem}
            showsHorizontalScrollIndicator={false}
            numColumns={this.numColumns} // for variants added in cart UI is full width
            bounces={false}
            keyExtractor={this.keyExtractor}
            extraData={{ ...selectedVariant, ...selectedMultiVariantPrimary }}
          />
        </>
      </View>
    );
  }
}

const mapStateToProps = (store, ownProps) => {
  const { skuId, id } = ownProps;
  return {
    couponCodes:
      store.bag.cartPricing && store.bag.cartPricing.coupon_codes
        ? store.bag.cartPricing.coupon_codes
        : [],
    todayDeals: store.todayDeals,
    favourites: store.UserAccountInfo.favourites,
    addedToCart: !!(
      (
        store.bag &&
        findKey(store.bag.cartItems, (item) => item.sku_id === skuId)
      ) // TODO: Please use object with sku-id/product-id as keys instead of array
    ),
    addedToCartForCard: !!(
      store.bag &&
      findKey(store.bag.cartItems, (item) => {
        // TODO: Please use object with sku-id/product-id as keys instead of array
        if (!Utility.isBlank(item.product)) {
          return item.product?.id === id;
        }
        return false;
      })
    ),
  };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      incrementDecrementCart,
      incrementDecrementCartBeforeCall,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(VariantList);
