// Dependencies
import React, { Component } from 'react';
import {
  ScrollView,
  Animated,
  ImageBackground,
  View,
  Image,
  Text,
  FlatList,
  TouchableOpacity,
  Linking,
  RefreshControl,
  BackHandler,
} from 'react-native';
import _ from 'lodash';
import Orientation from 'react-native-orientation';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FlatListPerformanceView } from '../../libraries/ReactNativePerformanceShopify';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import { ArtistDetailStyles } from './styles';
import { withMaybe } from '../../lib/Monads';
import { CustomNavigationHeader } from '../Product';
import DockedHeader from '../../utils/DockedHeader';
import images from '../../theme/Images';
import colors from '../../theme/Colors';
import { Header } from '../header';
import List from '../../containers/List/List';
import Media from '../../containers/media';
import { ORIENTATION, LAYOUT, MAX_LINES } from '../../config/Constants';
import ArtistDescriptionCard from './ArtistDescriptionCard';
import { viewItem } from '../../actions/ActionTypes';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import { ScreenPlaceholder } from '../shared';
import Toast from 'react-native-easy-toast';
import {
  AnalyticsManager,
  EventType,
  EventParameterKey,
  AnalyticsUtilty,
} from '../../analytics';
import PageNotFound from '../shared/PageNotFound';
import ErrorBoundary from '../shared/ErrorBoundary';
import { isNative } from '../../utils/BooleanUtility';

import withProfiledNavigation from '../../utils/withProfiledNavigation';
import { getListSource, getNavigationSource } from '../../utils/PerfUtility';

class ArtistDetail extends Component {
  state = {
    liked: false,
    followed: false,
    scrollY: new Animated.Value(0),
    socialUrl: '',
    isRefreshing: false,
    isCartVisible: false,
  };

  constructor(props) {
    super(props);
    this.slug = this.props.navigation.slug;
    this.viewItemsIds = [];
    if (Utility.isAndroid()) {
      this.backhandler = BackHandler.addEventListener(
        'hardwareBackPress',
        this.onHardwareBackKeyPress,
      );
    }

    this.navigationSource = getNavigationSource(this, this.props);
  }

  Components = {
    list: List,
    video: Media,
  };

  socialMediaUrl = '';

  componentDidMount() {
    const { viewItem, id, type, navigation } = this.props;
    if (isNative()) {
      // Orientation.lockToPortrait();
    }
    if (!Utility.isBlank(id)) {
      const data = { id, type, date: Math.floor(Date.now() / 1000) };
      viewItem(data);
    }
  }

  componentWillUnmount() {
    if (Utility.isAndroid()) {
      this.backhandler.remove();
    }
  }

  onHardwareBackKeyPress = () => {
    const { navigation } = this.props;
    navigation.goBack();
    return true;
  };

  toggleCartVisibility = () => {
    const { navigation } = this.props;
    navigation.push(this.navigationSource, 'Cart', {
      source: 'artist_details',
    });
  };

  onCartDismiss = () => {
    this.setState({ isCartVisible: false });
  };

  goBack = () => {
    AnalyticsUtilty.fireGoBackEvent();
    const { navigation } = this.props;
    navigation.goBack();
  };

  toggleLike = () => {
    this.setState({
      liked: !this.state.liked,
    });
  };

  listConditionFn = (props) => props.content === 'list';

  customNavigationHeader = withMaybe(this.listConditionFn)(
    CustomNavigationHeader,
  );

  navigate = (url) => {
    if (Utility.isPresent(url)) {
      Linking.openURL(url);
    }
  };

  navigateToProfile = (url) => {
    const { navigation } = this.props;
    // navigation.navigate('MyProfile', {});
  };

  lockScreenToPortrait = (payload) => {
    const { id, name, previousScreen } = this.props;
    AnalyticsManager.logEvent(EventType.discoveryEvents.ARTIST_VIEW, {
      [EventParameterKey.ARTIST_ID]: id,
      [EventParameterKey.ARTIST_NAME]: name,
    });
    if (isNative()) {
      // Orientation.lockToPortrait();
    }
    AnalyticsManager.setCurrentScreen(previousScreen);
  };

  refreshPage = () => {
    const { hitArtistApi, id } = this.props;
    hitArtistApi();
  };

  fullScreenShimmer = () => {
    const { scrollY } = this.state;
    return (
      <View style={{ flex: 1, backgroundColor: colors.background }}>
        {/* <NavigationEvents
          onDidFocus={(payload) => this.lockScreenToPortrait(payload)}
        /> */}
        <this.customNavigationHeader
          scrollY={scrollY}
          goBack={this.goBack}
          previousScreen={SCREEN_CONSTANTS.ARTIST}
          showMenuButton={false}
          navigateToProfile={this.navigateToProfile}
          content={'artist'}
        >
          <DockedHeader name={''} />
        </this.customNavigationHeader>

        <View style={{ height: '100%' }}>
          <ArtistDescriptionCard />
          <ScreenPlaceholder />
        </View>
      </View>
    );
  };

  getComponent(item) {
    const { id } = this.props;
    const ContainerComponent = this.Components[item.type];
    if (ContainerComponent === null || ContainerComponent === undefined) {
      return null;
    }
    return (
      <>
        <Header
          item={item}
          navigation={this.props.navigation}
          layout={item.display}
          previousScreen={SCREEN_CONSTANTS.ARTIST}
        />
        <ContainerComponent
          navigation={this.props.navigation}
          itemData={item}
          id={item.id}
          listName={item.name}
          listContent={item.content}
          type={item.type}
          size={item.size}
          orientation={ORIENTATION.VERTICAL}
          display={item.display}
          layout={item.display}
          previousScreen={SCREEN_CONSTANTS.ARTIST}
          allVideosId={id}
          showToast={this.showToast}
        />
      </>
    );
  }

  followButton = {
    text: {
      true: 'Following',
      false: 'Follow',
    },
    color: {
      true: colors.white,
      false: colors.havelockBlue,
    },
    image: {
      true: images.whiteTick,
      false: images.plus,
    },
  };

  isArtistTypeBlank = (artist_type) => {
    return (
      Utility.isBlank(artist_type) ||
      !_.isArray(artist_type) ||
      artist_type.length === 0
    );
  };

  formatArtistType = () => {
    //TODO: Move this in utility as this is repeated in artist description card
    let { artist_type, bio, artistTypes } = this.props;
    let artistTypeText = 'Artist';
    if (!Utility.isBlank(bio)) {
      return bio;
    }
    if (this.isArtistTypeBlank(artist_type) || Utility.isBlank(artistTypes)) {
      return artistTypeText;
    }
    //sort artist_type based on priority
    artist_type = _.sortBy(artist_type, [
      (artTypeId) => artistTypes[artTypeId].priority,
    ]);
    const id = artist_type[0];
    if (artist_type.length === 1) {
      artistTypeText = artistTypes[id].name;
      return artistTypeText;
    }
    const artistNameArray = artist_type.map((id) => {
      return artistTypes[id].name;
    });
    artistTypeText = _.join(artistNameArray.slice(0, 2), ', ');
    return artistTypeText;
  };

  socialMediaIcons = (props) => {
    let {
      instagram_url,
      youtube_url,
      instagram_handle = '',
      youtube_handle = '',
    } = props;
    if (Utility.isBlank(instagram_handle)) {
      instagram_handle = '';
    }
    if (Utility.isBlank(youtube_handle)) {
      youtube_handle = '';
    }
    if (instagram_handle === '' && youtube_handle !== '') {
      const socialMediaButtonHorizontalMargin = 6;
      const numberOfButtons = 1;
      const totalHorizontalSpacing = 78;
      return (
        <View style={ArtistDetailStyles.socialMediaIconContainer}>
          <TouchableOpacity
            style={[
              ArtistDetailStyles.socialMediaButton,
              {
                width: Utility.getDynamicWidth(
                  numberOfButtons,
                  socialMediaButtonHorizontalMargin,
                ),
              },
            ]}
            onPress={() => this.navigate(youtube_url)}
          >
            <Image
              source={images.socialMediaIcons.youtube}
              style={ArtistDetailStyles.socialMediaIconImage}
            />
            <View
              style={[
                ArtistDetailStyles.socialMediaIdContainer,
                {
                  width:
                    Utility.getScreenWidth() -
                    totalHorizontalSpacing -
                    Utility.largePadding,
                },
              ]}
            >
              <Text
                numberOfLines={MAX_LINES.single}
                style={ArtistDetailStyles.socialMediaIdStyle}
              >
                {`Subscribe to ${youtube_handle}  `}
              </Text>
            </View>
          </TouchableOpacity>
        </View>
      );
    }
    if (youtube_handle === '' && instagram_handle !== '') {
      const totalHorizontalSpacing = 78;
      return (
        <View style={ArtistDetailStyles.socialMediaIconContainer}>
          <TouchableOpacity
            style={[
              ArtistDetailStyles.socialMediaButton,
              { width: Utility.getDynamicWidth(1, 6) },
            ]}
            onPress={() => this.navigate(instagram_url)}
          >
            <Image
              source={images.socialMediaIcons.instagram}
              style={ArtistDetailStyles.socialMediaIconImage}
            />
            <View
              style={[
                ArtistDetailStyles.socialMediaIdContainer,
                {
                  width:
                    Utility.getScreenWidth() -
                    totalHorizontalSpacing -
                    Utility.largePadding,
                },
              ]}
            >
              <Text
                numberOfLines={MAX_LINES.single}
                style={ArtistDetailStyles.socialMediaIdStyle}
              >
                {`Follow ${instagram_handle}  `}
              </Text>
            </View>
          </TouchableOpacity>
        </View>
      );
    }
    if (youtube_handle === '' && instagram_handle === '') {
      return null;
    }
    return (
      <View style={ArtistDetailStyles.socialMediaIconContainer}>
        <TouchableOpacity
          style={ArtistDetailStyles.socialMediaButton}
          onPress={() => this.navigate(instagram_url)}
        >
          <Image
            source={images.socialMediaIcons.instagram}
            style={ArtistDetailStyles.socialMediaIconImage}
          />
          <View style={ArtistDetailStyles.socialMediaIdContainer}>
            <Text
              numberOfLines={MAX_LINES.single}
              style={ArtistDetailStyles.socialMediaIdStyle}
            >
              {`${instagram_handle}  `}
            </Text>
          </View>
        </TouchableOpacity>
        <TouchableOpacity
          style={ArtistDetailStyles.socialMediaButton}
          onPress={() => this.navigate(youtube_url)}
        >
          <Image
            source={images.socialMediaIcons.youtube}
            style={ArtistDetailStyles.socialMediaIconImage}
          />
          <View style={ArtistDetailStyles.socialMediaIdContainer}>
            <Text
              numberOfLines={MAX_LINES.single}
              style={ArtistDetailStyles.socialMediaIdStyle}
            >
              {`${youtube_handle}  `}
            </Text>
          </View>
        </TouchableOpacity>
      </View>
    );
  };

  showToast = (message) => {
    this.toast.show(message, 1500);
  };

  render() {
    const {
      id,
      slug,
      image_url,
      banner_image_url,
      type,
      share_url,
      name,
      objects,
      instagram_url,
      youtube_url,
      instagram_handle,
      youtube_handle,
      address,
      followers_count,
      subscribers_count,
      video_count,
      views_count,
      showShimmer = false,
      location,
      insta_posts_count,
      call_to_action,
      bio,
      emoji,
      artist_type,
      showFullShimmer = false,
      isPageLoading,
      navigation,
      route,
      serverError = false,
    } = this.props;

    const pageNotFound =
      (!serverError && !isPageLoading && !showFullShimmer && !showShimmer) ||
      false;
    if (pageNotFound) {
      return <PageNotFound goToHome slug={route.params?.slug} />;
    }
    if (showFullShimmer && !serverError && !pageNotFound) {
      //when no data is present for artist, show full screen shimmer instead of returning null
      return <this.fullScreenShimmer />;
    }
    const { scrollY, isRefreshing } = this.state;
    const nameToShowInDockedHeader = instagram_handle || youtube_handle || name;
    const artistTypeText = this.formatArtistType();
    console.tron.log('artist type test after form ', artistTypeText);
    return (
      <>
        <View style={{ flex: 1 }}>
          {/* <NavigationEvents
            onDidFocus={(payload) => this.lockScreenToPortrait(payload)}
          /> */}
          <this.customNavigationHeader
            scrollY={scrollY}
            slug={slug}
            goBack={this.goBack}
            content={type}
            shareUrl={share_url}
            image={image_url}
            name={name}
            subHeading={artistTypeText}
            previousScreen={SCREEN_CONSTANTS.ARTIST}
            showMenuButton={false}
            navigateToProfile={this.navigateToProfile}
            screenName={SCREEN_CONSTANTS.ARTIST_DETAILS}
            id={id}
          >
            <DockedHeader name={nameToShowInDockedHeader} />
          </this.customNavigationHeader>
          <ErrorBoundary
            showServerError={serverError}
            hideHeader
            screen_name={'artist_Detail'}
          >
            <ScrollView
              style={ArtistDetailStyles.scrollview}
              scrollEventThrottle={16}
              onScroll={Animated.event([
                { nativeEvent: { contentOffset: { y: scrollY } } },
              ])}
              refreshControl={
                <RefreshControl
                  refreshing={isRefreshing}
                  onRefresh={this.refreshPage}
                />
              }
            >
              <ArtistDescriptionCard
                id={id}
                image_url={image_url}
                banner_image_url={banner_image_url}
                name={name}
                artist_type={artist_type}
                address={address}
                youtube_url={youtube_url}
                instagram_url={instagram_url}
                followers_count={followers_count}
                subscribers_count={subscribers_count}
                video_count={video_count}
                views_count={views_count}
                slug={slug}
                location={location}
                insta_posts_count={insta_posts_count}
                call_to_action={call_to_action}
                bio={bio}
                emoji={emoji}
              />
              {showShimmer ? (
                <ScreenPlaceholder />
              ) : (
                <FlatListPerformanceView
                  listName={`${getListSource(this, this.props)}`}
                >
                  <FlatList
                    data={_.filter(
                      objects,
                      (item) => item.objects.length !== 0,
                    )}
                    renderItem={({ item }) => this.getComponent(item)}
                    keyExtractor={(item) => `${item.type}_${item.id}`}
                    style={{
                      backgroundColor: colors.background,
                      paddingBottom: 8,
                    }}
                    extraData={id}
                  />
                </FlatListPerformanceView>
              )}
              <this.socialMediaIcons
                instagram_url={instagram_url}
                youtube_url={youtube_url}
                instagram_handle={instagram_handle}
                youtube_handle={youtube_handle}
              />
            </ScrollView>
          </ErrorBoundary>
        </View>
        <Toast
          style={{ position: 'absolute', bottom: 20 }}
          ref={(ref) => {
            this.toast = ref;
          }}
        />
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({ viewItem }, dispatch),
});

export default withProfiledNavigation(
  connect(null, mapDispatchToProps)(ArtistDetail),
);
