import { View, Text, StyleSheet } from 'react-native';
import React, { useMemo } from 'react';
import FastImageView from '../FastImageView';
import colors from '../../theme/Colors';
import { isPresent } from '../../utils/BooleanUtility';
import { getPriceText } from '../../utils/NumberUtility';
import size from '../../theme/Fonts';
import DebouncedTouchableOpacity from '../shared/DebouncedTouchableOpacity';
import { getMinifiedImage } from '../../utils/ImageUtility';
import { Grayscale } from '../../libraries/ReactNativeColorMatrixImageFilters';
import Config from '../../libraries/ReactNativeConfig';

export default function VariantWithImage({
  item,
  onPress,
  disabled,
  isSelected,
}) {
  const { variant: { final_sp: sp, discount, images } = {} } = item;
  const image_url = images[0];

  const LayoutComponent = useMemo(
    () => (disabled ? Grayscale : View),
    [disabled],
  );

  return (
    <DebouncedTouchableOpacity
      onPress={onPress}
      disabled={disabled}
      style={isSelected ? styles.containerSelected : styles.container}
    >
      {isPresent(image_url) && (
        <LayoutComponent style={styles.imageContainer}>
          <FastImageView
            source={{ uri: getMinifiedImage(image_url) }}
            style={styles.image}
          ></FastImageView>
        </LayoutComponent>
      )}
      {disabled && (
        <View style={styles.disabledOverlay}>
          <Text style={styles.notInStock}>Not in stock</Text>
        </View>
      )}
      {isPresent(image_url) && <View style={styles.divider} />}
      <View style={styles.textContainer}>
        <Text
          style={disabled ? styles.variantName : styles.variantNameDisabled}
          numberOfLines={2}
        >
          {item?.value || item?.display_name}
        </Text>
        <View style={styles.priceContainer}>
          <Text
            style={[styles.spStyle, disabled ? styles.spDisabled : null]}
          >{`${getPriceText(parseFloat(sp))} `}</Text>
          <Text
            style={[
              styles.discountRateStyle,
              disabled ? styles.discountDisabled : null,
            ]}
          >{`${discount}`}</Text>
        </View>
      </View>
    </DebouncedTouchableOpacity>
  );
}

const styles = StyleSheet.create({
  container: {
    borderWidth: 1,
    borderRadius: 4,
    margin: 4,
    width: 110,
    borderColor: colors.background,
  },
  containerSelected: {
    borderWidth: 1,
    borderRadius: 4,
    margin: 4,
    width: 110,
    borderColor: colors.cta.lightBlue,
  },
  imageContainer: {
    alignSelf: 'center',
    justifyContent: 'flex-end',
    margin: 8,
  },
  image: {
    height: 100,
    width: 100,
  },
  disabledOverlay: {
    position: 'absolute',
    width: '100%',
    height: 30,
    backgroundColor: '#F7F6F7',
    alignItems: 'center',
    justifyContent: 'center',
  },
  divider: {
    width: '100%',
    height: 1,
    backgroundColor: colors.background,
  },
  textContainer: {
    paddingHorizontal: 8,
  },
  variantName: {
    height: 38,
    color: colors.foxyBlack,
    fontSize: size.h4,
    fontFamily: 'Roboto-Medium',
    fontStyle: 'normal',
    marginTop: 4,
  },
  variantNameDisabled: {
    height: 38,
    fontSize: size.h4,
    fontFamily: 'Roboto-Medium',
    fontStyle: 'normal',
    marginTop: 4,
    color: colors.subtitle,
  },
  spStyle: {
    color: colors.foxyBlack,
    fontSize: size.h4,
    fontFamily: 'Roboto-Medium',
    fontStyle: 'normal',
    letterSpacing: 0,
    lineHeight: 19,
    marginRight: 4,
  },
  spDisabled: {
    color: colors.subtitle,
    fontSize: size.h4,
    fontFamily: 'Roboto-Medium',
    fontStyle: 'normal',
    letterSpacing: 0,
    lineHeight: 19,
    marginRight: 4,
  },
  discountRateStyle: {
    color: Config.PRODUCT_DISCOUNT_COLOR,
    fontSize: size.h5,
    fontFamily: 'Roboto-Regular',
    fontStyle: 'normal',
    letterSpacing: 0,
    lineHeight: 19,
    opacity: 0.9,
    marginBottom: 6,
  },
  discountDisabled: {
    color: colors.subtitle,
    fontSize: size.h5,
    fontFamily: 'Roboto-Regular',
    fontStyle: 'normal',
    letterSpacing: 0,
    lineHeight: 19,
    opacity: 0.9,
    marginBottom: 6,
  },
  notInStock: {
    color: colors.subtitle,
    fontSize: size.h4,
    fontFamily: 'Roboto-Medium',
    fontStyle: 'normal',
    letterSpacing: 0,
    lineHeight: 19,
    marginRight: 4,
  },
});
