import _ from 'lodash';
import { normalize } from 'normalizr';
import initialState from './initialState';
import createReducer from '../helpers/CreateReducer';
import Utility from '../utils/Utility';
import { addressesSchema } from '../config/Schema';
import AddressUtility from '../utils/AddressUtility';

const actionHandlers = {
  CHANGE_COUNTRY_CODE: (state, action) => ({
    ...state,
    countryCode: action.countryCode,
    callingCode: action.callingCode,
  }),

  TOGGLE_AUTOFILL: (state, action) => ({
    ...state,
    autofillMyProfileDetails: !state.autofillMyProfileDetails,
  }),

  ADD_MOBILE_NUMBER: (state, action) => ({
    ...state,
    mobileNumber: action.mobileNumber,
    registered: true,
    deviceInfo: action.deviceInfo,
  }),

  INSERT_MOBILE_NUMBER: (state, action) => ({
    ...state,
    mobileNumber: action.mobileNumber,
    registered: true,
    deviceInfo: action.deviceInfo,
  }),

  ADD_PROFILE_DETAILS_SUCCESS: (state, action) => ({
    ...state,
    profile: {
      ...state.profile,
      handle: action.handle,
      name: action.name,
      email: action.email,
      status: 'Submit',
    },
  }),

  // Added Reducer for the profile pic START.

  STORE_CDN_PROFILE_PIC_URL: (state, action) => ({
    ...state,
    profile: {
      ...state.profile,
      selfie_image_url: action.cdnPath,
    },
  }),
  // Added Reducer for the profile pic store END

  ADD_PROFILE_DETAILS_FAILURE: (state, action) => ({
    ...state,
    status: 'Pending',
    errorCode: action.errorCode,
    errorMessage: action.errorMessage,
  }),

  ADD_PROFILE_PIC: (state, action) => ({
    ...state,
    profile: {
      ...state.profile,
      image_url: action.imageUri,
    },
  }),

  UPDATE_CURRENT_POST_ID: (state, action) => ({
    ...state,
    currentPostId: action.postID,
  }),

  TOGGLE_UPDATE_CURRENT_POST_STATUS: (state, action) => ({
    ...state,
    updateCurrentPost: action.status,
  }),

  TOGGLE_PRODUCT_SEARCHING_STATUS: (state, action) => ({
    ...state,
    productSearchingStatus: action.status,
  }),

  TOGGLE_INTEREST: (state, action) => ({
    ...state,
    selectedInterests: action.isInterestSelected
      ? _.reject(state.selectedInterests, (id) => id === action.interestID)
      : [...state.selectedInterests, action.interestID],
  }),

  // VERIFY_OTP: (state, action) => ({ ...state, isVerifying: true }),

  VERIFY_OTP_SUCCESS: (state, action) => {
    const addresses =
      action.user.ekanek_addresses ?? action.user.addresses ?? [];
    return {
      ...state,
      authToken: action.user.auth_token,
      invitationCode: action.user.referral_url,
      isVerifying: false,
      favourites: action.user.favourites,
      followedArtists: action.user.followedArtists,
      viewedArtistStory: action.user.followedArtists,
      addresses: Utility.isBlank(addresses)
        ? {}
        : normalize(
            AddressUtility.restructureAddressArray(addresses),
            addressesSchema,
          ).entities.address,
      shopifyAddresses: Utility.isBlank(action.user.shopify_addresses)
        ? {}
        : normalize(
            AddressUtility.restructureAddressArray(
              action.user.shopify_addresses,
            ),
            addressesSchema,
          ).entities.address,
      profile: action.user.profile,
      selectedInterests: action.user.profile.interests,
      instagram_profile: action.user.instagram_profile,
      youtube_profile: {
        ...action.user.google_profile,
        ...action.user.youtube_channel,
      },
      user_created_at: action.user.created_at,
      artistProfile: {
        ...state.artistProfile,
        isArtist: action.user.is_artist,
        claimedWelcomeGift: action.user.claimed_welcome_gift,
        city: action.user.artist ? action.user.artist.city : '',
        emoji: action.user.artist ? action.user.artist.emoji : '',
        bio: action.user.artist ? action.user.artist.bio : '',
        call_to_action_option_id: action.user.artist
          ? action.user.artist.call_to_action_option_id
          : '',
        selectedBrands:
          action.user &&
          action.user.artist &&
          action.user.artist.collaboration_wishes,
      },
      guestProfile: {
        ...state.guestProfile,
        guestAuthToken: action.user.guest_token,
        // current_state: CURRENT_GUEST_STATE.FULLY_REGISTERED, //TODO:uncomment this code, once we will enable skip button for onboarding
      },
      surprise_available: action.user.surprise_available,
      surprise_last_taken_at: action.user.surprise_last_taken_at,
      lead: action.user.lead,
      new_user: action.user.new_user,
      has_selfie: action.user.has_selfie,
      publicId: action.user.public_id,
      authorized: action.user.authorized,
      // productRatings: action.user.productRatings,
    };
  },

  REFRESH_PROFILE_DATA: (state, action) => {
    const addresses =
      action.user.ekanek_addresses ?? action.user.addresses ?? [];
    return {
      ...state,
      invitationCode: action.user.referral_url,
      isVerifying: false,
      favourites: action.user.favourites,
      followedArtists:
        action.user.followedArtists || state.UserAccountInfo.followedArtists,
      viewedArtistStory: action.user.followedArtists,
      addresses: Utility.isBlank(addresses)
        ? {}
        : normalize(
            AddressUtility.restructureAddressArray(addresses),
            addressesSchema,
          ).entities.address,
      shopifyAddresses: Utility.isBlank(action.user.shopify_addresses)
        ? {}
        : normalize(
            AddressUtility.restructureAddressArray(
              action.user.shopify_addresses,
            ),
            addressesSchema,
          ).entities.address,
      profile: action.user.profile,
      selectedInterests: action.user.profile.interests,
      instagram_profile: action.user.instagram_profile,
      youtube_profile: {
        ...action.user.google_profile,
        ...action.user.youtube_channel,
      },
      user_created_at: action.user.created_at,
      artistProfile: {
        ...state.artistProfile,
        isArtist: action.user.is_artist,
        claimedWelcomeGift: action.user.claimed_welcome_gift,
        city: action.user.artist ? action.user.artist.city : '',
        emoji: action.user.artist ? action.user.artist.emoji : '',
        bio: action.user.artist ? action.user.artist.bio : '',
        call_to_action_option_id: action.user.artist
          ? action.user.artist.call_to_action_option_id
          : '',
        selectedBrands:
          action.user &&
          action.user.artist &&
          action.user.artist.collaboration_wishes,
      },
      guestProfile: {
        ...state.guestProfile,
        guestAuthToken: action.user.guest_token,
        // current_state: CURRENT_GUEST_STATE.FULLY_REGISTERED, //TODO:uncomment this code, once we will enable skip button for onboarding
      },
      surprise_available: action.user.surprise_available,
      surprise_last_taken_at: action.user.surprise_last_taken_at,
      lead: action.user.lead,
      new_user: action.user.new_user,
      has_selfie: action.user.has_selfie,
      publicId: action.user.public_id,
      authorized: action.user.authorized,
      // productRatings: action.user.productRatings,
    };
  },

  UPDATE_USERS_DATA: (state, action) => {
    return {
      ...state,
      app_installed_at: action.user.app_installed_at,
      authorized: action.user.authorized,
    };
  },

  VERIFY_OTP_FAILURE: (state, action) => ({
    ...state,
    errors: action.error,
    isVerifying: false,
    requestedFromHintPrompt: false,
  }),

  CLEAR_OTP_ERROR: (state, action) => ({ ...state, errors: null }),

  VERIFY_HANDLE_SUCCESS: (state, action) => ({
    ...state,
    handleSuccess: true,
    handleError: null,
  }),

  SAVE_ALL_ADDRESSES: (state, action) => {
    const { ekanek_addresses = [], shopify_addresses = [] } =
      action?.data || {};
    return {
      ...state,
      addresses: Utility.isBlank(ekanek_addresses)
        ? {}
        : normalize(
            AddressUtility.restructureAddressArray(ekanek_addresses),
            addressesSchema,
          ).entities.address,
      shopifyAddresses: Utility.isBlank(shopify_addresses)
        ? {}
        : normalize(
            AddressUtility.restructureAddressArray(shopify_addresses),
            addressesSchema,
          ).entities.address,
    };
  },

  VERIFY_HANDLE_FAILURE: (state, action) => ({
    ...state,
    handleError: action.error,
    handleSuccess: false,
  }),

  CLEAR_HANDLE_STATES: (state, action) => ({
    ...state,
    handleError: null,
    handleSuccess: false,
  }),

  ADD_LOCALITY: (state, action) => ({ ...state, locality: action.location }),

  ADD_LAT_LONG: (state, action) => ({
    ...state,
    latitude: action.latitude,
    longitude: action.longitude,
  }),

  INTEREST_SELECTED: (state, action) => ({
    ...state,
    selectedInterests: action.interests,
  }),

  UPDATE_ONBOARDING_STATUS: (state, action) => ({
    ...state,
    onboardingStatus: action.status,
  }),

  TOGGLE_FOLLOW_ARTIST: (state, action) => ({
    ...state,
    followedArtist: !action.data.isFollowingArtist
      ? [...state.followedArtist, action.data.id]
      : _.filter(state.followedArtist, (id) => id !== action.data.id),
  }),

  TOGGLE_FAVORITE_CONTENT: (state, action) => ({
    ...state,
    favoriteContent: !action.data.isFavorite
      ? [...state.favoriteContent, action.data.id]
      : _.filter(state.favoriteContent, (id) => id !== action.data.id),
  }),

  USER_REGISTERED: (state, action) => ({
    ...state,
    registered: true,
  }),

  USER_AUTHENTICATED: (state, action) => ({
    ...state,
    authenticated: action.authenticated,
  }),

  USER_VERIFIED: (state, action) => ({
    ...state,
    verified: true,
  }),

  USER_SUSPICIOUS: (state, action) => ({
    ...state,
    suspicious: true,
  }),

  FAVORITE_CONTENT_FAILED: (state, action) => ({
    ...state,
    favoriteContentQueue: _.includes(state.favoriteContentQueue, action.data.id)
      ? state.favoriteContentQueue
      : [...state.favoriteContentQueue, action.data.id],
  }),
  FOLLOW_ARTIST: (state, action) => {
    const { followedArtists = {} } = state;
    if (Utility.isBlank(followedArtists[action.data.id])) {
      return {
        ...state,
        followedArtists: {
          ...state.followedArtists,
          [action.data.id]: action.data,
        },
        viewedArtistStory: {
          ...state.viewedArtistStory,
          [action.data.id]: {
            last_seen: Math.floor(Date.now() / 1000),
            seen: true,
          },
        },
        followedArtistStoryRecommendations: {
          ...state.followedArtistStoryRecommendations,
          [`${action.data.type}${action.data.id}`]: {
            item_type: action.data.type,
            item_id: action.data.id,
          },
        },
      };
    }
    return state;
  },
  UNFOLLOW_ARTIST: (state, action) => ({
    ...state,
    followedArtists: _.omit(state.followedArtists, [action.data.id]),
    viewedArtistStory: _.omit(state.viewedArtistStory, [action.data.id]),
    followedArtistStoryRecommendations: _.omit(
      state.followedArtistStoryRecommendations,
      [`${action.data.type}${action.data.id}`],
    ),
  }),

  SET_STORY_ITEM_RECOMMENDATION: (state, action) => ({
    ...state,
    followedArtistStoryRecommendations: action.data,
  }),

  FOLLOWED_STORIES: (state, action) => ({
    ...state,
    followedStories: state.followedStories.concat(action.data),
  }),

  UNFOLLOW_STORIES: (state, action) => {
    const { data } = action;
    const deleteStory = _.filter(
      state.followedStories,
      (item) => item.id !== data,
    );
    return {
      ...state,
      followedStories: deleteStory,
    };
  },

  LOGOUT_USER: (state, action) => ({
    ...state,
    shouldLogoutUser: true,
    notificationData: [],
    shouldRenderOnboarding: false,
    authToken: null,
    verified: false,
    authenticated: false,
    registered: false,
    profile: {
      name: '',
      email: '',
      locality: '',
      handle: '',
    },
    mobileNumber: '',
    checkoutDetails: {
      lastPaymentMode: {
        mode: '',
        title: '',
        detail: '',
      },
      savedUpis: [],
      savedIntents: [],
      savedNb: [],
      savedCards: [],
    },
    favourites: {
      products: {},
      lists: {},
      videos: {},
      images: {},
      brands: {},
      salons: {},
    },
    productRatings: {},
    deliveryRatings: {},
    viewedProducts: {},
    viewedArtists: {},
    viewedArtistStory: {},
    viewedVideos: {},
    updateCurrentPost: false,
    currentPostId: '',
    productSearchingStatus: false,
    searchHistory: [],
    salonSearchHistory: [],
    recentSearchId: '',
    addresses: {},
    productTutorialVideo: true,
    scanProductTutorialVideo: true,
    currentOptinStage: '',
    youtube_profile: {},
    instagram_profile: {
      insta_user_name: '',
      insta_full_name: '',
      insta_profile_picture_url: '',
      verified: false,
    },
    isUploading: false,
    showUploadTrayOnFeed: false,
    watermark: '',
    mediaCategories: [],
    singletonSteps: {
      createDirectory: true,
      productTutorialVideo: true,
      scanningTutorialVideo: true,
      downloadLogo: true,
      createWatermark: true,
      loadMediaCategories: true,
    },
    fetchingProductVariants: false,
    autofillMyProfileDetails: true,
    isVerifying: false,
    profilePic: null,
    currentOtp: '',
    requestedFromHintPrompt: false,
    profileAnalytics: {
      youtube: {
        subscriberCount: '0',
        accessToken: '',
        refreshToken: '',
      },
      instagram: {
        followersCount: '0',
      },
    },
    networkInfo: {},
    invitationCode: '',
    artistProfile: {
      pendingApprovalCampaigns: [],
      cancelledCampaigns: [],
      approvedCampaigns: [],
      declinedCampaigns: [],
      videoSharedCampaigns: [],
      isArtist: false,
      artistLevel: 0,
      freeProductsAllowance: 0,
      selectedArtistTypes: [],
      selectedBrands: [],
      selectedOfferings: {},
      selectedTrainings: [],
      videoInReviewCampaigns: [],
      videoApprovedCampaigns: [],
      cashRequestedCampaigns: [],
      completedCampaigns: [],
      maxEarningPotential: 0,
      claimedWelcomeGift: false,
      hasBeenReferred: false,
      foxyInviteCode: '',
      city: '',
      emoji: '',
      bio: '',
      call_to_action_option_id: '',
    },
    artistGift: {},
    termsAccepted: false,
    interests: [],
    posts: {},
    lastVideoUpload: {
      id: '',
      postData: '',
      status: '',
      progress: 0,
    },
    artistOnboarding: {
      onboardingCompleted: false,
      showCongratulationsModal: false,
    },
    currentPincode: '',
    currentCity: '',
    lastUsedAddress: '',
    scannedProducts: [],
    emojis: {},
    surprise_available: '',
    surprise_last_taken_at: '',
    facialAnalysis: {},
    areAttributesReviewed: false,
    myKitData: [],
    remote_configs: {
      last_refreshed_at: '',
      data: {},
    },
    paytmDetails: {},
    guestProfile: {
      guestAuthToken: '',
      firstOpenTime: '',
      current_state: '',
    },
    continueAction: {
      continueSlug: '',
      continueImage: '',
      continueScreenType: '',
    },

    new_user: '',
    has_selfie: '',
    activeGroupDeals: [],
    recentlyViewedProductIds: [],
    lastVisitedProductId: undefined,
    showDynamicListTitle: false,
    authorized: false,
    notificationData: [],
    activeRoutineId: false,
    activeRoutineSlug: '',
    activeRoutineSlugs: [],
  }),

  LIKE: (state, action) => {
    if (Utility.isBlank(action.data)) {
      return state;
    }
    const { id, type } = action.data;

    if (
      Utility.isBlank(state.favourites[`${type}s`]) ||
      Utility.isBlank(state.favourites[`${type}s`][id])
    ) {
      return {
        ...state,
        favourites: {
          ...state.favourites,
          [`${type}s`]: { ...state.favourites[`${type}s`], [id]: action.data },
        },
      };
    } else {
      return state;
    }
  },

  UNLIKE: (state, action) => {
    if (Utility.isBlank(action.data)) {
      return state;
    }
    const { id, type } = action.data;
    return {
      ...state,
      favourites: {
        ...state.favourites,
        [`${type}s`]: _.omit(state.favourites[`${type}s`], [id]),
      },
    };
  },
  RATE_PRODUCT: (state, action) => {
    if (Utility.isBlank(action.data)) {
      return state;
    }
    const {
      id,
      rating: { star },
    } = action.data;
    return {
      ...state,
      productRatings: {
        ...state.productRatings,
        [`${id}`]: star,
      },
    };
    return state;
  },

  RATE_DELIVERY: (state, action) => {
    if (Utility.isBlank(action.rating)) {
      return state;
    }
    const { id, rating } = action;
    return {
      ...state,
      deliveryRatings: {
        ...state.deliveryRatings,
        [`${id}`]: rating,
      },
    };
    return state;
  },

  VIEW_ITEM: (state, action) => {
    if (Utility.isBlank(action.data)) {
      return state;
    }
    const { id, date, type } = action.data;
    if (Utility.isBlank(type)) {
      return state;
    }
    return {
      ...state,
      // [`viewed${_.capitalize(type)}s`]: {
      //   ...state[`viewed${_.capitalize(type)}s`],
      //   [id]: Utility.isBlank(state[`viewed${_.capitalize(type)}s`][`${id}`])
      //     ? [date]
      //     : [...state[`viewed${_.capitalize(type)}s`][`${id}`], date],
      // },
      [`viewed${_.capitalize(type)}s`]: {
        ...state[`viewed${_.capitalize(type)}s`],
        [id]: { last_view: date },
      },
    };
  },

  UPDATE_SEARCH_HISTORY: (state, action) => {
    if (Utility.isBlank(action.payload)) {
      return state;
    }
    return {
      ...state,
      searchHistory: _.uniq([action.payload, ...state.searchHistory]),
    };
  },

  UPDATE_SALON_SEARCH_HISTORY: (state, action) => {
    if (Utility.isBlank(action.payload)) {
      return state;
    }
    return {
      ...state,
      salonSearchHistory: _.uniq([action.payload, ...state.salonSearchHistory]),
    };
  },

  UPDATE_SEARCH_ID: (state, action) => {
    if (Utility.isBlank(action.data)) {
      return state;
    }
    return {
      ...state,
      recentSearchId: action.data.id,
    };
  },

  SAVE_ADDRESS: (state, action) => {
    if (Utility.isBlank(action.data)) {
      return state;
    }
    const { id } = action.data;
    return {
      ...state,
      addresses: Utility.isBlank(state.addresses)
        ? { [id]: action.data }
        : { ...state.addresses, [id]: action.data },
    };
  },

  REMOVE_ADDRESS_SUCCESS: (state, action) => {
    if (Utility.isBlank(action.data)) {
      return state;
    }
    const { id } = action.data;
    return {
      ...state,
      addresses: _.omit(state.addresses, [id]),
    };
  },

  CLEAR_SEARCH_HISTORY: (state, action) => ({
    ...state,
    searchHistory: [],
  }),

  CLEAR_SALON_SEARCH_HISTORY: (state, action) => ({
    ...state,
    salonSearchHistory: [],
  }),

  CLOSE_PRODUCT_TUTORIAL_VIDEO: (state, action) => ({
    ...state,
    productTutorialVideo: false,
  }),

  CLOSE_SCAN_PRODUCT_TUTORIAL_VIDEO: (state, action) => ({
    ...state,
    scanProductTutorialVideo: false,
  }),

  VIEW_ARTIST_STORY: (state, action) => {
    if (Utility.isBlank(action.payload)) {
      return state;
    }
    return {
      ...state,
      viewedArtistStory: {
        ...state.viewedArtistStory,
        [action.payload.id]: {
          last_seen: action.payload.lastViewed,
          seen: true,
        },
      },
    };
  },

  UPDATE_ARTIST_STORY_LIST: (state, action) => {
    if (Utility.isBlank(action.data) || Utility.isBlank(action.data.objects)) {
      return state;
    }
    const tempViewedArtist = state.viewedArtistStory;
    action.data?.objects?.forEach((artist) => {
      if (!Utility.isBlank(tempViewedArtist[artist.id])) {
        tempViewedArtist[artist.id] = {
          ...tempViewedArtist[artist.id],
          seen: false,
        };
      }
    });
    return {
      ...state,
      viewedArtistStory: {
        ...tempViewedArtist,
      },
    };
  },

  MARK_MEDIA_UPLOAD_BEGIN: (state, action) => ({
    ...state,
    isUploading: true,
  }),

  SHOW_UPLOAD_TRAY_ON_FEED: (state, action) => {
    return {
      ...state,
      showUploadTrayOnFeed: action.showUploadTray,
    };
  },

  MARK_MEDIA_UPLOAD_SUCCESSFUL: (state, action) => ({
    ...state,
    isUploading: false,
    lastVideoUpload: {
      id: '',
      postData: {},
      status: '',
      progress: 0,
    },
  }),

  LAST_PAYMENT_MODE: (state, action) => {
    if (Utility.isBlank(action.data)) {
      return state;
    }
    return {
      ...state,
      checkoutDetails: {
        ...state.checkoutDetails,
        lastPaymentMode: action.data,
      },
    };
  },

  REMOVE_LAST_PAYMENT_MODE: (state, action) => {
    return {
      ...state,
      checkoutDetails: {
        ...state.checkoutDetails,
        lastPaymentMode: {
          mode: '',
          title: '',
          detail: '',
        },
      },
    };
  },

  STORE_INSTAGRAM_DATA: (state, action) => ({
    ...state,
    instagram_profile: action.instagram_profile,
    isInstagramConnected: action.isInstagramConnected,
  }),

  MARK_CREATING_DIRECTORY: (state, action) => ({
    ...state,
    singletonSteps: {
      ...state.singletonSteps,
      createDirectory: false,
    },
  }),

  MARK_LOADING_MEDIA_CATEGORIES: (state, action) => ({
    ...state,
    singletonSteps: {
      ...state.singletonSteps,
      loadMediaCategories: false,
    },
  }),

  MARK_DOWNLOADING_LOGO: (state, action) => ({
    ...state,
    singletonSteps: {
      ...state.singletonSteps,
      downloadLogo: false,
    },
  }),

  STORE_MEDIA_CATEGORIES: (state, action) => ({
    ...state,
    mediaCategories: action.mediaCategories,
  }),

  DISABLE_UPLOAD_TRAY_FROM_FEED: (state) => ({
    ...state,
    showUploadTrayOnFeed: false,
  }),

  ARTIST_ONBOARDING_COMPLETED: (state, action) => ({
    ...state,
    artistOnboarding: {
      ...state.artistOnboarding,
      onboardingCompleted: true,
    },
  }),
  STORE_CURRENT_OTP: (state, action) => ({
    ...state,
    currentOtp: action.otp,
  }),
  REQUESTED_FROM_HINT: (state, action) => ({
    ...state,
    requestedFromHintPrompt: action.isHintRequest,
  }),
  SAVE_REFFERAL_CODE: (state, action) => ({
    ...state,
    invitationCode: action.referralCode,
  }),

  SAVE_YOUTUBE_REFRESH_TOKEN: (state, action) => ({
    ...state,
    youtube_profile: {
      ...state.youtube_profile,
      refreshToken: action.refreshToken,
      accessToken: action.accessToken,
      accessTokenExpirationDate: action.accessTokenExpirationDate,
    },
  }),

  STORE_INSTAGRAM_FOLLOWER_COUNT: (state, action) => ({
    ...state,
    instagram_profile: {
      ...state.instagram_profile,
      insta_user_name: action.insta_user_name,
      insta_full_name: action.insta_full_name,
      insta_profile_picture_url: action.insta_profile_picture_url,
      follower_count: action.follower_count,
      post_count: action.post_count,
    },
  }),
  STORE_YOUTUBE_SUBSCRIBER_COUNT: (state, action) => ({
    ...state,
    youtube_profile: {
      ...state.youtube_profile,
      youtube_channel_id: action.youtube_channel_id,
      title: action.title,
      subscriber_count: action.subscriber_count,
      video_count: action.video_count,
      view_count: action.view_count,
      youtube_channel_image: action.channel_image,
    },
  }),

  UPDATE_ARTIST_PROFILE: (state, action) => ({
    ...state,
    artistProfile: {
      ...state.artistProfile,
      isArtist: action.data.user && action.data.user.is_artist,
      maxEarningPotential:
        action.data.user && action.data.user.maximum_earnings_potential,
      artistLevel: action.data.user && action.data.user.artist_level,
      freeProductsAllowance:
        action.data.user && action.data.user.free_products_allowance,
      uploadedVideosCount:
        action.data.user && action.data.user.uploaded_videos_count,
      pendingApprovalCampaigns:
        action.data.user &&
        action.data.user.campaign_registrations.pending_approval,
      cancelledCampaigns:
        action.data.user && action.data.user.campaign_registrations.cancelled,
      approvedCampaigns:
        action.data.user && action.data.user.campaign_registrations.approved,
      declinedCampaigns:
        action.data.user && action.data.user.campaign_registrations.declined,
      videoSharedCampaigns:
        action.data.user &&
        action.data.user.campaign_registrations.video_shared,
      videoInReviewCampaigns:
        action.data.user &&
        action.data.user.campaign_registrations.video_in_review,
      videoApprovedCampaigns:
        action.data.user &&
        action.data.user.campaign_registrations.video_approved,
      cashRequestedCampaigns:
        action.data.user &&
        action.data.user.campaign_registrations.cash_requested,
      completedCampaigns:
        action.data.user &&
        action.data.user.campaign_registrations.cash_disbursed,

      selectedArtistTypes:
        action.data.user &&
        action.data.user.artist &&
        action.data.user.artist.artist_type,
      selectedBrands:
        action.data.user &&
        action.data.user.artist &&
        action.data.user.artist.collaboration_wishes,
      selectedOfferings:
        action.data.user &&
        action.data.user.artist &&
        Utility.convertOfferingsArrayToObject(
          action.data.user.artist.offerings,
        ),
      selectedTrainings:
        action.data.user &&
        action.data.user.artist &&
        action.data.user.artist.trainer_profile &&
        Utility.convertTrainingArray(
          action.data.user.artist.trainer_profile.trainings,
        ),
      eligibleForFreeProducts:
        action.data.user && action.data.user.eligible_for_free_products,
      claimedWelcomeGift:
        action.data.user && action.data.user.claimed_welcome_gift,
      foxyInviteCode: action.data.user && action.data.user.referral_url,
      hasBeenReferred: action.data.user && action.data.user.has_been_referred,
      city:
        action.data.user &&
        action.data.user.artist &&
        action.data.user.artist.city,
      emoji:
        action.data.user &&
        action.data.user.artist &&
        action.data.user.artist.emoji,
      bio:
        action.data.user &&
        action.data.user.artist &&
        action.data.user.artist.bio,
      call_to_action_option_id:
        action.data.user &&
        action.data.user.artist &&
        action.data.user.artist.call_to_action_option_id,
    },
    profile: action.data.user && action.data.user.profile,
    instagram_profile: {
      ...state.instagram_profile,
      ...(action.data.user && action.data.user.instagram_profile),
    },
  }),

  REGISTER_CAMPAIGN_SUCCESS: (state, action) => ({
    ...state,
    artistProfile: {
      ...state.artistProfile,
      pendingApprovalCampaigns: [
        ...state.artistProfile.pendingApprovalCampaigns,
        action.campaignId,
      ],
    },
  }),

  CANCEL_CAMPAIGN_SUCCESS: (state, action) => ({
    ...state,
    artistProfile: {
      ...state.artistProfile,
      cancelledCampaigns: [
        ...state.artistProfile.cancelledCampaigns,
        action.campaignId,
      ],
      pendingApprovalCampaigns: _.without(
        state.artistProfile.pendingApprovalCampaigns,
        action.campaignId,
      ),
    },
  }),

  EDIT_PROFILE_SUCCESS: (state, action) => {
    console.tron.log('user is ', action.user);
    return {
      ...state,
      profile: {
        ...state.profile,
        ...action.user,
      },
      artistProfile: {
        ...state.artistProfile,
        ...action.artist,
      },
    };
  },

  ARTIST_RESPONSE_SUCCESS: (state, action) => ({
    ...state,
    artistProfile: {
      ...state.artistProfile,
      isArtist: true,
    },
  }),

  SAVE_ARTIST_TYPE_LOCAL: (state, action) => ({
    ...state,
    artistProfile: {
      ...state.artistProfile,
      selectedArtistTypes: action.artistTypes,
    },
  }),

  SAVE_BRANDS_SUCCESS: (state, action) => ({
    ...state,
    artistProfile: {
      ...state.artistProfile,
      selectedBrands: action.data.collaboration_wishes,
    },
  }),

  SAVE_OFFERINGS_SUCCESS: (state, action) => ({
    ...state,
    artistProfile: {
      ...state.artistProfile,
      selectedOfferings: action.data,
    },
  }),

  LAST_VIDEO_UPLOAD: (state, action) => ({
    ...state,
    lastVideoUpload: {
      id: action.data.id,
      postData: action.data.postData,
      status: 'in_progress',
      progress: 0,
    },
  }),

  SAVE_TRAININGS_SUCCESS: (state, action) => {
    console.tron.log('save off succe redu', action.data);
    return {
      ...state,
      artistProfile: {
        ...state.artistProfile,
        selectedTrainings: action.data.services,
      },
    };
  },
  ACCEPT_TERMS: (state, action) => ({
    ...state,
    termsAccepted: action.isChecked,
  }),

  TEMP_STORE_INSTA_HANDLE: (state, action) => ({
    ...state,
    tempInstagramHandle: action.handle,
  }),

  SMS_RETRIEVER_STATUS: (state, action) => ({
    ...state,
    smsRetrieverStatus: action.status,
  }),
  SAVE_RESUMABLE_INDEX: (state, action) => ({
    ...state,
    currentOptinStage: action.currentOptinStage,
    lastOptinIndex: action.lastOptinIndex,
  }),
  ALL_ARTIST_TYPES: (state, action) => ({
    ...state,
    artistProfile: {
      ...state.artistProfile,
      allArtistTypes: action.artistTypes,
    },
  }),
  INSTA_VERIFICATION_LAST_INITIATED_AT: (state, action) => ({
    ...state,
    instagram_profile: {
      ...state.instagram_profile,
      insta_verification_last_initiated_at: action.time,
    },
  }),
  SAVE_FREELANCER_PROFILE_LOCAL: (state, action) => ({
    ...state,
    artistProfile: {
      ...state.artistProfile,
      freelancer_profile: {
        ...state.artistProfile.freelancer_profile,
        ...action.payload.data,
      },
    },
  }),
  SAVE_SALON_LOCAL: (state, action) => ({
    ...state,
    artistProfile: {
      ...state.artistProfile,
      selectedSalon: {
        ...state.artistProfile.selectedSalon,
        searchTerm: action.searchTerm,
        selectedId: action.selectedId,
      },
    },
  }),
  SAVE_STORE_LOCAL: (state, action) => ({
    ...state,
    artistProfile: {
      ...state.artistProfile,
      selectedStore: {
        ...state.artistProfile.selectedStore,
        searchTerm: action.searchTerm,
        selectedId: action.selectedId,
      },
    },
  }),
  SAVE_STUDENT_ACADEMY_LOCAL: (state, action) => ({
    ...state,
    artistProfile: {
      ...state.artistProfile,
      selectedStudentAcademy: {
        ...state.artistProfile.selectedStudentAcademy,
        searchTerm: action.searchTerm,
        selectedId: action.selectedId,
      },
    },
  }),
  SAVE_ACADEMY_LOCAL: (state, action) => ({
    ...state,
    artistProfile: {
      ...state.artistProfile,
      selectedAcademy: {
        ...state.artistProfile.selectedAcademy,
        searchTerm: action.searchTerm,
        selectedId: action.selectedId,
      },
    },
  }),

  SAVE_ARTIST_GIFT: (state, action) => ({
    ...state,
    artistGift: action.data && action.data.gift,
  }),

  SAVE_STATUS_MESSAGE: (state, action) => ({
    ...state,
    campaigns: {
      ...state.campaigns,
      statusMessage: action.data,
    },
  }),

  RESET_YOUTUBE_ACCOUNT_LOCAL: (state, action) => ({
    ...state,
    youtube_profile: {
      ...action.youtube_profile_empty_object,
    },
  }),

  RENDER_ONBOARDING: (state, action) => ({
    ...state,
    shouldRenderOnboarding: action.shouldRender,
  }),

  SAVE_CURRENT_PINCODE: (state, action) => ({
    ...state,
    currentPincode: action.data.pincode,
    currentCity: action.data && action.data.city,
  }),

  UPDATE_MEDIA_DATA: (state, action) => {
    // to show in collection page
    console.tron.log('action_data_objects', action.data.objects);
    if (
      !Utility.isBlank(action.data.objects) &&
      action.data.objects.length !== 0 &&
      action.data.objects[0].content === 'product' &&
      !Utility.isBlank(action.data.objects[0].objects)
    ) {
      const productsArray = action.data.objects[0].objects;
      let tempViewedProducts = state.viewedProducts;
      productsArray.forEach((item) => {
        tempViewedProducts = {
          ...tempViewedProducts,
          [item.id]: { last_view: Date.now() / 1000 },
        };
      });
      return {
        ...state,
        viewedProducts: tempViewedProducts,
      };
    }
    return state;
  },

  ADD_INVITE_CODE_SUCCESS: (state, action) => ({
    ...state,
    artistProfile: {
      ...state.artistProfile,
      hasBeenReferred: true,
    },
  }),
  CANCEL_UPLOAD: (state, action) => ({
    ...state,
    isUploading: false,
    lastVideoUpload: {
      id: '',
      postData: {},
      status: '',
      progress: 0,
    },
  }),
  STOP_UPLOADING: (state, action) => ({
    ...state,
    isUploading: false,
  }),

  SAVE_GUEST_AUTH_TOKEN: (state, action) => ({
    ...state,
    guestProfile: {
      ...state.guestProfile,
      guestAuthToken: action.guestAuthToken,
    },
  }),

  SAVE_AUTH_TOKEN: (state, action) => ({
    ...state,
    authToken: action.authToken,
  }),
  ADD_INSTALLED_APPS_LIST: (state, action) => ({
    ...state,
    appInstalled: action.appList,
  }),

  GUEST_FIRST_APP_OPEN: (state, action) => ({
    ...state,
    guestProfile: {
      ...state.guestProfile,
      firstOpenTime: action.firstOpenTime,
    },
  }),
  SAVE_GUEST_STATE: (state, action) => ({
    ...state,
    guestProfile: {
      ...state.guestProfile,
      current_state: action.currentState,
    },
  }),
  SELFIE_ANALYSIS_STATE: (state, action) => ({
    ...state,
    facialAnalysis: {
      ...state.facialAnalysis,
      currentState: action.currentState,
    },
  }),

  SAVE_SELFIE_RESPONSE: (state, action) => ({
    ...state,
    facialAnalysis: {
      ...state.facialAnalysis,
      data: {
        ...state.facialAnalysis.data,
        ...action.dataObject,
      },
    },
  }),

  NEW_USER: (state, action) => ({
    ...state,
    new_user: action.isNewUser,
    has_selfie: action.has_selfie,
  }),

  SAVE_ALL_ARTIST_TYPES: (state, action) => {
    if (Utility.isBlank(action.data)) {
      return state;
    }
    return {
      ...state,
      artistTypes: Utility.convertArrayToObject(action.data),
    };
  },

  USER_ATTRIBUTES: (state, action) => ({
    ...state,
    facialAnalysis: {
      ...state.facialAnalysis,
      userAttributes: action.attributes,
    },
  }),
  IMAGE_UPLOAD_PERCENTAGE: (state, action) => ({
    ...state,
    facialAnalysis: {
      ...state.facialAnalysis,
      uploadPercentage: action.uploadPercentage,
    },
  }),
  SHOW_IMAGE_PROGRESSBAR: (state, action) => ({
    ...state,
    facialAnalysis: {
      ...state.facialAnalysis,
      showImageProgressBar: action.showImageProgressBar,
    },
  }),
  USER_FACIAL_PROPERTIES: (state, action) => ({
    ...state,
    facialAnalysis: {
      ...state.facialAnalysis,
      facialProperties: action.userFacialProperties,
    },
  }),
  SHOW_POPOVER: (state, action) => ({
    ...state,
    facialAnalysis: {
      ...state.facialAnalysis,
      showPopOver: action.showPopOver,
    },
  }),
  LOGIN_MODAL_STATE: (state, action) => ({
    ...state,
    loginModalState: action.loginModalState,
  }),
  LAST_REMOTE_CACHE_REFRESHED_AT: (state, action) => ({
    ...state,
    remote_configs: {
      ...state.remote_configs,
      last_refreshed_at: action.lastCacheRefreshedAt,
    },
  }),
  SAVE_REMOTE_CONFIG: (state, action) => ({
    ...state,
    remote_configs: {
      ...state.remote_configs,
      data: action.configs,
    },
  }),
  SAVE_ARTIST_CTA_OPTIONS: (state, action) => ({
    ...state,
    artistProfile: {
      ...state.artistProfile,
      artistCtaOptions: action.data,
    },
  }),
  SEND_SCAN_PRODUCT_DATA: (state, action) => ({
    ...state,
    scannedProducts: [...state.scannedProducts, state.id],
  }),

  DISMISS_FEATURE_CARD: (state, action) => ({
    ...state,
    featureCards: action.card_details,
  }),
  SET_PHONE_NUMBER_MODAL_INFO: (state, action) => ({
    ...state,
    phoneNumberModalInfo: action.loginModalInfo,
  }),
  SAVE_ALL_EMOJIS: (state, action) => ({
    ...state,
    emojis: action.data,
  }),
  UPDATE_ATTRIBUTE: (state, action) => ({
    ...state,
    facialAnalysis: {
      ...state.facialAnalysis,
      facialProperties: action.attributeValues,
    },
  }),
  SAVE_LAST_USED_ADDRESS: (state, action) => ({
    ...state,
    lastUsedAddress: action.address.id,
    currentPincode: action.address.pincode,
    currentCity: '', // since we have address not, no need to use IP based city
  }),
  SAVE_UPI_DETAILS: (state, action) => ({
    ...state,
    checkoutDetails: {
      ...state.checkoutDetails,
      savedUpis: _.uniqBy(
        [...state.checkoutDetails.savedUpis, action.data],
        'detail',
      ),
    },
  }),
  SAVE_INTENT_DETAILS: (state, action) => ({
    ...state,
    checkoutDetails: {
      ...state.checkoutDetails,
      savedIntents: _.uniqBy(
        [...state.checkoutDetails.savedIntents, action.data],
        'detail',
      ),
    },
  }),
  ARE_ATTRIBUTES_REVIEWED: (state, action) => ({
    ...state,
    areAttributesReviewed: action.attributeReviewed,
  }),
  UPDATE_SURPRISE_LAST_TAKEN: (state, action) => ({
    ...state,
    surprise_last_taken_at: Date.now() / 1000,
  }),
  SAVE_MY_KIT_DATA: (state, action) => ({
    ...state,
    myKitData: action.data,
  }),

  SAVE_MY_SELFIE_ATTRIBUTES: (state, action) => ({
    ...state,
    facialAnalysis: {
      ...state.facialAnalysis,
      my_attributes: action.attributes,
    },
  }),
  APP_LAST_OPEN_AT: (state, action) => ({
    ...state,
    app_last_open_at: action.app_last_open_at,
  }),
  SAVE_UPI_IN_REDUX: (state, action) => {
    if (Utility.isBlank(action.data) || action.data.length === 0) {
      return state;
    }
    return {
      ...state,
      checkoutDetails: {
        ...state.checkoutDetails,
        savedUpis: action.data.map((upiObj) => {
          return {
            mode: 'upi',
            title: 'upi',
            detail: upiObj.virtual_bank_account_id,
            details: upiObj?.details,
          };
        }),
      },
    };
  },

  SAVE_NETBANKING_IN_REDUX: (state, action) => {
    if (Utility.isBlank(action.data) || action.data.length === 0) {
      return state;
    }
    return {
      ...state,
      checkoutDetails: {
        ...state.checkoutDetails,
        savedNb: action.data.map((NbObj) => {
          return {
            mode: 'netbanking',
            name: NbObj.name,
            bankcode: NbObj.bankcode,
          };
        }),
      },
    };
  },

  SAVE_CARDS_IN_REDUX: (state, action) => {
    if (Utility.isBlank(action.data) || action.data.length === 0) {
      return state;
    }
    return {
      ...state,
      checkoutDetails: {
        ...state.checkoutDetails,
        savedCards: action.data.cards,
      },
    };
  },

  SAVE_FACE_ANALYSIS_DATA: (state, action) => {
    return {
      ...state,
      facialAnalysis: {
        ...state.facialAnalysis,
        ...action.data,
      },
    };
  },

  DELETE_FACE_ANALYSIS_DATA: (state, action) => {
    return {
      ...state,
      facialAnalysis: {},
    };
  },

  SAVE_LOCAL_PROFILE_IMAGE_URI: (state, action) => {
    return {
      ...state,
      profile: {
        ...state.profile,
        local_image_uri: action.local_image_uri,
      },
    };
  },
  SAVE_APP_KILL_REASON: (state, action) => {
    return { ...state, last_app_killed_status: action.reason };
  },

  OPT_FOR_PLASTIC_FREE_PACKAGING: (state, action) => {
    return { ...state, optForPlasticFreePackaging: action.optForPlasticFree };
  },

  FREE_PRODUCT_MODAL_OPENED: (state, action) => {
    return { ...state, freeProductModalOpened: action.opened };
  },

  SET_GENDER_USER_ATTRIBUTE: (state, action) => {
    return {
      ...state,
      facialAnalysis: {
        ...state.facialAnalysis,
        my_attributes_values: [
          // ...state.facialAnalysis.my_attributes_values,
          { ...action.object },
        ],
      },
    };
  },
  // SET_FORCE_LOGGED_OUT_FLOW: (state, action) => {
  //   return {
  //     ...state,
  //     force_logged_out_flow: action.isForced,
  //   };
  // },

  PRODUCT_VIEW: (state, action) => {
    return {
      ...state,
      user_engagement: {
        ...state.user_engagement,
        product_ids: !state.user_engagement?.product_ids
          ? [action.productId]
          : [...state.user_engagement?.product_ids, action.productId],
      },
    };
  },

  VIDEO_VIEW: (state, action) => {
    return {
      ...state,
      user_engagement: {
        ...state.user_engagement,
        video_ids: !state.user_engagement.video_ids
          ? [action.videoId]
          : [...state.user_engagement.video_ids, action.videoId],
      },
    };
  },
  UPDATE_UAC_SCORE: (state, action) => {
    return {
      ...state,
      uac_score: action.score,
    };
  },

  UPDATE_NEW_UAC_SCORE: (state, action) => {
    return {
      ...state,
      uac_score: state.uac_score + action.score,
    };
  },

  SAVE_SCANNED_IMAGE_URI: (state, action) => {
    return {
      ...state,
      lastScannedImageUrl: action.imageUrl,
    };
  },

  USER_S1_EVENT_TRIGGERED: (state, action) => {
    return {
      ...state,
      uac_events_status: {
        ...state.uac_events_status,
        user_s1_triggered: action.triggered,
      },
    };
  },

  USER_S2_EVENT_TRIGGERED: (state, action) => {
    return {
      ...state,
      uac_events_status: {
        ...state.uac_events_status,
        user_s2_triggered: action.triggered,
      },
    };
  },

  USER_S3_EVENT_TRIGGERED: (state, action) => {
    return {
      ...state,
      uac_events_status: {
        ...state.uac_events_status,
        user_s3_triggered: action.triggered,
      },
    };
  },

  UAC_GENDER_EVENT_TRIGGERED: (state, action) => {
    const {
      uac_events: { uac_gender_score },
    } = state;
    if (uac_gender_score === action.score) {
      return state;
    }
    return {
      ...state,
      uac_events: {
        ...state.uac_events,
        uac_gender_score: action.score,
      },
    };
  },

  UAC_SELFIE_EVENT_TRIGGERED: (state, action) => {
    const {
      uac_events: { uac_selfie_score },
    } = state;
    if (uac_selfie_score === action.score) {
      return state;
    }
    return {
      ...state,
      uac_events: {
        ...state.uac_events,
        uac_selfie_score: action.score,
      },
    };
  },

  UAC_DEVICE_EVENT_TRIGGERED: (state, action) => {
    const {
      uac_events: { uac_device_score },
    } = state;
    if (uac_device_score === action.score) {
      return state;
    }
    return {
      ...state,
      uac_events: {
        ...state.uac_events,
        uac_device_score: action.score,
      },
    };
  },

  UAC_COMMERCE_APP_EVENT_TRIGGERED: (state, action) => {
    const {
      uac_events: { uac_commerceapps_score },
    } = state;
    if (uac_commerceapps_score === action.score) {
      return state;
    }
    return {
      ...state,
      uac_events: {
        ...state.uac_events,
        uac_commerceapps_score: action.score,
      },
    };
  },

  UAC_BEAUTY_APP_EVENT_TRIGGERED: (state, action) => {
    return {
      ...state,
      uac_events: {
        ...state.uac_events,
        uac_paymentapps_score: action.score.beautyAppScore,
        uac_commerceapps_score: action.score.commerceAppScore,
        uac_device_score: action.score.deviceScore,
      },
    };
  },

  SAVE_GENDER: (state, action) => {
    return {
      ...state,
      gender: action.gender,
    };
  },

  SET_VIDEO_UPLOAD_PROGRESS: (state, action) => {
    return {
      ...state,
      lastVideoUpload: {
        ...state.lastVideoUpload,
        progress: action.progress,
      },
    };
  },
  SET_VIDEO_UPLOAD_STATUS: (state, action) => {
    return {
      ...state,
      lastVideoUpload: {
        ...state.lastVideoUpload,
        status: action.status,
      },
    };
  },
  SET_APP_INSTALL_SOURCE: (state, action) => {
    return {
      ...state,
      appInstalledSource: action.installSource,
    };
  },

  IS_SPECIAL_OFFER_APPLIED: (state, action) => {
    return {
      ...state,
      isSpecialOfferstoApplied: action.isApplied,
    };
  },

  SAVE_PAYTM_DETAIL_TO_REDUX: (state, action) => {
    return {
      ...state,
      paytmDetails: action.paytmDetails,
    };
  },

  POST_DEVICE_INFORMATION: (state, action) => {
    return {
      ...state,
      deviceId: action.deviceId,
      device_information: Utility.jsonParser(action.device_information),
    };
  },

  LAST_NOTIFICATION_MODAL_DISPLAY_TIME: (state, action) => {
    return {
      ...state,
      lastNotificationModalDisplayTime: action.time,
    };
  },

  SAVE_ANDY_WARHOL_IMAGES: (state, action) => {
    return {
      ...state,
      profile: {
        ...state.profile,
        andy_warhol_filters: action.filteredImagesArray,
      },
    };
  },

  ADD_NETWORK_INFO: (state, action) => {
    return {
      ...state,
      networkInfo: action.networkData,
    };
  },

  SWIPE_NEXT_CUEUE: (state, action) => {
    return {
      ...state,
      isSwipeNextCueDisplayed: action.isSwipeNextCueDisplayed,
    };
  },

  SHOW_PROFILE_SHIMMER: (state, action) => {
    return {
      ...state,
      showProfileShimmer: action.show,
    };
  },
  IS_USER_LOGGED_OUT_ONCE: (state, action) => {
    return {
      ...state,
      isUserLogoutOnce: action.isUserLogoutOnce,
    };
  },

  HAS_UAC_DEVICE_EVENT_TRIGGERED: (state, action) => {
    return {
      ...state,
      uac_events_status: {
        ...state.uac_events_status,
        has_device_event_triggered: action.triggered,
      },
    };
  },

  UPDATE_PHONE_NUMBER: (state, action) => ({
    ...state,
    mobileNumber: action.mobileNumber,
  }),

  NUM_OF_UPLOAD_RETRIES: (state, action) => {
    return {
      ...state,
      lastVideoUpload: {
        ...state.lastVideoUpload,
        numOfRetries: action.numOfRetries,
      },
    };
  },

  SAVE_ACTIVE_GROUP_DEALS: (state, action) => {
    return {
      ...state,
      activeGroupDeals: action.activeGroupDeals,
    };
  },

  HAS_PREVIOUS_ASSOCIATED_ACCOUNT: (state, action) => {
    return {
      ...state,
      hasPreviousAssociatedAccount: action.accountExist,
    };
  },

  SAVE_PUBLIC_ID: (state, action) => {
    return {
      ...state,
      publicId: action.publicId,
    };
  },

  APP_REVIEW_SUBMITTED: (state, action) => {
    return {
      ...state,
      appReviewSubmitted: action.submitted,
    };
  },

  ADD_ORDER_ACTION_CARD: (state, action) => {
    const { cardData } = action;
    console.tron.log(cardData, 'cardData');
    return {
      ...state,
      orderActionsCards: {
        ...state.orderActionsCards,
        [`${cardData.entity_type}_${cardData.entity_id}`]: {
          ...state.orderActionsCards[
            `${cardData.entity_type}_${cardData.entity_id}`
          ],
          [`${cardData.id}`]: {
            ...`${cardData.entity_type}_${cardData.entity_id}`[cardData],
            ...cardData,
          },
        },
      },
    };
  },
  LIKE_SALON: (state, action) => {
    return {
      ...state,
      likedSalons: [...state.likedSalons, action.salonId],
    };
  },

  UNLIKE_SALON: (state, action) => {
    const likedSalons = state.likedSalons.filter((e) => e !== action.salonId);
    return {
      ...state,
      likedSalons: likedSalons,
    };
  },

  OPT_FOR_CARRY_BAG: (state, action) => {
    return { ...state, optForCarryBag: action.optForCarryBag };
  },

  STORE_FAQ_LIKE_AND_DISLIKE_DATA: (state, action) => {
    return {
      ...state,
      isClickedLikeAndDislike: [action.faqId, ...state.isClickedLikeAndDislike],
    };
  },

  SET_CONGRATS_MODAL_EDGE: (state, action) => {
    return {
      ...state,
      showCongratsModalForEdge: action?.show,
    };
  },

  SAVE_LOCAL_NOTIFICATION: (state, action) => {
    if (
      action.data.type === 'payment_success_notification' ||
      action.data.type === 'cart_reminder_notification'
    ) {
      const filteredLocalNotification =
        state.notificationData?.filter(
          (obj) => obj?.type != 'cart_reminder_notification',
        ) || [];
      return {
        ...state,
        notificationData: [...filteredLocalNotification, action.data],
      };
    }
    return {
      ...state,
      notificationData: [...state.notificationData, action.data],
    };
  },

  UPDATE_LOCAL_NOTIFICATION: (state, action) => {
    const updatedLocalNotification = state.notificationData?.map(
      (notification) =>
        notification.type === action.notificationType
          ? { ...notification, isClicked: true }
          : notification,
    );
    return {
      ...state,
      notificationData: updatedLocalNotification,
    };
  },

  SET_APP_FIRST_OPENED: (state, action) => {
    return {
      ...state,
      appFirstOpened: action?.appFirstOpened,
    };
  },

  SET_SHOW_EDGE_PENDING_INDICATOR: (state, action) => {
    return {
      ...state,
      showEdgePendingIndicator: action?.showEdgePendingIndicator,
    };
  },

  ADD_CONTINUE_ACTION: (state, action) => {
    return {
      ...state,
      continueAction: {
        ...state.continueAction,
        continueSlug: action?.slug ?? '',
        continueImage: action?.image ?? '',
        continueScreenType: action?.continueScreenType ?? '',
      },
    };
  },

  SAVE_SHOPIFY_ADDRESS: (state, action) => ({
    ...state,
    addresses: Utility.isBlank(action.data)
      ? {}
      : normalize(action.data, addressesSchema).entities.address,
  }),

  ADD_TO_RECENTLY_VIEWED: (state, action) => {
    const { productId } = action;
    const arr = state.recentlyViewedProductIds;
    const index = arr.indexOf(productId);
    if (index > -1) {
      arr.splice(index, 1);
    }
    arr.unshift(productId);
    if (arr.length > 100) {
      arr.slice(0, 100);
    }
    return {
      ...state,
      lastVisitedProductId: productId,
      recentlyViewedProductIds: [...arr],
    };
  },

  TOGGLE_DYNAMIC_LIST_TITLE_VISIBILITY: (state, action) => ({
    ...state,
    showDynamicListTitle: action.isVisible,
  }),

  UPDATE_LAST_VISITED_PRODUCT_ID: (state, action) => ({
    ...state,
    lastVisitedProductId: action.id,
  }),

  ADD_EMAIL_TO_LOCAL_STORE: (state, action) => ({
    ...state,
    profile: {
      ...state.profile,
      email: action.email,
      email_verified: action.emailVerified ?? state.profile.email_verified,
    },
  }),
  USER_REGISTERED_ON_CT: (state, action) => ({
    ...state,
    userRegisteredOnCt: action.registered,
  }),
  SAVE_USER_LOCATION_INFO: (state, action) => ({
    ...state,
    locationInfo: action.locationInfo,
    locationUpdatedAt: action.locationUpdatedAt,
  }),
  UPDATE_USER_AUTHORIZED: (state, action) => {
    return {
      ...state,
      authorized: action.authorized,
    };
  },
  ADD_WEB_TOKENS: (state, action) => ({
    ...state,
    authToken: action.authToken,
    guestProfile: {
      ...state.guestProfile,
      guestAuthToken: action.guestToken,
    },
    authorized: action.authToken ? true : state.authorized,
  }),

  SET_ARTIST_HANDLE: (state, action) => ({
    ...state,
    profile: {
      ...state.profile,
      handle: action.handle,
    },
  }),
  

  SET_ACTIVE_ROUTINE_SLUGS: (state, action) => {
    const { activeRoutineSlugs = [] } = state;
    const newActiveRoutineSlugs = [
      ...activeRoutineSlugs,
      ...action?.activeRoutineSlugs,
    ];
    return {
      ...state,
      activeRoutineSlugs: Array.from(
        new Set(newActiveRoutineSlugs),
      ),
    };
  },
};

const UserAccountInfo = createReducer(initialState.userInfo, actionHandlers);

export default UserAccountInfo;
