// Dependencies
import React, { useRef, useEffect } from 'react';
import { View, Text, Platform, TouchableOpacity } from 'react-native';
import StyleSheet from 'react-native-media-query';
import Config from '../../libraries/ReactNativeConfig';
import { FlatList } from 'react-native-gesture-handler';
import Toast from 'react-native-easy-toast';
import { useNavigation } from '@react-navigation/native';
import Utility from '../../utils/Utility';
import colors from '../../theme/Colors';
import FoxyShadowButton from '../../lib/FoxyShadowButton';
import { AnalyticsManager, EventType } from '../../analytics';
import FastImageView from '../FastImageView';
import ProgressBarAnimated from '../../lib/ProgressBarAnimated';
import images from '../../theme/Images';
import DebouncedTouchableOpacity from '../shared/DebouncedTouchableOpacity';
import AddToCart from './AddToCart';
import PRODUCT from '../../config/LayoutConstants/ProductConfig';
import AppConfig from '../../config/AppConfig';
import ModalContainer from '../modals/ModalContainer';
import { isDesktop } from '../../utils/BooleanUtility';
import { getScreenWidth } from '../../utils/LayoutUtility';


const UnfulfilledOfferModal = (props) => {
  const navigation = useNavigation();
  const toastRef = useRef(null);

  const {
    params: {
      itemData,
      navigateToOfferDetail,
      fromProductPage = false,
      currentSku,
      selectedVariant,
      productInfo,
      setUnFullfilledCoupon,
    } = {},
  } = props?.route || {};

  useEffect(() => {
    if (itemData?.coupon_code && itemData?.message) {
      AnalyticsManager.logEvent(EventType.offer.OFFER_APPLICATION_FAILURE, {
        coupon_code: itemData?.coupon_code,
        reason: itemData?.message,
      });
    }
  }, []);

  const secondaryButtonClick = () => {
    AnalyticsManager.logEvent('modal_action', {
      modal_name: 'unfulfilled_offer',
      cta: itemData?.cta_text || '',
    });
    goBack();
    navigateToOfferDetail(itemData?.cta_url);
  };

  const CartProducts = ({ item, index }) => {
    return (
      <View style={unFullfilledCouponModalStyles.cartProductsContainer}>
        <FastImageView
          source={item}
          style={unFullfilledCouponModalStyles.cartProducts}
        />
      </View>
    );
  };

  const goBack = () => {
   
    navigation.goBack();
  };

  const CrossButton = () => {
    return (
      <TouchableOpacity
        style={unFullfilledCouponModalStyles.crossButtonContainer}
        onPress={goBack}
        hitSlop={Utility.getHitSlop('likeButton')}
      >
        <FastImageView
          source={images.salon.cross_black_2x.uri}
          style={unFullfilledCouponModalStyles.crossButton}
        />
      </TouchableOpacity>
    );
  };
  return (
    <ModalContainer>
      <View style={unFullfilledCouponModalStyles.modalContainer} dataSet={{ media: ids.modalContainer }}>
        <View style={unFullfilledCouponModalStyles.headingContainer}>
          <Text style={unFullfilledCouponModalStyles.couponcode}>
            {itemData?.coupon_code}
          </Text>

          <CrossButton />
        </View>

        <Text style={unFullfilledCouponModalStyles.heading}>
          {itemData?.message}
        </Text>

        {itemData?.bar_percent != 0 && (
          <View style={{ marginTop: 16 }}>
            <ProgressBarAnimated
              width={Utility.getScreenWidth() / (isDesktop() ? 2 : 1) - 24}
              height={4}
              value={itemData?.bar_percent}
              backgroundColor={colors.green}
              underlyingColor={colors.background}
              borderWidth={0}
              final={itemData.required_value}
              current={itemData.current_value}
              fromOffer
            />
          </View>
        )}

        <FlatList
          horizontal
          data={itemData?.images}
          renderItem={CartProducts}
          keyExtractor={(item) => `${item}`}
        />

        {!fromProductPage && (
          <Text style={unFullfilledCouponModalStyles.additional_message}>
            {itemData?.addition_message || ''}
          </Text>
        )}

        <View
          style={unFullfilledCouponModalStyles.actionButtonsContainer}
        >
          <DebouncedTouchableOpacity
            {...props}
            style={unFullfilledCouponModalStyles.secondaryButtonStyle}
            onPress={secondaryButtonClick}
            dataSet={{ media: ids.secondaryButtonStyle }}
          >
            <Text
              style={unFullfilledCouponModalStyles.secondaryButtonTextStyle}
            >
              {itemData?.cta_text}
            </Text>
          </DebouncedTouchableOpacity>
        </View>
        {fromProductPage && (
          <AddToCart
            addToCartLayout={PRODUCT.ADD_TO_CART_LAYOUT.PRODUCT_DETAIL}
            skuId={currentSku}
            hasVariants={productInfo?.has_variants}
            selectedVariant={selectedVariant}
            itemData={productInfo}
            alwaysShowQuantitySelector={AppConfig.getBooleanValue(
              Config.SHOW_QUANTITY_IN_ADD_TO_BAG_ALWAYS,
            )}
            inModal
            removeUnFullfilledCoupon={goBack}
            can_offer_fullfill={itemData?.can_offer_fullfill || false}
            offer_id={itemData.coupon_code}
            setUnFullfilledCoupon={setUnFullfilledCoupon}
            buyNowButtonDataSet={{ media: ids.addToCart }}
          />
        )}
      </View>
      <Toast ref={toastRef} />
    </ModalContainer>
  );
};

export default UnfulfilledOfferModal;

const { ids, styles: unFullfilledCouponModalStyles } = StyleSheet.create({
  modalStyle: {
    justifyContent: 'flex-end',
    alignItems: 'center',
    bottom: 0,
    left: 0,
    right: 0,
    height: 20,
  },
  modalContainer: {
    width: Utility.getScreenWidth(),
    borderRadius: 6,
    backgroundColor: 'white',
    marginBottom: -20,
    paddingBottom: 20,
    paddingHorizontal: 12,
    paddingTop: 16,
    '@media (min-width: 768px)': {
      width: Utility.getScreenWidth() / 2,
    },
  },
  offersContainer: { marginVertical: 8 },
  actionButtonsContainer: {
    marginTop: 10,
  },

  actionButtonsContainerFromProductPage: {
    marginTop: 10,
  },

  firstButtonStyle: {
    marginBottom: 8,
    borderColor: colors.green,
    borderWidth: 1,
  },
  firstButtonTextStyle: {
    color: colors.green,
    fontSize: 14,
  },
  secondaryButtonTextStyle: {
    fontSize: 14,
    color: colors.black,
  },
  offersInfoContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginVertical: 4,
    marginHorizontal: 16,
  },
  couponCodePillStyle: {
    flex: 1,
    height: 24,
    marginRight: 4,
  },
  offersDescription: {
    alignSelf: 'flex-start',
    flex: 4,
    paddingHorizontal: 4,
    color: colors.foxyBlack,
    fontSize: 12,
    fontFamily: 'Roboto-Regular',
  },
  couponcode: {
    fontSize: 19,
    fontFamily: 'Roboto-Bold',
    fontStyle: 'normal',
    color: colors.foxyBlack,
    marginBottom: 4,
  },
  heading: {
    fontSize: 14,
    fontFamily: 'Roboto-Regular',
    fontStyle: 'normal',
    color: colors.foxyBlack,
  },
  additional_message: {
    fontSize: 12,
    fontFamily: 'Roboto-Regular',
    fontStyle: 'normal',
    color: colors.foxyBlack,
    marginTop: 22,
  },
  cartProductsContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 50,
    width: 50,
    borderWidth: 1,
    borderRadius: 4,
    marginRight: 4,
    borderColor: colors.background,
    marginTop: 14,
  },
  cartProducts: {
    height: 40,
    width: 40,
  },
  barContainer: {
    width: '100%',
    height: 4,
    backgroundColor: colors.borderDark,
    marginVertical: 12,
    borderRadius: 100,
  },
  bar: { height: 4, backgroundColor: colors.green, borderRadius: 100 },
  crossButtonContainer: {
    backgroundColor: colors.background,
    height: 24,
    width: 24,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 11,
    padding: 4,
  },
  headingContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  crossButton: {
    height: 12,
    width: 12,
    ...Platform.select({
      web: {},
      default: { tintColor: colors.background },
    }),
  },
  secondaryButtonStyle: {
    height: 48,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 8,
    marginVertical: 8,
    width: Utility.getScreenWidth() - 24,
    borderWidth: 1,
    borderBottomWidth: 1.2,
    borderColor: colors.black,
    backgroundColor: colors.white,
    '@media (min-width: 768px)': {
      width: Utility.getScreenWidth() / 2 - 24,
    },
  },
  addToCart: {
    '@media (min-width: 768px)': {
      width: Utility.getScreenWidth() / 2 - 24,
    },
  },
});
