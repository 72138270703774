import {
  Alert,
  Linking,
  NativeEventEmitter,
  NativeModules,
  Platform,
} from 'react-native';
import Permissions from '../libraries/ReactNativePermissions';
import AnalyticsManager from '../analytics/AnalyticsManager';
import EventParameterKey from '../analytics/EventParameterKey';
import EventParameterValue from '../analytics/EventParameterValue';
import { isAndroid, isBlank, isIOS, isPresent, isWeb } from './BooleanUtility';
import EventType from '../analytics/AnalyticsEventType';
import { savePermissionState } from '../actions/ActionTypes';
import DeviceInfoUtils from './DeviceInfoUtils';
import {
  PERMISSION_CONSTANTS,
  PERMISSION_STATUS,
  PERMISSION_STORE,
} from '../config/Constants';
import { triggerPermissionsEvent } from '../analytics/PermissionsEvents';
import { createAndroidNotificationChannels } from './AndroidNotificationsUtils';
import { getStoreRef } from '../store/StoreUtility';
import { ReactMoE } from '../libraries/ReactMoe';
import { SCREEN_CONSTANTS } from '../config/ScreenConstants';
import NavigationService from '../navigator/NavigationService';
import { PermissionsAndroid } from '../libraries/NativeLibraries';
// import CleverTapAnalytics from '../analytics/CleverTapAnalytics';

export default class PermissionsUtility {
  static notificationSource = 'app_start';

  static SHOW_NOTIFICATION_PROMPT_FOR_CURRENT_SESSION = {
    [SCREEN_CONSTANTS.WISHLIST_PAGE]: true,
    [SCREEN_CONSTANTS.ORDER_COMPLETION]: true,
    [SCREEN_CONSTANTS.NOTIFY_ME]: true,
    [SCREEN_CONSTANTS.PRODUCT_WISHLISTING]: true,
    [SCREEN_CONSTANTS.WALLET_PAGE]: true,
    [SCREEN_CONSTANTS.ROUTINES_PAGE]: true,
    [SCREEN_CONSTANTS.BRAND]: true,
    [SCREEN_CONSTANTS.CATEGORY]: true,
    [SCREEN_CONSTANTS.FEED]: true,
    [SCREEN_CONSTANTS.ACTIVE_ROUTINE]: true,
    [SCREEN_CONSTANTS.POST_ROUTINE]: true,
    [SCREEN_CONSTANTS.PRE_ROUTINE]: true,
  };

  static openSettingsAlert = (
    alertTitle = '',
    alertDescription = '',
    onCancelAlert = () => {},
  ) => {
    Alert.alert(alertTitle, alertDescription, [
      {
        text: 'Open Settings',
        onPress: Linking.openSettings,
      },
      {
        text: 'Cancel',
        onPress: onCancelAlert,
        style: 'cancel',
      },
    ]);
  };

  static notificationManagerIOS = new NativeEventEmitter(
    NativeModules.LocalNotificationHandler,
  );

  static requestPermissionAndOpenSettings = async (
    type = '',
    alertTitle = '',
    alertDescription = '',
    onCancelAlert = () => {},
  ) => {
    try {
      const response = await Permissions.request(type);
      const isPermissionAllowed = response === 'authorized';
      if ((isIOS() && response === 'denied') || response === 'restricted') {
        this.openSettingsAlert(alertTitle, alertDescription, onCancelAlert);
      }

      logPermissionEvent(isPermissionAllowed, {
        type,
      });
      return isPermissionAllowed;
    } catch (error) {
      AnalyticsManager.logEvent(
        EventType.appLifeCycleEvents.EXCEPTION_CAPTURED,
        {
          [EventParameterKey.SOURCE]: `PermissionsUtility.js, type: ${type}. Error:  ${error}`,
        },
      );
      return false;
    }
  };

  static handleStoragePermission(error, successCallback = () => {}) {
    const state = getStoreRef().getState();
    const permission = PERMISSION_CONSTANTS.storage;
    const { permissionStatus: { storage = '' } = {} } = state;
    let storagePermissionState = storage;
    if (error === 'E_NO_LIBRARY_PERMISSION') {
      successCallback(false);
      if (storagePermissionState === 'never_ask_again') {
        return;
      } else if (isBlank(storagePermissionState)) {
        storagePermissionState = 'denied';
      } else if (storagePermissionState === 'denied') {
        storagePermissionState = 'never_ask_again';
      }

      getStoreRef().dispatch(
        savePermissionState(permission, storagePermissionState),
      );
    }
  }

  static checkPreviousStoragePermission(prevProps) {
    const state = getStoreRef().getState();
    const { permissionStatus: { storage: storagePermissionStatus = '' } = {} } =
      state;
    const { permissionStatus: { storage: prevStoragePermission = '' } = {} } =
      prevProps;
    if (prevStoragePermission !== storagePermissionStatus) {
      return storagePermissionStatus;
    }
    return prevStoragePermission;
  }

  static handleRequestExternalStoragePermission = (
    onUploadMediaPress = () => {},
    storagePermissionStatus = '',
    onCancelAlert = () => {},
    id = '',
    successCallback = () => {},
    activity_id = '',
  ) => {
    const mediaLibrary = isAndroid() ? 'storage' : 'mediaLibrary';
    const alertTitle = 'Permission Required';
    const alertDescription =
      'Change Access permission inside Settings to allow photo access';
    Permissions.request(mediaLibrary).then((response) => {
      const isPermissionAllowed = response === 'authorized';
      const metaData = { type: 'media_library' };
      if (response === 'authorized') {
        onUploadMediaPress(id, successCallback, activity_id);
        return;
      }
      if (
        (isIOS() && response === 'denied') ||
        storagePermissionStatus === 'never_ask_again'
      ) {
        PermissionsUtility.openSettingsAlert(
          alertTitle,
          alertDescription,
          onCancelAlert,
        );
      }
      logPermissionEvent(isPermissionAllowed, metaData);
    });
  };

  static osBasedPermissionName = (permissionName) => {
    if (isAndroid()) {
      return permissionName.android;
    }
    return permissionName.ios;
  };

  static checkPermissionStatic(
    permissionName,
    requiredInIOS,
    callback,
    meta = {},
  ) {
    const permissionTag =
      PermissionsUtility.osBasedPermissionName(permissionName);
    if (isAndroid()) {
      PermissionsAndroid.request(permissionTag)
        .then((data) => {
          if (data === 'granted') {
            logPermissionEvent(true, meta);
          } else {
            logPermissionEvent(false, meta);
          }
          callback(data);
        })
        .catch(() => {
          callback(false);
        });
    }
    if (requiredInIOS) {
      Permissions.request(permissionTag)
        .then((data) => {
          if (data === 'authorized') {
            logPermissionEvent(true, meta);
          } else {
            logPermissionEvent(false, meta);
          }
          callback(data);
        })
        .catch((err) => callback(false));
    }
  }

  static createPermissionMetaObject = (screen_name, permission_name) => {
    return { screen_name, permission_name };
  };

  static checkIOSPermissionStatus = (callback = () => {}) => {
    const state = getStoreRef().getState();
    const { permissionStatus = {} } = state;
    const { notification = '' } = permissionStatus;

    getIOSNotificationPermission('app_start', (permission) => {
      let status = 'not_asked';
      if (isBlank(notification) && permission === 'NotificationNotDetermined') {
        status = 'not_asked';
        // CleverTapAnalytics.subscribeToNotifications(false);
      } else if (permission === 'NotificationProvisional') {
        status = 'provisional';
        // CleverTapAnalytics.subscribeToNotifications(true);
      } else if (permission === 'NotificationDenied') {
        status = 'never_ask_again';
        // CleverTapAnalytics.subscribeToNotifications(false);
      } else if (permission === 'NotificationAuthorized') {
        status = 'allowed';
        // CleverTapAnalytics.subscribeToNotifications(true);
      }

      callback(status);

      savePermissionStatus(PERMISSION_CONSTANTS.notification, status, true);
    });
    getLiveActivityPermission((status) => {
      savePermissionStatus(PERMISSION_CONSTANTS.liveActivity, status);
    });
  };

  static permissionRequestCallback = (granted, data, permissionName) => {
    const state = getStoreRef().getState();
    const { permissionStatus = {} } = state;

    PermissionsUtility.setNotificationModalVisibilityForCurrentSession(
      SCREEN_CONSTANTS.FEED,
      false,
    );

    let currentStatus = data;
    const prevStatus = permissionStatus['notification'];
    if (data === 'never_ask_again') {
      currentStatus = 'dismissed';
    }
    if (prevStatus === 'denied' && data === 'never_ask_again') {
      currentStatus = 'never_ask_again';
    }

    if (granted) {
      ReactMoE.pushPermissionResponseAndroid(granted);
      // CleverTapAnalytics.subscribeToNotifications(granted);
      createAndroidNotificationChannels();
      triggerPermissionsEvent(
        EventType.miscAppEvents.PERMISSION_ALLOW,
        permissionName,
        this.notificationSource,
      );
      currentStatus = 'allowed';
    } else {
      triggerPermissionsEvent(
        EventType.miscAppEvents.PERMISSION_DENY,
        permissionName,
        this.notificationSource,
      );
    }

    savePermissionStatus(PERMISSION_CONSTANTS.notification, currentStatus);
  };

  static checkAndroidNotificationPermission = (callback = () => {}) => {
    const state = getStoreRef().getState();
    const { permissionStatus = {} } = state;
    const currentNotificationStatus = permissionStatus['notification'];

    if (Platform.Version < 33) {
      checkNotificationStatusFromNativeLayer(
        currentNotificationStatus,
        callback,
      );
      return;
    }

    if (currentNotificationStatus === 'denied') {
      if (
        PermissionsUtility.shouldShowNotificationModalForCurrentSession(
          SCREEN_CONSTANTS.FEED,
        )
      ) {
        NavigationService.push('NotificationPromptModal', {
          type: SCREEN_CONSTANTS.FEED,
        });
        PermissionsUtility.setNotificationModalVisibilityForCurrentSession(
          SCREEN_CONSTANTS.FEED,
          false,
        );
      }
      return;
    }

    checkNotificationStatusFromNativeLayer(
      currentNotificationStatus,
      (status) => {
        if (status === 'allowed') {
          // This is a corner case where user turned on notifications before opening app for the first time or
          // before seeing first notification prompt.
          callback('allowed');
        } else {
          if (isBlank(currentNotificationStatus)) {
            // This case occurs when user opens the app for the first time after installing.
            callback('not_asked');
          } else if (status === 'never_ask_again') {
            // This case occurs when user has denied notification permission everytime or turned
            // notifications off from the device settings/
            callback('never_ask_again');
            return;
          }
          // Trigger notification prompt to get notification permission.
          triggerPermissionsEvent(
            EventType.miscAppEvents.PERMISSION_PROMPT,
            EventParameterValue.PERMISSIONS.notifications,
            'app_launch',
          );
          checkPermission(
            PERMISSION_STORE.notification,
            false,
            (granted, data) =>
              this.permissionRequestCallback(
                granted,
                data,
                EventParameterValue.PERMISSIONS.notifications,
              ),
            {},
            false,
          );
        }
      },
    );
  };

  static requestPermission = {
    notification: {
      android: PermissionsUtility.checkAndroidNotificationPermission,
      ios: PermissionsUtility.checkIOSPermissionStatus,
    },
  };

  static askPermissionOnAppStartUp = (permission) => {
    if (isWeb()) return;
    PermissionsUtility.requestPermission[permission][Platform.OS]((status) => {
      if (
        ![
          'not_asked',
          'granted',
          'allowed',
          'provisional',
          'authorized',
        ].includes(status)
      ) {
        if (
          PermissionsUtility.shouldShowNotificationModalForCurrentSession(
            SCREEN_CONSTANTS.FEED,
          )
        ) {
          NavigationService.push('NotificationPromptModal', {
            type: SCREEN_CONSTANTS.FEED,
          });
          PermissionsUtility.setNotificationModalVisibilityForCurrentSession(
            SCREEN_CONSTANTS.FEED,
            false,
          );
        }
      }

      AnalyticsManager.logEvent(
        EventType.appLifeCycleEvents.NOTIFICATION_STATUS,
        {
          [EventParameterKey.NOTIFICATION_STATUS]: status,
        },
      );
    });
  };

  static shouldShowNotificationModalForCurrentSession(type) {
    return PermissionsUtility.SHOW_NOTIFICATION_PROMPT_FOR_CURRENT_SESSION[
      type
    ];
  }

  static setNotificationModalVisibilityForCurrentSession(type, visibility) {
    PermissionsUtility.SHOW_NOTIFICATION_PROMPT_FOR_CURRENT_SESSION[type] =
      visibility;
  }

  static resetNotificationModalVisibilityForCurrentSession() {
    PermissionsUtility.SHOW_NOTIFICATION_PROMPT_FOR_CURRENT_SESSION = {
      [SCREEN_CONSTANTS.WISHLIST_PAGE]: true,
      [SCREEN_CONSTANTS.ORDER_COMPLETION]: true,
      [SCREEN_CONSTANTS.NOTIFY_ME]: true,
      [SCREEN_CONSTANTS.PRODUCT_WISHLISTING]: true,
      [SCREEN_CONSTANTS.WALLET_PAGE]: true,
      [SCREEN_CONSTANTS.ROUTINES_PAGE]: true,
      [SCREEN_CONSTANTS.BRAND]: true,
      [SCREEN_CONSTANTS.CATEGORY]: true,
      [SCREEN_CONSTANTS.FEED]: true,
      [SCREEN_CONSTANTS.ACTIVE_ROUTINE]: true,
      [SCREEN_CONSTANTS.POST_ROUTINE]: true,
      [SCREEN_CONSTANTS.PRE_ROUTINE]: true,
    };
  }
}

export const savePermissionStatus = (
  permission,
  status,
  saveToRedux = true,
  sendResponseToServer = true,
) => {
  if (saveToRedux) {
    getStoreRef().dispatch(savePermissionState(permission, status));
  }

  AnalyticsManager.setUserProperty(
    'notification_status',
    PERMISSION_STATUS[status] || 'not_asked',
  );

  if (sendResponseToServer) {
    DeviceInfoUtils.retrieveDeviceInfo();
  }
};

export const getIOSNotificationPermission = (source, callback) => {
  if (isAndroid() || isWeb()) {
    return;
  }
  const event = 'notification_permission';
  const permissionListener =
    PermissionsUtility.notificationManagerIOS.addListener(
      event,
      ({ action }) => {
        callback(action);
        permissionListener?.remove();
      },
    );

  if (isIOS()) {
    NativeModules.LocalNotificationHandler.checkNotificationPermissions();
  }
};

/**
 * FIXME:
 * 1 - These methods shouldn't be here in Utility. because PermissionUtility is responsible for this action.
 * 2 - Raising and event is also a responsibility of permissionUtility.
 *
 * Moving further, we will remove these  related methods from this class
 */
export const checkPermission = (
  permissionName,
  requiredInIOS,
  callback,
  meta = {},
  logEvent = true,
) => {
  const permissionTag =
    PermissionsUtility.osBasedPermissionName(permissionName);
  if (isAndroid()) {
    PermissionsAndroid.request(permissionTag)
      .then((data) => {
        if (data === 'granted') {
          if (logEvent) {
            logPermissionEvent(true, meta);
          }
          callback(true, data);
        } else {
          if (logEvent) {
            logPermissionEvent(false, meta);
          }
          callback(false, data);
        }
      })
      .catch(() => callback(false, {}));
  }
  if (requiredInIOS && isIOS()) {
    Permissions.request(permissionTag)
      .then((data) => {
        if (data === 'authorized') {
          if (logEvent) {
            logPermissionEvent(true, meta);
          }
          callback(true, 'authorized');
        } else {
          if (logEvent) {
            logPermissionEvent(false, meta);
          }
          callback(false, 'denied');
        }
      })
      .catch((err) => callback(false));
  }
};

export const checkNotificationStatusFromNativeLayer = (
  previousStatus,
  callback = () => {},
) => {
  NativeModules.NotificationsChannelModule.notificationEnabledForApp()
    .then((status) => {
      ReactMoE.pushPermissionResponseAndroid(status);
      // CleverTapAnalytics.subscribeToNotifications(status);
      if (status) {
        savePermissionStatus(PERMISSION_CONSTANTS.notification, 'granted');
        callback('allowed');
      } else if (!status && previousStatus === 'granted') {
        savePermissionStatus(
          PERMISSION_CONSTANTS.notification,
          'never_ask_again',
        );
        callback('never_ask_again');
      } else {
        callback(previousStatus);
      }
    })
    .catch((err) => {
      console.tron.log(
        'Exception:checkNotificationStatusFromNativeLayer()',
        err,
      );
    });
};

export const checkAndroidNotificationStatusFromNativeLayer = (
  previousStatus,
  callback = () => {},
) => {
  NativeModules.NotificationsChannelModule.notificationEnabledForApp()
    .then((status) => {
      if (status) {
        callback('allowed');
      } else if (!status && previousStatus === 'granted') {
        callback('never_ask_again');
      } else {
        callback(previousStatus);
      }
    })
    .catch((err) => {
      console.tron.log(
        'Exception:checkNotificationStatusFromNativeLayer()',
        err,
      );
    });
};

export const getLiveActivityPermission = (callback) => {
  if (isAndroid()) {
    callback(false);
  } else {
    NativeModules.LocalNotificationHandler.getLiveActivityPermission(
      (status) => {
        callback(status);
      },
    );
  }
};

export const getNotificationStatus = (callback) => {
  if (isAndroid()) {
    NativeModules.NotificationsChannelModule.notificationEnabledForApp()
      .then((status) => {
        let notificationStatus = 'never_ask_again';
        if (status) {
          notificationStatus = 'allowed';
        }
        callback(notificationStatus);
      })
      .catch((err) => {
        callback('status_fetch_error');
      });
  } else {
    getIOSNotificationPermission('AppNavigator', (permission) => {
      let status = 'not_asked';
      if (permission === 'NotificationNotDetermined') {
        status = 'not_asked';
      } else if (permission === 'NotificationProvisional') {
        status = 'provisional';
      } else if (permission === 'NotificationDenied') {
        status = 'never_ask_again';
      } else if (permission === 'NotificationAuthorized') {
        status = 'allowed';
      }
      callback(status);
    });
  }
};

export const logPermissionEvent = (isAllowed, meta) => {
  const eventName = isAllowed
    ? EventType.miscAppEvents.PERMISSION_ALLOW
    : EventType.miscAppEvents.PERMISSION_DENY;

  AnalyticsManager.logEvent(eventName, meta);
};

export const checkMediaLibraryPermission = async () => {
  return PermissionsAndroid.check('android.permission.READ_MEDIA_IMAGES').then(
    (granted) => {
      return granted;
    },
  );
};
