import React, { Component } from 'react';
import { View, Text, TouchableOpacity, Image } from 'react-native';
import styles from './styles';
import colors from '../../theme/Colors';
import { MAX_LINES, WishlistAsBottomTab } from '../../config/Constants';
import Utility from '../../utils/Utility';
import DynamicLinkManager from '../../utils/DynamicLinkManager';
import { AnalyticsManager, EventType } from '../../analytics';
import _ from 'lodash';
import FastImageView from '../../components/FastImageView';
import Config from '../../libraries/ReactNativeConfig';
import DebouncedTouchableOpacity from '../../components/shared/DebouncedTouchableOpacity';
import { navigateToScreen } from '../../utils/NavigationUtility';

class Options extends Component {
  constructor(props) {
    super(props);
    this.debouncedOnPillClicked = _.debounce(this.onPillClicked, 500, {
      leading: true,
      trailing: false,
    });
    this.updateSearchQueryCache = {};
    this.pillClickHandlerCache = {};
  }

  updateSearchQuery = (query) => {
    const { onPillPress } = this.props;
    onPillPress(query);
  };

  handleRouteFromLink = (route, slug, path, extra) => {
    const { navigation } = this.props;
    if (WishlistAsBottomTab && route === 'TodayDeals') {
      navigation.navigate('Wishlist', { slug, extra, source: 'empty_search' });
      return;
    }
    if (Config.TABS.includes(route)) {
      navigation.navigate(route, { slug, extra, source: 'empty_search' });
      return;
    }
    navigateToScreen({
      navigation,
      type: 'push',
      screen: route,
      params: { slug, extra, source: 'empty_search' },
    });
  };

  memoizedUpdateSearchQuery = () => {
    return (searchQuery = '') => {
      AnalyticsManager.logEvent(EventType.search.SEARCH_INITIATE, { search_query: searchQuery });
      if (!this.updateSearchQueryCache[searchQuery]) {
        this.updateSearchQueryCache[searchQuery] = () =>
          this.updateSearchQuery(searchQuery);
      }

      return this.updateSearchQueryCache[searchQuery];
    };
  };

  optionPill = (props) => {
    const { option } = props;
    const updateSearchQuery = this.memoizedUpdateSearchQuery()(option);
    return (
      <TouchableOpacity onPress={updateSearchQuery} style={styles.optionPill}>
        <Text
          allowFontScaling={false}
          style={styles.optionText}
          numberOfLines={MAX_LINES.single}
        >
          {option}
        </Text>
      </TouchableOpacity>
    );
  };

  onPillClicked = (option) => {
    AnalyticsManager.logEvent(EventType.search.SEARCH_INITIATE, { search_query: option?.text || '' });
    if (Utility.isPresent(option.slug)) {
      AnalyticsManager.logEvent(EventType.discoveryEvents.ITEM_ACTION, {
        source: 'pill_clicked',
        text: option?.text,
      });

      DynamicLinkManager.handleLinkWithInternalTrackingParams(
        option.slug,
        this.handleRouteFromLink,
      );
    } else {
      this.updateSearchQuery(option.text);
    }
  };

  memoizedOnPillObjectClicked = () => {
    return (pill = {}) => {
      if (!this.pillClickHandlerCache[pill.slug]) {
        this.pillClickHandlerCache[pill.slug] = () =>
          this.debouncedOnPillClicked(pill);
      }

      return this.pillClickHandlerCache[pill.slug];
    };
  };

  optionPillObject = (props) => {
    const { option } = props;
    const onPillclicked = this.memoizedOnPillObjectClicked()(option);
    return (
      <DebouncedTouchableOpacity
        onPress={_.memoize(
          () => this.onPillClicked(option),
          () => [option],
        )}
        style={styles.optionPill}
      >
        {option?.image && (
          <FastImageView source={option.image} style={styles.optionImage} />
        )}
        <Text
          allowFontScaling={false}
          style={option?.image ? styles.optionTextWithImage : styles.optionText}
          numberOfLines={MAX_LINES.single}
        >
          {option.text}
        </Text>
      </DebouncedTouchableOpacity>
    );
  };

  render() {
    const {
      optionArray,
      color = colors.silver,
      backgroundColor = colors.background,
    } = this.props;

    return (
      <View style={styles.optionsContainer}>
        {optionArray.map((item, index) => {
          if (typeof item == 'object') {
            return (
              <this.optionPillObject
                option={item}
                color={color}
                key={`${index}_${item}`}
              />
            );
          }
          return (
            <this.optionPill
              option={item}
              color={color}
              key={`${index}_${item}`}
            />
          );
        })}
      </View>
    );
  }
}

export default Options;
