import { useEffect, useState } from 'react';
import { useRoute } from '@react-navigation/native';
import Config from '../../libraries/ReactNativeConfig';

import AppConfig from '../../config/AppConfig';
import { isBlank, isPresent } from '../../utils/BooleanUtility';
import VariantUtility, { findSkuIdByVariantId } from '../../utils/VariantUtility';

const useDefaultSelectedVariantForProduct = (data, routeSkuId = '') => {
  const [selectedVariant, setSelectedVariant] = useState({});
  const [currentSku, setCurrentSku] = useState(data?.sku_id);

  const [selectedMultiVariantPrimary, setSelectedMultiVariantPrimary] =
    useState({});
  const [selectedMultiVariantSecondary, setSelectedMultiVariantSecondary] =
    useState({});
  const [selectedMultiVariantTertiary, setSelectedMultiVariantTertiary] =
    useState({});

  const { params = {} } = useRoute();
  const { variant } = params;
  useEffect(() => {
    if (
      !AppConfig.getBooleanValue(
        Config.PRODUCT_PAGE_SHOW_DEFAULT_SELECTED_VARIANT,
      ) ||
      isPresent(selectedVariant)
    ) {
      return;
    }
    const {
      variants_details: { principal_sku_id = '' } = {},
      multi_level_variant_attributes = [],
    } = data;

    const preSelectedSkuId = routeSkuId || (variant && findSkuIdByVariantId(multi_level_variant_attributes, variant)) || principal_sku_id;

    if (
      VariantUtility.checkIfMultiVariant(
        data?.multi_level_variant_attributes,
      )
    ) {
      let primaryVariantToBeSet =
        VariantUtility.getParentVariantForChildVariantId(
          preSelectedSkuId,
          data?.multi_level_variant_attributes,
        );

      // in case empty first variant should be selected.
      if (isBlank(primaryVariantToBeSet)) {
        primaryVariantToBeSet = multi_level_variant_attributes[0];
      }

      const {
        multi_level_variant_attributes: primaryMultiVariantAttributes = [],
      } = primaryVariantToBeSet;
      let secondaryVariantToBeSet;
      if (isPresent(primaryMultiVariantAttributes[0].variant)) {
        secondaryVariantToBeSet = primaryMultiVariantAttributes?.filter(
          (secondaryVariant) =>
            secondaryVariant?.variant?.sku_id === preSelectedSkuId,
        )?.[0];
      } else {
        secondaryVariantToBeSet =
          VariantUtility.getParentVariantForChildVariantId(
            preSelectedSkuId,
            primaryMultiVariantAttributes,
          );
      }
      // in case empty first variant should be selected.
      if (isBlank(secondaryVariantToBeSet)) {
        secondaryVariantToBeSet = primaryMultiVariantAttributes[0];
      }

      setSelectedMultiVariantPrimary(primaryVariantToBeSet);
      setSelectedMultiVariantSecondary(secondaryVariantToBeSet);
      let selectedVariantData = secondaryVariantToBeSet?.variant || {};
      let selectedVariantSkuId = secondaryVariantToBeSet?.variant?.sku_id;

      const {
        multi_level_variant_attributes: secondaryMultiVariantAttributes = [],
      } = secondaryVariantToBeSet;

      if (
        isPresent(secondaryMultiVariantAttributes) &&
        secondaryMultiVariantAttributes.length > 0
      ) {
        let tertiaryVariantToBeSet = secondaryMultiVariantAttributes?.filter(
          (tertiaryVariant) =>
            tertiaryVariant?.variant?.sku_id === preSelectedSkuId,
        )?.[0];
        // in case empty first variant should be selected.
        if (isBlank(tertiaryVariantToBeSet)) {
          tertiaryVariantToBeSet = secondaryMultiVariantAttributes[0];
        }

        setSelectedMultiVariantTertiary(tertiaryVariantToBeSet);
        selectedVariantData = tertiaryVariantToBeSet?.variant || {};
        selectedVariantSkuId = tertiaryVariantToBeSet?.variant?.sku_id;
      }

      setSelectedVariant(selectedVariantData);
      setCurrentSku(selectedVariantSkuId);
    } else {
      const selectedVariantItem =
        multi_level_variant_attributes.filter(
          (e) => e?.variant?.sku_id === preSelectedSkuId,
        )?.[0] || multi_level_variant_attributes?.[0];
      const { variant = {}, sku_id = '' } = selectedVariantItem || {};
      if (isPresent(selectedVariantItem) && isBlank(selectedVariant)) {
        setCurrentSku(sku_id);
        setSelectedVariant(variant);
        setSelectedMultiVariantPrimary(selectedVariantItem);
      } else {
        setCurrentSku(data.sku_id);
      }
    }
  }, [
    selectedVariant,
    data?.multi_level_variant_attributes?.length,
    data?.sku_id,
  ]);

  return {
    selectedVariant,
    selectedMultiVariantPrimary,
    setSelectedVariant,
    setSelectedMultiVariantPrimary,
    selectedMultiVariantSecondary,
    setSelectedMultiVariantSecondary,
    selectedMultiVariantTertiary,
    setSelectedMultiVariantTertiary,
    currentSku,
    setCurrentSku,
  };
};

export default useDefaultSelectedVariantForProduct;
