import { StyleSheet } from 'react-native';
import colors from '../../../theme/Colors';
import { getScreenWidth } from '../../../utils/LayoutUtility';

const listingStyles = StyleSheet.create({
  flatlistContainer: {
    marginVertical: 8,
  },

  flatlistContainerWithBG: {
    marginVertical: 8,
    backgroundColor: colors.background,
  },
  margin: { height: 20 },
  container: {
    width: getScreenWidth(),
    alignSelf: 'center',
  },
});

export default listingStyles;
