import _ from 'lodash';
import { REMOTE_CONFIG_KEYS } from '../config/Constants';
import RemoteConfig from './RemoteConfig';
import Utility from './Utility';
import { getPriceText } from './NumberUtility';
import { isBlank, isPresent } from './BooleanUtility';
export default class OffersUtil {
  /**
   *
   * @param {*} genericOffer
   *  { offerId: {offerMetaInfo}}
   */
  static getHighPriorityGenericOffer = (genericOffer = {}) => {
    //convert all offers into a flat array ordered by promoted_display_order asc

    const isGreenGenericTextEnabled = RemoteConfig.getValue(
      REMOTE_CONFIG_KEYS.enable_generic_green_text,
    );

    if (!isGreenGenericTextEnabled) {
      return {};
    }

    const offers = _.orderBy(
      _.values(genericOffer),
      ['promoted_display_order'],
      ['asc'],
    );
    return offers[0] || {};
  };

  static hasGenericOfferHighPriority(skuOffer, genericOffer) {
    return (
      genericOffer.promoted_display_order <= skuOffer.promoted_display_order
    );
  }

  /**
   * This method will compare priority of generic offer and current boosted offer.
   * @return offer with higher priority
   */

  /**
   * No Generic - No Sku -> Nothing
   * No generic - Have Sku ->  Sku
   * Have Generic - No Sku -> Generic
   * Have Generic - Have Sku -> compare
   *
   */
  static getBoostedOffer = (skuId, boostedOffers) => {
    const { sku_offers = {}, generic_offers = {} } = boostedOffers;
    const genericOffer = this.getHighPriorityGenericOffer(generic_offers);
    const skuOffer = sku_offers[skuId];

    if (Utility.isBlank(genericOffer) && Utility.isBlank(skuOffer)) {
      return {};
    }
    if (Utility.isBlank(genericOffer) && Utility.isPresent(skuOffer)) {
      return skuOffer;
    }
    if (Utility.isPresent(genericOffer) && Utility.isBlank(skuOffer)) {
      return genericOffer;
    }
    if (Utility.isPresent(genericOffer) && Utility.isPresent(skuOffer)) {
      if (skuOffer.is_boosted && !genericOffer.is_boosted) {
        return skuOffer;
      }

      if (!skuOffer.is_boosted && genericOffer.is_boosted) {
        return genericOffer;
      }
      //Compare priority but sku having lower priority but boosted will be considered higher
      return this.hasGenericOfferHighPriority(skuOffer, genericOffer)
        ? genericOffer
        : skuOffer;
    }
  };

  //For Events params
  static isBoostedOffer = (skuId, boostedOffers) => {
    if (Utility.isBlank(skuId)) return false;

    const boostedOffer = this.getBoostedOffer(skuId, boostedOffers);
    return boostedOffer || {};
  };

  static isMemberOrEligible = (membership_cohort) => {
    return membership_cohort === 'member' || membership_cohort === 'eligible';
  };

  static getComboOfferPrompt = (
    currentQty,
    qtyRequired,
    maxQty,
    skuCapReached,
    sku_level_quantity_cap,
    offer_level_quantity_cap,
    showStrip,
    applied,
    hasMultipleLists,
  ) => {
    const comboBuilderPrompts = JSON.parse(
      RemoteConfig.getValue(REMOTE_CONFIG_KEYS.combo_builder_prompts),
    );

    if (
      currentQty > offer_level_quantity_cap &&
      Utility.isPresent(offer_level_quantity_cap)
    ) {
      return comboBuilderPrompts['offer_cap_reached'];
    }
    if (skuCapReached) {
      return comboBuilderPrompts['sku_cap_reached'];
    }
    if (currentQty == 0) {
      return comboBuilderPrompts['combo_not_started'];
    }

    if (qtyRequired - currentQty > 0) {
      return comboBuilderPrompts['incomplete_combo'];
    }

    if (showStrip && hasMultipleLists > 1) {
      return comboBuilderPrompts['all_products_added_from_a_list'];
    }

    if (showStrip) {
      return comboBuilderPrompts['extendable_combo'];
    }

    if (applied) {
      return comboBuilderPrompts['combo_completed'];
    }

    if (parseInt(currentQty) >= parseInt(qtyRequired) && hasMultipleLists > 1) {
      return comboBuilderPrompts['all_products_added_from_a_list'];
    }

    if (parseInt(currentQty) >= parseInt(qtyRequired)) {
      return comboBuilderPrompts['combo_required_quantity_fulfiled'];
    }

    return comboBuilderPrompts['default'];
  };
}

export const getProductOfferMessage = (
  productData = {},
  todayDeals = {},
  boostedOffers = {},
  selectedOfferId,
) => {
  const {
    variants_details: { principal_sku_id = '' } = {},
    sku_id = '',
    sku: { group_buying_price = '' } = {},
    promoted_offer_text,
    promoted_offer_discount = '',
    discount: defaultDiscount = '',
    is_promoted_offer_clubbable = false,
    promoted_offer_id,
    name,
  } = productData;
  const {
    skus = {},
    group_deal_skus = {},
    membership_cohort,
    edge_deal_skus = {},
  } = todayDeals;

  const skuToCheck = principal_sku_id || sku_id;

  const groupDealPrice =
    group_deal_skus[skuToCheck]?.group_buying_price || group_buying_price;

  const boostedOrGenericOffers = OffersUtil.getBoostedOffer(
    skuToCheck,
    boostedOffers,
  );
  let message = '';
  let discountToShow = undefined;
  let flashDealDiscount = undefined;
  let ignoreDiscountOffset = false;
  const eligibleCandidatesIdentifier = [];

  if (isPresent(skus[`${skuToCheck}`])) {
    const espToShow =
      membership_cohort === 'member' &&
      isPresent(skus[`${skuToCheck}`]?.member_esp)
        ? skus[`${skuToCheck}`]?.member_esp
        : skus[`${skuToCheck}`]?.esp;
    discountToShow =
      membership_cohort === 'member' &&
      isPresent(skus[`${skuToCheck}`]?.member_esp)
        ? skus[`${skuToCheck}`]?.member_discount_text
        : skus[`${skuToCheck}`]?.discount_text;
    discountToShow = discountToShow?.toString()?.includes('off')
      ? discountToShow
      : `${discountToShow}% off`;
    message = `${getPriceText(espToShow)} (${discountToShow}) for you`;
    flashDealDiscount =
      membership_cohort === 'member' &&
      isPresent(skus[`${skuToCheck}`]?.member_esp)
        ? skus[`${skuToCheck}`]?.member_discount
        : skus[`${skuToCheck}`]?.discount;
    message = `${getPriceText(espToShow)} (${discountToShow}) for you`;
    eligibleCandidatesIdentifier.push({
      message: message,
      discount: flashDealDiscount,
      ignore_discount_offset: false,
      identifier: 'flash_deal',
    });
  }

  if (isPresent(edge_deal_skus[`${skuToCheck}`])) {
    discountToShow = edge_deal_skus[`${skuToCheck}`]?.member_discount_text;
    discountToShow = discountToShow?.toString()?.includes('off')
      ? discountToShow
      : `${discountToShow}% off`;

    message = `${getPriceText(
      edge_deal_skus[`${skuToCheck}`]?.member_esp,
    )} (${discountToShow}) for you`;

    eligibleCandidatesIdentifier.push({
      message: message,
      discount: edge_deal_skus[`${skuToCheck}`]?.member_discount,
      ignore_discount_offset: false,
      identifier: 'flash_deal_activation',
    });
  }

  if (isPresent(boostedOrGenericOffers)) {
    const { ignore_discount_offset, discount_percentage } =
      boostedOrGenericOffers;
    ignoreDiscountOffset = ignore_discount_offset;
    message = boostedOrGenericOffers?.promoted_offer_text;
    eligibleCandidatesIdentifier.push({
      message: message,
      discount: discount_percentage,
      ignore_discount_offset: ignoreDiscountOffset,
      identifier: 'boosted_offer',
    });
  }

  if (isPresent(groupDealPrice)) {
    message = `Group deal for ${getPriceText(groupDealPrice)}`;
    eligibleCandidatesIdentifier.push({
      message: message,
      discount: group_deal_skus?.[skuToCheck]?.discount_percentage,
      ignore_discount_offset: false,
      identifier: 'group_deal',
    });
  }

  if (isPresent(promoted_offer_text)) {
    message = promoted_offer_text;
    eligibleCandidatesIdentifier.push({
      message: message,
      discount: promoted_offer_discount,
      ignore_discount_offset:
        isBlank(promoted_offer_discount) || is_promoted_offer_clubbable,
      identifier: 'promoted_offer_text',
    });
  }

  const discountOffset = Utility.jsonParser(
    RemoteConfig.getValue(REMOTE_CONFIG_KEYS.discount_offset),
    'discountOffset',
  );

  const eligibleCandidatesIdentifierNew = [];
  eligibleCandidatesIdentifier.forEach((eligibleCandidate) => {
    if (
      isPresent(eligibleCandidate.discount) ||
      eligibleCandidate.ignore_discount_offset
    ) {
      const currentDiscount = eligibleCandidate?.discount;

      let isDiscountEligible =
        parseInt(currentDiscount) >=
        parseInt(defaultDiscount?.match(/\d+/)?.[0] || 0) +
          parseInt(discountOffset);

      if (eligibleCandidate.ignore_discount_offset) {
        isDiscountEligible = true;
      }

      if (isDiscountEligible) {
        eligibleCandidatesIdentifierNew.push(eligibleCandidate);
      }
    }
  });

  return eligibleCandidatesIdentifierNew[0]?.message || '';
};
