const MIPS_CUSTOM_INJECTED_JAVASCRIPT = `const element = document.getElementById('rev-1');
const isCardDetailsPage = document.getElementById('frm_mips_Card');
if (element && !isCardDetailsPage) {
  const textContent = element.textContent || element.innerText;
  if (textContent !== "Payment successful" && textContent !== "Payment Successful") {
    window.ReactNativeWebView.postMessage(JSON.stringify({ success: false }));
  }
}`;

export default MIPS_CUSTOM_INJECTED_JAVASCRIPT;
