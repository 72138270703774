// Dependencies
import React, { Component } from 'react';
import { View, FlatList } from 'react-native';
import PropTypes from 'prop-types';
import { capitalize, debounce, max, upperFirst } from 'lodash';
import { FlatListPerformanceView } from '../../../libraries/ReactNativePerformanceShopify';

import { LAYOUT, THEME } from '../../../config/Constants';
import Utility from '../../../utils/Utility';
// Components
import Media from '../../../containers/media/Media';
import Salon from '../../../containers/salon/Salon';
import Collection from '../../../containers/collection/Collection';
import listingStyles from './styles';
import LAYOUT_CONFIG from '../../../config/LayoutConstants/LayoutConfig';
import { SCREEN_CONSTANTS } from '../../../config/ScreenConstants';
import SearchOffer from '../../../containers/offer/SearchOffer';
import { DefaultSizeProductList } from '../../Product';
import { DefaultSizeVariantList } from '../../variants';
import DefaultSizeBrandList from '../../brand/DefaultSizeBrandList';
import {
  SmallProductList,
  LargeProductList,
} from '../../Product/OtherProducts';
import { SmallBrandList, LargeBrandList } from '../../brand/OtherBrands';
import { SmallVariantList, LargeVariantList } from '../../variants/OtherSkus';
import { DefaultSizeVideoList } from '../../media';
import { SmallVideoList, LargeVideoList } from '../../media/OtherMediaCards';
import { DefaultSizeArtistList } from '../../Artist';
import {
  SmallArtistList,
  LargeArtistList,
} from '../../Artist/OtherArtistCards';
import Ingredient from '../../ingredient/Ingredient';
import DefaultSizeCategoryList from '../../category/DefaultSizeCategoryList';
import RoutineDescriptionThinCard from '../../Routines/RoutineDescriptionThinCard';
import FeatureCard from '../../feature/FeatureCard';
import BigBanner from '../../feature/BigBanner';
import { DefaultSizeOfferPromptFancyRail } from '../offerPrompt';
import { styles } from '../../../containers/offer/MyWallet';
import { getListSource } from '../../../utils/PerfUtility';
import Alert from '../../Alert/Alert';

class Listing extends Component {
  static Components = {
    media: Media,
    salon: Salon,
    list: Collection,
    video: Media,
    DefaultSizeOfferList: SearchOffer,
    DefaultSizeProductList,
    DefaultSizeVariantList,
    LargeProductList,
    SmallProductList,
    LargeVariantList,
    SmallVariantList,
    DefaultSizeVideoList,
    SmallVideoList,
    LargeVideoList,
    DefaultSizeArtistList,
    SmallArtistList,
    LargeArtistList,
    DefaultSizeBrandList,
    SmallBrandList,
    LargeBrandList,
    ingredient: Ingredient,
    product_category: DefaultSizeCategoryList,
    routine: RoutineDescriptionThinCard,
    feature: FeatureCard,
    LargeOfferList: BigBanner,
    offer_prompt: DefaultSizeOfferPromptFancyRail,
    alert: Alert,
  };

  constructor() {
    super();
    this.viewedIds = [];
    this.onFlatListEndReached = debounce(this.onEndReached, 500);
  }

  getComponent = (item, index) => {
    if (Utility.isBlank(item)) {
      return null;
    }

    const {
      id,
      size,
      content,
      navigation,
      theme,
      onPress,
      onboarding,
      onCardClick,
      previousScreen,
      search,
      searchQuery,
      elementItemCounts,
      toggleCartVisibility,
      listName,
      listData,
      maxFreeItemsToSelect,
      hideAddToCart,
      campaignId,
      onItemAddToCartFromCollab,
      fromPersonalisedCard,
      showToast,
      listContent,
      extraEventParameters = {},
    } = this.props;

    const listIndex = this.props.index;

    let ContainerComponent = Listing.Components[content];
    if (previousScreen === 'paginatedList') {
      if (item.type === 'product') {
        ContainerComponent = Listing.Components.DefaultSizeProductList;
      } else if (item.type === 'variant') {
        ContainerComponent = Listing.Components.DefaultSizeVariantList;
      } else if (item.type === 'video') {
        ContainerComponent = Listing.Components.DefaultSizeVideoList;
      } else if (item.type === 'artist') {
        ContainerComponent = Listing.Components.DefaultSizeArtistList;
      } else {
        ContainerComponent = Listing.Components[item.type];
      }
    }
    if (
      content === 'product' ||
      content === 'sku' ||
      content === 'media' ||
      content === 'artist' ||
      content === 'offer' ||
      content === 'brand'
    ) {
      ContainerComponent =
        Listing.Components[`${upperFirst(size)}${capitalize(item.type)}List`];
    }

    if (item === undefined || ContainerComponent === undefined) {
      return null;
    }

    return (
      <ContainerComponent
        listName={listName}
        listContent={listContent}
        size={size}
        layout={fromPersonalisedCard ? LAYOUT.PERSONALISEDRAIL : LAYOUT.LIST}
        id={item.id}
        itemData={item}
        type={item.type}
        navigation={navigation}
        theme={theme}
        listId={id}
        index={index}
        onPress={onPress}
        iconName={this.props.iconName}
        showCustomIcon={this.props.showCustomIcon}
        showColorComponent={this.props.showColorComponent}
        addedProducts={this.props.addedProducts}
        onboarding={onboarding}
        onCardClick={onCardClick}
        previousScreen={previousScreen}
        listIndex={listIndex}
        search={search}
        searchQuery={searchQuery}
        elementItemCounts={elementItemCounts}
        toggleCartVisibility={toggleCartVisibility}
        // addedProducts={this.props.addedProducts}
        // isVisible={this.props.isVisible}
        listData={listData}
        refreshOfferStrip={this.props.refreshOfferStrip}
        maxFreeItemsToSelect={maxFreeItemsToSelect}
        hideAddToCart={hideAddToCart}
        campaignId={campaignId}
        onItemAddToCartFromCollab={onItemAddToCartFromCollab}
        fromPersonalisedCard={fromPersonalisedCard ? true : false}
        showToast={showToast}
        key={item?.id || index}
        extraEventParameters={extraEventParameters}
        item={item}
        inheritStyles={styles}
        showSubHeading
      />
    );
  };

  onEndReached = () => {
    const { endReached = () => {} } = this.props;
    endReached();
  };

  keyExtractor = (item, index) => `${item.schema}_${item.id}_${index}list`;

  renderItem = ({ item, index }) => this.getComponent(item, index);

  render() {
    const { feed = false, previousScreen } = this.props;

    const itemData = this.props.item.filter((item) => !!item);
    this.displayCount = feed
      ? max([LAYOUT_CONFIG.minListCount, this.props.displayCount || 0])
      : this.props.displayCount || 0;
    if (Utility.isBlank(itemData)) {
      return null;
    }
    const data =
      previousScreen === SCREEN_CONSTANTS.MORE_PAGE
        ? itemData
        : itemData.slice(0, this.displayCount);
    return (
      <FlatListPerformanceView
        listName={`${getListSource(this, this.props)}`}
      >
        <FlatList
          data={data}
          renderItem={this.renderItem}
          keyExtractor={this.keyExtractor}
          initialNumToRender={20}
          contentContainerStyle={listingStyles.flatlistContainer}
          onEndReached={this.onFlatListEndReached}
          onEndReachedThreshold={0.2}
        />
      </FlatListPerformanceView>
    );
  }
}

Listing.propTypes = {
  list: PropTypes.shape({
    objects: PropTypes.array,
    size: PropTypes.string,
  }),
  item: PropTypes.array,
  theme: PropTypes.string,
};

Listing.defaultProps = {
  theme: THEME.LIGHT,
};

export default Listing;
