import React, { PureComponent } from 'react';
import { View, StyleSheet, ScrollView, StatusBar, Linking } from 'react-native';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import colors from '../../theme/Colors';
import SelectGenderCard from './SelectGenderCard';

import SelfieAnalysis from './SelfieAnalysis';

import {
  retrySelfieAnalysis,
  setPhoneNumberModalInfo,
} from '../../actions/LoginActions';
import withNavigation from '../../utils/WithNavigation';
import { getLoyaltyPlanPromptDetails } from '../../actions/FoxyEdgeAction';
import Utility from '../../utils/Utility';
import {
  SELFIE_STATE,
  PERMISSION_STORE,
  APP_DOWNLOAD_LINK,
  FOXY_URLS,
  AnalyticsScreenNames,
} from '../../config/Constants';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import {
  AnalyticsManager,
  EventType,
  EventParameterKey,
  EventParameterValue,
} from '../../analytics';
import ContactUsCard from '../orders/ContactUsCard';

import NavigationService from '../../navigator/NavigationService';
import { List } from '../../containers/List';
import { isWeb, isWebAndroid, isWebIOS, isPresent } from '../../utils/BooleanUtility';
import { PerformanceMeasureView } from '@shopify/react-native-performance';

class LoggedOutUserHomePage extends PureComponent {
  constructor() {
    super();
    this.state = {
      showGenderCard: true,
      currentState: 'default',
      progressFill: 0,
      selfieImageUri: '',
      hideButton: false,
      buttonTitle: 'Take Selfie',
      showFooterRetakeButton: false,
      genderCardTitle: 'Take a sneak peek without selfie',
      genderCardDesc: 'Select your gender to preview this page',
      messageTitle: '',
      messageDescription:
        'Get a personalized feed of content and products with advanced facial analytics',
      loyaltyPlansPromptData: {},
    };
    this.currentPermissionState = '';
  }

  componentDidMount = () => {
    const {
      profile: { local_image_uri = '' },
      getLoyaltyPlanPromptDetails,
    } = this.props;
    this.setState({
      selfieImageUri: local_image_uri,
    });
    getLoyaltyPlanPromptDetails(
      'home-page-loyalty-plan-prompt',
      (success, response) => {
        if (!success) return;
        this.setState({
          loyaltyPlansPromptData: response,
        });
      },
    );
    // Utility.setStatusBarWhite();
  };

  componentDidUpdate = () => {
    const {
      profile: { local_image_uri = '' },
      authToken,
      facialAnalysis: {
        selfie_upload_failure,
        analysis_stage,
        analysis_progress,
      },
    } = this.props;

    const { selfieImageUri } = this.state;
    //This check means user has changed his selfie via retake selfie
    if (
      // Utility.isPresent(selfieImageUri) &&
      selfieImageUri !== local_image_uri
    ) {
      this.setState({
        selfieImageUri: local_image_uri,
      });
    }
    if (
      Utility.isPresent(analysis_stage) &&
      analysis_stage !== SELFIE_STATE.COMPLETED &&
      analysis_stage !== SELFIE_STATE.ERROR
    ) {
      this.setState({
        genderCardTitle: 'Not happy with your selfie ?',
        genderCardDesc: '',
        showFooterRetakeButton: true,
        showGenderCard: true,
        currentState: 'analysing',
        progressFill: analysis_progress,
        hideButton: true,
        messageTitle: '',
        messageDescription:
          'Selfie is being analysed..Personalised feed coming up shortly.',
      });
    } else if (
      Utility.isPresent(analysis_stage) &&
      analysis_stage === SELFIE_STATE.ERROR &&
      selfie_upload_failure
    ) {
      this.setState({
        genderCardTitle: 'Take a sneak peek without selfie',
        genderCardDesc: 'Select your gender to preview this page',
        showFooterRetakeButton: false,
        currentState: 'upload_error',
        showGenderCard: true,
        hideButton: false,
        buttonTitle: 'Retry Upload',
        messageTitle: 'Your selfie was not uploaded',
        messageDescription:
          'Please make sure you are on a stable network connection and try again.',
      });
    } else if (
      Utility.isPresent(analysis_stage) &&
      analysis_stage === SELFIE_STATE.ERROR
    ) {
      this.setState({
        genderCardTitle: 'Take a sneak peek without selfie',
        genderCardDesc: 'Select your gender to preview this page',
        showFooterRetakeButton: false,
        currentState: 'analysis_error',
        hideButton: false,
        showGenderCard: true,
        buttonTitle: 'Retake Selfie',
        messageTitle: 'We were unable to analyze your selfie.',
        messageDescription:
          'Please make sure your face is well lit and clearly visible in the picture.',
      });
    }
  };

  componentWillUnmount() {
    const { firePageLoadStart } = this.props;
    firePageLoadStart();
  }

  onButtonPress = () => {
    const { currentState } = this.state;
    AnalyticsManager.logEvent(EventType.discoveryEvents.LIST_ITEM_CLICK, {
      [EventParameterKey.SCREEN_NAME]: SCREEN_CONSTANTS.NON_PERSONALISED_FEED,
      [EventParameterKey.ENTITY_NAME]: SCREEN_CONSTANTS.NON_PERSONALISED_FEED,
      [EventParameterKey.ITEM_TYPE]: EventParameterValue.CARDS.TAKE_SELFIE,
      [EventParameterKey.ITEM_NAME]: EventParameterValue.CARDS.TAKE_SELFIE,
      [EventParameterKey.ITEM_POSITION]: -1,
    });

    if (isWeb()) {
      AnalyticsManager.logEvent(
        EventType.MISC_WEB_EVENTS.GET_APP_CLICK,
        { location: location.pathname },
      );
      if (isWebIOS() || isWebAndroid()) {
        Linking.openURL(FOXY_URLS.dynamicAppLink);
      } else {
        Linking.openURL(APP_DOWNLOAD_LINK);
      }
    }

    if (currentState === 'default') {
      // this.renderOnboarding();
      this.retakeSelfie();
    } else if (currentState === 'upload_error') {
      this.retryImageUpload();
    } else if (currentState === 'analysis_error') {
      this.retakeSelfie();
    } else if (currentState === 'login') {
      this.renderOnboarding();
    }
  };

  renderOnboarding = () => {
    const { renderOnboarding, authToken, setPhoneNumberModalInfo } = this.props;
    if (Utility.isPresent(authToken)) {
      this.retakeSelfie();
      return;
    }

    AnalyticsManager.logEvent(EventType.onboardingEvent.LOGIN_INITIATE, {
      [EventParameterKey.SOURCE]:
        EventParameterValue.SOURCE.NON_PERSONALISED_HOMEPAGE,
    });
    setPhoneNumberModalInfo(Utility.getLoginModalInfo('DEFAULT'));
    NavigationService.renderOnboarding();
    Utility.invokeSelfieFlowAfterLoginForGuest();
  };

  retryImageUpload = () => {
    const { retrySelfieAnalysis } = this.props;
    AnalyticsManager.logEvent(EventType.onboardingEvent.SELFIE_UPLOAD_RETRY, {
      [EventParameterKey.SOURCE]: 'non_personalized_homepage',
    });
    retrySelfieAnalysis();
  };

  retakeSelfie = (isFromRetakeChips = false) => {
    const { navigation } = this.props;
    const retakeSelfieSource = isFromRetakeChips
      ? EventParameterValue.SOURCE.CHIP_BUTTON
      : EventParameterValue.SOURCE.NON_PERSONALISED_HOMEPAGE;
    AnalyticsManager.logEvent(EventType.onboardingEvent.SELFIE_RETAKE, {
      [EventParameterKey.SOURCE]: retakeSelfieSource,
    });

    //Check Permission before Navigating to take selfie screen

    Utility.checkPermissionStatic(
      PERMISSION_STORE.camera,
      true,
      (permissionStatus) => {
        if (
          permissionStatus === 'granted' ||
          permissionStatus === 'authorized'
        ) {
          navigation.navigate('TakeSelfie', {
            previousScreen: SCREEN_CONSTANTS.NON_PERSONALISED_FEED,
          });
          this.currentPermissionState = '';
        } else if (
          permissionStatus === 'never_ask_again' ||
          permissionStatus === 'denied'
        ) {
          if (Utility.isBlank(this.currentPermissionState)) {
            this.currentPermissionState = permissionStatus;
          } else {
            Linking.openSettings();
          }
        }
      },
    );
  };

  render() {
    const {
      currentState,
      progressFill,
      selfieImageUri,
      buttonTitle,
      hideButton,
      messageTitle,
      messageDescription,
      genderCardDesc,
      genderCardTitle,
      showFooterRetakeButton,
      showGenderCard,
      loyaltyPlansPromptData = {},
    } = this.state;

    const { authToken, navigation, onSalonStartNowPress } = this.props;
    const { scrollViewRef } = this.props;
    let buttonText = buttonTitle;
    let currentGenderCardState = currentState;
    if (currentState === 'default') {
      if (Utility.isPresent(authToken)) {
        buttonText = 'Take Smart Selfie';
        currentGenderCardState = 'logged_in_user';
      }
    }

    return (
      <PerformanceMeasureView
        screenName={AnalyticsScreenNames.NON_LOGGED_IN_GUEST_HOME_PAGE}
        interactive={isPresent(loyaltyPlansPromptData)}
        style={styles.container}
      >
        <StatusBar
          backgroundColor="white" // white for android
          barStyle={'dark-content'}
          translucent={false}
        />
        <ScrollView ref={scrollViewRef}>
          <View style={styles.container}>
            <SelfieAnalysis
              selfieImageUrl={selfieImageUri}
              currentState={currentState}
              messageTitle={messageTitle}
              messageDescription={messageDescription}
              buttonTitle={isWeb() ? 'Download App' : buttonText}
              onButtonPress={this.onButtonPress}
              progressBarFill={progressFill}
              hideButton={hideButton}
              onRetakePress={() => this.retakeSelfie(true)}
            />
            <View style={styles.SelectGenderCardStyle}>
              <SelectGenderCard
                showGenderCard={showGenderCard}
                showFooterRetakeButton={showFooterRetakeButton}
                headerText={genderCardTitle}
                descriptionText={genderCardDesc}
                onPressRetakeButton={this.retakeSelfie}
                currentState={currentGenderCardState}
              />
            </View>
            <List
              itemData={loyaltyPlansPromptData}
              id={loyaltyPlansPromptData?.id}
              previousScreen={SCREEN_CONSTANTS.NON_PERSONALISED_FEED}
              navigation={navigation}
              useInViewPort={true}
              showWebFooter
            />
            {(currentState === 'upload_error' ||
              currentState === 'analysis_error') && (
              <ContactUsCard
                //FIXME: Bad way of doing this, because screen's width is not properly adjusted
                wrapperStyle={{
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginTop: 5,
                  marginBottom: 8,
                }}
                title="Facing issues with selfie analysis?"
                description="Click here to get connected with support"
                onRowClick={() => Utility.openWhatsApp('')}
              />
            )}
          </View>

          <View style={{ height: 50 }} />
        </ScrollView>
      </PerformanceMeasureView>
    );
  }
}

const mapStateToProps = function (state) {
  return {
    facialAnalysis: state.UserAccountInfo.facialAnalysis,
    profile: state.UserAccountInfo.profile,
    authToken: state.UserAccountInfo.authToken,
  };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      retrySelfieAnalysis,
      setPhoneNumberModalInfo,
      getLoyaltyPlanPromptDetails,
    },
    dispatch,
  ),
});

export default withNavigation(
  connect(mapStateToProps, mapDispatchToProps)(LoggedOutUserHomePage),
);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.background,
  },
  SelectGenderCardStyle: {
    marginTop: 8,
    alignSelf: 'center',
  },
});
