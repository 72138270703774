import { View, Text } from 'react-native';
import React from 'react';
import { ProductDetailStyles } from './styles';
import { isInStock } from '../../utils/ProductUtility';

export default function ProductStockedStatus({
  stocked_status: productStockedStatus,
  gwp,
}) {
  let stockedStatus = '';

  if (isInStock(productStockedStatus)) {
    return null;
  }

  if (productStockedStatus === 'unstocked') {
    stockedStatus = 'This item is not in stock';
  }

  if (productStockedStatus === 'discontinued') {
    stockedStatus = 'This item is discontinued by brand';
  }

  if (productStockedStatus === 'stocked_out') {
    stockedStatus = 'Will be back in stock soon';
  }

  if (gwp) {
    stockedStatus = 'This item is not for sale';
  }

  return (
    <View style={ProductDetailStyles.stockedStatusContainer}>
      <Text style={ProductDetailStyles.stockedStatusText}>{stockedStatus}</Text>
    </View>
  );
}
