import { View, StyleSheet, Linking } from 'react-native';
import React, { useCallback } from 'react';
import Config from '../../../libraries/ReactNativeConfig';
import { useNavigation } from '@react-navigation/native';
import ShopifyOrderDetailsCard from './ShopifyOrderDetailsCard';
import SmallActionCard from '../../layout/cards/SmallActionCard';
import LargeActionCard from '../../layout/cards/LargeActionCard';
import { WHATS_APP_NUMBER } from '../../../config/Constants';
import AppConfig from '../../../config/AppConfig';
import Utility from '../../../utils/Utility';
import colors from '../../../theme/Colors';
import CancelShopifyOrder from './CancelShopifyOrder';
import OrderAddress from '../../payment/paymentStatus/OrderAddress';
import CancellationAndRefundPolicies from './CancellationAndRefundPolicies';
import { isBlank } from '../../../utils/BooleanUtility';

const ContainerComponent = {
  shopify_order_details_card: ShopifyOrderDetailsCard,
  omuni_order_details_card: ShopifyOrderDetailsCard,
  return_items: SmallActionCard,
  small_action_card: SmallActionCard,
  large_action_card: LargeActionCard,
  cancel_shipment: SmallActionCard,
  shipping_details: OrderAddress,
  cancellation_and_refund_policies: CancellationAndRefundPolicies,
};

export default function RenderActionCard({
  item = {},
  orderName,
  status,
  createdAt,
  orderEmail,
  shipmentId,
  onContactUsOptionPress,
  address,
  orderItems,
  totalAmount,
  returnReasons,
  returnRequests,
  refundCoupons,
  returnProcessingFee,
  returnPolicyUrl,
  shopifyOrderId,
  ekanekOrderId,
  cancellationReasons = []
}) {
  const navigation = useNavigation();
  const message = `Hey! I need help with this order - ${orderName}`;

  const chatWithUs = useCallback(onContactUsOptionPress);

  const {
    displayType,
    primaryButtonAction,
    secondaryButtonAction,
    primaryButtonText,
    secondaryButtonText,
  } = item;
  const firstActionButtonText =
    primaryButtonAction === 'chat_with_us' ? 'Contact Us' : primaryButtonText;
  const LayoutComponent = ContainerComponent[`${displayType}`];

  const invokeReturnPrimeFlow = () => {
    const orderNumber = orderName.replace('#', '');
    const shopifyStore = Config.SHOPIFY_STORE_ACCOUNT;
    const channelId = Config.RETURN_PRIME_CHANNEL_ID;
    const returnPrimeUrl = `${URL.RETURN_PRIME_URL}?order_number=${orderNumber}&email=${orderEmail}&store=${shopifyStore}&channel_id=${channelId}`;
    navigation.navigate('WebUrlView', {
      disableHttpErrorReturn: true,
      newDestination: returnPrimeUrl,
    });
  };

  const returnAndExchangeFlow = () => {
    const flow = Config.RETURN_EXCHANGE_SUPPORTED_FLOW;
    switch (flow) {
      case 'return_prime':
        invokeReturnPrimeFlow();
        break;
      default:
        break;
    }
  };

  const onPrimaryButtonAction = () => {
    switch (primaryButtonAction) {
      case 'chat_with_us': {
        chatWithUs();
        break;
      }
      default: {
        break;
      }
    }
  };

  const onSecondaryButtonAction = () => {
    if (secondaryButtonAction === 'return_and_exchange') {
      returnAndExchangeFlow();
      return;
    }
    if (
      secondaryButtonAction?.toLowerCase() === 'return' ||
      displayType === 'return_items' ||
      displayType === 'cancel_shipment'
    ) {
      navigation.navigate('ReturnOrCancelOrderDetails', {
        orderItems,
        address,
        orderName,
        totalAmount,
        returnReasons,
        returnProcessingFee,
        returnPolicyUrl,
        shopifyOrderId,
        ekanekOrderId,
        displayType,
        cancellationReasons,
        shipmentId,
      });
      return;
    }
    chatWithUs();
  };

  if (
    !LayoutComponent ||
    ((secondaryButtonAction?.toLowerCase() === 'return' ||
      displayType === 'return_items') &&
      isBlank(ekanekOrderId))
  ) {
    return null;
  }
  return (
    <View style={styles[`${displayType}`]}>
      <LayoutComponent
        {...item}
        item={item}
        orderName={orderName}
        status={status}
        createdAt={createdAt}
        secondaryActionButtonText={secondaryButtonText}
        firstActionButtonText={firstActionButtonText}
        primaryButtonAction={onPrimaryButtonAction}
        secondaryButtonAction={onSecondaryButtonAction}
        shipmentId={shipmentId}
        orderAddress={address}
        orderItems={orderItems}
        returnRequests={returnRequests}
        refundCoupons={refundCoupons}
        style={{ marginTop: 12 }}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  small_action_card: {
    margin: 12,
    padding: 0,
    backgroundColor: colors.background,
  },
  large_action_card: {
    margin: 0,
    padding: 6,
    backgroundColor: colors.white,
  },
});
