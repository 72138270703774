import React, { Component } from 'react';
import { Text, View } from 'react-native';
import StyleSheet from 'react-native-media-query';
import Config from '../../libraries/ReactNativeConfig';
import { ScrollView } from 'react-native-gesture-handler';
import { connect } from 'react-redux';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import { List } from '../../containers/List';
import { CartFoxyPromise } from '../../containers/cart';
import CountDown from '../../helpers/Counter';
import colors from '../../theme/Colors';
import images from '../../theme/Images';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import AppConfig from '../../config/AppConfig';
import FastImageView from '../FastImageView';
import { getScreenWidth } from '../../utils/LayoutUtility';
import { isNative } from '../../utils/BooleanUtility';
import { getMinifiedImage } from '../../utils/ImageUtility';

class TodayDeals extends Component {
  onWillFocus = () => {
    Utility.setStatusbarTranslucent();
  };

  render() {
    const {
      todayDeals,
      todayDealss: { ends_at },
      refresh = () => {},
      selfieImage,
    } = this.props;
    let imageComponentSource = images.for_you_placeholder;
    let imageStyle = styles.selfie;
    if (Utility.isPresent(selfieImage)) {
      imageComponentSource = {
        uri: getMinifiedImage(
          selfieImage,
          imageStyle.width,
          imageStyle.height,
        ),
      };
    }
    if (Utility.isPresent(Config.WISHLIST_HEADER_IMAGE)) {
      imageComponentSource = {
        uri: getMinifiedImage(
          Config.WISHLIST_HEADER_IMAGE,
          imageStyle.width,
          imageStyle.height,
        ),
      };
      imageStyle = styles.selfieWithTransparentBg;
    }
    return (
      <ScrollView style={styles.outerContainer}>
        <View style={styles.innerContainer} dataSet={{ media: ids.innerContainer }}>
          <FastImageView source={imageComponentSource.uri} style={imageStyle} />
          <View style={styles.level_one_circle} />
          <View style={styles.level_two_circle} />
          <View style={styles.level_three_circle} />
          <View style={styles.level_four_circle} />
          <View style={styles.container}>
            <Text style={styles.flashDealsTitle}>
              {(Config.WISHLIST_TITLE || '').toUpperCase() ||
                'YOUR WISHLIST DEALS'}
            </Text>
            {!AppConfig.getBooleanValue(Config.HIDE_WISHLIST_COUNTDOWN) &&
              Utility.isPresent(ends_at) &&
              Utility.calculateTimeDifferenceForOffer(ends_at) > 0 && (
                <CountDown
                  size={16}
                  until={Utility.calculateTimeDifferenceForOffer(ends_at)}
                  endsAt={ends_at}
                  onFinish={refresh}
                  digitStyle={styles.digitStyle}
                  digitTxtStyle={styles.digitTxtStyle}
                  timeLabelStyle={styles.timeLabelStyle}
                  separatorStyle={styles.separatorStyle}
                  timeToShow={['H', 'M', 'S']}
                  timeLabels={{ h: 'hrs', m: 'min', s: 'sec' }}
                  showSeparator
                />
              )}
          </View>
        </View>
        <List
          itemData={todayDeals}
          navigation={this.props.navigation}
          previousScreen={SCREEN_CONSTANTS.WISH_DEALS}
        />

        {isNative() && (
          <View style={styles.foxyPromise} dataSet={{ media: ids.foxyPromise }}>
            <CartFoxyPromise showToast={this.showToast} />
          </View>
        )}
      </ScrollView>
    );
  }
}

const mapStateToProps = function (state) {
  return {
    selfieImage: state.UserAccountInfo.profile.selfie_image_url,
    todayDealss: state.todayDeals,
  };
};

const { ids, styles } = StyleSheet.create({
  footer: {
    height: Utility.getScreenWidth() / 2,
    width: Utility.getScreenWidth(),
    marginVertical: 41,
  },
  selfie: {
    width: 0.3 * Utility.getScreenWidth(),
    height: 0.3 * Utility.getScreenWidth(),
    backgroundColor: colors.foxyBlack,
    borderRadius: (0.4 * Utility.getScreenWidth()) / 2,
    alignSelf: 'center',
    position: 'absolute',
    bottom: 120,
    zIndex: 1,
    overflow: 'hidden',
  },
  selfieWithTransparentBg: {
    width: 0.3 * Utility.getScreenWidth(),
    height: 0.3 * Utility.getScreenWidth(),
    backgroundColor: colors.transparent,
    alignSelf: 'center',
    position: 'absolute',
    bottom: 120,
    zIndex: 1,
  },
  level_one_circle: {
    width: 0.75 * getScreenWidth(),
    height: 0.75 * getScreenWidth(),
    backgroundColor: colors.foxyHeader,
    borderRadius: (0.75 * getScreenWidth()) / 2,
    alignSelf: 'center',
    position: 'absolute',
    bottom: 100,
    opacity: 1,
  },
  level_two_circle: {
    width: 1.1 * Utility.getScreenWidth(),
    height: 1.1 * Utility.getScreenWidth(),
    backgroundColor: colors.foxyHeader,
    borderRadius: (1.1 * Utility.getScreenWidth()) / 2,
    alignSelf: 'center',
    position: 'absolute',
    opacity: 0.6,
    bottom: 80,
  },
  level_three_circle: {
    width: 1.5 * Utility.getScreenWidth(),
    height: 1.5 * Utility.getScreenWidth(),
    backgroundColor: colors.foxyHeader,
    borderRadius: (1.5 * Utility.getScreenWidth()) / 2,
    alignSelf: 'center',
    marginTop: 0,
    position: 'absolute',
    opacity: 0.6,
    bottom: 60,
  },
  level_four_circle: {
    width: 3 * Utility.getScreenWidth(),
    height: 3 * Utility.getScreenWidth(),
    backgroundColor: colors.foxyHeader,
    borderRadius: (3 * Utility.getScreenWidth()) / 2,
    alignSelf: 'center',
    marginTop: 0,
    position: 'absolute',
    opacity: 0.4,
    bottom: 40,
  },
  container: {
    bottom: 8,
    width: Utility.getScreenWidth(),
    position: 'absolute',
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'flex-start',
    minHeight: 100,
  },
  flashDealsTitle: {
    fontFamily: Utility.isIOS() ? 'Roboto-Black' : 'Exo-Black',
    fontSize: 26,
    color: '#fff',
    marginBottom: 4,
    textShadowOffset: { width: -2, height: 4 },
    textShadowRadius: 5,
    overflow: 'visible',
    marginHorizontal: 12,
  },
  digitStyle: {
    backgroundColor: colors.black,
    borderRadius: 4,
  },
  digitTxtStyle: {
    color: colors.white,
    fontSize: 16,
    fontFamily: 'Roboto-Regular',
  },
  timeLabelStyle: {
    color: colors.black,
    fontFamily: 'Roboto-Regular',
    fontSize: 10,
    alignSelf: 'flex-end',
    marginRight: 4,
  },
  separatorStyle: {
    color: colors.black,
    marginBottom: 12,
  },
  innerContainer: {
    width: getScreenWidth(),
    height: 0.8 * getScreenWidth(),
    alignSelf: 'center',
    overflow: 'hidden',
    '@media (min-width: 768px)': {
      width: getScreenWidth(),
      height: 0.6 * Utility.screenHeight,
    },
  },
  outerContainer: {
    backgroundColor: colors.background,
  },
  foxyPromise: {
    '@media (min-width: 768px)': {
      width: 400,
      alignSelf: 'center',
      alignItems: 'center',
    },
  },
});

export default withNavigation(connect(mapStateToProps, null)(TodayDeals));
