import React, { Component } from 'react';
import {
  View,
  TouchableOpacity,
  Image,
  Text,
  ScrollView,
  Keyboard,
  BackHandler,
  KeyboardAvoidingView,
  Platform,
  ActivityIndicator,
} from 'react-native';
import { TextField } from 'react-native-material-textfield';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Toast from 'react-native-easy-toast';
import { camelCase } from 'lodash';
import { AddressStyles, CartStyles } from '../../components/cart/styles';
import images from '../../theme/Images';
import {
  addAddress,
  updateAddress,
  removeAddress,
  editProfile,
  pincodeInfo,
  getCartData,
  setDefaultAddresses,
  getCartPricing,
  setCartNotification,
} from '../../actions/ActionTypes';
import {
  changeCountryCode,
  updateMobileNumber,
} from '../../actions/LoginActions';
import colors from '../../theme/Colors';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import { withEither } from '../../lib/Monads';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import { StaticNavigationHeader } from '../../components/shared';
import {
  AnalyticsManager,
  EventType,
  EventParameterKey,
} from '../../analytics';
import FoxyShadowButton from '../../lib/FoxyShadowButton';
import FoxyAlert from '../../components/camera/shared/FoxyAlert';
import AddressCard from '../consumerProfile/Common/AddressCard';
import RemoteConfig from '../../utils/RemoteConfig';
import NotificationManager from '../../utils/NotificationsManager';
import {
  ADDRESS_DEEPLINK,
  CART_ITEMS_URL,
  CART_NOTIFICATION_ADD_ADDRESS_URL,
  CART_NOTIFICATION_STATES,
  NOTIFICATION_ACTIONS,
  NOTIFICATION_IDENTIFIERS,
  NOTIFICATION_TYPE,
  REMOTE_CONFIG_KEYS,
  TRACKING_PARAMS,
  COUNTRY_CODE,
} from '../../config/Constants';
import NotificationUtils from '../../utils/NotificationUtils';
import Config from '../../libraries/ReactNativeConfig';
import BirthDateTextField from '../FoxyEdge/BirthDateTextField';
import BirthDayInfoCard from '../FoxyEdge/BirthDayInfoCard';
import AppConfig from '../../config/AppConfig';
import PhoneNumberInput from './PhoneNumberInput';
import { isBlank, isDesktop } from '../../utils/BooleanUtility';
import { insertCharAtPosition } from '../../utils/StringUtility';
import {
  findCountryCode,
  isPhoneNumberValid,
} from '../../utils/AddressUtility';
import { pushInitiateCheckoutToGtm } from '../../analytics/GTMAnalytics';
import { getScreenWidth } from '../../utils/LayoutUtility';
import CartPricing from '../../components/cart/CartPricing';
import { getFirebasePerformanceTrace } from '../../utils/PerfUtility';
import { isInStock1ItemInCart } from '../../utils/ProductUtility';

class Address extends Component {
  addressConstants = {
    addressText: {
      true: 'Edit Address',
      false:
        this.previousScreen === SCREEN_CONSTANTS.PROFILE_EDIT
          ? 'Manage Address'
          : 'Add Address',
    },
    buttonText: {
      true: 'Save Address',
      false: 'Add Address',
    },
    textFieldConst: {
      phone_num: 'Phone Number',
      contact_name: 'Contact Name',
      pincode: 'Pin Code',
      addressLine1: 'Address (House, Flat, Block No. )',
      addressLine2: 'Apartment / Area / Locality ',
      placeName: 'Name this place. E.g. Home, Work etc',
      email: 'Email',
    },
    line1error: 'Enter an address',
    addressLimitError: `Enter atleast ${parseInt(
      Config.ADDRESS_FIELD_MINIMIMUM_CHARACTER_LIMIT,
    )} characters`,
    phoneNoError: 'Enter a valid mobile number',
    notValidPhoneNumber: 'not a valid phone no',
    contactNameError: 'Enter a name',
    isContactNameValid: 'Enter alphabets only',
    placeNameError: 'Place name cannot be blank',
    pincodeError: 'Enter a ZIP / Postal code',
    pincodeWrong: 'Pin code is invalid',
    emailError: 'Email cannot be blank',
    addPlaceNameText: 'Nickname for this address',
    removeAddressAlertText: 'Are you sure you want to remove this address?',
    deleteHeader: 'Delete address?',
    pinCodeNotExist: `Pincode doesn't exist`,
    somethingWentWrong: 'Something went wrong',
    blankNickName: 'Place nickname cannot be blank',
    notAValidEmail: 'Not a valid email',
    dateOfBirth: 'Date of Birth cannot be blank',
    emailEmpty: 'Enter an email id',
  };

  validationErrorConstants = {
    nameEmptyError: 'Enter a name',
    phoneNumberEmptyError: 'Enter a valid mobile number',
    line1EmptyError: 'Enter an address',
    line2EmptyError: 'Enter an address',
    pincodeEmptyError: 'Enter a ZIP / Postal code',
    emailEmptyError: 'Email must be present',
  };

  constructor(props) {
    super(props);
    this.trace = getFirebasePerformanceTrace('Address');
    this.trace.start();
    const { route } = props;
    this.previousScreen = route.params?.previousScreen ?? '';
    this.addressToEdit = route.params?.addressToEdit ?? {};
    this.onRefresh = route.params?.onRefresh;
    this.proceedTo = route.params?.proceedTo;
    this.extraScrollHeight = Utility.isAndroid() ? 0 : -120;
    const {
      contact_name = '',
      email = '',
      line1 = '',
      line2 = '',
      id = '',
      pincode = '',
      name = '',
    } = this.addressToEdit;
    let { phone_number = '', country_code: callingCode } = this.addressToEdit;
    if (isBlank(callingCode) || callingCode == 0) {
      callingCode = Config.COUNTRY_CODE.replace('+', '');
    }
    findCountryCode(callingCode, (countryCode) => {
      this.setState({ countryCode });
    });
    const { mobileNumber = '' } = props;
    if (Utility.isBlank(phone_number) && Utility.isPresent(mobileNumber)) {
      phone_number = mobileNumber;
    }
    phone_number = Utility.validatePhoneNum(phone_number);
    this.state = {
      phone_number,
      contact_name,
      addressLine1: line1, //DB stores 'line2' as Flat/ House no, which is displayed as 'addressLine1' on frontend
      addressLine2: line2, //DB stores 'line1' as Locality/Area, which is displayed as 'addressLine2' on frontend
      addressToEdit: id,
      pincode,
      city: '',
      state: '',
      placeName: name,
      callingCode,
      countryCode: Config.COUNTRY_ISO_CODE,
      showEditingModal: false,
      // showAddAddressScreen: true, //FIXME: Right now this is hard coded as true, but once romit is back need to ask if there is any better way to do it.
      showAddAddressScreen:
        (route.params?.addressToEdit ?? '') !== '' ||
        (route.params?.showAddAddressScreen ?? false),
      isPlaceNameEmpty: false,
      line2Empty: false,
      line1Empty: false,
      line1CharacterLimitError: false,
      line2CharacterLimitError: false,
      pincodeEmpty: false,
      isPincodeError: false,
      pinCodeErrorText: '',
      selectedAddressId: '',
      nameEmpty: false,
      isContactNameInvalid: false,
      addressToDelete: {},
      isNotValidEmail: false,
      fetchPincodeInfo: false,
      isLoaderVisible: false,
      phoneNumberEmpty: false,
      showSaveModal: false,
      showDeleteButton: false,
      phoneInputCount: 20,
      emailId: this.props.email || email,
      birthDate: '',
      isBirthDateEmpty: false,
      emailEmpty: false,
    };
    this.ref = [];
    this.ref.pincode = React.createRef();
    this.ref.addressLine1 = React.createRef();
    this.ref.addressLine2 = React.createRef();
    this.ref.placeName = React.createRef();
    this.ref.phoneNumber = React.createRef();
    this.ref.email = React.createRef();
    this.ref.userName = React.createRef();
    this.ref.scrollView = React.createRef();
    this.isAddressInfoInitiated = false;
    this.addressSelected = '';
    this.scrollViewRef = null;
    this.showBirthDate =
      this.previousScreen === SCREEN_CONSTANTS.LOYALTY_PLANS ||
      this.previousScreen === SCREEN_CONSTANTS.NON_MEMBER_LANDING_PAGE;
    this.mandatoryAddressFields =
      Config.ADDRESS_PAGE_MANDATORY_FIELDS?.split(',') || [];
    this.lastEnteredPhoneNumber = '';
    this.isInternationalChoiceEnabled = AppConfig.getBooleanValue(
      Config.ENABLE_INTERNATIONAL_CHOICE,
    );
  }

  componentDidMount() {
    const { mobileNumber, address, authToken } = this.props;
    const {
      pincode,
      showAddAddressScreen,
      addressToEdit,
      placeName,
      phone_number,
    } = this.state;
    // if (
    //   Utility.isBlank(phone_number) &&
    //   Utility.isPresent(mobileNumber) &&
    //   Utility.isPresent(authToken)
    // ) {
    //   this.setState({
    //     phone_number: mobileNumber.replace('+91', ''),
    //   });
    // }

    if (Utility.isBlank(placeName)) {
      if (Utility.isBlank(address)) {
        this.setState({
          placeName: 'Home',
        });
      } else {
        this.setState({
          placeName: 'Others',
        });
      }
    }
    if (Platform.OS === 'android') {
      this.backHandler = BackHandler.addEventListener(
        'hardwareBackPress',
        this.onHardwareBackKeyPress,
      );
    }
    if (Utility.isPresent(pincode)) {
      this.getPincodeInfo(pincode);
    }
    if (Utility.isBlank(phone_number) || phone_number === `${COUNTRY_CODE}-`) {
      this.moveToFieldWithTimeout('phoneNumber');
    } else {
      this.moveToFieldWithTimeout('userName');
    }

    if (
      Utility.isBlank(showAddAddressScreen) &&
      Utility.isBlank(addressToEdit) &&
      Utility.isPresent(address)
    ) {
      return;
    }
    // Show addres modal view event on opening address
    AnalyticsManager.logEvent(EventType.profileEvents.ADDRESS_MODAL_VIEW, {
      [EventParameterKey.SOURCE]: this.previousScreen,
      [EventParameterKey.IS_GUEST_USER]: Utility.isBlank(authToken),
    });
    //if (
    //  showAddAddressScreen ||
    //  this.previousScreen === SCREEN_CONSTANTS.PRODUCT_DETAIL ||
    //  this.previousScreen === SCREEN_CONSTANTS.PROFILE_EDIT ||
    //  this.previousScreen === SCREEN_CONSTANTS.MY_PROFILE
    //) {
    //  AnalyticsManager.logEvent(EventType.profileEvents.ADDRESS_MODAL_VIEW, {
    //    [EventParameterKey.SOURCE]: this.previousScreen,
    //    [EventParameterKey.IS_GUEST_USER]: Utility.isBlank(authToken),
    //  });
    //}
    this.trace.stop();
  }

  componentWillUnmount() {
    const { route } = this.props;
    const getAllAddress = route.params?.getAllAddress;
    if (Platform.OS === 'android') {
      this.backHandler.remove();
    }
    if (
      this.previousScreen === SCREEN_CONSTANTS.CONSUMER_PROFILE &&
      typeof getAllAddress === 'function'
    ) {
      getAllAddress();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { showAddAddressScreen, pincode = '' } = this.state;
    const { authToken } = this.props;
    const isGuestUser = Utility.isBlank(authToken);
    const { pincode: prevPincode = '' } = prevState;
    if (showAddAddressScreen && !prevState.showAddAddressScreen) {
      AnalyticsManager.logEvent(EventType.profileEvents.ADDRESS_MODAL_VIEW, {
        [EventParameterKey.SOURCE]:
          this.previousScreen || SCREEN_CONSTANTS.CART,
        [EventParameterKey.IS_GUEST_USER]: isGuestUser,
      });
    }

    if (
      Utility.isPresent(prevProps.address) &&
      Utility.isBlank(this.props.address)
    ) {
      AnalyticsManager.logEvent(EventType.profileEvents.ADDRESS_MODAL_VIEW, {
        [EventParameterKey.SOURCE]:
          this.previousScreen || SCREEN_CONSTANTS.CART,
        [EventParameterKey.IS_GUEST_USER]: isGuestUser,
      });
    }

    if (prevPincode.length === 0 && pincode.length >= 6) {
      this.getPincodeInfo(pincode);
    }
  }

  renderLoader = (isVisible) => {
    this.setState({
      isLoaderVisible: isVisible,
    });
  };

  hideAddressScreen = () => {
    const { navigation } = this.props;
    this.setState(
      {
        addressToEdit: '',
        showAddAddressScreen: false,
        placeName: '',
        pincode: '',
        addressLine1: '',
        addressLine2: '',
        phone_number: '',
        line2Empty: false,
        line1Empty: false,
        line1CharacterLimitError: false,
        line2CharacterLimitError: false,
        phoneNumberEmpty: false,
        isPlaceNameEmpty: false,
        pincodeEmpty: false,
        pincodeErrorText: '',
        isPincodeError: false,
        nameEmpty: false,
        isContactNameInvalid: false,
        contact_name: '',
        isNotValidEmail: false,
        isBirthDateEmpty: false,
        emailEmpty: false,
      },
      () => {
        history.back();
      },
    );
  };

  proceedWithAddressAdd = (source) => {
    const { navigation, route } = this.props;
    const { showAddAddressScreen, addressToEdit, placeName } = this.state;

    this.isAddressInfoInitiated = false;

    if (
      this.previousScreen === SCREEN_CONSTANTS.PROFILE_EDIT ||
      this.previousScreen === SCREEN_CONSTANTS.CONSUMER_PROFILE
    ) {
      history.back();
      this.setState({
        addressToEdit: '',
        showAddAddressScreen: true,
        placeName: '',
        pincode: '',
        addressLine1: '',
        addressLine2: '',
        contact_name: '',
        phone_number: '',
        line2Empty: false,
        line1Empty: false,
        line1CharacterLimitError: false,
        line2CharacterLimitError: false,
        phoneNumberEmpty: false,
        isPlaceNameEmpty: false,
        pincodeEmpty: false,
        pincodeEmpty: false,
        pincodeErrorText: '',
        isPincodeError: false,
        nameEmpty: false,
        isContactNameInvalid: false,
        emailId: '',
        isNotValidEmail: false,
        isBirthDateEmpty: false,
        emailEmpty: false,
      });
      return;
    }

    this.setState({
      addressToEdit: '',
      showAddAddressScreen: false,
      placeName: '',
      pincode: '',
      addressLine1: '',
      addressLine2: '',
      phone_number: '',
      line2Empty: false,
      line1Empty: false,
      line1CharacterLimitError: false,
      line2CharacterLimitError: false,
      phoneNumberEmpty: false,
      isPlaceNameEmpty: false,
      pincodeEmpty: false,
      pincodeErrorText: '',
      isPincodeError: false,
      nameEmpty: false,
      isContactNameInvalid: false,
      contact_name: '',
      isNotValidEmail: false,
      isBirthDateEmpty: false,
      emailEmpty: false,
    });

    const { params: { destination = '' } = {} } = route;
    if (source !== 'go_back') {
      if (
        this.previousScreen === 'cart_checkout' ||
        destination === CART_NOTIFICATION_ADD_ADDRESS_URL
      ) {
        setTimeout(this.setCartReminderNotificationAlarm, 600);
        if (isInStock1ItemInCart()) {
          this.props.navigation.replace('Cart');
          return;
        }
        this.props.navigation.replace('Payments');
        return;
      }
    }

    if (Utility.isPresent(this.proceedTo) && source !== 'go_back') {
      if (AppConfig.isCartPresentInBottomTabs()) {
        navigation.navigate(this.proceedTo, {
          showOrderSummary: true,
          source: 'address',
        });
        return;
      }
      navigation.replace(this.proceedTo, {
        showOrderSummary: true,
        source: 'address',
      });
      return;
    }

    if (
      this.previousScreen === SCREEN_CONSTANTS.NON_MEMBER_LANDING_PAGE &&
      source !== 'go_back'
    ) {
      navigation.replace('LoyaltyPlans', {
        previousScreen: this.previousScreen,
      });
      return;
    }

    history.back();
  };

  setCartReminderNotificationAlarm = async () => {
    const cartReminderNotificationData = JSON.parse(
      RemoteConfig.getValue(
        REMOTE_CONFIG_KEYS.customised_cart_reminder_notification_data,
      ),
    );
    const { cartData, address } = this.props;
    const notificationData = NotificationUtils.getCartReminderNotificationData(
      cartReminderNotificationData,
      cartData,
    );

    const { images: { added_to_bag = '', added_shipping = '' } = {} } =
      cartReminderNotificationData;

    const imageUrl = !notificationData?.extraData?.hasAddress
      ? added_to_bag
      : added_shipping;

    let link = Utility.appendUtmParamsWithUrl(
      CART_ITEMS_URL,
      TRACKING_PARAMS.NOTIFICATION.CART,
    );

    if (Utility.isBlank(address)) {
      link = Utility.appendUtmParamsWithUrl(
        ADDRESS_DEEPLINK,
        TRACKING_PARAMS.NOTIFICATION.CART,
      );
    }

    const payload = {
      ...notificationData,
      image: imageUrl,
      deepLink: link,
      primary_cta: NOTIFICATION_ACTIONS.CONTINUE.cta,
      primary_destination: link,
      secondary_cta: NOTIFICATION_ACTIONS.REMIND_IN_24_HOURS.cta,
      secondary_destination: NOTIFICATION_ACTIONS.REMIND_IN_24_HOURS.action,
      type: NOTIFICATION_TYPE.CART_REMINDER,
      isLiveNotification: true,
      addressPresent: Utility.isPresent(address),
    };
    NotificationManager.createNotification(
      payload,
      NOTIFICATION_IDENTIFIERS.CART_REMINDER,
    );
  };

  goBack = () => {
    this.sendEditAddressUserBackEvent('user_back_confirm');
    // since this page has two state- add address and edit address, back will have to be based on logic instead of just navigation.goBack()
    this.closeSaveModal();
    this.proceedWithAddressAdd('go_back');
  };

  onHardwareBackKeyPress = () => {
    this.onBackPress();
    return true;
  };

  addPincode = (value = '', isAutofilled = false) => {
    const { pincode } = this.state;
    const { pincodeInfo } = this.props;
    if (!this.isAddressInfoInitiated) {
      this.fireAddressInitiate();
      this.isAddressInfoInitiated = true;
    }
    if (Utility.isBlank(pincode) && value?.length === 1) {
      this.onFocus(this.addressConstants.textFieldConst.pincode, 'typing');
    }
    this.setState({
      pincode: value,
      pincodeEmpty: false,
      isPincodeError: false,
      city: '',
      state: '',
    });
    if (value?.length === parseInt(Config.ADDRESS_PAGE_PINCODE_LIMIT || 6)) {
      this.getPincodeInfo(value, isAutofilled);
    }
  };

  addBirthDate = (date) => {
    this.setState({
      birthDate: date,
      isBirthDateEmpty: false,
    });
  };

  firePinCodeEvent = (pincode, city, state) => {
    AnalyticsManager.logEvent(EventType.profileEvents.PIN_CODE_INPUT, {
      [EventParameterKey.PIN_CODE]: pincode,
      [EventParameterKey.CITY]: city,
      [EventParameterKey.STATE]: state,
    });
  };

  getPincodeInfo = (pincode, isAutofilled = false) => {
    const { pincodeInfo } = this.props;
    this.setState({
      fetchPincodeInfo: true,
    });
    pincodeInfo(pincode, (response) => {
      if (response) {
        this.setState(
          {
            city: response.city,
            state: response.state,
            fetchPincodeInfo: false,
          },
          () => this.firePinCodeEvent(pincode, response.city, response.state),
        );
        if (!isAutofilled) {
          Keyboard.dismiss();
        } else {
          this.moveToField('addressLine1');
        }
      } else {
        this.setState({
          fetchPincodeInfo: false,
          city: '',
          state: '',
          isPincodeError: true,
          pincodeErrorText: this.addressConstants.pinCodeNotExist,
        });
      }
    });
  };

  addPhoneNumber = (value) => {
    if (!this.isAddressInfoInitiated) {
      this.fireAddressInitiate();
      this.isAddressInfoInitiated = true;
    }
    let val = value.replace(/[^0-9]/g, '');

    if (this.isInternationalChoiceEnabled) {
      this.setState({ phone_number: val, phoneNumberEmpty: false });
      return;
    }

    const { phone_number } = this.state;
    let phoneInputCount = 0;
    val = val.slice(-AppConfig.phoneNumberLimit);
    if (val.length > 0 && val.length <= AppConfig.phoneNumberLimit) {
      phoneInputCount = AppConfig.phoneNumberLimit;
    } else {
      phoneInputCount = 2 * AppConfig.phoneNumberLimit;
    }

    if (val.length === AppConfig.phoneNumberLimit) {
      this.moveToField('userName');
    }
    if (Utility.isBlank(phone_number) && value?.length === 1) {
      this.onFocus(this.addressConstants.textFieldConst.phone_num, 'typing');
    }
    this.setState({
      phoneInputCount,
      phone_number: val,
      phoneNumberEmpty: false,
    });
  };

  addContactName = (value) => {
    const { contact_name } = this.state;
    if (!this.isAddressInfoInitiated) {
      this.fireAddressInitiate();
      this.isAddressInfoInitiated = true;
    }
    if (Utility.isBlank(contact_name) && value?.length === 1) {
      this.onFocus(this.addressConstants.textFieldConst.contact_name, 'typing');
    }
    this.setState({
      contact_name: value,
      nameEmpty: false,
      isContactNameInvalid: false,
    });
  };

  validate = (email) => {
    const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (reg.test(email) === false) {
      return false;
    }
    return true;
  };

  addEmail = (value) => {
    const { emailId } = this.state;
    if (!this.isAddressInfoInitiated) {
      this.fireAddressInitiate();
      this.isAddressInfoInitiated = true;
    }
    if (Utility.isBlank(emailId) && value?.length === 1) {
      this.onFocus(this.addressConstants.textFieldConst.email, 'typing');
    }
    this.setState({
      emailId: value,
      isNotValidEmail: false,
      emailEmpty: false,
    });

    if (value?.length > 0 && !this.validate(value)) {
      this.setState({
        isNotValidEmail: true,
      });
      AnalyticsManager.logEvent(EventType.profileEvents.EMAIL_ERROR, {
        [EventParameterKey.ERROR]: this.addressConstants.notAValidEmail,
      });
    }
  };

  addAddress = (value) => {
    const { addressLine1 } = this.state;
    if (!this.isAddressInfoInitiated) {
      this.fireAddressInitiate();
      this.isAddressInfoInitiated = true;
    }
    if (Utility.isBlank(addressLine1) && value?.length === 1) {
      this.onFocus(this.addressConstants.textFieldConst.addressLine1, 'typing');
    }
    this.setState({
      addressLine1: value,
      line1CharacterLimitError: false,
      line1Empty: false,
    });
  };

  addPlaceName = (value) => {
    if (!this.isAddressInfoInitiated) {
      this.fireAddressInitiate();
      this.isAddressInfoInitiated = true;
    }
    this.setState({ placeName: value, isPlaceNameEmpty: false });
  };

  isIosFn = () => Utility.isIOS();

  customKeyboardAvoid = withEither(this.isIosFn, KeyboardAvoidingView)(View);

  updateProfile = () => {
    const { editProfile, userProfile } = this.props;
    const { birthDate, emailId } = this.state;
    let user = {};
    if (Utility.isPresent(emailId)) {
      user = { email: emailId };
    }
    if (birthDate) {
      user = { ...user, date_of_birth: birthDate };
    }
    editProfile(user, {}, (callbackData, success) => {});
  };

  getCartData = () => {
    const { getCartData, getPaymentMethods, viewPaymentScreen } = this.props;

    getCartData((success, payuParams) => {
      if (success) {
        // payuParams.amount = '0'; //TODO: delete this
        getPaymentMethods(payuParams, (result) => {
          if (result) {
            viewPaymentScreen();
          } else {
            this.toast.show('Something went wrong.'); // FIXME: handle errors neatly;
          }
        });
      } else {
        this.toast.show('Something went wrong.');
      }
    });
  };

  checkIfFieldIsMandatory = (field) =>
    this.mandatoryAddressFields.includes(field);

  addressCharacterLimitError = (addressLine = '') => {
    return (
      parseInt(Config.ADDRESS_FIELD_MINIMIMUM_CHARACTER_LIMIT) >
      (addressLine ?? '').length
    );
  };

  isContactNameInvalid = () => {
    const { contact_name } = this.state;
    const regex = new RegExp(/^[a-zA-Z .-]+$/);
    return !regex.test(contact_name);
  };

  phoneNumberLimitCheck = () => {
    const { phone_number, callingCode, countryCode } = this.state;
    if (this.isInternationalChoiceEnabled) {
      return (
        isPhoneNumberValid(phone_number, callingCode, countryCode) ||
        phone_number?.startsWith('369900')
      );
    }
    if (phone_number.startsWith(COUNTRY_CODE)) {
      return (
        phone_number.length ===
        AppConfig.phoneNumberLimit + COUNTRY_CODE.length + 1
      );
    }
    return phone_number.length === AppConfig.phoneNumberLimit;
  };

  onSaveAddressPress = () => {
    const {
      addAddress,
      updateAddress,
      addressSource,
      viewPaymentScreen,
      email,
      mobileNumber,
      user_name,
      authToken,
      onCartDismiss,
      updateCartPrice,
      currentScreen,
      navigation,
      route,
      date_of_birth = '',
      address: addresses,
      shopifyAddresses,
      updateMobileNumber,
      setCartNotification,
      changeCountryCode,
    } = this.props;
    const {
      pincode,
      placeName = '',
      addressLine1,
      addressLine2,
      phone_number = '',
      addressToEdit,
      contact_name,
      city,
      state,
      emailId,
      birthDate,
      countryCode,
      callingCode,
    } = this.state;
    const data = {
      contact_name: contact_name.trim(),
      pincode,
      line1: addressLine1?.trim(), //DB stores 'line2' as Flat/ House no, which is displayed as 'addressLine1' on frontend
      line2: addressLine2?.trim(), //DB stores 'line1' as Locality/Area, which is displayed as 'addressLine2' on frontend
      phone_number: phone_number?.trim(),
      name: placeName?.trim(),
      id: addressToEdit,
      email: emailId?.trim(),
      address_type:
        addresses[addressToEdit]?.address_type ??
        shopifyAddresses[addressToEdit]?.address_type ??
        'EkanekAddress',
      callingCode,
    };

    if (
      this.checkIfFieldIsMandatory('birthDate') &&
      this.showBirthDate &&
      Utility.isBlank(date_of_birth) &&
      birthDate === ''
    ) {
      this.setState({
        isBirthDateEmpty: true,
      });
      AnalyticsManager.logEvent(EventType.profileEvents.BIRTH_DATE_ERROR, {
        [EventParameterKey.ERROR]: this.addressConstants.dateOfBirth,
      });
      return;
    }

    if (this.checkIfFieldIsMandatory('phoneNumber') && phone_number === '') {
      this.setState({
        phoneNumberEmpty: true,
      });
      this.moveToFieldWithTimeout('phoneNumber');
      AnalyticsManager.logEvent(EventType.profileEvents.ADDRESS_ERROR, {
        [EventParameterKey.ERROR]:
          this.validationErrorConstants.phoneNumberEmptyError,
      });
      return;
    }

    if (!this.phoneNumberLimitCheck()) {
      this.setState({
        phoneNumberEmpty: true,
      });
      this.moveToFieldWithTimeout('phoneNumber');
      AnalyticsManager.logEvent(EventType.profileEvents.ADDRESS_ERROR, {
        [EventParameterKey.ERROR]: this.addressConstants.notValidPhoneNumber,
      });
      return;
    }

    if (
      this.checkIfFieldIsMandatory('contactName') &&
      (contact_name === '' || this.isContactNameInvalid())
    ) {
      this.setState({
        nameEmpty: contact_name === '',
        isContactNameInvalid:
          contact_name !== '' && this.isContactNameInvalid(),
      });
      const contactNameError =
        contact_name !== '' && this.isContactNameInvalid()
          ? this.addressConstants.isContactNameValid
          : this.validationErrorConstants.nameEmptyError;
      AnalyticsManager.logEvent(EventType.profileEvents.ADDRESS_ERROR, {
        [EventParameterKey.ERROR]: contactNameError,
      });
      return;
    }

    if (this.checkIfFieldIsMandatory('emailId') && emailId === '') {
      this.setState({
        emailEmpty: true,
      });
      AnalyticsManager.logEvent(EventType.profileEvents.EMAIL_ERROR, {
        [EventParameterKey.ERROR]:
          this.validationErrorConstants.emailEmptyError,
      });
      return;
    }

    if (emailId?.length > 0 && !this.validate(emailId)) {
      this.setState({
        isNotValidEmail: true,
      });
      AnalyticsManager.logEvent(EventType.profileEvents.EMAIL_ERROR, {
        [EventParameterKey.ERROR]: this.addressConstants.notAValidEmail,
      });
      return;
    }

    if (
      this.checkIfFieldIsMandatory('addressLine1') &&
      (addressLine1 === '' || this.addressCharacterLimitError(addressLine1))
    ) {
      this.setState({
        line1Empty: addressLine1 === '',
        line1CharacterLimitError: this.addressCharacterLimitError(),
      });
      this.moveToFieldWithTimeout('addressLine1');
      const addressError = this.addressCharacterLimitError(addressLine1)
        ? this.addressConstants.addressLimitError
        : this.validationErrorConstants.line1EmptyError;
      AnalyticsManager.logEvent(EventType.profileEvents.ADDRESS_ERROR, {
        [EventParameterKey.ERROR]: addressError,
      });
      return;
    }

    if (
      this.checkIfFieldIsMandatory('addressLine2') &&
      (addressLine2 === '' || this.addressCharacterLimitError(addressLine2))
    ) {
      this.setState({
        line2Empty: addressLine2 === '',
        line2CharacterLimitError: this.addressCharacterLimitError(addressLine2),
      });
      this.moveToFieldWithTimeout('addressLine2');
      const addressError = this.addressCharacterLimitError(addressLine2)
        ? this.addressConstants.addressLimitError
        : this.validationErrorConstants.line2EmptyError;
      AnalyticsManager.logEvent(EventType.profileEvents.ADDRESS_ERROR, {
        [EventParameterKey.ERROR]: addressError,
      });
      return;
    }

    if (this.checkIfFieldIsMandatory('pincode') && pincode === '') {
      this.setState({
        pincodeEmpty: true,
      });
      this.moveToFieldWithTimeout('pincode');
      AnalyticsManager.logEvent(EventType.profileEvents.ADDRESS_ERROR, {
        [EventParameterKey.ERROR]:
          this.validationErrorConstants.pincodeEmptyError,
      });
      return;
    }
    if (this.checkIfFieldIsMandatory('placeName') && placeName === '') {
      this.setState({
        isPlaceNameEmpty: true,
      });
      this.toast.show(this.addressConstants.blankNickName);
      AnalyticsManager.logEvent(EventType.profileEvents.ADDRESS_ERROR, {
        [EventParameterKey.ERROR]: this.addressConstants.blankNickName,
      });
      return;
    }

    if (
      (this.checkIfFieldIsMandatory('placeName') && placeName === '') ||
      (this.checkIfFieldIsMandatory('pincode') && pincode === '') ||
      (this.checkIfFieldIsMandatory('address') &&
        (addressLine1 === '' ||
          this.addressCharacterLimitError(addressLine1) ||
          this.addressCharacterLimitError(addressLine2))) ||
      (this.checkIfFieldIsMandatory('contactName') &&
        (contact_name === '' || this.isContactNameInvalid())) ||
      (this.checkIfFieldIsMandatory('phoneNumber') && phone_number === '') ||
      !this.phoneNumberLimitCheck() ||
      (this.checkIfFieldIsMandatory('eamilId') && emailId === '') ||
      (emailId?.length > 0 && !this.validate(emailId)) ||
      (this.checkIfFieldIsMandatory('birthDate') &&
        this.showBirthDate &&
        birthDate === null)
    ) {
      return;
    }

    let emailForAnalytics;
    let mobileNumberForAnalytics;
    let nameForAnalytics;
    if (Utility.isBlank(email)) {
      emailForAnalytics = emailId;
    }
    this.updateProfile();
    if (Utility.isBlank(mobileNumber)) {
      mobileNumberForAnalytics = phone_number;
      updateMobileNumber(`${Config.COUNTRY_CODE}${phone_number}`);
      if (this.isInternationalChoiceEnabled) {
        changeCountryCode({ countryCode, callingCode });
      }
    }
    if (Utility.isBlank(user_name)) {
      nameForAnalytics = contact_name;
    }

    AnalyticsManager.setGeneralUserAttributes({
      name: `${nameForAnalytics}`,
      email: `${emailForAnalytics}`,
      contactNumber: `${mobileNumberForAnalytics}`,
    });

    this.setState({
      showSaveModal: false,
    });
    Keyboard.dismiss();
    pushInitiateCheckoutToGtm(2, 'Shipping Details');
    console.log({ addressToEdit })
    if (addressToEdit === '' || data?.address_type === 'ShopifyAddress') {
      this.renderLoader(true);
      // we are in add address state
      addAddress(data, (result, callbackdata, responseCode) => {
        this.renderLoader(false);
        if (result) {
          const screenName = Utility.isPresent(this.previousScreen)
            ? this.previousScreen
            : SCREEN_CONSTANTS.CART;

          AnalyticsManager.logEvent(EventType.profileEvents.ADDRESS_ADD, {
            [EventParameterKey.SOURCE]: screenName,
          });
          if (this.showBirthDate) {
            AnalyticsManager.logEvent(EventType.discoveryEvents.ITEM_ACTION, {
              [EventParameterKey.TYPE]: 'add_dob',
            });
          }
          AnalyticsManager.logFirebaseEvent(
            EventType.googleRemarketingEvents.ADD_SHIPPING_INFO,
            {
              [EventParameterKey.CURRENCY]: 'INR',
              [EventParameterKey.ITEMS]: [],
              shipping_tier: 'default',
              value: 0,
              coupon: '',
            },
          );

          setCartNotification(CART_NOTIFICATION_STATES.makePayment);
          this.proceedWithAddressAdd();
        } else if (
          !Utility.isBlank(callbackdata) &&
          !Utility.isBlank(callbackdata.errors)
        ) {
          if (
            !Utility.isBlank(callbackdata.errors.pincode) &&
            callbackdata.errors.pincode.length > 0
          ) {
            this.setState({
              pincodeErrorText: `Pin code ${callbackdata.errors.pincode[0]}`,
              isPincodeError: true,
            });
            AnalyticsManager.logEvent(EventType.profileEvents.ADDRESS_ERROR, {
              [EventParameterKey.ERROR]: `Pin code ${callbackdata.errors.pincode[0]}`,
            });
          }
        } else if (Utility.isBlank(city) && Utility.isBlank(state)) {
          this.toast.show(this.addressConstants.pinCodeNotExist);
          AnalyticsManager.logEvent(EventType.profileEvents.ADDRESS_ERROR, {
            [EventParameterKey.ERROR]: this.addressConstants.pinCodeNotExist,
          });
        } else {
          this.toast.show(this.addressConstants.somethingWentWrong);
          AnalyticsManager.logEvent(EventType.profileEvents.ADDRESS_ERROR, {
            [EventParameterKey.ERROR]: this.addressConstants.somethingWentWrong,
          });
        }
      });
    } else {
      // we are in edit address state
      console.log('well coming here tho')
      AnalyticsManager.logEvent(EventType.profileEvents.ADDRESS_UPDATE, { address_id: addressToEdit });
      this.renderLoader(true);
      updateAddress(data, (result, callbackdata, responseCode) => {
        if (result) {
          this.renderLoader(false);
          if (route.params?.updateAddress) {
            route.params?.updateAddress(callbackdata);
            history.back();
          } else {
            this.goBack();
          }
        } else if (
          !Utility.isBlank(callbackdata) &&
          !Utility.isBlank(callbackdata.errors)
        ) {
          if (
            !Utility.isBlank(callbackdata.errors.pincode) &&
            callbackdata.errors.pincode.length > 0
          ) {
            this.setState({
              pincodeErrorText: `Pin code ${callbackdata.errors.pincode[0]}`,
              isPincodeError: true,
            });
          }
        } else if (Utility.isBlank(city) && Utility.isBlank(state)) {
          this.toast.show(`Pincode doesn't exist`);
        } else {
          this.toast.show('Something went wrong');
        }
      });
    }
    this.setState({
      showSaveModal: false,
    });
  };

  onSaveEditAddressPress = () => {
    this.sendEditAddressUserBackEvent('user_back_confirm_and_save_address');
    this.onSaveAddressPress();
  }

  moveToFieldWithTimeout = (field) => {
    setTimeout(() => {
      this.moveToField(field);
    }, 500);
  };

  setValidationErrors = (validationErrors) => {
    Object.entries(validationErrors).map(([field = '', errorObject = {}]) => {
      const { presence = {}, format = {} } = errorObject;
      if (Utility.isPresent(presence)) {
        this.validationErrorConstants[`${camelCase(field)}EmptyError`] =
          presence.message;
        this.setState({
          [`${camelCase(field)}Empty`]: true,
        });
      }
    });
  };

  // divider = () => <View style={AddressStyles.divider} />;

  editAddress = (address) => {
    this.addressSelected = address;
    const { validation_errors = {} } = address;
    if (Utility.isPresent(validation_errors)) {
      this.setValidationErrors(validation_errors);
    }
    this.setState({
      addressToEdit: address.id,
      contact_name: address.contact_name,
      placeName: address.name,
      pincode: address.pincode,
      addressLine1: address.line1,
      addressLine2: address.line2,
      phone_number: address.phone_number,
      showAddAddressScreen: true,
      emailId: address.emailId,
      showDeleteButton: true,
    });

    if (Utility.isPresent(address?.pincode)) {
      this.getPincodeInfo(address?.pincode);
    }
    if (Utility.isBlank(address.phone_number)) {
      this.moveToFieldWithTimeout('phoneNumber');
    } else {
      this.moveToFieldWithTimeout('userName');
    }
  };

  showDeleteAlert = (address) => {
    this.setState({
      showAlert: true,
      addressToDelete: address,
    });
  };

  deleteAddress = () => {
    const { removeAddress } = this.props;
    const { addressToDelete } = this.state;
    if (Utility.isBlank(addressToDelete)) {
      return;
    }
    const data = {
      id: addressToDelete.id,
      address_type: addressToDelete?.address_type,
    };
    removeAddress(data, (success) => {
      if (success) {
        this.toast.show('Address removed');
        this.goBack();
      }
    });
  };

  viewAddressPage = () => {
    const { placeName } = this.state;
    const { address, email, mobileNumber } = this.props;
    this.setState({
      showAddAddressScreen: true,
    });

    if (Utility.isBlank(placeName)) {
      if (Utility.isBlank(address)) {
        this.setState({
          placeName: 'Home',
        });
      } else {
        this.setState({
          placeName: 'Others',
        });
      }
    }

    if (Utility.isPresent(email)) {
      this.setState({
        emailId: email,
      });
    }

    if (Utility.isPresent(mobileNumber)) {
      this.setState({
        phone_number: Utility.validatePhoneNum(mobileNumber),
      });
      this.moveToFieldWithTimeout('userName');
    } else {
      this.moveToFieldWithTimeout('phoneNumber');
    }
  };

  toggleAlert = () => {
    this.setState((prevState) => ({
      showAlert: !prevState.showAlert,
    }));
  };
  yesTappedInRemoveAddress = () => {
    this.deleteAddress();
    this.toggleAlert();
  };

  cancelTappedInRemoveAddress = () => {
    this.toggleAlert();
    this.setState({
      addressToDelete: {},
    });
  };

  formatCompleteAddress = (props) => {
    const { address } = props;
    const formatedAddress = `${address.name || ''}: ${address.line1 || ''},${
      address.line2 || ''
    }, - ${address.pincode || ''}`;
    return formatedAddress;
  };

  updateSelectedAddress = (address) => {
    const {
      getAddresses,
      updateCartPrice,
      setDefaultAddresses,
      getCartPricing,
      route,
    } = this.props;
    const { validation_errors } = address;
    if (Utility.isPresent(validation_errors)) {
      this.editAddress(address);
      return;
    }
    const addressType = address.address_type ?? 'EkanekAddress';
    if (this.previousScreen === SCREEN_CONSTANTS.PRODUCT_DETAIL) {
      // update address in product page (product delivery component)
      const selectAddress = route.params?.selectAddress;
      selectAddress(address);
      setDefaultAddresses(address.id, () => {}, addressType);
      this.proceedWithAddressAdd();
      return;
    }
    // update selected address on cart modal when user selects a particular address

    setDefaultAddresses(address.id, (success) => {
      if (success && typeof updateCartPrice === 'function') getCartPricing();
    });

    this.proceedWithAddressAdd();
  };

  placeNameSelected = () => {};

  getAddressIcon = (type) => {
    let icon = null;
    if (Utility.isBlank(type)) {
      return images.other_location;
    }
    if (type.toLowerCase() === 'home') {
      icon = images.home_building;
    } else if (
      type.toLowerCase() === 'office' ||
      type.toLowerCase() === 'work'
    ) {
      icon = images.office_building;
    } else {
      icon = images.other_location;
    }
    return icon;
  };

  address = (props) => {
    const {
      address: {
        name,
        line1,
        line2,
        pincode,
        isSelected,
        address_type,
        validation_errors = {},
      } = {},
      address,
    } = props;
    const { currentAddress } = this.props;
    const styles = CartStyles;
    const addressIcon = Utility.isPresent(validation_errors)
      ? { uri: images.error_exclamation_icon }
      : this.getAddressIcon(name);
    const locationIcon = this.getAddressIcon(name);
    const locationType = Utility.isPresent(name) ? name : 'Others';
    const addressLine1 = Utility.isPresent(line1) ? line1 : '';
    const addressLine2 = Utility.isPresent(line2) ? line2 : '';
    return (
      <>
        <View style={styles.individualAddressContainer}>
          <AddressCard
            locationIcon={addressIcon}
            locationType={locationType}
            addressLine1={addressLine2}
            addressLine2={addressLine1}
            pincode={pincode}
            onEditAddress={() => this.editAddress(address)}
            onAddressCardTap={() => this.updateSelectedAddress(address)}
            isSelected={isSelected && address_type !== 'ShopifyAddress'}
          />
        </View>
      </>
    );
  };

  selectAddressView = (props) => {
    const { address } = props;
    const { shopifyAddresses } = this.props;
    const styles = CartStyles;
    let addressArray = this.convertAddressObjectIntoArray(address);
    let shopifyAddressesArray =
      this.convertAddressObjectIntoArray(shopifyAddresses);
    const sorted = addressArray?.sort((a, b) => b.id - a.id) || [];
    const sortedShopifyAddress =
      shopifyAddressesArray?.sort((a, b) => b.id - a.id) || [];
    return (
      <View style={{ flex: 1, flexDirection: 'row', width: getScreenWidth() }}>
        <ScrollView
          style={[
            styles.addressContainer,
            { height: '100%', alignSelf: 'center' },
          ]}
        >
          {sorted.map((item, index) => (
            <this.address
              address={item}
              index={index}
              key={`${index}_${item}`}
            />
          ))}
          {sortedShopifyAddress.length > 0 && (
            <View style={AddressStyles.shopifyAddressContainer}>
              <Text style={AddressStyles.shopifyAddressText}>
                Addresses from your past orders from the website
              </Text>
              <View style={AddressStyles.marginTop10}>
                {sortedShopifyAddress.map((item, index) => (
                  <this.address
                    address={item}
                    index={index}
                    key={`${index}_${item}`}
                  />
                ))}
              </View>
            </View>
          )}
          <TouchableOpacity
            style={styles.addAddressContainer}
            onPress={this.viewAddressPage}
          >
            <Image source={images.plus} style={styles.plus} />
            <Text style={styles.addAddressText}>Add new Address</Text>
          </TouchableOpacity>
        </ScrollView>
        <this.cartPricingDetails />
      </View>
    );
  };

  convertAddressObjectIntoArray = (address) => {
    let addressArray = [];
    if (!Utility.isBlank(address)) {
      addressArray = Object.keys(address).map((key) => ({
        id: key,
        name: address[key].name,
        pincode: address[key].pincode,
        line1: address[key].line1,
        line2: address[key].line2,
        phone_number: !Utility.isBlank(address[key].phone_number)
          ? address[key].phone_number
          : '',
        contact_name: address[key].contact_name,
        emailId: address[key].email,
        isSelected: address[key].default,
        address_type: address[key]?.address_type,
        validation_errors: address[key]?.validation_errors ?? {},
      }));

      return addressArray;
    }
  };

  placeNameButton = (props) => {
    const { item } = props;
    const styles = AddressStyles;
    const { placeName } = this.state;
    const borderColor = placeName === item ? colors.green : colors.border;
    return (
      <TouchableOpacity onPress={() => this.addPlaceName(item)}>
        <View style={[styles.placeNameButton, { borderColor }]}>
          <Text allowFontScaling={false} style={styles.placeNameButtonText}>
            {item}
          </Text>
        </View>
      </TouchableOpacity>
    );
  };

  placeNameButtons = () => {
    const styles = AddressStyles;
    const placeNameArray = ['Home', 'Work', 'Others'];
    return (
      <View>
        <Text style={styles.addPlaceNameText}>
          {this.addressConstants.addPlaceNameText}
        </Text>
        <View style={styles.placeNameButtonsContainer}>
          {placeNameArray.map((item) => (
            <this.placeNameButton item={item} />
          ))}
        </View>
      </View>
    );
  };

  renderProgressBarInInputBox = () => {
    const { fetchPincodeInfo } = this.state;
    if (!fetchPincodeInfo) return null;
    return <ActivityIndicator size='small' color={colors.green} />;
    // return null;
  };

  moveToField = (fieldName) => {
    this.ref[fieldName]?.current?.focus();
  };

  onFocus = (fieldName, type) => {
    const {
      contact_name,
      addressLine1,
      addressLine2,
      phone_number,
      pincode,
      emailId,
    } = this.state;
    let fireEvent = false;
    switch (fieldName) {
      case this.addressConstants.textFieldConst.phone_num:
        fireEvent = Utility.isPresent(phone_number);
        this.scrollToInput(0);
        break;
      case this.addressConstants.textFieldConst.contact_name:
        fireEvent = Utility.isPresent(contact_name);
        this.scrollToInput(0);
        break;
      case this.addressConstants.textFieldConst.email:
        fireEvent = Utility.isPresent(emailId);
        this.scrollToInput(0);
        break;
      case this.addressConstants.textFieldConst.addressLine1:
        fireEvent = Utility.isPresent(addressLine1);
        this.scrollToInput(200);
        break;
      case this.addressConstants.textFieldConst.addressLine2:
        fireEvent = Utility.isPresent(addressLine2);
        this.scrollToInput(200);
        break;
      case this.addressConstants.textFieldConst.pincode:
        fireEvent = Utility.isPresent(pincode);
        this.scrollToInput(200);
        break;
      default:
        break;
    }

    if (type === 'typing') {
      fireEvent = true;
    }
    if (fireEvent) {
      AnalyticsManager.logEvent(EventType.profileEvents.ADDRESS_INPUT_START, {
        [EventParameterKey.FIELD_NAME]: fieldName,
        [EventParameterKey.TYPE]: type,
      });
    }
  };

  provinceFields = () => {
    const styles = AddressStyles;
    const { city, state } = this.state;
    return (
      <View style={styles.provinceContainer}>
        <View style={styles.provinceTextFieldContainer}>
          <TextField
            disabled
            placeholder={Config.ADDRESS_PAGE_PROVINCE_FIELD_1_LABEL || 'City'}
            placeholderTextColor={colors.silver}
            lineWidth={1}
            hideLabel={false}
            blurOnSubmit={false}
            value={city}
            renderAccessory={this.renderProgressBarInInputBox}
            inputContainerStyle={styles.provinceFieldBottomBorder}
            tintColor={colors.silver}
          />
        </View>
        <View style={styles.provinceFieldSeparator} />
        <View style={styles.provinceTextFieldContainer}>
          <TextField
            disabled
            placeholder={Config.ADDRESS_PAGE_PROVINCE_FIELD_2_LABEL || 'State'}
            lineWidth={1}
            placeholderTextColor={colors.silver}
            hideLabel={false}
            blurOnSubmit={false}
            value={state}
            renderAccessory={this.renderProgressBarInInputBox}
            inputContainerStyle={styles.provinceFieldBottomBorder}
            tintColor={colors.silver}
          />
        </View>
      </View>
    );
  };

  updateCountryCode = (selectedCountry) => {
    const { countryCode, callingCode = [] } = selectedCountry;
    this.setState({
      callingCode,
      countryCode,
    });
  };

  addEditAddressView = ({ showButton, address, shopifyAddresses }) => {
    const styles = AddressStyles;
    const {
      pincode = '',
      addressLine1,
      addressLine2,
      placeName,
      phone_number,
      line2Empty = false,
      line1Empty = false,
      line1CharacterLimitError,
      line2CharacterLimitError,
      phoneNumberEmpty,
      isPlaceNameEmpty,
      pincodeEmpty,
      nameEmpty,
      pincodeErrorText,
      isPincodeError,
      contact_name,
      city,
      state,
      emailId = '',
      isNotValidEmail,
      phoneInputCount,
      birthDate,
      isBirthDateEmpty = false,
      emailEmpty = false,
      isContactNameInvalid = false,
      callingCode,
      countryCode,
      showAddAddressScreen,
    } = this.state;
    const { date_of_birth } = this.props;
    let email = Utility.isPresent(emailId) ? emailId : '';
    let pincodeError = null;
    if (pincodeEmpty) {
      pincodeError = this.validationErrorConstants.pincodeEmptyError;
    } else if (isPincodeError) {
      pincodeError = pincodeErrorText;
    }
    const phoneNumber = phone_number || '';
    const isEmailValid = isNotValidEmail
      ? this.addressConstants.notAValidEmail
      : null;
    const emailError = emailEmpty
      ? this.validationErrorConstants.emailEmptyError
      : isEmailValid;
    const addressLine1CharacterLimitError = line1CharacterLimitError
      ? this.addressConstants.addressLimitError
      : null;
    const addressLine2CharacterLimitError = line2CharacterLimitError
      ? this.addressConstants.addressLimitError
      : null;
    const line1Error = line1Empty
      ? this.validationErrorConstants.line1EmptyError
      : addressLine1CharacterLimitError;
    const line2Error = line2Empty
      ? this.validationErrorConstants.line2EmptyError
      : addressLine2CharacterLimitError;
    const contactNameInvalidError = isContactNameInvalid
      ? this.addressConstants.isContactNameValid
      : null;
    const contactNameError = nameEmpty
      ? this.validationErrorConstants.nameEmptyError
      : contactNameInvalidError;

    const countryErrorText =
      isBlank(Config.ADDRESS_PAGE_TENANT_COUNTRY) ||
      Config.ADDRESS_PAGE_TENANT_COUNTRY === 'India'
        ? 'We only deliver within India at the moment'
        : '';
    return (
      <View
        style={[
          styles.keyboardAwareScrollView,
          {
            flex: 1,
            flexDirection: 'row',
            width: getScreenWidth(),
            alignSelf: 'center',
          },
        ]}
      >
        <View style={{ flex: 1 }}>
          {this.showBirthDate && <BirthDayInfoCard />}
          <Text
            style={{
              fontSize: 16,
              fontWeight: '500',
              fontFamily: 'Roboto-Bold',
              color: colors.foxyBlack,
              marginTop: 16,
              alignSelf: 'flex-start',
              marginLeft: 12,
            }}
          >
            Enter Delivery Details
          </Text>
          <ScrollView
            style={[
              styles.parentContainer,
              { paddingHorizontal: 12 },
              isDesktop() && {
                flex: null,
                paddingBottom: 24,
                width: '100%',
              },
            ]}
          >
            <PhoneNumberInput
              label={this.addressConstants.textFieldConst.phone_num}
              phoneNumber={phoneNumber}
              phoneInputCount={phoneInputCount}
              handleChangeText={this.addPhoneNumber}
              phoneRef={this.ref.phoneNumber}
              isMobileNoEmpty={phoneNumberEmpty}
              phoneNoError={this.validationErrorConstants.phoneNumberEmptyError}
              moveToField={this.moveToField}
              onFocus={this.onFocus}
              proceedWithAddressAdd={this.proceedWithAddressAdd}
              changeCountryCode={this.updateCountryCode}
              countryCode={countryCode}
              callingCode={callingCode}
            />
            <TextField
              label={this.addressConstants.textFieldConst.contact_name}
              lineWidth={1}
              activeLineWidth={1}
              blurOnSubmit={false}
              value={contact_name}
              onChangeText={this.addContactName}
              ref={this.ref.userName}
              hideLabel={false}
              error={contactNameError}
              onSubmitEditing={() => this.moveToField('email')}
              inputContainerStyle={{
                borderBottomColor: colors.border,
              }}
              textContentType='name'
              returnKeyType='next'
              autoCompleteType='username'
              onFocus={() =>
                this.onFocus(
                  this.addressConstants.textFieldConst.contact_name,
                  'autofill',
                )
              }
              enablesReturnKeyAutomatically
              tintColor={colors.silver}
            />
            <TextField
              label={this.addressConstants.textFieldConst.email}
              lineWidth={1}
              activeLineWidth={1}
              blurOnSubmit={false}
              value={email}
              onChangeText={this.addEmail}
              ref={this.ref.email}
              hideLabel={false}
              error={emailError}
              onSubmitEditing={() => this.moveToField('addressLine1')}
              inputContainerStyle={{ borderBottomColor: colors.border }}
              textContentType='emailAddress'
              returnKeyType='next'
              autoCompleteType='email'
              keyboardType='email-address'
              autoCapitalize={false}
              onFocus={() =>
                this.onFocus(
                  this.addressConstants.textFieldConst.email,
                  'autofill',
                )
              }
              enablesReturnKeyAutomatically
              tintColor={colors.silver}
            />

            {this.showBirthDate && Utility.isBlank(date_of_birth) && (
              <BirthDateTextField
                date={birthDate}
                onSubmit={this.addBirthDate}
                error={
                  isBirthDateEmpty ? this.addressConstants.dateOfBirth : null
                }
              />
            )}

            <Text
              style={{
                fontSize: 16,
                fontFamily: 'Roboto-Medium',
                color: colors.foxyBlack,
                marginTop: 16,
              }}
            >
              Shipping Address
            </Text>
            <TextField
              label={this.addressConstants.textFieldConst.addressLine1}
              lineWidth={1}
              activeLineWidth={1}
              blurOnSubmit={false}
              value={addressLine1}
              onChangeText={this.addAddress}
              ref={this.ref.addressLine1}
              hideLabel={false}
              onSubmitEditing={() => this.moveToField('addressLine2')}
              error={line1Error}
              inputContainerStyle={{ borderBottomColor: colors.border }}
              textContentType='streetAddressLine1'
              returnKeyType='next'
              autoCompleteType='street-address'
              onFocus={() => {
                this.onFocus(
                  this.addressConstants.textFieldConst.addressLine1,
                  'autofill',
                );
              }}
              enablesReturnKeyAutomatically
              tintColor={colors.silver}
            />
            <TextField
              label={this.addressConstants.textFieldConst.addressLine2}
              lineWidth={1}
              activeLineWidth={1}
              blurOnSubmit={false}
              value={addressLine2}
              onChangeText={this.addLocality}
              ref={this.ref.addressLine2}
              hideLabel={false}
              onSubmitEditing={() => this.moveToField('pincode')}
              inputContainerStyle={{ borderBottomColor: colors.border }}
              error={line2Error}
              textContentType='streetAddressLine2'
              returnKeyType='next'
              autoCompleteType='street-address'
              onFocus={() => {
                this.onFocus(
                  this.addressConstants.textFieldConst.addressLine2,
                  'autofill',
                );
              }}
              enablesReturnKeyAutomatically
              tintColor={colors.silver}
            />
            <TextField
              label={this.addressConstants.textFieldConst.pincode}
              lineWidth={1}
              blurOnSubmit={false}
              value={pincode.toString()}
              onChangeText={this.addPincode}
              maxLength={parseInt(Config.ADDRESS_PAGE_PINCODE_LIMIT || 6)}
              ref={this.ref.pincode}
              error={pincodeError}
              onSubmitEditing={this.onSaveAddressPress}
              hideLabel={false}
              inputContainerStyle={{ borderBottomColor: colors.border }}
              keyboardType={
                this.isInternationalChoiceEnabled ? undefined : 'numeric'
              }
              textContentType='postalCode'
              returnKeyType='done'
              onFocus={() =>
                this.onFocus(
                  this.addressConstants.textFieldConst.pincode,
                  'autofill',
                )
              }
              tintColor={colors.silver}
            />
            <this.provinceFields />
            <TextField
              disabled
              label='Country'
              lineWidth={1}
              blurOnSubmit={false}
              value={Config.ADDRESS_PAGE_TENANT_COUNTRY || 'India'}
              hideLabel={false}
              errorColor={colors.silver}
              error={countryErrorText}
              inputContainerStyle={{ borderBottomColor: colors.border }}
              tintColor={colors.silver}
            />
            <this.placeNameButtons />
            <View
              style={{
                height: Utility.isAndroid()
                  ? Utility.bottomInset + 80
                  : Utility.bottomInset,
              }}
            />
          </ScrollView>
          {(showAddAddressScreen ||
            (Utility.isBlank(address) &&
              Utility.isBlank(shopifyAddresses))) && (
            <this.bottomButton showButton={showButton} />
          )}
        </View>
        <this.cartPricingDetails />
      </View>
    );
  };

  editAddressCondition = (props) =>
    this.state.showAddAddressScreen === true ||
    this.state.addressToEdit !== '' ||
    this.previousScreen === SCREEN_CONSTANTS.PROFILE_EDIT ||
    (Utility.isBlank(props?.address) &&
      Utility.isBlank(props?.shopifyAddresses));

  addressView = withEither(
    this.editAddressCondition,
    this.addEditAddressView,
  )(this.selectAddressView);

  header = () => {
    const styles = AddressStyles;
    const { addressToEdit } = this.state;
    if (
      this.previousScreen === SCREEN_CONSTANTS.PROFILE_EDIT ||
      this.previousScreen === SCREEN_CONSTANTS.CONSUMER_PROFILE ||
      this.previousScreen === SCREEN_CONSTANTS.PRODUCT_DETAIL ||
      this.previousScreen === SCREEN_CONSTANTS.MY_PROFILE
    ) {
      return (
        <StaticNavigationHeader
          title={this.addressConstants.addressText[addressToEdit !== '']}
          customBack
          onBackPress={this.goBack}
        />
      );
    }
    return (
      <View style={styles.header}>
        <TouchableOpacity hitSlop={Utility.getHitSlop()} onPress={this.goBack}>
          <Image
            source={images.navigationWhiteBackIcon}
            style={styles.backArrow}
          />
        </TouchableOpacity>
        <Text style={styles.addressHeaderText}>
          {this.addressConstants.addressText[addressToEdit !== '']}
        </Text>
      </View>
    );
  };

  getAutocompletedAddressDetails = (data, details) => {
    const { address_components } = details;
    const lastComponent = address_components[address_components.length - 1];
    let pincode = lastComponent?.long_name;

    if (Utility.isBlank(data?.description)) {
      this.setState({ addressLine2: '', line2Empty: true });
      return;
    }
    this.setState({ addressLine2: data?.description, line2Empty: false });
    setTimeout(() => {
      if (lastComponent?.types[0] === 'postal_code') {
        this.addPincode(pincode, true);
      } else {
        pincode = null;
        this.moveToField('addressLine1');
      }
    }, 500);
    AnalyticsManager.logEvent(
      EventType.profileEvents.ADDRESS_SUGGESTION_SELECTED,
      {
        [EventParameterKey.VALUE]: data?.description,
        [EventParameterKey.PIN_CODE]: pincode,
      },
    );
  };

  addLocality = (addressLine2) => {
    this.setState({
      addressLine2,
      line2Empty: false,
      line2CharacterLimitError: false,
    });
  };

  bottomButton = (props) => {
    const { addressToEdit, isLoaderVisible } = this.state;
    const styles = AddressStyles;
    const keyboardOffset = Utility.isAndroid() ? 0 : 60;
    const keyboardBehaviour = Utility.isAndroid() ? 'position' : 'padding';
    const stylesDevice = Utility.isAndroid()
      ? styles.actionBottonContainer
      : styles.iosActionContainer;
    return (
      <this.customKeyboardAvoid
        behavior={keyboardBehaviour}
        enabled
        keyboardVerticalOffset={keyboardOffset}
      >
        <View
          style={[
            stylesDevice,
            {
              height: 66 + Utility.bottomInset,
              backgroundColor: 'rgba(255,255,255,0.8)',
            },
            isDesktop() && { width: '100%', bottom: 0 },
          ]}
        >
          <FoxyShadowButton
            title={
              Utility.isPresent(this.proceedTo)
                ? 'Proceed'
                : this.addressConstants.buttonText[addressToEdit !== '']
            }
            onPress={this.onSaveAddressPress}
            backgroundColor={colors.primaryActionBackgroundColor}
            style={[styles.saveAddressButton, { width: '98%' }]}
            Loading={isLoaderVisible}
            firstColor={colors.linearGradientGreenFirst}
            secondColor={colors.linearGradientGreenSecond}
            width='100%'
          />
        </View>
      </this.customKeyboardAvoid>
    );
  };

  onAddressBackPress = () => {
    const { email, address, date_of_birth = '', shopifyAddresses } = this.props;
    const {
      pincode,
      placeName = '',
      addressLine1,
      addressLine2,
      phone_number = '',
      addressToEdit,
      contact_name,
      city,
      state,
      emailId,
      birthDate,
    } = this.state;

    if (
      !this.state.showAddAddressScreen &&
      (Utility.isPresent(address) || Utility.isPresent(shopifyAddresses))
    ) {
      history.back();
      return;
    }

    // const data = {
    //   contact_name: contact_name.trim(),
    //   pincode,
    //   line1: addressLine1.trim(),
    //   phone_number: phone_number.trim(),
    //   name: placeName.trim(),
    //   id: addressToEdit,
    //   email: emailId.trim(),
    // };

    if (
      this.checkIfFieldIsMandatory('birthDate') &&
      this.showBirthDate &&
      Utility.isBlank(date_of_birth) &&
      birthDate === ''
    ) {
      this.setState({
        isBirthDateEmpty: true,
      });
      AnalyticsManager.logEvent(EventType.profileEvents.BIRTH_DATE_ERROR, {
        [EventParameterKey.ERROR]: this.addressConstants.dateOfBirth,
      });
    }

    if (this.checkIfFieldIsMandatory('phoneNumber') && phone_number === '') {
      this.setState({
        phoneNumberEmpty: true,
      });
      AnalyticsManager.logEvent(EventType.profileEvents.ADDRESS_ERROR, {
        [EventParameterKey.ERROR]:
          this.validationErrorConstants.phoneNumberEmptyError,
      });
    }

    if (phone_number !== '' && !this.phoneNumberLimitCheck()) {
      this.setState({
        phoneNumberEmpty: true,
      });
      AnalyticsManager.logEvent(EventType.profileEvents.ADDRESS_ERROR, {
        [EventParameterKey.ERROR]: this.addressConstants.notValidPhoneNumber,
      });
    }

    if (
      this.checkIfFieldIsMandatory('contactName') &&
      (contact_name === '' || this.isContactNameInvalid())
    ) {
      this.setState({
        nameEmpty: contact_name === '',
        isContactNameInvalid:
          contact_name !== '' && this.isContactNameInvalid(),
      });
      const contactNameError =
        contact_name !== '' && this.isContactNameInvalid()
          ? this.addressConstants.isContactNameValid
          : this.validationErrorConstants.nameEmptyError;
      AnalyticsManager.logEvent(EventType.profileEvents.ADDRESS_ERROR, {
        [EventParameterKey.ERROR]: contactNameError,
      });
    }

    if (this.checkIfFieldIsMandatory('emailId') && emailId === '') {
      this.setState({
        emailEmpty: true,
      });
      AnalyticsManager.logEvent(EventType.profileEvents.EMAIL_ERROR, {
        [EventParameterKey.ERROR]:
          this.validationErrorConstants.emailEmptyError,
      });
    }

    if (emailId.length > 0 && !this.validate(emailId)) {
      this.setState({
        isNotValidEmail: true,
      });
      AnalyticsManager.logEvent(EventType.profileEvents.EMAIL_ERROR, {
        [EventParameterKey.ERROR]: this.addressConstants.notAValidEmail,
      });
    }

    if (
      this.checkIfFieldIsMandatory('address') &&
      (addressLine1 === '' || this.addressCharacterLimitError(addressLine1))
    ) {
      this.setState({
        line1Empty: addressLine1 === '',
        line1CharacterLimitError: this.addressCharacterLimitError(addressLine1),
      });
      this.moveToFieldWithTimeout('addressLine1');
      const addressError = this.addressCharacterLimitError(addressLine1)
        ? this.addressConstants.addressLimitError
        : this.validationErrorConstants.line1EmptyError;
      AnalyticsManager.logEvent(EventType.profileEvents.ADDRESS_ERROR, {
        [EventParameterKey.ERROR]: addressError,
      });
    }

    if (addressLine2 === '' || this.addressCharacterLimitError(addressLine2)) {
      this.setState({
        line2Empty: addressLine2 === '',
        line2CharacterLimitError: this.addressCharacterLimitError(addressLine2),
      });
      this.moveToFieldWithTimeout('addressLine2');
      const addressError = this.addressCharacterLimitError(addressLine2)
        ? this.addressConstants.addressLimitError
        : this.validationErrorConstants.line2EmptyError;
      AnalyticsManager.logEvent(EventType.profileEvents.ADDRESS_ERROR, {
        [EventParameterKey.ERROR]: addressError,
      });
    }

    if (this.checkIfFieldIsMandatory('pincode') && pincode === '') {
      this.setState({
        pincodeEmpty: true,
      });
      AnalyticsManager.logEvent(EventType.profileEvents.ADDRESS_ERROR, {
        [EventParameterKey.ERROR]:
          this.validationErrorConstants.pincodeEmptyError,
      });
    }
    if (this.checkIfFieldIsMandatory('placeName') && placeName === '') {
      this.setState({
        isPlaceNameEmpty: true,
      });
      this.toast.show(this.addressConstants.blankNickName);
      AnalyticsManager.logEvent(EventType.profileEvents.ADDRESS_ERROR, {
        [EventParameterKey.ERROR]: this.addressConstants.blankNickName,
      });
    }

    if (
      (this.checkIfFieldIsMandatory('placeName') && placeName === '') ||
      (this.checkIfFieldIsMandatory('pincode') && pincode === '') ||
      (this.checkIfFieldIsMandatory('address') &&
        (addressLine1 === '' ||
          this.addressCharacterLimitError(addressLine1) ||
          this.addressCharacterLimitError(addressLine2))) ||
      (this.checkIfFieldIsMandatory('contactName') &&
        (contact_name === '' || this.isContactNameInvalid())) ||
      (phone_number !== '' && !this.phoneNumberLimitCheck()) ||
      (this.checkIfFieldIsMandatory('emailId') && email === '') ||
      (email.length > 0 && !this.validate(email))
    ) {
      Keyboard.dismiss();
      setTimeout(() => {
        this.setState({
          showEditingModal: true,
        });
      }, 50);

      return;
    }
    Keyboard.dismiss();
    setTimeout(() => {
      this.setState({
        showSaveModal: true,
      });
    }, 50);
  };

  onEditAddressBackPress = () => {
    this.sendEditAddressUserBackEvent('user_back_initiate');
    this.onAddressBackPress();
  }

  onBackPress = () => {
    this.onAddressBackPress();
  };

  closeSaveModal = () => {
    this.setState({
      showEditingModal: false,
    });
  };

  closeEditSaveModal = () => {
    this.sendEditAddressUserBackEvent('user_back_cancel_continue_editing');
    this.closeSaveModal();
  }

  deleteButton = () => {
    const { route } = this.props;
    const addressToEdit = route.params?.addressToEdit;
    const address = Utility.isPresent(addressToEdit)
      ? addressToEdit
      : this.addressSelected;
    return (
      <TouchableOpacity
        style={{
          position: 'absolute',
          fontFamily: 'Roboto-Regular',
          fontSize: 16,
          fontStyle: 'normal',
          color: colors.foxyBlack,
          bottom: Utility.isIOS() ? 16 : 18,
          right: 20,
          overflow: 'hidden',
        }}
        onPress={() => this.showDeleteAlert(address)}
        hitSlop={Utility.getHitSlop()}
      >
        <Image
          source={images.previewScreenIcons.delete}
          style={{ tintColor: 'black', height: 20, width: 20 }}
        />
      </TouchableOpacity>
    );
  };

  staticNavBarHeader = () => {
    const { navigation, route, address } = this.props;
    const { showDeleteButton, showAddAddressScreen } = this.state;
    const previousScreen = route.params?.previousScreen;
    const showDelete = route.params?.showDeleteButton;
    if (
      (previousScreen === SCREEN_CONSTANTS.CONSUMER_PROFILE && showDelete) ||
      showDeleteButton
    ) {
      return (
        <StaticNavigationHeader
          title='Edit Address'
          onBackPress={this.onEditAddressBackPress}
          showRightButton
          RightButton={this.deleteButton}
        />
      );
    }
    let title =
      showAddAddressScreen === true || Utility.isBlank(address)
        ? 'Enter delivery address'
        : 'Select delivery address';
    if (
      this.previousScreen === SCREEN_CONSTANTS.LOYALTY_PLANS ||
      this.previousScreen === SCREEN_CONSTANTS.NON_MEMBER_LANDING_PAGE
    ) {
      title = 'We’d like to know you better';
    }
    return (
      <StaticNavigationHeader
        title={title}
        onBackPress={this.onEditAddressBackPress}
      />
    );
  };

  closeDiscard = () => {
    this.setState({ showSaveModal: false });
    this.goBack();
  };

  scrollToInput(y) {
    // Add a 'scroll' ref to your ScrollView
    if (Utility.isAndroid()) {
      this.scrollViewRef.props.scrollToPosition(0, y);
    }
  }

  fireAddressInitiate = () => {
    const metaData = Utility.isPresent(this.previousScreen)
      ? { source: this.previousScreen }
      : {};
    AnalyticsManager.logEvent(
      EventType.profileEvents.ADDRESS_INITIATE,
      metaData,
    );
  };

  cartPricingDetails = () => {
    const { cartPricing, optForPlasticFreePackaging, isOfferShimmerVisible } =
      this.props;
    if (!isDesktop() || parseInt(cartPricing?.items_total || '0') === 0)
      return null;
    return (
      <View style={{ width: 320 }}>
        <CartPricing
          pricingData={cartPricing}
          optForPlasticFreePackaging={optForPlasticFreePackaging}
          isOfferShimmerVisible={isOfferShimmerVisible}
          isAddressPage
        />
      </View>
    );
  };

  sendEditAddressUserBackEvent = (type) => {
    const { addressToEdit = '' } = this.state;
    AnalyticsManager.logEvent(EventType.profileEvents.EDIT_ADDRESS_USER_BACK, {
      type,
      address_id: addressToEdit,
    });
  }

  conditionalFullScreenScrollView = withEither(isDesktop, View)(ScrollView);

  render() {
    const styles = AddressStyles;
    const { address, shopifyAddresses } = this.props;
    const {
      showAddAddressScreen,
      showAlert,
      showEditingModal,
      showSaveModal,
    } = this.state;
    //TODO: simplify the following check to previousScreen !== ''
    const marginTop =
      this.previousScreen === SCREEN_CONSTANTS.PROFILE_EDIT ||
      this.previousScreen === SCREEN_CONSTANTS.CONSUMER_PROFILE ||
      this.previousScreen === SCREEN_CONSTANTS.PRODUCT_DETAIL
        ? 0
        : 50;
    let showButton =
      this.previousScreen === SCREEN_CONSTANTS.PROFILE_EDIT ||
      showAddAddressScreen;
    showButton = true;

    return (
      <>
        <this.staticNavBarHeader />
        <this.conditionalFullScreenScrollView style={styles.parentContainer}>
          <this.addressView
            address={address}
            shopifyAddresses={shopifyAddresses}
            showButton={showButton}
          />

          <Toast
            ref={(ref) => {
              this.toast = ref;
            }}
            style={{ position: 'absolute', bottom: 70 }}
          />
        </this.conditionalFullScreenScrollView>
        <FoxyAlert
          isVisible={showAlert}
          alertBoxTitle={this.addressConstants.deleteHeader}
          alertMessage={this.addressConstants.removeAddressAlertText}
          firstButtonTitle={'Yes'}
          secondButtonTitle={'No'}
          firstButtonTextColor={colors.dodgerBluePressed}
          firstButtonOnPress={this.yesTappedInRemoveAddress}
          secondButtonOnPress={this.cancelTappedInRemoveAddress}
          textAlignment='left'
        />
        <FoxyAlert
          isVisible={showEditingModal}
          onTapOutside={this.closeSaveModal}
          alertBoxTitle='Are you sure you want to exit?'
          alertMessage={'Your changes will be lost'}
          firstButtonTitle='CONTINUE EDITING'
          secondButtonTitle='DISCARD '
          firstButtonOnPress={this.closeEditSaveModal}
          secondButtonOnPress={this.goBack}
          height={200}
          firstButtonTextColor={colors.foxyBlue}
          numOfLines={7}
          autoWrapContent
        />
        <FoxyAlert
          isVisible={showSaveModal}
          onTapOutside={this.closeSaveModal}
          alertBoxTitle='Are you sure you want to exit?'
          alertMessage={'Your changes will be lost'}
          firstButtonTitle='SAVE'
          secondButtonTitle='DISCARD '
          firstButtonOnPress={this.onSaveEditAddressPress}
          secondButtonOnPress={this.closeDiscard}
          height={200}
          firstButtonTextColor={colors.foxyBlue}
          numOfLines={7}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  address: state.UserAccountInfo.addresses,
  shopifyAddresses: state.UserAccountInfo.shopifyAddresses,
  email: state.UserAccountInfo.profile.email,
  user_name: state.UserAccountInfo.profile.name,
  authToken: state.UserAccountInfo.authToken,
  mobileNumber: state.UserAccountInfo.mobileNumber,
  cartData: state.bag?.cartData || {},
  userProfile: state.UserAccountInfo.profile,
  date_of_birth: state.UserAccountInfo.profile.date_of_birth,
  cartPricing: state.bag.cartPricing,
  optForPlasticFreePackaging: state.UserAccountInfo.optForPlasticFreePackaging,
  isOfferShimmerVisible: state.bag.isOfferShimmerVisible,
});

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(
      {
        addAddress,
        updateAddress,
        removeAddress,
        pincodeInfo,
        editProfile,
        getCartData,
        setDefaultAddresses,
        getCartPricing,
        updateMobileNumber,
        setCartNotification,
        changeCountryCode,
      },
      dispatch,
    ),
  };
}

export default withNavigation(
  connect(mapStateToProps, mapDispatchToProps)(Address),
);
