import React, { PureComponent } from 'react';
import { View, StyleSheet, FlatList, Image, Text } from 'react-native';
import { connect } from 'react-redux';
import colors from '../../theme/Colors';
import size from '../../theme/Fonts';
import images from '../../theme/Images';
import Utility from '../../utils/Utility';
import FastImageView from '../FastImageView';
import LeaderBoardCard from './LeaderBoardCard';
import MyLeaderBoardCard from './MyLeaderBoardCard';
import { map, slice } from 'lodash';
import ArrayUtility from '../../utils/ArrayUtility';
import { getMinifiedImage } from '../../utils/ImageUtility';

class LeaderBoard extends PureComponent {
  constructor(props) {
    super(props);
    this.setTopParticipantsDataExceptForTopThree();
  }

  setTopParticipantsDataExceptForTopThree = () => {
    const { leaderBoard } = this.props;
    this.topThreeParticipantsList = [
      leaderBoard[1],
      leaderBoard[0],
      leaderBoard[2],
    ];
    this.topParticipantsExceptForTopThree = slice(leaderBoard, 3);
  };

  renderHeader = () => {
    return (
      <View style={styles.headerContainer}>
        <FastImageView
          source={images.routines.tiltedTrophy}
          style={styles.headerImage}
        />
        <Text style={styles.headerText}>Leaderboard</Text>
      </View>
    );
  };

  renderLeaderboardCard = ({ item, index }) => {
    const { leaderBoard = [] } = this.props;
    const showDivider = index < leaderBoard.length - 1;
    return (
      <>
        <LeaderBoardCard data={item} />
        {showDivider && <View style={styles.divider} />}
      </>
    );
  };

  renderTopParticipants = () => {
    return (
      <FlatList
        data={this.topParticipantsExceptForTopThree}
        style={styles.leaderBoardList}
        renderItem={this.renderLeaderboardCard}
        showsVerticalScrollIndicator={false}
        bounces={false}
      />
    );
  };

  getMyData = (leaderBoard) => {
    const { myNumber } = this.props;
    const myData = leaderBoard.filter((item) => {
      return myNumber.includes(item.phoneNumber);
    });
    if (Utility.isPresent(myData) && myData.length > 0) {
      return myData[0];
    }
    return null;
  };

  renderItemTopThreeParticiants = (item = {}, index) => {
    const { imageUrl, name, rank, score } = item;
    const image = Utility.isBlank(imageUrl)
      ? images.for_you_placeholder.uri
      : item.imageUrl;
    return (
      <View style={index === 1 ? styles.rankOneCard : styles.topThreeCard}>
        <FastImageView
          style={styles.userImage}
          source={getMinifiedImage(
            image,
            styles.userImage.width,
            styles.userImage.height,
          )}
        />
        <Text style={styles.userName} numberOfLines={1} ellipsizeMode='clip'>
          {name}
        </Text>
        <Text style={styles.score}>{score}</Text>
        <FastImageView
          style={styles.rankMedal}
          source={images.medal[`${rank}_medal`]}
          resizeMode='contain'
        />
      </View>
    );
  };

  renderTopThreeParticipants = () => {
    return (
      <View style={styles.topThreeContainer}>
        {map(
          this.topThreeParticipantsList,
          this.renderItemTopThreeParticiants,
        )}
      </View>
    );
  };

  render() {
    const {
      leaderBoard = [],
      myRank,
      myRewardPoints,
      name = 'Me',
      profilePic = '',
    } = this.props;
    if (Utility.isBlank(leaderBoard)) return null;
    return (
      <View style={styles.container}>
        <this.renderHeader />
        <this.renderTopThreeParticipants />
        <MyLeaderBoardCard
          data={{
            name: Utility.isPresent(name) ? name : 'Me',
            score: myRewardPoints,
            rank: myRank,
            imageUrl: profilePic,
          }}
        /> 
        <this.renderTopParticipants />
      </View>
    );
  }
}
const mapStateToProps = (state, ownProps) => ({
  name: state.UserAccountInfo.profile.name,
  profilePic: state.UserAccountInfo.profile.selfie_image_url,
});

export default connect(mapStateToProps, null)(LeaderBoard);

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 12,
    flexDirection: 'column',
    marginTop: 24,
    marginBottom: 12,
  },
  headerContainer: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    height: 32,
    top: 0,
    marginBottom: 12,
  },
  leaderBoardList: {
    marginHorizontal: 4,
    borderRadius: 12,
    backgroundColor: 'white',
  },
  divider: {
    width: '100%',
    height: 1,
    backgroundColor: colors.background,
  },
  headerText: {
    color: colors.foxyBlack,
    fontSize: size.h0,
    fontFamily: 'Roboto-Medium',
    height: 24,
    marginLeft: 12,
  },
  headerImage: {
    height: 28,
    width: 28,
  },
  topThreeContainer: {
    alignSelf: 'center',
    flexDirection: 'row',
    height: 123 + 32,
    alignItems: 'flex-end',
  },
  topThreeCard: {
    backgroundColor: colors.white,
    width: Utility.getScreenWidth() / 3 - 24 - 32,
    height: 123,
    marginHorizontal: 16,
    borderRadius: 20,
  },
  userImage: {
    height: 70,
    width: Utility.getScreenWidth() / 3 - 24 - 36,
    alignSelf: 'center',
    borderRadius: 20,
    marginTop: 6,
    marginHorizontal: 6,
  },
  userName: {
    color: colors.foxyBlack,
    fontSize: 12,
    fontFamily: 'Roboto-Medium',
    alignSelf: 'center',
    marginTop: 4,
  },
  score: {
    color: colors.subText,
    fontSize: size.h4,
    fontFamily: 'Roboto-Medium',
    textAlign: 'center',
  },
  rankMedal: {
    height: 27,
    width: 27,
    position: 'absolute',
    top: -10,
    left: -10,
  },
  rankOneCard: {
    backgroundColor: colors.white,
    width: Utility.getScreenWidth() / 3 - 24 - 32,
    height: 123,
    marginHorizontal: 16,
    borderRadius: 20,
    marginBottom: 32,
  },
});
