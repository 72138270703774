// Dependencies
import React, { Component } from 'react';
import { View, TouchableOpacity, Text, Image } from 'react-native';
import StyleSheet from 'react-native-media-query';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ProductInfoStyles } from './styles';
import colors from '../../theme/Colors';
import {
  getCurrentPincode,
  getDeliveryTime,
  saveCurrentPincode,
  saveLastUsedAddress,
  getProductServiceability,
} from '../../actions/ActionTypes';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import GradientDivider from '../../utils/GradientDivider';
import images from '../../theme/Images';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import DeliveryInformation from '../cart/DeliveryInfo';
import TimeUtility from '../../utils/TimeUtility';
import { REMOTE_CONFIG_KEYS } from '../../config/Constants';
import RemoteConfig from '../../utils/RemoteConfig';
import AppConfig from '../../config/AppConfig';
import Config from '../../libraries/ReactNativeConfig';
import { isBlank, isPresent } from '../../utils/BooleanUtility';
import ChangePincodeModal from './ChangePincodeModal';
import { PRODUCT_RIGHT_CONTAINER_WIDTH } from '../../config/LayoutConstants/ProductConfig';
import { CartStyles } from '../cart/styles';
import size from '../../theme/Fonts';

class ProductDelivery extends Component {
  productDeliveryConstants = {
    deliveryCardHeader: 'Delivery Info',
    returnPolicyButtonText: 'See Policy',
    returnText: {
      true: 'Return Pickup Available',
      false: 'Return Pickup Not Available',
    },
    codAvailabilityText: {
      true: 'COD Available',
      false: 'COD Not Available',
    },
  };

  constructor(props) {
    super(props);
    this.state = {
      deliveryDurationMessage: 'Usually delivered in 5-6 days',
      deliveryTimeText: '',
      isChangePincodeModalVisible: false,
      deliveryTime: '',
      codAvailable: true,
      returnPickupAvailable: true,
      delivery: true,
    };
    this.emptyAddressDeliveryData = JSON.parse(
      RemoteConfig.getValue(
        REMOTE_CONFIG_KEYS.product_page_empty_address_delivery_data,
      ),
    );
  }

  componentDidMount() {
    const { addresses } = this.props;
    if (isPresent(addresses)) {
      const pincode = this.getDefaultAddress().pincode;
      this.hitAPIForDeliveryTime(pincode);
    } else {
      this.hitGetCurrentPinAPI();
    }
  }

  componentDidUpdate(prevProps) {
    const { addresses: prevAddresses = {} } = prevProps;
    // To update tats message when a new or guest user adds an address.
    const { addresses } = this.props;
    if (isBlank(prevAddresses) && isPresent(addresses)) {
      const defaultAddress = this.getDefaultAddress();
      this.hitAPIForDeliveryTime(defaultAddress.pincode);
    }
    return true;
  }

  getServiceabilityData = (pincode) => {
    const { getProductServiceability } = this.props;
    getProductServiceability(pincode, (success, data) => {
      if (success) {
        this.setState({
          codAvailable: data.cod_available,
          returnPickupAvailable: data.return_available,
        });
      }
    });
  };

  hitGetCurrentPinAPI = () => {
    const { getCurrentPincode } = this.props;
    getCurrentPincode((success, response) => {
      this.hitAPIForDeliveryTime(response.pincode || '110011');
    });
  };

  saveCurrentPincodeToRedux = (pincode) => {
    const { saveCurrentPincode } = this.props;
    const data = { pincode };
    saveCurrentPincode(data);
  };

  hitAPIForDeliveryTime = (pincode) => {
    const { getDeliveryTime, skuId } = this.props;
    getDeliveryTime(pincode, skuId, (success, response) => {
      if (success) {
        const {
          deliveryTime,
          delivery,
          delivery_duration_message: deliveryDurationMessage,
        } = response || {};
        this.setState({
          deliveryTime,
          delivery,
          deliveryDurationMessage,
        });
      }
    });
    this.getServiceabilityData(pincode);
  };

  smallSeparator = () => <View style={ProductInfoStyles.smallSeparator} />;

  togglePincodeModal = () => {
    this.setState((prevState) => ({
      isChangePincodeModalVisible: !prevState.isChangePincodeModalVisible,
    }));
  };

  navigateToAddress = () => {
    const { navigation } = this.props;
    navigation.navigate('Address', {
      previousScreen: SCREEN_CONSTANTS.PRODUCT_DETAIL,
      selectAddress: this.selectAddress,
    });
  };

  selectAddress = (address) => {
    // this funtion is called when used changes address from product page,
    // therefore we need to get delivery time based on new pincode
    const { saveLastUsedAddress } = this.props;
    saveLastUsedAddress(address);
    this.hitAPIForDeliveryTime(address.pincode);
  };

  getDefaultAddress = () => {
    const { addresses } = this.props;
    if (isBlank(addresses)) {
      return {};
    }

    const addressArray = Object.keys(addresses).map((key) => ({
      id: key,
      name: addresses[key].name,
      pincode: addresses[key].pincode,
      line1: addresses[key].line1,
      line2: addresses[key].line2,
      default: addresses[key].default,
      contact_name: addresses[key].contact_name,
    }));
    let defaultAddress = addressArray.filter((item) => item.default);
    if (Utility.isBlank(defaultAddress)) {
      defaultAddress = addressArray.splice(0, 1);
    }
    return defaultAddress[0] || {};
  }

  deliveryLocation = () => {
    const { currentCity, addresses } = this.props;
    const { message = '' } = this.emptyAddressDeliveryData;

    let textToShowForDeliveryLocation = currentCity;

    let locationIcon = images.other_location;
    if (Utility.isBlank(addresses)) {
      textToShowForDeliveryLocation = message;
    } else {
      const defaultAddress = this.getDefaultAddress();
      locationIcon = Utility.getAddressIcon(defaultAddress.name);
      if (Utility.isBlank(defaultAddress[0]?.line2)) {
        textToShowForDeliveryLocation = `${defaultAddress.contact_name}: ${defaultAddress.line1}, ${defaultAddress.pincode}`;
      } else {
        textToShowForDeliveryLocation = `${defaultAddress.contact_name} : ${defaultAddress.line1}, ${defaultAddress.line2}, ${defaultAddress.pincode}`;
      }
    }

    return (
      <View style={styles.deliveryLocationContainer}>
        <Image
          source={locationIcon}
          style={ProductInfoStyles.deliveryInfoIcon}
        />
        <Text ellipsizeMode='tail' numberOfLines={3} style={styles.statsTextStyle}>
          {textToShowForDeliveryLocation}
        </Text>
      </View>
    );
  };

  returnPolicyButton = () => {
    const styles = ProductInfoStyles;
    const { toggleReturnModal } = this.props;
    return (
      <TouchableOpacity onPress={toggleReturnModal}>
        <Text
          style={{
            fontFamily: 'Roboto-Regular',
            fontSize: 12,
            paddingLeft: 5,
            color: '#4990e2',
          }}
        >
          {this.productDeliveryConstants.returnPolicyButtonText}
        </Text>
      </TouchableOpacity>
    );
  };

  deliveryStats = () => {
    const { deliveryDurationMessage } = this.state;
    const { addresses, orderCampaign = {} } = this.props;
    if (isBlank(addresses)) {
      return (
        <View style={styles.deliveryStatsEmptyContainer}>
          <Image
            source={images.foxyPromise.fastDelivery}
            style={CartStyles.truckImage}
          />
          <Text style={styles.durationTextStyle} allowFontScaling={false}>
            {deliveryDurationMessage}
          </Text>
        </View>
      );
    }
    return (
      <View style={styles.deliveryStatsContainer}>
        <DeliveryInformation
          address={addresses}
          fromAddress
          orderCampaign={orderCampaign}
          getShipmentText={deliveryDurationMessage}
        />
      </View>
    );
  };

  addressCta = () => {
    const { addresses = {} } = this.props;
    const addressText = Utility.isBlank(addresses)
      ? 'Add address'
      : 'Change address';
    return (
      <TouchableOpacity
        style={styles.ctaContainer}
        onPress={this.navigateToAddress}
        hitSlop={Utility.getHitSlop()}
      >
        <Text
          style={styles.ctaText}
        >
          {addressText}
        </Text>
      </TouchableOpacity>
    );
  }

  render() {
    const { productPage = {} } = this.props;
    const productStyles = ProductInfoStyles;
    if (AppConfig.getBooleanValue(Config.HIDE_PRODUCT_DELIVERY_IN_PRODUCT_PAGE)) {
      return null;
    }
    return (
      <>
        <View style={productStyles.deliveryAndReturnsContainer}>
          <Text style={productStyles.productInfoCardHeaderText}>
            {productPage?.address?.heading || ''}
          </Text>
          <View style={styles.deliverAndReturnsCard} dataSet={{ media: ids.deliverAndReturnsCard }}>
            <View style={styles.textContainer}>
              <this.deliveryStats />
              <this.deliveryLocation />
            </View>
            <View style={styles.addressCtaContainer}>
              <this.addressCta />
            </View>
          </View>
        </View>
        <ChangePincodeModal
          isVisible={this.state.isChangePincodeModalVisible}
          togglePincodeModal={this.togglePincodeModal}
          hitPincodeAPI={this.hitAPIForDeliveryTime}
          saveCurrentPincodeToRedux={this.saveCurrentPincodeToRedux}
        />
      </>
    );
  }
}

const mapStateToProps = (store, ownProps) => ({
  currentPincode: store.UserAccountInfo.currentPincode,
  currentCity: store.UserAccountInfo.currentCity,
  lastUsedAddress: store.UserAccountInfo.lastUsedAddress,
  addresses: store.UserAccountInfo.addresses,
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      getCurrentPincode,
      getDeliveryTime,
      saveCurrentPincode,
      saveLastUsedAddress,
      getProductServiceability,
    },
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withNavigation(ProductDelivery));

const { ids, styles } = StyleSheet.create({
  deliverAndReturnsCard: {
    backgroundColor: colors.white,
    width: Utility.getScreenWidth(),
    flex: 5,
    flexDirection: 'row',
    padding: 14,
    '@media (min-width: 768px)': {
      width: PRODUCT_RIGHT_CONTAINER_WIDTH,
    },
  },
  deliveryLocationContainer: {
    flexDirection: 'row',
    // alignSelf: 'center',
    marginTop: 4,
  },
  textContainer: { flex: 1, flexDirection: 'column' },
  addressCtaContainer: {
    padding: 2,
    justifyContent: 'center',
    alignItems: 'center',
  },
  deliveryStatsContainer: {
    flexDirection: 'column',
  },
  ctaContainer: {
    alignSelf: 'flex-end',
    padding: 5,
    alignItems: 'center',
    justifyContent: 'center',
    borderColor: colors.cta.lightBlue,
    borderWidth: 1,
    borderRadius: 4,
  },
  ctaText: {
    fontFamily: 'Roboto-Medium',
    fontSize: 14,
    color: colors.cta.lightBlue,
  },
  preOrderContainer: { flexDirection: 'row', marginLeft: 4 },
  preOrderText1: {
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
    color: colors.foxyBlack,
  },
  preOrderText2: {
    fontFamily: 'Roboto-Bold',
    fontSize: 14,
    color: colors.foxyBlack,
    marginTop: Utility.isIOS() ? 2 : 0,
  },
  durationTextContainer: {
    flexDirection: 'row',
    marginBottom: 8,
  },
  durationTextStyle: {
    fontFamily: 'Roboto-Regular',
    fontSize: size.h3,
    color: colors.foxyBlack,
    flexWrap: 'wrap',
    flex: 1,
  },
  statsTextStyle: {
    fontFamily: 'Roboto-Regular',
    fontSize: size.h3,
    color: colors.foxyBlack,
    flexWrap: 'wrap',
    flex: 1,
    letterSpacing: 0,
    marginLeft: 8,
  },
  deliveryStatsEmptyContainer: { flexDirection: 'row', marginBottom: 8 },
});
