import React, { Component } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getListDetails } from '../../../actions/ActionTypes';
import { getRoutineSectionLists } from '../../../actions/RoutineActions';
import { SCREEN_CONSTANTS } from '../../../config/ScreenConstants';
import { List } from '../../../containers/List';
import { Header } from '../../header';

class RoutineActivityList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      itemData: {},
    };
    const { getListDetails, slug, listId } = this.props;
    getListDetails(listId, (success, response) => {
      this.setState({
        itemData: response,
      });
    });
  }

  render() {
    const { itemData = {} } = this.state;
    const {
      listId = '',
      onActionPress = () => {},
      itemData: { id: routineActivityId },
      navigation,
      tabListWidth,
    } = this.props;
    return (
      <View>
        <View style={styles.listHeaderStyle}>
          <Header
            item={itemData}
            navigation={navigation}
            layout={itemData.display}
            previousScreen={'previousScreen'}
            hideTopMargin
            // isVisible = {item.type !== 'quick_filters'}
          />
        </View>
        <List
          itemData={itemData}
          id={listId}
          previousScreen={SCREEN_CONSTANTS.DURING_ROUTINE}
          shimmerDisplay={'mediaRail'}
          onItemPress={onActionPress}
          routineActivityId={routineActivityId}
          useInViewPort={true}
          tabListWidth={tabListWidth}
        />
      </View>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      getRoutineSectionLists,
      getListDetails,
    },
    dispatch,
  ),
});

const styles = StyleSheet.create({
  listHeaderStyle: {
    marginLeft: -10,
  },
});

export default connect(null, mapDispatchToProps)(RoutineActivityList);
