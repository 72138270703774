import React, { PureComponent } from 'react';
import { View } from 'react-native';
import ScaleAnimate from '../shared/ScaleAnimate';
import _ from 'lodash';
import DynamicLinkManager from '../../utils/DynamicLinkManager';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import AnalyticsUtility from '../../analytics/AnalyticsUtility';
import BannerDescription from './BannerDescription';
import { ids, styles } from './styles';
import FastImageView from '../FastImageView';
import { isDesktop } from '../../utils/BooleanUtility';
import { getScreenWidth } from '../../utils/LayoutUtility';
import { navigateToScreen } from '../../utils/NavigationUtility';
import { getMinifiedImage } from '../../utils/ImageUtility';
import withProfiledNavigation from '../../utils/withProfiledNavigation';
import { getNavigationSource } from '../../utils/PerfUtility';

class BigBanner extends PureComponent {
  constructor(props) {
    super(props);
    const { itemData = {}, itemData: { large_banner: image_url = '' } = {} } =
      this.props;
    this.debouncedNavigate = _.debounce(this.onActionPressed, 2000, {
      leading: true,
      trailing: false,
    });
    const imageHeight = styles.largeBannerImage.height;
    const imageWidth = styles.largeBannerImage.width;
    this.newImageUrl = getMinifiedImage(
      image_url,
      imageWidth,
      imageHeight,
    );
    if (Utility.isBlank(image_url)) {
      this.newImageUrl = getMinifiedImage(
        itemData?.big_banner,
        imageWidth,
        imageHeight,
      );
    }
    this.navigationSource = getNavigationSource(this, this.props);
  }

  navigateToScreen = (path, destination) => {
    const { navigation } = this.props;

    navigation.navigate(this.navigationSource, path, {
      destination, // webview reads data from destination
      slug: destination,
    });
  };

  handleRouteFromLink = (route, slug, path, extra) => {
    const { navigation } = this.props;
    navigation.navigate(this.navigationSource, route, { slug, extra });
  };

  // For navigation within app if url includes foxy.in
  onActionPressed = () => {
    const {
      navigation,
      itemData,
      previousScreen = '',
      listId = '',
      listIndex,
      listContent = '',
      index,
      listName = '',
      listSlug = '',
    } = this.props;

    const { id, slug, type, destination } = itemData;
    const promptData = {
      type: 'list',
      id,
      offer: { slug },
      previousScreen,
      index: listIndex,
    };
    navigateToScreen({
      navigation,
      type: 'push',
      screen: 'OfferDetail',
      navigationSource: this.navigationSource,
      params: { promptData },
    });

    const url = Utility.isPresent(slug) ? slug : destination;

    const listNames = url.split('/')[url.split('/').length - 1];

    if (Utility.isPresent(itemData)) {
      const { id, type } = itemData;
      AnalyticsUtility.fireItemClickEvent(
        previousScreen,
        id,
        type,
        listNames,
        index,
        listId,
        'big_banner',
        listName,
        listIndex,
        '',
        '',
        listContent,
        '',
        itemData.slug,
        listSlug,
      );
    }

    // this.fireVideoCtaClickEvent('gif', this.ctaAction);
  };

  render() {
    const {
      itemData: { name, short_description, cta_text, cta_color } = {},
      isPair = false,
    } =
      this.props;
    const extraDesktopStyles = isPair
      ? { width: getScreenWidth() / 2 }
      : { backgroundColor: cta_color, width: getScreenWidth() };

    return (
      <ScaleAnimate {...this.props} onPress={this.debouncedNavigate}>
        <View
          style={[styles.flexContainer, isDesktop() && extraDesktopStyles]}
          dataSet={{ media: ids.flexContainer }}
        >
          <View style={styles.largeBanner} dataSet={{ media: ids.largeBanner }}>
            <FastImageView
              source={this.newImageUrl}
              style={styles.largeBannerImage}
              height={isDesktop() ? (Utility.getScreenWidth() - 24) / 1.7 / 2 : undefined}
            />

            <BannerDescription
              heading={name}
              subHeading={short_description}
              buttonText={cta_text}
              ctaColor={cta_color}
              onPress={this.debouncedNavigate}
              style={styles.bannerDescriptionStyle}
            />
          </View>
        </View>
      </ScaleAnimate>
    );
  }
}

export default withProfiledNavigation(BigBanner);
