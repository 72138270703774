import React, { Fragment } from 'react';

export const ProgressBarAndroid = ({ children }) => <Fragment>{children}</Fragment>;
export const ProgressViewIOS = ({ children }) => <Fragment>{children}</Fragment>;

const alert = (title, description, options, extra) => {
  const result = window.confirm(
    [title, description].filter(Boolean).join('\n'),
  );

  if (result) {
    const confirmOption = options.find(({ style }) => style !== 'cancel');
    confirmOption && confirmOption.onPress();
  } else {
    const cancelOption = options.find(({ style }) => style === 'cancel');
    cancelOption && cancelOption.onPress();
  }
};

export const Alert = { alert };
export const PermissionsAndroid = { PERMISSIONS: {} };
