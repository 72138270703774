import React, { useCallback } from 'react';
import { TouchableOpacity } from 'react-native';
import { debounce } from 'lodash';
import EdgeDebouncedTouchableOpacity from './EdgeDebouncedTouchableOpacity';

const edgeScreens = ['foxy_edge_non_member', 'foxy_edge_expired'];
const DebouncedTouchableOpacity = (props) => {
  const {
    onPress = () => {},
    children,
    disabled = false,
    previousScreen,
    listData: { options: { is_tappable: isTappable = false } = {} } = {},
  } = props;

  const debouncedOnPress = useCallback(
    debounce(onPress, 150, { leading: true, trailing: false }),
    [onPress],
  );

  if (edgeScreens.includes(previousScreen) && !isTappable) {
    return <EdgeDebouncedTouchableOpacity {...props} />;
  }

  return (
    <TouchableOpacity {...props} onPress={debouncedOnPress} disabled={disabled}>
      {children}
    </TouchableOpacity>
  );
};

export default DebouncedTouchableOpacity;
