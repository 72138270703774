import React, { Component } from 'react';
import { View, StatusBar, BackHandler } from 'react-native';
import { connect } from 'react-redux';
import _ from 'lodash';
import { denormalize } from 'normalizr';
import { compose } from 'recompose';
import { bindActionCreators } from 'redux';
import {
  FOXY_URLS,
  LAYOUT,
  LISTS_API_VERSION,
  EMPTY_ARRAY,
  EMPTY_OBJECT,
} from '../../config/Constants';
import { fetchCategory, fetchList } from '../../actions/ActionTypes';
import Utility from '../../utils/Utility';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import { AnalyticsManager } from '../../analytics';
import FilterUtils, { canOpenSortingModal } from '../../components/sort-filter-options/FilterUtils';
import SortingFilteringModal from '../../components/sort-filter-options/SortingFilteringModal';
import ScreenPlaceholder from '../../components/shared/ScreenPlaceholder';
import ServerError from '../../components/shared/ServerError';
import PageNotFound from '../../components/shared/PageNotFound';
import ErrorBoundary from '../../components/shared/ErrorBoundary';
import CategoryDetail from './CategoryDetail';
import AnalyticsUtility from '../../analytics/AnalyticsUtility';
import { getCategoryUrlParamsObject, getFullSlugFromName, nestedParamToName } from '../../utils/StringUtility';
import { isWeb, isPresent } from '../../utils/BooleanUtility';
import AppInstallPrompts from '../../components/shared/AppInstallPrompts';

class Category extends Component {
  constructor(props) {
    super(props);
    Utility.setPageLoadStart('category_detail');
    this.state = {
      categoryData: {},
      showShimmer: true,
      stickyPosition: -1,
      selectedFilters: {},
      filters: [],
      quickFilters: [],
      filtersEnabled: false,
      filteredSlug: '',
      isPageLoading: true,
      serverError: false,
      hideOosProduct: false,
      filtersList: {},
    };

    const { route } = this.props;
    this.property = route.params?.property;
    this.propertyName = route.params?.propertyName;
    this.slug = '';
    this.prevAppliedFiltersFromModal = [];
    this.selectedQuickFilters = [];
    this.quickFiltersRef = null;
    this.fnAutoListPagination = null;
    if (Utility.isAndroid()) {
      this.backHandler = BackHandler.addEventListener(
        'hardwareBackPress',
        this.goBack,
      );
    }
  }

  componentDidMount() {
    this.getSlugAndHitApi();
    this.fireListRenderedEvent();
  }

  componentWillUnmount() {
    if (Utility.isAndroid()) {
      this.backHandler?.remove();
    }
  }

  getSlugAndHitApi = () => {
    const { layout } = this.props;
    if (layout === undefined) {
      let slugString = this.getRouteSlug();
      slugString = slugString.replace(/\/v\d+\//, `/${LISTS_API_VERSION}/`);

      if (Utility.isPresent(this.property)) {
        if (slugString.indexOf('?') === -1) {
          slugString += `?property_values[]=${this.property}`;
        } else {
          slugString += `&property_values[]=${this.property}`;
        }
      }
      this.hitApiCall(slugString);
    }
  };

  onRefresh = () => {
    isPresent(this.quickFiltersRef) && this.quickFiltersRef.resetQuickFilters();
    this.setState({
      selectedFilters: {}, //TODO: Need to debug this why it's not working for EMPTY_OBJECT Value
      filteredSlug: '',
    });
    this.filtersEnabled = false;
    this.selectedQuickFilters = [];
    this.getSlugAndHitApi();
  };

  goBack = () => {
    const { navigation, route } = this.props;
    if (isPresent(route.params.first)) {
      navigation.replace('TabNavigator');
      return;
    }
    navigation.goBack();
    return true;
  };

  fireListRenderedEvent = () => {
    const { itemData } = this.props;
    Utility.fireListRenderedEvent(itemData, SCREEN_CONSTANTS.CATEGORY);
  };

  fetchCategoryCallBack = (success, data, status) => {
    this.setState({
      showShimmer: false,
    });
    if (success) {
      this.addFiltersObjectIntoListData(data);
    } else {
      const { route: { params: { query = '' } = {} } = {} } = this.props;
      AnalyticsManager.logEvent('fetch_category_fail', {
        query,
      });
      Utility.clearPageLoadTimer();
      this.setState({
        isPageLoading: false,
        serverError: status !== 404,
      });
    }
  };

  firePageLoadEnd = () => {
    Utility.setPageLoadEnd(SCREEN_CONSTANTS.CATEGORY);
  };

  hitApiCall = (slug) => {
    this.setState({
      showShimmer: true,
    });
    const { itemData, fetchCategory } = this.props;
    AnalyticsUtility.recordTime(
      {
        screen_name: SCREEN_CONSTANTS.CATEGORY,
        ...Utility.setRecordTimeEventMeta(itemData, 'category'),
      },
      this.fetchCategoryCallBack,
      fetchCategory,
      { slug },
    );
  };

  setListPaginatedCallApiRef = (fnAutoListPagination) => {
    this.fnAutoListPagination = fnAutoListPagination;
  };

  addFiltersObjectIntoListData = (apiResponse) => {
    let response = apiResponse;
    const {
      list: objects,
      position: stickyPosition,
      slug,
    } = Utility.createFilterObjectIfExists(
      apiResponse.list.objects,
      true,
      false,
      true,
    );
    this.slug = slug;
    response.list.objects = objects;
    const { filters, quickFilters } = FilterUtils.getFiltersFromList(
      response.list,
    );

    const updatedQuickFilters = quickFilters;
    const updatedSelectedFilters = {};
    let filteredSlug = '';

    if (isPresent(this.property)) {
      const indexOfFiltersList = _.findIndex(
        objects,
        (e) => e.type === 'quick_filters',
      );
      response.list.objects[indexOfFiltersList].quick_filters = [
        Utility.createPreSelectedFilter(this.property, this.propertyName),
        ...updatedQuickFilters,
      ];
      filteredSlug = response.list.objects[indexOfFiltersList].slug;
      if (filteredSlug.indexOf('?') === -1) {
        filteredSlug += `?property_values[]=${this.property}`;
      } else {
        filteredSlug += `&property_values[]=${this.property}`;
      }
      updatedSelectedFilters.property_values = [{
        count: 0,
        criteria_name: 'property_values',
        name: this.propertyName,
        value: this.property,
        selected: true,
      }]
    }

    this.setState({
      categoryData: response,
      filters,
      quickFilters: [...updatedQuickFilters],
      showShimmer: false,
      stickyPosition,
      selectedFilters: updatedSelectedFilters,
      filteredSlug,
    });
  };

  onFiltersPress = (
    item,
    added,
    index,
    resetQuickFilter,
    hideOosProduct = this.state.hideOosProduct,
  ) => {
    const { navigation } = this.props;
    const {
      categoryData,
      categoryData: { id = '', name = '', slug: brandSlug = '' },
      selectedFilters,
      filters,
    } = this.state;
    const { list } = FilterUtils.getList(categoryData.list);
    this.setState({
      filtersList: list,
    });

    const shouldOpenSortingModal = canOpenSortingModal(item, selectedFilters);
    if (shouldOpenSortingModal) {
      navigation.navigate('SortingFilteringModal', {
        selectedFilters,
        modalFilters: filters,
        onFilterApplyPress: this.onFiltersPress,
        listName: name,
        listId: id,
        listSlug: brandSlug,
        screenName: SCREEN_CONSTANTS.CATEGORY,
        listData: categoryData,
        entityName: name,
        onPressHideOosCheckBox: this.onPressHideOosCheckBox,
        hideOosProduct,
        showFoxyMatchModal: item.criteria_name === 'quick_filter_foxy_match',
        resetQuickFilter,
      });
      return;
    }

    const {
      slug,
      pageNo,
      allowNextPageLoad,
      filtersEnabled,
      groupedSelectedFilters,
      selectedQuickFilters,
      prevAppliedFiltersFromModal,
    } = FilterUtils.onFilterApplied(
      item,
      added,
      index,
      list,
      selectedFilters,
      this.quickFiltersRef,
      this.slug,
      this.prevAppliedFiltersFromModal,
      this.selectedQuickFilters,
      SCREEN_CONSTANTS.CATEGORY,
      name,
      hideOosProduct,
    );

    this.slug = slug;
    this.page = pageNo;
    this.allowNextPageLoad = allowNextPageLoad;
    this.selectedQuickFilters = selectedQuickFilters;
    this.filtersEnabled = filtersEnabled;
    this.prevAppliedFiltersFromModal = prevAppliedFiltersFromModal;
    categoryData.list.objects = Utility.addShimmerInListObject(
      categoryData.list,
    );
    this.filtersEnabled = this.slug.includes('&');
    this.setState({
      selectedFilters: groupedSelectedFilters,
      categoryData,
      filteredSlug: this.slug,
    });

    this.fetchFilteredList(this.slug, hideOosProduct);
  };

  getQuickFiltersListRef = (ref) => {
    this.quickFiltersRef = ref;
  };

  onPressHideOosCheckBox = (isHideOos) => {
    this.setState({
      hideOosProduct: isHideOos,
    });
  };

  resetFilters = () => {
    const { navigation } = this.props;
    const { hideOosProduct } = this.state;
    this.page = 0;
    this.filtersEnabled = false;
    this.slug = this.slug.substr(0, this.slug.indexOf('&'));
        Utility.isPresent(this.quickFiltersRef) &&
      this.quickFiltersRef.resetQuickFilters();
    this.setState({
      selectedFilters: {},
      filteredSlug: '',
    });
    this.selectedQuickFilters = [];

    this.fetchFilteredList(this.slug, hideOosProduct);
  };

  fetchFilteredList = (slug, hideOosProduct) => {
    const { fetchList } = this.props;
    this.page = 0;
    // FIXME: Buggy Code, need to fix
    fetchList({ slug, hideOosProduct }, this.page, (success, data) => {
      const { categoryData } = this.state;
      const paginateLastItem = categoryData.list.paginate_last_item;
      const listData = FilterUtils.appendFilteredListToMasterListObjects(
        slug,
        data,
        categoryData.list,
      );
      categoryData.list = listData;
      this.setState(
        {
          categoryData: { ...categoryData }, // spreading : this will create a new object ref and will re-render component. Otherwise we are just changing list of main object. Id of previous object will be same and component will not get re-render
        },
        // this.renderPaginationList,
      );
    });
  };

  renderPaginationList = () => {
    const { categoryData = {} } = this.state;
    const paginateLastItem = categoryData.list.paginate_last_item;
    if (paginateLastItem) {
      this.fnAutoListPagination(this.slug, -1, true);
    }
  };

  itemDataNullFn = (props) => !props.itemData;

  isLayoutScreenFn = (props) => props.layout === 'screen';

  renderScreenPlaceHolder = () => {
    const { showShimmer, isPageLoading, categoryData } = this.state;
    if (!showShimmer) {
      return null;
    }
    return (
      <ScreenPlaceholder fullscreen firePageLoadEnd={this.firePageLoadEnd} />
    );
  };

  getRouteSlug = () => {
    const { route = {} } = this.props;
    const { params = {} } = route;
    const {
      slug,
      first = '',
      second = '',
      third = '',
    } = params;
    return slug || getFullSlugFromName(nestedParamToName([first, second, third]), 'categories');
  }

  render() {
    const {
      navigation,
      route,
      itemData,
      isLoading,
      toggleCartVisibility,
      onPressResetButton,
    } = this.props;
    const {
      categoryData,
      showShimmer,
      isPageLoading,
      serverError,
      hideOosProduct,
      filtersList = {},
    } = this.state;
    let { layout } = this.props;
    layout = layout || (route.params?.display ?? LAYOUT.SCREEN);
    return (
      <>
        <this.renderScreenPlaceHolder />
        {!showShimmer && (
          <>
            {isPresent(categoryData) && (
              <CategoryDetail
                {...this.props}
                categoryData={categoryData}
                id={categoryData.id}
                layout={layout}
                isLoading={showShimmer} // for placeholder
                toggleCartVisibility={toggleCartVisibility}
                stickyPosition={this.state.stickyPosition}
                selectedFilters={this.state.selectedFilters}
                onFiltersPress={this.onFiltersPress}
                getQuickFiltersListRef={this.getQuickFiltersListRef}
                onPressResetButton={this.resetFilters}
                setListPaginatedCallApiRef={this.setListPaginatedCallApiRef}
                slug={this.slug}
                filteredSlug={this.state.filteredSlug}
                resetStatusBar={this.resetStatusBar}
                filtersEnabled={this.filtersEnabled}
                isPageLoading={isPageLoading}
                onRefresh={this.onRefresh}
                serverError={serverError}
                hideOosProduct={hideOosProduct}
                goBack={this.goBack}
                filtersList={filtersList}
              />
            )}
          </>
        )}
        <AppInstallPrompts link={FOXY_URLS.appInstallPage.category} />
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({ fetchCategory, fetchList }, dispatch),
});

export default connect(null, mapDispatchToProps)(Category);
