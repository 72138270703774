import { LISTS_API_VERSION } from '../config/Constants';

export const interpolateString = (string, variables) => {
  for (let key in variables) {
    let placeholder = `{${key}}`;
    let value = variables[key];
    string = string.replace(placeholder, value);
  }
  return string;
};

export const getCompactSlug = (slug = '') => slug.replace('.json', '').split('/').slice(-1)[0];

const slugFormer = {
  product: name => `/api/v2/products/${name}.json`,
  brand: name => `/api/v6/brands/${name}.json`,
  list: name => `/api/${LISTS_API_VERSION}/lists/${name}.json`,
  offers: name => `/api/v6/offers/${name}.json`,
  categories: name => `/api/v2/categories/${name}.json`,
  ingredients: name => `/api/v2/ingredients/${name}.json`,
};

export const getFullSlugFromName = (name, type) => (name ? slugFormer[type](name) : '');

export const getCategorySlugParams = (slug = '') => slug
  .replace('.json', '')
  .replace(/\/api\/v\d+\/categories\//, '')
  .split('/');

export const nestedParamToName = (nestedParams = []) => (
  nestedParams.reduce((acc, param) => (param ? `${acc}/${param}` : acc))
);

export const getCategoryUrlParamsObject = (slug = '') => {
  const [first, second, third] = getCategorySlugParams(slug);
  const newShortSlug = {};
  if (first) newShortSlug.first = first;
  if (second) newShortSlug.second = second;
  if (third) newShortSlug.third = third;
  return newShortSlug;
};

const getBrandSlugParams = (slug = '') => slug
  .replace('.json', '')
  .replace(/\/api\/v\d+\/brands\//, '')
  .split('/');

export const getBrandUrlParamsObject = (slug = '') => {
  const [shortSlug, isCategory, first, second, third] = getBrandSlugParams(slug);
  const newShortSlug = {};
  if (shortSlug) newShortSlug.shortSlug = shortSlug;
  if (isCategory) newShortSlug.isCategory = isCategory;
  if (first) newShortSlug.first = first;
  if (second) newShortSlug.second = second;
  if (third) newShortSlug.third = third;
  return newShortSlug;
};

export const getDisplayNameFromSlugSubstring = (substring = '') => (
  substring.replace('-', ' & ')
    .replace(/\b\w/g, match => match.toUpperCase())
);

export const getArtistListsParamsObject = (slug = '') => {
  const [handle, listType] = slug.replace('.json', '').split('/').slice(-2);
  return { handle, listType };
};

export const insertCharAtPosition = (index, string, stringToAdd) => {
  return (
    string.substring(0, index) +
    stringToAdd +
    string.substring(index, string.length)
  );
};

export const getProductListsParamsObject = (slug = '') => {
  const [name, listType] = slug.replace('.json', '').split('/').slice(-2);
  return { name, listType };
};
