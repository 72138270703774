import React, { Component } from 'react';
import {
  View,
  BackHandler,
  Text,
  KeyboardAvoidingView,
  Linking,
  ScrollView,
  Platform,
} from 'react-native';
import { Alert } from '../../libraries/NativeLibraries';
import StyleSheet from 'react-native-media-query';
import ImagePicker from '../../libraries/ReactNativeImageCropPicker';
import Permissions from '../../libraries/ReactNativePermissions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import RNFS from '../../libraries/ReactNativeFs';
import { v1 as uuidv1 } from 'uuid';
import { v5 as uuidv5 } from 'uuid';
import {
  publishPost,
  addPostToLocalState,
} from '../../actions/UploadPostActions';
import {
  updateLastVideoUpload,
  refreshMyProfile,
  cancelUpload,
  setVideoUploadProgress,
  rateProduct,
  setVideoUploadStatus,
  fetchProduct,
} from '../../actions/ActionTypes';
import _, { indexOf } from 'lodash';
import { getProductRatings } from '../../actions/RatingAndReviewsActions';
import RatedProduct from '../../components/productRatings/RatedProduct';
import UploadMedia from '../../components/productRatings/UploadMedia';
import WriteAReview from '../../components/productRatings/WriteAReview';
import StaticNavigationHeader from '../../components/shared/StaticNavigationHeader';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import colors from '../../theme/Colors';
import { StarRating } from '../../lib/StarRating';
import FoxyShadowButton from '../../lib/FoxyShadowButton';
import CameraUtils from '../../utils/CameraUtils';
import { uploadImageReview } from '../../actions/FacialAnalysisActions';
import FoxyAlert from '../../components/camera/shared/FoxyAlert';
import VariantModal from '../../components/Product/VariantModal';
import AnalyticsManager from '../../analytics/AnalyticsManager';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import Toast from 'react-native-easy-toast';
import { EventParameterKey, EventType } from '../../analytics';
import ImageResizer from 'react-native-image-resizer';
import { KeyboardAwareScrollView } from '../../libraries/KeyboardAwareScrollView';
import { AddressStyles } from '../../components/cart/styles';
import { ScreenPlaceholder } from '../../components/shared';
import { withEither } from '../../lib/Monads';
import VariantUtility from '../../utils/VariantUtility';
import { isDesktop, isWeb } from '../../utils/BooleanUtility';
import { getScreenWidth } from '../../utils/LayoutUtility';
import { Fragment } from 'react';
import { getFullSlugFromName } from '../../utils/StringUtility';
import PermissionsUtility, {
  checkMediaLibraryPermission,
} from '../../utils/PermissionsUtility';
import { isIOS, isPresent } from '../../utils/BooleanUtility';
import {
  foxyImagePicker,
  openGalleryOrShowPermissionModal,
} from '../../utils/ImagePickerUtility';
import { STORAGE_MODAL_INFO } from '../../config/Constants';

const { ids, styles } = StyleSheet.create({
  outerContainer: {
    backgroundColor: colors.white,
    flex: 1,
    '@media (min-width: 768px)': {
      backgroundColor: colors.shimmer.darkGrey,
    },
  },
  innerContainer: {
    marginBottom: 16,
    '@media (min-width: 768px)': {
      width: getScreenWidth(),
      alignSelf: 'center',
      flex: 1,
      paddingTop: 20,
    },
  },
  postReviewButton: {
    height: 70,
    bottom: 0,
    width: Utility.getScreenWidth(),
    paddingBottom: Utility.isIOS() ? 16 : 0,
    backgroundColor: 'white',
    flexDirection: 'column',
    zIndex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
  },
  verticalSeparator: {
    width: Utility.getScreenWidth(),
    ...Platform.select({
      web: { height: 50 },
      default: { height: Utility.getScreenWidth() / 2 },
    }),
  },
  divider: {
    height: 1,
    marginHorizontal: 16,
    width: Utility.getScreenWidth() - 32,
    marginVertical: 16,
    backgroundColor: '#EFEFEF',
    '@media (min-width: 768px)': {
      width: 'auto',
    },
  },
  yourRatingTitle: {
    fontFamily: 'Roboto-Medium',
    color: colors.foxyBlack,
    fontWeight: '500',
    fontSize: 14,
    paddingLeft: 12,
  },
  ratedProductContainer: {
    paddingHorizontal: 12,
    marginTop: 12,
    '@media (min-width: 768px)': {
      width: '30.3%',
      paddingHorizontal: 0,
      marginRight: 10,
      marginTop: 0,
    },
  },
  rowContainer: {
    flex: 1,
    '@media (min-width: 768px)': {
      flexDirection: 'row',
    },
  },
  rightContainer: {
    flex: 1,
    '@media (min-width: 768px)': {
      width: '69%',
      backgroundColor: colors.white,
      padding: 16,
    },
  },
  title: {
    marginVertical: 10,
    fontFamily: 'Roboto-Bold',
    fontSize: 16,
    color: colors.foxyBlack,
    fontWeight: '700',
  },
});
export class PostReview extends Component {
  constructor(props) {
    super(props);
    const { navigation, route } = this.props;
    const { params = {} } = route;
    const { selectedVariant, shortSlug } = params;
    this.state = {
      media: [],
      readExternalStoragePermission: false,
      isModalVisible: false,
      selectedVariant: typeof selectedVariant === 'string' ? {} : selectedVariant,
      // thumbnails: [],
      isReviewBodyEmpty: false,
      isReviewTitleEmpty: false,
      itemData: route.params?.itemData ?? {},
      renderShimmer: false,
      body: '',
      reviewTitle: '',
    };
    const notificationParams = route.params?.params;
    this.rating = notificationParams?.rating
      ? notificationParams?.rating
      : route.params?.rating;
    this.slug = notificationParams?.slug || (isWeb() ? getFullSlugFromName(shortSlug, 'product') : '');

    this.updateSelectedVariant = route.params?.updateSelectedVariant;
    this.openVariantModal = route.params?.openVariantModal;
    this.fromReviewCard = route.params?.fromReviewCard;
    this.id = route.params?.id;

    this.orderId = notificationParams?.order_id || '';
    this.order_item_sku = notificationParams?.sku_id || '';

    this.pageNo = 0;
    // this.images = [];
    if (Utility.isAndroid()) {
      this.backhandler = BackHandler.addEventListener(
        'hardwareBackPress',
        this.onHardwareBackKeyPress,
      );
    }
    this.skuId = undefined;
    this.mediaContainsOneVideo = false;
    const { permissionStatus: { storage: storagePermissionStatus = '' } = {} } =
      this.props;
    this.storagePermissionStatus = storagePermissionStatus;
  }

  componentDidMount() {
    AnalyticsManager.logFirebaseEvent(EventType.discoveryEvents.PAGE_VIEW, {
      page: 'ADD_RATING',
    });
    if (this.slug) {
      this.getProductFromSlug();
    }
  }

  componentDidUpdate(prevProps) {
    this.storagePermissionStatus =
      PermissionsUtility.checkPreviousStoragePermission(prevProps);
  }

  componentWillUnmount() {
    if (Utility.isAndroid()) {
      this.backhandler.remove();
    }
  }

  getProductFromSlug = () => {
    const { fetchProduct } = this.props;

    this.setState({
      renderShimmer: true,
    });

    fetchProduct(this.slug, (success, response, status) => {
      if (success) {
        this.setState({ itemData: response, renderShimmer: false });
      }
    });
  };

  onHardwareBackKeyPress = () => {
    const { navigation } = this.props;
    navigation.goBack();
    return true;
  };

  setReviewBody = (value) => {
    this.setState({
      isReviewBodyEmpty: Utility.isBlank(value),
      body: value,
    });
  };

  setReviewTitle = (value) => {
    this.setState({
      isReviewTitleEmpty: Utility.isBlank(value),
      reviewTitle: value,
    });
  };

  toggleReviewModal = (open) => {
    this.setState({
      isModalVisible: open,
    });
  };

  closeModal = () => {
    this.toggleReviewModal(false);
    const { navigation } = this.props;

    if (!!this.fromReviewCard) {
      navigation.replace('OrdersHistory');
      return;
    }

    if (isDesktop()) {
      // Since on Desktop, this page is opened as "Push", hence the page is popped here.
      navigation.pop();
    } else {
      navigation.goBack();
    }
  };

  onFinishRating = (rating) => {
    const { rateProduct } = this.props;
    const { selectedVariant, itemData = {} } = this.state;
    const { slug = '' } = itemData;

    // this.order_item_sku is commented because skuId is calculated without it in RateProduct.js. This
    // gives different value of skuId for products which have variants.
    const skuId = Utility.getSkuIdForProduct(
      itemData,
      selectedVariant,
      // this.order_item_sku,
    );

    const updatedSlug = slug.replace('/api/v2', '/api/v1');
    const url = updatedSlug.replace('.json', '/ratings.json');
    const data = {
      url,
      id: Utility.isPresent(this.order_item_sku) ? this.order_item_sku : skuId,
      rating: {
        star: rating,
      },
      order_id: this.orderId,
    };

    AnalyticsManager.logEvent('rate_product', {
      sku_id: skuId,
      product_id: itemData?.id,
      status: 'change',
      screen: 'post_review',
      rating,
    });
    rateProduct(data);
  };

  RequestExternalStoragePermission = () => {
    const mediaLibrary = Utility.isAndroid() ? 'storage' : 'photo';
    Permissions.request(mediaLibrary).then((response) => {
      const isPermissionAllowed = response === 'authorized';
      const metaData = { type: 'media_library' };
      if (response === 'authorized') {
        this.setState({ readExternalStoragePermission: true }, () => {
          this.onUploadMediaPress();
        });
        return;
      }
      if (Utility.isIOS() && response === 'denied') {
        this.openSettings();
      }
      if (response === 'restricted') {
        this.openSettings();
      }
      Utility.logPermissionEvent(isPermissionAllowed, metaData);
    });
  };

  uploadWebImage = (imageData) => {
    const { uploadImageReview } = this.props;
    const postData = {
      base64Data: imageData,
      id: this.id,
      type: 'Rating',
    };
    uploadImageReview(postData);
  };

  convertImageToBase64AndUpload = (uri) => {
    const { uploadImageReview } = this.props;
    CameraUtils.convertImageToBase64(uri)
      .then((response) => {
        const postData = {
          base64Data: `data:image/jpeg;base64,${response}`,
          id: this.id,
          type: 'Rating',
        };
        uploadImageReview(postData);
      })
      .catch((error) => {});
  };

  fireImageUploadEvent = () => {
    const { images = [] } = this.state;
    AnalyticsManager.logEvent('review_select_media', {
      type: 'image',
      count: images.length,
    });
  };

  onUploadMediaPress = (files = []) => {
    if (isWeb()) {
      const imageArray = this.createMediaArray(files.slice(0, 7));
      this.setState({ media: imageArray });
      return;
    }
    const { readExternalStoragePermission } = this.state;

    if (readExternalStoragePermission) {
      ImagePicker.openPicker({
        mediaType: 'any',
        multiple: true,
      }).then((images) => {
        const imageArray = this.createMediaArray(images.slice(0, 7));
        this.setState({ media: imageArray });
      });
    } else {
      this.RequestExternalStoragePermission();
    }
  };

  onUploadMediaPressAppend = (files = []) => {
    const { readExternalStoragePermission, media = [] } = this.state;
    if (isWeb()) {
      const newMedia = this.createMediaArray(files);
      const newImages = [...media, ...newMedia];
      const appendedImages = newImages.slice(0, 7);
      this.setState({ media: appendedImages });
      return;
    }
    if (readExternalStoragePermission || isDesktop()) {
      ImagePicker.openPicker({
        multiple: true,
      }).then((images) => {
        const newMedia = this.createMediaArray(images);
        const newImages = [...media, ...newMedia];
        const appendedImages = newImages.slice(0, 7);
        this.setState({ media: appendedImages });
      });
    } else {
      this.RequestExternalStoragePermission();
    }
  };

  createMediaArray = (mediaArray) => {
    const filteredArray = [];
    mediaArray.forEach((media) => {
      if (filteredArray.length > 7) {
        this.showToast('Only 7 media objects can be added in a review');
        return filteredArray;
      }
      if (media.mime === 'video/mp4') {
        if (!this.mediaContainsOneVideo) {
          this.mediaContainsOneVideo = true;
          filteredArray.push(media);
        } else {
          this.showToast('Only one video can be added per review');
        }
      } else {
        filteredArray.push(media);
      }
    });
    return filteredArray;
  }
  onClickMediaUploadButton = () => {
    openGalleryOrShowPermissionModal({
      openGallery: this.openGallery,
      type: 'Post Review',
      modalInfo: STORAGE_MODAL_INFO.post_review,
    });
  };

  onCancelAlert = () => {
    const { navigation } = this.props;
    navigation.goBack();
  };

  openGallery = async () => {
    const { media = [] } = this.state;
    const imagePickerProps = {
      mediaType: 'any',
      multiple: true,
    };
    const { appendedImages: mediaData, isMediaContainsOneVideo } =
      await foxyImagePicker({
        media,
        storagePermissionStatus: this.storagePermissionStatus,
        onCancelAlert: this.onCancelAlert,
        mediaContainsOneVideo: this.mediaContainsOneVideo,
        imagePickerProps,
      });
    if (isPresent(mediaData)) {
      this.setState({ media: mediaData });
      this.mediaContainsOneVideo = isMediaContainsOneVideo;
    }
  };

  onPostReview = () => {
    const {
      isReviewBodyEmpty,
      isReviewTitleEmpty,
      body,
      reviewTitle,
      itemData = {},
      media = [],
    } = this.state;
    let shouldReturn = false;
    if (Utility.isBlank(reviewTitle.trim())) {
      this.setState({ isReviewTitleEmpty: true });
      shouldReturn = true;
    }
    if (Utility.isBlank(body.trim())) {
      this.setState({ isReviewBodyEmpty: true });
      shouldReturn = true;
    }

    if (shouldReturn) {
      return;
    }
    const { rateProduct, ratings } = this.props;
    const { slug = '' } = itemData;
    const { selectedVariant } = this.state;
    // this.order_item_sku is commented because skuId is calculated without it in RateProduct.js. This
    // gives different value of skuId for products which have variants.
    this.skuId = Utility.getSkuIdForProduct(
      itemData,
      selectedVariant,
      // this.order_item_sku,
    );
    const updatedSlug = slug.replace('/api/v2', '/api/v1');
    const url = updatedSlug.replace('.json', '/ratings.json');
    const data = {
      url,
      id: Utility.isPresent(this.order_item_sku)
        ? this.order_item_sku
        : this.skuId,
      rating: {
        star: ratings[this.skuId],
        body: body.trim(),
        subject: reviewTitle.trim(),
      },
      order_id: this.orderId,
    };

    rateProduct(data);

    if (isWeb()) {
      media.forEach((image) => {
        if (image?.type?.includes('video')) {
          //todo: Vishwender
          // this.postVideo(image.data);
        } else {
          this.uploadWebImage(image.data);
        }
      });
    } else {
      media.forEach((image) => {
        if (image.mime === 'video/mp4') {
          this.postVideo(image);
        } else {
          this.convertImageToBase64AndUpload(image.path);
        }
      });
    }

    AnalyticsManager.logEvent('post_review', {
      sku_id: this.skuId,
      rating: ratings[this.skuId],
      review_length: body.length,
      media_type: 'photo/video',
      media_count: media.length,
    });
    this.toggleReviewModal(true);
  };

  updateSelectedVariants = (item) => {
    this.setState({
      selectedVariant: item,
    });
    const {
      route: {
        params: {
          itemData = {},
          setSelectedMultiVariantPrimary = () => {},
          setSelectedMultiVariantSecondary = () => {},
        } = {},
      } = {},
    } = this.props;
    if (
      VariantUtility.checkIfMultiVariant(
        itemData?.multi_level_variant_attributes,
      )
    ) {
      const primaryVariantToBeSet =
        VariantUtility.getParentVariantForChildVariantId(
          item?.id,
          itemData?.multi_level_variant_attributes,
        );
      const secondaryVariantToBeSet =
        primaryVariantToBeSet.multi_level_variant_attributes.filter(
          (secondaryVariant) => secondaryVariant?.variant?.id === item.id,
        )?.[0];
      if (
        Utility.isPresent(primaryVariantToBeSet) &&
        Utility.isPresent(secondaryVariantToBeSet)
      ) {
        setSelectedMultiVariantPrimary(primaryVariantToBeSet);
        setSelectedMultiVariantSecondary(secondaryVariantToBeSet);
      }
    }
    this.updateSelectedVariant(item);
  };

  deleteImage = (index) => {
    const { media } = this.state;
    if (media[index].mime === 'video/mp4') {
      this.mediaContainsOneVideo = false;
    }
    if (index > -1) {
      media.splice(index, 1);
      // this.thumbnails.splice(index, 1);
    }
    this.setState({
      media,
    });
  };

  postVideo = (video) => {
    const { body, reviewTitle } = this.state;
    const postId = uuidv1();
    const videoTitle = reviewTitle;
    const postData = {
      id: postId,
      localId: postId,
      category: 'video',
      postCacheDirectoryPath: '',
      uploadType: 'FoxyVideo',
      items: [
        {
          mimeType: 'video/mp4',
          uuid: uuidv5(video.path, uuidv5.URL),
          uri: video.path,
          mediaUriAbsolutePath: video.path,
          mediaUriExternalDirectoryPath: '',
          mediaBase64Uri: '',
          thumbnail: '',
          height: Utility.getScreenHeight(),
          width: Utility.getScreenWidth(),
          videoLength: 0,
          mediaType: 'video',
          caption: videoTitle,
          recentProductSearchID: '',
          products: [],
          videoFrames: [],
          productsTag: [],
        },
      ],
      postTitle: reviewTitle,
      postDescription: body,
      postThumbnail: '',
      postCategory: 'video',
      hashTags: [],
      mentions: [],
      editingMode: false,
      skuId: this.skuId,
    };

    const {
      publishPost,
      updateLastVideoUpload,
      addPostToLocalState,
      isVideoUploading,
      setVideoUploadProgress,
    } = this.props;

    Utility.setVideoUploadProgress = setVideoUploadProgress;
    addPostToLocalState(postData, (isAllowedToNavigate) => {
      if (!isVideoUploading) {
        publishPost(postId, postData);
        this.fireVideoUploadAnalytics(postData);
      }
    });
  };

  fireVideoUploadAnalytics = (postData) => {
    const { previousScreen } = this.props;
    if (previousScreen === SCREEN_CONSTANTS.MY_PROFILE) {
      RNFS.stat(postData.fileUrl)
        .then((stat) => {
          if (stat.size === 0) return;
          AnalyticsManager.logEvent(EventType.artistEvents.VIDEO_UPLOAD_START, {
            [EventParameterKey.VIDEO_DURATION]: postData.duration,
            [EventParameterKey.FILE_SIZE]: stat.size / 1000,
          });
        })
        .catch((err) => {
          AnalyticsManager.logEvent(EventType.artistEvents.VIDEO_UPLOAD_START, {
            [EventParameterKey.VIDEO_DURATION]: postData.duration,
          });
        });
    }
  };

  showVariantModal = () => {
    const { navigation } = this.props;
    const { itemData = {} } = this.state;
    navigation.navigate(SCREEN_CONSTANTS.VARIANT_MODAL, {
      itemData,
      selectOnly: true,
      updateSelectedVariantForReview: this.updateSelectedVariants,
    });
  };

  showToast = (message) => {
    this.toast.show(message, 1000);
  };

  assignRef = (ref) => {
    this.toast = ref;
  };

  isIosFn = () => Utility.isIOS();

  customKeyboardAvoid = withEither(this.isIosFn, KeyboardAvoidingView)(View);

  render() {
    const { ratings, navigation, isVideoUploading } = this.props;
    const {
      isModalVisible,
      selectedVariant,
      isReviewBodyEmpty,
      isReviewTitleEmpty,
      itemData = {},
      media,
      renderShimmer,
      body,
      reviewTitle,
    } = this.state;
    const alertMessage =
      'Your review is sent to our experts! Once approved you will be notified and you will earn your reward points';
    const numOflinesInModal = 7;

    if (renderShimmer) {
      return (
        <>
          {!isDesktop() && <StaticNavigationHeader title='Rate and Review' />}
          <ScreenPlaceholder />
        </>
      );
    }

    const keyboardBehaviour = Utility.isAndroid() ? 'position' : 'padding';
    const ContainerComponent = isWeb() ? ScrollView : Fragment;

    return (
      <ContainerComponent>
        <View style={styles.outerContainer} dataSet={{ media: ids.outerContainer }}>
          {!isDesktop() && <StaticNavigationHeader title='Rate and Review' />}
          <View style={styles.innerContainer} dataSet={{ media: ids.innerContainer }}>
            <KeyboardAwareScrollView showsVerticalScrollIndicator={false}>
              {isDesktop() && <Text style={styles.title}>Rate and Review</Text>}
              <View style={styles.rowContainer} dataSet={{ media: ids.rowContainer }}>
                <View style={styles.ratedProductContainer} dataSet={{ media: ids.ratedProductContainer }}>
                  <RatedProduct
                    itemData={itemData}
                    selectedVariant={selectedVariant}
                    toggleVariantModal={this.showVariantModal}
                  />
                </View>

                <View style={styles.rightContainer} dataSet={{ media: ids.rightContainer }}>
                  {!isDesktop() && <View style={styles.divider} />}
                  <Text style={styles.yourRatingTitle}>Your Rating</Text>
                  <StarRating
                    rating={
                      ratings[
                        // this.order_item_sku is commented because skuId is calculated without it in RateProduct.js. This
                        // gives different value of skuId for products which have variants.
                        Utility.getSkuIdForProduct(
                          itemData,
                          selectedVariant,
                          // this.order_item_sku,
                        )
                      ] || this.rating
                    }
                    count={5}
                    defaultRating={0}
                    size={40}
                    onFinishRating={this.onFinishRating}
                  />
                  <View style={styles.divider} dataSet={{ media: ids.divider }} />
                  <WriteAReview
                    setReviewBody={this.setReviewBody}
                    setReviewTitle={this.setReviewTitle}
                    isReviewBodyEmpty={isReviewBodyEmpty}
                    isReviewTitleEmpty={isReviewTitleEmpty}
                  />
                  <View style={styles.divider} dataSet={{ media: ids.divider }} />
                  <UploadMedia
                    onUploadMediaPress={isWeb() ? this.onUploadMediaPress : this.onClickMediaUploadButton}
                    onUploadMediaPressAppend={isWeb() ? this.onUploadMediaPressAppend : this.openGallery}
                    deleteImage={this.deleteImage}
                    navigation={navigation}
                    media={media}
                  />
                  <View style={styles.verticalSeparator} />
                  {isDesktop() && (
                    <FoxyShadowButton
                      width={Utility.getScreenWidth() - 34}
                      title='Post your review'
                      onPress={this.onPostReview}
                      backgroundColor={colors.primaryActionBackgroundColor}
                      firstColor={colors.linerGradientGreenFirst}
                      secondColor={colors.linerGradientGreenSecond}
                    />
                  )}
                </View>
              </View>
            </KeyboardAwareScrollView>
          </View>
        </View>
        {!isDesktop() && (
          <this.customKeyboardAvoid
            behavior={keyboardBehaviour}
            enabled
            keyboardVerticalOffset={-14}
          >
            <View style={styles.postReviewButton}>
              <FoxyShadowButton
                width={Utility.getScreenWidth() - 34}
                title='Post your review'
                onPress={this.onPostReview}
                backgroundColor={colors.primaryActionBackgroundColor}
                firstColor={colors.linerGradientGreenFirst}
                secondColor={colors.linerGradientGreenSecond}
                // disabled={Utility.isBlank(this.setReviewBody)}
              />
            </View>
          </this.customKeyboardAvoid>
        )}
        <Toast ref={this.assignRef} />
        <FoxyAlert
          isVisible={isModalVisible}
          onTapOutside={this.closeModal}
          alertBoxTitle='Thank you'
          alertMessage={alertMessage}
          firstButtonTitle='OK, Got it'
          firstButtonTextColor={colors.prompt.lightBlue}
          hideSecondButton
          firstButtonOnPress={this.closeModal}
          height={200}
          numOfLines={numOflinesInModal}
        />
      </ContainerComponent>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    ratings: store.UserAccountInfo.productRatings,
    isVideoUploading: store.UserAccountInfo.isUploading,
    permissionStatus: store.permissionStatus,
  };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      getProductRatings,
      rateProduct,
      uploadImageReview,
      publishPost,
      setVideoUploadProgress,
      updateLastVideoUpload,
      addPostToLocalState,
      setVideoUploadStatus,
      fetchProduct,
    },
    dispatch,
  ),
});

export default withNavigation(
  connect(mapStateToProps, mapDispatchToProps)(PostReview),
);
