import React, { useEffect, useRef, useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { memoize } from 'lodash';
import Config from '../../libraries/ReactNativeConfig';
import AutoHeightWebView from 'react-native-autoheight-webview';
import ShimmerPlaceHolder from '../../libraries/ReactNativeShimmerPlaceholder';
import { getScreenWidth } from '../../utils/LayoutUtility';
import { jsonParser } from '../../utils/JsonUtility';
import RemoteConfig from '../../utils/RemoteConfig';
import { REMOTE_CONFIG_KEYS } from '../../config/Constants';
import APLUS_CONTENT_CUSTOM_CSS_INJECTED from '../../injected/APlusCss';
import { isBlank } from '../../utils/BooleanUtility';
import { HandleNavigationFromWebview } from '../../utils/WebViewUtility';
import { AnalyticsManager, EventParameterKey, EventType } from '../../analytics';
import Utility from '../../utils/Utility';

const shimmerColors = ['#eeeeee', '#ddddddff', '#eeeeee'];

const populateProductListsRemovalWithTitles = (selectorsArray) => {
  let cssRule = '';
  selectorsArray.forEach((selector) => {
    cssRule += `
      SECTION_SELECTOR[id*="heading"]:has(+ SECTION_SELECTOR * ${selector}),
      SECTION_SELECTOR:has(${selector}),
    `;
  });
  return cssRule;
};

const injectedJavaScript = (() => {
  const AplusContentData =
    jsonParser(RemoteConfig.getValue(REMOTE_CONFIG_KEYS.a_plus_content_data)) ||
    {};
  const {
    header_footer_selectors = Config.PRODUCT_PAGE_HEADER_FOOTER_SELECTORS?.split(',') || [],
    reviews_rating_selector = Config.PRODUCT_PAGE_REVIEWS_RATINGS_SELECTOR || '',
    carousel_selector = Config.PRODUCT_PAGE_CAROUSEL_SELECTOR || '',
    section_selector = Config.PRODUCT_PAGE_SECTION_SELECTOR || '',
    product: {
      product_info_selector = Config.PRODUCT_PAGE_PRODUCT_INFO_SELECTOR || '',
      product_description_selector = Config.PRODUCT_PAGE_PRODUCT_DESCRIPTION_SELECTOR || '',
      product_list_selectors = Config.PRODUCT_PAGE_PRODUCT_LISTS_SELECTORS?.split(',') || [],
    } = {},
    other_list_selectors = Config.PRODUCT_PAGE_OTHER_LISTS_SELECTORS?.split(',') || [],
    main_content_selector = Config.PRODUCT_PAGE_MAIN_CONTENT_SELECTOR || '',
    background_color_selectors = Config.PRODUCT_PAGE_BACKGROUND_COLOR_SELECTORS?.split(',') || [],
    background_color = Config.PRODUCT_PAGE_BACKGROUND_COLOR || '',
  } = AplusContentData;

  return APLUS_CONTENT_CUSTOM_CSS_INJECTED.replace(
    /\bCAROUSEL\b/g,
    carousel_selector,
  )
    .replace(/\bHEADER_FOOTER\b/g, header_footer_selectors)
    .replace(/\bREVIEWS_RATING\b/g, reviews_rating_selector)
    .replace(/\bPRODUCT_INFO\b/g, product_info_selector)
    .replace(/\bPRODUCT_DESCRIPTION\b/g, product_description_selector)
    .replace(/\bOTHER_SELECTORS\b/g, other_list_selectors)
    .replace(
      /\bPRODUCT_LIST\b/g,
      populateProductListsRemovalWithTitles(product_list_selectors)?.replace(
        /,\s*$/,
        '',
      ),
    )
    .replace(/\bMAIN_CONTENT_SELECTOR\b/g, main_content_selector)
    .replace(/\bBACKGROUND_COLOR_SELECTORS\b/g, background_color_selectors)
    .replace(/\bBACKGROUND_COLOR\b/g, background_color)
    .replace(/\bSECTION_SELECTOR\b/g, section_selector);
})();

const WebviewLoader = () => {
  return (
    <View style={styles.shimmerContainer}>
      <TitleShimmer />
      <ContentShimmer />
    </View>
  );
};

const TitleShimmer = () => (
  <View>
    <ShimmerPlaceHolder
      colorShimmer={shimmerColors}
      autoRun
      style={styles.titleShimmer}
    />
    <ShimmerPlaceHolder
      colorShimmer={shimmerColors}
      autoRun
      style={styles.subtitleShimmer}
    />
  </View>
);

const ContentShimmer = () => (
  <View>
    <ShimmerPlaceHolder
      colorShimmer={shimmerColors}
      autoRun
      style={styles.contentShimmer}
    />
  </View>
);

const APlusContentWebView = ({ uri, previousScreen }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isTimeout, setIsTimeout] = useState(false);
  const [height, setHeight] = useState(0);
  const [fetchError, setFetchError] = useState(false);
  const webviewRef = useRef(null);
  let intervalRef = useRef(null);
  let loadStartTime = null;

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      if (height === 0) {
        AnalyticsManager.logEvent(EventType.pageLoad.WEBVIEW_LOAD_TIMEOUT, {
          [EventParameterKey.PREVIOUS_SCREEN]: previousScreen,
          [EventParameterKey.LOAD_TIME]: Utility.getTimeDiff(
            new Date(loadStartTime),
            new Date(),
          ),
          [EventParameterKey.URL]: uri,
        });
        setIsTimeout(true);
        clearInterval(intervalRef.current);
      }
    }, 15000);
    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);

  const onMessage = (event) => {
    if (fetchError) return;
    if (event.nativeEvent.data) {
      const { height: htmlHeight = 0 } =
        jsonParser(event.nativeEvent.data) || {};
      if (height === 0) {
        AnalyticsManager.logEvent(EventType.pageLoad.WEBVIEW_LOAD, {
          [EventParameterKey.PREVIOUS_SCREEN]: previousScreen,
          [EventParameterKey.LOAD_TIME]: Utility.getTimeDiff(
            new Date(loadStartTime),
            new Date(),
          ),
          [EventParameterKey.URL]: uri,
        });
        clearInterval(intervalRef.current);
      }
      setIsLoading(false);
      setHeight(htmlHeight);
    }
  };

  const onLoadProgress = ({ nativeEvent }) => {
    if (nativeEvent.progress > 0.9) {
      webviewRef.current.injectJavaScript(injectedJavaScript);
    }
  };

  const onLoadError = () => {
    setFetchError(true);
    AnalyticsManager.logEvent(EventType.pageLoad.WEBVIEW_LOAD_ERROR, {
      [EventParameterKey.PREVIOUS_SCREEN]: previousScreen,
      [EventParameterKey.URL]: uri,
    });
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    setIsLoading(false);
  };

  const onLoadStart = () => {
    loadStartTime = new Date();
  };

  if (isBlank(injectedJavaScript) || isTimeout) return null;

  return (
    <>
      <AutoHeightWebView
        ref={webviewRef}
        scrollEnabled={false}
        source={{
          uri,
        }}
        style={memoize(
          () => ({ height }),
          () => [height],
        )()}
        onHttpError={onLoadError}
        onMessage={onMessage}
        customScript={injectedJavaScript}
        onLoadProgress={onLoadProgress}
        onShouldStartLoadWithRequest={(clickParams) =>
          HandleNavigationFromWebview(clickParams, uri)
        }
        onLoadStart={onLoadStart}
        cacheEnabled
        onError={onLoadError}
        setBuiltInZoomControls={false}
        androidLayerType='hardware'
        domStorageEnabled
        scalesPageToFit={true}
        cacheMode='LOAD_DEFAULT'
        viewportContent={
          'width=device-width, user-scalable=no, initial-scale=1.0'
        }
      />
      {isLoading && <WebviewLoader />}
    </>
  );
};

const styles = StyleSheet.create({
  shimmerContainer: {
    paddingHorizontal: 12,
    marginTop: 12,
  },
  webviewLoadContainer: {
    height: 30,
    width: 30,
    resizeMode: 'contain',
  },
  titleShimmer: {
    height: 24,
    width: 240,
    borderRadius: 8,
  },
  subtitleShimmer: {
    height: 20,
    width: 200,
    borderRadius: 8,
    marginTop: 6,
    marginBottom: 16,
  },
  contentShimmer: {
    height: 150,
    width: getScreenWidth() - 24,
    borderRadius: 8,
    marginBottom: 4,
  },
});

export default APlusContentWebView;
