import React, { PureComponent } from 'react';
import { View, Text, StyleSheet, FlatList, Image } from 'react-native';
import {
  AnalyticsManager,
  EventParameterKey,
  EventParameterValue,
  EventType,
} from '../../analytics';
import DebouncedTouchableOpacity from '../../components/shared/DebouncedTouchableOpacity';
import { REMOTE_CONFIG_KEYS } from '../../config/Constants';
import NavigationService from '../../navigator/NavigationService';
import colors from '../../theme/Colors';
import size from '../../theme/Fonts';
import images from '../../theme/Images';
import ContactsUtility from '../../utils/ContactsUtility';
import RemoteConfig from '../../utils/RemoteConfig';
import Utility from '../../utils/Utility';

export default class RequestInviteView extends PureComponent {
  constructor(props) {
    super(props);
    const { inviteCenterStrings } = props;
    this.parseConfigStrings(inviteCenterStrings);
  }

  parseConfigStrings = (inviteCenterStrings) => {
    const {
      foxy_edge_request_modal: foxyEdgeModalSteps = [],
      popup_modal_title_subtitles: modalTitleSubtitle = {},
    } = inviteCenterStrings;

    this.stepsToShow = foxyEdgeModalSteps;
    this.headerStrings = modalTitleSubtitle.foxy_edge;
  };

  getModalName = () => {
    const { referralModalType = '' } = this.props;
    switch (referralModalType) {
      case 'invite_request_modal':
        return 'invite_request_modal';
      default:
        return 'invite_request_modal';
    }
  };

  fireModalActionEven = (cta) => {
    AnalyticsManager.logEvent(EventType.MODAL_ACTION, {
      [EventParameterKey.MODAL_NAME]: this.getModalName(),
      [EventParameterKey.CTA]: cta,
    });
  };

  RenderDashedLine = ({ index }) => {
    if (index + 1 === this.stepsToShow?.length) return null;
    return <Image style={styles.horizontalLine} source={images.dashedLine} />;
  };

  InviteContactsButton = () => {
    const { onPressInviteContacts = () => {} } = this.props;
    return (
      <DebouncedTouchableOpacity
        {...this.props}
        style={styles.buttonContainer}
        onPress={onPressInviteContacts}
      >
        <Text style={styles.buttonText}>Request Invite</Text>
      </DebouncedTouchableOpacity>
    );
  };

  CouponCodeButton = () => {
    const { onPressCouponCode = () => {} } = this.props;
    return (
      <DebouncedTouchableOpacity
        {...this.props}
        style={styles.couponCodeButtonContainer}
        onPress={onPressCouponCode}
      >
        <Text style={styles.couponCodeButtonText}>I have an invite code</Text>
      </DebouncedTouchableOpacity>
    );
  };

  RenderPoint = ({ index }) => {
    const style = this.isLastStep(index)
      ? styles.currentPointStyle
      : styles.pointStyle;
    return (
      <View style={style}>
        <View style={styles.point} />
      </View>
    );
  };

  isLastStep = (index) => index + 1 === this.stepsToShow?.length;

  renderSteps = ({ item, index }) => {
    const textStyle = this.isLastStep(index)
      ? styles.listItemTextBold
      : styles.listItemText;
    return (
      <>
        <View style={styles.listItemContainer}>
          <this.RenderPoint index={index} />
          <Text style={textStyle}>{item}</Text>
        </View>
        <this.RenderDashedLine index={index} />
      </>
    );
  };

  keyExtractor = (item, index) => index;

  render() {
    const { isModalVisible } = this.props;
    return (
      <View style={styles.modalContainer}>
        <Text style={styles.modalHeading}>{this.headerStrings.heading}</Text>
        <Text style={styles.modalSubheading}>
          {this.headerStrings.subheading}
        </Text>
        <FlatList
          style={styles.listContainer}
          data={this.stepsToShow}
          renderItem={this.renderSteps}
          keyExtractor={this.keyExtractor}
          bounces={false}
        />
        <this.InviteContactsButton />
        <this.CouponCodeButton />
        {/* <View style={styles.footerTextContainer}>
          <Text style={styles.footerText}>Please click </Text>
          <Text style={styles.footerTextBold}>'allow' </Text>
          <Text style={styles.footerText}>
            on the contact book permission screen
          </Text>
        </View> */}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  modalContainer: {
    width: '100%',
    height: '100%',
  },

  footerTextContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 10,
    marginBottom: 12,
    width: '100%',
  },

  footerText: {
    color: colors.black,
    fontSize: size.h4,
    fontFamily: 'Roboto-Regular',
    textAlign: 'center',
  },

  footerTextBold: {
    color: colors.black,
    fontSize: size.h4,
    fontFamily: 'Roboto-Bold',
    textAlign: 'center',
  },

  buttonContainer: {
    backgroundColor: colors.black,
    borderRadius: 4,
    height: 40,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  couponCodeButtonContainer: {
    backgroundColor: colors.white,
    borderRadius: 4,
    height: 40,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 6,
  },
  buttonText: {
    fontSize: size.h2,
    fontFamily: 'Roboto-Medium',
    color: colors.white,
  },
  couponCodeButtonText: {
    fontSize: size.h2,
    fontFamily: 'Roboto-Medium',
    color: colors.foxyBlack,
  },
  point: {
    width: 6,
    height: 6,
    backgroundColor: colors.black,
    borderRadius: 3,
  },
  horizontalLine: {
    height: '100%',
    width: 2,
    position: 'absolute',
    left: 5,
    top: 10,
    resizeMode: 'stretch',
  },
  listContainer: {
    marginTop: 24,
    marginBottom: 24,
  },
  listItemText: {
    fontSize: size.h3,
    fontFamily: 'Roboto-Regular',
    color: colors.black,
    marginLeft: 12,
    flex: 1,
  },
  listItemTextBold: {
    fontSize: size.h3,
    fontFamily: 'Roboto-Bold',
    color: colors.black,
    marginLeft: 15,
    flex: 1,
  },

  listItemContainer: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginBottom: 10,
  },

  pointStyle: {
    borderWidth: 1,
    height: 12,
    width: 12,
    borderRadius: 6,
    alignItems: 'center',
    justifyContent: 'center',
    borderColor: colors.white,
    marginTop: 4,
  },

  currentPointStyle: {
    borderWidth: 1,
    height: 12,
    width: 12,
    borderRadius: 6,
    alignItems: 'center',
    justifyContent: 'center',
    borderColor: colors.black,
    marginTop: 4,
  },

  bottomModal: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    marginBottom: 0,
    marginRight: 0,
    marginLeft: 0,
    paddingHorizontal: 12,
    paddingVertical: 24,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    overflow: 'hidden',
    backgroundColor: colors.white,
    borderTopLeftRadius: 25,
    borderTopRightRadius: 25,
  },

  modalHeading: {
    color: colors.black,
    paddingHorizontal: 12,
    fontSize: size.h1,
    fontFamily: 'Roboto-Bold',
  },
  modalSubheading: {
    color: colors.black,
    paddingHorizontal: 12,
    fontSize: size.h3,
    fontFamily: 'Roboto-Regular',
  },
  productCard: {
    marginLeft: 12,
  },
  seeHowButtonContainer: {
    backgroundColor: colors.white,
    borderRadius: 4,
    height: 40,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: colors.black,
    marginBottom: 12,
  },
  seeHowButtonText: {
    fontSize: size.h2,
    fontFamily: 'Roboto-Medium',
    color: colors.black,
  },
  seeHowBanner: {
    height: 70,
    width: Utility.getScreenWidth(),
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginTop: 24,
    backgroundColor: colors.referrals.golden,
    marginLeft: -12,
    paddingHorizontal: 12,
  },
  seeHowBannerText: {
    fontSize: size.h3,
    color: colors.foxyBlack,
    paddingRight: 12,
    fontFamily: 'Roboto-Regular',
    width: '80%',
  },
  seeHowBannerImage: {
    height: 62,
    width: 62,
  },
});
