import React from 'react';
import { StyleSheet, TouchableOpacity, View, Text, Linking } from 'react-native';
import FastImageView from '../FastImageView';
import { getScreenWidth } from '../../utils/LayoutUtility';
import colors from '../../theme/Colors';
import size from '../../theme/Fonts';
import images from '../../theme/Images';
import OverlappingHorizontalImageList from './OverlappingHorizontalImageList';
import Utility from '../../utils/Utility';
import { getMinifiedImage } from '../../utils/ImageUtility';
import { AnalyticsManager, EventParameterKey, EventParameterValue, EventType } from '../../analytics';

const JoinWhatsapp = (props) => {
  const { paymentDetails: { attributes = {} } = {}, source = '' } = props;
  const {
    title = '',
    image_url = '',
    discussion_group_link = '',
    id = '',
  } = attributes;
  const randomInitialNumber = Utility.getRandom(0, 6);
  const onWhatsappClick = () => {
    Linking.openURL(discussion_group_link);
    AnalyticsManager.logEvent(EventType.routine.ITEM_CLICK, {
      [EventParameterKey.TYPE]: EventParameterValue.ITEM_TYPE.WHATSAPP_JOIN,
      [EventParameterKey.NAME]: title,
      [EventParameterKey.ENTITY]: 'routine',
      [EventParameterKey.ENTITY_ID]: id,
      [EventParameterKey.SOURCE]: source,
    });
  };
  return (
    <View style={styles.container}>
      <Text style={styles.header}>Join Our Whatsapp Group</Text>
      <Text style={styles.subText} numberOfLines={2} ellipsizeMode='tail'>
        Stay connected to your beauty coach & get deals specially for you.
      </Text>
      <View style={styles.bottomContainer}>
        <View style={styles.imageContainer}>
          <FastImageView
            style={styles.image}
            source={getMinifiedImage(
              image_url,
              styles.image.width,
              styles.image.width,
            )}
          />
        </View>

        <View style={styles.bottomMiddleContainer}>
          <Text
            numberOfLines={1}
            ellipsizeMode='tail'
            style={styles.middleContainerText}
          >
            {title}
          </Text>
          <OverlappingHorizontalImageList
            images={images.routines.participantsArray.slice(
              randomInitialNumber,
              randomInitialNumber + 3,
            )}
          />
        </View>
      </View>
      <TouchableOpacity
        style={styles.JoinWhatsappButton}
        onPress={onWhatsappClick}
      >
        <FastImageView
          style={styles.whatsappIcon}
          source={images.whatsapp_green_icon}
        />
        <Text style={styles.buttonText}>Join Group</Text>
      </TouchableOpacity>
    </View>
  );
};

export default JoinWhatsapp;

const styles = StyleSheet.create({
  container: {
    width: getScreenWidth(),
    backgroundColor: colors.white,
    justifyContent: 'center',
    paddingVertical: 8,
  },
  header: {
    fontSize: size.h1,
    color: colors.black,
    fontFamily: 'Roboto-Bold',
  },
  subText: {
    fontSize: size.h4,
    color: colors.darkGrey,
    width: getScreenWidth() - 24,
  },
  bottomContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 10,
  },
  imageContainer: {},
  image: {
    height: 55,
    width: 55,
    borderRadius: 20,
  },
  bottomMiddleContainer: {
    padding: 10,
    width: getScreenWidth() * 0.58 - 24,
  },
  middleContainerText: {
    fontSize: size.h3,
    color: colors.black,
    fontFamily: 'Roboto-Medium',
    width: getScreenWidth() * 0.6 - 24,
  },
  avatarContainer: {},
  JoinWhatsappButton: {
    height: 46,
    width: getScreenWidth() - 30,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.black,
    borderRadius: 4,
  },
  whatsappIcon: {
    height: 14,
    width: 14,
    marginRight: 4,
  },
  buttonText: {
    fontSize: size.h2,
    color: colors.white,
    alignSelf: 'center',
    textAlign: 'center',
    fontFamily: 'Roboto-Medium',
  },
});
