import React, { useEffect, useState, useRef } from 'react';
import { Text, View, Image, Linking } from 'react-native';
import Config from '../../libraries/ReactNativeConfig';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import ShimmerPlaceHolder from '../../libraries/ReactNativeShimmerPlaceholder';
import CouponCodePill from '../cart/CouponCodePill';
import colors from '../../theme/Colors';
import Utility from '../../utils/Utility';
import images from '../../theme/Images';
import DynamicLinkManager from '../../utils/DynamicLinkManager';
import AnalyticsManager from '../../analytics/AnalyticsManager';
import {
  EventParameterKey,
  EventParameterValue,
  EventType,
} from '../../analytics';
import { FlatList } from 'react-native-gesture-handler';
import RoundedButton from '../layout/buttons/RoundedButton';
import DebouncedTouchableOpacity from '../shared/DebouncedTouchableOpacity';
import { ids, styles } from './styles';
import SlimOfferCoupon from './SlimOfferCoupon';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import withNavigation from '../../utils/WithNavigation';
import { addContinueAction } from '../../actions/FoxyEdgeAction';
import RemoteConfig from '../../utils/RemoteConfig';
import { REMOTE_CONFIG_KEYS } from '../../config/Constants';
import { isBlank, isPresent, isDesktop, isWeb, isWebIOS } from '../../utils/BooleanUtility';
import { navigateToScreen } from '../../utils/NavigationUtility';
import AnalyticsUtility from '../../analytics/AnalyticsUtility';
import { APP_DOWNLOAD_LINK, FOXY_URLS } from '../../config/Constants';


const onCtaPress = (
  url = '',
  id,
  navigation,
  couponCode = {},
  applyOffer = () => {},
  isCardTapped,
  todayDeals = {},
  addContinueActionForEdgeActivation = () => {},
  appOnly = false,
  description = '',
) => {
  if (Utility.isPresent(url)) {
    AnalyticsManager.logEvent(EventType.offer.PROMPT_ACTION_CLICK, {
      offer_id: couponCode.offer_id,
      coupon_code: couponCode.coupon_code,
      prompt_location: 'cart',
      cta_text: Utility.isPresent(couponCode.cta) && couponCode.cta,
      prompt_status: couponCode.status,
    });
  }
  if (isWeb() && appOnly) {
    AnalyticsManager.logEvent(EventType.offer.OFFER_APPLICATION_FAILURE, {
      coupon_code: couponCode?.coupon_code,
      reason: 'App only offer',
    });
    //if (couponCode?.coupon_code === 'FOXYFIRST') {
    //  navigation.navigate('AppInstallPrompt', { from: location.pathname });
    //  return;
    //}
    AnalyticsManager.logEvent(
      EventType.MISC_WEB_EVENTS.APP_ONLY_OFFER_MODAL_VIEW,
      { offer_id: id },
    );
    navigation.navigate('ApplyOnAppOfferModal', {
      couponCode: couponCode?.coupon_code,
      message: description.split('|')[0],
      offerId: id,
    });
    return;
  }
  const { membership_cohort = '' } = todayDeals;
  if (couponCode?.show_invalidity_modal) {
    AnalyticsManager.logEvent('item_action', {
      type: 'edge_activation',
      coupon: couponCode?.coupon_code,
      [EventParameterKey.PREVIOUS_SCREEN]: 'cart',
    });

    const {
      invalid_modal_data: invalidModalData = {},
      invalid_modal_data: {
        data: { minimum_edge_priority = -1 },
      },
    } = couponCode;
    if (isCardTapped || membership_cohort === 'member') {
      navigation.push('UpgradeEdgeModal', {
        previousScreen: SCREEN_CONSTANTS.CART,
        invalidModalData,
      });
    } else {
      addContinueActionForEdgeActivation();
      navigateToScreen({
        navigation,
        type: 'push',
        screen: SCREEN_CONSTANTS.loyalty_plans,
        params: {
          minimumEdgePriority: minimum_edge_priority,
          previousScreen: SCREEN_CONSTANTS.CART,
        },
      });
    }
    return;
  }
  if (couponCode?.initiate_edge_activation) {
    AnalyticsManager.logEvent('item_action', {
      type: 'edge_activation',
      coupon: couponCode?.coupon_code,
      [EventParameterKey.PREVIOUS_SCREEN]: 'cart',
    });

    addContinueActionForEdgeActivation();
    navigateToScreen({
      navigation,
      type: 'push',
      screen: SCREEN_CONSTANTS.loyalty_plans,
      params: {
        previousScreen: SCREEN_CONSTANTS.CART,
      },
    });
    return;
  }

  if (isPresent(url)) {
    AnalyticsManager.logEvent(EventType.offer.PROMPT_ACTION_CLICK, {
      offer_id: couponCode.offer_id,
      coupon_code: couponCode.coupon_code,
      prompt_location: 'cart',
      cta_text: isPresent(couponCode.cta) && couponCode.cta,
      prompt_status: couponCode.status,
    });
    if (url?.includes('choose_free_gift')) {
      navigation.push('ChooseFreeGiftScreen', {
        id,
      });
      return;
    }

    if (url === Config.WEB_URL) {
      navigation.navigate('Feed');
    }

    const promptData = {
      type: 'list',
      previousScreen: 'product_Detail',
      id,
    };

    DynamicLinkManager.handleLinkWithInternalTrackingParams(
      url,
      (route, slug, path, extra) => {
        if (route === 'CartOffers') {
          applyOffer(extra.promoCodeThroughDynamicLink, () => {});
        } else {
          navigateToScreen({
            navigation,
            type: 'push',
            screen: route,
            params: { slug, extra, promptData },
          });
        }
      },
    );
  }
};

const OfferInfoSaveMore = (props) => {
  const {
    coupon,
    description,
    couponCode,
    navigation,
    promptLoading,
    removeOffer,
    setCouponLayoutWidth,
    couponLayoutWidth,
    length,
    applyOffer,
    modalPrimaryAction,
    modalSecondaryAction,
    authToken,
    address,
    date_of_birth,
    todayDeals,
    addContinueActionForEdgeActivation,
    appOnly = false,
  } = props;

  if (isBlank(description)) {
    return null;
  }

  let cta_text = couponCode.cta_text;

  if (couponCode?.initiate_edge_activation) {
    cta_text = 'Activate';
  }

  const onCtaButtonPress = React.memo(() => {
    onCtaPress(
      couponCode.cta_url,
      couponCode.offer_id,
      navigation,
      couponCode,
      applyOffer,
      false,
      todayDeals,
      addContinueActionForEdgeActivation,
      appOnly,
      description,
    );
  });
  const onCardPress = React.memo(() => {
    onCtaPress(
      couponCode.cta_url,
      couponCode.offer_id,
      navigation,
      couponCode,
      applyOffer,
      true,
      todayDeals,
      addContinueActionForEdgeActivation,
      appOnly,
      description,
    );
  });
  return (
    <>
      <DebouncedTouchableOpacity
        {...props}
        style={
          couponCode.status === 'active' || couponCode.status === 'initialized'
            ? [
              styles.saveMoreContainerCta,
              {
                width:
                  length === 1
                    ? Utility.getScreenWidth() - 36
                    : Utility.getScreenWidth() - 108,
              },
            ]
            : [
              styles.saveMoreContainer,
              {
                width:
                  length === 1
                    ? Utility.getScreenWidth() - 36
                    : Utility.getScreenWidth() - 108,
              },
            ]
        }
        onPress={onCardPress.type}
        dataSet={{
          media:
            length === 1
              ? ids.singleCartPromptContainer
              : ids.multipleCartPromptContainer,
        }}
      >
        <View
          style={styles.subHeadingContainerSaveMore}
          dataSet={{ media: ids.subHeadingContainerSaveMore }}
        >
          <Text
            style={styles.subHeadingStyle}
            numberOfLines={2}
            dataSet={{ media: ids.subHeadingStyle }}
          >
            {description}
          </Text>
        </View>
        <View
          style={styles.offerPromptCouponContainer}
          dataSet={{ media: ids.offerPromptCouponContainer }}
        >
          <CouponCodePill
            couponCode={coupon}
            style={{
              paddingHorizontal: 4,
              backgroundColor: '#DADADA40',
              borderColor: '#aaaaaa',
            }}
            style={
              couponCode.status === 'active' ||
              couponCode.status === 'initialized'
                ? {
                    paddingHorizontal: 4,
                    backgroundColor: '#FF5A6014',
                    borderColor: '#FF5A60',
                  }
                : {
                    paddingHorizontal: 4,
                    backgroundColor: '#DADADA40',
                    borderColor: '#aaaaaa',
                  }
            }
            isEdgeOffer={couponCode.is_edge_offer}
          />
          <RoundedButton
            buttonText={isWeb() && appOnly ? `${cta_text} on app` : cta_text}
            buttonTextColor={colors.white}
            buttonColor={'#000'}
            buttonAction={onCtaButtonPress.type}
            style={{ minWidth: 60 }}
            disabled={false}
          />
        </View>
        <View style={styles.couponCodeLeftCutClipperWhiteBorder} />
        <View style={styles.couponCodeRightCutClipperWhiteBorder} />
        {isWeb() && appOnly && (
          <Image
            source={{
              uri: isWebIOS() ? images.appStoreIcon : images.androidIcon,
            }}
            style={styles.icon}
          />
        )}
      </DebouncedTouchableOpacity>
    </>
  );
};

function CartPrompt(props) {
  const {
    couponCodes = [],
    navigation,
    promptLoading,
    removeOffer,
    applyOffer,
    authToken,
    address,
    date_of_birth,
    todayDeals = {},
  } = props;

  const [couponLayoutWidth, setCouponLayoutWidth] = useState(null);
  const dispatch = useDispatch();
  const cartItems = useSelector((state) => state?.bag?.cartItems) || [];

  const AddContinueActionForEdgeActivation = () => {
    const appDomain = Config.WEB_URL;
    if (isPresent(cartItems[0].product?.image_url)) {
      dispatch(
        addContinueAction(
          `${appDomain}/cart`,
          cartItems[0].product?.image_url,
          SCREEN_CONSTANTS.CART,
        ),
      );
    }
  };

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }
  const previousLength = usePrevious(couponCodes.length);
  useEffect(() => {
    if (couponCodes.length !== previousLength) setCouponLayoutWidth(null);
  });

  const suggestedCoupons = _.filter(
    couponCodes,
    (couponCode) =>
      couponCode.status === 'uninitialized' ||
      couponCode.status === 'active' ||
      couponCode.status === 'initialized',
  );

  const appliedCoupons = _.filter(
    couponCodes,
    (couponCode) =>
      couponCode.status === 'partially_redeemed' ||
      couponCode.status === 'redeemed',
  );

  useEffect(() => {
    AnalyticsUtility.firePromptsVisible(couponCodes, 'Cart');
  }, []);
  
  const showMoreOffersStrip = RemoteConfig.getValue(
    REMOTE_CONFIG_KEYS.show_offer_rail_in_cart,
  );

  console.tron.log(showMoreOffersStrip, 'showMoreOffersStrip');

  const RenderItem = ({ item, index }) => (
    <OfferInfoSaveMore
      coupon={item.coupon_code}
      description={item.message}
      couponCode={item}
      navigation={navigation}
      promptLoading={promptLoading}
      setCouponLayoutWidth={setCouponLayoutWidth}
      couponLayoutWidth={couponLayoutWidth}
      length={suggestedCoupons.length}
      applyOffer={applyOffer}
      authToken={authToken}
      address={address}
      date_of_birth={date_of_birth}
      todayDeals={todayDeals}
      addContinueActionForEdgeActivation={AddContinueActionForEdgeActivation}
    />
  );

  return (
    <View style={styles.container}>
      {_.map(appliedCoupons, (couponCode) => {
        return (
          <SlimOfferCoupon
            coupon={couponCode.coupon_code}
            description={couponCode.message}
            couponCode={couponCode}
            navigation={navigation}
            promptLoading={promptLoading}
            removeOffer={removeOffer}
            setCouponLayoutWidth={setCouponLayoutWidth}
            couponLayoutWidth={couponLayoutWidth}
            onCtaPress={onCtaPress}
          />
        );
      })}

      {isPresent(suggestedCoupons) &&
        isPresent(appliedCoupons) &&
        showMoreOffersStrip === 'true' && (
          <Text style={styles.offerTitle}>More Offers</Text>
        )}

      {showMoreOffersStrip === 'true' && (
        <FlatList
          data={suggestedCoupons}
          horizontal
          showsHorizontalScrollIndicator={false}
          snapToAlignment={'start'}
          decelerationRate={'fast'}
          snapToInterval={
            suggestedCoupons.length
              ? Utility.getScreenWidth() - 108
              : Utility.getScreenWidth() - 54
          }
          style={styles.offerStrip}
          contentContainerStyle={styles.bottomSpacing}
          renderItem={RenderItem}
        />
      )}
    </View>
  );
}

export default withNavigation(CartPrompt);
