import React, { Component } from 'react';
import { Text } from 'react-native';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { addMoneyToPaytm } from '../../actions/PaytmActions';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import { PaymentStyles } from '../../components/cart/styles';
import PreferredPaymentss from '../../components/payment/PreferredPayments';
import { PaymentPagePlaceHolder } from '../../components/shared';
import { isDesktop } from '../../utils/BooleanUtility';

class PreferredPayments extends Component {
  constructor(props) {
    super(props);
    this.state = { savedCardCvv: '' };
    this.cvvRef = [];
  }

  setRef = (ref, index) => {
    this.cvvRef[index] = ref;
  };

  setCvvForSavedCard = (cvv) => {
    this.setState({
      savedCardCvv: cvv,
    });
  };

  createOrderAndPayWithPaytm = () => {
    const { createOrder } = this.props;
    const paymentPayload = {
      paymentGateway: 'paytm',
      paymentMethod: 'paytm',
      isSavedPaymentMethod: true,
      paymentMethodPayload: {},
      payuPayload: {},
      extraData: {
        method: 'paytm',
      },
    };
    createOrder(paymentPayload);
  };

  createOrderAndAddMoney = () => {
    const { addMoneyToPaytm, showToast, navigation, renderLoader } = this.props;
    renderLoader(true);
    addMoneyToPaytm((success, response) => {
      renderLoader(false);
      if (success) {
        navigation.navigate('PaytmWebView', {
          paytmPayload: response,
          createOrderAndPayWithPaytm: this.createOrderAndPayWithPaytm,
        });
      } else {
        showToast('Something went wrong');
      }
    });
  };

  render() {
    const {
      savedUpis,
      savedNbs,
      updateExpandableComponent,
      expandedStatus,
      createOrder,
      paytmDetails,
      savedCards,
      isShimmerVisible,
    } = this.props;
    const { savedCardCvv } = this.state;

    if (
      isShimmerVisible &&
      Utility.isBlank(savedUpis) &&
      Utility.isBlank(savedNbs) &&
      Utility.isBlank(savedCards)
    ) {
      return isDesktop() ? null : <PaymentPagePlaceHolder />;
    }

    if (
      Utility.isBlank(savedUpis) &&
      Utility.isBlank(savedNbs) &&
      Utility.isBlank(savedCards) &&
      Utility.isBlank(paytmDetails)
    ) {
      return null;
    }

    return (
      <>
        <Text style={PaymentStyles.preferredMethodsText}>
          Preferred Methods
        </Text>
        <PreferredPaymentss
          savedUpis={savedUpis}
          savedNbs={savedNbs}
          savedCards={savedCards}
          updateExpandableComponent={(paymentOption, index) => {
            try {
              this.cvvRef[index].focus();
            } catch (e) {}
            updateExpandableComponent(paymentOption);
          }}
          expandedStatus={expandedStatus}
          createOrder={createOrder}
          createOrderAndPayWithPaytm={this.createOrderAndPayWithPaytm}
          createOrderAndAddMoney={this.createOrderAndAddMoney}
          setCvvForSavedCard={this.setCvvForSavedCard}
          savedCardCvv={savedCardCvv}
          setRef={this.setRef}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  savedCards: state.UserAccountInfo.checkoutDetails.savedCards,
  savedUpis: state.UserAccountInfo.checkoutDetails.savedUpis,
  savedNbs: state.UserAccountInfo.checkoutDetails.savedNb,
  paymentDetails: state.UserAccountInfo.paymentDetails,
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      addMoneyToPaytm,
    },
    dispatch,
  ),
});

export default withNavigation(
  connect(mapStateToProps, mapDispatchToProps)(PreferredPayments),
);
