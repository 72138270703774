import { lazy } from 'react';

const Banner = lazy(() => import('../../../containers/banner/Banner'));
const Feature = lazy(() => import('../../../containers/feature/Feature'));
const Media = lazy(() => import('../../../containers/media/Media'));
const List = lazy(() => import('../../../containers/List/List'));
const FavouriteList = lazy(() => import('../../../containers/List/FavouriteList'));
const GridScreenPlaceHolder = lazy(() => import('../../shared/GridScreenPlaceHolder'));
const FilterResultsEmptyState = lazy(() => import('../../sort-filter-options/FilterResultsEmptyState'));
const EmptyHeader = lazy(() => import('../EmptyHeader'));
const ListsFilter = lazy(() => import('../ListsFilter'));
const ShortBanner = lazy(() => import('../../banner/ShortBanner'));
const OfferPair = lazy(() => import('../../feature/OfferPair'));

const Components = {
  list: List,
  video: Media,
  image: Media,
  feature: Feature,
  offer: Feature,
  short_banner: ShortBanner,
  favouriteList: FavouriteList,
  quick_filters: ListsFilter,
  quick_filters_header: EmptyHeader,
  shimmer: GridScreenPlaceHolder,
  filters_empty_state: FilterResultsEmptyState,
  banner: Banner,
  offer_pair: OfferPair,
};

export const getVerticalComponent = (type) => {
  return Components[type];
};

export const getVerticalListOffsets = (listData, getListComponentHeight) => {
  const offsetAndHeightList = {};
  let offset = 0;
  listData.forEach((item = {}, index) => {
    let height = 0;

    try {
      height = Components[item.type].getComponentHeight(item);
    } catch (error) {
      if (item.type !== 'list') height = 0;
      else {
        height = getListComponentHeight(item);
      }
    }
    offsetAndHeightList[index] = { length: height, offset };
    offset += height;
  });
  return offsetAndHeightList;
};
