import React from 'react';
import { useRoute } from '@react-navigation/native';
import ShopOnline from './ShopOnline';
import { API_DOMAIN, LISTS_API_VERSION } from '../../config/Constants';

export default function ShopOnlineList() {
  const { params = {} } = useRoute();
  const { name } = params;
  const url = `${API_DOMAIN}/api/${LISTS_API_VERSION}/lists/${name}/shop_online`;
  return <ShopOnline url={url} />;
}
