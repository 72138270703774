import { View, StyleSheet } from 'react-native';
import React from 'react';
import TopBrands from './TopBrands';
import FoxyPromise from './FoxyPromise';
import FoxyPolicies from './FoxyPolicies';
import AppContactDetails from './AppContactDetails';
import { isNative } from '../../utils/BooleanUtility';
import colors from '../../theme/Colors';
import Config from '../../libraries/ReactNativeConfig';
import AppConfig from '../../config/AppConfig';

const showTopBrands = AppConfig.getBooleanValue(Config.SHOW_TOP_BRANDS);
export default function WebFooter() {
  if (isNative()) return null;
  return (
    <View>
      {showTopBrands && <TopBrands />}
      <FoxyPromise />
      <FoxyPolicies />
      <AppContactDetails />
      <View style={styles.emptyView} />
    </View>
  );
}

const styles = StyleSheet.create({
  emptyView: {
    height: 100,
    backgroundColor: colors.background,
  },
});
