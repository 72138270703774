import { View, StyleSheet } from 'react-native';
import React from 'react';
import Config from '../../libraries/ReactNativeConfig';
import NavigationService from '../../navigator/NavigationService';
import { StaticNavigationHeader } from '../shared';
import EmptyState from '../layout/emptyState/Emptystate';
import images from '../../theme/Images';
import colors from '../../theme/Colors';
import Utility from '../../utils/Utility';
import { useDispatch } from 'react-redux';
import { setPhoneNumberModalInfo } from '../../actions/LoginActions';
import { AddressBeforeLoginAppConfig } from '../../config/Constants';

export default function LoginScreen() {
  const dispatch = useDispatch();

  const renderOnboarding = () => {
    dispatch(
      setPhoneNumberModalInfo(Utility.getLoginModalInfo('CHECK_ORDER_HISTORY')),
    );
    NavigationService.renderOnboarding();
  };

  const subtitle = !AddressBeforeLoginAppConfig
    ? `Place your first order on ${Config.APP_NAME}!`
    : '';

  return (
    <>
      <StaticNavigationHeader title="Order History" />
      <View style={styles.container}>
        <EmptyState
          title='No orders found'
          buttonText='Login to see your orders'
          subtitle={subtitle}
          image={images.emptyState.collection}
          onPress={renderOnboarding}
        />
      </View>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.background,
  },
});
