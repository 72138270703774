import React, { PureComponent } from 'react';
import { View } from 'react-native';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { TouchableWithoutFeedback } from 'react-native-gesture-handler';
import Utility from '../../utils/Utility';
import colors from '../../theme/Colors';

import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import NavigationService from '../../navigator/NavigationService';
import FastImageView from '../FastImageView';
import { getMinifiedImage } from '../../utils/ImageUtility';

class FullBanner extends PureComponent {
  navigateToScreen = (path) => {
    const { navigation, authToken, imageUrl } = this.props;

    if (path.includes('login')) {
      if (Utility.isBlank(imageUrl)) {
        navigation.replace('TakeSelfie', {
          previousScreen: SCREEN_CONSTANTS.FULL_SCREEN_SELFIE_BANNER,
          forceUpdatePersonalisedPageAfterSelfie:
            this.forceUpdatePersonalisedPageAfterSelfie,
        });
      } else {
        navigation.navigate('Feed');
        if (Utility.isBlank(authToken)) {
          // Timeout is used to open Onboarding flow as Feed screen is also being presented.
          setTimeout(() => {
            NavigationService.renderOnboarding();
          }, 400);
        }
        Utility.invokeSelfieFlowAfterLoginForGuest();
      }
      return;
    }

    // if (path.includes('login')) {
    //   if (Utility.isPresent(authToken) && Utility.isBlank(imageUrl)) {
    //     navigation.navigate('TakeSelfie', {
    //       previousScreen: 'fullBanner',
    //     });
    //   } else {
    //     navigation.navigate('Feed');
    //     renderOnboarding(true);
    //     Utility.invokeSelfieFlowAfterLoginForGuest();
    //   }
    //   return;
    // }
  };

  forceUpdatePersonalisedPageAfterSelfie = () => {
    console.tron.log('Refreshing Page ==> ', 'refreshing ');
    if (this.props.forceUpdatePersonalisedPageAfterSelfie) {
      setTimeout(() => {
        this.props.forceUpdatePersonalisedPageAfterSelfie();
      }, 500);
    }
  };

  render() {
    let { itemData } = this.props;
    itemData = itemData.objects[0];
    if (itemData === undefined) {
      return null;
    }
    const newImageUrl = getMinifiedImage(
      itemData.image_url,
      Utility.getScreenWidth(),
      Utility.getScreenWidth(),
    );

    return (
      <TouchableWithoutFeedback
        onPress={() => this.navigateToScreen(itemData.destination)}
      >
        <FastImageView
          resizeMode="cover"
          source={newImageUrl}
          style={{
            height: Utility.getScreenHeight() - 150,
            width: '100%',
          }}
        />
      </TouchableWithoutFeedback>
    );
  }
}

const mapStateToProps = function (state) {
  return {
    authToken: state.UserAccountInfo.authToken,
    imageUrl: state.UserAccountInfo.profile.selfie_image_url,
  };
};

export default connect(mapStateToProps, null)(FullBanner);
