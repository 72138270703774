import React from 'react';
import PropTypes from 'prop-types';

import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  AppState,
  Platform,
} from 'react-native';
import _ from 'lodash';
import { sprintf } from 'sprintf-js';
import CountDown from './Counter';
import colors from '../theme/Colors';
import size from '../theme/Fonts';

const DEFAULT_DIGIT_STYLE = { backgroundColor: '#FAB913' };
const DEFAULT_DIGIT_TXT_STYLE = { color: '#000' };
const DEFAULT_TIME_LABEL_STYLE = { color: '#000' };
const DEFAULT_SEPARATOR_STYLE = { color: '#000' };
const DEFAULT_TIME_TO_SHOW = ['D', 'H', 'M', 'S'];
const DEFAULT_TIME_LABELS = {
  d: 'Days',
  h: 'Hours',
  m: 'Minutes',
  s: 'Seconds',
};

class CountDownWithoutText extends React.Component {
  static propTypes = {
    id: PropTypes.string,
    digitStyle: PropTypes.object,
    separatorStyle: PropTypes.object,
    timeToShow: PropTypes.array,
    showSeparator: PropTypes.bool,
    until: PropTypes.number,
    onChange: PropTypes.func,
    onPress: PropTypes.func,
    onFinish: PropTypes.func,
  };

  state = {
    until: Math.max(this.props.until, 0),
    lastUntil: null,
    wentBackgroundAt: null,
  };

  constructor(props) {
    super(props);
    this.timer = setInterval(this.updateTimer, 1000);
  }

  componentDidMount() {
    AppState.addEventListener('change', this._handleAppStateChange);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
    AppState.removeEventListener('change', this._handleAppStateChange);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.endsAt !== this.props.endsAt) {
      this.setState({
        lastUntil: prevState.until,
        until: Math.max(prevProps.until, 0),
      });
    }
  }

  _handleAppStateChange = (currentAppState) => {
    const { until, wentBackgroundAt } = this.state;
    if (
      currentAppState === 'active' &&
      wentBackgroundAt &&
      this.props.running
    ) {
      const diff = (Date.now() - wentBackgroundAt) / 1000.0;
      this.setState({
        lastUntil: until,
        until: Math.max(0, until - diff),
      });
    }
    if (currentAppState === 'background') {
      this.setState({ wentBackgroundAt: Date.now() });
    }
  };

  getTimeLeft = () => {
    const { until } = this.state;
    return {
      seconds: until % 60,
      minutes: parseInt(until / 60, 10) % 60,
      hours: parseInt(until / (60 * 60), 10),
      days: parseInt(until / (60 * 60 * 24), 10),
    };
  };

  updateTimer = () => {
    // Don't fetch these values here, because their value might be changed
    // in another thread
    // const {lastUntil, until} = this.state;

    if (this.state.lastUntil === this.state.until || !this.props.running) {
      return;
    }
    if (
      this.state.until === 1 ||
      (this.state.until === 0 && this.state.lastUntil !== 1)
    ) {
      if (this.props.onFinish) {
        this.props.onFinish();
      }
      if (this.props.onChange) {
        this.props.onChange(this.state.until);
      }
    }

    if (this.state.until === 0) {
      this.setState({ lastUntil: 0, until: 0 });
    } else {
      if (this.props.onChange) {
        this.props.onChange(this.state.until);
      }
      this.setState({
        lastUntil: this.state.until,
        until: Math.max(0, this.state.until - 1),
      });
    }
  };

  renderDoubleDigits = (digits, showRedStyle) => {
    const { showRegularFont = false } = this.props;
    let styleDigitTxt = showRedStyle
      ? styles.redDigitBoldTxt
      : styles.digitBoldTxt;
    if (showRegularFont) {
      styleDigitTxt = showRedStyle ? styles.redDigitTxt : styles.digitTxt;
    }
    return (
      <View style={styles.doubleDigitCont}>
        <Text style={styleDigitTxt}>{digits}</Text>
      </View>
    );
  };

  renderSeparator = (showRedStyle) => {
    const { showRegularFont = false } = this.props;
    let separatorStyle = showRedStyle
      ? styles.separatorBoldTxtRed
      : styles.separatorBoldTxt;
    if (showRegularFont) {
      separatorStyle = showRedStyle
        ? styles.separatorTxtRed
        : styles.separatorTxt;
    }
    return (
      <View
        style={{
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Text style={separatorStyle}>{':'}</Text>
      </View>
    );
  };

  renderCountDown = () => {
    const { timeToShow, showSeparator } = this.props;
    const { days, hours, minutes, seconds } = this.getTimeLeft();
    let showRedStyle = false;
    if (hours < 6) {
      showRedStyle = true;
    }
    const newTime = sprintf(
      '%02d:%02d:%02d:%02d',
      days,
      hours,
      minutes,
      seconds,
    ).split(':');
    const Component = this.props.onPress ? TouchableOpacity : View;

    return (
      <Component style={styles.timeCont} onPress={this.props.onPress}>
        {timeToShow.includes('D')
          ? this.renderDoubleDigits(newTime[0], showRedStyle)
          : null}
        {showSeparator && timeToShow.includes('D') && timeToShow.includes('H')
          ? this.renderSeparator(showRedStyle)
          : null}
        {timeToShow.includes('H')
          ? this.renderDoubleDigits(newTime[1], showRedStyle)
          : null}
        {showSeparator && timeToShow.includes('H') && timeToShow.includes('M')
          ? this.renderSeparator(showRedStyle)
          : null}
        {timeToShow.includes('M')
          ? this.renderDoubleDigits(newTime[2], showRedStyle)
          : null}
        {showSeparator && timeToShow.includes('M') && timeToShow.includes('S')
          ? this.renderSeparator(showRedStyle)
          : null}
        {timeToShow.includes('S')
          ? this.renderDoubleDigits(newTime[3], showRedStyle)
          : null}
      </Component>
    );
  };

  render() {
    return <this.renderCountDown />;
  }
}

CountDownWithoutText.defaultProps = {
  digitStyle: DEFAULT_DIGIT_STYLE,
  separatorStyle: DEFAULT_SEPARATOR_STYLE,
  timeToShow: DEFAULT_TIME_TO_SHOW,
  showSeparator: false,
  until: 0,
  running: true,
};

const styles = StyleSheet.create({
  timeCont: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  timeTxt: {
    color: colors.black,
    marginVertical: 2,
    backgroundColor: 'transparent',
    alignSelf: 'flex-start',
  },
  timeInnerCont: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  digitCont: {
    borderRadius: 3,
    marginHorizontal: 2,
    alignItems: 'center',
    justifyContent: 'center',
  },
  doubleDigitCont: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  digitBoldTxt: {
    color: colors.black,
    fontFamily: 'Roboto-Bold',
    fontSize: size.h3,
  },
  redDigitBoldTxt: {
    color: '#ED5239',
    fontFamily: 'Roboto-Bold',
    fontSize: size.h3,
  },
  digitTxt: {
    color: colors.black,
    fontFamily: 'Roboto',
    fontSize: size.h3,
  },
  redDigitTxt: {
    color: '#ED5239',
    fontFamily: 'Roboto',
    fontSize: size.h3,
  },
  separatorBoldTxt: {
    color: colors.black,
    fontFamily: 'Roboto-Medium',
    fontSize: size.h2,
    marginHorizontal: 2,
    ...Platform.select({
      web: { marginBottom: 2 },
      default: { marginBottom: 4 },
    }),
  },
  separatorBoldTxtRed: {
    color: '#ED5239',
    fontFamily: 'Roboto-Medium',
    fontSize: size.h2,
    marginHorizontal: 2,
    ...Platform.select({
      web: { marginBottom: 2 },
      default: { marginBottom: 4 },
    }),
  },
  separatorTxt: {
    color: colors.black,
    fontFamily: 'Roboto',
    fontSize: size.h2,
    marginHorizontal: 2,
    ...Platform.select({
      web: { marginBottom: 2 },
      default: { marginBottom: 4 },
    }),
  },
  separatorTxtRed: {
    color: '#ED5239',
    fontFamily: 'Roboto',
    fontSize: size.h2,
    marginHorizontal: 2,
    ...Platform.select({
      web: { marginBottom: 2 },
      default: { marginBottom: 4 },
    }),
  },
});

export default CountDownWithoutText;
