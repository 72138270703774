import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import colors from '../../../theme/Colors';
import RemoteConfig from '../../../utils/RemoteConfig';
import { REMOTE_CONFIG_KEYS } from '../../../config/Constants';
import { jsonParser } from '../../../utils/JsonUtility';
import FastImageView from '../../FastImageView';
import { getMinifiedImage } from '../../../utils/ImageUtility';

const CancellationAndRefundPolicies = (props) => {
  const returnRequestData =
    jsonParser(RemoteConfig.getValue(REMOTE_CONFIG_KEYS.return_request_data)) ||
    {};

  const { return_policies } = returnRequestData;

  return (
    <View style={styles.container}>
      <Text style={styles.header}>Cancellations and Refunds</Text>
      {return_policies.map((returnPolicy = {}) => {
        const { image = '', policy = '' } = returnPolicy;
        return (
          <View style={styles.policyContainer}>
            <FastImageView
              source={getMinifiedImage(
                image,
                styles.policyImage.width,
                styles.policyImage.height,
              )}
              style={styles.policyImage}
            />
            <Text style={styles.policyText}>{policy}</Text>
          </View>
        );
      })}
    </View>
  );
};

export default CancellationAndRefundPolicies;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    backgroundColor: colors.white,
    padding: 12,
    width: '100%',
  },
  header: {
    fontSize: 14,
    fontFamily: 'Roboto-Medium',
    color: colors.foxyBlack,
    marginBottom: 8,
  },
  policyText: {
    fontSize: 12,
    fontFamily: 'Roboto-Regular',
    color: colors.foxyBlack,
    flex: 1,
  },
  policyImage: {
    width: 20,
    height: 20,
    tintColor: colors.foxyBlack,
    marginRight: 12,
    resizeMode: 'contain',
  },
  policyContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: 8,
  },
});
