import React, { PureComponent } from 'react';
import { View, StyleSheet, TouchableOpacity, Image } from 'react-native';
import _ from 'lodash';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Card from '../../lib/Card';
import { withEither } from '../../lib/Monads';
import ProductImageAndRating from './ProductImageAndRating';
import ProductDescription from './ProductDescription';
import { PRODUCT } from '../../config/LayoutConstants/ProductConfig';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import IncrementDecrement from './IncrementDecrement';
import ScaleAnimate from '../shared/ScaleAnimate';
import { LAYOUT } from '../../config/Constants';
import colors from '../../theme/Colors';
import ProductColorVariants from '../variants/ProductColorVariants';
import ProductOtherVariants from '../variants/ProductOtherVariants';
import ProductSizeVariants from '../variants/ProductSizeVariants';

import { addMyBrowsing } from '../../actions/UserInteractionsActions';
import {
  applyFlashDeal,
  setSearchResultClicks,
} from '../../actions/ActionTypes';
import GridStyles from '../layout/Grid/styles';
import images from '../../theme/Images';
import { isDesktop } from '../../utils/BooleanUtility';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';

const numOfItems = isDesktop() ? 4 : 2;

class DefaultSizeProductSimpleGrid extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      openProductDetailModal: false,
    };
  }

  productShadesCondition = ({ variantsPropertyName }) =>
    variantsPropertyName === 'Shade';

  productSizeCondition = ({ variantsPropertyName }) =>
    variantsPropertyName === 'Size';

  conditionalVariants = compose(
    withEither(this.productShadesCondition, ProductColorVariants),
    withEither(this.productSizeCondition, ProductSizeVariants),
  )(ProductOtherVariants);

  addToCart = (props) => {
    const { skuId, orientation, id, itemData, force_apply_coupon, offersData } =
      props;
    const { previousScreen, showToast, listId, offer_id } = this.props;

    let { outOfStock = false } = props;
    if (outOfStock === null) {
      outOfStock = true;
    }

    return (
      <IncrementDecrement
        skuId={skuId}
        orientation={orientation}
        toggleCartState={this.toggleCartState}
        layout={LAYOUT.GRID}
        addToCartLayout={PRODUCT.ADD_TO_CART_LAYOUT.PRODUCT_CARD}
        id={id}
        itemData={itemData}
        outOfStock={outOfStock}
        previousScreen={previousScreen}
        showToast={showToast}
        listId={listId}
        force_apply_coupon={force_apply_coupon}
        offer_id={offer_id}
        offersData={offersData}
      />
    );
  };

  productVariants = (props) => {
    const {
      itemData: { image_url: imageUrl, variants_details: variantDetails = {} },
    } = this.props;
    const { containerStyle } = props;

    const {
      variants_count: variantsCount = 0,
      variants_shade_images: variantShadeImages = [],
      variants_property_name: variantPropertyName = '',
    } = variantDetails;

    return (
      <this.conditionalVariants
        variantImage={imageUrl}
        variantsCount={variantsCount}
        variantShadesImages={variantShadeImages}
        variantsPropertyName={variantPropertyName}
        containerStyle={containerStyle}
      />
    );
  };

  showProductDetailModal = () => {
    const {
      navigation,
      itemData,
      campaignId,
      itemData: {
        id,
        slug,
        variants_details = {},
        has_variants = false,
        replacement_sku,
        stocked_status,
        name = '',
      } = {},
      toggleCartVisibility,
      previousScreen,
      addMyBrowsing,
      applyFlashDeal,
      force_apply_coupon,
      offer_id,
      listId,
      offersData: {
        initiate_edge_activation = false,
        show_invalidity_modal = false,
        invalidModalData = {},
      } = {},
    } = this.props;

    if (initiate_edge_activation) {
      navigation.push(SCREEN_CONSTANTS.BECOME_MEMBER_MODAL, {
        previousScreen,
      });
      return;
    }
    if (show_invalidity_modal) {
      navigation.push(SCREEN_CONSTANTS.UPGRADE_EDGE_MODAL, {
        previousScreen,
        invalidModalData,
      });
      return;
    }

    if (has_variants) {
      navigation.navigate('VariantModal', {
        itemData,
        listId,
        comboOffer: true,
        offer_id,
        campaignId,
        force_apply_coupon,
      });
      return;
    }
    navigation.push('ProductDetailModal', {
      id,
      slug,
      name: itemData.name,
      display: LAYOUT.SCREEN,
      itemData,
      toggleCartVisibility,
      variants_details,
      previousScreen: this.props?.previousScreen,
      force_apply_coupon,
      offer_id,
      listId,
    });
  };

  productCardRightTopIcon = () => {
    const { showEditButton, removeFromList, itemData } = this.props;

    if (!showEditButton) {
      return null;
    }

    return (
      <TouchableOpacity
        onPress={() => removeFromList(itemData)}
        hitSlop={Utility.getHitSlop()}
        style={GridStyles.trashIconContainer}
      >
        <Image
          source={images.previewScreenIcons.delete}
          style={GridStyles.trashIcon}
        />
      </TouchableOpacity>
    );
  };

  render() {
    const {
      orientation,
      previousScreen,
      itemData,
      hideAddToCart,
      hideActivityIndicator,
      itemData: {
        offer_value,
        brand_name: variantBrandName,
        images,
        webp_images,
        image_webp_url,
        rating,
        id,
        name = '',
        mrp,
        discount,
        brand: { name: brandName } = {},
        sku_id: skuId,
        prompts,
        has_variants: hasVariants,
        outOfStock,
        priority,
        image_url: imageUrl,
        single_stocked_variant: singleStockedVariantSku,
      } = {},
      showEditButton,

      force_apply_coupon,
      offer_id,
      offersData,
    } = this.props;

    return (
      <View>
        <ScaleAnimate {...this.props} onPress={this.showProductDetailModal}>
          <Card style={styles.cardContainer}>
            <View style={styles.imageContainer}>
              <ProductImageAndRating
                layout={LAYOUT.GRID}
                rating={rating}
                images={images}
                itemData={itemData}
                webp_images={webp_images}
                image_webp_url={image_webp_url}
                imageUrl={imageUrl}
                id={id}
                name={name}
                previousScreen={previousScreen}
                hideActivityIndicator={hideActivityIndicator}
                isVariant={hasVariants}
                showEditButton={showEditButton}
                hideTag
              />
            </View>
            <View style={styles.fullCardDivider} />
            <View style={{ flex: 1 }}>
              <ProductDescription
                itemData={itemData}
                name={name}
                mrp={mrp}
                offer_value={offer_value}
                sp={mrp}
                display_price={mrp}
                discount={discount}
                brandName={brandName}
                variantBrandName={variantBrandName}
                layout={LAYOUT.GRID}
                id={id}
                previousScreen={previousScreen}
                hideActivityIndicator={hideActivityIndicator}
                disableTodayDealsOrPromptMessage
              />
            </View>

            <this.addToCart
              skuId={skuId}
              orientation={orientation}
              toggleCartState={this.toggleCartState}
              layout={LAYOUT.GRID}
              addToCartLayout={PRODUCT.ADD_TO_CART_LAYOUT.PRODUCT_CARD}
              hideAddToCart={hideAddToCart}
              id={id}
              itemData={itemData}
              hasVariants={hasVariants}
              priority={priority}
              outOfStock={outOfStock}
              previousScreen={previousScreen}
              singleStockedVariantSku={singleStockedVariantSku}
              prompts={prompts}
              hideActivityIndicator={hideActivityIndicator}
              force_apply_coupon={force_apply_coupon}
              offer_id={offer_id}
              offersData={offersData}
            />
          </Card>
          <this.productVariants containerStyle={styles.variantContainerStyle} />
        </ScaleAnimate>
        <this.productCardRightTopIcon />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  cardContainer: {
    backgroundColor: colors.white,
    borderColor: colors.borderColor,
    margin: 4,
    flexDirection: 'column',
    borderRadius: 4,
    width: Utility.getDynamicWidthForGrid(numOfItems, 16),
    height: Utility.getDynamicWidthForGrid(numOfItems, 16) + 148,
    alignSelf: 'center',
  },
  imageContainer: {
    height: Utility.getDynamicWidthForGrid(numOfItems, 16 - 32),
  },
  fullCardDivider: {
    height: 1,
    width: '100%',
    backgroundColor: colors.borderColor,
  },
  cartImageContainer: {
    position: 'absolute',
    bottom: 78,
    right: 10,
  },
  variantContainerStyle: {
    position: 'absolute',
    left: 12,
    top: 16,
    borderWidth: 1,
    borderColor: colors.border,
    borderRadius: 15,
    maxWidth: 84,
    padding: 2,
    backgroundColor: '#ffffff',
  },
});

const mapStateToProps = (state) => ({
  myBrowsing: state.userInteractions.myBrowsing,
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      addMyBrowsing,
      applyFlashDeal,
      setSearchResultClicks,
    },
    dispatch,
  ),
});

export default withNavigation(
  connect(mapStateToProps, mapDispatchToProps)(DefaultSizeProductSimpleGrid),
);
