import React, { Fragment } from 'react';
import { View, Text, TouchableOpacity, Image } from 'react-native';
import StyleSheet from 'react-native-media-query';
import { ProductDetailStyles } from './styles';
import images from '../../theme/Images';
import ActionButton from '../shared/ActionButton';
import FastImageView from '../FastImageView';
import { isDesktop, isPresent } from '../../utils/BooleanUtility';
import { getScreenHeight, getScreenWidth } from '../../utils/LayoutUtility';
import { getMinifiedImage } from '../../utils/ImageUtility';

const CertificateModal = ({ navigation, route }) => {
  const crossButton = () => (
    <TouchableOpacity
      style={ProductDetailStyles.crossButtonInCertificate}
      onPress={navigation.goBack}
    >
      <View style={ProductDetailStyles.crossButtonImageInCertificate}>
        <Image
          source={images.cross}
          style={ProductDetailStyles.crossButtonForModal}
        />
      </View>
    </TouchableOpacity>
  );

  const sellerInfo = ({ seller: { name, verified } = {} }) => (
    <View style={ProductDetailStyles.sellerContainer}>
      {isPresent(name) && (
        <>
          <Text style={ProductDetailStyles.variantNameTextStyle}>Sold by:</Text>
          <Text style={ProductDetailStyles.greyTextStyleWhite}>{name}</Text>
          <Image
            verified={verified}
            source={images.verifiedTick}
            style={ProductDetailStyles.verifiedTick}
          />
        </>
      )}
    </View>
  );

  const certificate = ({ certificateImageUrl, seller }) => {
    const { params: { heading, subheading } = {} } = route;

    return (
      <View style={ProductDetailStyles.certificateContainer}>
        <Text style={ProductDetailStyles.certificateHeader}>{heading}</Text>
        <Text style={ProductDetailStyles.certificateSubtitle}>
          {subheading}
        </Text>
        {sellerInfo({ seller })}
        {isPresent(certificateImageUrl) && (
          <FastImageView
            source={getMinifiedImage(
              certificateImageUrl,
              ProductDetailStyles.certificateImage.width,
              ProductDetailStyles.certificateImage.height,
            )}
            style={ProductDetailStyles.certificateImage}
            dataSet={{ media: ids.certificateImage }}
            resizeMode='contain'
          />
        )}
      </View>
    );
  };

  const data = route?.params?.data || {};
  const { certificate_image_webp_url: certificateImageUrl, name: brandName } =
    data?.brand || {};
  const ContainerComponent = isDesktop() ? View : Fragment;
  return (
    <ContainerComponent style={styles.container}>
      <TouchableOpacity
        onPress={navigation.goBack}
        style={ProductDetailStyles.certificateModalContainer}
        dataSet={{ media: ids.certificateModalContainer }}
      />
      <View style={ProductDetailStyles.certificateModal}>
        {certificate({
          certificateImageUrl,
          brandName,
          seller: data?.seller,
        })}
      </View>
      <ActionButton
        layoutStyle={ProductDetailStyles.certificateModalActionButton}
        title='Got it!'
        onActionPress={navigation.goBack}
      />
      {crossButton()}
    </ContainerComponent>
  );
};

export default CertificateModal;

const { styles, ids } = StyleSheet.create({
  certificateModalContainer: {
    '@media (min-width: 800px)': {
      width: getScreenWidth() / 2,
      height: getScreenHeight() / 2 - 80,
      marginTop: 28,
    },
  },
  certificateImage: {
    '@media (min-width: 800px)': {
      height: '70%',
    },
  },
  container: { alignSelf: 'center' },
});
