import React from 'react';
import { Pressable } from 'react-native';
import PropTypes from 'prop-types';
import { getStoreRef } from '../../store/StoreUtility';

const edgeScreens = ['foxy_edge_non_member', 'foxy_edge_expired'];

const ScaleAnimate = ({
  onPress,
  onLongPress,
  children,
  scaleValue = 0.94,
  disabled = false,
  navigation,
  listData: { options: { is_tappable: isTappable = false } = {} } = {},
  previousScreen,
}) => {

  const handleOnPress = () => {
    if (edgeScreens.includes(previousScreen) && !isTappable) {
      const { membership_cohort: membershipCohort } = getStoreRef().getState().todayDeals || {};
      if (membershipCohort !== 'member') {
        navigation.push('LoyaltyPlans', {
          previousScreen,
        });
        return;
      }
    }
    onPress();
  };

  return (
    <Pressable
      disabled={disabled}
      onPress={handleOnPress}
      onLongPress={onLongPress}
    >
      {children}
    </Pressable>
  );
};

ScaleAnimate.defaultProps = {
  onPress: () => {},
  onLongPress: () => {},
  scaleValue: 0.94,
};

ScaleAnimate.propTypes = {
  onPress: PropTypes.func,
  onLongPress: PropTypes.func,
  children: PropTypes.any,
  scaleValue: PropTypes.number,
};

export default ScaleAnimate;
