import { AnalyticsManager, EventParameterKey, EventType } from '../../analytics';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';

export default class FilterAnalyticsEvents {
  static logQuickFilterApplyEvent = (
    filter,
    list,
    index,
    screenName = SCREEN_CONSTANTS.MORE_PAGE,
    entityName = '',
    hideOosProduct,
  ) => {
    const { criteria_name, value } = filter;
    const { id, name } = list;
    AnalyticsManager.logEvent(EventType.filters.QUICK_FILTER_APPLY, {
      [EventParameterKey.FILTER_OPTION]: criteria_name,
      [EventParameterKey.FILTER_VALUE]: value,
      [EventParameterKey.FILTER_POSITION]: index,
      [EventParameterKey.LIST_ID]: id,
      [EventParameterKey.LIST_NAME]: name,
      [EventParameterKey.SCREEN_NAME]: screenName,
      [EventParameterKey.ENTITY_NAME]: entityName,
      [EventParameterKey.FILTER_HIDE_OOS]: hideOosProduct,
    });
  };

  static logQuickFilterResetEvent = (
    filter,
    list,
    index,
    screenName = SCREEN_CONSTANTS.MORE_PAGE,
    entityName = '',
  ) => {
    const { criteria_name, value } = filter;
    const { id, name } = list;
    AnalyticsManager.logEvent(EventType.filters.QUICK_FILTER_REMOVE, {
      [EventParameterKey.FILTER_OPTION]: criteria_name,
      [EventParameterKey.FILTER_VALUE]: value,
      [EventParameterKey.FILTER_POSITION]: index,
      [EventParameterKey.LIST_ID]: id,
      [EventParameterKey.LIST_NAME]: name,
      [EventParameterKey.SCREEN_NAME]: screenName,
      [EventParameterKey.ENTITY_NAME]: entityName,
    });
  };

  static logFilterModalOpenEvent = (
    filtersOptions = [],
    list,
    screenName,
    entityName,
  ) => {
    const { id, name, slug = '' } = list;
    AnalyticsManager.logEvent(EventType.filters.FILTER_MODAL_OPEN, {
      [EventParameterKey.FILTERS_OPTION_VISIBLE]: filtersOptions.join(','),
      [EventParameterKey.LIST_ID]: id,
      [EventParameterKey.LIST_NAME]: name,
      [EventParameterKey.SCREEN_NAME]: screenName,
      [EventParameterKey.ENTITY_NAME]: entityName,
      [EventParameterKey.LIST_SLUG]: slug,
    });
  };

  static logFilterApplyEvent = (
    selectedFilters,
    list,
    screenName,
    entityName = '',
    hideOosProduct,
  ) => {
    const selectedFiltersObject = {};
    const { id, name, slug = '' } = list;
    for (const key in selectedFilters) {
      selectedFiltersObject[key] = selectedFilters[key].map((element) => element.value).join(',');
    }
    AnalyticsManager.logEvent(EventType.filters.FILTER_APPLY, {
      ...selectedFiltersObject,
      id,
      name,
      [EventParameterKey.SCREEN_NAME]: screenName,
      [EventParameterKey.ENTITY_NAME]: entityName,
      [EventParameterKey.SLUG]: slug,
      [EventParameterKey.FILTER_HIDE_OOS]: hideOosProduct,
    });
  };

  static logFilterValueUnselectEvent = (
    item,
    optionName,
    position,
    list,
    entityName,
    screenName,
  ) => {
    const { id, name, slug } = list;
    let { value } = item;
    if (Array.isArray(value)) {
      value = value.join(',');
    }

    AnalyticsManager.logEvent(EventType.filters.FILTER_VALUE_UNSELECT, {
      [EventParameterKey.FILTER_POSITION]: position,
      [EventParameterKey.LIST_ID]: id,
      [EventParameterKey.LIST_NAME]: name,
      [EventParameterKey.LIST_SLUG]: slug,
      [EventParameterKey.FILTER_OPTION]: optionName,
      [EventParameterKey.FILTER_VALUE]: value,
      [EventParameterKey.ENTITY_NAME]: entityName,
      [EventParameterKey.SCREEN_NAME]: screenName,
    });
  };

  static logFilterValueSelectEvent = (
    item,
    optionName,
    position,
    list,
    entityName,
    screenName,
  ) => {
    const { id, name, slug } = list;
    let { value } = item;

    if (Array.isArray(value)) {
      value = value.join(',');
    }

    AnalyticsManager.logEvent(EventType.filters.FILTER_VALUE_SELECT, {
      [EventParameterKey.FILTER_POSITION]: position,
      [EventParameterKey.LIST_ID]: id,
      [EventParameterKey.LIST_NAME]: name,
      [EventParameterKey.LIST_SLUG]: slug,
      [EventParameterKey.FILTER_OPTION]: optionName,
      [EventParameterKey.FILTER_VALUE]: value,
      [EventParameterKey.ENTITY_NAME]: entityName,
      [EventParameterKey.SCREEN_NAME]: screenName,
    });
  };

  static logFilterResultsEmptyEvent = (selectedFilters, list) => {
    const selectedFiltersObject = {};
    const { id, name, slug } = list;
    for (const key in selectedFilters) {
      selectedFiltersObject[key] = selectedFilters[key].map((element) => element.value).join(',');
    }

    AnalyticsManager.logEvent(EventType.filters.FILTER_RESULT_EMPTY, {
      ...selectedFiltersObject,
      id,
      name,
      slug,
    });
  };

  static logFilterResetEvent = (
    source = 'reset_button',
    screenName = SCREEN_CONSTANTS.MORE_PAGE,
    entityName = '',
    list = {},
  ) => {
    const { id = '', name = '', slug = '' } = list;
    AnalyticsManager.logEvent(EventType.filters.FILTER_RESET, {
      [EventParameterKey.SCREEN_NAME]: screenName,
      [EventParameterKey.ENTITY_NAME]: entityName,
      [EventParameterKey.SOURCE]: source,
      [EventParameterKey.LIST_ID]: id,
      [EventParameterKey.LIST_NAME]: name,
      [EventParameterKey.LIST_SLUG]: slug,
    });
  };
}
