import React, { PureComponent } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import colors from '../../../theme/Colors';
import size from '../../../theme/Fonts';
import CircularProgress from './CircularProgress';
import { getScreenWidth } from '../../../utils/LayoutUtility';
import { isBlank } from '../../../utils/BooleanUtility';

class RoutineTotalProgressCard extends PureComponent {
  render() {
    const {
      progress = 60,
      pointsExist,
      isRailCard,
      completedMessage = '',
    } = this.props;
    const containerStyle =
      pointsExist && isRailCard
        ? styles.container
        : styles.fullWidthCardContainer;
    const progressCardMessage = isBlank(completedMessage)
      ? `You only completed ${progress}% of the campaign. But hey, a start is a start! Remember, consistency is key to forming a habit!`
      : completedMessage;
    return (
      <LinearGradient
        colors={[colors.peach, colors.light_peach]}
        style={containerStyle}
        start={{ x: 0, y: 0 }}
        end={{ x: 0, y: 1 }}
      >
        <CircularProgress size={100} strokeWidth={8} progress={progress} />
        <Text style={styles.text}>{progressCardMessage}</Text>
      </LinearGradient>
    );
  }
}

export default RoutineTotalProgressCard;

const styles = StyleSheet.create({
  container: {
    borderRadius: 16,
    flexDirection: 'row',
    marginTop: 25,
    overflow: 'visible',
    marginHorizontal: 6,
    alignItems: 'center',
    width: getScreenWidth() - 60,
  },
  fullWidthCardContainer: {
    borderRadius: 16,
    flexDirection: 'row',
    marginTop: 25,
    overflow: 'visible',
    marginHorizontal: 6,
    alignItems: 'center',
    width: getScreenWidth() - 24,
  },
  text: {
    color: colors.black,
    fontFamily: 'Roboto-Regular',
    fontSize: size.h3,
    marginLeft: 20,
    flexWrap: 'wrap',
    flex: 1,
    paddingRight: 6,
  },
});
