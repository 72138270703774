import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import DebouncedTouchableOpacity from '../../components/shared/DebouncedTouchableOpacity';
import Utility from '../../utils/Utility';
import images from '../../theme/Images';
import colors from '../../theme/Colors';
import size from '../../theme/Fonts';
import NavigationService from '../../navigator/NavigationService';
import DynamicLinkManager from '../../utils/DynamicLinkManager';
import FastImageView from '../../components/FastImageView';

const RoutineActivityHeader = ({ item }) => {
  const {
    headerIconUrl = images.routines.sample_action_card_image,
    title = '',
    duration = '',
    points = '',
    supportingUrl,
  } = item;
  const image = Utility.isBlank(headerIconUrl)
    ? images.routines.sample_action_card_image
    : headerIconUrl;

  const navigateToScreen = (path, destination) => {
    NavigationService.push(path, {
      newDestination: destination,
      slug: destination,
    });
  };

  const onPlayPress = () => {
    DynamicLinkManager.handleDynamicLink(supportingUrl, navigateToScreen);
  };

  return (
    <View style={styles.activityCardHeaderContainer}>
      <FastImageView source={image} style={styles.activityCardHeaderIcon} />
      <View style={styles.activityCardTitleContainer}>
        <Text
          style={styles.activityCardTitle}
          numberOfLines={1}
          ellipsizeMode='tail'
        >
          {title}
        </Text>
        <Text style={styles.subtitle}>
          {duration} mins <Text style={styles.point}>•</Text> {points} points
        </Text>
      </View>
      {Utility.isPresent(supportingUrl) && (
        <DebouncedTouchableOpacity onPress={() => onPlayPress()}>
          <FastImageView
            source={images.routines.play_icon}
            style={styles.playIcon}
          />
        </DebouncedTouchableOpacity>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  activityCardHeaderContainer: {
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    paddingTop: 12,
    paddingHorizontal: 12,
  },
  activityCardTitleContainer: {
    flex: 1,
  },
  activityCardTitle: {
    color: colors.black,
    fontFamily: 'Roboto-Bold',
    fontSize: size.h1,
  },
  activityCardHeaderIcon: {
    width: 32,
    height: 32,
    marginRight: 12,
    resizeMode: 'contain',
  },
  subtitle: {
    color: colors.foxyBlack,
    fontSize: size.h4,
    fontFamily: 'Roboto-Regular',
    marginTop: 4,
  },
  point: {
    color: colors.subtitle,
    width: 4,
    height: 4,
  },
  playIcon: {
    width: 24,
    height: 24,
    resizeMode: 'contain',
  },
});

export default RoutineActivityHeader;
