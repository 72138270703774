import React, { PureComponent } from 'react';
import {
  View,
  Text,
  StyleSheet,
  FlatList,
  Animated,
  TouchableOpacity,
  BackHandler,
} from 'react-native';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import size from '../../theme/Fonts';
import colors from '../../theme/Colors';
import withNavigation from '../../utils/WithNavigation';
import { MoEReactInbox } from '../../libraries/ReactMoe';
import Utility from '../../utils/Utility';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import { CustomNavigationHeader } from '../Product';
import DockedHeader from '../../utils/DockedHeader';
import { AnalyticsManager, EventType } from '../../analytics';
import DynamicLinkManager from '../../utils/DynamicLinkManager';
import {
  getListDetails,
  applyCartCoupon,
  updateLocalNotificationData,
} from '../../actions/ActionTypes';
import { updateMediaId } from '../../actions/MediaDetailActions';
import NavigationService from '../../navigator/NavigationService';
import { LINK_DOMAIN, LINK_DOMAIN_SHORT } from '../../config/Constants';
import style from '../onboarding/Styles/ImageReviewStyles';
import FastImageView from '../FastImageView';
import { goBackWithFallbackHome, navigateToScreen } from '../../utils/NavigationUtility';

class NotificationHistory extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      inboxData: [],
      notifications: [],
      tappedIndices: new Map(),
      scrollY: new Animated.Value(0),
    };

    this.debouncedNavigate = _.debounce(this.handleRouteFromLink, 2000, {
      leading: true,
      trailing: false,
    });
    if (Utility.isAndroid()) {
      this.backHandler = BackHandler.addEventListener(
        'hardwareBackPress',
        this.goBack,
      );
    }
    this.getMessages();
    this.initialRenderItems = 30;
  }

  componentDidMount() {
    const { navigation } = this.props;
    this.unsubscribe = navigation.addListener('focus', () => {
      this.onFocus();
    });
  }

  componentWillUnmount() {
    this.unsubscribe();
    if (Utility.isAndroid()) {
      this.backHandler?.remove();
    }
  }

  sortNotifications = (a, b) => {
    const date1 = new Date(a.receivedTime);
    const date2 = new Date(b.receivedTime);
    return date2 - date1;
  };

  getMessages = async () => {
    if (Utility.isWeb()) return;
    const { localNotificationData = [] } = this.props;
    const data = await MoEReactInbox.fetchAllMessages();
    const notificationData = [...data.messages, ...localNotificationData];

    const sortedNotification = notificationData.sort(this.sortNotifications);

    if (
      Utility.isPresent(data?.messages) ||
      Utility.isPresent(localNotificationData)
    ) {
      this.setState(
        {
          inboxData: sortedNotification,
        },
        this.paginate,
      );
    }
  };

  handleRouteFromLink = (route, slug, path, extra = {}, params, url) => {
    const { authToken, navigation } = this.props;

    if (extra.externalActionRequired) {
      if (route === 'ContentModal' && path !== 'youtube_offer') {
        const { listId, videoId } = extra;
        this.getVideoListDetailById(listId, videoId);
      }
    } else if (Utility.isPresent(authToken)) {
      navigateToScreen({
        navigation,
        type: 'push',
        screen: route,
        params: {
          slug,
          extra,
          params,
          url,
          destination: url,
        },
      });
    } else {
      navigateToScreen({
        navigation,
        type: 'push',
        screen: route,
        params: {
          slug,
          extra,
          params,
          destination: url,
        },
      });
    }
  };

  getVideoListDetailById = (listId, videoId) => {
    const { getListDetails } = this.props;
    getListDetails(listId, (success, response) => {
      if (!success) {
        return;
      }
      const selectedVideoIndex = Utility.deriveIndexFromListObject(
        response?.objects,
        videoId,
      );
      NavigationService.push('ContentModal', {
        listId,
        index: selectedVideoIndex,
        itemData: response.objects[selectedVideoIndex],
        listData: response,
      });
    });
  };

  handleLink = (action, callbackHandler, internalNavigation) => {
    if (action.includes(LINK_DOMAIN_SHORT)) {
      DynamicLinkManager.resolveLink(
        action,
        callbackHandler,
        internalNavigation,
      );
    } else {
      DynamicLinkManager.handleDynamicLink(
        action,
        callbackHandler,
        internalNavigation,
      );
    }
  };

  handleNotificationTapAndDeepLink = (notification, index) => {
    const { updateLocalNotificationData } = this.props;
    const { action = [] } = notification;
    updateLocalNotificationData(notification.type);

    const { tappedIndices } = this.state;

    tappedIndices.set(index, true);
    this.setState({
      tappedIndices,
    });

    if (Utility.isPresent(notification.deepLink)) {
      DynamicLinkManager.handleDynamicLink(
        notification.deepLink,
        this.debouncedNavigate,
        false,
      );
      return;
    }

    if (!Utility.isWeb()) {
      MoEReactInbox.trackMessageClicked(notification);
    }
    if (action.length > 0 && Utility.isPresent(action[0].value)) {
      const link = action[0].value;
      if (link?.includes(LINK_DOMAIN)) {
        DynamicLinkManager.resolveLink(link, this.debouncedNavigate, true);
        return;
      }
      DynamicLinkManager.handleDynamicLink(
        action[0].value,
        this.debouncedNavigate,
        false,
      );
    }

    if (!notification.isClicked) {
      this.getMessages();
    }
  };

  notificationCard = ({ item, index }) => {
    const date = new Date(item.receivedTime);
    const shortDate = `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
    const { tappedIndices } = this.state;
    const cardContainerStyle =
      item.isClicked || !!tappedIndices.get(index)
        ? styles.cardContainerWhite
        : styles.cardContainerPink;
    const {
      media: { url = '' } = {},
      text: { title = '', message = '' } = {},
    } = item;
    const notificationTitle = item.isLocal ? item.title : title;
    const notificationMessage = item.isLocal ? item.description : message;
    const notificationImage = item.isLocal ? item.image : url;
    const imageStyle = item.isLocal
      ? styles.localNotificationImage
      : styles.image;
    return (
      <TouchableOpacity
        onPress={() => this.handleNotificationTapAndDeepLink(item, index)}
      >
        <View style={cardContainerStyle}>
          <View style={styles.cardTextContainer}>
            <Text style={styles.title} numberOfLines={1} ellipsizeMode='tail'>
              {notificationTitle}
            </Text>
            <Text style={styles.time}>{shortDate}</Text>
          </View>
          <Text style={styles.subtitle} numberOfLines={3} ellipsizeMode='tail'>
            {notificationMessage}
          </Text>
          {Utility.isPresent(notificationImage) && (
            <FastImageView source={notificationImage} style={imageStyle} />
          )}
        </View>
      </TouchableOpacity>
    );
  };

  onFocus = () => {
    AnalyticsManager.logEvent(EventType.discoveryEvents.NOTIFICATION_CENTER);
    AnalyticsManager.setCurrentScreen(SCREEN_CONSTANTS.NOTIFICATION_CENTER);
  };

  goBack = () => {
    const { navigation } = this.props;
    goBackWithFallbackHome(navigation);
    return true;
  };

  paginate = () => {
    const { inboxData = [], notifications = [] } = this.state;
    const notificationToBeAdded = inboxData.slice(
      notifications.length,
      notifications.length + this.initialRenderItems,
    );
    this.setState({
      notifications: [...notifications, ...notificationToBeAdded],
    });
  };

  keyExtractor = (item, index) => `${item?.textContent?.title}_${index}`;

  render() {
    const { scrollY, notifications } = this.state;

    return (
      <View style={styles.container}>
        <View style={styles.header}>
          <CustomNavigationHeader
            ref={this.navigationRefs}
            scrollY={scrollY}
            goBack={this.goBack}
            screenName={SCREEN_CONSTANTS.NOTIFICATION_CENTER}
            showCartIcon={false}
            showShareButton={false}
            utmCampaign={'notification_center'}
          >
            <DockedHeader name={'Notifications'} />
          </CustomNavigationHeader>
        </View>
        <FlatList
          extraData={this.state}
          data={notifications}
          renderItem={this.notificationCard}
          contentContainerStyle={styles.flatlist}
          onEndReached={this.paginate}
          onEndReachedThreshold={0.5}
          keyExtractor={this.keyExtractor}
          initialNumToRender={this.initialRenderItems}
          maxToRenderPerBatch={this.initialRenderItems}
        />
      </View>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.UserAccountInfo.authToken,
  localNotificationData: state.UserAccountInfo.notificationData,
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      getListDetails,
      applyCartCoupon,
      updateMediaId,
      updateLocalNotificationData,
    },
    dispatch,
  ),
});

export default withNavigation(
  connect(mapStateToProps, mapDispatchToProps)(NotificationHistory),
);

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.background2,
    flex: 1,
  },
  flatlist: {
    paddingVertical: 12,
  },
  header: { backgroundColor: colors.white },
  cardContainerWhite: {
    marginHorizontal: 12,
    backgroundColor: colors.white,
    marginVertical: 5,
    borderRadius: 4,
    paddingBottom: 8,
  },
  cardContainerPink: {
    marginHorizontal: 12,
    backgroundColor: colors.powderPink,
    marginVertical: 5,
    borderRadius: 4,
    paddingBottom: 8,
  },
  cardTextContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginHorizontal: 12,
    marginTop: 12,
  },
  image: {
    width: Utility.getScreenWidth() - 32,
    resizeMode: 'cover',
    height: 168,
    borderRadius: 4,
    alignSelf: 'center',
    backgroundColor: colors.white,
    marginTop: 8,
  },
  localNotificationImage: {
    width: Utility.getScreenWidth() - 32,
    resizeMode: 'contain',
    height: 130,
    borderRadius: 4,
    alignSelf: 'center',
    backgroundColor: colors.white,
    marginTop: 8,
  },
  title: {
    fontSize: size.h3,
    color: colors.black,
    fontFamily: 'Roboto-Bold',
    fontWeight: 'normal',
    flex: 1,
  },
  subtitle: {
    fontSize: size.h4,
    color: colors.black,
    fontWeight: 'normal',
    marginTop: 3,
    marginHorizontal: 12,
  },
  time: {
    fontSize: size.h4,
    color: colors.subtitle,
    textAlign: 'center',
    marginLeft: 4,
  },
});
