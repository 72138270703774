import React, { useMemo } from 'react';
import { View, StyleSheet } from 'react-native';
import Svg, { ClipPath, Rect, Image as SvgImage } from 'react-native-svg';
import images from '../../theme/Images';
import { getMinifiedImage } from '../../utils/ImageUtility';
import FastImageView from '../FastImageView';
import DebouncedTouchableOpacity from '../shared/DebouncedTouchableOpacity';
import { getHitSlop } from '../../utils/LayoutUtility';

const ClippedStar = ({ width, size }) => {
  const filledStar = getMinifiedImage(
    images.bottom_rating.filled_star,
    size,
    size,
  );
  return (
    <Svg width={size} height={size} viewBox='0 0 12 12'>
      <ClipPath id='clip'>
        <Rect x='0' y='0' width={width} height={size} />
      </ClipPath>
      <SvgImage
        href={filledStar}
        width='100%'
        height='100%'
        preserveAspectRatio='xMidYMid slice'
        clipPath='url(#clip)'
      />
    </Svg>
  );
};

const ProductRating = (props) => {
  const { rating = 0, size = 12, inBetweenSpace = 3, onIconTap } = props;
  const filledStar = getMinifiedImage(
    images.bottom_rating.filled_star,
    size,
    size,
  );
  const emptyStar = getMinifiedImage(
    images.bottom_rating.empty_star,
    size,
    size,
  );
  const fullStars = Math.floor(rating);
  const partialStar = rating - fullStars;
  const emptyStars = 5 - Math.ceil(rating);

  const starStyle = useMemo(
    () => [
      styles.star,
      { height: size, width: size, marginRight: inBetweenSpace },
    ],
    [size, inBetweenSpace],
  );

  const starStyleWithAbsolute = useMemo(
    () => [
      styles.starWithPositionAbsolute,
      { height: size, width: size, marginRight: inBetweenSpace },
    ],
    [size, inBetweenSpace],
  );

  return (
    <View style={styles.flexDirection}>
      <DebouncedTouchableOpacity
        {...props}
        onPress={onIconTap}
        hitSlop={getHitSlop()}
        style={styles.flexDirection}
      >
        {Array.from({ length: fullStars }).map((_, index) => (
          <FastImageView key={`filled-${index}`} source={filledStar} style={starStyle} />
        ))}
        {partialStar > 0 && (
          <View style={styles.margin}>
            <ClippedStar width={size * partialStar} size={size} />
            <FastImageView source={emptyStar} style={starStyleWithAbsolute} />
          </View>
        )}
        {Array.from({ length: emptyStars }).map((_, index) => (
          <FastImageView key={`empty-${index}`} source={emptyStar} style={starStyle} />
        ))}
      </DebouncedTouchableOpacity>
    </View>
  );
};

export default ProductRating;

const styles = StyleSheet.create({
  star: {
    height: 12,
    width: 12,
    marginRight: 3,
  },
  starWithPositionAbsolute: {
    height: 12,
    width: 12,
    position: 'absolute',
    zIndex: -1,
  },
  flexDirection: { flexDirection: 'row' },
  margin: { marginRight: 3 },
});
