import React, { Component } from 'react';
import {
  View,
  SafeAreaView,
  Text,
  Image,
  BackHandler,
  TouchableOpacity,
  StatusBar,
  Appearance,
} from 'react-native';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ImageSize from '../../libraries/ReactNativeImageSize';
import _ from 'lodash';
import { AnimatedCircularProgress } from 'react-native-circular-progress';
import style from './Styles/ImageReviewStyles';
import images from '../../theme/Images';
import colors, { Theme } from '../../theme/Colors';
import Utility from '../../utils/Utility';
import {
  FACIAL_FEATURE_TAGS,
  SCREEN,
  SELFIE_STATE,
} from '../../config/Constants';
import {
  getUserAttributes,
  getUserFacialAttributes,
  updateUserAttributeRemote,
  showImageProgressBar,
  updateAttributeLocal,
  areAttributesReviewed,
  startFacialAnalysis,
  retrySelfieAnalysis,
} from '../../actions/LoginActions';
import {
  uploadImage,
  setFacialAnalysisManualProgress,
} from '../../actions/FacialAnalysisActions';
import ImageTagsProcessingUtil from '../../utils/ImageTagsProcessingUtil';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import ProgressBarAnimated from '../../lib/ProgressBarAnimated';
import size from '../../theme/Fonts';
import {
  AnalyticsManager,
  EventParameterKey,
  EventType,
  EventParameterValue,
} from '../../analytics';
import CameraUtils from '../../utils/CameraUtils';
import RemoteConfig from '../../utils/RemoteConfig';
import { REMOTE_CONFIG_KEYS } from '../../config/Constants';
import FoxyShadowButton from '../../lib/FoxyShadowButton';
import FastImageView from '../FastImageView';
import { getFirebasePerformanceTrace } from '../../utils/PerfUtility';
import { getMinifiedImage } from '../../utils/ImageUtility';

class ImageReview extends Component {
  constructor() {
    super();
    this.trace = getFirebasePerformanceTrace(SCREEN_CONSTANTS.SELFIE_REVIEW);
    this.trace.start();
    this.state = {
      imageHeight: 0,
      imageWidth: 0,
      showAttributeSelectorModal: false,
      attributeSelectorModalTitle: '',
      facialAttributes: FACIAL_FEATURE_TAGS,
      currentAttributeValues: {},
      unselectedTags: [],
      unselectedTagCount: 12,
      localImagePath: '',
      selectedMarkerIndex: 0,
      selfieReviewError: false,
      systemTheme: 'light',
    };
    this.setLocalImage = false;
    this.backHandler = null;
    this.buttonText = 'Show my personalized items';
    this.backgroundColor = Theme.light.lightBackgroundColor;
  }

  componentDidMount() {
    const {
      profile: { selfie_image_url },
      getUserFacialAttributes,
      attributesReviewedByUser,
      facialAnalysis: { facialProperties = [] },
      navigation,
      route,
    } = this.props;
    this.setImageHeightWidth(getMinifiedImage(selfie_image_url));

    if (!facialProperties) {
      getUserFacialAttributes('selfie', (response) => {});
    }

    this.setState({
      currentAttributeValues:
        ImageTagsProcessingUtil.getAllowedTags(facialProperties),
      unselectedTagCount:
        ImageTagsProcessingUtil.getAllowedTags(facialProperties).length,
      unselectedTags: ImageTagsProcessingUtil.getAllowedTags(facialProperties),
    });

    if (Utility.isAndroid()) {
      this.backHandler = BackHandler.addEventListener(
        'hardwareBackPress',
        this.onHardwareBackKeyPress,
      );
    }

    this.setLocalImage = route.params?.setLocalImage ?? false;
    this.trace.stop();
    AnalyticsManager.logEvent(EventType.pageLoad.PAGE_LOAD, {
      [EventParameterKey.SCREEN_NAME]: SCREEN_CONSTANTS.SELFIE_REVIEW,
    });

    this.buttonText = RemoteConfig.getValue(
      REMOTE_CONFIG_KEYS.selfie_attribute_last_button_text,
    );

    this.setState({ systemTheme: Appearance.getColorScheme() });
    this.changeListener = Appearance.addChangeListener(({ colorScheme }) => {
      this.setState({ systemTheme: colorScheme });
    });
  }

  componentDidUpdate(prevProps) {
    const {
      attributesReviewedByUser,
      facialAnalysis: { analysis_stage, facialProperties = [] },
      showImageProgressBar,
      profile: { selfie_image_url },
    } = this.props;
    const { local_image_uri } = this.state;
    if (
      prevProps.facialAnalysis.analysis_stage !== SELFIE_STATE.COMPLETED &&
      analysis_stage === SELFIE_STATE.COMPLETED
    ) {
      this.setState({ selfieReviewError: false });
      this.navigateToAttributesSelector();
    } else if (
      prevProps.facialAnalysis.analysis_stage !== SELFIE_STATE.ERROR &&
      analysis_stage === SELFIE_STATE.ERROR
    ) {
      this.setState({ selfieReviewError: true });
      this.navigateToAttributesSelector();
    }
  }

  componentWillUnmount() {
    if (Utility.isAndroid()) {
      this.backHandler.remove();
    }
  }

  onHardwareBackKeyPress = () => {
    const { navigation } = this.props;
    navigation.goBack();
    return true;
  };

  setImageHeightWidth = (imageUrl) => {
    ImageSize.getSize(imageUrl).then((size) => {
      console.tron.log('size of image', size);
      this.setState({
        imageHeight: size.height * (80 / 100),
        imageWidth: size.width * (80 / 100),
      });
    });
  };

  navigateToSelfieScreen = () => {
    const { navigation } = this.props;
    AnalyticsManager.logEvent(EventType.onboardingEvent.SELFIE_RETAKE);
    this.setLocalImage = false;
    navigation.navigate(SCREEN.TAKE_SELFIE, {
      previousScreen: SCREEN_CONSTANTS.IMAGE_REVIEW,
      updateSelfieUriLocal: this.updateSelfieUriLocal,
    });
  };

  goBack = () => {
    const { navigation, setFacialAnalysisManualProgress } = this.props;
    this.setLocalImage = false;
    Utility.forceRefreshHomePage = true;
    Utility.forceHitHomeApi = true;
    setFacialAnalysisManualProgress(true);
    navigation.pop();
  };

  _progressBarContainer = (props) => (
    <View
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        bottom: 50,
        width: Utility.getScreenWidth(),
      }}
    >
      <ProgressBarAnimated
        value={props.currentProgress}
        maxValue={100}
        width={Utility.getScreenWidth() * 0.5}
        height={4}
        backgroundColor={colors.green}
        borderColor={colors.white}
        underlyingColor={colors.silverDark}
      />
      <Text
        style={{
          fontFamily: 'Roboto-Medium',
          fontSize: size.h4,

          color: colors.foxyBlack,
          marginTop: 10,
        }}
      >
        {props.currentProgress > 50 ? 'Analyzing...' : 'Uploading...'}
      </Text>
    </View>
  );

  retrySelfieUpload = () => {
    const { retrySelfieAnalysis } = this.props;
    retrySelfieAnalysis(false);
    AnalyticsManager.logEvent(EventType.onboardingEvent.SELFIE_UPLOAD_RETRY, {
      [EventParameterKey.SOURCE]:
        EventParameterValue.SOURCE.SELFIE_REVIEW_SCREEN,
    });
  };

  _imageSection = () => {
    const {
      profile: { selfie_image_url, local_image_uri },
      facialAnalysis,
    } = this.props;

    return (
      <View style={style.imageSection}>
        <AnimatedCircularProgress
          size={236}
          width={3}
          fill={facialAnalysis.analysis_progress}
          rotation={0}
          tintColor={'#FE4C83'}
          backgroundColor={colors.silverLit}
          style={{
            position: 'absolute',
          }}
        />
        <FastImageView
          source={local_image_uri}
          style={{
            height: 224,
            width: 224,
            borderRadius: 224 / 2,
            position: 'absolute',
          }}
        >
          <TouchableOpacity
            style={{
              backgroundColor: '#000',
              bottom: 0,
              zIndex: 1,
              height: 32,
              width: '100%',
              position: 'absolute',
              alignSelf: 'center',
              opacity: 0.8,
            }}
            activeOpacity={0.5}
            onPress={this.navigateToSelfieScreen}
          >
            <Text
              style={{
                alignSelf: 'center',
                fontSize: 14,
                fontWeight: '400',
                fontFamily: 'Roboto',
                top: 4,
                color: '#fff',
              }}
            >
              Retake
            </Text>
          </TouchableOpacity>
        </FastImageView>
      </View>
    );
  };

  updateSelfieUriLocal = (imageUri, imageName) => {
    const { uploadImage } = this.props;
    this.setLocalImage = true;

    this.setImageHeightWidth(imageUri);
    this.setState({ localImagePath: imageUri });
    CameraUtils.convertImageToBase64(imageUri)
      .then((response) => {
        uploadImage(`data:image/jpeg;base64,${response}`, false);
      })
      .catch((error) => {
        console.tron.log('Error');
      });
  };

  navigateToAttributesSelector = () => {
    const { navigation, route, areAttributesReviewed } = this.props;
    const previousScreen = route.params?.previousScreen;
    const extraData = route.params?.extraData || {};
    if (previousScreen === SCREEN_CONSTANTS.ATTRIBUTE_SELECTOR) {
      areAttributesReviewed(true);
      navigation.pop();
    } else {
      areAttributesReviewed(true);
      const forceUpdatePersonalisedPageAfterSelfie =
        route.params?.forceUpdatePersonalisedPageAfterSelfie;
      navigation.replace(SCREEN_CONSTANTS.ATTRIBUTE_SELECTOR, {
        forceUpdatePersonalisedPageAfterSelfie:
          forceUpdatePersonalisedPageAfterSelfie,
        previousScreen,
        ...extraData,
      });
    }
  };

  render() {
    const { localImagePath, selfieReviewError, systemTheme } = this.state;

    this.backgroundColor =
      systemTheme === 'light'
        ? Theme.light.lightBackgroundColor
        : Theme.dark.darkBackgroundColor;

    const {
      profile: { selfie_image_url },
      facialAnalysis,
      attributesReviewedByUser,
    } = this.props;
    const userFacialAttributes =
      facialAnalysis && facialAnalysis.facialProperties
        ? ImageTagsProcessingUtil.getAllowedTags(
            facialAnalysis.facialProperties,
          )
        : [];

    let selfieImageUrl = { uri: '' };

    if (Utility.isPresent(localImagePath) && this.setLocalImage) {
      selfieImageUrl = { uri: localImagePath };
    } else {
      selfieImageUrl = { uri: getMinifiedImage(selfie_image_url) };
    }

    const fontTitleColor =
      systemTheme === 'light' ? Theme.light.textColor : Theme.dark.textColor;

    const progressText =
      facialAnalysis.analysis_progress > 50 ? 'Analyzing...' : 'Uploading...';

    const statusBarTheme =
      systemTheme === 'light' ? 'dark-content' : 'light-content';

    return (
      <>
        <SafeAreaView
          style={[style.container, { backgroundColor: this.backgroundColor }]}
        >
          {Utility.isIOS() && (
            <StatusBar
              backgroundColor={systemTheme}
              barStyle={`${statusBarTheme}`}
            />
          )}
          <View style={style.wrapper}>
            <this._imageSection
              attributesReviewedByUser={attributesReviewedByUser}
              selfie_image_url={selfieImageUrl}
              facialAttributes={userFacialAttributes}
              currentSelfieAnalysisStatus={facialAnalysis.analysis_stage}
            />
            <View style={style.textContainer}>
              <Text
                style={[
                  style.text,
                  { color: fontTitleColor },
                ]}
              >
                {progressText}
              </Text>
            </View>

            {selfieReviewError && (
              <FoxyShadowButton
                width={Utility.getScreenWidth() - 30}
                title={'Retry'}
                style={style.foxyButton}
                borderRadius={16}
                firstColor="#FF585E"
                secondColor="#FE4C83"
                underlayColor="#FF585E"
                onPress={this.navigateToSelfieScreen}
              />
            )}
            <TouchableOpacity
              style={[
                style.imageViewContainer,
                { tintColor: '#000' },
              ]}
              onPress={this.goBack}
            >
              <Image
                source={images.cross}
                style={[style.imageView, { tintColor: fontTitleColor }]}
              />
            </TouchableOpacity>
          </View>
        </SafeAreaView>
      </>
    );
  }
}

const mapStateToProps = function (state) {
  return {
    profile: state.UserAccountInfo.profile,
    facialAnalysis: state.UserAccountInfo.facialAnalysis,
    attributesReviewedByUser: state.UserAccountInfo.areAttributesReviewed,
  };
};
const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      getUserAttributes,
      getUserFacialAttributes,
      updateUserAttributeRemote,
      updateAttributeLocal,
      showImageProgressBar,
      areAttributesReviewed,
      uploadImage,
      startFacialAnalysis,
      setFacialAnalysisManualProgress,
      retrySelfieAnalysis,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(ImageReview);
