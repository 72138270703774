import {
  View,
  Text,
  TouchableOpacity,
} from 'react-native';
import StyleSheet from 'react-native-media-query';
import React from 'react';
import { useNavigation } from '@react-navigation/native';
import colors from '../../theme/Colors';
import { navigateToScreen } from '../../utils/NavigationUtility';
import { useApiCall } from '../../lib/hooksUtil';
import { URL } from '../../config/Constants';

export default function TopBrands() {
  const { data: topBrands = [] } = useApiCall(URL.TOP_BRANDS, []);
  return (
    <View style={styles.container}>
      <View>
        <Text style={styles.heading} dataSet={{ media: ids.heading }}>TOP BRANDS</Text>
        <View style={styles.brandLinks} dataSet={{ media: ids.brandLinks }}>
          {topBrands.map(({ name, slug }) => <Brand name={name} link={slug} />)}
        </View>
      </View>
    </View>
  );
}


const Brand = ({ name, link }) => {
  const navigation = useNavigation();
  const navigateToBrand = () => {
    navigateToScreen({
      navigation,
      type: 'push',
      screen: 'Brand',
      params: {
        shortSlug: link,
      },
    });
  };
  return (
    <View key={name} style={styles.brand}>
      <TouchableOpacity onPress={navigateToBrand}>
        <Text style={styles.brandLink}>{name}</Text>
      </TouchableOpacity>
      <Text style={styles.brandLink}>   |   </Text>
    </View>
  );
};

const { ids, styles } = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
  },
  heading: {
    fontSize: 11,
    fontFamily: 'Roboto-Bold',
    fontWeight: 900,
    marginLeft: 20,
    marginTop: 44,
    marginBottom: 12,
    '@media (min-width: 768px)': {
      marginLeft: 155,
      marginTop: 30,
      color: colors.black,
      fontSize: 16,
      lineHeight: 24,
    },
  },
  brandLinks: {
    marginHorizontal: 20,
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginBottom: 30,
    '@media (min-width: 768px)': {
      marginTop: 20,
      marginHorizontal: 155,
    },
  },
  brandLink: {
    fontSize: 12,
    fontFamily: 'Roboto-Regular',
    color: '#17314380',
    fontWeight: 500,
  },
  brand: {
    marginBottom: 8,
    flexDirection: 'row',
  },
});
