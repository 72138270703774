import React, { Component } from 'react';
import { View } from 'react-native';
import Swiper from 'react-native-swiper/src';
import PropTypes from 'prop-types';
import styles from './styles';
import Banner from '../../../containers/banner/Banner';
import FeatureCard from '../../feature/FeatureCard';
import { LAYOUT, BANNER_TIMEOUT } from '../../../config/Constants';
import Utility from '../../../utils/Utility';
import Offer from '../../../containers/offer/Offer';
import TallBanner from '../../tall-banner/TallBanner';
import RatingCard from '../../testimonialCard/RatingCard';
import EdgeToEdgeBanner from '../../feature/EdgeToEdgeBanner';
import ErrorBoundaryComponent from '../../shared/ErrorBoundaryComponent';
import { isDesktop } from '../../../utils/BooleanUtility';

class Pager extends Component {
  static Components = {
    banner: Banner,
    offer: Offer,
    feature: FeatureCard,
    tall_banner: TallBanner,
    rating: RatingCard,
    edge_to_edge_banner: EdgeToEdgeBanner,
  };

  static getListComponent = (display) => {
    return Pager.Components[display];
  };

  static getComponentHeight(item) {
    const { objects = [] } = item;
    try {
      return this.getListComponent(objects[0]?.display).getComponentHeight(
        objects[0],
      );
    } catch (error) {
      return (
        styles.pageContainer.height + styles.pageContainer.marginVertical * 2
      );
    }
  }

  autoplay = true;

  getComponent = (item, index) => {
    if (Utility.isBlank(item)) {
      return null;
    }
    const {
      id,
      navigation,
      allVideosId,
      previousScreen,
      listData,
      listData: { slug: listSlug = '' } = {},
      listName,
      listContent,
      parentListsData = [],
      extraEventParameters = {},
    } = this.props;
    const ContainerComponent = Pager.Components[item.type];
    if (item === undefined || ContainerComponent === undefined) {
      return null;
    }
    return (
      <ErrorBoundaryComponent
        itemData={item}
        listData={listData}
        screenName={previousScreen}
        key={item.id}
      >
        <ContainerComponent
          key={item.id}
          id={item.id}
          layout={LAYOUT.PAGER}
          itemData={item}
          type={item.type}
          navigation={navigation}
          listId={id}
          index={index}
          listData={listData}
          listName={listName}
          listSlug={listSlug}
          listContent={listContent}
          listIndex={this.props.index}
          parentListsData={parentListsData}
          allVideosId={allVideosId}
          previousScreen={previousScreen}
          refreshOfferStrip={this.props.refreshOfferStrip}
          extraEventParameters={extraEventParameters}
        />
      </ErrorBoundaryComponent>
    );
  };

  saveSwiperRef = (ref) => {
    this.swiperRef = ref;
  };

  render() {
    const { display, content, item = [] } = this.props;
    if (Utility.isBlank(item)) {
      return null;
    }
    if (item.length === 0) {
      this.autoplay = false;
    }
    const { listData: { objects = [] } = {} } = this.props;
    const dotStyle =
      objects[0]?.type === 'feature' ? styles.dotStyleFeature : styles.dotStyle;

    const dotComponentStyle =
      display === 'pagerBottom' ? styles.dotStyleBottomPager : dotStyle;
    const dot = <View style={dotComponentStyle} />;
    const activeDotStyle =
      objects[0]?.type === 'feature'
        ? styles.activeDotStyleFeature
        : styles.activeDotStyle;

    const activeDot = <View style={activeDotStyle} />;

    const activeBottomDot = <View style={styles.dotStyleBottom} />;
    let imageSize = {};
    if (Utility.isPresent(objects)) {
      const { type } = objects[0];
      //TODO: This is only works for Feature cards of the type LargeBanner
      if (type === 'feature') {
        imageSize = [styles.pageContainerFeature, isDesktop() && styles.pageContainerFeatureDesktop];
        if (objects[0]?.display === 'postcard') {
          imageSize = styles.pagerBottomContainerFeature;
        }
        if (objects[0]?.display === 'edge_to_edge_banner') {
          imageSize = [
            ...imageSize,
            { height: EdgeToEdgeBanner.getComponentHeight(objects[0]) },
          ];
        }
        if (objects[0]?.display === 'overflow') {
          imageSize = styles.pagerBottomContainerOverFlow;
        }
      } else if (type === 'tall_banner') {
        imageSize = styles.pageContainerTallBanner;
      } else if (objects[0]?.display === 'large_banner') {
        imageSize = [
          styles.pageContainerFeature,
          { height: (Utility.getScreenWidth() - 24) / 1.25 },
        ];
      } else if (objects[0]?.type === 'rating') {
        imageSize = styles.pageContainerRating;
      } else {
        imageSize = styles.pageContainer;
      }
    }

    const showsPagination = content !== 'rating';

    const paginationStyle =
      display === 'pagerBottom'
        ? bottomPaginationStyle
        : sidePaginationStyle;

    const activeDotView =
      display === 'pagerBottom' ? activeBottomDot : activeDot;
    return (
      <View style={imageSize}>
        <Swiper
          ref={this.saveSwiperRef}
          autoplayTimeout={BANNER_TIMEOUT}
          autoplay={this.autoplay}
          showsPagination={showsPagination}
          dot={dot}
          activeDot={activeDotView}
          paginationStyle={paginationStyle}
        >
          {item.map((pagerItem, index) => this.getComponent(pagerItem, index))}
        </Swiper>
      </View>
    );
  }
}

Pager.propTypes = {
  list: PropTypes.shape({
    objects: PropTypes.array,
  }),
};
export default Pager;

const sidePaginationStyle = {
  position: 'absolute',
  left: null,
  right: 20,
  top: 8,
  bottom: null,
};

const bottomPaginationStyle = {
  position: 'absolute',
  left: 0,
  right: 0,
  bottom: 0,
};
