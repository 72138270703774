import React, { PureComponent } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import images from '../../theme/Images';
import { ProductDescription } from '../../components/Product';
import {
  LAYOUT,
  REMOTE_CONFIG_KEYS,
  CURRENCY_SYMBOL,
} from '../../config/Constants';
import Utility from '../../utils/Utility';
import DebouncedTouchableOpacity from '../../components/shared/DebouncedTouchableOpacity';
import colors from '../../theme/Colors';
import GrayScaledImage from './GrayscaleImage';
import size from '../../theme/Fonts';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import ContactsUtility from '../../utils/ContactsUtility';
import RemoteConfig from '../../utils/RemoteConfig';
import { navigateToScreen } from '../../utils/NavigationUtility';
import { getMinifiedImage } from '../../utils/ImageUtility';

class ReferralProductCard extends PureComponent {
  constructor(props) {
    super(props);
    const { item: { sku = 'free_gift' } = {} } = props;

    if (sku === 'free_gift') {
      this.referralGiftData = Utility.jsonParser(
        RemoteConfig.getValue(REMOTE_CONFIG_KEYS.group_deals_contacts_strings),
      ).referral_gift_data;
    }
  }

  GroupDealTag = (props) => {
    const { sku, enableGrayscale } = props;
    if (sku === 'free_gift') return null;
    return (
      <GrayScaledImage
        source={images.pinkLabel}
        style={styles.tagImageContainer}
        resizeMode='contain'
        enableGrayscale={enableGrayscale}
        isBackground
      >
        <Text style={styles.tagTextStyle}>Group Deal</Text>
      </GrayScaledImage>
    );
  };

  navigateToMoreItems = () => {
    const { navigation, listSlug, setModalVisible } = this.props;

    if (Utility.isBlank(listSlug)) return;

    if (setModalVisible) {
      setModalVisible(false);
    }
    setTimeout(() => {
      navigateToScreen({
        navigation,
        type: 'push',
        screen: 'MoreItems',
        params: {
          slug: `/${listSlug}`,
        },
      });
    }, 300);
  };

  navigateToProduct = () => {
    const {
      item = {},
      item: { id: productId, slug, product_slug: proSlug } = {},
      navigation,
      previousScreen,
    } = this.props;
    const finalSlug = Utility.isPresent(slug) ? slug : proSlug;

    if (
      Utility.isBlank(finalSlug) ||
      previousScreen === SCREEN_CONSTANTS.INVITE_TO_CLAIM_MODAL
    ) {
      return;
    }

    navigateToScreen({
      navigation,
      type: 'push',
      screen: 'Product',
      params: {
        productId,
        slug: finalSlug,
        display: LAYOUT.SCREEN,
        item,
      },
    });
  };

  navigate = () => {
    const { item: { sku = '' } = {} } = this.props;

    if (sku === 'free_gift') {
      this.navigateToMoreItems();
    } else {
      this.navigateToProduct();
    }
  };

  FreeGiftDescription = (props) => {
    const { enableGrayscale } = props;
    const { name, mrp } = this.referralGiftData;
    const spStyle = enableGrayscale
      ? styles.disabledReferralSellingPrice
      : styles.refSellingPrice;
    return (
      <View style={styles.referralProductContainer}>
        <Text style={styles.refProductTitle}>{name}</Text>
        <View style={styles.refPricingContainer}>
          <Text style={spStyle}>FREE</Text>
          <Text style={styles.refMRP}>{`${CURRENCY_SYMBOL}${mrp}`}</Text>
        </View>
      </View>
    );
  };

  ProductDescription = (props) => {
    const {
      item = {},
      item: {
        name = '',
        product_name: productName = '',
        mrp = '',
        offer_value = '',
        sp = '',
        discount = '',
        id = '',
        brand_name: variantBrandName = '',
        sku = {},
        sku: { group_buying_price: groupPrice = '' } = {},
      } = {},
      enableGrayscale,
    } = props;

    if (sku === 'free_gift') {
      return (
        <this.FreeGiftDescription
          name={name}
          mrp={mrp}
          enableGrayscale={enableGrayscale}
        />
      );
    }

    const disc = Utility.isPresent(groupPrice)
      ? `${ContactsUtility.calculateDiscountPercentage(groupPrice, mrp)}% off`
      : discount;
    const displayName = Utility.isPresent(name) ? name : productName;
    return (
      <ProductDescription
        itemData={item}
        name={displayName}
        mrp={mrp}
        offer_value={offer_value}
        sp={sp}
        display_price={groupPrice}
        discount={disc}
        variantBrandName={variantBrandName}
        layout={LAYOUT.LIST}
        id={id}
        enableGrayscale={enableGrayscale}
      >
        <this.GroupDealTag sku={sku} enableGrayscale={enableGrayscale} />
      </ProductDescription>
    );
  };

  ProductImage = (props) => {
    const {
      item: { images: imageArray = [], sku = '' } = {},
      enableGrayscale,
    } = props;
    const imageUrl =
      sku === 'free_gift'
        ? getMinifiedImage(this.referralGiftData?.image, styles.image.width, styles.image.height)
        : getMinifiedImage(imageArray[0], styles.image.width, styles.image.height);
    return (
      <GrayScaledImage
        style={styles.image}
        source={{ uri: imageUrl }}
        resizeMode='contain'
        enableGrayscale={enableGrayscale}
      />
    );
  };

  render() {
    const { enableGrayscale, item } = this.props;
    return (
      <DebouncedTouchableOpacity
        {...this.props}
        style={styles.productContainer}
        onPress={this.navigate}
      >
        <this.ProductImage item={item} enableGrayscale={enableGrayscale} />
        <View style={styles.productDesc}>
          <this.ProductDescription
            item={item}
            enableGrayscale={enableGrayscale}
          />
        </View>
      </DebouncedTouchableOpacity>
    );
  }
}

export default ReferralProductCard;

const styles = StyleSheet.create({
  productContainer: {
    flexDirection: 'row',
    marginTop: 12,
    alignItems: 'center',
  },
  tagImageContainer: {
    flex: 1,
    width: 85,
    height: 16,
    alignItems: 'flex-start',
    paddingLeft: 2,
    marginLeft: 8,
    marginTop: 4,
    justifyContent: 'center',
  },
  productDesc: {
    width: '83%',
    justifyContent: 'space-evenly',
    marginTop: -18,
  },
  tagTextStyle: {
    fontSize: size.h4_5,
    fontFamily: 'Roboto-Medium',
    color: colors.white,
    lineHeight: 12.9,
    marginLeft: 4,
  },
  image: { height: 64, width: 64, marginRight: 8 },
  referralProductContainer: { marginBottom: -4 },
  refProductTitle: {
    fontFamily: 'Roboto-Regular',
    fontSize: size.h4,
    color: colors.black,
    marginLeft: 8,
    marginTop: 12,
  },
  refPricingContainer: { flexDirection: 'row' },
  refSellingPrice: {
    fontFamily: 'Roboto-Medium',
    fontSize: size.h4,
    color: colors.green,
    marginLeft: 8,
  },
  disabledReferralSellingPrice: {
    fontFamily: 'Roboto-Medium',
    fontSize: size.h4,
    color: colors.subtitle,
    marginLeft: 8,
  },
  refMRP: {
    fontFamily: 'Roboto-Regular',
    fontSize: size.h4,
    color: colors.subText,
    marginLeft: 8,
    textDecorationLine: 'line-through',
    textDecorationStyle: 'solid',
  },
});
